import { Divider } from "antd";
import Upload from "antd/lib/upload/Upload";
import React, { useState } from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";
import uniqid from "uniqid";
import axios from "axios";
import ImgCrop from "antd-img-crop";

// import "antd/es/modal/style";
// import "antd/es/slider/style";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  compeleteResetProjectOnboarding,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import CustomColorPicker from "../../../Core/CommonV2/CustomColorPicker";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useNavigate } from "react-router";

const Step1 = () => {
  const { projectName, projectColor, projectImage } = useAppSelector(
    (state) => state.projectOnboarding
  );
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const { projectThemeColor } = useAppSelector((state) => state.dashboard);

  const [fileList, setFileList] = useState([]);

  const [fileUploadedList, setFileUploadedList] = useState([]);

  const handleChange = ({ fileList: newFileList }) => {
    console.log(newFileList, "newFileList dleetetest");
    setFileList(newFileList);
    const fileData = [];

    console.log(newFileList);

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
        });
      });
    });
    console.log(fileData, "file");
    setFileUploadedList(fileData);
    dispatch(
      updateProjectOnboarding({
        key: "projectImage",
        value: fileData[0]?.url || "",
      })
    );
  };

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    console.log("FILE: ", file);
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          console.log("UPLOADED", res.data);
          onSuccess(res.data);
        });
    } catch (err) {
      onError({ err });
    }
  };

  const formik = useFormik({
    initialValues: {
      projectName,
    },
    validationSchema: Yup.object({
      projectName: Yup.string()
        .required("Please enter a project name to continue")
        .typeError("Please enter a project name to continue"),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(changeStep(2));
    },
  });
  const navigate = useNavigate();
  return (
    <StepLayout>
      <CurrentStep className={"mt-20 "} currentStep={1} totalStep={6} />
      <StepHeading
        title={
          <p className="text-gray-900 out-500-20  " id="stepHead">
            Tell us about your
            <span className="text-success-600 out-500-20 ">
              {" "}
              #SoftwareForGood
            </span>{" "}
            initiative
          </p>
        }
        subTitle={
          <>
            Hi {user?.firstName[0]?.toUpperCase() + user.firstName.slice(1)},
            let’s create your first project together!
          </>
        }
      />

      <p className="mt-[18px] out-500-14 text-gray-900 text-base">
        Project Name
      </p>
      <div className="mt-[18px]">
        <CustomOnboardingInput
          placeholder="Enter Project name"
          name="projectName"
          //value={projectName}
          {...formik.getFieldProps("projectName")}
          onChange={(e, value) => {
            formik.setFieldValue("projectName", value);
            dispatch(
              updateProjectOnboarding({
                key: "projectName",
                value,
              })
            );
          }}
          onClear={() => {
            dispatch(
              updateProjectOnboarding({
                key: "projectName",
                value: "",
              })
            );
            formik.setFieldValue("projectName", "");
          }}
          formikHook={formik}
        />
      </div>

      <p className="mt-10 out-500-14 text-gray-900 text-base">
        Let’s add a logo and theme for this project.
      </p>
      <div className="mt-[18px] flex items-center gap-x-6">
        {projectImage && projectImage.length > 0 ? (
          <img
            src={projectImage}
            className="w-16 h-16 rounded-full cursor-pointer object-cover"
            onClick={async () => {
              // const src = projectImage as string;
              // if (!src) return;
              // const image = new Image();
              // image.src = src;
              // const imgWindow = window.open(src);
              // imgWindow?.document.write(image.outerHTML);
              handleChange({ fileList: [] });
            }}
            onMouseEnter={(e) => {
              e.currentTarget.src =
                "/images/icons/project-onboarding/cancelImage.svg";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.src = projectImage;
            }}
          />
        ) : (
          <ImgCrop rotationSlider modalClassName="image-crop-modal ">
            <Upload
              action={process.env.REACT_APP_API_URL + "/v1/upload"}
              // headers={{ Authorization: "Bearer " + user.token }}
              customRequest={uploadImage}
              fileList={fileList}
              onChange={handleChange}
            >
              <div className="w-16 h-16">
                <img
                  src="/images/v2/onboarding/Logo.svg"
                  className="w-16 h-16 cursor-pointer"
                />
              </div>
            </Upload>
          </ImgCrop>
        )}

        <div>
          <Divider type="vertical" className="h-6" />
          <p className="out-500-12 text-gray-500 text-center">or</p>
          <Divider type="vertical" className="h-6" />
        </div>
        <CustomAvatar
          title={projectName ? projectName : "P"}
          size={64}
          color={projectColor}
          whiteText
          fontSize="24px"
        />
        {/* <img src="/images/icons/logo-w.svg" className="w-16 h-16" /> */}
        <div className="flex items-center cursor-pointer text-gray-900">
          <p className="out-500-12 text-gray-500">Change colour</p>
          <CustomColorPicker
            placement="bottomLeft"
            onColorChange={(color) => {
              dispatch(
                updateProjectOnboarding({
                  key: "projectColor",
                  value: color,
                })
              );
            }}
          >
            <button>
              <ChevronIcon className="text-gray-500 ml-2" />
            </button>
          </CustomColorPicker>
        </div>
      </div>
      {/* <ImgCrop rotationSlider modalClassName="image-crop-modal "> */}
      {/* <div className="flex items-center mt-5 ml-1">
            <img src="/images/icons/upload.svg" alt="" />
            <p className="pl-2 out-500-12 text-gray-500">Upload</p>
          </div> */}
      <div className=" w-[162px]  h-[30px] flex items-center justify-between mt-[23px] py-[5px] px-2.5 gap-x-2">
        <div
          className={`w-5 h-5  rounded-[20px]`}
          style={{
            backgroundColor: projectThemeColor ? projectThemeColor : "#165ABF",
          }}
        ></div>
        <div className=" text-gray-500 out-500-12">Event theme</div>

        <CustomColorPicker
          placement="bottomLeft"
          onColorChange={(color) => {
            dispatch(
              updateDashboard({
                key: "projectThemeColor",
                value: color,
              })
            );
          }}
        >
          <button>
            <ChevronIcon className="text-gray-500 ml-2" />
          </button>
        </CustomColorPicker>
      </div>
      {/* </ImgCrop> */}
      <div className="mt-6 flex justify-between items-center">
        <CustomButton
          text="Cancel"
          width="75px"
          height="30px"
          className={`bg-gray-100 out-500-14 text-gray-700 ${
            !formik.isValid ? "opacity-30" : ""
          }`}
          onClick={() => {
            dispatch(compeleteResetProjectOnboarding({}));
            navigate("/dashboard");
          }}
        />
        <div className="flex gap-x-2">
          <CustomButton
            text="Back"
            // onClick={() => onCancel()}
            disabled
            className=" text-gray-700 bg-gray-100 out-500-14"
            height="30px"
            width="63px"
          />
          <CustomButton
            text="Continue"
            width="88px"
            height="30px"
            className={`bg-primary-600 out-500-14 text-white ${
              !formik.isValid ? "opacity-30" : ""
            }`}
            onClick={() => formik.handleSubmit()}
          />
        </div>
      </div>
    </StepLayout>
  );
};

export default Step1;
