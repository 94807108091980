import React, { useEffect, useState } from "react";
import ScheduleLayout from "../Components/ScheduleLayout";
import PlannerMenu from "./PlannerMenu";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import { Divider, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";

import EditSprintModal from "../Modals/EditSprintModal";
import DeleteSprintModal from "../Modals/DeleteSprintModal";

import ToDoList from "./ToDoList";
import SprintAccordian from "./SprintAccordian";

import {
  DndContext,
  useSensors,
  PointerSensor,
  useSensor,
  DragOverlay,
} from "@dnd-kit/core";
import {
  getGoalsBySprint,
  getLabels,
  getTodoList,
  updateGoal,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import Overlay from "./DNDKit/Overlay";
import {
  DEFAULT_LIMIT,
  TASK_ACTIVITY_LOG_ACTIONS,
} from "../../../Core/constants";
import usePermission from "../../../Core/hooks/usePermission";
import CompleteSprintModal from "../Modals/CompleteSprintModal";
import NewLabelModal from "../Modals/NewLabelModal";
import MoveSprintsModal from "../Modals/MoveSprintsModal";
import ConfirmSprintDates from "../Modals/ConfirmSprintDates";
import { useGetSprintsByPhase } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { useQueryClient } from "react-query";
import DeleteTaskModal from "../Modals/DeleteTaskModal";

const PlannerScreen = () => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const {
    editSprintModal,
    confirmDatesModal,
    deleteSprintModal,
    plannerIsDragActive,
    todoList,
    selectedTasks,
    completeSprintModal,
    phases,
    newLabelModal,
    filterGoalParameter,
    moveSprintsModal,
    deleteTaskModal,
  } = useAppSelector((state) => state.schedule);

  const queryClient: any = useQueryClient();

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } })
  );

  const [moveToSprint, setMoveToSprint] = useState(null);
  const [moveToData, setMoveToData] = useState(null);
  const activePhase = phases.find((p) => p.isOngoing);
  const { data: Data, isLoading: sprintsLoading } = useGetSprintsByPhase({
    projectId,
  });

  const sprints = Data?.result;

  const { hasPermission: PHASE_AND_SPRINT_ADD } = usePermission("5");
  return (
    <ScheduleLayout>
      <Spin spinning={sprintsLoading} wrapperClassName="bg-gray-50">
        <div className={` ${sprintsLoading ? " opacity-0" : "bg-white"}`}>
          <PlannerMenu
            search={
              filterGoalParameter?.searchValue?.projectId === projectId &&
              filterGoalParameter?.searchValue?.module === "planner"
                ? filterGoalParameter?.searchValue?.searchValue
                : ""
            }
          />
          <DndContext
            onDragStart={(data) => {
              dispatch(
                updateSchedule({
                  key: "plannerIsDragActive",
                  value: {
                    isActive: true,
                    current: data?.active?.data?.current,
                  },
                })
              );
            }}
            onDragEnd={async (data) => {
              const sprintId = data?.over?.id;
              const sprintName = sprints?.find(
                (sprint) => +sprint.id === sprintId
              )?.name;

              if (sprintId) {
                setMoveToSprint(sprintId);
                setMoveToData(data);
                await dispatch(
                  updateSchedule({
                    key: "moveSprintsModal",
                    value: {
                      visible: true,
                      sprint: sprintName
                        ? `${sprintName} sprint`
                        : "To-do List",
                    },
                  })
                );

                resetSchedule(["plannerIsDragActive"]);
              }
            }}
            onDragCancel={() => {
              resetSchedule(["plannerIsDragActive"]);
            }}
            sensors={sensors}
          >
            <DragOverlay>
              <Overlay
                task={plannerIsDragActive?.current?.task}
                count={
                  plannerIsDragActive?.current?.selectedItems?.length
                    ? plannerIsDragActive?.current?.selectedItems?.length
                    : 1
                }
              />
            </DragOverlay>
            <div className="h-[calc(100vh-170px)] overflow-y-scroll bg-gray-50 py-2 px-6">
              <ToDoList />

              {/* {PHASE_AND_SPRINT_ADD && (
                <button
                  onClick={() => {
                    dispatch(
                      updateSchedule({
                        key: "editSprintModal",
                        value: {
                          visible: true,
                          mode: "new",
                          phaseId: activePhase?.projectPhaseId || "",
                        },
                      })
                    );
                  }}
                  className="flex mt-6 gap-x-3 items-center"
                >
                  <PlusIcon />
                  <span className="out-500-14 text-gray-500">
                    Create new sprint
                  </span>
                </button>
              )} */}
            </div>
          </DndContext>
        </div>
      </Spin>
      <EditSprintModal
        visible={editSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["editSprintModal"]))}
      />
      <ConfirmSprintDates
        visible={confirmDatesModal?.visible}
        onClose={() => dispatch(resetSchedule(["confirmDatesModal"]))}
      />

      <DeleteSprintModal
        visible={deleteSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["deleteSprintModal"]))}
      />
      <MoveSprintsModal
        visible={moveSprintsModal?.visible}
        onMove={async () => {
          const overId = moveToData?.over?.id;
          const goalId = moveToData?.active?.data?.current?.task?.id;
          const currentSprintId =
            moveToData?.active?.data?.current?.task?.sprintId;
          if (moveToSprint) {
            const taskIds = moveToData?.active?.data?.current?.selectedItems;
            if (!taskIds.length) {
              //single item drag
              await dispatch(
                updateGoal({
                  sprintId: moveToSprint,
                  goalId: goalId,
                  action: {
                    oldValue: null,
                    newValue: moveToData?.active?.data?.current?.task,
                    actionName: TASK_ACTIVITY_LOG_ACTIONS.SPRINT_CHANGE,
                  },
                })
              );
              queryClient.invalidateQueries([
                "goalsBySprint",
                projectId,
                +overId,
              ]);
            } else {
              //multiple items drag
              await dispatch(
                updateGoal({
                  sprintId: moveToSprint,
                  goalId: taskIds,
                  action: {
                    oldValue: null,
                    newValue: taskIds,
                    actionName: TASK_ACTIVITY_LOG_ACTIONS.SPRINT_CHANGE,
                  },
                })
              );
              queryClient.invalidateQueries([
                "goalsBySprint",
                projectId,
                +overId,
              ]);
            }
            if (currentSprintId) {
              dispatch(
                getGoalsBySprint({
                  projectId,
                  sprintId: moveToData?.active?.data?.current?.task
                    ?.sprintId as string,
                  module: "sprints",
                })
              );
              queryClient.invalidateQueries([
                "goalsBySprint",
                projectId,
                +currentSprintId,
              ]);
              queryClient.invalidateQueries([
                "goalsBySprint",
                projectId,
                +overId,
              ]);
            } else {
              queryClient.invalidateQueries(["todoList", projectId]);
            }
            dispatch(
              getTodoList({ projectId, limit: DEFAULT_LIMIT, offset: 0 })
            );
          } else {
            //checking if the previous task had a sprint id
            const sprintId = moveToData?.active?.data?.current?.task?.sprintId;
            const taskIds = moveToData?.active?.data?.current?.selectedItems;
            if (sprintId) {
              // dispatch(
              //   updateSchedule({
              //     key: "todoList",
              //     value: todoList.filter((t) => !taskIds.includes(t.id)),
              //   })
              // );
              if (!taskIds.length) {
                await dispatch(
                  updateGoal({
                    sprintId: null,
                    goalId: moveToData?.active?.data?.current?.task?.id,
                    action: {
                      oldValue: sprintId,
                      newValue: taskIds,
                      actionName: TASK_ACTIVITY_LOG_ACTIONS.SPRINT_CHANGE,
                    },
                  })
                );
              } else {
                await dispatch(
                  updateGoal({
                    sprintId: null,
                    goalId: taskIds,
                    action: {
                      oldValue: sprintId,
                      newValue: taskIds,
                      actionName: TASK_ACTIVITY_LOG_ACTIONS.SPRINT_CHANGE,
                    },
                  })
                );
              }

              dispatch(
                getGoalsBySprint({
                  projectId,
                  sprintId: sprintId as string,
                  module: "sprints",
                })
              );

              dispatch(
                getTodoList({ projectId, limit: DEFAULT_LIMIT, offset: 0 })
              );
            }
            queryClient.invalidateQueries([
              "goalsBySprint",
              projectId,
              +sprintId,
            ]);
            queryClient.invalidateQueries(["todoList", projectId]);
          }
          await dispatch(
            updateSchedule({
              key: "moveSprintsModal",
              value: {
                visible: false,
                sprint: "",
              },
            })
          );
          setMoveToData(null);
          setMoveToSprint(null);
        }}
        onClose={() => dispatch(resetSchedule(["moveSprintsModal"]))}
      />

      <CompleteSprintModal
        visible={completeSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["completeSprintModal"]))}
      />
      <NewLabelModal
        visible={newLabelModal}
        onClose={() => dispatch(resetSchedule(["newLabelModal"]))}
      />
      <DeleteTaskModal
        selectedTasks={selectedTasks}
        isPlanner
        visible={deleteTaskModal.visible}
        onClose={() =>
          dispatch(
            updateSchedule({
              key: "deleteTaskModal",
              value: { ...deleteTaskModal, visible: false },
            })
          )
        }
      />
    </ScheduleLayout>
  );
};

export default PlannerScreen;
