import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import axios from "axios";
import uniqid from "uniqid";
import { UploadIcon } from "../../Core/svgV2/UploadIcon";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { CrossIcon } from "../../Core/svgV2/CrossIcon";
import { Progress, Spin } from "antd";
import { bytesToSize } from "../../Core/utils/bytesToSize";
import { useLocation, useParams } from "react-router-dom";
import { createFile, getAllFiles } from "../../Core/redux/api/vaultAPI";
import { resetVault } from "../../Core/redux/slices/vault.slice";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadFailIcon } from "../../Core/svgV2/UploadSuccessIcon";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";

const MessageMask = () => {
  const { currentThread } = useAppSelector((state) => state.conversations);
  return (
    <div
      className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-end justify-center m-2 border rounded-md border-primary-400 "
      style={{
        background: "rgba(245, 250, 255 , 0.8)",
      }}
    >
      <div className="flex gap-x-2 justify-center pb-[43px]">
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary-600">
          <UploadIcon className="text-white" />
        </div>
        <div className="flex items-center justify-center h-10 px-2 text-white rounded-lg bg-primary-600 w-max out-300-16">
          Drop files to send them to
          <span className="ml-2 out-500-16">
            {currentThread.type == "group"
              ? currentThread.threadName
              : currentThread.firstname + " " + currentThread.lastname}
          </span>
        </div>
      </div>
    </div>
  );
};

const UploadingItem = ({ file, onRemove, onTryAgain }) => {
  const renderFileTag = (mimeType = "") => {
    console.log(mimeType, "hvjhvjhvj");
    if (mimeType.includes("pdf")) {
      return "/images/v2/library/file.svg";
    } else if (mimeType.includes("excel") || mimeType.includes("spreadsheet")) {
      return "/images/v2/library/file.svg";
    } else if (mimeType.includes("image")) {
      return "/images/v2/library/image.svg";
    } else if (mimeType.includes("video")) {
      return "/images/v2/library/film.svg";
    } else {
      return "/images/v2/library/file.svg";
    }
  };
  if (file.status === "error") {
    return (
      <div className="flex pt-4 pb-2 items-center pl-9 pr-4">
        <div className="mr-[22px] h-full self-start  ">
          <UploadFailIcon
            className="text-error-600 mt-1.5"
            width="16"
            height="16"
          />
        </div>
        <div className="w-[278px] mr-4 ">
          <p className="out-500-14 text-error-600">
            Upload failed, please try again
          </p>
          <p className="out-300-14 w-[260px] truncate whitespace-nowrap text-error-500">
            {file.name}
          </p>
          <button
            className="out-500-14 text-error-600 mt-1"
            onClick={() => {
              onRemove(file);
              onTryAgain(file.orjFile);
            }}
          >
            Try again
          </button>
        </div>

        <button onClick={() => onRemove(file)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M2.5 5.00033H4.16667M4.16667 5.00033H17.5M4.16667 5.00033V16.667C4.16667 17.109 4.34226 17.5329 4.65482 17.8455C4.96738 18.1581 5.39131 18.3337 5.83333 18.3337H14.1667C14.6087 18.3337 15.0326 18.1581 15.3452 17.8455C15.6577 17.5329 15.8333 17.109 15.8333 16.667V5.00033H4.16667ZM6.66667 5.00033V3.33366C6.66667 2.89163 6.84226 2.46771 7.15482 2.15515C7.46738 1.84259 7.89131 1.66699 8.33333 1.66699H11.6667C12.1087 1.66699 12.5326 1.84259 12.8452 2.15515C13.1577 2.46771 13.3333 2.89163 13.3333 3.33366V5.00033M8.33333 9.16699V14.167M11.6667 9.16699V14.167"
              stroke="#B42318"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  }
  return (
    <div className="flex pt-4 pb-2 items-center pl-9 pr-4">
      <div className="mr-[22px] h-full self-start ">
        <img
          src={renderFileTag(file.type)}
          className="w-3 h-3 min-w-[12px] min-h-[12px] mt-1.5"
        />
      </div>
      <div className="w-[268px] mr-4 ">
        <p className="out-500-14 w-[270px] truncate whitespace-nowrap text-black">
          {file.name}
        </p>
        {file.size && (
          <p className="out-300-12 text-gray-500">
            {bytesToSize(file.size)} – {file.percent}% uploaded
          </p>
        )}
      </div>

      {file.percent < 100 && file.status !== "done" ? (
        <Progress
          type="circle"
          trailColor="#e6f4ff"
          percent={file.percent}
          strokeWidth={10}
          size={32}
          format={() => ""}
          className="progress-upload"
        />
      ) : file.percent === 100 && file.status !== "done" ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />} />
      ) : file.percent === 100 && file.status === "done" ? (
        <div className="h-[32px] w-[32px] flex items-center justify-center">
          <img
            src="/images/v2/conversations/checkbox.svg"
            className="min-h-[16px] min-w-[16px]"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const UploadList = ({ uploadList, onClose, onRemove, onTryAgain }) => {
  const [open, setOpen] = useState(true);
  const { currentSelectedFolder } = useAppSelector((state) => state.vault);

  const { projectId } = useParams();
  const { projectList } = useAppSelector((state) => state.projects);

  const getOverallStatus = () => {
    if (uploadList.every((file) => file.status === "done")) {
      return "done";
    } else {
      return "uploading";
    }
  };

  const calculateTotalProgress = () => {
    if (uploadList.length === 0) {
      return 0;
    }

    const totalProgress = uploadList.reduce((accumulator, file) => {
      return accumulator + file.percent;
    }, 0);

    return Math.round(totalProgress / uploadList.length);
  };

  const overallStatus = getOverallStatus();
  const totalProgress = calculateTotalProgress();

  const getFolderName = () => {
    // if(location.pathname.includes("my-lib"))
    if (currentSelectedFolder?.folderName) {
      return currentSelectedFolder?.folderName;
    }
    if (projectId) {
      const project = projectList.find((p) => p.id == projectId);
      if (project) {
        return project.title;
      }
      return "Project";
    }
    return "My Library";
  };

  if (uploadList.length === 0) return <></>;

  return (
    <div
      className={`w-[400px] z-50 ${
        open ? "h-[382px]" : "max-h-[98px]"
      } bg-white shadow-xl absolute bottom-0  right-6`}
    >
      <div
        className={`flex px-4 pt-4 ${
          open ? "pb-6" : "pb-[14px]"
        } justify-between`}
      >
        <span className="out-500-14 text-black">
          {totalProgress === 100 && overallStatus === "done"
            ? "Upload "
            : "Uploading "}
          Files
        </span>
        <div className="flex gap-x-2">
          <button onClick={() => setOpen(!open)}>
            <ChevronIcon className="text-gray-700" />
          </button>
          <button onClick={onClose}>
            {" "}
            <CrossIcon className="text-gray-700" />{" "}
          </button>
        </div>
      </div>
      {open ? (
        <div className="max-h-[310px] overflow-y-auto">
          {uploadList.map((file) => (
            <UploadingItem
              file={file}
              key={file.uid}
              onRemove={onRemove}
              onTryAgain={onTryAgain}
            />
          ))}
        </div>
      ) : (
        <div className="flex  pb-2 items-center pl-6 pr-4">
          <div className="mr-[22px]">
            <img src="/images/v2/conversations/upload-cloud.svg" />
          </div>
          <div className="w-[278px] mr-4 ">
            <p className="out-500-14 w-[270px] truncate whitespace-nowrap text-black">
              {totalProgress === 100 && overallStatus === "done"
                ? `${uploadList.length} files uploaded to ${getFolderName()}`
                : `Uploading ${uploadList.length} files`}
            </p>
          </div>

          {totalProgress < 100 && overallStatus !== "done" ? (
            <Progress
              type="circle"
              trailColor="#e6f4ff"
              percent={totalProgress}
              strokeWidth={10}
              size={32}
              format={() => ""}
              className="progress-upload"
            />
          ) : totalProgress === 100 && overallStatus !== "done" ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} />} />
          ) : totalProgress === 100 && overallStatus === "done" ? (
            <div className="h-[32px] w-[32px] flex items-center justify-center">
              <img src="/images/v2/conversations/checkbox.svg" />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

const DragAndDropLayer = ({ children }) => {
  const [fileList, setFileList] = useState([]);
  const userLocal = JSON.parse(localStorage.getItem("user"));
  const [uploadStatus, setUploadStatus] = useState([]);
  const { projectId, "*": splat } = useParams();
  const dispatch = useAppDispatch();
  const { files } = useAppSelector((state) => state.vault);
  const location = useLocation();
  const { projectList } = useAppSelector((state) => state.projects);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const projectDetails = projectList.find(
    (project) => project.id === projectId
  );

  const saveFileToDB = async (uploadedFileList) => {
    const successfulUploads = uploadedFileList.filter(
      (file) => file.status === "done"
    );
    console.log(uploadedFileList, "file mime upload");
    if (successfulUploads.length === 0) return;
    const payload = {
      files: successfulUploads,
      projectId: projectId,
      folderId: null,
      notificationId: null,
      tenantId: tenantDetails?.tenantId,
    };

    let queryObject: {
      projectId: string;
      folderId?: string;
      tenantId?: string;
    };

    if (projectId == "undefined" || projectId == null) {
      console.log(" hi comes");
      queryObject = { projectId: null, tenantId: tenantDetails?.tenantId };
    } else {
      queryObject = { projectId };
    }
    console.log(queryObject, "query");
    if (splat && splat.length > 0) {
      const folderId = splat.split("-");
      payload.folderId = folderId[folderId.length - 1];
      queryObject.folderId = folderId[folderId.length - 1];
    }
    // if (preFilledVaultModalValues.notificationId) {
    //   payload.notificationId = preFilledVaultModalValues.notificationId;
    // }

    try {
      await dispatch(createFile(payload as any)).then(() => {
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: "New File",
                subtitle: ` Uploaded ${uploadedFileList.length} new ${
                  uploadedFileList.length > 1 ? "files" : "file"
                } to `,
                description: location.pathname.includes("my-library")
                  ? "My Library"
                  : projectDetails?.title
                  ? projectDetails.title
                  : "Global Scope",
              },
            },
          })
        );
      });
    } catch (error) {
      console.log(error, "here 3");
    }

    //setFileList([]);
    //   const newFileList = [...fileList].map((file)=>{...})
    dispatch(resetVault(["addFileModal", "shareAccessDoc"]));
    dispatch(getAllFiles(queryObject));
  };

  const handleUploadToStorJ = async (files: FileList) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + userLocal.token,
      },
    };

    const createFileObject = (file) => {
      const uniqueFileName = `${uniqid(
        `${file.name.split(".")[0]}-`
      )}.${file.name.split(".").at(-1)}`;

      return {
        uid: `${uniqueFileName}`,
        name: file.name,
        status: "uploading",
        url: URL.createObjectURL(file),
        fileUrl: URL.createObjectURL(file),
        percent: 0,
        type: file.type,
        size: file.size,
        orjFile: file,
      };
    };

    const uploadFile = async (file) => {
      const fmData = new FormData();
      let dimensionalValue = 0;

      fmData.append("image", file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result.toString();

        img.onload = () => {
          // Access image dimensions

          dimensionalValue = img.width * img.height;

          // You can set the image state or perform further actions here
          // setImage(file);
        };
      };
      reader.readAsDataURL(file);

      const fileObj = createFileObject(file);

      setUploadStatus((prevState) => [...prevState, fileObj]);

      try {
        const response = await axios.post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          {
            ...config,
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              const updatedFile = {
                ...fileObj,
                percent: progress,
              };

              setUploadStatus((prevState) =>
                prevState.map((prevFile) =>
                  prevFile.uid === updatedFile.uid ? updatedFile : prevFile
                )
              );
            },
          }
        );
        let newFile;
        newFile = { ...fileObj };
        if (+dimensionalValue >= 71000000) {
          newFile = { ...newFile, imageOutOfBound: true };
        }

        const uploadedFile = {
          ...newFile,
          status: "done",
          url: response.data[0].fileUrl,
          response: [response.data],
          percent: 100,
        };

        setUploadStatus((prevState) =>
          prevState.map((prevFile) =>
            prevFile.uid === uploadedFile.uid ? uploadedFile : prevFile
          )
        );

        return uploadedFile;
      } catch (error) {
        console.log("Upload failed:", error);

        const uploadedFile = {
          ...fileObj,
          status: "error",
        };

        setUploadStatus((prevState) =>
          prevState.map((prevFile) =>
            prevFile.uid === uploadedFile.uid ? uploadedFile : prevFile
          )
        );
        return { ...fileObj, status: "failed" };
      }
    };

    try {
      const uploadPromises = Array.from(files).map(uploadFile);
      const uploadedFiles = await Promise.all(uploadPromises);
      await saveFileToDB(uploadedFiles);

      // ...continue with successful uploads...
    } catch (error) {
      console.log("One or more uploads failed:", error);
      // ...handle overall upload failure...
    }
  };

  const onRemove = (uploadedFile) => {
    setUploadStatus((prevState) =>
      prevState.filter((prevFile) => prevFile.uid !== uploadedFile.uid)
    );
  };

  const onTryAgain = (file) => {
    handleUploadToStorJ([file] as any);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles, "files to be uploaded check file name key");
      acceptedFiles.map((acceptedFile) => {
        if (files) {
          console.log("atleast i come here");
          files.map((existingFile) => {
            if (existingFile.name == acceptedFile.name) {
              console.log("name already exists");
              return;
            }
          });
        }
      });

      setFileList([...fileList, ...acceptedFiles]);
    },
    [fileList]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: (files) => {
      handleUploadToStorJ(files as any);
    },
  });

  return (
    <div
      style={{
        overflow: isDragActive ? "hidden" : "auto",
        position: "relative",
      }}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {/* {isDragActive ? } */}
        {isDragActive && <MessageMask />}
        {typeof children === "function" ? children(open) : children}
        <UploadList
          uploadList={uploadStatus}
          onClose={() => setUploadStatus([])}
          onRemove={onRemove}
          onTryAgain={onTryAgain}
        />
      </div>
    </div>
  );
};

export default DragAndDropLayer;
