import React from "react";
import Icon from "@ant-design/icons";

const EyeOpenSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.682 4.968c-.919.775-1.628 1.742-2.09 2.494a.5.5 0 11-.852-.523c.494-.805 1.268-1.868 2.297-2.735 1.03-.869 2.346-1.565 3.908-1.565 1.562 0 2.878.696 3.908 1.565 1.029.867 1.803 1.93 2.297 2.735a.5.5 0 11-.852.523c-.462-.752-1.171-1.72-2.09-2.494-.918-.773-2.013-1.33-3.263-1.33s-2.345.557-3.263 1.33zM1.917 8.252a.5.5 0 01.683.184c.933 1.626 2.815 3.726 5.345 3.726s4.412-2.1 5.345-3.726a.5.5 0 01.868.498c-.978 1.704-3.12 4.228-6.213 4.228s-5.234-2.525-6.213-4.228a.5.5 0 01.185-.682z"
      clipRule="evenodd"
    ></path>
    <circle cx="8" cy="8" r="2.4" stroke="#344054"></circle>
  </svg>
);
const EyeOffSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.162 3.395c.27-.04.55-.062.838-.062 3.404 0 5.637 3.004 6.387 4.192.09.143.136.215.162.326a.784.784 0 010 .298c-.026.11-.071.183-.163.328-.2.316-.505.761-.908 1.243M4.483 4.477c-1.441.977-2.42 2.336-2.869 3.047-.091.144-.137.216-.162.327a.782.782 0 000 .298c.025.11.07.183.161.326.75 1.188 2.984 4.192 6.387 4.192 1.373 0 2.555-.489 3.526-1.15M2 2l12 12M6.586 6.586a2 2 0 002.828 2.828"
    ></path>
  </svg>
);

const EyeOpenIcon = (props) => {
  return <Icon component={EyeOpenSvg} {...props} />;
};
const EyeOffIcon = (props) => {
  return <Icon component={EyeOffSvg} {...props} />;
};

export { EyeOffIcon, EyeOpenIcon };
