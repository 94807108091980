import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { Checkbox } from "antd";

import { useForm, Controller } from "react-hook-form";
import { client } from "../../../Core/utils/axiosClient";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { getAllRoles } from "../../../Core/redux/api/settingsAPI";

export const PERMISSIONS = {
  MANAGE_PROJECTS: 1,
  MANAGE_MEMBERS: 2,
  VIEW_ONLY_OVERVIEW: 3,
  VIEW_ONLY_SCHEDULE: 4,
  PHASE_AND_SPRINT_ADD: 5,
  PHASE_AND_SPRINT_EDIT: 6,
  PHASE_AND_SPRINT_DELETE: 7,
  TASK_EVENT_SCOPE_ADD: 8,
  TASK_EVENT_SCOPE_EDIT: 9,
  TASK_EVENT_SCOPE_DELETE: 10,
  SUB_TASK_AND_BUG_ADD: 11,
  SUB_TASK_AND_BUG_EDIT: 12,
  SUB_TASK_AND_BUG_DELETE: 13,
  COMMENT_ADD: 14,
  COMMENT_EDIT: 15,
  COMMENT_DELETE: 16,
  LOG_TIME_ADD: 17,
  LOG_TIME_EDIT: 18,
  LOG_TIME_DELETE: 19,
  REPORTER_EDIT: 20,
  VIEW_ONLY_LIBRARY: 21,
  FOLDER_FILE_LINK_UPLOAD: 22,
  FOLDER_FILE_LINK_EDIT: 23,
  FOLDER_FILE_LINK_DELETE_ALL: 24,
  FOLDER_FILE_LINK_DELETE_OWN: 25,
  LIBRARY_COMMENT_ADD: 26,
  LIBRARY_COMMENT_EDIT: 27,
  LIBRARY_COMMENT_DELETE: 28,
};

const MainPermissionCard = ({
  title,
  description,
  permissionId,
  checked,
  onCheck,
}) => {
  return (
    <div className="flex gap-x-2 items-start">
      <Checkbox checked={checked} onChange={() => onCheck(permissionId)} />
      <div>
        <p className="out-500-12 text-gray-700">{title}</p>
        <p className="out-300-12 text-gray-500">{description}</p>
      </div>
    </div>
  );
};

const SmallPermissionCard = ({ title, checked, onCheck, permissionId }) => {
  return (
    <div className="flex gap-x-2 items-center">
      <Checkbox checked={checked} onChange={() => onCheck(permissionId)} />{" "}
      <p className="out-300-12 text-gray-700">{title}</p>
    </div>
  );
};

const CreateAndEditRoleModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const { showRoleModal } = useAppSelector((state) => state.settings);
  const [loadingPermissionList, setLoadingPermissionList] = useState(false);
  const [searchParams] = useSearchParams();
  const { control, formState, handleSubmit, watch, setValue, reset } = useForm({
    values: {
      roleName: "",
    },
  });

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const handleCheckboxClick = (id) => {
    setSelectedPermissions((prevSelectedPermissions) => {
      const exists = prevSelectedPermissions.includes(id);

      return exists
        ? prevSelectedPermissions.filter((permId) => permId !== id)
        : [...prevSelectedPermissions, id];
    });
  };
  const onCancel = () => {
    setSelectedPermissions([]);
    reset();
    onClose();
  };
  const onSubmit = async (data) => {
    try {
      if (!searchParams.has("projectId")) return;
      if (showRoleModal.mode !== "edit") {
        const payload = {
          title: data.roleName,
          permissions: selectedPermissions,
          projectId: searchParams.get("projectId"),
        };

        await client.post("/permissions/add-role", payload);
      } else {
        const payload = {
          title: data.roleName,
          permissions: selectedPermissions,
          roleId: showRoleModal.role.id,
        };

        await client.post("/permissions/update-role", payload);
      }

      dispatch(getAllRoles({ projectId: searchParams.get("projectId") }));
      setSelectedPermissions([]);
      reset();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const getPermissionList = async () => {
    setLoadingPermissionList(true);

    try {
      const { data } = await client.get("/permissions/permission-list", {
        params: {
          roleId: showRoleModal.role.id,
        },
      });

      if (data.result.length > 0) {
        const permissions = data.result.map((p) => +p.permissionId);
        setSelectedPermissions(permissions);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingPermissionList(false);
    }
  };

  useEffect(() => {
    if (showRoleModal.visible && showRoleModal.mode === "edit") {
      getPermissionList();
      setValue("roleName", showRoleModal.role.roleName);
    }
  }, [showRoleModal]);

  return (
    <CustomModal
      visible={visible}
      width="667px"
      onCancel={() => onClose()}
      body={
        <div className="p-4 flex flex-col gap-y-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">
              {showRoleModal.mode === "new" ? "Create Role" : "Edit Role"}
            </p>

            <button onClick={() => onClose()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.1668 5.83331L5.8335 14.1666M5.8335 5.83331L14.1668 14.1666"
                  stroke="#344054"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <Controller
            control={control}
            name="roleName"
            rules={{ required: "Enter a name for the role" }}
            render={({ field }) => (
              <CustomInputBox
                {...field}
                placeholder="Enter name"
                formState={formState}
              />
            )}
          />
          <div className="max-h-[50vh] overflow-y-scroll">
            <div className="out-500-14 text-black">
              Modules and role permissions
            </div>
            <div className="p-3 border-b border-gray-200">
              <div className="out-600-12 text-black">Overview</div>
              <div className="flex gap-x-3 mt-5">
                <MainPermissionCard
                  title={"Manage Projects"}
                  description={"Add, edit and delete projects"}
                  permissionId={PERMISSIONS.MANAGE_PROJECTS}
                  checked={selectedPermissions.includes(
                    PERMISSIONS.MANAGE_PROJECTS
                  )}
                  onCheck={handleCheckboxClick}
                />
                <MainPermissionCard
                  title={"Manage Members"}
                  description={"Add, edit and delete members"}
                  permissionId={PERMISSIONS.MANAGE_MEMBERS}
                  checked={selectedPermissions.includes(
                    PERMISSIONS.MANAGE_MEMBERS
                  )}
                  onCheck={handleCheckboxClick}
                />
                <MainPermissionCard
                  title={"View only"}
                  description={"Cannot add, edit or delete anything"}
                  permissionId={PERMISSIONS.VIEW_ONLY_OVERVIEW}
                  checked={selectedPermissions.includes(
                    PERMISSIONS.VIEW_ONLY_OVERVIEW
                  )}
                  onCheck={handleCheckboxClick}
                />
              </div>
            </div>

            <div className="p-3 border-b flex flex-col gap-y-5 border-gray-200">
              <div className="out-600-12 text-black">Schedule</div>

              <MainPermissionCard
                title={"View Only"}
                description={"Cannot add, edit or delete anything in schedule"}
                permissionId={PERMISSIONS.VIEW_ONLY_SCHEDULE}
                checked={selectedPermissions.includes(
                  PERMISSIONS.VIEW_ONLY_SCHEDULE
                )}
                onCheck={handleCheckboxClick}
              />

              <div className="flex flex-wrap gap-y-5">
                <div className="flex flex-col gap-y-3 w-[33%]">
                  <div className="out-600-12 text-black">Phase & Sprint</div>
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.PHASE_AND_SPRINT_ADD}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.PHASE_AND_SPRINT_ADD
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Add"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.PHASE_AND_SPRINT_EDIT}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.PHASE_AND_SPRINT_EDIT
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Edit"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.PHASE_AND_SPRINT_DELETE}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.PHASE_AND_SPRINT_DELETE
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Delete"}
                  />
                </div>

                <div className="flex flex-col gap-y-3  w-[33%]">
                  <div className="out-600-12 text-black">
                    Task, Event & New scope
                  </div>
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.TASK_EVENT_SCOPE_ADD}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.TASK_EVENT_SCOPE_ADD
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Add"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.TASK_EVENT_SCOPE_EDIT}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.TASK_EVENT_SCOPE_EDIT
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Edit"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.TASK_EVENT_SCOPE_DELETE}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.TASK_EVENT_SCOPE_DELETE
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Delete"}
                  />
                </div>

                <div className="flex flex-col gap-y-3  w-[33%]">
                  <div className="out-600-12 text-black">Sub-task & Bug</div>
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.SUB_TASK_AND_BUG_ADD}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.SUB_TASK_AND_BUG_ADD
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Add"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.SUB_TASK_AND_BUG_EDIT}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.SUB_TASK_AND_BUG_EDIT
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Edit"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.SUB_TASK_AND_BUG_DELETE}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.SUB_TASK_AND_BUG_DELETE
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Delete"}
                  />
                </div>

                <div className="flex flex-col gap-y-3 w-[33%]">
                  <div className="out-600-12 text-black">Comment</div>
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.COMMENT_ADD}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.COMMENT_ADD
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Add"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.COMMENT_EDIT}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.COMMENT_EDIT
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Edit"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.COMMENT_DELETE}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.COMMENT_DELETE
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Delete"}
                  />
                </div>

                <div className="flex flex-col gap-y-3  w-[33%]">
                  <div className="out-600-12 text-black">Log Time</div>
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.LOG_TIME_ADD}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.LOG_TIME_ADD
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Add"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.LOG_TIME_EDIT}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.LOG_TIME_EDIT
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Edit"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.LOG_TIME_DELETE}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.LOG_TIME_DELETE
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Delete"}
                  />
                </div>

                <div className="flex flex-col gap-y-3  w-[33%]">
                  <div className="out-600-12 text-black">Reporter</div>

                  <SmallPermissionCard
                    permissionId={PERMISSIONS.REPORTER_EDIT}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.REPORTER_EDIT
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Edit"}
                  />
                </div>
              </div>
            </div>

            <div className="p-3 border-b flex flex-col gap-y-5 border-gray-200">
              <div className="out-600-12 text-black">Library</div>

              <MainPermissionCard
                title={"View Only"}
                description={
                  "Cannot add, edit or delete anything in library except for My Library"
                }
                permissionId={PERMISSIONS.VIEW_ONLY_LIBRARY}
                checked={selectedPermissions.includes(
                  PERMISSIONS.VIEW_ONLY_LIBRARY
                )}
                onCheck={handleCheckboxClick}
              />

              <div className="flex flex-wrap gap-y-5">
                <div className="flex flex-col gap-y-3 w-[33%]">
                  <div className="out-600-12 text-black">
                    Folder, File & Link
                  </div>

                  <SmallPermissionCard
                    permissionId={PERMISSIONS.FOLDER_FILE_LINK_UPLOAD}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.FOLDER_FILE_LINK_UPLOAD
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Upload"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.FOLDER_FILE_LINK_EDIT}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.FOLDER_FILE_LINK_EDIT
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Edit"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.FOLDER_FILE_LINK_DELETE_ALL}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.FOLDER_FILE_LINK_DELETE_ALL
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Delete All"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.FOLDER_FILE_LINK_DELETE_OWN}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.FOLDER_FILE_LINK_DELETE_OWN
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Delete Own"}
                  />
                </div>

                <div className="flex flex-col gap-y-3  w-[33%]">
                  <div className="out-600-12 text-black">Comment</div>
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.LIBRARY_COMMENT_ADD}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.LIBRARY_COMMENT_ADD
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Add"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.LIBRARY_COMMENT_EDIT}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.LIBRARY_COMMENT_EDIT
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Edit"}
                  />
                  <SmallPermissionCard
                    permissionId={PERMISSIONS.LIBRARY_COMMENT_DELETE}
                    checked={selectedPermissions.includes(
                      PERMISSIONS.LIBRARY_COMMENT_DELETE
                    )}
                    onCheck={handleCheckboxClick}
                    title={"Delete"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-x-3">
            <CustomButton
              text="Cancel"
              height="30px"
              className="bg-white text-gray-500"
              onClick={onCancel}
            />
            <CustomButton
              text={showRoleModal.mode === "new" ? "Create" : "Save Changes"}
              height="30px"
              className="bg-white text-primary-700"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      }
    />
  );
};

export default CreateAndEditRoleModal;
