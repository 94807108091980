import { DatePicker, Empty, Popover } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { ArrowIcon } from "../../../Core/svgV2/Arrow";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { useAppSelector } from "../../../Core/redux/hooks";

type OptionType = {
  id: string | number;
  value: string | number;
  label: string | React.ReactNode;
};

const CustomPopoverSelect = ({
  value,
  options,
  placeholder = "Select",
  onSelect = () => null,
  extraRender,
  buttonClassName = "bg-grey-200",
}: {
  value?: string | number;
  options: OptionType[];
  placeholder?: string | React.ReactNode;
  arrowClassName?: string;
  onSelect?: (v: any) => void;
  allowSearch?: boolean;
  onSearch?: (value) => void;
  extraRender?: any;
  buttonClassName?: string;
}) => {
  const [open, setOpen] = useState(false);
  const { selectedDate } = useAppSelector((state) => state.footPrintDetails);

  const [searchText] = useState("");

  const filteredOptions = options.filter((option) =>
    option.label.toString().toLowerCase().includes(searchText.toLowerCase())
  );

  const getlable = () => {
    if (dayjs().isSame(selectedDate, "day")) return "Today";

    if (selectedDate) return selectedDate;
    if (!value) return placeholder;
    const found = options.find((o) => o.value === value);
    if (found) return found.label;
    return placeholder;
  };

  const handleSelect = (option) => {
    setOpen(false);
    onSelect(option.value);
  };

  return (
    <Popover
      trigger={"click"}
      destroyTooltipOnHide
      content={
        <div
          className=" rounded-lg bg-white  min-w-[160px]"
          style={{
            boxShadow: " 0px 4px 16px 0px rgba(121, 121, 121, 0.12)",
          }}
        >
          {(searchText ? filteredOptions : options).map((option) => (
            <li
              onClick={() => handleSelect(option)}
              onKeyDown={() => handleSelect(option)}
              className={`px-0.5 flex  w-full capitalize cursor-pointer    hover:bg-gray-100  out-300-14 text-gray-600`}
              key={option.id}
            >
              <div className="flex w-full px-3 py-1.5 justify-between">
                {option.label}
                {option.value === value && !selectedDate && (
                  <CheckIcon className="text-primary-600" />
                )}
              </div>
            </li>
          ))}

          {extraRender ? extraRender : <></>}

          {(searchText && filteredOptions?.length === 0) ||
          options.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span className="text-xs">No search result</span>}
            />
          ) : null}
        </div>
      }
      placement="bottomRight"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
    >
      <button
        className={` ${buttonClassName} border border-gray-200 h-[30px] rounded-lg  py-[5px] px-2.5  flex  min-w-[87px] w-fit items-center justify-center gap-x-2`}
      >
        <span className="out-500-14 text-gray-700 flex w-full whitespace-nowrap">
          {getlable()}
        </span>
        <ChevronIcon className="text-gray-700" />
      </button>
    </Popover>
  );
};

export const DateFilterDropDown = ({ options, value, onSelect, ...props }) => {
  const isValueArray = Array.isArray(value) && value.length > 0;

  const [showDatePicker, setShowDatePicker] = useState(isValueArray);

  useEffect(() => {
    const isValueArray = Array.isArray(value) && value.length > 0;
    setShowDatePicker(isValueArray);
  }, [value]);

  const getFormattedPlaceholder = () => {
    if (isValueArray) {
      return `${dayjs(value[0]).format("DD MMM YYYY")} - ${dayjs(
        value[1]
      ).format("DD MMM YYYY")}`;
    }
    return "Today";
  };

  return (
    <CustomPopoverSelect
      placeholder={getFormattedPlaceholder()}
      options={options}
      arrowClassName="text-grey-100"
      onSelect={(value) => onSelect("addedOn", value)}
      value={value as string}
      {...props}

      // extraRender={
      //   showDatePicker ? (
      //     <div className=" pt-2">
      //       <DatePicker.RangePicker
      //         className="filter-picker"
      //         value={isValueArray ? (value.map((x) => dayjs(x)) as any) : null}
      //         onChange={(val, dates) => {
      //           const newDates = dates.filter((x) => x);
      //           if (newDates.length) {
      //             onSelect("addedOn", dates);
      //           } else {
      //             onSelect("addedOn", "all_time");
      //           }
      //         }}
      //       />
      //     </div>
      //   ) : (
      //     <button
      //       className={`rounded-md text-start w-full capitalize cursor-pointer px-2 py-2 block text-sm hover:bg-grey-200 text-med-emphasis font-medium `}
      //       onClick={() => setShowDatePicker(true)}
      //     >
      //       custom
      //     </button>
      //   )
      // }
    />
  );
};

export default CustomPopoverSelect;
