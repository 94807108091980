import { Input, Select } from "antd";
import React, { useEffect } from "react";
import { SearchIcon } from "../svgV2/SearchIcon";

/**
 * @param  {String} width
 * @param  {String} search
 * @param  {Function} setSearch
 * @param  {String} placeholder
 * */

type CustomSearchProps = {
  width?: string;
  search?: string;
  // isCancel?: boolean;
  suffix?: React.ReactNode;
  setSearch?: (...args: string[]) => void;
  onChange?: (e, v) => void;
  placeholder?: string;
  className?: string;
  allowClear?: boolean;
  style?: React.CSSProperties;
  autoFocus?: boolean;
  onBlur?: (e) => void;
  onFocus?: (e) => void;
};

// type CustomSearchWithDropDownProps = {
//   width?: string;
//   placeholder?: string;
//   className?: string;
//   allowClear?: boolean;

//   style?: React.CSSProperties;
//   options?: {
//     id: string | number;
//     value: string;
//     label: string;
//     render: any;
//     //onClick: (val) => void;
//   }[];
// };

const { Option } = Select;

const CustomSearchBox = ({
  width,
  search,
  setSearch,
  onChange,
  placeholder,
  className,
  style,
  onBlur,
  onFocus,
  suffix,
  autoFocus = false,
}: CustomSearchProps) => {
  return (
    <div
      className={`${className} border  flex justify-center custom-search-input items-center  search-shadow rounded-[4px] h-[30px]  ${width} `}
      style={{ ...style, boxSizing: "content-box" }}
    >
      <Input
        bordered={false}
        placeholder={placeholder || "Search"}
        value={search}
        allowClear
        onBlur={onBlur}
        onFocus={onFocus}

        autoFocus={autoFocus}
        onChange={(event) => {
          if (typeof setSearch === "function") {
            setSearch(event.target.value);
            console.log(event.target.value, search, "e, search 1");
          }
          if (typeof onChange === "function") {
            onChange(event, event.target.value);
            console.log(event.target.value, search, "e, search 1");
          }
        }}
        className=" px-4 font-medium text-sm custom-search-input  text-gray-600 out-300-14 h-[30px] placeholder:text-gray-500 "
        prefix={<SearchIcon />}
        suffix={suffix}
      />
    </div>
  );
};

export default CustomSearchBox;
