import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { ChevronDoubleIcon } from "../../../Core/svgV2/Chevron";
import GoogleLogin from "react-google-login";

const CalendarintegrationModal = ({
  visible,
  CLIENT_ID,
  responseGoogle,
  responseError,
  SCOPE,
}) => {
  return visible ? (
    <div className="flex flex-col gap-6 px-4 py-6 relative rounded-2xl  bg-[#F9FBFE] w-[391px]">
      <div className="flex gap-3 items-center justify-center">
        <img
          src="/images/icons/Googlecalendar.svg"
          className="w-[60px] h-[60px]"
        />
        <ChevronDoubleIcon className="text-[#C0CBD7] transform rotate-180" />
        <img
          className="w-[60px] h-[60px]"
          alt="logo"
          src={"/images/v2/layout/w.svg"}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex justify-center">
          <p className="text-blue-700 out-500-16">My Calendar</p>
        </div>
        <div>
          <p className="text-gray-500 out-300-14">
            Easily integrate{" "}
            <span className="text-zinc-800 out-500-14">Google Calendar</span>{" "}
            into Waddle so you can effortlessly create and oversee all your
            events from here.
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <GoogleLogin
          clientId={CLIENT_ID}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="bg-primary-600 text-white out-500-14 h-[30px] py-[5px] px-[10px] flex gap-2 items-center rounded-[4px]"
            >
              <div className="flex cursor-pointer" role="button">
                Integrate Google Calendar
              </div>
            </button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseError}
          cookiePolicy={"single_host_origin"}
          responseType="code"
          accessType="offline"
          scope={SCOPE}
          prompt="consent"
          uxMode="redirect"
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CalendarintegrationModal;
