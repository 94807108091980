import React from "react";
import Icon from "@ant-design/icons";

const RightArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.416 12.833l2.917-2.916L13.417 7"
    ></path>
    <path stroke="currentColor" strokeLinecap="round" d="M3 9.916h9.5"></path>
  </svg>
);

const RightArrowIcon = (props) => {
  return <Icon component={RightArrowSvg} {...props} />;
};

export { RightArrowIcon };
