import React from "react";
import Icon from "@ant-design/icons";

const MarkOptionalIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      stroke="#475467"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.125 5.251a1.312 1.312 0 012.548.437C8.673 6.563 7.362 7 7.362 7m.017 1.75h.006m-.094 2.917a4.958 4.958 0 10-4.7-3.374c.064.187.095.28.101.353a.525.525 0 01-.016.19c-.018.07-.057.142-.136.288l-.954 1.766c-.136.252-.204.378-.189.475.013.085.063.16.136.204.084.051.227.036.512.007l2.987-.309c.09-.01.136-.014.177-.012.04.001.069.005.109.014.04.01.09.029.191.068.553.213 1.154.33 1.782.33z"
    ></path>
  </svg>
);

const MarkOptionalIcon = (props) => (
  <Icon component={MarkOptionalIconSvg} {...props} />
);

export default MarkOptionalIcon;
