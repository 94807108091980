import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppSelector } from "../../../Core/redux/hooks";

import { CrossIcon } from "../../../Core/svgV2/CrossIcon";

const CreateRoleModal = ({ onOk, onClose }) => {
  const { createRoleModal } = useAppSelector((state) => state.dashboard);

  return (
    <CustomModal
      visible={createRoleModal}
      width="360px"
      onCancel={onClose}
      body={
        <div className="h-[299px]  flex flex-col px-4  py-[15px] gap-y-[17px]">
          <div className="flex   justify-between items-center ">
            <div className="h-5 out-500-14 text-primary-700">Create roles</div>
            <div className="h-5 w-5" onClick={onClose}>
              <CrossIcon className={"text-gray-500 cursor-pointer"} />
            </div>
          </div>
          <div className="flex   ">
            <img
              src="/images/v2/dashboard/FrostyJumping.svg"
              className="h-[108px]"
            />
          </div>
          <div className="h-[60px] out-300-14 flex text-gray-500">
            <p>
              Hooray! let’s create
              <span className="text-black out-500-14"> roles </span>and invite{" "}
              <span className="text-black out-500-14"> members</span> to your
              new project.
            </p>
          </div>
          <div className="h-[30px]  flex items-center justify-end gap-x-2.5 cursor-pointer">
            <div
              className="h-[30px] out-500-14 text-gray-500 flex py-[5px] px-[10px]"
              onClick={onClose}
            >
              Later
            </div>
            <button
              className="h-[30px] flex items-center justify-center out-500-14 text-white bg-primary-700 shadow-[0px_1px_2px_rgba(16,24,40,0.05)]  py-[5px] px-[10px] rounded-[4px] "
              onClick={onOk}
            >
              View Project Settings
            </button>
          </div>
        </div>
      }
    />
  );
};

export default CreateRoleModal;
