import React, { useEffect, useState } from "react";
import { RightArrowIcon } from "../../Core/svgV2/RightArrow";
import getWelcomeCardDetails from "./getWelcomeCardDetails";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useNavigate } from "react-router-dom";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
const WelcomeCard = ({
  // action,
  time,
}: {
  // action?: {
  //   action: "tasks to waddle" | "tasks to review" | "upcoming events";
  //   count: number;
  // }[];
  time: string;
  profileCompletePercent: number;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { waddleItGoals } = useAppSelector((state) => state.dashboard);
  const { events } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);

  const action = [
    { action: "tasks to waddle", count: waddleItGoals.length },
    { action: "unread requests", count: 0 },
    { action: "upcoming events", count: events.length },
  ];

  const [stepCounter, setStepCounter] = useState(1);

  const { imgUrl, wish } = getWelcomeCardDetails(time);

  useEffect(() => {
    const interval = setInterval(() => {
      if (waddleItGoals.length !== 0 || events.length !== 0) {
        setStepCounter((stepCounter) => {
          if (stepCounter === action.length) {
            return 1;
          }
          return stepCounter + 1;
        });
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    console.log("");
  }, [user, waddleItGoals, events]);

  //action obj has action=>waddle tasks/ review tasks /event, count,

  return (
    <div className=" flex  relative w-full h-[146px] bg-white  rounded-[12px] ">
      <div className="flex flex-col p-[24px] justify-between ">
        <div className=" flex flex-wrap   h-[30px] items-center justify-start  ">
          <div className="out-300-20">
            Good {wish}, <span className="out-500-20">{user.firstName}!</span>
          </div>
        </div>

        <div className="h-5 mt-2.5 out-300-14 text-gray-500">
          <span>
            {waddleItGoals.length !== 0 || events.length !== 0
              ? action.length === 1
                ? `Today, you have, `
                : "Today, you have"
              : +user.profileProgress === 100
              ? "It’s me "
              : `I noticed that only `}
          </span>
          <span className="text-primary-600 out-500-14">
            &nbsp;
            {waddleItGoals.length !== 0 || events.length !== 0
              ? action.length === 1
                ? ` ${action[stepCounter - 1].count} ${
                    action[stepCounter - 1].action
                  }`
                : ` ${action[stepCounter - 1].count} ${
                    action[stepCounter - 1].action
                  } `
              : +user.profileProgress === 100
              ? "Frosty"
              : `${user.profileProgress}%  `}
          </span>
          {waddleItGoals.length !== 0 || events.length !== 0 ? (
            action.length === 1 ? (
              ``
            ) : (
              ` `
            )
          ) : +user.profileProgress === 100 ? (
            ", your personal assistant. Let’s see what you can do here."
          ) : (
            <span>
              {" "}
              &nbsp;of your profile is complete. Let’s setup your profile.
            </span>
          )}
        </div>

        <div className="flex mt-[7px] gap-x-[5px]">
          {waddleItGoals.length !== 0 || events.length !== 0
            ? action.map((action, index) => {
                return (
                  <div
                    className={
                      stepCounter === index + 1
                        ? `w-[5px] h-[5px] rounded-[5px] bg-primary-600`
                        : `w-[5px] h-[5px] rounded-[5px] bg-primary-100`
                    }
                    key={index}
                  ></div>
                );
              })
            : ""}
        </div>

        <div
          role="button"
          className="flex flex-row out-500-14 text-primary-700 gap-x-2 mt-[6px] cursor-pointer"
          onClick={() => {
            if (waddleItGoals.length !== 0 || events.length !== 0) {
              if (stepCounter === action.length) {
                setStepCounter(1);
              } else {
                setStepCounter(stepCounter + 1);
              }
            } else if (+user.profileProgress === 100) {
              console.log("clicked");
              dispatch(
                updateDashboard({ key: "takeTourFromWelcomeCard", value: true })
              );
            } else {
              navigate("/manage-profile");
            }
          }}
        >
          {waddleItGoals.length !== 0 || events.length !== 0
            ? "Next"
            : +user.profileProgress === 100
            ? "Take The Tour"
            : "Set Up Profile"}
          <RightArrowIcon className="text-primary-700" />
        </div>
      </div>
      <div className="absolute right-0 overflow-hidden rounded-[12px]">
        <img className="h-[146px] " src={imgUrl} />
      </div>
    </div>
  );
};

export default WelcomeCard;
