import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const CloseRequestModal = ({ visible, onClose, handleCloseRequest }) => {
  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => console.log("")}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-primary-600 out-500-14">Close request</p>
            <button onClick={onClose}>
              {" "}
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Awesome! We’ll close this request and clear it from the requests
            panel. You can still see all the requests in details panel
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={onClose}
            />
            <CustomButton
              text="Close request"
              height="30px"
              width="105px"
              onClick={() => {
                handleCloseRequest();
                onClose();
              }}
              // className="bg-error-700 text-white"
            />
          </div>
        </div>
      }
    />
  );
};

export default CloseRequestModal;
