import React from "react";
import Icon from "@ant-design/icons";

const PinnedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill="currentColor"
      d="M4.012 2.448c-.171-.429-.257-.643-.221-.815a.583.583 0 01.248-.368c.147-.097.378-.097.84-.097h4.243c.461 0 .692 0 .839.097a.583.583 0 01.249.368c.035.172-.05.386-.222.815L9.4 3.918a1.214 1.214 0 00-.046.125.584.584 0 00-.017.088c-.003.033-.003.066-.003.133v1.243c0 .122 0 .182.011.24.011.052.028.102.052.148.027.053.065.1.141.195l.916 1.145c.388.485.582.728.582.932 0 .178-.08.346-.219.457-.16.127-.47.127-1.092.127h-5.45c-.621 0-.932 0-1.092-.127a.583.583 0 01-.22-.457c.001-.204.195-.447.584-.932l.915-1.145c.076-.094.114-.142.141-.195a.584.584 0 00.052-.148c.012-.058.012-.118.012-.24V4.264c0-.067 0-.1-.004-.133a.584.584 0 00-.017-.088 1.214 1.214 0 00-.046-.125l-.588-1.47z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7 8.751v4.084m-2.333-8.57v1.242c0 .122 0 .182-.012.24a.584.584 0 01-.052.148c-.027.053-.065.1-.14.195l-.916 1.145c-.389.485-.583.728-.583.932 0 .178.08.346.22.457.159.127.47.127 1.091.127h5.45c.622 0 .932 0 1.092-.127a.583.583 0 00.22-.457c0-.204-.195-.447-.583-.932L9.538 6.09c-.076-.094-.114-.142-.14-.195a.584.584 0 01-.053-.148c-.011-.058-.011-.118-.011-.24V4.264c0-.067 0-.1.003-.133a.584.584 0 01.017-.088c.009-.032.021-.063.046-.125l.588-1.47c.172-.429.258-.643.222-.815a.583.583 0 00-.25-.368c-.146-.097-.377-.097-.838-.097H4.879c-.462 0-.693 0-.84.097a.583.583 0 00-.248.368c-.036.172.05.386.221.815l.588 1.47c.025.062.037.093.046.125a.584.584 0 01.017.088c.004.033.004.066.004.133z"
    ></path>
  </svg>
);
const PinnedOutlinedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 9.999v4.666M5.335 4.871v1.42c0 .139 0 .208-.014.275a.667.667 0 01-.059.169c-.03.06-.074.114-.16.223L4.053 8.266c-.444.554-.666.832-.666 1.065 0 .203.092.395.25.522.183.146.538.146 1.249.146h6.228c.71 0 1.065 0 1.248-.146a.667.667 0 00.25-.522c0-.233-.222-.51-.665-1.065L10.9 6.958a1.412 1.412 0 01-.16-.223.665.665 0 01-.06-.17c-.014-.066-.014-.135-.014-.274v-1.42c0-.077 0-.115.005-.153a.673.673 0 01.019-.1c.01-.036.024-.072.053-.143l.671-1.68c.197-.49.295-.735.254-.932a.667.667 0 00-.285-.42c-.167-.111-.431-.111-.959-.111H5.576c-.528 0-.792 0-.959.111a.667.667 0 00-.284.42c-.041.197.057.442.253.932l.672 1.68c.028.07.042.107.052.143a.668.668 0 01.02.1c.004.038.004.076.004.153z"
    ></path>
  </svg>
);

const PinnedIcon = (props) => {
  return <Icon component={PinnedSvg} {...props} />;
};
const PinnedOutlinedIcon = (props) => {
  return <Icon component={PinnedOutlinedSvg} {...props} />;
};

export { PinnedIcon, PinnedOutlinedIcon };
