import React from "react";

const ExportIcon = ({ size = 16, className = "text-gray-600" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="M14 10v2.667A1.334 1.334 0 0112.667 14H3.333A1.334 1.334 0 012 12.667V10m9.333-4.667L8 2m0 0L4.667 5.333M8 2v8"
        ></path>
      </svg>
    </span>
  );
};

export default ExportIcon;
