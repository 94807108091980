import React from "react";
import { labelColors } from "../constants";

const MyBoardLabel = ({ labelColorId, text }) => {
  const baseColor = labelColors.find((l) => l.id === +labelColorId)?.baseColor;
  const textColor = labelColors.find((l) => l.id === +labelColorId)?.textColor;

  return (
    <div
      className="px-1.5 h-5 max-w-max rounded-2xl"
      style={{
        backgroundColor: baseColor,
      }}
    >
      <p className="text-center out-300-12" style={{ color: textColor }}>
        {text}
      </p>
    </div>
  );
};

export default MyBoardLabel;
