import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import { getGoalsByLabelId } from "../../../../Core/redux/api/scheduleAPI";
import { ChevronIcon } from "../../../../Core/svgV2/Chevron";
import CustomBadge from "../../../../Core/CommonV2/CustomBadge";
import dayjs from "dayjs";
import { updateSchedule } from "../../../../Core/redux/slices/schedule.slice";
import { ArrowIcon } from "../../../../Core/svgV3/ArrowIcon";
import { Button, Tooltip } from "antd";
import { labelColors } from "../../../../Core/constants";

const LabelCard = ({ label, index }) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const [showGoals, setShowGoals] = useState(false);
  const goals = useAppSelector(
    (state) => state.schedule.goalsByLabelId[label.id] || []
  );
  const { goalsByLabelId, timelineSelected } = useAppSelector(
    (state) => state.schedule
  );

  const fetchGoals = async () => {
    if (projectId && showGoals) {
      await dispatch(
        getGoalsByLabelId({ labelId: label.id.toString(), projectId })
      );
    }
  };

  const GoalItem = ({ goal, index }) => {
    const formattedCreatedAt = goal.startDate
      ? dayjs(goal.startDate).format("MMM DD")
      : "Not set";
    const formattedDueDate = goal.endDate
      ? dayjs(goal.endDate).format("MMM DD")
      : "Not set";
    return (
      <div
        className={`flex w-full justify-between items-center px-5.5  pt-1 pb-2 mt-2 ${
          index === 0 ? " mt-4" : " mt-[6px]"
        }`}
      >
        {/* <div className="text-zinc-800 text-xs font-light truncate cursor-pointer">
          {goal.name}
        </div> */}
        {/* </Tooltip> */}
        <Tooltip title={goal.name} placement="bottom">
          <div className="out-300-12 text-black truncate max-w-[40%] cursor-pointer">
            {goal.name}
          </div>
        </Tooltip>
        <div className="flex  w-max gap-x-2.5 items-center text-gray-500 out-400-10">
          <p className="w-full flex">
            {`${formattedCreatedAt} -${formattedDueDate}`}
          </p>
          <ArrowIcon
            className="cursor-pointer"
            onClick={() => handleStartDayClick(goal)}
          />
        </div>
      </div>
    );
  };

  const handleStartDayClick = (task) => {
    const selector = `[data-date="${dayjs(task.startDate).format(
      "YYYY-MM-DD"
    )}"]`;
    const today = document.querySelector(selector) as HTMLElement;
    if (today) {
      today.scrollIntoView({
        inline: "start",
        block: "end",
        behavior: "smooth",
      });

      const scrollContainer = today.parentElement;
      // document.querySelector("#gantt-scroll-container");
      // console.log(today.offsetLeft, scrollContainer, "today offset left34");
      // Assuming the parent element is the scrollable container
      if (scrollContainer) {
        scrollContainer.scrollTo({
          left: today.offsetLeft,
          behavior: "smooth", // Optional: Smooth scrolling animation
        });
      }
    }
  };

  useEffect(() => {
    fetchGoals();
  }, [label.id, projectId, showGoals]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(date);
  };

  const toggleGoalsDisplay = async () => {
    if (showGoals === true) {
      dispatch(
        updateSchedule({
          key: "goalsByLabelId",
          value: { goalsByLabelId, [+label.id]: [] },
        })
      );
    }
    setShowGoals(!showGoals);
  };

  const labelColor = labelColors.find(
    (color) => color.id === +label.labelColorId
  );
  const baseColor = labelColor ? labelColor.textColor : "#7DC7FF";

  return (
    <div
      className={`w-full flex flex-col px-[9px] pt-1 pb-2 ${
        index == 0 ? "mt-[2px]" : " mt-[2px]"
      } 
      `}
    >
      <div
        className="flex justify-between items-center gap-x-2.5 cursor-pointer"
        onClick={toggleGoalsDisplay}
      >
        <div className="flex justify-start items-center gap-x-1 flex-grow">
          <ChevronIcon
            className={`${showGoals ? "transform rotate-180" : ""}`}
          />
          <Tooltip title={label.name} placement="bottom">
            <span className="text-zinc-800 text-sm font-medium font-['Outfit'] flex-grow truncate max-w-[75px]">
              {label?.name}
            </span>
          </Tooltip>
        </div>
        <div className="text-gray-500 mt-[1px] text-[10px] font-normal font-['Outfit'] truncate">
          {formatDate(label.startDate)} - {formatDate(label.endDate)}
        </div>
        <div
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            handleStartDayClick(label);
          }}
        >
          <ArrowIcon />
        </div>
        <div
          className="w-3 h-3 rounded-full"
          style={{ backgroundColor: baseColor }}
        ></div>
      </div>
      <div className="flex flex-col gap-y-3 mt-4">
        {showGoals &&
          goals.map((goal, index) => (
            <GoalItem key={index} goal={goal} index={index} />
          ))}
      </div>
    </div>
  );
};

export default LabelCard;
