import React, { useCallback, useEffect, useRef, useState } from "react";
//import { HTML5Backend } from "react-dnd-html5-backend";
import KanbanColumn from "./KanbanColumn";
// import update from "immutability-helper";
import KanbanItem from "./KanbanItem";

import { useParams, useSearchParams } from "react-router-dom";

import {
  DndContext,
  useSensors,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";

import KanbanColumnHeader from "./KanbanColumnHeader";

import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import { updateGoal } from "../../../Core/redux/api/scheduleAPI";
import { setError } from "../../../Core/redux/slices/global.slice";

import TaskCard, { NewtaskCard } from "./TaskCard";

import { useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import AddNewStatus from "../Sprints/KanbanBoard/AddNewStatus";
import TaskItem from "./TaskItem";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import SubtaskKanbanBoard from "./SubtaskKanbanBoard";
import {
  getMyBoardTasks,
  updateMyBoardTask,
} from "../../../Core/redux/api/myBoardAPI";
import { TASK_ACTIVITY_LOG_ACTIONS } from "../../../Core/constants";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";

const KanbanBoard = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [addNewStatus, setAddNewStatus] = useState(false);
  const { projectId, sprintId } = useParams();
  const { myBoardTasks, showSubtaskView, showNewTaskCard, filters } =
    useAppSelector((state) => state.myBoard);

  const { filterGoalParameter, selectedTasksForDragAndDrop, labels } =
    useAppSelector((state) => state.schedule);

  const board = useRef(null);
  const subTaskBoard = useRef(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();

  const statusArray = [
    {
      id: "1",
      statusId: "1",
      title: "To Do",
      positionIndex: "1",
    },
    {
      id: "2",
      statusId: "2",
      title: "In Progress",
      positionIndex: "1",
    },
    {
      id: "3",
      statusId: "3",
      title: "Done",
      positionIndex: "1",
    },
  ];

  const { sprints } = useAppSelector((state) => state.schedule);
  const [maxHeight, setMaxHeight] = useState("0px");

  useEffect(() => {
    const updateHeight = () => {
      if (board && board.current) {
        const viewportOffset =
          board.current.getBoundingClientRect()?.top || 100;
        const height = `${window.innerHeight - viewportOffset - 20}px`;
        //  board.current.style.height = height;
        board.current.style.maxHeight = height;

        console.log(
          board.current,
          viewportOffset,
          window.innerHeight,
          "board.current.getBoundingClientRect().height"
        );
        setMaxHeight(board.current.getBoundingClientRect().height);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [board]);
  useEffect(() => {
    const updateHeight = () => {
      if (subTaskBoard && subTaskBoard.current) {
        const viewportOffset =
          subTaskBoard.current.getBoundingClientRect()?.top || 100;
        const height = `${window.innerHeight - viewportOffset - 20}px`;
        //  subTaskBoard.current.style.height = height;
        subTaskBoard.current.style.maxHeight = height;

        console.log(
          subTaskBoard.current,
          viewportOffset,
          window.innerHeight,
          "subTaskBoard.current.getBoundingClientRect().height"
        );
        setMaxHeight(subTaskBoard.current.getBoundingClientRect().height);
      }
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [subTaskBoard]);

  const changeTaskStatus = useCallback(
    async (data) => {
      const taskIds =
        selectedTasksForDragAndDrop.length > 0
          ? selectedTasksForDragAndDrop
          : [data?.active?.id];
      const status = showSubtaskView
        ? data?.over?.data?.current?.channel?.id
        : data?.over?.id;
      const statusId = statusArray.find((s) => s.id == status)?.statusId || 1;
      console.log(data, status, +data?.active?.id, "what comes");

      const updatedGoals = myBoardTasks.result.map((goal) => {
        if (taskIds.includes(goal.id)) {
          return { ...goal, statusId: status };
        }
        return goal;
      });
      if (+statusId === 3 && !showSubtaskView) {
        const selectedGoals = myBoardTasks?.result?.filter((goal) =>
          taskIds.includes(goal.id)
        );
        let inprogressSubtasks = false;
        selectedGoals.map((goal) => {
          const inprogressSub = goal?.subtask?.find(
            (subtask) => +subtask.statusId !== 3
          );
          if (inprogressSub) {
            inprogressSubtasks = true;
          }
        });
        if (inprogressSubtasks) {
          dispatch(
            updateMyBoard({
              key: "subtasksInProgressWarning",
              value: {
                visible: true,
                goalId: taskIds,
                statusId: statusId,
                swimlaneId: status,
                oldSwimlaneId: null,
              },
            })
          );
        } else {
          await dispatch(
            updateMyBoardTask({
              statusId: +status,
              goalId: taskIds,
              action: {
                oldValue: null,
                newValue: status,
                actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
              },
            })
          );
        }
      } else {
        await dispatch(
          updateMyBoardTask({
            statusId: +status,
            goalId: taskIds,
            action: {
              oldValue: null,
              newValue: status,
              actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
            },
          })
        );
      }
      if (taskIds.length > 1) {
        dispatch(getMyBoardTasks({}));
      }
      // await dispatch(
      //   updateGoal({ goalId: taskIds, statusId: status, statusId })
      // );
      dispatch(resetSchedule(["selectedTasksForDragAndDrop"]));
    },
    [
      sprints,
      sprintId,
      selectedTasksForDragAndDrop,
      myBoardTasks.result,
      showSubtaskView,
    ]
  );

  const count = (channel) => {
    const items = filterGoals(myBoardTasks?.result, channel);

    const isMatch = (item) => {
      return (
        (item.statusId === 0 && item.statusId + 1 === channel.id) ||
        item.statusId === channel.id
      );
    };

    return items.filter(isMatch).length;
  };

  const handleFilter = (goals) => {
    let filteredGoals = [...goals];

    const filterParams = filterGoalParameter;

    // filter by assignee

    if (filters?.collaborator?.length > 0) {
      filteredGoals = filteredGoals?.filter((goal) =>
        goal?.collaborators?.some((collaboratorId) =>
          filters?.collaborator?.includes(collaboratorId)
        )
      );
    }

    return filteredGoals;
  };
  const handleSubtaskFilter = (goals) => {
    const filteredGoals = [...goals];

    const filterParams = filterGoalParameter;

    // filter by assignee

    // if (filters?.collaborator.length > 0) {
    //   filteredGoals = filteredGoals?.filter((goal) =>
    //     filters?.collaborator?.includes(goal.assigneeId)
    //   );
    // }

    return filteredGoals;
  };

  const filterGoals = (goals, channel) => {
    const filteredGoals = handleFilter([...goals]);
    return [...filteredGoals].filter((item) => {
      return showSubtaskView
        ? +item.statusId === +channel.id
        : +item.statusId === +channel.id;
    });
  };
  const filterSubtaskGoals = (goals, channel) => {
    const filteredGoals = handleSubtaskFilter([...goals]);
    return [...filteredGoals].filter((item) => {
      return showSubtaskView
        ? +item.statusId === +channel.id
        : +item.statusId === +channel.id;
    });
  };
  const filterParentGoals = (goals, channel) => {
    const filteredGoals = handleFilter([...goals]);
    return filteredGoals;
  };
  const option = labels.map((label) => {
    return {
      id: label.id,
      value: label.id,
      label: label.name,
      render: () => (
        <div className="flex items-center justify-between">
          <CustomBadge
            text={label.name}
            color={label.color}
            className="border border-gray-200"
            style={{ borderColor: label.color }}
          />
          <div className="h-[14px] w-[14px] rounded-full" />
        </div>
      ),
    };
  });

  const newStatusDivWidth =
    document.getElementById("addNewStatus")?.offsetWidth;

  return (
    <div className="bg-gray-50   flex  p-4  relative ">
      <div className="flex-1 ">
        <div className={`overflow-y-scroll   max-w-[calc(100vw-250px)]"}`}>
          <DndContext
            modifiers={[restrictToFirstScrollableAncestor]}
            onDragEnd={changeTaskStatus}
            sensors={sensors}
          >
            <div className={`grid-container ml-[2px] `}>
              {statusArray &&
                statusArray.map((channel) => (
                  <KanbanColumnHeader
                    title={channel.title}
                    key={channel.id}
                    count={count(channel)}
                    channel={channel}
                    isTask={searchParams.has("selectedSprint")}
                    addSwimalne={() => setAddNewStatus(true)}
                  />
                ))}
            </div>
            {showSubtaskView ? (
              <div className="overflow-y-scroll  overflow-x-hidden  max-h-[calc(100vh-250px)]">
                {myBoardTasks?.result &&
                  filterParentGoals(myBoardTasks?.result, 1).map((task) => (
                    <SubtaskKanbanBoard
                      board={subTaskBoard}
                      changeTaskStatus={changeTaskStatus}
                      handleFilter={(tasks, channel) =>
                        filterSubtaskGoals(tasks, channel)
                      }
                      statusArray={statusArray}
                      task={task}
                      key={task.id}
                    />
                  ))}
              </div>
            ) : (
              <div
                data-kanbanboard={true}
                className="grid-container ml-[2px] overflow-y-scroll overflow-x-hidden  max-h-[calc(100vh-250px)] "
                ref={board}
              >
                {statusArray &&
                  [...statusArray].map((channel) => (
                    // todo check if removing null object any effect
                    <KanbanColumn
                      key={channel.id}
                      status={channel.id}
                      channel={channel}
                      changeTaskStatus={changeTaskStatus}
                      className=" kanban-col"
                    >
                      {showNewTaskCard.visible &&
                      showNewTaskCard.channel.id === channel.id ? (
                        <NewtaskCard
                          status={channel}
                          isHovered={true}
                          defaultOpen
                        />
                      ) : null}
                      {filterGoals(myBoardTasks?.result, channel).map(
                        (item) => (
                          <KanbanItem key={item.id} id={item.id}>
                            <TaskCard task={item} />
                          </KanbanItem>
                        )
                      )}
                    </KanbanColumn>
                  ))}
              </div>
            )}
          </DndContext>
        </div>
      </div>
    </div>
  );
};

export default KanbanBoard;
