import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";
import ChatCard from "./ChatCard";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import { PlusIcon } from "../../Core/svgV2/PlusIcon";
import { Dropdown } from "antd";
import { ThreadIcon } from "../../Core/svgV2/ThreadIcon";
import ChatWindow from "./ChatWindow";
import NewGroupModal from "./Modals/NewGroupModal";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  resetConversations,
  updateConversations,
  updatePrivateConversations,
  updateThreadMessage,
  updateUnreadCountForProjectThreads,
} from "../../Core/redux/slices/conversations.slice";
import DeleteGroupModal from "./Modals/DeleteGroupModal";
import ShareModal from "./Modals/ShareModal";
import SaveInLibrary from "./Modals/SaveInLibrary";
import GroupDetailsDrawer from "./Drawers/GroupDetailsDrawer";
import UserDetailsDrawer from "./Drawers/UserDetailsDrawer";
import SharedFilesDrawer, {
  SharedFileDetailsDrawer,
} from "./Drawers/SharedFilesDrawer";
import FilePreviweModal from "./Modals/FIlePreviweModal";
import ConversationsAppTour from "./ConversationsAppTour";
import { getThreads } from "../../Core/redux/api/conversationAPI";

import DeleteFileModal from "./Modals/DeleteFileModal";
import LeaveGroupModal from "./Modals/LeaveGroupModal";
import { playNotifySoundDebounced } from "../../Core/utils";
import { getAllTourStatus } from "../../Core/redux/api/dashboardAPI";
import { client } from "../../Core/utils/axiosClient";
import { Navigate, useNavigate } from "react-router-dom";
// import { playNotifySoundDebounced } from "../../Core/utils";

const ConversationsScreen = ({ socket }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { allowedUsersForGuest } = useAppSelector((state) => state.settings);
  const navigate = useNavigate();

  const {
    threads,
    directMessageUsers,
    userDetailsDrawer,
    groupDetailsDrawer,
    leaveThread,
    sharedFilesDrawer,
    sharedFileDetailsDrawer,
    shareMessageModal,
    saveInLibrary,
    filePreview,
    deleteFileModal,
    currentThreadMessage,
    currentThread,
  } = useAppSelector((state) => state.conversations);
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState("");

  const getDM = async () => {
    await dispatch(getThreads({ userId: user.id }));
    // await dispatch(getOrganizationUsers({ userId: user.id }));
    // await dispatch(getPrivateChats({ userId: user.id }));
  };

  // let allowedUsersToInteract = [];
  // let guestDirectMeesageUsers = [];

  const tourStatus = async () => {
    await dispatch(getAllTourStatus({ userId: user.id }));
  };
  useEffect(() => {
    getDM();
    tourStatus();
  }, [user.id]);

  const socketOnPrivateMessage = ({
    message,
    senderName,
    senderId,
    reciverId,
    createdAt,
    senderProfilePicture,
    replyMessage,
    _id,
    attachments,
    tenantId,
  }) => {
    if (tenantId !== currentThread.tenantId) {
      console.error("ERROR:[SOCKET] wrong tenant");
      return;
    }
    if (reciverId != user.id) {
      console.error("ERROR:[SOCKET] sending message to wrong user ");
      return;
    }
    dispatch(
      updatePrivateConversations({
        message,
        senderName,
        senderId,
        reciverId,
        createdAt,
        senderProfilePicture,
        replyMessage,
        _id,
        attachments,
        reactions: {},
      })
    );
    // sender id id of user who sent this message and reciver id the id of user who is rciving this message
    // in this case we are reciver so our id should be equal to this id

    if (currentThread.userId !== senderId) {
      playNotifySoundDebounced();
    }
    if (currentThread.userId === senderId) {
      socket.emit("readMessageFromSocket", {
        threadId: currentThread.threadId,
        threadType: currentThread.type,
        userId: user.id,
        reciverId: currentThread.userId,
      });
    }
  };

  const socketOnMessage = (message) => {
    dispatch(updateThreadMessage({ ...message, reactions: {} }));

    if (currentThread.threadId == message.threadId) {
      socket.emit("readMessageFromSocket", {
        threadId: currentThread.threadId,
        threadType: currentThread.type,
        userId: user.id,
        reciverId: currentThread.userId,
      });
    }
  };

  const socketNewThreadMessage = ({ threadId, projectId }) => {
    // console.log("updateUnreadCountForProjectThreads", { threadId, projectId });
    dispatch(updateUnreadCountForProjectThreads({ threadId, projectId }));
  };

  React.useEffect(() => {
    socket.on("privateMessage", socketOnPrivateMessage);
    socket.on("message", socketOnMessage);
    return () => {
      socket.off("privateMessage", socketOnPrivateMessage);
      socket.off("message", socketOnMessage);
      socket.off("newThreadMessage", socketNewThreadMessage);
    };
  }, [socket, currentThread]);

  // const allThreads = [...threads, ...directMessageUsers];
  let allThreads = [];
  if (+user.roleId === 4) {
    const guestDirectMeesageUsers = directMessageUsers.filter((user) =>
      allowedUsersForGuest?.includes(user.userId)
    );
    allThreads = [...threads, ...guestDirectMeesageUsers];
  } else {
    allThreads = [...threads, ...directMessageUsers];
  }

  // const getAllowedUsers = () => {
  //   client
  //     .get("/settings/get-guest-interaction-allowed-members", {
  //       params: {
  //         userId: user.id,
  //         tenantId: tenantDetails.tenantId,
  //       },
  //     })
  //     .then(({ data }) => {
  //       allowedUsersToInteract = [...data];
  //       guestDirectMeesageUsers = directMessageUsers.filter((user) =>
  //         allowedUsersToInteract?.includes(user.userId)
  //       );
  //       console.log(
  //         allowedUsersToInteract,
  //         guestDirectMeesageUsers,
  //         "guestdirectmessageusers"
  //       );
  //       allThreads = [...guestDirectMeesageUsers];
  //     });
  // };
  // const getAllowedUsers = async () => {
  //   allowedUsersToInteract = await client.get(
  //     "/settings/get-guest-interaction-allowed-members",

  //   return allowedUsersToInteract;
  // };

  // useEffect(() => {
  //   console.log("guestdire");

  //   console.log(allThreads, "gue");
  // }, [guestDirectMeesageUsers]);
  // const socketOnPrivateMessage = ({
  //   message,
  //   senderName,
  //   senderId,
  //   reciverId,
  //   createdAt,
  //   senderProfilePicture,
  //   replyMessage,
  //   _id,
  //   attachments,
  //   tenantId,
  // }) => {
  //   if (tenantId !== currentThread.tenantId) {
  //     console.error("ERROR:[SOCKET] wrong tenant");
  //     return;
  //   }
  //   if (reciverId != user.id) {
  //     console.error("ERROR:[SOCKET] sending message to wrong user ");
  //     return;
  //   }
  //   dispatch(
  //     updatePrivateConversations({
  //       message,
  //       senderName,
  //       senderId,
  //       reciverId,
  //       createdAt,
  //       senderProfilePicture,
  //       replyMessage,
  //       _id,
  //       attachments,
  //       reactions: {},
  //     })
  //   );
  //   // sender id id of user who sent this message and reciver id the id of user who is rciving this message
  //   // in this case we are reciver so our id should be equal to this id

  //   if (currentThread.userId !== senderId) {
  //     playNotifySoundDebounced();
  //   }
  //   if (currentThread.userId === senderId) {
  //     socket.emit("readMessageFromSocket", {
  //       threadId: currentThread.threadId,
  //       threadType: currentThread.type,
  //       userId: user.id,
  //       reciverId: currentThread.userId,
  //     });
  //   }
  // };

  // const socketOnMessage = (message) => {
  //   dispatch(updateThreadMessage({ ...message, reactions: {} }));

  //   if (currentThread.threadId == message.threadId) {
  //     socket.emit("readMessageFromSocket", {
  //       threadId: currentThread.threadId,
  //       threadType: currentThread.type,
  //       userId: user.id,
  //       reciverId: currentThread.userId,
  //     });
  //   }
  // };

  // const socketNewThreadMessage = ({ threadId, projectId }) => {
  //   // console.log("updateUnreadCountForProjectThreads", { threadId, projectId });
  //   dispatch(updateUnreadCountForProjectThreads({ threadId, projectId }));
  // };

  React.useEffect(() => {
    socket.on("privateMessage", socketOnPrivateMessage);
    socket.on("message", socketOnMessage);
    return () => {
      socket.off("privateMessage", socketOnPrivateMessage);
      socket.off("message", socketOnMessage);
      socket.off("newThreadMessage", socketNewThreadMessage);
    };
  }, [socket, currentThread]);

  // const allThreads = [...threads, ...directMessageUsers];

  const handleSort = (threads) => {
    const sortedThreads = threads.sort((a, b) => {
      const aTime = a.lastMessageTime
        ? new Date(a.lastMessageTime).getTime()
        : 0;
      const bTime = b.lastMessageTime
        ? new Date(b.lastMessageTime).getTime()
        : 0;

      if (aTime > bTime) {
        return -1;
      } else if (aTime < bTime) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedThreads;
  };

  const handleSearch = useCallback(
    (threads) => {
      const regex = new RegExp(search, "i");
      return threads.filter((thread) => {
        if (
          regex.test(thread.threadName) ||
          regex.test(thread.firstname) ||
          regex.test(thread.lastname)
        ) {
          return thread;
        }
      });
    },
    [search, threads, directMessageUsers]
  );

  // const memoizedHandleSort = useMemo(() => {
  //   return handleSort; // Assuming handleSort is a function
  // }, []);

  const sortThreads = useCallback(
    (threads) => {
      console.log("I ran sortThreads ");
      const pinnedThreads = handleSort(threads.filter((t) => t.isPinned == 1));
      const unPinnedThreads = handleSort(
        threads.filter((t) => t.isPinned != 1)
      );
      return [...pinnedThreads, ...unPinnedThreads];
    },
    [threads, directMessageUsers]
  );
  useEffect(() => {
    console.log(threads, directMessageUsers, "<<<<<<<<<<");
  }, [threads, directMessageUsers]);
  useEffect(() => {
    if (+user.roleId === 4 && user.isGuestDeactivated) {
      navigate("/");
    }
  }, []);

  return (
    <CustomLayout
      page="messages"
      title={<div className="text-black out-500-14">Messages</div>}
    >
      <ConversationsAppTour />
      <div className="flex h-full bg-white">
        <div
          className="w-[320px]  border-r border-gray-200"
          id="conversations-tour-messages"
        >
          <div className="flex gap-x-3 py-2  h-[46px] px-6 border-b border-gray-200">
            <CustomSearchBox
              placeholder="Search users"
              className="w-[238px] h-[30px]"
              search={search}
              setSearch={setSearch}
            />
            <Dropdown
              menu={{
                items: [
                  {
                    key: "3",
                    label: (
                      <div
                        className="text-gray-700 out-300-14"
                        onClick={() =>
                          dispatch(
                            updateConversations({
                              key: "newGroupModal",
                              value: true,
                            })
                          )
                        }
                      >
                        Create new group
                      </div>
                    ),
                    icon: <ThreadIcon />,
                  },
                ],
              }}
              trigger={["click"]}
            >
              <button>
                <PlusIcon className="text-gray-500" />
              </button>
            </Dropdown>
          </div>
          <div className="h-[calc(100vh-92px)] overflow-y-auto">
            {sortThreads(handleSearch(allThreads)).map((thread, i) => (
              <ChatCard key={i} thread={thread} socket={socket} />
            ))}
          </div>
        </div>

        <div className="flex-1 h-full">
          {/* <ChatEmptyState /> */}

          <ChatWindow socket={socket} />
        </div>
      </div>

      {/* Modals  */}
      <NewGroupModal
        onClose={() =>
          dispatch(
            resetConversations([
              "newGroupModal",
              "editThreadDetails",
              "editThread",
            ])
          )
        }
      />
      {/* <DeleteGroupModal />

    */}
      <FilePreviweModal
        visible={filePreview.visible}
        page="messages"
        onClose={() => dispatch(resetConversations(["filePreview"]))}
      />

      {/*  */}

      <SharedFilesDrawer
        visible={sharedFilesDrawer}
        onClose={() => dispatch(resetConversations(["sharedFilesDrawer"]))}
      />

      <SaveInLibrary
        visible={saveInLibrary.visible}
        onClose={() => dispatch(resetConversations(["saveInLibrary"]))}
      />

      <SharedFileDetailsDrawer
        visible={sharedFileDetailsDrawer.visible}
        data={sharedFileDetailsDrawer.data}
        onClose={() =>
          dispatch(resetConversations(["sharedFileDetailsDrawer"]))
        }
      />
      <DeleteFileModal
        visible={deleteFileModal}
        onClose={() => {
          dispatch(
            resetConversations(["deleteFileModal", "deleteFileInMessage"])
          );
        }}
      />
      <LeaveGroupModal
        visible={leaveThread}
        onClose={() => {
          dispatch(resetConversations(["leaveThread", "leaveThreadDetails"]));
        }}
      />
      <DeleteGroupModal />
      <ShareModal
        visible={shareMessageModal}
        socket={socket}
        onClose={() =>
          dispatch(
            updateConversations({ key: "shareMessageModal", value: false })
          )
        }
      />
      <GroupDetailsDrawer
        open={groupDetailsDrawer.visible}
        details={groupDetailsDrawer.data}
        onClose={() =>
          dispatch(
            updateConversations({
              key: "groupDetailsDrawer",
              value: { visible: false, data: {} },
            })
          )
        }
      />

      <UserDetailsDrawer
        open={userDetailsDrawer.visible}
        user={userDetailsDrawer.data}
        onClose={() =>
          dispatch(
            updateConversations({
              key: "userDetailsDrawer",
              value: { visible: false, data: {} },
            })
          )
        }
      />
    </CustomLayout>
  );
};

export default ConversationsScreen;
