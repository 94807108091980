import React from "react";
import Icon from "@ant-design/icons";

const RefreshIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.122 21.473a7.083 7.083 0 009.012-10.014l-.208-.361m-12.06 7.444a7.083 7.083 0 019.012-10.014m-10.8 10.084l2.277.61.61-2.277m10.07-3.89l.61-2.276 2.277.61"
    ></path>
  </svg>
);

const RefreshIcon = (props) => <Icon component={RefreshIconSvg} {...props} />;

export { RefreshIcon };
