import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomSelect, { MultiSelect } from "../../../Core/CommonV2/CustomSelect";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import uniqid from "uniqid";
import axios from "axios";
import { CrossIconSecondary } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  createThread,
  getThreads,
  getThreadsMembers,
  updateThread,
} from "../../../Core/redux/api/conversationAPI";
import { getProjectList } from "../../../Core/redux/api/projectAPI";
import getUsersName from "../../../Core/utils/getUserName";
import { Checkbox, Divider, Upload } from "antd";
import ImageCropModal from "../../../Core/CommonV2/ImageCropModal";
import CustomColorPicker from "../../../Core/CommonV2/CustomColorPicker";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";

const UserRow = ({ user, checked, onCheck }) => {
  return (
    <div className="flex py-[10px]  gap-x-3 justify-between px-3">
      <Checkbox checked={checked} onChange={() => onCheck(user.id)} />
      <CustomAvatar
        title={getUsersName(user)}
        src={user.profilePicture}
        size={24}
        fontSize={"12px"}
        whiteText
        color={user.profilePictureColorCode}
      />
      <div className="out-300-14 text-gray-900">{getUsersName(user)}</div>
    </div>
  );
};
const NewGroupModal = ({ onClose: onCancel }) => {
  const { newGroupModal } = useAppSelector((state) => state.conversations);
  const { user } = useAppSelector((state) => state.userDetails);

  const dispatch = useAppDispatch();

  const { editThreadDetails, editThread, threadMembers, directMessageUsers } =
    useAppSelector((state) => state.conversations);
  const { allowedUsersForGuest } = useAppSelector((state) => state.settings);
  const [image, setImage] = useState(editThreadDetails.threadProfilePicture);

  const [title, setTitle] = useState(editThreadDetails.threadName);
  const [color, setColor] = useState(
    editThreadDetails.threadProfilePictureColorCode
  );
  const [isDirty, setIsDirty] = useState(false);
  const [fileList, setFileList] = useState([]);

  const editGroupModal = {
    value: editThread,
  };

  const { projectList } = useAppSelector((state) => state.projects);

  useEffect(() => {
    dispatch(getProjectList(user.id));
  }, []);
  useEffect(() => {
    setTitle(editThreadDetails.threadName);
    setImage(editThreadDetails.threadProfilePicture);
    setColor(editThreadDetails.threadProfilePictureColorCode);
  }, [editThread]);

  let messageUsers;

  if (+user.roleId === 4) {
    messageUsers = directMessageUsers.filter((user) =>
      allowedUsersForGuest?.includes(user.userId)
    );
  } else {
    messageUsers = [...directMessageUsers];
  }

  const [threadName, setThreadName] = useState(editThreadDetails.threadName);
  const [selectedProject, setSelectedProject] = useState(
    editThreadDetails.projectId
  );

  const [selectedUsers, setSelectedUsers] = useState(
    editThread
      ? [
          ...threadMembers
            .map((m) => m.userId || m.id)
            .filter((u) => +u !== +user.id),
        ]
      : []
  );

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const fileData = [];

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
        });
      });
    });

    setImage(fileData[0]?.url || "");
    setIsDirty(true);
  };
  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          console.log("UPLOADED", res.data);
          onSuccess(res.data);
        });
    } catch (err) {
      onError({ err });
    }
  };
  const onClose = () => {
    setThreadName("");
    setSelectedProject(null);
    setSelectedUsers([]);
    onCancel();
  };

  const getThreadsMembersAsync = async () => {
    if (editThreadDetails.threadId)
      await dispatch(
        getThreadsMembers({ threadId: editThreadDetails.threadId })
      );
  };

  useEffect(() => {
    if (editThread) {
      getThreadsMembersAsync();
      setThreadName(editThreadDetails.threadName);
      setSelectedProject(editThreadDetails.projectId);
      // setSelectedUsers(threadMembers.map((m) => m.userId));
    }
  }, [editThread, editThreadDetails]);

  useEffect(() => {
    if (editThread) {
      const newThreadMembers = threadMembers
        .map((m) => m.userId || m.id)
        .filter((u) => +u !== +user.id);

      if (editThreadDetails.userToBeAdded) {
        newThreadMembers.push(editThreadDetails.userToBeAdded);
      }
      setSelectedUsers(newThreadMembers);
    }
  }, [threadMembers]);

  return (
    <CustomModal
      visible={newGroupModal}
      width="362px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div>
            {!editGroupModal.value && (
              <>
                {" "}
                <h3 className="out-500-14 text-black">Create a group</h3>
                <p className="out-300-12 text-black pb-4">
                  Make groups for your team to communicate better.{" "}
                </p>{" "}
              </>
            )}

            <div className="">
              <p className="out-500-14 text-gray-500 pb-4">Name of the group</p>
              <CustomInputBox
                placeholder="e.g. Design team"
                value={threadName}
                onChange={(e) => setThreadName(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <p className="out-500-14 text-gray-500 pb-4">Project</p>
              <CustomSelect
                placeholder="Select project"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e)}
                options={projectList.map((project) => {
                  return {
                    id: project.id,
                    value: project.id,
                    label: project.title,
                    render: () => (
                      <div className="flex items-center ">
                        <CustomAvatar
                          src={project.projectImage}
                          size={24}
                          title={project.title}
                        />

                        <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                          {project.title}
                        </p>
                      </div>
                    ),
                  };
                })}
              />
            </div>
            <div className="mt-4">
              <p className="out-500-14 text-gray-500 pb-4">Group icons</p>
              <div className=" flex items-center gap-x-6">
                {image && image?.length > 0 ? (
                  <img
                    src={image}
                    className="w-16 h-16 rounded-full cursor-pointer object-cover"
                    onClick={async () => {
                      // const src = image as string;
                      // if (!src) return;
                      // const image = new Image();
                      // image.src = src;
                      // const imgWindow = window.open(src);
                      // imgWindow?.document.write(image.outerHTML);
                      handleChange({ fileList: [] });
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.src =
                        "/images/icons/project-onboarding/cancelImage.svg";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.src = image;
                    }}
                  />
                ) : (
                  <ImageCropModal>
                    <Upload
                      action={process.env.REACT_APP_API_URL + "/v1/upload"}
                      // headers={{ Authorization: "Bearer " + user.token }}
                      customRequest={uploadImage}
                      fileList={fileList}
                      onChange={handleChange}
                      multiple={false}
                      showUploadList={false}
                    >
                      <div className="w-16 h-16">
                        <img
                          src="/images/v2/onboarding/Logo.svg"
                          className="w-16 h-16"
                        />
                      </div>
                    </Upload>
                  </ImageCropModal>
                )}

                <div>
                  <Divider type="vertical" className="h-6" />
                  <p className="out-500-12 text-gray-500 text-center">or</p>
                  <Divider type="vertical" className="h-6" />
                </div>

                <div className="flex gap-x-6 relative">
                  {image && (
                    <div className="absolute top-0 left-0 right-0 bottom-0 opacity-50 bg-white z-10" />
                  )}
                  <CustomAvatar
                    title={title ? title : "P"}
                    size={64}
                    color={color}
                    whiteText
                    fontSize="24px"
                  />

                  {/* <img src="/images/icons/logo-w.svg" className="w-16 h-16" /> */}
                  <CustomColorPicker
                    placement="bottomLeft"
                    onColorChange={(color) => {
                      setColor(color);
                      setIsDirty(true);
                    }}
                  >
                    <div className="flex items-center cursor-pointer text-gray-900">
                      <p className="out-500-12 text-gray-500 mr-2">
                        Change colour
                      </p>
                      <button>
                        <ChevronIcon
                          className="text-gray-500"
                          height="20"
                          width="20"
                        />
                      </button>
                    </div>
                  </CustomColorPicker>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="out-500-14 text-gray-500 pb-4">Add People</p>
              <MultiSelect
                placeholder="Add people to the group"
                options={messageUsers
                  // .filter(
                  //   (u) =>
                  //     selectedUsers?.indexOf(u.userId) === -1 &&
                  //     +u.userId !== +user.id
                  // )
                  .map((user) => {
                    return {
                      id: user.userId,
                      value: user.userId,
                      label: `${user.firstname} ${user.lastname}`,
                      render: (value, i) => (
                        <div
                          // onClick={() => {
                          //   setSelectedUsers((oldList) => [
                          //     ...oldList,
                          //     user.userId,
                          //   ]);
                          // }}
                          className="flex items-center "
                        >
                          {/* <CustomAvatar
                            src={user.profilePicture}
                            size={24}
                            title={`${user.firstname} ${user.lastname}`}
                          />

                          <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                            {user.firstname} {user.lastname}
                          </p> */}
                          <UserRow
                            user={user}
                            key={user.userId + i}
                            checked={
                              selectedUsers.includes(user.userId.toString()) ||
                              selectedUsers.includes(+user.userId)
                            }
                            onCheck={() => {
                              setSelectedUsers((prevSelectedUsers) => {
                                const exists = prevSelectedUsers.includes(
                                  user.userId
                                );

                                return exists
                                  ? prevSelectedUsers.filter(
                                      (permId) => permId !== user.userId
                                    )
                                  : [...prevSelectedUsers, user.userId];
                              });
                            }}
                          />
                        </div>
                      ),
                    };
                  })}
              />
            </div>
            <div className="mt-6 max-h-[176px] overflow-y-auto">
              <div className="flex py-[10px] justify-between px-3">
                <div className="flex items-center ">
                  <CustomAvatar
                    size={24}
                    src={user.profilePicture}
                    title={`${user.firstName} ${user.lastName}`}
                  />

                  <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                    {user.firstName} {user.lastName} (Me)
                  </p>
                </div>
              </div>
              {messageUsers.map((user, i) => {
                if (selectedUsers?.indexOf(user.userId) !== -1) {
                  return (
                    <div
                      className="flex py-[10px] justify-between px-3"
                      key={user.userId + i}
                    >
                      <div className="flex items-center ">
                        <CustomAvatar
                          size={24}
                          src={user.profilePicture}
                          title={`${user.firstname} ${user.lastname}`}
                        />

                        <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                          {user.firstname} {user.lastname}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          setSelectedUsers((oldList) => [
                            ...oldList.filter((old) => +old !== +user.userId),
                          ])
                        }
                      >
                        <CrossIconSecondary className="text-gray-700" />
                      </button>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="flex mt-[33px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text={editGroupModal.value ? "Save changes" : "Create"}
              height="30px"
              width={editGroupModal.value ? "105px" : "64px"}
              onClick={async () => {
                if (editThread) {
                  const updateObject: {
                    projectId?: string;
                    name: string;
                    description?: string;
                    users?: number[] | string[];
                    phaseId?: string;
                    threadId: string;
                    threadProfilePicture?: string | null;
                    threadProfilePictureColorCode?: string | null;
                  } = {
                    threadId: editThreadDetails.threadId,
                    name: editThreadDetails.threadName,
                  };
                  if (threadName !== editThreadDetails.threadName)
                    updateObject.name = threadName;
                  if (image !== editThreadDetails.threadProfilePicture)
                    updateObject.threadProfilePicture = image;
                  if (color !== editThreadDetails.threadProfilePictureColorCode)
                    updateObject.threadProfilePictureColorCode = color;

                  if (selectedUsers !== threadMembers.map((t) => t.threadId))
                    updateObject.users = [...selectedUsers];
                  if (selectedProject !== editThreadDetails.projectId) {
                    updateObject.projectId = selectedProject;
                  }
                  await dispatch(updateThread(updateObject));
                  getThreadsMembersAsync();
                } else {
                  await dispatch(
                    createThread({
                      name: threadName,
                      users: [...selectedUsers],
                      threadProfilePicture: image ? image : "",
                      threadProfilePictureColorCode: color ? color : "",
                      projectId: selectedProject,
                    })
                  );
                }
                onClose();
                dispatch(getThreads({ userId: user.id }));
              }}
              disabled={selectedUsers.length === 0}
            />
          </div>
        </div>
      }
    />
  );
};

export default NewGroupModal;
