import { useQuery, useMutation, useQueryClient } from "react-query";
import { client } from "../../utils/axiosClient";

const queryConfig = {
  enabled: true,
  refetchInterval: 1800000, // 30 minutes
  staleTime: Infinity,
  keepPreviousData: true,
};

// Fetch Todos
export const useGetTodos = (userId: number) => {
  return useQuery(
    ["getTodos", userId],
    async () => {
      const { data } = await client.get(
        `/dashboard/get-todos?userId=${userId}`
      );
      return data;
    },
    queryConfig
  );
};

// Create Todo
export const useCreateTodo = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ userId, title }: { userId: number; title: string }) => {
      const { data } = await client.post(`/dashboard/create-todo`, {
        userId,
        title,
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getTodos");
      },
    }
  );
};

// Update Todo
export const useUpdateTodo = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      userId,
      todoId,
      status,
      title,
      backgroundColor,
    }: {
      userId: number;
      todoId: number;
      status: number;
      title: string;
      backgroundColor?: string;
    }) => {
      const { data } = await client.put(`/dashboard/update-todo`, {
        userId,
        todoId,
        status,
        title,
        backgroundColor,
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getTodos");
      },
    }
  );
};

// Delete Todo
export const useDeleteTodo = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ userId, todoId }: { userId: number; todoId?: number }) => {
      const { data } = await client.delete(`/dashboard/delete-todo`, {
        params: { userId, todoId },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getTodos");
      },
    }
  );
};

// Delete All Todos
export const useDeleteAllTodo = (userId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      const { data } = await client.delete(`/dashboard/delete-all-todo`, {
        params: { userId },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getTodos");
      },
    }
  );
};

// Get Ongoing Project Count
export const useGetOngoingProjectCount = (userId: number) => {
  return useQuery(
    ["getOngoingProjects", userId],
    async () => {
      const { data } = await client.get(
        `/dashboard/project-count?userId=${userId}`
      );
      return data[0];
    },
    queryConfig
  );
};

// Get All Tour Status
export const useGetAllTourStatus = (userId: number) => {
  return useQuery(
    ["getAllTourStatus", userId],
    async () => {
      const { data } = await client.get(
        `/dashboard/tour-status?userId=${userId}`
      );
      return data;
    },
    queryConfig
  );
};

// Update Tour Status
export const useUpdateTourStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      userId,
      tourStatus,
    }: {
      userId: number;
      tourStatus:
        | "dashboardTourStatus"
        | "scheduleTourStatus"
        | "libraryTourStatus"
        | "messagesTourStatus";
    }) => {
      const { data } = await client.put(`/dashboard/update-tour-status`, {
        userId,
        tourStatus,
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllTourStatus");
      },
    }
  );
};

// Get Completed Project Count
export const useGetCompletedProjectCount = (userId: number) => {
  return useQuery(
    ["getCompletedProjectCount", userId],
    async () => {
      const { data } = await client.get(
        `/dashboard/completed-project-count?userId=${userId}`
      );
      return data[0];
    },
    queryConfig
  );
};

// Get My Tasks
export const useGetMyTasks = ({
  userId,
  status,
  projectId,
}: {
  userId: number;
  status: number;
  projectId: number | string;
}) => {
  return useQuery(
    ["getMyTasks", userId, status, projectId],
    async () => {
      const { data } = await client.get(
        `/dashboard/my-tasks?userId=${userId}&status=${status}&projectId=${projectId}`
      );
      return data;
    },
    queryConfig
  );
};

// Get Workspaces
export const useGetWorkspaces = () => {
  return useQuery(
    "getWorkspaces",
    async () => {
      const { data } = await client.get("/auth/get-workspaces");
      return data;
    },
    queryConfig
  );
};

// Get Workspaces by ID
export const useGetWorkspacesById = (id: string) => {
  return useQuery(
    ["getWorkspacesById", id],
    async () => {
      const { data } = await client.get(`/auth/get-workspaces-by-id`, {
        params: { id },
      });
      return data;
    },
    queryConfig
  );
};

// Change Workspace
export const useChangeWorkspace = () => {
  return useMutation(async ({ tenantId }: { tenantId: string }) => {
    const { data } = await client.post("/auth/change-workspace", { tenantId });
    const user = {
      ...JSON.parse(localStorage.getItem("user")),
      token: data.token,
    };
    localStorage.setItem("user", JSON.stringify(user));
    return data;
  });
};
