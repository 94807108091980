/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "antd";
import React from "react";

/**
 * @param  {String} text
 * @param  {String} width
 * @param   {String} height
 * @param  {Function} onClick
 * @param  {String} icon
 * */

type CustomButtonProps = {
  text?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  icon?: string;
  width?: string;
  height?: string;
  className?: string;
  type?: any;
  disabled?: boolean;
  iconAlignment?: string;
  iconPadding?: string;
  iconSize?: string;
};

export default function CustomButton({
  text = "button",
  onClick,

  icon,
  iconAlignment,
  iconPadding,
  iconSize,
  width = "161px",
  height = "40px",
  className,
  type = "button",
  disabled = false,
}: CustomButtonProps) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${
        className || "bg-primary-600 text-white"
      }  mon-500-14 rounded-xl ${disabled ? "opacity-50" : ""}`}
      style={{ width, height }}
      disabled={disabled}
    >
      <div
        className={`flex justify-center items-center ${
          iconAlignment === "right" ? "flex-row-reverse" : ""
        }`}
      >
        {icon && (
          <img
            src={icon}
            className={`${iconPadding} ${iconSize ? iconSize : "w-5 h-5"}`}
          />
        )}
        <p
          className={`${
            icon && iconAlignment === "right" ? "mr-2.5" : "mx-2.5"
          }`}
        >
          {text}
        </p>
      </div>
    </button>
  );
}
