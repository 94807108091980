import { useQuery } from "react-query";
import { client } from "../../utils/axiosClient";

// const payload = {
//   weeks: [
//     { startDate: "2022-08-1 00:00:00.000", endDate: "2024-08-25 00:00:00.000" },
//     { startDate: "2022-08-1 00:00:00.000", endDate: "2024-08-25 00:00:00.000" },
//   ],
//   projectIds: [
//     506, 343, 498, 487, 249, 456, 298, 322, 536, 524, 448, 504, 505, 521,
//   ],
//   userIds: [113, 48, 264, 535, 536, 534],
//   teamIds: [1, 2, 4],
// };

// Tenant Insights Hook
export const useGetTenantInsights = () => {
  return useQuery(
    ["tenantInsights"],
    async () => {
      const { data } = await client.get("report/get-tenant-insights");
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
    }
  );
};

// Total Time vs Estimation Per Project Hook
export const useGetTotalTimeVsEstimationPerProject = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );

  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["totalTimeVsEstimationPerProject", payload],
    async () => {
      const { data } = await client.post(
        "report/total-time-vs-estimation-per-project",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

// Team Time on Projects Hook
export const useGetTeamTimeOnProjects = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );

  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["teamTimeOnProjects", payload],
    async () => {
      const { data } = await client.post(
        "report/team-time-on-projects",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

// Task vs Bug Count Per Project Hook
export const useGetTaskVsBugCountPerProject = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );

  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["taskVsBugCountPerProject", payload],
    async () => {
      const { data } = await client.post(
        "report/task-vs-bug-count-per-project",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

// Task vs Bug Time Per Project Hook
export const useGetTaskVsBugTimePerProject = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );

  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["taskVsBugTimePerProject", payload],
    async () => {
      const { data } = await client.post(
        "report/task-vs-bug-time-per-project",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

// Team Allocation Per Project Hook
export const useGetTeamAllocationPerProject = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );
  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["teamAllocationPerProject", payload],
    async () => {
      const { data } = await client.post(
        "report/team-allocation-per-project",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

// Total Time Spent by Teams Hook
export const useGetTotalTimeSpentByTeams = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );

  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["totalTimeSpentByTeams", payload],
    async () => {
      const { data } = await client.post(
        "report/total-time-spent-by-teams",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

// Total Time vs Estimation Per User Hook
export const useGetTotalTimeVsEstimationPerUser = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );

  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["totalTimeVsEstimationPerUser", payload],
    async () => {
      const { data } = await client.post(
        "report/total-time-vs-estimation-per-user",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

// Assignee Time on Projects Hook
export const useGetAssigneeTimeOnProjects = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );

  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["assigneeTimeOnProjects", payload],
    async () => {
      const { data } = await client.post(
        "report/assignee-time-on-projects",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

// Task vs Bug Count Per User Hook
export const useGetTaskVsBugCountPerUser = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );
  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["taskVsBugCountPerUser", payload],
    async () => {
      const { data } = await client.post(
        "report/task-vs-bug-count-per-user",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

// Task vs Bug Time Per User Hook
export const useGetTaskVsBugTimePerUser = (
  weeks,
  projectIds,
  userIds,
  teamIds,
  enabled
) => {
  const payload = { weeks, projectIds, userIds, teamIds };

  // const queryEnabled = Boolean(
  //   enabled &&
  //     weeks?.length &&
  //     projectIds?.length &&
  //     userIds?.length &&
  //     teamIds?.length
  // );

  const queryEnabled = Boolean(
    enabled &&
      weeks?.length &&
      !(
        weeks.length === 1 &&
        weeks[0]?.startDate === "" &&
        weeks[0]?.endDate === ""
      ) && // Check for empty state
      projectIds?.length &&
      (userIds?.length || teamIds?.length)
  );

  return useQuery(
    ["taskVsBugTimePerUser", payload],
    async () => {
      const { data } = await client.post(
        "report/task-vs-bug-time-per-user",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled,
    }
  );
};

export const useCompareWeeklyTrends = (
  startDate,
  endDate,
  projectIds,
  userIds,
  enabled
) => {
  // Prepare the payload for the request
  const payload = { startDate, endDate, projectIds, userIds };
  // const payload = {
  //   startDate: "2024-04-19 21:45:41.704",
  //   endDate: "2024-05-19 21:45:41.704",
  //   projectIds: [
  //     506, 343, 498, 487, 249, 456, 298, 322, 536, 524, 448, 504, 505, 521,
  //   ],
  //   userIds: [113, 48, 264, 535, 536, 534],
  // };

  // Determine if the query should be enabled
  const queryEnabled = Boolean(
    enabled && startDate && endDate && projectIds?.length && userIds?.length
  );

  return useQuery(
    ["compareWeeklyTrends", payload],
    async () => {
      const { data } = await client.post(
        "report/compare-weekly-trends",
        payload
      );
      return data;
    },
    {
      refetchInterval: 1800000, // Refetch every 30 minutes
      staleTime: 1800000,
      keepPreviousData: true,
      enabled: queryEnabled, // Only run the query if conditions are met
    }
  );
};

// Get Teams Querey Hook

export const useGetTeams = () => {
  return useQuery(
    ["teams"], // Unique key for caching
    async () => {
      const { data } = await client.get("teams/get-teams");
      return data;
    },
    {
      refetchInterval: 1800000,
      staleTime: 1800000,
      keepPreviousData: true,
    }
  );
};
