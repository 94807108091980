import React from "react";
import Icon from "@ant-design/icons";

const UsersIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 10.558c.97.488 1.803 1.27 2.41 2.248.12.194.18.291.201.425a.723.723 0 01-.398.716c-.125.053-.265.053-.546.053m-3-6.312a3 3 0 000-5.376M9.333 5a3 3 0 11-6 0 3 3 0 016 0zm-7.627 7.626C2.769 11.03 4.446 10 6.333 10c1.887 0 3.565 1.03 4.627 2.626.233.35.35.524.336.747a.74.74 0 01-.263.492c-.178.135-.424.135-.915.135h-7.57c-.49 0-.736 0-.914-.135a.74.74 0 01-.264-.492c-.013-.223.103-.398.336-.747z"
    ></path>
  </svg>
);

const UsersIcon = (props) => <Icon component={UsersIconSvg} {...props} />;

export { UsersIcon };
