import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

type DatePickerProps = {
  value?: any;

  onChange?: (val) => void;
  placeholder: string;
  className?: string;
  format?: string;
  suffixIcon?: React.ReactNode;
  disabled?: boolean;
  formState?: any;
  name?: string;
  disabledDate?: any;
};

const CustomDatePicker = ({
  value,
  onChange,
  name,
  className = "w-full  bg-transparent border  rounded-[4px] ",
  format = "DD MMM YYYY",
  placeholder = "Phase start date",
  suffixIcon,
  disabled,
  formState = {},
  disabledDate,
}: DatePickerProps) => {
  const { errors } = formState;
  const showError = errors?.[name];
  const errorText = errors?.[name]?.message;

  const datePickerClassName = `${className} ${
    showError ? "border border-error-600 shadow-input-error" : ""
  }`;
  console.log(value, formState, "value");

  return (
    <div className="flex flex-col w-full">
      <DatePicker
        name={name}
        className={datePickerClassName}
        format={format}
        placeholder={placeholder}
        value={value ? dayjs(value) : null}
        onChange={onChange}
        suffixIcon={suffixIcon}
        disabled={disabled}
        disabledDate={disabledDate}
        status={showError && "error"}
      />
      {showError && (
        <div className="mt-1.5 out-300-12 text-left text-error-500">
          {errorText}
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
