import Checkbox from "antd/lib/checkbox";
import React, { useState, useEffect } from "react";

import CustomModal from "../../../Core/CommonV2/CustomModal";
import {
  createTodo,
  deleteTodo,
  deleteAllTodo,
  getTodos,
  updateTodo,
} from "../../../Core/redux/api/dashboardAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { EditPencilIcon } from "../../../Core/svgV2/EditIcon";
import NotesCard from "../NotesCard";
import { Input } from "antd";

const CheckListItem = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { todoList } = useAppSelector((state) => state.dashboard);
  const fetchFreshTodos = (loadingFalse = false) => {
    dispatch(
      getTodos({
        userId: user.id,
      })
    );
  };
  useEffect(() => {
    dispatch(
      getTodos({
        userId: user.id,
      })
    );
  }, [todoList]);
  const handleDeleteTodo = (id: number) => {
    dispatch(
      deleteTodo({
        userId: user.id,
        todoId: id,
      })
    ).finally(() => {
      fetchFreshTodos(true);
    });
  };

  return (
    <div>
      {todoList.map((todo, index) => (
        <div
          className="h-9 flex items-center justify-between border-b pl-4  hover:bg-gray-50 group"
          key={index}
        >
          <div className="flex flex-row items-center justify-center gap-x-3">
            <Checkbox
              className=""
              checked={todo.status ? true : false}
              onClick={() => {
                dispatch(
                  updateTodo({
                    todoId: todo.id,
                    status: todo.status === 1 ? 0 : 1,
                    title: todo.title,
                    userId: user.id,
                  })
                );
                fetchFreshTodos();
              }}
            />
            <div
              className={`${
                todo.status
                  ? "line-through out-500-12 text-gray-400 decoration-[0.1px]"
                  : "out-500-12 text-gray-900"
              }`}
              // style={todo.status?{textDecoration}:{}}
            >
              {todo.title}
            </div>
          </div>
          <button
            className="hidden group-hover:block"
            onClick={() => handleDeleteTodo(todo.id)}
          >
            <CrossIcon className="text-gray-400 out-300-14" />
          </button>
        </div>
      ))}
    </div>
  );
};
const StickyNotesScreen = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { todoList } = useAppSelector((state) => state.dashboard);

  const todoListCopy = [...todoList];
  const handleSort = (todoList) => {
    const sortedTodoList = todoList.sort((a, b) => {
      const aTodoCreatedAt = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const bTodoCreatedAt = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      console.log(aTodoCreatedAt, bTodoCreatedAt, "cre");
      if (aTodoCreatedAt > bTodoCreatedAt) {
        return 1;
      } else if (aTodoCreatedAt < bTodoCreatedAt) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedTodoList;
  };

  useEffect(() => {
    fetchFreshTodos();
  }, [user.id]);
  const fetchFreshTodos = () => {
    dispatch(
      getTodos({
        userId: user.id,
      })
    );
  };

  // const handleDeleteTodo = (id: number) => {
  //   dispatch(
  //     deleteTodo({
  //       userId: user.id,
  //       todoId: id,
  //     })
  //   ).finally(() => {
  //     fetchFreshTodos();
  //   });
  // };
  // const handleSaveTodo = async () => {
  //   if (todoInput?.trim()?.length === 0) {
  //     setTodoInput(null);
  //     return;
  //   }
  //   await dispatch(
  //     createTodo({
  //       userId: user.id,
  //       title: todoInput,
  //     })
  //   ).finally(() => {
  //     fetchFreshTodos();
  //   });
  //   setTodoInput(null);
  // };
  return (
    <div
      className={` pl-6 pr-[9px] mt-[23px] min-h-[170px]  max-h-[521px]  w-full ${
        todoList.length >= 18 ? "overflow-y-scroll" : ""
      }  flex flex-wrap  gap-[18px]`}
    >
      {todoList.length !== 0
        ? handleSort(todoListCopy).map((todo, index) => (
            <NotesCard
              isWhiteBoardModal={true}
              className=""
              todo={todo}
              key={index}
            />
          ))
        : ""}
      <NotesCard
        isWhiteBoardModal={true}
        className="opacity-70 hover:opacity-100 "
      />
    </div>
  );
};
const CheckListScreen = () => {
  const dispatch = useAppDispatch();
  const { todoList } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);
  const [isInputFocussed, setInputFocussed] = useState(false);
  console.log(isInputFocussed, "hi  ");
  let value = "";
  useEffect(() => {
    fetchFreshTodos();
  }, [user.id]);
  const fetchFreshTodos = () => {
    dispatch(
      getTodos({
        userId: user.id,
      })
    );
  };

  const handleSaveTodo = async () => {
    if (todoInput?.trim()?.length === 0) {
      setTodoInput(null);
      return;
    }
    await dispatch(
      createTodo({
        userId: user.id,
        title: todoInput,
      })
    ).finally(() => {
      fetchFreshTodos();
    });
    setTodoInput(null);
  };
  const [todoInput, setTodoInput] = useState(null);
  return (
    <div className="flex flex-col pt-[23px] px-6 gap-y-3">
      <Input
        onPressEnter={() => {
          handleSaveTodo();
          value = "";
          console.log(value, "value");
        }}
        type={"input"}
        prefix={<EditPencilIcon className="text-gray-500" />}
        placeholder={` Jot down your notes...`}
        value={todoInput}
        onFocus={() => {
          setInputFocussed(true);
          console.log("hi", isInputFocussed);
        }}
        onBlur={() => setInputFocussed(false)}
        onChange={(e) => {
          setTodoInput(e.target.value);
          console.log("");
          console.log(value, "value");
        }}
        className={`out-300-14 notes-input  h-10 w-full rounded-[4px] bg-inherit checklist-ant-input  ${
          isInputFocussed ? "shadow-input-focus" : ""
        }    `}
      />

      <div className="h-[423px]">
        <div
          className={`${
            todoList.length > 12 ? "overflow-y-scroll" : " "
          } h-[423px]`}
        >
          <CheckListItem />
        </div>
      </div>
    </div>
  );
};
const WhiteBoard = ({ onCancel, visible }) => {
  const { checkListScreen } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const fetchFreshTodos = () => {
    dispatch(
      getTodos({
        userId: user.id,
      })
    );
  };
  const handleDeleteAllTodo = () => {
    dispatch(
      deleteAllTodo({
        userId: user.id,
      })
    ).finally(() => {
      fetchFreshTodos();
    });
  };

  return (
    <CustomModal
      visible={visible}
      width="1127px"
      onCancel={() => onCancel()}
      body={
        <div className="h-[618px]  pt-[22px]">
          <div className="flex justify-between items-center pr-6 pl-6">
            <div className="h-6 out-500-16 "> My notes</div>
            <div className="flex gap-x-4">
              <div className="h-5">
                <button onClick={() => handleDeleteAllTodo()}>Clear all</button>
              </div>
              <div className="h-5">
                <CrossIcon className="text-gray-700" onClick={onCancel} />
              </div>
            </div>
          </div>
          <div className="h-5 flex pl-6">
            <p className="out-300-14 text-gray-500">
              Create your virtual board with sticky notes. Don’t like sticky
              notes? switch to &nbsp;
            </p>
            <div
              className=" text-primary-600 underline out-500-14 cursor-pointer"
              onClick={() => {
                dispatch(
                  updateDashboard({
                    key: "checkListScreen",
                    value: !checkListScreen,
                  })
                );
              }}
            >
              {!checkListScreen ? "checklist" : "sticky notes"}
            </div>
          </div>
          {checkListScreen ? <CheckListScreen /> : <StickyNotesScreen />}
        </div>
      }
    />
  );
};

export default WhiteBoard;
