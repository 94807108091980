import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetConversations,
  updateConversationsMultiple,
} from "../../../Core/redux/slices/conversations.slice";
import {
  closeThread,
  getPrivateChats,
  getThreads,
} from "../../../Core/redux/api/conversationAPI";
import { getOrganizationUsers } from "../../../Core/redux/api/projectAPI";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

// const handleDeleteThread = async () => {
//   setLoading(true);
//   await dispatch(
//     leaveThread({
//       userId: String(user.id),
//       threadId: currentThread.threadId,
//     })
//   );
//   onClose();
//   dispatch(
//     resetConversations(['showThreadProfileDrawer', 'currentThread', 'currentThreadMessage'])
//   );

//   dispatch(getThreads({ userId: user.id }));
//   onClose();
//   router.back();
//   setLoading(false);
// };
const DeleteGroupModal = () => {
  const { deleteThread, deleteThreadDetails, currentThread } = useAppSelector(
    (state) => state.conversations
  );
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const getDM = async () => {
    dispatch(getThreads({ userId: user.id }));
    await dispatch(getOrganizationUsers({ userId: user.id }));
    await dispatch(getPrivateChats({ userId: user.id }));
  };

  return (
    <CustomModal
      visible={deleteThread}
      width="362px"
      onCancel={() => console.log("")}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Delete group</p>
            <button>
              {" "}
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to delete this group? <br />
            This action cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() =>
                dispatch(
                  updateConversationsMultiple([
                    { key: "deleteThread", value: false },
                    { key: "deleteThreadDetails", value: {} },
                  ])
                )
              }
            />
            <CustomButton
              text="Delete group"
              height="30px"
              width="101px"
              className="bg-error-700 text-white"
              onClick={async () =>
                await dispatch(
                  closeThread({
                    userId: user.id.toString(),
                    threadId: deleteThreadDetails.threadId,
                  })
                ).then(() => {
                  dispatch(
                    updateConversationsMultiple([
                      { key: "deleteThread", value: false },
                      { key: "deleteThreadDetails", value: {} },
                    ])
                  );
                  if (currentThread.threadId == deleteThreadDetails.threadId) {
                    dispatch(
                      resetConversations([
                        "showThreadProfileDrawer",
                        "currentThread",
                        "currentThreadMessage",
                      ])
                    );
                  }
                  getDM();
                  dispatch(
                    updateDashboard({
                      key: "alertPopupModal",
                      value: {
                        visible: true,
                        data: {
                          title: `${deleteThreadDetails.threadName} group deleted`,
                          subtitle: "",
                          description: `Group was deleted`,
                        },
                      },
                    })
                  );
                })
              }
            />
          </div>
        </div>
      }
    />
  );
};

export default DeleteGroupModal;
