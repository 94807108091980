import React, { useState } from "react";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomSelect, { MultiSelect } from "../../../Core/CommonV2/CustomSelect";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { Calendar, Checkbox, Input, Popover } from "antd";
import dayjs from "dayjs";
import ClockIcon from "../../../Core/svgV2/Clock";
import { createEvent } from "../../../Core/redux/api/scheduleAPI";
import { client } from "../../../Core/utils/axiosClient";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

const CustomDatePicker = ({ onChange }) => {
  const [open, setOpen] = useState(false);

  const [date, setDate] = useState(dayjs());

  return (
    <Popover
      trigger={"click"}
      content={
        <div className="custom-calendar w-[328px] p-4">
          <div className="custom-calendar ">
            <Calendar
              fullscreen={false}
              value={date}
              onChange={(date) => {
                setDate(date);
                onChange(date);
              }}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                // const start = 0;
                // const end = 12;
                // const monthOptions = [];
                const year = value.year();
                // const month = value.month();

                const current = value.clone();
                const localeData = value.localeData();

                return (
                  <div className="">
                    <div className="flex  justify-between mt-4 mb-4 px-4">
                      <button onClick={() => setDate(date.add(-1, "months"))}>
                        <img
                          src="/images/icons/chevron-left.svg"
                          className="h-5 w-5"
                        />
                      </button>
                      <p className="out-500-16 text-black">
                        {localeData.months(current)} {year}
                      </p>
                      <button onClick={() => setDate(date.add(1, "months"))}>
                        <img
                          src="/images/icons/chevron-right.svg"
                          className="h-5 w-5"
                        />
                      </button>
                    </div>
                  </div>
                );
              }}
              className=""
            />{" "}
          </div>
        </div>
      }
    >
      <button
        onClick={() => setOpen(!open)}
        className="out-500-14 text-gray-500"
      >
        {date.format("dddd , DD MMMM")}
      </button>
    </Popover>
  );
};

// const TimePicker = ({ onChange, placeholder }) => {
//   const { chatRequest } = useAppSelector((state) => state.conversations);

//   const dispatch = useAppDispatch();

//   const [amPm, setAmPm] = useState("am");
//   const [time, setTime] = useState(null);
//   const [min, setMin] = useState("00");

//   useEffect(() => {
//     onChange(`${time}:${min} ${amPm}`);
//   }, [amPm, time]);
//   return (
//     <Popover
//       trigger={"click"}
//       content={
//         <div className="p-2">
//           <div className="flex">
//             <Input
//               type="number"
//               min={0}
//               max={12}
//               placeholder="00"
//               style={{
//                 padding: "0px 9px",
//               }}
//               value={time}
//               onChange={(e) =>
//                 setTime(+e.target.value > 12 ? 12 : e.target.value)
//               }
//               className="w-10 h-[25px] border-gray-50 focus:outline-none"
//             />
//             <span className="px-1 out-500-14">:</span>
//             <Input
//               // type="number"
//               onChange={(e) =>
//                 setMin(+e.target.value > 60 ? `${60}` : `${e.target.value}`)
//               }
//               placeholder="00"
//               style={{
//                 padding: "0px 9px",
//               }}
//               min={0}
//               max={60}
//               value={min}
//               className="w-10 h-[25px] border-gray-50 focus:outline-none"
//             />
//             <div className="w-[51px] flex h-[25px] items-center ml-2">
//               <div
//                 onClick={() => setAmPm("am")}
//                 className={`out-500-12 h-full w-[25px] ${
//                   amPm === "am"
//                     ? "bg-primary-600 border-primary-600 text-white"
//                     : "text-gray-300 border-gray-300"
//                 }  cursor-pointer rounded-l border-t border-l border-b flex items-center justify-center `}
//               >
//                 AM
//               </div>
//               <div
//                 onClick={() => setAmPm("pm")}
//                 className={`out-500-12 h-full w-[27px] cursor-pointer ${
//                   amPm === "pm"
//                     ? "bg-primary-600 border-4 border-primary-600 text-white"
//                     : "text-gray-300 border-r border-gray-300"
//                 }   rounded-r border-t  border-b flex items-center justify-center `}
//               >
//                 PM
//               </div>
//             </div>
//           </div>
//         </div>
//       }
//     >
//       {time ? (
//         <button className="out-500-14 text-gray-500">{`${time}:${min} ${amPm}`}</button>
//       ) : (
//         <button className="out-500-14 text-gray-500">{placeholder}</button>
//       )}
//     </Popover>
//   );
// };

const TimePicker = ({ onChange, placeholder }) => {
  const [amPm, setAmPm] = useState("am");
  const [time, setTime] = useState("");
  const [min, setMin] = useState("00");

  const handleTimeChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setTime("");
      setMin("00");
    } else if (/^\d+$/.test(value) && value.length <= 2 && +value <= 12) {
      setTime(value);
    }
  };

  const handleMinuteChange = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setMin("");
    }
    if (/^\d+$/.test(value) && +value < 60) {
      setMin(value);
    }
  };

  const handleAmPmChange = (selectedAmPm) => {
    setAmPm(selectedAmPm);
  };

  return (
    <Popover
      trigger="click"
      onOpenChange={(val) => {
        if (!val) {
          const m = min ? (min === "60" ? "59" : min.padStart(2, "0")) : "00";
          setMin(m);
          onChange(`${time.padStart(2, "0")}:${m}:00 ${amPm}`);
          // dispatch(
          //   updateConversations({
          //     key: "chatRequest",
          //     value: {
          //       ...chatRequest,
          //       deadlineTime: `${time.padStart(2, "0")}:${m}:00 ${amPm}`,
          //     },
          //   })
          // );
        }
      }}
      content={
        <div className="p-2">
          <div className="flex">
            <Input
              type="number"
              min={1}
              max={12}
              value={time}
              placeholder="00"
              onChange={handleTimeChange}
              className="w-10 h-[25px] border-gray-50 focus:outline-none"
            />
            <span className="px-1 out-500-14">:</span>
            <Input
              type="number"
              min={0}
              max={59}
              value={min}
              placeholder="00"
              onChange={handleMinuteChange}
              className="w-10 h-[25px] border-gray-50 focus:outline-none"
            />
            <div className="w-[51px] flex h-[25px] items-center ml-2">
              <div
                onClick={() => handleAmPmChange("am")}
                className={`out-500-12 h-full w-[25px] ${
                  amPm === "am"
                    ? "bg-primary-600 border-primary-600 text-white"
                    : "text-gray-300 border-gray-300"
                } cursor-pointer rounded-l border-t border-l border-b flex items-center justify-center`}
              >
                AM
              </div>
              <div
                onClick={() => handleAmPmChange("pm")}
                className={`out-500-12 h-full w-[27px] cursor-pointer ${
                  amPm === "pm"
                    ? "bg-primary-600 border-4 border-primary-600 text-white"
                    : "text-gray-300 border-r border-gray-300"
                } rounded-r border-t border-b flex items-center justify-center`}
              >
                PM
              </div>
            </div>
          </div>
        </div>
      }
    >
      {time ? (
        <button className="out-500-14 text-gray-500">{`${time.padStart(
          2,
          "0"
        )}:${min} ${amPm}`}</button>
      ) : (
        <button className="out-500-14 text-gray-500">{placeholder}</button>
      )}
    </Popover>
  );
};

const NewEventModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const [eventName, setEventName] = useState("");
  const { newEventModal } = useAppSelector((state) => state.dashboard);
  const [isGoogleMeet, setIsGoogleMeet] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const { projectList } = useAppSelector((state) => state.projects);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const [selectedProject, setSelectedProject] = useState(null);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handelCreate = async () => {
    const usersToAttend = tenantMembers
      .filter((u) => selectedUsers.includes(u.id))
      .map((user) => {
        return {
          email: user.email,
          userId: user.id,
        };
      });

    usersToAttend.push({ email: user.email, userId: user.id });

    const payload = {
      projectId: selectedProject,

      name: eventName,
      isCompleted: 0,
      date: date.format("YYYY-MM-DD"),
      description: "",
      location: "Mumbai",
      startTime: dayjs(
        `${date.format("DD-MM-YYYY")},${startTime}`,
        "DD-MM-YYYY,hh:mm a"
      ),
      endTime: dayjs(
        `${date.format("DD-MM-YYYY")},${endTime}`,
        "DD-MM-YYYY,hh:mm a"
      ),
      clickAction: "/dashboard",
      // endTime: endTime,
      isGoogleMeet,
      attendees: usersToAttend,
    };

    console.log(payload, "eventnew");

    await dispatch(createEvent(payload));

    const getEvents = async () => {
      try {
        const { data: events } = await client.get(
          "/integration/calender/get-events",
          {
            params: {
              userId: user.id,
              date: dayjs(newEventModal.date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              ),
            },
          }
        );

        dispatch(
          updateDashboard({
            key: "events",
            value: events.result || [],
          })
        );
      } catch (e) {
        console.log(e);
      }
    };

    if (newEventModal.date) {
      getEvents();
    }

    onClose();

    // console.log(payload, "event");
  };
  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div>
            <h3 className="out-500-14  text-black">New Event</h3>
            <div className="mt-4">
              <CustomInputBox
                placeholder="Add event title"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
            </div>
            <div className="flex mt-4 items-center">
              <ClockIcon height="16" width="16" className="text-gray-500" />
              <div className="ml-4">
                <CustomDatePicker onChange={(date) => setDate(date)} />
              </div>
              <div className="mx-2 out-500-14 text-gray-500">•</div>
              <TimePicker
                onChange={(time) => setStartTime(time)}
                placeholder={"Start time"}
              />
              <div className="mx-1 out-500-14 text-gray-500">-</div>
              <TimePicker
                onChange={(time) => setEndTime(time)}
                placeholder={"End time"}
              />
            </div>
            <div className="mt-5">
              <div className="flex gap-x-3 items-center">
                <Checkbox className="bg-transaprent" />
                <div className="out-300-12 text-gray-700">All day</div>
              </div>
              {/* <div className="flex gap-x-3 items-center">
                <Checkbox />
                <div className="out-300-12 text-gray-700">Set a timezone</div>
              </div> */}
            </div>
            <div className="mt-5">
              <CustomButton
                text=" Add Google Meet video conferencing"
                width="278px"
                height="30px"
                icon={
                  <img
                    src={
                      isGoogleMeet
                        ? "/images/icons/check-white.svg"
                        : "/images/icons/plus.svg"
                    }
                    className="ml-2"
                  />
                }
                onClick={() => setIsGoogleMeet(!isGoogleMeet)}
              />
            </div>
            <div className="mt-4">
              <p className="out-300-14 text-gray-500 pb-4">Project</p>
              <CustomSelect
                placeholder="Associate a Project to the event"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e)}
                options={projectList.map((project) => {
                  return {
                    id: project.id,
                    value: project.id,
                    label: project.title,
                    render: () => (
                      <div className="flex items-center ">
                        <CustomAvatar
                          src={project.projectImage}
                          size={24}
                          title={project.title}
                          whiteText
                        />

                        <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                          {project.title}
                        </p>
                      </div>
                    ),
                  };
                })}
              />
            </div>
            <div className="mt-4">
              <p className="out-300-14 text-gray-500 pb-4">Invite people</p>
              <MultiSelect
                placeholder="Invite people to the event"
                options={tenantMembers
                  .filter(
                    (u) =>
                      selectedUsers?.indexOf(u.userId) === -1 &&
                      +u.userId !== +user.id
                  )
                  .map((user) => {
                    return {
                      id: user.userId,
                      value: user.userId,
                      label: `${user.firstname} ${user.lastname}`,
                      render: () => (
                        <div
                          onClick={() => {
                            setSelectedUsers((oldList) => [
                              ...oldList,
                              user.userId,
                            ]);
                          }}
                          className="flex items-center "
                        >
                          <CustomAvatar
                            src={user.profilePicture}
                            size={24}
                            title={`${user.firstname} ${user.lastname}`}
                            color={user.profilePictureColorCode}
                            whiteText
                          />

                          <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                            {user.firstname} {user.lastname}
                          </p>
                        </div>
                      ),
                    };
                  })}
              />
            </div>
            <div className="mt-2 max-h-[176px] overflow-y-scroll">
              <div className="flex py-[10px] justify-between px-3">
                <div className="flex items-center ">
                  <CustomAvatar
                    size={24}
                    src={user.profilePicture}
                    title={`${user.firstName} ${user.lastName}`}
                    color={user.profilePictureColorCode}
                    whiteText
                  />

                  <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                    {user.firstName} {user.lastName} (Organiser)
                  </p>
                </div>
              </div>
              {tenantMembers.map((user, i) => {
                if (selectedUsers?.indexOf(user.userId) !== -1) {
                  return (
                    <div
                      className="flex py-[10px] justify-between px-3"
                      key={user.userId + i}
                    >
                      <div className="flex items-center ">
                        <CustomAvatar
                          size={24}
                          src={user.profilePicture}
                          title={`${user.firstname} ${user.lastname}`}
                          color={user.profilePictureColorCode}
                          whiteText
                        />

                        <p className="out-300-12 pl-2 text-gray-900 capitalize ">
                          {user.firstname} {user.lastname}
                        </p>
                      </div>
                      <button
                        onClick={() =>
                          setSelectedUsers((oldList) => [
                            ...oldList.filter((old) => +old !== +user.userId),
                          ])
                        }
                      >
                        <CrossIcon className="text-gray-700" />
                      </button>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="flex mt-[33px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text={"Create"}
              height="30px"
              onClick={handelCreate}
              disabled={selectedUsers.length === 0}
            />
          </div>
        </div>
      }
    />
  );
};

export default NewEventModal;
