import React from "react";

const TickIcon = ({ size = 20, className = "text-primary-600" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.667"
          d="M16.665 5L7.5 14.167 3.332 10"
        ></path>
      </svg>
    </span>
  );
};

export default TickIcon;
