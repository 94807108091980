// useTenantAPI.ts
import { useQuery } from "react-query";
import { client } from "../../utils/axiosClient";

export const useGetTenantMembers = () => {
  return useQuery(
    "tenantMembers",
    async () => {
      const response = await client.get("/tenant/members");
      return response.data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

export const useGetTenantDetails = () => {
  return useQuery(
    "tenantDetails",
    async () => {
      const response = await client.get("/tenant/tenant-details");
      return response.data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};
