import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { Checkbox } from "antd";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { deleteEvent } from "../../../Core/redux/api/scheduleAPI";

const CancelEventModal = ({ visible, onClose, selectedEventId, onDelete }) => {
  const dispatch = useAppDispatch();
  const [sendUpdates, setSendUpdates] = useState(false);

  const handleCheckboxChange = (e) => {
    setSendUpdates(e.target.checked);
  };

  const handleCancelEvent = () => {
    const payload = {
      eventId: selectedEventId,
      sendUpdates: sendUpdates,
    };
    console.log("payload for delete", payload);
    dispatch(deleteEvent(payload))
      .then((response) => {
        console.log("Event deleted successfully", response);

        onDelete();
      })
      .catch((error) => {
        console.error("Error deleting event", error);
      });
  };

  return (
    <CustomModal
      visible={visible}
      width="423px"
      onCancel={onClose}
      body={
        <div className="h-[279px]  flex flex-col px-4  py-[15px] gap-y-[17px]">
          <div className="flex   justify-between items-center ">
            <div className="h-5 out-500-14 text-error-700">Cancel Event?</div>
            <div role="button" className="h-5 w-5" onClick={onClose}>
              <CrossIcon className={"text-gray-500 cursor-pointer"} />
            </div>
          </div>
          <div className="flex">
            <img
              src="/images/v3/calendar/FrostyThinking.svg"
              className="h-[108px]"
            />
          </div>
          <div className="h-[60px] out-300-14 flex text-gray-500">
            <p className="text-gray-500 text-sm font-light">
              Are you sure you want to cancel this event? We will update all the
              invitees via email.
            </p>
          </div>
          <div className="h-[30px]  flex items-center gap-x-2.5 cursor-pointer">
            <div className="flex gap-2 items-center">
              <Checkbox
                style={{
                  borderColor: "initial",
                  background: "initial",
                }}
                checked={sendUpdates}
                onChange={handleCheckboxChange}
              />
              <p className="text-slate-700 text-sm font-light">
                Send email to invitees
              </p>
            </div>

            <button
              className="out-500-14 text-gray-500 px-2.5 py-[5px]"
              onClick={onClose}
            >
              Back to editing
            </button>

            <button
              className="h-[30px] items-center justify-center out-500-14 text-slate-50 bg-error-700 py-[5px] px-2.5 rounded inline-flex "
              onClick={handleCancelEvent}
            >
              Cancel event
            </button>
          </div>
        </div>
      }
    />
  );
};

export default CancelEventModal;
