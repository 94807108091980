import React, { useEffect, useRef, useState } from "react";
import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  handleCompanyLocation,
  handleCompanyName,
  resetToInitialOnboarding,
} from "../../../Core/redux/slices/onboarding.slice";
import { changeStep } from "../../../Core/redux/slices/onboarding.slice";
import CurrentStep from "./CurrentStep";
import CustomAddressInput from "../../../Core/Common/CustomAddressInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { client } from "../../../Core/utils/axiosClient";
import StepProgressBar from "./StepProgressBar";
import map from "../../../Core/LottieFilesV2/map.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { CrossIconLarge } from "../../../Core/svgV2/CrossIcon";

const Content = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const [name, setName] = React.useState("");
  // const [locationName, setLocationName] = React.useState("");
  const { companyName, companyLocation } = useAppSelector(
    (state) => state.onboarding
  );
  const { tenant_id } = useAppSelector((state) => state.userDetails).user;

  const HandleCompanyDetails = (values) => {
    dispatch(handleCompanyName(values.companyName));
    dispatch(handleCompanyLocation(values.locationName));
  };
  const [showError, setShowError] = useState(false);
  // const [errorText, setErrorText] = useState("");

  const formik = useFormik({
    initialValues: {
      companyName: companyName,
      // stationName: null,
      locationName: companyLocation,
      locationSubName: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
      locationName: Yup.string()
        .required("Please type the address")
        .typeError("Please type the address"),
      locationSubName: Yup.string(),
      cords: Yup.object().shape({
        lat: Yup.number(),
        long: Yup.number(),
      }),
    }),

    onSubmit: async (values) => {
      const { data } = await client.get(
        "/onboarding/check-if-hostname-exists",
        { params: { tenant: values.companyName } }
      );
      console.log(data);
      if (data.exists) {
        formik.setFieldError("companyName", "Already Exist!");
      } else {
        HandleCompanyDetails(values);
        dispatch(changeStep(4));
      }
    },
  });

  // if (formik) {
  //   if (formik.touched["companyName"] && !!formik.errors["companyName"]) {
  //     setShowError(true);
  //   }
  //   if (formik.touched["locationName"] && formik.errors["locationName"]) {
  //     setErrorText("Please enter your company’s name and location to continue");
  //   }
  //   console.log(formik, name, showError, "formik");

  return (
    <div className="p-6 w-full h-screen bg-white">
      {tenant_id ? (
        <div
          className="ml-10 mt-10 flex items-center justify-center out-500-14 text-gray-500 gap-x-2 cursor-pointer absolute z-[100]"
          role="button"
          onClick={() => {
            dispatch(resetToInitialOnboarding({}));
            navigate(-1);
          }}
        >
          <CrossIconLarge className="text-gray-500" /> Cancel
        </div>
      ) : (
        ""
      )}
      <StepProgressBar currentStep={3} totalSteps={5} givenElementId="form3" />
      <div className="pl-14 w-full h-full bg-white flex justify-start items-center">
        <form
          autoComplete="off"
          // style={{ width: 520 }}
          onSubmit={formik.handleSubmit}
          id="formik"
        >
          <div>
            <CurrentStep
              currentStep={3}
              totalStep={5}
              className=""
              id="form3"
            />
            <h1 className="out-500-20 text-gray-900 mb-2 mt-6">
              Excellent! Let’s setup your company and acheive these goals
              together
            </h1>
            <div
              className={`flex justify-start items-center text-show-animation gap-x-2 out-400-16 ${
                showError ? "text-error-600" : "text-gray-500 "
              }`}
            >
              <img
                src="/images/icons/frosty-icon.svg"
                className={`min-h-[24px] min-w-[24px] `}
                alt="frosty"
              />
              {showError
                ? "Please enter your company’s name and location to continue"
                : "Let’s put you on the map!"}
            </div>
            <div>
              <div className="pt-4 gap-x-2">
                <div className="out-500-14 text-gray-900 mb-4">
                  Company’s Name
                </div>
                <CustomOnboardingInput
                  className="out-500-14 text-gray-900 py-1 pl-0.5"
                  placeholder="Type your answer here..."
                  name="companyName"
                  {...formik.getFieldProps("companyName")}
                  formikHook={formik}
                  onClear={() => {
                    formik.setFieldValue("companyName", "");
                  }}
                />
                <div className="w-[341px]"></div>
              </div>
              <div className="pt-10 mb-6 gap-x-2 ob-address-input">
                <div className="out-500-14 text-gray-900 mb-4">
                  Where are you based?
                </div>

                <CustomAddressInput
                  className="out-500-14 text-gray-900"
                  placeholder={
                    <div className="out-500-14 text-gray-400">
                      {" "}
                      City, Country
                    </div>
                  }
                  name="locationName"
                  // value={locationName}
                  {...formik.getFieldProps("locationName")}
                  onChange={(value) =>
                    formik.setFieldValue("locationName", value)
                  }
                  onSelect={({ locationName, locationSubName, lat, long }) => {
                    formik.setFieldValue("locationName", locationName);
                    formik.setFieldValue("locationSubName", locationSubName);
                    formik.setFieldValue("cords", { lat, long });
                  }}
                  formikHook={formik}
                />
                <div className=" w-[341px]"></div>
              </div>
              <div className="flex items-center gap-x-6">
                <CustomButton
                  type="submit"
                  className={`bg-primary-600 text-white ${
                    !formik.isValid ? "opacity-30" : ""
                  }`}
                  text="Continue"
                  height="30px"
                  width="76px"
                  // disabled={name === "" || locationName === "" ? true : false}
                />
                <CustomButton
                  className="bg-white border-gray-200 border-[1px]"
                  onClick={() => dispatch(changeStep(2))}
                  text="Back"
                  height="30px"
                  width="51px"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const lottieRef = useRef(null);
  console.log(lottieRef, "lottie");
  useEffect(() => {
    lottieRef?.current?.setSpeed(0.7);
  }, []);
  return (
    <div className="w-full h-full overflow-hidden relative flex justify-center items-center">
      <Lottie
        lottieRef={lottieRef}
        animationData={map}
        loop={true}
        style={{ height: "100%", width: "100%", background: "#D1E9FF" }}
      />
    </div>
  );
};

const MapSelection = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default MapSelection;
