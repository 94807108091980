import React, { isValidElement } from "react";
import { Avatar, Badge } from "antd";
import { hexToRgb } from "../utils/colorConverter";
import { useMemo } from "react";
import getImageWeServeUrl from "../utils/getImageWeServeUrl";
import { useAppSelector } from "../redux/hooks";

const CustomAvatar = ({
  title = "User",
  src,
  size,
  color,
  whiteText = false,
  fontSize = "14px",
  className = "",
  style,
  badge = false,
  userId = null,
  customBadge = null,
}: {
  title?: string;
  src?: string;
  size?: number;
  color?: string;
  whiteText?: boolean;
  fontSize?: string | number;
  className?: string;
  style?: React.CSSProperties;
  badge?: boolean;
  userId?: number | string | null | undefined;
  customBadge?: React.ReactNode;
}) => {
  const Colors = {
    Q: [66, 7, 231],
    W: [230, 98, 241],
    E: [249, 81, 211],
    R: [50, 193, 190],
    T: [20, 53, 108],
    Y: [199, 214, 159],
    U: [4, 239, 67],
    I: [188, 57, 47],
    O: [96, 148, 57],
    P: [81, 78, 16],
    L: [142, 102, 180],
    K: [157, 172, 221],
    J: [172, 243, 6],
    H: [203, 127, 88],
    G: [218, 197, 129],
    F: [234, 11, 170],
    D: [8, 151, 252],
    S: [35, 123, 149],
    A: [241, 12, 171],
    Z: [184, 144, 118],
    X: [215, 28, 200],
    C: [240, 189, 193],
    V: [245, 169, 26],
    B: [240, 229, 54],
    N: [111, 218, 98],
    M: [127, 32, 139],
  };

  // function randomColor() {
  //   const hex = Math.floor(Math.random() * 0xffffff);
  //   const color = "#" + hex.toString(16);

  //   return color;
  // }

  const getColor = (opacity = 1) => {
    if (color) {
      const rgb = hexToRgb(color, 0.1);
      return rgb;
    }
    if (initials) {
      if (whiteText && !color && Colors[initials[0]?.toUpperCase()]) {
        return `rgba(${Colors[initials[0].toUpperCase()].join(",")})`;
      }
      if (Colors[initials[0].toUpperCase()]) {
        return `rgba(${Colors[initials[0].toUpperCase()].join(
          ","
        )},${opacity})`;
      }

      return `rgba(${Colors["A"].join(",")},${opacity})`;
    }
  };

  //const randomColorMemo = useMemo(() => randomColor(), [title]);
  const initials = title
    ? title
        .toString()
        .toUpperCase()
        .split(" ")
        .filter((n) => n)
        .splice(0, 2)
        .map((n) => n[0])
        .join("")
    : "U";

  const textColor = color ? color : getColor();

  let showBadge = false;

  if (badge && userId) {
    const { activeUsers } = useAppSelector((state) => state.tenant);
    const user = activeUsers.find((a) => a.userId == userId);
    if (user) {
      showBadge = user.userPresence;
    }
  }

  if (customBadge && isValidElement(customBadge)) {
    showBadge = true;
  }

  return (
    <div
      className="relative flex justify-center items-center"
      style={{
        height: size,
        width: size,
      }}
    >
      <Avatar
        className={className}
        alt={title}
        src={src ? getImageWeServeUrl(src) : null}
        size={size}
        style={{
          backgroundColor: whiteText
            ? color
              ? color
              : getColor()
            : getColor(0.1),
          fontSize,
          fontWeight: 700,
          minWidth: `${size}px`,
          ...style,
        }}
      >
        <span
          style={{
            color: whiteText ? "#ffff" : textColor,
            height: size ? size * 0.5 : 16,
            width: size ? size * 0.5 : 16,
            minHeight: size ? size * 0.5 : 16,
            minWidth: size ? size * 0.5 : 16,
            fontFamily: "Outfit",
          }}
        >
          {initials}
        </span>
      </Avatar>
      {showBadge ? (
        customBadge ? (
          customBadge
        ) : (
          <div
            className="badge"
            style={{
              position: "absolute",
              height: `${size ? size * 0.3 : 5}px`,
              width: `${size ? size * 0.3 : 5}px`,
              borderRadius: "1000px",
              backgroundColor: "#039855",
              border: `${size ? size * 0.02 : 1}px solid white`,
              bottom: " 0%",
              right: "5%",
            }}
          />
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default React.memo(CustomAvatar);
