import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { ArrowIcon } from "../../../Core/svgV2/Arrow";
import { getPhaseColor } from "../../../Core/utils/getPhaseName";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { updateProjectOnboarding } from "../../../Core/redux/slices/projectOnboarding.slice";

const phaseDescriptionArray = [
  {
    id: 0,
    phaseName: "What are phases?",

    phaseDesc: `Phases are your project’s essential building blocks. Initially, you generate ideas ${` <span class="text-gray-700 out-500-14"> (Ideation) </span>`}, then craft the blueprint${` <span class="text-gray-700 out-500-14"> (Design) </span>`} , construct the project ${` <span class="text-gray-700 out-500-14"> (Development) </span>`} , release it to the world ${` <span class="text-gray-700 out-500-14"> (Deployment) </span>`} , and finally, maintain its functionality ${` <span class="text-gray-700 out-500-14"> (Maintenance) </span>`}. `,
    secondPhaseDesc:
      " These phases act like organised steps, ensuring a systematic and successful progression in your project. It's like assembling different pieces to complete a puzzle, each contributing to the overall picture of project success.",
  },
  {
    id: 1,
    phaseName: "Ideation",
    phaseDesc:
      "This phase marks the beginning of your project journey. Here, you'll delve into brainstorming sessions, exploring creative concepts, and refining ideas to shape the vision and scope of your project. ",
    secondPhaseDesc:
      "It's a time for innovation, where you'll generate various possibilities and lay the groundwork for what your project will ultimately become.",
  },
  {
    id: 2,
    phaseName: "Design",
    phaseDesc:
      "In the design phase, you'll take the ideas generated during ideation and begin to translate them into tangible plans and visual representations.",
    secondPhaseDesc:
      "      Here, you'll create wireframes, prototypes, and visual designs, carefully crafting the user interface and experience of your project.",
    thirdPhaseDesc:
      "It's about bringing your vision to life in a way that resonates with your audience and meets their needs.",
  },
  {
    id: 3,
    phaseName: "Development",
    phaseDesc:
      "With your designs finalized, you'll move into the development phase. This is where the real work begins, as you start building the actual project.",
    secondPhaseDesc:
      "Whether it's coding software, developing a website, or creating an application, this phase is all about turning your concepts into functional, working solutions.",
    thirdPhaseDesc:
      " You'll write code, integrate features, and bring your project to life one step at a time.",
  },
  {
    id: 4,
    phaseName: "Deployment",
    phaseDesc:
      "Once development is complete, your project is ready to go live. In the deployment phase, you'll prepare for the official launch, ensuring everything is set up correctly for a smooth transition to live operation. ",
    secondPhaseDesc:
      "This involves testing your project in real-world environments, configuring servers and infrastructure, and addressing any last-minute issues or concerns to ensure a successful launch.",
  },
  {
    id: 5,
    phaseName: "Maintenance",

    phaseDesc:
      "After your project is live, it enters the maintenance phase. Here, you'll provide ongoing support and updates to ensure it continues to run smoothly and remains relevant and effective over time. ",
    secondPhaseDesc:
      "You'll monitor performance, address any issues or bugs that arise, and make updates or improvements as needed to keep your project in top shape.",
  },
];
//todo add arrow icon
const PhaseBlock = ({ phase, onClick, step, position }) => {
  const phaseColor = getPhaseColor(phase.id);
  return (
    <div
      className={` h-7 flex px-2 py-1 gap-x-0.5 items-center rounded-[4px] cursor-pointer ${
        step === 0 ? "" : step - 1 === position ? "" : "opacity-40"
      }`}
      style={{ background: phaseColor }}
      onClick={onClick}
    >
      <p
        className={`out-500-14`}
        style={
          step === 0 || step - 1 === position
            ? {
                color: "white",
              }
            : {
                color: phaseColor,
              }
        }
      >
        {phase.phaseName}
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M2.5 6H9.5M9.5 6L6 2.5M9.5 6L6 9.5"
          stroke={step === 0 || step - 1 === position ? "#F9FBFE" : phaseColor}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
const PhasesDescriptionModal = () => {
  const { phasesDescriptionModal } = useAppSelector(
    (state) => state.projectOnboarding
  );
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);
  const [phaseDetails, setPhaseDetails] = useState(phaseDescriptionArray[step]);
  useEffect(() => setPhaseDetails(phaseDescriptionArray[step]), [step]);
  const handleClose = () => {
    dispatch(
      updateProjectOnboarding({
        key: "phasesDescriptionModal",
        value: false,
      })
    );
  };
  return (
    <CustomModal
      visible={phasesDescriptionModal}
      width="525px"
      height="542px"
      onCancel={handleClose}
      body={
        <div className="flex flex-col w-full justify-between py-6 h-[542px] bg-white relative rounded-2xl">
          <div className="flex flex-col py-4 justify-center items-center gap-y-6">
            <div className="w-[140px] h-[140px] ">
              <img src="/images/v3/onboarding/Frosty.svg" />
            </div>
            <div className="flex w-full gap-x-1 justify-center ">
              {phaseDescriptionArray.slice(1).map((phase, i) => (
                <PhaseBlock
                  phase={phase}
                  step={step}
                  position={i}
                  key={i}
                  onClick={() => setStep(i + 1)}
                />
              ))}
            </div>
            <div className="flex flex-col px-6 gap-y-1.5 ">
              <div className="flex  justify-center w-full">
                <p className=" out-500-24 text-gray-600">
                  {phaseDetails.phaseName}
                </p>
              </div>
              <div className="flex flex-col  w-full justify-between  gap-y-2 out-300-14 text-gray-700">
                <p
                  dangerouslySetInnerHTML={{ __html: phaseDetails.phaseDesc }}
                />
                <p className="">{phaseDetails.secondPhaseDesc}</p>
                <p className="">{phaseDetails.thirdPhaseDesc}</p>
              </div>
            </div>
          </div>
          <div
            className={`flex px-6 ${
              step === 0 ? "justify-end" : "justify-between"
            }  items-center`}
          >
            {step !== 0 && (
              <CustomButton
                text="Back"
                onClick={() => setStep(0)}
                className=" text-gray-700 bg-gray-100 out-500-14"
                height="30px"
                width="63px"
              />
            )}
            <CustomButton
              text="Close"
              onClick={handleClose}
              className=" text-gray-700 bg-gray-100 out-500-14"
              height="30px"
              width="67px"
            />
          </div>
          <CrossIcon
            className={"absolute top-4 right-4 text-gray-700 cursor-pointer"}
            onClick={handleClose}
          />
        </div>
      }
    />
  );
};

export default PhasesDescriptionModal;
