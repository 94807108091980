import React, { useEffect, useState } from "react";

const StepProgressBar = ({ currentStep, totalSteps, givenElementId }) => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  console.log(viewportHeight);

  const [offsetLeft, setOffsetLeft] = useState(0);
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    setOffsetLeft(
      document.getElementById(givenElementId)
        ? document.getElementById(givenElementId).getBoundingClientRect().left
        : 0
    );
    setOffsetTop(
      document.getElementById(givenElementId)
        ? document.getElementById(givenElementId).getBoundingClientRect().top
        : 0
    );
  }, [
    givenElementId,
    document.getElementById(givenElementId)
      ? document.getElementById(givenElementId).getBoundingClientRect().top
      : 0,
  ]);

  const step1Height = offsetTop;
  const step2Height = viewportHeight - offsetTop - 30;
  const step3Height = 30;

  console.log("i am re running", offsetTop);

  return (
    <>
      <style>
        {`
         .progress-line {
          position: fixed;
          left:${offsetLeft - 30}px;
          top: 0;
          bottom: 0;
          width: 4px;
          background: transparent;
        }
        .progress-line .step {
          position: absolute;
          width: 100%;
          display: flex;
          align-items: center;
        }
        .progress-line .step .number {
         
          left:${offsetLeft - 30}px;
          position: absolute;
          bottom:10px;
          width:100px;
        }
        .progress-line .step .box {
          position: absolute;
          top: -3px;
          left: -10px;
          border-radius: 2px;
          transform: matrix(0.26, -0.97, 0.96, 0.27, 0, 0);
        }
        .box-2{
          bottom:10px;
          position: absolute;
          left: -10px;
          border-radius: 2px;
          transform: matrix(0.26, -0.97, 0.96, 0.27, 0, 0);
        }
        .progress-line .step.top {
          top: 0;
        }
        .progress-line .step.middle {
          top: ${step1Height}px;
        }
        .progress-line .step.bottom {
          bottom: 0;
        }
        .given-element {
          position: relative;
        }
        .given-element::before {
          content: "";
          position: absolute;
          top: -16px;
          left: 0;
          right: 0;
          height: 8px;
          background: #555;
        }
       
        `}
      </style>
      <div
        style={{
          position: "absolute",
          height: "123px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          zIndex: "99",
          background:
            "linear-gradient(180deg, rgba(249, 251, 254, 0) 0%, #F9FBFE 110.57%)",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          height: "123px",
          left: "0px",
          right: "0px",
          top: "0px",
          zIndex: "99",
          background:
            "linear-gradient(180deg, rgba(249, 251, 254, 0) 0%, #F9FBFE 110.57%)",
          transform: "matrix(1, 0, 0, -1, 0, 0)",
        }}
      ></div>

      <div className="progress-line">
        {currentStep !== 1 && (
          <div
            className="step top"
            style={{
              height: step1Height,
              backgroundColor: "#165ABF",
            }}
          ></div>
        )}

        <div
          className="step middle"
          style={{
            height: step2Height,
            backgroundColor:
              currentStep !== totalSteps ? "#C0CBD7" : "transparent",
          }}
        >
          <div
            className="box w-[26px] h-[26px] max-h-[26px] max-w-[26px]  bg-primary-600"
            // style={{
            //   height: "26px",
            //   width: "26px",
            //   maxWidth: "26px",
            // }}
          ></div>
        </div>
        {currentStep !== totalSteps && (
          <div
            className="step bottom"
            style={{
              height: step3Height,
              backgroundColor: "#C0CBD7",
            }}
          >
            <div className="number">
              <p className="out-500-14 text-gray-900">
                Step {currentStep + 1} /
                <span className="out-500-14 text-gray-500">{totalSteps} </span>
              </p>
            </div>
            <div className="box-2 w-[26px] h-[26px] max-h-[26px] max-w-[26px] bg-[#C0CBD7]"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default StepProgressBar;
