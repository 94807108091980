import React from "react";
import Icon from "@ant-design/icons";

const PastRequestSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12 2.40039H4V12.8004H12V2.40039Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.6001 5.19922H10.4001"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M5.6001 7.59961H9.6001"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
);

const NewRequestSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12 8.00039V2.40039H4V12.8004H9.2"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.2 9.19922V12.3992" stroke="#344054" strokeLinecap="round" />
    <path d="M9.6001 10.8008H12.8001" stroke="#344054" strokeLinecap="round" />
    <path d="M5.6001 5.19922H10.4001" stroke="#344054" strokeLinecap="round" />
    <path d="M5.6001 7.59961H9.6001" stroke="#344054" strokeLinecap="round" />
  </svg>
);

const PastRequestIcon = (props) => {
  return <Icon component={PastRequestSvg} {...props} />;
};

const NewRequestIcon = (props) => {
  return <Icon component={NewRequestSvg} {...props} />;
};

export { PastRequestIcon, NewRequestIcon };
