import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getAllFolders = createAsyncThunk(
  "project/getAllFolders",
  async (
    payload: //   phaseId,
    {
      projectId?: string;
      folderId?: string;
      tenantId?: string;
      //   phaseId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/repository/folder`, {
        params: payload,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createFolder = createAsyncThunk(
  "project/createFolder",
  async (
    payload: {
      folderName: string;
      phaseId: string | number;
      projectId: string;
      userId: number;
      folderId?: string;
      notificationId?: string;
      tenantId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post(`/repository/folder`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const transferOwnershipController = createAsyncThunk(
  "project/transferOwnershipController",
  async (
    payload: {
      newUserId;
      docId;
      docType;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put("/repository/folder", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError(err));
    }
  }
);

export const updateVaultFolder = createAsyncThunk(
  "project/updateVaultFolder",
  async (
    payload: {
      folderName;
      folderId;
      userId;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put("/repository/folder", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError(err));
    }
  }
);

export const createRequest = createAsyncThunk(
  "vault/requestAccess",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(`/repository/request`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const rejectRequest = createAsyncThunk(
  "vault/rejectRequest",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(
        `/notifications/reject-request`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const deleteFolder = createAsyncThunk(
  "project/deleteFolder",
  async (
    {
      folderId,
      userId,
    }: {
      folderId: string;
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.delete(`/repository/folder`, {
        params: {
          folderId,
          userId,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getAllFiles = createAsyncThunk(
  "project/getAllFiles",
  async (
    payload: //   phaseId,
    {
      projectId?: string;
      folderId?: string;
      tenantId?: string | null;
      //   phaseId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/repository/all-files`, {
        params: payload,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createFile = createAsyncThunk(
  "project/createFile",
  async (
    {
      phaseId,
      // shareWith,
      goalId,
      files,
      projectId,
      folderId,
      users,
      notificationId,
      tenantId,
    }: {
      phaseId: string | null;
      // shareWith: string;
      files: any[] | null;
      goalId: string | null;
      projectId: string | null;
      folderId: string | null;
      users: any[];
      notificationId?: string;
      tenantId?: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post(`/repository/files`, {
        phaseId,
        // shareWith,
        goalId,
        files,
        projectId,
        folderId,
        users,
        notificationId,
        tenantId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateVaultFile = createAsyncThunk(
  "project/updateVaultFile",
  async (
    payload: {
      name;
      fileId;
      userId;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put("/repository/files", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError(err));
    }
  }
);

export const copyFolderAPI = createAsyncThunk(
  "vault/copyFolder",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(`/repository/copy-folder`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const copyFileAPI = createAsyncThunk(
  "vault/copyFile",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(`/repository/copy-file`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const copyLinkAPI = createAsyncThunk(
  "vault/copyLink",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(`/repository/copy-link`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const deleteFile = createAsyncThunk(
  "project/deleteFile",
  async (
    {
      fileIds,
    }: {
      fileIds: any[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.delete(`/repository/files`, {
        params: {
          fileIds,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getAllLinks = createAsyncThunk(
  "project/getAllLinks",
  async (
    payload: //   phaseId,
    {
      projectId?: string;
      folderid?: string;
      tenantId?: string | null;
      //   phaseId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/repository/get-all-links`, {
        params: payload,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createLink = createAsyncThunk(
  "project/createLink",
  async (
    {
      updateLink,
      linkId,
      linkName,
      description,
      url,
      username,
      password,
      phaseId,
      projectId,
      senderId,
      shareWith,
      folderId,
      userId,
      notificationId,
      goalId,
      users,
      tenantId,
    }: {
      updateLink: boolean;
      linkId: string | null;
      linkName: string | null;
      description: string | null;
      url: string | null;
      username: string | null;
      password: string | null;
      phaseId: string | null;
      projectId: string | null;
      senderId: string | null;
      shareWith: string | null;
      folderId: string | null;
      goalId: string | null;
      userId: string | null;
      notificationId: string | null;
      users: any[];
      tenantId: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const payload = {
        linkName,
        description,
        url,
        username,
        password,
        phaseId,
        projectId,
        senderId,
        shareWith,
        folderId,
        userId,
        notificationId,
        goalId,
        users,
        tenantId,
      };

      let response;
      if (updateLink) {
        response = await client.put(`/repository/link`, { linkId, ...payload });
      } else {
        response = await client.post(`/repository/link`, payload);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateVaultLink = createAsyncThunk(
  "project/updateVaultLink",
  async (
    payload: {
      linkName;
      linkId;
      userId;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put("/repository/link", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(getError(err));
    }
  }
);

export const deleteLink = createAsyncThunk(
  "project/deleteLink",
  async (
    {
      userId,
      links,
    }: {
      userId: number;
      links: any[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.delete(`/repository/link`, {
        params: {
          userId,
          links,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const shareAccess = createAsyncThunk(
  "project/shareAccess",
  async (
    {
      docId,
      users,
      projectId,
      name,
      docType,
      notificationId,
    }: {
      docId: number;
      users: any[];
      projectId: number;
      name: string;
      docType: string;
      notificationId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(`/repository/share-access`, {
        docId,
        users,
        projectId,
        name,
        docType,
        notificationId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
