import React from "react";
import Icon from "@ant-design/icons";

const FrostyTImeEstimatesSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="141" height="108" viewBox="0 0 141 108" fill="none">
        <g clipPath="url(#clip0_6588_16124)">
            <path opacity="0.672265" d="M97.9238 95.4933L140.933 87.8483L136.759 95.0291L97.3965 102.389L97.9238 95.4933Z" fill="#CCE9FF"/>
            <path opacity="0.672265" d="M58.9453 101.544L90.1066 92.2516L89.9381 98.8016L59.1978 107.968L58.9453 101.544Z" fill="#9EDBFF"/>
            <path opacity="0.672265" d="M90.1066 92.2516L97.9238 95.4933L97.3965 102.389L89.938 98.8016L90.1066 92.2516Z" fill="#9EDBFF"/>
            <path opacity="0.672265" d="M0.0380859 86.3175L22.7985 91.1483L16.4868 94.2508L4.5647 91.5158L0.0380859 86.3175Z" fill="#9EDBFF"/>
            <path opacity="0.672265" d="M12.3789 96.3691L58.9449 101.544L59.1975 107.968L19.599 102.389L12.3789 96.3691Z" fill="#CCE9FF"/>
            <path opacity="0.672265" d="M22.7985 91.1483L0.0380859 86.3175L26.7315 76.5016L116.739 73.0608L140.933 87.8483L97.9237 95.4933L90.1064 92.2516L58.9451 101.544L14.9151 96.6508L12.3791 96.3691L22.7985 91.1483Z" fill="#EDF8FF"/>
            <path opacity="0.672265" d="M89.0718 79.2441L140.933 87.8483L127.189 90.2908L85.7319 79.2441H89.0718Z" fill="#CCE9FF"/>
            <path opacity="0.672265" d="M61.4018 84.7675L90.1067 92.2516L58.9453 101.544L46.3965 84.7675H61.4018Z" fill="#CCE9FF"/>
            <path opacity="0.672265" d="M90.1066 92.2516L91.1886 98.3633L97.3965 102.389L89.938 98.8016L90.1066 92.2516Z" fill="#66C8FF"/>
            <path opacity="0.672265" d="M87.402 96.3691L90.1066 92.2516L89.938 98.8016L67.6113 105.459L87.402 96.3691Z" fill="#66C8FF"/>
            <path opacity="0.672265" d="M13.3202 92.2516L22.7988 91.1483L16.5788 94.265L4.56494 91.5158L13.3202 92.2516Z" fill="#66C8FF"/>
            <path opacity="0.672265" d="M127.189 90.2908L140.933 87.8483L136.759 95.0291L102.262 101.48L130.663 93.1558L127.189 90.2908Z" fill="#9EDBFF"/>
            <path opacity="0.672265" d="M46.3963 104.064L58.9451 101.544L59.1976 107.968L25.0498 103.158L46.3963 104.064Z" fill="#9EDBFF"/>
            <path opacity="0.672265" d="M14.1797 96.5691L27.6312 98.0641L16.1119 97.9416L20.3865 102.5L14.1797 96.5691Z" fill="white"/>
            <path opacity="0.672265" d="M18.0669 97.0016L35.6466 98.9549L58.9451 101.544L38.9724 101.038L18.0669 97.0016Z" fill="white"/>
            <path opacity="0.672265" d="M97.9238 95.4934L107.86 93.7267L99.1636 96.3692L97.3965 102.389L97.9238 95.4934Z" fill="white"/>
            <path opacity="0.672265" d="M93.104 93.495L97.9236 95.4933L97.3963 102.389V96.3692L93.104 93.495Z" fill="white"/>
            <path d="M89.0722 38.5842V79.2442C89.0722 79.2442 88.8364 79.845 87.3625 79.8558C86.146 79.8658 85.7324 79.2442 85.7324 79.2442V77.8567" fill="#0A93DD"/>
            <path d="M85.7322 78.6017V1.22284C85.7322 1.22284 85.6658 0.0319253 87.501 0.0249253C89.0928 0.018842 89.0721 1.22284 89.0721 1.22284V39.644" fill="#24B2FF"/>
            <path opacity="0.427127" d="M73.7744 12.4996C73.7744 12.4996 75.0366 16.207 81.8665 16.8227C92.8584 17.8136 96.3377 17.3833 96.3377 17.3833L74.5259 20.0805L73.7744 12.4996Z" fill="#9EDBFF"/>
            <path d="M73.7744 12.4996L98.8829 6.4314L106.697 11.1523L100.864 16.8236L91.7448 17.9513L74.5259 20.0805L73.7744 12.4996Z" fill="#DBFFE3"/>
            <path d="M74.5259 20.0805L100.864 16.8236L102.262 16.9748L75.4509 20.3183L74.5259 20.0805Z" fill="#BBEDC6"/>
            <path d="M106.697 11.1523L107.86 11.1853L102.262 16.9748L100.864 16.8236L106.697 11.1523Z" fill="#BBEDC6"/>
            <path d="M99.9332 6.4314L107.86 11.1852L106.697 11.1523L98.8828 6.4314H99.9332Z" fill="#EDFEF1"/>
            <path d="M101.697 28.4943L76.5889 22.426L68.7749 27.1469L74.6076 32.8182L83.7269 33.9459L100.946 36.0751L101.697 28.4943Z" fill="#FFDBDB"/>
            <path d="M100.946 36.0752L74.6075 32.8182L73.21 32.9694L100.021 36.3129L100.946 36.0752Z" fill="#FAC3C3"/>
            <path d="M68.7747 27.147L67.6113 27.1799L73.2098 32.9694L74.6073 32.8182L68.7747 27.147Z" fill="#FAC3C3"/>
            <path d="M75.5382 22.426L67.6113 27.1799L68.7747 27.1469L76.5887 22.426H75.5382Z" fill="#FEE7E7"/>
            <path d="M40.4634 88.945C40.4634 87.4942 47.5486 86.3175 56.2887 86.3175C65.0287 86.3175 72.1141 87.4942 72.1141 88.945C72.1141 90.3967 65.0287 91.5725 56.2887 91.5725C47.5486 91.5725 40.4634 90.3967 40.4634 88.945Z" fill="#DBE5EE"/>
            <path d="M53.3416 34.9948C54.6003 32.4657 57.8986 31.9933 60.0151 32.6138C61.7351 33.118 62.4521 34.5469 62.4521 34.5469L65.4293 38.2437L66.1109 40.3215C66.1109 40.3215 65.8948 40.8368 68.6144 45.3089C72.4417 51.6041 73.8483 56.7358 74.3466 62.3416C75.4452 74.6899 70.4861 81.0333 70.4861 81.0333C70.4861 81.0333 68.7298 83.0641 66.8132 84.7675C64.8968 86.4716 62.82 87.8483 62.82 87.8483H60.5782C60.5782 87.8483 58.7342 86.7675 57.8732 85.7825C56.8774 84.6425 56.289 83.2725 56.289 83.2725C56.289 83.2725 54.8716 85.1733 53.5242 86.3175C52.1768 87.4616 50.8992 87.8483 50.8992 87.8483L48.7077 87.8575C48.7077 87.8575 42.9356 86.3175 40.975 78.4125C40.5235 76.5933 39.5917 75.5591 39.4646 73.7183C39.1957 69.8275 38.1793 66.8133 39.2898 62.7249C40.8594 56.9466 43.9161 52.3425 46.9833 49.5766C50.7914 46.144 54.5362 45.271 54.5362 45.271C54.5362 45.271 57.7775 45.0007 58.3349 44.4042C58.8922 43.8078 56.7657 42.8852 56.7657 42.8852C56.7657 42.8852 54.9598 42.4445 53.6139 40.526C52.2789 38.623 52.3438 36.9997 53.3416 34.9948Z" fill="white"/>
            <path opacity="0.923077" d="M53.3415 34.9948C54.6 32.529 57.9375 32.0353 60.015 32.6138C61.8042 33.1119 62.4521 34.5469 62.4521 34.5469L62.5617 34.8663C62.5617 34.8663 60.9296 33.3302 58.9452 33.2196C57.3876 33.1329 54.8071 34.0868 54.0112 36.2575C52.6052 40.0925 57.75 42.3802 57.75 42.3802C57.75 42.3802 59.986 43.896 59.5161 44.7004C58.9452 45.678 58.1767 45.6207 55.0188 46.5559C49.0424 48.3258 44.8299 52.9908 42.0783 62.5724C39.3267 72.1541 44.1679 84.7674 44.1679 84.7674C44.1679 84.7674 34.4459 71.7858 40.9282 57.9499C43.4678 52.5291 47.0186 49.4683 49.7002 47.3864C52.504 45.2095 54.5362 45.2709 54.5362 45.2709C54.5362 45.2709 57.8595 44.9218 58.3865 44.3275C58.7618 43.9043 56.7656 42.8852 56.7656 42.8852C56.7656 42.8852 50.5393 40.4854 53.3415 34.9948Z" fill="#EFECDF"/>
            <path opacity="0.990385" d="M72.7099 64.9891C72.7099 60.3991 70.5317 49.4883 67.6113 45.3088C66.7976 44.1446 66.1108 41.7313 66.1108 40.5334C66.1108 39.5014 66.5315 42.3318 68.6144 45.3088C71.4709 49.3933 75.2816 56.5574 75.4427 63.1708C75.691 73.4049 70.4861 81.0333 70.4861 81.0333C70.4861 81.0333 72.7099 75.2266 72.7099 64.9891Z" fill="#EFECDF"/>
            <path d="M52.8162 30.4104C59.0438 26.9698 63.8237 33.1599 63.8237 33.1599L62.4519 34.5469C62.4519 34.5469 61.7505 33.1676 60.0148 32.6138C57.9746 31.9627 54.6102 32.5916 53.3413 34.9948C50.4934 40.3885 56.7654 42.8852 56.7654 42.8852C56.7654 42.8852 58.1548 43.4614 58.3768 44.0257C58.6417 44.6986 57.5222 44.752 54.536 45.271C49.7751 46.0984 44.4817 51.2375 41.6602 56.4816C37.9063 63.4591 38.5059 69.6158 39.3053 73.0608C40.9082 79.9691 44.1677 84.7674 44.1677 84.7674C44.1677 84.7674 38.7032 80.5075 36.9699 73.0608C35.2365 65.6149 36.7413 61.1966 38.5059 55.9383C39.7278 52.2966 47.007 45.8477 47.2732 43.6834C47.4559 42.1989 45.329 34.5469 52.8162 30.4104Z" fill="#190330"/>
            <path opacity="0.996919" d="M68.6143 45.3088C68.6143 45.3088 76.4083 54.2683 76.7977 66.5183C77.0776 75.3399 70.486 81.0333 70.486 81.0333C70.486 81.0333 74.5258 75.0691 74.5258 66.2433C74.5258 53.2124 68.6143 45.3088 68.6143 45.3088Z" fill="#190330"/>
            <path d="M15.0948 60.2216C36.486 57.0941 46.5358 45.2095 46.5358 45.2095L40.0945 57.0682L37.28 66.2399C37.28 66.2399 28.8026 66.529 25.7424 66.0374C21.9746 65.4324 9.34812 61.0616 15.0948 60.2216Z" fill="url(#paint0_linear_6588_16124)"/>
            <path d="M69.5869 38.6013C74.526 37.9292 81.17 43.257 82.3948 51.5575C83.3257 57.8667 75.4428 63.1708 75.4428 63.1708L74.2512 54.9417L70.0702 47.2301C70.0702 47.2301 74.9877 51.8067 75.4428 49.695C76.8063 43.3651 67.6222 38.8687 69.5869 38.6013Z" fill="url(#paint1_linear_6588_16124)"/>
            <path d="M58.1897 35.0963C57.3364 35.0963 56.6431 35.7525 56.6431 36.5599C56.6431 37.1961 57.0762 37.7314 57.6758 37.9323C57.6649 37.8619 57.6525 37.792 57.6525 37.7187C57.6525 36.9113 58.3432 36.2578 59.1965 36.2578C59.3775 36.2578 59.5489 36.2922 59.7103 36.3463C59.601 35.6403 58.9662 35.0963 58.1897 35.0963Z" fill="#190330"/>
            <path opacity="0.994231" d="M57.8593 39.284C57.5839 38.8768 57.8468 38.2157 58.4465 37.8072C59.0461 37.3986 59.7555 37.3975 60.031 37.8047C60.3063 38.2117 60.0435 38.873 59.4438 39.2815C58.8441 39.69 58.1347 39.6912 57.8593 39.284Z" fill="#FFF2F6"/>
            <path d="M56.2891 83.2724L57.75 81.4441C57.75 81.4441 57.673 83.8891 58.5892 85.4899C59.5054 87.0916 61.4149 87.8483 61.4149 87.8483H60.5783C60.5783 87.8483 58.9456 86.9266 57.8733 85.7824C56.801 84.6383 56.2891 83.2724 56.2891 83.2724Z" fill="#C9BDAA"/>
            <path d="M56.8433 82.5791L57.75 81.4441C57.75 81.4441 57.8778 83.9933 58.852 85.5941C59.8261 87.1949 61.4149 87.8483 61.4149 87.8483H60.8123C60.8123 87.8483 59.3708 86.9208 58.3568 85.6008C57.3428 84.2808 56.8433 82.5791 56.8433 82.5791Z" fill="#EFECDF"/>
            <path opacity="0.982908" d="M63.8237 33.1599C63.8237 33.1599 70.1314 33.3757 71.8694 33.2017C74.6105 32.9272 68.2514 36.5142 66.8836 38.2217C65.9917 39.335 66.1488 40.32 66.1488 40.32C66.1488 40.32 65.5903 39.2362 63.3557 37.1807C60.7577 34.7905 63.8237 33.1599 63.8237 33.1599Z" fill="#E2270C"/>
            <path d="M63.8238 33.1599C63.8238 33.1599 70.1314 33.3757 71.8694 33.2017C74.6106 32.9272 68.1659 35.2585 66.4507 36.74C64.9504 38.0356 64.134 37.9222 64.134 37.9222C64.134 37.9222 62.5566 36.975 62.3111 35.2619C62.155 34.1718 63.8238 33.1599 63.8238 33.1599Z" fill="#FF4100"/>
            <path d="M61.9291 87.3033V89.4025C61.2922 89.4025 60.6538 89.4183 60.0168 89.3983C59.4851 89.3816 58.9543 89.3291 58.4248 89.2775C58.2829 89.2633 58.1382 89.2125 58.008 89.1508C57.7067 89.0075 57.665 88.76 57.9056 88.5233C58.0161 88.4158 58.1499 88.3208 58.2895 88.2583C58.5272 88.1508 58.7743 88.0608 59.0237 87.9866C59.5927 87.8183 60.1667 87.6658 60.7357 87.4991C60.7875 87.4841 60.8395 87.4691 60.8914 87.4533C61.238 87.3508 61.5832 87.2975 61.9291 87.3033Z" fill="#FF4100"/>
            <path d="M65.6661 89.1775C65.2968 89.345 64.896 89.3825 64.5012 89.39C63.6441 89.4067 62.787 89.395 61.9292 89.395V87.3033C62.2086 87.3075 62.4894 87.35 62.7724 87.4375C63.0415 87.52 63.3135 87.595 63.5856 87.6692C63.9585 87.7717 64.3323 87.8708 64.7023 87.9808C64.9815 88.0633 65.2507 88.1825 65.5176 88.3C65.62 88.345 65.7136 88.4233 65.7933 88.5042C66.0478 88.7592 65.9981 89.0283 65.6661 89.1775Z" fill="#E2270C"/>
            <path d="M50.0902 87.3033V89.4025C49.4533 89.4025 48.8149 89.4183 48.178 89.3983C47.6463 89.3816 47.1154 89.3291 46.586 89.2775C46.4441 89.2633 46.2993 89.2125 46.1691 89.1508C45.8678 89.0075 45.8262 88.76 46.0667 88.5233C46.1773 88.4158 46.311 88.3208 46.4507 88.2583C46.6884 88.1508 46.9355 88.0608 47.1849 87.9866C47.7538 87.8183 48.3279 87.6658 48.8969 87.4991C48.9487 87.4841 49.0007 87.4691 49.0526 87.4533C49.3992 87.3508 49.7444 87.2975 50.0902 87.3033Z" fill="#FF4100"/>
            <path d="M53.8272 89.1775C53.458 89.345 53.0571 89.3825 52.6622 89.39C51.8052 89.4067 50.9481 89.395 50.0903 89.395V87.3033C50.3698 87.3075 50.6505 87.35 50.9335 87.4375C51.2026 87.52 51.4747 87.595 51.7467 87.6692C52.1196 87.7717 52.4934 87.8708 52.8634 87.9808C53.1427 88.0633 53.4118 88.1825 53.6788 88.3C53.7812 88.345 53.8747 88.4233 53.9545 88.5042C54.209 88.7592 54.1592 89.0283 53.8272 89.1775Z" fill="#E2270C"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_6588_16124" x1="10.0699" y1="62.5249" x2="36.3421" y2="56.2222" gradientUnits="userSpaceOnUse">
            <stop stopColor="#381A66"/>
            <stop offset="1" stopColor="#190330"/>
            </linearGradient>
            <linearGradient id="paint1_linear_6588_16124" x1="71.2219" y1="36.6809" x2="79.3673" y2="46.7923" gradientUnits="userSpaceOnUse">
            <stop stopColor="#381A66"/>
            <stop offset="1" stopColor="#190330"/>
            </linearGradient>
            <clipPath id="clip0_6588_16124">
            <rect width="141" height="108" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const FrostyTImeEstimatesIcon = (props) => (
    <Icon component={FrostyTImeEstimatesSvg} {...props} />
  );
  
export default FrostyTImeEstimatesIcon