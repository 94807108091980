import { createSlice } from "@reduxjs/toolkit";

import { getComments } from "../api/conversationAPI";
import { userAuthenticate } from "../api/userAPI";
import {
  getAllFolders,
  updateVaultFolder,
  copyFolderAPI,
  copyFileAPI,
  copyLinkAPI,
  updateVaultFile,
  updateVaultLink,
} from "../api/vaultAPI";
import { getAllFiles } from "../api/vaultAPI";
import { getAllLinks } from "../api/vaultAPI";

//We do not recommend the object notation for extraReducers any more, especially with TypeScript you should be using the builder notation --by redux toolkit team.

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  addFileModal: false,
  addFolderModal: false,
  preFilledVaultModalValues: {
    goalId: null,
    phaseId: null,
    users: [],
    notificationId: "",
  },
  addLinkModal: false,
  openCommentsModal: {
    visible: false,
    docType: "file",
    docId: "",
    name: "",
    comments: [],
    users: [],
  },
  deleteFolderModal: {
    visible: false,
    id: null,
  },
  deleteFileModal: {
    visible: false,
    id: null,
  },
  deleteLinkModal: {
    visible: false,
    id: null,
  },
  copyModal: {
    visible: false,
    docType: "",
    id: null,
    title: "",
  },
  moveToFolderModal: {
    visible: false,
    docId: "",
    docType: "",
  },
  requestAccessModal: {
    visible: false,
    title: "",
    subtext: "",
    data: {
      isProject: true,
      type: "folder",
      folder: { id: "", createdById: "", folderName: "" },
      file: { id: "", createdById: "", name: "" },
      link: { id: "", createdById: "", name: "" },
    },
  },
  requestUploadModal: false,
  downloadModal: {
    visible: false,
    type: "",
    id: null,
  },
  openLinkModal: {
    visible: false,
    id: null,
  },
  folders: [],
  subfolders: [],
  files: [],
  links: [],
  shareAccessDoc: {
    docId: "",
    users: [],
    docType: "",
    docDetails: {
      folderName: "",
      fileName: "",
      linkName: "",
      name: "",
    },
    notificationId: "",
  },
  showDocDetailsDrawer: {
    visible: false,
    docId: "",
    docType: "",
    docDetails: {},
  },
  transferOwnershipModal: {
    visible: false,
    id: null,
    docType: null,
    usersWithAccess: [],
  },

  openAndShareAccessFile: {
    visible: false,
    id: "",
  },

  mySpaceFolder: null,

  // V2

  newFolderModal: false,
  newLinkModal: false,
  editLinkModal: {
    visible: false,
    linkName: "",
    url: "",
    username: "",
    password: "",
    description: "",
    linkId: "",
  },
  renameModal: {
    visible: false,
    currentName: "",
    type: "folder",
    id: "",
  },
  viewDetailsDrawer: {
    visible: false,
    documentType: "",
    document: {
      title: "",
      createdAt: "",
      usersWithAccess: [],
      fileSize: "",
      filesCount: "",
      docId: "",
      createdById: "",
      mimeType: "",
      folderSize: "",
    },
    title: "",
  },
  commentsDrawer: {
    visible: false,
    title: "",
  },

  shareModal: {
    visible: false,
    documentType: "",
    title: "",
  },

  shareAccessModal: {
    visible: false,
    docId: "",
    users: [],
    docType: "",
    isGlobal: false,
    docDetails: {
      folderName: "",
      fileName: "",
      linkName: "",
      name: "",
    },
    notificationId: "",
  },

  currentSelectedProject: { title: "", projectImage: "" },

  shareInMessageModal: {
    type: "folder",
    visible: false,
    url: "",
    file: [],
  },

  viewType: 1,
  search: "",
  currentSelectedFolder: {
    id: "0",
    folderName: "Test",
    canEdit: 1,
    canView: 1,
    isOwner: 1,
  },
};

export const vaultSlice = createSlice({
  name: "vault",
  initialState,
  reducers: {
    addDummyDataForVaultScreen: (state, action) => {
      const dummyState = action.payload;
      Object.keys(dummyState).forEach((key) => (state[key] = dummyState[key]));
    },
    resetToInitialVault: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    updateVault: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },

    resetVault: (state, action) => {
      action.payload.map((ele) => {
        state[ele] = initialState[ele];
      });
    },
    // setUser: (state, action) => {
    //   state.user = action.payload;
    //   state.loading = false;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFolders.pending, (state, { payload }) => {
      state.folders = [];
      state.subfolders = [];
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getAllFolders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.folders = payload.result;
      state.currentSelectedFolder = payload.currentFolder;
      state.error = null;
    });

    builder.addCase(getAllFolders.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getAllFiles.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
      state.files = [];
    });
    builder.addCase(getAllFiles.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.files = payload.result;
      state.error = null;
    });
    builder.addCase(getAllFiles.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getAllLinks.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getAllLinks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.links = payload.result;
      state.error = null;
    });
    builder.addCase(getAllLinks.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getComments.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getComments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.openCommentsModal = {
        ...state.openCommentsModal,
        comments: payload.result,
      };
      state.error = null;
    });
    builder.addCase(getComments.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(updateVaultFolder.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateVaultFolder.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      const resultFolder = payload.result[0];
      state.folders = state.folders.map((folder) => {
        if (folder.id === resultFolder.id) {
          return { ...folder, ...resultFolder };
        }
        return folder;
      });
      state.error = null;
    });
    builder.addCase(updateVaultFolder.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(updateVaultFile.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateVaultFile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      const resultFile = payload.result[0];
      state.files = state.files.map((file) => {
        if (file.id === resultFile.id) {
          return { ...file, ...resultFile };
        }
        return file;
      });
      state.error = null;
    });
    builder.addCase(updateVaultFile.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(updateVaultLink.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateVaultLink.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      const resultLink = payload.result[0];
      state.links = state.links.map((link) => {
        if (link.id === resultLink.id) {
          return { ...link, ...resultLink };
        }
        return link;
      });
      state.error = null;
    });
    builder.addCase(updateVaultLink.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(copyFolderAPI.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(copyFolderAPI.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(copyFolderAPI.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(copyFileAPI.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(copyFileAPI.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(copyFileAPI.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(copyLinkAPI.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(copyLinkAPI.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(copyLinkAPI.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
  },
});

export const {
  updateVault,
  resetVault,
  resetToInitialVault,
  addDummyDataForVaultScreen,
} = vaultSlice.actions;
export default vaultSlice.reducer;
