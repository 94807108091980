import React from "react";
// import { hexToRgb } from "../utils/colorConverter";

type CustomBadgeProps = {
  color: string;
  text: string;
  dot?: boolean;
  className?: string;
  bgColor?: string;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  dotColor?: string;
  fontWeight?: string; // New prop for font weight
};

const CustomBadge = ({
  color,
  dotColor,
  text,
  dot = false,
  className,
  icon,
  style,
  fontWeight = "300", // Default font weight
}: CustomBadgeProps) => {
  // const backgroundColor = hexToRgb(color, 0.1);

  return (
    <div
      className={`flex justify-center items-center h-[22px] px-2 max-w-max rounded-2xl ${className}`}
      style={style}
    >
      {dot && (
        <div
          style={{
            height: "6px",
            width: "6px",
            backgroundColor: dotColor ? dotColor : color,
            borderRadius: "50%",
          }}
        />
      )}
      <p
        className={`out-${fontWeight}-12 mt-[0px] max-w-[10vw] truncate capitalize`} // Use the fontWeight prop
        style={{ color, marginLeft: dot ? "7px" : "0px" }}
      >
        {text}
      </p>
      {icon && (
        <div className="ml-1" style={{ color }}>
          {icon}
        </div>
      )}
    </div>
  );
};

export default CustomBadge;
