import React from "react";
import { useDroppable } from "@dnd-kit/core";

const DropTarget = ({ id, children }) => {
  const { setNodeRef } = useDroppable({
    id: id,
  });

  return <div ref={setNodeRef}> {children}</div>;
};

export default DropTarget;
