import React, { useEffect, useState } from "react";
import ScheduleLayout from "../Components/ScheduleLayout";
import { Checkbox, Dropdown, Popover } from "antd";

import InfoByFrosty from "../../../Core/CommonV2/InfoByFrosty";
import NewLabelModal from "../Modals/NewLabelModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
import ManageLabelsModal from "../Modals/ManageLabelsModal";
import DeleteLabelModal from "../Modals/DeleteLabelModal";
import NewReleaseVersionModal from "../Modals/NewReleaseVersionModal";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import AttachmentsAndSubtask from "./AttachmentsandSubtask";
import { client } from "../../../Core/utils/axiosClient";
import { getComments } from "../../../Core/redux/api/conversationAPI";
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  TASK_ACTIVITY_LOG_ACTIONS,
} from "../../../Core/constants";

import { CopyIcon, CopyLinkIcon } from "../../../Core/svgV2/CopyIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import copyTextToClipboard from "../../../Core/utils/clipboard";
import DeleteTaskModal from "../Modals/DeleteTaskModal";

import FilePreviweModal from "../../Conversations/Modals/FIlePreviweModal";
import { resetConversations } from "../../../Core/redux/slices/conversations.slice";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useQueryClient } from "react-query";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import dayjs from "dayjs";
import {
  CollaboratorSelect,
  TaskDueDate,
  ParentTaskSelect,
  AddApprovers,
  // CollaboratorSelect,
  DueDate,
  LabelSelect,
  PrioritySelect,
  RowLayout,
  SelectStatus,
  // TaskDueDate,
  TaskInput,
  VersionSelect,
  TimeSelect,
  SelectStatusMyBoard,
} from "./TaskReusable";
import {
  createMyBoardTask,
  getAllMyBoardTasks,
  getMyBoardSubtaskById,
  getMyBoardTaskById,
  getMyBoardTasks,
  getTaskActivityLog,
  updateMyBoardTask,
} from "../../../Core/redux/api/myBoardAPI";
import {
  resetToInitialMyBoard,
  updateMyBoard,
} from "../../../Core/redux/slices/myBoard.slice";
import { ThreeDots } from "./TaskItem";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import getCurrentUser from "./getCurrentUser";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import TaskHeader from "./TaskScreenHeader";
import CommentsAndActivity from "./CommentsAndActivity";
import MyBoardLayout from "./MyBoardLayout";
import DiscardEditedGoalModal from "../Task/DiscardEditedGoalModal";
import { StatusSelect } from "./SubTaskItem";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";

const UserSelect = ({ task }) => {
  const dispatch = useAppDispatch();

  const { userList } = useAppSelector((state) => state.overview);
  const { collaboratorsForTask } = useAppSelector((state) => state.myBoard);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleMultiSelect = (current) => {
    dispatch(
      updateMyBoard({
        key: "collaboratorsForTask",
        value: collaboratorsForTask.includes(current.userId)
          ? collaboratorsForTask.filter((item) => item != current.userId)
          : [...collaboratorsForTask, current.userId],
      })
    );
  };

  const onClose = () => {
    setOpen(false);
    dispatch(
      updateMyBoard({
        key: "collaboratorsForTask",
        value: [],
      })
    );
  };
  const onRequest = async () => {
    setOpen(false);
    if (task.id) {
      await dispatch(
        updateMyBoardTask({
          goalId: task.id,
          collaborators: collaboratorsForTask,
        })
      );
      dispatch(
        updateMyBoard({
          key: "collaboratorsForTask",
          value: [],
        })
      );
    }
    dispatch(
      updateDashboard({
        key: "alertPopupModal",
        value: {
          visible: true,
          data: {
            title: `Sent Collaboration Request`,
            subtitle: `Collaboration request was sent for task`,
            description: `${task.name}`,
          },
        },
      })
    );
  };

  return (
    <Popover
      getPopupContainer={(parent) =>
        parent?.parentElement?.parentElement?.parentElement?.parentElement
      }
      content={
        <div className="min-w-[200px] bg-white rounded-lg">
          <CustomInputBox
            className="px-3 pt-2"
            placeholder="Search for a name"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            autoFocus
          />

          <div className="overflow-y-scroll h-[200px]">
            <p className="px-3 pt-1.5 text-gray-500 out-300-14">
              Select 1 or multiple members
            </p>
            {userList
              .filter(
                (u) =>
                  u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                  u.lastname.toLowerCase().includes(search.toLowerCase())
              )
              .map((current, index) => (
                <div
                  role="button"
                  key={`${current.userId}${index}`}
                  className="flex items-center gap-x-3 mt-1.5 px-3"
                  onClick={() => handleMultiSelect(current)}
                >
                  <Checkbox
                    checked={collaboratorsForTask?.includes(current.userId)}
                  />
                  <CustomAvatar
                    src={current.profilePicture}
                    size={24}
                    title={getUsersName(current)}
                    whiteText
                    color={current.profilePictureColorCode}
                  />

                  <p className="text-gray-900 capitalize out-300-12">
                    {getUsersName(current)}
                  </p>
                </div>
              ))}
          </div>

          <div className="z-10 flex items-center justify-end w-full p-3 bg-white rounded-b-lg">
            <CustomButton
              text="Cancel"
              className="text-gray-500 bg-transparent"
              height="30px"
              width="63px"
              onClick={onClose}
            />
            <CustomButton
              text="Request"
              height="30px"
              width="71px"
              className="bg-transparent text-primary-600"
              onClick={onRequest}
            />
          </div>
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={(open) => setOpen(open)}
      placement="bottomLeft"
      trigger={"click"}
    >
      {task && getCurrentUser({ task })}
    </Popover>
  );
};
const SubtaskScreen = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const {
    newLabelModal,
    manageLabelModal,
    deleteLabelModal,
    newReleaseVersionModal,
    labels,
  } = useAppSelector((state) => state.schedule);
  const {
    currentSelectedSubTask,
    collaboratorsForTask,
    newTask,
    deleteTaskModal,
    taskActivity,
  } = useAppSelector((state) => state.myBoard);

  const { filePreview } = useAppSelector((state) => state.conversations);

  const [taskDeleteModal, setTaskDeleteModal] = useState(false);
  const [completedOn, setCompletedOn] = useState("");

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { taskId, subtaskId } = useParams();
  const [selectedGoalId, setSelectedGoalId] = useState(subtaskId);
  const [taskType, setTaskType] = useState(1);
  const [editedTaskDetails, setEditedTaskDetails] = useState(null);
  const [discardModal, setDiscardModal] = useState(false);

  const initialState = {
    id: "",
    name: "",
    isCompleted: 0,
    isNewGoal: 0,
    statusId: "1",
    priorityId: "1",
    goalType: 4,
    assigneeId: " ",
    parentGoalId: taskId,
    isSubTask: true,
    description: "",
    labelId: "",
    releaseVersionId: "",
    createdAt: dayjs().format(),
    estimatedTime: "",
    totalTimeLogged: "",
  };
  const [taskDetails, setTaskDetails] = useState(initialState);
  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    const selectedUser = tenantMembers.find(
      (user) => user.id === taskDetails.assigneeId
    );
    setUserDetails(selectedUser);
  }, [taskDetails]);

  const labelOptions = labels
    ?.filter((item) => item.isMyBoard)
    ?.map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });

  const { formState, control, setValue, handleSubmit, getValues } = useForm({
    values: {
      taskName: "",
      timeLogged: "",
    },
  });

  const [titleEdit, setTitleEdit] = useState(false);

  useEffect(() => {
    dispatch(getAllMyBoardTasks({}));
  }, []);
  useEffect(() => {
    setSelectedGoalId(subtaskId);
    if (subtaskId !== "new") {
      dispatch(getMyBoardSubtaskById({ taskId: subtaskId }));
      dispatch(getTaskActivityLog({ goalId: subtaskId }));
    }
  }, [subtaskId, taskId]);

  const groupItems = [
    {
      key: "1",
      label: <div className="out-300-14 text-gray-700">Copy link</div>,
      icon: <CopyLinkIcon />,
    },

    {
      key: "2",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Duplicate</div>
      ),
      icon: <CopyIcon className="text-gray-700" />,
    },

    //todo add vlaidation so user cannot delete if he is not creator
    // TASK_EVENT_SCOPE_DELETE ? { key: "divider", type: "divider" } : null,
    {
      key: "3",
      label: <div className="out-300-14 text-gray-700">Delete</div>,

      icon: <DeleteIcon className="text-gray-700 " />,
    },
  ];

  const handleDuplicate = async () => {
    try {
      const { data } = await client.post("/my-board/duplicate-goal", {
        id: selectedGoalId,
      });
      dispatch(
        getMyBoardTasks({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET })
      );

      dispatch(
        updateMyBoard({
          key: "currentSelectedSubTask",
          value: { ...data.result },
        })
      );
      navigate(`/my-board/task/${taskId}/${data.result.id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const onClick = ({ key }) => {
    switch (key) {
      case "1":
        copyTextToClipboard(window.location.href);
        break;
      case "2":
        handleDuplicate();
        break;
      case "3":
        dispatch(
          updateMyBoard({
            key: "selectedTasks",
            value: [subtaskId],
          })
        );
        setTaskDeleteModal(true);
        break;
      default:
        break;
    }
  };

  const redirectToLink = `/my-board`;
  const handleClose = () => {
    redirectToLink ? navigate(redirectToLink) : navigate(-1);
  };
  const createTask = async () => {
    const payload = {
      ...taskDetails,
      collaborators: [user.id, ...collaboratorsForTask],
    };
    try {
      await dispatch(createMyBoardTask(payload));
      await dispatch(
        getMyBoardTasks({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET })
      );
      dispatch(updateMyBoard({ key: "createTaskHeader", value: false }));
      dispatch(updateMyBoard({ key: "newTask", value: null }));
      dispatch(
        updateMyBoard({
          key: "collaboratorsForTask",
          value: [],
        })
      );
      navigate("/my-board");
    } catch (error) {
      console.log("Error occured while creating the task", error);
    } finally {
      setTaskDetails(initialState);
    }
  };
  useEffect(
    () => console.log(editedTaskDetails, "editeddd"),
    [editedTaskDetails]
  );

  useEffect(() => {
    if (selectedGoalId !== "new" && currentSelectedSubTask) {
      setValue("taskName", currentSelectedSubTask?.name);
      setTaskDetails({
        ...currentSelectedSubTask,
      });

      setTaskType(+currentSelectedSubTask?.goalType);

      if (
        taskActivity &&
        taskActivity.length > 0 &&
        taskActivity[0].newValue == 3 &&
        taskActivity[0].action == "STATUS_CHANGED"
      ) {
        if (currentSelectedSubTask.id == taskActivity[0].goalId)
          setCompletedOn(dayjs(taskActivity[0].timestamp).format("DD/MM/YYYY"));
      }
    }
  }, [currentSelectedSubTask]);

  useEffect(() => {
    if (newTask && Object.keys(newTask).length !== 0) {
      setValue("taskName", newTask?.name);
      setValue("timeLogged", newTask?.timeLogged);
      setTaskType(newTask?.goalType);
      setTaskDetails(newTask?.taskDetails);
      setTaskDetails({
        ...taskDetails,
        name: newTask?.name ? newTask?.name : taskDetails?.name,
        priorityId: newTask?.priorityId
          ? newTask?.priorityId
          : taskDetails?.priorityId,
        labelId: newTask?.labelId ? newTask?.labelId : taskDetails?.labelId,
        totalTimeLogged: newTask?.totalTimeLogged
          ? newTask?.totalTimeLogged
          : taskDetails?.totalTimeLogged,
        parentGoalId: taskId,
      });
      console.log(taskDetails, "taskDetails");
    }
  }, [newTask]);

  // automation code
  useEffect(() => {
    if (selectedGoalId === "new") {
      setTaskType(4);

      if (getValues("timeLogged")) {
        setTaskDetails((prev) => {
          const obj = { ...prev, timeLogged: getValues("timeLogged") };
          return obj;
        });
      }

      setTaskDetails((prev) => {
        const obj = { ...prev, assigneeId: user.id.toString() };
        return obj;
      });
    }
  }, [taskType, taskId]);

  /* todo list
  new subtaskscreen
  navigation and header
  comments and activity
  navigation better logic insteda pf fetching all goals
  
  
  */
  const editTask = async () => {
    const action = [];
    if (editedTaskDetails.assigneeId) {
      action.push({
        oldValue: currentSelectedSubTask.assigneeId,
        newValue: editedTaskDetails.assigneeId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.ASSIGNEE_CHANGED,
      });
    }

    if (editedTaskDetails.statusId || editedTaskDetails.swimlaneId) {
      action.push({
        oldValue: currentSelectedSubTask.statusId,
        newValue: editedTaskDetails.statusId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
      });
    }

    if (editedTaskDetails.priorityId) {
      action.push({
        oldValue: currentSelectedSubTask?.priorityId,
        newValue: editedTaskDetails.priorityId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.PRIORITY_CHANGE,
      });
    }
    if (editedTaskDetails.labelId) {
      action.push({
        oldValue: currentSelectedSubTask.labelId,
        newValue: editedTaskDetails.labelId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.LABEL_CHANGE,
      });
    }

    if (editedTaskDetails.estimatedTime) {
      action.push({
        oldValue: currentSelectedSubTask.estimatedTime,
        newValue: editedTaskDetails.estimatedTime,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.ESTIMATE_CHANGE,
      });
    }
    if (editedTaskDetails.timeLogged) {
      action.push({
        oldValue: currentSelectedSubTask.timeLogged,
        newValue: editedTaskDetails.timeLogged,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
      });
    }
    if (editedTaskDetails.releaseVersionId) {
      action.push({
        oldValue: currentSelectedSubTask.releaseVersionId,
        newValue: editedTaskDetails.releaseVersionId,
        actionName: TASK_ACTIVITY_LOG_ACTIONS.RELEASE_VERSION_CHANGE,
      });
    }

    const payload = {
      ...editedTaskDetails,
      action: [...action],
      goalId: subtaskId,
    };
    await dispatch(updateMyBoardTask(payload));
    dispatch(getMyBoardSubtaskById({ taskId: subtaskId }));
    dispatch(getTaskActivityLog({ goalId: subtaskId }));
    // if (editedTaskDetails.assigneeId) {
    //   await client.post("/notifications/create", {
    //     notificationType: "32",
    //     goalId: taskId,
    //     allUsers: [
    //       { userId: taskDetails.assigneeId },
    //     ],
    //     meta: {
    //       oldAssigneeId: selectedTask.assigneeId,
    //       newAssigneeId: editedTaskDetails.assigneeId,
    //     },
    //   });
    // }

    setEditedTaskDetails(null);
    handleClose();
  };
  const onSubmit = async () => {
    if (subtaskId === "new") {
      createTask();
    } else {
      editTask();
    }
  };
  return (
    <MyBoardLayout>
      <TaskHeader
        taskType={subtaskId ? 4 : 1}
        handleSubmit={onSubmit}
        task={taskDetails}
        onCancel={() => {
          if (
            editedTaskDetails &&
            Object.keys(editedTaskDetails).length !== 0
          ) {
            setDiscardModal(true);
          } else {
            handleClose();
          }
        }}
      />

      <div className="flex bg-white min-h-[calc(100vh-100px)]">
        <div className="flex-1 px-6 py-4 min-h-[calc(100vh-100px)] max-h-[calc(100vh-100px)] overflow-y-auto">
          {selectedGoalId === "new" ? (
            <Controller
              name="taskName"
              control={control}
              rules={{ required: "Please enter a title before submitting" }}
              render={({ field, formState }) => (
                <TaskInput
                  {...field}
                  autoSize
                  placeholder="Enter a title"
                  value={taskDetails.name}
                  showError={formState.errors.taskName?.message ? true : false}
                  onChange={async (e) => {
                    setTaskDetails({ ...taskDetails, name: e.target.value });
                  }}
                />
              )}
            />
          ) : (
            <TaskInput
              autoSize
              value={taskDetails.name}
              placeholder="Enter a title"
              autoFocus={true}
              onSave={async (e, save) => {
                if (save) {
                  e.preventDefault();

                  setTaskDetails({ ...taskDetails, name: e.target.value });
                  setEditedTaskDetails({
                    ...editedTaskDetails,
                    name: e.target.value,
                  });
                  // await dispatch(
                  //   updateMyBoardTask({
                  //     name: e.target.value,
                  //     goalId: taskDetails.id,
                  //   })
                  // );
                  setTitleEdit(false);
                }
              }}
              onChange={async (e) => {
                setTaskDetails({ ...taskDetails, name: e.target.value });
                setEditedTaskDetails({
                  ...editedTaskDetails,
                  name: e.target.value,
                });
                // await dispatch(
                //   updateMyBoardTask({
                //     name: e.target.value,
                //     goalId: taskDetails.id,
                //   })
                // );
              }}
            />
          )}

          {formState.errors.taskName?.message && (
            <div className="mt-1.5 out-300-12 text-left text-error-500">
              {formState.errors.taskName?.message}
            </div>
          )}

          <div className="mt-4  ">
            <TaskInput
              onChange={async (e) => {
                setTaskDetails({ ...taskDetails, description: e.target.value });
                if (selectedGoalId !== "new") {
                  setEditedTaskDetails({
                    ...editedTaskDetails,
                    description: e.target.value,
                  });
                  // await dispatch(
                  //   updateMyBoardTask({
                  //     description: e.target.value,
                  //     goalId: taskDetails.id,
                  //   })
                  // );
                }
              }}
              autoSize={{ minRows: 3 }}
              inputClassName="task-description"
              placeholder="Enter a description..."
              value={taskDetails.description}
            />
          </div>

          {selectedGoalId !== "new" && (
            <CommentsAndActivity task={currentSelectedSubTask} />
          )}
        </div>
        <div className="px-6 w-[386px] border-l border-gray-200 min-h-[calc(100vh-100px)] max-h-[calc(100vh-100px)] overflow-y-auto pt-5 ">
          <div className="flex justify-between">
            <SelectStatusMyBoard
              setTask={async (newStatusId) => {
                setTaskDetails((oldState) => ({
                  ...oldState,
                  statusId: newStatusId,
                  swimlaneId: newStatusId,
                }));

                if (taskId !== "new") {
                  setEditedTaskDetails((oldState) => ({
                    ...oldState,
                    statusId: newStatusId,
                    swimlaneId: newStatusId,
                  }));
                }
              }}
              statusId={taskDetails.statusId}
              task={taskDetails}
            />

            {taskId !== "new" ? (
              <Dropdown
                dropdownRender={(menu) => (
                  <div className="w-[200px]">{menu}</div>
                )}
                trigger={["click"]}
                menu={{ items: groupItems, onClick }}
              >
                <button>
                  <DotsVerticalIcon className="text-gray-900" />
                </button>
              </Dropdown>
            ) : null}
          </div>

          <RowLayout customLeft="Assignee" className="mt-5 mb-5">
            <div className="flex border border-transparent hover:border-gray-200 w-full rounded  h-[30px]  items-center ">
              <CustomAvatar
                src={userDetails?.profilePicture}
                size={24}
                title={getUsersName(userDetails)}
                whiteText
                color={userDetails?.profilePictureColorCode}
              />

              <p className="out-500-14 pl-2 text-gray-900 capitalize ">
                {getUsersName(userDetails)}
              </p>
            </div>
          </RowLayout>

          <>
            <RowLayout customLeft="Priority" className=" mb-5">
              <PrioritySelect
                task={taskDetails}
                setTask={async (e) => {
                  setTaskDetails({ ...taskDetails, priorityId: e });
                  if (selectedGoalId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,
                      priorityId: e,
                    });
                    // await dispatch(
                    //   updateMyBoardTask({
                    //     priorityId: e,
                    //     goalId: taskDetails.id,
                    //   })
                    // );
                  }
                }}
              />
            </RowLayout>
            <RowLayout customLeft="Parent Task" className=" mb-8">
              <ParentTaskSelect
                disabled={false}
                subTask={taskDetails}
                setSubTask={async (e) => {
                  setTaskDetails({ ...taskDetails, parentGoalId: e });
                  if (selectedGoalId !== "new") {
                    setEditedTaskDetails({
                      ...editedTaskDetails,
                      parentGoalId: e,
                    });
                    // await dispatch(
                    //   updateMyBoardTask({ labelId: e, goalId: taskDetails.id })
                    // );
                    // dispatch(getTaskActivityLog({ goalId: taskId }));
                  }
                }}
              />
            </RowLayout>
          </>
          <div className="out-500-14 text-black">Time log</div>

          <RowLayout
            customLeft={
              <div className="flex items-center gap-x-1">
                <span>Original estimate</span>{" "}
                <InfoByFrosty
                  title="Original estimate"
                  content={
                    <div className="ml-12">
                      <p className="out-500-14 text-gray-500">0w 0d 00h 00m</p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        w = weeks
                      </p>
                      <p className="out-300-14  pt-2 text-gray-500">d = days</p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        h = hours
                      </p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        m = minutes
                      </p>
                    </div>
                  }
                />
              </div>
            }
            className="mt-4 mb-5"
          >
            <TimeSelect
              task={taskDetails}
              setTask={setTaskDetails}
              visible={true}
              type="estimatedTime"
              setEditedTaskDetails={(e) => {
                setEditedTaskDetails({
                  ...editedTaskDetails,
                  estimatedTime: e,
                });
              }}
            />
          </RowLayout>

          <RowLayout
            customLeft={
              <div className="flex items-center gap-x-1">
                <span>Log more time</span>{" "}
                <InfoByFrosty
                  title="Log more time"
                  content={
                    <div className="ml-12">
                      <p className="out-500-14 text-gray-500">0w 0d 00h 00m</p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        w = weeks
                      </p>
                      <p className="out-300-14  pt-2 text-gray-500">d = days</p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        h = hours
                      </p>
                      <p className="out-300-14  pt-2 text-gray-500">
                        m = minutes
                      </p>
                    </div>
                  }
                />
              </div>
            }
            className="mt-4 mb-8"
          >
            {selectedGoalId === "new" ? (
              <Controller
                name="timeLogged"
                control={control}
                // rules={{ required: "Please enter a title before submitting" }}
                render={({ field, formState }) => (
                  <TimeSelect
                    task={taskDetails}
                    setTask={setTaskDetails}
                    visible={true}
                    type="timeLogged"
                  />
                )}
              />
            ) : (
              <TimeSelect
                task={taskDetails}
                setTask={setTaskDetails}
                visible={true}
                type="timeLogged"
                setEditedTaskDetails={(e) => {
                  setEditedTaskDetails({
                    ...editedTaskDetails,
                    totalTimeLogged: e,
                  });
                }}
              />
            )}
          </RowLayout>

          <div className="out-500-14 text-black">More details</div>
          {/* <RowLayout
            customLeft={
              <div className="flex items-center gap-x-1">
                <span>Release version</span>{" "}
                <InfoByFrosty
                  title="Release version"
                  content={
                    "Release versions are important for identifying and tracking different versions of the software. They help with testing, debugging, troubleshooting, and enable users to understand which version of the software they are using, along with its features and improvements."
                  }
                />
              </div>
            }
            className="mt-4 mb-5"
          >
            <VersionSelect
              task={taskDetails}
              disabled={false}
              setTask={async (e) => {
                setTaskDetails({ ...taskDetails, releaseVersionId: e });
                if (selectedGoalId !== "new") {
                  setEditedTaskDetails({
                    ...editedTaskDetails,

                    releaseVersionId: e,
                  });
                  // await dispatch(
                  //   updateMyBoardTask({
                  //     releaseVersionId: e,
                  //     goalId: selectedGoalId,
                  //     action: {
                  //       oldValue: currentSelectedSubTask.releaseVersionId,
                  //       newValue: e,
                  //       actionName:
                  //         TASK_ACTIVITY_LOG_ACTIONS.RELEASE_VERSION_CHANGE,
                  //     },
                  //   })
                  // );
                }
              }}
            />
          </RowLayout> */}
          <RowLayout customLeft="Created on" className="mt-4 mb-5">
            <div className="flex px-[14px]">
              <p className="out-300-14 text-gray-400  ">
                {currentSelectedSubTask?.createdAt
                  ? dayjs(currentSelectedSubTask?.createdAt).format(
                      "DD/MM/YYYY"
                    )
                  : dayjs().format("DD/MM/YYYY")}
              </p>
            </div>
          </RowLayout>

          {completedOn && (
            <RowLayout customLeft="Completed on" className="mt-4 mb-5">
              <div className="flex px-[14px]">
                <p className="out-300-14 text-gray-400  ">{completedOn}</p>
              </div>
            </RowLayout>
          )}
        </div>
      </div>

      <NewLabelModal
        visible={newLabelModal}
        onClose={() => dispatch(resetSchedule(["newLabelModal"]))}
        isMyBoard
      />

      <ManageLabelsModal
        visible={manageLabelModal}
        onClose={() => dispatch(resetSchedule(["manageLabelModal"]))}
      />
      <DeleteLabelModal
        visible={deleteLabelModal.visible}
        onClose={() => dispatch(resetSchedule(["deleteLabelModal"]))}
      />
      <NewReleaseVersionModal
        visible={newReleaseVersionModal}
        onClose={() => dispatch(resetSchedule(["newReleaseVersionModal"]))}
      />
      <DeleteTaskModal
        visible={taskDeleteModal}
        selectedTasks={[+subtaskId]}
        onClose={() => {
          dispatch(resetToInitialMyBoard(["deleteTaskModal"]));
          setTaskDeleteModal(false);
          dispatch(
            updateMyBoard({
              key: "selectedTasks",
              value: [],
            })
          );
        }}
      />
      <DiscardEditedGoalModal
        onClose={() => setDiscardModal(false)}
        onDiscard={() => {
          setEditedTaskDetails(false);
          setDiscardModal(false);
          handleClose();
        }}
        visible={discardModal}
      />
      <FilePreviweModal
        visible={filePreview.visible}
        page="task"
        onClose={() => dispatch(resetConversations(["filePreview"]))}
      />
    </MyBoardLayout>
  );
};

export default SubtaskScreen;
