import React from "react";
import Icon from "@ant-design/icons";

const CalendarDcfSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#165ABF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M5.333 1.333v2M10.667 1.333v2M2.333 6.06h11.334M14.667 12.667c0 .5-.14.973-.387 1.373A2.646 2.646 0 0112 15.333a2.603 2.603 0 01-1.753-.666 2.396 2.396 0 01-.527-.627 2.614 2.614 0 01-.387-1.373 2.666 2.666 0 115.334 0z"
    ></path>
    <path
      stroke="#165ABF"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.96 12.667l.66.66 1.42-1.313"
    ></path>
    <path
      stroke="#165ABF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M14 5.666v5.24a2.666 2.666 0 00-4.667 1.76c0 .5.14.974.387 1.374.14.24.32.453.527.626H5.333C3 14.666 2 13.333 2 11.333V5.666c0-2 1-3.333 3.333-3.333h5.334C13 2.333 14 3.666 14 5.666z"
    ></path>
  </svg>
);

const CalendarDcfIcon = (props) => {
  return <Icon component={CalendarDcfSvg} {...props} />;
};

export { CalendarDcfIcon };
