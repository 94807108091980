import React from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";

const MyFootprintLayout = ({ children }) => {
  return (
    <CustomLayout
      page="my-footprint"
      title={<div className="flex gap-x-1.5 "></div>}
    >
      {/* add my footprint tour component */}
      {children}
    </CustomLayout>
  );
};

export default MyFootprintLayout;
