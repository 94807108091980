import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";

const usePermission = (permissionId: string) => {
  const { user, permissionArray } = useAppSelector((state: RootState) => ({
    user: state.userDetails.user,
    permissionArray: state.projects.permissionArray,
  }));

  const hasPermission =
    permissionArray?.includes(permissionId) || +user.roleId < 3;

  return { hasPermission };
};

export default usePermission;
