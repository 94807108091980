import React from "react";
import Icon from "@ant-design/icons";

const ChevronIcon = ({
  className = "",
  height = "20",
  width = "20",
  style = {},
  rotate = "",
  strokeWidth = "1",
  onClick = () => console.log(""),
}) => (
  <span
    role="img"
    className={`anticon ${className}`}
    style={style}
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M5 7.5l5 5 5-5"
      ></path>
    </svg>
  </span>
);

const ChevronDoubleSvg = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    fill="none"
    viewBox="0 0 21 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.5 14.166L11.333 10 15.5 5.833m-5.833 8.333L5.5 10l4.167-4.167"
    ></path>
  </svg>
));

ChevronDoubleSvg.displayName = "ChevronDoubleSvg";
// const ChevronIcon = (props) => {
//   return <Icon component={ChevronSvg} {...props} />;
// };

const ChevronDoubleIcon = (props) => {
  return <Icon component={ChevronDoubleSvg} {...props} />;
};

export { ChevronIcon, ChevronDoubleIcon };
