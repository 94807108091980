import React from "react";
import Icon from "@ant-design/icons";

const EmojiSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.844 11.75a3.224 3.224 0 01-1.156 1.201c-.48.288-1.024.44-1.579.44a3.065 3.065 0 01-1.578-.44 3.224 3.224 0 01-1.156-1.201"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M12.625 3.509a7 7 0 103.866 3.866"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.67"
      d="M7.648 8.523h.006m.268 0a.273.273 0 11-.547 0 .273.273 0 01.547 0zM12.314 8.523h.038m.273 0a.273.273 0 11-.547 0 .273.273 0 01.547 0z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M13.5 5.188h3.063M15.031 6.719V3.656"
    ></path>
  </svg>
);

const EmojiIcon = (props) => {
  return <Icon component={EmojiSvg} {...props} />;
};

export { EmojiIcon };
