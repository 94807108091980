import { Checkbox, Col, Dropdown, Input, Row, Tooltip } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import getUsersName from "../../../Core/utils/getUserName";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import {
  getGoalsBySprint,
  getTodoList,
} from "../../../Core/redux/api/scheduleAPI";

const CheckboxGroup = Checkbox.Group;
const UserQuickFilters = ({ max = 4 }) => {
  const { userList } = useAppSelector((state) => state.overview);
  const { user } = useAppSelector((state) => state.userDetails);
  const { projectId, sprintId } = useParams();
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const { filterGoalParameter, isMyTasks } = useAppSelector(
    (state) => state.schedule
  );

  if (userList.length === 0) {
    return <></>;
  }

  const toggleSelectedUser = (id) => {
    const { assignee } = filterGoalParameter;

    //creating a copy of the assignee object as we cannot modify on a read only object
    let allProjectAssigneeFilter = [...assignee];

    //creating a copy of the current project filter object
    const previousFilters = allProjectAssigneeFilter?.find(
      (ele) => +ele.projectId === +projectId
    );
    let currentProjectFilters =
      previousFilters !== undefined
        ? JSON.parse(JSON.stringify(previousFilters))
        : [];

    if (currentProjectFilters !== undefined) {
      //if there is any filter on the current project , check if the assignee array includes the current user
      const isMyTaskSelected = currentProjectFilters?.assignee?.includes(id);

      let currentProjectAssigneeArray = currentProjectFilters?.assignee
        ? currentProjectFilters?.assignee
        : [];
      if (isMyTaskSelected) {
        //if the user filter existed , remove it
        currentProjectAssigneeArray = currentProjectFilters?.assignee.filter(
          (assigneeId) => +assigneeId !== +id
        );
      } else {
        //if the user filter did not exist add it
        currentProjectAssigneeArray.push(id);
      }

      //create the new assignee object for current project
      currentProjectFilters = {
        projectId,
        assignee: currentProjectAssigneeArray,
      };
      //remove the current project assignee filter from filtergoalparameter object
      allProjectAssigneeFilter = allProjectAssigneeFilter?.filter(
        (ele) => +ele.projectId !== +projectId
      );
    } else {
      //create the new assignee object for current project
      currentProjectFilters = { projectId, assignee: [id] };
    }
    //push the updated array
    allProjectAssigneeFilter.push(currentProjectFilters);
    if (filterGoalParameter?.searchValue?.searchValue) {
      const payload: {
        limit: number;
        offset: number;
        searchQuery?: string;
        projectId: string;
        assignee?: any;
      } = {
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
        projectId,
      };
      payload.searchQuery =
        (filterGoalParameter?.searchValue?.projectId === projectId &&
          filterGoalParameter?.searchValue?.module === "planner") ||
        !sprintId
          ? filterGoalParameter?.searchValue?.searchValue?.toLowerCase()
          : "";
      payload.assignee = isMyTasks
        ? [user.id]
        : currentProjectFilters?.assignee;
      if (location.pathname.includes("backlog")) {
        dispatch(getTodoList(payload));
      }
      // else{
      //   dispatch(
      //     getGoalsBySprint({
      //       projectId,
      //       sprintId: sprint.id,
      //       limit: DEFAULT_LIMIT,
      //       offset: offset,
      //     })
      //   );
      // }
    }
    dispatch(
      updateSchedule({
        key: "filterGoalParameter",
        value: {
          ...filterGoalParameter,
          assignee: allProjectAssigneeFilter,
        },
      })
    );
  };

  const newUser = [...userList];

  const sortedUser = newUser.sort((a, b) => {
    if (a.userId == user.id) return -1;
    if (b.userId == user.id) return 1;
    return 0;
  });
  //checking if there is any filter on the current project
  const currentProjectFilter = filterGoalParameter?.assignee?.find(
    (filter) => +filter.projectId === +projectId
  );
  const avatarUser = sortedUser.slice(0, max);
  const otherUsers = sortedUser.slice(max);
  return (
    <div className="">
      <div className="avatar-group">
        {avatarUser.map((user, i) => (
          <Tooltip
            title={getUsersName(user)}
            key={user.userId}
            placement="bottom"
          >
            <span
              className="avatar cursor-pointer"
              key={user.userId}
              style={{
                border: currentProjectFilter?.assignee?.includes(user.userId)
                  ? "2px solid #165ABF"
                  : "2px solid #fff",
                zIndex: i,
              }}
              onClick={() => {
                dispatch(
                  updateSchedule({
                    key: "isMyTasks",
                    value: false,
                  })
                );
                toggleSelectedUser(user.userId);
              }}
            >
              <CustomAvatar
                src={user.profilePicture}
                title={getUsersName(user)}
                size={24}
                whiteText
                fontSize={"12px"}
                color={user.profilePictureColorCode}
              />
            </span>
          </Tooltip>
        ))}
        {otherUsers.length > 0 && (
          <span
            className="avatar bg-primary-gray z-[1]"
            style={{
              border: otherUsers
                .map((m) => m.userId)
                .some((el) => currentProjectFilter?.assignee?.includes(el))
                ? "2px solid #165ABF"
                : "2px solid #D8E1EF",
            }}
          >
            <Dropdown
              trigger={["click"]}
              overlay={
                <div className="shadow-box border min-w-[200px] max-w-[200px] rounded-lg overflow-hidden bg-white ">
                  <div className="sticky top-0 z-10  bg-gray-50">
                    <Input
                      className="w-full rounded-lg bg-white focus:outline-none mon-500-12  h-10 "
                      prefix={<img src="/images/icons/search.svg" />}
                      bordered={false}
                      //onChange={handleSearch}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search User"
                    />
                  </div>
                  <div className="px-3">
                    <CheckboxGroup
                      value={currentProjectFilter?.assignee}
                      className="max-h-[300px] min-h-[100px] overflow-y-scroll overflow-x-hidden"
                    >
                      <Row gutter={[10, 10]}>
                        {otherUsers
                          .filter(
                            (user) =>
                              user.firstName
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              user.lastName
                                ?.toLowerCase()
                                .includes(search.toLowerCase()) ||
                              user.email
                                ?.toLowerCase()
                                .includes(search.toLowerCase())
                          )
                          .map((user, i) => (
                            <Col span={24} key={user.userId + i}>
                              {" "}
                              <Checkbox
                                value={user.userId}
                                onChange={() => {
                                  toggleSelectedUser(user.userId);
                                  dispatch(
                                    updateSchedule({
                                      key: "isMyTasks",
                                      value: false,
                                    })
                                  );
                                }}
                                checked={currentProjectFilter?.assignee?.includes(
                                  user.userId
                                )}
                              >
                                <p className="mon-400-12 text-gray-700 ml-1 capitalize">
                                  {user.firstname} {user.lastname}
                                </p>{" "}
                              </Checkbox>{" "}
                            </Col>
                          ))}
                      </Row>
                    </CheckboxGroup>
                  </div>
                </div>
              }
            >
              <span className="h-full cursor-pointer w-full flex justify-center items-center mon-500-14 text-gray-700">
                +{otherUsers.length}
              </span>
            </Dropdown>
          </span>
        )}
      </div>
    </div>
  );
};

export default UserQuickFilters;
