import React, { useEffect, useState } from "react";
import CustomModal from "../../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";

import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  TASK_ACTIVITY_LOG_ACTIONS,
} from "../../../../Core/constants";
import {
  getMyBoardTasks,
  updateMyBoardTask,
} from "../../../../Core/redux/api/myBoardAPI";
import { updateMyBoard } from "../../../../Core/redux/slices/myBoard.slice";

const SubtasksIneProgress = ({ visible }) => {
  const dispatch = useAppDispatch();
  const { projectId, sprintId } = useParams();
  const {
    subtasksInProgressWarning,
    myBoardTasks,
    selectedTasks,
    deleteTaskModal,
    createTaskHeader,
    exportModal,
    projectListModal,
    addCollaboratorsModal,
    myBoardKanbanView,
    filters,
  } = useAppSelector((state) => state.myBoard);

  const [statusIndicator, setStatusIndicator] = useState(null);
  const [subtasksToDone, setSubtasksToDone] = useState([]);

  useEffect(() => {
    if (
      Array.isArray(subtasksInProgressWarning?.goalId) &&
      subtasksInProgressWarning?.goalId.length > 0
    ) {
      const subtasksIds = [];
      subtasksInProgressWarning?.goalId.forEach((goalId) => {
        const selectedGoal = myBoardTasks.result.find(
          (goal) => +goal.id === +goalId
        );
        console.log(selectedGoal, "selectedGoal yo");
        if (selectedGoal && Array.isArray(selectedGoal?.subtask)) {
          const subtasksWithStatus1 = selectedGoal.subtask.filter(
            (subtask) => subtask.statusId === 1
          );
          const subtasksWithStatus2 = selectedGoal.subtask.filter(
            (subtask) => subtask.statusId === 2
          );

          subtasksIds.push(
            ...subtasksWithStatus1.map((subtask) => subtask.id),
            ...subtasksWithStatus2.map((subtask) => subtask.id)
          );

          const hasStatus1 = subtasksWithStatus1.length > 0;
          const hasStatus2 = subtasksWithStatus2.length > 0;

          if (hasStatus1 && hasStatus2) {
            setStatusIndicator(3); // Both status 1 and 2 are present
          } else if (hasStatus1) {
            setStatusIndicator(1); // Only status 1 is present
          } else if (hasStatus2) {
            setStatusIndicator(2); // Only status 2 is present
          }
        }
      });
      setSubtasksToDone(subtasksIds);
    } else if (subtasksInProgressWarning?.goalId) {
      const selectedGoal = myBoardTasks.result.find(
        (goal) => +goal.id === +subtasksInProgressWarning?.goalId
      );
      console.log(selectedGoal, "selectedGoal yo");

      if (selectedGoal && Array.isArray(selectedGoal?.subtask)) {
        const subtasksWithStatus1 = selectedGoal.subtask.filter(
          (subtask) => subtask.statusId === 1
        );
        const subtasksWithStatus2 = selectedGoal.subtask.filter(
          (subtask) => subtask.statusId === 2
        );

        const subtasksIds = [
          ...subtasksWithStatus1.map((subtask) => subtask.id),
          ...subtasksWithStatus2.map((subtask) => subtask.id),
        ];

        setSubtasksToDone(subtasksIds);

        const hasStatus1 = subtasksWithStatus1.length > 0;
        const hasStatus2 = subtasksWithStatus2.length > 0;

        if (hasStatus1 && hasStatus2) {
          setStatusIndicator(3); // Both status 1 and 2 are present
        } else if (hasStatus1) {
          setStatusIndicator(1); // Only status 1 is present
        } else if (hasStatus2) {
          setStatusIndicator(2); // Only status 2 is present
        }
      }
    }
    console.log(subtasksToDone, "selectedGoal yo");
  }, [visible]);

  const onCancel = () => {
    dispatch(
      getMyBoardTasks({
        sprintId: subtasksInProgressWarning?.sprintId,
        limit: sprintId
          ? myBoardKanbanView
            ? null
            : DEFAULT_LIMIT
          : DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
      })
    );

    dispatch(
      updateMyBoard({
        key: "subtasksInProgressWarning",
        value: {
          visible: false,
          goalId: null,
          statusId: null,
          swimlaneId: null,
          oldSwimlaneId: null,
          sprintId: null,
        },
      })
    );
  };

  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={onCancel}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Move Tasks to done</p>
            <button
              onClick={() => {
                dispatch(
                  updateMyBoard({
                    key: "subtasksInProgressWarning",
                    value: {
                      visible: false,
                      goalId: null,
                      statusId: null,
                      swimlaneId: null,
                      oldSwimlaneId: null,
                      sprintId: null,
                    },
                  })
                );
              }}
            >
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            The selected tasks include subtasks that are
            {statusIndicator === 1 && " currently in To Do. "}
            {statusIndicator === 2 && " currently in Progress. "}
            {statusIndicator === 3 && " currently in To Do & in Progress. "}
            Are you sure you want to mark the subtasks as done too?
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-between">
            <CustomButton
              text="No, don’t move"
              className="bg-transparent text-gray-500"
              height="30px"
              onClick={onCancel}
            />
            <CustomButton
              text="Yes, go ahead"
              height="30px"
              className="bg-error-700 text-white"
              onClick={async () => {
                await dispatch(
                  updateMyBoardTask({
                    statusId: subtasksInProgressWarning?.statusId,
                    goalId: subtasksInProgressWarning?.goalId,
                  })
                );
                await dispatch(
                  updateMyBoardTask({
                    statusId: subtasksInProgressWarning?.statusId,
                    goalId: subtasksToDone,
                  })
                );
                dispatch(
                  getMyBoardTasks({
                    sprintId: subtasksInProgressWarning?.sprintId,
                    limit: sprintId
                      ? myBoardKanbanView
                        ? null
                        : DEFAULT_LIMIT
                      : DEFAULT_LIMIT,
                    offset: DEFAULT_OFFSET,
                  })
                );
                // if (subtasksInProgressWarning?.sprintId) {
                //   dispatch(
                //     getmyBoardTasksBySprint({
                //       projectId,
                //       sprintId: subtasksInProgressWarning?.sprintId,
                //     })
                //   );
                // } else {
                //   dispatch(
                //     getTodoList({ projectId, limit: DEFAULT_LIMIT, offset: 0 })
                //   );
                // }
                dispatch(
                  updateMyBoard({
                    key: "subtasksInProgressWarning",
                    value: {
                      visible: false,
                      goalId: null,
                      statusId: null,
                      swimlaneId: null,
                      oldSwimlaneId: null,
                      sprintId: null,
                    },
                  })
                );
              }}
            />
          </div>
        </div>
      }
    />
  );
};

export default SubtasksIneProgress;
