import React from "react";
import Icon from "@ant-design/icons";

const EditSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path stroke="currentColor" strokeLinecap="round" d="M18 18H2"></path>
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="M6.906 14.92L17.075 3.119 14.611 1 9.49 7.113 4.365 13v2.669l2.541-.75z"
    ></path>
  </svg>
);
const EditPencilSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="M5.525 11.936l8.135-9.441L11.69.8 7.59 5.69 3.492 10.4v2.135l2.033-.6z"
    ></path>
  </svg>
);

const EditIcon = (props) => {
  return <Icon component={EditSvg} {...props} />;
};
const EditPencilIcon = (props) => {
  return <Icon component={EditPencilSvg} {...props} />;
};

export { EditIcon, EditPencilIcon };
