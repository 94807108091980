import React from "react";
import Icon from "@ant-design/icons";

const YesResponseIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_2097_5827)">
      <circle cx="8" cy="8" r="8" fill="#ECFDF3"></circle>
      <path
        stroke="#027A48"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.667 6l-5.75 5.333L4 8.606"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_2097_5827">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

const YesResponseIcon = (props) => (
  <Icon component={YesResponseIconSvg} {...props} />
);

export { YesResponseIcon };
