import React, { useState, useEffect, useRef } from "react";
import { useDrop } from "react-dnd";

const DropTarget = ({ id, onDrop, children, onDropTargetChange, type }) => {
  const ref = useRef(null);
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "card",
    drop: (item) => {
      console.log("dropped");
      if (typeof onDrop === "function") onDrop({ type, id });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  drop(ref);

  useEffect(() => {
    onDropTargetChange(id, { isOver, canDrop });
  }, [isOver, canDrop]);


  return <div ref={ref}> {children}</div>;
};

export default DropTarget;
