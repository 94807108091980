/* eslint-disable prefer-const */
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import React from "react";
import {
  monthDiff,
  getDaysInMonth,
  getDayOfWeek,
  createFormattedDateFromStr,
  createFormattedDateFromDate,
  dayDiff,
  calculateNewDate,
  calculateTotalDays,
} from "./dateFunctions";
import { ChevronIcon } from "../../../../Core/svgV2/Chevron";

import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
} from "@dnd-kit/core";
// DragOverlay unused

import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import DayCell from "./DayCell";
import customCollisionDetectionAlgorithm from "./customCollisionDetectionAlgorithm";
import dayjs from "dayjs";
import { Affix, Tooltip } from "antd";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import {
  updatePhase,
  updateSprint,
} from "../../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
dayjs.extend(customParseFormat);

dayjs.extend(advancedFormat);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const newMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function TimeTable({
  timeRange,
  tasks,
  setTasks,
  zoom,
  phases,
  setPhases,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  useEffect(() => {
    const selector = `[data-today="true"]`;
    const today = document.querySelector(selector) as HTMLElement;
    setTimeout(() => {
      if (today) {
        today.scrollIntoView({
          inline: "start",
          block: "end",
          behavior: "smooth",
        });
        const scrollContainer = today.parentElement; // Assuming the parent element is the scrollable container
        if (scrollContainer) {
          scrollContainer.scrollTo({
            left: today.offsetLeft,
            behavior: "smooth", // Optional: Smooth scrolling animation
          });
        }
      }
    }, 1000);
  }, []);

  // for dynamic css styling
  const ganttTimePeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: `minmax(${zoom}px, 1fr)`,
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };
  const ganttWeekPeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: `minmax(${280}px, 1fr)`,
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };

  const ganttTimePeriodSpan: React.CSSProperties = {
    margin: "auto",
  };

  const ganttTimePeriodCell: React.CSSProperties = {
    position: "relative",
    // borderRight: "1px solid #DDE5ED",
  };

  // // creating rows
  const startMonth = new Date(
    parseInt(timeRange.fromSelectYear),
    timeRange.fromSelectMonth
  );
  const endMonth = new Date(
    parseInt(timeRange.toSelectYear),
    timeRange.toSelectMonth
  );

  const numMonths = monthDiff(startMonth, endMonth) + 1;
  let month = new Date(startMonth);

  let monthRows = [];
  let dayRows = [];
  let dayRow = [];
  let taskRows = [];
  let taskRow = [];
  let weekRow = [];
  let weekRows = [];

  const handleResize = (distance, direction, task) => {
    const currentDate = direction === "toRight" ? task.endDate : task.startDate;
    const newDate = calculateNewDate(distance, direction, zoom, currentDate);

    const startDate = direction === "toRight" ? task.startDate : newDate;
    const endDate = direction === "toRight" ? newDate : task.endDate;

    if (task.type && task.type === "sprint") {
      const payload = {
        sprintId: task.id,
        startDate,
        endDate,
      };

      dispatch(updateSprint(payload));
    } else {
      dispatch(
        updatePhase({
          startDate,
          endDate,
          projectId,
          phaseId: task.phase.phaseId,
          projectPhaseId: task.phase.projectPhaseId,
          // projectPhaseId: task.projectPhaseId,
        })
      );
    }
  };

  const handleMonthClick = (currentMonthId, action = "next") => {
    const monthId = action === "next" ? currentMonthId + 1 : currentMonthId - 1;
    const selector = `[data-month-id="${monthId}"]`;
    const month = document.querySelector(selector) as HTMLElement;

    if (month) {
      month.scrollIntoView({
        inline: "start",
        block: "nearest",
        behavior: "smooth",
      });
    }
  };

  const handleTodayClick = () => {
    const selector = `[data-today="true"]`;
    const today = document.querySelector(selector) as HTMLElement;

    if (today) {
      today.scrollIntoView({
        inline: "start",
        block: "end",
        behavior: "smooth",
      });
      const scrollContainer = today.parentElement; // Assuming the parent element is the scrollable container
      if (scrollContainer) {
        scrollContainer.scrollTo({
          left: today.offsetLeft,
          behavior: "smooth", // Optional: Smooth scrolling animation
        });
      }
    }
  };

  useLayoutEffect(() => {
    handleTodayClick();
  }, []);

  let count = 1;
  const numProjectDays = dayDiff(timeRange.fromDate, timeRange.toDate);
  const numDaysInMonths = calculateTotalDays(
    timeRange.fromDate,
    timeRange.toDate
  );
  const numWeeks = Math.ceil(numDaysInMonths / 7);
  const numExtraDays = numWeeks * 7 - numDaysInMonths;

  // const numExtraDays =
  //for rows
  const startOfYearDate = `${timeRange.fromSelectMonth + 1}-01-${
    timeRange.fromSelectYear
  }`;

  let j = 1;
  while (j <= numDaysInMonths + numExtraDays) {
    for (let i = 1; i <= numWeeks; i++) {
      weekRows.push(
        <div
          key={i}
          className=" flex mb-[4px]  w-[276px] h-[34px] rounded py-2.5 justify-center bg-white"
          style={{
            outline: "none",
            height: "auto",
          }}
          data-month-id={i}
        >
          <div className=" max-w-max  flex gap-x-1 items-center justify-center  out-500-14  text-black">
            <p>W{i}</p>
            <p className="out-500-10 text-gray-600 mt-1">
              (
              {j == 1
                ? dayjs(startOfYearDate).format("MMM")
                : dayjs(startOfYearDate).add(j, "day").format("MMM")}
              {j == 1
                ? dayjs(startOfYearDate).format("MMM") ===
                  dayjs(startOfYearDate).add(7, "day").format("MMM")
                  ? ""
                  : "-"
                : dayjs(startOfYearDate).add(j, "day").format("MMM") ===
                  dayjs(startOfYearDate)
                    .add(j + 7, "day")
                    .format("MMM")
                ? ""
                : "-"}
              {j == 1
                ? dayjs(startOfYearDate).format("MMM") ===
                  dayjs(startOfYearDate).add(7, "day").format("MMM")
                  ? ""
                  : dayjs(startOfYearDate).add(7, "day").format("MMM")
                : dayjs(startOfYearDate).add(j, "day").format("MMM") ===
                  dayjs(startOfYearDate)
                    .add(j + 7, "day")
                    .format("MMM")
                ? ""
                : dayjs(startOfYearDate)
                    .add(j + 7, "day")
                    .format("MMM")}
              )
            </p>
          </div>
        </div>
      );
      j += 7;
    }
  }
  for (let i = 0; i < numMonths; i++) {
    // create month rows
    monthRows.push(
      <div
        key={i}
        style={{
          ...ganttTimePeriod,
          outline: "none",
          height: "auto",
          padding: "16px 8px",
        }}
        data-month-id={i}
      >
        <div className=" max-w-max   out-500-14 flex text-black">
          <div className="min-w-[106px]">
            {months[month.getMonth()] + " " + month.getFullYear()}
          </div>
          <div className="flex ml-2 gap-x-2">
            <button onClick={() => handleMonthClick(i, "prev")}>
              <ChevronIcon className="rotate-[90deg]" />
            </button>
            <button onClick={() => handleMonthClick(i, "next")}>
              <ChevronIcon className="rotate-[-90deg]" />
            </button>
          </div>
        </div>
      </div>
    );

    // create day and week rows
    const numDays = getDaysInMonth(month.getFullYear(), month.getMonth() + 1);
    // const currYear = month.getFullYear();
    // const currMonth = month.getMonth() + 1;

    //count % 7 ==1 are first days of the week and count % 7 ==0 are 7th days of the week

    for (let j = 1; j <= numDays; j++) {
      dayRow.push(
        <div
          key={j}
          className="bg-gray-100 w-10   flex items-center"
          style={{ ...ganttTimePeriod, outline: "none", height: "auto" }}
          data-today={dayjs(
            `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
            "D-MMMM-YYYY"
          ).isToday()}
        >
          <Tooltip
            title={`${dayjs(
              `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
              "D-MMMM-YYYY"
            ).format("dddd, MMMM Do YYYY")}`}
          >
            <span
              className={`${
                dayjs(
                  `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                  "D-MMMM-YYYY"
                ).isToday()
                  ? ""
                  : "text-black out-500-12"
              } flex justify-center py-1.5 bg-gray-200  ${
                count % 7 == 1 ? "rounded-tl-[4px] mr-[1px] w-10" : "w-10"
              }  ${
                count % 7 == 0 ? " rounded-tr-[4px] ml-[-1px] w-10 " : "w-10 "
              } `}
            >
              <div
                className={`${
                  dayjs(
                    `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                    "D-MMMM-YYYY"
                  ).isToday()
                    ? "flex justify-center out-500-10 items-center w-5 h-5 rounded-[30px] bg-primary-500 text-white p-1"
                    : ""
                } `}
              >
                {/* bg-primary-600  text-white px-[7px] */}

                <span
                  className={`${
                    dayjs(
                      `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                      "D-MMMM-YYYY"
                    ).isToday()
                      ? ""
                      : ""
                  } relative`}
                >
                  {j}
                  {dayjs(
                    `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                    "D-MMMM-YYYY"
                  ).isToday() && (
                    <div className="h-screen absolute left-[5px] top-[18px] w-[1px] bg-primary-500 z-10"></div>
                  )}
                </span>
              </div>
            </span>
          </Tooltip>
        </div>
      );
      count++;
    }

    dayRows.push(
      <div
        key={i}
        style={{
          ...ganttTimePeriod,
          outline: "none",
          height: "auto",
          // paddingBottom: "5px",
        }}
      >
        {dayRow}
      </div>
    );

    dayRow = [];

    month.setMonth(month.getMonth() + 1);
  }
  for (let j = 1; j <= numExtraDays; j++) {
    dayRow.push(
      <div
        key={j}
        className="bg-gray-100 w-10   flex items-center"
        style={{ ...ganttTimePeriod, outline: "none", height: "auto" }}
        data-today={dayjs(
          `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
          "D-MMMM-YYYY"
        ).isToday()}
      >
        <Tooltip
          title={`${dayjs(
            `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
            "D-MMMM-YYYY"
          ).format("dddd, MMMM Do YYYY")}`}
        >
          <span
            className={`${
              dayjs(
                `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                "D-MMMM-YYYY"
              ).isToday()
                ? "bg-primary-600  text-white px-[7px]"
                : "text-gray-500"
            } out-300-14  flex justify-center py-1.5 bg-gray-200  ${
              count % 7 == 1 ? "rounded-tl-[4px] mr-[1px] w-10" : "w-10"
            }  ${
              count % 7 == 0 ? " rounded-tr-[4px] ml-[-1px] w-9 " : "w-10 "
            } `}
          >
            <span className="">{j}</span>
          </span>
        </Tooltip>
      </div>
    );
    count++;
  }

  dayRows.push(
    <div
      style={{
        ...ganttTimePeriod,
        outline: "none",
        height: "auto",
        // paddingBottom: "5px",
      }}
    >
      {dayRow}
    </div>
  );

  // create task rows
  if (tasks && tasks.length) {
    let taskCount = 1;
    let lastMonthJ = 1;
    let gray = 1;
    const taskPhaseArray = [...tasks, ...phases];

    [...taskPhaseArray].forEach((task, index) => {
      let mnth = new Date(startMonth);
      const totalMonths = numExtraDays ? numMonths + 1 : numMonths;
      let k = 1;

      for (let i = 0; i < totalMonths; i++) {
        const curYear = mnth.getFullYear();
        const curMonth = mnth.getMonth() + 1;
        let numDays = 0;
        if (numExtraDays !== 0 && i === totalMonths - 1) {
          numDays = numExtraDays;
        } else {
          numDays = getDaysInMonth(curYear, curMonth);
        }
        // const numProjectDays = dayDiff(timeRange.fromDate, timeRange.toDate);
        // const numDaysInMonths = calculateTotalDays(
        //   timeRange.fromDate,
        //   timeRange.toDate
        // );
        // const numWeeks = Math.ceil(numDaysInMonths / 7);
        // const numExtraDays = numWeeks * 7 - numDaysInMonths;

        for (let j = 1; j <= numDays; j++) {
          // console.log("current month:", curMonth, "days in month:", numDays);
          // color weekend cells differently
          const dayOfTheWeek = getDayOfWeek(curYear, curMonth - 1, j - 1);
          // add task and date data attributes
          const formattedDate = createFormattedDateFromStr(
            curYear,
            curMonth,
            j
          );
          taskRow.push(
            <DayCell
              cellWidth={40}
              key={`${task?.projectPhaseId || task?.id}-${j}`}
              task={task}
              formattedDate={formattedDate}
              style={{
                ...ganttTimePeriodCell,

                backgroundColor: k % 2 == 0 ? "#F9FBFE" : "#F0F5FA", //if you do taskcount here instead of j , it gives a checkered pattern
              }}
              handleResize={(distance, direction) =>
                handleResize(distance, direction, task)
              }
              // count={taskCount}
            />
          );
          taskCount++;
          k++;
          if (+j === +numDays) {
            gray = gray % 2 == 0 ? 1 : 2;
          } else {
            gray++;
          }
        }

        taskRows.push(
          <div
            key={`${i}-${task?.projectPhaseId || task?.id}`}
            style={{
              ...ganttTimePeriod,

              height:
                index === tasks.length - 1
                  ? "80vh"
                  : task.type && task.type === "sprint"
                  ? "var(--cell-height-sprint)"
                  : "var(--cell-height)",
            }}
          >
            {taskRow}
            {/* {j} */}
          </div>
        );

        taskRow = [];
        mnth.setMonth(mnth.getMonth() + 1);
      }
      // }
      // for (let j = 1; j <= numExtraDays; j++) {
      //   let curMonth = new Date(
      //     parseInt(timeRange.toSelectYear),
      //     timeRange.toSelectMonth + 1
      //   );
      //   let extraMonth = new Date(timeRange.toDate);

      //   // Add one month
      //   extraMonth.setMonth(extraMonth.getMonth() + 1);
      //   const curYear = extraMonth.getFullYear();

      //   // color weekend cells differently
      //   const dayOfTheWeek = getDayOfWeek(
      //     curYear,
      //     extraMonth.getMonth() + 1 - 1,
      //     j - 1
      //   );
      //   // add task and date data attributes
      //   const formattedDate = createFormattedDateFromStr(
      //     curYear,
      //     extraMonth.getMonth() + 1,
      //     j
      //   );
      //   taskRow.push(
      //     <DayCell
      //       key={`${task?.projectPhaseId || task?.id}-${j}`}
      //       task={task}
      //       formattedDate={formattedDate}
      //       style={{
      //         ...ganttTimePeriodCell,
      //         backgroundColor: dayOfTheWeek === "S" ? "#F0F5FA" : "#F9FBFE",
      //       }}
      //       handleResize={(distance, direction) =>
      //         handleResize(distance, direction, task)
      //       }
      //       count={taskCount}
      //     />
      //   );
      //   taskCount++;
      // }
      // taskRows.push(
      //   <div
      //     key={`extra-${task?.projectPhaseId || task?.id}`}
      //     style={{
      //       ...ganttTimePeriod,

      //       height:
      //         index === tasks.length - 1
      //           ? "80vh"
      //           : task.type && task.type === "sprint"
      //           ? "var(--cell-height-sprint)"
      //           : "var(--cell-height)",
      //     }}
      //   >
      //     {taskRow}
      //   </div>
      // );
      // taskRow = [];
    });
  }
  function handleDragEnd(data) {
    // find task
    const task = data?.active?.data?.current?.task;

    const dataDate = data?.over?.id;

    const daysDuration = dayDiff(task.startDate, task.endDate);

    // get new task values
    // get start, calc end using daysDuration - make Date objects - change taskDurations

    const newStartDate = new Date(dataDate);
    let newEndDate = new Date(dataDate);
    newEndDate.setDate(newEndDate.getDate() + daysDuration - 1);

    const startDate = createFormattedDateFromDate(newStartDate);
    const endDate = createFormattedDateFromDate(newEndDate);

    const newTask = {
      ...task,
      startDate,
      endDate,
    };

    if (task.type && task.type === "sprint") {
      const payload = {
        sprintId: task.id,
        startDate,
        endDate,
      };

      dispatch(updateSprint(payload));
      const currentTaskIndex = tasks.findIndex(
        (t) => t.type === "sprint" && t.id === task.id
      );
      const allTaskCopy = [...tasks];
      allTaskCopy[currentTaskIndex] = newTask;
      setTasks(allTaskCopy);

      // const remainingTasks = tasks.filter((t) => t.id !== task.id);
      // const newTasks = [...remainingTasks, newTask].sort((a, b) => a.id - b.id);
      // setTasks([...newTasks]);
    } else {
      dispatch(
        updatePhase({
          startDate,
          endDate,
          projectId,
          phaseId: task.phase.phaseId,
          projectPhaseId: task.phase.projectPhaseId,
          // projectPhaseId: task.projectPhaseId,
        })
      );
      const currentTaskIndex = tasks.findIndex(
        (t) => t.projectPhaseId == task.projectPhaseId
      );
      const allTaskCopy = [...tasks];
      allTaskCopy[currentTaskIndex] = newTask;
      setTasks(allTaskCopy);
    }
    // data.stopPropagation();
  }

  const weekRowsRef = useRef(null);
  const dayRowsRef = useRef(null);

  useEffect(() => {
    const scrollContainer = document.getElementById("gantt-scroll-container");

    const handleScroll = () => {
      if (scrollContainer && weekRowsRef.current && dayRowsRef.current) {
        const scrollTop = scrollContainer.scrollTop;
        weekRowsRef.current.style.top = `${scrollTop}px`;
        dayRowsRef.current.style.top = `${
          scrollTop + weekRowsRef.current.offsetHeight
        }px`;
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div
      className="flex flex-col w-full  px-1.5 bg-gray-100  "
      id="gantt-grid-container__time"
      style={{ overflowY: "hidden", position: "relative", height: "100%" }}
    >
      <div
        ref={weekRowsRef}
        className="flex ml-[1px] w-full pt-0 bg-gray-100 gap-x-1"
        style={{
          gridTemplateColumns: `repeat(${numWeeks}, 1fr)`,
          position: "sticky",
          top: 0,
          zIndex: 50,
        }}
      >
        {weekRows}
      </div>

      <div
        ref={dayRowsRef}
        className="flex w-full"
        style={{
          gridTemplateColumns: `repeat(${numMonths}, 1fr)`,
          position: "sticky",
          top: weekRowsRef.current
            ? `${weekRowsRef.current.offsetHeight}px`
            : "0px",
          zIndex: 50,
        }}
      >
        {dayRows}
      </div>

      <DndContext
        modifiers={[restrictToHorizontalAxis]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        collisionDetection={customCollisionDetectionAlgorithm}
      >
        <div
          id="gantt-time-period-cell-container"
          className="relative "
          style={{
            gridColumn: "1/-1",
            display: "grid",
            gridTemplateColumns: `repeat(${
              numExtraDays ? numMonths + 1 : numMonths
            }, 1fr)`,
            paddingLeft: "0.5px",
            // paddingTop: "100px",
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          {taskRows}
        </div>
      </DndContext>
      <style>{`
        #gantt-grid-container__time {
          display: grid;
          overflow-x: auto;
          outline: 0.5px solid var(--color-outline);
          overflow-y:hidden
        }
      `}</style>
    </div>
  );
}
export function MonthTimetable({
  timeRange,
  tasks,
  setTasks,
  zoom,
  phases,
  setPhases,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const monthRowsRef = useRef(null);
  const dayRowsRef = useRef(null);

  // for dynamic css styling
  const ganttTimePeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: `minmax(${zoom}px, 1fr)`,
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };
  const ganttMonthPeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };
  const ganttDayPeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: `minmax(${1}px, 1fr)`,
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };

  const ganttWeekPeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };

  const ganttTimePeriodSpan: React.CSSProperties = {
    margin: "auto",
  };

  const ganttTimePeriodCell: React.CSSProperties = {
    position: "relative",
  };

  // // creating rows
  const startMonth = new Date(
    parseInt(timeRange.fromSelectYear),
    timeRange.fromSelectMonth
  );
  const endMonth = new Date(
    parseInt(timeRange.toSelectYear),
    timeRange.toSelectMonth
  );

  const numMonths = monthDiff(startMonth, endMonth) + 1;
  let month = new Date(startMonth);

  let monthRows = [];
  let dayRows = [];
  let dayRow = [];
  let taskRows = [];
  let taskRow = [];
  let weekRow = [];
  let weekRows = [];

  const handleResize = (distance, direction, task) => {
    const currentDate = direction === "toRight" ? task.endDate : task.startDate;
    const newDate = calculateNewDate(distance, direction, zoom, currentDate);

    const startDate = direction === "toRight" ? task.startDate : newDate;
    const endDate = direction === "toRight" ? newDate : task.endDate;

    if (task.type && task.type === "sprint") {
      const payload = {
        sprintId: task.id,
        startDate,
        endDate,
      };

      dispatch(updateSprint(payload));
    } else {
      dispatch(
        updatePhase({
          startDate,
          endDate,
          projectId,
          phaseId: task.phase.phaseId,
          projectPhaseId: task.phase.projectPhaseId,
          // projectPhaseId: task.projectPhaseId,
        })
      );
    }
  };

  const handleMonthClick = (currentMonthId, action = "next") => {
    const monthId = action === "next" ? currentMonthId + 1 : currentMonthId - 1;
    const selector = `[data-month-id="${monthId}"]`;
    const month = document.querySelector(selector) as HTMLElement;

    if (month) {
      month.scrollIntoView({
        inline: "start",
        block: "nearest",
        behavior: "smooth",
      });
    }
  };

  const handleTodayClick = () => {
    const selector = `[data-today="true"]`;
    const today = document.querySelector(selector) as HTMLElement;

    if (today) {
      today.scrollIntoView({
        inline: "start",
        block: "end",
        behavior: "smooth",
      });
      const scrollContainer = today.parentElement; // Assuming the parent element is the scrollable container
      if (scrollContainer) {
        scrollContainer.scrollTo({
          left: today.offsetLeft,
          behavior: "smooth", // Optional: Smooth scrolling animation
        });
      }
    }
  };

  useLayoutEffect(() => {
    handleTodayClick();
  }, []);

  let count = 1;
  const numProjectDays = dayDiff(timeRange.fromDate, timeRange.toDate);
  const numDaysInMonths = calculateTotalDays(
    timeRange.fromDate,
    timeRange.toDate
  );
  const numWeeks = Math.ceil(numDaysInMonths / 7);
  const numExtraDays = numWeeks * 7 - numDaysInMonths;

  // const numExtraDays =
  //for rows
  for (let i = 1; i <= numWeeks; i++) {
    weekRows.push(
      <div
        key={i}
        className=" flex   w-[276px] h-[34px] rounded py-2.5 justify-center bg-white"
        style={{
          outline: "none",
          height: "auto",
        }}
        data-month-id={i}
      >
        <div className=" max-w-max   out-500-14 flex text-black">W{i}</div>
      </div>
    );
  }

  const numDays = getDaysInMonth(month.getFullYear(), month.getMonth() + 1);

  for (let i = 0; i < numMonths; i++) {
    const numDays = getDaysInMonth(month.getFullYear(), month.getMonth() + 1);

    // create month rows
    monthRows.push(
      <div
        key={i}
        style={{
          ...ganttMonthPeriod,
          outline: "none",
          height: "34px",
          gridAutoColumns: ` ${(160 / 7) * numDays}px`,
        }}
        className="bg-white"
        data-month-id={i}
      >
        <div className="   out-500-14 flex w-full  text-black justify-center items-center py-2.5 rounded gap-x-2.5">
          <div className="min-w-[106px]">{newMonths[month.getMonth()]}</div>
        </div>
      </div>
    );

    // create day and week rows
    // const currYear = month.getFullYear();
    // const currMonth = month.getMonth() + 1;

    //count % 7 ==1 are first days of the week and count % 7 ==0 are 7th days of the week

    for (let j = 1; j <= numDays; j++) {
      dayRow.push(
        <div
          key={j}
          className="bg-gray-200    flex items-center"
          style={{ ...ganttDayPeriod, outline: "none", height: "auto" }}
          data-today={dayjs(
            `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
            "D-MMMM-YYYY"
          ).isToday()}
        >
          <Tooltip
            title={` ${dayjs(
              `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
              "D-MMMM-YYYY"
            ).format("dddd, MMMM Do YYYY")}`}
          >
            <span
              className={` flex justify-center py-1.5 bg-gray-200  ${
                `${dayjs(
                  `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                  "D-MMMM-YYYY"
                ).isToday()}`
                  ? ""
                  : count % 7 == 1
                  ? "rounded-tl-[4px] mr-[1px] text-black out-500-12 "
                  : "hidden"
              }   `}
            >
              <div
                className={`${
                  dayjs(
                    `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                    "D-MMMM-YYYY"
                  ).isToday()
                    ? "flex justify-center out-500-10 items-center w-5 h-5 rounded-[30px] bg-primary-500 text-white p-1"
                    : count % 7 == 1
                    ? " "
                    : "hidden"
                } `}
              >
                {/* bg-primary-600  text-white px-[7px] */}

                <span
                  className={`${
                    dayjs(
                      `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                      "D-MMMM-YYYY"
                    ).isToday()
                      ? ""
                      : ""
                  } relative`}
                >
                  {j}
                  {dayjs(
                    `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                    "D-MMMM-YYYY"
                  ).isToday() && (
                    <div className="h-screen absolute left-[5px] top-[18px] w-[1px] bg-primary-500 z-10"></div>
                  )}
                </span>
              </div>
            </span>
          </Tooltip>
        </div>
      );
      count++;
    }

    dayRows.push(
      <div
        key={i}
        className="mt-1.5"
        style={{
          ...ganttWeekPeriod,
          outline: "none",
          height: "auto",
          // paddingBottom: "5px",
          gridAutoColumns: ` ${160 / 7}px`,
        }}
      >
        {dayRow}
      </div>
    );

    dayRow = [];

    month.setMonth(month.getMonth() + 1);
  }

  // create task rows
  if (tasks && tasks.length) {
    let taskCount = 1;
    const taskPhaseArray = [...tasks, ...phases];

    [...taskPhaseArray].forEach((task, index) => {
      let mnth = new Date(startMonth);
      const totalMonths = numExtraDays ? numMonths + 1 : numMonths;
      let w = 1;

      while (w <= numWeeks) {
        for (let i = 0; i < totalMonths; i++) {
          const curYear = mnth.getFullYear();
          const curMonth = mnth.getMonth() + 1;
          let numDays = 0;
          if (numExtraDays !== 0 && i === totalMonths - 1) {
            numDays = numExtraDays;
          } else {
            numDays = getDaysInMonth(curYear, curMonth);
          }

          for (let j = 1; j <= numDays; j++) {
            // console.log("current month:", curMonth, "days in month:", numDays);
            // color weekend cells differently
            const dayOfTheWeek = getDayOfWeek(curYear, curMonth - 1, j - 1);
            // add task and date data attributes
            const formattedDate = createFormattedDateFromStr(
              curYear,
              curMonth,
              j
            );
            taskRow.push(
              <DayCell
                isMonthView
                cellWidth={160 / 7}
                isEOW={taskCount % 7 === 0}
                key={`${task?.projectPhaseId || task?.id}-${j}`}
                task={task}
                formattedDate={formattedDate}
                style={{
                  ...ganttTimePeriodCell,

                  backgroundColor: w % 2 == 0 ? "#F9FBFE" : "#F0F5FA",
                }}
                handleResize={(distance, direction) =>
                  handleResize(distance, direction, task)
                }
                // count={taskCount}
              />
            );
            if (taskCount % 7 == 0) w++;
            taskCount++;
          }

          taskRows.push(
            <div
              key={`${i}-${task?.projectPhaseId || task?.id}`}
              style={{
                ...ganttTimePeriod,
                gridAutoColumns: `${160 / 7}px`,

                height:
                  index === tasks.length - 1
                    ? "80vh"
                    : task.type && task.type === "sprint"
                    ? "var(--cell-height-sprint)"
                    : "var(--cell-height)",
              }}
            >
              {taskRow}
            </div>
          );

          taskRow = [];
          mnth.setMonth(mnth.getMonth() + 1);
        }
      }
      // for (let j = 1; j <= numExtraDays; j++) {
      //   let curMonth = new Date(
      //     parseInt(timeRange.toSelectYear),
      //     timeRange.toSelectMonth + 1
      //   );
      //   let extraMonth = new Date(timeRange.toDate);

      //   // Add one month
      //   extraMonth.setMonth(extraMonth.getMonth() + 1);
      //   const curYear = extraMonth.getFullYear();

      //   // color weekend cells differently
      //   const dayOfTheWeek = getDayOfWeek(
      //     curYear,
      //     extraMonth.getMonth() + 1 - 1,
      //     j - 1
      //   );
      //   // add task and date data attributes
      //   const formattedDate = createFormattedDateFromStr(
      //     curYear,
      //     extraMonth.getMonth() + 1,
      //     j
      //   );
      //   taskRow.push(
      //     <DayCell
      //       key={`${task?.projectPhaseId || task?.id}-${j}`}
      //       task={task}
      //       formattedDate={formattedDate}
      //       style={{
      //         ...ganttTimePeriodCell,
      //         backgroundColor: dayOfTheWeek === "S" ? "#F0F5FA" : "#F9FBFE",
      //       }}
      //       handleResize={(distance, direction) =>
      //         handleResize(distance, direction, task)
      //       }
      //       count={taskCount}
      //     />
      //   );
      //   taskCount++;
      // }
      // taskRows.push(
      //   <div
      //     key={`extra-${task?.projectPhaseId || task?.id}`}
      //     style={{
      //       ...ganttTimePeriod,

      //       height:
      //         index === tasks.length - 1
      //           ? "80vh"
      //           : task.type && task.type === "sprint"
      //           ? "var(--cell-height-sprint)"
      //           : "var(--cell-height)",
      //     }}
      //   >
      //     {taskRow}
      //   </div>
      // );
      // taskRow = [];
    });
  }
  function handleDragEnd(data) {
    // find task
    const task = data?.active?.data?.current?.task;

    const dataDate = data?.over?.id;

    const daysDuration = dayDiff(task.startDate, task.endDate);

    // get new task values
    // get start, calc end using daysDuration - make Date objects - change taskDurations

    const newStartDate = new Date(dataDate);
    let newEndDate = new Date(dataDate);
    newEndDate.setDate(newEndDate.getDate() + daysDuration - 1);

    const startDate = createFormattedDateFromDate(newStartDate);
    const endDate = createFormattedDateFromDate(newEndDate);

    const newTask = {
      ...task,
      startDate,
      endDate,
    };

    if (task.type && task.type === "sprint") {
      const payload = {
        sprintId: task.id,
        startDate,
        endDate,
      };

      dispatch(updateSprint(payload));
      const currentTaskIndex = tasks.findIndex(
        (t) => t.type === "sprint" && t.id === task.id
      );
      const allTaskCopy = [...tasks];
      allTaskCopy[currentTaskIndex] = newTask;
      setTasks(allTaskCopy);

      // const remainingTasks = tasks.filter((t) => t.id !== task.id);
      // const newTasks = [...remainingTasks, newTask].sort((a, b) => a.id - b.id);
      // setTasks([...newTasks]);
    } else {
      dispatch(
        updatePhase({
          startDate,
          endDate,
          projectId,
          phaseId: task.phase.phaseId,
          projectPhaseId: task.phase.projectPhaseId,
          // projectPhaseId: task.projectPhaseId,
        })
      );
      const currentTaskIndex = tasks.findIndex(
        (t) => t.projectPhaseId == task.projectPhaseId
      );
      const allTaskCopy = [...tasks];
      allTaskCopy[currentTaskIndex] = newTask;
      setTasks(allTaskCopy);
    }
    // data.stopPropagation();
  }
  useEffect(() => {
    const scrollContainer = document.getElementById("gantt-scroll-container");
    const handleScroll = () => {
      if (scrollContainer && monthRowsRef.current && dayRowsRef.current) {
        const scrollTop = scrollContainer.scrollTop;
        monthRowsRef.current.style.top = `${scrollTop}px`;
        dayRowsRef.current.style.top = `${
          scrollTop + monthRowsRef.current.offsetHeight
        }px`;
      }
    };
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div
      className="flex flex-col w-full  px-1.5 bg-gray-100 "
      id="gantt-grid-container__time"
      style={{ overflowY: "hidden", position: "relative", height: "100%" }}
    >
      <div
        ref={monthRowsRef}
        className="flex ml-[1px] w-max bg-gray-100 pt-0   gap-x-1 "
        style={{
          gridTemplateColumns: `repeat(${numMonths}, 1fr)`,
          position: "sticky",
          top: 0,
          zIndex: 50,
        }}
      >
        {monthRows}
      </div>

      <div
        className="flex w-max "
        ref={dayRowsRef}
        style={{
          position: "sticky",
          top: monthRowsRef.current
            ? `${monthRowsRef.current.offsetHeight}px`
            : "0px",
          zIndex: 50,
        }}
      >
        {dayRows}
      </div>
      <DndContext
        modifiers={[restrictToHorizontalAxis]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        collisionDetection={customCollisionDetectionAlgorithm}
      >
        <div
          id="gantt-time-period-cell-container"
          className="relative"
          style={{
            gridColumn: "1/-1",
            display: "grid",
            gridTemplateColumns: `repeat(${
              numExtraDays ? numMonths + 1 : numMonths
            }, 1fr)`,
            paddingLeft: "0.5px",
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          {taskRows}
        </div>
      </DndContext>
      <style>{`
        #gantt-grid-container__time {
          display: grid;
          overflow-x: auto;
          outline: 0.5px solid var(--color-outline);
          overflow-y:hidden
        }
      `}</style>
    </div>
  );
}
export function QuarterViewTimeTable({
  timeRange,
  tasks,
  setTasks,
  zoom,
  phases,
  setPhases,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { selectedPhases } = useAppSelector((state) => state.schedule);

  const quarterRowsRef = useRef(null);
  const dayRowsRef = useRef(null);

  // for dynamic css styling
  const ganttTimePeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: `minmax(${zoom}px, 1fr)`,
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };
  const ganttMonthPeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };
  const ganttDayPeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: `minmax(${1}px, 1fr)`,
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };

  const ganttWeekPeriod: React.CSSProperties = {
    display: "grid",
    gridAutoFlow: "column",
    height: "var(--cell-height)",
    transition: "grid-auto-columns 5s ease-in-out",
  };

  const ganttTimePeriodSpan: React.CSSProperties = {
    margin: "auto",
  };

  const ganttTimePeriodCell: React.CSSProperties = {
    position: "relative",
  };

  // // creating rows
  const startMonth = new Date(parseInt(timeRange.fromSelectYear), 0);
  const endMonth = new Date(parseInt(timeRange.toSelectYear), 11);

  const numMonths = monthDiff(startMonth, endMonth) + 1;
  let month = new Date(startMonth);

  const startYear = dayjs(`${timeRange.fromSelectYear}`, "YYYY");
  const endYear = dayjs(`${timeRange.toSelectYear}`, "YYYY");
  let currentYear = startYear;

  const projectYears = [];
  while (currentYear.isBefore(endYear) || currentYear.isSame(endYear, "year")) {
    projectYears.push(currentYear.format("YYYY"));
    currentYear = currentYear.add(1, "year");
  }
  let monthRows = [];
  let dayRows = [];
  let dayRow = [];
  let taskRows = [];
  let taskRow = [];
  let weekRow = [];
  let weekRows = [];
  let quarterRows = [];
  let phaseRow = [];
  let phaseRows = [];

  const handleResize = (distance, direction, task) => {
    const currentDate = direction === "toRight" ? task.endDate : task.startDate;
    const newDate = calculateNewDate(distance, direction, zoom, currentDate);

    const startDate = direction === "toRight" ? task.startDate : newDate;
    const endDate = direction === "toRight" ? newDate : task.endDate;

    if (task.type && task.type === "sprint") {
      const payload = {
        sprintId: task.id,
        startDate,
        endDate,
      };

      dispatch(updateSprint(payload));
    } else {
      dispatch(
        updatePhase({
          startDate,
          endDate,
          projectId,
          phaseId: task.phase.phaseId,
          projectPhaseId: task.phase.projectPhaseId,
          // projectPhaseId: task.projectPhaseId,
        })
      );
    }
  };

  const handleMonthClick = (currentMonthId, action = "next") => {
    const monthId = action === "next" ? currentMonthId + 1 : currentMonthId - 1;
    const selector = `[data-month-id="${monthId}"]`;
    const month = document.querySelector(selector) as HTMLElement;

    if (month) {
      month.scrollIntoView({
        inline: "start",
        block: "nearest",
        behavior: "smooth",
      });
    }
  };

  const handleTodayClick = () => {
    const selector = `[data-today="true"]`;
    const today = document.querySelector(selector) as HTMLElement;

    if (today) {
      today.scrollIntoView({
        inline: "start",
        block: "end",
        behavior: "smooth",
      });
      const scrollContainer = today.parentElement; // Assuming the parent element is the scrollable container
      if (scrollContainer) {
        scrollContainer.scrollTo({
          left: today.offsetLeft,
          behavior: "smooth", // Optional: Smooth scrolling animation
        });
      }
    }
  };

  useLayoutEffect(() => {
    handleTodayClick();
  }, []);

  let count = 1;
  const numProjectDays = dayDiff(timeRange.fromDate, timeRange.toDate);
  const numDaysInMonths = calculateTotalDays(
    timeRange.fromDate,
    timeRange.toDate
  );
  const numWeeks = Math.ceil(numDaysInMonths / 7);
  const numExtraDays = numWeeks * 7 - numDaysInMonths;

  // const numExtraDays =
  //for rows
  for (let i = 1; i <= numWeeks; i++) {
    weekRows.push(
      <div
        key={i}
        className=" flex   w-[276px] h-[34px] rounded py-2.5 justify-center bg-white"
        style={{
          outline: "none",
          height: "auto",
        }}
        data-month-id={i}
      >
        <div className=" max-w-max   out-500-14 flex text-black">W{i}</div>
      </div>
    );
  }
  const numDays = getDaysInMonth(month.getFullYear(), month.getMonth() + 1);

  for (let i = 0; i < numMonths / 3; i++) {
    quarterRows.push(
      <div
        key={i}
        style={{
          ...ganttMonthPeriod,
          outline: "none",
          height: "34px",
          gridAutoColumns: ` ${160 * 3}px`,
        }}
        className={`${i == 0 ? "ml-[1px]" : ""}`}
        data-month-id={i}
      >
        <div className="relative  w-full out-500-14 flex   bg-white  text-black justify-center items-center  rounded">
          <div className="absolute left-[47%] min-w-[106px]">Q{i + 1}</div>
        </div>
      </div>
    );
  }

  for (let i = 0; i < numMonths; i++) {
    const numDays = getDaysInMonth(month.getFullYear(), month.getMonth() + 1);

    // create month rows

    // create day and week rows
    // const currYear = month.getFullYear();
    // const currMonth = month.getMonth() + 1;

    //count % 7 ==1 are first days of the week and count % 7 ==0 are 7th days of the week

    for (let j = 1; j <= numDays; j++) {
      dayRow.push(
        <div
          key={j}
          className={` bg-gray-200 relative pt-1.5 pb-0.5  flex items-center  ${
            j == 1 ? "rounded-tl-[4px]" : ""
          } ${j == numDays ? "rounded-tr-[4px]" : ""}`}
          style={{ ...ganttDayPeriod, outline: "none", height: "auto" }}
          data-today={dayjs(
            `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
            "D-MMMM-YYYY"
          ).isToday()}
        >
          <Tooltip
            title={`${dayjs(
              `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
              "D-MMMM-YYYY"
            ).format("dddd, MMMM Do YYYY")}`}
          >
            <span
              className={` flex justify-center py-1.5 bg-gray-200  ${
                `${dayjs(
                  `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                  "D-MMMM-YYYY"
                ).isToday()}`
                  ? " mr-[1px] text-black out-500-12 "
                  : "hidden"
              }   `}
            >
              <div
                className={`absolute top-[26px] ${
                  dayjs(
                    `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                    "D-MMMM-YYYY"
                  ).isToday()
                    ? "flex justify-center out-500-10 items-center w-5 h-5 rounded-[30px] bg-primary-500 text-white p-1 z-30"
                    : "hidden"
                } `}
              >
                {/* bg-primary-600  text-white px-[7px] */}

                <span
                  className={`${
                    dayjs(
                      `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                      "D-MMMM-YYYY"
                    ).isToday()
                      ? ""
                      : ""
                  } relative`}
                >
                  {j}
                  {dayjs(
                    `${j}-${months[month.getMonth()]}-${month.getFullYear()}`,
                    "D-MMMM-YYYY"
                  ).isToday() && (
                    <div className="h-screen absolute left-[5px] top-[18px] w-[1px] bg-primary-500 z-10"></div>
                  )}
                </span>
              </div>
            </span>
          </Tooltip>
        </div>
      );
      count++;
    }

    dayRows.push(
      <div
        key={i}
        className="mt-1.5 mr-1   relative"
        style={{
          ...ganttWeekPeriod,
          outline: "none",
          height: "30px",
          // paddingBottom: "5px",
          gridAutoColumns: ` ${158 / numDays}px`,
        }}
      >
        {dayRow}
        <div className="absolute out-500-12 text-gray-700   top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {newMonths[month.getMonth()]}
        </div>
      </div>
    );

    dayRow = [];

    month.setMonth(month.getMonth() + 1);
  }

  // create task rows
  if (tasks && tasks.length) {
    let taskCount = 1;
    const taskPhaseArray = [...tasks, ...phases];

    [...taskPhaseArray].forEach((task, index) => {
      let mnth = new Date(startMonth);
      const totalMonths = numMonths;
      let w = 1;

      while (w <= numWeeks) {
        for (let i = 0; i < totalMonths; i++) {
          const curYear = mnth.getFullYear();
          const curMonth = mnth.getMonth() + 1;
          let numDays = 0;
          if (numExtraDays !== 0 && i === totalMonths - 1) {
            numDays = numExtraDays;
          } else {
            numDays = getDaysInMonth(curYear, curMonth);
          }

          for (let j = 1; j <= numDays; j++) {
            // console.log("current month:", curMonth, "days in month:", numDays);
            // color weekend cells differently
            const dayOfTheWeek = getDayOfWeek(curYear, curMonth - 1, j - 1);
            // add task and date data attributes
            const formattedDate = createFormattedDateFromStr(
              curYear,
              curMonth,
              j
            );
            taskRow.push(
              <DayCell
                isQuarterView
                isMonthView
                isEOW={taskCount % 7 === 0}
                key={`${task?.projectPhaseId || task?.id}-${j}`}
                task={task}
                formattedDate={formattedDate}
                style={{
                  ...ganttTimePeriodCell,

                  backgroundColor: i % 2 == 0 ? "#F9FBFE" : "#F0F5FA",
                }}
                handleResize={(distance, direction) =>
                  handleResize(distance, direction, task)
                }
                cellWidth={160 / numDays}
                // count={taskCount}
              />
            );
            if (taskCount % 7 == 0) w++;
            taskCount++;
          }

          taskRows.push(
            <div
              key={`${i}-${task?.projectPhaseId || task?.id}`}
              style={{
                ...ganttTimePeriod,
                gridAutoColumns: `${160 / numDays}px`,
                height:
                  index === tasks.length - 1
                    ? "80vh"
                    : task.type && task.type === "sprint"
                    ? "var(--cell-height-sprint)"
                    : "var(--cell-height)",
              }}
            >
              {taskRow}
            </div>
          );

          taskRow = [];
          mnth.setMonth(mnth.getMonth() + 1);
        }
      }
      // for (let j = 1; j <= numExtraDays; j++) {
      //   let curMonth = new Date(
      //     parseInt(timeRange.toSelectYear),
      //     timeRange.toSelectMonth + 1
      //   );
      //   let extraMonth = new Date(timeRange.toDate);

      //   // Add one month
      //   extraMonth.setMonth(extraMonth.getMonth() + 1);
      //   const curYear = extraMonth.getFullYear();

      //   // color weekend cells differently
      //   const dayOfTheWeek = getDayOfWeek(
      //     curYear,
      //     extraMonth.getMonth() + 1 - 1,
      //     j - 1
      //   );
      //   // add task and date data attributes
      //   const formattedDate = createFormattedDateFromStr(
      //     curYear,
      //     extraMonth.getMonth() + 1,
      //     j
      //   );
      //   taskRow.push(
      //     <DayCell
      //       key={`${task?.projectPhaseId || task?.id}-${j}`}
      //       task={task}
      //       formattedDate={formattedDate}
      //       style={{
      //         ...ganttTimePeriodCell,
      //         backgroundColor: dayOfTheWeek === "S" ? "#F0F5FA" : "#F9FBFE",
      //       }}
      //       handleResize={(distance, direction) =>
      //         handleResize(distance, direction, task)
      //       }
      //       count={taskCount}
      //     />
      //   );
      //   taskCount++;
      // }
      // taskRows.push(
      //   <div
      //     key={`extra-${task?.projectPhaseId || task?.id}`}
      //     style={{
      //       ...ganttTimePeriod,

      //       height:
      //         index === tasks.length - 1
      //           ? "80vh"
      //           : task.type && task.type === "sprint"
      //           ? "var(--cell-height-sprint)"
      //           : "var(--cell-height)",
      //     }}
      //   >
      //     {taskRow}
      //   </div>
      // );
      // taskRow = [];
    });
  }

  //create phase rows
  // if (phases && phases.length) {
  //   let phaseCount = 1;
  //   console.log(phases, "phases array");
  //   phases.forEach((task, index) => {
  //     let mnth = new Date(startMonth);
  //     const totalMonths = numMonths;
  //     let w = 1;

  //     while (w <= numWeeks) {
  //       for (let i = 0; i < totalMonths; i++) {
  //         const curYear = mnth.getFullYear();
  //         const curMonth = mnth.getMonth() + 1;
  //         let numDays = 0;
  //         if (numExtraDays !== 0 && i === totalMonths - 1) {
  //           numDays = numExtraDays;
  //         } else {
  //           numDays = getDaysInMonth(curYear, curMonth);
  //         }

  //         for (let j = 1; j <= numDays; j++) {
  //           // console.log("current month:", curMonth, "days in month:", numDays);
  //           // color weekend cells differently
  //           const dayOfTheWeek = getDayOfWeek(curYear, curMonth - 1, j - 1);
  //           // add task and date data attributes
  //           const formattedDate = createFormattedDateFromStr(
  //             curYear,
  //             curMonth,
  //             j
  //           );
  //           console.log(task, "phase data 2");
  //           phaseRow.push(
  //             <DayCell
  //               isQuarterView
  //               isMonthView
  //               week={w}
  //               isEOW={phaseCount % 7 === 0}
  //               isFirstRow={j === 1}
  //               key={`${task?.projectPhaseId || task?.id}-${j}`}
  //               task={task}
  //               formattedDate={formattedDate}
  //               style={{
  //                 ...ganttTimePeriodCell,

  //                 backgroundColor: i % 2 == 0 ? "#F9FBFE" : "#F0F5FA",
  //               }}
  //               handleResize={(distance, direction) =>
  //                 handleResize(distance, direction, task)
  //               }
  //               // count={phaseCount}
  //             />
  //           );
  //           if (phaseCount % 7 == 0) w++;
  //           phaseCount++;
  //         }

  //         phaseRows.push(
  //           <div
  //             key={`${i}-${task?.projectPhaseId || task?.id}`}
  //             style={{
  //               ...ganttTimePeriod,
  //               gridAutoColumns: `${160 / numDays}px`,

  //               height:
  //                 index === phases.length - 1
  //                   ? "80vh"
  //                   : task.type && task.type === "sprint"
  //                   ? "var(--cell-height-sprint)"
  //                   : "var(--cell-height)",
  //             }}
  //           >
  //             {phaseRow}
  //           </div>
  //         );
  //         phaseRow = [];
  //         mnth.setMonth(mnth.getMonth() + 1);
  //       }
  //     }
  //   });
  // }

  function handleDragEnd(data) {
    // find task
    const task = data?.active?.data?.current?.task;

    const dataDate = data?.over?.id;

    const daysDuration = dayDiff(task.startDate, task.endDate);

    // get new task values
    // get start, calc end using daysDuration - make Date objects - change taskDurations

    const newStartDate = new Date(dataDate);
    let newEndDate = new Date(dataDate);
    newEndDate.setDate(newEndDate.getDate() + daysDuration - 1);

    const startDate = createFormattedDateFromDate(newStartDate);
    const endDate = createFormattedDateFromDate(newEndDate);

    const newTask = {
      ...task,
      startDate,
      endDate,
    };

    if (task.type && task.type === "sprint") {
      const payload = {
        sprintId: task.id,
        startDate,
        endDate,
      };

      dispatch(updateSprint(payload));
      const currentTaskIndex = tasks.findIndex(
        (t) => t.type === "sprint" && t.id === task.id
      );
      const allTaskCopy = [...tasks];
      allTaskCopy[currentTaskIndex] = newTask;
      setTasks(allTaskCopy);

      // const remainingTasks = tasks.filter((t) => t.id !== task.id);
      // const newTasks = [...remainingTasks, newTask].sort((a, b) => a.id - b.id);
      // setTasks([...newTasks]);
    } else {
      dispatch(
        updatePhase({
          startDate,
          endDate,
          projectId,
          phaseId: task.phase.phaseId,
          projectPhaseId: task.phase.projectPhaseId,
          // projectPhaseId: task.projectPhaseId,
        })
      );
      const currentTaskIndex = tasks.findIndex(
        (t) => t.projectPhaseId == task.projectPhaseId
      );
      const allTaskCopy = [...tasks];
      allTaskCopy[currentTaskIndex] = newTask;
      setTasks(allTaskCopy);
    }
    // data.stopPropagation();
  }

  useEffect(() => {
    const scrollContainer = document.getElementById("gantt-scroll-container");
    const handleScroll = () => {
      if (scrollContainer && quarterRowsRef.current && dayRowsRef.current) {
        const scrollTop = scrollContainer.scrollTop;
        quarterRowsRef.current.style.top = `${scrollTop}px`;
        dayRowsRef.current.style.top = `${
          scrollTop + quarterRowsRef.current.offsetHeight
        }px`;
      }
    };
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div
      className="flex flex-col w-full  px-1.5 bg-gray-100 "
      id="gantt-grid-container__time"
      style={{ overflowY: "hidden", position: "relative", height: "100%" }}
    >
      <div
        ref={quarterRowsRef}
        className="flex w-full bg-gray-100  gap-x-2.5 pt-0 "
        style={{
          gridTemplateColumns: `repeat(${numMonths}, 1fr)`,
          position: "sticky",
          top: 0,
          zIndex: 50,
        }}
      >
        {quarterRows}
      </div>

      <div
        ref={dayRowsRef}
        className="flex w-max "
        style={{
          position: "sticky",
          top: quarterRowsRef.current
            ? `${quarterRowsRef.current.offsetHeight}px`
            : "0px",
          zIndex: 50,
        }}
      >
        {dayRows}
      </div>
      <DndContext
        modifiers={[restrictToHorizontalAxis]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
        collisionDetection={customCollisionDetectionAlgorithm}
      >
        <div
          id="gantt-time-period-cell-container"
          className="relative"
          style={{
            gridColumn: "1/-1",
            display: "grid",
            gridTemplateColumns: `repeat(${numMonths}, 1fr)`,
            paddingLeft: "0.5px",
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          {taskRows}
        </div>
      </DndContext>
      <style>{`
        #gantt-grid-container__time {
          display: grid;
          overflow-x: auto;
          outline: 0.5px solid var(--color-outline);
          overflow-y:hidden
        }
      `}</style>
    </div>
  );
}
