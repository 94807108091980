import React from "react";
import { useDraggable } from "@dnd-kit/core";

const DragSource = ({
  id,
  selectedItems,
  children,
  disabled,
  className = "",
  task,
}) => {
  //unused usedraggable props : transform, isdragging
  const { attributes, transform, listeners, setNodeRef, isDragging, active } =
    useDraggable({
      id: id,
      disabled: disabled,
      data: {
        selectedItems,
        dragId: id,
        task,
      },
    });
  // console.log(
  //   id,
  //   selectedItems,
  //   task,
  //   selectedItems && plannerIsDragActive.isActive,
  //   plannerIsDragActive.isActive,
  //   "dscvsdvsvs"
  // );
  const style =
    selectedItems && active
      ? selectedItems.includes(id)
        ? { opacity: 0, maxHeight: "0px" }
        : {}
      : {};

  // const style = transform
  //   ? {
  //       transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  //     }
  //   : undefined;

  return (
    <div
      style={style as React.CSSProperties}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={className}
      // style={style}
    >
      {children}
    </div>
  );
};

export default DragSource;
