import React from "react";
import CustomModal from "./CustomModal";
import CustomButton from "./CustomButton";
import { RightArrowIcon } from "../svgV2/RightArrow";

const TakeDashboardTourModal = ({ onOk, onClose, module }) => {
  // return (
  //   <div className="p-4 fixed bg-white w-[326px] z-20 bottom-5 right-5 shadow-lg rounded-lg">
  //     <div className="flex justify-between">
  //       <div className="flex items-center gap-x-[14px]">
  //         {" "}
  //         <img src="/images/v2/conversations/frosty.svg" />
  //         <p className="text-black out-500-16">Hey there!</p>
  //       </div>
  //       <button onClick={onClose}>
  //         {" "}
  //         <CrossIcon className="text-gray-700" />
  //       </button>
  //     </div>
  //     <p className="mt-4 out-500-14 text-gray-500 leading-5">
  //       I noticed that you are here for the first time!
  //       <br /> Take a tour to understand {module} better.
  //     </p>
  //     <div className="flex mt-[21px] items-center gap-x-3 justify-start">
  //       <CustomButton
  //         onClick={onOk}
  //         text="Take the tour"
  //         height="30px"
  //         width="102px"
  //       />
  //       <CustomButton
  //         text="I’ll explore on my own"
  //         className="bg-transparent text-gray-500"
  //         height="30px"
  //         width="153px"
  //         onClick={onClose}
  //       />
  //     </div>
  //   </div>
  // );

  return (
    <CustomModal
      visible={true}
      width="524px"
      onCancel={onClose}
      body={
        <div className="flex rounded-2xl bg-[#FFFFFF]">
          <img
            src="/images/v2/dashboard/welcomeTour.svg"
            className="mt-0 absolute rounded-2xl overflow-hidden"
          ></img>
          <img
            src="/images/v2/dashboard/frostyWaving.svg"
            className=" absolute mt-[281px] ml-[80%]"
          ></img>
          <div className="mt-[56px] gap-y-3 fixed ml-[125px] flex flex-col z-20">
            <div className="out-500-30 text-black flex justify-center items-center w-full">
              <p>
                {" "}
                Welcome to <span className="text-primary-500">Waddle!</span>
              </p>
            </div>
            <div className="out-300-14 text-gray-500 flex justify-center items-center">
              {"We're excited to have you on board!"}
            </div>
          </div>
          <div className="mt-[448px] mb-[56px] flex flex-col pl-[74px] gap-y-4">
            <div className="out-300-14 text-gray-500">
              Frosty is here to assist you in setting up your very first
              project.
            </div>
            <div className="flex justify-between items-center  ">
              <CustomButton
                className=" bg-primary-600 text-white  cursor-pointer   "
                height="30px"
                width="171.5px"
                text="Take the tour"
                icon={<RightArrowIcon className="text-white" />}
                iconAlignment="right"
                onClick={onOk}
              />
              <CustomButton
                text="I’ll explore on my own "
                className="bg-transparent text-gray-500 cursor-pointer"
                height="30px"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default TakeDashboardTourModal;
