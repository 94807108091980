import React from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useParams } from "react-router-dom";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import { getTodoList } from "../../../Core/redux/api/scheduleAPI";

const MyTaskFilterButton = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { projectId } = useParams();
  const { filterGoalParameter, isMyTasks } = useAppSelector(
    (state) => state.schedule
  );

  const dispatch = useAppDispatch();

  const handleMyTask = () => {
    dispatch(
      updateSchedule({
        key: "filterGoalParameter",
        value: {
          ...filterGoalParameter,
          assignee: [],
        },
      })
    );
    dispatch(
      updateSchedule({
        key: "isMyTasks",
        value: !isMyTasks,
      })
    );
  };
  // const handleMyTask = () => {
  //   const { assignee } = filterGoalParameter;
  //   //final goal : add current user in assignnee array, if he exists remove him from the array

  //   //creating a copy of the assignee object as we cannot modify on a read only object
  //   let allProjectAssigneeFilter = [...assignee];

  //   //creating a copy of the current project filter object
  //   const previousFilters = allProjectAssigneeFilter?.find(
  //     (ele) => +ele.projectId === +projectId
  //   );
  //   let currentProjectFilters =
  //     previousFilters !== undefined
  //       ? JSON.parse(JSON.stringify(previousFilters))
  //       : [];

  //   if (currentProjectFilters !== undefined) {
  //     //if there is any filter on the current project , check if the assignee array includes the current user
  //     const isMyTaskSelected = currentProjectFilters?.assignee?.includes(
  //       user.id
  //     );

  //     let currentProjectAssigneeArray = currentProjectFilters?.assignee
  //       ? currentProjectFilters?.assignee
  //       : [];
  //     if (isMyTaskSelected) {
  //       //if the user filter existed , remove it
  //       currentProjectAssigneeArray = currentProjectFilters?.assignee?.filter(
  //         (assigneeId) => +assigneeId !== +user.id
  //       );
  //     } else {
  //       //if the user filter did not exist add it

  //       currentProjectAssigneeArray.push(user.id);
  //     }

  //     //create the new assignee object for current project
  //     currentProjectFilters = {
  //       projectId,
  //       assignee: currentProjectAssigneeArray,
  //     };
  //     //remove the current project assignee filter from filtergoalparameter object
  //     allProjectAssigneeFilter = allProjectAssigneeFilter?.filter(
  //       (ele) => +ele.projectId !== +projectId
  //     );
  //   } else {
  //     //create the new assignee object for current project
  //     currentProjectFilters = { projectId, assignee: [user.id] };
  //   }
  //   //push the updated array
  //   allProjectAssigneeFilter.push(currentProjectFilters);
  //   const payload: {
  //     limit: number;
  //     offset: number;
  //     searchQuery?: string;
  //     projectId: string;
  //     assignee?: any;
  //   } = {
  //     limit: DEFAULT_LIMIT,
  //     offset: DEFAULT_OFFSET,
  //     projectId,
  //   };
  //   payload.searchQuery =
  //     filterGoalParameter?.searchValue?.projectId === projectId &&
  //     filterGoalParameter?.searchValue?.module === "planner"
  //       ? filterGoalParameter?.searchValue?.searchValue?.toLowerCase()
  //       : "";
  //   payload.assignee = currentProjectFilters?.assignee;
  //   dispatch(getTodoList(payload));
  //   dispatch(
  //     updateSchedule({
  //       key: "filterGoalParameter",
  //       value: {
  //         ...filterGoalParameter,
  //         assignee: allProjectAssigneeFilter,
  //       },
  //     })
  //   );
  //   dispatch(
  //     updateSchedule({
  //       key: "isMyTasks",
  //       value: !isMyTasks,
  //     })
  //   );
  // };
  //checking if there is any filter on the current project
  const currentProjectFilter = filterGoalParameter?.assignee?.find(
    (filter) => +filter.projectId === +projectId
  );
  //checking if myTask button is created

  return (
    <CustomButton
      width="74px"
      className={`bg-transparent out-500-14 ${
        isMyTasks ? "text-primary-600" : "text-gray-500"
      } `}
      text="My tasks"
      onClick={handleMyTask}
    />
  );
};

export default MyTaskFilterButton;
