import React from "react";
import Icon from "@ant-design/icons";

// InfoSvg Component (Existing)
const InfoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 11.2v-4"
    ></path>
    <circle cx="8" cy="8.002" r="6.4" stroke="currentColor"></circle>
    <path fill="currentColor" d="M8.4 5.2a.4.4 0 11-.8 0 .4.4 0 01.8 0z"></path>
  </svg>
);

// InfoIcon Component (Using Ant Design Icon Wrapper)
const InfoIcon = (props) => {
  return <Icon component={InfoSvg} {...props} />;
};

// ReportsInfoSvg Component (New Icon)
const ReportsInfoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M8 14.666c3.666 0 6.666-3 6.666-6.666 0-3.667-3-6.667-6.666-6.667-3.667 0-6.667 3-6.667 6.667 0 3.666 3 6.666 6.667 6.666zM8 4.75h.009M8 7.25v4"
    ></path>
  </svg>
);

// ReportsInfoIcon Component (New Icon using Ant Design Icon Wrapper)
const ReportsInfoIcon = (props) => {
  return <Icon component={ReportsInfoSvg} {...props} />;
};

// Export both icons
export { InfoIcon, ReportsInfoIcon };
