import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getTenantProjects = createAsyncThunk(
  "timeEstimates/getTenantProjects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get(`/time-estimate/get-all-projects`);

      return response.data.projects;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const deleteAssignees = createAsyncThunk(
  "timeEstimates/deleteAssignees",
  async (
    deletions: {
      weekId: string;
      assigneeId: string;
      projectId: string;
    }[],
    { rejectWithValue }
  ) => {
    try {
      const response = await client.delete("/time-estimate/delete-assignee", {
        data: deletions,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const addAssignees = createAsyncThunk(
  "timeEstimates/addAssignees",
  async (
    assigneesPayload: {
      projectId: string;
      weekId: string;
      assignees: { userId: string; noOfDays: number; noOfHours: number }[];
    }[],
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post(
        `/time-estimate/add-assignee`,
        assigneesPayload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const addProjectWeeks = createAsyncThunk(
  "timeEstimates/addWeek",
  async (
    {
      projectId,
      weeks,
    }: {
      projectId: number;
      weeks: {
        id: string;
        week: string;
        startDate: string;
        endDate: string;
        assignees: { userId: number; days: number; hours: number }[];
      }[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post("/time-estimate/add-week", {
        projectId,
        weeks,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const updateAssignees = createAsyncThunk(
  "timeEstimates/updateAssignees",
  async (
    updates: {
      projectId: number;
      weekId: string;
      assignees: {
        id: string;
        userId: string;
        noOfDays: number;
        noOfHours: number;
      }[];
    }[],
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(
        "/time-estimate/update-assignee",
        updates
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const updateWeeks = createAsyncThunk(
  "timeEstimates/updateWeeks",
  async (
    {
      projectId,
      existingWeeks,
    }: {
      projectId: number;
      existingWeeks: {
        id: string;
        week: string;
        startDate: string;
        endDate: string;
        assignees: { userId: string; noOfDays: number; noOfHours: number }[];
      }[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put("/time-estimate/update-week", {
        projectId,
        existingWeeks,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const deleteWeeks = createAsyncThunk(
  "timeEstimates/deleteWeeks",
  async (
    {
      projectId,
      deletedWeeks,
    }: {
      projectId: number;
      deletedWeeks: {
        id: string;
      }[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.delete("/time-estimate/delete-week", {
        data: {
          projectId,
          deletedWeeks,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const editProjectDates = createAsyncThunk(
  "projects/editProjectDates",
  async (
    {
      projectId,
      updateObj,
    }: {
      projectId: number;
      updateObj: {
        startDate?: string;
        endDate?: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(`/time-estimate/edit-project-date`, {
        projectId,
        updateObj,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);
