import React from "react";
import Icon from "@ant-design/icons";

const CopySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#667085"
      strokeLinejoin="round"
      d="M2.8 6H10v7.2H2.8V6z"
    ></path>
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.6 2.8H6v1.6m5.6-1.6h1.6v1.6m0 4v1.2h-1.6M9.2 2.8h.8M13.2 6v.8"
    ></path>
  </svg>
);

const CopyIcon = (props) => <Icon component={CopySvg} {...props} />;

export { CopyIcon };
