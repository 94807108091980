import React, { useEffect, useState } from "react";
import { CrossIcon } from "../../../../Core/svgV2/CrossIcon";
import CustomModal from "../../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../../Core/CommonV2/CustomButton";
import GoogleLogin from "react-google-login";
import { client } from "../../../../Core/utils/axiosClient";
import { gapi } from "gapi-script";
import { useAppSelector } from "../../../../Core/redux/hooks";

const GoogleCalendarModal = ({ visible, onClose }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const SCOPE = "openid email profile https://www.googleapis.com/auth/calendar";

  const [isConnected, setIsConnected] = useState(false);

  const checkIfgoogleCalenderIsIntegrated = async () => {
    try {
      const { data } = await client.get(
        "/integration/calender/refresh-token-status",
        {
          params: { userId: user.id },
        }
      );
      if (data.value) {
        setIsConnected(true);
      }
    } catch (e) {
      setIsConnected(false);
    }
  };
  const removeGoogleCalendarIntegration = async () => {
    try {
      const { data } = await client.get(
        "/integration/calender/remove-refresh-token",
        {
          params: { userId: user.id },
        }
      );

      if (!data.value) {
        setIsConnected(false);
      }
    } catch (e) {
      setIsConnected(true);
    }
  };
  const responseGoogle = async (response) => {
    console.log(response);
    try {
      const { data } = await client.post(
        "/integration/calender/create-tokens",
        {
          code: response.code,
        }
      );
      await checkIfgoogleCalenderIsIntegrated();
    } catch (e) {
      console.log(e);
    }
  };
  const responseError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    function start() {
      gapi.client?.init({
        clientId: CLIENT_ID,
        scope: SCOPE,
      });
    }

    gapi.load("client:auth2", start);
    checkIfgoogleCalenderIsIntegrated();
  }, []);

  useEffect(() => {
    checkIfgoogleCalenderIsIntegrated();
  }, [isConnected, visible]);

  useEffect(() => {
    console.log("");
  }, [isConnected]);

  return (
    <CustomModal
      visible={visible}
      width="667px"
      onCancel={() => onClose()}
      body={
        <div className="p-4 flex flex-col gap-y-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Google Calendar</p>

            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>

          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between items-center">
              <p className="out-300-14 text-gray-500">
                Integrate with Google Calendar
              </p>
              {isConnected ? (
                <CustomButton
                  text="Disconnect"
                  height="30px"
                  onClick={async () => await removeGoogleCalendarIntegration()}
                />
              ) : (
                <GoogleLogin
                  clientId={CLIENT_ID}
                  render={(renderProps) => (
                    <CustomButton
                      text="Connect"
                      height="30px"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    />
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseError}
                  cookiePolicy={"single_host_origin"}
                  responseType="code"
                  accessType="offline"
                  scope={SCOPE}
                  uxMode="redirect"
                />
              )}
            </div>

            <div className="border-solid border-gray-200 relative w-full h-px  border-t  rounded-none" />

            <p className="text-black out-500-14">Features</p>

            <p className="out-300-14 text-gray-500">
              With this integration you can easily manage your events, get
              reminders on upcoming events and create new events directly from
              here.
            </p>

            <div className="flex justify-end">
              <CustomButton
                text="Close"
                className="bg-white text-gray-500"
                height="30px"
                onClick={() => onClose()}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default GoogleCalendarModal;
