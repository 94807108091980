import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import CustomDatePicker from "../../../Core/CommonV2/CustomDatePicker";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import dayjs from "dayjs";
import { useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import {
  addProjectPhase,
  getProjectPhases,
} from "../../../Core/redux/api/scheduleAPI";
import { useQueryClient } from "react-query";
import { useGetProjectPhases } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";

const PhaseModal = ({ visible, onClose }) => {
  const { projectDetails } = useAppSelector((state) => state.overview);
  const { projectId: projectIdParam } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const {
    data: phases,
    isLoading,
    isError,
    error,
  } = useGetProjectPhases(projectIdParam);

  const { handleSubmit, control, formState, getValues } = useForm({
    values: {
      phase: null,
      phaseStartDate: null,
      phaseEndDate: null,
    },
  });

  const getDayDiff = (startDate, endDate) => {
    if (startDate && endDate) {
      return endDate.diff(startDate, "days");
    }
    return 0;
  };
  const getIterationNumber = (id) => {
    const currentCount = phases?.filter((p) => p.phaseId == id).length;
    if (currentCount === 0) return "";
    return currentCount + 1;
  };

  const option = [
    {
      id: 1,
      value: 1,
      label: `Ideation`,
      render: (value) => (
        <div className="flex items-center h-full ">
          <PhaseIcon phaseId={1} width="16" height="16" />
          <p className="pl-2 out-300-14 leading-5 text-black capitalize">
            Ideation {getIterationNumber(1)}
          </p>
        </div>
      ),
    },
    {
      id: 2,
      value: 2,
      label: "Design",
      render: (value) => (
        <div className="flex items-center h-full">
          <PhaseIcon phaseId={2} width="16" height="16" />
          <p className="pl-2 out-300-14 leading-5 text-black capitalize">
            Design {getIterationNumber(2)}
          </p>
        </div>
      ),
    },
    {
      id: 3,
      value: 3,
      label: "Development",
      render: (value) => (
        <div className="flex items-center h-full">
          <PhaseIcon phaseId={3} width="16" height="16" />
          <p className="pl-2 out-300-14 leading-5 text-black capitalize">
            Development {getIterationNumber(3)}
          </p>
        </div>
      ),
    },
    {
      id: 4,
      value: 4,
      label: "Development",
      render: (value) => (
        <div className="flex items-center h-full">
          <PhaseIcon phaseId={4} width="16" height="16" />
          <p className="pl-2 out-300-14 leading-5 text-black capitalize">
            Deployment {getIterationNumber(4)}
          </p>
        </div>
      ),
    },
    {
      id: 5,
      value: 5,
      label: "Maintenance",
      render: (value) => (
        <div className="flex items-center h-full">
          <PhaseIcon phaseId={5} width="16" height="16" />
          <p className="pl-2 out-300-14 leading-5 text-black capitalize">
            Maintenance {getIterationNumber(5)}
          </p>
        </div>
      ),
    },
  ];
  const onSubmit = async (data) => {
    const iterationNumber = getIterationNumber(data.phase);
    const projectId = projectIdParam || searchParams.get("projectId");
    const payload = {
      phaseId: data.phase,
      iterationNumber: iterationNumber === "" ? null : iterationNumber,
      startDate: data.phaseStartDate
        ? data.phaseStartDate.format("YYYY-MM-DD")
        : "",
      endDate: data.phaseEndDate ? data.phaseEndDate.format("YYYY-MM-DD") : "",
      projectId,
    };

    await dispatch(addProjectPhase(payload));
    dispatch(getProjectPhases({ projectId }));
    queryClient.invalidateQueries(["projectPhases", projectId]);
    onClose();
  };

  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => onClose()}
      body={
        <div className="py-3 px-4">
          <div>
            <h3 className="out-500-14 text-black">New Phase</h3>

            <div className="pt-4">
              <Controller
                name={"phase"}
                control={control}
                rules={{ required: "Please select the phase" }}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    placeholder="Select phase"
                    options={option}
                    formState={formState}
                    // onChange={(e) => {
                    //   setPhase(e);
                    // }}
                  />
                )}
              />
            </div>
            <div className="pt-4">
              <Controller
                name={"phaseStartDate"}
                control={control}
                rules={{ required: "Please select phase start date" }}
                render={({ field }) => (
                  <CustomDatePicker
                    {...field}
                    className="w-full bg-transparent  rounded-[4px] border-gray-200"
                    format="DD MMM YYYY"
                    placeholder="Start date"
                    // value={phaseStartDate}
                    // onChange={(value) => setPhaseStartDate(value)}
                    formState={formState}
                    suffixIcon={<></>}
                    disabledDate={(current) =>
                      dayjs(current).isBefore(projectDetails.startDate) ||
                      current.isAfter(projectDetails.endDate)
                    }
                  />
                )}
              />
            </div>
            <div className="pt-4">
              <Controller
                name={"phaseEndDate"}
                control={control}
                rules={{ required: "Please select phase end date" }}
                render={({ field }) => (
                  <CustomDatePicker
                    {...field}
                    className="w-full  bg-transparent rounded-[4px] border-gray-200"
                    format="DD MMM YYYY"
                    placeholder="End date"
                    suffixIcon={<></>}
                    formState={formState}
                    // value={phaseEndDate}
                    // onChange={(value) => setPhaseEndDate(value)}
                    disabledDate={(current) =>
                      dayjs(current).isBefore(projectDetails.startDate) ||
                      current.isAfter(projectDetails.endDate)
                    }
                  />
                )}
              />
            </div>
            <div className="mt-4">
              <h4 className="out-500-14 text-black">Duration</h4>
              <div className="mt-3 flex justify-between">
                <p className="out-300-14 text-gray-500 ">
                  {getDayDiff(
                    getValues("phaseStartDate"),
                    getValues("phaseEndDate")
                  )}{" "}
                  days
                </p>
              </div>
            </div>
          </div>
          <div className="flex mt-[33px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Create"
              height="30px"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      }
    />
  );
};

export default PhaseModal;
