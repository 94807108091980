import React, { useState } from "react";
import CustomInputBox from "../../Core/CommonV2/CustomInputBox";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Core/CommonV2/CustomButton";
import Spinner from "../../Core/svg/Spinner";
import { client } from "../../Core/utils/axiosClient";
import RightScreenV2 from "./RightScreen";

// type Props = {};

const ForgotPasswordScreenV2 = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const [checkingEmail, setCheckingEmail] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const checkIfEmailValid = async (email, callback) => {
    console.log(email);

    try {
      formik.setFieldValue("checkingEmail", true);
      setCheckingEmail(true);

      const response = await client.get(`/auth/check-if-email-exists`, {
        params: {
          email,
        },
      });
      const { exists } = response.data;
      if (exists) {
        setCheckingEmail(false);
        if (typeof callback === "function") callback();
      } else {
        console.log("hehe");
        setIsEmailValid(false);
      }
    } catch (error) {
      console.log("eheehe");
      //return error;
    } finally {
      setCheckingEmail(false);
    }
  };

  const sendForgotPasswordEmail = async (email, callback) => {
    console.log(email);

    const response = await client.post(`/auth/forgot-password`, {
      email,
    });
    if (typeof callback === "function") callback();
  };

  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
      showPassword: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
    }),
    onSubmit: (values) => {
      console.log(values);

      const { email, password } = values;

      switch (step) {
        case 1:
          checkIfEmailValid(email, () => {
            sendForgotPasswordEmail(email, () => {
              setStep(2);
            });
          });
          // setStep(2);
          break;
        default:
          return;
      }
    },
  });

  const renderBody = () => {
    switch (step) {
      case 1:
        return (
          <div className=" flex flex-col h-[210px] gap-y-5.5">
            <div className="h-[80px] flex justify-between w-full">
              <div className="min-w-[223px] w-[70%] gap-y-1 flex flex-col h-[76px] justify-between  ">
                <div className="out-600-24 text-primary-600 h-[32px]">
                  Forgot Password?
                </div>
                <div className=" out-300-14 text-gray-500  ">
                  Enter your email and you will receive a link to reset your
                  password.
                </div>
              </div>
              <div className="h-[80px]  w-[58px]  ">
                <img
                  className="h-[80px]"
                  alt="img"
                  src={"/images/v2/onboarding/forgot-password.svg"}
                />
              </div>
            </div>
            <div className=" flex h-[30px] items-center justify-between w-[115px]">
              <div className="out-400-14 text-gray-500 mr-2  ">Or</div>
              <div>
                <CustomButton
                  text="Sign in"
                  className=" bg-inherit border-gray-300 border-[1px]"
                  height="30px"
                  width="90px"
                  onClick={() => navigate("/loginV2")}
                />
              </div>
            </div>
            <div className="w-full h-[56px]">
              <div className="out-500-14">Email</div>
              <form
                autoComplete="off"
                // style={{ width: 520 }}
                onSubmit={formik.handleSubmit}
                id="formik"
                className=""
              >
                <CustomInputBox
                  name={"email"}
                  type="email"
                  placeholder="Enter your work email"
                  {...formik.getFieldProps("email")}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    formik.setFieldValue("email", e.target.value);
                    if (!isEmailValid) setIsEmailValid(true);
                  }}
                  formikHook={formik}
                  className="mt-1.5 bg-transparent"
                  wrapperClassName="bg-transparent"
                  suffix={checkingEmail ? <Spinner color="black" spin /> : ""}
                  showError={!isEmailValid}
                  errorText={
                    !isEmailValid
                      ? "Email in not valid. Please check email."
                      : undefined
                  }
                />
                <CustomButton
                  text="Send me the link"
                  type="submit"
                  height="36px"
                  width="100%"
                  className={`text-white ${
                    !(formik.isValid && formik.dirty)
                      ? "bg-primary-100"
                      : "bg-primary-600"
                  } out-500-14 mt-12`}
                  disabled={!(formik.isValid && formik.dirty)}
                />
              </form>
              <div className="flex items-center justify-between mt-[26px]">
                <div className="out-300-14 text-gray-500">
                  {"Don't have an account?"}
                </div>
                <div>
                  <CustomButton
                    text="Sign Up"
                    className="text-gray-700  bg-inherit border-gray-300 border-[1px]  out-500-14"
                    height="30px"
                    width="90px"
                    onClick={() => navigate("/signupV2")}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className=" flex flex-col h-[407px] gap-y-5.5">
            <div className="h-[80px] flex justify-between w-full">
              <div className="min-w-[223px] w-[70%] gap-y-1 flex flex-col h-[76px] justify-between  ">
                <div className="out-600-24 text-primary-600 h-[32px]">
                  Reset link sent!
                </div>
                <div className=" out-300-14 text-gray-500  ">
                  Check your inbox! We’ve sent an email with a link.
                </div>
              </div>
              <div className="h-[80px]  w-[58px]  ">
                <img
                  className="h-[80px]"
                  alt="img"
                  src={"/images/v2/onboarding/resent-link.svg"}
                />
              </div>
            </div>
            <div className=" flex h-[30px] items-center justify-between w-[115px]">
              <div className="out-400-14 text-gray-500 mr-2  ">Or</div>
              <div>
                <CustomButton
                  text="Sign in"
                  className=" bg-inherit border-gray-300 border-[1px]"
                  height="30px"
                  width="90px"
                  onClick={() => navigate("/loginV2")}
                />
              </div>
            </div>
            <div className="w-full h-[56px]">
              <div className="flex items-center justify-between mt-[26px]">
                <div className="out-300-14 text-gray-500">
                  {"Don't have an account?"}
                </div>
                <div>
                  <CustomButton
                    text="Sign Up"
                    className="text-gray-700  bg-inherit border-gray-300 border-[1px]  out-500-14"
                    height="30px"
                    width="90px"
                    onClick={() => navigate("/signupV2")}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="flex w-full h-screen">
      <div className="flex-[6] bg-blue-50">
        <RightScreenV2 />
      </div>
      <div className="flex-[4] flex-center h-screen overflow-y-auto ">
        <div className="w-[407px] h-[414px] flex flex-col justify-start p-6  mt-[50px] mx-auto">
          {renderBody()}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreenV2;
