import React, { useState, useEffect, useMemo } from "react";

const useSearch = (ListData, filterKeys) => {
  const [search, setSearch] = useState("");

  const filteredData = useMemo(() => {
    const handleSearchFilter = () => {
      const isNegativeSearch = search.includes("-");
      const sanitizedSearch = search.replace("-", "").trim().toLowerCase();
      if (!ListData) return [];
      if (!(ListData.length > 0)) return [];
      return ListData.filter((item) => {
        return filterKeys.some((key) => {
          const value = item[key];
          const lowercaseValue = value ? value.toLowerCase() : "";

          if (isNegativeSearch) {
            return !lowercaseValue.includes(sanitizedSearch);
          } else {
            return lowercaseValue.includes(sanitizedSearch);
          }
        });
      });
    };

    return handleSearchFilter();
  }, [search, ListData, filterKeys]);

  return { search, setSearch, filteredData };
};

export default useSearch;
