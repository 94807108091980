import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { BarLineChartIcon } from "../../Core/svgV2/BarLineChart";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import CompleteProjectModal from "./Modals/CompleteProjectModal";
import CompleteProjectSuccessModal from "./Modals/CompleteProjectSuccessModal";

import ErrorMessageModal from "./Modals/ErrorMessageModal";
import { Popover, Tooltip } from "antd";

import { CheckIcon } from "../../Core/svgV2/Check";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import usePermission from "../../Core/hooks/usePermission";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import useSearch from "../../Core/hooks/useSearch";
import { Chart, ArcElement } from "chart.js";
import CompleteSprintModal from "../Schedule/Modals/CompleteSprintModal";
import { resetSchedule } from "../../Core/redux/slices/schedule.slice";

Chart.register(ArcElement);

export function getProjectStatusV2(
  startDate,
  endDate,
  currentDate,
  projectProgress,
  projectStatus,
  projectDetails
) {
  // Convert input dates to Day.js objects
  startDate = dayjs(startDate);
  endDate = dayjs(endDate);
  currentDate = dayjs(currentDate);

  if (projectProgress === 100 || projectDetails?.isEnded) {
    return "Completed";
  }

  //if dates are invalid
  if (!startDate.isValid() || !endDate.isValid() || !currentDate.isValid()) {
    return "Invalid Date";
  }
  console.log(projectStatus, projectStatus === "Stuck");
  // Check if end date is before start date
  if (endDate.isBefore(startDate)) {
    return "Invalid Date Range";
  }

  if (currentDate.isAfter(endDate) || projectStatus === "Stuck") {
    return "Breaks On";
  } else if (projectStatus === "Invalid") {
    return "Error";
  } else {
    return projectStatus;
  }
}

const ProjectProgress = ({
  projectDetails,
  projectList,
}: {
  projectDetails;
  projectList;
}) => {
  //requires title,project progress of current projcect
  const { projectId } = useParams();
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { completeSprintModal } = useAppSelector((state) => state.schedule);
  console.log(projectDetails, "projectDetails");
  const { title, progress, projectStatus, isEnded, startDate, endDate } =
    projectDetails.projectDetails;
  const ongoingPhases = projectList
    ?.find((project) => project.id === projectId)
    ?.phases?.filter((phase) => phase.isOngoing === 1).length;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let backgroundColor;
  let frostyHelpBgColor;
  let frostyHelpText;
  let backgroundText;
  const {
    errorMessageModal,
    completeProjectModal,
    completeProjectSuccessModal,
  } = useAppSelector((state) => state.dashboard);
  // startDate,
  // endDate,
  // currentDate,
  // projectProgress
  const finalProjectStatus = getProjectStatusV2(
    startDate,
    endDate,
    dayjs(),
    progress,
    projectStatus,
    projectDetails
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //ebrahim asked to make this change for Edulution Project
  // if (
  //   tenantDetails.tenantId === "3fb2a4e6-f736-4209-aceb-05e77d19b808" &&
  //   +projectId === 14
  // ) {
  //   backgroundColor = "#165ABF";
  //   frostyHelpBgColor = "#EFF8FF";
  //   frostyHelpText = "Project is on track to be completed as planned.";
  //   backgroundText = "On Track";
  // } else {
  switch (finalProjectStatus) {
    case "On Track":
      (backgroundColor = "#165ABF"),
        (frostyHelpBgColor = "#EFF8FF"),
        (frostyHelpText = "Project is on track to be completed as planned."),
        (backgroundText = "On Track");
      break;
    case "Potential Delay":
      (backgroundColor = "#EC4A0A"),
        (frostyHelpBgColor = "#FFF6ED"),
        (frostyHelpText =
          "Complete all your pending goals as soon as possible to avoid  a potential delay"),
        (backgroundText = "delay");
      break;
    case "Manageable Delay":
      (backgroundColor = "#DC6803"),
        (frostyHelpBgColor = "#FFFAEB"),
        (frostyHelpText =
          "Complete all your assigned goals to avoid any delay in project"),
        (backgroundText = "delay");
      break;
    case "Extreme Delay":
      (backgroundColor = "#D92D20"),
        (frostyHelpBgColor = "#FEF3F2"),
        (frostyHelpText =
          "Project is delayed beyond repair, ask your PM to set a new end date"),
        (backgroundText = "delay");
      break;
    case "Breaks On":
      (backgroundColor = "#667085"),
        (frostyHelpBgColor = "#EDF3F9"),
        (frostyHelpText =
          "Project is on hold. Restart one or more phases to continue"),
        (backgroundText = "on hold");
      break;
    case "Completed":
      (backgroundColor = "#144EA6"),
        (frostyHelpBgColor = "#EFF8FF"),
        (frostyHelpText = `Project was completed on ${dayjs(endDate).format(
          "MMMM DD YYYY"
        )}`),
        (backgroundText = "completed");
      break;
    default:
      (backgroundColor = "#667085"),
        (frostyHelpBgColor = "#EDF3F9"),
        (frostyHelpText = projectStatus),
        (backgroundText = "N/A");
      break;
  }
  // }
  const data = {
    datasets: [
      {
        data: [progress, 100 - progress],
        backgroundColor: [`${backgroundColor}`, "#EDF3F9"],
        borderColor: ["F9FBFE", "#F9FBFE"],
        borderWidth: 0,
      },
    ],
  };
  const centerText = {
    id: "centerText",
    beforeDatasetsDraw: function (chart, args, options) {
      const {
        ctx,
        chartArea: { left, top, right, bottom, width, height },
      } = chart;
      ctx.save();
      ctx.font = "bold 15px Poppins";
      ctx.fillStyle = "#12B76A";
      ctx.fillText("78%", 100, 100);
    },
  };
  const options = {
    spacing: 2,
    cutout: "65%",

    borderWidth: 0,
    borderRadius: 3,
    maintainAspectRatio: false,
    events: [],
    plugins: {
      ...centerText,
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const groupItems = [];
  projectList.slice(0, 10).map((project, index) => {
    groupItems.push({
      key: index,
      label: (
        <div
          onClick={() => {
            navigate(`/dashboard/${project.id}`);
            setIsDropdownOpen(false);
          }}
          className={`w-full flex justify-between items-center ${
            project.id === projectId ? "bg-gray-50" : ""
          }`}
        >
          <div className="out-300-14 leading-5 text-gray-700">
            {project.title}
          </div>
          {project.id === projectId ? (
            <CheckIcon className="text-primary-600" />
          ) : (
            ""
          )}
        </div>
      ),
    });
  });

  if (projectList.length > 10) {
    groupItems.push({
      key: groupItems.length - 1,
      label: (
        <div
          onClick={() => navigate("/dashboard?showAllProjects=1")}
          className="w-full flex justify-between items-center"
        >
          <div className="out-300-14 leading-5 text-primary-600">
            View all projects
          </div>
        </div>
      ),
    });
  }

  const { hasPermission } = usePermission("1");
  const { search, setSearch, filteredData } = useSearch(projectList, ["title"]);

  return (
    <div className="w-[40%]  h-[374px] border-r border-b ">
      <div className="flex justify-between w-[full]  ">
        <div className="flex items-center  pt-[24px] pl-[26px]  pr-4 w-full justify-between">
          <Popover
            open={isDropdownOpen}
            placement="bottomLeft"
            onOpenChange={(open) => {
              setSearch("");
              setIsDropdownOpen(open);
            }}
            content={
              <div className="w-[320px] shadow-md rounded-md bg-white border border-gray-100">
                <div className="p-2 px-4">
                  <CustomSearchBox search={search} setSearch={setSearch} />
                </div>
                <div className="max-h-[200px] overflow-y-auto">
                  {filteredData.map((project) => (
                    <div
                      key={project.id}
                      onClick={() => {
                        navigate(`/dashboard/${project.id}`);
                        setIsDropdownOpen(false);
                      }}
                      className={`w-full cursor-pointer hover:bg-gray-50 flex justify-between items-center py-2 px-4 ${
                        project.id === projectId ? "bg-gray-50" : ""
                      }`}
                    >
                      <div className="out-300-14 leading-5 text-gray-700">
                        {project.title}
                      </div>
                      {project.id === projectId ? (
                        <CheckIcon className="text-primary-600" />
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
                <div
                  onClick={() => navigate("/dashboard?showAllProjects=1")}
                  className="w-full py-3 px-4 flex justify-between items-center"
                >
                  <div className="out-300-14 leading-5 text-primary-600">
                    View all projects
                  </div>
                </div>
              </div>
            }
            arrow={false}
            trigger={["click"]}
          >
            <button onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <div className="flex">
                {/* <div className="out-500-14 flex items-center gap-x-2 text-black"> */}
                <div className=" h-[24px] mr-[8px] out-500-16">
                  {title.charAt(0).toUpperCase()}
                  {title.slice(1)}
                </div>
                <ChevronIcon
                  className="text-black"
                  style={{ transform: isDropdownOpen ? "rotate(180deg)" : "" }}
                />
              </div>
            </button>
          </Popover>

          {isEnded ? (
            <div className="text-success-600 out-500-14">Completed</div>
          ) : (
            hasPermission && (
              <button
                className={`text-primary-700 out-500-14 cursor-pointer ${
                  errorMessageModal.visible ? "underline" : ""
                }`}
                onClick={() => {
                  if (ongoingPhases > 0) {
                    dispatch(
                      updateDashboard({
                        key: "errorMessageModal",
                        value: { visible: true, type: "project" },
                      })
                    );
                  } else {
                    dispatch(
                      updateDashboard({
                        key: "completeProjectModal",
                        value: {
                          visible: true,
                          edit: false,
                          action: "end",
                          projectId: projectId,
                        },
                      })
                    );
                  }
                }}
              >
                Complete Project
              </button>
            )
          )}
        </div>
      </div>
      <div
        className="flex relative mx-auto items-center justify-center w-[190px] h-[190px] mt-[15px]  "
        id="centerText"
      >
        <Doughnut
          data={data as any}
          width={172}
          height={172}
          options={options}

          // plugins={plugins}
        />
        <div
          className="absolute items-center justify-center flex flex-col"
          style={{ color: backgroundColor }}
        >
          <div className={`flex   items-center justify-center`}>
            <p className="out-600-24">
              {progress ? Math.round(progress) : "0"}
              <span className="out-300-12">%</span>
            </p>
          </div>
          <div className="out-500-14">{backgroundText.toUpperCase()}</div>
        </div>
      </div>
      <div
        className={`w-[90%] h-[86px]  mt-[24px] flex flex-col mx-auto p-[8px] gap-y-[10px] `}
        style={{ backgroundColor: frostyHelpBgColor }}
      >
        <div className="h-[20px] flex ">
          <BarLineChartIcon className="text-gray-700 mr-[12px] " />

          <div className="out-500-14">
            {
              // tenantDetails.tenantId ===
              //   "3fb2a4e6-f736-4209-aceb-05e77d19b808" && +projectId === 14
              //   ? "On Track"
              //   :
              projectStatus === "Stuck" ? "Breaks On" : projectStatus
            }
          </div>
        </div>
        <div className="text-gray-500 out-300-14 ">{frostyHelpText}</div>
      </div>
      {errorMessageModal && <ErrorMessageModal status={0} />}
      {completeProjectModal && <CompleteProjectModal />}
      {/* {completePhaseModal && <CompletePhaseModal edit={false} />} */}
      {completeProjectSuccessModal && <CompleteProjectSuccessModal />}
      {
        <CompleteSprintModal
          visible={completeSprintModal.visible}
          onClose={() => dispatch(resetSchedule(["completeSprintModal"]))}
        />
      }
    </div>
  );
};

export default ProjectProgress;
