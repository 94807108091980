import React, { useEffect, useState } from "react";
import InfoByFrosty from "../../../Core/CommonV2/InfoByFrosty";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { Checkbox, Col, Popover, Row } from "antd";
import { TaskInput } from "../Task/TaskReusable";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useForm, Controller } from "react-hook-form";
import CustomDatePicker from "../../../Core/CommonV2/CustomDatePicker";
import { client } from "../../../Core/utils/axiosClient";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { getReleaseVersions } from "../../../Core/redux/api/scheduleAPI";
import dayjs from "dayjs";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";

import useSearch from "../../../Core/hooks/useSearch";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { CheckIcon } from "../../../Core/svgV2/Check";

const NewReleaseVersionModal = ({
  visible,
  onClose,
  isEdit = false,
  releaseNoteDetails = null,
}) => {
  const { releaseVersions } = useAppSelector((state) => state.schedule);
  const [checkedList, setCheckedList] = useState([]);
  const [description, setDescription] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [releaseType, setReleaseType] = useState("PROD");
  const { handleSubmit, control, formState, reset, setValue } = useForm({
    values: {
      versionName: "",
      startDate: "",
      releaseDate: "",
    },
  });
  const [releaseVersionsList, setReleaseVersionsList] = useState([]);

  useEffect(() => {
    const unassociatedQaReleaseNotes = releaseVersions.filter((releaseNote) => {
      return !releaseNote.hasAssociatedVersions && !releaseNote.isAssociated;
    });

    setReleaseVersionsList([...unassociatedQaReleaseNotes]);
  }, [releaseVersions]);
  // useEffect(() => {
  //   const associatedQaReleaseNotes = [];
  //   releaseVersions.map((releaseNote) => {
  //     releaseNoteDetails?.associatedVersions?.map((childNote) => {
  //       if (releaseNote.id === childNote) {
  //         associatedQaReleaseNotes.push(releaseNote);

  //       }
  //     });
  //   });
  //   setReleaseVersionsList((prevlist) => {
  //     return [...prevlist, ...associatedQaReleaseNotes];
  //   });
  //   if (releaseNoteDetails?.associatedVersions) {
  //     setCheckedList([...releaseNoteDetails.associatedVersions]);
  //   }

  //   // console.log(associatedQaReleaseNotes, releaseNoteDetails, "as qa");
  // }, [releaseNoteDetails]);

  const { search, setSearch, filteredData } = useSearch(releaseVersionsList, [
    "versionName",
  ]);

  useEffect(() => {
    if (isEdit) {
      setValue("versionName", releaseNoteDetails?.versionName || "c");
      setValue(
        "startDate",
        releaseNoteDetails?.startDate
          ? dayjs(releaseNoteDetails.startDate)
          : ("" as any)
      );
      setValue(
        "releaseDate",
        releaseNoteDetails?.releaseDate
          ? dayjs(releaseNoteDetails.releaseDate)
          : ("" as any)
      );
      setDescription(releaseNoteDetails?.description || "");
    }
  }, [isEdit, releaseNoteDetails]);

  useEffect(() => {
    if (projectId && !isEdit && releaseType === "PROD") {
      dispatch(getReleaseVersions({ projectId }));
    }
  }, [projectId, isEdit, releaseType]);

  const onSubmit = async (data) => {
    try {
      const payload: any = {
        versionName: data.versionName,
        startDate: data.startDate,
        releaseDate: data.releaseDate,
        projectId: projectId,
        description,
      };
      if (isEdit) {
        await client.put("/schedule/release-version", {
          ...payload,
          versionId: releaseNoteDetails.id,
        });
      } else {
        if (releaseType === "PROD") {
          payload.checkedReleaseNotes = checkedList;
        }
        await client.post("/schedule/release-version", payload);
      }

      reset();
      dispatch(getReleaseVersions({}));
      onClose();
    } catch (e) {
      console.log("Something went wrong while creating a new label");
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="609px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">
              {isEdit ? "Edit" : "New"} Release Version
            </p>
            <div className="flex items-center gap-x-2.5 ">
              <InfoByFrosty
                title="Release Version"
                content="Release versions are important for identifying and tracking different versions of the software. They help with testing, debugging, troubleshooting, and enable users to understand which version of the software they are using, along with its features and improvements."
              />
              <button onClick={() => onClose()}>
                <CrossIcon className="text-gray-700" />
              </button>
            </div>
          </div>
          <div className="mt-4 flex gap-x-4 items-start">
            <Controller
              control={control}
              name="versionName"
              rules={{ required: "Please Add the Version Name" }}
              render={({ field }) => (
                <CustomInputBox
                  {...field}
                  className="w-full"
                  placeholder="Enter version name"
                  formState={formState}
                />
              )}
            />
            {!isEdit && (
              <Popover
                open={popoverOpen}
                arrow={false}
                trigger={"click"}
                placement="bottomRight"
                onOpenChange={(open) => setPopoverOpen(open)}
                content={
                  <div className="w-[320px] bg-white border border-gray-100  rounded-lg shadow-lg">
                    <div className="px-3.5 pt-3.5 pb-2.5 border-b border-gray-200  out-300-14 text-gray-500">
                      Type
                    </div>
                    <div
                      role="button"
                      onClick={() => {
                        setReleaseType("QA");
                        setPopoverOpen(false);
                      }}
                      className="px-3.5 py-2.5 flex justify-between items-center hover:bg-gray-50 out-300-14 w-full text-gray-900"
                    >
                      <p className="flex-1">QA</p>
                      {releaseType === "QA" ? (
                        <CheckIcon className="text-primary-600" />
                      ) : null}
                    </div>
                    <div
                      role="button"
                      onClick={() => {
                        setReleaseType("PROD");
                        setPopoverOpen(false);
                      }}
                      className="px-3.5 py-2.5  flex justify-between items-center hover:bg-gray-50 out-300-14 w-full text-gray-900"
                    >
                      <p className="flex-1"> PROD </p>
                      {releaseType === "PROD" ? (
                        <CheckIcon className="text-primary-600" />
                      ) : null}
                    </div>
                  </div>
                }
              >
                <div
                  role="button"
                  className="flex items-center cursor-pointer h-[30px] justify-center gap-x-2"
                >
                  <span className="out-500-14 text-gray-500">
                    {releaseType}
                  </span>
                  <ChevronIcon className="text-gray-500" />
                </div>
              </Popover>
            )}
          </div>
          <div className="flex items-center gap-x-4 mt-4">
            <Controller
              control={control}
              name="startDate"
              rules={{ required: "Please Add Start Date" }}
              render={({ field }) => (
                <CustomDatePicker
                  {...field}
                  className="w-full  bg-transparent rounded-[4px] border-gray-200"
                  format="DD MMM YYYY"
                  placeholder="Start date"
                  suffixIcon={<></>}
                  formState={formState}
                />
              )}
            />
            <Controller
              control={control}
              name="releaseDate"
              rules={{ required: "Please Add the Release Date" }}
              render={({ field }) => (
                <CustomDatePicker
                  {...field}
                  className="w-full  bg-transparent rounded-[4px] border-gray-200"
                  format="DD MMM YYYY"
                  placeholder="Release date"
                  suffixIcon={<></>}
                  formState={formState}
                />
              )}
            />
          </div>
          {releaseType === "PROD" && !isEdit && (
            <div className="mt-4">
              <p className="out-500-14 text-gray-900">
                Select from the list of your QA releases
              </p>
              <CustomSearchBox
                placeholder="Search"
                className="my-4"
                search={search}
                setSearch={setSearch}
              />
              <Checkbox.Group
                value={checkedList}
                onChange={(list) => setCheckedList(list)}
                className="max-h-[40vh] w-full overflow-y-scroll overflow-x-hidden"
              >
                <Row className="w-full">
                  {filteredData.map((version, i) => (
                    <Col
                      span={24}
                      key={version.id + i}
                      className="py-2.5  border-b"
                    >
                      <Checkbox value={version.id}>
                        <div className="flex gap-x-2 items-center justify-start">
                          <span className="out-300-12 text-gray-900">
                            {version.versionName}
                          </span>
                          {version.versionStatus === 0 ? (
                            <CustomBadge
                              text={"Unreleased"}
                              color="#667085"
                              className="border border-gray-200 out-300-12 bg-white"
                              bgColor="#F9FBFE"
                            />
                          ) : (
                            <CustomBadge
                              text={"Released"}
                              color="#039855"
                              className="border border-gray-200"
                              bgColor="#F9FBFE"
                            />
                          )}
                        </div>
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </div>
          )}
          <div className="mt-4">
            <TaskInput
              autoSize={{ minRows: 3 }}
              value={description}
              onChange={(e) => {
                console.log(e.target.value);
                setDescription(e.target.value);
              }}
              inputClassName="task-description"
              placeholder="Enter a description..."
            />
          </div>

          <div className="flex items-center mt-4 justify-between">
            {!isEdit ? (
              <CustomButton
                text="View Release Notes"
                className="bg-transparent text-primary-700 out-500-14"
                height="30px"
                onClick={() =>
                  navigate(`/schedule/${projectId}/reports/release-notes`)
                }
              />
            ) : (
              <div></div>
            )}

            <div className="flex items-center">
              <CustomButton
                text="Cancel"
                className="bg-transparent text-gray-500 out-500-14"
                height="30px"
                onClick={onClose}
              />
              <CustomButton
                text={isEdit ? "Save" : "Create"}
                className="bg-transparent text-primary-700 out-500-14"
                height="30px"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default NewReleaseVersionModal;
