import React from "react";
import CustomTable from "../../../../Core/CommonV2/CustomTable";
import { useGetProjectPhases } from "../../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useGetProjectDetails } from "../../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import usePermission from "../../../../Core/hooks/usePermission";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import { hasUnfinishedGoals } from "../../../../Core/redux/api/scheduleAPI";
import { updateDashboard } from "../../../../Core/redux/slices/dashboard.slice";
import CompletePhaseModal from "../../../../ModulesV2/Dashboard/Modals/CompletePhaseModal";

const PhasesTable = () => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const { completePhaseModal } = useAppSelector((state) => state.dashboard);
  const {
    data: phases,
    isLoading,
    isError,
    error,
  } = useGetProjectPhases(projectId);

  const { data: projectDetails, isLoading: projectLoading } =
    useGetProjectDetails(projectId);

  const { hasPermission: PHASE_AND_SPRINT_EDIT } = usePermission("6");

  const renderDate = (date) => {
    if (!date) return "Not set";
    return dayjs(date).format("MMM DD, YYYY");
  };

  const renderStatus = (isCompleted, isOngoing) => {
    if (isOngoing) {
      return (
        <div className="w-[75px] h-[18px] px-2 bg-blue-100 rounded-2xl justify-center items-center gap-1 inline-flex">
          <div className="text-center text-blue-700 text-xs font-medium leading-[18px]">
            Active
          </div>
        </div>
      );
    } else if (isCompleted) {
      return (
        <div className="w-[75px] h-[18px] px-2 bg-emerald-100 rounded-2xl justify-center items-center gap-1 inline-flex">
          <div className="text-center text-emerald-700 text-xs font-medium leading-[18px]">
            Completed
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-[75px] h-[18px] px-2 bg-slate-200 rounded-2xl justify-center items-center gap-1 inline-flex">
          <div className="text-center text-gray-500 text-xs font-medium leading-[18px]">
            Upcoming
          </div>
        </div>
      );
    }
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      width: "5%",
      align: "center",
      className: "whitespace-nowrap",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Phase",
      dataIndex: "phases",
      key: "phases",
      width: "28%",
      render: (text, record) =>
        `${text} ${record.phaseIteration ? record.phaseIteration : ""}`,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: "15%",
      align: "center",
      render: renderDate,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: "15%",
      align: "center",
      render: renderDate,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "",
      align: "center",
      render: (_, record) => renderStatus(record.isCompleted, record.isOngoing),
    },
    {
      title: "Colour Assigned",
      dataIndex: "color",
      key: "color",
      width: "15%",
      align: "center",
      render: (color) => (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            className="w-3.5 h-3.5 rounded-full"
            style={{ backgroundColor: color, display: "inline-block" }}
          ></div>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "blank",
      key: "blank",
      width: "12%",
      render: (_, record) => {
        const phase = record;
        if (phase?.isCompleted === 1) {
          return <div></div>;
        } else if (phase?.isOngoing === 1 && PHASE_AND_SPRINT_EDIT) {
          return (
            <div
              role="button"
              className={`${
                projectDetails?.progress === "100"
                  ? " bg-[#AADAFF] text-white"
                  : " bg-primary-50 text-primary-700"
              } h-[22px] gap-x-[7px] flex items-center justify-center cursor-pointer out-500-12 py-1 pl-[6px] pr-2 ${
                projectDetails?.isEnded ? "opacity-30" : ""
              }`}
              onClick={async () => {
                if (!projectDetails.isEnded) {
                  const showErrorMessageModal = await dispatch(
                    hasUnfinishedGoals({
                      phaseId: +phase.phaseId,
                      projectId: +projectDetails?.projectDetails.id,
                    })
                  );
                  console.log(
                    showErrorMessageModal.payload.result,
                    "showErrorMessagemOdal"
                  );
                  if (showErrorMessageModal?.payload?.result) {
                    dispatch(
                      updateDashboard({
                        key: "errorMessageModal",
                        value: { visible: true, type: "phase" },
                      })
                    );

                    dispatch(
                      updateDashboard({
                        key: "completePhaseModal",
                        value: {
                          phase,
                          action: "end",
                          visible: false,
                        },
                      })
                    );
                  } else {
                    dispatch(
                      updateDashboard({
                        key: "completePhaseModal",
                        value: {
                          visible: true,
                          edit: false,
                          action: "end",
                          phase: phase,
                        },
                      })
                    );
                  }
                }
              }}
            >
              <div
                className={
                  projectDetails?.progress === "18.18"
                    ? `w-[6px] h-[6px] rounded-xl bg-white`
                    : `w-[6px] h-[6px] rounded-xl bg-primary-500`
                }
              ></div>
              End Phase
            </div>
          );
        }
        //  else if (PHASE_AND_SPRINT_EDIT && phase.isNew) {
        //   return (
        //     <div
        //       className={`${
        //         projectDetails?.progress === "100"
        //           ? "bg-[#AADAFF]"
        //           : "bg-primary-600"
        //       } h-[22px]  rounded-2xl gap-x-[7px] flex items-center justify-center out-500-12 text-white py-1 px-2 cursor-pointer   ${
        //         projectDetails?.isEnded ? "opacity-30" : ""
        //       } `}
        //       role="button"
        //       onClick={() => {
        //         // onClick functionality here
        //       }}
        //     >
        //       Add phase
        //     </div>
        //   );
        // }
        else if (PHASE_AND_SPRINT_EDIT) {
          return (
            <div
              className={`${
                projectDetails?.progress === "100"
                  ? "bg-[#AADAFF]"
                  : "bg-primary-600"
              } h-[22px] gap-x-[7px] flex items-center justify-center out-500-12 text-white py-1 px-2 cursor-pointer  ${
                projectDetails?.isEnded ? "opacity-30" : ""
              }`}
              role="button"
              onClick={() => {
                if (!projectDetails?.isEnded) {
                  dispatch(
                    updateDashboard({
                      key: "completePhaseModal",
                      value: {
                        phase,
                        action: "start",
                        visible: true,
                        edit: false,
                      },
                    })
                  );
                }
              }}
            >
              Start Phase
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];

  return (
    <>
      <CustomTable
        className="custom-header-timeline custom-table normal-cursor"
        columns={columns}
        data={phases || []}
        loading={isLoading}
        paginationEnabled={false}
      />
      {completePhaseModal.visible && <CompletePhaseModal />}
    </>
  );
};

export default PhasesTable;
