import React, { useState } from "react";
import { Upload } from "antd";
import axios from "axios";
import uniqid from "uniqid";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setFulfilled,
  setRejected,
  setRequest,
} from "../redux/slices/global.slice";

const { Dragger } = Upload;

const CustomDragAndDrop = ({ onUploaded, defaultFileList = [] }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const userLocal = user;

  const dispatch = useAppDispatch();

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const reader = new FileReader();
    let dimensionalValue = 0;

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result.toString();

      img.onload = () => {
        // Access image dimensions

        dimensionalValue = img.width * img.height;

        // You can set the image state or perform further actions here
        // setImage(file);
      };
    };
    reader.readAsDataURL(file);
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      dispatch(setRequest({ endPoint: "/storej" }));
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          dispatch(setFulfilled({}));
          let newResObj = { ...res.data[0] };
          if (+dimensionalValue >= 71000000) {
            newResObj = { ...newResObj, imageOutOfBound: true };
          }

          const data = [];
          data.push(newResObj);
          console.log("UPLOADED", res.data);
          onSuccess(data);
        });
    } catch (err) {
      dispatch(setRejected({ error: err, from: "CustomDragAndDrop" }));
      onError({ err });
    }
  };

  const [fileList, setFileList] = useState(defaultFileList);
  const [fileUploadedList, setFileUploadedList] = useState([]);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const fileData = [];

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status === "done") {
        file.response.forEach((xfile) => {
          const { fileUrl: url, thumbnail } = xfile;

          fileData.push({
            name,
            url,
            size,
            type,
            lastModified,
            imageOutOfBound: file?.imageOutOfBound ? true : false,

            thumbnail: thumbnail.fileUrl,
          });
        });
      }
    });

    if (defaultFileList) {
      fileData.push(...defaultFileList);
    }
    const uniqueArray = fileData.filter(
      (obj, index, self) => index === self.findIndex((t) => t.url === obj.url)
    );

    console.log(defaultFileList, "defaultfilelist");
    setFileUploadedList(uniqueArray);
    onUploaded(uniqueArray);
  };

  return (
    <Dragger
      customRequest={uploadImage}
      fileList={fileList}
      onChange={handleChange}
      className="custom-drag-drop"
      multiple
      showUploadList={false}
      style={{ backgroundColor: "#F5FAFF" }}
    >
      <div className="flex justify-center">
        <img
          src="/images/v2/common/file-upload.svg"
          className="w-[34px] h-[34px]"
        />
      </div>

      <div className="out-500-12 text-gray-500  pt-2">
        <span className=" text-primary-600 underline">Click to upload</span> or
        Drop your files here
      </div>
    </Dragger>
  );
};

export default CustomDragAndDrop;
