import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { client } from "../../../Core/utils/axiosClient";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import dayjs from "dayjs";
import {
  rejectProposedTime,
  updateEventTime,
} from "../../../Core/redux/api/scheduleAPI";

const NewTimeRequestModal = () => {
  const dispatch = useAppDispatch();
  const { projectId, eventId, subeventId } = useParams();
  const { newTimeRequestModal } = useAppSelector((state) => state.dashboard);

  const { user } = useAppSelector((state) => state.userDetails);

  const navigate = useNavigate();

  const handleDecline = async () => {
    const payload = {
      eventId: newTimeRequestModal.eventId,
      eventName: newTimeRequestModal.eventName,
      receiverId: newTimeRequestModal.senderId,
    };

    dispatch(rejectProposedTime(payload));

    dispatch(
      updateDashboard({
        key: "newTimeRequestModal",
        value: {
          ...newTimeRequestModal,
          visible: false,
          eventId: "",
          eventName: "",
          sender: "",
          newEndTime: "",
          optionalNotes: "",
          senderId: "",
        },
      })
    );
    console.log("Declined Request");
  };

  const handleAccept = async () => {
    const payload = {
      eventId: newTimeRequestModal.eventId,
      startTime: dayjs.utc(newTimeRequestModal.newStartTime).toISOString(),
      endTime: dayjs.utc(newTimeRequestModal.newEndTime).toISOString(),
      // Add other necessary fields to the payload if needed
    };

    dispatch(updateEventTime(payload));

    dispatch(
      updateDashboard({
        key: "newTimeRequestModal",
        value: {
          ...newTimeRequestModal,
          visible: false,
          eventId: "",
          eventName: "",
          sender: "",
          newEndTime: "",
          optionalNotes: "",
          senderId: "",
        },
      })
    );
    console.log("Accepted Request");
  };

  // Function to format the start and end times
  const formatTimeRange = (startTimeString, endTimeString) => {
    const startDate = new Date(startTimeString);
    const endDate = new Date(endTimeString);
    const formattedStartTime = startDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const formattedEndTime = endDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const formattedDate = startDate.toLocaleDateString("en-US");
    return `${formattedStartTime} - ${formattedEndTime}, ${formattedDate}`;
  };

  return (
    <CustomModal
      visible={newTimeRequestModal?.visible}
      width="360px"
      onCancel={() => {
        dispatch(
          updateDashboard({
            key: "newTimeRequestModal",
            value: {
              ...newTimeRequestModal,
              visible: false,
              eventId: "",
              eventName: "",
              sender: "",
              newEndTime: "",
              optionalNotes: "",
              senderId: "",
            },
          })
        );
      }}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-primary-700 out-500-14">Accept Proposed Time?</p>
            <button
              onClick={() => {
                dispatch(
                  updateDashboard({
                    key: "newTimeRequestModal",
                    value: {
                      ...newTimeRequestModal,
                      visible: false,
                      eventId: "",
                      eventName: "",
                      sender: "",
                      newEndTime: "",
                      optionalNotes: "",
                      senderId: "",
                    },
                  })
                );
              }}
            >
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            {newTimeRequestModal?.sender} proposed a new time &quot;
            {formatTimeRange(
              newTimeRequestModal?.newStartTime,
              newTimeRequestModal?.newEndTime
            )}
            &quot; for &quot;
            {newTimeRequestModal?.eventName}”,
            <br /> would you like to accept?
          </p>
          {newTimeRequestModal?.optionalNotes && (
            <div className="mt-1 out-300-14 text-gray-500">
              Note Added <br />
              {newTimeRequestModal?.optionalNotes}
            </div>
          )}
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Decline"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => {
                handleDecline();
                dispatch(
                  updateDashboard({
                    key: "newTimeRequestModal",
                    value: {
                      ...newTimeRequestModal,
                      visible: false,
                      eventId: "",
                      eventName: "",
                      sender: "",
                      newEndTime: "",
                      optionalNotes: "",
                      senderId: "",
                    },
                  })
                );
              }}
            />
            <CustomButton
              text="Accept"
              height="30px"
              width="101px"
              className="bg-primary-700 text-white"
              onClick={handleAccept}
            />
          </div>
        </div>
      }
    />
  );
};

export default NewTimeRequestModal;
