import React from "react";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../Core/redux/hooks";
import { updateVault } from "../../Core/redux/slices/vault.slice";

const ProjectTitleContainer = ({ project }) => {
  const dispatch = useAppDispatch();

  return (
    <Link
      to={`/library/${project.id}`}
      onClick={(e) => {
        dispatch(
          updateVault({ key: "currentSelectedProject  ", value: project })
        );
        return e;
      }}
    >
      <div className="w-[211px] h-[76px] cursor-pointer  rounded-lg border border-gray-200 ">
        {/* <div className="py-2.5"></div> */}
        <div className="flex  w-[211px] h-[76px]  items-center px-3 gap-x-[6px]  ">
          <CustomAvatar
            size={20}
            title={project.title}
            src={project.projectImage}
            fontSize={"12px"}
            whiteText
            color={project.projectColor}
          />
          <p className="out-500-14 leading-5 text-gray-700  flex">
            {project.title}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ProjectTitleContainer;
