import React from "react";

function PageTitle({ title, subtext }) {
  return (
    <div className="">
      <div className="out-500-20 text-black">{title}</div>
      <div className="out-300-14 text-gray-500">{subtext}</div>
    </div>
  );
}

export default PageTitle;
