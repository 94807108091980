import React from "react";
import Icon from "@ant-design/icons";

const UploadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      d="M8.5 10a.5.5 0 01-1 0h1zm-1 0V2.8h1V10h-1z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.4 4.4L8 2.4l-2.4 2M3.2 9.6v3.2h9.6V9.6"
    ></path>
  </svg>
);
const FolderUploadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.667 4.667l-.744-1.488c-.214-.428-.32-.642-.48-.798a1.333 1.333 0 00-.499-.308C6.733 2 6.494 2 6.015 2H3.467c-.747 0-1.12 0-1.405.145-.251.128-.455.332-.583.583-.146.285-.146.659-.146 1.405v.534m0 0h10.134c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874c.218.428.218.988.218 2.108V10.8c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874c-.428.218-.988.218-2.108.218H4.533c-1.12 0-1.68 0-2.107-.218a2 2 0 01-.875-.874c-.218-.428-.218-.988-.218-2.108V4.667zM6 9.333l2-2m0 0l2 2m-2-2v4"
    ></path>
  </svg>
);
const FileUploadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.333 1.513v2.753c0 .374 0 .56.073.703a.667.667 0 00.291.291c.143.073.33.073.703.073h2.753M6 9.333l2-2m0 0l2 2m-2-2v4m1.333-10H5.867c-1.12 0-1.68 0-2.108.218a2 2 0 00-.875.874c-.217.428-.217.988-.217 2.108v6.933c0 1.12 0 1.68.217 2.108a2 2 0 00.875.874c.427.218.987.218 2.108.218h4.266c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874c.218-.428.218-.988.218-2.108V5.333l-4-4z"
    ></path>
  </svg>
);
const LinkUploadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_12167_5898)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.667 8.667a3.333 3.333 0 005.027.36l2-2A3.333 3.333 0 008.98 2.314l-1.147 1.14m1.5 3.88a3.333 3.333 0 00-5.026-.36l-2 2a3.333 3.333 0 004.713 4.713l1.14-1.14"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_12167_5898">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

const UploadIcon = (props) => {
  return <Icon component={UploadSvg} {...props} />;
};
const FolderUploadIcon = (props) => {
  return <Icon component={FolderUploadSvg} {...props} />;
};
const FileUploadIcon = (props) => {
  return <Icon component={FileUploadSvg} {...props} />;
};
const LinkUploadIcon = (props) => {
  return <Icon component={LinkUploadSvg} {...props} />;
};

export { UploadIcon, FolderUploadIcon, FileUploadIcon, LinkUploadIcon };
