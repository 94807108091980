import React from "react";

const MoveIcon = ({ size = 16, className = "text-gray-600" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="#475467"
          d="M9 6.5l-.705.705L9.585 8.5H5v1h4.585l-1.29 1.295L9 11.5 11.5 9 9 6.5z"
        ></path>
        <path
          fill="#475467"
          d="M5.586 3l1.707 1.707.293.293H14v8H2V3h3.586zm0-1H2a1 1 0 00-1 1v10a1 1 0 001 1h12a1 1 0 001-1V5a1 1 0 00-1-1H8L6.293 2.293A1 1 0 005.586 2z"
        ></path>
      </svg>
    </span>
  );
};

export default MoveIcon;
