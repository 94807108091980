import React from "react";
import Icon from "@ant-design/icons";

const SaveSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.6 4.291v9.31L8 10.4l4.4 3.2V4.29M3.6 2.4h8.8"
    ></path>
  </svg>
);

const SaveIcon = (props) => {
  return <Icon component={SaveSvg} {...props} />;
};

export { SaveIcon };
