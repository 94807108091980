import { createSlice } from "@reduxjs/toolkit";
import {
  createMyBoardTask,
  deleteMyBoardTasks,
  getAllMyBoardTasks,
  getCollaboratorsForCurrentUser,
  getMyBoardSubtaskById,
  getMyBoardTaskById,
  getMyBoardTasks,
  getTaskActivityLog,
  updateMyBoardTask,
} from "../api/myBoardAPI";
import { updateTask } from "../../utils/scheduleUtils";

const initialState = {
  myBoardLoading: false,
  error: {},
  status: "idle",
  currentSelectedTask: {
    id: "",
    name: "",
    isCompleted: 0,
    statusId: 1,
    swimlaneId: "1",
    componentId: null,
    priorityId: 1,
    startDate: null,
    endDate: null,
    isNewGoal: 0,
    isActive: 1,
    projectId: "",
    tenantId: "",
    goalType: null,
    parentGoalId: null,
    assigneeId: "",
    description: "",
    attachments: "",
    estimatedTime: "",
    createdAt: "",
    timeLogged: "",
    linkedGoalId: null,
    labelId: "",
    releaseVersionId: null,
    identifier: "",
    subtask: [],
    isSubtask: false,
    collaborators: [],
  },
  currentSelectedSubTask: {
    id: "",
    name: "",
    isCompleted: 0,
    isNewGoal: 0,
    statusId: "",
    priorityId: "",
    goalType: 4,
    assigneeId: "",
    parentGoalId: "",
    isSubTask: true,
    description: "",
    labelId: "",
    releaseVersionId: "",
    createdAt: "",
    estimatedTime: "",
    timeLogged: "",
    totalTimeLogged: "",
  },
  newTask: null,
  myBoardTasks: {
    result: [],
    totalRecords: 0,
  },
  taskActivity: [],
  createTaskHeader: false,
  newTaskModal: false,
  myBoardKanbanView: false,
  selectedTasks: [],
  collaboratorsForTask: [],
  projectListModal: false,
  deleteTaskModal: {
    visible: false,
    multiple: true,
    taskId: null,
  },
  exportModal: false,
  addCollaboratorsModal: false,
  myBoardAllGoals: [],
  showSubtaskView: true,
  showNewTaskCard: {
    visible: false,
    channel: { id: "" },
    taskId: "",
    goalId: "",
  },
  collaboratorsWithCurrentUser: [],
  parentTask: [],
  collabRequestModal: {
    visible: null,
    taskId: null,
    taskName: null,
    sender: null,
  },
  isMyTasks: false,
  filters: {
    collaborator: [],
    label: [],
    status: [],
    priority: [],
  },
  subtasksInProgressWarning: {
    visible: false,
    goalId: null,
    statusId: null,
    swimlaneId: null,
    oldSwimlaneId: null,
    sprintId: null,
  },
};

export const myBoardSlice = createSlice({
  name: "myBoard",
  initialState,
  reducers: {
    resetToInitialMyBoard: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    updateMyBoard: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    resetMyBoard: (state, action) => {
      action.payload.map((ele) => {
        state[ele] = initialState[ele];
      });
    },
  },
  extraReducers: (builder) => {
    // create my board task
    builder.addCase(createMyBoardTask.pending, (state) => {
      state.myBoardLoading = true;
      state.status = "pending";
    });
    builder.addCase(createMyBoardTask.fulfilled, (state) => {
      state.myBoardLoading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(createMyBoardTask.rejected, (state, { payload }) => {
      state.myBoardLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get my board tasks
    builder.addCase(getMyBoardTasks.pending, (state) => {
      state.myBoardLoading = true;
      state.status = "pending";
    });
    builder.addCase(getMyBoardTasks.fulfilled, (state, { payload }) => {
      return {
        ...state,
        error: null,
        myBoardLoading: false,
        status: "fulfilled",
        myBoardTasks: payload,
      };
    });
    builder.addCase(getMyBoardTasks.rejected, (state, { payload }) => {
      state.myBoardLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    // get all my board tasks
    builder.addCase(getAllMyBoardTasks.pending, (state) => {
      state.myBoardLoading = true;
      state.status = "pending";
    });
    builder.addCase(getAllMyBoardTasks.fulfilled, (state, { payload }) => {
      return {
        ...state,
        error: null,
        myBoardLoading: false,
        status: "fulfilled",
        myBoardAllGoals: payload,
      };
    });
    builder.addCase(getAllMyBoardTasks.rejected, (state, { payload }) => {
      state.myBoardLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    // get my board task by id
    builder.addCase(getMyBoardTaskById.pending, (state) => {
      state.myBoardLoading = true;
      state.status = "pending";
    });
    builder.addCase(getMyBoardTaskById.fulfilled, (state, { payload }) => {
      return {
        ...state,
        error: null,
        myBoardLoading: false,
        status: "fulfilled",
        currentSelectedTask: payload.result[0],
      };
    });
    builder.addCase(getMyBoardTaskById.rejected, (state, { payload }) => {
      state.myBoardLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    // get my board subtask by id
    builder.addCase(getMyBoardSubtaskById.pending, (state) => {
      state.myBoardLoading = true;
      state.status = "pending";
    });
    builder.addCase(getMyBoardSubtaskById.fulfilled, (state, { payload }) => {
      return {
        ...state,
        error: null,
        myBoardLoading: false,
        status: "fulfilled",
        currentSelectedSubTask: payload.result[0],
      };
    });
    builder.addCase(getMyBoardSubtaskById.rejected, (state, { payload }) => {
      state.myBoardLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // delete tasks
    builder.addCase(deleteMyBoardTasks.pending, (state) => {
      state.myBoardLoading = true;
      state.status = "pending";
    });
    builder.addCase(deleteMyBoardTasks.fulfilled, (state) => {
      state.myBoardLoading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(deleteMyBoardTasks.rejected, (state, { payload }) => {
      state.myBoardLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // update my board task
    builder.addCase(updateMyBoardTask.pending, (state) => {
      state.myBoardLoading = true;
      state.status = "pending";
    });
    builder.addCase(updateMyBoardTask.fulfilled, (state, { payload }) => {
      const updatedTask = payload.result[0];
      const newTasksArr = updateTask({ updatedTask, state });

      return {
        ...state,
        error: null,
        myBoardLoading: false,
        status: "fulfilled",
        myBoardTasks: {
          ...state.myBoardTasks,
          result: newTasksArr,
        },
      };
    });
    builder.addCase(updateMyBoardTask.rejected, (state, { payload }) => {
      state.myBoardLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get task activity

    builder.addCase(getTaskActivityLog.pending, (state, { payload }) => {
      state.myBoardLoading = true;
      state.status = "pending";
    });
    builder.addCase(getTaskActivityLog.fulfilled, (state, { payload }) => {
      state.myBoardLoading = false;
      state.status = "fulfilled";
      state.taskActivity = payload.result;
      state.error = null;
    });
    builder.addCase(getTaskActivityLog.rejected, (state, { payload }) => {
      state.myBoardLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // get collaborators for current User
    builder.addCase(getCollaboratorsForCurrentUser.pending, (state) => {
      state.myBoardLoading = true;
      state.status = "pending";
    });
    builder.addCase(
      getCollaboratorsForCurrentUser.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          error: null,
          myBoardLoading: false,
          status: "fulfilled",
          collaboratorsWithCurrentUser: payload.result,
        };
      }
    );
    builder.addCase(
      getCollaboratorsForCurrentUser.rejected,
      (state, { payload }) => {
        state.myBoardLoading = false;
        state.status = "rejected";
        state.error = payload;
      }
    );
  },
});

export const { updateMyBoard, resetToInitialMyBoard, resetMyBoard } =
  myBoardSlice.actions;
export default myBoardSlice.reducer;
