import React, { useEffect, useState } from "react";
import PhaseIcon from "../svg/PhaseIcon";
import CustomInputBox from "./CustomInputBox";
import { useAppSelector } from "../redux/hooks";
import { client } from "../utils/axiosClient";
import { Breadcrumb } from "antd";
import { CheckIcon } from "../svgV2/Check";
import { useParams } from "react-router-dom";

function highlightSearchTerm(string, searchTerm) {
  if (!searchTerm) return string;
  // Escape special characters in the search term
  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Create a regular expression to match the search term
  const regex = new RegExp(`(${escapedSearchTerm})`, "gi");

  // Split the string into parts based on the search term
  const parts = string.split(regex);

  // Wrap the search term with a highlight tag or CSS class
  const highlightedString = parts
    .map((part) => {
      if (part.toLowerCase() === searchTerm.toLowerCase()) {
        return `<span class="text-black out-700-14">${part}</span>`; // Wrap with a <span> tag and apply a CSS class
      }
      return part;
    })
    .join("");

  return highlightedString;
}

export const FolderCard = ({
  folder,
  onClick,
  onDoubleClick,
  isSelected,
  searchString,
}) => {
  return (
    <div
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      className="flex cursor-pointer hover:bg-gray-50 items-center py-[10px] px-[17px] "
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          {folder.phaseId && (
            <div className="mr-2.5">
              {" "}
              <PhaseIcon phaseId={+folder.phaseId} width="10px" height="10px" />
            </div>
          )}
          <img
            className="w-4 h-4 mr-2"
            src="/images/v2/library/folder-fill.svg"
          />
          <p
            className="text-gray-900 out-300-14"
            dangerouslySetInnerHTML={{
              __html: highlightSearchTerm(folder.folderName, searchString),
            }}
          ></p>
        </div>
        {isSelected && <CheckIcon className="text-primary-700" />}
      </div>
    </div>
  );
};

export const ProjectCard = ({
  project,
  onClick,
  onDoubleClick,
  isSelected,
  searchString,
}) => {
  return (
    <div
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      className="flex cursor-pointer hover:bg-gray-50 items-center py-[10px] px-[17px] "
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <img
            className="w-4 h-4 mr-2"
            src="/images/v2/library/folder-fill.svg"
          />
          <p
            className="text-gray-900 out-300-14"
            dangerouslySetInnerHTML={{
              __html: highlightSearchTerm(
                project.title
                  ? project.title
                  : project.folderName
                  ? project.folderName
                  : "Untitled Folder",
                searchString
              ),
            }}
          ></p>
        </div>
        {isSelected && <CheckIcon className="text-primary-700" />}
      </div>
    </div>
  );
};

const FileFolderListAndSelect = ({
  onFolderSelect = null,
  onProjectSelect,
}) => {
  const [isMyLibrary, setIsMyLibrary] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [foldersArray, setFoldersArray] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [projectFolders, setProjectFolders] = useState(null);
  const [globalDocumentsFolders, setGlobalDocumentsFolders] = useState(null);
  const [error, setError] = useState(null);
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);

  const { projectList } = useAppSelector((state) => state.projects);

  const getGlobalFolders = async () => {
    const response = await getFolders({
      projectId: null,
      tenantId: tenantDetails?.tenantId,
    });

    setGlobalDocumentsFolders(response.result);
  };

  useEffect(() => {
    if (foldersArray.length === 0) {
      getGlobalFolders();
    }
  }, []);

  const getFolders = async (payload) => {
    const response = await client.get(`/repository/folder`, {
      params: payload,
    });
    console.log(response.data, "folders");
    return response.data;
  };

  useEffect(() => {
    if (onProjectSelect) {
      onProjectSelect(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    const folder = projectFolders?.find((f) => f.id == selectedFolder);
    onFolderSelect(selectedFolder, folder);
  }, [selectedFolder]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let payload: {
          projectId?: string;
          folderId?: string;
          tenantId?: string;
        } = {};
        if (selectedProject?.id) {
          payload = {
            projectId: selectedProject?.id,
          };
        }

        if (foldersArray && foldersArray.length > 0) {
          payload.folderId = foldersArray[foldersArray.length - 1].id;
        }

        const folderResponse = await getFolders(payload);

        console.log(folderResponse, "folderResponse");

        setGlobalDocumentsFolders(null);
        setProjectFolders(null);
        setProjectFolders(folderResponse.result);
        //   console.log(globalDocumentsFolders, "gb");
        // } else {
        //   setGlobalDocumentsFolders(null);
        //   setGlobalDocumentsFolders(folderResponse.result);
        //   console.log(globalDocumentsFolders, "gb");
        // }
      } catch (err) {
        console.log(err, "selectedFolderselectedFolderselectedFolder");
        setError(err);
      }
    };
    if (selectedProject) {
      fetchData();
    }

    if (isMyLibrary) {
      fetchData();
    }

    if (selectedFolder) {
      fetchData();
    }
  }, [selectedProject, foldersArray, isMyLibrary]);

  const extraBreadcrumbItems = foldersArray.map((_, index) => {
    return (
      <Breadcrumb.Item key={_.id}>
        <span
          className={` cursor-pointer   ${
            index + 1 == foldersArray.length
              ? "out-500-14 text-black"
              : "out-300-14 text-gray-500"
          }`}
          onClick={() => {
            const updatedArray = [...foldersArray]; // create a copy of the original array
            updatedArray.splice(index + 1); // remove all items after the index
            setFoldersArray(updatedArray); // update the state
          }}
        >
          {_.folderName}
        </span>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="Library">
      <span
        className="text-gray-500 cursor-pointer out-300-14"
        onClick={() => {
          setFoldersArray([]);
          setSelectedProject(null);
          setProjectFolders(null);
          setIsMyLibrary(false);
        }}
      >
        Library
      </span>
    </Breadcrumb.Item>,
    selectedProject ? (
      <Breadcrumb.Item key={selectedProject?.title}>
        <span
          className="text-gray-500 cursor-pointer out-300-14"
          onClick={() => {
            setFoldersArray([]);
            setProjectFolders(null);
          }}
        >
          {selectedProject?.title}
        </span>
      </Breadcrumb.Item>
    ) : null,
  ].concat(extraBreadcrumbItems);

  return (
    <>
      <CustomInputBox
        placeholder="Search in library"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {(selectedProject?.id || isMyLibrary || foldersArray.length > 0) && (
        <div className="mt-4 max-w-[330px] truncate">
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        </div>
      )}
      <div className="max-h-[340px] mt-4 min-h-[340px] overflow-y-auto">
        {foldersArray.length === 0 && selectedProject == null && (
          <ProjectCard
            project={{ title: "My Library" }}
            isSelected={selectedFolder == user.myLibraryId}
            searchString={search}
            onClick={() => {
              setProjectId(null);

              if (selectedFolder === user.myLibraryId) {
                setSelectedFolder(null);
              } else {
                setSelectedFolder(user.myLibraryId);
              }
            }}
            onDoubleClick={() => {
              setProjectId(null);
              setIsMyLibrary(true);
              setSelectedFolder(user.myLibraryId);
              setFoldersArray((arr) => [
                ...arr,
                { folderName: "My Library ", id: user.myLibraryId },
              ]);
            }}
          />
        )}
        {projectList &&
          !selectedProject &&
          !isMyLibrary &&
          foldersArray.length === 0 &&
          projectList
            .filter(
              (prj) =>
                prj?.title?.toLowerCase().includes(search.toLowerCase()) ||
                prj?.folderName?.toLowerCase().includes(search.toLowerCase())
            )
            .map((prj, i) => (
              <ProjectCard
                project={prj}
                isSelected={prj.id == projectId}
                searchString={search}
                key={prj.id + i}
                onClick={() => {
                  if (prj.id == projectId) {
                    setProjectId(null);
                    console.log(prj.id, projectId, "prj");
                  } else {
                    setProjectId(prj.id);
                    console.log(prj.id, projectId, "prj");
                  }
                }}
                onDoubleClick={() => {
                  //todo check if thhis breaks something else
                  setProjectId(prj.id);
                  setSelectedProject(prj);
                }}
              />
            ))}
        {projectFolders &&
          projectFolders.length > 0 &&
          projectFolders
            .filter(
              (folder) =>
                folder?.folderName
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) && folder.canEdit === 1
            )
            .map((folder, i) => (
              <FolderCard
                folder={folder}
                key={folder.id + i}
                isSelected={selectedFolder == folder.id}
                searchString={search}
                onClick={() => {
                  if (selectedFolder === folder.id) {
                    setSelectedFolder(null);
                  } else {
                    setSelectedFolder(folder.id);
                  }
                }}
                onDoubleClick={() => {
                  setFoldersArray((arr) => [...arr, folder]);
                  setSelectedFolder(folder);
                }}
              />
            ))}
        {globalDocumentsFolders &&
          globalDocumentsFolders.length > 0 &&
          globalDocumentsFolders
            .filter(
              (folder) =>
                folder?.folderName
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) && folder.canEdit === 1
            )
            .map((folder, i) => (
              <FolderCard
                folder={folder}
                key={folder.id + i}
                isSelected={selectedFolder == folder.id}
                searchString={search}
                onClick={() => {
                  if (selectedFolder === folder.id) {
                    setSelectedFolder(null);
                  } else {
                    setSelectedFolder(folder.id);
                  }
                }}
                onDoubleClick={() => {
                  console.log(folder, "foldersArr2");
                  setFoldersArray((arr) => [...arr, folder]);
                  setSelectedFolder(folder.id);
                }}
              />
            ))}
      </div>
    </>
  );
};

export default FileFolderListAndSelect;
