import React from "react";
import Icon from "@ant-design/icons";

const NoResponseIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_2097_5854)">
      <circle cx="8" cy="8" r="8" fill="#FEF3F2"></circle>
      <path
        stroke="#B42318"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.334 4.667l-6.667 6.666m0-6.666l6.667 6.666"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_2097_5854">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

const NoResponseIcon = (props) => (
  <Icon component={NoResponseIconSvg} {...props} />
);

export { NoResponseIcon };
