import React from "react";
import Icon from "@ant-design/icons";

const DeleteIcon = ({
  width = "16",
  height = "16",
  className = "",
  style = {},
}) => (
  <span role="img" className={`anticon ${className}`} style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 5.602c0 3.5.8 8 .8 8h6.4s.8-4.5.8-8"
      ></path>
      <path stroke="currentColor" strokeLinecap="round" d="M2.4 4h11.2"></path>
      <path
        stroke="currentColor"
        d="M5.6 3.998v-1.1a.5.5 0 01.5-.5h3.8a.5.5 0 01.5.5v1.1"
      ></path>
    </svg>
  </span>
);

const DeleteScheduleIcon = ({ size = 16, className = "text-error-600" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="M10.667 4v-.533c0-.746 0-1.12-.146-1.405a1.333 1.333 0 00-.582-.583c-.286-.145-.659-.145-1.406-.145H7.467c-.747 0-1.12 0-1.406.145-.25.128-.455.332-.582.583-.146.285-.146.659-.146 1.405v.534M2 4h12m-1.333 0v7.466c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874c-.428.218-.988.218-2.108.218H6.533c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874c-.218-.427-.218-.988-.218-2.108V4.001"
        ></path>
      </svg>
    </span>
  );
};

const DeleteCalendarIcon = ({ size = 20, className = "" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.667 4v-.533c0-.746 0-1.12-.146-1.405a1.333 1.333 0 00-.582-.583c-.286-.145-.659-.145-1.406-.145H7.467c-.747 0-1.12 0-1.406.145-.25.128-.455.332-.582.583-.146.285-.146.659-.146 1.405v.534M2 4h12m-1.333 0v7.466c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874c-.428.218-.988.218-2.108.218H6.533c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874c-.218-.427-.218-.988-.218-2.108V4.001"
        ></path>
      </svg>
    </span>
  );
};

export { DeleteIcon, DeleteScheduleIcon, DeleteCalendarIcon };
