import React, { useEffect, useState } from "react";

const StepProgressBar = ({ currentStep, totalSteps, givenElementId }) => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [offsetLeft, setOffsetLeft] = useState(0);
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    setOffsetLeft(
      document.getElementById(givenElementId)
        ? document.getElementById(givenElementId).getBoundingClientRect().left
        : 0
    );
    setOffsetTop(
      document.getElementById(givenElementId)
        ? document.getElementById(givenElementId).getBoundingClientRect().top
        : 0
    );
  }, [
    givenElementId,
    document.getElementById(givenElementId)
      ? document.getElementById(givenElementId).getBoundingClientRect().top
      : 0,
  ]);

  const step1Height = offsetTop;

  return (
    <>
      <style>
        {`
         .progress-line {
          position: fixed;
          left:${offsetLeft - 50}px;
          top: 0;
          bottom: 0;
          width: 4px;
          background: transparent;
        }
        .progress-line .step {
          position: absolute;
          width: 100%;
          display: flex;
          align-items: center;
        }
        .progress-line .step .number {
         
          left:${offsetLeft - 30}px;
          position: absolute;
          bottom:10px;
          width:100px;
        }
        .progress-line .step .box1 {
          position: absolute;
          top: calc((80vh ) /5);
          left: -7px;
          border-radius: 2px;
          transform: rotate(45deg);
          z-index:10;
        }
        .progress-line .step .box2 {
          position: absolute;
          top: calc((80vh ) /5);
          left: -7px;
          border-radius: 2px;
          transform: rotate(45deg);
          z-index:10;

        }
        .progress-line .step .box3 {
          position: absolute;
          top:calc((80vh ) /5);
          left: -7px;
          border-radius: 2px;
          transform: rotate(45deg);
          z-index:10;

        }
        .progress-line .step .box4 {
          position: absolute;
          top:calc((80vh ) /5);
          left: -7px;
          border-radius: 2px;
          transform: rotate(45deg);
          z-index:10;

        }
        .progress-line .step .box5 {
          position: absolute;
          top: calc((80vh ) /5);
          left: -7px;
          border-radius: 2px;
          transform: rotate(45deg);
          z-index:10;

        }
        .box-2{
          bottom:10px;
          position: absolute;
          left: -10px;
          border-radius: 2px;
          transform: matrix(0.26, -0.97, 0.96, 0.27, 0, 0);
        }
        .progress-line .step.top {
          top: 53px;
        }
        .progress-line .step.middle-1 {
          top: calc(${step1Height}px - (80vh / 5));
          height:calc((80vh ) / 5);
          background:linear-gradient(to top, #165ABF, #FFFFFF);
        }
        .progress-line .step.middle-2 {
          top: ${step1Height}px;;
          height:calc((80vh ) / 5);
          background:${currentStep < 2 ? "#DDE5ED" : "#165ABF"};
        }
        .progress-line .step.middle-3 {
          top:calc((80vh / 5) + ${step1Height}px);
          height:calc((80vh ) / 5);
          background:${currentStep < 3 ? "#DDE5ED" : "#165ABF"};

        }
        .progress-line .step.middle-4 {
          top: calc((80vh / 5) * 2 + ${step1Height}px);
          height:calc((80vh ) / 5);
          background:${currentStep < 4 ? "#DDE5ED" : "#165ABF"};

        }
        .progress-line .step.middle-5 {
          
          top:calc((80vh / 5) * 3 + ${step1Height}px);
          
          height:calc((80vh ) / 5);
          background:${currentStep < 5 ? "#DDE5ED" : "#165ABF"};

        }
        .progress-line .step.bottom {
          bottom: 0;
        }
        .given-element {
          position: relative;
        }
        .given-element::before {
          content: "";
          position: absolute;
          top: -16px;
          left: 0;
          right: 0;
          height: 8px;
          background: #555;
        }
       
        `}
      </style>

      <div className="progress-line">
        {
          <div
            className="step top"
            style={{
              height: step1Height,
              backgroundColor:
                "linear-gradient(180deg, rgba(249, 251, 254, 0) 0%, #F9FBFE 110.57%)",
            }}
          ></div>
        }

        <div className="step middle-1 ">
          <div
            className={`box1 w-[18px] h-[18px] max-h-[18px] max-w-[18px]  bg-primary-600`}
          ></div>
        </div>
        <div className="step middle-2 ">
          <div
            className={`box2 w-[18px] h-[18px] max-h-[18px] max-w-[18px] ${
              currentStep < 2 ? "bg-gray-200" : "bg-primary-600"
            }`}
          ></div>
        </div>
        <div className="step middle-3 ">
          <div
            className={`box3 w-[18px] h-[18px] max-h-[18px] max-w-[18px]  ${
              currentStep < 3 ? "bg-gray-200" : "bg-primary-600"
            }`}
          ></div>
        </div>
        <div className="step middle-4 ">
          <div
            className={`box4 w-[18px] h-[18px] max-h-[18px] max-w-[18px]  ${
              currentStep < 4 ? "bg-gray-200" : "bg-primary-600"
            }`}
          ></div>
        </div>
        <div className="step middle-5 ">
          <div
            className={`box5 w-[18px] h-[18px] max-h-[18px] max-w-[18px]  ${
              currentStep < 5 ? "bg-gray-200" : "bg-primary-600"
            }`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default StepProgressBar;
