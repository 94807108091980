import React from "react";
import Icon from "@ant-design/icons";

const DotsPointsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 8H6m8-4H6m8 8H6M3.333 8A.667.667 0 112 8a.667.667 0 011.333 0zm0-4A.667.667 0 112 4a.667.667 0 011.333 0zm0 8A.667.667 0 112 12a.667.667 0 011.333 0z"
    ></path>
  </svg>
);

const DotsPointsIcon = (props) => <Icon component={DotsPointsSvg} {...props} />;

export { DotsPointsIcon };
