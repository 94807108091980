/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { PlusIcon, PlusIconSecondary } from "../../../Core/svgV2/PlusIcon";
import { Input, Popover } from "antd";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import { DeleteScheduleIcon } from "../../../Core/svgV3/DeleteIcon";
import MoveIcon from "../../../Core/svgV3/MoveIcon";
import ExportIcon from "../../../Core/svgV3/ExportIcon";
import { useNavigate } from "react-router-dom";
import TaskIcon from "../../../Core/svgV3/TaskIcon";
import ExpandIcon from "../../../Core/svgV3/ExpandIcon";
import {
  createMyBoardTask,
  getMyBoardTasks,
} from "../../../Core/redux/api/myBoardAPI";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import dayjs from "dayjs";
import getUsersName from "../../../Core/utils/getUserName";
import { convertArrayToCsv } from "../../../Core/utils/exportToCsv";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { LabelSelect, PrioritySelect, StatusSelect } from "./TaskReusable";
import DatePicker, { Calendar } from "react-multi-date-picker";

// when none of the task is selected
const PrimaryTaskHeader = () => {
  const dispatch = useAppDispatch();
  const { myBoardTasks } = useAppSelector((state) => state.myBoard);
  return (
    <div className="flex flex-col px-3 gap-y-2">
      <div className="flex items-center gap-x-1.5">
        <p className="text-black out-500-14">Tasks</p>
        <p className="text-gray-500 out-300-14">
          {myBoardTasks?.totalRecords} items
        </p>
      </div>

      <button
        className="flex items-center gap-x-1.5 py-[11px] max-w-max"
        onClick={() =>
          dispatch(updateMyBoard({ key: "createTaskHeader", value: true }))
        }
      >
        <PlusIconSecondary className="text-gray-500" />
        <p className="text-gray-500 out-500-14">Create task</p>
      </button>
    </div>
  );
};

// when the task is selected
const SelectedTaskHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedTasks, myBoardTasks, deleteTaskModal } = useAppSelector(
    (state) => state.myBoard
  );
  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const [open, setOpen] = useState({
    selectionOptions: false,
    projectOptions: false,
  });

  const handleExport = () => {
    function formatKeys(obj) {
      const formattedObj = {};
      for (const key in obj) {
        switch (key) {
          case "id":
            formattedObj["ID"] = obj[key];
            break;
          case "name":
            formattedObj["NAME"] = obj[key];
            break;
          case "statusId":
            formattedObj["STATUS"] =
              obj[key] == 1 ? "TO DO" : obj[key] == 2 ? "IN PROGRESS" : "DONE";
            break;
          case "status":
            formattedObj["STATUS"] =
              obj[key] == 1 ? "TO DO" : obj[key] == 2 ? "IN PROGRESS" : "DONE";
            break;
          case "priorityId":
            formattedObj["PRIORITY"] =
              obj[key] == 1 ? "LOW" : obj[key] == 2 ? "MEDIUM" : "HIGH";
            break;

          case "goalType":
            formattedObj["GOAL TYPE"] =
              obj[key] == 1
                ? "TASK"
                : obj[key] == 2
                ? "EVENT"
                : obj[key] == 3
                ? "BUG"
                : "SUBTASK";
            break;
          case "parentGoalId":
            formattedObj["PARENT GOAL ID"] = obj[key];
            break;
          // case "sprintId":
          //   formattedObj["SPRINT ID"] = obj[key];
          //   break;
          // case "assigneeId":
          //   formattedObj["ASSIGNEE ID"] = obj[key];
          //   formattedObj["ASSIGNEE NAME"] = getUsersName(
          //     tenantMembers.find((user) => user.userId == obj[key])
          //   );
          //   break;
          // case "reporterId":
          //   formattedObj["REPORTER ID"] = obj[key];
          //   formattedObj["REPORTER NAME"] = getUsersName(
          //     tenantMembers.find((user) => user.userId == obj[key])
          //   );
          //   break;
          case "estimatedTime":
            formattedObj["ESTIMATED TIME"] = obj[key];
            break;
          case "timeLogged":
            formattedObj["TIME LOGGED"] = obj[key];
            break;

          case "createdAt":
            formattedObj["CREATED AT"] = obj[key]
              ? dayjs(obj[key]).format("DD MMM YYYY")
              : "-";
            break;
          case "startDate":
            formattedObj["START DATE"] = obj[key]
              ? dayjs(obj[key]).format("DD MMM YYYY")
              : "-";
            break;
          case "endDate":
            formattedObj["END DATE"] = obj[key]
              ? dayjs(obj[key]).format("DD MMM YYYY")
              : "-";
            break;
          case "goals":
            formattedObj["TOALT GOALS COUNT"] = obj[key].length;
            break;
          case "createdById":
            formattedObj["CREATED BY"] = getUsersName(
              tenantMembers.find((user) => user.userId == obj[key])
            );
            break;
          default:
            break;
        }
      }
      return formattedObj;
    }

    const goals = myBoardTasks.result.filter((goal) =>
      selectedTasks.includes(goal.id)
    );
    goals.forEach((goal) => {
      if (goal.subtask && goal.subtask.length > 0) {
        goal.subtask.map((subtask) => goals.push(subtask));
      }
    });
    const data = [...goals];

    // goals.forEach((g) => {
    //   if (g.subTasks && g.subTasks.length) {
    //     data.push(...g.subTasks);
    //   }
    // });
    // console.log(sprints.map(formatKeys), "convertArrayToCsv");
    // exportToCsv(
    //   "sprint",
    //   searchParams.has("selectedSprint")
    //     ? data.map(formatKeys)
    //     : sprints.map(formatKeys)
    // );
    convertArrayToCsv(data.map(formatKeys));
  };

  const selectionOptions = [
    {
      id: 1,
      value: "Select All",
      onClick: () => {
        dispatch(
          updateMyBoard({
            key: "selectedTasks",
            value: [
              ...(myBoardTasks?.result?.map((item) => item.id) || []),
            ].flat(),
          })
        );
        setOpen({ ...open, selectionOptions: false });
      },
    },
    {
      id: 2,
      value: "Clear Selection",
      onClick: () => {
        dispatch(
          updateMyBoard({
            key: "selectedTasks",
            value: [],
          })
        );
        setOpen({ ...open, selectionOptions: false });
      },
    },
  ];

  const projectOptions = [
    {
      id: 1,
      value: "New Project",
      onClick: () => {
        navigate("/project-onboardingV2");
        setOpen({ ...open, projectOptions: false });
      },
    },
    {
      id: 2,
      value: "Existing Project",
      onClick: () => {
        dispatch(
          updateMyBoard({
            key: "projectListModal",
            value: true,
          })
        );
        setOpen({ ...open, projectOptions: false });
      },
    },
  ];

  return (
    <div className="bg-white flex items-center gap-x-3 p-1.5 rounded-lg my-1.5">
      <Popover
        trigger="click"
        placement="bottomLeft"
        content={
          <div className="w-[136px] bg-white border border-gray-200 rounded-lg overflow-hidden">
            {selectionOptions.map((option) => (
              <div
                key={option.id}
                className="px-3 py-1.5 cursor-pointer hover:bg-gray-50"
                onClick={option.onClick}
              >
                <p className="text-gray-600 out-300-14">{option.value}</p>
              </div>
            ))}
          </div>
        }
        open={open.selectionOptions}
        arrow={false}
      >
        <button
          className="py-[5px] pl-[10px] pr-[5px] flex items-center justify-center gap-x-1 border border-gray-200 rounded"
          onClick={() =>
            setOpen({
              ...open,
              selectionOptions: !open.selectionOptions,
              projectOptions: false,
            })
          }
        >
          <p className="text-gray-600 out-300-14">
            {selectedTasks?.length} of {myBoardTasks?.totalRecords} selected
          </p>
          <ChevronIcon className="text-gray-700" />
        </button>
      </Popover>

      <Popover
        trigger="click"
        placement="bottomLeft"
        content={
          <div className="w-[136px] bg-white border border-gray-200 rounded-lg overflow-hidden">
            {projectOptions.map((option) => (
              <div
                key={option.id}
                className="px-3 py-1.5 cursor-pointer hover:bg-gray-50"
                onClick={option.onClick}
              >
                <p className="text-gray-600 out-300-14">{option.value}</p>
              </div>
            ))}
          </div>
        }
        open={open.projectOptions}
        arrow={false}
      >
        <button
          className="p-1 rounded cursor-pointer hover:bg-gray-50"
          onClick={() =>
            setOpen({
              ...open,
              projectOptions: !open.projectOptions,
              selectionOptions: false,
            })
          }
        >
          <MoveIcon />
        </button>
      </Popover>

      <button
        className="p-1 rounded cursor-pointer hover:bg-gray-50"
        onClick={() => {
          dispatch(
            updateMyBoard({
              key: "exportModal",
              value: true,
            })
          );
        }}
      >
        <ExportIcon />
      </button>

      <button
        className="p-1 rounded cursor-pointer hover:bg-error-50"
        onClick={() => {
          dispatch(
            updateMyBoard({
              key: "deleteTaskModal",
              value: { ...deleteTaskModal, visible: true },
            })
          );
        }}
      >
        <DeleteScheduleIcon />
      </button>
    </div>
  );
};
const TaskDates = ({ task, setTask }) => {
  const [showRangePicker, setShowRangePicker] = useState(false);

  const [dates, setDates] = useState([null, null]);

  const handleDateRange = (e) => {
    console.log(e, "theft");
    setDates(e);
    setShowRangePicker(false);
    setTask(e);
  };

  return (
    <div>
      <button
        className="pl-2 out-300-12"
        onClick={() => setShowRangePicker(!showRangePicker)}
      >
        <Popover
          trigger="click"
          placement="bottomLeft"
          content={
            <Calendar
              value={dates}
              format=""
              onChange={handleDateRange}
              range
              numberOfMonths={2}
              showOtherDays
            />
          }
          arrow={false}
        >
          {dates[0] && dates[1] ? (
            <p className="text-black">
              {dayjs(dates[0]).format("DD MMM")} -{" "}
              {dayjs(dates[1]).format("DD MMM")}
            </p>
          ) : (
            <p className="text-gray-500">Start/End dates</p>
          )}

          {/* <DatePicker.RangePicker
          className="custom-picker"
          open={showRangePicker}
          suffixIcon={false}
          onCalendarChange={handleDateRange}
          key={"newTask"}
          onOpenChange={(value) => setShowRangePicker(!value)}
          // value={dates as any}
          // disabledDate={(current) =>
          //   current.isBefore(dayjs().subtract(1, "day"))
          // }
        /> */}
        </Popover>
      </button>
    </div>
  );
};

const CreateTaskHeader = ({ labelOptions }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.userDetails);
  const { myBoardTasks, collaboratorsForTask } = useAppSelector(
    (state) => state.myBoard
  );

  const [showRangePicker, setShowRangePicker] = useState(false);

  const initialState = {
    name: "",
    labelId: null,
    statusId: 1,
    priorityId: 1,
    startDate: null,
    endDate: null,
  };

  const [task, setTask] = useState(initialState);

  useEffect(
    () => console.log(task.startDate, task.endDate, "dates top 44"),
    [task]
  );
  const enableCreateButton = task.name.trim() !== "";

  const handleExpand = () => {
    dispatch(
      updateMyBoard({
        key: "newTask",
        value: {
          goalType: 1,
          name: task.name,
          taskDetails: task,
        },
      })
    );

    const url = `/my-board/task/new`;
    navigate(url);
  };
  const createTask = async () => {
    const payload = {
      ...task,
      collaborators: [user.id, ...collaboratorsForTask],
    };
    try {
      await dispatch(createMyBoardTask(payload));
      await dispatch(
        getMyBoardTasks({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET })
      );
      dispatch(updateMyBoard({ key: "createTaskHeader", value: false }));
      dispatch(
        updateMyBoard({
          key: "collaboratorsForTask",
          value: [],
        })
      );
    } catch (error) {
      console.log("Error occured while creating the task", error);
    } finally {
      setTask(initialState);
    }
  };

  return (
    <div>
      <div className="flex items-center gap-x-1.5 pl-3">
        <p className="text-black out-500-14">Tasks</p>
        <p className="text-gray-500 out-300-14">
          {myBoardTasks?.totalRecords} items
        </p>
      </div>

      <div className="bg-white border-2 border-primary-400 rounded-t-xl py-[5px] px-3 flex items-center gap-x-3 relative mt-2">
        <TaskIcon />
        <div className="flex-1">
          <Input
            autoFocus
            value={task?.name}
            placeholder="What needs to be done?"
            className="w-full text-black bg-white border-none outline-none out-300-14"
            type="text"
            onChange={(e) => setTask({ ...task, name: e.target.value })}
            onPressEnter={createTask}
          />
        </div>

        <LabelSelect
          visible={true}
          task={task}
          labelOptions={labelOptions}
          setTask={async (e) => {
            setTask({ ...task, labelId: e });
          }}
        />

        <StatusSelect
          task={task}
          setTask={async (e) => {
            setTask({ ...task, statusId: e });
          }}
        />

        <TaskDates
          task={task}
          setTask={async (e) => {
            setTask({
              ...task,
              startDate: dayjs(e[0]),
              endDate: dayjs(e[1]),
            });
          }}
        />

        <PrioritySelect
          task={task}
          setTask={async (e) => {
            setTask({ ...task, priorityId: e });
          }}
        />

        <button
          className="relative"
          onClick={() => {
            dispatch(
              updateMyBoard({
                key: "addCollaboratorsModal",
                value: true,
              })
            );
          }}
        >
          <div
            className={`absolute top-[-6px] right-[-4px]  rounded-full w-[14px] h-[14px] z-20 ${
              collaboratorsForTask.length ? "bg-success-600" : "bg-primary-100"
            }`}
          >
            {collaboratorsForTask.length ? (
              <p className="text-center text-white out-600-8 pt-[1px]">
                +{collaboratorsForTask?.length}
              </p>
            ) : (
              <div>
                <PlusIcon width={10} height={10} className="text-primary-600" />
              </div>
            )}
          </div>
          <CustomAvatar
            src={user.profilePicture}
            size={20}
            title={getUsersName(user)}
            whiteText
            color={user.profilePictureColorCode}
          />
        </button>

        <button onClick={handleExpand}>
          <ExpandIcon />
        </button>

        <button onClick={createTask} disabled={!enableCreateButton}>
          <p
            className={`out-500-14 ${
              enableCreateButton ? "text-primary-600" : "text-gray-300"
            }`}
          >
            Create
          </p>
        </button>

        <button
          onClick={() =>
            dispatch(updateMyBoard({ key: "createTaskHeader", value: false }))
          }
        >
          <p className="text-gray-500 out-500-14">Cancel</p>
        </button>
      </div>
    </div>
  );
};

export { PrimaryTaskHeader, SelectedTaskHeader, CreateTaskHeader };
