import Tooltip from "antd/es/tooltip";
import React, { useState, useRef, useEffect } from "react";

interface CustomTooltipProps {
  text?: string;
  textClassName?: string;
}

export const CustomResTooltip = ({
  text,
  textClassName,
}: CustomTooltipProps) => {
  const contRef = useRef<HTMLParagraphElement>(null);
  const [hover, setHover] = useState(false);

  const compareWidth = () => {
    if (!contRef.current) return;

    setHover(contRef.current.scrollWidth > contRef.current.clientWidth);
  };

  useEffect(() => {
    compareWidth();
    window.addEventListener("resize", compareWidth);

    const observer = new ResizeObserver(() => {
      compareWidth();
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (contRef.current) {
      observer.observe(contRef.current);
    }

    return () => {
      window.removeEventListener("resize", compareWidth);
      contRef.current && observer.unobserve(contRef.current);
    };
  }, []);

  useEffect(() => {
    compareWidth();
  }, [text]);

  return (
    <Tooltip
      title={hover ? text : false}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
    >
      <p
        className={
          textClassName ||
          "block whitespace-nowrap overflow-hidden overflow-ellipsis cursor-pointer font-poppins font-medium text-h1 "
        }
        ref={contRef}
        style={{ marginBottom: 0 }}
      >
        {text !== null || text !== undefined ? text : "-"}
      </p>
    </Tooltip>
  );
};
