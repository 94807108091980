import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useNavigate } from "react-router-dom";

const LoggedUser = ({
  visible,
  company = "White Insights",
  email,
  handleClose,
  handleClick,
}) => {
  const navigate = useNavigate();

  return (
    <CustomModal
      visible={visible}
      width="549px"
      onCancel={handleClose}
      body={
        <div className="min-w-[549px] min-h-[236px] p-[50px] flex flex-col gap-y-6">
          <div className="w-full flex gap-x-3 ">
            <div className="flex w-[15%] h-[69px]">
              <img
                src="/images/v2/project-onboarding/frosty.svg"
                className="w-[69px] h-[69px]"
              />
            </div>

            <div className="flex flex-col w-[85%]  gap-y-3 ">
              <div className="out-500-20 ">
                Seems like you are already registered.
              </div>
              <div className="out-300-14 text-gray-500">
                {"The Email ID you entered is already part of "}
                <span className="text-primary-600">
                  {company ? company : "White Insights"}
                </span>{" "}
                {company.includes("workspace") ? "" : " workspace"}. Please Sign
                In to continue to your last opened workspace.
              </div>
            </div>
          </div>
          <div className="w-full h-full flex gap-x-6">
            <CustomButton
              text="Sign in"
              width="144px"
              height="30"
              className="bg-primary-600 text-white py-[5px] px-[10px] "
              onClick={() => navigate("/loginV2")}
            />
            <CustomButton
              text="Change email ID"
              width="144px"
              height="30"
              className="bg-white border border-gray-300"
              onClick={handleClose}
            />
            <button
              className="text-primary-700 cursor-pointer out-500-14"
              onClick={() => navigate("/forgot-passwordV2")}
            >
              Forgot Password?
            </button>
          </div>
        </div>
      }
    />
  );
};

export default LoggedUser;
