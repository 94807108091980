import { Drawer } from "antd";
import React, { useEffect } from "react";
import { ChevronDoubleIcon } from "../../../Core/svgV2/Chevron";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { getComments } from "../../../Core/redux/api/conversationAPI";
import ChatWindow from "../../Conversations/ChatWindow";
import ConversationInput from "../../Conversations/ConversationInput";

const CommentsDrawer = ({ open = false, onClose, socket }) => {
  const { openCommentsModal } = useAppSelector((state) => state.vault);
  const dispatch = useAppDispatch();

  const handelGetComments = async () => {
    await dispatch(
      getComments({
        docId: openCommentsModal?.docId,
        docType: openCommentsModal?.docType,
        limit: 1000,
        offset: 0,
      })
    );
  };
  useEffect(() => {
    if (open) {
      handelGetComments();
    }
  }, [open]);
  return (
    <Drawer
      title={null}
      closable={false}
      placement="right"
      open={open}
      width={"318px"}
    >
      <div className="flex bg-white flex-col h-screen relative  ">
        <div className="flex items-center mt-4 px-4 pb-4 border-b border-gray-100">
          <button onClick={() => onClose()}>
            <ChevronDoubleIcon className="text-gray-700 rotate-180" />
          </button>
          <p className="ml-5  whitespace-nowrap truncate out-500-14 leading-5 text-black">
            {openCommentsModal.name}
          </p>
        </div>
        {/* <div className="p-4">
          <p className="out-500-14 leading-5 text-black">
            Collaborators ({openCommentsModal.users.length})
          </p>
        </div> */}
        <ChatWindow comments socket={socket} />
      </div>
    </Drawer>
  );
};

export default CommentsDrawer;
