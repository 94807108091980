import { Menu, Table } from "antd";

import React from "react";
// import PropTypes from "prop-types";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

/**
 * @param  {array} data
 * @param  {array} columns
 * @param  {number} current
 * @param  {number} pageSize
 * @param  {function} setPageSize
 * @param  {function} setCurrent
 * @param  {string} customPadding
 * @param  {string} rowClassName
 * @param  {bool} loading
 */

type CustomTableProps = {
  data: any;
  columns: object[];
  current?: number;
  pageSize?: number;
  setPageSize?: (e: number) => void;
  setCurrent?: (e: number) => void;
  customPadding?: string;
  scroll?: string;
  rowClassName?: any | string;
  loading?: boolean;
  dataCount?: number;
  className?: string;
  customFooter?: any;
  onRow?: any;
  scrollX?: string;
  locale?: any;
  paginationEnabled?: boolean;
};

export default function CustomTable({
  data,
  columns,
  current,
  pageSize,
  setPageSize,
  setCurrent,
  customPadding,
  scroll = "calc(100vh - 250px)",
  rowClassName,
  loading = false,
  dataCount,
  className,
  customFooter,
  onRow,
  scrollX = "max-content",
  locale = {},
  paginationEnabled = true,
}: CustomTableProps) {
  // const getData = (current, pageSize) => {
  //   return data.slice((current - 1) * pageSize, current * pageSize)
  // }

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    let value = 0;

    switch (key) {
      case "1":
        value = 10;
        break;
      case "2":
        value = 50;
        break;
      case "3":
        value = 100;
        break;
      default:
        value = 10;
        break;
    }

    setPageSize(value);
  };

  const menu = (
    <Menu
      className="w-32 search-shadow"
      style={{
        borderRadius: "8px",
        color: "#667085",
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "13px",
      }}
      onClick={handleMenuClick}
      items={[
        {
          label: "10/Page",
          key: "1",
          //   value: 10,
        },
        {
          label: "50/Page",
          key: "2",
          //   value: 50,
        },
        {
          label: "100/Page",
          key: "3",
          //   value: 100,
        },
      ]}
    />
  );

  // const customNoData = () => <></>;

  return (
    <Table
      //key={data.id}
      columns={columns}
      dataSource={[...data]}
      // dataSource={getData(current, pageSize)}
      //rowKey="id"
      bordered
      scroll={{
        y: scroll,
        x: scrollX,
      }}
      //size="small"

      rowKey={(record) => record.id}
      showSorterTooltip={false}
      pagination={
        paginationEnabled
          ? {
              defaultCurrent: 1,
              defaultPageSize: 10,
              current: current,
              pageSize: pageSize,
              total: dataCount,
            }
          : false
      }
      // pagination={false}
      rowClassName={rowClassName}
      onRow={onRow}
      // footer={
      //   customFooter
      //     ? customFooter
      //     : () => (
      //         <div
      //           className={`flex justify-between border-t border-gray-200 items-center h-full ${
      //             customPadding || "px-3 py-2"
      //           }`}
      //         >
      //           <div className="flex items-center justify-start gap-x-4">
      //             <Dropdown overlay={menu}>
      //               <div className="flex items-center bg-gray-50 justify-between border rounded-lg border-grey-100 px-1 py-1 min-w-[61px]">
      //                 <div className="text-gray-400 mon-500-12">{pageSize}</div>
      //                 <img src="/images/icons/chevron-down-gray.svg" alt="" />
      //               </div>
      //             </Dropdown>
      //             <div className="text-gray-400 mon-500-12">
      //               Showing {current}-
      //               {pageSize > dataCount ? dataCount : pageSize} of {dataCount}
      //             </div>
      //           </div>
      //           <Pagination
      //             total={dataCount}
      //             current={current}
      //             onChange={setCurrent}
      //             pageSize={pageSize}
      //             size="small"
      //           />
      //         </div>
      //       )
      // }
      footer={customFooter || null}
      className={`table-shadow table-min-height ${className || "custom-table"}`}
      loading={
        loading && {
          spinning: loading,
          indicator: (
            <LoadingOutlined style={{ fontSize: 26, color: "#4E2C90" }} spin />
          ),
        }
      }
      locale={locale}
    />
  );
}
