import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import { Checkbox, Popover } from "antd";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { SearchProjects } from "../SettingsLayout";
import { client } from "../../../Core/utils/axiosClient";
import useSearch from "../../../Core/hooks/useSearch";
import { useSearchParams } from "react-router-dom";

const RoleCard = ({ role, handleCheckboxClick, checked }) => {
  return (
    <div
      className={`relative group rounded-lg px-3 py-2.5 border w-[201px] border-gray-200`}
    >
      <Checkbox
        checked={checked}
        onChange={() => handleCheckboxClick(role.id)}
      />
      <div className="out-500-14 text-gray-700 mt-1">{role.roleName}</div>
    </div>
  );
};

const ImportRolesModal = ({
  visible,
  onClose,
  currentSelectedProject,
  setCurrentSelectedProject,
}) => {
  const [roleList, setRoleList] = useState([]);
  const [searchParams] = useSearchParams();

  const { search, setSearch, filteredData } = useSearch(roleList, ["roleName"]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const getProjectRole = async () => {
    try {
      const { data } = await client.get("/permissions/role-list", {
        params: { projectId: currentSelectedProject.id },
      });
      setRoleList(data.result);
    } catch (e) {
      console.log(e);
    }
  };
  const onCancel = () => {
    setSelectedRoles([]);
    onClose();
  };

  useEffect(() => {
    if (currentSelectedProject.id && visible) {
      getProjectRole();
    }
  }, [currentSelectedProject, visible]);

  const handleCheckboxClick = (id) => {
    setSelectedRoles((prevSelectedRoles) => {
      const exists = prevSelectedRoles.includes(id);

      return exists
        ? prevSelectedRoles.filter((roleId) => roleId !== id)
        : [...prevSelectedRoles, id];
    });
  };

  const handelImport = async () => {
    if (selectedRoles.length === 0) return;
    if (searchParams.has("projectId")) {
      const projectId = searchParams.get("projectId");
      if (projectId == currentSelectedProject.id) return;
      await client.post("/permissions/import-role", {
        projectId,
        roleIdList: selectedRoles,
      });
    }

    onClose();
  };

  return (
    <CustomModal
      visible={visible}
      width="667px"
      onCancel={() => onClose()}
      body={
        <div className="p-4 flex flex-col gap-y-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Import Roles</p>

            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <div className="flex w-full gap-x-3">
            <CustomSearchBox
              placeholder="Search Roles"
              style={{ flex: 1 }}
              search={search}
              setSearch={setSearch}
            />
            <Popover
              trigger={"click"}
              content={
                <SearchProjects
                  currentSelectedProject={currentSelectedProject}
                  onProjectClick={(project) =>
                    setCurrentSelectedProject(project)
                  }
                />
              }
              placement="bottomRight"
              arrow={false}
            >
              <div className="flex gap-x-1.5 cursor-pointer items-center">
                <div className="out-500-14 text-black">
                  {currentSelectedProject.title}
                </div>
                <ChevronIcon />
              </div>
            </Popover>
          </div>
          <div className="out-300-14 text-gray-500">Select roles to import</div>
          <div className="flex flex-wrap gap-4">
            {filteredData.map((role) => (
              <RoleCard
                key={role.id}
                role={role}
                handleCheckboxClick={handleCheckboxClick}
                checked={selectedRoles.includes(role.id)}
              />
            ))}
          </div>
          <div className="flex justify-end gap-x-3">
            <CustomButton
              text="Cancel"
              height="30px"
              className="bg-white text-gray-500"
              onClick={onCancel}
            />
            <CustomButton
              text="Import"
              height="30px"
              className="bg-white text-primary-700"
              onClick={handelImport}
            />
          </div>
        </div>
      }
    />
  );
};

export default ImportRolesModal;
