import { Avatar, Divider, Drawer, Tooltip } from "antd";
import React from "react";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";

import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { bytesToSize } from "../../../Core/utils/bytesToSize";
import { useLocation } from "react-router-dom";
import { updateVault } from "../../../Core/redux/slices/vault.slice";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const ViewDetailsDrawer = ({ open = false, onClose }) => {
  const { viewDetailsDrawer } = useAppSelector((state) => state.vault);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const renderFileTag = (mimeType = "") => {
    console.log(mimeType, "hvjhvjhvj");
    if (mimeType.includes("pdf")) {
      return "/images/v2/library/pdf-file-icon.svg";
    } else if (mimeType.includes("excel") || mimeType.includes("spreadsheet")) {
      return "/images/v2/library/excel-file-icon.svg";
    } else if (mimeType.includes("image")) {
      return "/images/v2/library/image-file-icon.svg";
    } else if (mimeType.includes("video")) {
      return "/images/v2/library/video-file-icon.svg";
    } else {
      return "/images/v2/library/default-file-icon.svg";
    }
  };

  const ownerDetails = tenantMembers.filter(
    (user) => user.userId === viewDetailsDrawer?.document?.createdById
  )[0];

  // const getStorageSpace = async (docId) => {
  //   const result = await client.get(`/repository/get-storage-space`, {
  //     params: { folderId: docId, projectId: projectId },
  //   });
  //   console.log(result.data.result[0].sum, "check storage space");
  //   setStorageSpace(result.data.result[0].sum);
  // };

  // useEffect(() => {
  //   viewDetailsDrawer.documentType === "folder" &&
  //     getStorageSpace(viewDetailsDrawer.document?.docId);
  // }, [viewDetailsDrawer.document?.docId]);

  const getDocImage = () => {
    if (viewDetailsDrawer.documentType === "folder") {
      return "/images/icons/folder-fill-vault.svg";
    }
    if (viewDetailsDrawer.documentType === "link") {
      return "/images/v2/library/link-icon.svg";
    }
    return renderFileTag(viewDetailsDrawer.document?.mimeType);
  };

  const handleManageCollaborators = () => {
    dispatch(
      updateVault({
        key: "shareAccessModal",
        value: {
          visible: true,
          docId: viewDetailsDrawer?.document?.docId,
          users: viewDetailsDrawer?.document?.usersWithAccess,
          docType: viewDetailsDrawer.documentType,
          docDetails: {
            ...viewDetailsDrawer?.document,
            name: viewDetailsDrawer?.document.title,
          },
        },
      })
    );
    onClose();
  };

  const getStorage = () => {
    return viewDetailsDrawer.document?.fileSize ||
      viewDetailsDrawer.document?.folderSize
      ? `${bytesToSize(
          viewDetailsDrawer.document?.fileSize ||
            viewDetailsDrawer.document?.folderSize
        )}`
      : "0 KB";
  };

  return (
    <Drawer
      title={null}
      closable={false}
      placement="right"
      open={open}
      width={"318px"}
    >
      <div className="p-4 flex flex-col h-full relative max-w-[318px]">
        <div className="flex-1">
          <button onClick={onClose} className="absolute top-4">
            <CrossIcon className="text-gray-700" />
          </button>
          <div className="flex w-full justify-center flex-col items-center">
            <img src={getDocImage()} className="w-5 h-5" />
            <Tooltip
              title={viewDetailsDrawer?.document?.title}
              placement="bottom"
            >
              <p className="out-500-14 whitespace-nowrap  max-w-[280px] truncate  leading-5 text-black mt-[6px]">
                {viewDetailsDrawer?.document?.title}
              </p>
            </Tooltip>
          </div>

          <div className="mt-8">
            <p className="out-500-14 leading-5 text-black">
              Collaborators (
              {viewDetailsDrawer?.document?.usersWithAccess?.length})
            </p>

            <div className="mt-4 cursor-pointer">
              <Avatar.Group
                maxCount={3}
                maxPopoverTrigger="click"
                size={24}
                maxStyle={{
                  color: "#165ABF",
                  backgroundColor: "#EFF8FF",
                  cursor: "pointer",
                }}
              >
                {viewDetailsDrawer?.document?.usersWithAccess.map((member) => {
                  return (
                    <Tooltip
                      key={member.title}
                      title={`${member.firstname} ${member.lastname}`}
                      placement="bottom"
                    >
                      {/* <Avatar
                      style={{ backgroundColor: "#87d068" }}
                      icon={<CustomAvatar title={member.title} />}
                    /> */}
                      <div>
                        {" "}
                        <CustomAvatar
                          title={`${member.firstname} ${member.lastname}`}
                          src={member.profilePicture}
                          size={32}
                          whiteText
                          fontSize={"10px"}
                          color={member.profilePictureColorCode}
                        />
                      </div>
                      {/* <Avatar  /> */}
                    </Tooltip>
                  );
                })}
              </Avatar.Group>
            </div>

            <div className="mt-4">
              <CustomButton
                text="Manage members"
                className="text-primary-600 bg-primary-50"
                height="30px"
                onClick={handleManageCollaborators}
              />
            </div>
          </div>
          <div>
            <Divider className="w-full bg-gray-200" />
          </div>

          <div className="mt-4">
            <p className="out-500-14 text-black">Details</p>
          </div>
          <div className="mt-4">
            <p className="out-300-12 leading-[18px] text-gray-500">Location</p>
            <div className="mt-2 flex items-center">
              {/* <CustomAvatar
                title={viewDetailsDrawer?.document?.title}
                src={viewDetailsDrawer?.document?.title}
                size={16}
              /> */}
              <p className="out-500-12 leading-[18px] text-black  capitalize">
                {/* {viewDetailsDrawer?.document?.title} */}
                {location.pathname}
              </p>
            </div>
          </div>
          <div className="mt-5">
            <p className="out-300-12 leading-[18px] text-gray-500">Owner</p>
            <div className="mt-2 flex items-center">
              <CustomAvatar
                title={`${
                  ownerDetails?.firstname ? ownerDetails.firstname : "User"
                } ${ownerDetails?.lastname ? ownerDetails.lastname : ""}`}
                src={ownerDetails?.profilePicture}
                size={16}
                whiteText
                color={ownerDetails?.profilePictureColorCode}
              />
              <p className="out-500-12 leading-[18px] text-black ml-2">
                {`${
                  ownerDetails?.firstname ? ownerDetails.firstname : "User"
                } ${ownerDetails?.lastname ? ownerDetails.lastname : ""}`}
              </p>
            </div>
          </div>
          <div className="mt-5">
            <p className="out-300-12 leading-[18px] text-gray-500">
              Created on
            </p>
            <p className="out-500-12 leading-[18px] text-black mt-2">
              {viewDetailsDrawer.document?.createdAt
                ? dayjs(viewDetailsDrawer.document?.createdAt)
                    .local()
                    .format("MMMM DD YYYY, hh:mm a")
                : "N/A"}
            </p>
          </div>
          <div className="mt-5">
            {viewDetailsDrawer?.documentType !== "link" && (
              <>
                <p className="out-300-12 leading-[18px] text-gray-500">Size</p>
                <p className="out-500-12 leading-[18px] text-black mt-2">
                  {" "}
                  {getStorage()}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ViewDetailsDrawer;
