import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { client } from "../../../Core/utils/axiosClient";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { getGoalDetails } from "../../../Core/redux/api/scheduleAPI";

const UnwaddleModal = ({ visible, onClose }) => {
  const { taskId } = useParams();
  const dispatch = useAppDispatch();
  const handleConfirm = async () => {
    try {
      await client.put("/schedule/un-waddle-it", {
        goalId: taskId,
      });
      dispatch(getGoalDetails({ goalId: taskId }));
    } catch (e) {
      console.log(e);
    } finally {
      onClose();
    }
  };
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-primary-600 out-500-14">Un-Waddle this task</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to un-waddle this task? This action cannot be
            undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Confirm"
              height="30px"
              onClick={handleConfirm}
            />
          </div>
        </div>
      }
    />
  );
};

export default UnwaddleModal;
