import React from "react";
import Icon from "@ant-design/icons";

const FilterSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7 2.5h11.333l-6.666 7.883v6.791L8.332 15.5v-5.117L1.666 2.5H4"
    ></path>
  </svg>
);

const FilterIcon = (props) => {
  return <Icon component={FilterSvg} {...props} />;
};

export { FilterIcon };
