import React, { useState } from "react";
import KanbanColumn from "./KanbanColumn";
import TaskCard, { NewtaskCard } from "../TaskCard";
import KanbanItem from "./KanbanItem";
import { useAppSelector } from "../../../../Core/redux/hooks";
import CustomBadge from "../../../../Core/CommonV2/CustomBadge";
import TaskItem from "../../Planner/TaskItem";
import { useParams } from "react-router-dom";

const BugKanbanBoard = ({ task, board, statusArray, handleFilter }) => {
  const { showNewTaskCard } = useAppSelector((state) => state.schedule);
  const { projectId } = useParams();

  const { labels } = useAppSelector((state) => state.schedule);
  const [showSubtasks, setShowSubTasks] = useState(true);
  const option = labels
    .filter((label) => label.projectId === projectId)
    .map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <CustomBadge
              text={label.name}
              color={label.color}
              className="border border-gray-200"
              style={{ borderColor: label.color }}
            />
            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });
  return (
    <div>
      {showSubtasks && (
        <div
          data-kanbanboard={true}
          className="grid-container ml-[2px]   "
          ref={board}
        >
          {statusArray &&
            [...statusArray].map((channel, index) => (
              // todo check if removing null object any effect
              <KanbanColumn
                isBugs = {true}
                key={channel.id}
                status={`${channel.id}_bug_${index}`}
                channel={channel}
                className=" kanban-col "
              >
                {showNewTaskCard?.visible &&
                showNewTaskCard?.channel.id === channel.id  && showNewTaskCard?.taskId === "" ?(
                  <NewtaskCard
                    status={channel}
                    isHovered={true}
                    defaultOpen
                    parentTaskId={task.id}
                    isBugs={true}
                  />
                ) : null}
                {handleFilter(task, channel).map((item) => (
                  <KanbanItem key={item.id} id={item.id}>
                    <TaskCard task={item} />
                  </KanbanItem>
                ))}
              </KanbanColumn>
            ))}
        </div>
      )}
    </div>
  );
};

export default BugKanbanBoard;
