import React from "react";
import { useAppSelector } from "../../Core/redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProjectSettingHeader } from "./SettingsLayout";
import CustomButton from "../../Core/CommonV2/CustomButton";
import usePermission from "../../Core/hooks/usePermission";

const ProjectsSettingsWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { projectList } = useAppSelector((state) => state.projects);
  const { user } = useAppSelector((state) => state.userDetails);
  const { hasPermission: CREATE_PROJECT } = usePermission("1");

  const [searchParams] = useSearchParams();

  if (projectList.length === 0) {
    return (
      <div className="mt-[78px] w-full flex  items-center flex-col rounded-lg gap-y-6 border border-gray-200 px-4 py-6">
        <div className="out-500-14 text-center text-black">
          No project created
        </div>
        <img
          src="/images/v2/settings/frosty-search.svg"
          className="w-[146px]"
        />
        <p className="out-300-14 text-gray-500">
          {CREATE_PROJECT
            ? "Create a new project to manage roles, members, notifications, etc."
            : "You do not have permission to create a new project"}
        </p>
        {CREATE_PROJECT && +user.roleId !== 4 && (
          <CustomButton
            text="Create New Project"
            height="30px"
            onClick={() => navigate("/project-onboardingV2")}
          />
        )}
      </div>
    );
  }

  const isMyBoard = searchParams.get("myboard") === "true";
  const projectId = searchParams.get("projectId");

  if (!projectId && !isMyBoard) {
    return (
      <div className="mt-[78px] w-full flex  items-center flex-col rounded-lg gap-y-6 border border-gray-200 px-4 py-6">
        <div className="out-500-14 text-center text-black">
          No project selected
        </div>
        <img
          src="/images/v2/settings/frosty-search.svg"
          className="w-[146px]"
        />
        <p className="out-300-14 text-gray-500">
          Select a project to manage roles, members, notifications, etc.
        </p>
        <ProjectSettingHeader />
      </div>
    );
  }

  return children;
};

export default ProjectsSettingsWrapper;
