import { createSlice, current } from "@reduxjs/toolkit";
import { getSectors } from "../api/onboardingAPI";

const initialState = {
  loading: false,
  error: {},
  currentStep: 1,
  // step 1
  projectName: "",
  projectImage: "",
  projectColor: "",
  // step 2
  clientName: "",
  clientImage: "",
  internalProject: false,
  clientColor: "",
  // step 3
  projectDuration: null,
  clientLocation: "",
  // step 4
  softwareType: [],
  isOpen: 0,
  // step 5
  selectedPhase: [1, 2, 3, 4, 5],
  phaseDetails: [
    {
      id: 1,
      startDate: null,
      endDate: null,
      phaseName: "Ideation",
      components: [
        {
          id: 1,
          title: "Back-end",
          content: [
            {
              id: 1,
              heading: "Node JS",
            },
          ],
        },
        {
          id: 2,
          title: "Front-end",
          content: [
            {
              id: 1,
              heading: "React JS",
            },
            {
              id: 2,
              heading: "Next JS",
            },
          ],
        },
        {
          id: 3,
          title: "Testing",
          content: [
            {
              id: 1,
              heading: "BrowserStack",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      startDate: null,
      endDate: null,
      phaseName: "Design",
      components: [
        {
          id: 1,
          title: "Back-end",
          content: [
            {
              id: 1,
              heading: "Node JS",
            },
          ],
        },
        {
          id: 2,
          title: "Front-end",
          content: [
            {
              id: 1,
              heading: "React JS",
            },
            {
              id: 2,
              heading: "Next JS",
            },
          ],
        },
        {
          id: 3,
          title: "Testing",
          content: [
            {
              id: 1,
              heading: "BrowserStack",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      startDate: null,
      endDate: null,
      phaseName: "Development",
      components: [
        {
          id: 1,
          title: "Back-end",
          content: [
            {
              id: 1,
              heading: "Node JS",
            },
          ],
        },
        {
          id: 2,
          title: "Front-end",
          content: [
            {
              id: 1,
              heading: "React JS",
            },
            {
              id: 2,
              heading: "Next JS",
            },
          ],
        },
        {
          id: 3,
          title: "Testing",
          content: [
            {
              id: 1,
              heading: "BrowserStack",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      startDate: null,
      endDate: null,
      phaseName: "Deployment",
      components: [
        {
          id: 1,
          title: "Back-end",
          content: [
            {
              id: 1,
              heading: "Node JS",
            },
          ],
        },
        {
          id: 2,
          title: "Front-end",
          content: [
            {
              id: 1,
              heading: "React JS",
            },
            {
              id: 2,
              heading: "Next JS",
            },
          ],
        },
        {
          id: 3,
          title: "Testing",
          content: [
            {
              id: 1,
              heading: "BrowserStack",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      startDate: null,
      endDate: null,
      phaseName: "Maintenance",
      components: [
        {
          id: 1,
          title: "Back-end",
          content: [
            {
              id: 1,
              heading: "Node JS",
            },
          ],
        },
        {
          id: 2,
          title: "Front-end",
          content: [
            {
              id: 1,
              heading: "React JS",
            },
            {
              id: 2,
              heading: "Next JS",
            },
          ],
        },
        {
          id: 3,
          title: "Testing",
          content: [
            {
              id: 1,
              heading: "BrowserStack",
            },
          ],
        },
      ],
    },
  ],
  components: {
    Design: [
      {
        id: 1,
        title: "Back-end",
        content: [
          {
            id: 1,
            heading: "Node JS",
          },
        ],
      },
      {
        id: 2,
        title: "Front-end",
        content: [
          {
            id: 1,
            heading: "React JS",
          },
          {
            id: 2,
            heading: "Next JS",
          },
        ],
      },
      {
        id: 3,
        title: "Testing",
        content: [
          {
            id: 1,
            heading: "BrowserStack",
          },
        ],
      },
    ],
    Development: [
      {
        id: 1,
        title: "Back-end",
        content: [
          {
            id: 1,
            heading: "Node JS",
          },
        ],
      },
      {
        id: 2,
        title: "Front-end",
        content: [
          {
            id: 1,
            heading: "React JS",
          },
          {
            id: 2,
            heading: "Next JS",
          },
        ],
      },
      {
        id: 3,
        title: "Testing",
        content: [
          {
            id: 1,
            heading: "BrowserStack",
          },
        ],
      },
    ],
    Testing: [
      {
        id: 1,
        title: "Back-end",
        content: [
          {
            id: 1,
            heading: "Node JS",
          },
        ],
      },
      {
        id: 2,
        title: "Front-end",
        content: [
          {
            id: 1,
            heading: "React JS",
          },
          {
            id: 2,
            heading: "Next JS",
          },
        ],
      },
      {
        id: 3,
        title: "Testing",
        content: [
          {
            id: 1,
            heading: "BrowserStack",
          },
        ],
      },
    ],
    Deployment: [
      {
        id: 1,
        title: "Back-end",
        content: [
          {
            id: 1,
            heading: "Node JS",
          },
        ],
      },
      {
        id: 2,
        title: "Front-end",
        content: [
          {
            id: 1,
            heading: "React JS",
          },
          {
            id: 2,
            heading: "Next JS",
          },
        ],
      },
      {
        id: 3,
        title: "Testing",
        content: [
          {
            id: 1,
            heading: "BrowserStack",
          },
        ],
      },
    ],
  },
  phasesDescriptionModal: false,
  projectCreationModal: { visible: false, data: null },
  projectType: 1,
  hasPhases: true,
};

export const projectOnboardingSlice = createSlice({
  name: "projectOnboarding",
  initialState: { ...initialState },
  reducers: {
    changeStep: (state, action) => {
      state.currentStep = action.payload;
    },
    updateProjectOnboarding: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    resetToInitialProjectOnboarding: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    compeleteResetProjectOnboarding: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const {
  changeStep,
  updateProjectOnboarding,
  compeleteResetProjectOnboarding,
  resetToInitialProjectOnboarding,
} = projectOnboardingSlice.actions;
export default projectOnboardingSlice.reducer;
