import { Checkbox, Divider, Upload } from "antd";
import React, { useState } from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  compeleteResetProjectOnboarding,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";
import uniqid from "uniqid";
import axios from "axios";
import ImgCrop from "antd-img-crop";
// import "antd/es/modal/style";
// import "antd/es/slider/style";

import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomColorPicker from "../../../Core/CommonV2/CustomColorPicker";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { useNavigate } from "react-router";

const Step2 = () => {
  const dispatch = useAppDispatch();
  const {
    clientName,
    clientImage,
    internalProject,
    clientColor,
    projectImage,
  } = useAppSelector((state) => state.projectOnboarding);

  const [fileList, setFileList] = useState([]);

  const [fileUploadedList, setFileUploadedList] = useState([]);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const fileData = [];

    console.log(newFileList);

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
        });
      });
    });
    console.log(fileData, "file");
    setFileUploadedList(fileData);
    dispatch(
      updateProjectOnboarding({
        key: "clientImage",
        value: fileData[0]?.url || "",
      })
    );
  };

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    console.log("FILE: ", file);
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          console.log("UPLOADED", res.data);
          onSuccess(res.data);
        });
    } catch (err) {
      onError({ err });
    }
  };

  const formik = useFormik({
    initialValues: {
      clientName,
    },
    validationSchema: Yup.object({
      clientName: Yup.string()
        .required("Please enter the client’s company name to continue")
        .typeError("Please enter the client’s company name to continue"),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(changeStep(3));
    },
  });

  const { tenantDetails } = useAppSelector((state) => state.tenant);

  const defaultCompanyName = tenantDetails?.tenantName || "";
  const defaultCompanyAddress = tenantDetails?.tenantLocation || "";
  const navigate = useNavigate();
  return (
    <StepLayout>
      <CurrentStep className={"mt-20"} currentStep={2} totalStep={6} />
      <StepHeading
        title={
          <p className="text-gray-900 out-500-20" id="stepHead">
            Great! Who are you working with?
          </p>
        }
        subTitle={
          <p className="w-[485px]">
            {/* {internalProject */}
            {/* ? "Awesome, I have heard great ideas shape the world." */}
            If it’s your internal project, click on the checkbox!
          </p>
        }
      />
      {/* {internalProject ? (
        <></>
      ) : ( */}
      <div className="mt-[18px] flex items-center justify-between">
        {/* {internalProject ? (
          <div></div>
        ) : ( */}
        <p className="out-500-14 text-gray-900 text-base">Add client</p>
        {/* )} */}

        <div className="flex items-center ml-10">
          <Checkbox
            value={internalProject}
            onChange={(e) => {
              if (e.target.checked) {
                formik.setFieldValue("clientName", defaultCompanyName);
                dispatch(
                  updateProjectOnboarding({
                    key: "clientName",
                    value: defaultCompanyName,
                  })
                );
                dispatch(
                  updateProjectOnboarding({
                    key: "clientLocation",
                    value: defaultCompanyAddress,
                  })
                );
                dispatch(
                  updateProjectOnboarding({
                    key: "internalProject",
                    value: e.target.checked,
                  })
                );
              } else {
                formik.setFieldValue("clientName", "");
                dispatch(
                  updateProjectOnboarding({
                    key: "clientName",
                    value: "",
                  })
                );
                dispatch(
                  updateProjectOnboarding({
                    key: "clientLocation",
                    value: "",
                  })
                );
                dispatch(
                  updateProjectOnboarding({
                    key: "internalProject",
                    value: e.target.checked,
                  })
                );
              }
            }}
          />
          <p className="out-400-12 text-gray-700 pl-2">Internal project</p>
        </div>
      </div>
      {/* )} */}

      <div className="mt-[18px]">
        <CustomOnboardingInput
          placeholder="Enter company name"
          value={clientName}
          name="clientName"
          {...formik.getFieldProps("clientName")}
          onChange={(e, value) => {
            formik.setFieldValue("clientName", value);
            dispatch(
              updateProjectOnboarding({
                key: "clientName",
                value,
              })
            );
          }}
          onClear={() => {
            dispatch(
              updateProjectOnboarding({
                key: "clientName",
                value: "",
              })
            );
            formik.setFieldValue("clientName", "");
          }}
          formikHook={formik}
          //   customEnd={
          //     <div className="flex items-center ml-10">
          //       <Checkbox defaultChecked />
          //       <p className="out-400-12 text-gray-700 pl-2">Internal project</p>
          //     </div>
          //   }
        />
      </div>

      <p className="mt-10 out-500-14 text-gray-900 text-base">
        Let’s add a Logo too
      </p>
      <div className="mt-[18px] flex items-center gap-x-6">
        {clientImage?.length > 0 ? (
          <img
            src={clientImage}
            className="w-16 h-16 rounded-full cursor-pointer object-cover"
            onClick={async () => {
              // const src = clientImage as string;
              // if (!src) return;
              // const image = new Image();
              // image.src = src;
              // const imgWindow = window.open(src);
              // imgWindow?.document.write(image.outerHTML);
              handleChange({ fileList: [] });
            }}
            onMouseEnter={(e) => {
              e.currentTarget.src =
                "/images/icons/project-onboarding/cancelImage.svg";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.src = clientImage;
            }}
          />
        ) : (
          <ImgCrop rotationSlider modalClassName="image-crop-modal ">
            <Upload
              action={process.env.REACT_APP_API_URL + "/v1/upload"}
              // headers={{ Authorization: "Bearer " + user.token }}
              customRequest={uploadImage}
              fileList={fileList}
              onChange={handleChange}
            >
              <div className="w-16 h-16 cursor-pointer">
                <img
                  src="/images/v2/onboarding/Logo.svg"
                  className="w-16 h-16"
                />
              </div>
            </Upload>
          </ImgCrop>
        )}
        <div>
          <Divider type="vertical" className="h-6" />
          <p className="out-500-12 text-gray-500 text-center">or</p>
          <Divider type="vertical" className="h-6" />
        </div>
        <CustomAvatar
          title={clientName ? clientName : "P"}
          size={64}
          color={clientColor}
          whiteText
          fontSize="24px"
        />
        <div className="flex items-center cursor-pointer">
          <p className="out-500-12 text-gray-500">Change colour</p>
          {/* <Dropdown
            getPopupContainer={(trigger) => trigger.parentElement}
            className=""
            overlay={
              <Menu className="rounded-lg custom-menu-dropdown bg-white  shadow-dropdown">
                <CustomColorPicker
            placement="bottomLeft"
            currentColor={clientColor}
            onColorChange={(color) => {
              dispatch(
                updateProjectOnboarding({
                  key: "clientColor",
                  value: color,
                })
              )
            }}
          />
              </Menu>
            }
            trigger={["click"]}
          > */}
          <CustomColorPicker
            placement="bottomLeft"
            onColorChange={(color) => {
              dispatch(
                updateProjectOnboarding({
                  key: "clientColor",
                  value: color,
                })
              );
            }}
          >
            <button>
              <ChevronIcon className="text-gray-500 ml-2" />
            </button>
          </CustomColorPicker>
          {/* </Dropdown> */}
        </div>
      </div>
      {/* <ImgCrop rotationSlider modalClassName="image-crop-modal ">
        <Upload
          action={process.env.REACT_APP_API_URL + "/v1/upload"}
          // headers={{ Authorization: "Bearer " + user.token }}
          customRequest={uploadImage}
          fileList={fileList}
          onChange={handleChange}
        >
          <div className="flex items-center mt-5 ml-1">
            <img src="/images/icons/upload.svg" />
            <p className="pl-2 out-500-12 text-gray-500">Upload</p>
          </div>
        </Upload>
      </ImgCrop> */}
      <div className="flex items-center mt-[18px]">
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              dispatch(
                updateProjectOnboarding({
                  key: "clientImage",
                  value: projectImage,
                })
              );
            } else {
              dispatch(
                updateProjectOnboarding({
                  key: "clientImage",
                  value: "",
                })
              );
            }
          }}
        />
        <p className="out-400-12 text-gray-700 pl-2">
          Use same logo as the Project
        </p>
      </div>
      <div className="mt-6 flex justify-between items-center">
        <CustomButton
          text="Cancel"
          width="75px"
          height="30px"
          className={`bg-gray-100 out-500-14 text-gray-700 `}
          onClick={() => {
            dispatch(compeleteResetProjectOnboarding({}));
            navigate("/dashboard");
          }}
        />
        <div className="flex gap-x-2">
          <CustomButton
            text="Back"
            onClick={() => dispatch(changeStep(1))}
            className=" text-gray-700 bg-gray-100 out-500-14"
            height="30px"
            width="63px"
          />
          <CustomButton
            text="Continue"
            width="88px"
            height="30px"
            className={`bg-primary-600 out-500-14 text-white ${
              !formik.isValid ? "opacity-30" : ""
            }`}
            onClick={() => formik.handleSubmit()}
          />
        </div>
      </div>
    </StepLayout>
  );
};

export default Step2;
