import { Tour } from "antd";
//import Tour from "@rc-component/tour";

import React, { useEffect } from "react";

import type { TourProps } from "antd";

import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import TakeTourModal from "../../Core/CommonV2/TakeTourModal";
import TourPanel from "../../Core/CommonV2/TourPanel";
import {
  addDummyDataForScheduleScreen,
  updateSchedule,
} from "../../Core/redux/slices/schedule.slice";
import { useNavigate, useParams } from "react-router-dom";
import { scheduleDummyData } from "../../Core/dummyData";
import {
  getAllTourStatus,
  updateTourStatus,
} from "../../Core/redux/api/dashboardAPI";

const ScheduleAppTour = ({ initialProjectId }) => {
  const { run, showTakeTourModal, currentTourStep } = useAppSelector(
    (state) => state.schedule
  );
  const { user } = useAppSelector((state) => state.userDetails);
  const { toursPopup } = useAppSelector((state) => state.dashboard);
  const navigate = useNavigate();
  const { projectId } = useParams();

  const tourStatus = async () => {
    await dispatch(getAllTourStatus({ userId: user.id }));
  };
  useEffect(() => {
    tourStatus();
  }, [user.id]);

  const setRun = (value) => {
    dispatch(updateSchedule({ key: "run", value }));
  };

  const setShowTakeTourModal = (value) => {
    dispatch(updateSchedule({ key: "run", value }));
  };

  const steps: TourProps["steps"] = [
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Schedule"}
            module="ScheduleTour"
            description={
              "Schedule comes with four different modules: Roadmap, Planner, Sprints and Reports"
            }
            total={8}
            img="/images/v2/schedule/tour-1.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Schedule</div>,
      style: { right: "15%", width: "420px" },

      target: () =>
        document.querySelector(
          "#schedule-tour-schedule-route-selector"
        ) as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Roadmap"}
            description={
              "Plan your projects more effectively using Roadmap. Easily visualize your project timeline using the different views available. Create and manage Sprints right here."
            }
            module="ScheduleTour"
            total={8}
            img="/images/v2/schedule/tour-2.svg"
            nextStepText="Month View"
          />
        );
      },
      style: { width: "403px", left: "100px", top: "auto", bottom: "10px" },
      title: <div className="out-500-16 text-primary-600">Roadmap</div>,
      placement: "leftBottom",
      arrow: false,

      target: () =>
        document.querySelector("#schedule-tour-roadmap") as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Month View"}
            description={
              "Plan your projects more effectively using Roadmap. Click and drag the Phases to change the duration. Add and edit sprints using the Phase progress bar."
            }
            module="ScheduleTour"
            total={8}
            img="/images/v2/schedule/tour-3.svg"
            nextStepText="Year View"
          />
        );
      },
      style: { width: "403px", left: "100px", top: "auto", bottom: "10px" },
      title: <div className="out-500-16 text-primary-600">Month View</div>,
      placement: "leftBottom",
      arrow: false,

      target: () =>
        document.querySelector("#schedule-tour-roadmap") as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Year View"}
            description={
              "Plan your projects more effectively using Roadmap. Click and drag the Phases to change the duration. Add and edit sprints using the Phase progress bar."
            }
            module="ScheduleTour"
            total={8}
            img="/images/v2/schedule/tour-3.svg"
          />
        );
      },
      style: { width: "403px", left: "100px", top: "auto", bottom: "10px" },
      title: <div className="out-500-16 text-primary-600">Month View</div>,
      placement: "leftBottom",
      arrow: false,

      target: () =>
        document.querySelector("#schedule-tour-roadmap") as HTMLElement,
    },

    {
      style: { width: "403px" },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Backlog"}
            description={
              "Create new Labels for organizing your tasks better. Planner also lets you create Release versions."
            }
            module="ScheduleTour"
            total={8}
            img="/images/v2/schedule/tour-3.svg"
          />
        );
      },

      title: <div className="out-500-16 text-primary-600">Planner</div>,
      placement: "bottomLeft",
      target: () =>
        document.querySelector(
          "#schedule-tour-planner-new-button"
        ) as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Sprints"}
            description={
              "Manage all sprints created from the planner, and hold daily meetings (called scrums) to stay on track."
            }
            module="ScheduleTour"
            total={8}
            img="/images/v2/schedule/tour-3.svg"
          />
        );
      },

      title: <div className="out-500-16 text-primary-600">Sprints</div>,

      arrow: false,
      style: {
        width: "403px",
        left: "auto",
        top: "auto",
        right: "24px",
        bottom: "24px",
      },
      placement: "bottomRight",
      target: () =>
        document.querySelector("#schedule-tour-sprints") as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Reports"}
            description={
              "View reports for the time logged and number of tasks within a range of dates."
            }
            module="ScheduleTour"
            total={8}
            img="/images/v2/schedule/tour-3.svg"
          />
        );
      },

      title: <div className="out-500-16 text-primary-600">Reports</div>,
      style: { width: "403px", left: "100px", top: "auto", bottom: "10px" },
      placement: "leftBottom",
      arrow: false,
      target: () =>
        document.querySelector(
          "#schedule-tour-reports-timelogged"
        ) as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Release Notes"}
            initialProjectId={initialProjectId}
            description={
              "Release important changes and improvements made in a update, allowing effective communicate with developers and stakeholders about the latest release."
            }
            module="ScheduleTour"
            total={8}
            img="/images/v2/schedule/tour-3.svg"
          />
        );
      },

      title: <div className="out-500-16 text-primary-600">Release Notes</div>,
      style: { width: "403px", left: "100px", top: "auto", bottom: "10px" },
      placement: "leftBottom",
      arrow: false,

      target: () =>
        document.querySelector("#schedule-tour-release-notes") as HTMLElement,
    },
  ];

  const dispatch = useAppDispatch();

  // if (localStorage.getItem("LibraryTour") === "1") {
  //   return <></>;
  // }

  if (!run && showTakeTourModal && toursPopup?.scheduleTour !== true) {
    return (
      <TakeTourModal
        onOk={() => {
          navigate(`/schedule/${projectId}/timeline`);
          dispatch(addDummyDataForScheduleScreen(scheduleDummyData));
          localStorage.setItem("appTour", "1");
          setRun(true);
        }}
        module="Schedule"
        onClose={async () => {
          setRun(false);
          localStorage.setItem("ScheduleTour", "1");

          await dispatch(
            updateTourStatus({
              tourStatus: "scheduleTourStatus",
              userId: user.id,
            })
          );

          localStorage.removeItem("appTour");
          setShowTakeTourModal(false);

          window.location.reload();
        }}
      />
    );
  }

  return (
    <div>
      <Tour
        open={run}
        onClose={() => setRun(false)}
        steps={steps}
        current={currentTourStep}
        mask={{
          style: {
            // boxShadow: "inset 0 0 15px #333",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",

            // WebkitBackdropFilter: "blur(px)",
            // backdropFilter: "blur(3.5px)",
            // filter: "blur(8px)",
          },
          color: "rgba(0, 0, 0, 0.4)",
        }}
        onChange={(current) => {
          dispatch(updateSchedule({ key: "currentTourStep", value: current }));
          if (current === 1) {
            dispatch(updateSchedule({ key: "roadMapZoom", value: 160 }));
            dispatch(
              updateSchedule({ key: "roadMapView", value: "ganttView" })
            );
          }
          if (current === 2) {
            dispatch(updateSchedule({ key: "roadMapZoom", value: 40 }));
            dispatch(
              updateSchedule({ key: "roadMapView", value: "ganttView" })
            );
          }
          if (current === 3) {
            dispatch(updateSchedule({ key: "roadMapZoom", value: 40 }));
            dispatch(
              updateSchedule({ key: "roadMapView", value: "calendarView" })
            );
          }
          if (current === 4) {
            navigate("/schedule/344/backlog");
          }
          if (current === 5) {
            navigate("/schedule/344/sprints/1");
          }
          if (current === 6) {
            navigate("/schedule/344/reports?report=ReportByTimeLog");
          }
          if (current === 7) {
            navigate("/schedule/344/reports/release-notes");
          }
        }}
      />
    </div>
  );
};

export default ScheduleAppTour;
