import React, { useState, useEffect } from "react";
import TimeTable, { MonthTimetable, QuarterViewTimeTable } from "./TimeTable";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import CalendarView from "./CalendarView";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { updateSchedule } from "../../../../Core/redux/slices/schedule.slice";
import { labelColors } from "../../../../Core/constants";

export default function GanttChart() {
  const {
    roadMapZoom,
    roadMapView,
    phases,
    roadMapPhaseId,
    sprints,
    labels,
    goalsByLabelId,
    selectedPhases,
    timelineSelected,
  } = useAppSelector((state) => state.schedule);
  const { projectDetails } = useAppSelector((state) => state.overview);
  const { projectId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [selPhases, setSelPhases] = useState([]);

  useEffect(() => setSelPhases([...selectedPhases]), [selectedPhases]);

  const [timeRange, setTimeRange] = useState({
    fromSelectMonth: projectDetails.startDate
      ? +dayjs(projectDetails.startDate).format("MM") - 1
      : 0,
    fromSelectYear: projectDetails.startDate
      ? +dayjs(projectDetails.startDate).format("YYYY")
      : "2023",
    toSelectMonth: projectDetails.endDate
      ? +dayjs(projectDetails.endDate).format("MM") - 1
      : 0,
    toSelectYear: projectDetails.endDate
      ? +dayjs(projectDetails.endDate).format("YYYY")
      : "2023",
    fromDate: projectDetails.startDate
      ? +dayjs(projectDetails.startDate)
      : dayjs("01/01/2023"),
    toDate: projectDetails.endDate
      ? +dayjs(projectDetails.endDate)
      : dayjs("01/01/2024"),
  });

  // useEffect(() => {
  //   const mapTask = (item) => ({
  //     id: item.id,
  //     name: item.phases,
  //     projectPhaseId: item.projectPhaseId,
  //     phase: item,
  //     startDate: item.startDate
  //       ? dayjs(item.startDate).format("YYYY-MM-DD")
  //       : "",
  //     endDate: item.endDate ? dayjs(item.endDate).format("YYYY-MM-DD") : "",
  //   });

  //   const allSprints = sprints.map((sprint) => ({
  //     id: sprint.id,
  //     sprintName: sprint.name,
  //     startDate: sprint.startDate
  //       ? dayjs(sprint.startDate).format("YYYY-MM-DD")
  //       : "",
  //     endDate: sprint.endDate ? dayjs(sprint.endDate).format("YYYY-MM-DD") : "",
  //     type: "sprint",
  //     sprint,
  //   }));

  //   const newPhases = phases.map(mapTask);
  //   const phaseIndex = newPhases.findIndex(
  //     (p) => +p.projectPhaseId === +roadMapPhaseId
  //   );

  //   const tasks =
  //     phaseIndex !== -1
  //       ? [
  //           ...newPhases.slice(0, phaseIndex + 1),
  //           ...allSprints,
  //           ...newPhases.slice(phaseIndex + 1),
  //         ]
  //       : newPhases;

  //   setTasks(tasks);
  // }, [roadMapPhaseId, phases, sprints]);
  useEffect(() => {
    const mapTask = (item) => {
      const labelColor = labelColors.find(
        (color) => color.id === +item.labelColorId
      );
      const baseColor = labelColor ? labelColor.textColor : "#7DC7FF";
      return {
        id: item.id,
        name: item.name,
        color: baseColor,
        colorId: item.labelColorId,
        label: item,
        startDate: item.startDate
          ? dayjs(item.startDate).format("YYYY-MM-DD")
          : "",
        endDate: item.endDate ? dayjs(item.endDate).format("YYYY-MM-DD") : "",
      };
    };

    const newLabels = labels
      .filter((label) => label.projectId === projectId)
      .map(mapTask);

    const labelGoalArray = [];
    newLabels.map((label) => {
      if (Object.keys(goalsByLabelId)?.length === 0) {
        labelGoalArray.push(label);
      }
      if (goalsByLabelId[+label.id]?.length > 0) {
        labelGoalArray.push(label);
        goalsByLabelId[+label.id].map((goal) => labelGoalArray.push(goal));
      } else {
        labelGoalArray.push(label);
      }
    });

    // if (Object.keys(goalsByLabelId)?.length !== 0) {
    //   console.log(goalsByLabelId, "labels goals ");
    // }

    const tasks =
      Object.keys(goalsByLabelId)?.length === 0
        ? [...newLabels]
        : [...labelGoalArray];

    setTasks(tasks);
  }, [labels, goalsByLabelId, projectId]);
  useEffect(() => {
    const selector = `[data-today="true"]`;
    const today = document.querySelector(selector) as HTMLElement;
    if (today) {
      today.scrollIntoView({
        inline: "start",
        block: "end",
        behavior: "smooth",
      });
      const scrollContainer = today.parentElement; // Assuming the parent element is the scrollable container
      if (scrollContainer) {
        scrollContainer.scrollTo({
          left: today.offsetLeft,
          behavior: "smooth", // Optional: Smooth scrolling animation
        });
      }
    }
  }, [timelineSelected]);

  const dispatch = useAppDispatch();
  const updateZoomValue = (timelineSelected) => {
    switch (timelineSelected) {
      case 0:
        dispatch(updateSchedule({ key: "roadMapZoom", value: 40 }));
        break;
      case 1:
        dispatch(updateSchedule({ key: "roadMapZoom", value: 160 }));
        break;
      case 2:
        dispatch(updateSchedule({ key: "roadMapZoom", value: 160 }));
        break;
    }
  };

  useEffect(
    () => updateZoomValue(timelineSelected),
    [roadMapZoom, timelineSelected]
  );
  const getTimelineView = () => {
    switch (timelineSelected) {
      case 0:
        return (
          <TimeTable
            timeRange={timeRange}
            tasks={tasks}
            setTasks={setTasks}
            zoom={roadMapZoom}
            phases={selPhases}
            setPhases={setSelPhases}
          />
        );
      case 1:
        return (
          <MonthTimetable
            timeRange={timeRange}
            tasks={tasks}
            setTasks={setTasks}
            zoom={roadMapZoom}
            phases={selPhases}
            setPhases={setSelPhases}
          />
        );
      case 2:
        return (
          <QuarterViewTimeTable
            timeRange={timeRange}
            tasks={tasks}
            setTasks={setTasks}
            zoom={roadMapZoom}
            phases={selPhases}
            setPhases={setSelPhases}
          />
        );
    }
  };

  return (
    <>
      {timelineSelected !== 3 ? (
        <div id="gantt-container">
          <div id="gantt-grid-container">{getTimelineView()}</div>

          <style>{`
        #gantt-container {
          --color-text: #272a2e;
          --color-primary-dark: #0195e4;
          --color-primary-light: #9ddcff;
          --color-secondary: #4be35a;
          --color-tertiary: #f7f7f7;
          --color-orange: #ef5350;
          --color-outline: #e9eaeb;
          --border-radius: 5px;
          --cell-height: 50px; 
          --cell-height-sprint: 35px; 
        }
      `}</style>
        </div>
      ) : (
        <CalendarView timeRange={timeRange} tasks={tasks} />
      )}
    </>
  );
}
