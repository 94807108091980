import React, { useEffect, useState } from "react";

import { UrgencyFillIcon } from "../../Core/svgV2/Urgency";
import { useAppSelector } from "../../Core/redux/hooks";
import RequestDetailsModal from "./Modals/RequestDetailsModal";

// type RequestProps = {
//   hasRequest?: boolean;
//   RequestList?: Record<any, any>[];
// };

const RequestCard = ({ type, urgency, onClick, hasUpdate }) => {
  const getColor = () => {
    switch (urgency) {
      case "Critical":
        return "text-error-600";
      case "Major":
        return "text-warning-600";
      case "Medium":
        return "text-primary-600";
      case "Minor":
        return "text-gray-600";
      default:
        return "text-gray-600";
    }
  };
  return (
    <div
      onClick={onClick}
      className="flex relative cursor-pointer flex-row items-center justify-center  px-[8px] gap-x-[8px] w-[115px] h-[30px] rounded-[4px] border border-gray-200"
    >
      <UrgencyFillIcon className={getColor()} />
      <div className={`out-500-14 ${getColor()} text-center relative `}>
        {type}
      </div>
      {hasUpdate && (
        <div className="inline-block absolute top-[-4px] right-[-4px] h-2 w-2 rounded-full bg-error-600" />
      )}
    </div>
  );
};

const RequestHeader = ({ socket }) => {
  const { currentThreadMessage, currentThread, directMessageUsers } =
    useAppSelector((state) => state.conversations);

  const [requests, setRequests] = useState([]);

  const [requestModal, setRequestModal] = useState({
    visible: false,
    data: {},
  });

  const getRequests = () => {
    let Requests = [];

    if (
      currentThread &&
      currentThread?.threadId &&
      currentThreadMessage?.length > 0
    ) {
      Requests = currentThreadMessage.filter(
        (message) =>
          message.request &&
          message.request?.type &&
          message.request?.status !== "Closed"
      );
    }

    if (currentThread.userId) {
      const user = directMessageUsers.find(
        (user) => user.userId === currentThread.userId
      );

      if (user && user.messages?.length > 0) {
        Requests = user.messages.filter(
          (message) =>
            message.request &&
            message.request?.type &&
            message.request?.status !== "Closed"
        );
      }
    }

    return Requests;
  };

  useEffect(() => {
    const Requests = getRequests();

    setRequests(Requests);
  }, [currentThreadMessage, directMessageUsers]);

  if (requests.length == 0) {
    return <></>;
  }

  return (
    <header style={{ position: "sticky", top: 0, zIndex: 1, width: "100%" }}>
      <div className="flex justify-between h-[46px] bg-white border-b border-gray-200 px-6 items-center">
        <div className="flex items-center gap-x-[12px] h-[30px] ">
          {/* <CustomAvatar title={"Ganesh Mohanty"} size={24} badge /> */}
          {/* <div className="out-500-14 text-black ">Alex Baker</div> */}
          <img src="/images/v2/conversations/AttachmentHeader.svg " />
          {/* <RequestCard /> */}
          {requests.map((message) => {
            return (
              <RequestCard
                type={message.request.type}
                urgency={message.request.urgency}
                key={message._id}
                hasUpdate={message.request.updateCount > 0}
                onClick={() =>
                  setRequestModal({ visible: true, data: message })
                }
              />
            );
          })}
        </div>
      </div>
      <RequestDetailsModal
        socket={socket}
        visible={requestModal.visible}
        data={requestModal.data}
        onClose={() => setRequestModal({ data: {}, visible: false })}
      />
    </header>
  );
};
export default RequestHeader;
