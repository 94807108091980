import React from "react";
import { compeleteResetProjectOnboarding } from "../../../Core/redux/slices/projectOnboarding.slice";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../Core/redux/hooks";

const CurrentStep = ({ currentStep, totalStep, className, id = "" }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div className={`${className} flex justify-between items-center w-full`}>
      <p className="out-500-14 text-gray-900">
        Step {currentStep} /<span className="out-500-14 text-gray-500"> 5</span>
      </p>
      <div
        onClick={() => {
          dispatch(compeleteResetProjectOnboarding({}));
          navigate("/dashboard");
        }}
        className="flex items-center cursor-pointer"
      >
        <img src="/images/icons/x-close.svg" className="w-5 h-5" />
      </div>
    </div>
  );
};

export default CurrentStep;
