export const extensions = {
    "application/pdf": "pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    "application/vnd.ms-excel": "xls",
    "text/csv":"csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/vnd.ms-powerpoint": "ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
    "application/x-zip-compressed": "zip",
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/gif": "gif",
    'image/svg+xml':"svg",
    "text/plain": "txt",
    "text/html": "html",
    "application/octet-stream": "bin",
    "application/rtf": "rtf",

    "video/mp4": "mp4",
    "video/quicktime": "mov",
    "video/x-msvideo": "avi",
    "video/x-flv": "flv",
    "video/x-ms-wmv": "wmv",
    "video/x-matroska": "mkv",
    "video/webm": "webm",
  };