import React from "react";

import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import getUsersName from "../../../Core/utils/getUserName";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { convertArrayToCsv } from "../../../Core/utils/exportToCsv";
import { useGetTodoList } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";

// TODO : add the export function in handleExport
const ExportModal = ({
  visible,
  onClose,
  isBacklog = false,
  todoListData = [],
}) => {
  const dispatch = useAppDispatch();
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { todoList, goals, selectedTasks } = useAppSelector(
    (state) => state.schedule
  );

  const handleExport = () => {
    function formatKeys(obj) {
      const formattedObj = {};
      for (const key in obj) {
        switch (key) {
          case "id":
            formattedObj["ID"] = obj[key];
            break;
          case "name":
            formattedObj["NAME"] = obj[key];
            break;
          case "statusId":
            formattedObj["STATUS"] =
              obj[key] == 1 ? "TO DO" : obj[key] == 2 ? "IN PROGRESS" : "DONE";
            break;
          case "status":
            formattedObj["STATUS"] =
              obj[key] == 1 ? "TO DO" : obj[key] == 2 ? "IN PROGRESS" : "DONE";
            break;
          case "priorityId":
            formattedObj["PRIORITY"] =
              obj[key] == 1 ? "LOW" : obj[key] == 2 ? "MEDIUM" : "HIGH";
            break;

          case "goalType":
            formattedObj["GOAL TYPE"] =
              obj[key] == 1
                ? "TASK"
                : obj[key] == 2
                ? "EVENT"
                : obj[key] == 3
                ? "BUG"
                : "SUBTASK";
            break;
          case "parentGoalId":
            formattedObj["PARENT GOAL ID"] = obj[key];
            break;
          // case "sprintId":
          //   formattedObj["SPRINT ID"] = obj[key];
          //   break;
          // case "assigneeId":
          //   formattedObj["ASSIGNEE ID"] = obj[key];
          //   formattedObj["ASSIGNEE NAME"] = getUsersName(
          //     tenantMembers.find((user) => user.userId == obj[key])
          //   );
          //   break;
          // case "reporterId":
          //   formattedObj["REPORTER ID"] = obj[key];
          //   formattedObj["REPORTER NAME"] = getUsersName(
          //     tenantMembers.find((user) => user.userId == obj[key])
          //   );
          //   break;
          case "estimatedTime":
            formattedObj["ESTIMATED TIME"] = obj[key];
            break;
          case "timeLogged":
            formattedObj["TIME LOGGED"] = obj[key];
            break;

          case "createdAt":
            formattedObj["CREATED AT"] = obj[key]
              ? dayjs(obj[key]).format("DD MMM YYYY")
              : "-";
            break;
          case "startDate":
            formattedObj["START DATE"] = obj[key]
              ? dayjs(obj[key]).format("DD MMM YYYY")
              : "-";
            break;
          case "endDate":
            formattedObj["END DATE"] = obj[key]
              ? dayjs(obj[key]).format("DD MMM YYYY")
              : "-";
            break;
          case "goals":
            formattedObj["TOALT GOALS COUNT"] = obj[key].length;
            break;
          case "createdById":
            formattedObj["CREATED BY"] = getUsersName(
              tenantMembers.find((user) => user.userId == obj[key])
            );
            break;
          default:
            break;
        }
      }
      return formattedObj;
    }

    const newGoals = isBacklog
      ? todoListData.filter((goal) => selectedTasks.includes(goal.id))
      : goals.filter((goal) => selectedTasks.includes(goal.id));
    newGoals.forEach((goal) => {
      if (goal.subtasks && goal.subtasks.length > 0) {
        goal.subtasks.map((subtask) => newGoals.push(subtask));
      }
    });
    const data = [...newGoals];

    convertArrayToCsv(data.map(formatKeys));
    onClose();
    dispatch(
      updateMyBoard({
        key: "selectedTasks",
        value: [],
      })
    );
    dispatch(
      updateSchedule({
        key: "selectedTasks",
        value: [],
      })
    );
  };

  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-primary-600 out-500-14">Export as CSV?</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 text-gray-500 out-300-14">
            Are you sure you want to export the selected
            <br /> tasks as a .csv file?
          </p>
          <div className="flex items-center justify-end mt-4 gap-x-3">
            <CustomButton
              text="Cancel"
              className="text-gray-500 bg-transparent"
              height="30px"
              width="63px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Export"
              height="30px"
              width="61px"
              className="text-white bg-primary-600"
              onClick={handleExport}
            />
          </div>
        </div>
      }
    />
  );
};

export default ExportModal;
