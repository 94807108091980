import React from "react";
import { GridViewIcon } from "../../../Core/svgV2/GridViewIcon";
import { ListViewIcon } from "../../../Core/svgV2/ListViewIcon";
import { Divider } from "antd";

const ViewToggleButton = ({ viewType, setViewType }) => {
  return (
    <div
      id="library-tour-toggle"
      className="flex items-center justify-center h-[38px] rounded-lg p-1 bg-gray-50"
    >
      <div
        className={`flex justify-center items-center w-[30px] h-[30px] rounded-lg cursor-pointer ${
          viewType === 1 ? "bg-white" : "bg-transparent"
        }`}
        style={{
          boxShadow:
            viewType === 1
              ? "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
              : "",
        }}
        onClick={() => {
          setViewType(1);
        }}
      >
        <GridViewIcon
          className={` ${
            viewType === 1 ? "text-primary-600" : "text-gray-500"
          }`}
        />
      </div>

      <Divider type="vertical" className="h-full" />

      <div
        className={`flex justify-center items-center w-[30px] h-[30px] rounded-lg cursor-pointer ${
          viewType === 2 ? "bg-white" : "bg-transparent"
        }`}
        style={{
          boxShadow:
            viewType === 2
              ? "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
              : "",
        }}
        onClick={() => {
          setViewType(2);
        }}
      >
        <ListViewIcon
          className={` ${
            viewType === 2 ? "text-primary-600" : "text-gray-500"
          }`}
        />
      </div>
    </div>
  );
};

export default ViewToggleButton;
