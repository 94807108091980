import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../api/notificationAPI";
import { getTenantDetails, getTenantMembers } from "../api/tenantAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  tenantMembers: [],
  activeUsers: [],
  currentTenantId: "",
  tenantDetails: {
    tenantId: "",
    tenantHostname: "",
    tenantName: "",
    createdAt: "",
    updatedAt: "",
    sdgs: "",
    sectorsList: "",
    tenantLocation: "",
    tenantSize: "",
  },
};

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    changeProject: (state, action) => {
      //state.selectedProject = action.payload;
    },
    changeTenant: (state, action) => {
      state.currentTenantId = action.payload;
    },
    resetToInitialTenant: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    setActiveUsers: (state, action) => {
      state.activeUsers = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getTenantMembers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTenantMembers.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.tenantMembers = action.payload.result;
    });
    builder.addCase(getTenantMembers.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
    builder.addCase(getTenantDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTenantDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.tenantDetails = action.payload.result;
    });
    builder.addCase(getTenantDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const {
  changeProject,
  changeTenant,
  resetToInitialTenant,
  setActiveUsers,
} = tenantSlice.actions;
export default tenantSlice.reducer;
