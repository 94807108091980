import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { DatePicker, Divider, TimePicker } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "../../../Core/redux/hooks";
import dayjs from "dayjs";
import { proposeNewTime } from "../../../Core/redux/api/scheduleAPI";

const NewTimeModal = ({ visible, onClose }) => {
  const { selectedEvent } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();

  const [proposedStartTime, setProposedStartTime] = useState(dayjs());
  const [proposedStartDate, setProposedStartDate] = useState(dayjs());
  const [proposedEndTime, setProposedEndTime] = useState(dayjs());
  const [proposedEndDate, setProposedEndDate] = useState(dayjs());
  const [optionalNotes, setOptionalNotes] = useState("");

  const handleSendProposal = () => {
    // Early return if any required fields are missing
    if (
      !proposedStartDate ||
      !proposedStartTime ||
      !proposedEndDate ||
      !proposedEndTime
    ) {
      alert("Input fields empty");
      return;
    }

    const startTimeWithDate = proposedStartTime.set(
      "date",
      proposedStartDate.date()
    );
    const endTimeWithDate = proposedEndTime.set("date", proposedEndDate.date());

    // Convert to UTC
    const startTimeUTC = dayjs.utc(startTimeWithDate).toISOString();
    const endTimeUTC = dayjs.utc(endTimeWithDate).toISOString();

    const payload = {
      name: selectedEvent.summary,
      startTime: selectedEvent.start.dateTime,
      endTime: selectedEvent.end.dateTime,
      attendees: selectedEvent.attendees,
      clickAction: "/calendar",
      date: dayjs(selectedEvent.start.dateTime).format("DDMMYYYY"),
      proposedStartTime: startTimeUTC,
      proposedEndTime: endTimeUTC,
      proposedEventId: selectedEvent.id,
      optionalNotes,
    };

    dispatch(proposeNewTime(payload));
    onClose();
  };

  const handleClose = () => {
    setProposedStartTime(null);
    setProposedStartDate(null);
    setProposedEndTime(null);
    setProposedEndDate(null);
    setOptionalNotes("");
    onClose();
  };

  return (
    <CustomModal
      visible={visible}
      width="480px"
      onCancel={handleClose}
      body={
        <div className="h-[532px] flex flex-col">
          <div className="flex justify-between items-center p-4">
            <div className="out-500-14 text-gray-900">Propose A New Time?</div>
            <div className="h-5 w-5" role="button" onClick={handleClose}>
              <CrossIcon className={"text-gray-500 cursor-pointer"} />
            </div>
          </div>
          <Divider className="m-0" />
          <div className="flex flex-col gap-4 p-4">
            <div className="text-zinc-800 out-500-14">
              {selectedEvent.summary}
            </div>
            <div className="p-2 bg-slate-100">
              <p className="text-slate-700 out-500-14">Current event time</p>
              <div className="flex gap-2 mt-0.5 text-gray-500 out-300-14">
                <p>
                  {dayjs(selectedEvent.start.dateTime).format("dddd, DD MMMM")}{" "}
                  {dayjs(selectedEvent.start.dateTime).format("h:mm a")} -{" "}
                  {dayjs(selectedEvent.end.dateTime).format("h:mm a")}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-slate-700 out-500-14">Your proposal</p>
              <Divider className="m-0" />
            </div>
            <div>
              <p className="text-slate-700 out-500-14">Starts</p>
              <div className="flex gap-2 items-center">
                <DatePicker
                  onChange={(date, dateString) => {
                    setProposedStartDate(date);
                  }}
                  className="w-[238px] border border-slate-200 rounded text-grey-900 out-300-14"
                  format="dddd, DD MMMM"
                  suffixIcon={null}
                  value={proposedStartDate}
                />
                <div className="flex items-center">
                  <TimePicker
                    value={proposedStartTime}
                    onChange={(time) => setProposedStartTime(time)}
                    format="h:mm a"
                    className="border border-slate-200 rounded text-grey-900 out-300-14"
                    suffixIcon={
                      <ClockCircleOutlined className="text-gray-500 mr-1" />
                    }
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="text-slate-700 out-500-14">Ends</p>
              <div className="flex gap-2 items-center">
                <DatePicker
                  onChange={(date, dateString) => {
                    setProposedEndDate(date);
                  }}
                  className="w-[238px] border border-slate-200 rounded text-grey-900 out-300-14"
                  format="dddd, DD MMMM"
                  suffixIcon={null}
                  value={proposedEndDate}
                />
                <div className="flex items-center">
                  <TimePicker
                    value={proposedEndTime}
                    onChange={(time) => setProposedEndTime(time)}
                    format="h:mm a"
                    className="border border-slate-200 rounded text-grey-900 out-300-14"
                    suffixIcon={
                      <ClockCircleOutlined className="text-gray-500 mr-1" />
                    }
                  />
                </div>
              </div>
            </div>
            <textarea
              value={optionalNotes}
              onChange={(e) => setOptionalNotes(e.target.value)}
              className="border border-slate-200 rounded p-2 text-grey-900 out-300-14"
              placeholder="Add optional notes"
              rows={3}
            />
            <Divider className="m-0" />
            <div className="flex justify-end gap-3">
              <button className="text-gray-500 out-500-14 py-[5px] px-[10px] flex justify-between items-center rounded-[4px]">
                <div
                  className="flex cursor-pointer"
                  role="button"
                  onClick={handleClose}
                >
                  Cancel
                </div>
              </button>
              <button
                className="bg-primary-600 text-white out-500-14 py-[5px] px-[10px] flex justify-between items-center rounded-[4px]"
                onClick={handleSendProposal}
              >
                <div className="flex cursor-pointer" role="button">
                  Send Proposal
                </div>
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default NewTimeModal;
