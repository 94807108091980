// import React from "react";

import React, { useState } from "react";
import { Avatar, Dropdown, Tooltip } from "antd";
import { SearchElongatedIcon } from "../../Core/svgV2/SearchIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import { CrossIcon } from "../../Core/svgV2/CrossIcon";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { GroupUserIcon } from "../../Core/svgV2/UserIcon";
import { PinnedOutlinedIcon } from "../../Core/svgV2/Pinned";
import { BellOutlinedIcon } from "../../Core/svgV2/BellIcon";
import { ShareFileIcon } from "../../Core/svgV2/ShareFile";
import { LeaveGroupIcon } from "../../Core/svgV2/LeaveGroupIcon";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import {
  NewRequestIcon,
  PastRequestIcon,
} from "../../Core/svgV2/PastRequestIcon";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  updateConversations,
  updateConversationsMultiple,
  updateThreadForUser,
} from "../../Core/redux/slices/conversations.slice";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import { client } from "../../Core/utils/axiosClient";
import {
  getPrivateMessages,
  getThreadMessages,
} from "../../Core/redux/api/conversationAPI";
import PastRequestModal from "./Modals/PastRequestModal";
import { EditPencilIcon } from "../../Core/svgV2/EditIcon";

type ChatProps = {
  isGroup?: boolean;
  memberList?: Record<any, any>[];
};
const ChatHeader = ({ isGroup, memberList }: ChatProps) => {
  const dispatch = useAppDispatch();
  const [showPastRequestModal, setShowPastRequestModal] = useState(false);
  const { user } = useAppSelector((state) => state.userDetails);
  const { currentThread, threadMembers } = useAppSelector(
    (state) => state.conversations
  );
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const isThread = currentThread.type === "group";
  const isAdmin = isThread && currentThread?.createdById == user.id.toString();

  const handleToggle = () => {
    if (isSearchOpen) {
      setIsSearchOpen(false);
    } else {
      setIsSearchOpen(true);
    }
  };

  const handleEditThread = () => {
    dispatch(
      updateConversationsMultiple([
        { key: "editThread", value: true },
        { key: "newGroupModal", value: true },
        { key: "editThreadDetails", value: currentThread },
      ])
    );
  };

  const handlePinChat = async () => {
    dispatch(
      updateThreadForUser({
        threadId: currentThread.threadId,
        userId: currentThread.userId,
        isPinned: +!currentThread.isPinned,
        isThread: currentThread.threadId,
      })
    );

    if (currentThread.threadId) {
      await client.put("/conversation/update-thread-for-user", {
        threadId: currentThread.threadId,
        userId: user.id,
        isPinned: +!currentThread.isPinned,
      });
    } else {
      await client.put("/conversation/private-chat", {
        receiverId: currentThread.userId,
        senderId: user.id,
        isPinned: +!currentThread.isPinned,
      });
    }
  };

  const handleMuteChat = async () => {
    dispatch(
      updateThreadForUser({
        threadId: currentThread.threadId,
        userId: currentThread.userId,
        isMute: +!currentThread.isMute,
        isThread: currentThread.threadId,
      })
    );
    if (currentThread.threadId) {
      await client.put("/conversation/update-thread-for-user", {
        threadId: currentThread.threadId,
        userId: user.id,
        isMute: +!currentThread.isMute,
      });
    } else {
      await client.put("/conversation/private-chat", {
        receiverId: currentThread.userId,
        senderId: user.id,
        isMute: +!currentThread.isMute,
      });
    }
  };

  const handleDeleteThread = async () => {
    dispatch(
      updateConversationsMultiple([
        { key: "deleteThread", value: true },
        { key: "deleteThreadDetails", value: currentThread },
      ])
    );
  };

  const dmItems = [
    {
      key: "1",
      label: (
        <button
          onClick={() =>
            dispatch(
              updateConversations({
                key: "userDetailsDrawer",
                value: { visible: true, data: currentThread },
              })
            )
          }
          className="out-300-14 text-gray-700"
        >
          View details{" "}
        </button>
      ),
      icon: <EllipseIcon className="text-gray-700" />,
    },
    // {
    //   key: "2",
    //   label: <div className="out-300-14 text-gray-700">Show past requests</div>,
    //   icon: <PastRequestIcon className="text-gray-700" />,
    //   groupMenu: true
    // },
    {
      key: "3",
      label: (
        <div
          onClick={() =>
            dispatch(
              updateConversations({ key: "sharedFilesDrawer", value: true })
            )
          }
          className="out-300-14 text-gray-700"
        >
          Shared files
        </div>
      ),
      icon: <ShareFileIcon className="text-gray-700" />,
    },
    // {
    //   key: "4",
    //   label: <div className="out-300-14 text-gray-700">Add members</div>,
    //   icon: <GroupUserIcon className="text-gray-700" />,
    // },
    {
      key: "5",
      label: (
        <div onClick={handlePinChat} className="out-300-14 text-gray-700">
          {currentThread.isPinned == 1 ? "Unpin chat" : "Pin chat"}
        </div>
      ),
      icon: <PinnedOutlinedIcon className="text-gray-700" />,
    },
    {
      key: "6",
      label: (
        <div onClick={handleMuteChat} className="out-300-14 text-gray-700">
          {currentThread.isMute == 1
            ? "Unmute notifications"
            : "Mute notifications"}
        </div>
      ),
      icon: <BellOutlinedIcon className="text-gray-700" />,
    },
    // {
    //   key: "3",
    //   label: <div className="out-300-14 text-gray-700">Mute notifications</div>,
    //   icon: <UserIcon className="text-gray-700" />,
    // },
    // { key: "7", type: "divider" },
    //removed remove from list option from dm items -- as discussed with kumail
    // {
    //   key: "8",
    //   label: (
    //     <div className="out-300-14 text-gray-700  ">Remove from list </div>
    //   ),
    //   icon: <LeaveGroupIcon className="text-gray-700 " />,
    //   danger: true,
    // },
    // {
    //   key: "9",
    //   label: <div className="out-300-14 text-gray-700  ">Leave group</div>,
    //   icon: <DeleteIcon className="text-gray-700 " />,
    // },
  ];
  const groupItems = [
    {
      key: "1",
      label: (
        <div
          onClick={() =>
            dispatch(
              updateConversations({
                key: "groupDetailsDrawer",
                value: { visible: true, data: currentThread },
              })
            )
          }
          className="out-300-14 text-gray-700"
        >
          View details{" "}
        </div>
      ),
      icon: <EllipseIcon className="text-gray-700" />,
    },
    {
      key: "newRequest",
      label: (
        <div
          role="button"
          onClick={() =>
            dispatch(updateConversations({ key: "requestOption", value: true }))
          }
          className="out-300-14 text-gray-700"
        >
          Make a new request
        </div>
      ),
      icon: <NewRequestIcon />,
    },
    {
      key: "pastRequest",
      label: (
        <div
          onClick={() => setShowPastRequestModal(true)}
          className="out-300-14 text-gray-700"
        >
          Show past requests
        </div>
      ),
      icon: <PastRequestIcon className="text-gray-700" />,
    },
    {
      key: "3",
      label: (
        <div
          onClick={() =>
            dispatch(
              updateConversations({ key: "sharedFilesDrawer", value: true })
            )
          }
          className="out-300-14 text-gray-700"
        >
          Shared files
        </div>
      ),
      icon: <ShareFileIcon className="text-gray-700" />,
    },
    {
      key: "4",
      label: (
        <div onClick={handleEditThread} className="out-300-14 text-gray-700">
          Edit group
        </div>
      ),
      icon: <EditPencilIcon className="text-gray-700" />,
    },
    {
      key: "5",
      label: (
        <div onClick={handlePinChat} className="out-300-14 text-gray-700">
          {currentThread.isPinned == 1 ? "Unpin chat" : "Pin chat"}
        </div>
      ),
      icon: <PinnedOutlinedIcon className="text-gray-700" />,
    },
    {
      key: "6",
      label: (
        <div onClick={handleMuteChat} className="out-300-14 text-gray-700">
          {currentThread.isMute == 1
            ? "Unmute notifications"
            : "Mute notifications"}
        </div>
      ),
      icon: <BellOutlinedIcon className="text-gray-700" />,
    },
    // {
    //   key: "3",
    //   label: <div className="out-300-14 text-gray-700">Mute notifications</div>,
    //   icon: <UserIcon className="text-gray-700" />,
    // },
    { key: "7", type: "divider" },
    // {
    //   key: "8",
    //   label: <div className="out-300-14 text-gray-700  ">Remove from list</div>,
    //   icon: <LeaveGroupIcon className="text-gray-700 " />,
    // },
    {
      key: "9",
      label: (
        <div
          onClick={() => {
            dispatch(
              updateConversationsMultiple([
                { key: "leaveThread", value: true },
                { key: "leaveThreadDetails", value: currentThread },
              ])
            );
          }}
          className="out-300-14 text-gray-700  "
        >
          Leave group
        </div>
      ),
      icon: <LeaveGroupIcon className="text-gray-700 " />,
      danger: true,
    },
    isThread &&
      isAdmin && {
        key: "10",
        label: (
          <div
            className="flex gap-x-2 hover:text-error-600 text-gray-700"
            role="button"
            onClick={handleDeleteThread}
          >
            <DeleteIcon className=" " />
            <div className="out-300-14   ">Delete group</div>
          </div>
        ),
        danger: true,
      },
  ];

  const handelSearch = async (e) => {
    const search = e;
    //if (e.length < 3) search = "";
    if (currentThread.type === "group") {
      await dispatch(
        getThreadMessages({
          threadId: currentThread.threadId,
          limit: 100,
          offset: 0,
          search: search,
        })
      );
    } else {
      await dispatch(
        getPrivateMessages({
          userId: user.id,
          reciverId: +currentThread.userId,
          limit: 100,
          offset: 0,
          search: search,
        })
      );
    }
  };

  return (
    <header style={{ position: "sticky", top: 0, zIndex: 1, width: "100%" }}>
      <div className="flex justify-between h-[46px] bg-white border-b border-gray-200 px-4 items-center">
        <div className="flex items-center gap-x-[8px] h-[24px] ">
          <div className="relative">
            <CustomAvatar
              title={
                isThread
                  ? currentThread.threadName
                  : `${currentThread.firstname} ${currentThread.lastname}`
              }
              size={24}
              src={
                isThread
                  ? currentThread.threadProfilePicture
                  : currentThread.profilePicture
              }
              whiteText
              userId={currentThread.userId}
              badge
              color={
                isThread
                  ? currentThread.threadProfilePictureColorCode
                  : currentThread.profilePictureColorCode
              }
            />
            {isThread && (
              <div className="absolute bottom-0 right-0">
                {" "}
                <img
                  className="h-2.5 w-2.5"
                  src="/images/v2/conversations/group-placeholder.svg"
                />
              </div>
            )}
          </div>
          <div className="out-500-14 text-black ">
            {isThread
              ? currentThread.threadName
              : `${currentThread.firstname} ${currentThread.lastname}`}
          </div>
        </div>
        <div className="flex items-center gap-x-[24px]">
          {isThread && !isSearchOpen && (
            <Avatar.Group
              maxCount={4}
              maxPopoverTrigger="click"
              size={24}
              maxStyle={{
                color: "#165ABF",
                backgroundColor: "#EFF8FF",
                cursor: "pointer",
              }}
            >
              {threadMembers.map((member) => {
                return (
                  <Tooltip
                    key={member.title}
                    title={`${member.firstname} ${member.lastname}`}
                    placement="bottom"
                  >
                    {/* <Avatar
                      style={{ backgroundColor: "#87d068" }}
                      icon={<CustomAvatar title={member.title} />}
                    /> */}
                    <div>
                      {" "}
                      <CustomAvatar
                        title={`${member.firstname} ${member.lastname}`}
                        src={member.profilePicture}
                        size={24}
                        whiteText
                        fontSize={"10px"}
                        color={member.profilePictureColorCode}
                      />
                    </div>
                    {/* <Avatar  /> */}
                  </Tooltip>
                );
              })}
            </Avatar.Group>
          )}
          {isSearchOpen && (
            <div>
              <CustomSearchBox
                // isCancel={true}
                suffix={
                  <button onClick={() => setIsSearchOpen(false)}>
                    <CrossIcon />
                  </button>
                }
                setSearch={(val) => handelSearch(val)}
                placeholder="Search in messages"
                className="w-[238px] h-[30px]"
              />
            </div>
          )}
          <div className="cursor-pointer">
            <button
              className={`${isSearchOpen ? "hidden" : "block"}`}
              onClick={() => handleToggle()}
            >
              <SearchElongatedIcon className="text-black" />
            </button>
          </div>
          <div className="cursor-pointer">
            <Dropdown
              menu={{ items: isThread ? groupItems : dmItems }}
              trigger={["click"]}
              dropdownRender={(menu) => <div className="w-[200px]">{menu}</div>}
            >
              <DotsVerticalIcon className="text-black" />
            </Dropdown>
          </div>
        </div>
      </div>
      {showPastRequestModal && (
        <PastRequestModal
          visible={showPastRequestModal}
          onClose={() => setShowPastRequestModal(false)}
        />
      )}
    </header>
  );
};

export default ChatHeader;
