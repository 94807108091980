import React from "react";
import Icon from "@ant-design/icons";

const ArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.416 12.833l2.917-2.916L13.417 7"
    ></path>
    <path stroke="#165ABF" strokeLinecap="round" d="M3 9.916h9.5"></path>
  </svg>
);

const ArrowIcon = (props) => {
  return <Icon component={ArrowSvg} {...props} />;
};

export { ArrowIcon };
