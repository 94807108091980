import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  deleteScheduleTasks,
  getGoalsBySprint,
  getTodoList,
  updateGoal,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import { useNavigate, useParams } from "react-router-dom";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useQueryClient } from "react-query";
import { useGetProjectList } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import { deleteMyBoardTasks } from "../../../Core/redux/api/myBoardAPI";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  TASK_ACTIVITY_LOG_ACTIONS,
} from "../../../Core/constants";
import { client } from "../../../Core/utils/axiosClient";

const SubtasksInProgress = ({ visible }) => {
  const dispatch = useAppDispatch();
  const { projectId, sprintId } = useParams();
  const { goals, subtasksInProgressWarning, sprintKanbanView, todoList } =
    useAppSelector((state) => state.schedule);

  const [statusIndicator, setStatusIndicator] = useState(null);
  const tasks = sprintId ? goals : todoList;
  useEffect(() => {
    if (
      Array.isArray(subtasksInProgressWarning?.goalId) &&
      subtasksInProgressWarning?.goalId.length > 0
    ) {
      subtasksInProgressWarning?.goalId.forEach((goalId) => {
        const selectedGoal = tasks.find((goal) => +goal.id === +goalId);

        if (selectedGoal) {
          const hasStatus1 = selectedGoal.subtasks.some(
            (subtask) => subtask.statusId === 1
          );
          const hasStatus2 = selectedGoal.subtasks.some(
            (subtask) => subtask.statusId === 2
          );

          if (hasStatus1 && hasStatus2) {
            setStatusIndicator(3); // Both status 1 and 2 are present
          } else if (hasStatus1) {
            setStatusIndicator(1); // Only status 1 is present
          } else if (hasStatus2) {
            setStatusIndicator(2); // Only status 2 is present
          }
        }
      });
    } else if (subtasksInProgressWarning?.goalId) {
      const selectedGoal = tasks.find(
        (goal) => +goal.id === +subtasksInProgressWarning?.goalId
      );
      // console.log(selectedGoal, " yo");

      if (selectedGoal) {
        const hasStatus1 = selectedGoal.subtasks.some(
          (subtask) => subtask.statusId === 1
        );
        const hasStatus2 = selectedGoal.subtasks.some(
          (subtask) => subtask.statusId === 2
        );

        if (hasStatus1 && hasStatus2) {
          setStatusIndicator(3); // Both status 1 and 2 are present
        } else if (hasStatus1) {
          setStatusIndicator(1); // Only status 1 is present
        } else if (hasStatus2) {
          setStatusIndicator(2); // Only status 2 is present
        }
      }
      console.log(statusIndicator, " yo 1");
    }
  }, [visible]);

  const onCancel = async () => {
    const oldGoals = [];
    if (
      Array.isArray(subtasksInProgressWarning?.goalId) &&
      subtasksInProgressWarning?.goalId.length &&
      subtasksInProgressWarning?.goalId.length > 0
    ) {
      subtasksInProgressWarning?.goalId.map(async (goalId) => {
        const selectedGoal = tasks.find((goal) => +goal.id === +goalId);

        oldGoals.push(selectedGoal);
      });
    } else {
      const selectedGoal = tasks.find(
        (goal) => +goal.id === +subtasksInProgressWarning?.goalId
      );

      oldGoals.push(selectedGoal);
    }
    await dispatch(
      updateGoal({
        goalId: subtasksInProgressWarning?.goalId,
        swimlaneId: subtasksInProgressWarning?.swimlaneId,
        statusId: subtasksInProgressWarning?.statusId,
        action: {
          oldValue: subtasksInProgressWarning?.oldSwimlaneId,
          newValue: subtasksInProgressWarning?.swimlaneId,
          actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
        },
      })
    );
    if (
      subtasksInProgressWarning?.goalId &&
      subtasksInProgressWarning?.goalId.length &&
      subtasksInProgressWarning?.goalId.length > 0 &&
      oldGoals.length
    ) {
      oldGoals.map(async (goal) => {
        await client.post("/notifications/create", {
          notificationType: "33",
          goalId: goal.id,

          allUsers: [{ userId: goal.assigneeId }, { userId: goal.reporterId }],
          projectId: goal.projectId,
          meta: {
            oldStatusId: goal.statusId,
            newStatusId: subtasksInProgressWarning?.statusId,
            goalId: goal.id,
          },
        });
      });
    } else {
      await client.post("/notifications/create", {
        notificationType: "33",
        goalId: subtasksInProgressWarning?.goalId,

        allUsers: [
          { userId: oldGoals[0].assigneeId },
          { userId: oldGoals[0].reporterId },
        ],
        projectId: oldGoals[0].projectId,
        meta: {
          oldStatusId: oldGoals[0].statusId,
          newStatusId: subtasksInProgressWarning?.statusId,
          goalId: subtasksInProgressWarning?.goalId,
        },
      });
    }

    // if (subtasksInProgressWarning?.sprintId) {
    //   dispatch(
    //     getGoalsBySprint({
    //       projectId,
    //       sprintId: subtasksInProgressWarning?.sprintId,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     getTodoList({ projectId, limit: DEFAULT_LIMIT, offset: 0 })
    //   );
    // }
    dispatch(
      updateSchedule({
        key: "subtasksInProgressWarning",
        value: {
          visible: false,
          goalId: null,
          statusId: null,
          swimlaneId: null,
          oldSwimlaneId: null,
          sprintId: null,
        },
      })
    );
    if (sprintId) {
      dispatch(getGoalsBySprint({ projectId, sprintId, module: "sprints" }));
    } else {
      dispatch(getTodoList({ projectId, limit: DEFAULT_LIMIT, offset: 0 }));
    }
  };

  const handleClick = async () => {
    const oldGoals = [];
    const taskIds = [];

    if (
      Array.isArray(subtasksInProgressWarning?.goalId) &&
      subtasksInProgressWarning?.goalId.length
    ) {
      subtasksInProgressWarning?.goalId.forEach((goalId) => {
        const selectedGoal = tasks.find((goal) => +goal.id === +goalId);
        oldGoals.push(selectedGoal);
        taskIds.push(selectedGoal.id);

        // Add incomplete subtasks to the oldGoals list
        if (selectedGoal?.subtasks?.length > 0) {
          const incompleteSubtasks = selectedGoal.subtasks
            .filter((subtask) => subtask.statusId !== 3)
            .map((subtask) => ({ ...subtask, isSubtask: true }));
          oldGoals.push(...incompleteSubtasks);
          taskIds.push(...incompleteSubtasks.map((subtask) => subtask.id));
        }
      });
    } else {
      const selectedGoal = tasks.find(
        (goal) => +goal.id === +subtasksInProgressWarning?.goalId
      );
      oldGoals.push(selectedGoal);
      taskIds.push(selectedGoal.id);

      // Add incomplete subtasks to the oldGoals list
      if (selectedGoal?.subtasks?.length > 0) {
        const incompleteSubtasks = selectedGoal.subtasks
          .filter((subtask) => subtask.statusId !== 3)
          .map((subtask) => ({ ...subtask, isSubtask: true }));
        oldGoals.push(...incompleteSubtasks);
        taskIds.push(...incompleteSubtasks.map((subtask) => subtask.id));
      }
    }
    await dispatch(
      updateGoal({
        goalId: taskIds,
        swimlaneId: subtasksInProgressWarning?.swimlaneId,
        statusId: 3,
        action: {
          oldValue: subtasksInProgressWarning?.oldSwimlaneId,
          newValue: subtasksInProgressWarning?.swimlaneId,
          actionName: TASK_ACTIVITY_LOG_ACTIONS.STATUS_CHANGED,
        },
      })
    );

    if (oldGoals.length) {
      for (const goal of oldGoals) {
        if (!goal.isSubtask) {
          await client.post("/notifications/create", {
            notificationType: "33",
            goalId: goal.id,
            allUsers: [
              { userId: goal.assigneeId },
              { userId: goal.reporterId },
            ],
            projectId: goal.projectId,
            meta: {
              oldStatusId: goal.statusId,
              newStatusId: subtasksInProgressWarning?.statusId,
              goalId: goal.id,
            },
          });
        }
      }
    } else {
      await client.post("/notifications/create", {
        notificationType: "33",
        goalId: subtasksInProgressWarning?.goalId,
        allUsers: [
          { userId: oldGoals[0].assigneeId },
          { userId: oldGoals[0].reporterId },
        ],
        projectId: oldGoals[0].projectId,
        meta: {
          oldStatusId: oldGoals[0].statusId,
          newStatusId: subtasksInProgressWarning?.statusId,
          goalId: subtasksInProgressWarning?.goalId,
        },
      });
    }
    if (sprintId) {
      dispatch(getGoalsBySprint({ projectId, sprintId, module: "sprints" }));
    } else {
      dispatch(getTodoList({ projectId, limit: DEFAULT_LIMIT, offset: 0 }));
    }
    // Dispatch schedule update
    dispatch(
      updateSchedule({
        key: "subtasksInProgressWarning",
        value: {
          visible: false,
          goalId: null,
          statusId: null,
          swimlaneId: null,
          oldSwimlaneId: null,
          sprintId: null,
        },
      })
    );
  };

  const onClose = () => {
    dispatch(
      updateSchedule({
        key: "subtasksInProgressWarning",
        value: {
          visible: false,
          goalId: null,
          statusId: null,
          swimlaneId: null,
          oldSwimlaneId: null,
          sprintId: null,
        },
      })
    );
  };

  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={onClose}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Move Tasks to done</p>
            <button
              onClick={() => {
                dispatch(
                  updateSchedule({
                    key: "subtasksInProgressWarning",
                    value: {
                      visible: false,
                      goalId: null,
                      statusId: null,
                      swimlaneId: null,
                      oldSwimlaneId: null,
                      sprintId: null,
                    },
                  })
                );
              }}
            >
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            The selected tasks include subtasks that are
            {statusIndicator === 1 && " currently in To Do. "}
            {statusIndicator === 2 && " currently in Progress. "}
            {statusIndicator === 3 && " currently in To Do & in Progress. "}
            Are you sure you want to mark the subtasks as done too?
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-between">
            <CustomButton
              text="No, don’t move"
              className="bg-transparent text-gray-500 "
              height="30px"
              onClick={onCancel}
            />
            <CustomButton
              text="Yes, go ahead"
              height="30px"
              className="bg-error-700 text-white"
              onClick={handleClick}
            />
          </div>
        </div>
      }
    />
  );
};

export default SubtasksInProgress;
