import React from "react";
import { useAppSelector } from "../../../../Core/redux/hooks";
import CustomAvatar from "../../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../../Core/utils/getUserName";
import PriorityFlag from "../../../../Core/svgV2/PriorityFlag";
import CustomBadge from "../../../../Core/CommonV2/CustomBadge";

const Overlay = ({ task, count }) => {
  const { userList } = useAppSelector((state) => state.overview);

  const assignee = userList.find((user) => +user.userId === +task.assigneeId);
  return (
    <div className="flex items-center gap-x-3 bg-white px-3 py-2  border rounded relative border-gray-200 ">
      <div className="h-5 w-5 rounded-full flex items-center justify-center out-500-14 text-gray-700 bg-primary-300">
        {count}
      </div>
      <img className="h-5 w-5 " src="/images/v2/schedule/Checkbox.svg" />
      <p
        className="out-300-12 text-black flex-1"
        style={{
          fontWeight: 500,
        }}
      >
        {task.name}
      </p>
      {task.label && (
        <CustomBadge
          text="Onboarding flow"
          color="#DD2590"
          className="border border-gray-200"
        />
      )}
      <p className="out-300-12 text-gray-500">PI - {task.id}</p>
      <PriorityFlag priorityId={task.priorityId} />
      {assignee && (
        <div>
          <CustomAvatar
            title={getUsersName(assignee)}
            src={assignee?.profilePicture}
            size={20}
            fontSize={"12px"}
            whiteText
            color={assignee?.profilePictureColorCode}
          />
        </div>
      )}
    </div>
  );
};

export default Overlay;
