import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { resetConversations } from "../../../Core/redux/slices/conversations.slice";

import FileFolderListAndSelect from "../../../Core/CommonV2/FileFolderListAndSelect";
import { createFile } from "../../../Core/redux/api/vaultAPI";
import {
  getPrivateMessages,
  getThreadMessages,
  saveInVault,
} from "../../../Core/redux/api/conversationAPI";

// const FolderCard = () => {
//   return (
//     <div className="flex items-center py-[10px] pl-[17px] ">
//       <PhaseIcon phaseId={1} width="10px" height="10px" />
//       <img
//         className="h-4 w-4 ml-2.5 mr-2"
//         src="/images/v2/conversations/folder-fill-vault.svg"
//       />
//       <p className="out-300-14 text-gray-900">My Library</p>
//     </div>
//   );
// };

const SaveInLibrary = ({ visible, onClose }) => {
  const [moveToFolderId, setMoveToFolderId] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { saveInLibrary, currentThread } = useAppSelector(
    (state) => state.conversations
  );
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    const payload = {
      files: [saveInLibrary.file],
      projectId: selectedProjectId,
      folderId: null,
      tenantId: tenantDetails?.tenantId,
      phaseId: null,
      goalId: null,
      users: [],
    };

    if (moveToFolderId) {
      payload.folderId = moveToFolderId;
    }

    await dispatch(createFile(payload));
    console.log("here?");
    dispatch(resetConversations(["saveInLibrary"]));

    await dispatch(saveInVault(saveInLibrary.file));
    if (currentThread.type === "group") {
      dispatch(
        getThreadMessages({
          threadId: currentThread.threadId,
          limit: 20,
          offset: 0,
        })
      );
    } else {
      dispatch(
        getPrivateMessages({
          userId: user.id,
          reciverId: +currentThread.userId,
          limit: 20,
          offset: 0,
        })
      );
    }
  };
  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => console.log("")}
      body={
        <div className="p-4" id="conversations-tour-save-in-library">
          <p className="text-black out-500-14 pb-4">Save in</p>

          <FileFolderListAndSelect
            onFolderSelect={(selectedFolderId) =>
              setMoveToFolderId(selectedFolderId)
            }
            onProjectSelect={(selectedProjectId) =>
              setSelectedProjectId(selectedProjectId)
            }
          />
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={onClose}
            />
            <CustomButton
              text="Save here"
              height="30px"
              width="82px"
              disabled={!moveToFolderId && !selectedProjectId}
              onClick={() => onSubmit()}
              // className="bg-error-700 text-white"
            />
          </div>
        </div>
      }
    />
  );
};

export default SaveInLibrary;
