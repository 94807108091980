import React from "react";
import Icon from "@ant-design/icons";

const CrossSvg = ({ width = 20, height = 20 }) => {
  const scaleFactor = Math.min(width, height) / 20; // Calculate scale factor

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.67 * scaleFactor} // Scale strokeWidth
        d={`M${14.167 * scaleFactor} ${5.834 * scaleFactor}l${
          -8.334 * scaleFactor
        } ${8.333 * scaleFactor}m0 ${-8.333 * scaleFactor}l${
          8.334 * scaleFactor
        } ${8.333 * scaleFactor}`}
      ></path>
    </svg>
  );
};

const CrossLargeSvg = ({ width = 20, height = 20 }) => {
  const scaleFactor = Math.min(width, height) / 20; // Calculate scale factor

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d={`M${15 * scaleFactor} ${5 * scaleFactor}L${5 * scaleFactor} ${
          15 * scaleFactor
        }M${5 * scaleFactor} ${5 * scaleFactor}l${10 * scaleFactor} ${
          10 * scaleFactor
        }`}
      ></path>
    </svg>
  );
};

const CrossIconSecondary = ({
  width = 20,
  height = 20,
  className = "",
  style = {},
}) => (
  <span role="img" className={`anticon ${className}`} style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d={`M15 5L5 15M5 5L15 15`}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

const CrossIcon = ({ width = 20, height = 20, ...props }) => {
  return (
    <Icon
      component={() => <CrossSvg width={width} height={height} />}
      {...props}
    />
  );
};

const CrossIconLarge = ({ width = 20, height = 20, ...props }) => {
  return (
    <Icon
      component={() => <CrossLargeSvg width={width} height={height} />}
      {...props}
    />
  );
};

export { CrossIcon, CrossIconLarge, CrossIconSecondary };
