import { Tour } from "antd";
//import Tour from "@rc-component/tour";

import React, { useEffect, useState } from "react";

import type { TourProps } from "antd";
import { ArrowIcon } from "../../Core/svgV2/Arrow";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  addDummyDataForMessagesScreen,
  resetToInitialConversation,
} from "../../Core/redux/slices/conversations.slice";

import {
  updateDashboard,
  addDummyDataForDashboardScreen,
  resetToInitialDashBoard,
} from "../../Core/redux/slices/dashboard.slice";

import TakeDashboardTourModal from "../../Core/CommonV2/TakeDashboardTourModal";

import {
  conversationsDummyData,
  dashboardDummyData,
  overViewDummyData,
  projectDummyData,
  scheduleDummyData,
} from "../../Core/dummyData";
import {
  addDummyDataForProject,
  resetToInitialProjects,
} from "../../Core/redux/slices/projects.slice";
import {
  addDummyDataForOverviewScreen,
  resetToInitialOverview,
} from "../../Core/redux/slices/overview.slice";
import {
  addDummyDataForScheduleScreen,
  resetToInitialSchedule,
} from "../../Core/redux/slices/schedule.slice";

import { useNavigate } from "react-router-dom";

import { updateTourStatus } from "../../Core/redux/api/dashboardAPI";

const TourPanel = ({
  step,
  current,
  total,
  img,
  title,
  description,
  actionButton = "",
  onActionClick = () => {
    console.log("");
  },
}) => {
  console.log(current, "sdvsfvsfvsfvsffvsfv");
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  return (
    <div className="bg-white p-3 rounded-lg w-[403px] relative overflow-hidden">
      <div className="out-500-16 text-primary-600">{title}</div>
      <div className="flex gap-x-[23px] items-center">
        <div className="out-300-14 text-gray-500 w-[246px] mt-2 leading-5">
          {description}
        </div>
        <div>
          <img src={img} />
        </div>
      </div>
      <div className="mt-3 out-500-14 text-gray-700 leading-5">
        {current + 1}/{total}
      </div>
      <div className="mt-3">
        <div className="flex items-center gap-x-6">
          {actionButton ? (
            <>
              <button onClick={onActionClick}>
                <div className="flex items-center gap-x-2">
                  <span className="text-primary-700 out-500-14">
                    {actionButton || ""}
                  </span>{" "}
                </div>
              </button>

              <button
                onClick={async () => {
                  dispatch(
                    updateDashboard({
                      key: "takeTourFromWelcomeCard",
                      value: false,
                    })
                  );

                  localStorage.setItem("DashboardTour", "1");
                  await dispatch(
                    updateTourStatus({
                      tourStatus: "dashboardTourStatus",
                      userId: user.id,
                    })
                  );

                  localStorage.removeItem("appTour");
                  step.onNext();
                  dispatch(resetToInitialDashBoard({}));
                  dispatch(resetToInitialProjects({}));
                  dispatch(resetToInitialOverview({}));
                  dispatch(resetToInitialConversation({}));
                  dispatch(resetToInitialSchedule({}));

                  window.location.reload();
                }}
              >
                <div className="flex items-center gap-x-2">
                  <span className="text-gray-500 out-500-14">
                    {" "}
                    {" I'll do it later"}
                  </span>{" "}
                </div>
              </button>
            </>
          ) : (
            <button
              onClick={async () => {
                console.log(step, "sdcsd");
                if (current + 1 === total) {
                  localStorage.setItem("DashboardTour", "1");
                  await dispatch(
                    updateTourStatus({
                      tourStatus: "dashboardTourStatus",
                      userId: user.id,
                    })
                  );
                  localStorage.removeItem("appTour");
                  step.onNext();
                  dispatch(resetToInitialDashBoard({}));
                  dispatch(resetToInitialProjects({}));
                  dispatch(resetToInitialOverview({}));
                  dispatch(resetToInitialConversation({}));
                  dispatch(resetToInitialSchedule({}));

                  window.location.reload();
                }
                step.onNext();
              }}
            >
              {" "}
              {current + 1 === total ? (
                <div className="flex items-center gap-x-2">
                  <span className="text-primary-700 out-500-14">
                    Finish Tour
                  </span>{" "}
                </div>
              ) : (
                <div className="flex items-center gap-x-2">
                  <span className="text-primary-700 out-500-14">Next</span>{" "}
                  <ArrowIcon className="text-primary-600" />
                </div>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center absolute left-0 right-0 bottom-0 rounded-b-lg ">
        <div
          className="inline-block bg-primary-700 h-1 "
          style={{
            width: `${(403 / total) * (current + 1)}px`,
          }}
        ></div>
        <div className="inline-block flex-1 bg-gray-200 h-1 "></div>
      </div>
    </div>
  );
};

const DashboardAppTour = () => {
  const [run, setRun] = useState(false);
  const [showTakeTourModal, setShowTakeTourModal] = useState(true);
  // const [startAt, setStartAt] = useState(0);
  const navigate = useNavigate();
  const { takeTourFromWelcomeCard, toursPopup } = useAppSelector(
    (state) => state.dashboard
  );
  const { user } = useAppSelector((state) => state.userDetails);

  // const integrateCalendar = () => navigate("/");

  const setUpProfile = async () => {
    dispatch(resetToInitialDashBoard({}));
    dispatch(resetToInitialProjects({}));
    dispatch(resetToInitialOverview({}));
    dispatch(resetToInitialConversation({}));
    dispatch(resetToInitialSchedule({}));

    setRun(false);

    localStorage.setItem("DashboardTour", "1");
    await dispatch(
      updateTourStatus({
        tourStatus: "dashboardTourStatus",
        userId: user.id,
      })
    );

    localStorage.removeItem("appTour");
    setShowTakeTourModal(false);
    dispatch(updateDashboard({ key: "dashboardRedirected", value: true }));
    navigate("/manage-profile");
    window.location.reload();
  };

  useEffect(() => {
    console.log(takeTourFromWelcomeCard, "takeTour");
    if (takeTourFromWelcomeCard) {
      setRun(true);
    }
  }, [takeTourFromWelcomeCard]);

  const steps: TourProps["steps"] = [
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Dashboard"}
            description={
              "Get all the important updates about your current projects on the dashboard."
            }
            total={10}
            img="/images/v2/dashboard/tour1.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Dashboard</div>,
      placement: "right",
      target: () =>
        document.querySelector("#dashboard-tour-dashboard") as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Messages"}
            description={
              "To collaborate with everyone in your organization. Create channels or even send direct messages to your team."
            }
            total={10}
            img="/images/v2/dashboard/tour-2.svg"
          />
        );
      },
      style: { width: "403px" },
      title: <div className="out-500-16 text-primary-600">Messages</div>,
      placement: "right",

      target: () =>
        document.querySelector("#dashboard-tour-messages") as HTMLElement,
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Schedule"}
            description={`To manage your sprints, team tasks and conduct your daily scrums for each project.`}
            total={10}
            img="/images/v2/dashboard/Tour-3.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Schedule</div>,
      placement: "right",

      target: () =>
        document.querySelector("#dashboard-tour-schedule") as HTMLElement,
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Library"}
            description={`With an organized library as per your projects, you can store and share all your important files, folders and even links.`}
            total={10}
            img="/images/v2/dashboard/Tour-4.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Library</div>,
      placement: "right",

      target: () =>
        document.querySelector("#dashboard-tour-library") as HTMLElement,
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Workspace"}
            description={`Manage all your multiple workspaces from here`}
            total={10}
            img="/images/v2/dashboard/Tour-5.svg"
          />
        );
      },
      placement: "right",
      title: <div className="out-500-16 text-primary-600">Workspace</div>,

      target: () =>
        document.querySelector("#dashboard-tour-workspace") as HTMLElement,
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Notes"}
            description={`Use it to create your to-do list or jot down points from your meetings and discussions.`}
            total={10}
            img="/images/v2/dashboard/Tour-6.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Notes</div>,
      placement: "left",
      target: () =>
        document.querySelector("#dashboard-tour-notes") as HTMLElement,
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"My projects"}
            description={`Get an entire overview of all your current projects. Manage your phases, overlook your upcoming tasks, edit project info and add/remove components used in your project.`}
            total={10}
            img="/images/v2/dashboard/Tour-7.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">My projects</div>,
      placement: "right",
      target: () =>
        document.querySelector("#dashboard-tour-myProjects") as HTMLElement,
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Quick glance"}
            description={`Get updates from all your latest messages and pin your favourite chats to this space for the ease of catching up with your team, favourite colleagues or groups. `}
            total={10}
            img="/images/v2/dashboard/Tour-8.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Quick glance</div>,
      placement: "left",
      target: () => {
        dispatch(updateDashboard({ key: "messagesScreen", value: true }));
        return document.querySelector(
          "#dashboard-tour-quickGlanceMessages"
        ) as HTMLElement;
      },
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Quick glance"}
            description={`Integrate your Google Calendar and create and manage all your events without leaving Waddle.`}
            total={10}
            img="/images/v2/dashboard/Tour-9.svg"
            // actionButton="Integrate Google calendar"
            // onActionClick={integrateCalendar}
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Quick glance</div>,
      placement: "left",

      target: () => {
        dispatch(updateDashboard({ key: "messagesScreen", value: false }));
        return document.querySelector(
          "#dashboard-tour-quickGlanceEvents"
        ) as HTMLElement;
      },
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Profile"}
            description={`Set up your profile and contact info for everyone to connect with you easily`}
            actionButton="Set up my profile"
            total={10}
            img="/images/v2/dashboard/Tour-10.svg"
            onActionClick={setUpProfile}
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Profile</div>,
      placement: "left",

      target: () =>
        document.querySelector("#dashboard-tour-profile") as HTMLElement,
    },
  ];

  const dispatch = useAppDispatch();

  // if (localStorage.getItem("DashboardTour") === "1") {
  //   return <></>;
  // }

  if (
    !run &&
    showTakeTourModal &&
    toursPopup?.dashboardTour !== true
    // localStorage.getItem("DashboardTour") !== "1"
  ) {
    // dispatch(
    //   updateDashboard({
    //     key: "dashboardWelcomeTourModal",
    //     value: true,
    //   })
    // );
    return (
      <TakeDashboardTourModal
        onOk={() => {
          console.log(run, "run");
          // add dummy datas

          dispatch(addDummyDataForDashboardScreen(dashboardDummyData));
          dispatch(addDummyDataForProject(projectDummyData));
          dispatch(addDummyDataForOverviewScreen(overViewDummyData));
          dispatch(addDummyDataForMessagesScreen(conversationsDummyData));
          dispatch(addDummyDataForScheduleScreen(scheduleDummyData));
          localStorage.setItem("appTour", "1");
          setRun(true);
        }}
        module="Dashboard"
        onClose={async () => {
          setRun(false);
          console.log(run, "run");
          localStorage.setItem("DashboardTour", "1");
          await dispatch(
            updateTourStatus({
              tourStatus: "dashboardTourStatus",
              userId: user.id,
            })
          );
          localStorage.removeItem("appTour");
          setShowTakeTourModal(false);
          window.location.reload();

          // dispatch(
          //   updateDashboard({
          //     key: "dashboardWelcomeTourModal",
          //     value: false,
          //   })
          // );
        }}
      />
    );
  }

  return (
    <div>
      <Tour
        open={run}
        onClose={() => {
          setRun(false);
        }}
        steps={steps}
        mask={{
          style: {
            // boxShadow: "inset 0 0 15px #333",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",

            // WebkitBackdropFilter: "blur(px)",
            // backdropFilter: "blur(3.5px)",
            // filter: "blur(8px)",
          },
          color: "rgba(0, 0, 0, 0.4)",
        }}
        onChange={(current) => {
          console.log(current, ":sdvsfvsfvsfvsffvsfv");
          if (current <= 2) {
            dispatch(updateDashboard({ key: "viewType", value: 1 }));
          }
          if (current === 3) {
            console.log(":sdvsfvsfvsfvsffvsfvs", current);
            dispatch(updateDashboard({ key: "viewType", value: 2 }));
          }
          if (current === 9) {
            dispatch(
              updateDashboard({ key: "takeTourFromWelcomeCard", value: false })
            );
          }
        }}
      />
    </div>
  );
};

export default DashboardAppTour;
