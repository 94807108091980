import React from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { client } from "../../../Core/utils/axiosClient";
import { getLabels } from "../../../Core/redux/api/scheduleAPI";
import { useParams, useSearchParams } from "react-router-dom";

const DeleteLabelModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const { deleteLabelModal } = useAppSelector((state) => state.schedule);

  const handleDelete = async () => {
    try {
      await client.delete("/schedule/label", {
        params: { labelId: deleteLabelModal.labelId },
      });
      dispatch(
        getLabels({
          projectId: projectId ? projectId : searchParams.get("projectId"),
        })
      );
    } catch (e) {
      console.log("Some error happend while updateing the label");
    } finally {
      onClose();
    }
  };
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Delete Label</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to delete this label? This label will be
            deleted from all projects in this workspace.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Delete"
              height="30px"
              className="bg-error-700 text-white"
              onClick={handleDelete}
            />
          </div>
        </div>
      }
    />
  );
};

export default DeleteLabelModal;
