import React, { useState } from "react";
import { Dropdown, Input, Row, Col, Tooltip, Menu, Checkbox } from "antd";
import CustomAvatar from "../../../../Core/CommonV2/CustomAvatar";

const AssigneeFilter = ({
  assignees,
  onFilterChange,
  maxCount = 4,
  filteringEnabled = true,
}) => {
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [search, setSearch] = useState("");

  const handleSelectAssignee = (id) => {
    if (!filteringEnabled) return; // Do nothing if filtering is disabled

    const currentIndex = selectedAssignees.indexOf(id);
    const newSelectedAssignees = [...selectedAssignees];

    if (currentIndex === -1) {
      newSelectedAssignees.push(id);
    } else {
      newSelectedAssignees.splice(currentIndex, 1);
    }

    setSelectedAssignees(newSelectedAssignees);
    onFilterChange(newSelectedAssignees);
  };

  const displayedAssignees = assignees.slice(0, maxCount);
  const overflowAssignees = assignees.slice(maxCount);

  const overlayContent = filteringEnabled ? (
    <div className="shadow-box border min-w-[200px] max-w-[200px] rounded-lg overflow-hidden bg-white ">
      <div className="sticky top-0 z-10 bg-gray-50">
        <Input
          className="w-full rounded-lg bg-white focus:outline-none mon-500-12 h-10 "
          prefix={<img src="/images/icons/search.svg" alt="search" />}
          bordered={false}
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
          placeholder="Search User"
        />
      </div>
      <div className="px-3">
        <Row gutter={[10, 10]}>
          {overflowAssignees
            .filter((user) => user.title.toLowerCase().includes(search))
            .map((assignee) => (
              <Col span={24} key={assignee.id}>
                <Checkbox
                  value={assignee.id}
                  onChange={() => handleSelectAssignee(assignee.id)}
                  checked={selectedAssignees.includes(assignee.id)}
                >
                  {assignee.title}
                </Checkbox>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  ) : (
    // Overlay for when filtering is disabled, showing avatars and names
    <Menu>
      {overflowAssignees
        .filter((assignee) =>
          assignee.title.toLowerCase().includes(search.toLowerCase())
        )
        .map((assignee) => (
          <Menu.Item key={assignee.id}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomAvatar
                src={assignee.src}
                title={assignee.title}
                size={24}
              />
              <span style={{ marginLeft: 8 }}>{assignee.title}</span>
            </div>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div className="avatar-group">
      {displayedAssignees.map((assignee, index) => (
        <Tooltip
          title={filteringEnabled ? assignee.title : ""}
          key={assignee.id}
          placement="bottom"
        >
          <span
            className="avatar cursor-pointer"
            style={{
              zIndex: 10 - index,
              border:
                filteringEnabled && selectedAssignees.includes(assignee.id)
                  ? "2px solid #165ABF"
                  : "2px solid transparent",
            }}
            onClick={() => handleSelectAssignee(assignee.id)}
          >
            <CustomAvatar src={assignee.src} title={assignee.title} size={24} />
          </span>
        </Tooltip>
      ))}
      {overflowAssignees.length > 0 && (
        <Dropdown overlay={overlayContent} trigger={["click"]}>
          <span
            className="avatar cursor-pointer bg-primary-gray text-gray-700 text-center"
            style={{
              zIndex: 1,
            }}
          >
            +{overflowAssignees.length}
          </span>
        </Dropdown>
      )}
    </div>
  );
};

export default AssigneeFilter;
