import { Checkbox, Col, Divider, Dropdown, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import CustomSearch from "../../Core/Common/CustomSearch";

import { getActivityLogs } from "../../Core/redux/api/activityLogAPI";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { activities } from "./activities";

const CheckboxGroup = Checkbox.Group;

const ActivityLogSideBar = () => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const [checkedList, setCheckedList] = useState([]);
  const [checkedActivities, setCheckedActivities] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [search, setSearch] = useState("");

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < tenantMembers.length);
    setCheckAll(list.length === tenantMembers.length);
  };

  const onCheckAllChange = (e) => {
    const ids = tenantMembers.map((t) => t.id);
    setCheckedList(e.target.checked ? ids : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const dispatch = useAppDispatch();

  const activityLogs = async () => {
    await dispatch(
      getActivityLogs({ userId: checkedList, activityType: checkedActivities })
    );
  };

  useEffect(() => {
    activityLogs();
  }, [checkedActivities, checkedList]);

  const handleSearch = (ele) => {
    console.log(
      (ele.firstname && ele.firstname.toLowerCase().includes(search)) ||
        (ele.lastname && ele.lastname.toLowerCase().includes(search)) ||
        (ele.email && ele.email.toLowerCase().includes(search))
    );
    return (
      (ele.firstname && ele.firstname.toLowerCase().includes(search)) ||
      (ele.lastname && ele.lastname.toLowerCase().includes(search)) ||
      (ele.email && ele.email.toLowerCase().includes(search))
    );
  };

  const filterMembers = tenantMembers.filter(handleSearch);

  return (
    <div className="relative min-w-[379px] max-w-[379px] bg-white px-4 ">
      <CustomSearch
        placeholder="Search threads, people"
        className="mt-4  bg-gray-50"
        style={{ height: "44px" }}
        setSearch={setSearch}
      />
      <div className="mt-4">
        <div className="flex justify-between">
          <div className="flex justify-center items-center ">
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              <p className="mon-500-14 text-gray-700">
                {" "}
                {checkedList.length} selected{" "}
              </p>
            </Checkbox>
            <Dropdown
              overlay={
                <Menu
                  className="w-[178px] rounded-lg custom-menu-dropdown  shadow-dropdown "
                  style={{
                    border: "1px solid #EDF3F9",
                  }}
                >
                  <Menu.Item
                    className=" py-2.5 px-4 rounded-lg"
                    onClick={() =>
                      onCheckAllChange({ target: { checked: true } })
                    }
                  >
                    <span className=" mb-2 text-gray-700 whitespace-nowrap mon-400-12">
                      Select all
                    </span>
                  </Menu.Item>
                  <Menu.Item
                    className=" py-2.5 px-4 rounded-lg border-b-[1px]"
                    onClick={() =>
                      onCheckAllChange({ target: { checked: false } })
                    }
                  >
                    <span className=" mb-2 text-gray-700 whitespace-nowrap mon-400-12">
                      Clear all
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <button>
                <img
                  src="/images/icons/chevron-down-gray.svg"
                  className="w-5 h-5"
                />
              </button>
            </Dropdown>
          </div>
          <Dropdown
            overlay={
              <Menu
                className="w-[274px] max-h-[50vh] h-[50vh] overflow-y-scroll  rounded-lg custom-menu-dropdown  shadow-dropdown "
                style={{
                  border: "1px solid #EDF3F9",
                }}
              >
                <div className="py-2 px-4  ">
                  <CheckboxGroup
                    value={checkedActivities}
                    onChange={(list) => setCheckedActivities(list)}
                  >
                    <Row gutter={[20, 20]}>
                      {activities.map((activity) => (
                        <Col span={24} key={activity.id}>
                          {" "}
                          <Checkbox value={activity.id}>
                            <p className="mon-400-12 text-gray-700 ml-1 capitalize">
                              {activity.value}
                            </p>{" "}
                          </Checkbox>{" "}
                        </Col>
                      ))}
                    </Row>
                  </CheckboxGroup>
                </div>
              </Menu>
            }
          >
            <div className="flex justify-center items-center cursor-pointer">
              <img src="/images/icons/Filters-lines.svg" alt=" " />
              <p className="mon-500-14 text-gray-500 pl-2">Activities</p>
            </div>
          </Dropdown>
        </div>
        <Divider />

        <CheckboxGroup
          value={checkedList}
          onChange={onChange}
          className="max-h-[calc(100vh-256px)] overflow-y-scroll overflow-x-hidden"
        >
          <Row gutter={[20, 20]}>
            {filterMembers.map((user, i) => (
              <Col span={24} key={user.id + i}>
                {" "}
                <Checkbox value={user.id}>
                  <p className="mon-400-14 text-gray-700 ml-1 capitalize">
                    {user.firstname} {user.lastname}
                  </p>{" "}
                </Checkbox>{" "}
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
        <div className="mon-400-12 text-gray-500 mt-5 mb-2">
          Showing {filterMembers.length} out of {tenantMembers.length}
        </div>
      </div>
    </div>
  );
};

export default ActivityLogSideBar;
