import React from "react";
import ImgCrop from "antd-img-crop";
import { CrossIcon } from "../svgV2/CrossIcon";

const ImageCropModal = ({ children }) => {
  return (
    <ImgCrop
      rotationSlider
      modalClassName="image-crop-modal "
      modalOk="Crop"
      modalProps={{ closeIcon: <CrossIcon /> }}
      modalTitle={
        (<div className="out-500-16 text-black">Crop your image</div>) as any
      }
    >
      {children}
    </ImgCrop>
  );
};

export default ImageCropModal;
