import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getSprintsByPhase,
  updateSprint,
} from "../../../Core/redux/api/scheduleAPI";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";

const DeleteSprintModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();
  const { projectId } = useParams();
  const { deleteSprintModal } = useAppSelector((state) => state.schedule);
  const navigate = useNavigate();

  const handleDelete = async () => {
    const payload: {
      sprintId: string;
      isActive: boolean;
      newSprintId?: string | number;
    } = {
      sprintId: deleteSprintModal.sprintId,
      isActive: false,
    };

    // if (value === 2 && selectedSprint !== null) {
    //   payload.newSprintId = selectedSprint;
    // }

    console.log(payload, "payload sprint delete");

    await dispatch(updateSprint(payload));
    dispatch(
      getSprintsByPhase({
        projectId,
      })
    );
    queryClient.removeQueries(["sprintsByPhase", projectId]);
    dispatch(resetSchedule(["deleteSprintModal", "sprintDetailsModal"]));
    onClose();
    navigate(`/schedule/${projectId}/backlog`);
  };

  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Delete Sprint</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />

          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to delete this sprint? <br /> This action
            cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Delete"
              height="30px"
              width="101px"
              className="bg-error-700 text-white"
              onClick={handleDelete}
            />
          </div>
        </div>
      }
    />
  );
};

export default DeleteSprintModal;
