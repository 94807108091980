import { Segmented, Tooltip } from "antd";
import React, { useCallback, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import ChatCard from "../Conversations/ChatCard";

import Events from "./Events";
import EventsModal from "./Modals/EventsModal";
import {
  getPrivateChats,
  getThreads,
} from "../../Core/redux/api/conversationAPI";
import { getOrganizationUsers } from "../../Core/redux/api/projectAPI";
import QuickChat from "./QuickChat";
import { useNavigate } from "react-router-dom";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";

const QuickGlance = ({ socket }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { allowedUsersForGuest } = useAppSelector((state) => state.settings);

  const { threads, directMessageUsers, unreadCount, currentThread } =
    useAppSelector((state) => state.conversations);
  const [isChatSelected, setIsChatSelected] = useState(false);
  const navigate = useNavigate();
  const [showEventsModal, setShowEventsModal] = useState(false);
  const getDM = async () => {
    dispatch(getThreads({ userId: user.id }));
    await dispatch(getOrganizationUsers({ userId: user.id }));
    await dispatch(getPrivateChats({ userId: user.id }));
  };

  useEffect(() => {
    dispatch(getThreads({ userId: user.id }));
    getDM();
  }, [user.id]);

  const { events } = useAppSelector((state) => state.dashboard);
  let allThreads = [];
  if (+user.roleId === 4) {
    const guestDirectMeesageUsers = directMessageUsers.filter((user) =>
      allowedUsersForGuest?.includes(user.userId)
    );
    allThreads = [...threads, ...guestDirectMeesageUsers];
  } else {
    allThreads = threads
      ? [...threads, ...directMessageUsers]
      : [...directMessageUsers];
  }

  const handleSort = (threads) => {
    const sortedThreads = threads.sort((a, b) => {
      const aTime = a.lastMessageTime
        ? new Date(a.lastMessageTime).getTime()
        : 0;
      const bTime = b.lastMessageTime
        ? new Date(b.lastMessageTime).getTime()
        : 0;

      if (aTime > bTime) {
        return -1;
      } else if (aTime < bTime) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedThreads;
  };
  const sortThreads = useCallback(
    (threads) => {
      const pinnedThreads = handleSort(threads.filter((t) => t.isPinned == 1));
      const unPinnedThreads = handleSort(
        threads.filter((t) => t.isPinned != 1)
      );

      return [...pinnedThreads, ...unPinnedThreads];
    },
    [threads, directMessageUsers]
  );
  const dispatch = useAppDispatch();
  const { messagesScreen } = useAppSelector((state) => state.dashboard);
  return (
    <div
      className="h-full  w-[20%] min-w-[318px] bg-white "
      id={
        messagesScreen
          ? "dashboard-tour-quickGlanceMessages"
          : "dashboard-tour-quickGlanceEvents"
      }
    >
      <div>
        <div className="flex pt-[32px]    justify-between items-center  px-6 h-10 pb-4">
          <div className="out-500-16 text-gray-900"> Quick Glance </div>
          <button
            onClick={() => {
              if (+user.roleId == 4 && user.isGuestDeactivated) {
                setShowEventsModal(true);
              } else {
                if (messagesScreen) {
                  navigate("/messages");
                } else {
                  navigate("/my-calendar");
                }
                setShowEventsModal(true);
              }
            }}
          >
            <Tooltip
              title={
                +user.roleId == 4 && user.isGuestDeactivated
                  ? "Expand Events"
                  : messagesScreen
                  ? "Open messages"
                  : "Expand events"
              }
              placement="left"
            >
              <img className="" src="/images/v2/schedule/expand-01.svg" />
            </Tooltip>
          </button>
        </div>
      </div>
      {+user.roleId == 4 && user.isGuestDeactivated ? (
        <></>
      ) : (
        !isChatSelected && (
          <div className="px-6 pt-2 pb-4 border-b ">
            <Segmented
              options={[
                {
                  label: (
                    <div className="flex  items-center rounded-md h-[38px]  gap-x-2 justify-center   ">
                      <div
                        className={`  text-center out-500-14 ${
                          messagesScreen ? "text-black" : "text-gray-500"
                        }  `}
                      >
                        Messages
                      </div>
                      {unreadCount > 0 && (
                        <div className="flex items-center justify-center relative w-[23px] h-[22px] bg-gray-200 rounded-2xl ">
                          {unreadCount}
                        </div>
                      )}
                    </div>
                  ),
                  value: "Messages",
                },
                {
                  label: (
                    <div className="flex h-[38px]  items-center gap-x-2 justify-center rounded-md ">
                      <div
                        className={`  text-center out-500-14 ${
                          !messagesScreen ? "text-black" : "text-gray-500"
                        }  `}
                      >
                        Events
                      </div>
                      {events.length > 0 && (
                        <div className="flex items-center justify-center  w-[23px] h-[22px] bg-gray-200 rounded-2xl relative">
                          <div className="">{events.length}</div>
                        </div>
                      )}
                    </div>
                  ),
                  value: "Events",
                },
              ]}
              block={true}
              defaultValue="Messages"
              value={messagesScreen ? "Messages" : "Events"}
              className="text-center mon-500-14 h-[46px]  text-gray-700 rounded-lg p-1  "
              onChange={(value) => {
                dispatch(
                  updateDashboard({
                    key: "messagesScreen",
                    value: !messagesScreen,
                  })
                );
                console.log(value, "value");
              }}
            />
          </div>
        )
      )}

      {+user.roleId == 4 && user.isGuestDeactivated ? (
        <Events />
      ) : (
        !isChatSelected && (
          <>
            {messagesScreen ? (
              <div className="max-h-[calc(100vh-170px)] overflow-y-scroll">
                {sortThreads(allThreads).map((thread, i) => (
                  <ChatCard
                    key={i}
                    thread={thread}
                    socket={socket}
                    showMoreOptions={false}
                    onChatClickCallBack={(val) => {
                      dispatch(
                        updateConversations({
                          key: "previousChatId",
                          value: currentThread.threadId
                            ? currentThread.threadId
                            : currentThread.userId,
                        })
                      );
                      setIsChatSelected(val);
                    }}
                  />
                ))}
              </div>
            ) : (
              <Events />
            )}
          </>
        )
      )}

      {isChatSelected && (
        <QuickChat
          onBackClick={async () => {
            await dispatch(
              updateConversations({
                key: "quickGlanceChatDraftUpdate",
                value: true,
              })
            );

            // dispatch(resetConversations(["currentThread"]));

            setTimeout(() => setIsChatSelected(false), 100);
          }}
          socket={socket}
        />
      )}
      <EventsModal
        visible={showEventsModal}
        onCancel={() => setShowEventsModal(false)}
      />
    </div>
  );
};

export default QuickGlance;
