import React from "react";
import { hexToRgb } from "../utils/colorConverter";
type CustomBadgeProps = {
  color: string;
  text: string;
  dot?: boolean;
  className?: string;
  bgColor?: string;
  icon?: React.ReactNode;
};
const CustomBadge = ({
  color,
  text,
  dot = false,
  className,
  bgColor,
  icon,
}: CustomBadgeProps) => {
  const backgroundColor = hexToRgb(color, 0.1);
  return (
    <div
      className={`flex justify-center items-center h-[22px] px-2  max-w-max rounded-2xl bg-[${bgColor}] ${className}`}
      style={!bgColor ? { backgroundColor } : null}
    >
      {dot && (
        <div
          style={{
            height: "6px",
            width: "6px",
            backgroundColor: color,
            borderRadius: "50%",
          }}
        />
      )}
      <p
        className="mon-500-12 mt-[0px] max-w-[10vw] truncate"
        style={{ color, marginLeft: dot ? "7px" : "0px" }}
      >
        {text}
      </p>
      {icon && <div className="ml-1">{icon}</div>}
    </div>
  );
};

export default CustomBadge;
