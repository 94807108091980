import React from "react";
import CustomLayout from "../../../Core/LayoutV2/CustomLayout";
import SubtasksIneProgress from "./Modals/SubtasksIneProgress";
import { useAppSelector } from "../../../Core/redux/hooks";

/* 
1.for creating a new task = /my-board/task/new
2. for editing/viewing a task = /my-board/task/123
3. for creating a subtask =  /my-board/task/123/subtask/new
4. for editing a subtask =  /my-board/task/:taskId/subtask/subtaskId
*/
const MyBoardLayout = ({ children }) => {
  const { subtasksInProgressWarning } = useAppSelector(
    (state) => state.myBoard
  );
  return (
    <CustomLayout
      page="my-board"
      title={
        <div
          className="flex gap-x-1.5 h-full 
"
        ></div>
      }
    >
      <div className="bg-slate-100 h-full">
        {/* add my board tour component */}
        {children}
      </div>
      <SubtasksIneProgress visible={subtasksInProgressWarning?.visible} />
    </CustomLayout>
  );
};

export default MyBoardLayout;
