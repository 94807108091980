import React from "react";
import Icon from "@ant-design/icons";

const ReportsIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M1.667 18.333h16.666"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.125 3.334v15h3.75v-15c0-.917-.375-1.667-1.5-1.667h-.75c-1.125 0-1.5.75-1.5 1.667zM2.5 8.334v10h3.333v-10c0-.917-.333-1.667-1.333-1.667h-.667c-1 0-1.333.75-1.333 1.667zM14.167 12.5v5.833H17.5V12.5c0-.917-.333-1.667-1.333-1.667H15.5c-1 0-1.333.75-1.333 1.667z"
    ></path>
  </svg>
);

const ReportsIcon = (props) => <Icon component={ReportsIconSvg} {...props} />;

export { ReportsIcon };
