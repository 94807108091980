import axios from "axios";
import Cookies from "js-cookie";
import {
  setFulfilled,
  setRejected,
  setRequest,
} from "../redux/slices/global.slice";
import { updateUserData, updateUserToken } from "../redux/slices/user.slice";
import localforage from "localforage";

const commonHeader: {
  "Content-Type": string;
  "Content-Disposition": string;
  Accept: string;
  "Access-Control-Allow-Origin": string;
  "Access-Control-Allow-Credentials": boolean;
  Authorization?: string;
} = {
  "Content-Type": "application/json",
  "Content-Disposition": "",
  Accept: "*/*",
  "Access-Control-Allow-Origin": window.location.origin,
  "Access-Control-Allow-Credentials": true,
};

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
const clientV3 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V3,
});

const chatClient = axios.create({
  baseURL: process.env.REACT_APP_SOCKET_URL,
});

const handleLogOutUser = (dispatch) => {
  Cookies.remove("jwt");
  Cookies.remove("refreshToken");
  localStorage.removeItem("persist:root");
  localStorage.removeItem("user");
  localforage.clear();

  if (window.location.pathname !== "/") {
    dispatch(
      updateUserData([
        { key: "loggedIn", value: false },
        { key: "user", value: {} },
      ])
    );
    window.location.href = "/";
  }
};

client.defaults.withCredentials = true;
client.defaults.xsrfCookieName = "jwt";
client.defaults.xsrfHeaderName = "jwt";

clientV3.defaults.withCredentials = true;
clientV3.defaults.xsrfCookieName = "jwt";
clientV3.defaults.xsrfHeaderName = "jwt";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
}

let isRefreshTokenInProgress = false;
const requestClinetQueue = [];

async function refreshAccessTokenInResponse(error, store, isClient = true) {
  const { dispatch } = store;
  const originalConfig = error.config;
  const user = JSON.parse(localStorage.getItem("user"));

  if (
    user &&
    user.refreshToken &&
    error.response.status === 401 &&
    !originalConfig._retry &&
    !isRefreshTokenInProgress
  ) {
    originalConfig._retry = true;
    isRefreshTokenInProgress = true;
    console.log("cdscsdjkjbks");
    try {
      // make a request to the refresh token endpoint to get a new access token
      const refreshResponse = await client.post("/auth/refresh-token", {
        refreshToken: user.refreshToken,
      });

      // if we receive a new access token, save it in the cookie and local storage

      const { token } = refreshResponse.data;

      localStorage.setItem("user", JSON.stringify({ ...user, token }));
      Cookies.set("jwt", token);
      // re-send the original request with the new access token
      dispatch(updateUserToken(token));
      error.config.headers.Authorization = `Bearer ${token}`;
      if (isClient) return await client(error.config);

      return chatClient(error.config);
    } catch (err) {
      handleLogOutUser(dispatch);
    } finally {
      isRefreshTokenInProgress = false;
    }
  } else {
    handleLogOutUser(dispatch);
  }
}

function isTokenExpired(token) {
  if (!token) return true;
  const decodedToken = JSON.parse(window.atob(token.split(".")[1]));
  const currentTime = Math.floor(Date.now() / 1000);
  return decodedToken.exp < currentTime;
}

const getNewRefreshTokenInRequest = async (store) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { dispatch } = store;

  try {
    // make a request to the refresh token endpoint to get a new access token

    const refreshResponse = await axios.post(
      process.env.REACT_APP_API_URL + "/auth/refresh-token",
      {
        refreshToken: user.refreshToken,
      }
    );

    const { token } = refreshResponse.data;
    dispatch(updateUserToken(token));
    localStorage.setItem("user", JSON.stringify({ ...user, token }));
    Cookies.set("jwt", token);
    return token;
  } catch (e) {
    handleLogOutUser(dispatch);
  }
};

const setupAxiosInterceptors = (store) => {
  // we can get the access of store here by which we can dispatch action's like clearing the user state
  const { dispatch } = store;

  client.interceptors.request.use(
    async (config) => {
      const user = JSON.parse(localStorage.getItem("user"));
      const appTourInProgress = localStorage.getItem("appTour");

      if (appTourInProgress === "1") {
        throw new axios.Cancel("Request stopped due to app tour");
      }
      //const keys = JSON.parse(value)
      const token = getCookie("jwt"); // helper function to get the JWT token from the cookie

      if (token) {
        commonHeader.Authorization = `Bearer ${token}`;
      } else {
        if (user !== null && user.token) {
          commonHeader.Authorization = `Bearer ${user.token}`;
        }
      }
      if (user?.token) {
        const tokenExpired = isTokenExpired(user?.token);
        if (tokenExpired) {
          const newToken = await getNewRefreshTokenInRequest(store);
          commonHeader.Authorization = `Bearer ${newToken}`;
        }
      }

      dispatch(setRequest({ endPoint: config.url }));
      config.headers = commonHeader;

      return config;
    },
    (error) => {
      if (error.response) {
        error = getError(error);
      }
      dispatch(setRejected({ error, from: "request" }));

      Promise.reject(error);
    }
  );
  clientV3.interceptors.request.use(
    async (config) => {
      const user = JSON.parse(localStorage.getItem("user"));
      const appTourInProgress = localStorage.getItem("appTour");

      if (appTourInProgress === "1") {
        throw new axios.Cancel("Request stopped due to app tour");
      }
      //const keys = JSON.parse(value)
      const token = getCookie("jwt"); // helper function to get the JWT token from the cookie

      if (token) {
        commonHeader.Authorization = `Bearer ${token}`;
      } else {
        if (user !== null && user.token) {
          console.log(token, "token  Found2");
          commonHeader.Authorization = `Bearer ${user.token}`;
        }
      }
      if (user?.token) {
        const tokenExpired = isTokenExpired(user?.token);
        console.log(tokenExpired, "tokenExp");
        if (tokenExpired) {
          const newToken = await getNewRefreshTokenInRequest(store);
          commonHeader.Authorization = `Bearer ${newToken}`;
        }
      }

      dispatch(setRequest({ endPoint: config.url }));
      config.headers = commonHeader;

      return config;
    },
    (error) => {
      if (error.response) {
        error = getError(error);
      }
      dispatch(setRejected({ error, from: "request" }));

      Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (res) => {
      dispatch(setFulfilled({}));

      return res;
    },
    async (error) => {
      let newErr;
      if (error.response) {
        newErr = getError(error);
      }

      const { status } = error.response;
      if (status === 401) {
        const authErr =
          newErr.message == "Incorrect email or password." ? newErr : {};
        dispatch(setRejected({ error: authErr, from: "response" }));
        await refreshAccessTokenInResponse(error, store, true);
      }

      dispatch(
        setRejected({ error: newErr ? newErr : error, from: "response" })
      );

      return Promise.reject(error);
    }
  );
  clientV3.interceptors.response.use(
    (res) => {
      dispatch(setFulfilled({}));

      return res;
    },
    async (error) => {
      let newErr;
      if (error.response) {
        newErr = getError(error);
      }

      const { status } = error.response;
      if (status === 401) {
        const authErr =
          newErr.message == "Incorrect email or password." ? newErr : {};
        dispatch(setRejected({ error: authErr, from: "response" }));
        await refreshAccessTokenInResponse(error, store, true);
      }

      dispatch(
        setRejected({ error: newErr ? newErr : error, from: "response" })
      );

      return Promise.reject(error);
    }
  );

  chatClient.interceptors.request.use(
    async (config) => {
      const user = JSON.parse(localStorage.getItem("user"));

      const appTourInProgress = localStorage.getItem("appTour");

      if (appTourInProgress === "1") {
        throw new axios.Cancel("Request stopped due to app tour");
      }

      if (user !== null && user.token) {
        commonHeader.Authorization = `Bearer ${user.token}`;
      }
      if (user?.token) {
        if (isTokenExpired(user?.token)) {
          const newToken = await getNewRefreshTokenInRequest(store);
          commonHeader.Authorization = `Bearer ${newToken}`;
          //commonHeader.["x-tenant-id"] = ""
        }
      }
      config.headers = commonHeader;

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  chatClient.interceptors.response.use(
    (res) => {
      return res;
    },
    async (error) => {
      const { status } = error.response;
      if (status === 401) {
        return await refreshAccessTokenInResponse(error, store, false);
        // handleLogOutUser(dispatch)
      }
      return Promise.reject(error);
    }
  );
};

const getError = ({ response }) => {
  const { data } = response;
  if (!data.code) {
    data.code = response.status;
  }
  data.statusText = response.statusText;
  return { ...data };
};

export { client, clientV3, setupAxiosInterceptors, getError, chatClient };
