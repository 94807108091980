import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { resetVault } from "../../../Core/redux/slices/vault.slice";
import FileFolderListAndSelect from "../../../Core/CommonV2/FileFolderListAndSelect";
import {
  copyFileAPI,
  copyFolderAPI,
  copyLinkAPI,
} from "../../../Core/redux/api/vaultAPI";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

const MakeACopyModal = ({ visible, onClose }) => {
  const { copyModal, folders, files, links } = useAppSelector(
    (state) => state.vault
  );
  const dispatch = useAppDispatch();
  const [copyToFolderId, setCopyToFolderId] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedFolderDetails, setSelectedFolderDetails] = useState(null);
  let docName;

  const { projectList } = useAppSelector((state) => state.projects);

  const selectedProjectName = projectList.find(
    (project) => project.id === selectedProjectId
  )?.title;

  const handleCopyFolder = async () => {
    if (copyModal.docType === "folder") {
      const payload = {
        folderId: copyModal.id,
        projectId: selectedProjectId,
        copyToFolderId: copyToFolderId,
      };
      await dispatch(copyFolderAPI(payload));
      docName = folders.find(
        (folder) => copyModal.id === folder.id
      )?.folderName;

      dispatch(resetVault(["copyModal"]));
    }

    if (copyModal.docType === "file") {
      const payload = {
        fileId: copyModal.id,
        copyToFolderId: copyToFolderId,
        projectId: selectedProjectId,
      };

      await dispatch(copyFileAPI(payload));

      docName = files.find((file) => copyModal.id === file.id)?.name;
      dispatch(resetVault(["copyModal"]));
    }

    if (copyModal.docType === "link") {
      const payload = {
        linkId: copyModal.id,
        copyToFolderId: copyToFolderId,
        projectId: selectedProjectId,
      };
      await dispatch(copyLinkAPI(payload));
      docName = links.find((link) => copyModal.id === link.id)?.name;

      dispatch(resetVault(["copyModal"]));
    }

    dispatch(
      updateDashboard({
        key: "alertPopupModal",
        value: {
          visible: true,
          data: {
            title: docName || "New Copy",
            subtitle: "Created a new copy in  ",
            description: location.pathname.includes("my-library")
              ? "My Library"
              : selectedFolderDetails?.folderName || selectedProjectName,
          },
        },
      })
    );
  };

  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <p className="text-black out-500-14 pb-4">Copy to</p>
          <FileFolderListAndSelect
            onFolderSelect={(selectedFolderId, folder) => {
              setCopyToFolderId(selectedFolderId);
              if (folder) {
                setSelectedFolderDetails(folder);
              }
            }}
            onProjectSelect={(selectedProjectId) =>
              setSelectedProjectId(selectedProjectId)
            }
          />
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={onClose}
            />
            <CustomButton
              text="Copy Here"
              height="30px"
              width="88px"
              onClick={handleCopyFolder}
              // className="bg-error-700 text-white"
            />
          </div>
        </div>
      }
    />
  );
};

export default MakeACopyModal;
