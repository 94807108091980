import React, { useCallback, useRef } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import dayjs from "dayjs";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { Divider } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { CalendarColors } from "../../../Core/constants";

const MonthEventCell = ({
  startTime,
  endTime,
  eventId,
  title,
  eventDetails,
}) => {
  const eventRef = useRef();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);

  const getBorderStyle = useCallback(() => {
    if (eventDetails.selectedColor === "-1") {
      return "#FFDB01";
    }
    const selectedColorId = parseInt(eventDetails.selectedColor);
    const color = CalendarColors.find((color) => color.id === selectedColorId);
    return color ? color.fill : "#FFDB01";
  }, [eventDetails.selectedColor]);

  const getBackgroundColor = useCallback(() => {
    const organizerEmail = eventDetails.organizer?.email;
    const attendee = eventDetails.attendees?.find(
      (attendee) =>
        attendee.email === user.email && attendee.responseStatus === "accepted"
    );
    if (attendee || user.email === organizerEmail) {
      if (eventDetails.selectedColor === "-1") {
        return "#FFDB01";
      }
      const selectedColorId = parseInt(eventDetails.selectedColor);
      const color = CalendarColors.find(
        (color) => color.id === selectedColorId
      );
      return color ? color.fill : "#FFDB01";
    } else {
      return "#F9FBFE";
    }
  }, [
    eventDetails.selectedColor,
    user.email,
    eventDetails.organizer,
    eventDetails.attendees,
  ]);

  const handleEventClick = (e) => {
    e.stopPropagation();
    dispatch(updateDashboard({ key: "eventInfoModal", value: true }));
    dispatch(updateDashboard({ key: "selectedEvent", value: eventDetails }));
  };

  return (
    <div>
      <div
        data-event-id={eventId}
        data-start-time={startTime}
        data-end-time={endTime}
        ref={eventRef}
        role="button"
        onClick={handleEventClick}
        className={`w-full rounded border border-white px-0.5 transform transition-all duration-200 h-[21px] flex items-center`} // Added flex and items-center
        style={{
          backgroundColor: getBackgroundColor(),
          borderColor: getBorderStyle(),
        }}
      >
        <span className="w-full flex justify-between flex-nowrap gap-x-2">
          <span className="text-[10px] font-normal font-['Outfit'] text-gray-900 truncate">
            {title || "(No title)"}
          </span>
          <span className="text-[10px] font-normal font-['Outfit'] text-gray-900">
            {startTime}
          </span>
        </span>
      </div>
    </div>
  );
};

const MonthDateModal = ({ selectedDate, visible, onClose }) => {
  const { events } = useAppSelector((state) => state.dashboard);
  const formattedSelectedDate = dayjs(selectedDate);
  const isToday = dayjs().isSame(formattedSelectedDate, "day");

  const filteredEvents = events.filter((event) =>
    dayjs(event.start.dateTime).isSame(formattedSelectedDate, "day")
  );

  filteredEvents.sort((a, b) => {
    const startTimeA: dayjs.Dayjs = dayjs(a.start.dateTime);
    const startTimeB: dayjs.Dayjs = dayjs(b.start.dateTime);
    return startTimeA.diff(startTimeB);
  });

  return (
    <CustomModal
      visible={visible}
      width="428px"
      onCancel={onClose}
      body={
        <div>
          <div className="flex justify-between p-4">
            <div className="flex gap-2">
              <p>{formattedSelectedDate.format("dddd")}</p>
              <p
                className={`${
                  isToday
                    ? "flex justify-center bg-blue-500 text-white w-5 h-5 rounded-xl"
                    : ""
                }`}
              >
                {formattedSelectedDate.format("D")}
              </p>
            </div>
            <div role="button" className="h-5 w-5" onClick={onClose}>
              <CrossIcon className="text-gray-500 cursor-pointer" />
            </div>
          </div>
          <Divider className="m-0" />
          <div className="flex flex-col gap-2 overflow-y-auto max-h-[572px] p-2">
            {filteredEvents.map((event) => (
              <MonthEventCell
                key={event.id}
                eventId={event.etag}
                startTime={dayjs(event.start.dateTime).format("HH:mm")}
                endTime={dayjs(event.end.dateTime).format("HH:mm")}
                title={event.summary}
                eventDetails={event}
              />
            ))}
          </div>
        </div>
      }
    />
  );
};

export default MonthDateModal;
