import React, { useState } from "react";
import { NewFolderIcon } from "../../../Core/svgV2/NewFolderIcon";
import { FileUploadIcon, LinkUploadIcon } from "../../../Core/svgV2/UploadIcon";
import { Dropdown, MenuProps } from "antd";
import { updateVault } from "../../../Core/redux/slices/vault.slice";
import { useDispatch } from "react-redux";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { useLocation, useParams } from "react-router-dom";
import usePermission from "../../../Core/hooks/usePermission";
import { useAppSelector } from "../../../Core/redux/hooks";

const NewButton = ({ onUploadClick }) => {
  const { "*": spalt } = useParams();
  const location = useLocation();

  const { hasPermission: FOLDER_FILE_LINK_UPLOAD } = usePermission("22");

  const { currentSelectedFolder } = useAppSelector((state) => state.vault);

  let hasPermission = FOLDER_FILE_LINK_UPLOAD;

  if (spalt?.length) {
    if (location.pathname.includes("my-library")) {
      hasPermission = true;
    } else {
      hasPermission =
        currentSelectedFolder?.canEdit === 1 ||
        currentSelectedFolder?.isOwner === 1;
    }
  }

  const dispatch = useDispatch();
  const [toggleNewButton, setToggleNewButton] = useState(false);
  const groupItems = [
    {
      key: "1",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">New Folder</div>
      ),
      icon: <NewFolderIcon className="text-gray-500" />,
    },
    { key: "2", type: "divider" },

    {
      key: "3",
      label: (
        // <Upload>
        <div className="out-300-14 leading-5 text-gray-700">File Upload</div>
        // </Upload>
      ),
      icon: <FileUploadIcon className="text-gray-500" />,
    },
    {
      key: "4",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Link Upload</div>
      ),
      icon: <LinkUploadIcon className="text-gray-500" />,
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "1":
        dispatch(
          updateVault({
            key: "newFolderModal",
            value: true,
          })
        );
        break;
      case "3":
        onUploadClick();
        break;
      case "4":
        dispatch(
          updateVault({
            key: "newLinkModal",
            value: true,
          })
        );
        break;
      default:
        console.log("item others");
        break;
    }
  };
  return (
    <>
      {hasPermission && (
        <Dropdown
          onOpenChange={(e) => setToggleNewButton(e)}
          menu={{ items: groupItems, onClick }}
          trigger={["click"]}
          dropdownRender={(origin) => {
            return <div className="w-[320px]">{origin}</div>;
          }}
        >
          <div className="flex items-center justify-center gap-x-2 bg-primary-600 w-[76px] h-[30px] rounded cursor-pointer">
            <p className="out-500-14 leading-5 text-white">New</p>
            <ChevronIcon
              className={`text-white mt-0.5 ${
                toggleNewButton ? "rotate-180" : ""
              }`}
            />
          </div>
        </Dropdown>
      )}
      {/* // <div
        //   role="button"
        //   onClick={() =>
        //     dispatch(
        //       updateVault({
        //         key: "requestAccessModal",
        //         value: {
        //           visible: true,
        //           data: {
        //             type: "folder",
        //             isProject: spalt.length === 0,
        //           },
        //         },
        //       })
        //     )
        //   }
        //   className="flex items-center justify-center opacity-40 gap-x-2 bg-primary-600 w-[76px] h-[30px] rounded cursor-pointer"
        // >
        //   <p className="out-500-14 leading-5 text-white">New</p>
        //   <ChevronIcon
        //     className={`text-white mt-0.5 ${
        //       toggleNewButton ? "rotate-180" : ""
        //     }`}
        //   />
        // </div> */}
    </>
  );
};

export default NewButton;
