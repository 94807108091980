import { Tooltip } from "antd";
import React from "react";

const TaskTypeIcon = ({
  type,
  width = "16",
  height = "16",
}: {
  type: string | number;
  width?: string;
  height?: string;
}) => {
  switch (type) {
    // ideation
    case "task":
      return (
        <Tooltip title="Task">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              fill="#F9FBFE"
              stroke="#DDE5ED"
              d="M14.7 8c0 3.676-3.313 6.7-6.917 6.7C4.217 14.7 1.3 11.715 1.3 8c0-3.676 3.313-6.7 6.918-6.7 3.565 0 6.482 2.985 6.482 6.7z"
            ></path>
            <path
              stroke="#144EA6"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
              d="M4.174 8.185l2.483 2.228a.5.5 0 00.69-.02l4.532-4.582"
            ></path>
          </svg>
        </Tooltip>
      );
    case 1:
      return (
        <Tooltip title="Task">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              fill="#F9FBFE"
              stroke="#DDE5ED"
              d="M14.7 8c0 3.676-3.313 6.7-6.917 6.7C4.217 14.7 1.3 11.715 1.3 8c0-3.676 3.313-6.7 6.918-6.7 3.565 0 6.482 2.985 6.482 6.7z"
            ></path>
            <path
              stroke="#144EA6"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
              d="M4.174 8.185l2.483 2.228a.5.5 0 00.69-.02l4.532-4.582"
            ></path>
          </svg>
        </Tooltip>
      );
    case 2:
      return (
        <Tooltip title="Calendar event">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              fill="#F9FBFE"
              d="M11.667 4.333H4.333v7.334h7.334V4.333z"
            ></path>
            <path
              fill="#1E88E5"
              d="M8.56 6.973l.336.48.528-.384v2.784H10V6.205h-.48l-.96.768zM7.648 7.915c.208-.191.337-.457.337-.75 0-.582-.51-1.056-1.139-1.056-.534 0-.99.337-1.11.818l.553.14c.055-.221.289-.382.557-.382.314 0 .57.216.57.48 0 .265-.256.48-.57.48h-.332v.576h.332c.36 0 .665.25.665.547 0 .301-.289.547-.644.547-.32 0-.595-.204-.638-.473l-.562.092c.087.545.603.957 1.2.957.669 0 1.213-.504 1.213-1.123 0-.341-.168-.647-.432-.853z"
            ></path>
            <path
              fill="#FBC02D"
              d="M11.333 14H4.667l-.334-1.333.334-1.334h6.666l.334 1.334L11.333 14z"
            ></path>
            <path
              fill="#4CAF50"
              d="M12.667 11.667L14 11.333V4.667l-1.333-.334-1.334.334v6.666l1.334.334z"
            ></path>
            <path
              fill="#1E88E5"
              d="M11.333 4.667l.334-1.334L11.333 2H3a1 1 0 00-1 1v8.333l1.333.334 1.334-.334V4.667h6.666z"
            ></path>
            <path fill="#E53935" d="M11.333 11.333V14L14 11.333h-2.667z"></path>
            <path
              fill="#1565C0"
              d="M13 2h-1.667v2.667H14V3a1 1 0 00-1-1zM3 14h1.667v-2.667H2V13a1 1 0 001 1z"
            ></path>
          </svg>
        </Tooltip>
      );
    case 3:
      return (
        <Tooltip title="Bug">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
          >
            <g clipPath="url(#clip0_12662_72258)">
              <circle
                cx="8.001"
                cy="8"
                r="6.7"
                fill="#F9FBFE"
                stroke="#DDE5ED"
              ></circle>
              <path
                stroke="#292927"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.602 4l.8.8M10.402 4l-.8.8"
              ></path>
              <path
                fill="#B42318"
                fillRule="evenodd"
                d="M8 4c-.848 0-1.662.345-2.262.96a3.316 3.316 0 00-.937 2.316v1.448c0 .869.337 1.702.937 2.316.6.615 1.414.96 2.263.96.848 0 1.662-.345 2.263-.96.6-.614.937-1.447.937-2.316V7.276c0-.869-.337-1.702-.938-2.316A3.163 3.163 0 008.002 4zM6.265 5.498a2.427 2.427 0 011.737-.736c.651 0 1.276.265 1.736.736.223.229.4.497.521.788H5.744c.121-.291.297-.56.52-.788zm-.709 1.55c-.007.075-.01.152-.01.228v1.448c0 .667.259 1.306.72 1.778.46.471 1.084.736 1.736.736.651 0 1.276-.265 1.736-.736.46-.472.72-1.111.72-1.778V7.276c0-.076-.004-.153-.01-.228H5.554z"
                clipRule="evenodd"
              ></path>
              <path
                fill="#B42318"
                d="M5.555 7.048c-.007.075-.01.152-.01.228v1.448c0 .667.259 1.306.72 1.778.46.471 1.084.736 1.736.736.651 0 1.276-.265 1.736-.736.46-.472.72-1.111.72-1.778V7.276c0-.076-.004-.153-.01-.228H5.554z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_12662_72258">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </Tooltip>
      );
    case "subtask":
      return (
        <Tooltip title="Subtask">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 19 20"
          >
            <g clipPath="url(#clip0_12740_117612)">
              <circle
                cx="10"
                cy="10.5"
                r="8.5"
                fill="#F9FBFE"
                stroke="#DDE5ED"
              ></circle>
              <path
                stroke="#667085"
                strokeLinecap="round"
                d="M6.943 8.696v3.056c0 .28 0 .42.055.527a.5.5 0 00.218.219c.107.054.247.054.527.054h3.071m-3.87-3.856c.686 0 1.242-.559 1.242-1.248 0-.69-.556-1.248-1.243-1.248-.686 0-1.243.559-1.243 1.248 0 .69.557 1.248 1.243 1.248zm3.87 3.856c0 .69.557 1.248 1.243 1.248.687 0 1.243-.558 1.243-1.248 0-.689-.556-1.247-1.243-1.247-.686 0-1.243.558-1.243 1.247z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_12740_117612">
                <path
                  fill="#fff"
                  d="M0 0H19V19H0z"
                  transform="translate(0 .5)"
                ></path>
              </clipPath>
            </defs>
          </svg>
        </Tooltip>
      );
    case 4:
      return (
        <Tooltip title="Subtask">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 19 20"
          >
            <g clipPath="url(#clip0_12740_117612)">
              <circle
                cx="10"
                cy="10.5"
                r="8.5"
                fill="#F9FBFE"
                stroke="#DDE5ED"
              ></circle>
              <path
                stroke="#667085"
                strokeLinecap="round"
                d="M6.943 8.696v3.056c0 .28 0 .42.055.527a.5.5 0 00.218.219c.107.054.247.054.527.054h3.071m-3.87-3.856c.686 0 1.242-.559 1.242-1.248 0-.69-.556-1.248-1.243-1.248-.686 0-1.243.559-1.243 1.248 0 .69.557 1.248 1.243 1.248zm3.87 3.856c0 .69.557 1.248 1.243 1.248.687 0 1.243-.558 1.243-1.248 0-.689-.556-1.247-1.243-1.247-.686 0-1.243.558-1.243 1.247z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_12740_117612">
                <path
                  fill="#fff"
                  d="M0 0H19V19H0z"
                  transform="translate(0 .5)"
                ></path>
              </clipPath>
            </defs>
          </svg>
        </Tooltip>
      );

    case "newscope":
      return (
        <Tooltip title="New scope">
          <div style={{ height: +height, width: +width }}>
            <img
              src="/images/icons/plus-circle.svg"
              className="w-full h-full"
            />
          </div>
        </Tooltip>
      );

    case 5:
      return (
        <Tooltip title="New scope">
          <div style={{ height: +height, width: +width }}>
            <img
              src="/images/icons/plus-circle.svg"
              className="w-full h-full"
            />
          </div>
        </Tooltip>
      );

    default:
      break;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#F9FBFE"
        stroke="#DDE5ED"
        d="M14.7 8c0 3.676-3.313 6.7-6.917 6.7C4.217 14.7 1.3 11.715 1.3 8c0-3.676 3.313-6.7 6.918-6.7 3.565 0 6.482 2.985 6.482 6.7z"
      ></path>
      <path
        stroke="#144EA6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M4.174 8.185l2.483 2.228a.5.5 0 00.69-.02l4.532-4.582"
      ></path>
    </svg>
  );
};

export default React.memo(TaskTypeIcon);
