/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import io from "socket.io-client";
import {
  getComments,
  reactToComment,
} from "../../../Core/redux/api/conversationAPI";
import { updateVault } from "../../../Core/redux/slices/vault.slice";
import TaskActivityLog from "./TaskActivityLog";
import ConversationInput from "../../Conversations/ConversationInput";
import { Popover, Tooltip } from "antd";
import { EmojiIcon } from "../../../Core/svgV2/EmojiIcon";
import { updateConversations } from "../../../Core/redux/slices/conversations.slice";
import { EditIcon } from "../../../Core/svgV2/EditIcon";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import dayjs from "dayjs";
import UserDetailsCard from "../../Conversations/UserDetailsCard";
import ParseMentions from "../../Conversations/ParseMentions";
import { REACTIONS } from "../../../Core/constants";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import { chatClient } from "../../../Core/utils/axiosClient";

const QuickChatMessage = ({
  message,
  reactToMessage,
  allowHover = true,
  showSender = true,
  deleteMessage,
}: {
  message: any;
  comments?: boolean;
  allowHover?: boolean;
  reactToMessage?: (
    emoji: string,
    messageId: string,
    isAlreadyReacted: boolean
  ) => void;
  deleteMessage?: (id: string) => void;
  showSender?: boolean;
}) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();

  const isAlreadyReacted = (emoji) =>
    message.reactions &&
    message.reactions[emoji] &&
    message.reactions[emoji].includes(user.id);

  const reactionOverLay = (
    <div className="flex bg-white shadow-bottom items-center rounded-full h-[40px]">
      {REACTIONS.map((reaction) => (
        <button
          key={reaction.reaction}
          className="w-5  mx-3"
          onClick={() =>
            reactToMessage(
              reaction.emoji,
              message._id,
              isAlreadyReacted(reaction.emoji)
            )
          }
        >
          <span className="text-xl">{reaction.emoji}</span>
        </button>
      ))}
    </div>
  );

  const getHoverMenu = () => {
    return (
      <div className="bg-white absolute top-[-18px]  right-4 h-9 border border-gray-100 flex px-3 gap-x-3 items-center  rounded-[4px]  ">
        <Popover
          trigger={["click"]}
          arrow={false}
          content={reactionOverLay}
          className="group-hover"
          getPopupContainer={(node) => node.parentElement}
        >
          <button className="">
            <EmojiIcon />
          </button>
        </Popover>
        <Tooltip title="Edit Comment">
          <button
            onClick={() => {
              dispatch(
                updateConversations({
                  key: "editMessage",
                  value: { edit: true, messageObject: message },
                })
              );
            }}
          >
            <EditIcon className="text-black" />
          </button>
        </Tooltip>
        <Tooltip title="Delete Comment">
          <button onClick={() => deleteMessage(message._id)}>
            <DeleteIcon className="text-black" />
          </button>
        </Tooltip>
      </div>
    );
  };

  const senderProfilePicture = tenantMembers.find(
    (t) => t.id == message.senderId
  )?.profilePicture;
  //todo implement profiloepicture color

  return (
    <div
      className={`px-4 py-1 scroll-mt-[20vh] relative ${
        allowHover ? "group hover:bg-gray-100" : ""
      }`}
      data-message-id={message._id}
    >
      <div className="flex">
        <div className="hidden group-hover:block">{getHoverMenu()}</div>
        {showSender ? (
          <CustomAvatar
            title={message.senderName}
            size={28}
            src={senderProfilePicture}
            className=""
            whiteText
          />
        ) : (
          <div className="h-full mt-1.5 items-start flex w-[28px]">
            <Tooltip
              title={
                message.createdAt
                  ? dayjs(message.createdAt)
                      .utc()
                      .local()
                      .format("MMMM DD YYYY, hh:mm a")
                  : "-"
              }
            >
              <div className="out-300-10 text-gray-500 hidden group-hover:block">
                {message.createdAt
                  ? dayjs(message.createdAt).utc().local().format("HH:MM")
                  : "-"}
              </div>
            </Tooltip>
          </div>
        )}
        <div className="ml-2 w-full">
          {showSender && (
            <div className="flex items-center gap-x-1.5">
              <UserDetailsCard
                userName={message.senderName}
                isDisable={false}
                userId={message.senderId}
              />
              {message.request?.type && (
                <img
                  src="/images/v2/conversations/AttachmentHeader.svg"
                  className="w-[26px] h-5"
                  alt="Attachment Icon"
                />
              )}
              <Tooltip
                title={
                  message.createdAt
                    ? dayjs(message.createdAt)
                        .utc()
                        .local()
                        .format("MMMM DD YYYY, hh:mm a")
                    : "-"
                }
              >
                <div className="out-300-10 text-gray-500">
                  {message.createdAt
                    ? dayjs(message.createdAt).utc().local().format("hh:mm a")
                    : "-"}
                </div>
              </Tooltip>
            </div>
          )}

          <div className="mt-1">
            <ParseMentions
              value={message.message}
              textClassName="max-w-[250px] out-300-14"
            />
          </div>

          <div className={`flex items-center ${"justify-start"} gap-x-2`}>
            {message.reactions &&
              Object.keys(message.reactions).map((emoji, index) => {
                const hasReactions = message.reactions[emoji].length > 0;
                return (
                  hasReactions && (
                    <button
                      className="flex items-center gap-x-2 pt-2"
                      key={index}
                      onClick={() =>
                        reactToMessage(
                          emoji,
                          message._id,
                          message.reactions[emoji].includes(user.id)
                        )
                      }
                    >
                      <div
                        className="flex items-center py-0.5 px-2 h-6 bg-white border border-gray-200 rounded-2xl"
                        style={{ padding: "2px 8px" }}
                      >
                        <span className="text-lg">{emoji}</span>
                        {message.reactions[emoji].length > 1 && (
                          <span className="out-500-12 pl-1 text-gray-700">
                            {message.reactions[emoji].length}
                          </span>
                        )}
                      </div>
                    </button>
                  )
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Comments = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { openCommentsModal } = useAppSelector((state) => state.vault);
  const dispatch = useAppDispatch();
  // const { projectId } = useParams();

  // conection to socket
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    transports: ["websocket"],
    path: "/conversation-socket",
    auth: {
      projectId: 161,
      userId: user.id,
    },
    autoConnect: false,
  });
  // isAlreadyReacted
  const reactToMessage = (emoji, messageId) => {
    dispatch(
      reactToComment({ emoji, userId: user.id, messageId, comment: true })
    );

    const updatedComments = openCommentsModal.comments.map((m) => {
      if (m._id === messageId) {
        const reactions = m.reactions || {};
        const userReaction = m.reactions[emoji] ? m.reactions[emoji] : [];
        const updatedReaction = {
          ...reactions,
          [emoji]: [...userReaction, user.id],
        };
        return { ...m, reactions: updatedReaction };
      }
      return m;
    });

    const updatedOpenCommentsModal = {
      ...openCommentsModal,
      comments: updatedComments,
    };
    dispatch(
      updateVault({ key: "openCommentsModal", value: updatedOpenCommentsModal })
    );

    return "";
  };

  const deleteMessage = async (id) => {
    await chatClient.delete("/conversation/delete-message", {
      params: { messageId: id, comment: true },
    });

    const messageListCopy = [...openCommentsModal.comments];

    const updatedComments = messageListCopy.filter((m) => {
      return m._id !== id;
    });

    const updatedOpenCommentsModal = {
      ...openCommentsModal,
      comments: updatedComments,
    };
    dispatch(
      updateVault({ key: "openCommentsModal", value: updatedOpenCommentsModal })
    );
  };

  return (
    <div className="w-full bg-white flex flex-col gap-y-3">
      <ConversationInput socket={socket} comments={true} isMyBoard />
      {[...openCommentsModal.comments].reverse().map((comment) => (
        <QuickChatMessage
          message={comment}
          key={comment._id}
          reactToMessage={reactToMessage}
          deleteMessage={deleteMessage}
        />
      ))}
    </div>
  );
};

const CommentsAndActivity = ({ task }) => {
  const [currentTab, setCurrentTab] = useState(1);
  const { openCommentsModal } = useAppSelector((state) => state.vault);
  const { selectedTask } = useAppSelector((state) => state.schedule);

  const dispatch = useAppDispatch();

  const getName = () => {
    switch (task?.goalType) {
      case "1":
        return "task";
      case "3":
        return "bug";
      default:
        return "task";
    }
  };

  useEffect(() => {
    // dispatch(
    //   updateVault({
    //     key: "openCommentsModal",
    //     value: {
    //       docType: getName(),
    //       docId: task?.id,
    //       name: task?.name,
    //       comments: [],
    //       users: [{ userId: task?.assigneeId }, { userId: task?.reporterId }],
    //     },
    //   })
    // );

    dispatch(
      updateVault({
        key: "openCommentsModal",
        value: {
          docType: "task",

          docId: task?.id,
          name: task?.name,
          comments: [],
          users: [{ userId: task?.assigneeId }, { userId: task?.reporterId }],
        },
      })
    );
    dispatch(
      getComments({
        docId: task?.id,
        docType: "task",
        limit: 1000,
        offset: 0,
      })
    );
  }, [task?.id]);
  useEffect(() => {
    dispatch(
      updateVault({
        key: "openCommentsModal",
        value: {
          docType: "task",

          docId: task?.id,
          name: task?.name,
          comments: [],
          users: [{ userId: task?.assigneeId }, { userId: task?.reporterId }],
        },
      })
    );
  }, [task?.goalType, selectedTask]);

  return (
    <div className="mt-[37px]">
      <div className="flex gap-x-5">
        <div
          onClick={() => setCurrentTab(1)}
          className={`${
            currentTab === 1
              ? "text-primary-700 border-b-2 border-primary-700"
              : "text-gray-500"
          }  pb-2 out-500-14 px-[2px] `}
          role="button"
          tabIndex={1}
        >
          <div className="flex items-center">
            {" "}
            <p>Comments</p>{" "}
            <div className="bg-primary-50 h-[23px] w-[23px] flex justify-center items-center rounded-2xl ml-2">
              <span>{openCommentsModal.comments.length}</span>
            </div>
          </div>
        </div>
        <div
          onClick={() => setCurrentTab(2)}
          className={`${
            currentTab === 2
              ? "text-primary-700 border-b-2 border-primary-700"
              : "text-gray-500"
          }  pb-2 out-500-14 px-[2px] `}
          role="button"
          tabIndex={1}
        >
          <div className="flex items-center">
            <p>Activity</p>
          </div>
        </div>
      </div>

      {currentTab == 1 && (
        <div className="mt-8">
          <Comments />
        </div>
      )}
      {currentTab == 2 && <TaskActivityLog task={task} />}
    </div>
  );
};

export default CommentsAndActivity;
