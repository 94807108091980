import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  docs: [],
  from: "",
  visible: false,
  currentIndex:0
};

export const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {
    updatePreview: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updatePreviewMultiple: (state, action) => {
      action.payload.map((ele) => {
        state[ele.key] = ele.value;
      });
    },
    resetPreview: (state, action) => {
      action.payload.map((ele) => {
        state[ele] = initialState[ele];
      });
    },
    resetToInitialPreview: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    resetToInitial: (state, action) => {
        Object.keys(initialState).forEach(
          (key) => (state[key] = initialState[key])
        );
      },
  },
});

export const { updatePreview  ,updatePreviewMultiple , resetPreview , resetToInitial , resetToInitialPreview} = previewSlice.actions;
export default previewSlice.reducer;
