import React, { useEffect, useState } from "react";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { useAppSelector } from "../../../Core/redux/hooks";
import { Avatar, Tooltip } from "antd";
import { useParams } from "react-router-dom";

const getCurrentUser = ({ task, isSubTask = false }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { collaboratorsForTask } = useAppSelector((state) => state.myBoard);
  const hasCollaborators = task?.collaborators?.length > 1;
  const [selectedUser, setSelectedUser] = useState(user);
  const { taskId, subtaskId } = useParams();
  useEffect(
    () => console.log("task.co", task),
    //opening task , collab not found
    [collaboratorsForTask]
  );

  useEffect(() => {
    const selUser = tenantMembers.find((user) => +user.id === +task.assigneeId);
    if (task.assigneeId) {
      setSelectedUser(selUser);
    }
    //opening task , collab not found
    console.log("task.com", selectedUser, task.identifier, task);
  }, [task]);

  return (
    <>
      {" "}
      {taskId || subtaskId ? (
        <div className="gap-x-1 flex w-full h-full">
          {task.collaborators?.length > 0 && (
            <Avatar.Group
              maxCount={4}
              maxPopoverTrigger="click"
              size={24}
              maxStyle={{
                color: "#165ABF",
                backgroundColor: "#EFF8FF",
                cursor: "pointer",
              }}
            >
              {tenantMembers
                .filter(
                  (u) =>
                    task?.collaborators?.includes(u.id) && u.id !== user?.id
                )
                .map((member) => {
                  return (
                    <Tooltip
                      key={member.title}
                      title={`${member.firstname} ${member.lastname}`}
                      placement="bottom"
                    >
                      <div>
                        {" "}
                        <CustomAvatar
                          title={`${member.firstname} ${member.lastname}`}
                          src={member.profilePicture}
                          size={24}
                          whiteText
                          fontSize={"10px"}
                          color={member.profilePictureColorCode}
                        />
                      </div>
                    </Tooltip>
                  );
                })}
            </Avatar.Group>
          )}
          <div className="w-6 h-6 rounded-[12px] border border-dashed border-primary-500 relative">
            <PlusIcon className="text-primary-500 absolute top-1  right-1" />
          </div>
        </div>
      ) : (
        <div
          className="relative"
          role={!isSubTask && "button"}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {+task.goalType !== 4 && (
            <div
              className={`absolute top-[-6px] right-[-4px]  rounded-full w-[14px] h-[14px] z-20 ${
                hasCollaborators ? "bg-success-600" : "bg-primary-100"
              }`}
            >
              {hasCollaborators ? (
                <p className="text-center text-white out-600-8 pt-[1px]">
                  +{task?.collaborators?.length - 1}
                </p>
              ) : (
                <div>
                  <PlusIcon
                    width={10}
                    height={10}
                    className="text-primary-600"
                  />
                </div>
              )}
            </div>
          )}
          <CustomAvatar
            title={
              task.assigneeId ? getUsersName(selectedUser) : getUsersName(user)
            }
            src={
              task.assigneeId
                ? selectedUser?.profilePicture
                : user.profilePicture
            }
            size={20}
            fontSize={"12px"}
            whiteText
            color={
              task.assigneeId
                ? selectedUser?.profilePictureColorCode
                : user.profilePictureColorCode
            }
          />
        </div>
      )}
    </>
  );
};

export default getCurrentUser;
