import { Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Error from "./Core/Common/Error";
import SuccessMessage from "./Core/Common/SuccessMessage";
import { useAppDispatch, useAppSelector } from "./Core/redux/hooks";
// import FilePreviewScreenNew from "./Modules/Conversations/FilePreviewScreenNew";
import { getMessaging, onMessage } from "firebase/messaging";
import AllowNotificationPopup from "./Core/Common/AllowNotificationPopup";
import NoInternetConnection from "./Core/CommonV2/NoInternetConnectionPopup";
import ActionCompletedAlertModal from "./Core/CommonV2/ActionCompletedAlertModal";
import { getCurrentWorkSpaceRole } from "./Core/redux/api/userAPI";
import Cookies from "js-cookie";

import { updateDashboard } from "./Core/redux/slices/dashboard.slice";
import { updateSchedule } from "./Core/redux/slices/schedule.slice";
import localforage from "localforage";
import CollabRequestModal from "./ModulesV2/Schedule/MyBoard/Modals/CollabRequestModal";
import NewTimeRequestModal from "./ModulesV3/Calendar/Modals/NewTimeRequestModal";

const ProgressBar = ({ loading }) => {
  if (!loading) {
    return <></>;
  }
  return (
    <div className="progress-materializecss">
      <div className="indeterminate"></div>
    </div>
  );
};

const CheckAndUpdateVersion = () => {
  const clearCache = (reloadAfterClear = true, metaVersion) => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach(async (name) => {
          await caches.delete(name);
        });
      });

      localStorage.clear();
      localforage.clear();

      Cookies.remove("jwt");
      Cookies.remove("refreshToken");
      localStorage.setItem("appVersion", metaVersion);

      if (reloadAfterClear) window.location.href = "/";
    }
  };

  useEffect(() => {
    const metaVersion = document
      .querySelector('meta[name="app-version"]')
      .getAttribute("content");

    const localVersion = localStorage.getItem("appVersion");

    if (!localVersion || localVersion !== metaVersion) {
      clearCache(true, metaVersion);
    }
  }, []);

  return null; // This component doesn't render anything
};

const AppWrapper = ({ children }) => {
  const dispatch = useAppDispatch();
  const { user, loggedIn } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { currentThread } = useAppSelector((state) => state.conversations);
  const { currentModule } = useAppSelector((state) => state.dashboard);
  const { isGoalEdited, isTaskExpanded } = useAppSelector(
    (state) => state.schedule
  );

  const { activeRequests, loading, success } = useAppSelector(
    (state) => state.global
  );

  const { visible: filePreviewVisible } = useAppSelector(
    (state) => state.preview
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const isLoading = activeRequests > 0 && loading;

  const openNotification = (title, body) => {
    notification.open({
      message: <p className="mon-500-14 text-gray-900">{title}</p>,
      description: <p className="mon-500-12 text-gray-500">{body}</p>,
      duration: 5,
      onClick: () => {
        console.log("Notification Clicked!");
      },
      className: "rounded-lg",
      closeIcon: <img src="/images/icons/x-close.svg" />,
      icon: <img src="/images/w.svg" />,
    });
  };

  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    const currentUrl = window.location.href;

    if (payload.data && currentUrl.includes("conversations")) {
      if (
        payload.data.threadId &&
        payload.data.threadId == currentThread.threadId
      ) {
        return;
      }
      if (
        payload.data.senderId &&
        payload.data.senderId == currentThread.userId
      ) {
        return;
      }
    } else {
      openNotification(payload.notification.title, payload.notification.body);
    }
  });

  useEffect(() => {
    if (tenantDetails.tenantId && user.id && loggedIn) {
      dispatch(getCurrentWorkSpaceRole());
    }
  }, [user.id, tenantDetails.tenantId]);

  useEffect(() => {
    dispatch(
      updateDashboard({ key: "currentModule", value: window.location.pathname })
    );
  }, [window.location.pathname]);

  useEffect(() => {
    if (!isTaskExpanded && isGoalEdited) {
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: "Changes saved",
              subtitle: "Your changes were saved succesfully !",
              description: "",
            },
          },
        })
      );
      dispatch(updateSchedule({ key: "isGoalEdited", value: false }));
    }
  }, [isGoalEdited, isTaskExpanded]);

  return (
    <div className="relative">
      <CheckAndUpdateVersion />
      <Error />
      <ProgressBar loading={isLoading} />
      <NoInternetConnection />
      {/* <Spin spinning={isLoading} style={{ maxHeight: "100vh", zIndex: 9999 }}> */}
      <AllowNotificationPopup />
      {/* {success?.message?.length !== 0 && <SuccessMessage />} */}
      {children}
      <ActionCompletedAlertModal />
      <CollabRequestModal />
      <NewTimeRequestModal />
      {/* </Spin> */}
    </div>
  );
};

export default AppWrapper;
