import React, { useState } from "react";
import KanbanColumn from "./KanbanColumn";
import TaskCard, { NewtaskCard } from "../TaskCard";
import KanbanItem from "./KanbanItem";
import { useAppSelector } from "../../../../Core/redux/hooks";
import CustomBadge from "../../../../Core/CommonV2/CustomBadge";
import TaskItem from "../../Planner/TaskItem";
import { useParams } from "react-router-dom";
import MyBoardLabel from "../../../../Core/CommonV3/MyBoardLabel";

const SubtaskKanbanBoard = ({ task, board, statusArray, handleFilter }) => {
  const { showNewTaskCard } = useAppSelector((state) => state.schedule);
  const { projectId } = useParams();

  const { labels } = useAppSelector((state) => state.schedule);
  const [showSubtasks, setShowSubTasks] = useState(true);
  const option = labels
    .filter((label) => label.projectId === projectId)
    .map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });

  console.log(task.subtasks, "subtasks");

  const handleSubtaskFilter = (tasks, channel) => {
    // return handleFilter(tasks, channel);
    const filteredSubtaskTask = handleFilter(tasks, channel);
    console.log(filteredSubtaskTask, "here filter xyz", tasks);
    if (filteredSubtaskTask.length > 0) return filteredSubtaskTask;
    else
      return task.subtasks.filter((item) => +item.swimlaneId === +channel.id);
  };

  return (
    <div className=" first:mt-[10px] pb-[10px] ">
      <TaskItem
        taskId={task.id}
        key={task.id}
        task={task}
        labelOptions={option}
        showSubtaskKanbanBoard={showSubtasks}
        setShowSubtaskKanbanBoard={setShowSubTasks}
      />
      {showSubtasks && (
        <div
          data-kanbanboard={true}
          className="grid-container ml-[2px]   "
          ref={board}
        >
          {statusArray &&
            [...statusArray].map((channel, index) => (
              // todo check if removing null object any effect
              <KanbanColumn
                key={channel.id}
                status={`${channel.id}_${task.id}_${index}`}
                channel={channel}
                className=" kanban-col "
                parentTaskId={task.id}
              >
                {showNewTaskCard?.visible &&
                +showNewTaskCard?.channel?.id === +channel?.id &&
                +showNewTaskCard?.taskId === +task.id ? (
                  <NewtaskCard
                    status={channel}
                    isHovered={true}
                    defaultOpen
                    parentTaskId={task.id}
                  />
                ) : null}
                {console.log(
                  handleSubtaskFilter(task.subtasks, channel),
                  "what xyz"
                )}
                {handleSubtaskFilter(task.subtasks, channel).map(
                  (item, index) => (
                    <KanbanItem key={`${item.id}-${index}`} id={item.id}>
                      <TaskCard task={item} />
                    </KanbanItem>
                  )
                )}
              </KanbanColumn>
            ))}
        </div>
      )}
    </div>
  );
};

export default SubtaskKanbanBoard;
