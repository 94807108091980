import { Progress, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import AlertIcon from "../../Core/svgV2/AlertIcon";
import { useAppSelector } from "../../Core/redux/hooks";
import { useNavigate } from "react-router-dom";

type ProjectCard = Partial<{
  projectDetails: Partial<{
    title: string;
    progress: string;
    projectImage: string;
    id: string | number;
    colorCode: string;
    hasTimeEstimates: boolean;
  }>;
  onClick: (projectId) => void;
}>;

//todo space between title and progress bar
const ProjectCard = ({ projectDetails, onClick }: ProjectCard) => {
  const { title, progress, projectImage, id, colorCode } = projectDetails;
  const { projectId } = useParams();
  const { pendingTimeEstimates } = useAppSelector(
    (state) => state.timeEstimates
  );
  const navigate = useNavigate();

  useEffect(() => {
    console.log("");
  }, [projectDetails]);

  return (
    <div
      className={`rounded-xl  flex-col justify-center px-6 py-[18px] bg-white h-[78px] min-w-[250px] 2xl:min-w-[255px] items-center 
    cursor-pointer ${
      projectId === id ? `shadow-[0px_0px_0px_2px_#D1E9FF]` : ""
    } `}
      onClick={() => onClick(projectDetails.id)}
      role="button"
    >
      <div className="flex justify-between items-center gap-x-3">
        <div className="flex items-center gap-x-2">
          <CustomAvatar
            title={title}
            color={colorCode ? colorCode : ""}
            size={24}
            whiteText
            fontSize="9px"
            src={projectImage ? projectImage : ""}
          />
          <span className="out-500-14 text-gray-900">{title}</span>
        </div>
        {!projectDetails?.hasTimeEstimates && +progress !== 100 && (
          <Tooltip
            title={"Estimates are pending"}
            placement="bottom"
            style={{
              display: "flex",
              padding: "4px 8px",
              flexDirection: "column",
              alignItems: "flex-start",
              borderRadius: "4px",
              background: "#000000",
              color: "#ffffff",
            }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/time-estimates/${projectDetails.id}`);
              }}
              style={{ cursor: "pointer" }}
            >
              <AlertIcon height="14px" width="14px" />
            </div>
          </Tooltip>
        )}
      </div>

      <div className=" flex ">
        <Progress
          showInfo={false}
          strokeWidth={4}
          strokeColor="#165ABF"
          percent={+progress}
          trailColor="#EDF3F9"
          className="progress-bar-tiny"
        />
        <div className="out-500-14 text-gray-700 ml-3">
          {/* {Math.round(+progress)}% */}
          {Math.round(+progress)}%
        </div>
      </div>
    </div>
  );
};
export default ProjectCard;
