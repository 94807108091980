import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { Popover } from "antd";
import ClockIcon from "../../../Core/svgV2/Clock";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { updateEvent } from "../../../Core/redux/api/scheduleAPI";

const HourArray = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const EventDetailsPopover = ({ eventDetails }) => {
  const dispatch = useAppDispatch();
  console.log(eventDetails, "eventDetails");
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);

  const attendees = [];
  eventDetails.meetMetaData.attendees.map((attendee) => {
    let selectedUser = tenantMembers.find(
      (tenant) => tenant.email == attendee.email
    );

    if (attendee.email === eventDetails.meetMetaData.organizer.email) {
      selectedUser = { ...selectedUser, isOrganizer: 1 };

      // selectedUser.isOrganizer = 1;
    } else {
      selectedUser = { ...selectedUser, isOrganizer: 0 };

      // selectedUser.isOrganizer = 0;
    }
    selectedUser = { ...selectedUser, responsestatus: attendee.responsestatus };
    // selectedUser.responsestatus = attendee.responsestatus;
    console.log(selectedUser, "after");
    attendees.push(selectedUser);
  });
  const noOfAccepted = Object.values(attendees).filter(
    (o) => o.responsestatus === "accepted"
  ).length;
  const noOfNeedsAction = Object.values(attendees).filter(
    (o) => o.responsestatus === "needsAction"
  ).length;

  const startTime = eventDetails.startTime
    ? dayjs(eventDetails.startTime).local().format("hh:mm a")
    : "00:00";
  const endTime = eventDetails.endTime
    ? dayjs(eventDetails.endTime).local().format("hh:mm a")
    : "00:00";
  return (
    <div
      className="min-w-[352px] bg-white  shadow-lg rounded-lg"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="p-4">
        <h3 className="out-500-16 text-black">{eventDetails.title}</h3>
        <div className="flex mt-4 items-center">
          <ClockIcon height="16" width="16" className="text-gray-500" />
          <div className="out-500-14 text-black ml-4">
            {eventDetails.eventDate
              ? dayjs(eventDetails.eventDate).format("dddd, DD MMMM")
              : "-"}
          </div>
          <div className="mx-2 out-500-14 text-gray-500">•</div>
          <div className="out-500-14 text-gray-500">{startTime}</div>
          <div className="mx-1 out-500-14 text-gray-500">-</div>
          <div className="out-500-14 text-gray-500">{endTime}</div>
        </div>
        <div className="mt-4 flex items-center">
          <img src="/images/v2/schedule/users-03.svg" />
          <span className="out-500-14 text-black ml-4">
            {eventDetails?.meetMetaData.attendees?.length || 0} Guests
          </span>
          <div className="mx-2 out-500-14 text-gray-500">•</div>
          <span className="out-500-14 text-success-600">
            {noOfAccepted} Yes
          </span>
          <div className="mx-2 out-500-14 text-gray-500">•</div>
          <span className="out-500-14 text-gray-500">
            {noOfNeedsAction} Awaiting
          </span>
        </div>
        <div className="ml-4 ">
          {attendees.map((attendee, index) => (
            <div className="flex mt-4 items-center " key={index}>
              <div
                className={`${
                  attendee.responsestatus === "accepted"
                    ? "bg-[#A6F4C5]"
                    : attendee.responsestatus === "tentative"
                    ? " bg-gray-200"
                    : attendee.responsestatus === "declined"
                    ? "bg-red-200"
                    : ""
                } rounded-full p-1`}
              >
                <CustomAvatar
                  title={
                    (attendee.firstname || "w") +
                    " " +
                    (attendee.lastname || "d")
                  }
                  src={attendee.profilePicture}
                  whiteText
                  color={attendee.profilePictureColorCode}
                />
              </div>
              <div className="ml-2">
                <p className="out-500-14 text-gray-700">
                  {attendee.firstname} {attendee.lastname}
                </p>
                {attendee.isOrganizer ? (
                  <p className="out-300-12 text-gray-500">Organiser</p>
                ) : (
                  " "
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="flex mt-4 justify-between items-center">
          <span className="out-500-14 text-black">Attending?</span>
          <div className="flex gap-x-2">
            <CustomButton
              text="Yes"
              onClick={async () => {
                const meetAttendees = [...eventDetails.meetMetaData.attendees];

                //filtering other users
                const allAttendees = [];
                meetAttendees.map((attendee) => {
                  if (attendee.email != user.email) {
                    allAttendees.push(attendee);
                  }
                });

                const updatedAttendees = {
                  email: user.email,
                  responseStatus: "accepted",
                };
                allAttendees.push(updatedAttendees);
                console.log(allAttendees, "event update");
                const payload = {
                  startTime: eventDetails.startTime,
                  endTime: eventDetails.endTime,
                  eventId: eventDetails.eventId,
                  attendees: allAttendees,
                  meetId: eventDetails.meetId,
                };

                await dispatch(updateEvent(payload));
              }}
              icon={<CheckIcon className="text-primary-600 ml-3" />}
              className="text-primary-600 bg-primary-50 out-500-14"
            />
            <CustomButton
              text="No"
              className="text-gray-500 out-500-14"
              onClick={async () => {
                const meetAttendees = [...eventDetails.meetMetaData.attendees];

                //filtering other users
                const allAttendees = [];
                meetAttendees.map((attendee) => {
                  if (attendee.email != user.email) {
                    allAttendees.push(attendee);
                  }
                });

                const updatedAttendees = {
                  email: user.email,
                  responseStatus: "declined",
                };
                allAttendees.push(updatedAttendees);
                console.log(allAttendees, "event update");
                const payload = {
                  startTime: eventDetails.startTime,
                  endTime: eventDetails.endTime,
                  eventId: eventDetails.eventId,
                  attendees: allAttendees,
                  meetId: eventDetails.meetId,
                };

                await dispatch(updateEvent(payload));
              }}
            />
            <CustomButton
              text="Maybe"
              className="text-gray-500 out-500-14"
              onClick={async () => {
                const meetAttendees = [...eventDetails.meetMetaData.attendees];

                //filtering other users
                const allAttendees = [];
                meetAttendees.map((attendee) => {
                  if (attendee.email != user.email) {
                    allAttendees.push(attendee);
                  }
                });

                const updatedAttendees = {
                  email: user.email,
                  responseStatus: "tentative",
                };
                allAttendees.push(updatedAttendees);
                console.log(allAttendees, "event update");
                const payload = {
                  startTime: eventDetails.startTime,
                  endTime: eventDetails.endTime,
                  eventId: eventDetails.eventId,
                  attendees: allAttendees,
                  meetId: eventDetails.meetId,
                };

                await dispatch(updateEvent(payload));
              }}
            />
          </div>
        </div>
      </div>
      <div
        role="button"
        onClick={() => window.open(eventDetails?.meetLink, "_blank")}
        className="mt-4 border-t py-3 flex items-center justify-center gap-x-1.5"
      >
        <img className="" src="/images/v2/schedule/meet.svg" />
        <span className="out-500-14 text-primary-600">
          Join with Google Meet
        </span>
      </div>
    </div>
  );
};

const EventCell = ({
  startTime,
  endTime,
  eventId,
  title,
  eventCellStyle,
  popoverPlacement,
  eventDetails,
}) => {
  const eventRef = useRef();
  const { projectList } = useAppSelector((state) => state.projects);
  const hourHeight = 60;
  const [startHour, startMinutes] = startTime.split(":");
  const [endHour, endMinutes] = endTime.split(":");

  const hourDiff = Math.abs(endHour - startHour);
  const minDiff = Math.abs(startMinutes - endMinutes);
  const styles = {
    top: `${(startMinutes * hourHeight) / 60}px`,
    height: `${hourDiff * hourHeight + minDiff}px`,
    minHeight: "15px",
    ...eventCellStyle,
  };

  console.log(
    hourDiff,
    hourHeight,
    minDiff,
    "eventDetails",
    endHour,
    startHour
  );

  const getProjectTheme = useCallback(() => {
    if (eventDetails.projectId) {
      const project = projectList.find((p) => p.id == eventDetails.projectId);
      if (project && project.projectThemeColor) {
        return project.projectThemeColor;
      }

      return "#165ABF";
    }
    return "#165ABF";
  }, [eventDetails]);

  return (
    <Popover
      arrow={false}
      trigger={"click"}
      placement={popoverPlacement}
      content={<EventDetailsPopover eventDetails={eventDetails} />}
    >
      <div
        data-event-id={eventId}
        data-start-time={startTime}
        data-end-time={endTime}
        ref={eventRef}
        role="button"
        onClick={(e) => e.stopPropagation()}
        className={`absolute  w-full rounded border border-white px-4 hover:z-[999!important] transform transition-all duration-200`}
        style={{
          ...styles,
          backgroundColor: getProjectTheme(),
        }}
      >
        <span className="flex justify-center h-full flex-col flex-wrap gap-x-2 w-max">
          <span className="out-500-12 text-white w-max">
            {title || "(No title)"}
          </span>
          <span className=" out-300-12 text-gray-50">
            {startTime} - {endTime}
          </span>
        </span>
      </div>
    </Popover>
  );
};

const TimeCell = ({ hour, tasks, currentSelectedDate, popoverPlacement }) => {
  const timecellRef = useRef(null);

  const dispatch = useAppDispatch();

  const [width, setWidth] = useState(200);

  useEffect(() => {
    if (timecellRef.current) {
      setWidth(timecellRef.current?.offsetWidth);
    }
  }, []);

  let currentTimeHTML = <></>;

  const [cellTimeHour] = hour.split(":");
  const currentTime = dayjs().format("HH:mm");
  const [currentTimeHour, currentTimeMinutes] = currentTime.split(":");

  if (cellTimeHour === currentTimeHour) {
    currentTimeHTML = (
      <div
        data-time-current={currentTime}
        data-date-time={dayjs().format("DD/MM/YYYY HH:MM")}
        className="absolute top-2 border-b z-50 border-error-500"
        style={{ width, right: "24px", top: `${currentTimeMinutes}px` }}
      ></div>
    );
  }

  const getStyles = useCallback(
    (i) => {
      if (i == 0) {
        return { width: "100%", left: 0 };
      }
      if (timecellRef.current) {
        const left = timecellRef.current?.offsetWidth / tasks.length;
        return {
          left,
          width: timecellRef.current?.offsetWidth - left,
        };
      } else {
        return {};
      }
    },
    [timecellRef?.current]
  );

  return (
    <div
      className="flex items-start  first:pt-1 min-h-[60px] w-full pr-6 relative "
      data-time={hour}
      style={{ height: `60px` }}
    >
      <div className="out-300-12 relative top-[-9px] text-gray-500 flex w-[43px]">
        {hour}
      </div>
      {currentTimeHTML}
      <div
        ref={timecellRef}
        className="w-full ml-2 flex  gap-x-1 relative border-t min-h-[60px] border-gray-200"
        role="button"
        onClick={() => {
          dispatch(
            updateDashboard({
              key: "newEventModal",
              value: {
                visible: true,
                startTime: hour,
                endTime: `${+hour.split(":")[0] + 1}:00`,
                date: currentSelectedDate.format("DD-MM-YYYY"),
              },
            })
          );
        }}
      >
        {tasks.map((task, i) => (
          <EventCell
            key={task.id}
            startTime={dayjs(task.start.dateTime).local().format("HH:mm")}
            endTime={dayjs(task.end.dateTime).local().format("HH:mm")}
            eventId={task.etag}
            title={task.summary}
            eventDetails={task}
            eventCellStyle={{
              zIndex: i + 1,
              ...getStyles(i),
            }}
            popoverPlacement={popoverPlacement}
          />
        ))}
      </div>
    </div>
  );
};

const Timeline = ({ currentDate, popoverPlacement = "left", hourArray }) => {
  // const tasks = [
  //   {
  //     id: 1,
  //     title: "Meeting 1",
  //     startTime: dayjs().set("hour", 9).format("HH:MM"),
  //     endTime: dayjs().set("hour", 10).format("HH:MM"),
  //   },
  //   {
  //     id: 2,
  //     title: "Meeting 2",
  //     startTime: dayjs().set("hour", 9).format("HH:MM"),
  //     endTime: dayjs().set("hour", 11).format("HH:MM"),
  //   },
  //   {
  //     id: 3,
  //     title: "Meeting 3",
  //     startTime: dayjs().set("hour", 12).format("HH:MM"),
  //     endTime: "14:30",
  //   },
  // ];

  const { events } = useAppSelector((state) => state.dashboard);

  return (
    <div className="relative">
      {(hourArray.length !== 0 ? hourArray : HourArray).map((hour) => (
        <TimeCell
          key={hour}
          hour={hour}
          tasks={events.filter((event) => {
            const eventHour = dayjs(event.start.dateTime).format("HH");
            return eventHour === hour.split(":")[0];
          })}
          currentSelectedDate={currentDate}
          popoverPlacement={popoverPlacement}
        />
      ))}
    </div>
  );
};

export default Timeline;
