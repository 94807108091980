import React, { useCallback, useEffect, useState } from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { Checkbox } from "antd";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import useSearch from "../../../Core/hooks/useSearch";
import { client } from "../../../Core/utils/axiosClient";
import { useSearchParams } from "react-router-dom";
import { getProjectDetatis } from "../../../Core/redux/api/projectAPI";
import usePermission from "../../../Core/hooks/usePermission";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { Popover } from "antd/lib";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { updateSettings } from "../../../Core/redux/slices/settings.slice";
import { TooltipPlacement } from "antd/es/tooltip";

const ChangeRoles = ({
  children,
  user,
  placement = "leftBottom",
  handleRoleChange,
}) => {
  const { roleList } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const { search, setSearch, filteredData } = useSearch(roleList, ["roleName"]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [checkedRole, setCheckedRole] = useState(null);
  // const handleRoleChange = async (role) => {
  //   try {
  //     // await client.put("/settings/member-assign-roles", {
  //     //   userId: user.userId,
  //     //   roleId: role.id,
  //     //   roleName: role.roleName,
  //     //   projectId,
  //     // });
  //     setPopoverOpen(false);
  //     dispatch(getProjectDetatis({ projectId }));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <Popover
      open={popoverOpen}
      onOpenChange={(val) => setPopoverOpen(val)}
      content={
        <div className="w-[320px] bg-white  border border-gray-200 rounded-lg shadow-lg py-1">
          <div className="py-2.5  px-[14px]">
            <CustomSearchBox
              placeholder="Search Roles"
              search={search}
              setSearch={setSearch}
            />
          </div>
          <div className="max-h-[200px]  overflow-y-auto">
            {filteredData.map((role) => (
              <div
                role="button"
                className={`py-2.5 flex items-center gap-x-2 px-[14px] ${
                  +user.roleId === role.id && "bg-gray-100"
                }`}
                key={role.id}
                onClick={() => {
                  handleRoleChange(role);
                  setCheckedRole(role);
                }}
              >
                <div className="out-300-14 text-gray-900 flex-1">
                  {role.roleName}
                </div>
                {checkedRole?.id === role.id && (
                  <CheckIcon className="text-primary-600" />
                )}
              </div>
            ))}

            <div
              role="button"
              onClick={() => {
                setPopoverOpen(false);
                dispatch(
                  updateSettings({
                    key: "showRoleModal",
                    value: {
                      visible: true,
                      mode: "new",
                      role: {},
                    },
                  })
                );
              }}
              className="out-500-14  text-primary-600 px-[14px] py-[10px]"
            >
              Create New Role
            </div>
          </div>
        </div>
      }
      arrow={false}
      placement={placement as TooltipPlacement}
      trigger={"click"}
    >
      {children}
    </Popover>
  );
};

const UserRow = ({ user, checked, onCheck, onRoleChange }) => {
  const { hasPermission: manageMembers } = usePermission("2");

  const [role, setRole] = useState(null);
  // const handleRoleChange = useCallback(() => {
  //   onRoleChange(user.id, role);
  // }, [user.id, role, onRoleChange]);

  // Calling `handleRoleChange` directly when `role` or `user.id` changes
  // useEffect(() => {
  //   handleRoleChange();
  // }, [role, user.id, handleRoleChange]);
  useEffect(() => onRoleChange(user.id, role), [role]);
  return (
    <div className="flex justify-between">
      <div className="flex gap-x-3 py-2.5 px-3 items-center">
        <Checkbox
          checked={checked}
          onChange={() => {
            console.log("role check", role);
            // if (role) {
            //   onCheck(user.id, role);
            // } else {
            onCheck(user.id);
            // }
          }}
        />
        <CustomAvatar
          title={getUsersName(user)}
          src={user.profilePicture}
          size={24}
          fontSize={"12px"}
          whiteText
          color={user.profilePictureColorCode}
        />
        <div className="out-300-14 text-gray-900">{getUsersName(user)}</div>
      </div>
      <div className="items-center">
        {manageMembers ? (
          <ChangeRoles
            user={user}
            placement="bottomLeft"
            handleRoleChange={(role) => {
              setRole(role);
            }}
          >
            {role?.roleName && role?.roleName !== "EMPTY" ? (
              <div className="flex items-center gap-x-1">
                <div className="out-500-14 leading-5 group-hover:underline  whitespace-nowrap truncate text-primary-700 ">
                  {role?.roleName}
                </div>
                <ChevronIcon className="text-primary-700" />
              </div>
            ) : (
              <div className="flex items-center gap-x-1">
                <div className="out-500-14 leading-5 group-hover:underline text-primary-700 ">
                  Assign Role
                </div>
                <ChevronIcon className="text-primary-700" />
              </div>
            )}
          </ChangeRoles>
        ) : (
          <div className="out-500-14 leading-5  text-primary-700  whitespace-nowrap truncate">
            {user?.roleName || "--"}
          </div>
        )}
      </div>
    </div>
  );
};

const AddMembersToProjectModal = ({ visible, onClose }) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { userList } = useAppSelector((state) => state.overview);
  const projectId = searchParams.get("projectId");

  const users = tenantMembers.filter((member) => {
    const user = userList.find((u) => u.userId == member.userId);
    if (user) {
      //menas already in project so dont show him
      return false;
    }
    return true;
  });

  const handleCheckboxClick = (id, role) => {
    setSelectedUser((prevSelectedUsers) => {
      const exists = prevSelectedUsers.includes(id);

      return exists
        ? prevSelectedUsers.filter((permId) => permId !== id)
        : [...prevSelectedUsers, id];
    });
  };

  const { search, setSearch, filteredData } = useSearch(users, [
    "firstname",
    "lastname",
    "email",
  ]);
  const handleAssignRole = async (user) => {
    if (selectedUser.includes(user.id)) {
      await client.put("/settings/member-assign-roles", {
        userId: user.id,
        roleId: user.role.id,
        roleName: user.role.roleName,
        projectId,
      });
      dispatch(getProjectDetatis({ projectId }));
    }
  };
  const handleAddToProject = async () => {
    if (selectedUser.length === 0) return;
    const projectId = searchParams.get("projectId");
    try {
      const data = await client.post("/settings/add-members-to-project", {
        projectId,
        users: selectedUser,
      });

      if (selectedRoles) {
        selectedRoles.map((user) => handleAssignRole(user));
      }

      dispatch(getProjectDetatis({ projectId }));
    } catch (err) {
      console.log(err);
    } finally {
      onClose();

      setSelectedUser([]);
      setSelectedRoles([]);
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="667px"
      onCancel={() => {
        onClose();
        setSelectedRoles([]);
      }}
      body={
        <div className="p-4 flex flex-col gap-y-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Add Members</p>

            <button
              onClick={() => {
                onClose();
                setSelectedRoles([]);
              }}
            >
              <CrossIcon className="text-gray-700" />
            </button>
          </div>

          <CustomInputBox
            placeholder="Enter name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className="out-300-14 text-gray-500">
            Select from a list of people in your workspace.
          </div>

          <div className="max-h-[40vh] min-h-[40vh] overflow-y-scroll">
            {filteredData.map((user, i) => (
              <UserRow
                user={user}
                key={user.userId + i}
                checked={selectedUser.includes(user.userId)}
                onCheck={handleCheckboxClick}
                onRoleChange={(id, role) => {
                  if (role) {
                    setSelectedRoles((prevSelectedRoles) => {
                      const exists = prevSelectedRoles.find(
                        (user) => +user.id === +id
                      );

                      if (exists) {
                        prevSelectedRoles = prevSelectedRoles.filter(
                          (user) => +user.id !== +id
                        );
                      }
                      const p = [...prevSelectedRoles, { id, role }];
                      return [...prevSelectedRoles, { id, role }];
                    });
                  }
                }}
              />
            ))}
          </div>

          <div className="flex justify-end gap-x-3">
            <CustomButton
              text="Cancel"
              height="30px"
              className="bg-white text-gray-500"
              onClick={() => {
                onClose();
                setSelectedRoles([]);
              }}
            />
            <CustomButton
              text="Add To Project"
              height="30px"
              className="bg-white text-primary-700"
              onClick={handleAddToProject}
            />
          </div>
        </div>
      }
    />
  );
};

export default AddMembersToProjectModal;
