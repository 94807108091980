import React, { useState, useEffect } from "react";
import { Dropdown, Menu, Layout, Divider } from "antd";
import { CustomResTooltip } from "../Common/CustomResTooltip";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getProjectPhases } from "../redux/api/scheduleAPI";
import { updateVault } from "../redux/slices/vault.slice";
import CustomAvatar from "../Common/CustomAvatar";
import { changeProductTab, changeTab } from "../redux/slices/settings.slice";
import { client } from "../utils/axiosClient";
import { getPhaseName } from "../utils/getPhaseName";

type SideBarMenuItemProps = {
  title: string;
  image?: string;
  activeImage?: string;
  isActive?: boolean;
  path?: string;
  noImage?: boolean;
  onClick?: () => void;
  showMoreOptions?: boolean;
};

const SideBarMenuItem = ({
  title,
  image,
  activeImage,
  isActive,
  path,
  noImage,
  showMoreOptions,
}: SideBarMenuItemProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  return (
    <Link to={path}>
      <div className="pb-2">
        <div
          className={`flex ${
            noImage ? "pl-1.5" : "pl-3.5"
          } py-[9px] items-center justify-between ${
            isActive ? "bg-primary-50 rounded-lg" : " bg-transparent"
          }`}
          onMouseEnter={() => setShowMore(isActive)}
          onMouseLeave={() => setShowMore(false)}
        >
          <div className="flex items-center">
            {!noImage && (
              <div className="w-5 h-5">
                <img
                  src={isActive ? activeImage : image}
                  alt={title}
                  className="w-full h-full"
                />
              </div>
            )}
            <div className="ml-3">
              <p
                className={`${
                  isActive
                    ? "text-primary-600 font-medium"
                    : "text-gray-500 hover:text-primary-600 font-normal"
                } text-xs`}
              >
                {title}
              </p>
            </div>
          </div>
          {showMoreOptions && (
            <Dropdown
              //getPopupContainer={(trigger) => trigger.parentElement}
              className=""
              overlay={
                <Menu className="w-[200px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                  <Menu.Item
                    className=" py-2.5 px-4 rounded-t-lg"
                    onClick={() =>
                      dispatch(
                        updateVault({ key: "addFolderModal", value: true })
                      )
                    }
                  >
                    <div
                      className="flex items-center"
                      // onClick={() => setNewSprintModal(true)}
                    >
                      <img
                        src="/images/icons/folder-plus-01.svg"
                        className="w-4 h-4"
                        alt="add-new-sprint"
                      />
                      <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                        New Folder
                      </span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    className="py-2.5 px-4 rounded-b-lg"
                    onClick={() =>
                      dispatch(
                        updateVault({ key: "addFileModal", value: true })
                      )
                    }
                  >
                    <div
                      className="flex items-center "
                      // onClick={() => setNewScopeModal(true)}
                    >
                      <img
                        src="/images/icons/file-plus-01.svg"
                        alt="add-new-sprint"
                        className="w-4 h-4"
                      />
                      <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                        New File
                      </span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    className="py-2.5 px-4 rounded-b-lg"
                    onClick={() =>
                      dispatch(
                        updateVault({ key: "addLinkModal", value: true })
                      )
                    }
                  >
                    <div
                      className="flex items-center "
                      // onClick={() => setNewScopeModal(true)}
                    >
                      <img
                        src="/images/icons/link.svg"
                        alt="add-new-sprint"
                        className="w-4 h-4"
                      />
                      <span className="mon-400-12 ml-2 whitespace-nowrap  text-gray-700">
                        New Link
                      </span>
                    </div>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div className="pr-2 cursor-pointer">
                <img
                  src="/images/icons/plus-square.svg"
                  className="w-5 h-5 "
                  alt="add-item"
                />
              </div>
            </Dropdown>
          )}
        </div>
      </div>
    </Link>
  );
};

const SettingsMenuItem = ({
  title,
  image,
  activeImage,
  isActive,
  noImage,
  onClick,
}: SideBarMenuItemProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <div onClick={onClick} className="pb-2 cursor-pointer">
      <div
        className={`flex ${
          noImage ? "pl-1.5" : "pl-3.5"
        } py-[9px] items-center justify-between ${
          isActive ? "bg-primary-50 rounded-lg" : " bg-transparent"
        }`}
        onMouseEnter={() => setShowMore(isActive)}
        onMouseLeave={() => setShowMore(false)}
      >
        <div className="flex items-center">
          {!noImage && (
            <div className="w-5 h-5">
              <img
                src={isActive ? activeImage : image}
                alt={title}
                className="w-full h-full"
              />
            </div>
          )}
          <div className="ml-3">
            <p
              className={`${
                isActive
                  ? "text-primary-600 font-medium"
                  : "text-gray-500 hover:text-primary-600 font-normal"
              } text-xs`}
            >
              {title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SideBar = ({ page }: { page: string }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userDetails);
  const { projectDetails } = useAppSelector((state) => state.overview);
  const dispatch = useAppDispatch();

  const getPhase = async () => {
    await dispatch(getProjectPhases({ projectId }));
  };

  const [activeSprint, setActiveSprint] = useState(null);

  const { tabs, currentTab, productTabs, currentProductTab } = useAppSelector(
    (state) => state.settings
  );

  useEffect(() => {
    if (projectId) {
      getPhase();
      getActiveSprint();
    }
  }, [projectId]);

  const { phases } = useAppSelector((state) => state.schedule);

  const getActiveSprint = async () => {
    const { data } = await client.get("/schedule/activeSprint", {
      params: { projectId },
    });
    if (data) {
      setActiveSprint(data?.activeSprint);
    }
  };

  let scheduleUrl = `/schedule/${projectId}?selectedPhase=${phases[0]?.phases}`;
  if (activeSprint) {
    const phaseName = getPhaseName(activeSprint.phaseId);
    scheduleUrl = `/schedule/${projectId}?selectedPhase=${phaseName}&selectedSprint=${activeSprint.id}`;
  }

  return (
    <Layout.Sider
      theme="light"
      trigger={null}
      collapsible
      collapsedWidth={65}
      className="border-r border-gray-300"
      collapsed={false}
    >
      <div className="max-h-[calc(100vh-56px)] h-[calc(100vh-56px)] bottom-0 w-[200px]  fixed border-r-[1px]  bg-white   border-r-gray-200">
        {/* Project Title and Image start */}
        {page === "product-settings" ? (
          <>
            <div className="px-2 mt-8">
              <div
                onClick={() => navigate(-1)}
                className="px-2 cursor-pointer py-3 flex justify-start items-center gap-x-3"
              >
                <div>
                  <img src="/images/icons/chevron-left.svg" alt="" />
                </div>
                <div className="mon-500-12 text-gray-500">Back</div>
              </div>
              <Divider className="m-0" />
              <div className="mon-500-14 text-gray-700 p-4">
                Product settings
              </div>
              {user.roleId === "3" ? (
                <div className="mt-2">
                  {productTabs
                    ?.filter((i) => i.id !== 3)
                    .map((i) => (
                      <SettingsMenuItem
                        key={i.id}
                        title={i.name}
                        noImage={true}
                        isActive={
                          currentProductTab.toString() === i.id.toString()
                        }
                        onClick={() =>
                          dispatch(changeProductTab(i.id.toString()))
                        }
                      />
                    ))}
                </div>
              ) : (
                <div className="mt-2">
                  {productTabs?.map((i) => (
                    <SettingsMenuItem
                      key={i.id}
                      title={i.name}
                      noImage={true}
                      isActive={
                        currentProductTab.toString() === i.id.toString()
                      }
                      onClick={() =>
                        dispatch(changeProductTab(i.id.toString()))
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="pl-3.5 flex items-center mt-10">
              {/* <div className="w-[30px] h-[30px] min-w-[30px] rounded-full">
            <img
              src="/images/icons/mazi.png"
              className="w-full h-full rounded-full"
            />
          </div> */}
              <CustomAvatar
                title={projectDetails.title}
                size={30}
                color={projectDetails.colorCode}
                whiteText
                fontSize="16px"
                src={projectDetails.projectImage}
              />
              <div className="ml-2 pr-5">
                <CustomResTooltip
                  textClassName="text-primary-600 font-[500] w-[128px]  truncate text-xs "
                  text={projectDetails.title}
                />
                <p className="text-xs font-[400] text-gray-400 text-left mt-0.5 ">
                  {projectDetails.clientName || "-"}
                </p>
              </div>
            </div>
            {/* Project Title and Image end */}
            {/* sidebar menu start */}
            {page === "settings" ? (
              <div className="px-2 mt-8">
                <div
                  onClick={() => navigate(scheduleUrl)}
                  className="px-2 cursor-pointer py-3 flex justify-start items-center gap-x-3"
                >
                  <div>
                    <img src="/images/icons/chevron-left.svg" alt="" />
                  </div>
                  <div className="mon-500-12 text-gray-500">
                    Back to project
                  </div>
                </div>
                <Divider className="m-0" />
                <div className="mon-500-14 text-gray-700 p-4">
                  Project Settings
                </div>
                <div className="mt-2">
                  {tabs.map((i) => (
                    <SettingsMenuItem
                      key={i.id}
                      title={i.name}
                      noImage={true}
                      isActive={currentTab.toString() === i.id.toString()}
                      onClick={() => dispatch(changeTab(i.id.toString()))}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="px-2 mt-8">
                <SideBarMenuItem
                  title={"Overview"}
                  image="/images/icons/overview.svg"
                  isActive={page === "overview"}
                  activeImage="/images/icons/overviewBlue.svg"
                  path={`/overview/${projectId}`}
                />
                <SideBarMenuItem
                  title={"Schedule"}
                  image="/images/icons/calendar.svg"
                  activeImage="/images/icons/calendarBlue.svg"
                  isActive={page === "schedule"}
                  path={scheduleUrl}
                />
                <SideBarMenuItem
                  title={"Vault"}
                  image="/images/icons/folder.svg"
                  activeImage="/images/icons/folder-fill.svg"
                  isActive={page === "vault"}
                  path={`/vault/${projectId}`}
                  showMoreOptions={true}
                />
                {/* <SideBarMenuItem
              title={"Conversations"}
              image="/images/icons/message.svg"
              activeImage="/images/icons/message-dots-circle-fill.svg"
              isActive={page === "conversations"}
              path={`/conversations`}
            /> */}
                <Divider className="m-0" />
                <div className="mt-2">
                  <SideBarMenuItem
                    title={"Project Settings"}
                    image="/images/icons/settings-icon.svg"
                    activeImage="/images/icons/settings-blue-icon.svg"
                    isActive={page === "settings"}
                    path={`/settings/${projectId}`}
                  />
                </div>
              </div>
            )}
            {/* sidebar menu end */}
          </>
        )}
      </div>
    </Layout.Sider>
  );
};

export default SideBar;
