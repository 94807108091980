import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const DiscardModal = ({
  visible,
  onClose,
  onSave,
  onDiscard,
  message = "Do you want to save changes to your project?",
}) => {
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-[#DC6803] out-500-14">Unsaved changes</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/discard-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">{message}</p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Discard"
              className="bg-transparent text-gray-500"
              height="30px"
              onClick={() => onDiscard()}
            />
            <CustomButton text="Save changes" height="30px" onClick={onSave} />
          </div>
        </div>
      }
    />
  );
};

export default DiscardModal;
