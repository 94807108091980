import React from "react";
import Icon from "@ant-design/icons";

const PdfSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"12"}
    height="12"
    fill="none"
    viewBox="0 0 12 12"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.5 1H3a1 1 0 00-1 1v8a1 1 0 001 1h6a1 1 0 001-1V4.5M6.5 1L10 4.5M6.5 1v3.5H10"
    ></path>
  </svg>
);

const PdfFileIcon = (props) => {
  return <Icon component={PdfSvg} {...props} />;
};

export { PdfFileIcon };
