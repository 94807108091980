import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

import {
  updateProjectDetails,
  deleteProject,
  getProjectList,
} from "../../../Core/redux/api/projectAPI";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";

const DiscardDeleteProjectModal = () => {
  const { user } = useAppSelector((state) => state.userDetails);

  // const { startDate, endDate } = projectDetails;

  // const handleUpdate = async (values) => {
  //   const {
  //     startDate,
  //     projectId,
  //     projectName: title,
  //     endDate,
  //     clientName,
  //     clientLocation,
  //     clientImage,
  //     projectImage,
  //   } = values;

  //   dispatch(
  //     updateProjectDetails({
  //       id: projectId,
  //       title,
  //       startDate,
  //       endDate,
  //       clientName,
  //       clientLocation,
  //       projectImage,
  //       clientImage,
  //       // projectImage: projectImage,
  //     })
  //   ).then(() => {
  //     dispatch(getProjectDetatis({ projectId }));
  //   });
  // };

  // const formik = useFormik({
  //   initialValues: {
  //     startDate: startDate ? dayjs(startDate) : null,
  //     endDate: endDate ? dayjs(endDate) : null,
  //   },
  //   enableReinitialize: true,
  //   validationSchema: Yup.object({
  //     startDate: Yup.string()
  //       .required("This field is required")
  //       .typeError("This field is required"),
  //     endDate: Yup.string()
  //       .required("This field is required")
  //       .typeError("This field is required"),
  //   }),
  //   onSubmit: (values) => {
  //     console.log("values", values);
  //     handleUpdate(values);
  //   },
  // });
  const { discardProjectModal } = useAppSelector((state) => state.dashboard);

  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(
      updateDashboard({
        key: "discardProjectModal",
        value: { visible: false, type: discardProjectModal.type, title: " " },
      })
    );
  };

  return (
    <CustomModal
      visible={discardProjectModal.visible}
      width="360px"
      onCancel={() => console.log(status, "status")}
      body={
        <div className="h-[299px]  flex flex-col px-4  py-[15px] gap-y-[17px]">
          <div className="flex   justify-between items-center ">
            <div className="h-5 out-500-14 text-error-700">
              {discardProjectModal.type === "discard"
                ? "Discard project"
                : "Delete project"}
            </div>
            <div className="h-5 w-5" onClick={handleClose}>
              <CrossIcon className={"text-gray-500 cursor-pointer"} />
            </div>
          </div>
          <div className="flex   ">
            <img
              src="/images/v2/dashboard/WarningState_DeletePopUps.svg"
              className="h-[108px]"
            />
          </div>
          <div className="h-[60px] out-300-14 flex text-gray-500">
            {discardProjectModal.type === "discard" ? (
              <div>
                Are you sure you want to discard this project?You can still
                access all the data for
                <span className="out-500-14"> 30 days</span> before it is
                permanently deleted.
              </div>
            ) : (
              "Are you sure you want to permanently delete this project? You will lose access to all the data for this project."
            )}
          </div>
          <div className="h-[30px]  flex items-center justify-end gap-x-2.5 cursor-pointer">
            <div
              className="h-[30px] out-500-14 text-gray-500 flex py-[5px] px-[10px]"
              onClick={handleClose}
            >
              Cancel
            </div>
            <button
              className="h-[30px] flex items-center justify-center out-500-14 text-white bg-error-700 shadow-[0px_1px_2px_rgba(16,24,40,0.05)]  py-[5px] px-[10px] rounded-[4px] "
              onClick={async () => {
                //discarding project=> set isdiscarded=true
                if (discardProjectModal.type === "discard") {
                  dispatch(
                    updateDashboard({
                      key: "discardProjectModal",
                      value: {
                        visible: false,
                        type: discardProjectModal.type,
                        title: discardProjectModal.title,
                        id: discardProjectModal.id,
                      },
                    })
                  );
                  await dispatch(
                    updateProjectDetails({
                      id: discardProjectModal.id,
                      isDiscarded: 1,
                      isPinned: 0,
                    })
                  ).then(() => {
                    dispatch(getProjectList(user.id));
                    dispatch(
                      updateDashboard({
                        key: "alertPopupModal",
                        value: {
                          visible: true,
                          data: {
                            title: "1 Project Discarded",
                            subtitle: discardProjectModal.title,
                            description: "",
                          },
                        },
                      })
                    );
                  });
                } else {
                  //deleting project
                  dispatch(
                    updateDashboard({
                      key: "discardProjectModal",
                      value: {
                        visible: false,
                        type: "delete",
                        title: discardProjectModal.title,
                      },
                    })
                  );
                  dispatch(
                    deleteProject({ projectId: discardProjectModal.id })
                  ).then(() => {
                    dispatch(getProjectList(user.id));
                    dispatch(
                      updateDashboard({
                        key: "alertPopupModal",
                        value: {
                          visible: true,
                          data: {
                            title: "1 Project Deleted",
                            subtitle: discardProjectModal.title,
                            description: "",
                          },
                        },
                      })
                    );
                  });
                }
              }}
            >
              {discardProjectModal.type === "discard" ? "Discard" : "Delete"}
            </button>
          </div>
        </div>
      }
    />
  );
};

export default DiscardDeleteProjectModal;
