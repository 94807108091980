import React from "react";
import Icon from "@ant-design/icons";

const CheckSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.667"
      d="M16.667 5L7.5 14.167 3.333 10"
    ></path>
  </svg>
);

const CheckIcon = (props) => {
  return <Icon component={CheckSvg} {...props} />;
};

export { CheckIcon };
