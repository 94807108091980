import React, { useEffect, useState } from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { Checkbox, Popover } from "antd";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import { getPriorityDetails } from "../../../Core/utils/scheduleUtils";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { getGoalsBySprint } from "../../../Core/redux/api/scheduleAPI";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import { useGetProjectDetails } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";

const FilterLayout = ({
  filterName,
  children,
  footer = false,
  onClear = () => null,
  onClose = () => null,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { projectId, sprintId } = useParams();
  const { filters, sprints } = useAppSelector((state) => state.schedule);
  const [filterOpen, setFilterOpen] = useState(true);

  useEffect(() => {
    console.log("yo FilterLayout mounted");
    return () => {
      console.log("yo FilterLayout unmounted");
    };
  }, []);

  useEffect(() => {
    console.log("filterOpen state changed:", filterOpen);
  }, [filterOpen]);

  const handleClose = () => {
    console.log("yo handleClose triggered, setting filterOpen to false");
    setFilterOpen(false);
    onClose(); // Ensure this doesn't affect filterOpen
  };

  useEffect(() => {
    console.log("yo filterName changed:", filterName);
    setFilterOpen(true); // Set filterOpen to true when filterName changes
  }, [filterName]);

  return (
    <>
      {
        <div
          className="w-[320px] bg-white border border-gray-100 rounded-lg "
          style={{
            boxShadow:
              "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
          }}
        >
          <h3 className="out-300-14 capitalize pt-[14px] pb-2.5 px-[14px] border-b border-gray-200 text-gray-500">
            {filterName}
          </h3>
          <div className="max-h-[304px] overflow-y-auto">{children}</div>

          {footer ? (
            <div className="flex border-t border-gray-200 pt-[2px] pb-[2px] justify-end items-center">
              <CustomButton
                className="bg-transparent out-500-14 text-gray-500"
                text="Clear"
                onClick={() => {
                  onClear();
                  dispatch(
                    getGoalsBySprint({
                      projectId,
                      sprintId: sprintId,
                      phase: filters.phase,
                      assignee: filters.assignee,
                      reporter: filters.reporter,
                      priority: filters.priority,
                      status: filters.status,
                      label: filters.label,
                      type: filters.type,
                      module: "sprints",
                    })
                  );
                }}
              />
              <CustomButton
                className="bg-transparent out-500-14 text-primary-600"
                text="Done"
                onClick={() => {
                  handleClose();
                  const body = document.querySelector("body");
                  // Programmatically trigger a click event on the body
                  const clickEvent = new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                  });

                  body.dispatchEvent(clickEvent);
                  if (sprintId) {
                    queryClient.invalidateQueries([
                      "goalsBySprint",
                      projectId,
                      sprintId,
                    ]);
                    dispatch(
                      getGoalsBySprint({
                        projectId,
                        sprintId: sprintId,
                        phase: filters.phase,
                        assignee: filters.assignee,
                        reporter: filters.reporter,
                        priority: filters.priority,
                        status: filters.status,
                        label: filters.label,
                        type: filters.type,
                        module: "sprints",
                      })
                    );
                  } else {
                    dispatch(
                      getGoalsBySprint({
                        projectId,
                        sprintId: sprintId,
                        phase: filters.phase,
                        assignee: filters.assignee,
                        reporter: filters.reporter,
                        priority: filters.priority,
                        status: filters.status,
                        label: filters.label,
                        type: filters.type,
                        sprint: sprints.map((sprint) => sprint.id),
                        module: "sprints",
                      })
                    );
                    // sprints.map((sprint) =>
                    //   queryClient.invalidateQueries([
                    //     "goalsBySprint",
                    //     projectId,
                    //     sprint.id,
                    //   ])
                    // );
                  }
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        // ) : (
        //   <></>
      }
    </>
  );
};

const PhaseFilter = ({ onClose = () => null }) => {
  const { phases, filters } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const { projectId, sprintId } = useParams();
  const queryClient: any = useQueryClient();

  const selected = filters.phase || [];

  const handleClick = (id) => {
    if (selected.includes(id)) {
      const newStatusFilters = selected.filter((i) => i !== id);
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, phase: newStatusFilters },
        })
      );
    } else {
      const newStatusFilters = [...selected, id];
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, phase: newStatusFilters },
        })
      );
    }
  };

  return (
    <FilterLayout
      filterName={"Phase"}
      onClose={onClose}
      footer={true}
      onClear={() => {
        dispatch(
          updateSchedule({
            key: "filters",
            value: { ...filters, phase: [] },
          })
        );
      }}
    >
      {phases.map((p) => (
        <div
          role="button"
          onClick={() => handleClick(p.projectPhaseId)}
          key={p.id}
          className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
        >
          <div className="flex items-center gap-x-2">
            <PhaseIcon height="16px" width="16px" phaseId={+p.phaseId} />
            <p className="out-300-14 text-gray-900">
              {p.phases} {p.phaseIteration}
            </p>
          </div>
          <Checkbox checked={selected.includes(p.projectPhaseId)} />
        </div>
      ))}
    </FilterLayout>
  );
};

const TypeFilters = ({ onClose = () => null }) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.schedule);
  const typeFilters = filters.type || [];
  const handleClick = (id) => {
    if (typeFilters.includes(id)) {
      const newTypeFilters = typeFilters.filter((i) => i !== id);
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, type: newTypeFilters },
        })
      );
    } else {
      const newTypeFilters = [...typeFilters, id];
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, type: newTypeFilters },
        })
      );
    }
  };

  console.log("im ihere in type");

  return (
    <FilterLayout
      filterName={"Type"}
      footer={true}
      onClose={onClose}
      onClear={() =>
        dispatch(
          updateSchedule({
            key: "filters",
            value: { ...filters, type: [] },
          })
        )
      }
    >
      <div
        role="button"
        onClick={() => handleClick(1)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <div className="flex items-center">
          <img className="" src="/images/v2/schedule/Task.svg" />
          <span className="ml-2 out-300-14 text-gray-900">Task</span>
        </div>
        <Checkbox checked={typeFilters.includes(1)} />
      </div>
      {/* <div
        role="button"
        onClick={() => handleClick(2)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <div className="flex items-center">
          <img className="" src="/images/v2/schedule/meet.svg" />
          <span className="ml-2 out-300-14 text-gray-900">Event</span>
        </div>
        <Checkbox checked={typeFilters.includes(2)} />
      </div> */}
      <div
        role="button"
        onClick={() => handleClick(3)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <div className="flex items-center">
          <img className="" src="/images/v2/schedule/Bug.svg" />
          <span className="ml-2 out-300-14 text-gray-900">Bug</span>
        </div>
        <Checkbox checked={typeFilters.includes(3)} />
      </div>
    </FilterLayout>
  );
};

const PriorityFilters = ({ onClose = () => null }) => {
  const getLable = (priority) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p className="out-300-14 ml-2" style={{ color }}>
          {priorityName}
        </p>
      </div>
    );
  };

  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.schedule);
  const priorityFilters = filters.priority || [];
  const handleClick = (id) => {
    if (priorityFilters.includes(id)) {
      const newPrioeityFilters = priorityFilters.filter((i) => i !== id);
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, priority: newPrioeityFilters },
        })
      );
    } else {
      const newPrioeityFilters = [...priorityFilters, id];
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, priority: newPrioeityFilters },
        })
      );
    }
  };
  return (
    <FilterLayout
      filterName={"Priority"}
      footer={true}
      onClose={onClose}
      onClear={() =>
        dispatch(
          updateSchedule({
            key: "filters",
            value: { ...filters, priority: [] },
          })
        )
      }
    >
      <div
        role="button"
        onClick={() => handleClick(1)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getLable(1)}
        <Checkbox checked={priorityFilters.includes(1)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(2)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getLable(2)}
        <Checkbox checked={priorityFilters.includes(2)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(3)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getLable(3)}
        <Checkbox checked={priorityFilters.includes(3)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(4)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getLable(4)}
        <Checkbox checked={priorityFilters.includes(4)} />
      </div>
    </FilterLayout>
  );
};

const AssigneeFilters = ({ filterName = "assignee", onClose = () => null }) => {
  const { userList } = useAppSelector((state) => state.overview);
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.schedule);
  const assigneeFilters = filters?.[filterName] || [];
  const handleClick = (id) => {
    if (assigneeFilters.includes(id)) {
      const newAssigneeFilters = assigneeFilters.filter((i) => i !== id);
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, [filterName]: newAssigneeFilters },
        })
      );
    } else {
      const newAssigneeFilters = [...assigneeFilters, id];
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, [filterName]: newAssigneeFilters },
        })
      );
    }
  };
  return (
    <FilterLayout
      filterName={filterName}
      footer={true}
      onClose={onClose}
      onClear={() =>
        dispatch(
          updateSchedule({
            key: "filters",
            value: { ...filters, [filterName]: [] },
          })
        )
      }
    >
      {userList.map((user) => (
        <div
          key={user.userId}
          role="button"
          onClick={() => handleClick(user.userId)}
          className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
        >
          <div className="flex items-center">
            <CustomAvatar
              src={user.profilePicture}
              title={getUsersName(user)}
              size={24}
              whiteText
              color={user?.profilePictureColorCode}
              fontSize={"12px"}
            />
            <span className="ml-2 out-300-14 text-gray-900">
              {getUsersName(user)}
            </span>
          </div>
          <Checkbox checked={assigneeFilters.includes(user.userId)} />
        </div>
      ))}
    </FilterLayout>
  );
};

const LabelFilter = ({ onClose = () => null }) => {
  const dispatch = useAppDispatch();
  const { filters, labels } = useAppSelector((state) => state.schedule);
  const labelFilters = filters.label || [];
  const handleClick = (id) => {
    if (labelFilters.includes(id)) {
      const newLabelFilters = labelFilters.filter((i) => i !== id);
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, label: newLabelFilters },
        })
      );
    } else {
      const newLabelFilters = [...labelFilters, id];
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, label: newLabelFilters },
        })
      );
    }
  };
  return (
    <FilterLayout
      filterName={"Label"}
      footer={true}
      onClose={onClose}
      onClear={() =>
        dispatch(
          updateSchedule({
            key: "filters",
            value: { ...filters, label: [] },
          })
        )
      }
    >
      {labels.map((label) => (
        <div
          role="button"
          key={label.id}
          onClick={() => handleClick(`${label.id}`)}
          className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
        >
          <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
          <Checkbox checked={labelFilters.includes(`${label.id}`)} />
        </div>
      ))}
    </FilterLayout>
  );
};

const StatusFilter = ({ onClose = () => null }) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.schedule);
  const statusFilters = filters.status || [];
  const handleClick = (id) => {
    if (statusFilters.includes(id)) {
      const newStatusFilters = statusFilters.filter((i) => i !== id);
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, status: newStatusFilters },
        })
      );
    } else {
      const newStatusFilters = [...statusFilters, id];
      dispatch(
        updateSchedule({
          key: "filters",
          value: { ...filters, status: newStatusFilters },
        })
      );
    }
  };
  return (
    <FilterLayout
      filterName={"Status"}
      onClose={onClose}
      footer={true}
      onClear={() =>
        dispatch(
          updateSchedule({
            key: "filters",
            value: { ...filters, status: [] },
          })
        )
      }
    >
      <div
        role="button"
        onClick={() => handleClick(1)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <CustomBadge
          text="To Do"
          color="#667085"
          className="border border-[#667085]"
          dot
        />
        <Checkbox checked={statusFilters.includes(1)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(2)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <CustomBadge
          text="In Progress"
          color="#165ABF"
          className="border border-[#165ABF]"
          dot
        />
        <Checkbox checked={statusFilters.includes(2)} />
      </div>

      <div
        role="button"
        onClick={() => handleClick(3)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <CustomBadge
          text="Done"
          color="#039855"
          className="border border-[#039855]"
          dot
        />
        <Checkbox checked={statusFilters.includes(3)} />
      </div>
    </FilterLayout>
  );
};

const FilterItem = ({ filterName, filterContent }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      trigger={"click"}
      content={filterContent}
      arrow={false}
      placement="rightBottom"
      onOpenChange={setFilterOpen}
      open={filterOpen}
    >
      <div
        className="cursor-pointer hover:bg-gray-50 px-3.5 py-2.5"
        onClick={() => setFilterOpen(true)}
      >
        <p className="text-gray-900 out-300-14">{filterName}</p>
      </div>
    </Popover>
  );
};

const Filters = ({ onClose = () => null }) => {
  const { projectId } = useParams();

  const { data: projectDetails, isLoading: projectLoading } =
    useGetProjectDetails(projectId);

  console.log("Filters component rendered");

  return (
    <div>
      <FilterLayout filterName={"Filters"} onClose={onClose}>
        <FilterItem
          filterContent={<TypeFilters onClose={onClose} />}
          filterName={"Type"}
        />
        <FilterItem
          filterContent={<AssigneeFilters onClose={onClose} />}
          filterName={"Assignee"}
        />
        <FilterItem
          filterContent={
            <AssigneeFilters filterName="reporter" onClose={onClose} />
          }
          filterName={"Reporter"}
        />
        <FilterItem
          filterContent={<PriorityFilters onClose={onClose} />}
          filterName={"Priority"}
        />
        <FilterItem
          filterContent={<LabelFilter onClose={onClose} />}
          filterName={"Label"}
        />
        <FilterItem
          filterContent={<StatusFilter onClose={onClose} />}
          filterName={"Status"}
        />
        {!projectLoading && projectDetails?.projectDetails?.hasPhases && (
          <FilterItem
            filterContent={<PhaseFilter onClose={onClose} />}
            filterName={"Phase"}
          />
        )}
      </FilterLayout>
    </div>
  );
};

const AppliedFilterItem = ({ filterName, filterCount, filterContent }) => {
  return (
    <Popover
      trigger={"click"}
      content={filterContent}
      arrow={false}
      placement="bottomLeft"
    >
      <button className="flex items-center justify-center px-2.5 py-[4px] gap-x-2  rounded border border-gray-200 bg-white">
        <p className="out-500-14 text-gray-700">
          {filterName} : {filterCount}
        </p>
        <ChevronIcon className=" text-gray-700" />
      </button>
    </Popover>
  );
};

export const AppliedFilters = ({ style = {} }) => {
  const dispatch = useAppDispatch();
  const { filters, sprints } = useAppSelector((state) => state.schedule);
  const { projectId, sprintId } = useParams();
  const typeFilters = filters.type || [];
  const statusFilters = filters.status || [];
  const labelFilters = filters.label || [];
  const assigneeFilters = filters.assignee || [];
  const priorityFilters = filters.priority || [];
  const reporterFilter = filters.reporter || [];
  const allFilters = [
    ...typeFilters,
    ...statusFilters,
    ...labelFilters,
    ...assigneeFilters,
    ...priorityFilters,
    ...reporterFilter,
  ];
  return (
    <div className="flex gap-x-3 items-center" style={style}>
      {allFilters.length > 0 && (
        <CustomButton
          text="Reset All filters"
          height="30px"
          className="bg-transparent text-gray-500 out-500-14"
          onClick={() => {
            if (sprintId) {
              dispatch(resetSchedule(["filters"]));
              dispatch(
                getGoalsBySprint({
                  projectId,
                  sprintId: sprintId,
                  module: "sprints",
                })
              );
            } else {
              dispatch(resetSchedule(["filters"]));
              dispatch(
                getGoalsBySprint({
                  projectId,
                  sprint: sprints.map((sprint) => sprint.id),
                  module: "sprints",
                })
              );
            }
          }}
        />
      )}
      {typeFilters.length > 0 && (
        <AppliedFilterItem
          filterCount={typeFilters.length}
          filterName={"Type"}
          filterContent={<TypeFilters />}
        />
      )}
      {assigneeFilters.length > 0 && (
        <AppliedFilterItem
          filterCount={assigneeFilters.length}
          filterName={"Assignee"}
          filterContent={<AssigneeFilters />}
        />
      )}
      {reporterFilter.length > 0 && (
        <AppliedFilterItem
          filterCount={reporterFilter.length}
          filterName={"Reporter"}
          filterContent={<AssigneeFilters filterName="reporter" />}
        />
      )}
      {priorityFilters.length > 0 && (
        <AppliedFilterItem
          filterCount={priorityFilters.length}
          filterName={"Priority"}
          filterContent={<PriorityFilters />}
        />
      )}
      {labelFilters.length > 0 && (
        <AppliedFilterItem
          filterCount={labelFilters.length}
          filterName={"Label"}
          filterContent={<LabelFilter />}
        />
      )}
      {statusFilters.length > 0 && (
        <AppliedFilterItem
          filterCount={statusFilters.length}
          filterName={"Status"}
          filterContent={<StatusFilter />}
        />
      )}
    </div>
  );
};

export default Filters;
