import React from "react";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import { useAppSelector } from "../../Core/redux/hooks";
import ChatWindow from "../Conversations/ChatWindow";

const QuickChat = ({ onBackClick, socket }) => {
  const { currentThread } = useAppSelector((state) => state.conversations);
  const isThread = currentThread.type === "group";
  return (
    <div className="mt-2 flex flex-col h-[calc(100vh-110px)]">
      <div className="flex py-2.5 px-[17px] gap-x-3 border-b border-gray-200">
        <button onClick={onBackClick}>
          <ChevronIcon className="text-gray-500 rotate-90" />
        </button>
        <div className="flex items-center gap-x-3 h-[24px] ">
          <div className="relative">
            <CustomAvatar
              title={
                isThread
                  ? currentThread.threadName
                  : `${currentThread.firstname} ${currentThread.lastname}`
              }
              size={32}
              src={
                isThread
                  ? currentThread.threadName
                  : currentThread.profilePicture
              }
              userId={currentThread.userId}
              badge
            />
            {isThread && (
              <div className="absolute bottom-0 right-0">
                {" "}
                <img
                  className="h-[14px] w-[14px]"
                  src="/images/v2/conversations/group-placeholder.svg"
                />
              </div>
            )}
          </div>
          <div className="out-500-14 text-black ">
            {isThread
              ? currentThread.threadName
              : `${currentThread.firstname} ${currentThread.lastname}`}
          </div>
        </div>
      </div>

      <ChatWindow socket={socket} view="quickChat" />
    </div>
  );
};

export default QuickChat;
