const getWelcomeCardDetails = (time) => {
  let imgUrl: string;
  let wish: string;

  switch (time) {
    case "6 am":
      imgUrl = "/images/v2/dashboard/6-am.svg";
      wish = "morning";
      break;
    case "7 am":
      imgUrl = "/images/v2/dashboard/6-am.svg";
      wish = "morning";

      break;

    case "8 am":
      imgUrl = "/images/v2/dashboard/10-am.svg";
      wish = "morning";

      break;

    case "9 am":
      imgUrl = "/images/v2/dashboard/9-am.svg";
      wish = "morning";

      break;

    case "10 am":
      imgUrl = "/images/v2/dashboard/10-am.svg";
      wish = "morning";

      break;
    case "11 am":
      imgUrl = "/images/v2/dashboard/10-am.svg";
      wish = "morning";

      break;

    case "12 pm":
      imgUrl = "/images/v2/dashboard/12-pm.svg";
      wish = "afternoon";

      break;
    case "1 pm":
      imgUrl = "/images/v2/dashboard/12-pm.svg";
      wish = "afternoon";

      break;

    case "2 pm":
      imgUrl = "/images/v2/dashboard/2-pm.svg";
      wish = "afternoon";

      break;

    case "3 pm":
      imgUrl = "/images/v2/dashboard/2-pm.svg";
      wish = "afternoon";

      break;

    case "4 pm":
      imgUrl = "/images/v2/dashboard/4-pm.svg";
      wish = "afternoon";

      break;
    case "5 pm":
      imgUrl = "/images/v2/dashboard/4-pm.svg";
      wish = "afternoon";

      break;

    case "6 pm":
      imgUrl = "/images/v2/dashboard/4-pm.svg";
      wish = "evening";

      break;
    case "7 pm":
      imgUrl = "/images/v2/dashboard/4-pm.svg";
      wish = "evening";

      break;
    case "8 pm":
      imgUrl = "/images/v2/dashboard/8-pm.svg";
      wish = "evening";

      break;
    case "9 pm":
      imgUrl = "/images/v2/dashboard/8-pm.svg";
      wish = "evening";

      break;
    case "10 pm":
      imgUrl = "/images/v2/dashboard/10-pm.svg";
      wish = "evening";

      break;
    case "11 pm":
      imgUrl = "/images/v2/dashboard/10-pm.svg";
      wish = "evening";

      break;
    case "12 am":
      imgUrl = "/images/v2/dashboard/12-am.svg";
      wish = "evening";

      break;
    case "1 am":
      imgUrl = "/images/v2/dashboard/1-am.svg";
      wish = "evening";

      break;
    // case "5 pm " :        imgUrl= "/images/v2/dashboard/82.svg";

    // case "6 pm":        imgUrl= "/images/v2/dashboard/71.svg";

    // case "7 pm":        imgUrl= "/images/v2/dashboard/71.svg";

    // case "8 pm":        imgUrl= "/images/v2/dashboard/71.svg";

    // case "9 pm":        imgUrl= "/images/v2/dashboard/71.svg";

    // case "10 pm":        imgUrl= "/images/v2/dashboard/71.svg";

    // case "11 pm":        imgUrl= "/images/v2/dashboard/71.svg";

    default:
      imgUrl = "/images/v2/dashboard/6-am.svg";
      break;
  }

  return { imgUrl, wish };
};
export default getWelcomeCardDetails;
