import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch } from "../../../Core/redux/hooks";

import { useParams } from "react-router-dom";

import { getReleaseVersions } from "../../../Core/redux/api/scheduleAPI";
import { client } from "../../../Core/utils/axiosClient";

const DeleteReleaseVersionModal = ({
  visible,
  onClose,
  releaseNoteDetails,
}) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const onDelete = async () => {
    try {
      await client.delete("/schedule/release-version", {
        params: { versionId: releaseNoteDetails.id },
      });

      dispatch(getReleaseVersions({ projectId }));
      onClose();
    } catch (e) {
      console.log("Something went wrong while creating a new label");
    } finally {
      onClose();
    }
  };
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Delete Version</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to delete this version? <br /> This action
            cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Delete"
              height="30px"
              width="101px"
              className="bg-error-700 text-white"
              onClick={onDelete}
            />
          </div>
        </div>
      }
    />
  );
};

export default DeleteReleaseVersionModal;
