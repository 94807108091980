import React from "react";
import Icon from "@ant-design/icons";

const DCFSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0_4327_2457)">
      <g>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.21"
          d="M18.43 5.596c.017.017.031.042.036.076.084.883.186 2.7-.104 4.656-.291 1.97-.967 3.994-2.37 5.38-1.116 1.042-2.561 1.632-4.024 1.655-1.463.022-2.829-.524-3.818-1.513-.989-.989-1.535-2.355-1.513-3.818.023-1.462.612-2.906 1.654-4.023 1.423-1.42 3.457-2.091 5.42-2.373 1.954-.281 3.76-.165 4.643-.075.035.004.06.018.077.035z"
        ></path>
        <g>
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.21"
            d="M10.08 10.244L8.775 11.55a.635.635 0 00-.17.59l.521 2.047"
          ></path>
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.21"
            d="M14.656 12.565l-3.016.018"
          ></path>
          <path
            fill="#165ABF"
            d="M10.008 10.315a1.03 1.03 0 101.457-1.457 1.03 1.03 0 00-1.457 1.457z"
          ></path>
          <path
            fill="#165ABF"
            d="M13.949 10.017a1.03 1.03 0 101.457-1.457 1.03 1.03 0 00-1.457 1.457z"
          ></path>
          <path
            fill="#165ABF"
            d="M14.438 13.239a1.03 1.03 0 101.457-1.457 1.03 1.03 0 00-1.457 1.457z"
          ></path>
          <path
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeWidth="1.21"
            d="M14.6 9.292l-6.357 6.357"
          ></path>
        </g>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1.21"
          d="M9.348 14.481L5.96 17.868"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4327_2457">
        <path
          fill="#fff"
          d="M0 0H14.52V19.36H0z"
          transform="rotate(45 6.83 16.562)"
        ></path>
      </clipPath>
    </defs>
  </svg>
);

const DCFIcon = (props) => <Icon component={DCFSvg} {...props} />;

export { DCFIcon };
