import React from "react";
import { getPriorityDetails } from "../../../Core/utils/scheduleUtils";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";

const getPriorityLabel = (priority) => {
  const { color, priorityName } = getPriorityDetails(priority);
  return (
    <div className="flex items-center">
      <PriorityFlag
        style={{
          color,
        }}
      />
      <p className="ml-2 out-300-14" style={{ color }}>
        {priorityName}
      </p>
    </div>
  );
};

export default getPriorityLabel;
