import React, { useEffect, useRef, useState } from "react";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import { PhaseIcon } from "../../Core/svgV2/PhaseIcon";
import { Avatar, Tooltip } from "antd";
import dayjs from "dayjs";

export const ProjectCard = ({ project }) => {
  const progress = Math.round(project?.progress || 0);

  // const PhaseIconGroup = ({ phases = [], maxCount = 4 }) => {
  //   const displayedPhases = phases.slice(0, maxCount); // Phases to display
  //   const overflowCount = phases.length - maxCount; // Count of hidden phases

  //   return (
  //     <div className="relative flex">
  //       {/* Stack phases one on top of the other with proper spacing */}
  //       {displayedPhases.map((phase, index) => (
  //         <Tooltip
  //           title={<div>{phase?.name}</div>}
  //           placement="bottom"
  //           key={index}
  //         >
  //           <div
  //             className="absolute"
  //             style={{
  //               left: `${index * 16}px`, // Adjust horizontal spacing between icons
  //               zIndex: displayedPhases.length - index,
  //             }}
  //           >
  //             <PhaseIcon phaseId={phase?.phaseId} reportPhases={true} />
  //           </div>
  //         </Tooltip>
  //       ))}

  //       {/* Show remaining phases count */}
  //       {overflowCount > 0 && (
  //         <div
  //           className="absolute flex items-center justify-center text-xs font-medium bg-gray-200 rounded-full border border-gray-300"
  //           style={{
  //             left: `${maxCount * 16}px`, // Position the +X indicator after the last visible phase
  //             width: "24px",
  //             height: "24px",
  //             zIndex: 0,
  //           }}
  //         >
  //           +{overflowCount}
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  const tooltipText = project?.phases?.map((phase) => phase?.name).join(", ");
  return (
    <div className="flex flex-col gap-4 border pt-4 w-full h-full rounded-xl bg-white">
      <div className="flex justify-between px-4">
        <div>
          <div className="out-500-10 text-gray-400 mb-0.5 uppercase">
            {project?.title || "Project Name"}
          </div>
          <Tooltip
            title={<div className="">{project?.range}</div>}
            placement={"bottom"}
          >
            <div className="cursor-pointer">
              <span className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px] mr-1">
                {progress}%
              </span>
              <span className="text-[#667085]">Complete</span>
            </div>
          </Tooltip>
        </div>
        <div>
          <CustomAvatar
            src={project?.projectImage}
            title={project?.title}
            size={24}
            style={{ border: "1px solid #edf3f9", borderRadius: "50%" }}
          />
        </div>
      </div>

      {/* Progress Bar */}
      <div className="bg-[#F7FAFD] rounded-xl">
        <div className="relative w-full h-0.5 bg-gray-100 overflow-hidden">
          <div
            className="absolute top-0 left-0 h-full bg-primary-600"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <div className="flex justify-between py-3 px-4 bg-[#F7FAFD] rounded-xl">
          {!project?.hasPhases ? (
            <div className="out-300-12 text-gray-500"></div>
          ) : project?.status !== "Completed" ? (
            <>
              <div className="out-300-12 text-gray-500">Active Phases</div>
              <Tooltip title={<div>{tooltipText}</div>} placement={"bottom"}>
                <div className="flex items-center">
                  <Avatar.Group
                    maxCount={project?.phases?.length}
                    size={12}
                    maxStyle={{
                      display: "none",
                    }}
                  >
                    {project?.phases?.map((phase, index) => (
                      <div className="mr-0.5" key={index}>
                        <PhaseIcon
                          key={index}
                          phaseId={phase?.phaseId}
                          reportPhases={true}
                        />
                      </div>
                    ))}
                  </Avatar.Group>
                </div>
              </Tooltip>
            </>
          ) : (
            <div className="text-[#1659bf] out-500-12">
              Completed on{" "}
              {project?.endDate
                ? dayjs(project.endDate).format("MMM DD YYYY")
                : "N/A"}
            </div>
          )}

          {/* <div className="relative flex items-center">
              {" "}
              <PhaseIconGroup phases={project.phases} />
            </div> */}
        </div>
      </div>
    </div>
  );
};

const CustomCarousel = ({ projects = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const itemsPerPage = 4;

  // Total number of slides/pages
  const totalSlides = Math.ceil(projects.length / itemsPerPage);

  // Timeout reference to debounce scroll events
  let scrollTimeout = null;

  // Scroll Handler
  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    scrollTimeout = setTimeout(() => {
      const container = carouselRef.current;
      if (container) {
        const scrollPosition = container.scrollLeft;
        const containerWidth = container.offsetWidth;

        // Calculate visible items
        const visibleItems = Math.ceil(
          scrollPosition / (containerWidth / itemsPerPage)
        );

        // Calculate the new page index
        const newIndex = Math.ceil(visibleItems / itemsPerPage);

        // Only update the state when necessary to avoid unnecessary re-renders
        if (newIndex !== currentIndex) {
          setCurrentIndex(newIndex);
        }
      }
    }, 100); // Set a slight delay to debounce the scroll event
  };

  // Attach scroll listener
  useEffect(() => {
    const container = carouselRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [currentIndex]);

  // Next Slide Handler
  const nextSlide = () => {
    const container = carouselRef.current;
    if (container && currentIndex < totalSlides - 1) {
      container.scrollBy({ left: container.offsetWidth, behavior: "smooth" });
    }
  };

  // Previous Slide Handler
  const prevSlide = () => {
    const container = carouselRef.current;
    if (container && currentIndex > 0) {
      container.scrollBy({ left: -container.offsetWidth, behavior: "smooth" });
    }
  };

  // Go to Specific Slide Handler
  const goToSlide = (index) => {
    const container = carouselRef.current;
    if (container) {
      const newPosition = index * container.offsetWidth;
      container.scrollTo({ left: newPosition, behavior: "smooth" });
      // Update index right away to avoid flicker
      setCurrentIndex(index);
    }
  };

  return (
    <div className="relative w-full">
      {/* Project Cards */}
      <div
        ref={carouselRef}
        className="flex overflow-x-scroll scroll-smooth no-scrollbar"
        style={{
          scrollSnapType: "x mandatory",
        }}
      >
        {projects?.map((project, index) => (
          <div
            key={project.id}
            className="px-2"
            style={{
              flex: `0 0 ${100 / itemsPerPage}%`, // Dynamically set the width based on items per page
              scrollSnapAlign: "start",
            }}
          >
            <ProjectCard project={project} />
          </div>
        ))}
      </div>

      {/* Navigation Controls */}
      <div className="flex justify-center items-center mt-4 gap-2">
        {/* Previous Arrow */}
        <button
          onClick={prevSlide}
          className={`${
            currentIndex === 0
              ? "cursor-not-allowed text-gray-400"
              : "text-gray-700"
          }`}
          disabled={currentIndex === 0}
        >
          {projects?.length > 4 ? <ChevronIcon className="rotate-90" /> : null}
        </button>

        {/* Dots */}
        {projects?.length > 4 &&
          Array.from({ length: totalSlides }).map((_, index) => (
            <svg
              key={index}
              onClick={() => goToSlide(index)}
              width={currentIndex === index ? "8" : "6"} // Set selected circle size to 8
              height={currentIndex === index ? "8" : "6"}
              className={`transition-colors duration-300 cursor-pointer ${
                currentIndex === index ? "fill-primary-600" : "fill-gray-400"
              }`}
              viewBox={currentIndex === index ? "0 0 8 8" : "0 0 6 6"} // Match viewBox to dimensions
              overflow="visible"
            >
              <circle
                cx="50%"
                cy="50%"
                r={currentIndex === index ? "4" : "3"}
              />
            </svg>
          ))}

        {/* Next Arrow */}
        <button
          onClick={nextSlide}
          className={`${
            currentIndex === totalSlides - 1
              ? "cursor-not-allowed text-gray-400"
              : "text-gray-700"
          }`}
          disabled={currentIndex === totalSlides - 1}
        >
          {projects?.length > 4 ? <ChevronIcon className="-rotate-90" /> : null}
        </button>
      </div>
    </div>
  );
};

export default CustomCarousel;
