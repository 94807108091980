import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../api/notificationAPI";

const initialState = {
  loading: false,
  success: {
    message: null,
  },
  error: {
    message: "",
  },
  status: "idle",
  endPoint: "",
  activeRequests: 0,
  from: "",
  errorModal: {
    show: false,
    text: "",
  },
  unreadConversationCount: 0,
  dashboardCount: false,
};

const URLS_FOR_WHICH_DONT_SHOW_LOADER = [
  "/conversation/threads/members",
  // "conversation/private-chat",
  // "conversation/private-message",
  "/conversation/private-chat",
  "/conversation/unread-count-total",
  "/notifications",
  "/notifications/read",
  "/conversation/scrape",
];

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    resetToInitialGlobal: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    // setUnreadConversationCount:(state , action)
    updateGlobal: (state, action) => {
      console.log(action.payload, "setUnreadConversationCount");
      state.unreadConversationCount = action.payload.value
        ? action.payload.value
        : 0;
    },
    updateGlobalKey: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setRequest: (state, action) => {
      if (
        !URLS_FOR_WHICH_DONT_SHOW_LOADER.includes(
          action.payload.endPoint?.split("?")[0]
        )
      ) {
        state.loading = true;
        state.error = initialState.error;
        state.activeRequests++;
        state.endPoint = action.payload.endPoint;
        state.status = "fecthing";
      }
    },
    setFulfilled: (state, action) => {
      state.loading = false;
      state.error = initialState.error;
      if (state.activeRequests > 0) {
        state.activeRequests--;
      }

      state.status = "fulfilled";
      // if(action.payload.message){
      //   state.success.message = action.payload.message
      // }
    },
    setRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;

      state.status = "rejected";
      if (state.activeRequests > 0) {
        state.activeRequests--;
      }
      state.from = action.payload.from;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.status = "rejected";
    },
    resetToInitial: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    setSuccess: (state, action) => {
      console.log("ONSUCCESS: ", action.payload);
      state.success = action.payload;
      state.status = "fulfilled";
    },
    updateDashboardCount: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setRequest,
  setFulfilled,
  setRejected,
  setError,
  setSuccess,
  resetToInitial,
  updateDashboardCount,
  resetToInitialGlobal,
  updateGlobal,
  updateGlobalKey,
} = globalSlice.actions;

export default globalSlice.reducer;
