import React from "react";

const ParseMentions = ({
  value = "",
  className = "bg-primary-500",
  textClassName = "out-300-14 text-gray-700 leading-[20px] ",
  clickDisable = false,
}) => {
  let newMessage = value;

  // Match URLs using regular expression
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  newMessage = newMessage.replace(urlRegex, (url) => {
    return `<${
      clickDisable ? "span" : "a"
    } class="text-primary-600 underline  out-300-14" href="${url}" target="_blank">${url}</${
      clickDisable ? "span" : "a"
    }>`;
  });

  // Parse mentions
  newMessage = newMessage
    .split(`@@@__`)
    .join(
      ` <span class="bg-[#2e90fa] inline-block  w-max  px-2 rounded-lg whitespace-nowrap truncate out-500-12 leading-[18px] text-white" data-user-id=`
    );
  newMessage = newMessage.split(`^^__`).join('">@');
  newMessage = newMessage.split(`@@@^^^`).join("</span>");

  function parseMentionsMobile(message) {
    const regex = /@\[([\w\s]+)\]\((\d+)\)/g;
    const parsedMessage = message.replace(
      regex,
      '<span class="bg-[#2e90fa] inline-block w-max px-2 rounded-lg whitespace-nowrap truncate out-500-12 leading-[18px] text-white" data-user-id="$2">@$1</span>'
    );
    return parsedMessage;
  }

  newMessage = parseMentionsMobile(newMessage);

  newMessage = newMessage.replace(/\n\r?/g, `<br />`);

  return (
    <p
      className={textClassName}
      dangerouslySetInnerHTML={{ __html: newMessage }}
    ></p>
  );
};

export default React.memo(ParseMentions);
