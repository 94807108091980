import React, { useEffect } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";

import CustomDatePicker from "../../../Core/CommonV2/CustomDatePicker";

import ClockIcon from "../../../Core/svgV2/Clock";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import {
  createSprint,
  getGoalsBySprint,
  getSprintsByPhase,
  updateSprint,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { resetSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useQueryClient } from "react-query";

const ConfirmSprintDates = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();
  const { projectId } = useParams();
  const { confirmDatesModal, phases } = useAppSelector(
    (state) => state.schedule
  );
  const { user } = useAppSelector((state) => state.userDetails);

  const { projectDetails } = useAppSelector((state) => state.overview);

  const { formState, reset, handleSubmit, setValue, control } = useForm({
    values: {
      sprintName: "",
      startDate: null,
      endDate: null,
    },
    defaultValues: {
      sprintName: confirmDatesModal?.sprint
        ? confirmDatesModal?.sprint.name
        : "",
      startDate: confirmDatesModal?.sprint
        ? dayjs(confirmDatesModal?.sprint.startDate)
        : "",
      endDate: confirmDatesModal?.sprint
        ? dayjs(confirmDatesModal?.sprint.endDate)
        : "",
    },
  });

  useEffect(() => {
    {
      if (confirmDatesModal?.visible) {
        const onGoingPhases = phases.filter(({ isOngoing }) => isOngoing === 1);

        if (
          projectDetails.hasPhases &&
          onGoingPhases.length === 0 &&
          confirmDatesModal?.phaseId === ""
        ) {
          message.error({
            content: (
              <span className="out-400-14 text-gray-900 cursor-pointer">
                No ongoing phases. Please start the phase first.
              </span>
            ),
            className: "custom-message",
            style: {
              borderRadius: "10px",
            },
          });
          reset();
          onClose();
        }
      }
      if (confirmDatesModal?.visible) {
        setValue(
          "sprintName",
          confirmDatesModal?.sprint ? confirmDatesModal?.sprint.name : ""
        );
        setValue(
          "startDate",
          confirmDatesModal?.sprint
            ? dayjs(confirmDatesModal?.sprint.startDate)
            : ""
        );
        setValue(
          "endDate",
          confirmDatesModal?.sprint
            ? dayjs(confirmDatesModal?.sprint.endDate)
            : ""
        );
      }
    }
  }, [confirmDatesModal]);

  const onSubmit = async (data) => {
    try {
      await dispatch(
        updateSprint({
          sprintId: confirmDatesModal?.sprint.id,
          title: data.sprintName,
          startDate: data.startDate.format("YYYY-MM-DD"),
          endDate: data.endDate.format("YYYY-MM-DD"),
        })
      );

      ///

      const payload = {
        sprintId: confirmDatesModal?.sprint.id,
        isCompleted: 1,

        isOngoing: 0,
        status: 3,
        newSprintId: confirmDatesModal?.newValue,
        endDate: data.endDate.format("YYYY-MM-DD"),
        // swimlaneId: swimlanes.find((s) => s.statusId == 3)?.id,
      };
      await dispatch(updateSprint(payload));
      await dispatch(
        getSprintsByPhase({
          projectId,
        })
      );

      onClose();
      dispatch(resetSchedule(["completeSprintModal"]));
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `${confirmDatesModal?.sprint.name} completed`,
              subtitle: `Completed Sprint - ${confirmDatesModal?.sprint.name}`,
              description: "",
            },
          },
        })
      );
      dispatch(resetSchedule(["confirmDatesModal"]));
    } catch (e) {
      console.log(e);
    } finally {
      const sprintsGetPayload: any = { projectId };
      queryClient.invalidateQueries([
        "goalsBySprint",
        projectId,
        +confirmDatesModal?.sprint.id,
      ]);
      queryClient.invalidateQueries(["sprintsByPhase", projectId]);
      queryClient.invalidateQueries(["activeSprint", projectId]);
      dispatch(getSprintsByPhase(sprintsGetPayload));
      reset();
      onClose();
    }
  };
  console.log(dayjs().format(), "date toay");

  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => onClose()}
      body={
        <div className="py-3 px-4">
          <div>
            <h3 className="out-500-14 text-black capitalize">
              Confirm Sprint Dates
            </h3>

            <div className="pt-4">
              <Controller
                control={control}
                name="sprintName"
                rules={{ required: "Please Add the Sprint Name" }}
                render={({ field }) => (
                  <CustomInputBox
                    {...field}
                    className="w-full"
                    placeholder="Sprint Name"
                    formState={formState}
                  />
                )}
              />
            </div>
            <div className="pt-4">
              <Controller
                control={control}
                name="startDate"
                rules={{ required: "Please Add Start Date" }}
                render={({ field }) => (
                  <CustomDatePicker
                    {...field}
                    className="w-full  bg-transparent rounded-[4px] border-gray-200"
                    format="DD MMM YYYY"
                    placeholder="Start date"
                    suffixIcon={<></>}
                    formState={formState}
                    disabledDate={(current) =>
                      confirmDatesModal?.sprint
                        ? dayjs(current).isAfter(
                            confirmDatesModal?.sprint.endDate
                          )
                        : dayjs(current).isBefore(projectDetails.startDate) ||
                          current.isAfter(projectDetails.endDate)
                    }
                  />
                )}
              />
            </div>
            <div className="pt-4">
              <Controller
                control={control}
                name="endDate"
                rules={{
                  required: "Please Add End Date",
                  validate: {
                    required: (value) => {
                      console.log("validate function called"); // Log when the validate function is called
                      if (dayjs(value).isAfter(dayjs().format())) {
                        return "Please ensure the end date is on or before current date";
                      } else {
                        return true;
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <CustomDatePicker
                    {...field}
                    className="w-full  bg-transparent rounded-[4px] border-gray-200"
                    format="DD MMM YYYY"
                    placeholder="End date"
                    suffixIcon={<></>}
                    formState={formState}
                    disabledDate={(current) =>
                      confirmDatesModal?.sprint
                        ? dayjs(current).isBefore(
                            confirmDatesModal?.sprint.startDate
                          ) || dayjs(current).isAfter(dayjs().format())
                        : dayjs(current).isBefore(projectDetails.startDate) ||
                          current.isAfter(projectDetails.endDate)
                    }
                  />
                )}
              />
            </div>
            {projectDetails.hasPhases && (
              <div className="mt-4">
                <h4 className="out-500-14 text-black">Ongoing phases</h4>

                {phases
                  .filter(({ isOngoing }) => isOngoing === 1)
                  .map((item) => (
                    <div className="mt-3 flex justify-between" key={item.id}>
                      <div className="flex ">
                        <PhaseIcon
                          phaseId={+item.phaseId}
                          height="24"
                          width="24"
                        />
                        <p className="out-300-14 text-gray-900 ml-2">
                          {item.phases}
                        </p>
                      </div>
                      <div className="flex ">
                        <ClockIcon className="text-gray-500" />
                        <p className="out-300-14 text-gray-500 ml-2">
                          {dayjs(item.startDate).format("DD MMM")} -{" "}
                          {dayjs(item.endDate).format("DD MMM")}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="flex mt-[33px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text={"Confirm"}
              height="30px"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      }
    />
  );
};

export default ConfirmSprintDates;
