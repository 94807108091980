import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../../Core/CommonV2/CustomButton";
import { updateMyBoard } from "../../../../Core/redux/slices/myBoard.slice";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import { client } from "../../../../Core/utils/axiosClient";
import { getMyBoardTasks } from "../../../../Core/redux/api/myBoardAPI";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../../Core/constants";
import { updateDashboard } from "../../../../Core/redux/slices/dashboard.slice";

const CollabRequestModal = () => {
  const dispatch = useAppDispatch();
  const { projectId, taskId, subtaskId } = useParams();
  const { collabRequestModal } = useAppSelector((state) => state.myBoard);

  const { user } = useAppSelector((state) => state.userDetails);

  const navigate = useNavigate();

  const handleDecline = async () => {
    const { data } = await client.post("/my-board/decline-collab-request", {
      id: collabRequestModal.taskId,
    });
  };
  const handleAccept = async () => {
    console.log(collabRequestModal, "cool collls");
    const { data } = await client.post("/my-board/accept-collab-request", {
      id: collabRequestModal.taskId,
    });
    dispatch(getMyBoardTasks({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET }));
    navigate(`/my-board`);

    dispatch(
      updateDashboard({
        key: "alertPopupModal",
        value: {
          visible: true,
          data: {
            title: `Accepted Collaboration Request`,
            subtitle: `You are now collaborating with ${collabRequestModal?.sender} on`,
            description: `${collabRequestModal?.taskName}`,
          },
        },
      })
    );
    dispatch(
      updateMyBoard({
        key: "collabRequestModal",
        value: {
          ...collabRequestModal,
          visible: null,
          taskId: null,
          taskName: null,
          sender: null,
        },
      })
    );
  };
  return (
    <CustomModal
      visible={collabRequestModal?.visible}
      width="360px"
      onCancel={() => {
        dispatch(
          updateMyBoard({
            key: "collabRequestModal",
            value: {
              ...collabRequestModal,
              visible: null,
              taskId: null,
              taskName: null,
              sender: null,
            },
          })
        );
      }}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-primary-700 out-500-14">
              Accept Collaboration Request?
            </p>
            <button
              onClick={() => {
                dispatch(
                  updateMyBoard({
                    key: "collabRequestModal",
                    value: {
                      ...collabRequestModal,
                      visible: null,
                      taskId: null,
                      taskName: null,
                      sender: null,
                    },
                  })
                );
              }}
            >
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            {collabRequestModal?.sender} invited you to collaborate on task
            &quot;
            {collabRequestModal?.taskName}”,
            <br /> would you like to accept?
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Decline"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => {
                handleDecline();
                dispatch(
                  updateMyBoard({
                    key: "collabRequestModal",
                    value: {
                      ...collabRequestModal,
                      visible: null,
                      taskId: null,
                      taskName: null,
                      sender: null,
                    },
                  })
                );
              }}
            />
            <CustomButton
              text="Accept"
              height="30px"
              width="101px"
              className="bg-primary-700 text-white"
              onClick={handleAccept}
            />
          </div>
        </div>
      }
    />
  );
};

export default CollabRequestModal;
