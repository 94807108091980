import { Avatar, Drawer, Tooltip } from "antd";
import React from "react";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import dayjs from "dayjs";
import { updateConversationsMultiple } from "../../../Core/redux/slices/conversations.slice";

const GroupDetailsDrawer = ({ open, onClose, details }) => {
  const { threadMembers } = useAppSelector((state) => state.conversations);
  const dispatch = useAppDispatch();
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { projectList } = useAppSelector((state) => state.projects);

  const handleEditThread = () => {
    dispatch(
      updateConversationsMultiple([
        { key: "editThread", value: true },
        { key: "newGroupModal", value: true },
        { key: "editThreadDetails", value: details },
      ])
    );
    onClose();
  };

  const getProjectName = () => {
    const project = projectList.find((p) => p.id === details.projectId);

    return project?.title || "--";
  };

  console.log(details, "check details for drawer");
  return (
    <Drawer
      title={null}
      closable={false}
      placement="right"
      open={open}
      width={"318px"}
    >
      <div className="p-4 flex flex-col h-full relative">
        <div className="flex-1">
          <button onClick={onClose} className="absolute top-4">
            <CrossIcon className="text-gray-700" />
          </button>
          <div className="flex  w-full justify-center flex-col items-center">
            <div className="relative">
              <CustomAvatar
                size={24}
                title={details.threadName}
                src={details.threadProfilePicture}
                whiteText
                color={details.threadProfilePictureColorCode}
              />
              <div className="absolute bottom-0 right-0">
                {" "}
                <img
                  className="h-2.5 w-2.5"
                  src="/images/v2/conversations/group-placeholder.svg"
                />
              </div>
            </div>
            <p className="out-500-14 mt-[6px]">{details.threadName}</p>
          </div>
          <div className="mt-8">
            <p className="out-500-14 text-black">
              Collaborators ({threadMembers?.length})
            </p>
            <div className="mt-4">
              <Avatar.Group
                maxCount={4}
                maxPopoverTrigger="click"
                size={24}
                maxStyle={{
                  color: "#165ABF",
                  backgroundColor: "#EFF8FF",
                  cursor: "pointer",
                }}
              >
                {threadMembers?.map((member) => {
                  return (
                    <Tooltip
                      key={member.title}
                      title={`${member.firstname} ${member.lastname}`}
                      placement="bottom"
                    >
                      {/* <Avatar
                      style={{ backgroundColor: "#87d068" }}
                      icon={<CustomAvatar title={member.title} />}
                    /> */}
                      <div>
                        {" "}
                        <CustomAvatar
                          title={`${member.firstname} ${member.lastname}`}
                          src={member.profilePicture}
                          size={32}
                          whiteText
                          fontSize={"10px"}
                          color={member.profilePictureColorCode}
                        />
                      </div>
                      {/* <Avatar  /> */}
                    </Tooltip>
                  );
                })}
              </Avatar.Group>
            </div>
            <div className="mt-4">
              <CustomButton
                text="Manage members"
                className="text-primary-600 bg-primary-50"
                width="133px"
                onClick={handleEditThread}
              />
            </div>
          </div>

          <div className="mt-4">
            <p className="out-500-14 text-black">Details</p>
          </div>
          <div className="mt-4">
            <p className="out-300-12 text-gray-500">Company</p>
            <p className="out-500-12 text-black mt-2">
              {tenantDetails?.tenantName}
            </p>
          </div>
          <div className="mt-4">
            <p className="out-300-12 text-gray-500">Project</p>
            <p className="out-500-12 text-black mt-2">{getProjectName()}</p>
          </div>
          <div className="mt-4">
            <p className="out-300-12 text-gray-500">Created on</p>
            <p className="out-500-12 text-black mt-2">
              {details.createdAt
                ? dayjs(details.createdAt).format("MMMM DD YYYY, hh:mm a")
                : "-"}
            </p>
          </div>
        </div>
        <div className="flex mt-[21px] items-center gap-x-3 justify-end">
          <CustomButton
            text="Edit"
            className="bg-transparent text-gray-500"
            height="30px"
            width="45px"
            onClick={handleEditThread}
          />
          <CustomButton
            text="Leave group"
            height="30px"
            width="98px"
            className="bg-error-700 text-white"
            onClick={() => {
              dispatch(
                updateConversationsMultiple([
                  { key: "leaveThread", value: true },
                  { key: "leaveThreadDetails", value: details },
                ])
              );
              onClose();
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default GroupDetailsDrawer;
