import React, { useEffect, useState } from "react";
import { CrossIcon } from "../../../../Core/svgV2/CrossIcon";
import CustomModal from "../../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../../Core/CommonV2/CustomButton";
import CustomSelect from "../../../../Core/CommonV2/CustomSelect";
import { getStatusDetails } from "../../../../Core/utils/scheduleUtils";
import CustomBadge from "../../../../Core/Common/CustomBadge";
import { PlusIcon } from "../../../../Core/svgV2/PlusIcon";
import { client } from "../../../../Core/utils/axiosClient";
import { CopyIcon } from "../../../../Core/svgV2/CopyIcon";
import { useAppSelector } from "../../../../Core/redux/hooks";
import copyTextToClipboard from "../../../../Core/utils/clipboard";

const BitbucketInfoModal = ({ visible, onClose, type }) => {
  const { tenantDetails } = useAppSelector((state) => state.tenant);

  const connectionWebhookUrl = `${process.env.REACT_APP_API_URL}/webhook/${type}/${tenantDetails.tenantId}`;
  return (
    <CustomModal
      visible={visible}
      width="547px"
      onCancel={() => onClose()}
      body={
        <div className="p-4 flex flex-col gap-y-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Connect {type}</p>

            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <div className="out-500-14 ">Setup</div>
          <div className="px-4">
            <ol className="list-decimal	list-inside">
              <li className="py-4">
                In your{" "}
                <a className="underline" href="https://bitbucket.org/">
                  {type}
                </a>{" "}
                repository navigate to{" "}
                <strong className="sc-beFYmZ iUZnX">
                  Repository Settings → Webhooks
                </strong>
                . Click the{" "}
                <strong className="sc-beFYmZ iUZnX">Add webhook</strong> button
              </li>
              <li className="py-4">
                Please provide a title of your preference and insert the
                provided <strong>URL</strong> into the designated URL input
                field.
                <div className="mt-3 flex items-center border border-gray-200 px-1 py-1 rounded-md">
                  <span className="truncate whitespace-nowrap px-1 text-gray-700">
                    {connectionWebhookUrl}
                  </span>

                  <button
                    aria-label="Copy to clipboard"
                    className="px-1"
                    type="button"
                    onClick={() => copyTextToClipboard(connectionWebhookUrl)}
                  >
                    <CopyIcon />
                  </button>
                </div>
              </li>
              <li className="py-4">
                Select the Active , Push and Merged Checkbox
              </li>
              <li className="py-4">
                Leave the other settings as default and complete the setup by
                clicking <strong>Add webhook</strong>.
              </li>
            </ol>
          </div>
          <div className="px-4 flex justify-end">
            <CustomButton text="Done" width="65px" onClick={onClose} />
          </div>
        </div>
      }
    />
  );
};

const AutomationRow = ({ automation, getAllAutomation, type = "" }) => {
  const typeList = [{ name: "Commit" }, { name: "Pull Request" }];
  const status = [
    { id: "1", name: "To Do" },
    { id: "2", name: "In Progress" },
    { id: "3", name: "Done" },
  ];

  const [currentAutomation, setCurrentAutomation] = useState(automation);

  useEffect(() => {
    setCurrentAutomation(automation);
  }, [automation]);

  const removeAutomation = async () => {
    try {
      await client.delete(
        "/integration/reposervice/delete-reposervice-automation",
        {
          params: {
            id: automation.id,
            type: type,
          },
        }
      );
      getAllAutomation();
    } catch (err) {
      console.log(err);
    }
  };

  const updateAutomation = async (key, value, type) => {
    setCurrentAutomation((prevAutomation) => {
      const newAutomation = { ...prevAutomation, [key]: value };
      return newAutomation;
    });

    try {
      await client.put(
        `/integration/reposervice/update-reposervice-automation`,
        {
          [key]: value,
          automationId: automation.id,
          type: type,
        }
      );
      getAllAutomation();
    } catch (err) {
      console.log(err);
    }
  };
  const createAutomation = async (key, value, type) => {
    setCurrentAutomation((prevAutomation) => {
      const newAutomation = { ...prevAutomation, [key]: value };
      return newAutomation;
    });

    try {
      await client.post(
        `/integration/reposervice/create-new-reposervice-automation`,
        {
          [key]: value,
          type,
        }
      );
      getAllAutomation();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex gap-x-3 group items-center">
      <div className="mr-3 min-w-5 flex items-center h-5">
        <button
          className="group-hover:block hidden "
          onClick={removeAutomation}
        >
          <CrossIcon className="text-gray-500" />
        </button>
      </div>
      <div className="out-300-14 text-gray-500 whitespace-nowrap">Type is</div>

      <CustomSelect
        placeholder="Type"
        value={currentAutomation?.fieldName || " "}
        onChange={(val) => {
          if (automation?.id) {
            updateAutomation("fieldName", val, type);
          } else {
            createAutomation("fieldName", val, type);
          }
        }}
        options={typeList.map((type) => {
          return {
            id: type.name,
            value: type.name,
            label: type.name,
            render: () => (
              <div className="flex items-center h-full ">
                <p className="out-300-12  text-gray-900 capitalize ">
                  {type.name}
                </p>
              </div>
            ),
          };
        })}
      />
      <div className="out-300-14 text-gray-500 whitespace-nowrap">
        status is
      </div>
      <CustomSelect
        placeholder="Status"
        value={currentAutomation?.value || ""}
        onChange={(val) => updateAutomation("value", val, type)}
        options={status.map((status) => {
          const { color, className } = getStatusDetails(status.id);
          return {
            id: status.id,
            value: status.id,
            label: status.name,
            render: () => (
              <div className="flex items-center h-full ">
                <CustomBadge
                  text={status.name}
                  color={color}
                  className={className}
                  dot
                />
              </div>
            ),
          };
        })}
      />
    </div>
  );
};

const RepositoryServiceModal = ({ visible, onClose, type }) => {
  const [isConnected, setIsConnected] = useState(false);

  const [infoModal, setInfoModal] = useState(false);
  const [automations, setAutomations] = useState([]);

  const getAllAutomation = async () => {
    try {
      const response = await client.get(
        `/integration/reposervice/get-all-reposervice-automations?type=${type}`
      );
      if (response.data.result) {
        setAutomations(response.data.result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const checkIfServiceIsIntegrated = async () => {
    try {
      const { data } = await client.get(
        `/integration/reposervice/status?type=${type}`
      );
      getAllAutomation();
      console.log(data);
      if (data.integartion.enabled) {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    } catch (e) {
      setIsConnected(false);
    }
  };

  useEffect(() => {
    console.log(type, "type of reposervice");
    if (type) {
      checkIfServiceIsIntegrated();
    }
  }, [type]);
  //   useEffect(()=>{
  // if(automations.length===2){
  //   setTypeList([])
  // }else if(automations.length===1){
  //   setTypeList()
  // }
  //   },[automations])

  const handleConnectClick = async () => {
    try {
      if (!isConnected) {
        const { data } = await client.get(
          `/integration/reposervice/connect?type=${type}`
        );

        setInfoModal(true);
      } else {
        const { data } = await client.delete(
          `/integration/reposervice/disconnect?type=${type}`
        );
      }
    } catch (e) {
      console.log("ERROR:", e);
    } finally {
      checkIfServiceIsIntegrated();
    }
  };

  const createNewAutomation = async () => {
    try {
      await client.post(
        `/integration/reposervice/create-new-reposervice-automation`,
        {
          fieldName: "",
          type,
        }
      );
      getAllAutomation();
    } catch (err) {
      console.log(err);
    }
    // return (
    //   <AutomationRow
    //     automation={{}}
    //     type={type}
    //     getAllAutomation={getAllAutomation}
    //   />
    // );
  };

  return (
    <CustomModal
      visible={visible}
      width="667px"
      onCancel={() => onClose()}
      body={
        <div className="p-4 flex flex-col gap-y-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">{type}</p>

            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>

          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between items-center">
              <p className="out-300-14 text-gray-500 ">Integrate with {type}</p>
              {!isConnected ? (
                <CustomButton
                  text="Connect"
                  height="30px"
                  onClick={handleConnectClick}
                />
              ) : (
                <div className="flex items-center gap-x-2">
                  <CustomButton
                    text="Learn More"
                    height="30px"
                    onClick={() => setInfoModal(true)}
                  />

                  <CustomButton
                    text="Disconnect"
                    height="30px"
                    className="bg-error-600 text-white"
                    onClick={handleConnectClick}
                  />
                </div>
              )}
            </div>

            <div className="border-solid border-gray-200 relative w-full h-px  border-t  rounded-none" />

            <p className="text-black out-500-14">Settings</p>
            {automations?.length > 0 &&
              automations.map((automation) => (
                <AutomationRow
                  key={automation?.id}
                  automation={automation}
                  type={type}
                  getAllAutomation={getAllAutomation}
                />
              ))}

            {automations.length < 2 && (
              <button className="flex gap-x-2 " onClick={createNewAutomation}>
                <PlusIcon className="text-gray-500" />
                <p className="out-500-14 text-gray-500">
                  Add {automations.length > 0 && "more"}
                </p>
              </button>
            )}

            <div className="border-solid border-gray-200 relative w-full h-px  border-t  rounded-none" />

            <p className="text-black out-500-14">Features</p>

            <p className="out-300-14 text-gray-500">
              With this integration you can automatically change statuses of
              your tasks from your commits/pull requests.
            </p>

            <div className="flex justify-end">
              <CustomButton
                text="Close"
                className="bg-white text-gray-500"
                height="30px"
                onClick={onClose}
              />
            </div>
          </div>
          <BitbucketInfoModal
            visible={infoModal}
            type={type}
            onClose={() => setInfoModal(false)}
          />
        </div>
      }
    />
  );
};

export default RepositoryServiceModal;
