import React from "react";
import Icon from "@ant-design/icons";

const PencilEditIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill="#344054"
      d="M3.075 9.653c.02-.175.029-.262.055-.344a.845.845 0 01.099-.205 1.79 1.79 0 01.234-.258l5.58-5.58a1.195 1.195 0 011.69 1.69l-5.58 5.58a1.783 1.783 0 01-.258.234.84.84 0 01-.205.098 1.782 1.782 0 01-.344.056l-1.43.159.159-1.43z"
    ></path>
  </svg>
);

const PencilEditIcon = (props) => (
  <Icon component={PencilEditIconSvg} {...props} />
);

export { PencilEditIcon };
