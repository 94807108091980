import { createSlice } from "@reduxjs/toolkit";
import {
  getActivityMonitorChartData,
  getDailyDCFAverageOfUser,
  getSelectedMonthActivity,
  getTodaysDcfActivity,
  getTotalDCFDetails,
} from "../api/myFootprintAPI";
import dayjs from "dayjs";
// import { changeWorkspace } from "../api/dashboardAPI";
//todo add apis

//We do not recommend the object notation for extraReducers any more, especially with TypeScript you should be using the builder notation --by redux toolkit team.

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  view: "today", //0:day, 1:week, 2:year
  selectedDate: "",
  selectedMonth: "",
  selectedYear: "",
  welcomeModal: false,
  footprintMode: 1, //1 : user , 2: tenant
  todayGCO2value: 0,
  previousDayGCO2value: 0,
  totalUsageOverview: {
    dcf: 0,
    totalDataConsumed: 0,
    totalUsageTime: 0,
  },
  currentMonthUsageOverview: {
    totalDcf: 0,
    averageDcf: 0,
    dayData: [],
  },
  activityGraphData: {
    totalDcf: 0,
    graphData: [],
    totalTrendDcf: 0,
  },

  usageTimeInSeconds: 0,
};

export const footprintSlice = createSlice({
  name: "footprintDetails",
  initialState,
  reducers: {
    updateFootprint: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },

    updateFootprintData: (state, action) => {
      action.payload.map((ele) => {
        state[ele.key] = ele.value;
      });
    },

    resetToInitialFootprint: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTodaysDcfActivity.pending, (state) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getTodaysDcfActivity.fulfilled, (state, { payload }) => {
      console.log(payload, "dcf data");
      return {
        ...state,
        error: null,
        loading: false,
        status: "fulfilled",
        todayGCO2value: payload.totalDcf,
        previousDayGCO2value: payload.previousDayTotalDcf,
      };
    });
    builder.addCase(getTodaysDcfActivity.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(getTotalDCFDetails.pending, (state) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getTotalDCFDetails.fulfilled, (state, { payload }) => {
      return {
        ...state,
        error: null,
        loading: false,
        status: "fulfilled",
        totalUsageOverview: payload,
      };
    });
    builder.addCase(getTotalDCFDetails.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(getSelectedMonthActivity.pending, (state) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(
      getSelectedMonthActivity.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          error: null,
          loading: false,
          status: "fulfilled",
          currentMonthUsageOverview: {
            ...state.currentMonthUsageOverview,
            totalDcf: payload.totalDcf,
            dayData: payload.data,
            averageDcf: payload.average.gCO2Total,
          },
        };
      }
    );
    builder.addCase(getSelectedMonthActivity.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(getActivityMonitorChartData.pending, (state) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(
      getActivityMonitorChartData.fulfilled,
      (state, { payload }) => {
        localStorage.setItem("lastFetchedDCFTime", dayjs().format());
        return {
          ...state,
          error: null,
          loading: false,
          status: "fulfilled",
          activityGraphData: {
            totalDcf: payload.totalDcf,
            graphData: payload.data,
            totalTrendDcf: payload.totalTrendDcf,
          },
        };
      }
    );
    builder.addCase(
      getActivityMonitorChartData.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.status = "rejected";
        state.error = payload;
      }
    );
  },
});

export const { updateFootprintData, resetToInitialFootprint, updateFootprint } =
  footprintSlice.actions;
export default footprintSlice.reducer;
