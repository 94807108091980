import React from "react";
import Icon from "@ant-design/icons";

const ReportsArrowIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    fill="none"
    viewBox="0 0 13 12"
  >
    <g>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.333 2v8m0 0l3-3m-3 3l-3-3"
      ></path>
    </g>
  </svg>
);

const ReportsArrowIcon = (props) => (
  <Icon component={ReportsArrowIconSvg} {...props} />
);

export { ReportsArrowIcon };
