import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useGetActiveSprint } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";

const Route = ({ title, isSelected = false, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`flex  items-center  rounded px-4 py-[5px] justify-center ${
        isSelected ? "bg-white" : ""
      }`}
      style={{
        boxShadow: isSelected
          ? "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
          : "none",
      }}
    >
      <div
        className={`text-center out-500-14  ${
          isSelected ? "text-gray-700" : "text-gray-500"
        }`}
      >
        {title}
      </div>
    </button>
  );
};

const ScheduleRouteSelector = ({ current }) => {
  const { projectId } = useParams();
  const { sprints, goals } = useAppSelector((state) => state.schedule);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Fetch active sprint data using query hook
  const { data: activeSprintData, isLoading: activeSprintLoading } =
    useGetActiveSprint({
      projectId,
    });

  // Determine sprints URL
  const getSprintsUrl = () => {
    if (activeSprintData) {
      return `/schedule/${projectId}/sprints/${activeSprintData.id}`;
    } else if (sprints.length === 0 || goals.length === 0) {
      return `/schedule/${projectId}/backlog`;
    } else {
      return `/schedule/${projectId}/sprints`;
    }
  };

  // Set sprints URL
  const [sprintsUrl, setSprintsUrl] = useState(getSprintsUrl());

  // Update sprints URL when active sprint or project ID changes
  useEffect(() => {
    setSprintsUrl(getSprintsUrl());
  }, [activeSprintData, projectId]);

  // Function to handle click action
  //todo @siddhant here the activesprintdata is of previous project
  const handleClick = async () => {
    if (!activeSprintLoading) {
      const currentActiveSprint = activeSprintData?.id;
      let url = "";
      if (currentActiveSprint) {
        navigate(sprintsUrl);
      } else {
        await dispatch(updateSchedule({ key: "emptyScreen", value: true }));
        url = `/schedule/${projectId}/sprints`;
        navigate(url);
      }
    }
  };

  return (
    <div
      id="schedule-tour-schedule-route-selector"
      className="w-[363px] max-h-[38px] bg-gray-50 rounded-lg gap-x-2 flex p-1"
    >
      <Route
        title="Timeline"
        isSelected={current === "timeline"}
        onClick={() => navigate(`/schedule/${projectId}/timeline`)}
      />
      <Route
        title="Backlog"
        isSelected={current === "backlog"}
        onClick={() => navigate(`/schedule/${projectId}/backlog`)}
      />
      <Route
        title="Sprints"
        isSelected={current === "sprints"}
        onClick={handleClick}
      />
      <Route
        title="Reports"
        isSelected={current === "reports"}
        onClick={() => navigate(`/schedule/${projectId}/reports`)}
      />
    </div>
  );
};

export default ScheduleRouteSelector;
