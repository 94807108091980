import React from "react";
import Icon from "@ant-design/icons";

const MaybeResponseIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_2097_27762)">
      <circle cx="8" cy="8" r="8" fill="#EDF3F9"></circle>
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.5 5.72a2.573 2.573 0 015 .857c0 1.715-2.573 2.573-2.573 2.573m.069 3.43h.008"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_2097_27762">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

const MaybeResponseIcon = (props) => (
  <Icon component={MaybeResponseIconSvg} {...props} />
);

export { MaybeResponseIcon };
