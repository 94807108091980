import { Calendar, Dropdown, Input, Popover } from "antd";
import dayLocaleData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useState } from "react";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { CalendarIcon } from "../../../Core/svgV2/CalendarIcon";
import { AlarmClockIcon } from "../../../Core/svgV2/AlarmClock";
import { UrgencyFillIcon, UrgencyIcon } from "../../../Core/svgV2/Urgency";

import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateConversations } from "../../../Core/redux/slices/conversations.slice";

dayjs.extend(dayLocaleData);
dayjs.extend(customParseFormat);

const MenuItem = ({
  selectedRequestType,
  setSelectedRequestType,
  requestType,
}) => {
  return (
    <button
      className="out-300-14 w-[135px] justify-between flex gap-x-[14px] text-gray-900"
      onClick={() => setSelectedRequestType(requestType)}
    >
      <span className="out-300-14  text-gray-900">{requestType}</span>{" "}
      <div className="w-5 h-5">
        {selectedRequestType === requestType && (
          <CheckIcon className="text-primary-600" />
        )}
      </div>
    </button>
  );
};

const CustomDatePicker = () => {
  const [open, setOpen] = useState(false);
  const { chatRequest } = useAppSelector((state) => state.conversations);

  const dispatch = useAppDispatch();
  const [date, setDate] = useState(dayjs());
  return (
    <Popover
      trigger={"click"}
      content={
        <div className="custom-calendar w-[328px] p-4">
          <div className="custom-calendar ">
            <Calendar
              fullscreen={false}
              value={date}
              onChange={(date) => {
                setDate(date);
                dispatch(
                  updateConversations({
                    key: "chatRequest",
                    value: {
                      ...chatRequest,
                      deadlineDate: date.format("YYYY-MM-DD"),
                    },
                  })
                );
              }}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                // const start = 0;
                // const end = 12;
                // const monthOptions = [];
                const year = value.year();
                // const month = value.month();

                const current = value.clone();
                const localeData = value.localeData();

                return (
                  <div className="">
                    <div className="flex  justify-between mt-4 mb-4 px-4">
                      <button onClick={() => setDate(date.add(-1, "months"))}>
                        <img
                          src="/images/icons/chevron-left.svg"
                          className="h-5 w-5"
                        />
                      </button>
                      <p className="out-500-16 text-black">
                        {localeData.months(current)} {year}
                      </p>
                      <button onClick={() => setDate(date.add(1, "months"))}>
                        <img
                          src="/images/icons/chevron-right.svg"
                          className="h-5 w-5"
                        />
                      </button>
                    </div>
                  </div>
                );
              }}
              className=""
            />{" "}
          </div>
        </div>
      }
    >
      <button onClick={() => setOpen(!open)} className="flex gap-x-2">
        <CalendarIcon className="text-gray-700" />
        <span className="out-300-14 text-gray-500">
          {chatRequest.deadlineDate
            ? dayjs(chatRequest.deadlineDate).format("DD MMM YYYY")
            : "Set due date"}
        </span>
      </button>
    </Popover>
  );
};

const TimePicker = () => {
  const { chatRequest } = useAppSelector((state) => state.conversations);
  const dispatch = useAppDispatch();

  const [amPm, setAmPm] = useState("am");
  const [time, setTime] = useState("");
  const [min, setMin] = useState("00");

  const handleTimeChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setTime("");
      setMin("00");
    } else if (/^\d+$/.test(value) && value.length <= 2 && +value <= 12) {
      setTime(value);
    }
  };

  const handleMinuteChange = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setMin("");
    }
    if (/^\d+$/.test(value) && +value < 60) {
      setMin(value);
    }
  };

  const handleAmPmChange = (selectedAmPm) => {
    setAmPm(selectedAmPm);
  };

  return (
    <Popover
      trigger="click"
      onOpenChange={(val) => {
        if (!val) {
          const m = min ? (min === "60" ? "59" : min.padStart(2, "0")) : "00";
          setMin(m);
          dispatch(
            updateConversations({
              key: "chatRequest",
              value: {
                ...chatRequest,
                deadlineTime: `${time.padStart(2, "0")}:${m}:00 ${amPm}`,
              },
            })
          );
        }
      }}
      content={
        <div className="p-2">
          <div className="flex">
            <Input
              type="number"
              min={1}
              max={12}
              value={time}
              onChange={handleTimeChange}
              className="w-10 h-[25px] border-gray-50 focus:outline-none"
            />
            <span className="px-1 out-500-14">:</span>
            <Input
              type="number"
              min={0}
              max={59}
              value={min}
              onChange={handleMinuteChange}
              className="w-10 h-[25px] border-gray-50 focus:outline-none"
            />
            <div className="w-[51px] flex h-[25px] items-center ml-2">
              <div
                onClick={() => handleAmPmChange("am")}
                className={`out-500-12 h-full w-[25px] ${
                  amPm === "am"
                    ? "bg-primary-600 border-primary-600 text-white"
                    : "text-gray-300 border-gray-300"
                } cursor-pointer rounded-l border-t border-l border-b flex items-center justify-center`}
              >
                AM
              </div>
              <div
                onClick={() => handleAmPmChange("pm")}
                className={`out-500-12 h-full w-[27px] cursor-pointer ${
                  amPm === "pm"
                    ? "bg-primary-600 border-4 border-primary-600 text-white"
                    : "text-gray-300 border-r border-gray-300"
                } rounded-r border-t border-b flex items-center justify-center`}
              >
                PM
              </div>
            </div>
          </div>
        </div>
      }
    >
      <button className="flex gap-x-2">
        <AlarmClockIcon className="text-gray-700" />
        <span className="out-300-14 text-gray-500">
          {time ? `${time.padStart(2, "0")}:${min} ${amPm}` : "Set time"}
        </span>
      </button>
    </Popover>
  );
};

const UrgencyPicker = () => {
  const { chatRequest } = useAppSelector((state) => state.conversations);

  const dispatch = useAppDispatch();

  const setSelectedUrgency = (urgency: string) =>
    dispatch(
      updateConversations({
        key: "chatRequest",
        value: { ...chatRequest, selectedUrgency: urgency },
      })
    );

  const getColor = () => {
    switch (chatRequest.selectedUrgency) {
      case "Critical":
        return "text-error-600";
      case "Major":
        return "text-warning-600";
      case "Medium":
        return "text-primary-600";
      case "Minor":
        return "text-gray-600";
      default:
        return "text-gray-600";
    }
  };

  const UrgencyItems = [
    {
      key: "1",
      label: (
        <div
          className="w-[100px] gap-x-2 flex items-center"
          onClick={() => setSelectedUrgency("Critical")}
        >
          <UrgencyFillIcon className="text-error-600" style={{}} />
          <span className="out-300-14 text-gray-700">Critical</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="w-[100px] gap-x-2 flex items-center"
          onClick={() => setSelectedUrgency("Major")}
        >
          <UrgencyFillIcon className="text-warning-600" style={{}} />
          <span className="out-300-14 text-gray-700">Major</span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className="w-[100px] gap-x-2 flex items-center"
          onClick={() => setSelectedUrgency("Medium")}
        >
          <UrgencyFillIcon className="text-primary-600" style={{}} />
          <span className="out-300-14 text-gray-700">Medium</span>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          className="w-[100px] gap-x-2 flex items-center"
          onClick={() => setSelectedUrgency("Minor")}
        >
          <UrgencyFillIcon className="text-gray-600" style={{}} />
          <span className="out-300-14 text-gray-700">Minor</span>
        </div>
      ),
    },
  ];
  return (
    <Dropdown menu={{ items: UrgencyItems }}>
      {chatRequest.selectedUrgency ? (
        <button className="flex gap-x-2">
          <UrgencyFillIcon className={getColor()} />
          <span className="out-300-14 text-gray-500">
            {chatRequest.selectedUrgency}
          </span>
        </button>
      ) : (
        <button className="flex gap-x-2">
          <UrgencyIcon className="text-gray-700" />
          <span className="out-300-14 text-gray-500">Set urgency</span>
        </button>
      )}
    </Dropdown>
  );
};

const RequestFlow = () => {
  const { chatRequest } = useAppSelector((state) => state.conversations);

  const dispatch = useAppDispatch();
  //const [selectedRequestType, setSelectedRequestType] = useState("Attachment");
  const setSelectedRequestType = (requestType: string) =>
    dispatch(
      updateConversations({
        key: "chatRequest",
        value: { ...chatRequest, selectedRequestType: requestType },
      })
    );
  const requestType = [
    {
      key: "1",
      label: (
        <MenuItem
          selectedRequestType={chatRequest?.selectedRequestType}
          setSelectedRequestType={setSelectedRequestType}
          requestType={"Attachment"}
        />
      ),
    },
    {
      key: "2",
      label: (
        <MenuItem
          selectedRequestType={chatRequest?.selectedRequestType}
          setSelectedRequestType={setSelectedRequestType}
          requestType={"Approval"}
        />
      ),
    },
    {
      key: "3",
      label: (
        <MenuItem
          selectedRequestType={chatRequest?.selectedRequestType}
          setSelectedRequestType={setSelectedRequestType}
          requestType={"Feedback"}
        />
      ),
    },
    {
      key: "4",
      label: (
        <MenuItem
          selectedRequestType={chatRequest?.selectedRequestType}
          setSelectedRequestType={setSelectedRequestType}
          requestType={"Custom"}
        />
      ),
    },
  ];
  return (
    <div className="mx-4 border-b pb-[18px]">
      <div className="out-400-14 pt-4  pb-2">/Request</div>
      <div className="flex justify-between items-center">
        <div className=" flex gap-x-[21px]">
          <span className="out-300-14 text-gray-500">
            Select the type of your request
          </span>
          <div className="flex items-center gap-x-2">
            <span className="out-500-14 text-gray-500">
              {chatRequest?.selectedRequestType}
            </span>
            <Dropdown menu={{ items: requestType, selectable: true }}>
              <button>
                <ChevronIcon className="text-gray-700" />
              </button>
            </Dropdown>
          </div>
        </div>
        <div className="flex gap-x-[21px] pr-">
          <CustomDatePicker />
          <TimePicker />
          <UrgencyPicker />
        </div>
      </div>
    </div>
  );
};

export default RequestFlow;
