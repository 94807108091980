import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { Radio, Space } from "antd";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { getStatusDetails } from "../../../Core/utils/scheduleUtils";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  getGoalsBySprint,
  getSwimlanes,
  removeSwimlane,
} from "../../../Core/redux/api/scheduleAPI";
import { useQueryClient } from "react-query";

const DeleteSwimlaneModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();
  const { projectId, sprintId } = useParams();
  const { swimlanes, swimlaneDetails } = useAppSelector(
    (state) => state.schedule
  );
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const { formState, control, handleSubmit } = useForm({
    values: {
      status: null,
    },
  });

  const handleDelete = async (data) => {
    const swimlaneToRemove = {
      id: swimlaneDetails.id,
      projectId,
      statusId: null,
      newSwimlaneId: null,
    };
    if (data.status) {
      const filteredSwimlanes = swimlanes.find(
        (item) => item.id == data.status
      );
      console.log(filteredSwimlanes, swimlaneDetails, swimlanes, data);
      swimlaneToRemove.statusId = filteredSwimlanes.statusId;
      swimlaneToRemove.newSwimlaneId = filteredSwimlanes.id;
    }
    await dispatch(removeSwimlane(swimlaneToRemove));
    queryClient.invalidateQueries(["swimlanes", projectId]);
    dispatch(
      getGoalsBySprint({
        projectId,
        sprintId: sprintId,
        module: "sprints",
      })
    );
    onClose();
  };

  const StatusItem = ({ status, text }) => {
    const { color, className } = getStatusDetails(status);
    return (
      <div key={text} className="h-full flex items-center">
        <CustomBadge text={text} color={color} className={className} dot />
      </div>
    );
  };

  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Delete Swimlane</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to delete this status? This action cannot be
            undone.
          </p>
          <p className="out-500-14 mt-4 text-gray-900">
            Choose what to do with items inside the swimlane:
          </p>
          <div className="mt-4">
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                <Radio value={1}>
                  <span className="out-300-14 text-gray-500">
                    Delete swimlane and the items inside
                  </span>
                </Radio>
                <Radio value={2}>
                  <span className="out-300-14 text-gray-500">
                    Move all items to a different swimlane
                  </span>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div
            className="mt-4"
            style={{
              opacity: value === 1 ? 0.6 : 1,
            }}
          >
            <p className="out-500-14 text-gray-700 ">Select a swimlane</p>

            <Controller
              name="status"
              control={control}
              rules={{
                required: value === 1 ? false : "Please select the swimlane",
              }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder="Select Swimlane"
                  wrapperClassName="mt-1.5"
                  disabled={value === 1}
                  formState={formState}
                  options={swimlanes
                    .filter((s) => s.id != swimlaneDetails.id)
                    .map((s) => {
                      return {
                        id: s.id,
                        value: s.id,
                        label: s.title,
                        render: () => (
                          <StatusItem text={s.title} status={s.statusId} />
                        ),
                      };
                    })}
                />
              )}
            />
          </div>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Delete"
              height="30px"
              className="bg-error-700 text-white"
              onClick={handleSubmit(handleDelete)}
            />
          </div>
        </div>
      }
    />
  );
};

export default DeleteSwimlaneModal;
