import React from "react";
import { CrossIcon } from "../svgV2/CrossIcon";
import CustomButton from "./CustomButton";

const TakeTourModal = ({ onOk, onClose, module = "Messages" }) => {
  return (
    <div className="p-4 fixed bg-white w-[326px] z-20 bottom-5 right-5 shadow-lg rounded-lg">
      <div className="flex justify-between">
        <div className="flex items-center gap-x-[14px]">
          {" "}
          <img src="/images/v2/conversations/frosty.svg" />
          <p className="text-black out-500-16">Hey there!</p>
        </div>
        <button onClick={onClose}>
          {" "}
          <CrossIcon className="text-gray-700" />
        </button>
      </div>
      <p className="mt-4 out-500-14 text-gray-500 leading-5">
        I noticed that you are here for the first time!
        <br /> Take a tour to understand {module} better.
      </p>
      <div className="flex mt-[21px] items-center gap-x-3 justify-start">
        <CustomButton
          onClick={onOk}
          text="Take the tour"
          height="30px"
          width="102px"
        />
        <CustomButton
          text="I’ll explore on my own"
          className="bg-transparent text-gray-500"
          height="30px"
          width="153px"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default TakeTourModal;
