import React from "react";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";

const getStatus = ({ statusId, showIcon = false }) => {
  switch (statusId) {
    case 1:
      return (
        <div className="flex w-full items-center gap-x-[6px] px-[6px] bg-white border border-gray-500 rounded-full max-w-max">
          {showIcon ? (
            <div className="w-2 h-2 bg-gray-500 rounded-full" />
          ) : (
            <div />
          )}
          <p className="text-gray-500 out-300-12 ">To Do</p>
          {showIcon ? <ChevronIcon className="text-gray-500" /> : <div />}
        </div>
      );
    case 2:
      return (
        <div className="flex items-center gap-x-[6px] px-[6px] bg-white border border-primary-600 rounded-full max-w-max">
          {showIcon ? (
            <div className="w-2 h-2 rounded-full bg-primary-600" />
          ) : (
            <div />
          )}
          <p className="out-300-12 text-primary-600">In Progress</p>
          {showIcon ? <ChevronIcon className="text-primary-600" /> : <div />}
        </div>
      );
    case 3:
      return (
        <div className="flex items-center gap-x-[6px] px-[6px] bg-white border border-success-600 rounded-full max-w-max">
          {showIcon ? (
            <div className="w-2 h-2 rounded-full bg-success-600" />
          ) : (
            <div />
          )}
          <p className="out-300-12 text-success-600">Done</p>
          {showIcon ? <ChevronIcon className="text-success-600" /> : <div />}
        </div>
      );
    default:
      return (
        <div className="flex items-center gap-x-[6px] px-[6px] bg-white border border-gray-500 rounded-full max-w-max">
          {showIcon ? (
            <div className="w-2 h-2 bg-gray-500 rounded-full" />
          ) : (
            <div />
          )}
          <p className="text-gray-500 out-300-12 ">To Do</p>
          {showIcon ? <ChevronIcon className="text-gray-500" /> : <div />}
        </div>
      );
  }
};

export default getStatus;
