import React from "react";
import Icon from "@ant-design/icons";

const DcfHeaderSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <g fill="#144EA6" clipPath="url(#clip0_4284_1387)">
      <path d="M19.027 5.575a.702.702 0 00-.201-.44.702.702 0 00-.44-.201C16.582 4.74 10.99 4.42 7.939 7.506a6.608 6.608 0 00-1.922 4.15c-.116 1.545.345 3.046 1.295 4.21l1.402-1.402-.7-2.968a.539.539 0 01.005-.276.578.578 0 01.14-.245l1.532-1.53.035-.036a1.144 1.144 0 01-.008-.617c.053-.206.162-.396.313-.55.229-.195.518-.301.81-.297a1.1 1.1 0 01.765.318c.201.2.315.474.319.766.004.292-.102.58-.297.81a1.217 1.217 0 01-.55.316c-.206.054-.42.05-.618-.011l-.035.035-1.28 1.28.514 2.138 4.92-4.921a1.14 1.14 0 01.006-.601c.055-.2.162-.383.31-.532.148-.153.332-.266.533-.326.2-.061.411-.068.61-.02a1.077 1.077 0 01.798.816c.044.2.034.41-.031.61a1.248 1.248 0 01-.884.824 1.15 1.15 0 01-.608-.014l-2.197 2.198 2.541-.3c.056-.18.157-.344.292-.479.167-.169.378-.288.606-.341.228-.054.463-.04.675.041.212.08.393.223.518.41.125.187.19.41.187.642-.003.231-.075.46-.206.658a1.273 1.273 0 01-.53.454c-.215.098-.45.132-.678.098a1.099 1.099 0 01-.596-.291l-.105-.105-3.744.429a.642.642 0 01-.147-.007l-3.81 3.81c1.16.964 2.663 1.438 4.215 1.328a6.59 6.59 0 004.167-1.922c2.993-3.038 2.71-8.675 2.527-10.481z"></path>
      <path d="M10.728 15.006l-.107-.107c-.207-.207-.614-.135-.91.16l-3.208 3.209c-.295.295-.367.702-.16.909l.107.107c.207.206.614.135.909-.16l3.208-3.21c.296-.295.368-.702.16-.908z"></path>
    </g>
    <defs>
      <clipPath id="clip0_4284_1387">
        <path
          fill="#fff"
          d="M0 0H14.52V19.36H0z"
          transform="rotate(45 6.83 16.562)"
        ></path>
      </clipPath>
    </defs>
  </svg>
);

const DcfHeaderIcon = (props) => <Icon component={DcfHeaderSvg} {...props} />;

export default DcfHeaderIcon;
