import React from "react";
import Icon from "@ant-design/icons";

const NewFolderSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.667 4.667l-.744-1.488c-.214-.428-.32-.642-.48-.798a1.333 1.333 0 00-.499-.308C6.733 2 6.494 2 6.015 2H3.467c-.747 0-1.12 0-1.405.145-.251.128-.455.332-.583.583-.146.285-.146.659-.146 1.405v.534m0 0h10.134c1.12 0 1.68 0 2.108.218a2 2 0 01.874.874c.218.428.218.988.218 2.108V10.8c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874c-.428.218-.988.218-2.108.218H4.533c-1.12 0-1.68 0-2.107-.218a2 2 0 01-.875-.874c-.218-.428-.218-.988-.218-2.108V4.667zM8 11.333v-4m-2 2h4"
    ></path>
  </svg>
);

const NewFolderIcon = (props) => {
  return <Icon component={NewFolderSvg} {...props} />;
};

export { NewFolderIcon };
