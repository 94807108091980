import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const DeleteMessageModal = ({ visible, onClose, onDelete }) => {
  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Delete message</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to delete this message? <br />
            This action cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={onClose}
            />
            <CustomButton
              text="Delete message"
              height="30px"
              className="bg-error-700 text-white"
              onClick={onDelete}
            />
          </div>
        </div>
      }
    />
  );
};

export default DeleteMessageModal;
