import React from "react";
import Icon from "@ant-design/icons";

const AttachmentSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M9.487 6.294v7c0 .412.249 1.235 1.244 1.235s1.243-.823 1.243-1.235V5.06c0-.686-.497-2.059-2.487-2.059C7.497 3 7 4.373 7 5.059v8.647C7 14.804 7.746 17 10.73 17c2.348 0 3.27-.823 3.27-1.647M14 13V4"
    ></path>
  </svg>
);

const AttachmentIcon = (props) => {
  return <Icon component={AttachmentSvg} {...props} />;
};

export { AttachmentIcon };
