import React, { useEffect, useState } from "react";
import ScheduleLayout from "../Components/ScheduleLayout";
import ReportsMenu from "./ReportsMenu";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Reports from "./Reports";
import { useAppSelector } from "../../../Core/redux/hooks";
import getUsersName from "../../../Core/utils/getUserName";
import { getDatesInRange } from "../../../Core/utils/date";
import dayjs from "dayjs";
import {
  convertMinutesToTimeLog,
  getTimeLoginMinutes,
} from "../../../Core/utils/timeLogUtils";

const ReportsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const [searchParams, setSearchParam] = useSearchParams();
  const {
    reportsGroupBy,
    goals,
    swimlanes,
    sprints,
    phases,
    filterGoalParameter,
    reportGoals,
  } = useAppSelector((state) => state.schedule);
  const { userList } = useAppSelector((state) => state.overview);
  const [data, setData] = useState({});

  const handleNavigate = (path) => {
    const currentPath = location.pathname;
    const newPath = currentPath + path; // Append '/new' to the current path

    navigate(newPath);
  };

  const getTime = (array) => {
    let value = array?.reduce((acc, curr) => {
      return acc + getTimeLoginMinutes(curr.timeLogged);
    }, 0);

    array?.map(
      (goal) =>
        (value =
          value +
          goal?.subtasks?.reduce((acc, curr) => {
            return acc + getTimeLoginMinutes(curr.timeLogged);
          }, 0))
    );

    return value;
  };
  const getTotalTime = (timeString) => {
    const timeArray = !Array.isArray(timeString)
      ? timeString.includes(",")
        ? timeString.replace("'", "").split(",")
        : [timeString]
      : ["0m"];

    const value = timeArray.reduce((acc, curr) => {
      return acc + getTimeLoginMinutes(curr);
    }, 0);

    return value;
  };
  const filterByAssignee = (goal) => {
    const currentProjectFilter = filterGoalParameter?.assignee?.find(
      (filter) => +filter.projectId === +projectId
    );

    if (currentProjectFilter !== undefined) {
      return currentProjectFilter?.assignee?.includes(goal.assigneeId);
    }
    return false;
  };
  const generateDataForTask = () => {
    let data = [];

    switch (reportsGroupBy.key) {
      case "Sprint":
        data = sprints
          .filter((s) => reportsGroupBy.value.includes(s.id))
          .map((sprint) => {
            const sprintGoals = goals.filter(
              (goal) => goal.sprintId == sprint.id && filterByAssignee(goal)
            );
            console.log(sprintGoals, "swimlaneGoals");
            const usersData = userList.map((user) => ({
              name: getUsersName(user),
              value: sprintGoals.filter(
                (goal) => goal.assigneeId == user.userId
              ).length,
            }));

            return {
              name: sprint.name,
              value: sprintGoals.length,
              users: usersData,
            };
          });

        break;
      case "Phase":
        data = phases
          .filter((s) => reportsGroupBy.value.includes(s.projectPhaseId))
          .map((phase) => {
            const phaseGoals = goals.filter(
              (goal) =>
                goal.phaseId == phase.projectPhaseId && filterByAssignee(goal)
            );
            console.log(phaseGoals, "swimlaneGoals");
            const usersData = userList.map((user) => ({
              name: getUsersName(user),
              value: phaseGoals.filter((goal) => goal.assigneeId == user.userId)
                .length,
            }));

            return {
              name: phase.phases,
              value: phaseGoals.length,
              users: usersData,
            };
          });

        break;
      case "Status":
        data = swimlanes
          .filter((s) => reportsGroupBy.value.includes(s.id))
          .map((swimlane) => {
            const swimlaneGoals = goals.filter(
              (goal) =>
                goal.swimlaneId === swimlane.id && filterByAssignee(goal)
            );
            console.log(filterByAssignee, "swimlaneGoals");
            const usersData = userList.map((user) => ({
              name: getUsersName(user),
              value: swimlaneGoals.filter(
                (goal) => goal.assigneeId == user.userId
              ).length,
            }));

            return {
              name: swimlane.title,
              value: swimlaneGoals.length,
              users: usersData,
            };
          });
        break;
      case "Date Range": {
        const dates = getDatesInRange(
          reportsGroupBy.value[0],
          reportsGroupBy.value[1]
        );

        data = dates.map((date) => {
          const dateGoals = goals.filter((goal) => {
            return (
              dayjs(goal.createdAt).format("DD-MM-YYYY") === date &&
              filterByAssignee(goal)
            );
          });

          const usersData = userList.map((user) => ({
            name: getUsersName(user),
            value: dateGoals.filter((goal) => goal.assigneeId == user.userId)
              .length,
          }));

          return {
            name: date,
            value: dateGoals.length,
            users: usersData,
          };
        });
        break;
      }
      case "Month":
        data = reportsGroupBy.value.map((month) => {
          const dateGoals = goals.filter(
            (goal) =>
              dayjs(goal.createdAt).isSame(dayjs(month, "MMMM"), "month") &&
              filterByAssignee(goal)
          );

          const usersData = userList.map((user) => ({
            name: getUsersName(user),
            value: dateGoals.filter((goal) => goal.assigneeId == user.userId)
              .length,
          }));

          return {
            name: month,
            value: dateGoals.length,
            users: usersData,
          };
        });
        break;
      case "Year":
        data = reportsGroupBy.value.map((year) => {
          const dateGoals = goals.filter(
            (goal) =>
              dayjs(goal.createdAt).isSame(dayjs(year, "YYYY"), "year") &&
              filterByAssignee(goal)
          );

          const usersData = userList.map((user) => ({
            name: getUsersName(user),
            value: dateGoals.filter((goal) => goal.assigneeId == user.userId)
              .length,
          }));

          return {
            name: year,
            value: dateGoals.length,
            users: usersData,
          };
        });
        break;
      default:
        break;
    }

    setData({
      xAxisLable: reportsGroupBy.key,
      yAxisLable: "No. of Tasks",
      value: data,
      total: data.reduce((p, c) => c.value + p, 0),
    });
  };

  const generateDataForTimeLogged = () => {
    let data = [];

    //todo account for users who are not in the project
    const totalTime = convertMinutesToTimeLog(
      getTime(goals.filter(filterByAssignee))
    );

    switch (reportsGroupBy.key) {
      case "Sprint":
        // data = sprints
        //   .filter((s) => reportsGroupBy.value.includes(s.id))
        //   .map((sprint) => {
        //     const sprintGoals = reportGoals?.filter(
        //       (report) =>
        //         report[sprintId] == sprint.id && filterByAssignee(goal)
        //     );
        //     console.log(sprintGoals, "swimlaneGoals");
        //     const usersData = userList.map((user) => ({
        //       name: getUsersName(user),
        //       value: convertMinutesToTimeLog(
        //         getTime(
        //           sprintGoals.filter((goal) => goal.assigneeId == user.userId)
        //         )
        //       ),
        //     }));

        //     return {
        //       name: sprint.name,
        //       value: getTime(sprintGoals),
        //       users: usersData,
        //     };
        //   });

        data = sprints
          .filter((s) => reportsGroupBy.value.includes(s.id))
          .map((sprint) => {
            const userGoalsInSprint = reportGoals[sprint.id];

            let totalTimeLogged = "";
            const usersData = [];
            if (userGoalsInSprint) {
              Object.keys(userGoalsInSprint).map((userGoal, index) => {
                const totalTime = getTotalTime(
                  userGoalsInSprint[userGoal].timeLogged?.includes("{") ||
                    userGoalsInSprint[userGoal].timeLogged?.includes("[") ||
                    !userGoalsInSprint[userGoal].timeLogged.length
                    ? "0m"
                    : userGoalsInSprint[userGoal].timeLogged
                );
                totalTimeLogged = `${totalTimeLogged},${totalTime}m`;

                usersData.push({
                  name: `${userGoalsInSprint[userGoal].firstname} ${userGoalsInSprint[userGoal].lastname}`,
                  value: convertMinutesToTimeLog(totalTime),
                });
              });
            }

            return {
              name: sprint.name,
              value: getTotalTime(totalTimeLogged),
              users: usersData,
            };
          });
        break;
      case "Phase":
        data = phases
          .filter((s) => reportsGroupBy.value.includes(s.projectPhaseId))
          .map((phase) => {
            const phaseGoals = goals.filter(
              (goal) =>
                goal.phaseId == phase.projectPhaseId && filterByAssignee(goal)
            );
            console.log(phaseGoals, "swimlaneGoals");
            const usersData = userList.map((user) => ({
              name: getUsersName(user),
              value: convertMinutesToTimeLog(
                getTime(
                  phaseGoals.filter((goal) => goal.assigneeId == user.userId)
                )
              ),
            }));

            return {
              name: phase.phases,
              value: getTime(phaseGoals),
              users: usersData,
            };
          });

        break;
      case "Status":
        data = swimlanes
          .filter((s) => reportsGroupBy.value.includes(s.id))
          .map((swimlane) => {
            const swimlaneGoals = goals.filter(
              (goal) =>
                goal.swimlaneId === swimlane.id && filterByAssignee(goal)
            );

            const usersData = userList.map((user) => ({
              name: getUsersName(user),
              value: convertMinutesToTimeLog(
                getTime(
                  swimlaneGoals.filter((goal) => goal.assigneeId == user.userId)
                )
              ),
            }));

            return {
              name: swimlane.title,
              value: getTime(swimlaneGoals),
              // value: swimlaneGoals.length,
              users: usersData,
            };
          });
        break;
      case "Date Range": {
        const dates = getDatesInRange(
          reportsGroupBy.value[0],
          reportsGroupBy.value[1]
        );

        data = dates.map((date) => {
          const dateGoals = reportGoals[date];
          let totalTimeLogged = "";
          const usersData = [];
          if (dateGoals) {
            Object.keys(dateGoals).map((userGoal, index) => {
              const totalTime = getTotalTime(
                dateGoals[userGoal].timeLogged?.includes("{") ||
                  dateGoals[userGoal].timeLogged?.includes("[") ||
                  !dateGoals[userGoal].timeLogged.length
                  ? "0m"
                  : dateGoals[userGoal].timeLogged
              );
              totalTimeLogged = `${totalTimeLogged},${totalTime}m`;

              usersData.push({
                name: `${dateGoals[userGoal].firstname} ${dateGoals[userGoal].lastname}`,
                value: convertMinutesToTimeLog(totalTime),
              });
            });
          }
          return {
            name: date,
            value: getTotalTime(totalTimeLogged),
            users: usersData,
          };
        });
        break;
      }
      case "Month":
        data = reportsGroupBy.value.map((month) => {
          const dateGoals = goals.filter(
            (goal) =>
              dayjs(goal.createdAt).isSame(dayjs(month, "MMMM"), "month") &&
              filterByAssignee(goal)
          );

          const usersData = userList.map((user) => ({
            name: getUsersName(user),
            value: convertMinutesToTimeLog(
              getTime(
                dateGoals.filter((goal) => goal.assigneeId == user.userId)
              )
            ),
          }));

          return {
            name: month,
            value: getTime(dateGoals),
            users: usersData,
          };
        });
        break;
      case "Year":
        data = reportsGroupBy.value.map((year) => {
          const dateGoals = goals.filter(
            (goal) =>
              dayjs(goal.createdAt).isSame(dayjs(year, "YYYY"), "year") &&
              filterByAssignee(goal)
          );

          const usersData = userList.map((user) => ({
            name: getUsersName(user),
            value: convertMinutesToTimeLog(
              getTime(
                dateGoals.filter((goal) => goal.assigneeId == user.userId)
              )
            ),
          }));

          return {
            name: year,
            value: getTime(dateGoals),
            users: usersData,
          };
        });
        break;
      default:
        break;
    }
    setData({
      xAxisLable: reportsGroupBy.key,
      yAxisLable: "Logged Time",
      value: data,
      total: totalTime,
    });
  };

  useEffect(() => {
    if (searchParams.get("report") === "ReportByTask") {
      generateDataForTask();
    } else {
      generateDataForTimeLogged();
    }
  }, [
    projectId,
    searchParams.get("report"),
    reportsGroupBy,
    filterGoalParameter,
    reportGoals,
  ]);

  const render = () => {
    if (searchParams.has("report")) {
      return <Reports report={searchParams.get("report")} data={data} />;
    }
    return (
      <div className="flex h-full p-6">
        <div
          onClick={() => handleNavigate("?report=ReportByTask")}
          className="flex items-center justify-center w-full h-full rounded cursor-pointer hover:bg-gray-200"
          role="button"
        >
          <div className="flex flex-col items-center">
            <img className="w-[126px]" src="/images/v2/common/report.svg" />
            <p className="mt-3 text-gray-700 out-500-14">
              View Reports by Task
            </p>
          </div>
        </div>
        <div
          onClick={() => handleNavigate("?report=ReportByTimeLog")}
          className="flex items-center justify-center w-full h-full rounded cursor-pointer hover:bg-gray-200"
          role="button"
        >
          <div className="flex flex-col items-center">
            <img className="w-[126px]" src="/images/v2/common/report.svg" />
            <p className="mt-3 text-gray-700 out-500-14">
              View Reports by Time Logged
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <ScheduleLayout>
      <div className="bg-white" id="schedule-tour-reports-timelogged">
        <ReportsMenu data={data} />
        <div className="h-[calc(100vh-170px)]  bg-gray-50">{render()}</div>
      </div>
    </ScheduleLayout>
  );
};

export default ReportsScreen;
