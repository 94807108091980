import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIconSecondary } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import axios from "axios";
import uniqid from "uniqid";
import { Divider, Upload } from "antd";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import CustomColorPicker from "../../../Core/CommonV2/CustomColorPicker";
import ImageCropModal from "../../../Core/CommonV2/ImageCropModal";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { updateProjectOnboarding } from "../../../Core/redux/slices/projectOnboarding.slice";
import getImageWeServeUrl from "../../../Core/utils/getImageWeServeUrl";

const ChangeImageModal = ({ visible, onClose, onOk, data, colorType = "" }) => {
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const dispatch = useAppDispatch();

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (visible) {
      setImage(data.image);
      setTitle(data.title);
      setColor(data.color);
    }
  }, [data]);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const fileData = [];

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
        });
      });
    });

    setImage(fileData[0]?.url || "");
    setIsDirty(true);
  };

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        // Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          console.log("UPLOADED", res.data);
          onSuccess(res.data);
        });
    } catch (err) {
      onError({ err });
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="524px"
      onCancel={() => onClose()}
      body={
        <div className="p-4 flex flex-col gap-y-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Change image</p>

            <button onClick={() => onClose()}>
              <CrossIconSecondary className="text-gray-700" />
            </button>
          </div>

          <div className="mt-[18px] flex items-center gap-x-6">
            {image && image.length > 0 ? (
              <img
                src={getImageWeServeUrl(image, {
                  w: "64",
                  h: "64",
                })}
                className="w-16 h-16 rounded-full cursor-pointer object-cover"
                onClick={async () => {
                  handleChange({ fileList: [] });
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.src =
                    "/images/icons/project-onboarding/cancelImage.svg";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.src = getImageWeServeUrl(image, {
                    w: "64",
                    h: "64",
                  });
                }}
              />
            ) : (
              <ImageCropModal>
                <Upload
                  action={process.env.REACT_APP_API_URL + "/v1/upload"}
                  // headers={{ Authorization: "Bearer " + user.token }}
                  customRequest={uploadImage}
                  fileList={fileList}
                  onChange={handleChange}
                  multiple={false}
                  showUploadList={false}
                >
                  <div className="w-16 h-16">
                    <img
                      src="/images/v2/onboarding/Logo.svg"
                      className="w-16 h-16"
                    />
                  </div>
                </Upload>
              </ImageCropModal>
            )}

            <div>
              <Divider type="vertical" className="h-6" />
              <p className="out-500-12 text-gray-500 text-center">or</p>
              <Divider type="vertical" className="h-6" />
            </div>

            <div className="flex gap-x-6 relative">
              {image && (
                <div className="absolute top-0 left-0 right-0 bottom-0 opacity-50 bg-white z-10" />
              )}
              <CustomAvatar
                title={title ? title : "P"}
                size={64}
                color={color}
                whiteText
                fontSize="24px"
              />

              {/* <img src="/images/icons/logo-w.svg" className="w-16 h-16" /> */}
              <CustomColorPicker
                placement="bottomLeft"
                onColorChange={(color) => {
                  setColor(color);
                  setIsDirty(true);
                  if (colorType) {
                    dispatch(
                      updateProjectOnboarding({
                        key: colorType,
                        value: color,
                      })
                    );
                  }
                }}
              >
                <div className="flex items-center cursor-pointer text-gray-900">
                  <p className="out-500-12 text-gray-500 mr-2">Change colour</p>
                  <button>
                    <ChevronIcon
                      className="text-gray-500"
                      height="20"
                      width="20"
                    />
                  </button>
                </div>
              </CustomColorPicker>
            </div>
          </div>

          <div className="flex items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-white border border-gray-200"
              height="30px"
              onClick={onClose}
            />
            <CustomButton
              text="Change"
              disabled={!isDirty}
              height="30px"
              onClick={() => {
                onOk(image, color);
                onClose();
              }}
            />
          </div>
        </div>
      }
    />
  );
};

export default ChangeImageModal;
