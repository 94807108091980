import { Drawer, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";

import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import { OpenInWindowIcon } from "../../../Core/svgV2/OpenInWindow";
import { DownloadIcon } from "../../../Core/svgV2/DownloadIcon";
import { EllipseIcon } from "../../../Core/svgV2/Ellipse";
import { ForwardIcon } from "../../../Core/svgV2/ForwardIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import { SaveIcon } from "../../../Core/svgV2/SaveIcon";
import dayjs from "dayjs";
import { formatBytes } from "../../../Core/utils/vaultUtils";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { downloadFiles } from "../../../Core/utils";
import {
  updateConversations,
  updateConversationsMultiple,
} from "../../../Core/redux/slices/conversations.slice";

const SharedFilesDrawer = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();

  const [files, setFiles] = useState([]);

  const { currentThreadMessage } = useAppSelector(
    (state) => state.conversations
  );

  useEffect(() => {
    const attachmentsWithMessageId = currentThreadMessage
      .filter((m) => m.attachments?.length > 0)
      .flatMap((newM) => {
        return newM.attachments.map((attachment) => ({
          ...attachment,
          messageId: newM._id,
          senderName: newM.senderName,
          createdAt: newM.createdAt,
          message: newM,
        }));
      });

    setFiles(attachmentsWithMessageId);
  }, [currentThreadMessage]);

  //TODO: Add the three dots code

  return (
    <Drawer
      title={null}
      closable={false}
      placement="right"
      open={visible}
      width={"318px"}
    >
      <div className="p-4 flex flex-col h-full relative">
        <div className="flex-1">
          <button onClick={onClose} className="absolute top-4">
            <CrossIcon className="text-gray-700" />
          </button>
          <div className="flex  w-full justify-center flex-col items-center">
            <img src="/images/v2/conversations/file-attachment-05.svg" />
            <p className="out-500-14 mt-[6px]">Shared Files</p>
          </div>

          <div className="mt-8">
            {files.map((file, i) => {
              return (
                <div
                  key={i}
                  className="flex justify-between items-center py-[14px]"
                >
                  <div className="flex truncate items-center">
                    <img src="/images/v2/conversations/file.svg" />
                    <p className="out-500-14 ml-4 truncate text-gray-700">
                      {file.name}
                    </p>
                  </div>
                  <div className="flex items-center gap-x-[12px]">
                    <button
                      onClick={() => {
                        downloadFiles([file.url], file.fileSize);
                      }}
                    >
                      {" "}
                      <DownloadIcon className="text-gray-700" />{" "}
                    </button>
                    <button>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: (
                                <div
                                  onClick={() =>
                                    window.open(
                                      file.url.replace(/\?download=1$/, ""),
                                      "_blank"
                                    )
                                  }
                                  className="out-300-14 text-gray-700"
                                >
                                  Open in new window{" "}
                                </div>
                              ),
                              icon: (
                                <OpenInWindowIcon className="text-gray-700" />
                              ),
                            },
                            {
                              key: "2",
                              label: (
                                <div
                                  onClick={() => {
                                    downloadFiles([file.url], file.fileSize);
                                  }}
                                  className="out-300-14 text-gray-700"
                                >
                                  Download
                                </div>
                              ),
                              icon: <DownloadIcon className="text-gray-700" />,
                            },
                            {
                              key: "3",
                              label: (
                                <div
                                  onClick={() =>
                                    dispatch(
                                      updateConversations({
                                        key: "sharedFileDetailsDrawer",
                                        value: {
                                          visible: true,
                                          data: {
                                            ...file,
                                            senderName: file.senderName,
                                            createdAt: file.createdAt,
                                          },
                                        },
                                      })
                                    )
                                  }
                                  className="out-300-14 text-gray-700"
                                >
                                  view details
                                </div>
                              ),
                              icon: <EllipseIcon className="text-gray-700" />,
                            },
                            {
                              key: "4",
                              label: (
                                <div
                                  onClick={() => {
                                    dispatch(
                                      updateConversationsMultiple([
                                        {
                                          key: "shareMessageModal",
                                          value: true,
                                        },
                                        {
                                          key: "forwardMessage",
                                          value: {
                                            ...file.message,
                                            attachments:
                                              file.message.attachments.filter(
                                                (a) => a.url === file.url
                                              ),
                                          },
                                        },
                                        {
                                          key: "shareType",
                                          value: "forwardMessage",
                                        },
                                      ])
                                    );
                                  }}
                                  className="out-300-14 text-gray-700"
                                >
                                  Share in message
                                </div>
                              ),
                              icon: <ForwardIcon className="text-gray-700" />,
                            },
                            !file.savedInVault
                              ? {
                                  key: "5",
                                  label: (
                                    <div
                                      onClick={() =>
                                        dispatch(
                                          updateConversations({
                                            key: "saveInLibrary",
                                            value: {
                                              visible: true,
                                              file: file,
                                            },
                                          })
                                        )
                                      }
                                      className="out-300-14 text-gray-700"
                                    >
                                      Save in library
                                    </div>
                                  ),
                                  icon: <SaveIcon className="text-gray-700" />,
                                }
                              : null,
                            { key: "6", type: "divider" },
                            {
                              key: "7",
                              label: (
                                <div
                                  onClick={() => {
                                    dispatch(
                                      updateConversationsMultiple([
                                        {
                                          key: "deleteFileModal",
                                          value: true,
                                        },
                                        {
                                          key: "deleteFileInMessage",
                                          value: file,
                                        },
                                      ])
                                    );
                                  }}
                                  className="out-300-14 text-gray-700  "
                                >
                                  Delete
                                </div>
                              ),
                              icon: <DeleteIcon className="text-gray-700 " />,
                            },
                          ],
                        }}
                        trigger={["click"]}
                      >
                        <DotsVerticalIcon className="text-gray-700" />
                      </Dropdown>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export const SharedFileDetailsDrawer = ({ visible, data, onClose }) => {
  return (
    <Drawer
      title={null}
      closable={false}
      placement="right"
      open={visible}
      width={"318px"}
    >
      <div className="p-4 flex flex-col h-full relative">
        <div className="flex-1">
          <button onClick={onClose} className="absolute top-4">
            <CrossIcon className="text-gray-700" />
          </button>

          <div className="flex  w-full justify-center flex-col items-center">
            <img src="/images/v2/conversations/file.svg" />
            <p className="out-500-14 mt-[13px]">{data.name}</p>
          </div>

          <div className="mt-[34px]">
            <img className="h-[195px] w-[286px] object-cover" src={data.url} />
          </div>

          <div className="mt-4">
            <p className="out-400-12 text-black">
              Sent by{" "}
              <span className="out-500-12 text-black">{data.senderName}</span>{" "}
              on{" "}
              <span className="out-500-12 text-black">
                {data.createdAt
                  ? dayjs(data.createdAt).utc().local().format("MMMM DD, YYYY")
                  : "-"}
              </span>{" "}
              at{" "}
              <span className="out-500-12 text-black">
                {" "}
                {data.createdAt
                  ? dayjs(data.createdAt).utc().local().format("hh:mm a")
                  : "-"}
              </span>
            </p>
            <span className="out-500-12 text-black mt-2">
              {formatBytes(data.size)}
            </span>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default SharedFilesDrawer;
