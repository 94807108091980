import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import Timeline from "../Timeline/Timeline";
import dayjs from "dayjs";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";

const EventsModal = ({ visible, onCancel }) => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const dispatch = useAppDispatch();
  const HourArray = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
  return (
    <CustomModal
      visible={visible}
      width="1127px"
      onCancel={() => onCancel()}
      body={
        <div className="pl-6 py-6 relative">
          <div className="flex justify-between items-center pr-6">
            <h4 className="out-500-16 text-black">Events</h4>
            <button onClick={() => onCancel()}>
              <CrossIcon className="text-gray-500" />
            </button>
          </div>
          <p className="out-300-14 text-gray-500">
            Check what your day looks like. Create and manage events{" "}
          </p>
          <div className="flex items-center mt-4">
            <span className="out-500-16 text-black">
              {currentDate.format("DD MMM YYYY")}
            </span>
            <div className="flex ml-2 gap-x-2">
              <button
                onClick={() => setCurrentDate(currentDate.add(-1, "days"))}
              >
                <ChevronIcon className="rotate-[90deg]" />
              </button>
              <button
                onClick={() => setCurrentDate(currentDate.add(1, "days"))}
              >
                <ChevronIcon className="rotate-[-90deg]" />
              </button>
            </div>
          </div>
          <div className="mt-8 max-h-[70vh] overflow-y-auto">
            <Timeline
              currentDate={currentDate}
              popoverPlacement="top"
              hourArray={HourArray}
            />
          </div>
          <div
            role="button"
            onClick={() => {
              dispatch(
                updateDashboard({
                  key: "newEventModal",
                  value: {
                    visible: true,
                    startTime: "",
                    endTime: "",
                    date: currentDate.format("DD-MM-YYYY"),
                  },
                })
              );
            }}
            className="absolute w-[30px] h-[30px] bg-white rounded border border-gray-200 flex item-center justify-center bottom-5 right-[20px] z-[999]"
          >
            <PlusIcon className="text-gray-600" />
          </div>
        </div>
      }
    />
  );
};

export default EventsModal;
