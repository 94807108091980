import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import { Checkbox } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { getAllowedNotification } from "../../../Core/redux/api/notificationAPI";
import { client } from "../../../Core/utils/axiosClient";
import { requestPermission } from "../../../FireBase/firebaseInit";

const TableHeader = ({ title, mobile = false }) => {
  return (
    <div className="flex gap-x-9 py-4 border-b border-gray-200">
      <div className="out-500-14 text-black flex-1">{title}</div>
      <div className="out-500-14 text-black w-[45px]">Email</div>
      <div className="out-500-14 text-black w-[45px]">In-app</div>
      <div className="out-500-14 text-black w-[45px]">Browser</div>
      <div className="out-500-14 text-black w-[45px]">{mobile && "Mobile"}</div>
    </div>
  );
};

const TableRow = ({
  title,
  mobile = false,
  notificationType = null,
  value = { email: true, inApp: true, browser: true, mobile: true },
}) => {
  const dispatch = useAppDispatch();

  const handleAddNotifcation = async (key, value) => {
    if (!notificationType) return;
    try {
      await client.post("/notifications/allow-notification", {
        notificationType,
        [key]: value,
      });
      dispatch(getAllowedNotification());
    } catch (err) {
      console.log(err);
    }
  };

  console.log(value, "value");
  return (
    <div className="flex gap-x-9 py-3 pl-3 ">
      <div className="out-300-12 text-gray-700 flex-1">{title}</div>
      <div className="flex justify-center items-center w-[45px]">
        <Checkbox
          checked={value.email}
          onChange={(e) => handleAddNotifcation("email", e.target.checked)}
        />
      </div>
      <div className="flex justify-center items-center w-[45px]">
        <Checkbox
          checked={value.inApp}
          onChange={(e) => handleAddNotifcation("inApp", e.target.checked)}
        />
      </div>
      <div className="flex justify-center items-center w-[45px]">
        <Checkbox
          checked={value.browser}
          onChange={(e) => handleAddNotifcation("browser", e.target.checked)}
        />
      </div>
      <div className="flex justify-center items-center w-[45px]">
        {mobile && (
          <Checkbox
            checked={value.mobile}
            onChange={(e) => handleAddNotifcation("mobile", e.target.checked)}
          />
        )}
      </div>
    </div>
  );
};

const AllowNotificationPopup = () => {
  const [fcmTokenFound, setFCMTokenFound] = useState(false);
  const { loggedIn } = useAppSelector((state) => state.userDetails);
  const [isFireBaseServiceBaseRegisterd, setIsFireBaseServiceBaseRegisterd] =
    useState(false);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistration("firebase-messaging-sw.js")
        .then(function (registration) {
          console.log(
            "firebase-messaging-sw.js",
            registration,
            Notification.permission
          );
          if (registration) {
            setIsFireBaseServiceBaseRegisterd(true);
          }
        });
    }
  }, [Notification.permission]);

  if (!loggedIn) {
    return null;
  }

  if (Notification.permission === "denied") {
    return (
      <div className=" text-white flex justify-between out-500-16 bg-red-400 mt-5 px-4 py-2 rounded ">
        <span>You have denied permission to show desktop notifications</span>
        <span
          className="underline cursor-pointer out-500-14"
          onClick={() => {
            requestPermission(setFCMTokenFound);
          }}
          role="button"
        >
          Learn More
        </span>
      </div>
    );
  }

  if (Notification.permission === "granted") {
    return (
      <div className=" px-4 py-2 bg-primary-100 flex justify-between out-500-16 mt-5 rounded">
        Desktop notifications for waddle are enabled!
      </div>
    );
  }

  return (
    <div className=" px-4 py-2 bg-primary-100 flex justify-between out-500-16 mt-5 rounded">
      <div>
        <span>Waddle needs your permission to </span>
        <span
          className="underline cursor-pointer"
          onClick={() => {
            requestPermission(setFCMTokenFound);
          }}
          role="button"
        >
          enable desktop notifications.
        </span>
      </div>

      <span className="uppercase hidden">
        Status : {Notification.permission}
      </span>
    </div>
  );
};

const Notifications = () => {
  const dispatch = useAppDispatch();

  const { allowedNotifications } = useAppSelector((state) => state.userDetails);

  useEffect(() => {
    dispatch(getAllowedNotification());
  }, []);

  const getValue = (notificationType) => {
    const current = allowedNotifications.find(
      (n) => n.notificationType == notificationType
    );
    return current || { email: true, inApp: true, browser: true, mobile: true };
  };
  return (
    <div>
      <PageTitle
        title={"Notifications"}
        subtext={"Manage where and when you want to be notified."}
      />

      <AllowNotificationPopup />

      <div className="w-[677px] mt-2">
        <TableHeader title={"General"} />
        <div className="py-2.5 border-b border-gray-200">
          <TableRow
            title={"New Project Role Assigned "}
            notificationType={22}
            value={getValue(22)}
          />
          <TableRow
            title={"Project deadline reminders"}
            notificationType={23}
            value={getValue(23)}
          />
          <TableRow
            title={"Project progress updates"}
            notificationType={34}
            value={getValue(34)}
          />
          <TableRow
            title={"When a project I’m added to, is deleted"}
            notificationType={29}
            value={getValue(29)}
          />
        </div>
        <TableHeader title={"Schedule"} />
        <div className="py-2.5 border-b border-gray-200">
          <TableRow
            title={"Task assigned to me"}
            notificationType={24}
            value={getValue(24)}
          />
          <TableRow
            title={"Made Reporter to task "}
            notificationType={27}
            value={getValue(27)}
          />
          <TableRow
            title={"Bugs assigned to me"}
            notificationType={9}
            value={getValue(9)}
          />
          <TableRow
            title={"Made Reporter to bug "}
            notificationType={26}
            value={getValue(26)}
          />
          {/* <TableRow title={"New scope assigned to me"} /> */}
          {/* <TableRow title={"Invited to an event"} />
          <TableRow title={"Changes in event"} />
          <TableRow title={"Event reminder"} /> */}
          <TableRow
            title={"Added as an approver to waddle a task"}
            notificationType={30}
            value={getValue(30)}
          />
          <TableRow
            title={"Waddle it changes"}
            notificationType={31}
            value={getValue(31)}
          />
          <TableRow
            title={"Assignee changes"}
            notificationType={32}
            value={getValue(32)}
          />
          <TableRow
            title={"Status changes"}
            notificationType={33}
            value={getValue(33)}
          />
          {/* <TableRow title={"Dependency changes on bugs assigned to me"} /> */}
          <TableRow
            title={"New comments on tasks/bugs/scope assigned to me"}
            notificationType={1}
            value={getValue(1)}
          />
          {/* <TableRow title={"Comments I’m mentioned in"} /> */}
          <TableRow
            title={"New sprint started/ended"}
            notificationType={10}
            value={getValue(10)}
          />
          <TableRow
            title={"Changes in sprint dates"}
            notificationType={35}
            value={getValue(35)}
          />
          <TableRow
            title={"New phase started/ended"}
            notificationType={11}
            value={getValue(11)}
          />
          {/* <TableRow title={"Phase deleted"} /> */}
          <TableRow
            title={"Changes in phase dates"}
            notificationType={36}
            value={getValue(36)}
          />
        </div>
        <TableHeader title={"Library"} />
        <div className="py-2.5 border-b border-gray-200">
          <TableRow
            title={"Access shared with me"}
            notificationType={16}
            value={getValue(16)}
          />
          <TableRow
            title={"New comments"}
            notificationType={1}
            value={getValue(1)}
          />
          {/* <TableRow
            title={"Comments I’m mentioned in"}
            notificationType={1}
            value={getValue(1)}
          /> */}
        </div>
        <TableHeader title={"Messages"} mobile />
        <div className="py-2.5 border-b border-gray-200">
          <TableRow
            title={"Added in a group"}
            mobile
            notificationType={13}
            value={getValue(13)}
          />
          <TableRow
            title={"Unread messages"}
            mobile
            notificationType={25}
            value={getValue(25)}
          />
          <TableRow
            title={"New Message"}
            mobile
            notificationType={14}
            value={getValue(14)}
          />
          <TableRow
            title={"Tagged in a new request"}
            mobile
            notificationType={37}
            value={getValue(37)}
          />
          <TableRow
            title={"New replies on my request"}
            mobile
            notificationType={38}
            value={getValue(38)}
          />
          <TableRow
            title={"Closed a request I’m tagged in"}
            mobile
            notificationType={39}
            value={getValue(39)}
          />
          {/* <TableRow title={"Changes in request custom fields"} mobile /> */}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
