import dayjs from "dayjs";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";

import usePermission from "../../Core/hooks/usePermission";
//todo current timezone and time
const ProjectInfo = ({ projectDetails, projectList }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  // const { projectList } = useAppSelector((state) => state.projects);
  const {
    clientName,
    clientImage,
    clientLocation,
    clientColor,
    colorCode,
    title,
    startDate,
    endDate,
    projectImage,
  } = projectDetails.projectDetails;

  const { hasPermission } = usePermission("1");

  return (
    <div className="w-[40%] border-r h-[492px] py-6 pl-6 ">
      <div className=" w-[95%]   h-full  ">
        <div className="flex  justify-between  items-center pb-5 ">
          <div className="flex out-500-16">Project Info</div>
          {hasPermission && (
            <div
              className="flex text-primary-700 out-500-14"
              role="button"
              onClick={() =>
                navigate(`/settingsV2?tab=General&projectId=${projectId}`)
              }
            >
              Edit
            </div>
          )}
        </div>
        <div className="flex flex-col  gap-y-5">
          <div className="flex flex-col  h-12 gap-y-2 ">
            <div className="h-5 out-300-14 text-gray-500">Project Name</div>
            <div className="out-500-14 flex  gap-x-2">
              <CustomAvatar
                size={24}
                title={title}
                whiteText
                color={colorCode}
                src={projectImage || ""}
              />

              {title}
            </div>
          </div>
          <div className="flex flex-col h-12 gap-y-2 ">
            <div className="h-5 out-300-14 text-gray-500">Start date</div>
            <div className="out-500-14">
              {dayjs(startDate).format("DD MMMM YYYY")}
            </div>
          </div>
          <div className="flex flex-col h-12 gap-y-2 ">
            <div className="h-5 out-300-14 text-gray-500">End date</div>
            <div className="out-500-14">
              {" "}
              {dayjs(endDate).format("DD MMMM YYYY")}
            </div>
          </div>
          <div className="flex flex-col h-12 gap-y-2 ">
            <div className="h-5 out-300-14 text-gray-500">Client Name</div>
            <div className="out-500-14 flex  gap-x-2">
              <CustomAvatar
                size={24}
                title={clientName}
                whiteText
                color={clientColor}
                src={clientImage || ""}
              />
              {clientName}
            </div>
          </div>
          <div className="flex flex-col h-12 gap-y-2 ">
            <div className="h-5 out-300-14 text-gray-500">Based in</div>
            <div className="out-500-14 flex  gap-x-2">
              {/* <img src="/images/v2/dashboard/KE.svg" /> */}
              {clientLocation}
            </div>
          </div>
          <div className="flex flex-col h-12 gap-y-2 ">
            <div className="h-5 out-300-14 text-gray-500">
              Current time & timezone
            </div>
            <div className="out-500-14">{`${dayjs.tz.guess()}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;
