import React from "react";
import Icon from "@ant-design/icons";

const UserSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 8.4a3 3 0 100-6 3 3 0 000 6z"
    ></path>
    <path
      fill="currentColor"
      d="M13.6 14v.5a.5.5 0 00.5-.5h-.5zM2.4 14h-.5a.5.5 0 00.5.5V14zm.5 0c0-.903.525-1.766 1.457-2.425C5.288 10.918 6.586 10.5 8 10.5v-1c-1.601 0-3.104.471-4.22 1.258C2.669 11.544 1.9 12.681 1.9 14h1zM8 10.5c1.414 0 2.711.418 3.642 1.075.933.659 1.458 1.522 1.458 2.425h1c0-1.32-.768-2.456-1.881-3.242C11.104 9.971 9.601 9.5 7.999 9.5v1zm-5.6 4H8v-1H2.4v1zm5.6 0h5.6v-1H8v1z"
    ></path>
  </svg>
);

const ShareAccessSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    fill="none"
    viewBox="0 0 20 21"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 11.5a3 3 0 100-6 3 3 0 000 6z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M14.5 2.5v5M12 5h5"
    ></path>
    <path
      fill="currentColor"
      d="M15 17.5v.5a.5.5 0 00.5-.5H15zm-12 0h-.5a.5.5 0 00.5.5v-.5zm.5 0c0-.887.553-1.75 1.564-2.416C6.069 14.422 7.47 14 9 14v-1c-1.701 0-3.3.467-4.487 1.25-1.182.778-2.013 1.915-2.013 3.25h1zM9 14c1.53 0 2.931.422 3.936 1.084 1.01.666 1.564 1.53 1.564 2.416h1c0-1.335-.831-2.472-2.013-3.25C12.3 13.466 10.7 13 9 13v1zm-6 4h6v-1H3v1zm6 0h6v-1H9v1z"
    ></path>
  </svg>
);

const GroupUserSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    fill="none"
    viewBox="0 0 14 12"
  >
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.4 6.301A2.4 2.4 0 009.4 2"
    ></path>
    <path
      fill="#344054"
      d="M9.4 10.8v.5a.5.5 0 00.5-.5h-.5zm-8.8 0H.1a.5.5 0 00.5.5v-.5zM2.11 8.4l-.27-.422.27.422zM3.8 7.724l.103.489-.103-.49zM5 8.1c1.09 0 2.085.328 2.797.839.713.512 1.103 1.176 1.103 1.86h1c0-1.093-.625-2.029-1.52-2.672C7.484 7.483 6.28 7.1 5 7.1v1zm-3.9 2.7c0-.738.456-1.455 1.277-1.979l-.537-.843C.827 8.624.1 9.618.1 10.8h1zm-.5.5h2.2v-1H.6v1zm2.2 0H5v-1H2.8v1zM2.377 8.82a4.621 4.621 0 011.526-.608l-.206-.978a5.62 5.62 0 00-1.857.743l.537.843zm1.526-.608C4.253 8.139 4.622 8.1 5 8.1v-1c-.448 0-.886.047-1.303.135l.206.978zM5 11.3h4.4v-1H5v1zM13.4 10.799h.5a.5.5 0 01-.5.5v-.5zm-1.508-2.4l.269-.422-.27.421zm-.492 2.9a.5.5 0 110-1v1zm-1.303-3.087a.5.5 0 11.206-.979l-.206.979zm2.803 2.587c0-.739-.456-1.455-1.277-1.979l.538-.843c1.012.645 1.74 1.64 1.74 2.822h-1zm.5.5h-2v-1h2v1zM11.623 8.82a4.622 4.622 0 00-1.526-.608l.206-.979a5.62 5.62 0 011.857.744l-.537.843z"
    ></path>
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.371 6a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z"
    ></path>
  </svg>
);

export const UserEditIcon = ({ className = "text-[#344054]", style = {} }) => {
  return (
    <span role="img" className={`anticon ${className}`} style={style}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.00065 10.3333H5.00065C4.07028 10.3333 3.60509 10.3333 3.22656 10.4482C2.37429 10.7067 1.70734 11.3736 1.44881 12.2259C1.33398 12.6044 1.33398 13.0696 1.33398 14M9.66732 5C9.66732 6.65685 8.32417 8 6.66732 8C5.01046 8 3.66732 6.65685 3.66732 5C3.66732 3.34315 5.01046 2 6.66732 2C8.32417 2 9.66732 3.34315 9.66732 5ZM7.33398 14L9.40155 13.4093C9.50056 13.381 9.55007 13.3668 9.59624 13.3456C9.63724 13.3268 9.67622 13.3039 9.71259 13.2772C9.75354 13.2471 9.78995 13.2107 9.86276 13.1379L14.1673 8.83336C14.6276 8.37311 14.6276 7.62689 14.1673 7.16665C13.7071 6.70642 12.9609 6.70642 12.5007 7.16666L8.1961 11.4712C8.12328 11.544 8.08688 11.5804 8.05681 11.6214C8.03011 11.6578 8.00718 11.6967 7.98835 11.7377C7.96715 11.7839 7.95301 11.8334 7.92472 11.9324L7.33398 14Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
const UserIcon = (props) => {
  return <Icon component={UserSvg} {...props} />;
};
const GroupUserIcon = (props) => {
  return <Icon component={GroupUserSvg} {...props} />;
};

const ShareAccessIcon = (props) => {
  return <Icon component={ShareAccessSvg} {...props} />;
};

export { UserIcon, GroupUserIcon, ShareAccessIcon };
