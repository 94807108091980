import React from "react";

const ClockIcon = ({
  className = "",
  height = "20",
  width = "20",
  style = {},
}) => (
  <span role="img" className={`anticon ${className}`} style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 5v5l3.333 1.667m5-1.667a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z"
      ></path>
    </svg>
  </span>
);

export default ClockIcon;
