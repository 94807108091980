import React from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { CustomOnboardingDateRangePicker } from "../../../Core/Common/CustomOnboardingDatePicker";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  updateProjectOnboarding,
  changeStep,
  compeleteResetProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";
import CustomAddressInput from "../../../Core/Common/CustomAddressInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";

const Step3 = () => {
  const dispatch = useAppDispatch();
  const { projectName, projectDuration, clientLocation } = useAppSelector(
    (state) => state.projectOnboarding
  );

  const formik = useFormik({
    initialValues: {
      projectDuration: projectDuration,
      clientLocation,
    },
    validationSchema: Yup.object({
      projectDuration: Yup.array()
        .min(2, "Please enter the duration to continue")
        .required("Please enter the duration to continue")
        .typeError("Please enter the duration to continue"),
      clientLocation: Yup.string()
        .required("Please enter the location to continue")
        .typeError("Please enter the location to continue"),
      locationSubName: Yup.string(),
      cords: Yup.object().shape({
        lat: Yup.number(),
        long: Yup.number(),
      }),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(changeStep(4));
    },
  });
  const navigate = useNavigate();
  return (
    <StepLayout>
      <CurrentStep className={"mt-20"} currentStep={3} totalStep={6} />
      <StepHeading
        title={
          <p
            className="text-gray-900 out-500-20 max-w-[900px] truncate flex  "
            id="stepHead"
          >
            Let’s add more information about &apos;
            <span className="max-w-[800px]  truncate">{projectName}</span>
            &apos;
          </p>
        }
        subTitle={
          <p className="min-w-[405px]">Start date, end date and location</p>
        }
      />

      <div className="mt-[18px]">
        <p className="out-500-14 text-gray-900 text-base">Set the duration</p>

        <CustomOnboardingDateRangePicker
          {...formik.getFieldProps("projectDuration")}
          onClear={() =>
            dispatch(
              updateProjectOnboarding({ key: "projectDuration", value: null })
            )
          }
          onChange={(e) => {
            formik.setFieldValue("projectDuration", e);
            dispatch(
              updateProjectOnboarding({ key: "projectDuration", value: e })
            );
          }}
          className="mt-2.5"
          value={projectDuration !== null ? projectDuration : ""}
          formikHook={formik}
        />
      </div>

      <div className="mt-[18px]">
        <p className="out-500-14 text-gray-900 text-base">
          Which city is your client based in?
        </p>

        <CustomAddressInput
          placeholder="City, Country"
          width="100%"
          className={
            clientLocation?.length > 0
              ? "border-b-2 border-primary-600"
              : " border-b-2 border-gray-400"
          }
          value={clientLocation}
          name="clientLocation"
          {...formik.getFieldProps("clientLocation")}
          onChange={(value) => {
            formik.setFieldValue("clientLocation", value);
            dispatch(updateProjectOnboarding({ key: "clientLocation", value }));
          }}
          onSelect={({ locationName, locationSubName, lat, long }) => {
            formik.setFieldValue("clientLocation", locationName);
            formik.setFieldValue("locationSubName", locationSubName);
            formik.setFieldValue("cords", { lat, long });
          }}
          formikHook={formik}
        />
      </div>

      <div className="mt-6 flex justify-between items-center">
        <CustomButton
          text="Cancel"
          width="75px"
          height="30px"
          className={`bg-gray-100 out-500-14 text-gray-700 `}
          onClick={() => {
            dispatch(compeleteResetProjectOnboarding({}));
            navigate("/dashboard");
          }}
        />
        <div className="flex gap-x-2">
          <CustomButton
            text="Back"
            onClick={() => dispatch(changeStep(2))}
            className=" text-gray-700 bg-gray-100 out-500-14"
            height="30px"
            width="63px"
          />
          <CustomButton
            text="Continue"
            width="88px"
            height="30px"
            className={`bg-primary-600 out-500-14 text-white ${
              !formik.isValid ? "opacity-30" : ""
            }`}
            onClick={() => formik.handleSubmit()}
          />
        </div>
      </div>
    </StepLayout>
  );
};

export default Step3;
