import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { updateFootprint } from "../../../Core/redux/slices/footprint.slice";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getAllTourStatus,
  updateTourStatus,
} from "../../../Core/redux/api/dashboardAPI";

const WelcomeModal = () => {
  const { welcomeModal } = useAppSelector((state) => state.footPrintDetails);
  const { user } = useAppSelector((state) => state.userDetails);
  const { toursPopup } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  console.log(toursPopup?.footprintOnboard);

  return (
    <CustomModal
      visible={welcomeModal && toursPopup?.footprintOnboard !== true}
      width="600px"
      onCancel={() => {
        dispatch(updateFootprint({ key: "welcomeModal", value: false }));
      }}
      body={
        <div className="relative w-full h-full bg-white rounded-2xl shadow-lg overflow-hidden">
          <button
            className="absolute top-[10px] right-[10px]"
            onClick={() => {
              dispatch(updateFootprint({ key: "welcomeModal", value: false }));
              dispatch(
                updateTourStatus({
                  tourStatus: "myFootprintTourStatus",
                  userId: user.id,
                })
              );
              dispatch(getAllTourStatus({ userId: user.id }));
            }}
          >
            <CrossIcon className="text-[#667085]" />
          </button>
          <div className="w-full flex justify-center items-center bg-grey-300">
            <img
              src="/images/v3/footprint/taketour.png"
              alt="take tour"
              className="w-[600px] min-h-[405px] object-cover object-left"
            />
          </div>
          <div className="flex flex-col items-center gap-2 px-6 pt-3 justify-center">
            <p className="text-stone-900 text-2xl font-medium font-['Outfit'] leading-loose text-center">
              Welcome to My Footprint!
            </p>
            <p className="text-slate-600 out-14-300 text-center">
              Track your digital carbon footprint generated by your daily usage
              of Waddle and start your journey towards digital sustainability!
            </p>
            <div className="mt-3 pb-6">
              <CustomButton
                text="Let’s go!"
                width="100px"
                height="30px"
                onClick={() => {
                  dispatch(
                    updateFootprint({ key: "welcomeModal", value: false })
                  );
                  dispatch(
                    updateTourStatus({
                      tourStatus: "myFootprintTourStatus",
                      userId: user.id,
                    })
                  );
                  dispatch(getAllTourStatus({ userId: user.id }));
                }}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default WelcomeModal;
