import { Dropdown } from "antd";
import React from "react";
import { ThreadIcon } from "../../Core/svgV2/ThreadIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";
import { useAppDispatch } from "../../Core/redux/hooks";

const ChatEmptyState = () => {
  const dispatch = useAppDispatch();
  const items = [
    {
      key: "3",
      label: (
        <div
          onClick={() =>
            dispatch(
              updateConversations({
                key: "newGroupModal",
                value: true,
              })
            )
          }
          className="out-300-14 text-gray-700"
        >
          Create new group
        </div>
      ),
      icon: <ThreadIcon />,
    },
  ];
  return (
    <div className="h-full w-full relative">
      <div className=" absolute right-[26px] top-[13px]">
        {" "}
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button>
            <DotsVerticalIcon className="text-gray-500" />
          </button>
        </Dropdown>
      </div>
      <div className="flex justify-center items-center w-full h-full">
        <div>
          <img className="" src="/images/v2/conversations/empty.svg" />
          <div className="out-300-14 text-gray-500 text-center">
            Hey, open an existing chat and catch up, or <br />
            start a new conversation with someone?
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatEmptyState;
