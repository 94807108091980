/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getActivityMonitorChartData = createAsyncThunk(
  "myFootprint/getActivityMonitorChartDataController",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`/my-footprint/get-dcf-activity`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getDailyDCFAverageOfUser = createAsyncThunk(
  "myFootprint/getDailyDCFAverageOfUser",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`/my-footprint/get-average-dcf`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getTodaysDcfActivity = createAsyncThunk(
  "myFootprint/getTodaysDcfActivity",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`/my-footprint/get-todays-dcf`);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getSelectedMonthActivity = createAsyncThunk(
  "myFootprint/getSelectedMonthActivity",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(
        `/my-footprint/get-selected-month-dcf`,
        {
          params: payload,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getTotalDCFDetails = createAsyncThunk(
  "myFootprint/getTotalDCFDetails",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`/my-footprint/get-total-dcf`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createDcfActivityLog = createAsyncThunk(
  "myFootprint/createDcfActivityLog",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(
        `/my-footprint/create-dcf-activity-log`,
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
