import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { ProjectStartDateEndDate } from "../TimeEstimatesReusable";

const ProjectDateModal = ({
  onCancel,
  visible,
  startDate,
  endDate,
  onSave,
}) => {
  const [newStartDate, setNewStartDate] = useState(startDate);
  const [newEndDate, setNewEndDate] = useState(endDate);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    setNewStartDate(startDate);
    setNewEndDate(endDate);
  }, [startDate, endDate]);

  const handleSave = () => {
    onSave(newStartDate, newEndDate);
    onCancel();
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options);
  };

  const isSaveDisabled =
    formatDate(newStartDate) === formatDate(startDate) &&
    formatDate(newEndDate) === formatDate(endDate);

  return (
    <CustomModal
      visible={visible}
      width="352px"
      onCancel={() => onCancel()}
      body={
        <div className="w-full inline-flex flex-col items-start gap-4 p-3.5 rounded-lg bg-[#F9FBFE] shadow-lg">
          <div className="flex justify-end items-center">
            <div className="text-[#292927] text-sm font-medium leading-5 font-outfit">
              Edit Project Dates
            </div>
          </div>

          <div className="flex flex-col items-start w-[320px]">
            <div className="flex flex-col items-start gap-1.5 self-stretch">
              <div className="flex flex-col items-start gap-1.5 self-stretch">
                <div className="text-[#344054] text-sm font-medium leading-5 font-outfit">
                  Start date
                </div>
                <ProjectStartDateEndDate
                  startDate={startDate}
                  endDate={endDate}
                  type={"Start"}
                  onChange={setNewStartDate}
                />
              </div>
            </div>
          </div>

          <div className="flex w-[320px] flex-col items-start">
            <div className="flex flex-col items-start gap-1.5 self-stretch">
              <div className="flex flex-col items-start gap-1.5 self-stretch">
                <div
                  className="text-[#344054] text-sm font-medium leading-5"
                  style={{ fontFamily: "Outfit, sans-serif" }}
                >
                  End date
                </div>
                <ProjectStartDateEndDate
                  startDate={startDate}
                  endDate={endDate}
                  type={"End"}
                  onChange={setNewEndDate}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end items-start gap-3 self-stretch">
            <div className="flex items-start rounded-lg">
              <div
                className="flex p-1.5 justify-center items-center gap-2 rounded cursor-pointer"
                onClick={() => onCancel()}
              >
                <div
                  className="text-[#667085] text-sm font-medium leading-5"
                  style={{ fontFamily: "Outfit, sans-serif" }}
                >
                  Cancel
                </div>
              </div>
            </div>

            <div className="flex items-start rounded-lg">
              <div
                className={`w-[60px] flex p-1.5 justify-center items-center gap-2 rounded border ${
                  isSaveDisabled
                    ? "border-gray-300 bg-gray-300"
                    : "border-[#165ABF] bg-[#165ABF]"
                } shadow-xs cursor-pointer`}
                onClick={!isSaveDisabled ? handleSave : null}
              >
                <div
                  className="text-[#F9FBFE] text-sm font-medium leading-5"
                  style={{ fontFamily: "Outfit, sans-serif" }}
                >
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ProjectDateModal;
