import React, { useEffect, useState } from "react";
import SprintMenu from "./SprintMenu";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

import ScheduleLayout from "../Components/ScheduleLayout";

import {
  getGoalsBySprint,
  getProjectPhases,
  getSprintsByPhase,
  getSwimlanes,
} from "../../../Core/redux/api/scheduleAPI";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import {
  getPriorityDetails,
  getStatusDetails,
} from "../../../Core/utils/scheduleUtils";
import dayjs from "dayjs";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { Pagination, Tooltip } from "antd";
import TaskTypeIcon from "../../../Core/svgV2/TaskTypeIcon";
import { getPhaseName } from "../../../Core/utils/getPhaseName";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import CustomTable from "../../../Core/CommonV2/CustomTable";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import EditSprintModal from "../Modals/EditSprintModal";
import usePermission from "../../../Core/hooks/usePermission";
import ConfirmSprintDates from "../Modals/ConfirmSprintDates";
import SprintAccordian from "../Planner/SprintAccordian";
import CustomPagination from "../../../Core/CommonV3/CustomPagination";
import { DEFAULT_LIMIT } from "../../../Core/constants";
import {
  CreateTaskHeader,
  PrimaryTaskHeader,
  SelectedTaskHeader,
} from "../Planner/BacklogTaskHeader";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import DeleteTaskModal from "../Modals/DeleteTaskModal";
import ExportModal from "../Modals/ExportModal";
import DeleteSprintModal from "../Modals/DeleteSprintModal";
import CompleteSprintModal from "../Modals/CompleteSprintModal";
const EmptyState = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId, sprintId } = useParams();
  const { phases } = useAppSelector((state) => state.schedule);
  const activePhase = phases.find((p) => p.isOngoing);
  const { hasPermission: PHASE_AND_SPRINT_ADD } = usePermission("5");

  const onClick = async () => {
    await dispatch(
      updateSchedule({
        key: "editSprintModal",
        value: {
          visible: true,
          mode: "new",
          phaseId: activePhase?.projectPhaseId || "",
        },
      })
    );
  };
  return (
    <div className=" w-full flex  items-center justify-center flex-col rounded-lg gap-y-6 h-[calc(100vh-300px)] px-4 py-6">
      <div className="out-500-14 text-center text-black">
        No Active Sprints{" "}
      </div>
      <img src="/images/v2/settings/frosty-search.svg" className="w-[146px]" />

      <p className="out-300-14 text-gray-500">
        Create a new sprint or check for sprints in the planner
      </p>
      <div
        className={`flex  ${
          PHASE_AND_SPRINT_ADD ? "justify-between" : "justify-center"
        } items-center w-[225px]`}
      >
        {PHASE_AND_SPRINT_ADD && (
          <CustomButton text="Create Sprint" height="30px" onClick={onClick} />
        )}
        {
          <CustomButton
            text="Go To Backlog"
            height="30px"
            onClick={() => navigate(`/schedule/${projectId}/backlog`)}
          />
        }
      </div>
    </div>
  );
};

const AllSprintsScreen = () => {
  const dispatch = useAppDispatch();
  const { projectId, sprintId } = useParams();

  const {
    sprints,
    goals,
    phases,
    emptyScreen,
    confirmDatesModal,
    deleteSprintModal,
    editSprintModal,
    filterGoalParameter,
    exportModal,
    deleteTaskModal,
    completeSprintModal,
    labels,
    selectedTasks,
  } = useAppSelector((state) => state.schedule);

  const { userList } = useAppSelector((state) => state.overview);
  const navigate = useNavigate();

  const StatusItem = ({ status }) => {
    const { text, color, className } = getStatusDetails(status);
    return <CustomBadge text={text} color={color} className={className} dot />;
  };

  const handleFilter = (sprints) => {
    const filteredSprints = sprints.filter((sprint) =>
      filterGoalParameter.sprintIds.includes(sprint.id)
    );
    return filteredSprints;
  };
  const columns = [
    sprintId
      ? null
      : {
          title: () => (
            <div className="out-500-12 leading-[18px] text-gray-500">
              Sprint
            </div>
          ),
          width: "100px",
          dataIndex: "sprintId",
          defaultSortOrder: "ascend",
          sorter: (a, b) => a.id - b.id,
          render: (text) => (
            <div className="leading-5 text-gray-500 out-300-12 whitespace-nowrap">
              {sprints.find((s) => s.id == text)?.name || "--"}
            </div>
          ),
        },
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 ">Type</div>
      ),
      width: "100px",
      dataIndex: "goalType",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <Tooltip
          className="text-white cursor-pointer"
          title={getPhaseName(record.phaseId)}
        >
          <TaskTypeIcon
            type={record.isNewGoal ? "newscope" : +record.goalType}
            width="20"
            height="20"
          />
        </Tooltip>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">ID No.</div>
      ),
      width: "100px",
      dataIndex: "id",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="leading-5 text-gray-500 out-300-12 ">
          {" "}
          PI - {`${record.linkedGoalId || record.id}`}
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Title</div>
      ),
      width: "320px",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="leading-5 text-black out-300-12 ">{text}</p>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Assignee
        </div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "assigneeId",
      render: (text, record) => {
        const assignee = userList.find(
          (user) => +user.userId === +record.assigneeId
        );
        //const ownerDetails = getOwnerDetails(text);
        return (
          <div className="flex items-center gap-x-3 ">
            <CustomAvatar
              title={`${getUsersName(assignee)}`}
              src={assignee?.profilePicture}
              size={20}
              whiteText
              color={assignee?.profilePictureColorCode}
            />
            <p className="leading-5 text-gray-500 out-300-12">
              {getUsersName(assignee)}
            </p>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Reporter
        </div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "reporterId",
      render: (text, record) => {
        const reporter = userList.find(
          (user) => +user.userId === +record.reporterId
        );
        //const ownerDetails = getOwnerDetails(text);
        return (
          <div className="flex items-center gap-x-3 ">
            <CustomAvatar
              title={`${getUsersName(reporter)}`}
              src={reporter?.profilePicture}
              size={20}
              whiteText
              color={reporter?.profilePictureColorCode}
            />

            <p className="leading-5 text-gray-500 out-300-12">
              {getUsersName(reporter)}
            </p>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Status</div>
      ),
      width: "200px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "statusId",
      render: (text) => {
        //const ownerDetails = getOwnerDetails(text);
        return <StatusItem status={text} />;
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">Phase</div>
      ),

      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      width: "156px",
      dataIndex: "phaseId",
      render: (text) => {
        const phase = phases.find((p) => p.projectPhaseId == text);
        if (!phase) {
          return "-";
        }
        return (
          <div className="flex items-center gap-x-3 ">
            <PhaseIcon phaseId={+phase.phaseId} />
            <p className="text-gray-500 out-200-12">
              {phase.phases} {phase.phaseIteration}
            </p>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Priority
        </div>
      ),
      width: "68px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,

      dataIndex: "priorityId",
      render: (text) => {
        const priorityData = getPriorityDetails(text);
        return (
          <PriorityFlag
            style={{ color: priorityData.color }}
            height="20"
            width="20"
          />
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Created On
        </div>
      ),
      width: "200px",
      dataIndex: "createdAt",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="leading-5 text-gray-500 out-300-12 ">
          {text ? dayjs(text).format("DD MMM YYYY") : "-"}
        </p>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 ">
          Updated On
        </div>
      ),
      width: "200px",
      dataIndex: "updatedAt",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <p className="leading-5 text-gray-500 out-300-12 ">
          {text ? dayjs(text).format("DD MMM YYYY") : "-"}
        </p>
      ),
    },
  ];

  const [limit, setLimit] = useState(10);

  const [currentPageNo, setCurrentPageNo] = useState(1);

  useEffect(() => {
    dispatch(getProjectPhases({ projectId }));
    dispatch(getSwimlanes({ projectId }));
  }, [projectId]);

  useEffect(() => {
    if (filterGoalParameter?.sprintIds?.length === 1) {
      navigate(
        `/schedule/${projectId}/sprints/${filterGoalParameter?.sprintIds[0]}`
      );
    }
  }, [filterGoalParameter]);
  // useEffect(() => {
  //   if (projectId) {
  //     dispatch(
  //       getGoalsBySprint({
  //         projectId,
  //       })
  //     );
  //     dispatch(getSprintsByPhase({ projectId }));
  //   }
  // }, [projectId]);

  return (
    <ScheduleLayout>
      <div id="schedule-tour-sprints" className="bg-white">
        <SprintMenu />
        <div className="min-h-[calc(100vh-170px)] max-h-[calc(100vh-170px)] overflow-y-scroll bg-gray-50">
          {sprints.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="bg-white">
              <div className="px-6 py-3 bg-gray-50">
                {handleFilter(sprints)?.map((sprint) => (
                  <div key={sprint.id} className="mt-3 ">
                    <SprintAccordian sprint={sprint} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <EditSprintModal
        visible={editSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["editSprintModal"]))}
      />

      <ConfirmSprintDates
        visible={confirmDatesModal?.visible}
        onClose={() => dispatch(resetSchedule(["confirmDatesModal"]))}
      />
      <ExportModal
        visible={exportModal}
        onClose={() => {
          dispatch(
            updateSchedule({
              key: "exportModal",
              value: false,
            })
          );
        }}
      />
      <DeleteSprintModal
        visible={deleteSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["deleteSprintModal"]))}
      />
      <DeleteTaskModal
        visible={deleteTaskModal.visible}
        selectedTasks={selectedTasks}
        onClose={() => {
          dispatch(
            updateSchedule({
              key: "selectedTasks",
              value: [],
            })
          );
          dispatch(
            updateSchedule({
              key: "deleteTaskModal",
              value: { ...deleteTaskModal, visible: false },
            })
          );
        }}
      />
      <CompleteSprintModal
        visible={completeSprintModal.visible}
        onClose={() => dispatch(resetSchedule(["completeSprintModal"]))}
      />
    </ScheduleLayout>
  );
};

export default AllSprintsScreen;
