export const DEVICE_TYPE = {
  DESKTOP: 1,
  MOBILE: 2,
};
const defaultCarbonIntensityFactorIngCO2PerKWh = 499;

//The Ammount electricity required for each byte
const kWhPerByteDataCenter = 0.000000000072;
const kWhPerByteNetwork = 0.000000000152;
const kWhPerMinuteDevice = 0.00021;
const kWhPerMinuteLaptop = 0.00032;

const carbonIntensityFactorIngCO2PerKWh = {
  europeanUnion: 487,
  france: 143,
  usa: 433,
  china: 681,
  india: 645,
  world: defaultCarbonIntensityFactorIngCO2PerKWh,
};

/**
 * Calculates the total carbon footprint and electricity consumption of internet usage.
 *
 * @param {number} totalBytes - The total data transfer size in bytes.
 * @param {number} duration - The duration of device use in minutes.
 * @param {string} region - The region where the device is being used.
 * @param {number} [deviceType=1] - The type of device being used (1 for desktop, 2 for mobile).
 * @returns {Object} - An object containing the total carbon footprint (gCO2Total) and total electricity consumption (TotalElectricityConsumption).
 */
export const calculateTotalCarbonFootprintAndElectricity = (
  totalBytes,
  duration,
  region,
  deviceType
) => {
  const DeviceEnergyImpact =
    deviceType === "AVG"
      ? (kWhPerMinuteDevice + kWhPerMinuteLaptop) / 2
      : deviceType === DEVICE_TYPE.MOBILE
      ? kWhPerMinuteDevice
      : kWhPerMinuteLaptop;

  //todo device type? region ?

  const carbonIntensityFactor =
    carbonIntensityFactorIngCO2PerKWh[region?.toLowerCase()] ||
    defaultCarbonIntensityFactorIngCO2PerKWh;

  // Device Emissions Impact
  // Device Emissions Impact = Duration of Device Use * Device Energy Impact* Location Based Carbon Intensity
  const kWhDeviceTotal = duration * DeviceEnergyImpact;
  const GESDeviceTotal = kWhDeviceTotal * carbonIntensityFactor;

  // Data Centre Emissions Impact
  // Data Centre Emissions Impact =Data transfer size* Energy Impact of Data Centres* I
  const kWhDataCenterTotal = totalBytes * kWhPerByteDataCenter;
  const GESDataCenterTotal = kWhDataCenterTotal * carbonIntensityFactor;

  // Network Emissions Impact
  // Network Emissions Impact =Data transfer size* Energy Impact of FAN WIFI Network* I
  const kWhNetworkTotal = totalBytes * kWhPerByteNetwork;
  const GESNetworkTotal = kWhNetworkTotal * carbonIntensityFactor;

  // Total Electricity Consumption of Internet Usage
  // Total Electricity Consumption = Energy Impact of device Use × device Use Time + Data Transfer Size (Network Impact + Data Centre Impact)
  const totalElectricityConsumption = Math.round(
    DeviceEnergyImpact * duration +
      totalBytes * (GESNetworkTotal + GESDataCenterTotal)
  );

  // Final Emissions Due to Internet Consumption
  // Final Emissions (in gCO2) = Device Emissions Impact + Data Centre Emissions Impact + Network Emissions Impact
  const gCO2Total = Math.round(
    GESDataCenterTotal + GESNetworkTotal + GESDeviceTotal
  );

  return { gCO2Total, totalElectricityConsumption };
};
