import React from "react";
import Icon from "@ant-design/icons";

const RefreshSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.167 5.833s1.17-1.593 2.12-2.544a5.25 5.25 0 11-1.331 5.17m-.79-2.626v-3.5m0 3.5h3.5"
    ></path>
  </svg>
);

const RefreshIcon = (props) => {
  return <Icon component={RefreshSvg} {...props} />;
};

export { RefreshIcon };
