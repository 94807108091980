import { createSlice, current } from "@reduxjs/toolkit";
import { getSDGs, getSectors } from "../api/onboardingAPI";

const initialState = {
  loading: false,
  error: {},
  currentStep: 1,
  selectedSector: [],
  sectorList: [],
  selectedSDGs: [],
  sdgList: [],
  companyName: "",
  companyLocation: "",
  companySize: "",
  hoverIndexFromContent: 0,
  companyMembers: [
    {
      id: 1,
      email: "",
      roleId: 0,
    },
  ],
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    resetToInitialOnboarding: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    changeStep: (state, action) => {
      state.currentStep = action.payload;
    },
    selectSector: (state, action) => {
      state.selectedSector.push(action.payload);
    },
    handleSelect: (state, action) => {
      if (state.selectedSector.includes(action.payload)) {
        state.selectedSector = state.selectedSector.filter(
          (i) => i !== action.payload
        );
      } else {
        state.selectedSector = [...state.selectedSector, action.payload];
      }
    },
    handleSdgSelect: (state, action) => {
      if (state.selectedSDGs.includes(action.payload)) {
        state.selectedSDGs = state.selectedSDGs.filter(
          (i) => i !== action.payload
        );
      } else {
        state.selectedSDGs = [...state.selectedSDGs, action.payload];
      }
    },
    handleCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    handleHoverIndex: (state, action) => {
      state.hoverIndexFromContent = action.payload;
    },
    handleCompanyLocation: (state, action) => {
      state.companyLocation = action.payload;
    },
    handleCompanySize: (state, action) => {
      state.companySize = action.payload;
    },
    handleCompanyMembers: (state, action) => {
      state.companyMembers = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSectors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSectors.fulfilled, (state, action) => {
        state.loading = false;
        state.sectorList = action.payload.map((val) => {
          return {
            ...val,
            id: +val.id,
          };
        });
      })
      .addCase(getSectors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.sectorList = [];
      });

    // getSDGs

    builder
      .addCase(getSDGs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSDGs.fulfilled, (state, action) => {
        state.loading = false;
        state.sdgList = action.payload;
      })
      .addCase(getSDGs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.sdgList = [];
      });
  },
});

export const {
  changeStep,
  selectSector,
  handleSelect,
  handleSdgSelect,
  handleCompanyName,
  handleHoverIndex,
  handleCompanyLocation,
  handleCompanySize,
  handleCompanyMembers,
  resetToInitialOnboarding,
} = onboardingSlice.actions;
export default onboardingSlice.reducer;
