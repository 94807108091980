import React from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  compeleteResetProjectOnboarding,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import { useNavigate } from "react-router";
import { client } from "../../../Core/utils/axiosClient";
import { useQueryClient } from "react-query";
import StepLayout from "./StepLayout";
import CurrentStep from "./CurrentStep";

const Step5NoPhases = () => {
  const queryClient = useQueryClient();

  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();

  const {
    projectName,
    projectImage,
    projectColor,

    clientName,
    clientImage,
    internalProject,
    clientColor,

    projectDuration,
    clientLocation,
    projectType,
    selectedPhase,
    phaseDetails,
  } = useAppSelector((state) => state.projectOnboarding);

  const navigate = useNavigate();

  const handelCreateProject = async () => {
    const phases = phaseDetails.filter((p) => selectedPhase.includes(p.id));

    const project = {
      title: projectName,
      startDate: projectDuration[0],
      endDate: projectDuration[1],
      projectColor,
      projectImage,
      clientName,
      clientImage,
      clientLocation,
      projectType,
      phases,
      internalProject,
      clientColor,
      hasPhases: false,
    };
    const { data } = await client.post(`/project/create`, project);

    queryClient.invalidateQueries("projectList");

    if (data.projectId) {
      dispatch(
        updateProjectOnboarding({
          key: "projectCreationModal",
          value: { visible: true, data: data },
        })
      );
    }
  };

  return (
    <StepLayout style={{ width: "70%" }}>
      <div>
        <CurrentStep className={"mt-20"} currentStep={5} totalStep={5} />
        <p className="text-gray-900 out-500-20 mt-6">
          All set for your new project,{" "}
          {user?.firstName[0]?.toUpperCase() + user.firstName.slice(1)}!
        </p>

        <div className="flex gap-2 items-center mt-4">
          <img
            src="/images/icons/frosty-icon.svg"
            className={`min-h-[24px] min-w-[24px]`}
            alt="frosty"
          />
          <p className="text-grey-500 out-300-16">
            Everything is configured and Project{" "}
            <span className="text-zinc-800 out-500-16">{projectName}</span> is
            ready to be created.
          </p>
        </div>

        <div className="mt-[60px] flex justify-between items-center">
          <CustomButton
            text="Cancel"
            width="75px"
            height="30px"
            className={`bg-gray-100 out-500-14 text-gray-700 `}
            onClick={() => {
              dispatch(compeleteResetProjectOnboarding({}));
              navigate("/dashboard");
            }}
          />
          <div className="flex gap-x-2">
            <CustomButton
              text="Back"
              onClick={() => {
                dispatch(changeStep(4));
              }}
              className=" text-gray-700 bg-gray-100 out-500-14"
              height="30px"
              width="63px"
            />
            <CustomButton
              text="Continue"
              width="88px"
              height="30px"
              className={`bg-primary-600 out-500-14 text-white `}
              onClick={handelCreateProject}
            />
          </div>
        </div>
      </div>
    </StepLayout>
  );
};

export default Step5NoPhases;
