import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import dayjs from "dayjs";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { CalendarColors, HourArray, DayNames } from "../../Core/constants";
import { DotsPointsIcon } from "../../Core/svgV3/DotsPointsIcon";
import { InfoCircleOutlined } from "@ant-design/icons";

const WeekEventCell = ({
  startTime,
  endTime,
  eventId,
  title,
  eventCellStyle,
  eventDetails,
}) => {
  const eventRef = useRef();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);

  const [isHovered, setIsHovered] = useState(false);
  const hourHeight = 60;
  const [startHour, startMinutes] = startTime.split(":").map(Number);
  const [endHour, endMinutes] = endTime.split(":").map(Number);

  const startTotalMinutes = startHour * 60 + startMinutes;
  const endTotalMinutes = endHour * 60 + endMinutes;

  const totalMinutesDiff = Math.abs(endTotalMinutes - startTotalMinutes);
  const height = (totalMinutesDiff / 60) * ((hourHeight * 90) / 100);

  const styles = {
    top: `${(startMinutes / 60) * hourHeight}px`,
    height: `${height}px`,
    minHeight: "15px",
    flexShrink: 0,
    ...eventCellStyle,
  };

  const getBorderStyle = useCallback(() => {
    if (eventDetails.selectedColor === "-1") {
      return "#AADAFF";
    }
    const selectedColorId = parseInt(eventDetails.selectedColor);
    const color = CalendarColors.find((color) => color.id === selectedColorId);
    return color ? color.fill : "#AADAFF";
  }, [eventDetails.selectedColor]);

  const getBackgroundColor = useCallback(() => {
    const organizerEmail = eventDetails.organizer?.email;
    const attendee = eventDetails.attendees?.find(
      (attendee) =>
        attendee.email === user.email && attendee.responseStatus === "accepted"
    );
    if (attendee || user.email === organizerEmail) {
      if (eventDetails.selectedColor === "-1") {
        return "#AADAFF";
      }
      const selectedColorId = parseInt(eventDetails.selectedColor);
      const color = CalendarColors.find(
        (color) => color.id === selectedColorId
      );
      return color ? color.fill : "#AADAFF";
    } else {
      return "#F9FBFE";
    }
  }, [
    eventDetails.selectedColor,
    user.email,
    eventDetails.organizer,
    eventDetails.attendees,
  ]);
  const handleEventClick = (e) => {
    e.stopPropagation();
    dispatch(updateDashboard({ key: "eventInfoModal", value: true }));
    dispatch(updateDashboard({ key: "selectedEvent", value: eventDetails }));
  };

  const isProposedEvent =
    eventDetails.proposedBy === user.id || eventDetails.proposedFor === user.id;

  const attendees = eventDetails ? eventDetails?.attendees : [];
  const allDeclined =
    eventDetails?.organizer?.email === user.email &&
    attendees?.length > 1 &&
    attendees
      ?.filter((att) => att.email !== user.email)
      ?.every((att) => att.responseStatus === "declined");

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const bgColor = getBackgroundColor();

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        data-event-id={eventId}
        data-start-time={startTime}
        data-end-time={endTime}
        ref={eventRef}
        role="button"
        onClick={handleEventClick}
        className={`absolute w-full rounded border border-white px-1 transform transition-all duration-200 overflow-hidden `}
        style={{
          ...styles,
          backgroundColor: getBackgroundColor(),
          borderColor: bgColor !== "#F9FBFE" ? "#FFFFFF" : getBorderStyle(),

          backgroundImage: isProposedEvent
            ? "linear-gradient(45deg, transparent, transparent 40%, rgba(0, 0, 0, .2) 40%, rgba(0, 0, 0, .2) 50%, transparent 50%, transparent 90%, rgba(0, 0, 0, .2) 90%, rgba(0, 0, 0, .2))"
            : "none",
          backgroundSize: "12px 12px",
        }}
      >
        <span className="flex justify-start h-full flex-col flex-nowrap gap-x-2 overflow-hidden">
          <span className="text-[10px] font-normal font-['Outfit'] text-gray-900 flex justify-between">
            {height > 30 ? (
              title || "(No title)"
            ) : (
              <div className="whitespace-nowrap">{title || "(No title)"}</div>
            )}
            {eventDetails.description && !allDeclined && (
              <div className=" top-0 right-0 w-3 h-3">
                <DotsPointsIcon />
              </div>
            )}
            {allDeclined && (
              <div className=" top-1 right-0 w-3 h-3">
                <InfoCircleOutlined className="text-error-500" />
              </div>
            )}
          </span>
          {height > 30 && (
            <span className="text-[10px] font-normal font-['Outfit'] text-gray-900 overflow-hidden">
              {startTime} - {endTime}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

const MonthEventCell = ({
  startTime,
  endTime,
  eventId,
  title,
  eventCellStyle,
  eventDetails,
}) => {
  const eventRef = useRef();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);

  const getBorderStyle = useCallback(() => {
    if (eventDetails.selectedColor === "-1") {
      return "#AADAFF";
    }
    const selectedColorId = parseInt(eventDetails.selectedColor);
    const color = CalendarColors.find((color) => color.id === selectedColorId);
    return color ? color.fill : "#AADAFF";
  }, [eventDetails.selectedColor]);

  const getBackgroundColor = useCallback(() => {
    const organizerEmail = eventDetails.organizer?.email;
    const attendee = eventDetails.attendees?.find(
      (attendee) =>
        attendee.email === user.email && attendee.responseStatus === "accepted"
    );
    if (attendee || user.email === organizerEmail) {
      if (eventDetails.selectedColor === "-1") {
        return "#AADAFF";
      }
      const selectedColorId = parseInt(eventDetails.selectedColor);
      const color = CalendarColors.find(
        (color) => color.id === selectedColorId
      );
      return color ? color.fill : "#AADAFF";
    } else {
      return "#F9FBFE";
    }
  }, [
    eventDetails.selectedColor,
    user.email,
    eventDetails.organizer,
    eventDetails.attendees,
  ]);

  const handleEventClick = (e) => {
    e.stopPropagation();
    dispatch(updateDashboard({ key: "eventInfoModal", value: true }));
    dispatch(updateDashboard({ key: "selectedEvent", value: eventDetails }));
  };
  const organizerEmail = eventDetails.organizer?.email;
  const attendee = eventDetails.attendees?.find(
    (attendee) =>
      attendee.email === user.email && attendee.responseStatus === "accepted"
  );
  return (
    <div>
      <div
        data-event-id={eventId}
        data-start-time={startTime}
        data-end-time={endTime}
        ref={eventRef}
        role="button"
        onClick={handleEventClick}
        className={`${
          attendee || user.email === organizerEmail
            ? "month-view-event-cell"
            : "month-hover-border"
        } w-full rounded border border-white px-0.5 transform transition-all duration-200 `}
        style={{
          backgroundColor: getBackgroundColor(),
          borderColor: getBorderStyle(),
          overflow: "hidden",
          position: "relative",
        }}
      >
        <span className="flex justify-between flex-nowrap gap-x-2 ">
          <span className="text-[10px] font-normal font-['Outfit'] text-gray-900 truncate">
            {title || "(No title)"}
          </span>
          <span className=" text-[10px] font-normal font-['Outfit'] text-gray-900">
            {startTime}
          </span>
        </span>
      </div>
    </div>
  );
};

const EventCell = ({
  startTime,
  endTime,
  eventId,
  title,
  eventCellStyle,
  eventDetails,
}) => {
  const eventRef = useRef();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const [isHovered, setIsHovered] = useState(false);

  const hourHeight = 60;
  const [startHour, startMinutes] = startTime.split(":").map(Number);
  const [endHour, endMinutes] = endTime.split(":").map(Number);

  const startTotalMinutes = startHour * 60 + startMinutes;
  const endTotalMinutes = endHour * 60 + endMinutes;

  const totalMinutesDiff = Math.abs(endTotalMinutes - startTotalMinutes);
  const height = (totalMinutesDiff / 60) * ((hourHeight * 90) / 100);

  const styles = {
    top: `${(startMinutes / 60) * hourHeight}px`,
    height: `${height}px`,
    minHeight: "15px",
    flexShrink: 0,
    ...eventCellStyle,
  };

  const getBorderStyle = useCallback(() => {
    if (eventDetails.selectedColor === "-1") {
      return "#AADAFF";
    }
    const selectedColorId = parseInt(eventDetails.selectedColor);
    const color = CalendarColors.find((color) => color.id === selectedColorId);
    return color ? color.fill : "#AADAFF";
  }, [eventDetails.selectedColor]);

  const getBackgroundColor = useCallback(() => {
    const organizerEmail = eventDetails.organizer?.email;
    const attendee = eventDetails.attendees?.find(
      (attendee) =>
        attendee.email === user.email && attendee.responseStatus === "accepted"
    );
    if (attendee || user.email === organizerEmail) {
      if (eventDetails.selectedColor === "-1") {
        return "#AADAFF";
      }
      const selectedColorId = parseInt(eventDetails.selectedColor);
      const color = CalendarColors.find(
        (color) => color.id === selectedColorId
      );
      return color ? color.fill : "#AADAFF";
    } else {
      return "#AADAFF";
    }
  }, [
    eventDetails.selectedColor,
    user.email,
    eventDetails.organizer,
    eventDetails.attendees,
  ]);

  const handleEventClick = (e) => {
    e.stopPropagation();
    dispatch(updateDashboard({ key: "eventInfoModal", value: true }));
    dispatch(updateDashboard({ key: "selectedEvent", value: eventDetails }));
  };

  const isProposedEvent =
    eventDetails.proposedBy === user.id || eventDetails.proposedFor === user.id;

  const attendees = eventDetails ? eventDetails?.attendees : [];
  const allDeclined =
    eventDetails?.organizer?.email === user.email &&
    attendees?.length > 1 &&
    attendees
      ?.filter((att) => att.email !== user.email)
      ?.every((att) => att.responseStatus === "declined");

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const bgColor = getBackgroundColor();
  console.log(bgColor, eventDetails, "bg colors");
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        data-event-id={eventId}
        data-start-time={startTime}
        data-end-time={endTime}
        ref={eventRef}
        role="button"
        onClick={handleEventClick}
        className={`absolute w-full rounded border border-white px-1 py-0.5 transform transition-all duration-200`}
        style={{
          ...styles,
          backgroundColor: getBackgroundColor(),
          borderColor: bgColor !== "#F9FBFE" ? "#FFFFFF" : getBorderStyle(),
          backgroundImage: isProposedEvent
            ? "linear-gradient(45deg, transparent, transparent 40%, rgba(0, 0, 0, .2) 40%, rgba(0, 0, 0, .2) 50%, transparent 50%, transparent 90%, rgba(0, 0, 0, .2) 90%, rgba(0, 0, 0, .2))"
            : "none",
          backgroundSize: "12px 12px",
        }}
      >
        <span className="flex justify-center h-full flex-col flex-wrap gap-x-2 w-max">
          <span className="out-500-12 text-gray-900 w-max">
            {title || "No Title"}
          </span>
          <span className=" text-[10px] font-normal font-['Outfit'] text-gray-900">
            {startTime} - {endTime}
          </span>
        </span>
        {isHovered && eventDetails.description && !allDeclined && (
          <div className=" absolute top-1 right-1 w-3 h-3">
            <DotsPointsIcon />
          </div>
        )}
        {allDeclined && (
          <div className=" absolute top-1 right-1 w-3 h-3">
            <InfoCircleOutlined className="text-error-500" />
          </div>
        )}
      </div>
    </div>
  );
};

const MonthTimeCell = ({ date, tasks, onClick, currentSelectedDate }) => {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(
      updateDashboard({
        key: "monthDateModal",
        value: {
          visible: true,
          selectedDate: date,
        },
      })
    );
  };

  const isFirstDayOfMonth = date.date() === 1;
  const isInSelectedMonth = date.month() === currentSelectedDate.month();
  const isToday = date.isSame(dayjs(), "day");

  return (
    <div
      className={`w-full h-[107px] flex flex-col gap-y-1 relative border-t border-r min-h-[60px] bg-white border-gray-200 p-[3px] ${
        isInSelectedMonth ? "text-gray-900" : "text-gray-500"
      }`}
      role="button"
      onClick={handleClick}
    >
      <div className="flex justify-center items-center">
        <div
          className={`${
            isToday
              ? "w-5 h-[21px] bg-blue-700 rounded-xl flex justify-center text-slate-50"
              : ""
          }`}
        >
          {date.format("D")}{" "}
        </div>{" "}
        &nbsp;
        {isFirstDayOfMonth && `${date.format("MMMM")}`}
      </div>
      {tasks.slice(0, 3).map((task, i) => (
        <div key={task.id} className="flex flex-col gap-1">
          <MonthEventCell
            startTime={dayjs(task.start.dateTime).format("HH:mm")}
            endTime={dayjs(task.end.dateTime).format("HH:mm")}
            eventId={task.etag}
            title={task.summary}
            eventDetails={task}
            eventCellStyle={{
              zIndex: i + 1,
            }}
          />
        </div>
      ))}
      {tasks.length > 3 && (
        <div className="flex items-center justify-start cursor-pointer text-blue-700 text-[10px] font-medium font-['Outfit']">
          +{tasks.length - 3} more
        </div>
      )}
    </div>
  );
};

const TimeCell = ({ hour, tasks, currentSelectedDate, selectedDay, view }) => {
  const timecellRef = useRef(null);
  const currentTimeRef = useRef(null);
  const dispatch = useAppDispatch();
  const [scrollToRedLine, setScrollToRedLine] = useState(true);

  const currentTime = dayjs().format("HH:mm");
  const [currentTimeHour, currentTimeMinutes] = currentTime.split(":");
  const currentDay = dayjs();

  useEffect(() => {
    if (scrollToRedLine && currentTimeRef.current) {
      currentTimeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setScrollToRedLine(false);
    }
  }, [scrollToRedLine]);

  const currentTimeHTML =
    hour.split(":")[0] === currentTimeHour ? (
      <div
        ref={currentTimeRef}
        data-time-current={currentTime}
        data-date-time={dayjs().format("DD/MM/YYYY HH:MM")}
        className="absolute top-2 w-full border-b z-30 border-error-500"
        style={{ top: `${currentTimeMinutes}px` }}
      >
        {selectedDay.format("YYYY-MM-DD") ===
          currentDay.format("YYYY-MM-DD") && (
          <div
            className="absolute top-1/2 transform -translate-y-1/2 w-2 h-2 bg-error-500 rounded-full"
            style={{ left: view === 1 ? "-3%" : "-0.5%" }}
          ></div>
        )}
      </div>
    ) : null;

  const getStyles = useCallback(
    (i) => {
      if (!timecellRef.current) return {};

      if (i === 0) {
        return {
          width: view === 0 ? "100%" : "98%",
          left: 0,
        };
      } else {
        const cellWidth =
          view === 0
            ? timecellRef.current.offsetWidth
            : timecellRef.current.offsetWidth * 0.98;
        const width = cellWidth / tasks.length;

        let left = 0;
        let overlapped = false;

        for (let j = 0; j < i; j++) {
          const event1 = tasks[i];
          const event2 = tasks[j];

          const start1 = dayjs(event1.start.dateTime);
          const end1 = dayjs(event1.end.dateTime);
          const start2 = dayjs(event2.start.dateTime);
          const end2 = dayjs(event2.end.dateTime);

          // Check if events overlap in time
          if (
            (start1.isBefore(end2) && end1.isAfter(start2)) ||
            (start2.isBefore(end1) && end2.isAfter(start1))
          ) {
            overlapped = true;
            left += width;
          }
        }

        if (!overlapped) {
          return {
            width: view === 0 ? "100%" : "98%",
            left: 0,
          };
        } else {
          return {
            width,
            left,
          };
        }
      }
    },
    [timecellRef?.current, tasks]
  );

  function formattedHour(hour) {
    const [hours, minutes] = hour.split(":");
    const suffix = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    if (hour === "00:00") {
      return (
        <div className="mt-1.5">
          {formattedHours} {suffix}
        </div>
      );
    } else {
      return `${formattedHours} ${suffix}`;
    }
  }

  return (
    <div
      className="flex items-start min-h-[60px] w-full relative "
      style={{ height: `60px` }}
    >
      {(selectedDay.format("ddd") === "Sun" || view === 0) && (
        <div className="out-300-12 absolute top-[-9px] text-gray-500 flex w-[43px] -left-[45px]">
          {formattedHour(hour)}
        </div>
      )}
      {currentTimeHTML}

      <div
        ref={timecellRef}
        className="w-full flex gap-x-1 relative border-t border-r min-h-[60px] bg-white border-gray-200"
        role="button"
        onClick={() => {
          dispatch(
            updateDashboard({
              key: "createEventModal",
              value: {
                visible: true,
                startTime: dayjs(`${hour}:00`, "HH:mm"),
                endTime: dayjs(`${parseInt(hour) + 1}:00`, "HH:mm"),
                date: selectedDay,
              },
            })
          );
        }}
      >
        {view === 0
          ? // Render EventCell
            tasks.map((task, i) => (
              <EventCell
                key={task.id}
                startTime={dayjs(task.start.dateTime).local().format("HH:mm")}
                endTime={dayjs(task.end.dateTime).local().format("HH:mm")}
                eventId={task.etag}
                title={task.summary}
                eventDetails={task}
                eventCellStyle={{
                  zIndex: i + 1,
                  ...getStyles(i),
                }}
              />
            ))
          : // Render WeekEventCell
            tasks.map((task, i) => (
              <WeekEventCell
                key={task.id}
                startTime={dayjs(task.start.dateTime).local().format("HH:mm")}
                endTime={dayjs(task.end.dateTime).local().format("HH:mm")}
                eventId={task.etag}
                title={task.summary}
                eventDetails={task}
                eventCellStyle={{
                  zIndex: i + 1,
                  ...getStyles(i),
                }}
              />
            ))}
      </div>
    </div>
  );
};

const MonthCalendar = ({ monthDate, onClick }) => {
  const startOfMonth = new Date(monthDate);
  startOfMonth.setDate(1);
  startOfMonth.setHours(0, 0, 0, 0);

  const endOfMonth = new Date(startOfMonth);
  endOfMonth.setMonth(endOfMonth.getMonth() + 1);
  endOfMonth.setDate(0);

  const firstDayOfMonth = startOfMonth.getDay();

  const weeks = [];
  let week = [];

  for (let i = 0; i < firstDayOfMonth; i++) {
    week.push(null);
  }

  const currentDatePointer = new Date(startOfMonth);

  while (currentDatePointer.getMonth() === startOfMonth.getMonth()) {
    week.push(new Date(currentDatePointer));
    if (week.length === 7) {
      weeks.push(week);
      week = [];
    }
    currentDatePointer.setDate(currentDatePointer.getDate() + 1);
  }

  for (let i = week.length; i < 7; i++) {
    week.push(null);
  }
  weeks.push(week);

  const isSelected = (day) => {
    const today = new Date();
    return (
      day &&
      day.getDate() === today.getDate() &&
      day.getMonth() === today.getMonth() &&
      day.getFullYear() === today.getFullYear()
    );
  };

  return (
    <div className="w-[191px] p-2 rounded-lg">
      <div className="text-center mb-2 text-grey-500 out-300-14">
        {startOfMonth.toLocaleString("default", { month: "long" })}
      </div>
      <div className="flex justify-between">
        {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
          <div
            key={index}
            className="w-[25px] text-center text-gray-500 text-[10px] font-normal font-['Outfit']"
          >
            {day}
          </div>
        ))}
      </div>
      {weeks.map((week, rowIndex) => (
        <div key={rowIndex} className="flex justify-between">
          {week.map((day, index) => (
            <div
              key={index}
              className={`w-[25px] h-[25px] px-2 py-[9px] rounded-[100px] flex items-center justify-center cursor-pointer ${
                day && isSelected(day)
                  ? "bg-blue-700 text-slate-50"
                  : day
                  ? " text-zinc-800 hover:bg-gray-300"
                  : "" // No hover effect for null day cells
              }`}
              onClick={() => {
                if (day && onClick) onClick(dayjs(day));
              }}
            >
              {day && (
                <div className="text-center text-[10px] font-normal font-['Outfit']">
                  {day.getDate()}
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const Timeline = ({ currentDate, view = 1, onClick }) => {
  const { events, myCalendarFilter } = useAppSelector(
    (state) => state.dashboard
  );
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();

  const daysInWeek = Array.from({ length: 7 }, (_, i) =>
    currentDate.clone().startOf("week").add(i, "days")
  );

  if (view === 3) {
    const currentYear = currentDate.year();

    const monthsOfYear = Array.from({ length: 12 }, (_, i) =>
      dayjs().set("year", currentYear).set("month", i)
    );

    return (
      <div className="grid grid-cols-4 gap-4 bg-slate-50">
        {monthsOfYear.map((month, index) => (
          <div key={index} className="flex justify-center ">
            <MonthCalendar key={index} monthDate={month} onClick={onClick} />
          </div>
        ))}
      </div>
    );
  }

  const firstDayOfMonth = currentDate.clone().startOf("month").startOf("week");
  const lastDayOfMonth = currentDate.clone().endOf("month").endOf("week");

  const days = [];
  let dayIterator = firstDayOfMonth.clone();

  while (dayIterator.isSameOrBefore(lastDayOfMonth, "day")) {
    days.push(dayIterator.clone());
    dayIterator = dayIterator.add(1, "day");
  }

  const today = dayjs();

  const filteredEvents = events;

  const getBackgroundColor = (eventDetails) => {
    const organizerEmail = eventDetails.organizer?.email;
    const attendee = eventDetails.attendees?.find(
      (attendee: any) =>
        attendee.email === user.email && attendee.responseStatus === "accepted"
    );

    if (attendee || user.email === organizerEmail) {
      if (eventDetails.selectedColor === "-1") {
        return "#AADAFF";
      }
      const selectedColorId = parseInt(eventDetails.selectedColor);
      const color = CalendarColors.find(
        (color) => color.id === selectedColorId
      );
      return color ? color.fill : "#AADAFF";
    } else {
      return "#F9FBFE";
    }
  };

  // Separate all-day events
  const regularEvents = [];
  const allDayEvents = [];
  filteredEvents.forEach((event) => {
    if (event.start.date && event.end.date) {
      allDayEvents.push(event);
    } else {
      regularEvents.push(event);
    }
  });

  const handleEventClick = (event, e) => {
    e.stopPropagation();
    dispatch(updateDashboard({ key: "eventInfoModal", value: true }));
    dispatch(updateDashboard({ key: "selectedEvent", value: event }));
  };

  console.log("Regular Events:", regularEvents);
  console.log("All-Day Events:", allDayEvents);

  return (
    <div className="relative">
      {view === 2 && (
        <div className="mb-4">
          <div className="h-[37px] grid grid-cols-7 sticky top-0 z-40 bg-slate-100">
            {DayNames.map((day, index) => (
              <div
                key={index}
                className="flex justify-center border-gray-200 border-t border-r items-center"
              >
                {day}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-7">
            {days.map((day, index) => (
              <MonthTimeCell
                key={index}
                date={day}
                tasks={regularEvents.filter((event) =>
                  dayjs(event.start.dateTime).isSame(day, "day")
                )}
                currentSelectedDate={currentDate}
                onClick={onClick}
              />
            ))}
          </div>
        </div>
      )}

      {view === 1 && (
        <div className="flex">
          <div className="w-full flex ml-16">
            {daysInWeek.map((day, index) => (
              <div key={index} className="w-full relative">
                <div className="h-[37px] border border-slate-200 flex justify-center items-center gap-1 sticky top-0 z-40 bg-slate-100">
                  <p
                    className={
                      "text-gray-500 text-sm font-light font-['Outfit']"
                    }
                  >
                    {DayNames[day.day()]}
                  </p>
                  <p
                    className={`text-sm font-medium font-['Outfit' rounded-xl px-1.5 ${
                      day.format("YYYY-MM-DD") === today.format("YYYY-MM-DD")
                        ? "bg-blue-700 rounded text-slate-50"
                        : "text-zinc-800"
                    }`}
                  >
                    {currentDate
                      .clone()
                      .startOf("week")
                      .add(index, "days")
                      .format("D")}
                  </p>
                </div>
                <div className="absolute top-8 z-10 w-full flex flex-col items-start">
                  {allDayEvents
                    .filter((event) =>
                      dayjs(event.start.date).isSame(day, "day")
                    )
                    .map((event, idx) => (
                      <div
                        role="button"
                        key={idx}
                        className="border border-gray-200 bg-gray-100"
                        style={{
                          backgroundColor: getBackgroundColor(event),
                          width: "calc(100%)",
                        }}
                        onClick={(e) => handleEventClick(event, e)}
                      >
                        <p className="text-[12px] font-normal font-['Outfit'] text-gray-900 truncate">
                          {event.summary}
                        </p>
                      </div>
                    ))}
                </div>
                {HourArray.map((hour) => (
                  <TimeCell
                    key={`${day}-${hour}`}
                    hour={hour}
                    tasks={regularEvents.filter((event) => {
                      const eventHour = dayjs(event.start.dateTime).format(
                        "HH"
                      );
                      return (
                        eventHour === hour.split(":")[0] &&
                        dayjs(event.start.dateTime).day() === day.day()
                      );
                    })}
                    selectedDay={day}
                    currentSelectedDate={currentDate}
                    view={1}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      )}

      {view === 0 && (
        <div className="flex">
          <div className="w-full ml-16 relative">
            <div className="absolute top-0 z-10 w-full flex flex-col items-start">
              {allDayEvents.map((event, idx) => (
                <div
                  role="button"
                  key={idx}
                  className="border border-gray-200 bg-gray-100"
                  style={{
                    backgroundColor: getBackgroundColor(event),
                    width: "calc(100%)",
                  }}
                  onClick={(e) => handleEventClick(event, e)}
                >
                  <p className="text-[12px] font-normal font-['Outfit'] text-gray-900 truncate">
                    {event.summary}
                  </p>
                </div>
              ))}
            </div>
            {HourArray.map((hour, index) => (
              <TimeCell
                key={index}
                hour={hour}
                tasks={regularEvents.filter((event) => {
                  const eventHour = dayjs(event.start.dateTime).format("HH");
                  return (
                    eventHour === hour.split(":")[0] &&
                    dayjs(event.start.dateTime).day() === currentDate.day()
                  );
                })}
                selectedDay={currentDate}
                currentSelectedDate={currentDate}
                view={0}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Timeline;
