export const activities = [
  {
    id: 1,
    value: "assigned/changed System role",
  },
  {
    id: 2,
    value: "invited to waddle",
  },
  {
    id: 3,
    value: "signed up to waddle",
  },
  {
    id: 4,
    value: "signed in to waddle",
  },
  {
    id: 5,
    value: "added/removed from project",
  },
  {
    id: 6,
    value: "assigned/changed project role",
  },
  {
    id: 7,
    value: "thread",
  },
  {
    id: 8,
    value: "Created/deleted a project",
  },
  {
    id: 9,
    value: "phase",
  },
  {
    id: 10,
    value: "sprint",
  },
  {
    id: 11,
    value: "task",
  },
  {
    id: 12,
    value: "event",
  },
  {
    id: 13,
    value: "Bug",
  },
  {
    id: 14,
    value: "new scope",
  },
  {
    id: 15,
    value: "all Settings",
  },
  {
    id: 16,
    value: "Integrations",
  },
  {
    id: 17,
    value: "subtask",
  },
  {
    id: 18,
    value: "Folder",
  },
  {
    id: 19,
    value: "File",
  },
  {
    id: 20,
    value: "Link",
  },
  {
    id: 21,
    value: "Share Access",
  },
  {
    id: 22,
    value: "View Profile",
  },
  {
    id: 23,
    value: "Meta Data",
  },
  {
    id: 24,
    value: "Remove Access",
  },
];
