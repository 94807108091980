import { Tour } from "antd";
//import Tour from "@rc-component/tour";

import React, { useState } from "react";

import type { TourProps } from "antd";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import TakeTourModal from "../../Core/CommonV2/TakeTourModal";
import TourPanel from "../../Core/CommonV2/TourPanel";
import {
  updateVault,
  addDummyDataForVaultScreen,
} from "../../Core/redux/slices/vault.slice";
import {
  overViewDummyData,
  projectDummyData,
  vaultDummyData,
} from "../../Core/dummyData";
import { addDummyDataForOverviewScreen } from "../../Core/redux/slices/overview.slice";
import { addDummyDataForProject } from "../../Core/redux/slices/projects.slice";
import { useNavigate } from "react-router-dom";
import { updateTourStatus } from "../../Core/redux/api/dashboardAPI";

const LibraryAppTour = () => {
  const [run, setRun] = useState(false);
  const [showTakeTourModal, setShowTakeTourModal] = useState(true);
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userDetails);
  const { toursPopup } = useAppSelector((state) => state.dashboard);

  const steps: TourProps["steps"] = [
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Projects"}
            module="LibraryTour"
            description={
              "Get all the important updates about your current projects on the dashboard."
            }
            total={6}
            img="/images/v2/library/tour-1.svg"
          />
        );
      },
      style: {
        width: "403px",
        left: "auto",
        top: "auto",
        right: "24px",
        bottom: "24px",
      },

      title: <div className="out-500-16 text-primary-600">Projects</div>,
      target: () =>
        document.querySelector("#library-tour-projects") as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Views"}
            description={
              "Manage your files at ease with different view options to choose from: Grid view and List view."
            }
            module="LibraryTour"
            total={6}
            img="/images/v2/library/tour-2.svg"
          />
        );
      },
      style: { width: "403px" },
      title: <div className="out-500-16 text-primary-600">Views</div>,
      placement: "left",
      target: () =>
        document.querySelector("#library-tour-toggle") as HTMLElement,
    },
    {
      style: {
        width: "403px",
        left: "auto",
        top: "auto",
        right: "24px",
        bottom: "24px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Grid View"}
            description={`The grid view is generally helpful when the folder contains images.`}
            total={6}
            img="/images/v2/library/tour-3.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Grid View </div>,
      placement: "center",
      target: () =>
        document.querySelector("#library-tour-grid-view") as HTMLElement,
    },
    {
      style: {
        width: "403px",
        left: "auto",
        top: "auto",
        right: "24px",
        bottom: "24px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"List View"}
            description={`The list view is helpful when working with documents, spreadsheets, and presentations.`}
            total={6}
            module="LibraryTour"
            img="/images/v2/library/tour-4.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Grid View</div>,
      placement: "center",
      target: () =>
        document.querySelector("#library-tour-grid-view") as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Drag and Drop"}
            module="LibraryTour"
            description={
              "Now easily move around files with Drag and Drop function. Quickly drag the file that needs to be uploaded and drop it in the location."
            }
            total={6}
            img="/images/v2/library/tour-5.svg"
          />
        );
      },
      style: {
        width: "403px",
        left: "auto",
        top: "auto",
        right: "24px",
        bottom: "24px",
      },
      title: <div className="out-500-16 text-primary-600">Projects</div>,
      target: () =>
        document.querySelector("#library-tour-projects") as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Share in message"}
            module="LibraryTour"
            description={
              "Make collaborations easy by sharing anything from the Library as messages to anyone in your organisation. "
            }
            total={6}
            img="/images/v2/library/tour-6.svg"
          />
        );
      },
      // style: { left: "190px" },
      placement: "right",
      title: <div className="out-500-16 text-primary-600">Projects</div>,
      target: () => {
        // dispatch(updateVault({key:"shareAccessModal",value:{
        //   visible: true,
        //   // docId: '3408',
        //   // users: [
        //   //   {
        //   //     id: '1700',
        //   //     canEdit: 1,
        //   //     canView: 1,
        //   //     isOwner: 0,
        //   //     userId: '264',
        //   //     firstname: 'Anushka',
        //   //     lastname: 'Gaonkar',
        //   //     profilePicture: 'https://lh3.googleusercontent.com/a/AAcHTtf7KFLdNrsVb6bZU-yQN-QOk4kzGdYW6UzUfNk-NJ6d=s96-c'
        //   //   },
        //   //   {
        //   //     id: '342',
        //   //     canEdit: 1,
        //   //     canView: 1,
        //   //     isOwner: 1,
        //   //     userId: '113',
        //   //     firstname: 'Ganesh',
        //   //     lastname: 'Mohanty',
        //   //     profilePicture: 'https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1'
        //   //   }
        //   // ],
        //   // docType: 'file',

        // }}));

        return document.querySelector(
          "#library-tour-share-in-message"
        ) as HTMLElement;
      },
    },
  ];

  const dispatch = useAppDispatch();

  // if (localStorage.getItem("LibraryTour") === "1") {
  //   return <></>;
  // }

  if (!run && showTakeTourModal && toursPopup?.libraryTour !== true) {
    return (
      <TakeTourModal
        onOk={() => {
          localStorage.setItem("appTour", "1");
          dispatch(addDummyDataForVaultScreen(vaultDummyData));
          dispatch(addDummyDataForOverviewScreen(overViewDummyData));
          dispatch(addDummyDataForProject(projectDummyData));

          navigate("/library");
          setRun(true);
        }}
        module="Library"
        onClose={async () => {
          setRun(false);
          localStorage.removeItem("appTour");
          localStorage.setItem("LibraryTour", "1");
          await dispatch(
            updateTourStatus({
              tourStatus: "libraryTourStatus",
              userId: user.id,
            })
          );
          setShowTakeTourModal(false);
          window.location.reload();
        }}
      />
    );
  }

  return (
    <div>
      <Tour
        open={run}
        onClose={() => setRun(false)}
        steps={steps}
        mask={{
          style: {
            // boxShadow: "inset 0 0 15px #333",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",

            // WebkitBackdropFilter: "blur(px)",
            // backdropFilter: "blur(3.5px)",
            // filter: "blur(8px)",
          },
          color: "rgba(0, 0, 0, 0.4)",
        }}
        onChange={(current) => {
          console.log(current, ":sdvsfvsfvsfvsffvsfv");

          // dispatch(addDummyDataForProject(projectDummyData));

          if (current <= 2) {
            dispatch(updateVault({ key: "viewType", value: 1 }));
          }
          if (current == 3) {
            console.log(":sdvsfvsfvsfvsffvsfvs");
            dispatch(updateVault({ key: "viewType", value: 2 }));
          }
          if (current == 4) {
            dispatch(updateVault({ key: "viewType", value: 1 }));
          } //todo checj index

          if (current === 5) {
            dispatch(
              updateVault({
                key: "shareInMessageModal",
                value: {
                  type: "file",
                  visible: true,
                  url: "",
                  file: [],
                },
              })
            );
          }
        }}
      />
    </div>
  );
};

export default LibraryAppTour;
