import { message, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useAppSelector } from "../redux/hooks";
import copyTextToClipboard from "../utils/clipboard";

function Error() {
  const { error, status } = useAppSelector((state) => state.global);

  useEffect(() => {
    if (error && error.message && status === "rejected") {
      message.error({
        content: (
          <Tooltip title="Click To Copy the Error">
            <span
              className="out-500-14 text-gray-900 cursor-pointer"
              onClick={() => copyTextToClipboard(error)}
            >
              {error.message}
            </span>
          </Tooltip>
        ),
        className: "custom-message",
        //duration: 10000,
        style: {
          // marginTop: '20vh',
          borderRadius: "10px",
        },
      });
    }
  }, [status]);

  // rest of the component's code goes here
  return <></>;
}

export default Error;
