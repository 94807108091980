import React from "react";

const EmptyState = ({
  message = (
    <>
      {" "}
      Hi, it looks like you have not uploaded anything here. <br />
      Drop your files here or Click on ‘New’ to add items.
    </>
  ),
}) => {
  return (
    <div className="w-full h-[calc(100vh-156px)] bg-white">
      <div className="flex flex-col items-center justify-center h-full">
        <img className="" src="/images/v2/library/frosty-empty-icon.svg" />
        <p className="mt-3 out-300-14 leading-5 text-gray-500 text-center">
          {message}
        </p>
      </div>
    </div>
  );
};

export default EmptyState;
