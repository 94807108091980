import React, { useState } from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { client } from "../../../Core/utils/axiosClient";
import { useQueryClient } from "react-query"; // Import useQueryClient

type FormState = {
  errors: {
    teamName?: { message: string };
  };
};

const NewTeamModal = ({ visible, onClose, teams }) => {
  const [teamName, setTeamName] = useState<string>(""); // Define teamName as string
  const [formState, setFormState] = useState<FormState>({ errors: {} }); // Add FormState type

  const queryClient = useQueryClient(); // Initialize query client

  const handleCreate = async () => {
    const normalizedTeamName = teamName.trim();

    // Check for existing teams
    const existingTeam = teams.find(
      (team) =>
        team.teamName.trim().toLowerCase() === normalizedTeamName.toLowerCase()
    );

    if (existingTeam) {
      setFormState({
        errors: {
          teamName: { message: "Team already exists" },
        },
      });
      return; // Exit if the team already exists
    } else {
      setFormState({ errors: {} });
    }

    try {
      const { data } = await client.post("teams/create-team", {
        teamName: normalizedTeamName,
      });

      console.log("Team created successfully:", data);

      // Invalidate the query to refetch teams
      queryClient.invalidateQueries("teams");
      setTeamName("");
      onClose(); // Close the modal after successful creation
    } catch (error) {
      console.error("Error creating team:", error);
      setFormState({
        errors: {
          teamName: { message: "Failed to create team. Please try again." },
        },
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setTeamName(inputValue);

    const normalizedTeamName = inputValue.trim().toLowerCase();

    // Check for existing teams
    const existingTeam = teams.find(
      (team) => team.teamName.trim().toLowerCase() === normalizedTeamName
    );

    if (existingTeam) {
      setFormState({
        errors: {
          teamName: { message: "Team already exists" },
        },
      });
    } else {
      setFormState({ errors: {} });
    }
  };

  const hasError = !!formState.errors.teamName;
  const isInputEmpty = !teamName.trim();

  return (
    <CustomModal
      visible={visible}
      width="352px"
      onCancel={() => {
        setTeamName("");
        onClose();
      }}
      body={
        <div className="px-4 pt-3 pb-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">New Team</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <div className="my-4">
            <CustomInputBox
              placeholder="Enter a name"
              value={teamName}
              onChange={handleInputChange}
              name="teamName"
              formState={formState} // Passing the formState with error handling
            />
          </div>
          <div className="flex mt-[10px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Create"
              height="30px"
              className="text-primary-700"
              onClick={handleCreate}
              disabled={hasError || isInputEmpty}
            />
          </div>
        </div>
      }
    />
  );
};

export default NewTeamModal;
