import { Checkbox, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { FilterIcon } from "../../../Core/svgV2/FilterIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { getMyBoardTasks } from "../../../Core/redux/api/myBoardAPI";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import getStatus from "./getStatus";
import getPriorityLabel from "./getPriorityLabel";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";

// TODO : to be reviewed by Anushka
const CollaboratorFilters = ({ onClose = () => null }) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.myBoard);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const { collaboratorsWithCurrentUser } = useAppSelector(
    (state) => state.myBoard
  );

  const collaboratorList = tenantMembers.filter((user) =>
    collaboratorsWithCurrentUser.includes(user.userId)
  );

  console.log(collaboratorList, "check collaborator list");
  const [filtersApplied, setFiltersApplied] = useState(
    filters?.collaborator || []
  );
  useEffect(() => {
    setFiltersApplied(filters?.collaborator || []);
  }, [filters]);

  const handleClick = (id) => {
    if (filtersApplied.includes(id)) {
      setFiltersApplied((prev) => prev.filter((item) => item !== id));
    } else {
      setFiltersApplied((prev) => [...prev, id]);
    }
  };

  const onClear = async () => {
    setFiltersApplied([]);
    dispatch(
      updateMyBoard({
        key: "filters",
        value: {
          ...filters,
          collaborator: [],
        },
      })
    );
    if (
      areFiltersEmpty({
        ...filters,
        collaborator: [],
      })
    ) {
      await dispatch(
        getMyBoardTasks({
          limit: DEFAULT_LIMIT,
          offset: DEFAULT_OFFSET,
        })
      );
    } else {
      await dispatch(
        getMyBoardTasks({
          priorityId: filters?.priority,
          statusId: filters?.status,
          labelId: filters?.label,
          collaborator: [],
        })
      );
    }
  };

  const onDone = async () => {
    await dispatch(
      updateMyBoard({
        key: "filters",
        value: {
          ...filters,
          collaborator: filtersApplied,
        },
      })
    );
    dispatch(
      getMyBoardTasks({
        priorityId: filters?.priority,
        statusId: filters?.status,
        labelId: filters?.label,
        collaborator: filters?.collaborator,
      })
    );
  };

  return (
    <FilterLayout
      filterName="Collaborator"
      footer
      onClear={onClear}
      onClose={onClose}
      onDone={onDone}
    >
      <div className="max-h-[200px] overflow-y-scroll">
        {collaboratorList?.map((collaborator) => (
          <div
            role="button"
            className="flex items-center justify-between px-3.5 py-2.5 hover:bg-gray-50"
            key={collaborator.userId}
            onClick={() => handleClick(collaborator.userId)}
          >
            <div className="flex items-center gap-x-2">
              <CustomAvatar
                src={collaborator.profilePicture}
                size={24}
                title={getUsersName(collaborator)}
                whiteText
                color={collaborator.profilePictureColorCode}
              />
              <p className="text-gray-900 capitalize out-300-12">
                {getUsersName(collaborator)}
              </p>
            </div>

            <Checkbox checked={filtersApplied.includes(collaborator.userId)} />
          </div>
        ))}
      </div>
    </FilterLayout>
  );
};

const LabelFilters = ({ onClose = () => null }) => {
  const dispatch = useAppDispatch();
  const { labels } = useAppSelector((state) => state.schedule);
  const { filters } = useAppSelector((state) => state.myBoard);

  const [filtersApplied, setFiltersApplied] = useState(filters?.label || []);
  useEffect(() => {
    setFiltersApplied(filters?.label || []);
  }, [filters]);

  const handleClick = (id) => {
    if (filtersApplied.includes(id)) {
      setFiltersApplied((prev) => prev.filter((item) => item !== id));
    } else {
      setFiltersApplied((prev) => [...prev, id]);
    }
  };

  const onClear = async () => {
    setFiltersApplied([]);
    dispatch(
      updateMyBoard({
        key: "filters",
        value: {
          ...filters,
          label: [],
        },
      })
    );
    if (
      areFiltersEmpty({
        ...filters,
        label: [],
      })
    ) {
      await dispatch(
        getMyBoardTasks({
          limit: DEFAULT_LIMIT,
          offset: DEFAULT_OFFSET,
        })
      );
    } else {
      await dispatch(
        getMyBoardTasks({
          priorityId: filters?.priority,
          statusId: filters?.status,
          labelId: [],
          collaborator: filters?.collaborator,
        })
      );
    }
  };

  const onDone = async () => {
    await dispatch(
      updateMyBoard({
        key: "filters",
        value: {
          ...filters,
          label: filtersApplied,
        },
      })
    );
    dispatch(
      getMyBoardTasks({
        priorityId: filters?.priority,
        statusId: filters?.status,
        labelId: filtersApplied,
        collaborator: filters?.collaborator,
      })
    );
  };

  return (
    <FilterLayout
      filterName="Label"
      footer
      onClear={onClear}
      onDone={onDone}
      onClose={onClose}
    >
      <div className="max-h-[200px] overflow-y-scroll">
        {labels
          ?.filter((item) => item.isMyBoard)
          ?.map((label) => (
            <div
              className="flex items-center justify-between px-3.5 py-2.5 hover:bg-gray-50"
              key={label.id}
              onClick={() => handleClick(label.id)}
            >
              <MyBoardLabel
                labelColorId={label.labelColorId}
                text={label.name}
              />
              <Checkbox checked={filtersApplied.includes(label.id)} />
            </div>
          ))}
      </div>
    </FilterLayout>
  );
};

const StatusFilters = ({ onClose = () => null }) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.myBoard);
  const [filtersApplied, setFiltersApplied] = useState(filters?.status || []);

  useEffect(() => {
    setFiltersApplied(filters?.status || []);
  }, [filters]);

  const handleClick = (id) => {
    if (filtersApplied.includes(id)) {
      setFiltersApplied((prev) => prev.filter((item) => item !== id));
    } else {
      setFiltersApplied((prev) => [...prev, id]);
    }
  };

  const onClear = async () => {
    setFiltersApplied([]);
    dispatch(
      updateMyBoard({
        key: "filters",
        value: {
          ...filters,
          status: [],
        },
      })
    );
    if (
      areFiltersEmpty({
        ...filters,
        status: [],
      })
    ) {
      await dispatch(
        getMyBoardTasks({
          limit: DEFAULT_LIMIT,
          offset: DEFAULT_OFFSET,
        })
      );
    } else {
      await dispatch(
        getMyBoardTasks({
          priorityId: filters?.priority,
          statusId: [],
          labelId: filters?.label,
          collaborator: filters?.collaborator,
        })
      );
    }
  };

  const onDone = async () => {
    await dispatch(
      updateMyBoard({
        key: "filters",
        value: {
          ...filters,
          status: filtersApplied,
        },
      })
    );
    dispatch(
      getMyBoardTasks({
        priorityId: filters?.priority,
        statusId: filtersApplied,
        labelId: filters?.label,
        collaborator: filters?.collaborator,
      })
    );
  };

  return (
    <FilterLayout
      filterName="Status"
      footer
      onClear={onClear}
      onDone={onDone}
      onClose={onClose}
    >
      <div
        role="button"
        onClick={() => handleClick(1)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5"
      >
        {getStatus({ statusId: 1 })}
        <Checkbox checked={filtersApplied.includes(1)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(2)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5"
      >
        {getStatus({ statusId: 2 })}
        <Checkbox checked={filtersApplied.includes(2)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(3)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5"
      >
        {getStatus({ statusId: 3 })}
        <Checkbox checked={filtersApplied.includes(3)} />
      </div>
    </FilterLayout>
  );
};

const PriorityFilters = ({ onClose = () => null }) => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.myBoard);
  const [filtersApplied, setFiltersApplied] = useState(filters?.priority || []);

  useEffect(() => {
    setFiltersApplied(filters?.priority || []);
  }, [filters]);

  const handleClick = (id) => {
    if (filtersApplied.includes(id)) {
      setFiltersApplied((prev) => prev.filter((item) => item !== id));
    } else {
      setFiltersApplied((prev) => [...prev, id]);
    }
  };

  const onClear = async () => {
    setFiltersApplied([]);
    await dispatch(
      updateMyBoard({
        key: "filters",
        value: {
          ...filters,
          priority: [],
        },
      })
    );
    if (
      areFiltersEmpty({
        ...filters,
        priority: [],
      })
    ) {
      await dispatch(
        getMyBoardTasks({
          limit: DEFAULT_LIMIT,
          offset: DEFAULT_OFFSET,
        })
      );
    } else {
      await dispatch(
        getMyBoardTasks({
          priorityId: [],
          statusId: filters?.status,
          labelId: filters?.label,
          collaborator: filters?.collaborator,
        })
      );
    }
  };

  const onDone = async () => {
    await dispatch(
      updateMyBoard({
        key: "filters",
        value: {
          ...filters,
          priority: filtersApplied,
        },
      })
    );
    dispatch(
      getMyBoardTasks({
        priorityId: filtersApplied,
        statusId: filters?.status,
        labelId: filters?.label,
        collaborator: filters?.collaborator,
      })
    );
  };

  return (
    <FilterLayout
      filterName="Priority"
      footer
      onClear={onClear}
      onDone={onDone}
      onClose={onClose}
    >
      <div
        role="button"
        onClick={() => handleClick(1)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getPriorityLabel(1)}
        <Checkbox checked={filtersApplied.includes(1)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(2)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getPriorityLabel(2)}
        <Checkbox checked={filtersApplied.includes(2)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(3)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getPriorityLabel(3)}
        <Checkbox checked={filtersApplied.includes(3)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(4)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        {getPriorityLabel(4)}
        <Checkbox checked={filtersApplied.includes(4)} />
      </div>
    </FilterLayout>
  );
};

const FilterItem = ({ filterName, filterContent }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  return (
    <Popover
      getPopupContainer={(parent) => parent?.parentElement}
      trigger={"click"}
      content={filterContent}
      arrow={false}
      placement="rightBottom"
      onOpenChange={setFilterOpen}
      open={filterOpen}
    >
      <div
        className="cursor-pointer hover:bg-gray-50 px-3.5 py-2.5"
        onClick={() => setFilterOpen(true)}
      >
        <p className="text-gray-900 out-300-14">{filterName}</p>
      </div>
    </Popover>
  );
};

const FilterLayout = ({
  filterName,
  children,
  footer = false,
  onClear = () => null,
  onDone = () => null,
  onClose = () => null,
}) => {
  const [filterOpen, setFilterOpen] = useState(true);
  const handleClose = () => {
    setFilterOpen(false);
    onDone();
    onClose();
  };

  return (
    <>
      {/* {filterOpen ? ( */}
      <div
        className="w-[320px] bg-white border border-gray-100 rounded-lg "
        style={{
          boxShadow:
            "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <h3 className="out-300-14 capitalize pt-[14px] pb-2.5 px-[14px] border-b border-gray-200 text-gray-500">
          {filterName}
        </h3>
        <div className="max-h-[304px] overflow-y-auto">{children}</div>
        {footer ? (
          <div className="flex border-t border-gray-200 pt-[2px] pb-[2px] justify-end items-center">
            <CustomButton
              className="text-gray-500 bg-transparent out-500-14"
              text="Clear"
              onClick={onClear}
            />
            <CustomButton
              className="bg-transparent out-500-14 text-primary-600"
              text="Done"
              onClick={handleClose}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* ) : (
        <></>
      )} */}
    </>
  );
};

const Filters = ({ onClose = () => null }) => {
  return (
    <div>
      <FilterLayout filterName="Filters" onClose={onClose}>
        <FilterItem
          filterContent={<CollaboratorFilters onClose={onClose} />}
          filterName="Collaborators"
        />

        <FilterItem
          filterContent={<PriorityFilters onClose={onClose} />}
          filterName="Priority"
        />
        <FilterItem
          filterContent={<LabelFilters onClose={onClose} />}
          filterName="Label"
        />
        <FilterItem
          filterContent={<StatusFilters onClose={onClose} />}
          filterName="Status"
        />
      </FilterLayout>
    </div>
  );
};

export default Filters;

const AppliedFilterItem = ({ filterName, filterCount, filterContent }) => {
  return (
    <Popover
      trigger={"click"}
      content={filterContent}
      arrow={false}
      placement="bottomLeft"
    >
      <button className="flex items-center justify-center px-2.5 py-[4px] gap-x-2  rounded border border-gray-200 bg-white">
        <p className="out-500-14 text-gray-700">
          {filterName} : {filterCount}
        </p>
        <ChevronIcon className=" text-gray-700" />
      </button>
    </Popover>
  );
};

const areFiltersEmpty = (filters) => {
  return (
    !filters?.collaborator?.length &&
    !filters?.label?.length &&
    !filters?.status?.length &&
    !filters?.priority?.length
  );
};

export const AppliedFilters = ({ style = {} }) => {
  const dispatch = useAppDispatch();
  const { filters, myBoardKanbanView } = useAppSelector(
    (state) => state.myBoard
  );

  const collaboratorFilters = filters?.collaborator || [];
  const labelFilters = filters?.label || [];
  const statusFilters = filters?.status || [];
  const priorityFilters = filters?.priority || [];
  const allFilters = [
    ...collaboratorFilters,
    ...labelFilters,
    ...statusFilters,
    ...priorityFilters,
  ];

  const handleResetFilters = async () => {
    if (myBoardKanbanView) {
      await dispatch(getMyBoardTasks({}));
    } else {
      await dispatch(
        getMyBoardTasks({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET })
      );
    }
    dispatch(
      updateMyBoard({
        key: "filters",
        value: {
          collaborator: [],
          label: [],
          status: [],
          priority: [],
        },
      })
    );
  };

  return (
    <div className="flex gap-x-3 items-center" style={style}>
      {allFilters?.length > 0 && (
        <CustomButton
          text="Reset All filters"
          height="30px"
          className="bg-transparent text-gray-500 out-500-14"
          onClick={handleResetFilters}
        />
      )}
      {collaboratorFilters?.length > 0 && (
        <AppliedFilterItem
          filterCount={collaboratorFilters?.length}
          filterName={"Collaborator"}
          filterContent={<CollaboratorFilters />}
        />
      )}
      {labelFilters?.length > 0 && (
        <AppliedFilterItem
          filterCount={labelFilters?.length}
          filterName={"Label"}
          filterContent={<LabelFilters />}
        />
      )}
      {statusFilters?.length > 0 && (
        <AppliedFilterItem
          filterCount={statusFilters?.length}
          filterName={"Status"}
          filterContent={<StatusFilters />}
        />
      )}
      {priorityFilters?.length > 0 && (
        <AppliedFilterItem
          filterCount={priorityFilters?.length}
          filterName={"Priority"}
          filterContent={<PriorityFilters />}
        />
      )}
    </div>
  );
};
