import React, { useState, useEffect, useRef } from "react";
import { NewtaskCard } from "./TaskCard";
import { useDroppable } from "@dnd-kit/core";
import usePermission from "../../../Core/hooks/usePermission";
import { useAppSelector } from "../../../Core/redux/hooks";

const KanbanColumn = ({
  status,
  changeTaskStatus,
  children,
  className,
  channel,
  parentTaskId = "",
  customPadding = false,
}) => {
  const { setNodeRef } = useDroppable({
    id: status,
    data: { channel },
  });
  const [isHovered, setIsHoverd] = useState(false);
  const { showSubtaskView } = useAppSelector((state) => state.myBoard);

  console.log(channel, status, "channel and status");
  const { hasPermission: TASK_EVENT_SCOPE_ADD } = usePermission("8");
  return (
    <div
      ref={setNodeRef}
      className={` h-full ${customPadding ? "pb-[10px]" : "pb-[40px]"}   `}
      onMouseEnter={() => {
        setIsHoverd(true);
        console.log(parentTaskId, "what ?");
      }}
      onMouseLeave={() => setIsHoverd(false)}
    >
      {children}
      {status && TASK_EVENT_SCOPE_ADD && (
        <div>
          <NewtaskCard
            status={channel}
            isHovered={
              !showSubtaskView
                ? isHovered
                : +channel?.statusId === 1
                ? true
                : false
            }
            parentTaskId={showSubtaskView ? parentTaskId : ""}
          />
        </div>
      )}
    </div>
  );
};

export default KanbanColumn;
