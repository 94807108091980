/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { Dropdown, Tooltip, MenuProps, message } from "antd";
import { CommentIcon, CommentWithRedDotIcon } from "../../Core/svgV2/Comments";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import { ShareInMessageIcon } from "../../Core/svgV2/ShareFile";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { ShareAccessIcon } from "../../Core/svgV2/UserIcon";
import { EditIcon } from "../../Core/svgV2/EditIcon";
import { DownloadIcon } from "../../Core/svgV2/DownloadIcon";
import { CopyIcon } from "../../Core/svgV2/CopyIcon";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import { getPhaseName } from "../../Core/utils/getPhaseName";
import { client } from "../../Core/utils/axiosClient";
import { useNavigate, useParams } from "react-router-dom";
import { downloadFiles } from "../../Core/utils";

type Props = {
  folder: any;
  isGlobal?: boolean;
};

const FolderContainer = ({ folder, isGlobal }: Props) => {
  const dispatch = useAppDispatch();

  const { openCommentsModal } = useAppSelector((state) => state.vault);

  // const { hasPermission: FOLDER_FILE_LINK_EDIT } = usePermission("23");

  // const canEdit =
  //   folder.isOwner === 1 ? true : folder.canEdit === 1 ? true : false;

  const groupItems = [
    {
      key: "1",
      label: (
        <div className="leading-5 text-gray-700 out-300-14">View details</div>
      ),

      icon: <EllipseIcon className="text-gray-700" />,
    },
    { key: "2", type: "divider" },
    {
      key: "3",
      label: (
        <div className="leading-5 text-gray-700 out-300-14">Open comments</div>
      ),
      icon: <CommentIcon className="text-gray-700" width="16" height="16" />,
    },
    {
      key: "4",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            folder.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Share
        </div>
      ),

      icon: (
        <ShareAccessIcon
          className={`text-gray-700 ${
            folder.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "5",
      label: (
        <div className="leading-5 text-gray-700 out-300-14">
          Send in message
        </div>
      ),
      icon: <ShareInMessageIcon className="text-gray-700" />,
    },
    {
      key: "6",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            folder.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Rename
        </div>
      ),

      icon: (
        <EditIcon
          className={`text-gray-700 ${
            folder.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "7",
      label: (
        <div className="leading-5 text-gray-700 out-300-14">Make a copy</div>
      ),
      icon: <CopyIcon className="text-gray-700" />,
    },
    {
      key: "8",
      label: <div className="leading-5 text-gray-700 out-300-14">Download</div>,
      icon: <DownloadIcon className="text-gray-700" />,
    },
    { key: "9", type: "divider" },
    {
      key: "10",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            folder.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Delete
        </div>
      ),

      icon: (
        <DeleteIcon
          className={`text-gray-700 ${
            folder.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
  ];

  const { projectId } = useParams();
  const handelDownload = async () => {
    const { data } = await client.get("/repository/all-files", {
      params: { folderId: folder.id, projectId },
    });

    if (data.result && data.result.length > 0) {
      const fileUrls = data.result.map((d) => d.url);
      const fileSizes = data.result.map((d) => d.fileSize);

      downloadFiles(fileUrls, fileSizes);
    } else {
      message.error("Nothing to download!", 3);
    }
  };

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setDropdownOpen(false);
    switch (key) {
      case "1":
        dispatch(
          updateVault({
            key: "viewDetailsDrawer",
            value: {
              visible: true,
              documentType: "folder",
              document: {
                title: folder.folderName,
                usersWithAccess: folder.usersWithAccess,
                createdAt: folder.createdAt,
                filesCount: folder.filesCount,
                docId: folder.id,
                createdById: folder.createdById,
                folderSize: folder.folderSize,
              },
            },
          })
        );
        break;
      case "3":
        dispatch(
          updateVault({
            key: "openCommentsModal",
            value: {
              visible: true,
              docType: "folder",
              name: folder.folderName,
              docId: folder.id,
              comments: [],
              users: folder.usersWithAccess,
            },
          })
        );
        break;
      case "4":
        if (folder.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "folder",
                  folder,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "shareAccessModal",
            value: {
              visible: true,
              docId: folder.id,
              users: folder.usersWithAccess,
              isGlobal,
              docType: "folder",
              docDetails: {
                ...folder,
              },
            },
          })
        );
        break;
      case "5":
        dispatch(
          updateVault({
            key: "shareInMessageModal",
            value: {
              type: "folder",
              visible: true,
              url:
                window.location.href +
                `/${folder.folderName}-${folder.id}?shared-folder-id=${folder.id}`,
              file: [],
            },
          })
        );
        break;
      case "6":
        if (folder.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "folder",
                  folder,
                },
              },
            })
          );
        }

        dispatch(
          updateVault({
            key: "renameModal",
            value: {
              visible: true,
              type: "folder",
              id: folder.id,
              currentName: folder.folderName,
            },
          })
        );
        break;
      case "7":
        dispatch(
          updateVault({
            key: "copyModal",
            value: {
              visible: true,
              docType: "folder",
              id: folder.id,
            },
          })
        );
        break;
      case "8":
        handelDownload();
        break;
      case "10":
        if (folder.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "folder",
                  folder,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "deleteFolderModal",
            value: {
              visible: true,
              id: folder.id,
            },
          })
        );
        break;
      default:
        console.log("item others");
        break;
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div
        className="w-[211px] rounded-lg bg-white border border-gray-200 pt-4 pb-[10px] px-3 group relative cursor-pointer"
        onDoubleClick={() => {
          //const newTitle = slugify(title);
          let path = location.pathname;
          if (isGlobal) {
            path = location.pathname + "/global";
          }
          const newPath = path + `/${folder.folderName}-${folder.id}`;
          navigate(newPath);
        }}
      >
        <div className="flex justify-between">
          <div className="flex items-center pl-1 gap-x-3">
            {folder.phaseId && (
              <Tooltip
                className="text-white cursor-pointer"
                title={getPhaseName(folder.phaseId)}
              >
                <div>
                  <PhaseIcon
                    phaseId={+folder.phaseId}
                    width="10px"
                    height="10px"
                  />
                </div>
              </Tooltip>
            )}

            <Tooltip
              className="text-white cursor-pointer"
              title="Open comments"
              placement="bottom"
              color="#0D3271"
            >
              <div
                className="mt-[-3px]"
                onClick={() => {
                  dispatch(
                    updateVault({
                      key: "openCommentsModal",
                      value: {
                        visible: true,
                        docType: "folder",
                        name: folder.folderName,
                        docId: folder.id,
                        comments: [],
                        users: folder.usersWithAccess,
                      },
                    })
                  );
                }}
              >
                {openCommentsModal.comments.length > 0 ? (
                  <CommentWithRedDotIcon />
                ) : (
                  <CommentIcon />
                )}
              </div>
            </Tooltip>
            <Tooltip
              className="text-white cursor-pointer"
              title="Shared document"
              placement="bottom"
              color="#0D3271"
            >
              <div
                onClick={() =>
                  dispatch(
                    updateVault({
                      key: "shareAccessModal",
                      value: {
                        visible: true,
                        docId: folder.id,
                        users: folder.usersWithAccess,
                        docType: "folder",
                        docDetails: {
                          ...folder,
                        },
                      },
                    })
                  )
                }
              >
                <img src="/images/users.svg" alt="people with access" />
              </div>
            </Tooltip>
          </div>

          <div
            className={`absolute top-[10px] right-[12px] cursor-pointer group-hover:block ${
              dropdownOpen ? "block" : "hidden"
            }`}
          >
            <Dropdown
              onOpenChange={(e) => setDropdownOpen(e)}
              menu={{ items: groupItems, onClick }}
              trigger={["click"]}
              dropdownRender={(origin) => {
                return <div className="w-[250px]">{origin}</div>;
              }}
            >
              <DotsVerticalIcon className="text-black" />
            </Dropdown>
          </div>
        </div>
        <div
          // onClick={() => {
          //   //const newTitle = slugify(title);
          //   let path = location.pathname;
          //   if (isGlobal) {
          //     path = location.pathname + "/global";
          //   }
          //   const newPath = path + `/${folder.folderName}-${folder.id}`;
          //   navigate(newPath);
          // }}
          className="flex items-center mt-4 cursor-pointer"
        >
          <img src="/images/icons/folder-fill-vault.svg" className="w-5 h-5" />
          <Tooltip title={folder.folderName} placement="bottom">
            <p className="ml-1.5 out-500-14 whitespace-nowrap truncate leading-5 text-gray-700">
              {folder.folderName}
            </p>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default FolderContainer;
