import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { Calendar, ConfigProvider } from "antd";
import {
  getPhaseColor,
  getPhaseName,
} from "../../../../Core/utils/getPhaseName";
import isBetween from "dayjs/plugin/isBetween";
import { ChevronIcon } from "../../../../Core/svgV2/Chevron";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import { updateSchedule } from "../../../../Core/redux/slices/schedule.slice";

dayjs.extend(isBetween);

const CustomCalendar = ({ tasks, month }) => {
  const [date, setDate] = useState(dayjs());
  const { selectedPhases } = useAppSelector((state) => state.schedule);
  const phases = tasks.filter((task) => !task.type);

  const dateFullCellRender = (value) => {
    const selectedDate = dayjs(date).isSame(value, "day");

    const getBorderAndBg = () => {
      const currentPhase = selectedPhases.find((phase) => {
        if (!phase.startDate || !phase.endDate) {
          return false;
        }
        return (
          dayjs(value).isBetween(phase.startDate, phase.endDate, "day", "[]") ||
          dayjs(phase.startDate).isSame(value, "day")
        );
      });

      if (currentPhase) {
        const phaseColor = getPhaseColor(currentPhase.id, "light");
        const backgroundColor = getPhaseColor(currentPhase.id, "light");
        const phaseName = getPhaseName(currentPhase.id);
        return {
          backgroundColor,
          opacity: 0.5,
          borderLeftClass: dayjs(currentPhase.startDate).isSame(value, "day")
            ? `border-l-2 `
            : "",
          borderRightClass: dayjs(currentPhase.endDate).isSame(value, "day")
            ? `border-r-2 `
            : "",
          borderColor: phaseColor,
          marginLeft: dayjs(currentPhase.startDate).isSame(value, "day")
            ? "2px"
            : "",
          marginRight: dayjs(currentPhase.endDate).isSame(value, "day")
            ? "2px"
            : "",
          phaseName: phaseName,
        };
      } else {
        return {
          backgroundColor: "",
          borderRightClass: "",
          borderLeftClass: "",
          phaseName: "",
        };
      }
    };
    const phaseStyles = getBorderAndBg();
    return (
      <div
        className={` h-full flex items-center justify-center `}
        style={{
          backgroundColor: phaseStyles.backgroundColor,
        }}
      >
        <div className="flex justify-center items-center z-10">
          {selectedDate ? (
            <div className="bg-primary-600 rounded-full flex justify-center  items-center h-[22px] w-[22px] text-white text-xs text-center ">
              <span>{value.date()}</span>
            </div>
          ) : (
            <div className="flex justify-center  items-center h-[22px] w-[22px]">
              {value.date()}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="custom-calendar roadmap-calendar w-[250px] ">
      <style>
        {`
        .ant-picker-content {
          background-color: #F0F5FA !important;
        }
        .ant-picker-calendar .ant-picker-cell-disabled::before{
          background:transparent !important
        }
        .ant-picker-cell{
          height:42px
        }
        `}
      </style>

      <Calendar
        onSelect={() => null}
        fullscreen={false}
        value={dayjs(`${month}`, "MMMM YYYY")}
        disabledDate={() => true}
        fullCellRender={dateFullCellRender}
        headerRender={({ value }) => {
          return (
            <div className="out-500-14 w-full flex justify-center rounded bg-slate-200 items-center text-gray-700 py-1.5">
              {value.format("MMMM YYYY")}
            </div>
          );
        }}
        className="bg-white"
      />
    </div>
  );
};

const CalendarView = ({ timeRange, tasks }) => {
  const [currentYear, setCurrentYear] = useState(timeRange.fromSelectYear);
  const { timelineSelected, selectedPhases, phases } = useAppSelector(
    (state) => state.schedule
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (timelineSelected === 3) {
      const phasesToPush = phases.map((phase) => ({ ...phase, isPhase: true }));
      dispatch(
        updateSchedule({
          key: "selectedPhases",
          value: phasesToPush,
        })
      );
    }
  }, [timelineSelected, phases]);

  const minYear = timeRange.fromSelectYear;
  const maxYear = timeRange.toSelectYear;

  const handlePrevYear = () => {
    if (currentYear > minYear) {
      setCurrentYear(currentYear - 1);
    }
  };

  const handleNextYear = () => {
    if (currentYear < maxYear) {
      setCurrentYear(currentYear + 1);
    }
  };

  const startYear = dayjs(`${currentYear}`, "YYYY");
  const endYear = dayjs(`${currentYear}`, "YYYY");

  const monthsBetween = [];
  let currentMonth = startYear;

  while (
    currentMonth.isBefore(endYear) ||
    currentMonth.isSame(endYear, "year")
  ) {
    monthsBetween.push(currentMonth.format("MMMM YYYY"));
    currentMonth = currentMonth.add(1, "month");
  }

  const isDisabled = (isPrev) => {
    return isPrev ? currentYear <= minYear : currentYear >= maxYear;
  };

  return (
    <div className="py-3 w-full bg-gray-50 flex flex-wrap gap-x-[64px] justify-center items-center gap-y-[27px] max-h-[100%] overflow-y-auto">
      <div
        className="w-full  mx-4 h-[34px] px-1.5 py-[7px]  rounded justify-center items-center gap-3 inline-flex"
        style={{ background: "#F9FBFE" }}
      >
        <button
          onClick={
            isDisabled(true)
              ? () => {
                  console.log("");
                }
              : handlePrevYear
          }
          style={{
            color: isDisabled(true) ? "#EFF8FF" : "#165ABF",
            cursor: isDisabled(true) ? "not-allowed" : "pointer",
          }}
          disabled={isDisabled(true)}
        >
          <ChevronIcon
            style={{
              transform: "rotate(90deg)",
              color: isDisabled(true) ? "#B2B2B2" : "#165ABF",
            }}
          />
        </button>
        <span style={{ color: "#165ABF" }}>{currentYear}</span>
        <button
          onClick={isDisabled(false) ? () => console.log("") : handleNextYear}
          style={{
            color: isDisabled(false) ? "#EFF8FF" : "#165ABF",
            cursor: isDisabled(false) ? "not-allowed" : "pointer",
          }}
          disabled={isDisabled(false)}
        >
          <ChevronIcon
            style={{
              transform: "rotate(-90deg)",
              color: isDisabled(false) ? "#B2B2B2" : "#165ABF",
            }}
          />
        </button>
      </div>
      {monthsBetween.map((month, index) => (
        <ConfigProvider
          key={index}
          theme={{
            components: {
              Calendar: {
                fullBg: "#ffd7e2",
              },
            },
          }}
        >
          <CustomCalendar key={month} tasks={tasks} month={month} />
        </ConfigProvider>
      ))}
    </div>
  );
};

export default CalendarView;
