import { Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomOnboardingDatePicker from "../../../Core/CommonV2/CustomOnboardingDatePicker";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  compeleteResetProjectOnboarding,
  updateProjectOnboarding,
} from "../../../Core/redux/slices/projectOnboarding.slice";
import CurrentStep from "./CurrentStep";
import StepHeading from "./StepHeading";
import StepLayout from "./StepLayout";
import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import { client } from "../../../Core/utils/axiosClient";
import { useNavigate } from "react-router-dom";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import { getMyBoardTasks } from "../../../Core/redux/api/myBoardAPI";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";

const PhaseCard = ({ phaseId, phaseName, disabled, color }) => {
  const dispatch = useAppDispatch();
  const [hover, setHover] = useState(false);

  const { phaseDetails, projectDuration } = useAppSelector(
    (state) => state.projectOnboarding
  );

  const [focused, setFocused] = useState(false);

  const handelChange = (key, val) => {
    const newPhaseDetails = phaseDetails.map((p) => {
      if (p.id === phaseId) {
        const newP = { ...p };
        newP[key] = val;
        return newP;
      }
      return p;
    });
    dispatch(
      updateProjectOnboarding({
        key: "phaseDetails",
        value: newPhaseDetails,
      })
    );
  };

  const phase = phaseDetails.find((phase) => phase.id == phaseId);
  const { startDate, endDate } = phase;
  const diff =
    startDate && endDate ? dayjs(endDate).diff(startDate, "days") : 0;

  function createDisableDate(phaseDetails, phaseId, projectDuration) {
    return (current) => {
      const phaseStartDate = phaseDetails.find(
        (p) => p.id === phaseId
      )?.startDate;
      const phaseEndDate = phaseDetails.find((p) => p.id === phaseId)?.endDate;
      return (
        (current &&
          (current < dayjs(projectDuration[0]) ||
            current > dayjs(projectDuration[1]) ||
            (phaseStartDate && current < dayjs(phaseStartDate)) ||
            (phaseEndDate && current > dayjs(phaseEndDate)))) ||
        ""
      );
    };
  }

  return (
    <div
      className=" rounded-2xl h-[222px] min-w-[180px] pt-[18px] pb-[16px] px-4 relative hover:shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)] transform duration-200"
      style={{
        border: focused
          ? `1px solid ${color}`
          : hover
          ? "1px solid #F9FBFE"
          : "1px solid #DDE5ED",
        backgroundColor: disabled ? "#F7FAFD" : "#F9FBFE",
        cursor: disabled ? "not-allowed" : "pointer",
        boxShadow: hover
          ? "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)"
          : "",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocused(true)}
      // onFocus={()=>conso}
      //onBlur={() => setFocused(false)}
    >
      {disabled ? (
        <Tooltip
          title={
            <p className="out-500-12">
              Not included.
              <br />
              Add in the previous step
            </p>
          }
          placement="right"
        >
          <div
            className=" rounded-2xl h-[222px] w-[180px] pt-[18px] pb-[16px] px-4 absolute bg-gray-50 z-10 top-0 left-0 "
            style={{
              opacity: 0.5,
            }}
          ></div>
        </Tooltip>
      ) : (
        <></>
      )}
      <div className="flex items-center ">
        <PhaseIcon height="20" width="20" phaseId={+phaseId} />

        <p className="out-500-12 ml-3.5">{phaseName}</p>
      </div>
      <CustomOnboardingDatePicker
        value={phaseDetails.find((p) => p.id === phaseId)?.startDate || ""}
        onChange={(e) =>
          handelChange("startDate", dayjs(e).format("YYYY-MM-DD"))
        }
        placeholder="Start Date"
        className="mt-3 out-500-12 text-gray-400"
        disableDate={createDisableDate(phaseDetails, phaseId, projectDuration)}
      />
      <CustomOnboardingDatePicker
        value={phaseDetails.find((p) => p.id === phaseId)?.endDate || ""}
        onChange={(e) => handelChange("endDate", dayjs(e).format("YYYY-MM-DD"))}
        placeholder="End Date"
        className="mt-6 "
        disableDate={createDisableDate(phaseDetails, phaseId, projectDuration)}
      />
      <div className="out-500-10 text-gray-400 mt-6">Duration</div>
      <div className="out-500-12 text-gray-700 mt-1.5">{diff} days</div>
    </div>
  );
};

const Step6 = () => {
  const {
    projectName,
    projectImage,
    projectColor,

    clientName,
    clientImage,
    internalProject,
    clientColor,

    projectDuration,
    clientLocation,
    // step 4
    softwareType,
    // step 5
    selectedPhase,
    phaseDetails,
    components,
  } = useAppSelector((state) => state.projectOnboarding);
  const { selectedTasks } = useAppSelector((state) => state.myBoard);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const color = [
    { id: 1, color: "#FFDB01" },
    { id: 2, color: "#F92B63" },
    { id: 3, color: "#6938EF" },
    { id: 4, color: "#33B7FF" },
    { id: 5, color: "#46C66C" },
  ];

  const handelCreateProject = async () => {
    const phases = phaseDetails.filter((p) => selectedPhase.includes(p.id));

    const project = {
      title: projectName,
      //components,
      startDate: projectDuration[0],
      endDate: projectDuration[1],
      projectColor,
      projectImage,
      clientName,
      clientImage,
      clientLocation,
      projectType: softwareType,
      phases,
      internalProject,
      clientColor,
    };
    const { data } = await client.post(`/project/create`, project);

    if (data.projectId) {
      dispatch(compeleteResetProjectOnboarding({}));

      navigate(`/dashboard/${data.projectId}`);
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `New Project`,
              subtitle: `Created a new project`,
              description: projectName,
            },
          },
        })
      );
      dispatch(
        updateDashboard({
          key: "createRoleModal",
          value: true,
        })
      );
    }
  };
  const sortedPhases = [...phaseDetails].sort((a, b) => {
    return selectedPhase.includes(b.id) ? 1 : -1;
  });

  return (
    <StepLayout style={{ width: "90%" }}>
      <CurrentStep className={"mt-20"} currentStep={6} totalStep={6} />
      <StepHeading
        title={
          <p className="text-gray-900 out-500-20" id="stepHead">
            Set it up!
          </p>
        }
        subTitle={
          <p className="out-400-16 text-gray-500 ">
            Setting up phases will ensure you finish the project within your
            timeline.
          </p>
        }
      />

      <div className=" mt-6 flex gap-x-6 overflow-x-scroll py-2  pr-[36px] scrollbar-hide">
        {sortedPhases.map((phase) => (
          <PhaseCard
            key={phase.id}
            phaseId={phase.id}
            phaseName={phase.phaseName}
            disabled={!selectedPhase.includes(phase.id)}
            color={color[phase.id - 1].color}
          />
        ))}
      </div>

      <div className="flex justify-between items-center mt-6">
        <div className=" flex gap-x-6">
          <CustomButton
            text="Create Project"
            className="out-500-14 text-white bg-primary-600 "
            height="30px"
            width="111px"
            onClick={() => handelCreateProject()}
          />
          <CustomButton
            text="Back"
            className="border border-gray-300 text-gray-700 bg-white out-500-14"
            height="30px"
            width="51px"
            onClick={() => dispatch(changeStep(5))}
          />
        </div>
        <div
          className="out-500-14 text-primary-600 cursor-pointer pr-10 "
          onClick={() => handelCreateProject()}
        >
          {" "}
          I’ll do it later
        </div>
      </div>
    </StepLayout>
  );
};

export default Step6;
