import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getGoalsBySprint,
  updateGoal,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import { useNavigate, useParams } from "react-router-dom";

const MoveSprintsModal = ({ visible, onClose, onMove }) => {
  const dispatch = useAppDispatch();
  const { projectId, taskId } = useParams();
  const { goals, moveSprintsModal } = useAppSelector((state) => state.schedule);

  const navigate = useNavigate();

  const selectedGoal = goals.find((goal) => goal.id == taskId);

  //   const handleMove = async () => {
  //     await dispatch(
  //       updateGoal({
  //         key: "moveSprintsModal",
  //         value: {
  //           visible: false,
  //           sprint: "",
  //         },
  //       })
  //     );
  //   };
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Move Tasks</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            {` You are moving these tasks to`}{" "}
            <span className="text-black">{`${moveSprintsModal?.sprint}
           `}</span>
            <br /> {` Are you sure you want to move this task?`}
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Move"
              height="30px"
              width="101px"
              className="bg-error-700 text-white"
              onClick={onMove}
            />
          </div>
        </div>
      }
    />
  );
};

export default MoveSprintsModal;
