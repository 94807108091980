import React, { useCallback, useMemo, useState } from "react";
import { BarItemProps, ResponsiveBar } from "@nivo/bar";
import { SettingIcon } from "../../Core/svgV2/SettingsIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { Popover, Checkbox, Divider, Tooltip } from "antd";
import { useTooltip } from "@nivo/tooltip";
import { DeleteScheduleIcon } from "../../Core/svgV3/DeleteIcon";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  calculateNiceTickInterval,
  CustomGridLine,
  generateDynamicTickValues,
  roundToNextMaxValue,
} from "./CustomBulletChart";
import { ReportsInfoIcon } from "../../Core/svgV3/InfoIcon";
import ReactDOM from "react-dom";

type BarDatum = {
  max: number;
  id: string;
  tasks: number;
  bugs: number;
  height: number;
};

const formatTime = (timeInHours) => {
  if (timeInHours === 0) return "0h 0m";
  const hours = Math.floor(timeInHours);
  const minutes = Math.round((timeInHours - hours) * 60);
  return `${hours}h ${minutes}m`;
};

const CustomBar: React.FC<
  BarItemProps<BarDatum> & { selectedKeys: string[]; chartType: number }
> = ({ bar, selectedKeys, chartType }) => {
  const { tasks = 0, bugs = 0 } = bar.data.data;
  const { x, y, width, height } = bar;

  // Determine colors based on chartType
  const tasksBarColor =
    chartType === 4 || chartType === 10 ? "#D1E9FF" : "#D9D6FE";
  const bugsBarColor =
    chartType === 4 || chartType === 10 ? "#2E90FA" : "#7A5AF8";

  // Show ratio if both tasks and bugs are selected
  const showRatio =
    (selectedKeys.includes("tasks") && selectedKeys.includes("bugs")) ||
    (!selectedKeys.includes("tasks") && !selectedKeys.includes("bugs"));

  const ratio = showRatio && tasks && bugs ? (tasks / bugs).toFixed(2) : null;

  // Calculate max value for proportional scaling
  const maxValue = bar.data.data.height || 10; // Use a default minimum value to avoid zero scaling

  // Calculate heights for tasks and bugs based on maxValue
  const tasksHeight = (tasks * height) / maxValue;
  const bugsHeight = (bugs * height) / maxValue;

  // Calculate y-positions for tasks and bugs
  const tasksY = y + height - tasksHeight;
  const bugsY = y + height - bugsHeight;

  // Adjust width and spacing
  const adjustedWidth = showRatio ? 10 : 20;
  const adjustedXTasks = x + width / 2 - 10;
  const adjustedXBugs = showRatio ? x + width / 2 : adjustedXTasks;

  // Calculate corner radius
  const cornerRadius = Math.min(6, height / 2);

  // Path for tasks bar
  const tasksPath = `
    M${adjustedXTasks},${tasksY + cornerRadius} 
    a${cornerRadius},${cornerRadius} 0 0 1 ${cornerRadius},-${cornerRadius} 
    h${adjustedWidth - 2 * cornerRadius} 
    a${cornerRadius},${cornerRadius} 0 0 1 ${cornerRadius},${cornerRadius} 
    v${tasksHeight - cornerRadius}
    h-${adjustedWidth} 
    z
  `;

  // Path for bugs bar
  const adjustedBugsCornerRadius = Math.min(cornerRadius, bugsHeight / 2);
  const bugsPath = `
    M${adjustedXBugs},${bugsY + adjustedBugsCornerRadius} 
    a${adjustedBugsCornerRadius},${adjustedBugsCornerRadius} 0 0 1 ${adjustedBugsCornerRadius},-${adjustedBugsCornerRadius} 
    h${adjustedWidth - 2 * adjustedBugsCornerRadius} 
    a${adjustedBugsCornerRadius},${adjustedBugsCornerRadius} 0 0 1 ${adjustedBugsCornerRadius},${adjustedBugsCornerRadius} 
    v${bugsHeight - adjustedBugsCornerRadius}
    h-${adjustedWidth} 
    z
  `;

  // Calculate the highest point (smallest y-coordinate between tasks and bugs)
  const highestPointY = Math.min(
    tasksY, // top of tasks bar
    bugsY // top of bugs bar
  );

  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [tooltipContent, setTooltipContent] = useState<JSX.Element | null>(
    null
  );

  const handleMouseMove = useCallback((event: React.MouseEvent<SVGElement>) => {
    setTooltipPosition({
      top: event.clientY + 10, // Adjust Y position
      left: event.clientX + 10, // Adjust X position
    });
  }, []);

  const handleMouseEnter = (event, value, label) => {
    const tooltip = (
      <div className="shadow-xl rounded-lg bg-white p-2 flex items-center whitespace-nowrap">
        <span
          style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: label === "Tasks" ? tasksBarColor : bugsBarColor,
            borderRadius: "50%",
            marginRight: "5px",
          }}
        />
        <div>
          {chartType === 4 || chartType === 10 ? (
            <div className="out-300-14 text-black">
              Time on {label}:{" "}
              <span className="out-500-14 text-black ml-1">
                {formatTime(value || 0)}
              </span>
            </div>
          ) : (
            <div className="out-300-14 text-black">
              No. of {label}:{" "}
              <span className="out-500-14 text-black ml-1">{value}</span>
            </div>
          )}
        </div>
      </div>
    );
    setTooltipContent(tooltip); // Set the tooltip content
    // showTooltipFromEvent(tooltip, event);
  };

  const handleMouseLeave = () => {
    hideTooltip();
    setTooltipContent(null); // Hide the tooltip
  };

  // Set the font size for the text inside the bar
  const fontSize = 10;

  const hasEnoughHeightForText = (value: number, type: "tasks" | "bugs") => {
    const roundedValue = Math.round(value);

    // Calculate the number of digits after rounding
    const numberOfDigits = Math.max(1, roundedValue.toString().length);
    const requiredHeight = numberOfDigits * fontSize * 1.1;

    // Select height based on type
    const heightToUse = type === "tasks" ? tasksHeight : bugsHeight;

    return heightToUse >= requiredHeight;
  };

  return (
    <g>
      {/* Case when neither "tasks" nor "bugs" are selected, render both bars */}
      {!selectedKeys.includes("tasks") && !selectedKeys.includes("bugs") && (
        <>
          {/* Tasks bar */}
          {typeof tasks !== "undefined" && tasks > 0 && (
            <>
              <path
                d={tasksPath}
                fill={tasksBarColor}
                onMouseEnter={(e) => handleMouseEnter(e, tasks, "Tasks")}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
              {hasEnoughHeightForText(tasks, "tasks") && ( // Only render text if height is enough for the text
                <text
                  x={adjustedXTasks + adjustedWidth / 2}
                  y={tasksY + tasksHeight / 2 + fontSize / 2 - 1.3} // Center vertically in the tasks bar
                  fill="black"
                  fontSize={fontSize.toString()}
                  textAnchor="middle"
                  transform={`rotate(-90, ${
                    adjustedXTasks + adjustedWidth / 2
                  }, ${tasksY + tasksHeight / 2})`}
                  className="cursor-default"
                  onMouseEnter={(e) => handleMouseEnter(e, tasks, "Tasks")}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                >
                  {Math.round(tasks)}
                </text>
              )}
            </>
          )}

          {/* Bugs bar */}
          {typeof bugs !== "undefined" && bugs > 0 && (
            <>
              <path
                d={bugsPath}
                fill={bugsBarColor}
                onMouseEnter={(e) => handleMouseEnter(e, bugs, "Bugs")}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
              {hasEnoughHeightForText(bugs, "bugs") && ( // Only render text if bugs bar height is enough for the text
                <text
                  x={adjustedXBugs + adjustedWidth / 2}
                  y={bugsY + bugsHeight / 2 + fontSize / 2 - 1.3} // Center vertically in the bugs bar
                  fill="white"
                  fontSize={fontSize.toString()}
                  textAnchor="middle"
                  transform={`rotate(-90, ${
                    adjustedXBugs + adjustedWidth / 2
                  }, ${bugsY + bugsHeight / 2})`}
                  className="cursor-default"
                  onMouseEnter={(e) => handleMouseEnter(e, bugs, "Bugs")}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                >
                  {Math.round(bugs)}
                </text>
              )}
            </>
          )}
        </>
      )}

      {/* Tasks bar when selected */}
      {selectedKeys.includes("tasks") &&
        typeof tasks !== "undefined" &&
        tasks > 0 && (
          <>
            <path
              d={tasksPath}
              fill={tasksBarColor}
              onMouseEnter={(e) => handleMouseEnter(e, tasks, "Tasks")}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
            {hasEnoughHeightForText(tasks, "tasks") && (
              <text
                x={adjustedXTasks + adjustedWidth / 2}
                y={tasksY + tasksHeight / 2 + fontSize / 2 - 1.3} // Center vertically in the tasks bar
                fill="black"
                fontSize={fontSize.toString()}
                textAnchor="middle"
                transform={`rotate(-90, ${
                  adjustedXTasks + adjustedWidth / 2
                }, ${tasksY + tasksHeight / 2})`}
                className="cursor-default"
                onMouseEnter={(e) => handleMouseEnter(e, tasks, "Tasks")}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              >
                {Math.round(tasks)}
              </text>
            )}
          </>
        )}

      {/* Bugs bar when selected */}
      {selectedKeys.includes("bugs") &&
        typeof bugs !== "undefined" &&
        bugs > 0 && (
          <>
            <path
              d={bugsPath}
              fill={bugsBarColor}
              onMouseEnter={(e) => handleMouseEnter(e, bugs, "Bugs")}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
            {hasEnoughHeightForText(bugs, "bugs") && (
              <text
                x={adjustedXBugs + adjustedWidth / 2}
                y={bugsY + bugsHeight / 2 + fontSize / 2 - 1.3} // Center vertically in the bugs bar
                fill="white"
                fontSize={fontSize.toString()}
                textAnchor="middle"
                transform={`rotate(-90, ${adjustedXBugs + adjustedWidth / 2}, ${
                  bugsY + bugsHeight / 2
                })`}
                className="cursor-default"
                onMouseEnter={(e) => handleMouseEnter(e, bugs, "Bugs")}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              >
                {Math.round(bugs)}
              </text>
            )}
          </>
        )}

      {/* Ratio display (if both tasks and bugs are selected) */}
      {showRatio &&
        ratio !== undefined &&
        +ratio !== Infinity &&
        ratio !== null &&
        !isNaN(+ratio) && (
          <text
            x={(adjustedXTasks + adjustedXBugs + adjustedWidth) / 2 + 15}
            y={highestPointY - 5.5}
            fontWeight={300}
            fill="#292927"
            fontSize="12"
            textAnchor="middle"
            transform={`rotate(-90, ${
              (adjustedXTasks + adjustedXBugs + adjustedWidth) / 2
            }, ${highestPointY - 10})`}
          >
            {ratio}
          </text>
        )}

      {tooltipContent &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "absolute",
              top: tooltipPosition.top,
              left: tooltipPosition.left,
            }}
          >
            {tooltipContent}
          </div>,
          document.body // Render outside the chart
        )}
    </g>
  );
};

const CustomBarChart: React.FC<{
  data: BarDatum[];
  selectedKeys: string[];
  weeks: number;
  indexByX: string;
  indexByY: string;
  legendTextX: string;
  chartType: number;
  showValues: boolean;
}> = ({
  data,
  selectedKeys,
  weeks,
  indexByX,
  indexByY,
  legendTextX,
  chartType,
  showValues,
}) => {
  const tickRotationBottom = data.length > 4 ? -45 : 0;
  const lastObject = data?.[data.length - 1];
  const max = Math.max(lastObject?.max, 20); // Use max from the last object or fallback to provided maxValue
  const maxValue = max
    ? roundToNextMaxValue(max, calculateNiceTickInterval(max))
    : 20;
  const tickValues = generateDynamicTickValues(maxValue);
  console.log(maxValue, "max", max);

  // Filter out the last object from the data array
  const filteredData = data?.slice(0, -1);
  const isOverflow = filteredData?.length > 45;

  const formatTickValue = (value: number) => {
    if (value >= 10000) {
      return `${(value / 1000).toFixed(0)}K`; // Format in K for thousands
    }
    return value.toString(); // Otherwise, return as is
  };

  return (
    <div className="relative bg-white flex">
      {/* Left Axis Chart */}
      <div
        className="min-w-[50px] max-w-[50px]"
        style={{
          height: showValues ? "400px" : "452.2px",
        }}
      >
        <ResponsiveBar
          data={filteredData}
          keys={["height"]}
          indexBy={indexByX}
          margin={{ top: 10, right: 0, bottom: 80, left: 20 }}
          padding={0.3}
          layout="vertical"
          colors={{ scheme: "nivo" }}
          axisLeft={{
            tickSize: 0,
            tickValues: tickValues,
            tickPadding: -25,
            tickRotation: 0,
            legend: indexByY,
            legendOffset: -8,
            legendPosition: "middle",
            format: formatTickValue,
          }}
          axisBottom={null}
          enableGridX={false}
          enableGridY={true}
          minValue={0}
          maxValue={maxValue}
          animate={true}
          layers={["axes"]}
          theme={{
            axis: {
              legend: {
                text: {
                  fontFamily: "Outfit",
                  fontSize: 12,
                  fontWeight: 300,
                  textTransform: "capitalize",
                  fill: "#667085",
                },
              },
            },
            text: {
              fontFamily: "Outfit",
              fontSize: 10,
              fontWeight: 500,
              textTransform: "capitalize",
              fill: "#667085",
            },
          }}
        />
      </div>

      {/* Main Chart */}
      <div className={`${isOverflow ? "overflow-x-auto" : "w-full"}`}>
        <div
          className={`${showValues ? "h-[400px]" : "h-[452.2px]"} ${
            isOverflow ? "w-[2300px]" : "w-full"
          }`}
        >
          <ResponsiveBar
            data={filteredData}
            keys={["height"]}
            indexBy={indexByX}
            margin={{ top: 10, right: 0, bottom: 80, left: 5 }}
            padding={0.3}
            layout="vertical"
            barComponent={(props) => (
              <CustomBar
                {...props}
                selectedKeys={selectedKeys}
                chartType={chartType}
              />
            )}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: tickRotationBottom,
              renderTick: (tick) => {
                const label = tick.value || "";
                const isTruncated = label?.length > 14 && data?.length > 4;
                const displayText = isTruncated
                  ? label.substring(0, 11) + "..."
                  : label;

                return (
                  <g transform={`translate(${tick.x},${tick.y + 10})`}>
                    <text
                      textAnchor={tickRotationBottom === 0 ? "middle" : "end"} // Adjust alignment based on rotation
                      fontSize={12}
                      fontWeight={300}
                      fontFamily="Outfit"
                      fill="#667085"
                      transform={`rotate(${tickRotationBottom}, 0, 0)`} // Apply the rotation
                      dy={tickRotationBottom === 0 ? "0.35em" : "0.35em"}
                      style={{ textTransform: "capitalize", cursor: "default" }}
                    >
                      {/* Tooltip on hover for full label */}
                      <title>
                        {label
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </title>
                      {displayText}
                    </text>
                  </g>
                );
              },
              // legend: legendTextX,
              // legendPosition: "middle",
              // legendOffset: 73,
            }}
            axisLeft={null}
            // axisLeft={{
            //   tickSize: 0,
            //   tickPadding: 5,
            //   tickRotation: 0,
            //   legend: indexByY,
            //   legendPosition: "middle",
            //   legendOffset: -40,
            //   tickValues: tickValues,
            // }}
            layers={[
              "grid",
              "axes",
              // "markers",
              "bars",
              "legends",
              "annotations",
              CustomGridLine(0, "#969696", ""),
            ]}
            minValue={0}
            maxValue={maxValue}
            enableGridX={false}
            enableGridY={true}
            gridYValues={tickValues}
            animate={true}
            theme={{
              axis: {
                legend: {
                  text: {
                    fontFamily: "Outfit",
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "capitalize",
                    fill: "#667085",
                  },
                },
              },
              text: {
                fontFamily: "Outfit",
                fontSize: 10,
                fontWeight: 500,
                textTransform: "capitalize",
                fill: "#667085",
              },
              grid: {
                line: {
                  stroke: "#ccc",
                  strokeWidth: 1,
                  strokeDasharray: "4 4",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CustomGroupedBarChart = ({
  data,
  keys,
  indexByX = "project",
  indexByY = "Numbers",
  heading = "Team time on projects",
  tooltip = "Test",
  showTotalValues = false,
  Total1Text = "Total 1",
  Total2Text = "Total 2",
  weeks = 1,
  heightKey = 21,
  legendTextX = indexByX,
  chartType = 3,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(keys);
  const [isSettingPopoverOpen, setIsSettingPopoverOpen] = useState(false);
  const [isDotsPopoverOpen, setIsDotsPopoverOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { selectedCharts } = useAppSelector((state) => state.dashboard);

  const colorMapping = useMemo(() => {
    const colorScale =
      indexByY === "Numbers" ? ["#D9D6FE", "#7A5AF8"] : ["#D1E9FF", "#2E90FA"];

    return keys.reduce((acc, key, i) => {
      acc[key] = colorScale[i % colorScale.length];
      return acc;
    }, {} as Record<string, string>);
  }, [keys, indexByY]);

  const handleKeyChange = (key) => {
    if (selectedKeys.includes(key)) {
      setSelectedKeys(
        selectedKeys.filter((selectedKey) => selectedKey !== key)
      );
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }
  };

  // Assume total1Key and total2Key are the first two keys from the 'keys' array
  const total1Key = keys[0];
  const total2Key = keys[1];

  // Update the logic to handle the case where neither key is selected
  const filteredData = data.map((item) => {
    const filteredItem = {};
    filteredItem[indexByX] = item[indexByX];

    // If neither key is selected, include both total1Key and total2Key
    if (
      !selectedKeys.includes(total1Key) &&
      !selectedKeys.includes(total2Key)
    ) {
      filteredItem[total1Key] = item[total1Key];
      filteredItem[total2Key] = item[total2Key];
    } else {
      // If some keys are selected, filter based on selected keys
      selectedKeys.forEach((key) => {
        filteredItem[key] = item[key];
      });
    }
    return filteredItem;
  });

  // Calculate total1
  const total1 = filteredData.reduce((sum, item) => {
    // Include total1 if either it's selected or no keys are selected
    if (!selectedKeys.length || selectedKeys.includes(total1Key)) {
      return sum + Number(item[total1Key] || 0);
    }
    return sum;
  }, 0);

  // Calculate total2
  const total2 = filteredData.reduce((sum, item) => {
    // Include total2 if either it's selected or no keys are selected
    if (!selectedKeys.length || selectedKeys.includes(total2Key)) {
      return sum + Number(item[total2Key] || 0);
    }
    return sum;
  }, 0);

  const ratio = total1 && total2 ? (total1 / total2).toFixed(2) : null;

  const popoverContent = (
    <div className="border border-gray-200 rounded-lg p-[1px]">
      <div className="flex justify-center py-1 px-2 border-b border-gray-200">
        Display Setting
      </div>
      <div className="flex flex-col">
        {keys?.map((key, index) => (
          <div
            key={key}
            className={`py-1 px-2 ${
              selectedKeys.includes(key) ? "bg-blue-100" : "hover:bg-gray-100"
            } ${index === keys.length - 1 ? "rounded-b-[7px]" : ""}`}
          >
            <Checkbox
              checked={selectedKeys.includes(key)}
              onChange={() => handleKeyChange(key)}
            >
              {key}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );

  const popoverContentThreeDots = (
    <div
      className="p-2 flex gap-2 items-center hover:bg-gray-200 cursor-pointer rounded"
      onClick={() => {
        const chartKey = `chart${chartType}`;

        dispatch(
          updateDashboard({
            key: "selectedCharts",
            value: {
              ...selectedCharts,
              [chartKey]: false, // Dynamically updating the correct chart
            },
          })
        );
      }}
    >
      <div>
        <DeleteScheduleIcon className="text-error-600" />
      </div>
      <div className="text-error-600">Delete Chart</div>
    </div>
  );

  const FormattedTime: React.FC<{ time: number }> = ({ time }) => {
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);

    return (
      <div>
        {hours > 0 && (
          <>
            {hours}
            <span className="out-500-14 text-gray-500 mx-1">h</span>
          </>
        )}
        {minutes > 0 && (
          <>
            {minutes}
            <span className="out-500-14 text-gray-500 ml-1">m</span>
          </>
        )}
        {hours === 0 && minutes === 0 && (
          <>
            {0}
            <span className="out-500-14 text-gray-500 ml-1">h</span>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-xl border border-grey-200">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <div className="out-500-16 text-black">{heading}</div>
          <Tooltip
            title={<div className="">{tooltip}</div>}
            placement={"right"}
          >
            <div>
              <ReportsInfoIcon className=" text-gray-500" />
            </div>
          </Tooltip>
        </div>

        <div className="flex">
          {/* Tooltip and Popover for Settings */}
          <Tooltip title="Display Settings" placement="topRight">
            <Popover
              content={popoverContent}
              trigger="click"
              placement="bottomRight"
              className={`p-[5px] rounded ${
                isSettingPopoverOpen ? "bg-gray-200" : "hover:bg-gray-200"
              }`}
              arrow={false}
              onOpenChange={(visible) => setIsSettingPopoverOpen(visible)}
            >
              <SettingIcon className="text-black cursor-pointer" />
            </Popover>
          </Tooltip>

          {/* Tooltip and Popover for Dots */}
          <Tooltip title="More actions" placement="topRight">
            <Popover
              content={popoverContentThreeDots}
              trigger="click"
              placement="bottomRight"
              className={`p-[5px] rounded ${
                isDotsPopoverOpen ? "bg-gray-200" : "hover:bg-gray-200"
              }`}
              arrow={false}
              onOpenChange={(visible) => setIsDotsPopoverOpen(visible)}
            >
              <DotsVerticalIcon className="text-gray-500" />
            </Popover>
          </Tooltip>
        </div>
      </div>

      {showTotalValues && (
        <div className="flex items-center gap-4 h-[52.2px]">
          {/* If neither total1Key nor total2Key is selected, show both Total1 and Total2 */}
          {!selectedKeys.includes(total1Key) &&
            !selectedKeys.includes(total2Key) && (
              <>
                <div className="flex flex-col gap-1">
                  <div className="out-500-10 text-gray-400 uppercase mt-[2px]">
                    {Total1Text}
                  </div>
                  <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                    {indexByY === "Hours" ? (
                      <FormattedTime time={total1 || 0} />
                    ) : (
                      <>{total1}</>
                    )}
                  </div>
                </div>
                <Divider
                  type="vertical"
                  style={{ height: "40px", margin: "0" }}
                />
                <div className="flex flex-col gap-1">
                  <div className="out-500-10 text-gray-400 uppercase mt-[2px]">
                    {Total2Text}
                  </div>
                  <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                    {indexByY === "Hours" ? (
                      <FormattedTime time={total2 || 0} />
                    ) : (
                      <>{total2}</>
                    )}
                  </div>
                </div>
              </>
            )}

          {/* Show Total1 only when total1Key is selected */}
          {selectedKeys.includes(total1Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase mt-[2px]">
                {Total1Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                {indexByY === "Hours" ? (
                  <FormattedTime time={total1 || 0} />
                ) : (
                  <>{total1}</>
                )}
              </div>
            </div>
          )}

          {/* Show Divider if both Total1 and Total2 are selected */}
          {selectedKeys.includes(total1Key) &&
            selectedKeys.includes(total2Key) && (
              <Divider
                type="vertical"
                style={{ height: "40px", margin: "0" }}
              />
            )}

          {/* Show Total2 only when total2Key is selected */}
          {selectedKeys.includes(total2Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase mt-[2px]">
                {Total2Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                {indexByY === "Hours" ? (
                  <FormattedTime time={total2 || 0} />
                ) : (
                  <>{total2}</>
                )}
              </div>
            </div>
          )}

          {/* Show Ratio if both Total1 and Total2 are selected, and ratio is available */}
          {(!selectedKeys.length ||
            (selectedKeys.includes(total1Key) &&
              selectedKeys.includes(total2Key))) &&
            ratio && (
              <>
                <Divider
                  type="vertical"
                  style={{ height: "40px", margin: "0" }}
                />
                <div className="flex flex-col gap-1">
                  <div
                    className={`out-500-10 text-gray-400 uppercase ${
                      indexByY !== "Hours" ? "mt-[2px]" : ""
                    }`}
                  >
                    Ratio
                  </div>
                  <div
                    className={`text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]`}
                  >
                    {ratio}
                  </div>
                </div>
              </>
            )}
        </div>
      )}

      <CustomBarChart
        data={data}
        selectedKeys={selectedKeys}
        weeks={weeks}
        indexByX={indexByX}
        indexByY={indexByY}
        legendTextX={legendTextX}
        chartType={chartType}
        showValues={showTotalValues}
      />
      <div className={`relative bg-gray-50 rounded py-1 px-1]`}>
        <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 out-300-12 text-[#667085] capitalize">
          {legendTextX}
          <span className="out-300-12 text-[#667085]">s</span>
        </div>
        <div
          className={`h-[${heightKey}px] items-center justify-center flex gap-4`}
          style={{ height: `${heightKey}px` }}
        >
          {Object.keys(colorMapping)?.map((key) => (
            <div
              key={key}
              onClick={() => {
                if (selectedKeys.includes(key)) {
                  setSelectedKeys(
                    selectedKeys.filter((selectedKey) => selectedKey !== key)
                  );
                } else {
                  setSelectedKeys([...selectedKeys, key]);
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                marginBottom: "2px",
                cursor: "pointer",
                opacity: selectedKeys.includes(key) ? 1 : 0.5,
              }}
            >
              <div
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: colorMapping[key],
                  borderRadius: "50%",
                }}
              />
              <span className="capitalize text-[#667085] font-light">
                {key}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomGroupedBarChart;
