import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useInView } from "react-intersection-observer";
import Header from "../../Core/LayoutV2/Header";
import RequestHeader from "./RequestHeader";
import ChatHeader from "./ChatHeader";
import ConversationInput from "./ConversationInput";
import Message, { Day, TypingIndicator, Unread } from "./Message";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  closeRequest,
  replyToRequest,
  updateConversations,
  updatePrivateConversations,
  updateReaction,
  updateThreadMessage,
  updateUnreadCountForMarkAsUnread,
  updateUnreadCountForProjectThreads,
} from "../../Core/redux/slices/conversations.slice";
import { playNotifySoundDebounced } from "../../Core/utils";
import dayjs from "dayjs";
import {
  getMorePrivateMessages,
  getMoreThreadMessages,
  getPrivateMessages,
  getThreadMessages,
  reactToComment,
} from "../../Core/redux/api/conversationAPI";

import ChatEmptyState from "./ChatEmptyState";
import { chatClient } from "../../Core/utils/axiosClient";
import DragAndDropLayer from "./ConversationInput/DragAndDropLayer";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import { useLocation, useParams } from "react-router-dom";
import QuickChatMessage from "./QuickChatMessage";
import usePermission from "../../Core/hooks/usePermission";
import DeleteMessageModal from "./Modals/DeleteMessageModal";
import InfiniteScroll from "react-infinite-scroller";

const ChatWindow = ({ socket, comments = false, view = "" }) => {
  const {
    currentThreadMessage,
    currentThread,
    directMessageUsers,
    unreadCount: unreadCountFromRedux,
    projectsWithThreads,
    getMessageLoading,
  } = useAppSelector((state) => state.conversations);
  const { tenantDetails } = useAppSelector((state) => state.tenant);

  const { openCommentsModal } = useAppSelector((state) => state.vault);
  const [offset, setOffset] = useState(0);
  const [initialLoad, setInitialLoad] = useState(false);
  const [prevThreadId, setPrevThreadId] = useState(null);
  const [prevChatPosition, setPrevChatPosition] = useState(null);
  const [forbidScrollToBottom, setForbidScrollToBottom] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inView === true) {
      if (initialLoad === true) {
        setOffset(offset + 1);
      } else {
        setInitialLoad(false);
      }
    }
    console.log(inView, "SCROLLED");
  }, [inView]);

  const [deleteMessageModal, setDeleteMessageModal] = useState({
    visible: false,
    id: null,
  });

  const chatWindow = React.useRef(null);
  const chatMessage = React.useRef(null);

  const scrollToChatBottom = () => {
    const chatBoxDiv = chatWindow.current;

    if (chatBoxDiv) {
      chatBoxDiv.scrollTop = chatBoxDiv?.scrollHeight;
    }
  };

  useEffect(() => {
    const offsetNew = chatWindow?.current?.scrollHeight - prevChatPosition;

    if (offsetNew > 0) {
      chatWindow.current.scrollTop = offsetNew;
    }
    setPrevChatPosition(null);
  }, [directMessageUsers]);

  useEffect(() => {
    setInitialLoad(true);
    setPrevThreadId(currentThread.threadId);
    setOffset(0);
    document.getElementById("conversation-chat-input")?.focus();
    if (currentThread.type === "private" && currentThread.userId) {
      dispatch(
        getPrivateMessages({
          userId: user.id,
          reciverId: +currentThread.userId,
          limit: 20,
          offset: 0,
        })
      );
    } else if (currentThread.threadId) {
      dispatch(
        getThreadMessages({
          threadId: currentThread.threadId,
          limit: 20,
          offset: 0,
        })
      );
    }
  }, [currentThread]);

  useEffect(() => {
    if (currentThread.threadId !== prevThreadId) {
      setInitialLoad(false);
      setOffset(0);
    }
  }, [prevThreadId]);

  useEffect(() => {
    if (offset > 0) {
      setForbidScrollToBottom(true);
      if (currentThread.type === "private" && currentThread.userId) {
        dispatch(
          getMorePrivateMessages({
            userId: user.id,
            reciverId: +currentThread.userId,
            limit: 20,
            offset,
          })
        );
      } else if (currentThread.threadId) {
        dispatch(
          getMoreThreadMessages({
            threadId: currentThread.threadId,
            limit: 20,
            offset,
          })
        );
      }
    }

    setPrevChatPosition(chatWindow?.current?.scrollHeight);
  }, [offset]);

  const { user } = useAppSelector((state) => state.userDetails);

  const reactToMessage = (emoji, messageId, isAlreadyReacted) => {
    if (comments) {
      dispatch(
        reactToComment({ emoji, userId: user.id, messageId, comment: true })
      );
      const oldModeMetaComments = openCommentsModal.comments;
      const updateComments = oldModeMetaComments.map((m) => {
        if (m._id === messageId) {
          const reactions = m.reactions || {};
          const userReaction = m.reactions[emoji] ? m.reactions[emoji] : [];
          const updatedReaction = {
            ...reactions,
            [emoji]: [...userReaction, user.id],
          };

          const newMessage = { ...m, reactions: updatedReaction };
          return newMessage;
        }
        return m;
      });
      const newModelMeta = { ...openCommentsModal, comments: updateComments };
      dispatch(
        updateVault({
          key: "openCommentsModal",
          value: newModelMeta,
        })
      );
      return "";
    }

    socket.emit("reaction", {
      emoji,
      userId: user.id,
      messageId,
      isAlreadyReacted,
      threadId:
        currentThread.type === "group"
          ? currentThread.threadId
          : currentThread.userId,
    });

    const removeOrAdd = (userReaction, userId) => {
      if (userReaction.includes(userId)) {
        // console.log(
        //   userReaction.filter((u) => u != userId),
        //   "reactions1"
        // );
        return userReaction.filter((u) => u != userId);
      } else {
        // console.log([...userReaction, userId], "reactions2");
        return [...userReaction, userId];
      }
    };
    const addReaction = (oldMessage) => {
      const message = oldMessage.map((m) => {
        if (m._id === messageId) {
          const reactions = m.reactions || {};

          const userReaction = reactions[emoji] ? reactions[emoji] : [];
          const updatedReaction = {
            ...reactions,
            [emoji]: removeOrAdd(userReaction, user.id),
          };

          const newMessage = { ...m, reactions: updatedReaction };
          return newMessage;
        }
        return m;
      });
      return message;
    };

    if (currentThread.type === "private") {
      const users = directMessageUsers.map((user) => {
        if (+user.userId === +currentThread.userId) {
          const message = addReaction(user.messages);
          return { ...user, messages: [...message] };
        }
        return user;
      });

      dispatch(
        updateConversations({
          key: "directMessageUsers",
          value: [...users],
        })
      );
    } else {
      const message = addReaction(currentThreadMessage);

      dispatch(
        updateConversations({ key: "currentThreadMessage", value: message })
      );
    }
  };

  React.useEffect(() => {
    if (!forbidScrollToBottom) {
      scrollToChatBottom();
    } else {
      setForbidScrollToBottom(false);
    }
  }, [currentThread, currentThreadMessage?.length]);

  const socketOnReaction = ({ messageId, userId, emoji, threadId }) => {
    // console.log("socketOnReaction", { messageId, userId, emoji, threadId });
    // const message = currentThreadMessage.map((m) => {
    //   if (m._id === messageId) {
    //     const reactions = m.reactions || {};
    //     const userReaction = m.reactions?.[emoji] || [];
    //     const updatedReaction = {
    //       ...reactions,
    //       [emoji]: [...userReaction, userId],
    //     };
    //     console.log(updatedReaction);
    //     const newMessage = { ...m, reactions: updatedReaction };
    //     return newMessage;
    //   }
    //   return m;
    // });

    // // merge-conflict: below might be part of some other function
    // // console.log(message);
    // dispatch(
    //   updateConversations({ key: "currentThreadMessage", value: message })
    // );

    dispatch(updateReaction({ messageId, userId, emoji, threadId }));
  };

  const [typingStatusState, setTypingStatusState] = useState({
    isTyping: false,
    userName: "",
    threadId: "",
    userId: "",
    threadType: "",
    message: "",
  });

  const socketOnTyping = ({
    isTyping,
    projectId,
    threadId,
    userName,
    userId,
    threadType,
    tenantId,
    message,
  }) => {
    if (tenantDetails.tenantId === tenantId) {
      if (isTyping) {
        setTypingStatusState({
          isTyping,
          userName,
          threadId,
          userId,
          threadType,
          message,
        });
      } else {
        setTimeout(
          () =>
            setTypingStatusState({
              isTyping,
              userName,
              threadId,
              userId,
              threadType,
              message,
            }),
          3000
        );
      }
      scrollToChatBottom();
    }
  };

  const socketReplyToRequest = ({ parentMessageId, message }) => {
    dispatch(replyToRequest({ parentMessageId, message }));
  };

  const socketCloseMessageRequest = ({ message }) => {
    dispatch(closeRequest({ message }));
  };

  // const readMessage = ()=>{
  //   // this function is used to triigre the readMessage event so that socket can decreases the unread amount by one and remove t

  // }

  const socketOnPrivateMessage = ({
    message,
    senderName,
    senderId,
    reciverId,
    createdAt,
    senderProfilePicture,
    replyMessage,
    _id,
    attachments,
    tenantId,
  }) => {
    if (tenantId !== currentThread.tenantId) {
      console.error("ERROR:[SOCKET] wrong tenant");
      return;
    }
    if (reciverId != user.id) {
      console.error("ERROR:[SOCKET] sending message to wrong user ");
      return;
    }

    dispatch(
      updatePrivateConversations({
        message,
        senderName,
        senderId,
        reciverId,
        createdAt,
        senderProfilePicture,
        replyMessage,
        _id,
        attachments,
        reactions: {},
      })
    );
    // sender id id of user who sent this message and reciver id the id of user who is rciving this message
    // in this case we are reciver so our id should be equal to this id

    if (currentThread.userId !== senderId) {
      playNotifySoundDebounced();
    }
    if (currentThread.userId === senderId) {
      scrollToChatBottom();
      socket.emit("readMessageFromSocket", {
        threadId: currentThread.threadId,
        threadType: currentThread.type,
        userId: user.id,
        reciverId: currentThread.userId,
      });
    }
  };

  const socketOnMessage = (message) => {
    dispatch(updateThreadMessage({ ...message, reactions: {} }));

    if (currentThread.threadId == message.threadId) {
      scrollToChatBottom();
      socket.emit("readMessageFromSocket", {
        threadId: currentThread.threadId,
        threadType: currentThread.type,
        userId: user.id,
        reciverId: currentThread.userId,
      });
    }
  };

  const socketNewThreadMessage = ({ threadId, projectId }) => {
    // console.log("updateUnreadCountForProjectThreads", { threadId, projectId });

    dispatch(updateUnreadCountForProjectThreads({ threadId, projectId }));
  };

  React.useEffect(() => {
    socket.on("reaction", socketOnReaction);
    socket.on("userTyping", socketOnTyping);
    socket.on("privateMessage", socketOnPrivateMessage);
    socket.on("message", socketOnMessage);
    socket.on("newThreadMessage", socketNewThreadMessage);
    socket.on("replyToRequest", socketReplyToRequest);
    socket.on("closeMessageRequest", socketCloseMessageRequest);
    return () => {
      socket.off("reaction", socketOnReaction);
      socket.off("userTyping", socketOnTyping);
      socket.off("privateMessage", socketOnPrivateMessage);
      socket.off("message", socketOnMessage);
      socket.off("newThreadMessage", socketNewThreadMessage);
      socket.off("replyToRequest", socketReplyToRequest);
      socket.off("closeMessageRequest", socketCloseMessageRequest);
    };
  }, [socket, currentThread]);

  const deleteMessage = (id) => {
    const message = {
      messageId: id,
      threadId: currentThread.threadId,
    };
    // console.log(message, "deleted message");

    socket.emit("deleteMessage", message);

    if (currentThread.type === "private") {
      const users = directMessageUsers.map((user) => {
        if (+user.userId === +currentThread.userId) {
          const message = user.messages.filter((m) => {
            return m._id !== id;
          });
          return { ...user, messages: [...message] };
        }
        return user;
      });

      dispatch(
        updateConversations({
          key: "directMessageUsers",
          value: [...users],
        })
      );
    } else {
      const messageListCopy = [...currentThreadMessage];

      const updatedMessageListCopy = messageListCopy.filter((m) => {
        return m._id !== id;
      });

      dispatch(
        updateConversations({
          key: "currentThreadMessage",
          value: [...updatedMessageListCopy],
        })
      );
    }
  };

  const handleMarkAsUnread = async (message) => {
    const allMessages = [...currentThreadMessage];
    const messageIndex = allMessages.findIndex((m) => m._id === message._id);
    const newUnreadCount = allMessages.length - messageIndex;

    // console.log(messageIndex, newUnreadCount, "handleMarkAsUnread");

    dispatch(
      updateUnreadCountForMarkAsUnread({
        threadId: message.threadId,
        userId: currentThread.userId,
        unreadCount: newUnreadCount,
      })
    );

    await chatClient.put("/conversation/unread-count-for-mark-as-unread", {
      threadId: message.threadId,
      senderId: user.id,
      receiverId: currentThread.userId,
      unreadCount: newUnreadCount,
    });
  };

  function groupedDays(messages) {
    return messages?.reduce((acc, el) => {
      const messageDay = dayjs(el.createdAt).format("YYYY-MM-DD");
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
  }

  function generateItems(messages: any[], unreadCount?: number) {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort(
      (x, y) => dayjs(y, "YYYY-MM-DD").unix() - dayjs(x, "YYYY-MM-DD").unix()
    );
    const items = sortedDays.reduce((acc, date) => {
      const sortedMessages = days[date].sort(
        (x, y) =>
          new Date(y.createdAt).valueOf() - new Date(x.createdAt).valueOf()
      );
      return acc.concat([...sortedMessages, { type: "day", date, id: date }]);
    }, []);
    if (unreadCount && unreadCount > 0) {
      items.splice(unreadCount, 0, {
        type: "unread",
        count: unreadCount,
      });
    }
    return items.reverse();
  }

  function renderItem(message: any, prevMessage?: any, firstMessage?: any) {
    if (message.type && message.type === "day") {
      return <Day {...message} />;
    }
    if (message.type && message.type === "unread") {
      return <Unread {...message} />;
    }

    let showSender = true;
    // let showReciver = true;

    if (prevMessage && !prevMessage.type && !message.type) {
      // const timeDiff = dayjs(message.createdAt).diff(
      //   prevMessage.createdAt,
      //   "second"
      // );
      if (prevMessage.senderId === message.senderId) {
        showSender = false;
      }

      if (message.request && message.request?.type) {
        showSender = true;
      }
    }

    if (view === "quickChat") {
      return (
        <QuickChatMessage
          key={message._id}
          message={message}
          // reaction={true}
          //  image={false}
          reactToMessage={reactToMessage}
          deleteMessage={(id) => setDeleteMessageModal({ visible: true, id })}
          comments={comments}
          showSender={showSender}
          handleMarkAsUnread={handleMarkAsUnread}
        />
      );
    }
    if (message._id === firstMessage) {
      return (
        <Message
          key={message._id}
          message={message}
          // reaction={true}
          //  image={false}
          reactToMessage={reactToMessage}
          deleteMessage={(id) => setDeleteMessageModal({ visible: true, id })}
          comments={comments}
          showSender={showSender}
          handleMarkAsUnread={handleMarkAsUnread}
        />
      );
    } else
      return (
        <Message
          key={message._id}
          message={message}
          // reaction={true}
          //  image={false}
          reactToMessage={reactToMessage}
          deleteMessage={(id) => setDeleteMessageModal({ visible: true, id })}
          comments={comments}
          showSender={showSender}
          handleMarkAsUnread={handleMarkAsUnread}
        />
      );
  }

  const getMessage = () => {
    if (comments) {
      if (openCommentsModal.comments.length === 0) return <></>;
      const newMessages = generateItems(openCommentsModal.comments);

      return newMessages?.map((message) => renderItem(message));
    } else {
      if (currentThread.type === "group") {
        if (currentThreadMessage?.length === 0) return <></>;
        const newMessages = generateItems(
          currentThreadMessage,
          unreadCountFromRedux
        );
        return newMessages?.map((message, i) =>
          renderItem(message, newMessages[i - 1], newMessages[1]._id)
        );
      } else {
        if (directMessageUsers) {
          const user = directMessageUsers?.find(
            (user) => +user.userId === +currentThread.userId
          );
          const oldmessage = user?.messages;
          // const unreadCount = user?.unreadCount;

          if (!oldmessage || oldmessage?.length === 0) return <></>;

          const newMessages = generateItems(oldmessage, unreadCountFromRedux);

          return newMessages.map((message, i) =>
            renderItem(message, newMessages[i - 1], newMessages?.[1]?._id)
          );
        }
      }
    }
  };

  const location = useLocation();
  const { taskId } = useParams();

  const screen =
    location.pathname.includes("schedule") && location.pathname.includes("task")
      ? "schedule"
      : location.pathname.includes("library")
      ? "library"
      : "messages";

  const { hasPermission: COMMENT_ADD } = usePermission("14");
  const { hasPermission: LIB_COMMENT_ADD } = usePermission("26");

  const commentsAddPermissions =
    screen === "schedule"
      ? COMMENT_ADD
      : screen === "library"
      ? LIB_COMMENT_ADD
      : true;

  if (view === "quickChat") {
    return (
      <div className={`flex h-full flex-col justify-between overflow-hidden`}>
        <div className="relative grid flex-1 h-full max-h-full overflow-x-hidden overflow-y-scroll">
          <div className={`flex  flex-col last:mb-3  justify-end `}>
            {getMessage()}
          </div>
        </div>

        <div className={`mx-5 mb-5`}>
          <ConversationInput
            socket={socket}
            comments={comments}
            allowRequest={false}
          />
        </div>
      </div>
    );
  }

  if (
    currentThread.threadId === "" &&
    currentThread.userId === "" &&
    !comments
  ) {
    return <ChatEmptyState />;
  }

  return (
    <div
      className={`flex  ${
        taskId ? "flex-col-reverse" : "flex-col"
      } justify-between h-full overflow-y-hidden `}
      id="conversations-tour-chat-window"
    >
      {!comments ? (
        <>
          {" "}
          <ChatHeader
            isGroup={true}
            memberList={[
              { title: "Ganesh Mohanty" },
              { title: "Anushka Gaonkar" },
              { title: "Preetinder Kalsi" },
              { title: "Kevin Shah" },
              { title: "Prachiti Mayekar" },
              { title: "Yocheved Leo" },
            ]}
          />
          <RequestHeader socket={socket} />{" "}
        </>
      ) : (
        <></>
      )}
      <div className={`flex  ${taskId ? "" : "flex-col"} justify-end h-full `}>
        <DragAndDropLayer comments={comments} containerRef={chatWindow}>
          <div className="relative grid h-full overflow-x-hidden overflow-y-scroll ">
            <div className="invisible" ref={ref} />
            {getMessageLoading === true ? (
              <div className="flex items-center justify-center w-full my-5">
                <ReactLoading
                  type="spin"
                  color="#165ABF"
                  height={20}
                  width={20}
                />
              </div>
            ) : null}

            <div
              ref={chatMessage}
              className={`flex   flex-col last:mb-3  justify-end`}
            >
              {getMessage()}
            </div>
          </div>
        </DragAndDropLayer>
        <TypingIndicator
          typingStatus={typingStatusState}
          currentThread={currentThread}
        />

        {commentsAddPermissions && (
          <div
            className={`${taskId ? "" : "mx-5"} ${
              chatWindow?.current?.scrollHeight > 700 ? "mb-[70px]" : "mb-5"
            } `}
          >
            <ConversationInput socket={socket} comments={comments} />
          </div>
        )}
      </div>

      <DeleteMessageModal
        visible={deleteMessageModal.visible}
        onClose={() => setDeleteMessageModal({ visible: false, id: null })}
        onDelete={() => {
          deleteMessage(deleteMessageModal.id);
          setDeleteMessageModal({ visible: false, id: null });
        }}
      />
    </div>
  );
};

export default ChatWindow;
