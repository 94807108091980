import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

const CalendarSyncedModal = ({ visible, onClose }) => {
  return (
    <CustomModal
      visible={visible}
      width="524px"
      onCancel={onClose}
      body={
        <div className="h-[358px]  flex flex-col pt-[51px] pr-[31px] pb-[32px] pl-[24px] gap-6">
          <div className="flex justify-center items-center">
            <div className="relative">
              <img
                src="/images/v2/dashboard/FrostyJumping.svg"
                className="h-[108px]"
              />
              <span className="absolute -top-3.5 -right-6 p-2 text-slate-700 out-500-16">
                Wohoo!
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-slate-700 out-500-14">
              Your calendars are synced up now.
            </p>
            <p className="w-[469px] text-grey-500 out-300-14">
              Create events in Waddle, check them out on Google Calendar, and
              vice versa. Respond to events from either side, and we&apos;ll
              keep everything in sync.
              <br />
              Easy peasy!
            </p>
          </div>
          <div className="h-[30px]  flex items-center justify-center gap-x-2.5 cursor-pointer">
            <button
              className="h-[30px] flex items-center justify-center out-500-14 text-blue-800 py-[5px] px-[10px] rounded-[4px] "
              onClick={onClose}
            >
              Got it!
            </button>
          </div>
        </div>
      }
    />
  );
};

export default CalendarSyncedModal;
