import React from "react";

type CustomFrostyErrorMessageProps = {
  text?: React.ReactNode | string | any;
  shadow?: boolean;
};

const CustomFrostyErrorMessage = ({ text }: CustomFrostyErrorMessageProps) => {
  return (
    text && (
      <div className="mb-4 max-w-max">
        <div className={`flex h-11 bg-white rounded-xl `}>
          <div className="flex items-center pl-1 pt-1 pb-1">
            <img
              src="/images/icons/frosty-icon.svg"
              className="min-h-[24px] min-w-[24px]"
              alt="frosty"
            />
          </div>
          <div className="flex items-center pl-2 mon-500-16 text-error-600 ">
            {text}
          </div>
        </div>
      </div>
    )
  );
};

export default CustomFrostyErrorMessage;
