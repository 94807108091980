import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Checkbox, Avatar, Tooltip } from "antd";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import CustomBadge from "../../Core/CommonV2/CustomBadge";
import ProjectsReportsModal from "../../ModulesV2/Schedule/Modals/ProjectsReportsModal";
import TeamsReportsModal from "../../ModulesV2/Schedule/Modals/TeamsReportsModal";
import AssigneesReportsModal from "../../ModulesV2/Schedule/Modals/ReportsModal";
import { RefreshIcon } from "../../Core/svgV3/RefreshIcon";
import ExportIcon from "../../Core/svgV3/ExportIcon";
import { ExportBoxIcon } from "../../Core/svgV3/ExportBoxIcon";
import { useQueryClient } from "react-query";
import { categoriesData, reportColorsScheme } from "../../Core/constants";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Papa from "papaparse";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import ReportsModal from "../../ModulesV2/Schedule/Modals/ReportsModal";
import {
  resetReports,
  resetTrends,
  unSelectWeeks,
  updateDashboard,
} from "../../Core/redux/slices/dashboard.slice";
import { CrossIconSecondary } from "../../Core/svgV2/CrossIcon";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import { isEqual } from "lodash";
import dayjs from "dayjs";
import { TimeEstimateIcon } from "../../Core/LayoutV2/LayoutIcons";
import { useNavigate } from "react-router-dom";

const CustomRouteButton = ({ title, isSelected, onSelect }) => (
  <button
    onClick={() => onSelect()}
    className={`flex items-center rounded px-4 py-[5px] justify-center ${
      isSelected ? "bg-white shadow" : "bg-gray-50"
    }`}
  >
    <div
      className={`text-center out-500-14 whitespace-nowrap leading-[20px] ${
        isSelected ? "text-gray-700" : "text-[#667085]"
      }`}
    >
      {title}
    </div>
  </button>
);

const ReportsNavBar = ({ screen, setScreen, data, chartRef, ref1, ref2 }) => {
  // Use only Redux state instead of local useState hooks
  const {
    selectedCharts,
    weeks,
    selectProjects,
    selectAssignees,
    selectTeams,
    trendsCategory,
    selectAssigneesTrends,
    selectProjectsTrends,
  } = useAppSelector((state) => state.dashboard);
  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const [search, setSearch] = useState("");
  const [dropdownOpenWeek, setDropdownOpenWeek] = useState(false);
  const [dropdownOpenProject, setDropdownOpenProject] = useState(false);
  const [dropdownOpenTeams, setDropdownOpenTeams] = useState(false);
  const [dropdownOpenAssignee, setDropdownOpenAssignee] = useState(false);
  const [dropdownOpenAddChart, setDropdownOpenAddChart] = useState(false);
  const [dropdownOpenExport, setDropdownOpenExport] = useState(false);
  const [reportType, setReportType] = useState("");
  const [reportsModal, setReportsModal] = useState(false);
  const [dropdownOpenCategory, setDropdownOpenCategory] = useState(false);

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const hasValidWeeks =
    weeks.length > 0 &&
    !(
      weeks.length === 1 &&
      weeks[0]?.startDate === "" &&
      weeks[0]?.endDate === ""
    );
  console.log(hasValidWeeks, "hasValidWeeks");

  // Utility function to map selected weeks for API
  const mapWeeksForApi = (selectedWeeks, filteredWeeks) => {
    // Create a mapping from weekName to { startDate, endDate }
    const weekMap =
      filteredWeeks?.reduce((acc, week) => {
        acc[week.week] = {
          startDate: week.startDate,
          endDate: week.endDate,
        };
        return acc;
      }, {}) || {};
    console.log(
      selectedWeeks,
      "inside weeks for api",
      filteredWeeks,
      "weekMap => ",
      weekMap
    );

    // Map selectedWeeks to their respective startDate and endDate
    return selectedWeeks.map(
      (weekName) => weekMap[weekName] || { startDate: null, endDate: null }
    );
  };

  // Use data from API for filtered weeks
  const filteredWeeks =
    data?.filteredWeeks?.map((weekData) => {
      const { startDate, endDate, weekName } = weekData;

      const start = new Date(startDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
      });
      const end = new Date(endDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      return {
        week: weekName,
        dates: `${start} - ${end}`,
        startDate,
        endDate,
      };
    }) || [];

  // Refactor the useEffect to rely on Redux state for condition checks
  useEffect(() => {
    // if (!isEqual(weeksForApi, weeks)) {
    //   dispatch(updateDashboard({ key: "weeks", value: weeksForApi }));
    // }

    // if (!isEqual(selectProjects, selectProjects)) {
    //   dispatch(
    //     updateDashboard({ key: "selectProjects", value: selectProjects })
    //   );
    // }

    // if (!isEqual(selectTeams, selectTeams)) {
    //   dispatch(updateDashboard({ key: "selectTeams", value: selectTeams }));
    // }

    // if (!isEqual(selectAssignees, selectAssignees)) {
    //   dispatch(
    //     updateDashboard({ key: "selectAssignees", value: selectAssignees })
    //   );
    // }

    // if (!isEqual(selectedCategories, trendsCategory)) {
    //   dispatch(
    //     updateDashboard({ key: "trendsCategory", value: selectedCategories })
    //   );
    // }

    const hasSelectedCharts = Object.values(selectedCharts).some(Boolean);
    console.log(selectedCharts, "selected charts here");

    if (
      (weeks?.length > 0 ||
        selectProjects?.length > 0 ||
        selectTeams?.length > 0 ||
        selectAssignees?.length > 0) &&
      hasSelectedCharts
    ) {
      console.log(
        "Triggering Query for",
        selectProjects,
        selectedCharts,
        selectTeams,
        selectAssignees
      );

      Object.keys(selectedCharts).forEach((key) => {
        if (selectedCharts[key]) {
          // Check if weeks contain valid dates
          switch (key) {
            case "chart1":
              if (hasValidWeeks) {
                queryClient.invalidateQueries([
                  "totalTimeVsEstimationPerProject",
                ]);
              }
              break;
            case "chart2":
              if (hasValidWeeks) {
                queryClient.invalidateQueries(["teamTimeOnProjects"]);
              }
              break;
            case "chart3":
              if (hasValidWeeks) {
                queryClient.invalidateQueries(["taskVsBugCountPerProject"]);
              }
              break;
            case "chart4":
              if (hasValidWeeks) {
                queryClient.invalidateQueries(["taskVsBugTimePerProject"]);
              }
              break;
            case "chart5":
              if (hasValidWeeks) {
                queryClient.invalidateQueries(["teamAllocationPerProject"]);
              }
              break;
            case "chart6":
              if (hasValidWeeks) {
                queryClient.invalidateQueries(["totalTimeSpentByTeams"]);
              }
              break;
            case "chart7":
              if (hasValidWeeks) {
                queryClient.invalidateQueries(["totalTimeVsEstimationPerUser"]);
              }
              break;
            case "chart8":
              if (hasValidWeeks) {
                queryClient.invalidateQueries(["assigneeTimeOnProjects"]);
              }
              break;
            case "chart9":
              if (hasValidWeeks) {
                queryClient.invalidateQueries(["taskVsBugCountPerUser"]);
              }
              break;
            case "chart10":
              if (hasValidWeeks) {
                queryClient.invalidateQueries(["taskVsBugTimePerUser"]);
              }
              break;
            default:
              break;
          }
        }
      });
    }
  }, [weeks, selectProjects, selectTeams, selectAssignees, selectedCharts]);

  // Apply filtering based on search input
  const searchFilteredWeeks = filteredWeeks?.filter(
    (weekData) =>
      weekData?.week?.toLowerCase()?.includes(search?.toLowerCase()) ||
      weekData?.dates?.toLowerCase()?.includes(search?.toLowerCase())
  );

  // Filtered projects data
  const projectsData = data?.overviewProjectsData
    ?.filter((project) => !project?.isDiscarded)
    .map((project) => {
      const { id, title, startDate, endDate, isEnded, projectImage } = project;

      const start = new Date(startDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
      });
      const end = new Date(endDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      // Determine project status
      let status;
      if (new Date(startDate) > new Date()) {
        status = "Upcoming";
      } else if (isEnded) {
        status = "Completed";
      } else {
        status = "Ongoing";
      }

      return {
        id,
        name: title,
        dates: `${start} - ${end}`,
        status,
        image: projectImage,
      };
    });

  const filteredProjects = projectsData?.filter(
    (project) =>
      project?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      project?.dates?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const teamkeys = data?.teams?.map((team) => [team?.name]);

  const filteredTeams = data?.teams?.filter((team) =>
    team?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const filteredCategories = categoriesData?.filter((category) =>
    category?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const assigneesData = data?.membersPerTenant?.map((member) => ({
    id: member.id,
    name: `${member.firstname} ${member.lastname}`,
    image: member.profilePicture,
  }));
  const filteredAssignees = assigneesData?.filter((assignee) =>
    assignee?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const hideTeamsDropdown = tenantMembers.some((member) => !member.teamId);

  const handleSelectChange = (list, item, key, convertToInt = false) => {
    const itemValue = convertToInt ? parseInt(item, 10) : item;

    let updatedList;
    if (list?.includes(itemValue)) {
      updatedList = list?.filter((selectedItem) => selectedItem !== itemValue);
    } else {
      updatedList = [...list, itemValue];
    }

    // Dispatch the updated list to Redux
    dispatch(updateDashboard({ key, value: updatedList }));
  };

  const handleSelectAll =
    (list, data, field, key, convertToInt = false) =>
    (e) => {
      const allItems = data.map((item) =>
        convertToInt ? parseInt(item[field], 10) : item[field]
      );
      // console.log(
      //   "list = ",
      //   list,
      //   "data = ",
      //   data,
      //   " field = ",
      //   field,
      //   "key = ",
      //   key,
      //   convertToInt
      // );
      const updatedList =
        e.target.checked || key === "weeks" // Check if the all items should be selected
          ? allItems
          : key === "weeks"
          ? [{ startDate: "", endDate: "" }] // Set to the empty initial state for weeks
          : []; // Set to an empty array for other lists

      // Dispatch the updated list to Redux
      dispatch(updateDashboard({ key, value: updatedList }));
    };

  const handleWeekChange = (week) => {
    const weekData = mapWeeksForApi([week], filteredWeeks)[0];
    console.log(weekData, "weekData here");
    if (weeks.some((w) => w.startDate === weekData.startDate)) {
      // Remove week if already selected
      dispatch(
        updateDashboard({
          key: "weeks",
          value: weeks.filter((w) => w.startDate !== weekData.startDate),
        })
      );
    } else {
      // Add week, removing the empty state if present
      dispatch(
        updateDashboard({
          key: "weeks",
          value: [...weeks.filter((w) => w.startDate !== ""), weekData],
        })
      );
    }
  };

  // Function to select or deselect all weeks
  const handleSelectAllWeeks = async (e) => {
    if (e.target.checked) {
      const newWeeks = data?.filteredWeeks.map((week) => ({
        startDate: week.startDate,
        endDate: week.endDate,
      }));
      dispatch(updateDashboard({ key: "weeks", value: newWeeks }));
    } else {
      await dispatch(unSelectWeeks());
    }
  };

  const getSelectedItemsText = (
    list,
    data,
    singleItemText,
    isCatogories = false
  ) => {
    const itemLabel = isCatogories ? "categorie" : singleItemText;

    if (list?.length === 1) {
      const selectedItem = isCatogories
        ? data?.find((item) => item?.name === list[0])
        : data?.find((item) => +item?.id === list[0]);

      console.log(selectedItem, "navbar", list, data);

      return (
        <div className="out-500-14 text-gray-700">
          {isCatogories ? "Category" : itemLabel}:{" "}
          <span className="out-300-12 text-gray-500 capitalize">
            {selectedItem?.name}
          </span>
        </div>
      );
    } else if (list?.length > 1) {
      if (list?.length === data?.length) {
        return <div className="out-500-14 text-gray-700">All {itemLabel}s</div>;
      }

      return (
        <div className="out-500-14 text-gray-700">
          {list?.length} {itemLabel}s
        </div>
      );
    }

    return (
      <div className="out-500-14 text-gray-700">
        Select {isCatogories ? "category" : itemLabel}
      </div>
    );
  };

  const colorMapping = useMemo(() => {
    const colorScale = reportColorsScheme;
    return teamkeys?.reduce((acc, key, i) => {
      console.log("acc:", acc, "key:", key, "i:", i);
      acc[key] = colorScale[i % colorScale.length];
      return acc;
    }, {});
  }, [filteredTeams, reportColorsScheme]);

  const renderProjectsDropdown = () => (
    <div className="min-w-[400px] max-w-[400px] max-h-[350px] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search projects"
            className=""
            suffix={
              search ? (
                <span onClick={() => setSearch("")} className="cursor-pointer">
                  <CrossIconSecondary />
                </span>
              ) : null
            }
          />
        </div>
        {!search && (
          <div
            className={`group py-2 px-4 border-b ${
              selectProjects?.length === projectsData?.length
                ? "bg-primary-50 border-primary-100"
                : "hover:bg-gray-100 border-gray-100"
            }`}
          >
            <Checkbox
              checked={selectProjects?.length === projectsData?.length}
              className="custom-checkbox"
              onChange={handleSelectAll(
                selectProjects,
                projectsData,
                "id",
                "selectProjects",
                true
              )}
            >
              <div className="out-300-14 text-[#475467]">Select All</div>
            </Checkbox>
          </div>
        )}
        {filteredProjects?.map((project) => {
          const title = project.name;
          const image = project.image;

          const isSelected = selectProjects?.includes(+project?.id);
          // console.log(selectProjects, "herely", project, isSelected);
          return (
            <div
              key={project.id}
              className={`group flex justify-between items-center py-2 px-4 cursor-pointer border-b ${
                isSelected
                  ? "bg-primary-50 border-primary-100"
                  : "hover:bg-gray-100 border-gray-100"
              }`}
              onClick={() =>
                handleSelectChange(
                  selectProjects,
                  project?.id,
                  "selectProjects",
                  true
                )
              }
            >
              <div className="flex gap-3 items-center">
                {/* Checkbox */}
                <Checkbox
                  checked={isSelected}
                  className="custom-checkbox"
                  onChange={() =>
                    handleSelectChange(
                      selectProjects,
                      project?.id,
                      "selectProjects",
                      true
                    )
                  }
                />

                {/* Avatar and project details */}
                <CustomAvatar
                  title={title}
                  size={24}
                  whiteText
                  fontSize="9px"
                  src={image}
                  style={{ border: "1px solid #edf3f9", borderRadius: "50%" }}
                />
                <div className="text-gray-700">
                  {project.name}
                  <div className="text-gray-500 text-[10px]">
                    {project.dates}
                  </div>
                </div>
              </div>

              {/* Badge for project status */}
              <CustomBadge
                color={
                  project.status === "Ongoing"
                    ? "#165ABF"
                    : project.status === "Completed"
                    ? "#027A48"
                    : "#667085"
                }
                text={project.status}
                className="out-500-12"
                style={{
                  backgroundColor:
                    project.status === "Ongoing"
                      ? "#D1E9FF"
                      : project.status === "Completed"
                      ? "#D1FADF"
                      : "#DDE5ED",
                }}
                fontWeight="500"
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderProjectsTrendsDropdown = () => (
    <div className="min-w-[400px] max-w-[400px] max-h-[350px] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search projects"
            className=""
            suffix={
              search ? (
                <span onClick={() => setSearch("")} className="cursor-pointer">
                  <CrossIconSecondary />
                </span>
              ) : null
            }
          />
        </div>
        {!search && (
          <div
            className={`group py-2 px-4 border-b ${
              selectProjectsTrends?.length === projectsData?.length
                ? "bg-primary-50 border-primary-100"
                : "hover:bg-gray-100 border-gray-100"
            }`}
          >
            <Checkbox
              checked={selectProjectsTrends?.length === projectsData?.length}
              className="custom-checkbox"
              onChange={handleSelectAll(
                selectProjectsTrends,
                projectsData,
                "id",
                "selectProjectsTrends",
                true
              )}
            >
              <div className="out-300-14 text-[#475467]">Select All</div>
            </Checkbox>
          </div>
        )}
        {filteredProjects?.map((project) => {
          const title = project.name;
          const image = project.image;

          const isSelected = selectProjectsTrends?.includes(+project?.id);
          // console.log(selectProjects, "herely", project, isSelected);
          return (
            <div
              key={project.id}
              className={`group flex justify-between items-center py-2 px-4 cursor-pointer border-b ${
                isSelected
                  ? "bg-primary-50 border-primary-100"
                  : "hover:bg-gray-100 border-gray-100"
              }`}
              onClick={() =>
                handleSelectChange(
                  selectProjectsTrends,
                  project?.id,
                  "selectProjectsTrends",
                  true
                )
              }
            >
              <div className="flex gap-3 items-center">
                {/* Checkbox */}
                <Checkbox
                  checked={isSelected}
                  className="custom-checkbox"
                  onChange={() =>
                    handleSelectChange(
                      selectProjectsTrends,
                      project?.id,
                      "selectProjectsTrends",
                      true
                    )
                  }
                />

                {/* Avatar and project details */}
                <CustomAvatar
                  title={title}
                  size={24}
                  whiteText
                  fontSize="9px"
                  src={image}
                  style={{ border: "1px solid #edf3f9", borderRadius: "50%" }}
                />
                <div className="text-gray-700">
                  {project.name}
                  <div className="text-gray-500 text-[10px]">
                    {project.dates}
                  </div>
                </div>
              </div>

              {/* Badge for project status */}
              <CustomBadge
                color={
                  project.status === "Ongoing"
                    ? "#165ABF"
                    : project.status === "Completed"
                    ? "#027A48"
                    : "#667085"
                }
                text={project?.status}
                className="out-500-12"
                style={{
                  backgroundColor:
                    project.status === "Ongoing"
                      ? "#D1E9FF"
                      : project.status === "Completed"
                      ? "#D1FADF"
                      : "#DDE5ED",
                }}
                fontWeight="500"
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderTeamsDropdown = () => (
    <div className=" min-w-[280px] max-w-[280px] max-h-[350px] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            autoFocus={true}
            search={search}
            setSearch={setSearch}
            placeholder="Search teams"
            className=""
            suffix={
              search ? (
                <span onClick={() => setSearch("")} className="cursor-pointer">
                  <CrossIconSecondary />
                </span>
              ) : null
            }
          />
        </div>
        {!search && (
          <div
            className={`group py-2 px-4 border-b ${
              selectTeams?.length === data?.teams?.length
                ? "bg-primary-50 border-primary-100"
                : "hover:bg-gray-100 border-gray-100"
            }`}
          >
            <Checkbox
              checked={selectTeams?.length === data?.teams?.length}
              className="custom-checkbox"
              onChange={handleSelectAll(
                selectTeams,
                data?.teams,
                "id",
                "selectTeams",
                true
              )}
            >
              <div className="out-300-14 text-[#475467]">Select All</div>
            </Checkbox>
          </div>
        )}
        {filteredTeams?.map((team) => {
          const isSelected = selectTeams?.includes(+team?.id);
          // console.log(team, "acc");
          // console.log(selectedTeams, "herely", team, isSelected);

          const teamColor = colorMapping[team?.name]; // Get the color from colorMapping
          // console.log(teamColor, "navbar acc", team?.name);
          return (
            <div
              key={team.id}
              className={`group flex gap-1.5 items-center py-2 px-4 cursor-pointer border-b ${
                isSelected
                  ? "bg-primary-50 border-primary-100"
                  : "hover:bg-gray-100 border-gray-100"
              }`}
            >
              <Checkbox
                checked={isSelected}
                className="custom-checkbox"
                onChange={() =>
                  handleSelectChange(selectTeams, team?.id, "selectTeams", true)
                }
              />
              <span
                className="w-2.5 h-2.5 rounded-full"
                style={{ backgroundColor: teamColor }} // Use color from colorMapping for the dot as well
              />
              <div className="out-300-14 text-gray-500">{team?.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderCategoriesDropdown = () => (
    <div className="min-w-[280px] max-w-[280px] max-h-[50vh] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search categories"
            className=""
            suffix={
              search ? (
                <span onClick={() => setSearch("")} className="cursor-pointer">
                  <CrossIconSecondary />
                </span>
              ) : null
            }
          />
        </div>
        {!search && (
          <div
            className={`group py-2 px-4 ${
              trendsCategory?.length === categoriesData?.length
                ? "bg-primary-50"
                : "hover:bg-gray-100"
            }`}
          >
            <Checkbox
              checked={trendsCategory?.length === categoriesData?.length}
              className="custom-checkbox"
              onChange={handleSelectAll(
                trendsCategory,
                categoriesData,
                "name",
                "trendsCategory"
              )}
            >
              <div className="out-300-14 text-[#475467]">Select All</div>
            </Checkbox>
          </div>
        )}
        {filteredCategories?.map((category) => {
          const isSelected = trendsCategory?.includes(category?.name);

          return (
            <div
              key={category.id}
              className={`group flex gap-1.5 items-center py-2 px-4 cursor-pointer ${
                isSelected ? "bg-primary-50" : "hover:bg-gray-100"
              }`}
            >
              <Checkbox
                checked={trendsCategory?.includes(category.name)}
                className="custom-checkbox"
                onChange={() =>
                  handleSelectChange(
                    trendsCategory,
                    category?.name,
                    "trendsCategory"
                  )
                }
              />
              <span className="out-300-14 text-gray-600">{category.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderAssigneesDropdown = () => (
    <div className="min-w-[280px] max-w-[280px] max-h-[50vh] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search assignees"
            className=""
            suffix={
              search ? (
                <span onClick={() => setSearch("")} className="cursor-pointer">
                  <CrossIconSecondary />
                </span>
              ) : null
            }
          />
        </div>
        {!search && (
          <div
            className={`group py-2 px-4 border-b ${
              selectAssignees?.length === assigneesData?.length
                ? "bg-primary-50 border-primary-100"
                : "hover:bg-gray-100 border-gray-100"
            }`}
          >
            <Checkbox
              checked={selectAssignees?.length === assigneesData?.length}
              className="custom-checkbox"
              onChange={handleSelectAll(
                selectAssignees,
                assigneesData,
                "id",
                "selectAssignees",
                true
              )}
            >
              <div className="out-300-14 text-[#475467]">Select All</div>
            </Checkbox>
          </div>
        )}
        {filteredAssignees?.map((assignee) => {
          const isSelected = selectAssignees?.includes(+assignee.id);
          console.log(selectAssignees, "sele Asig");
          return (
            <div
              key={assignee.id}
              className={`group flex gap-1.5 items-center py-2 px-4 cursor-pointer border-b ${
                isSelected
                  ? "bg-primary-50 border-primary-100"
                  : "hover:bg-gray-100 border-gray-100"
              }`}
            >
              <Checkbox
                checked={isSelected}
                className="custom-checkbox"
                onChange={() =>
                  handleSelectChange(
                    selectAssignees,
                    +assignee.id,
                    "selectAssignees",
                    true
                  )
                }
              />
              <CustomAvatar
                title={assignee.name}
                size={24}
                whiteText
                fontSize="9px"
                src={assignee?.image}
                style={{ border: "1px solid #edf3f9", borderRadius: "50%" }}
              />
              <div className="out-300-14 text-gray-600">{assignee.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderAssigneesTrendsDropdown = () => (
    <div className="min-w-[280px] max-w-[280px] max-h-[50vh] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search assignees"
            className=""
            suffix={
              search ? (
                <span onClick={() => setSearch("")} className="cursor-pointer">
                  <CrossIconSecondary />
                </span>
              ) : null
            }
          />
        </div>
        {!search && (
          <div
            className={`group py-2 px-4 border-b ${
              selectAssigneesTrends?.length === assigneesData?.length
                ? "bg-primary-50 border-primary-100"
                : "hover:bg-gray-100 border-gray-100"
            }`}
          >
            <Checkbox
              checked={selectAssigneesTrends?.length === assigneesData?.length}
              className="custom-checkbox"
              onChange={handleSelectAll(
                selectAssigneesTrends,
                assigneesData,
                "id",
                "selectAssigneesTrends",
                true
              )}
            >
              <div className="out-300-14 text-[#475467]">Select All</div>
            </Checkbox>
          </div>
        )}
        {filteredAssignees?.map((assignee) => {
          const isSelected = selectAssigneesTrends?.includes(+assignee.id);
          return (
            <div
              key={assignee.id}
              className={`group flex gap-1.5 items-center py-2 px-4 cursor-pointer border-b ${
                isSelected
                  ? "bg-primary-50 border-primary-100"
                  : "hover:bg-gray-100 border-gray-100"
              }`}
            >
              <Checkbox
                checked={isSelected}
                className="custom-checkbox"
                onChange={() =>
                  handleSelectChange(
                    selectAssigneesTrends,
                    +assignee.id,
                    "selectAssigneesTrends",
                    true
                  )
                }
              />
              <CustomAvatar
                title={assignee.name}
                size={24}
                whiteText
                fontSize="9px"
                src={assignee?.image}
                style={{ border: "1px solid #edf3f9", borderRadius: "50%" }}
              />
              <div className="out-300-14 text-gray-600">{assignee.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderAddChartDropdown = () => (
    <div className="min-w-[276px] max-w-[276px] shadow-xl rounded-lg bg-white">
      <div
        className="flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
        onClick={() => {
          setReportType("projects");
          setReportsModal(true);
        }}
      >
        <div>
          <div className="out-500-14 text-gray-700">Projects</div>
          <div className="out-500-10 text-gray-500">
            Overview of one or more projects
          </div>
        </div>
        <ChevronIcon className="-rotate-90 text-primary-600" />
      </div>
      <div
        className="flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
        onClick={() => {
          setReportType("teams");
          setReportsModal(true);
        }}
      >
        <div>
          <div className="out-500-14 text-gray-700">Teams</div>
          <div className="out-500-10 text-gray-500">
            Overview of one or more teams
          </div>
        </div>
        <ChevronIcon className="-rotate-90 text-primary-600" />
      </div>
      <div
        className="flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
        onClick={() => {
          setReportType("assignees");
          setReportsModal(true);
        }}
      >
        <div>
          <div className="out-500-14 text-gray-700">Assignees</div>
          <div className="out-500-10 text-gray-500">
            Overview of one or more assignees
          </div>
        </div>
        <ChevronIcon className="-rotate-90 text-primary-600" />
      </div>
    </div>
  );

  const getSelectedWeeksText = () => {
    // Check if all weeks are selected
    if (weeks?.length === filteredWeeks?.length) {
      return <div className="out-500-14 text-gray-700">All weeks</div>;
    }

    if (
      weeks?.length === 1 &&
      !(weeks[0]?.startDate === "" && weeks[0]?.endDate === "")
    ) {
      const selectedWeek = filteredWeeks?.find(
        (weekData) => weekData?.startDate === weeks[0]?.startDate
      );
      console.log(selectedWeek, "selectedWeeks", filteredWeeks);
      return (
        <div className="out-500-14 text-gray-700">
          {selectedWeek?.week}{" "}
          <span className="out-300-12 text-gray-500 ml-0.5">
            ({selectedWeek?.dates})
          </span>
        </div>
      );
    } else if (weeks?.length > 1) {
      return (
        <div className="out-500-14 text-gray-700">{weeks?.length} weeks</div>
      );
    }

    return <div className="out-500-14 text-gray-700">Select Weeks</div>;
  };

  const previousWeekStart = dayjs().day(-6); // Monday of last week
  const previousWeekEnd = dayjs().day(-2); // Friday of last week

  // Define the start and end of the current week
  const currentWeekStart = dayjs().startOf("week");
  const currentWeekEnd = dayjs().endOf("week");

  const dropdownContentWeek = (
    <div className="min-w-[280px] max-w-[280px] max-h-[50vh] overflow-auto shadow-xl rounded-lg bg-white">
      {/* Prevent Dropdown from closing when clicking inside */}
      <div onClick={(e) => e.stopPropagation()}>
        {/* Custom Search Box */}
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search weeks"
            className=""
            suffix={
              search ? (
                <span onClick={() => setSearch("")} className="cursor-pointer">
                  <CrossIconSecondary />
                </span>
              ) : null
            }
          />
        </div>

        {/* Select All Checkbox */}
        {!search && (
          <div
            className={`group py-2 px-4 border-b ${
              weeks?.length === filteredWeeks?.length
                ? "bg-primary-50 border-primary-100"
                : "hover:bg-gray-100 border-gray-100"
            }`}
          >
            <Checkbox
              checked={weeks?.length === filteredWeeks?.length}
              className="custom-checkbox"
              onChange={handleSelectAllWeeks}
            >
              <div className="out-300-14 text-[#475467]">Select All</div>
            </Checkbox>
          </div>
        )}

        {/* Week Options */}
        <div>
          {searchFilteredWeeks?.map((weekData) => {
            const isSelected = weeks?.some(
              (w) => w?.startDate === weekData?.startDate
            );

            // Check if weekData is the previous week
            // const isPreviousWeek = dayjs(weekData?.startDate).isBetween(
            //   previousWeekStart,
            //   previousWeekEnd,
            //   "day",
            //   "[]"
            // );

            // Check if weekData is the current week
            const isCurrentWeek = dayjs(weekData?.startDate).isBetween(
              currentWeekStart,
              currentWeekEnd,
              "day",
              "[]"
            );

            return (
              <div
                key={weekData?.week}
                className={`group flex justify-between items-center py-2 px-4 cursor-pointer border-b ${
                  isSelected
                    ? "bg-primary-50 border-primary-100"
                    : "hover:bg-gray-100 border-gray-100"
                }`}
              >
                <div className="flex gap-3">
                  <Checkbox
                    checked={isSelected}
                    className="custom-checkbox"
                    onChange={() => handleWeekChange(weekData?.week)}
                  />
                  <div className="out-500-14 text-gray-700">
                    {weekData?.week}
                    <div className="out-500-10 text-gray-500">
                      {weekData?.dates}
                    </div>
                  </div>
                </div>

                {/* Render "Previous Week" badge */}
                {/* {isPreviousWeek && (
                  <CustomBadge
                    color={"#165ABF"}
                    text={"Previous Week"}
                    className="bg-primary-100"
                    fontWeight="500"
                  />
                )} */}

                {/* Render "Current Week" badge */}
                {isCurrentWeek && (
                  <CustomBadge
                    color={"#28A745"}
                    text={"Current Week"}
                    className="bg-green-100"
                    fontWeight="500"
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const exportMenu = (
    <div className="bg-white shadow rounded min-w-[156px]">
      <div
        key="1"
        onClick={() => exportChart("png")}
        className="cursor-pointer px-3 py-1.5 hover:bg-gray-50"
      >
        <div className="flex items-center gap-2 out-300-14 text-[#475467]">
          <ExportIcon className="text-[#475467]" />
          <div>Export as PNG</div>
        </div>
      </div>
      <div
        key="2"
        onClick={() => exportChart("pdf")}
        className="cursor-pointer px-3 py-1.5 hover:bg-gray-50"
      >
        <div className="flex items-center gap-2 out-300-14 text-[#475467]">
          <ExportIcon className="text-[#475467]" />
          <div>Export as PDF</div>
        </div>
      </div>
      <div
        key="3"
        onClick={() => exportChart("csv")}
        className="cursor-pointer px-3 py-1.5 hover:bg-gray-50"
      >
        <div className="flex items-center gap-2 out-300-14 text-[#475467]">
          <ExportIcon className="text-[#475467]" />
          <div>Export as CSV</div>
        </div>
      </div>
    </div>
  );

  const exportChart = (format) => {
    switch (format) {
      case "png":
        exportAsPNG();
        break;
      case "pdf":
        exportAsPDF();
        break;
      case "csv":
        exportAsCSV();
        break;
      default:
        console.error("Unknown format");
    }
  };

  const exportAsPNG = () => {
    if (chartRef.current && ref1.current && ref2.current) {
      const container = chartRef.current;
      const originalOverflow = container.style.overflow;
      const originalHeight = container.style.height;

      // Make ref1 hidden and ref2 visible before exporting
      ref1.current.style.display = "none"; // Hide ref1
      ref2.current.style.display = "block"; // Show ref2

      // Temporarily set overflow and height to ensure full capture
      container.style.overflow = "visible";
      container.style.height = `${container.scrollHeight}px`;

      html2canvas(container, {
        scrollX: 0,
        scrollY: -window.scrollY,
        width: container.scrollWidth,
        height: container.scrollHeight,
        useCORS: true,
      }).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();

        // Restore original styles
        container.style.overflow = originalOverflow;
        container.style.height = originalHeight;

        // Revert visibility
        ref1.current.style.display = "block"; // Show ref1
        ref2.current.style.display = "none"; // Hide ref2
      });
    }
  };

  const exportAsPDF = () => {
    console.log("Starting exportAsPDF...");

    if (chartRef.current && ref1.current && ref2.current) {
      console.log("chartRef, ref1, and ref2 exist.");

      const container = chartRef.current;
      const originalOverflow = container.style.overflow;
      const originalHeight = container.style.height;

      // Hide ref1 and show ref2 before capturing
      if (screen === 1) {
        console.log("Hiding ref1 and showing ref2...");
        ref1.current.style.display = "none"; // Hide ref1
        ref2.current.style.display = "block"; // Show ref2
      }

      // Temporarily set overflow and height to ensure full capture
      console.log("Setting overflow and height for full capture...");
      container.style.overflow = "visible";
      container.style.height = `${container.scrollHeight}px`;

      // Wait for DOM changes to reflect before capturing
      setTimeout(() => {
        html2canvas(container, {
          scrollX: 0,
          scrollY: -window.scrollY,
          width: container.scrollWidth,
          height: container.scrollHeight,
          useCORS: true,
        })
          .then((canvas) => {
            console.log("Canvas successfully generated.");

            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4"); // Set PDF to A4 size (portrait)

            // Calculate the dimensions for the PDF based on canvas size and aspect ratio
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

            console.log("Calculated PDF dimensions:", { pdfWidth, pdfHeight });

            // If the canvas height exceeds a single page, split into multiple pages
            const pageHeight = pdf.internal.pageSize.getHeight();
            let heightLeft = pdfHeight;
            let position = 0;

            // Add the first page
            console.log("Adding the first page to PDF...");
            pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight;

            // Add additional pages if content overflows
            while (heightLeft > 0) {
              position = heightLeft - pdfHeight; // Move to next section
              console.log("Adding a new page to PDF...", { position });
              pdf.addPage();
              pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
              heightLeft -= pageHeight;
            }

            console.log("Saving PDF...");
            pdf.save("chart.pdf");

            // Restore original styles
            container.style.overflow = originalOverflow;
            container.style.height = originalHeight;

            // Revert visibility of refs
            console.log("Reverting visibility of ref1 and ref2...");
            if (screen === 1) {
              ref1.current.style.display = "block"; // Show ref1
              ref2.current.style.display = "none"; // Hide ref2
            }
            console.log("PDF export complete.");
          })
          .catch((error) => {
            console.error("Error generating canvas:", error);
          });
      }, 1000); // Wait for 1 second to ensure DOM updates
    } else {
      console.error("chartRef, ref1, or ref2 is missing.");
    }
  };

  const exportAsCSV = () => {
    if (chartRef.current) {
      const csvData = [
        ["Column1", "Column2"],
        ["Data1", "Data2"],
      ]; // Replace with your actual data
      const csv = Papa.unparse(csvData);
      const link = document.createElement("a");
      link.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      link.download = "chart.csv";
      link.click();
    }
  };

  const handleRefresh = () => {
    screen == 1 ? dispatch(resetReports()) : dispatch(resetTrends());
  };

  return (
    <div className="px-6 py-3  bg-white flex flex-col gap-2.5">
      <div className="flex justify-between items-center">
        <div>
          <div className="out-500-14">Reports</div>
          <div className="out-300-14">
            View the weekly performance reports of projects and add time
            estimates for assignees.
          </div>
        </div>
        <div
          role="button"
          className="flex gap-2 rounded-[4px] px-2.5 py-[5px] items-center bg-primary-50 border border-primary-100 h-[30px] w-[173px] whitespace-nowrap mt-[5px]"
          onClick={() => navigate(`/time-estimates/`)}
        >
          <TimeEstimateIcon className="text-primary-700" />
          <div className="text-primary-700 out-500-14">Add Time Estimates</div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        {/* Route Buttons */}
        <div className="inline-flex bg-gray-50 rounded-[5px] gap-2 p-1 text-[#FCFDFF]">
          <CustomRouteButton
            title="All Charts"
            isSelected={screen === 1}
            onSelect={() => setScreen(1)}
          />
          <CustomRouteButton
            title="Compare Trends"
            isSelected={screen === 2}
            onSelect={() => setScreen(2)}
          />
        </div>

        <div className="flex gap-2 items-center">
          <Tooltip title="Refresh" placement="bottom">
            <div
              className="cursor-pointer hover:bg-gray-50 rounded-sm"
              onClick={handleRefresh}
            >
              <RefreshIcon className="text-gray-700" />
            </div>
          </Tooltip>
          {screen === 2 && (
            <Dropdown
              overlay={renderCategoriesDropdown}
              trigger={["click"]}
              open={dropdownOpenCategory}
              onOpenChange={(open) => {
                setDropdownOpenCategory(open);
                setSearch("");
              }}
              placement="bottomRight"
            >
              <div
                className={`flex items-center gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer ${
                  dropdownOpenCategory ? "bg-gray-50" : "hover:bg-gray-50"
                }`}
              >
                <p className="out-500-14 text-gray-700 whitespace-nowrap">
                  {getSelectedItemsText(
                    trendsCategory,
                    categoriesData,
                    "Category",
                    true
                  )}
                </p>
                <ChevronIcon
                  className={`text-gray-700 ${
                    dropdownOpenCategory ? "rotate-180" : ""
                  }`}
                />
              </div>
            </Dropdown>
          )}
          {/* Dropdown for Weeks Selection */}
          {screen === 1 && (
            <Dropdown
              overlay={dropdownContentWeek}
              trigger={["click"]}
              open={dropdownOpenWeek}
              onOpenChange={(open) => {
                setDropdownOpenWeek(open);
                setSearch("");
              }}
              placement="bottomRight"
            >
              <div
                className={`flex items-center gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer ${
                  dropdownOpenWeek ? "bg-gray-50" : "hover:bg-gray-50"
                }`}
              >
                <p className="out-500-14 text-gray-700 whitespace-nowrap">
                  {getSelectedWeeksText()}
                </p>
                <ChevronIcon
                  className={`text-gray-700 ${
                    dropdownOpenWeek ? "rotate-180" : ""
                  }`}
                />
              </div>
            </Dropdown>
          )}

          {screen === 1 && (
            <Dropdown
              overlay={renderProjectsDropdown}
              trigger={["click"]}
              open={dropdownOpenProject}
              onOpenChange={(open) => {
                setDropdownOpenProject(open);
                setSearch("");
              }}
              placement="bottomRight"
            >
              <div
                className={`flex items-center gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer ${
                  dropdownOpenProject ? "bg-gray-50" : "hover:bg-gray-50"
                }`}
              >
                <p className="out-500-14 text-gray-700 whitespace-nowrap">
                  {getSelectedItemsText(
                    selectProjects,
                    projectsData,
                    "Project"
                  )}
                </p>
                <ChevronIcon
                  className={`text-gray-700 ${
                    dropdownOpenProject ? "rotate-180" : ""
                  }`}
                />
              </div>
            </Dropdown>
          )}

          {screen === 2 && (
            <Dropdown
              overlay={renderProjectsTrendsDropdown}
              trigger={["click"]}
              open={dropdownOpenProject}
              onOpenChange={(open) => {
                setDropdownOpenProject(open);
                setSearch("");
              }}
              placement="bottomRight"
            >
              <div
                className={`flex items-center gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer ${
                  dropdownOpenProject ? "bg-gray-50" : "hover:bg-gray-50"
                }`}
              >
                <p className="out-500-14 text-gray-700 whitespace-nowrap">
                  {getSelectedItemsText(
                    selectProjectsTrends,
                    projectsData,
                    "Project"
                  )}
                </p>
                <ChevronIcon
                  className={`text-gray-700 ${
                    dropdownOpenProject ? "rotate-180" : ""
                  }`}
                />
              </div>
            </Dropdown>
          )}

          {screen === 1 && !hideTeamsDropdown && (
            <Dropdown
              overlay={renderTeamsDropdown}
              trigger={["click"]}
              open={dropdownOpenTeams}
              onOpenChange={(open) => {
                setDropdownOpenTeams(open);
                setSearch("");
              }}
              placement="bottomRight"
            >
              <div
                className={`flex items-center gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer ${
                  dropdownOpenTeams ? "bg-gray-50" : "hover:bg-gray-50"
                }`}
              >
                <p className="out-500-14 text-gray-700 whitespace-nowrap">
                  {getSelectedItemsText(selectTeams, data?.teams, "Team")}
                </p>
                <ChevronIcon
                  className={`text-gray-700 ${
                    dropdownOpenTeams ? "rotate-180" : ""
                  }`}
                />
              </div>
            </Dropdown>
          )}

          {screen === 1 && (
            <Dropdown
              overlay={renderAssigneesDropdown}
              trigger={["click"]}
              open={dropdownOpenAssignee}
              onOpenChange={(open) => {
                setDropdownOpenAssignee(open);
                setSearch("");
              }}
              placement="bottomRight"
            >
              <div
                className={`flex items-center gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer ${
                  dropdownOpenAssignee ? "bg-gray-50" : "hover:bg-gray-50"
                }`}
              >
                <p className="out-500-14 text-gray-700 whitespace-nowrap">
                  {getSelectedItemsText(
                    selectAssignees,
                    assigneesData,
                    "Assignee"
                  )}
                </p>
                <ChevronIcon
                  className={`text-gray-700 ${
                    dropdownOpenAssignee ? "rotate-180" : ""
                  }`}
                />
              </div>
            </Dropdown>
          )}

          {screen === 2 && (
            <Dropdown
              overlay={renderAssigneesTrendsDropdown}
              trigger={["click"]}
              open={dropdownOpenAssignee}
              onOpenChange={(open) => {
                setDropdownOpenAssignee(open);
                setSearch("");
              }}
              placement="bottomRight"
            >
              <div
                className={`flex items-center gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer ${
                  dropdownOpenAssignee ? "bg-gray-50" : "hover:bg-gray-50"
                }`}
              >
                <p className="out-500-14 text-gray-700 whitespace-nowrap">
                  {getSelectedItemsText(
                    selectAssigneesTrends,
                    assigneesData,
                    "Assignee"
                  )}
                </p>
                <ChevronIcon
                  className={`text-gray-700 ${
                    dropdownOpenAssignee ? "rotate-180" : ""
                  }`}
                />
              </div>
            </Dropdown>
          )}

          <Dropdown
            overlay={exportMenu}
            trigger={["click"]}
            open={dropdownOpenExport}
            onOpenChange={setDropdownOpenExport}
            placement="bottomRight"
          >
            <div
              className={`out-500-14 text-gray-700 border border-grey-200 py-[5px] px-[10px] rounded-[4px] cursor-pointer ${
                dropdownOpenExport ? "bg-gray-50" : "hover:bg-gray-50"
              }`}
            >
              <div className="flex items-center">
                <div>Export</div>
                <ExportBoxIcon className="text-gray-700 ml-2" />
              </div>
            </div>
          </Dropdown>

          {screen === 1 && (
            <Dropdown
              overlay={renderAddChartDropdown}
              trigger={["click"]}
              open={dropdownOpenAddChart}
              onOpenChange={setDropdownOpenAddChart}
              placement="bottomLeft"
            >
              <div className="flex items-center gap-x-1.5 pl-[14px] pr-[10px] py-1 bg-primary-600 rounded-[4px] hover:bg-primary-700 cursor-pointer">
                <p className="out-500-14 text-[#FCFDFF] whitespace-nowrap">
                  Add Chart
                </p>
                <ChevronIcon
                  className={`text-white ${
                    dropdownOpenAddChart ? "rotate-180" : ""
                  }`}
                />
              </div>
            </Dropdown>
          )}
        </div>
        <ReportsModal
          visible={reportsModal}
          onClose={() => setReportsModal(false)}
          reportType={reportType}
        />
      </div>
    </div>
  );
};

export default ReportsNavBar;
