import React from "react";
import Icon from "@ant-design/icons";

const UrgencySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <rect
      width="13"
      height="3"
      x="3.5"
      y="14.5"
      stroke="currentColor"
      rx="0.5"
    ></rect>
    <path stroke="currentColor" d="M5.5 11a4.5 4.5 0 019 0v3.5h-9V11z"></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M10 2v2M15 6l1.414-1.414M5.414 6L4 4.586M19 12h-2M3 12H1"
    ></path>
  </svg>
);

const UrgencyIcon = (props) => {
  return <Icon component={UrgencySvg} {...props} />;
};

const UrgencyFillSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 20 20"
  >
    <rect
      width="13"
      height="3"
      x="3.5"
      y="14.5"
      stroke="currentColor"
      rx="0.5"
    ></rect>
    <path
      stroke="currentColor"
      fill="currentColor"
      d="M5.5 11a4.5 4.5 0 019 0v3.5h-9V11z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M10 2v2M15 6l1.414-1.414M5.414 6L4 4.586M19 12h-2M3 12H1"
    ></path>
  </svg>
);

const UrgencyFillIcon = (props) => {
  return <Icon component={UrgencyFillSvg} {...props} />;
};

export { UrgencyIcon, UrgencyFillIcon };
