import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppSelector } from "../../../Core/redux/hooks";
import { client } from "../../../Core/utils/axiosClient";
import { Breadcrumb, Checkbox, Empty, Spin } from "antd";
import {
  FolderCard,
  ProjectCard,
} from "../../../Core/CommonV2/FileFolderListAndSelect";
import _ from "lodash";

// const FolderCard = () => {
//   return (
//     <div className="flex items-center py-[10px] pl-[17px] ">
//       <PhaseIcon phaseId={1} width="10px" height="10px" />
//       <img
//         className="h-4 w-4 ml-2.5 mr-2"
//         src="/images/v2/conversations/folder-fill-vault.svg"
//       />
//       <p className="out-300-14 text-gray-900">My Library</p>
//     </div>
//   );
// };

const UploadFromLibrary = ({ visible, onClose, onChange }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const { projectList } = useAppSelector((state) => state.projects);
  const { user } = useAppSelector((state) => state.userDetails);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [folders, setFolders] = useState(null);
  const [files, setFiles] = useState(null);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [foldersArray, setFoldersArray] = useState([]);

  const getFolders = async (payload) => {
    const response = await client.get(`/repository/folder`, {
      params: payload,
    });
    return response.data;
  };

  const getFiles = async (payload) => {
    const response = await client.get(`/repository/all-files`, {
      params: payload,
    });
    return response.data;
  };

  useEffect(() => {
    const fetchData = async (selectedProject) => {
      try {
        setLoading(true);
        const payload: { projectId?: string; folderId?: string } = {};

        if (selectedProject) {
          payload.projectId = selectedProject;
        }

        if (foldersArray && foldersArray.length > 0) {
          payload.folderId = foldersArray[foldersArray.length - 1].id;
        }
        const folderResponse = await getFolders(payload);
        const fileResponse = await getFiles(payload);
        setFolders(folderResponse.result);
        setFiles(fileResponse.result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData(selectedProject?.id);
  }, [selectedProject, foldersArray]);

  const ownedByMeFolders = folders
    ? folders.filter(
        (folder) =>
          folder.canEdit === 1 &&
          folder.folderName.toLowerCase().includes(search)
      )
    : [];

  const ownedByMeFiles = files
    ? files.filter(
        (file) => file.canEdit === 1 && file.name.toLowerCase().includes(search)
      )
    : [];

  const handelAddFile = (checked, file) => {
    if (checked) {
      setSelectedFiles((files) => [...files, file]);
    } else {
      setSelectedFiles((files) => files.filter((f) => f.id != file.id));
    }
  };

  const extraBreadcrumbItems = foldersArray.map((_, index) => {
    return (
      <Breadcrumb.Item key={_.id}>
        <span
          className="mon-500-12 cursor-pointer text-gray-700"
          onClick={() => {
            const updatedArray = [...foldersArray]; // create a copy of the original array
            updatedArray.splice(index + 1); // remove all items after the index
            setFoldersArray(updatedArray); // update the state
          }}
        >
          {_.folderName}
        </span>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="Library">
      <span
        className="out-300-14 cursor-pointer text-gray-500"
        onClick={() => {
          setFoldersArray([]);
          setSelectedProject(null);
          setFolders(null);
        }}
      >
        Library
      </span>
    </Breadcrumb.Item>,
    selectedProject?.title ? (
      <Breadcrumb.Item key={selectedProject?.title}>
        <span
          className="out-300-14 cursor-pointer text-gray-500"
          onClick={() => {
            setFoldersArray([]);
            setFolders(null);
          }}
        >
          {selectedProject?.title}
        </span>
      </Breadcrumb.Item>
    ) : null,
  ].concat(extraBreadcrumbItems);

  // Create a debounced version of the click handler
  const debouncedAddFolderToArray = _.debounce((folder) => {
    setFoldersArray((arr) => [...arr, folder]);
  }, 500); // 500 milliseconds

  return (
    <CustomModal
      visible={true}
      width="362px"
      onCancel={() => console.log("")}
      body={
        <div className="p-4">
          <p className="text-black out-500-14 pb-4">Upload from</p>
          <CustomInputBox
            placeholder="Search in library"
            onChange={(e) => setSearch(e.target.value)}
          />

          {selectedProject?.id || foldersArray.length ? (
            <div className="mt-4 max-w-[330px] truncate">
              <Breadcrumb>{breadcrumbItems}</Breadcrumb>
            </div>
          ) : null}
          <Spin spinning={loading}>
            <div className="max-h-[340px] mt-4 min-h-[340px] overflow-y-auto">
              {foldersArray.length === 0 && selectedProject === null && (
                <ProjectCard
                  project={{ title: "My Library" }}
                  isSelected={false}
                  searchString={search}
                  onClick={() => {
                    setFoldersArray((arr) => [
                      ...arr,
                      {
                        title: "My Library ",
                        folderName: "My Library ",
                        id: user.myLibraryId,
                        isMyLibrary: true,
                      },
                    ]);
                  }}
                  onDoubleClick={() => null}
                />
              )}

              {foldersArray.length === 0 &&
                projectList &&
                !selectedProject &&
                projectList
                  .filter((prj) =>
                    prj?.title?.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((prj, i) => (
                    <ProjectCard
                      project={prj}
                      isSelected={prj.id == selectedProject?.id}
                      searchString={search}
                      key={prj.id + i}
                      onClick={() => {
                        setSelectedProject(prj);
                      }}
                      onDoubleClick={() => setSelectedProject(prj)}
                    />
                  ))}

              {foldersArray.length > 0 || selectedProject ? (
                ownedByMeFiles.length === 0 && ownedByMeFolders.length === 0 ? (
                  <div className="h-full w-full flex my-2 justify-center items-center">
                    <Empty
                      description={
                        <div className="mon-400-12 text-gray-500 ">
                          No Files Or Folders Available
                        </div>
                      }
                    />
                  </div>
                ) : (
                  <div className="max-w-[492px]">
                    <div>
                      {ownedByMeFolders?.map((folder, i) => (
                        <FolderCard
                          folder={folder}
                          key={folder.id + i}
                          isSelected={false}
                          searchString={search}
                          onClick={() => debouncedAddFolderToArray(folder)}
                          onDoubleClick={() => null}
                        />
                      ))}
                      {files
                        .filter(
                          (file) =>
                            file.canEdit === 1 &&
                            file.name.toLowerCase().includes(search)
                        )
                        ?.map((file, i) => (
                          <div
                            className="flex items-center py-2.5 px-4"
                            key={file.id + i}
                          >
                            <Checkbox
                              value={file.id}
                              onChange={(e) =>
                                handelAddFile(e.target.checked, file)
                              }
                              checked={
                                selectedFiles.findIndex(
                                  (f) => f.id === file.id
                                ) !== -1
                              }
                            />
                            <p className="mon-400-12 text-gray-700  ml-2">
                              {file.name}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                )
              ) : null}
            </div>
          </Spin>

          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={onClose}
            />
            <CustomButton
              text="Upload"
              height="30px"
              width="82px"
              disabled={selectedFiles.length == 0}
              onClick={() => {
                onChange(selectedFiles);
                onClose();
              }}
              // className="bg-error-700 text-white"
            />
          </div>
        </div>
      }
    />
  );
};

export default UploadFromLibrary;
