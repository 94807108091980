import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useNavigate } from "react-router-dom";

const SignedUpError = ({
  visible,
  company = "White Insights",
  email,
  handleClose,
  handleClick,
}) => {
  //   const { SignedUpErrorModal } = useAppSelector((state) => state.dashboard);
  const navigate = useNavigate();

  return (
    <CustomModal
      visible={visible}
      width="549px"
      onCancel={handleClose}
      body={
        <div className="min-w-[549px] min-h-[256px] p-[50px] flex flex-col gap-y-6">
          <div className="w-full flex gap-x-3 ">
            <div className="flex w-[15%] h-[69px]">
              <img
                src="/images/v2/project-onboarding/frosty.svg"
                className="w-[69px] h-[69px]"
              />
            </div>

            <div className="flex flex-col w-[85%]  gap-y-3 ">
              <div className="out-500-20 ">
                Seems like you are not registered with us!
              </div>
              <div className="out-300-14 text-gray-500">
                The Email ID you entered is not registered to Waddle. Sign up
                with your correct email ID to continue.
              </div>
            </div>
          </div>
          <div className="w-full">
            <CustomButton
              text="Sign Up"
              width="449px"
              height="30"
              className="bg-primary-600 text-white"
              onClick={() => navigate("/signupV2")}
            />
            <CustomButton
              text="Change Email ID"
              width="449px"
              height="30"
              className="bg-white "
              onClick={handleClose}
            />
          </div>
        </div>
      }
    />
  );
};

export default SignedUpError;
