import React, { useState } from "react";
import CustomModal from "../../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../../Core/CommonV2/CustomInputBox";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import { updateMyBoard } from "../../../../Core/redux/slices/myBoard.slice";
import { Checkbox } from "antd";
import CustomAvatar from "../../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../../Core/utils/getUserName";
import CustomButton from "../../../../Core/CommonV2/CustomButton";

const AddCollaboratorsModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();

  const { userList } = useAppSelector((state) => state.overview);
  const { collaboratorsForTask } = useAppSelector((state) => state.myBoard);
  const { user } = useAppSelector((state) => state.userDetails);

  const [search, setSearch] = useState("");

  console.log("Current user ID:", user?.id); // Debugging line to check user ID
  console.log("User list:", userList); // Debugging line to check the user list

  const handleMultiSelect = (current) => {
    dispatch(
      updateMyBoard({
        key: "collaboratorsForTask",
        value: collaboratorsForTask.includes(current.userId)
          ? collaboratorsForTask.filter((item) => item !== current.userId)
          : [...collaboratorsForTask, current.userId],
      })
    );
  };

  const handleOnClose = () => {
    onClose();
    dispatch(
      updateMyBoard({
        key: "collaboratorsForTask",
        value: [],
      })
    );
  };

  return (
    <CustomModal
      visible={visible}
      width="240px"
      onCancel={() => onClose()}
      body={
        <div>
          <div className="min-w-[200px] bg-white rounded-lg">
            <CustomInputBox
              className="px-3 pt-2"
              placeholder="Search for a name"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              autoFocus
            />

            <div className="overflow-y-scroll h-[200px]">
              <p className="px-3 pt-1.5 text-gray-500 out-300-14">
                Select 1 or multiple members
              </p>
              {userList
                .filter(
                  (u) =>
                    u.userId !== user?.id &&
                    (u.firstname.toLowerCase().includes(search.toLowerCase()) ||
                      u.lastname.toLowerCase().includes(search.toLowerCase()))
                )
                .map((current, index) => (
                  <div
                    role="button"
                    key={`${current.userId}${index}`}
                    className="flex items-center gap-x-3 mt-1.5 px-3"
                    onClick={() => handleMultiSelect(current)}
                  >
                    <Checkbox
                      checked={collaboratorsForTask?.includes(current.userId)}
                    />
                    <CustomAvatar
                      src={current.profilePicture}
                      size={24}
                      title={getUsersName(current)}
                      whiteText
                      color={current.profilePictureColorCode}
                    />

                    <p className="text-gray-900 capitalize out-300-12">
                      {getUsersName(current)}
                    </p>
                  </div>
                ))}
            </div>

            <div className="z-10 flex items-center justify-end w-full p-3 bg-white rounded-b-lg">
              <CustomButton
                text="Cancel"
                className="text-gray-500 bg-transparent"
                height="30px"
                width="63px"
                onClick={handleOnClose}
              />
              <CustomButton
                text="Request"
                height="30px"
                width="71px"
                className="bg-transparent text-primary-600"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default AddCollaboratorsModal;
