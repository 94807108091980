
//todo minimize the dummy data , send minimal data

export const DummyInitialState = {
  global: {
    loading: false,
    success: {
      message: null,
    },
    error: {
      message: "",
    },
    status: "fulfilled",
    endPoint: "/schedule/sprints",
    activeRequests: 0,
    from: "",
    errorModal: {
      show: false,
      text: "",
    },
    unreadConversationCount: 0,
    dashboardCount: false,
  },
  tenant: {
    loading: false,
    error: {},
    status: "fulfilled",
    tenantMembers: [
      {
        id: "113",
        userId: "113",
        firstname: "Ganesh",
        lastname: "Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        roleid: "1",
        email: "preetinder@antarcticaglobal.com",
        mobile: "7039734268",
        company: "Antarctica ",
        createdAt: "2022-07-28T04:47:25.000Z",
        updatedAt: "2023-07-20T00:10:08.568Z",
        lat: 19.0759837,
        long: 72.8776559,
        jobTitle: "Full Stack Developer",
        location: "Mumbai, Maharashtra, India",
      },
      {
        id: "136",
        userId: "136",
        firstname: "Kevinva",
        lastname: "Shah",
        profilePicture: null,
        roleid: "2",
        email: "kevin@antarcticaglobal.com",
        mobile: null,
        company: null,
        createdAt: "2022-08-23T00:05:26.000Z",
        updatedAt: "2023-07-17T02:05:51.862Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "136",
        userId: "136",
        firstname: "Kevinva",
        lastname: "Shah",
        profilePicture: null,
        roleid: "2",
        email: "kevin@antarcticaglobal.com",
        mobile: null,
        company: null,
        createdAt: "2022-08-23T00:05:26.000Z",
        updatedAt: "2023-07-17T02:05:51.862Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "136",
        userId: "136",
        firstname: "Kevinva",
        lastname: "Shah",
        profilePicture: null,
        roleid: "2",
        email: "kevin@antarcticaglobal.com",
        mobile: null,
        company: null,
        createdAt: "2022-08-23T00:05:26.000Z",
        updatedAt: "2023-07-17T02:05:51.862Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "136",
        userId: "136",
        firstname: "Kevinva",
        lastname: "Shah",
        profilePicture: null,
        roleid: "2",
        email: "kevin@antarcticaglobal.com",
        mobile: null,
        company: null,
        createdAt: "2022-08-23T00:05:26.000Z",
        updatedAt: "2023-07-17T02:05:51.862Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "136",
        userId: "136",
        firstname: "Kevinva",
        lastname: "Shah",
        profilePicture: null,
        roleid: "2",
        email: "kevin@antarcticaglobal.com",
        mobile: null,
        company: null,
        createdAt: "2022-08-23T00:05:26.000Z",
        updatedAt: "2023-07-17T02:05:51.862Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "136",
        userId: "136",
        firstname: "Kevinva",
        lastname: "Shah",
        profilePicture: null,
        roleid: "2",
        email: "kevin@antarcticaglobal.com",
        mobile: null,
        company: null,
        createdAt: "2022-08-23T00:05:26.000Z",
        updatedAt: "2023-07-17T02:05:51.862Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "48",
        userId: "48",
        firstname: "Ganesh",
        lastname: "Shah",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/44776.jpg-1660301953228.jpg?download=1",
        roleid: "2",
        email: "ganesh@antarcticaglobal.com",
        mobile: null,
        company: null,
        createdAt: "2022-06-13T01:27:38.000Z",
        updatedAt: "2023-07-17T05:11:55.956Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "48",
        userId: "48",
        firstname: "Ganesh",
        lastname: "Shah",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/44776.jpg-1660301953228.jpg?download=1",
        roleid: "2",
        email: "ganesh@antarcticaglobal.com",
        mobile: null,
        company: null,
        createdAt: "2022-06-13T01:27:38.000Z",
        updatedAt: "2023-07-17T05:11:55.956Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "274",
        userId: "274",
        firstname: "jhvh",
        lastname: "jkhbk",
        profilePicture: null,
        roleid: "1",
        email: "movuqy@lyft.live",
        mobile: null,
        company: null,
        createdAt: "2023-02-14T03:16:47.885Z",
        updatedAt: "2023-02-14T03:55:55.440Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "317",
        userId: "317",
        firstname: "Kumail",
        lastname: "A",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        roleid: "1",
        email: "kumail@antarcticaglobal.com",
        mobile: "999",
        company: "Antarctica ",
        createdAt: "2022-07-28T04:47:25.000Z",
        updatedAt: "2023-07-06T12:58:20.102Z",
        lat: 19.0759837,
        long: 72.8776559,
        jobTitle: "Full Stack Developer",
        location: "Mumbai, Maharashtra, India",
      },
      {
        id: "318",
        userId: "318",
        firstname: "Mathieu",
        lastname: "f",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        roleid: "1",
        email: "mathieu@antarcticaglobal.com",
        mobile: "991",
        company: "Antarctica ",
        createdAt: "2022-07-28T04:47:25.000Z",
        updatedAt: "2023-07-06T14:27:27.869Z",
        lat: 19.0759837,
        long: 72.8776559,
        jobTitle: "Full Stack Developer",
        location: "Mumbai, Maharashtra, India",
      },
      {
        id: "319",
        userId: "319",
        firstname: "Ebrahim",
        lastname: "k",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        roleid: "1",
        email: "ebrahim@antarcticaglobal.com",
        mobile: "992",
        company: "Antarctica ",
        createdAt: "2022-07-28T04:47:25.000Z",
        updatedAt: "2023-07-06T09:13:31.916Z",
        lat: 19.0759837,
        long: 72.8776559,
        jobTitle: "Full Stack Developer",
        location: "Mumbai, Maharashtra, India",
      },
      {
        id: "320",
        userId: "320",
        firstname: "Shraddha",
        lastname: "p",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        roleid: "1",
        email: "shraddha@antarcticaglobal.com",
        mobile: "993",
        company: "Antarctica ",
        createdAt: "2022-07-28T04:47:25.000Z",
        updatedAt: "2023-07-06T02:16:39.383Z",
        lat: 19.0759837,
        long: 72.8776559,
        jobTitle: "Full Stack Developer",
        location: "Mumbai, Maharashtra, India",
      },
      {
        id: "322",
        userId: "322",
        firstname: "Nutan",
        lastname: "g",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        roleid: "1",
        email: "nutan@antarcticaglobal.com",
        mobile: "994",
        company: "Antarctica ",
        createdAt: "2022-07-28T04:47:25.000Z",
        updatedAt: "2023-07-19T03:52:14.162Z",
        lat: 19.0759837,
        long: 72.8776559,
        jobTitle: "Full Stack Developer",
        location: "Mumbai, Maharashtra, India",
      },
      {
        id: "325",
        userId: "325",
        firstname: "xosuwome",
        lastname: "finews",
        profilePicture: null,
        roleid: "2",
        email: "xosuwome@finews.biz",
        mobile: null,
        company: "c3",
        createdAt: "2023-07-10T02:05:54.140Z",
        updatedAt: "2023-07-10T02:08:58.698Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "264",
        userId: "264",
        firstname: "Anushka",
        lastname: "Gaonkar",
        profilePicture:
          "https://lh3.googleusercontent.com/a/AAcHTtf7KFLdNrsVb6bZU-yQN-QOk4kzGdYW6UzUfNk-NJ6d=s96-c",
        roleid: "1",
        email: "anushka@antarcticaglobal.com",
        mobile: "8169535928",
        company: "Antarctica global",
        createdAt: "2023-01-25T02:01:40.971Z",
        updatedAt: "2023-07-19T07:55:04.041Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "333",
        userId: "333",
        firstname: "not",
        lastname: "new",
        profilePicture: null,
        roleid: "1",
        email: "vidol75503@paldept.com",
        mobile: null,
        company: null,
        createdAt: "2023-07-18T05:43:09.985Z",
        updatedAt: null,
        lat: null,
        long: null,
        jobTitle: null,
        location: "India",
      },
      {
        id: "340",
        userId: "340",
        firstname: "hello",
        lastname: "hello",
        profilePicture: null,
        roleid: "3",
        email: "bewito8325@miqlab.com",
        mobile: null,
        company: "c3",
        createdAt: "2023-07-19T05:27:22.063Z",
        updatedAt: "2023-07-19T05:30:39.198Z",
        lat: null,
        long: null,
        jobTitle: null,
        location: null,
      },
    ],
    activeUsers: [
      {
        _id: "64241d2fe5a59ccd41d23a2b",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        userId: "113",
        userPresence: true,
      },
      {
        _id: "64a3be62e2a7dd33231252c4",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        userId: "264",
        userPresence: true,
      },
    ],
    currentTenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
    tenantDetails: {
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      tenantHostname: "c3",
      tenantName: "c3",
      createdAt: "2022-12-29T06:29:29.500Z",
      updatedAt: null,
      sdgs: '["10"]',
      sectorsList: "[1]",
      tenantLocation: "Melbourne",
      tenantSize: "2",
    },
  },
  userDetails: {
    loading: false,
    error: null,
    status: "fulfilled",
    loggedIn: true,
    user: {
      id: "113",
      roleId: "1",
      firstName: "Ganesh",
      lastName: "Mohanty",
      mobile: "7039734268",
      email: "preetinder@antarcticaglobal.com",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExMyIsImVtYWlsIjoicHJlZXRpbmRlckBhbnRhcmN0aWNhZ2xvYmFsLmNvbSIsInRlbmFudF9pZCI6IjY5MjkxNDM3LTQ4NDYtNGU5Mi04ODA2LTQ1NDdjZGE2YjNlNCIsImlhdCI6MTY4OTgzMTYwNSwiZXhwIjoxNjg5OTE4MDA1fQ.hZ4NOOBwWXpdR4r36jLApT_vVmO0IE9e9-uer8xDHno",
      logMe: 1,
      tenant_id: "69291437-4846-4e92-8806-4547cda6b3e4",
      tenantDetails: {
        tenantId: "b33b4c23-19e9-48bf-bb8f-051572600198",
        tenantHostname: "invite",
        tenantName: "invite",
        createdAt: "2023-07-19T04:37:24.818Z",
        updatedAt: null,
        sdgs: '["4","6","12"]',
        sectorsList: "[2]",
        tenantLocation: "Mumbai, Maharashtra, India",
        tenantSize: "2",
      },
      companyName: "Antarctica ",
      conpany: "Antarctica ",
      jobTitle: "Full Stack Developer",
      location: "Mumbai, Maharashtra, India",
      showJobTitle: true,
      showPhoneNumber: true,
      showCompanyName: true,
      myLibraryId: "3976",
      refreshToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExMyIsImVtYWlsIjoicHJlZXRpbmRlckBhbnRhcmN0aWNhZ2xvYmFsLmNvbSIsInRlbmFudF9pZCI6ImIzM2I0YzIzLTE5ZTktNDhiZi1iYjhmLTA1MTU3MjYwMDE5OCIsImlhdCI6MTY4OTgzMTYwNSwiZXhwIjoxNjkyNDIzNjA1fQ.hxp7Ct1nXsOy_SAWpsOxKftCyN4m65bINeT8g_pc9x8",
      profileProgress: 100,
    },
    allowedNotifications: [],
  },
  overview: {
    loading: false,
    error: null,
    status: "fulfilled",
    projectDetails: {
      id: "345",
      title: "Project Xd",
      description: null,
      createdById: "113",
      updatedById: null,
      components: null,
      startDate: "2023-01-03T14:16:23.984Z",
      endDate: "2023-01-17T14:16:23.984Z",
      progress: 75,
      projectEndDateChangeReason: null,
      createdAt: "2023-01-10T03:47:11.619Z",
      updatedAt: "2023-07-20T00:26:40.604Z",
      isActive: 1,
      isPinned: 0,
      colorCode: "",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      isEnded: 1,
      projectImage:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Antarctica_white_no_bg_ljgx97kx.png-1688029626289.png?download=1",
      clientName: "XD designs",
      clientImage:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Adobe_ljgy9ipq.png-1688031320362.png?download=1",
      clientLocation: "Mumbai, Maharashtra, India",
      projectType: "1",
      internalProject: 0,
      clientColor: "",
      isDiscarded: 0,
      projectThemeColor: "#0066FF",
      automations: true,
    },
    userList: [
      {
        userId: "113",
        roleId: "135",
        firstname: "Ganesh",
        lastname: "Mohanty",
        isActive: true,
        mobile: "7039734268",
        email: "preetinder@antarcticaglobal.com",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        roleName: "New God",
        dateAdded: "2023-07-03T01:24:39.888Z",
        createdAt: "2022-07-28T04:47:25.000Z",
        lat: 19.0759837,
        long: 72.8776559,
        location: "Mumbai, Maharashtra, India",
        company: "Antarctica ",
        jobTitle: "Full Stack Developer",
        spoc: 0,
      },
      {
        userId: "48",
        roleId: "135",
        firstname: "Ganesh",
        lastname: "Shah",
        isActive: true,
        mobile: null,
        email: "ganesh@antarcticaglobal.com",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/44776.jpg-1660301953228.jpg?download=1",
        roleName: "New God",
        dateAdded: "2023-06-30T08:02:47.769Z",
        createdAt: "2022-06-13T01:27:38.000Z",
        lat: null,
        long: null,
        location: "India",
        company: null,
        jobTitle: null,
        spoc: 0,
      },
      {
        userId: "136",
        roleId: "143",
        firstname: "Kevinva",
        lastname: "Shah",
        isActive: true,
        mobile: null,
        email: "kevin@antarcticaglobal.com",
        profilePicture: null,
        roleName: "New User",
        dateAdded: "2023-07-05T07:18:47.892Z",
        createdAt: "2022-08-23T00:05:26.000Z",
        lat: null,
        long: null,
        location: "India",
        company: null,
        jobTitle: null,
        spoc: 0,
      },
      {
        userId: "264",
        roleId: null,
        firstname: "Anushka",
        lastname: "Gaonkar",
        isActive: true,
        mobile: "8169535928",
        email: "anushka@antarcticaglobal.com",
        profilePicture:
          "https://lh3.googleusercontent.com/a/AAcHTtf7KFLdNrsVb6bZU-yQN-QOk4kzGdYW6UzUfNk-NJ6d=s96-c",
        roleName: null,
        dateAdded: "2023-07-18T00:07:01.502Z",
        createdAt: "2023-01-25T02:01:40.971Z",
        lat: null,
        long: null,
        location: "India",
        company: "Antarctica global",
        jobTitle: null,
        spoc: 0,
      },
    ],
  },
  schedule: {
    loading: false,
    sprintsLoading: false,
    phaseLoading: false,
    goalsLoading: false,
    newSprintModal: {
      visible: false,
      isNewScope: false,
      scopeDescription: "",
    },
    newSprintScope: false,
    newGoalModal: false,
    newGoalScope: false,
    newScopeModal: false,
    moveSwimlaneModal: false,
    error: null,
    status: "fulfilled",
    phases: [
      {
        projectId: "345",
        phaseId: "1",
        isCompleted: 1,
        startDate: "2023-01-05T07:30:00.000Z",
        endDate: "2023-01-18T07:30:00.000Z",
        phaseProgress: 50,
        isOngoing: 0,
        phaseEndDateChangeReason: null,
        id: "1",
        isActive: 1,
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        components:
          '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
        phaseIteration: null,
        phases: "Ideation",
        color: "#FFDB01",
        projectPhaseId: "817",
      },
      {
        projectId: "345",
        phaseId: "2",
        isCompleted: 0,
        startDate: "2023-01-30T18:30:00.000Z",
        endDate: "2023-02-02T02:00:00.000Z",
        phaseProgress: 50,
        isOngoing: 1,
        phaseEndDateChangeReason: null,
        id: "2",
        isActive: 1,
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        components:
          '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
        phaseIteration: null,
        phases: "Design",
        color: "#F92B63",
        projectPhaseId: "818",
      },
      {
        projectId: "345",
        phaseId: "3",
        isCompleted: 1,
        startDate: "2023-01-08T18:30:00.000Z",
        endDate: "2023-01-25T18:30:00.000Z",
        phaseProgress: 50,
        isOngoing: 0,
        phaseEndDateChangeReason: null,
        id: "3",
        isActive: 1,
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        components:
          '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
        phaseIteration: null,
        phases: "Development",
        color: "#6938EF",
        projectPhaseId: "819",
      },
      {
        projectId: "345",
        phaseId: "4",
        isCompleted: 0,
        startDate: "2023-06-01T01:02:30.474Z",
        endDate: "2023-06-21T01:02:26.843Z",
        phaseProgress: 50,
        isOngoing: 1,
        phaseEndDateChangeReason: null,
        id: "4",
        isActive: 1,
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        components: null,
        phaseIteration: null,
        phases: "Deployment",
        color: "#33B7FF",
        projectPhaseId: "938",
      },
      {
        projectId: "345",
        phaseId: "5",
        isCompleted: 0,
        startDate: "2023-01-03T18:30:00.000Z",
        endDate: "2023-01-10T18:30:00.000Z",
        phaseProgress: 50,
        isOngoing: 1,
        phaseEndDateChangeReason: "",
        id: "5",
        isActive: 1,
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        components: "",
        phaseIteration: "",
        phases: "Maintenance",
        color: "#5FC3DB",
        projectPhaseId: "978",
      },
    ],
    currentPhaseGoals: [],
    currentSelectedPhase: {
      id: 0,
      phaseId: "0",
      phaseProgress: 0,
      phases: "",
      startDate: "",
      endDate: "",
      color: "",
      isOngoing: 0,
      isCompleted: 0,
    },
    selectedSprint: {
      id: "0",
      name: "",
      endDate: "",
      startDate: "",
      goals: [],
      isActive: 1,
      isCompleted: 0,
      isOngoing: 0,
      status: 1,
      phaseId: 1,
    },
    sprints: [
      {
        id: 2468,
        name: "sprint in ideation 1",
        isCompleted: 0,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "818",
        startDate: "2023-06-30T18:30:00.000Z",
        endDate: "2023-07-06T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 2,
        isOngoing: 0,
        createdById: "48",
        createdAt: "2023-07-01T07:19:21.186Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "57",
        goals: [
          {
            id: "1040",
            name: "waddle it bug",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 4,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-19T06:50:08.007Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: "PX-111",
          },
          {
            id: "1038",
            name: "hello new",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "136",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments:
              '[{"name":"🦆 emoji _love letter_.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8guwg9.png-1689695137763.png?download=1","size":595,"type":"image/png","lastModified":1673085186497,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8guwgd.svg-1689695137765.svg?download=1","size":2161,"type":"image/svg+xml","lastModified":1673080818081,"thumbnail":"/images/thumbnails/file.svg"},{"name":"01 1.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/01_1_lk8guwgh.png-1689695137766.png?download=1","size":2923,"type":"image/png","lastModified":1673082964314,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8gv52v.svg-1689695148930.svg?download=1","size":2161,"type":"image/svg+xml","lastModified":1673080818081,"thumbnail":"/images/thumbnails/file.svg"}]',
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-18T10:16:00.445Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: "PX-110",
          },
          {
            id: "1035",
            name: "new ",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "136",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-17T00:08:47.995Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: "PX-109",
          },
          {
            id: "1034",
            name: "hello id check",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "136",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-17T00:07:51.906Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: "PX_108",
          },
          {
            id: "1031",
            name: "sdcvsdvs",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "264",
            reporterId: "136",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-16T10:12:29.747Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: "P_107",
          },
          {
            id: "1030",
            name: "cz zcs",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "136",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-16T10:05:53.729Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: "P_106",
          },
          {
            id: "1029",
            name: "hgvghvgh",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "136",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-16T09:19:59.257Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: "P_105",
          },
          {
            id: "1028",
            name: "bhvhj",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "48",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-16T09:19:14.670Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: "P_104",
          },
          {
            id: "1025",
            name: "notif",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "136",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-13T04:44:49.619Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1024",
            name: "hi",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "136",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments:
              '[{"name":"file.mp4","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/file_lk2990ws.mp4-1689319562871.mp4?download=1","size":2233489,"type":"video/mp4","lastModified":1672749446278,"thumbnail":"/images/thumbnails/file.svg"},{"name":"Asset (1).csv","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Asset_1_lk2986u1.csv-1689319523783.csv?download=1","size":12806,"type":"text/csv","lastModified":1675235118371,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk2905ui.png-1689319149226.png?download=1","size":595,"type":"image/png","lastModified":1673085186497,"thumbnail":"/images/thumbnails/file.svg"}]',
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-13T04:43:13.714Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1022",
            name: "waddle emails",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 4,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-13T04:38:47.893Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1020",
            name: "waddle emails",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 4,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-13T02:54:34.939Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1019",
            name: "hello",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: null,
            description: null,
            swimlaneId: "149",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: null,
            phaseId: null,
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-07T11:41:57.455Z",
            componentName: null,
            timeLogged: null,
            linkedGoalId: null,
            labelId: "6",
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1018",
            name: "hello",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 3,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "136",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-07T11:40:21.349Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1017",
            name: "Hello",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: null,
            description: null,
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: null,
            phaseId: null,
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-07T11:38:32.617Z",
            componentName: null,
            timeLogged: null,
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1016",
            name: "hello ",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: null,
            description: null,
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: null,
            phaseId: null,
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-07T11:24:38.163Z",
            componentName: null,
            timeLogged: null,
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1015",
            name: "hell",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: null,
            description: null,
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: null,
            phaseId: null,
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-07T11:23:40.231Z",
            componentName: null,
            timeLogged: null,
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1013",
            name: "test new copy",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-02T06:07:06.008Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "1002",
            name: "waddwl",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "264",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-04T00:54:20.578Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "999",
            name: "test",
            isCompleted: 0,
            statusId: 2,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: null,
            description: null,
            swimlaneId: "57",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: null,
            phaseId: null,
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-02T07:10:57.739Z",
            componentName: null,
            timeLogged: null,
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "998",
            name: "test 3",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "48",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "1h",
            createdAt: "2023-07-02T06:10:41.549Z",
            componentName: null,
            timeLogged: "2h ",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "995",
            name: "test new",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-02T06:07:06.008Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "978",
            name: "task 11",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-01T03:56:34.733Z",
            componentName: "",
            timeLogged: "2h 10m ",
            linkedGoalId: "626",
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "977",
            name: "This is reaaallllly Log titleeee This is reaaallllly Log titleeee This is reaaallllly Log titleeee ",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "114",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-06T04:08:52.876Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: "650",
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "976",
            name: "Reporter Task Testing",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "114",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-07T13:27:12.340Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: "661",
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "975",
            name: "New Scope",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 1,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-01T07:15:27.107Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "974",
            name: "Hello bug Reporter",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "48",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-01T07:12:58.997Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "973",
            name: "bug reporter",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "48",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-01T07:11:30.839Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "972",
            name: "Test bug 1",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-01T07:09:31.490Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "971",
            name: "Rest Reporter ",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "48",
            reporterId: "113",
            description: "123",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-01T07:03:24.976Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "970",
            name: "Hello 1",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-07-01T06:05:42.335Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "890",
            name: "test waddle it 2",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: "2023-06-13T23:42:10.902Z",
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "48",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-06-12T23:42:19.327Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "889",
            name: "test waddle it  1",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 3,
            dueDate: "2023-06-11T23:41:30.103Z",
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "48",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "818",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-06-12T23:41:38.958Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "884",
            name: "waddle it ",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 3,
            dueDate: "2023-06-06T02:49:10.226Z",
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "136",
            reporterId: "48",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments:
              '[{"name":"Screenshot 2023-04-20 at 1.44.36 AM.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Screenshot_2023_04_20_at_1_liiuckrq.png-1685968894616.png?download=1","size":2460701,"type":"image/png","lastModified":1681935288704,"thumbnail":"/images/thumbnails/file.svg"}]',
            phaseId: "817",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "2h",
            createdAt: "2023-06-05T02:49:14.405Z",
            componentName: null,
            timeLogged: "1w 2d 1h ",
            linkedGoalId: null,
            labelId: "6",
            releaseVersionId: "3",
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "883",
            name: "hudjkvsndjkv",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: null,
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-06-05T02:47:52.771Z",
            componentName: null,
            timeLogged: "-1w -1d -1h ",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "847",
            name: "Chill Time",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "2",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "We will Chill ",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: null,
            phaseId: "1",
            meetId: "tvmpo7fml8bs1gk241gila62p0",
            meetLink: "https://meet.google.com/nwx-ygpz-cpq",
            meetMetaData:
              '{"kind":"calendar#event","etag":"\\"3360502023113000\\"","id":"tvmpo7fml8bs1gk241gila62p0","status":"confirmed","htmlLink":"https://www.google.com/calendar/event?eid=dHZtcG83Zm1sOGJzMWdrMjQxZ2lsYTYycDAgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t","created":"2023-03-31T08:23:30.000Z","updated":"2023-03-31T08:23:32.194Z","summary":"Chill Time","description":"We will Chill ","location":"MUmbai ","creator":{"email":"ganesh@antarcticaglobal.com","self":true},"organizer":{"email":"ganesh@antarcticaglobal.com","self":true},"start":{"dateTime":"2023-03-31T15:53:00+05:30","timeZone":"Asia/Kolkata"},"end":{"dateTime":"2023-03-31T21:53:10+05:30","timeZone":"Asia/Kolkata"},"iCalUID":"tvmpo7fml8bs1gk241gila62p0@google.com","sequence":0,"attendees":[{"email":"kevin@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"preetinder@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"ganesh@antarcticaglobal.com","organizer":true,"self":true,"responseStatus":"needsAction"}],"hangoutLink":"https://meet.google.com/nwx-ygpz-cpq","conferenceData":{"createRequest":{"requestId":"5z26w8b7lfwa2hpj","conferenceSolutionKey":{"type":"hangoutsMeet"},"status":{"statusCode":"success"}},"entryPoints":[{"entryPointType":"video","uri":"https://meet.google.com/nwx-ygpz-cpq","label":"meet.google.com/nwx-ygpz-cpq"},{"entryPointType":"more","uri":"https://tel.meet/nwx-ygpz-cpq?pin=7590525011566","pin":"7590525011566"},{"regionCode":"US","entryPointType":"phone","uri":"tel:+1-252-404-1160","label":"+1 252-404-1160","pin":"753408721"}],"conferenceSolution":{"key":{"type":"hangoutsMeet"},"name":"Google Meet","iconUri":"https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"},"conferenceId":"nwx-ygpz-cpq"},"reminders":{"useDefault":false,"overrides":[{"method":"popup","minutes":10},{"method":"email","minutes":1440}]},"eventType":"default"}',
            estimatedTime: null,
            createdAt: "2023-03-31T02:53:34.591Z",
            componentName: null,
            timeLogged: null,
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "846",
            name: "Chill TIme",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "2",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "fsvsfv",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: null,
            phaseId: "1",
            meetId: "45fk2r0mpjid544oq8juf3dh68",
            meetLink: null,
            meetMetaData:
              '{"kind":"calendar#event","etag":"\\"3360501771840000\\"","id":"45fk2r0mpjid544oq8juf3dh68","status":"confirmed","htmlLink":"https://www.google.com/calendar/event?eid=NDVmazJyMG1wamlkNTQ0b3E4anVmM2RoNjggZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t","created":"2023-03-31T08:21:25.000Z","updated":"2023-03-31T08:21:25.920Z","summary":"Chill TIme","description":"fsvsfv","location":"Mumbai","creator":{"email":"ganesh@antarcticaglobal.com","self":true},"organizer":{"email":"ganesh@antarcticaglobal.com","self":true},"start":{"dateTime":"2023-03-31T15:00:00+05:30","timeZone":"Asia/Kolkata"},"end":{"dateTime":"2023-03-31T19:51:05+05:30","timeZone":"Asia/Kolkata"},"iCalUID":"45fk2r0mpjid544oq8juf3dh68@google.com","sequence":0,"attendees":[{"email":"kevin@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"preetinder@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"ganesh@antarcticaglobal.com","organizer":true,"self":true,"responseStatus":"needsAction"}],"reminders":{"useDefault":false,"overrides":[{"method":"email","minutes":1440},{"method":"popup","minutes":10}]},"eventType":"default"}',
            estimatedTime: null,
            createdAt: "2023-03-31T02:51:28.456Z",
            componentName: null,
            timeLogged: null,
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "845",
            name: "test",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "2",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "svhv",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: null,
            attachments: null,
            phaseId: "1",
            meetId: "gc257suq3fojlk0qc3sa6vdhp8",
            meetLink: "https://meet.google.com/xfi-rixi-jyo",
            meetMetaData:
              '{"kind":"calendar#event","etag":"\\"3360500807516000\\"","id":"gc257suq3fojlk0qc3sa6vdhp8","status":"confirmed","htmlLink":"https://www.google.com/calendar/event?eid=Z2MyNTdzdXEzZm9qbGswcWMzc2E2dmRocDggZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t","created":"2023-03-31T08:13:23.000Z","updated":"2023-03-31T08:13:24.280Z","summary":"test","description":"svhv","location":"mumabi","creator":{"email":"ganesh@antarcticaglobal.com","self":true},"organizer":{"email":"ganesh@antarcticaglobal.com","self":true},"start":{"dateTime":"2023-03-31T13:43:00+05:30","timeZone":"Asia/Kolkata"},"end":{"dateTime":"2023-03-31T13:52:05+05:30","timeZone":"Asia/Kolkata"},"iCalUID":"gc257suq3fojlk0qc3sa6vdhp8@google.com","sequence":0,"attendees":[{"email":"kevin@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"ganesh@antarcticaglobal.com","organizer":true,"self":true,"responseStatus":"needsAction"}],"hangoutLink":"https://meet.google.com/xfi-rixi-jyo","conferenceData":{"createRequest":{"requestId":"5z26w8b7lfw9pgs5","conferenceSolutionKey":{"type":"hangoutsMeet"},"status":{"statusCode":"success"}},"entryPoints":[{"entryPointType":"video","uri":"https://meet.google.com/xfi-rixi-jyo","label":"meet.google.com/xfi-rixi-jyo"},{"entryPointType":"more","uri":"https://tel.meet/xfi-rixi-jyo?pin=1780429240315","pin":"1780429240315"},{"regionCode":"US","entryPointType":"phone","uri":"tel:+1-515-518-4413","label":"+1 515-518-4413","pin":"448324621"}],"conferenceSolution":{"key":{"type":"hangoutsMeet"},"name":"Google Meet","iconUri":"https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"},"conferenceId":"xfi-rixi-jyo"},"reminders":{"useDefault":false,"overrides":[{"method":"email","minutes":1440},{"method":"popup","minutes":10}]},"eventType":"default"}',
            estimatedTime: null,
            createdAt: "2023-03-31T02:43:26.795Z",
            componentName: null,
            timeLogged: null,
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "705",
            name: "hello 2",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-22T02:33:01.034Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: "P1-67",
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "704",
            name: "hello",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-22T02:32:37.854Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: "P1-66",
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "703",
            name: "7678",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-22T02:31:17.075Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: "P1-641",
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "702",
            name: "frfrfr",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-22T02:29:10.622Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: "P1-7011",
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "701",
            name: "test 2",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-22T02:27:47.517Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: "P1-1",
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "700",
            name: "test linkid 1",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-22T02:25:50.889Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: "P1-1",
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "679",
            name: "hello",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "114",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-13T07:08:15.172Z",
            componentName: "",
            timeLogged: "1h ",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "678",
            name: "&lt;dfvsd>csvsd",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "114",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-13T07:04:40.536Z",
            componentName: "",
            timeLogged: "2h ",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "677",
            name: "&lt;rr>dsvsv",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "114",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-13T07:04:10.025Z",
            componentName: "",
            timeLogged: "1h ",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "665",
            name: "csdcs",
            isCompleted: 0,
            statusId: 2,
            componentId: "0",
            priorityId: 2,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "48",
            reporterId: "48",
            description: "",
            swimlaneId: "57",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-07T13:32:43.538Z",
            componentName: "",
            timeLogged: "1h ",
            linkedGoalId: null,
            labelId: "6",
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "664",
            name: "dcsdc",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "114",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-07T13:31:49.123Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "662",
            name: "Reporter bug Testing",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "114",
            categoryId: "1",
            dependencyId: "1",
            environment: "",
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-07T13:27:55.339Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "660",
            name: "Helloo",
            isCompleted: 0,
            statusId: 1,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "48",
            description: "",
            swimlaneId: "114",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-07T13:14:22.380Z",
            componentName: "",
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "649",
            name: "This is reaaallllly Log titleeee This is reaaallllly Log titleeee This is reaaallllly Log titleeee ",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "114",
            categoryId: "1",
            dependencyId: "1",
            environment: "",
            attachments: "[]",
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-06T04:08:26.706Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "629",
            name: "Bug 3",
            isCompleted: 0,
            statusId: 1,
            componentId: null,
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "114",
            categoryId: "1",
            dependencyId: "1",
            environment: "",
            attachments:
              '[{"name":"java_ldokvxq7.png-1675431887045.png_download=1 (2).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ldokvxq7_ldon6lhw.png-1675435743595.png?download=1","size":47826,"type":"image/png","lastModified":1675432292462}]',
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-01T04:14:04.456Z",
            componentName: null,
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "628",
            name: "Bug 2",
            isCompleted: 0,
            statusId: 2,
            componentId: "2",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "115",
            categoryId: "1",
            dependencyId: "1",
            environment: "",
            attachments:
              '[{"name":"arrow_right_ldmqmlrm.svg-1675320597022.svg_download=1 (1).svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/arrow_right_ldmqmlrm_ldoiybd7.svg-1675428638802.svg?download=1","size":281,"type":"image/svg+xml","lastModified":1675321668657}]',
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-01T04:09:01.710Z",
            componentName: "Next JS",
            timeLogged: "1h ",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "627",
            name: "Bug 1 check again",
            isCompleted: 0,
            statusId: 1,
            componentId: "1",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "3",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "113",
            reporterId: "113",
            description: "",
            swimlaneId: "56",
            categoryId: "1",
            dependencyId: "1",
            environment: "Vault",
            attachments:
              '[{"name":"animation-frosty-jr.mp4","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/animation_frosty_jr_lefb7iyu.mp4-1677048218383.mp4?download=1","size":836892,"type":"video/mp4","lastModified":1671093770660,"thumbnail":"/images/thumbnails/file.svg"},{"name":"Group 20168@2x.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Group_20168_2x_lefadoyy.png-1677046826467.png?download=1","size":21458,"type":"image/png","lastModified":1671093769397,"thumbnail":"/images/thumbnails/file.svg","uid":"__AUTO__1677048194240_0__"},{"name":"animation-frosty-jr.mp4","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/animation_frosty_jr_lefa3dpj.mp4-1677046345357.mp4?download=1","size":836892,"type":"video/mp4","lastModified":1671093770660,"thumbnail":"/images/thumbnails/file.svg","uid":"__AUTO__1677046389974_0__"}]',
            phaseId: "3",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "",
            createdAt: "2023-02-01T04:07:59.529Z",
            componentName: "React JS",
            timeLogged: "",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
          {
            id: "617",
            name: "Task 1",
            isCompleted: 0,
            statusId: 2,
            componentId: "0",
            priorityId: 1,
            dueDate: null,
            isNewGoal: 0,
            isActive: 1,
            projectId: "345",
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            goalType: "1",
            parentGoalId: null,
            sprintId: "2468",
            assigneeId: "136",
            reporterId: "113",
            description: "",
            swimlaneId: "57",
            categoryId: "0",
            dependencyId: "1",
            environment: null,
            attachments:
              '[{"name":"java_ldokvxq7.png-1675431887045.png_download=1 (1).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ldokvxq7_ldon4jiv.png-1675435647795.png?download=1","size":47826,"type":"image/png","lastModified":1675432011123},{"name":"Polygon 3 (1).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Polygon_3_1_ldon4yao.png-1675435666886.png?download=1","size":47598,"type":"image/png","lastModified":1675062132614},{"name":"Polygon 3 (2).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Polygon_3_2_ldon4yar.png-1675435666896.png?download=1","size":72052,"type":"image/png","lastModified":1675062142310},{"name":"Polygon 3 (3).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Polygon_3_3_ldon4yat.png-1675435666891.png?download=1","size":15431,"type":"image/png","lastModified":1675062161688}]',
            phaseId: "2",
            meetId: null,
            meetLink: null,
            meetMetaData: null,
            estimatedTime: "1h",
            createdAt: "2023-01-31T04:41:41.745Z",
            componentName: "",
            timeLogged: "-1w -1d -1h ",
            linkedGoalId: null,
            labelId: null,
            releaseVersionId: null,
            projectPhaseId: null,
            identifier: null,
          },
        ],
      },
      {
        id: 2406,
        name: "Test 1",
        isCompleted: 0,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "3",
        startDate: "2023-01-15T18:30:00.000Z",
        endDate: "2023-01-30T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 1,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-01-16T02:03:33.241Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "19",
      },
      {
        id: 2469,
        name: "new not started",
        isCompleted: 0,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "818",
        startDate: "2023-07-05T18:30:00.000Z",
        endDate: "2023-07-11T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 1,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-07-06T01:44:38.577Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "0",
      },
      {
        id: 2470,
        name: "Test ",
        isCompleted: 0,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "818",
        startDate: "2023-07-10T18:30:00.000Z",
        endDate: "2023-07-11T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 1,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-07-11T01:30:42.174Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "0",
      },
      {
        id: 2471,
        name: "Xyz ",
        isCompleted: 0,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "818",
        startDate: "2023-07-10T18:30:00.000Z",
        endDate: "2023-07-11T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 1,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-07-11T01:31:11.583Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "0",
      },
      {
        id: 2472,
        name: "sprint in ideation 1",
        isCompleted: 0,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "818",
        startDate: "2023-07-12T18:30:00.000Z",
        endDate: "2023-07-19T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 1,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-07-13T02:12:09.978Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "0",
      },
      {
        id: 2473,
        name: "test from here ",
        isCompleted: 0,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "818",
        startDate: "2023-07-12T18:30:00.000Z",
        endDate: "2023-07-19T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 1,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-07-13T02:12:38.542Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "0",
      },
      {
        id: 2474,
        name: "test form where",
        isCompleted: 0,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "818",
        startDate: "2023-07-12T18:30:00.000Z",
        endDate: "2023-07-20T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 1,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-07-13T02:13:07.199Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "0",
      },
      {
        id: 2475,
        name: "Hello ",
        isCompleted: 0,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "818",
        startDate: "2023-07-16T18:30:00.000Z",
        endDate: "2023-07-18T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 1,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-07-17T00:27:10.826Z",
        linkedSprintId: null,
        identifier: "PX-11",
        goalCount: "0",
      },
      {
        id: 2403,
        name: "sprint 1",
        isCompleted: 1,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "1",
        startDate: "2023-01-11T18:30:00.000Z",
        endDate: "2023-01-25T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 3,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-01-12T05:42:39.265Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "0",
      },
      {
        id: 2404,
        name: "sprint 2",
        isCompleted: 1,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "2",
        startDate: "2023-01-31T18:30:00.000Z",
        endDate: "2023-02-21T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 3,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-01-12T05:43:30.494Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "4",
      },
      {
        id: 2405,
        name: "nbkj 123",
        isCompleted: 1,
        isNewSprint: 0,
        isActive: 1,
        projectId: "345",
        phaseId: "3",
        startDate: "2023-01-15T18:30:00.000Z",
        endDate: "2023-01-30T18:30:00.000Z",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        status: 3,
        isOngoing: 0,
        createdById: "113",
        createdAt: "2023-01-16T01:57:41.934Z",
        linkedSprintId: null,
        identifier: null,
        goalCount: "2",
      },
    ],
    allSprints: [],
    goals: [
      {
        id: "1040",
        name: "waddle it bug",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 4,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-19T06:50:08.007Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: "PX-111",
      },
      {
        id: "1038",
        name: "hello new",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "136",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments:
          '[{"name":"🦆 emoji _love letter_.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8guwg9.png-1689695137763.png?download=1","size":595,"type":"image/png","lastModified":1673085186497,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8guwgd.svg-1689695137765.svg?download=1","size":2161,"type":"image/svg+xml","lastModified":1673080818081,"thumbnail":"/images/thumbnails/file.svg"},{"name":"01 1.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/01_1_lk8guwgh.png-1689695137766.png?download=1","size":2923,"type":"image/png","lastModified":1673082964314,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8gv52v.svg-1689695148930.svg?download=1","size":2161,"type":"image/svg+xml","lastModified":1673080818081,"thumbnail":"/images/thumbnails/file.svg"}]',
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-18T10:16:00.445Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: "PX-110",
      },
      {
        id: "1035",
        name: "new ",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "136",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-17T00:08:47.995Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: "PX-109",
      },
      {
        id: "1034",
        name: "hello id check",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "136",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-17T00:07:51.906Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: "PX_108",
      },
      {
        id: "1031",
        name: "sdcvsdvs",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "264",
        reporterId: "136",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-16T10:12:29.747Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: "P_107",
      },
      {
        id: "1030",
        name: "cz zcs",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "136",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-16T10:05:53.729Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: "P_106",
      },
      {
        id: "1029",
        name: "hgvghvgh",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "136",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-16T09:19:59.257Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: "P_105",
      },
      {
        id: "1028",
        name: "bhvhj",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-16T09:19:14.670Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: "P_104",
      },
      {
        id: "1025",
        name: "notif",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "136",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-13T04:44:49.619Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1024",
        name: "hi",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "136",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments:
          '[{"name":"file.mp4","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/file_lk2990ws.mp4-1689319562871.mp4?download=1","size":2233489,"type":"video/mp4","lastModified":1672749446278,"thumbnail":"/images/thumbnails/file.svg"},{"name":"Asset (1).csv","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Asset_1_lk2986u1.csv-1689319523783.csv?download=1","size":12806,"type":"text/csv","lastModified":1675235118371,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk2905ui.png-1689319149226.png?download=1","size":595,"type":"image/png","lastModified":1673085186497,"thumbnail":"/images/thumbnails/file.svg"}]',
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-13T04:43:13.714Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1022",
        name: "waddle emails",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 4,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-13T04:38:47.893Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1020",
        name: "waddle emails",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 4,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-13T02:54:34.939Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1019",
        name: "hello",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: null,
        description: null,
        swimlaneId: "149",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-07T11:41:57.455Z",
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: "6",
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1018",
        name: "hello",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 3,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "136",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-07T11:40:21.349Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1017",
        name: "Hello",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: null,
        description: null,
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-07T11:38:32.617Z",
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1016",
        name: "hello ",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: null,
        description: null,
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-07T11:24:38.163Z",
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1015",
        name: "hell",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: null,
        description: null,
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-07T11:23:40.231Z",
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1013",
        name: "test new copy",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-02T06:07:06.008Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "1002",
        name: "waddwl",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "264",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-04T00:54:20.578Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "999",
        name: "test",
        isCompleted: 0,
        statusId: 2,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: null,
        description: null,
        swimlaneId: "57",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-02T07:10:57.739Z",
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "998",
        name: "test 3",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "1h",
        createdAt: "2023-07-02T06:10:41.549Z",
        componentName: null,
        timeLogged: "2h ",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "995",
        name: "test new",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-02T06:07:06.008Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "978",
        name: "task 11",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-01T03:56:34.733Z",
        componentName: "",
        timeLogged: "2h 10m ",
        linkedGoalId: "626",
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "977",
        name: "This is reaaallllly Log titleeee This is reaaallllly Log titleeee This is reaaallllly Log titleeee ",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "114",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-06T04:08:52.876Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: "650",
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "976",
        name: "Reporter Task Testing",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "114",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-07T13:27:12.340Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: "661",
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "975",
        name: "New Scope",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 1,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-01T07:15:27.107Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "974",
        name: "Hello bug Reporter",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-01T07:12:58.997Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "973",
        name: "bug reporter",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-01T07:11:30.839Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "972",
        name: "Test bug 1",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-01T07:09:31.490Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "971",
        name: "Rest Reporter ",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "113",
        description: "123",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-01T07:03:24.976Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "970",
        name: "Hello 1",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-07-01T06:05:42.335Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "890",
        name: "test waddle it 2",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: "2023-06-13T23:42:10.902Z",
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-06-12T23:42:19.327Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "889",
        name: "test waddle it  1",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 3,
        dueDate: "2023-06-11T23:41:30.103Z",
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-06-12T23:41:38.958Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "884",
        name: "waddle it ",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 3,
        dueDate: "2023-06-06T02:49:10.226Z",
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "136",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments:
          '[{"name":"Screenshot 2023-04-20 at 1.44.36 AM.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Screenshot_2023_04_20_at_1_liiuckrq.png-1685968894616.png?download=1","size":2460701,"type":"image/png","lastModified":1681935288704,"thumbnail":"/images/thumbnails/file.svg"}]',
        phaseId: "817",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "2h",
        createdAt: "2023-06-05T02:49:14.405Z",
        componentName: null,
        timeLogged: "1w 2d 1h ",
        linkedGoalId: null,
        labelId: "6",
        releaseVersionId: "3",
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "883",
        name: "hudjkvsndjkv",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-06-05T02:47:52.771Z",
        componentName: null,
        timeLogged: "-1w -1d -1h ",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "847",
        name: "Chill Time",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "2",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "We will Chill ",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: null,
        phaseId: "1",
        meetId: "tvmpo7fml8bs1gk241gila62p0",
        meetLink: "https://meet.google.com/nwx-ygpz-cpq",
        meetMetaData:
          '{"kind":"calendar#event","etag":"\\"3360502023113000\\"","id":"tvmpo7fml8bs1gk241gila62p0","status":"confirmed","htmlLink":"https://www.google.com/calendar/event?eid=dHZtcG83Zm1sOGJzMWdrMjQxZ2lsYTYycDAgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t","created":"2023-03-31T08:23:30.000Z","updated":"2023-03-31T08:23:32.194Z","summary":"Chill Time","description":"We will Chill ","location":"MUmbai ","creator":{"email":"ganesh@antarcticaglobal.com","self":true},"organizer":{"email":"ganesh@antarcticaglobal.com","self":true},"start":{"dateTime":"2023-03-31T15:53:00+05:30","timeZone":"Asia/Kolkata"},"end":{"dateTime":"2023-03-31T21:53:10+05:30","timeZone":"Asia/Kolkata"},"iCalUID":"tvmpo7fml8bs1gk241gila62p0@google.com","sequence":0,"attendees":[{"email":"kevin@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"preetinder@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"ganesh@antarcticaglobal.com","organizer":true,"self":true,"responseStatus":"needsAction"}],"hangoutLink":"https://meet.google.com/nwx-ygpz-cpq","conferenceData":{"createRequest":{"requestId":"5z26w8b7lfwa2hpj","conferenceSolutionKey":{"type":"hangoutsMeet"},"status":{"statusCode":"success"}},"entryPoints":[{"entryPointType":"video","uri":"https://meet.google.com/nwx-ygpz-cpq","label":"meet.google.com/nwx-ygpz-cpq"},{"entryPointType":"more","uri":"https://tel.meet/nwx-ygpz-cpq?pin=7590525011566","pin":"7590525011566"},{"regionCode":"US","entryPointType":"phone","uri":"tel:+1-252-404-1160","label":"+1 252-404-1160","pin":"753408721"}],"conferenceSolution":{"key":{"type":"hangoutsMeet"},"name":"Google Meet","iconUri":"https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"},"conferenceId":"nwx-ygpz-cpq"},"reminders":{"useDefault":false,"overrides":[{"method":"popup","minutes":10},{"method":"email","minutes":1440}]},"eventType":"default"}',
        estimatedTime: null,
        createdAt: "2023-03-31T02:53:34.591Z",
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "846",
        name: "Chill TIme",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "2",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "fsvsfv",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: null,
        phaseId: "1",
        meetId: "45fk2r0mpjid544oq8juf3dh68",
        meetLink: null,
        meetMetaData:
          '{"kind":"calendar#event","etag":"\\"3360501771840000\\"","id":"45fk2r0mpjid544oq8juf3dh68","status":"confirmed","htmlLink":"https://www.google.com/calendar/event?eid=NDVmazJyMG1wamlkNTQ0b3E4anVmM2RoNjggZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t","created":"2023-03-31T08:21:25.000Z","updated":"2023-03-31T08:21:25.920Z","summary":"Chill TIme","description":"fsvsfv","location":"Mumbai","creator":{"email":"ganesh@antarcticaglobal.com","self":true},"organizer":{"email":"ganesh@antarcticaglobal.com","self":true},"start":{"dateTime":"2023-03-31T15:00:00+05:30","timeZone":"Asia/Kolkata"},"end":{"dateTime":"2023-03-31T19:51:05+05:30","timeZone":"Asia/Kolkata"},"iCalUID":"45fk2r0mpjid544oq8juf3dh68@google.com","sequence":0,"attendees":[{"email":"kevin@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"preetinder@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"ganesh@antarcticaglobal.com","organizer":true,"self":true,"responseStatus":"needsAction"}],"reminders":{"useDefault":false,"overrides":[{"method":"email","minutes":1440},{"method":"popup","minutes":10}]},"eventType":"default"}',
        estimatedTime: null,
        createdAt: "2023-03-31T02:51:28.456Z",
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "845",
        name: "test",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "2",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "svhv",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: null,
        phaseId: "1",
        meetId: "gc257suq3fojlk0qc3sa6vdhp8",
        meetLink: "https://meet.google.com/xfi-rixi-jyo",
        meetMetaData:
          '{"kind":"calendar#event","etag":"\\"3360500807516000\\"","id":"gc257suq3fojlk0qc3sa6vdhp8","status":"confirmed","htmlLink":"https://www.google.com/calendar/event?eid=Z2MyNTdzdXEzZm9qbGswcWMzc2E2dmRocDggZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t","created":"2023-03-31T08:13:23.000Z","updated":"2023-03-31T08:13:24.280Z","summary":"test","description":"svhv","location":"mumabi","creator":{"email":"ganesh@antarcticaglobal.com","self":true},"organizer":{"email":"ganesh@antarcticaglobal.com","self":true},"start":{"dateTime":"2023-03-31T13:43:00+05:30","timeZone":"Asia/Kolkata"},"end":{"dateTime":"2023-03-31T13:52:05+05:30","timeZone":"Asia/Kolkata"},"iCalUID":"gc257suq3fojlk0qc3sa6vdhp8@google.com","sequence":0,"attendees":[{"email":"kevin@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"ganesh@antarcticaglobal.com","organizer":true,"self":true,"responseStatus":"needsAction"}],"hangoutLink":"https://meet.google.com/xfi-rixi-jyo","conferenceData":{"createRequest":{"requestId":"5z26w8b7lfw9pgs5","conferenceSolutionKey":{"type":"hangoutsMeet"},"status":{"statusCode":"success"}},"entryPoints":[{"entryPointType":"video","uri":"https://meet.google.com/xfi-rixi-jyo","label":"meet.google.com/xfi-rixi-jyo"},{"entryPointType":"more","uri":"https://tel.meet/xfi-rixi-jyo?pin=1780429240315","pin":"1780429240315"},{"regionCode":"US","entryPointType":"phone","uri":"tel:+1-515-518-4413","label":"+1 515-518-4413","pin":"448324621"}],"conferenceSolution":{"key":{"type":"hangoutsMeet"},"name":"Google Meet","iconUri":"https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"},"conferenceId":"xfi-rixi-jyo"},"reminders":{"useDefault":false,"overrides":[{"method":"email","minutes":1440},{"method":"popup","minutes":10}]},"eventType":"default"}',
        estimatedTime: null,
        createdAt: "2023-03-31T02:43:26.795Z",
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "705",
        name: "hello 2",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-22T02:33:01.034Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: "P1-67",
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "704",
        name: "hello",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-22T02:32:37.854Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: "P1-66",
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "703",
        name: "7678",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-22T02:31:17.075Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: "P1-641",
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "702",
        name: "frfrfr",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-22T02:29:10.622Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: "P1-7011",
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "701",
        name: "test 2",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-22T02:27:47.517Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: "P1-1",
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "700",
        name: "test linkid 1",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-22T02:25:50.889Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: "P1-1",
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "679",
        name: "hello",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "114",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-13T07:08:15.172Z",
        componentName: "",
        timeLogged: "1h ",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "678",
        name: "&lt;dfvsd>csvsd",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "114",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-13T07:04:40.536Z",
        componentName: "",
        timeLogged: "2h ",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "677",
        name: "&lt;rr>dsvsv",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "114",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-13T07:04:10.025Z",
        componentName: "",
        timeLogged: "1h ",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "665",
        name: "csdcs",
        isCompleted: 0,
        statusId: 2,
        componentId: "0",
        priorityId: 2,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "48",
        description: "",
        swimlaneId: "57",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-07T13:32:43.538Z",
        componentName: "",
        timeLogged: "1h ",
        linkedGoalId: null,
        labelId: "6",
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "664",
        name: "dcsdc",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "114",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-07T13:31:49.123Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "662",
        name: "Reporter bug Testing",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "114",
        categoryId: "1",
        dependencyId: "1",
        environment: "",
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-07T13:27:55.339Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "660",
        name: "Helloo",
        isCompleted: 0,
        statusId: 1,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "48",
        description: "",
        swimlaneId: "114",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-07T13:14:22.380Z",
        componentName: "",
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "649",
        name: "This is reaaallllly Log titleeee This is reaaallllly Log titleeee This is reaaallllly Log titleeee ",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "114",
        categoryId: "1",
        dependencyId: "1",
        environment: "",
        attachments: "[]",
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-06T04:08:26.706Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "629",
        name: "Bug 3",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "114",
        categoryId: "1",
        dependencyId: "1",
        environment: "",
        attachments:
          '[{"name":"java_ldokvxq7.png-1675431887045.png_download=1 (2).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ldokvxq7_ldon6lhw.png-1675435743595.png?download=1","size":47826,"type":"image/png","lastModified":1675432292462}]',
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-01T04:14:04.456Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "628",
        name: "Bug 2",
        isCompleted: 0,
        statusId: 2,
        componentId: "2",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "115",
        categoryId: "1",
        dependencyId: "1",
        environment: "",
        attachments:
          '[{"name":"arrow_right_ldmqmlrm.svg-1675320597022.svg_download=1 (1).svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/arrow_right_ldmqmlrm_ldoiybd7.svg-1675428638802.svg?download=1","size":281,"type":"image/svg+xml","lastModified":1675321668657}]',
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-01T04:09:01.710Z",
        componentName: "Next JS",
        timeLogged: "1h ",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "627",
        name: "Bug 1 check again",
        isCompleted: 0,
        statusId: 1,
        componentId: "1",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "3",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "113",
        reporterId: "113",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: "Vault",
        attachments:
          '[{"name":"animation-frosty-jr.mp4","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/animation_frosty_jr_lefb7iyu.mp4-1677048218383.mp4?download=1","size":836892,"type":"video/mp4","lastModified":1671093770660,"thumbnail":"/images/thumbnails/file.svg"},{"name":"Group 20168@2x.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Group_20168_2x_lefadoyy.png-1677046826467.png?download=1","size":21458,"type":"image/png","lastModified":1671093769397,"thumbnail":"/images/thumbnails/file.svg","uid":"__AUTO__1677048194240_0__"},{"name":"animation-frosty-jr.mp4","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/animation_frosty_jr_lefa3dpj.mp4-1677046345357.mp4?download=1","size":836892,"type":"video/mp4","lastModified":1671093770660,"thumbnail":"/images/thumbnails/file.svg","uid":"__AUTO__1677046389974_0__"}]',
        phaseId: "3",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-02-01T04:07:59.529Z",
        componentName: "React JS",
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "617",
        name: "Task 1",
        isCompleted: 0,
        statusId: 2,
        componentId: "0",
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: "345",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "136",
        reporterId: "113",
        description: "",
        swimlaneId: "57",
        categoryId: "0",
        dependencyId: "1",
        environment: null,
        attachments:
          '[{"name":"java_ldokvxq7.png-1675431887045.png_download=1 (1).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ldokvxq7_ldon4jiv.png-1675435647795.png?download=1","size":47826,"type":"image/png","lastModified":1675432011123},{"name":"Polygon 3 (1).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Polygon_3_1_ldon4yao.png-1675435666886.png?download=1","size":47598,"type":"image/png","lastModified":1675062132614},{"name":"Polygon 3 (2).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Polygon_3_2_ldon4yar.png-1675435666896.png?download=1","size":72052,"type":"image/png","lastModified":1675062142310},{"name":"Polygon 3 (3).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Polygon_3_3_ldon4yat.png-1675435666891.png?download=1","size":15431,"type":"image/png","lastModified":1675062161688}]',
        phaseId: "2",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "1h",
        createdAt: "2023-01-31T04:41:41.745Z",
        componentName: "",
        timeLogged: "-1w -1d -1h ",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
    ],
    selectedTask: null,
    selectedBug: null,
    selectedSubTask: null,
    selectedEvent: null,
    sprintDetailsModal: false,
    deleteSubTaskModal: false,
    newTaskModal: {
      visible: false,
      isNewScope: false,
      scopeDescription: "",
    },
    newBugModal: false,
    newSubTaskModal: {
      visible: false,
      editTask: false,
      subtask: {
        sprintId: "",
        projectId: "",
        phaseId: "",
        names: [],
        isCompleted: false,
        statusId: "",
        priorityId: "",
        goalType: "",
        assigneeId: "",
        reporterId: "",
        description: "",
        parentGoalId: "",
        goalId: "",
        parentTaskName: "Task",
      },
    },
    newEventModal: false,
    deleteTaskModal: {
      visible: false,
      statusId: 0,
      goalId: 1,
    },
    deleteBugModal: {
      visible: false,
      statusId: 0,
      goalId: 1,
    },
    deleteStatusModal: false,
    swimlanes: [
      {
        id: "56",
        projectId: "345",
        statusId: "1",
        title: "To Do",
        tenantId: null,
        positionIndex: "1",
      },
      {
        id: "57",
        projectId: "345",
        statusId: "2",
        title: "In Progress",
        tenantId: null,
        positionIndex: "2",
      },
      {
        id: "149",
        projectId: "345",
        statusId: "1",
        title: "test 1",
        tenantId: null,
        positionIndex: "3",
      },
      {
        id: "150",
        projectId: "345",
        statusId: "2",
        title: "test 2",
        tenantId: null,
        positionIndex: "4",
      },
      {
        id: "114",
        projectId: "345",
        statusId: "1",
        title: "Hello",
        tenantId: null,
        positionIndex: "5",
      },
      {
        id: "115",
        projectId: "345",
        statusId: "2",
        title: "Test New",
        tenantId: null,
        positionIndex: "6",
      },
      {
        id: "58",
        projectId: "345",
        statusId: "3",
        title: "Done",
        tenantId: null,
        positionIndex: "7",
      },
    ],
    swimlaneDetails: {
      id: 0,
      projectId: 0,
      statusId: 1,
      title: "To Do",
    },
    endPhaseModal: false,
    deleteSprintModal: {
      visible: false,
      sprintId: "",
    },
    completeSprintModal: {
      visible: false,
      sprintId: "",
      sprint: {
        name: "",
      },
    },
    filterGoalParameter: {
      searchValue: "",
      assignee: ["113", "48", "136", "264"],
      typeDropdown: [],
      filter: "",
    },
    createdSprintId: null,
    startSprintModal: false,
    startPhaseModal: false,
    newSprint: {},
    filters: {
      type: [],
      assignee: [],
      reporter: [],
      phase: [],
      priority: [],
      label: [],
      status: [],
    },
    duplicateTaskModal: {
      visible: false,
      duplicateTaskDetails: null,
    },
    editSprintModal: {
      visible: false,
      sprint: {
        id: "",
        name: "",
        startDate: "",
        endDate: "",
        phaseId: "",
      },
      mode: "new",
      phaseId: "",
    },
    newLabelModal: false,
    manageLabelModal: false,
    deleteLabelModal: {
      visible: false,
      labelId: "",
    },
    newReleaseVersionModal: false,
    sprintKanbanView: true,
    plannerIsDragActive: {
      isActive: false,
      current: null,
    },
    phaseModal: {
      visible: false,
      phaseId: "",
    },
    roadMapZoom: 160,
    roadMapView: "ganttView",
    roadMapPhaseId: 0,
    run: true,
    showTakeTourModal: false,
    currentTourStep: 0,
    labels: [],
    releaseVersions: [
      {
        id: 3,
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        versionName: "P1 v1.0",
        projectId: "345",
        startDate: "2023-06-06T04:07:38.000Z",
        releaseDate: "2023-06-13T04:07:40.517Z",
        description: "test release version",
        versionStatus: 0,
        associatedVersions: null,
        hasAssociatedVersions: 0,
      },
    ],
    taskActivity: [],
    reportsGroupBy: {
      key: "Date Range",
      value: ["2023-07-20T05:41:18.874Z", "2023-08-31T05:41:18.874Z"],
    },
    todoList: [],
    selectedTasksForDragAndDrop: [],
    showNewTaskCard: {
      visible: false,
      channel: {
        id: "",
      },
    },
    newTask: null,
  },
  vault: {
    loading: false,
    error: {},
    status: "idle",
    addFileModal: false,
    addFolderModal: false,
    preFilledVaultModalValues: {
      goalId: null,
      phaseId: null,
      users: [],
      notificationId: "",
    },
    addLinkModal: false,
    openCommentsModal: {
      visible: false,
      docType: "file",
      docId: "",
      name: "",
      comments: [],
      users: [],
    },
    deleteFolderModal: {
      visible: false,
      id: null,
    },
    deleteFileModal: {
      visible: false,
      id: null,
    },
    deleteLinkModal: {
      visible: false,
      id: null,
    },
    copyModal: {
      visible: false,
      docType: "",
      id: null,
      title: "",
    },
    moveToFolderModal: {
      visible: false,
      docId: "",
      docType: "",
    },
    requestAccessModal: {
      visible: false,
      title: "",
      subtext: "",
      data: {
        isProject: true,
        type: "folder",
        folder: {
          id: "",
          createdById: "",
          folderName: "",
        },
        file: {
          id: "",
          createdById: "",
          name: "",
        },
        link: {
          id: "",
          createdById: "",
          name: "",
        },
      },
    },
    requestUploadModal: false,
    downloadModal: {
      visible: false,
      type: "",
      id: null,
    },
    openLinkModal: {
      visible: false,
      id: null,
    },
    folders: [],
    subfolders: [],
    files: [],
    links: [],
    shareAccessDoc: {
      docId: "",
      users: [],
      docType: "",
      docDetails: {
        folderName: "",
        fileName: "",
        linkName: "",
        name: "",
      },
      notificationId: "",
    },
    showDocDetailsDrawer: {
      visible: false,
      docId: "",
      docType: "",
      docDetails: {},
    },
    transferOwnershipModal: {
      visible: false,
      id: null,
      docType: null,
      usersWithAccess: [],
    },
    openAndShareAccessFile: {
      visible: false,
      id: "",
    },
    mySpaceFolder: null,
    newFolderModal: false,
    newLinkModal: false,
    editLinkModal: {
      visible: false,
      linkName: "",
      url: "",
      username: "",
      password: "",
      description: "",
      linkId: "",
    },
    renameModal: {
      visible: false,
      currentName: "",
      type: "folder",
      id: "",
    },
    viewDetailsDrawer: {
      visible: false,
      documentType: "",
      document: {
        title: "",
        createdAt: "",
        usersWithAccess: [],
        fileSize: "",
        filesCount: "",
        docId: "",
        createdById: "",
        mimeType: "",
        folderSize: "",
      },
      title: "",
    },
    commentsDrawer: {
      visible: false,
      title: "",
    },
    shareModal: {
      visible: false,
      documentType: "",
      title: "",
    },
    shareAccessModal: {
      visible: false,
      docId: "",
      users: [],
      docType: "",
      docDetails: {
        folderName: "",
        fileName: "",
        linkName: "",
        name: "",
      },
      notificationId: "",
    },
    currentSelectedProject: {
      title: "",
      projectImage: "",
    },
    shareInMessageModal: {
      type: "folder",
      visible: false,
      url: "",
      file: [],
    },
    viewType: 1,
    search: "",
    currentSelectedFolder: {
      id: "0",
      folderName: "Test",
      canEdit: 1,
      canView: 1,
      isOwner: 1,
    },
  },

  dashboard: {
    loading: false,
    error: {},
    onGoingProjects: 0,
    completedProjects: 0,
    workspaces: [
      {
        id: "12",
        userId: "113",
        tenantId: "294e4afc-a45c-4bd7-9d04-b3a260daa62f",
        tenantHostname: "dcds",
        tenantName: "dcds",
        createdAt: "2022-12-21T04:21:07.102Z",
        updatedAt: null,
        sdgs: '["1","2","3"]',
        sectorsList: "[1]",
        tenantLocation: "San Diego",
        tenantSize: "1",
      },
      {
        id: "14",
        userId: "113",
        tenantId: "e43f0717-83ed-483a-8f98-3f3c89ab50ba",
        tenantHostname: "dccfsdfcsds",
        tenantName: "dccfsdfcsds",
        createdAt: "2022-12-21T04:22:37.715Z",
        updatedAt: null,
        sdgs: '["1","2","3"]',
        sectorsList: "[1]",
        tenantLocation: "San Diego",
        tenantSize: "1",
      },
      {
        id: "22",
        userId: "113",
        tenantId: "1d1d8b67-20e2-4b42-84a4-ccfb6e1165d3",
        tenantHostname: "test-final",
        tenantName: "test final",
        createdAt: "2022-12-22T00:55:25.189Z",
        updatedAt: null,
        sdgs: '["1","2"]',
        sectorsList: "[1]",
        tenantLocation: "Melbourne",
        tenantSize: "1",
      },
      {
        id: "25",
        userId: "113",
        tenantId: "39668235-4be3-4464-b171-8395f449b26f",
        tenantHostname: "test-final-final",
        tenantName: "test final final",
        createdAt: "2022-12-22T00:59:04.899Z",
        updatedAt: null,
        sdgs: '["1"]',
        sectorsList: "[1]",
        tenantLocation: "Melbourne",
        tenantSize: "1",
      },
      {
        id: "26",
        userId: "113",
        tenantId: "376e7dc0-4e8f-4316-9b8d-e3ec820c35b6",
        tenantHostname: "new-company-name-1",
        tenantName: "new company name 1",
        createdAt: "2022-12-26T00:13:26.778Z",
        updatedAt: null,
        sdgs: '["5","12","17","16"]',
        sectorsList: "[2,3]",
        tenantLocation: "Mumbai",
        tenantSize: "2",
      },
      {
        id: "28",
        userId: "113",
        tenantId: "5f113eac-15c4-497c-8265-a599bfe1b01c",
        tenantHostname: "new-company-name-2",
        tenantName: "new company name 2",
        createdAt: "2022-12-26T00:23:49.229Z",
        updatedAt: null,
        sdgs: '["5","12","17","16"]',
        sectorsList: "[2,3]",
        tenantLocation: "Mumbai",
        tenantSize: "2",
      },
      {
        id: "32",
        userId: "113",
        tenantId: "a3e6581d-13ac-4676-9421-bb868398d7b0",
        tenantHostname: "new-company-name-3",
        tenantName: "new company name 3",
        createdAt: "2022-12-26T00:25:34.875Z",
        updatedAt: null,
        sdgs: '["5","12","17","16"]',
        sectorsList: "[2,3]",
        tenantLocation: "Madrid",
        tenantSize: "3",
      },
      {
        id: "35",
        userId: "113",
        tenantId: "ffb7fc45-96ff-4049-9b64-a568a9208883",
        tenantHostname: "new-company-name-4",
        tenantName: "new company name 4",
        createdAt: "2022-12-26T00:37:09.263Z",
        updatedAt: null,
        sdgs: '["5","12","17","16"]',
        sectorsList: "[2,3]",
        tenantLocation: "Miami",
        tenantSize: "2",
      },
      {
        id: "38",
        userId: "113",
        tenantId: "04e09c3b-07da-47c8-a194-d6261416fb37",
        tenantHostname: "new-company-name-5",
        tenantName: "new company name 5",
        createdAt: "2022-12-26T00:39:04.854Z",
        updatedAt: null,
        sdgs: '["5","12","17","16"]',
        sectorsList: "[2,3]",
        tenantLocation: "Miami",
        tenantSize: "2",
      },
      {
        id: "41",
        userId: "113",
        tenantId: "0d241163-ac25-45cd-953a-743a2dacd434",
        tenantHostname: "c1",
        tenantName: "C1",
        createdAt: "2022-12-29T04:54:21.536Z",
        updatedAt: null,
        sdgs: '["9","12"]',
        sectorsList: "[3]",
        tenantLocation: "Chennai",
        tenantSize: "3",
      },
      {
        id: "43",
        userId: "113",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        tenantHostname: "c3",
        tenantName: "c3",
        createdAt: "2022-12-29T06:29:29.500Z",
        updatedAt: null,
        sdgs: '["10"]',
        sectorsList: "[1]",
        tenantLocation: "Melbourne",
        tenantSize: "2",
      },
      {
        id: "127",
        userId: "113",
        tenantId: "c05646e1-90d6-4e1d-bc2c-5b761164fb62",
        tenantHostname: "test",
        tenantName: "test",
        createdAt: "2023-02-02T02:09:30.183Z",
        updatedAt: null,
        sdgs: '["14"]',
        sectorsList: "[4]",
        tenantLocation: "Mumbai",
        tenantSize: "1",
      },
      {
        id: "128",
        userId: "113",
        tenantId: "238fa249-3003-446b-8054-6ea5d41d9bb9",
        tenantHostname: "mmm",
        tenantName: "mmm",
        createdAt: "2023-02-06T02:22:03.505Z",
        updatedAt: null,
        sdgs: '["10"]',
        sectorsList: "[2]",
        tenantLocation: "Mumbai",
        tenantSize: "1",
      },
      {
        id: "162",
        userId: "113",
        tenantId: "4072c041-3836-49be-b29c-bcd648435543",
        tenantHostname: "w",
        tenantName: "w",
        createdAt: "2023-07-07T05:28:06.203Z",
        updatedAt: null,
        sdgs: '["4","3"]',
        sectorsList: "[2]",
        tenantLocation: "Mumbai, Maharashtra, India",
        tenantSize: "2",
      },
      {
        id: "169",
        userId: "113",
        tenantId: "0a667565-f1b3-4ad1-9b11-4c0e197f1609",
        tenantHostname: "hgd",
        tenantName: "hgd",
        createdAt: "2023-07-18T01:03:11.165Z",
        updatedAt: null,
        sdgs: '["3","6"]',
        sectorsList: "[3]",
        tenantLocation: "Mumbai, Maharashtra, India",
        tenantSize: "2",
      },
      {
        id: "171",
        userId: "113",
        tenantId: "b33b4c23-19e9-48bf-bb8f-051572600198",
        tenantHostname: "invite",
        tenantName: "invite",
        createdAt: "2023-07-19T04:37:24.818Z",
        updatedAt: null,
        sdgs: '["4","6","12"]',
        sectorsList: "[2]",
        tenantLocation: "Mumbai, Maharashtra, India",
        tenantSize: "2",
      },
    ],
    todoList: [],
    myTasks: [],
    allProjectView: false,
    whiteBoardModal: false,
    checkListScreen: false,
    search: "",
    errorMessageModal: {
      visible: false,
      type: "project",
    },
    completeProjectModal: {
      visible: false,
      edit: false,
      action: "start",
      projectId: " ",
    },
    discardProjectModal: {
      visible: false,
      type: "discard",
      title: " ",
      id: " ",
    },
    alertPopupModal: {
      visible: false,
      data: {
        title: "",
        subtitle: "",
        description: "",
      },
    },
    newEventModal: {
      visible: false,
      startTime: "",
      endTime: "",
      date: "",
    },
    takeTourFromWelcomeCard: false,
    createRoleModal: false,
    dashboardWelcomeTourModal: false,
    completePhaseModal: {
      visible: false,
      edit: false,
      action: "start",
      phase: {
        phases: "Design",
        phaseId: 1,
        startDate: "",
        endDate: "",
        projectPhaseId: "",
        isNew: true,
      },
    },
    userDetailsDrawer: {
      visible: false,
      data: {},
    },
    completeProjectSuccessModal: false,
    messagesScreen: true,
    viewType: 1,
    filters: {
      lead: [],
      duration: [],
      status: [],
    },
    events: [
      {
        id: '21',
        userId: '113',
        eventId: '21',
        title: 'CFA Final Demo ',
        startTime: '2023-07-20T05:40:00.000Z',
        endTime: '2023-07-20T06:40:00.000Z',
        meetLink: 'https://meet.google.com/gwc-ibwg-ycj',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: 'r4gf7b7tbdnrse6ur9vophimjg',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379702302298000"',
          id: 'r4gf7b7tbdnrse6ur9vophimjg',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=cjRnZjdiN3RiZG5yc2U2dXI5dm9waGltamcgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T11:05:50.000Z',
          updated: '2023-07-20T11:05:52.043Z',
          summary: 'CFA Final Demo ',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T16:40:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T17:40:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: 'r4gf7b7tbdnrse6ur9vophimjg@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'ebrahim@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            },
            {
              email: 'anushka@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'nutan@antarcticaglobal.com',
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/gwc-ibwg-ycj',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb1qspg',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/gwc-ibwg-ycj',
                label: 'meet.google.com/gwc-ibwg-ycj'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/gwc-ibwg-ycj?pin=7483282713322',
                pin: '7483282713322'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-781-514-9163',
                label: '+1 781-514-9163',
                pin: '655952075'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'gwc-ibwg-ycj'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'popup',
                minutes: 10
              },
              {
                method: 'email',
                minutes: 1440
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      },
      {
        id: '22',
        userId: '113',
        eventId: '22',
        title: 'Test',
        startTime: '2023-07-20T07:00:00.000Z',
        endTime: '2023-07-20T07:30:00.000Z',
        meetLink: 'https://meet.google.com/izy-qoit-uud',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: 'ht7lbc6bias7gudsgo2ekddcfo',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379708920623000"',
          id: 'ht7lbc6bias7gudsgo2ekddcfo',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=aHQ3bGJjNmJpYXM3Z3Vkc2dvMmVrZGRjZm8gZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T12:00:59.000Z',
          updated: '2023-07-20T12:01:01.116Z',
          summary: 'Test',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T18:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T18:30:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: 'ht7lbc6bias7gudsgo2ekddcfo@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/izy-qoit-uud',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb3pq6b',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/izy-qoit-uud',
                label: 'meet.google.com/izy-qoit-uud'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/izy-qoit-uud?pin=5540874092180',
                pin: '5540874092180'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-609-469-1646',
                label: '+1 609-469-1646',
                pin: '624454471'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'izy-qoit-uud'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'popup',
                minutes: 10
              },
              {
                method: 'email',
                minutes: 1440
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      },
      {
        id: '23',
        userId: '113',
        eventId: '23',
        title: 'Test',
        startTime: '2023-07-20T07:00:00.000Z',
        endTime: '2023-07-20T07:30:00.000Z',
        meetLink: 'https://meet.google.com/jyp-btzc-qgx',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: 'b39cn8tvtn3tfaedi6ka9upl60',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379709067755000"',
          id: 'b39cn8tvtn3tfaedi6ka9upl60',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=YjM5Y244dHZ0bjN0ZmFlZGk2a2E5dXBsNjAgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T12:02:13.000Z',
          updated: '2023-07-20T12:02:14.606Z',
          summary: 'Test',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T18:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T18:30:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: 'b39cn8tvtn3tfaedi6ka9upl60@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/jyp-btzc-qgx',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb3rax4',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/jyp-btzc-qgx',
                label: 'meet.google.com/jyp-btzc-qgx'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/jyp-btzc-qgx?pin=9962137267346',
                pin: '9962137267346'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-413-338-0156',
                label: '+1 413-338-0156',
                pin: '864360064'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'jyp-btzc-qgx'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'email',
                minutes: 1440
              },
              {
                method: 'popup',
                minutes: 10
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      },
      {
        id: '24',
        userId: '113',
        eventId: '24',
        title: 'Test',
        startTime: '2023-07-20T07:00:00.000Z',
        endTime: '2023-07-20T08:00:00.000Z',
        meetLink: 'https://meet.google.com/fqs-yffz-ija',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: 'jc7fjj3v5doji63rr57ga8hag0',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379709360134000"',
          id: 'jc7fjj3v5doji63rr57ga8hag0',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=amM3ZmpqM3Y1ZG9qaTYzcnI1N2dhOGhhZzAgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T12:04:39.000Z',
          updated: '2023-07-20T12:04:40.643Z',
          summary: 'Test',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T18:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T19:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: 'jc7fjj3v5doji63rr57ga8hag0@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/fqs-yffz-ija',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb3ufsa',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/fqs-yffz-ija',
                label: 'meet.google.com/fqs-yffz-ija'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/fqs-yffz-ija?pin=7163012513525',
                pin: '7163012513525'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-443-671-8632',
                label: '+1 443-671-8632',
                pin: '248674895'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'fqs-yffz-ija'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'popup',
                minutes: 10
              },
              {
                method: 'email',
                minutes: 1440
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      },
      {
        id: '25',
        userId: '113',
        eventId: '25',
        title: 'Test',
        startTime: '2023-07-20T07:00:00.000Z',
        endTime: '2023-07-20T08:00:00.000Z',
        meetLink: 'https://meet.google.com/pdh-aeou-xar',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: 'i5euglspns04s9a843830cbu54',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379709473992000"',
          id: 'i5euglspns04s9a843830cbu54',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=aTVldWdsc3BuczA0czlhODQzODMwY2J1NTQgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T12:05:36.000Z',
          updated: '2023-07-20T12:05:37.602Z',
          summary: 'Test',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T18:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T19:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: 'i5euglspns04s9a843830cbu54@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/pdh-aeou-xar',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb3vnqf',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/pdh-aeou-xar',
                label: 'meet.google.com/pdh-aeou-xar'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/pdh-aeou-xar?pin=5966053428738',
                pin: '5966053428738'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-570-415-1078',
                label: '+1 570-415-1078',
                pin: '852629142'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'pdh-aeou-xar'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'popup',
                minutes: 10
              },
              {
                method: 'email',
                minutes: 1440
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      },
      {
        id: '26',
        userId: '113',
        eventId: '26',
        title: 'Test',
        startTime: '2023-07-20T06:00:00.000Z',
        endTime: '2023-07-20T07:00:00.000Z',
        meetLink: 'https://meet.google.com/ror-wswe-scv',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: 'e6g75b089rll24ovdin3er6a24',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379709826405000"',
          id: 'e6g75b089rll24ovdin3er6a24',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=ZTZnNzViMDg5cmxsMjRvdmRpbjNlcjZhMjQgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T12:08:32.000Z',
          updated: '2023-07-20T12:08:34.027Z',
          summary: 'Test',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T17:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T18:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: 'e6g75b089rll24ovdin3er6a24@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/ror-wswe-scv',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb3zfgh',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/ror-wswe-scv',
                label: 'meet.google.com/ror-wswe-scv'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/ror-wswe-scv?pin=1996393668552',
                pin: '1996393668552'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-319-975-1043',
                label: '+1 319-975-1043',
                pin: '894738095'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'ror-wswe-scv'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'popup',
                minutes: 10
              },
              {
                method: 'email',
                minutes: 1440
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      },
      {
        id: '27',
        userId: '113',
        eventId: '27',
        title: 'Test',
        startTime: '2023-07-20T06:00:00.000Z',
        endTime: '2023-07-20T07:00:00.000Z',
        meetLink: 'https://meet.google.com/emk-mcsr-koa',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: '1sjib2mlokd2pa40uesmvec6m0',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379709827134000"',
          id: '1sjib2mlokd2pa40uesmvec6m0',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=MXNqaWIybWxva2QycGE0MHVlc212ZWM2bTAgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T12:08:32.000Z',
          updated: '2023-07-20T12:08:34.553Z',
          summary: 'Test',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T17:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T18:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: '1sjib2mlokd2pa40uesmvec6m0@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/emk-mcsr-koa',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb3zfrq',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/emk-mcsr-koa',
                label: 'meet.google.com/emk-mcsr-koa'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/emk-mcsr-koa?pin=6161398206044',
                pin: '6161398206044'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-601-963-1127',
                label: '+1 601-963-1127',
                pin: '125839243'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'emk-mcsr-koa'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'popup',
                minutes: 10
              },
              {
                method: 'email',
                minutes: 1440
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      },
      {
        id: '28',
        userId: '113',
        eventId: '28',
        title: 'Test',
        startTime: '2023-07-20T09:15:00.000Z',
        endTime: '2023-07-20T09:40:00.000Z',
        meetLink: 'https://meet.google.com/nmh-ybgh-hij',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: '2a64re5k7vl049abv2lhoob8bg',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379710016740000"',
          id: '2a64re5k7vl049abv2lhoob8bg',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=MmE2NHJlNWs3dmwwNDlhYnYybGhvb2I4YmcgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T12:10:07.000Z',
          updated: '2023-07-20T12:10:08.968Z',
          summary: 'Test',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T20:15:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T20:40:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: '2a64re5k7vl049abv2lhoob8bg@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/nmh-ybgh-hij',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb41h41',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/nmh-ybgh-hij',
                label: 'meet.google.com/nmh-ybgh-hij'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/nmh-ybgh-hij?pin=9943077922913',
                pin: '9943077922913'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-304-459-9083',
                label: '+1 304-459-9083',
                pin: '981523702'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'nmh-ybgh-hij'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'popup',
                minutes: 10
              },
              {
                method: 'email',
                minutes: 1440
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      }
    ],
    waddleItGoals: [
      {
        id: "890",
        projectId: "345",
        userId: "113",
        goalId: "890",
        isWaddled: 0,
        waddledOn: null,
        name: "test waddle it 2",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: "2023-06-13T23:42:10.902Z",
        isNewGoal: 0,
        isActive: 1,
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-06-12T23:42:19.327Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
      {
        id: "889",
        projectId: "345",
        userId: "113",
        goalId: "889",
        isWaddled: 0,
        waddledOn: null,
        name: "test waddle it  1",
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 3,
        dueDate: "2023-06-11T23:41:30.103Z",
        isNewGoal: 0,
        isActive: 1,
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        goalType: "1",
        parentGoalId: null,
        sprintId: "2468",
        assigneeId: "48",
        reporterId: "48",
        description: "",
        swimlaneId: "56",
        categoryId: "1",
        dependencyId: "1",
        environment: null,
        attachments: "[]",
        phaseId: "818",
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: "",
        createdAt: "2023-06-12T23:41:38.958Z",
        componentName: null,
        timeLogged: "",
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null,
      },
    ],
    projectThemeColor: "#165ABF",
    alreadyExistsModal: 0,
  },
  onboarding: {
    loading: false,
    error: {},
    currentStep: 1,
    selectedSector: [],
    sectorList: [],
    selectedSDGs: [],
    sdgList: [],
    companyName: "",
    companyLocation: "",
    companySize: "",
    hoverIndexFromContent: 0,
    companyMembers: [
      {
        id: 1,
        email: "",
        roleId: 0,
      },
    ],
  },
  projects: {
    loading: false,
    error: {},
    status: "fulfilled",
    loggedIn: false,
    projectList: [
      {
        id: "428",
        title: "New vala project",
        components: null,
        startDate: "2023-07-18T03:25:20.841Z",
        userId: "113",
        endDate: "2023-09-07T03:25:20.841Z",
        projectImage: "",
        createdById: "113",
        progress: 2,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 0,
        createdAt: "2023-07-18T03:25:48.143Z",
        currentactivesprint: null,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "428",
            phaseId: "1",
            isCompleted: 0,
            startDate: "2023-07-19T23:54:28.026Z",
            endDate: "2023-07-30T23:54:30.913Z",
            phaseProgress: 0,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1045",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "428",
            phaseId: "2",
            isCompleted: 1,
            startDate: "2023-07-20T00:00:44.240Z",
            endDate: "2023-07-27T00:00:46.820Z",
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1046",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "428",
            phaseId: "3",
            isCompleted: 0,
            startDate: "2023-07-20T00:04:53.829Z",
            endDate: "2023-07-27T00:04:56.082Z",
            phaseProgress: 0,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1047",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "428",
            phaseId: "4",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1048",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "428",
            phaseId: "5",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1049",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
        ],
      },
      {
        id: "422",
        title: "asdemo",
        components: null,
        startDate: "2023-07-07T03:56:33.357Z",
        userId: "113",
        endDate: "2023-07-14T03:56:33.357Z",
        projectImage: "",
        createdById: "113",
        progress: 67.5,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 0,
        createdAt: "2023-07-07T04:04:43.196Z",
        currentactivesprint: 2477,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "422",
            phaseId: "1",
            isCompleted: 1,
            startDate: "2023-07-06T18:30:00.000Z",
            endDate: "2023-07-12T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1023",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "422",
            phaseId: "2",
            isCompleted: 0,
            startDate: "2023-07-18T06:55:32.378Z",
            endDate: "2023-07-20T06:55:35.093Z",
            phaseProgress: 25,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1024",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "422",
            phaseId: "3",
            isCompleted: 0,
            startDate: "2023-07-05T06:53:08.180Z",
            endDate: "2023-07-19T06:53:10.578Z",
            phaseProgress: 50,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1025",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "422",
            phaseId: "4",
            isCompleted: 0,
            startDate: "2023-07-19T11:32:00.605Z",
            endDate: "2023-07-26T11:32:03.154Z",
            phaseProgress: 0,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1026",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "422",
            phaseId: "5",
            isCompleted: 1,
            startDate: "2023-07-11T06:53:24.443Z",
            endDate: "2023-07-19T06:53:27.026Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1027",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
        ],
      },
      {
        id: "418",
        title: "popup fix",
        components: null,
        startDate: "2023-07-14T02:57:55.412Z",
        userId: "113",
        endDate: "2023-08-17T02:57:55.412Z",
        projectImage: "",
        createdById: "113",
        progress: 15.7,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 0,
        createdAt: "2023-07-07T02:58:06.411Z",
        currentactivesprint: 2476,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "418",
            phaseId: "1",
            isCompleted: 0,
            startDate: "2023-07-10T02:03:49.250Z",
            endDate: "2023-07-21T02:03:52.117Z",
            phaseProgress: 41,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1018",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "418",
            phaseId: "2",
            isCompleted: 0,
            startDate: "2023-07-12T03:35:21.114Z",
            endDate: "2023-07-27T03:35:23.913Z",
            phaseProgress: 23.5,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1019",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "418",
            phaseId: "3",
            isCompleted: 0,
            startDate: "2023-07-18T04:48:36.829Z",
            endDate: "2023-07-26T04:48:39.411Z",
            phaseProgress: 6.5,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1020",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "418",
            phaseId: "4",
            isCompleted: 0,
            startDate: "2023-07-18T04:54:03.469Z",
            endDate: "2023-07-27T04:54:06.002Z",
            phaseProgress: 5.5,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1021",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "418",
            phaseId: "5",
            isCompleted: 0,
            startDate: "2023-07-18T04:55:30.825Z",
            endDate: "2023-07-27T04:55:33.391Z",
            phaseProgress: 5.5,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "1022",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
        ],
      },
      {
        id: "417",
        title: "popup test",
        components: null,
        startDate: "2023-07-07T02:56:11.193Z",
        userId: "113",
        endDate: "2023-08-23T02:56:11.193Z",
        projectImage: "",
        createdById: "113",
        progress: 6.5,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 0,
        createdAt: "2023-07-07T02:56:21.154Z",
        currentactivesprint: null,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "417",
            phaseId: "1",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1013",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "417",
            phaseId: "2",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1014",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "417",
            phaseId: "3",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1015",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "417",
            phaseId: "4",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1016",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "417",
            phaseId: "5",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "1017",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
        ],
      },
      {
        id: "410",
        title: "email",
        components: null,
        startDate: "2023-06-06T02:45:34.964Z",
        userId: "113",
        endDate: "2023-07-04T02:45:34.964Z",
        projectImage: "",
        createdById: "113",
        progress: 53.35,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 0,
        createdAt: "2023-06-30T02:45:47.608Z",
        currentactivesprint: null,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "410",
            phaseId: "1",
            isCompleted: 0,
            startDate: "2023-07-07T00:13:36.137Z",
            endDate: "2023-07-25T00:13:38.620Z",
            phaseProgress: 33.5,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "979",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "410",
            phaseId: "2",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "980",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "410",
            phaseId: "3",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "981",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "410",
            phaseId: "4",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "982",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "410",
            phaseId: "5",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "983",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
        ],
      },
      {
        id: "397",
        title: "gfdg",
        components: null,
        startDate: "2023-06-21T04:15:17.774Z",
        userId: "113",
        endDate: "2023-07-17T04:15:17.774Z",
        projectImage: "",
        createdById: "113",
        progress: 29,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 0,
        createdAt: "2023-06-23T04:15:45.004Z",
        currentactivesprint: null,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "397",
            phaseId: "1",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "954",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "397",
            phaseId: "3",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "955",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
        ],
      },
      {
        id: "385",
        title: "dvbsdkjvbkjs",
        components: null,
        startDate: "2023-02-08T05:29:45.308Z",
        userId: "113",
        endDate: "2023-02-28T18:29:45.308Z",
        projectImage: "",
        createdById: "113",
        progress: 55.55555555555556,
        colorCode: "",
        isDiscarded: 1,
        isActive: 1,
        isPinned: 1,
        createdAt: "2023-02-08T05:34:35.676Z",
        currentactivesprint: null,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "385",
            phaseId: "1",
            isCompleted: 1,
            startDate: "2023-02-07T18:30:00.000Z",
            endDate: "2023-02-20T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "906",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "385",
            phaseId: "2",
            isCompleted: 1,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "918",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: null,
            phaseIteration: null,
          },
          {
            projectId: "385",
            phaseId: "3",
            isCompleted: 1,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "919",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: null,
            phaseIteration: null,
          },
          {
            projectId: "385",
            phaseId: "4",
            isCompleted: 1,
            startDate: "2023-06-14T18:30:00.000Z",
            endDate: "2023-06-28T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: "",
            id: "953",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: "",
            phaseIteration: null,
          },
        ],
      },
      {
        id: "375",
        title: "per2",
        components: null,
        startDate: "2023-02-06T07:14:38.939Z",
        userId: "113",
        endDate: "2023-02-21T07:14:38.939Z",
        projectImage: "",
        createdById: "113",
        progress: 100,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 1,
        createdAt: "2023-02-06T07:16:29.945Z",
        currentactivesprint: null,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "375",
            phaseId: "1",
            isCompleted: 0,
            startDate: "2023-07-06T13:00:00.000Z",
            endDate: "2023-09-19T13:00:00.000Z",
            phaseProgress: 0,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "867",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "375",
            phaseId: "2",
            isCompleted: 1,
            startDate: "2023-06-08T05:09:17.249Z",
            endDate: "2023-06-15T05:09:21.549Z",
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "937",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: null,
            phaseIteration: null,
          },
          {
            projectId: "375",
            phaseId: "3",
            isCompleted: 0,
            startDate: "2023-07-06T18:30:00.000Z",
            endDate: "2023-07-19T18:30:00.000Z",
            phaseProgress: 0,
            isOngoing: 1,
            phaseEndDateChangeReason: "",
            id: "1010",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: "",
            phaseIteration: null,
          },
          {
            projectId: "375",
            phaseId: "4",
            isCompleted: 0,
            startDate: "2023-07-06T18:30:00.000Z",
            endDate: "2023-07-19T18:30:00.000Z",
            phaseProgress: 0,
            isOngoing: 1,
            phaseEndDateChangeReason: "",
            id: "1011",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: "",
            phaseIteration: null,
          },
          {
            projectId: "375",
            phaseId: "5",
            isCompleted: 0,
            startDate: "2023-07-06T18:30:00.000Z",
            endDate: "2023-07-25T18:30:00.000Z",
            phaseProgress: 0,
            isOngoing: 1,
            phaseEndDateChangeReason: "",
            id: "1012",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: "",
            phaseIteration: null,
          },
        ],
      },
      {
        id: "373",
        title: "Permission",
        components: null,
        startDate: "2023-02-06T07:10:41.696Z",
        userId: "113",
        endDate: "2023-03-31T07:10:41.696Z",
        projectImage: "",
        createdById: "113",
        progress: 0,
        colorCode: "",
        isDiscarded: 1,
        isActive: 1,
        isPinned: 0,
        createdAt: "2023-02-06T07:11:19.843Z",
        currentactivesprint: null,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "373",
            phaseId: "1",
            isCompleted: 0,
            startDate: "2023-02-05T18:30:00.000Z",
            endDate: "2023-02-12T18:30:00.000Z",
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "866",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "373",
            phaseId: "2",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "935",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: null,
            phaseIteration: null,
          },
          {
            projectId: "373",
            phaseId: "3",
            isCompleted: 0,
            startDate: null,
            endDate: null,
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "936",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: null,
            phaseIteration: null,
          },
        ],
      },
      {
        id: "367",
        title: "Project 03-02",
        components: null,
        startDate: "2023-02-03T00:21:21.754Z",
        userId: "113",
        endDate: "2023-04-08T00:21:21.754Z",
        projectImage: "",
        createdById: "113",
        progress: 75,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 1,
        createdAt: "2023-02-03T00:22:36.780Z",
        currentactivesprint: null,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "367",
            phaseId: "1",
            isCompleted: 0,
            startDate: "2023-02-02T18:30:00.000Z",
            endDate: "2023-02-09T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "845",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "367",
            phaseId: "2",
            isCompleted: 0,
            startDate: "2023-02-10T18:30:00.000Z",
            endDate: "2023-02-17T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "846",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "367",
            phaseId: "3",
            isCompleted: 0,
            startDate: "2023-02-18T18:30:00.000Z",
            endDate: "2023-02-25T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "847",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
        ],
      },
      {
        id: "349",
        title: "Project check 1",
        components: null,
        startDate: "2023-01-23T00:03:10.084Z",
        userId: "113",
        endDate: "2023-03-11T00:03:10.084Z",
        projectImage: "",
        createdById: "113",
        progress: 100,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 1,
        createdAt: "2023-01-23T00:03:48.701Z",
        currentactivesprint: 2415,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "349",
            phaseId: "1",
            isCompleted: 0,
            startDate: "2023-01-22T18:30:00.000Z",
            endDate: "2023-01-29T18:30:00.000Z",
            phaseProgress: 0,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "828",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "349",
            phaseId: "2",
            isCompleted: 0,
            startDate: "2023-01-22T18:30:00.000Z",
            endDate: "2023-01-29T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "829",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "349",
            phaseId: "3",
            isCompleted: 0,
            startDate: "2023-02-07T18:30:00.000Z",
            endDate: "2023-02-10T18:30:00.000Z",
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "830",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
        ],
      },
      {
        id: "345",
        title: "Project Xd",
        components: null,
        startDate: "2023-01-03T14:16:23.984Z",
        userId: "113",
        endDate: "2023-01-17T14:16:23.984Z",
        projectImage:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Antarctica_white_no_bg_ljgx97kx.png-1688029626289.png?download=1",
        createdById: "113",
        progress: 75,
        colorCode: "",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 0,
        createdAt: "2023-01-10T03:47:11.619Z",
        currentactivesprint: 2468,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "345",
            phaseId: "1",
            isCompleted: 1,
            startDate: "2023-01-05T07:30:00.000Z",
            endDate: "2023-01-18T07:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "817",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "345",
            phaseId: "2",
            isCompleted: 0,
            startDate: "2023-01-30T18:30:00.000Z",
            endDate: "2023-02-02T02:00:00.000Z",
            phaseProgress: 50,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "818",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "345",
            phaseId: "3",
            isCompleted: 1,
            startDate: "2023-01-08T18:30:00.000Z",
            endDate: "2023-01-25T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "819",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "345",
            phaseId: "4",
            isCompleted: 0,
            startDate: "2023-06-01T01:02:30.474Z",
            endDate: "2023-06-21T01:02:26.843Z",
            phaseProgress: 50,
            isOngoing: 1,
            phaseEndDateChangeReason: null,
            id: "938",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: null,
            phaseIteration: null,
          },
          {
            projectId: "345",
            phaseId: "5",
            isCompleted: 0,
            startDate: "2023-01-03T18:30:00.000Z",
            endDate: "2023-01-10T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 1,
            phaseEndDateChangeReason: "",
            id: "978",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: "",
            phaseIteration: "",
          },
        ],
      },
      {
        id: "344",
        title: "Test Project",
        components: null,
        startDate: "2022-12-28T19:07:56.509Z",
        userId: "113",
        endDate: "2023-03-21T13:37:56.509Z",
        projectImage: "",
        createdById: "113",
        progress: 71.42857142857143,
        colorCode: "#CC0000",
        isDiscarded: 0,
        isActive: 1,
        isPinned: 0,
        createdAt: "2022-12-30T04:08:53.565Z",
        currentactivesprint: 2396,
        fullName: "Ganesh Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        phases: [
          {
            projectId: "344",
            phaseId: "1",
            isCompleted: 1,
            startDate: "2023-01-02T07:30:00.000Z",
            endDate: "2023-01-27T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: "",
            id: "923",
            isActive: 0,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: "",
            phaseIteration: "1",
          },
          {
            projectId: "344",
            phaseId: "1",
            isCompleted: 1,
            startDate: "2023-01-04T18:30:00.000Z",
            endDate: "2023-01-21T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "813",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "344",
            phaseId: "2",
            isCompleted: 1,
            startDate: "2023-01-05T18:30:00.000Z",
            endDate: "2023-01-13T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "814",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "344",
            phaseId: "2",
            isCompleted: 0,
            startDate: "2023-01-05T18:30:00.000Z",
            endDate: "2023-01-11T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 1,
            phaseEndDateChangeReason: "",
            id: "925",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: "",
            phaseIteration: "1",
          },
          {
            projectId: "344",
            phaseId: "3",
            isCompleted: 1,
            startDate: "2023-01-03T18:30:00.000Z",
            endDate: "2023-02-01T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "815",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "344",
            phaseId: "3",
            isCompleted: 0,
            startDate: "2023-01-31T18:30:00.000Z",
            endDate: "2023-03-07T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: "",
            id: "1009",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: "",
            phaseIteration: "2",
          },
          {
            projectId: "344",
            phaseId: "4",
            isCompleted: 1,
            startDate: "2023-01-10T18:30:00.000Z",
            endDate: "2023-01-06T18:30:00.000Z",
            phaseProgress: 0,
            isOngoing: 0,
            phaseEndDateChangeReason: null,
            id: "816",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components:
              '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
            phaseIteration: null,
          },
          {
            projectId: "344",
            phaseId: "5",
            isCompleted: 1,
            startDate: "2023-01-01T18:30:00.000Z",
            endDate: "2023-01-09T18:30:00.000Z",
            phaseProgress: 50,
            isOngoing: 0,
            phaseEndDateChangeReason: "",
            id: "924",
            isActive: 1,
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            components: "",
            phaseIteration: "",
          },
        ],
      },
    ],
    refreshCount: 0,
    selectedProject: null,
    projectPermission: {
      SCHEDULE: [
        {
          permissionId: "4",
          permissionName: "VIEW_ONLY",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "17",
          permissionName: "LOG_TIME_ADD",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "20",
          permissionName: "REPORTER_EDIT",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "18",
          permissionName: "LOG_TIME_EDIT",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "5",
          permissionName: "PHASE_AND_SPRINT_ADD",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "6",
          permissionName: "PHASE_AND_SPRINT_EDIT",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "7",
          permissionName: "PHASE_AND_SPRINT_DELETE",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "10",
          permissionName: "TASK_EVENT_SCOPE_DELETE",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "9",
          permissionName: "TASK_EVENT_SCOPE_EDIT",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "8",
          permissionName: "TASK_EVENT_SCOPE_ADD",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "11",
          permissionName: "SUB_TASK_AND_BUG_ADD",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "12",
          permissionName: "SUB_TASK_AND_BUG_EDIT",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "13",
          permissionName: "SUB_TASK_AND_BUG_DELETE",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "15",
          permissionName: "COMMENT_EDIT",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "14",
          permissionName: "COMMENT_ADD",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "16",
          permissionName: "COMMENT_DELETE",
          moduleName: "SCHEDULE",
        },
        {
          permissionId: "19",
          permissionName: "LOG_TIME_DELETE",
          moduleName: "SCHEDULE",
        },
      ],
      OVERVIEW: [
        {
          permissionId: "1",
          permissionName: "MANAGE_PROJECTS",
          moduleName: "OVERVIEW",
        },
        {
          permissionId: "2",
          permissionName: "MANAGE_MEMBERS",
          moduleName: "OVERVIEW",
        },
      ],
      LIBRARY: [
        {
          permissionId: "22",
          permissionName: "FOLDER_FILE_LINK_UPLOAD",
          moduleName: "LIBRARY",
        },
        {
          permissionId: "23",
          permissionName: "FOLDER_FILE_LINK_EDIT",
          moduleName: "LIBRARY",
        },
        {
          permissionId: "24",
          permissionName: "FOLDER_FILE_LINK_DELETE_ALL",
          moduleName: "LIBRARY",
        },
        {
          permissionId: "25",
          permissionName: "FOLDER_FILE_LINK_DELETE_OWN",
          moduleName: "LIBRARY",
        },
        {
          permissionId: "26",
          permissionName: "COMMENT_ADD",
          moduleName: "LIBRARY",
        },
        {
          permissionId: "27",
          permissionName: "COMMENT_EDIT",
          moduleName: "LIBRARY",
        },
        {
          permissionId: "28",
          permissionName: "COMMENT_DELETE",
          moduleName: "LIBRARY",
        },
      ],
    },
    permissionArray: [
      "4",
      "17",
      "20",
      "18",
      "1",
      "2",
      "5",
      "6",
      "7",
      "10",
      "9",
      "8",
      "11",
      "12",
      "13",
      "15",
      "14",
      "16",
      "19",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
    ],
    permissionInfo: null,
    overview: null,
    schedule: null,
    vault: null,
  },
  projectOnboarding: {
    loading: false,
    error: {},
    currentStep: 1,
    projectName: "",
    projectImage: "",
    projectColor: "",
    clientName: "",
    clientImage: "",
    internalProject: false,
    clientColor: "",
    projectDuration: null,
    clientLocation: "",
    softwareType: [],
    isOpen: 0,
    selectedPhase: [1, 2, 3, 4, 5],
    phaseDetails: [
      {
        id: 1,
        startDate: null,
        endDate: null,
        phaseName: "Ideation",
        components: [
          {
            id: 1,
            title: "Back-end",
            content: [
              {
                id: 1,
                heading: "Node JS",
              },
            ],
          },
          {
            id: 2,
            title: "Front-end",
            content: [
              {
                id: 1,
                heading: "React JS",
              },
              {
                id: 2,
                heading: "Next JS",
              },
            ],
          },
          {
            id: 3,
            title: "Testing",
            content: [
              {
                id: 1,
                heading: "BrowserStack",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        startDate: null,
        endDate: null,
        phaseName: "Design",
        components: [
          {
            id: 1,
            title: "Back-end",
            content: [
              {
                id: 1,
                heading: "Node JS",
              },
            ],
          },
          {
            id: 2,
            title: "Front-end",
            content: [
              {
                id: 1,
                heading: "React JS",
              },
              {
                id: 2,
                heading: "Next JS",
              },
            ],
          },
          {
            id: 3,
            title: "Testing",
            content: [
              {
                id: 1,
                heading: "BrowserStack",
              },
            ],
          },
        ],
      },
      {
        id: 3,
        startDate: null,
        endDate: null,
        phaseName: "Development",
        components: [
          {
            id: 1,
            title: "Back-end",
            content: [
              {
                id: 1,
                heading: "Node JS",
              },
            ],
          },
          {
            id: 2,
            title: "Front-end",
            content: [
              {
                id: 1,
                heading: "React JS",
              },
              {
                id: 2,
                heading: "Next JS",
              },
            ],
          },
          {
            id: 3,
            title: "Testing",
            content: [
              {
                id: 1,
                heading: "BrowserStack",
              },
            ],
          },
        ],
      },
      {
        id: 4,
        startDate: null,
        endDate: null,
        phaseName: "Deployment",
        components: [
          {
            id: 1,
            title: "Back-end",
            content: [
              {
                id: 1,
                heading: "Node JS",
              },
            ],
          },
          {
            id: 2,
            title: "Front-end",
            content: [
              {
                id: 1,
                heading: "React JS",
              },
              {
                id: 2,
                heading: "Next JS",
              },
            ],
          },
          {
            id: 3,
            title: "Testing",
            content: [
              {
                id: 1,
                heading: "BrowserStack",
              },
            ],
          },
        ],
      },
      {
        id: 5,
        startDate: null,
        endDate: null,
        phaseName: "Maintenance",
        components: [
          {
            id: 1,
            title: "Back-end",
            content: [
              {
                id: 1,
                heading: "Node JS",
              },
            ],
          },
          {
            id: 2,
            title: "Front-end",
            content: [
              {
                id: 1,
                heading: "React JS",
              },
              {
                id: 2,
                heading: "Next JS",
              },
            ],
          },
          {
            id: 3,
            title: "Testing",
            content: [
              {
                id: 1,
                heading: "BrowserStack",
              },
            ],
          },
        ],
      },
    ],
    components: {
      Design: [
        {
          id: 1,
          title: "Back-end",
          content: [
            {
              id: 1,
              heading: "Node JS",
            },
          ],
        },
        {
          id: 2,
          title: "Front-end",
          content: [
            {
              id: 1,
              heading: "React JS",
            },
            {
              id: 2,
              heading: "Next JS",
            },
          ],
        },
        {
          id: 3,
          title: "Testing",
          content: [
            {
              id: 1,
              heading: "BrowserStack",
            },
          ],
        },
      ],
      Development: [
        {
          id: 1,
          title: "Back-end",
          content: [
            {
              id: 1,
              heading: "Node JS",
            },
          ],
        },
        {
          id: 2,
          title: "Front-end",
          content: [
            {
              id: 1,
              heading: "React JS",
            },
            {
              id: 2,
              heading: "Next JS",
            },
          ],
        },
        {
          id: 3,
          title: "Testing",
          content: [
            {
              id: 1,
              heading: "BrowserStack",
            },
          ],
        },
      ],
      Testing: [
        {
          id: 1,
          title: "Back-end",
          content: [
            {
              id: 1,
              heading: "Node JS",
            },
          ],
        },
        {
          id: 2,
          title: "Front-end",
          content: [
            {
              id: 1,
              heading: "React JS",
            },
            {
              id: 2,
              heading: "Next JS",
            },
          ],
        },
        {
          id: 3,
          title: "Testing",
          content: [
            {
              id: 1,
              heading: "BrowserStack",
            },
          ],
        },
      ],
      Deployment: [
        {
          id: 1,
          title: "Back-end",
          content: [
            {
              id: 1,
              heading: "Node JS",
            },
          ],
        },
        {
          id: 2,
          title: "Front-end",
          content: [
            {
              id: 1,
              heading: "React JS",
            },
            {
              id: 2,
              heading: "Next JS",
            },
          ],
        },
        {
          id: 3,
          title: "Testing",
          content: [
            {
              id: 1,
              heading: "BrowserStack",
            },
          ],
        },
      ],
    },
  },
  notification: {
    loading: false,
    error: {},
    status: "fulfilled",
    notificationList: [
      {
        id: "11299",
        receiverId: "113",
        general: 1,
        firstname: "Ganesh",
        lastname: "Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        projectTitle: "New vala project",
        projectId: "428",
        phaseId: "3",
        notificationType: "11",
        priority: "Low",
        createdAt: "2023-07-20T00:05:00.845Z",
        isResolved: 0,
        deadline: null,
        meta: '{"0":{"projectId":"428","phaseId":"3","isCompleted":0,"startDate":"2023-07-20T00:04:53.829Z","endDate":"2023-07-27T00:04:56.082Z","phaseProgress":0,"isOngoing":1,"phaseEndDateChangeReason":null,"id":"1047","isActive":1,"tenantId":"69291437-4846-4e92-8806-4547cda6b3e4","components":"[{\\"id\\":1,\\"title\\":\\"Back-end\\",\\"content\\":[{\\"id\\":1,\\"heading\\":\\"Node JS\\"}]},{\\"id\\":2,\\"title\\":\\"Front-end\\",\\"content\\":[{\\"id\\":1,\\"heading\\":\\"React JS\\"},{\\"id\\":2,\\"heading\\":\\"Next JS\\"}]},{\\"id\\":3,\\"title\\":\\"Testing\\",\\"content\\":[{\\"id\\":1,\\"heading\\":\\"BrowserStack\\"}]}]","phaseIteration":null},"phaseName":{"id":"3","phases":"Development","color":"#6938EF","isActive":1},"projectId":"428","phaseId":"3"}',
        clickAction: "/overview/428",
        phases: "Development",
        threadId: null,
        threadName: null,
        isUnread: 1,
      },
      {
        id: "11295",
        receiverId: "113",
        general: 1,
        firstname: "Ganesh",
        lastname: "Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        projectTitle: "New vala project",
        projectId: "428",
        phaseId: "2",
        notificationType: "11",
        priority: "Low",
        createdAt: "2023-07-20T00:00:52.144Z",
        isResolved: 0,
        deadline: null,
        meta: '{"0":{"projectId":"428","phaseId":"2","isCompleted":0,"startDate":"2023-07-20T00:00:44.240Z","endDate":"2023-07-27T00:00:46.820Z","phaseProgress":0,"isOngoing":1,"phaseEndDateChangeReason":null,"id":"1046","isActive":1,"tenantId":"69291437-4846-4e92-8806-4547cda6b3e4","components":"[{\\"id\\":1,\\"title\\":\\"Back-end\\",\\"content\\":[{\\"id\\":1,\\"heading\\":\\"Node JS\\"}]},{\\"id\\":2,\\"title\\":\\"Front-end\\",\\"content\\":[{\\"id\\":1,\\"heading\\":\\"React JS\\"},{\\"id\\":2,\\"heading\\":\\"Next JS\\"}]},{\\"id\\":3,\\"title\\":\\"Testing\\",\\"content\\":[{\\"id\\":1,\\"heading\\":\\"BrowserStack\\"}]}]","phaseIteration":null},"phaseName":{"id":"2","phases":"Design","color":"#F92B63","isActive":1},"projectId":"428","phaseId":"2"}',
        clickAction: "/overview/428",
        phases: "Design",
        threadId: null,
        threadName: null,
        isUnread: 1,
      },
      {
        id: "11291",
        receiverId: "113",
        general: 1,
        firstname: "Ganesh",
        lastname: "Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        projectTitle: "New vala project",
        projectId: "428",
        phaseId: "1",
        notificationType: "11",
        priority: "Low",
        createdAt: "2023-07-19T23:54:36.307Z",
        isResolved: 0,
        deadline: null,
        meta: '{"0":{"projectId":"428","phaseId":"1","isCompleted":0,"startDate":"2023-07-19T23:54:28.026Z","endDate":"2023-07-30T23:54:30.913Z","phaseProgress":0,"isOngoing":1,"phaseEndDateChangeReason":null,"id":"1045","isActive":1,"tenantId":"69291437-4846-4e92-8806-4547cda6b3e4","components":"[{\\"id\\":1,\\"title\\":\\"Back-end\\",\\"content\\":[{\\"id\\":1,\\"heading\\":\\"Node JS\\"}]},{\\"id\\":2,\\"title\\":\\"Front-end\\",\\"content\\":[{\\"id\\":1,\\"heading\\":\\"React JS\\"},{\\"id\\":2,\\"heading\\":\\"Next JS\\"}]},{\\"id\\":3,\\"title\\":\\"Testing\\",\\"content\\":[{\\"id\\":1,\\"heading\\":\\"BrowserStack\\"}]}]","phaseIteration":null},"phaseName":{"id":"1","phases":"Ideation","color":"#FFDB01","isActive":1},"projectId":"428","phaseId":"1"}',
        clickAction: "/overview/428",
        phases: "Ideation",
        threadId: null,
        threadName: null,
        isUnread: 1,
      },
      {
        id: "11289",
        receiverId: "113",
        general: 1,
        firstname: "Ganesh",
        lastname: "Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        projectTitle: null,
        projectId: null,
        phaseId: null,
        notificationType: "1",
        priority: "Low",
        createdAt: "2023-07-19T12:24:45.415Z",
        isResolved: 0,
        deadline: null,
        meta: '{"_id":"64b823632ad9763ce70b17ae","docId":"1040","docType":"bug","message":"nn","senderName":"Ganesh Mohanty","senderId":"113","replyMessage":null,"attachments":[],"users":[{"userId":"113"},{"userId":"48"}],"name":"waddle it bug"}',
        clickAction: null,
        phases: null,
        threadId: null,
        threadName: null,
        isUnread: 1,
      },
      {
        id: "11276",
        receiverId: "113",
        general: 1,
        firstname: "Anushka",
        lastname: "Gaonkar",
        profilePicture:
          "https://lh3.googleusercontent.com/a/AAcHTtf7KFLdNrsVb6bZU-yQN-QOk4kzGdYW6UzUfNk-NJ6d=s96-c",
        projectTitle: "Project Xd",
        projectId: "345",
        phaseId: null,
        notificationType: "30",
        priority: "Low",
        createdAt: "2023-07-19T08:00:37.689Z",
        isResolved: 0,
        deadline: null,
        meta: '{"goalType":"1","name":"sdcvsdvs","goal":{"id":"1031","name":"sdcvsdvs","isCompleted":0,"statusId":1,"componentId":null,"priorityId":1,"dueDate":null,"isNewGoal":0,"isActive":1,"projectId":"345","tenantId":"69291437-4846-4e92-8806-4547cda6b3e4","goalType":"1","parentGoalId":null,"sprintId":"2468","assigneeId":"264","reporterId":"136","description":"","swimlaneId":"56","categoryId":"1","dependencyId":"1","environment":null,"attachments":"[]","phaseId":"818","meetId":null,"meetLink":null,"meetMetaData":null,"estimatedTime":"","createdAt":"2023-07-16T10:12:29.747Z","componentName":null,"timeLogged":"","linkedGoalId":null,"labelId":null,"releaseVersionId":null,"projectPhaseId":null,"identifier":"P_107"}}',
        clickAction: "schedule/345/task/1031",
        phases: null,
        threadId: null,
        threadName: null,
        isUnread: 1,
      },
      {
        id: "11274",
        receiverId: "113",
        general: 1,
        firstname: "Ganesh",
        lastname: "Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        projectTitle: "Project Xd",
        projectId: "345",
        phaseId: null,
        notificationType: "32",
        priority: "Low",
        createdAt: "2023-07-19T08:00:21.653Z",
        isResolved: 0,
        deadline: null,
        meta: '{"goalType":"1","name":"sdcvsdvs","meta":{"oldAssigneeId":"113","newAssigneeId":"264"},"goal":{"id":"1031","name":"sdcvsdvs","isCompleted":0,"statusId":1,"componentId":null,"priorityId":1,"dueDate":null,"isNewGoal":0,"isActive":1,"projectId":"345","tenantId":"69291437-4846-4e92-8806-4547cda6b3e4","goalType":"1","parentGoalId":null,"sprintId":"2468","assigneeId":"264","reporterId":"136","description":"","swimlaneId":"56","categoryId":"1","dependencyId":"1","environment":null,"attachments":"[]","phaseId":"818","meetId":null,"meetLink":null,"meetMetaData":null,"estimatedTime":"","createdAt":"2023-07-16T10:12:29.747Z","componentName":null,"timeLogged":"","linkedGoalId":null,"labelId":null,"releaseVersionId":null,"projectPhaseId":null,"identifier":"P_107"}}',
        clickAction: "schedule/345/task/1031",
        phases: null,
        threadId: null,
        threadName: null,
        isUnread: 1,
      },
      {
        id: "11270",
        receiverId: "113",
        general: 1,
        firstname: "Ganesh",
        lastname: "Mohanty",
        profilePicture:
          "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
        projectTitle: "Project Xd",
        projectId: "345",
        phaseId: null,
        notificationType: "32",
        priority: "Low",
        createdAt: "2023-07-19T08:00:07.970Z",
        isResolved: 0,
        deadline: null,
        meta: '{"goalType":"1","name":"sdcvsdvs","meta":{"oldAssigneeId":"113","newAssigneeId":"264"},"goal":{"id":"1031","name":"sdcvsdvs","isCompleted":0,"statusId":1,"componentId":null,"priorityId":1,"dueDate":null,"isNewGoal":0,"isActive":1,"projectId":"345","tenantId":"69291437-4846-4e92-8806-4547cda6b3e4","goalType":"1","parentGoalId":null,"sprintId":"2468","assigneeId":"264","reporterId":"136","description":"","swimlaneId":"56","categoryId":"1","dependencyId":"1","environment":null,"attachments":"[]","phaseId":"818","meetId":null,"meetLink":null,"meetMetaData":null,"estimatedTime":"","createdAt":"2023-07-16T10:12:29.747Z","componentName":null,"timeLogged":"","linkedGoalId":null,"labelId":null,"releaseVersionId":null,"projectPhaseId":null,"identifier":"P_107"}}',
        clickAction: "schedule/345/task/1031",
        phases: null,
        threadId: null,
        threadName: null,
        isUnread: 1,
      },
      {
        id: "11267",
        receiverId: "113",
        general: 1,
        firstname: null,
        lastname: null,
        profilePicture: null,
        projectTitle: "Project Xd",
        projectId: "345",
        phaseId: null,
        notificationType: "31",
        priority: "Low",
        createdAt: "2023-07-19T06:17:01.132Z",
        isResolved: 0,
        deadline: null,
        meta: '{"goalType":"1","name":"waddwl","goal":{"id":"1002","name":"waddwl","isCompleted":0,"statusId":1,"componentId":null,"priorityId":1,"dueDate":null,"isNewGoal":0,"isActive":1,"projectId":"345","tenantId":"69291437-4846-4e92-8806-4547cda6b3e4","goalType":"1","parentGoalId":null,"sprintId":"2468","assigneeId":"264","reporterId":"113","description":"","swimlaneId":"56","categoryId":"1","dependencyId":"1","environment":null,"attachments":"[]","phaseId":"818","meetId":null,"meetLink":null,"meetMetaData":null,"estimatedTime":"","createdAt":"2023-07-04T00:54:20.578Z","componentName":null,"timeLogged":"","linkedGoalId":null,"labelId":null,"releaseVersionId":null,"projectPhaseId":null,"identifier":null},"type":"waddled_by_everyone"}',
        clickAction: "schedule/345/task/1002",
        phases: null,
        threadId: null,
        threadName: null,
        isUnread: 1,
      },
    ],
    notificationTab: "",
  },
  settings: {
    loading: false,
    error: {},
    status: "idle",
    loggedIn: false,
    currentTab: 1,
    tabs: [
      {
        id: 1,
        name: "Details",
      },
      {
        id: 2,
        name: "Notifications",
      },
      {
        id: 3,
        name: "Roles",
      },
      {
        id: 4,
        name: "Team Members",
      },
      {
        id: 5,
        name: "Schedule Metadata",
      },
    ],
    productTabs: [
      {
        id: 1,
        name: "My Account",
      },
      {
        id: 2,
        name: "Integrations",
      },
      {
        id: 3,
        name: "Members",
      },
      {
        id: 4,
        name: "Support",
      },
    ],
    currentProductTab: 1,
    memberList: [],
    selectedMembers: [],
    categoryList: [],
    selectedRole: [],
    notificationActions: [],
    alertSeverity: [],
    projectUsers: [],
    removeMemberModal: {
      visible: false,
      action: "removeTenantMember",
      user: {
        userId: "",
      },
    },
    roleList: [],
    showRoleModal: {
      visible: false,
      mode: "new",
      role: {
        id: "",
        roleName: "",
      },
    },
    automations: [
      {
        id: 6,
        projectId: "345",
        goalType: "1",
        fieldName: "Reporter",
        value: "136",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      },
      {
        id: 8,
        projectId: "345",
        goalType: "3",
        fieldName: "Assignee",
        value: "113",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      },
      {
        id: 9,
        projectId: "345",
        goalType: "3",
        fieldName: "Reporter",
        value: "48",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      },
      {
        id: 10,
        projectId: "345",
        goalType: "4",
        fieldName: "Assignee",
        value: "113",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      },
      {
        id: 11,
        projectId: "345",
        goalType: "4",
        fieldName: "Reporter",
        value: "48",
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      },
    ],
    defaultNavigation: {
      id: 4,
      isEnabled: true,
      userId: "113",
      screenId: "3",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
    },
  },
  activityLogs: {
    loading: false,
    error: {},
    status: "fulfilled",
  },
  preview: {
    loading: false,
    error: {},
    status: "idle",
    docs: [],
    from: "",
    visible: false,
    currentIndex: 0,
  },
  _persist: {
    version: 1,
    rehydrated: true,
  },
};

export const conversationsDummyData = {
  projectsWithThreads: [],
  loading: false,
  getMessageLoading: false,
  error: null,
  status: "fulfilled",
  threadModal: false,
  showThreadProfileDrawer: false,
  threadProfileDrawerCurrenTab: "details",
  newMessageModal: false,
  leaveThread: false,
  deleteThread: false,
  addFolderModal: false,
  addLinkModal: false,
  shareMessageModal: false,
  shareInMessageModal: {
    type: "folder",
    visible: false,
    url: "",
    file: [],
  },
  viewFileDetails: false,
  deleteFileModal: false,
  deleteFileInMessage: null,
  saveInVaultModal: {
    visible: false,
    file: {},
  },
  shareType: "shareMessage",
  threads: [
    {
      threadId: "346",
      threadName: "QA Testing ",
      newScope: 0,
      firstname: "Nutan",
      lastname: "g",
      createdAt: "2023-07-12T01:37:40.282Z",
      createdById: "322",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: "Nutan g : Hello again",
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: null,
      lastMessageTime: "2023-07-17T03:22:47.079Z",
    },
    {
      threadId: "334",
      threadName: "Waddle Team",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Mohanty",
      createdAt: "2023-06-23T13:01:35.976Z",
      createdById: "113",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: null,
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: "344",
      lastMessageTime: null,
    },
    {
      threadId: "334",
      threadName: "Waddle Team",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Mohanty",
      createdAt: "2023-06-23T13:01:35.976Z",
      createdById: "113",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: null,
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: "344",
      lastMessageTime: null,
    },
    {
      threadId: "320",
      threadName: "One Piece",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Mohanty",
      createdAt: "2023-02-07T14:10:49.100Z",
      createdById: "113",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: "You : ",
      phaseId: null,
      description: "",
      isMute: 0,
      projectId: null,
      lastMessageTime: "2023-07-18T02:43:36.162Z",
    },
    {
      threadId: "313",
      threadName: "Death Note",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Mohanty",
      createdAt: "2023-01-09T02:29:44.659Z",
      createdById: "113",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: null,
      phaseId: null,
      description: "casdca",
      isMute: 0,
      projectId: null,
      lastMessageTime: null,
    },
    {
      threadId: "337",
      threadName: "Project Test",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Mohanty",
      createdAt: "2023-06-25T03:28:37.353Z",
      createdById: "113",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: "Ganesh Shah : jhbvhjjh",
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: "344",
      lastMessageTime: "2023-07-09T01:54:54.250Z",
    },
    {
      threadId: "347",
      threadName: "QA Testing group",
      newScope: 0,
      firstname: "Nutan",
      lastname: "g",
      createdAt: "2023-07-12T01:43:44.166Z",
      createdById: "322",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: null,
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: null,
      lastMessageTime: null,
    },
    {
      threadId: "343",
      threadName: "Notifications",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Shah",
      createdAt: "2023-07-01T09:33:29.200Z",
      createdById: "48",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: null,
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: null,
      lastMessageTime: null,
    },
    {
      threadId: "330",
      threadName: "Alibaug ",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Mohanty",
      createdAt: "2023-05-03T05:36:39.460Z",
      createdById: "113",
      isApproved: 1,
      read: 1,
      isPinned: 1,
      isDashboard: 0,
      unreadCount: 0,
      lastMessage: "You : ",
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: "345",
      lastMessageTime: "2023-07-19T05:43:36.178Z",
      messages: [
        {
          _id: "6453e1b2dcd9fdaa27e2c665",
          threadId: 330,
          message: "hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-05-04T16:47:46.608Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
        },
        {
          _id: "6453e2c054e98285db728450",
          threadId: 330,
          message: "jjj",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-05-04T16:52:16.719Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
        },
        {
          _id: "6453e2e154e98285db728451",
          threadId: 330,
          message: "jjj",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-05-04T16:52:49.533Z",
          reactions: {
            "😊": ["48"],
          },
          replyMessage: {},
          attachments: [],
        },
        {
          _id: "6453e30454e98285db728452",
          threadId: 330,
          message: "hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-05-04T16:53:24.856Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
        },
        {
          _id: "6453e31454e98285db728453",
          threadId: 330,
          message: "kkk",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-05-04T16:53:40.610Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
        },
        {
          _id: "645686cdf6e8bcba5ba7e0d7",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-06T16:56:45.617Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              name: "volume (1).png",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/volume_1_lhc8936k.png-1683392200863.png?download=1",
              size: 6060,
              type: "image/png",
              lastModified: 1681886474876,
              savedInVault: false,
              thumbnail: "/images/thumbnails/file.svg",
            },
            {
              name: "volume.png",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/volume_lhc8936l.png-1683392200852.png?download=1",
              size: 10511,
              type: "image/png",
              lastModified: 1681886468864,
              savedInVault: false,
              thumbnail: "/images/thumbnails/file.svg",
            },
          ],
          request: null,
        },
        {
          _id: "645882f9d194391a16f48b5e",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T05:04:57.330Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              name: "sitemap.xml",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/sitemap_lhedpbun.xml-1683522289079.xml?download=1",
              size: 7021,
              type: "text/xml",
              lastModified: 1682330527359,
              savedInVault: false,
              thumbnail: "/images/thumbnails/file.svg",
            },
          ],
          request: null,
        },
        {
          _id: "6458865a7b2817e6e68cc2e0",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T05:19:22.618Z",
          reactions: {
            "😊": ["113"],
            "❤️": ["113"],
          },
          replyMessage: {},
          attachments: [
            {
              name: "Sat Dec 24 2022 12_52_58 GMT+0530 (India Standard Time).csv",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Sat_Dec_24_2022_12_52_58_GMT_0530_India_Standard_Time_lhee6v9h.csv-1683523107411.csv?download=1",
              size: 899,
              type: "text/csv",
              lastModified: 1671866578040,
              savedInVault: false,
              thumbnail: "/images/thumbnails/file.svg",
            },
          ],
          request: null,
        },
        {
          _id: "6458871a7b2817e6e68cc2e1",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T05:22:34.603Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              name: "Flonnect_2022_12_27_1fde03c0_31c2_42cd_9ad6_9f70376e11fb_lc7meswt_lcegdinf.mp4-1672642865195.mp4_download=1.mp4",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Flonnect_2022_12_27_1fde03c0_31c2_42cd_9ad6_9f70376e11fb_lc7meswt_lcegdinf_lheebrwk.mp4-1683523336259.mp4?download=1",
              size: 2233489,
              type: "video/mp4",
              lastModified: 1673077975643,
              savedInVault: false,
              thumbnail: "/images/thumbnails/file.svg",
            },
          ],
          request: null,
        },
        {
          _id: "645887a07b2817e6e68cc2e2",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T05:24:48.221Z",
          reactions: {
            "😊": ["113"],
          },
          replyMessage: {},
          attachments: [
            {
              name: "Flonnect_2022_12_27_1fde03c0_31c2_42cd_9ad6_9f70376e11fb_lc7meswt_lcegdinf.mp4-1672642865195.mp4_download=1.mp4",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Flonnect_2022_12_27_1fde03c0_31c2_42cd_9ad6_9f70376e11fb_lc7meswt_lcegdinf_lheeegvh.mp4-1683523461912.mp4?download=1",
              size: 2233489,
              type: "video/mp4",
              lastModified: 1673077975643,
              savedInVault: false,
              thumbnail: "/images/thumbnails/file.svg",
            },
            {
              name: "Sat Dec 24 2022 12_52_58 GMT+0530 (India Standard Time).csv",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Sat_Dec_24_2022_12_52_58_GMT_0530_India_Standard_Time_lheeeq5g.csv-1683523473923.csv?download=1",
              size: 899,
              type: "text/csv",
              lastModified: 1671866578040,
              savedInVault: false,
              thumbnail: "/images/thumbnails/file.svg",
            },
          ],
          request: null,
        },
        {
          _id: "645895267b2817e6e68cc2e3",
          threadId: 330,
          message: "https://meet.google.com/any-zeue-cdq",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T06:22:30.206Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64589999de4543f9d1cc5655",
          threadId: 330,
          message:
            "https://www.figma.com/file/5lrDaMCHMAWfNI0yR7I8bC/WADDLE-V1.3--Library-%26-Messages-for-Developers?node-id=11037-127915&t=lZ5ozZKleuqJPk6f-0",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T06:41:29.070Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458df04e6bb1bdaf5646f3c",
          threadId: 330,
          message: "hii",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:37:40.214Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e2f2cea860599cedca3b",
          threadId: 330,
          message: "pleadse done this",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:26.859Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: {
            type: "Approval",
            status: "Closed",
            requestClosingDate: "2023-05-08T11:58:38.949Z",
            urgency: "Critical",
            deadlineDate: "2023-05-10",
            deadlineTime: "5:00:00 pm",
            activity: [
              {
                threadId: "330",
                message: "ok will do it",
                senderId: "48",
                senderName: "Ganesh Shah",
                createdAt: "2023-05-08T11:58:11.891Z",
                _id: "6458e3d3802bae7fc78bf23d",
                reactions: {},
                replyMessage: {},
                attachments: [],
                tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
                request: null,
              },
            ],
            updateCount: 1,
            updateSeenUsers: [],
          },
        },
        {
          _id: "6458e30ccea860599cedca3c",
          threadId: 330,
          message: "hi",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:52.758Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e30fcea860599cedca3d",
          threadId: 330,
          message: "fdvdf",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:55.471Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e30fcea860599cedca3e",
          threadId: 330,
          message: "fd",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:55.945Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e310cea860599cedca3f",
          threadId: 330,
          message: "fdvd",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:56.483Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e310cea860599cedca40",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:56.842Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e311cea860599cedca41",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:57.004Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e311cea860599cedca42",
          threadId: 330,
          message: "vdf",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:57.149Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e311cea860599cedca43",
          threadId: 330,
          message: "v",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:57.281Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e311cea860599cedca44",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:57.439Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e311cea860599cedca45",
          threadId: 330,
          message: "fdv",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:57.911Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e312cea860599cedca46",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:58.053Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e312cea860599cedca47",
          threadId: 330,
          message: "dfdscsd",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:58.349Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e312cea860599cedca48",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:58.634Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e312cea860599cedca49",
          threadId: 330,
          message: "fd",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:58.787Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e312cea860599cedca4a",
          threadId: 330,
          message: "v",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:58.903Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e313cea860599cedca4b",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:59.052Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e313cea860599cedca4c",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:59.206Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e313cea860599cedca4d",
          threadId: 330,
          message: "v",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:59.320Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e313cea860599cedca4e",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:59.461Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e313cea860599cedca4f",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:59.642Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6458e313cea860599cedca50",
          threadId: 330,
          message: "df",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-05-08T11:54:59.793Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "648afdbc9b79461f208fdd6e",
          threadId: 330,
          message: "hii",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-15T12:02:04.571Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "648c3c086f0c49e69e1660cb",
          threadId: 330,
          message: "okk will do it",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-16T10:40:08.761Z",
          reactions: {},
          replyMessage: {
            _id: "648afdbc9b79461f208fdd6e",
            threadId: 330,
            message: "hii",
            senderId: "113",
            senderName: "Preetinder Kalsi",
            createdAt: "2023-06-15T12:02:04.571Z",
            reactions: {},
            attachments: [],
            request: null,
          },
          attachments: [],
          request: null,
        },
        {
          _id: "648c8b9e6f0c49e69e1660cc",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-16T16:19:42.811Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              name: "big_buck_bunny.mp4",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/big_buck_bunny_liyryuod.mp4-1686932374579.mp4?download=1",
              size: 2239733,
              type: "video/mp4",
              lastModified: 1686932326370,
              savedInVault: false,
              thumbnail: "/images/thumbnails/file.svg",
            },
          ],
          request: null,
        },
        {
          _id: "648c95746f0c49e69e1660cd",
          threadId: 330,
          message:
            "@@@__113^^__Preetinder Kalsi (You)@@@^^^  hello this is link https://meet.google.com/",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-16T17:01:40.696Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64940ff8b4071fd196064042",
          threadId: 330,
          message: "Hiii23",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-22T09:10:17.115Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6494115ab4071fd196064045",
          threadId: 330,
          message: "Hello",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-22T09:16:10.913Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64942f18d9c0a4fc6c2a7c16",
          threadId: 330,
          message: "Hello",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-22T11:23:05.017Z",
          reactions: {},
          replyMessage: {
            _id: "6453e30454e98285db728452",
            threadId: 330,
            message: "hii",
            senderId: "48",
            senderName: "Ganesh Shah",
            createdAt: "2023-05-04T16:53:24.856Z",
            reactions: {},
            attachments: [],
          },
          attachments: [],
          request: null,
        },
        {
          _id: "6494478f799bad9dd6794f4d",
          threadId: 330,
          message: "Test forward message",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-22T13:07:28.142Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6495543d028e396c8ffb8c6c",
          threadId: 330,
          message: "Hello boho",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-23T08:13:49.677Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: {
            type: "Approval",
            status: "Closed",
            requestClosingDate: "2023-06-23T12:09:36.740Z",
            urgency: "Minor",
            deadlineDate: "2023-07-23",
            deadlineTime: "11:01 am",
            activity: [
              {
                threadId: "330",
                message: "Less ho",
                senderId: "113",
                senderName: "Preetinder Kalsi",
                createdAt: "2023-06-23T08:14:17.248Z",
                _id: "64955459028e396c8ffb8c6d",
                reactions: {},
                replyMessage: {},
                attachments: [],
                tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
                request: null,
              },
            ],
            updateCount: 1,
            updateSeenUsers: [],
          },
        },
        {
          _id: "64955485028e396c8ffb8c6e",
          threadId: 330,
          message: "Sahi",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-23T08:15:01.748Z",
          reactions: {},
          replyMessage: {
            threadId: "330",
            message: "Less ho",
            senderId: "113",
            senderName: "Preetinder Kalsi",
            createdAt: "2023-06-23T08:14:17.248Z",
            _id: "64955459028e396c8ffb8c6d",
            reactions: {},
            attachments: [],
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            request: null,
          },
          attachments: [],
          request: null,
        },
        {
          _id: "64958c0a34f69b7d7fe37519",
          threadId: 330,
          message: "Please do this asap",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-23T12:11:54.840Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: {
            type: "Feedback",
            status: "Open",
            requestClosingDate: null,
            urgency: "Critical",
            deadlineDate: "2023-06-23",
            deadlineTime: "05:40 pm",
            activity: [],
            updateCount: 0,
            updateSeenUsers: [],
          },
        },
        {
          _id: "6496f43b0bef6fad2747c22a",
          threadId: 330,
          message: "Hello  😄",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-24T13:48:43.353Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6496f67a0bef6fad2747c22b",
          threadId: 330,
          message: "@[Preetinder Kalsi (You)](113) hello",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-24T13:58:18.823Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649735d52f8c7bc36f693e08",
          threadId: 330,
          message: "Hello",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-24T18:28:37.170Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6497364e2f8c7bc36f693e09",
          threadId: 330,
          message: "Hii",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-24T18:30:38.147Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              uid: "Sat Jun 24 2023 23:41:06 GMT+0530 (India Standard Time)-2801B455-E6E6-405D-B9B8-A6DD948097B3.png",
              name: "2801B455-E6E6-405D-B9B8-A6DD948097B3.png",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/2801B455_E6E6_405D_B9B8_A6DD948097B3.png-1687630266874.png?download=1",
              fileUrl:
                "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/2801B455-E6E6-405D-B9B8-A6DD948097B3.png",
              percent: 100,
              type: "image",
              size: 121624,
              response: [
                [
                  {
                    fileName: "2801B455_E6E6_405D_B9B8_A6DD948097B3.png",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/2801B455_E6E6_405D_B9B8_A6DD948097B3.png-1687630266874.png?download=1",
                    thumbnail: {
                      fileName: "2801B455_E6E6_405D_B9B8_A6DD948097B3.png",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
            {
              uid: "Sat Jun 24 2023 23:54:24 GMT+0530 (India Standard Time)-141942CC-44FB-4B43-A032-2123D6A46556.jpg",
              name: "141942CC-44FB-4B43-A032-2123D6A46556.jpg",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/141942CC_44FB_4B43_A032_2123D6A46556.jpg-1687631064812.jpg?download=1",
              fileUrl:
                "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/141942CC-44FB-4B43-A032-2123D6A46556.jpg",
              percent: 100,
              type: "image",
              size: 13727237,
              response: [
                [
                  {
                    fileName: "141942CC_44FB_4B43_A032_2123D6A46556.jpg",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/141942CC_44FB_4B43_A032_2123D6A46556.jpg-1687631064812.jpg?download=1",
                    thumbnail: {
                      fileName: "141942CC_44FB_4B43_A032_2123D6A46556.jpg",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
            {
              uid: "Sat Jun 24 2023 23:57:15 GMT+0530 (India Standard Time)-50EA9201-8A12-4E5E-8F2B-A43A512B3372.jpg",
              name: "50EA9201-8A12-4E5E-8F2B-A43A512B3372.jpg",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/50EA9201_8A12_4E5E_8F2B_A43A512B3372.jpg-1687631235675.jpg?download=1",
              fileUrl:
                "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/50EA9201-8A12-4E5E-8F2B-A43A512B3372.jpg",
              percent: 100,
              type: "image",
              size: 3423356,
              response: [
                [
                  {
                    fileName: "50EA9201_8A12_4E5E_8F2B_A43A512B3372.jpg",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/50EA9201_8A12_4E5E_8F2B_A43A512B3372.jpg-1687631235675.jpg?download=1",
                    thumbnail: {
                      fileName: "50EA9201_8A12_4E5E_8F2B_A43A512B3372.jpg",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
            {
              uid: "Sat Jun 24 2023 23:58:03 GMT+0530 (India Standard Time)-C7487C92-7CC2-47DF-BAF0-024BBA1FD4AC.jpg",
              name: "C7487C92-7CC2-47DF-BAF0-024BBA1FD4AC.jpg",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/C7487C92_7CC2_47DF_BAF0_024BBA1FD4AC.jpg-1687631283995.jpg?download=1",
              fileUrl:
                "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/C7487C92-7CC2-47DF-BAF0-024BBA1FD4AC.jpg",
              percent: 100,
              type: "image",
              size: 2456699,
              response: [
                [
                  {
                    fileName: "C7487C92_7CC2_47DF_BAF0_024BBA1FD4AC.jpg",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/C7487C92_7CC2_47DF_BAF0_024BBA1FD4AC.jpg-1687631283995.jpg?download=1",
                    thumbnail: {
                      fileName: "C7487C92_7CC2_47DF_BAF0_024BBA1FD4AC.jpg",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
          ],
          request: null,
        },
        {
          _id: "6497da018ef62af249d70cfb",
          threadId: 330,
          message: "Hello",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T06:09:05.810Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6497da198ef62af249d70cfc",
          threadId: 330,
          message: "Hii",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T06:09:29.203Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6497dbe9598044c76920f37e",
          threadId: 330,
          message: "Hii",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T06:17:13.859Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6497dcf5598044c76920f380",
          threadId: 330,
          message: "Hellooo",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T06:21:41.329Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              uid: "Sun Jun 25 2023 11:51:30 GMT+0530 (India Standard Time)-CAF1CB99-4792-437A-9199-ABBBD38FC21B.png",
              name: "CAF1CB99-4792-437A-9199-ABBBD38FC21B.png",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/CAF1CB99_4792_437A_9199_ABBBD38FC21B.png-1687674090229.png?download=1",
              fileUrl:
                "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/CAF1CB99-4792-437A-9199-ABBBD38FC21B.png",
              percent: 100,
              type: "image/png",
              size: 121624,
              response: [
                [
                  {
                    fileName: "CAF1CB99_4792_437A_9199_ABBBD38FC21B.png",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/CAF1CB99_4792_437A_9199_ABBBD38FC21B.png-1687674090229.png?download=1",
                    thumbnail: {
                      fileName: "CAF1CB99_4792_437A_9199_ABBBD38FC21B.png",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
          ],
          request: null,
        },
        {
          _id: "64982f3bdd6ed545e3b98a54",
          threadId: 330,
          message: "He’ll",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T12:12:43.876Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6498399ddacab76836dadd0a",
          threadId: 330,
          message: "Hiii",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T12:57:01.443Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649839aedacab76836dadd0b",
          threadId: 330,
          message: "Vol big",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T12:57:18.027Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649839f6dacab76836dadd0c",
          threadId: 330,
          message: "Na na",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T12:58:31.054Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64983c52952e51f85ddeef02",
          threadId: 330,
          message: "Hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T13:08:34.983Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64983c95952e51f85ddeef03",
          threadId: 330,
          message: "Fftt",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T13:09:41.066Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64983ccfa6a6bdac5ecdec0c",
          threadId: 330,
          message: "Hii",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T13:10:39.155Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64983cda952e51f85ddeef04",
          threadId: 330,
          message: "Dred",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T13:10:50.307Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6498738e8c94eb31ad33f392",
          threadId: 330,
          message: "Hii",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T17:04:14.602Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64987416c4be6c68835d0326",
          threadId: 330,
          message: "Hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T17:06:30.795Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6498749bc4be6c68835d0328",
          threadId: 330,
          message: "Xaaa",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T17:08:43.684Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649874a58c94eb31ad33f393",
          threadId: 330,
          message: "Bhjj",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T17:08:53.595Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64987535c4be6c68835d0329",
          threadId: 330,
          message: "999",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T17:11:17.510Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649875c6c4be6c68835d032a",
          threadId: 330,
          message: "Ddd",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T17:13:42.149Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649875f8c4be6c68835d032b",
          threadId: 330,
          message: "Xxx",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T17:14:32.094Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64987621c4be6c68835d032c",
          threadId: 330,
          message: "Aaa",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T17:15:13.284Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649876908c94eb31ad33f394",
          threadId: 330,
          message: "Gggg",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-25T17:17:04.949Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649876b6c4be6c68835d032d",
          threadId: 330,
          message: "Jjj",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-25T17:17:42.251Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64993809f89b13c1aca01b06",
          threadId: 330,
          message: "Hello there",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-26T07:02:33.933Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64993819f89b13c1aca01b07",
          threadId: 330,
          message: "Less ho",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-26T07:02:49.261Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6499382bf89b13c1aca01b08",
          threadId: 330,
          message: "Gine",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-26T07:03:07.480Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649938d6f89b13c1aca01b09",
          threadId: 330,
          message: "Hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-26T07:05:58.471Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649944f400d1dc0b1db3ca2c",
          threadId: 330,
          message: "@[Preetinder Kalsi](113).   Do this asap",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-26T07:57:40.564Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: {
            type: "Approval",
            status: "Closed",
            requestClosingDate: "2023-06-26T07:58:14.119Z",
            urgency: "Medium",
            deadlineDate: "2023-06-26",
            deadlineTime: "01:18 pm",
            activity: [],
            updateCount: 0,
            updateSeenUsers: [],
          },
        },
        {
          _id: "649957ec54d67f70c3baa560",
          threadId: 330,
          message: "Hello",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-26T09:18:36.330Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649957ff54d67f70c3baa561",
          threadId: 330,
          message: "Hii",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-26T09:18:55.170Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "6499580754d67f70c3baa562",
          threadId: 330,
          message: "Less hai",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-26T09:19:03.119Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "649958920572e740022cf0b6",
          threadId: 330,
          message: "Sahi hai",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-26T09:21:22.790Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              uid: "Mon Jun 26 2023 14:51:05 GMT+0530-37b89cde-d245-483c-a2e3-ccdb87fce2f0.jpeg",
              name: "37b89cde-d245-483c-a2e3-ccdb87fce2f0.jpeg",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/37b89cde_d245_483c_a2e3_ccdb87fce2f0.jpeg-1687771267553.jpeg?download=1",
              fileUrl:
                "file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/37b89cde-d245-483c-a2e3-ccdb87fce2f0.jpeg",
              percent: 100,
              type: "image/jpeg",
              response: [
                [
                  {
                    fileName: "37b89cde_d245_483c_a2e3_ccdb87fce2f0.jpeg",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/37b89cde_d245_483c_a2e3_ccdb87fce2f0.jpeg-1687771267553.jpeg?download=1",
                    thumbnail: {
                      fileName: "37b89cde_d245_483c_a2e3_ccdb87fce2f0.jpeg",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
          ],
          request: null,
        },
        {
          _id: "64996f9f926d1e53ea7102f3",
          threadId: 330,
          message: "Hello",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-26T10:59:43.004Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64996fae926d1e53ea7102f4",
          threadId: 330,
          message: "Kesa hai",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-26T10:59:58.807Z",
          reactions: {
            "😊": ["48"],
          },
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64996fd054d67f70c3baa563",
          threadId: 330,
          message: "Mast mai au Ty bata",
          senderId: "113",
          senderName: "Preetinder Kalsi",
          createdAt: "2023-06-26T11:00:32.315Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64996ff9926d1e53ea7102f5",
          threadId: 330,
          message: "Hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-06-26T11:01:13.408Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a03b2901a057b7daaffeef",
          threadId: 330,
          message: "hello",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-01T14:41:45.630Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a03cb101a057b7daaffef0",
          threadId: 330,
          message: "hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-01T14:48:17.278Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a4548fe88ea2893ea812f8",
          threadId: 330,
          message: "hello in group",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-04T17:19:11.879Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a4550ce88ea2893ea812f9",
          threadId: 330,
          message: "hello in group",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-04T17:21:16.073Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a45bfbe3919e11e440f9f4",
          threadId: 330,
          message: "Thi",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-04T17:50:51.734Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              uid: "Tue Jul 04 2023 23:20:35 GMT+0530-60f5e264-81d7-4183-acee-abf9165a4f9a.jpeg",
              name: "60f5e264-81d7-4183-acee-abf9165a4f9a.jpeg",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/60f5e264_81d7_4183_acee_abf9165a4f9a.jpeg-1688493036723.jpeg?download=1",
              fileUrl:
                "file:///data/user/0/com.antarcticaglobal.ai.waddle/cache/ImagePicker/60f5e264-81d7-4183-acee-abf9165a4f9a.jpeg",
              percent: 100,
              type: "image/jpeg",
              response: [
                [
                  {
                    fileName: "60f5e264_81d7_4183_acee_abf9165a4f9a.jpeg",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/60f5e264_81d7_4183_acee_abf9165a4f9a.jpeg-1688493036723.jpeg?download=1",
                    thumbnail: {
                      fileName: "60f5e264_81d7_4183_acee_abf9165a4f9a.jpeg",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
          ],
          request: null,
        },
        {
          _id: "64a45d4512116838548cda57",
          threadId: 330,
          message: "jjjjjjjjjjj",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-04T17:56:21.197Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a5400e9d58eb7772413a34",
          threadId: 330,
          message: "Hey",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-05T10:03:58.318Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              uid: "Wed Jul 05 2023 15:33:39 GMT+0530-86e93a4e-33d3-4b3c-afde-03ba217c5724.jpeg",
              name: "86e93a4e-33d3-4b3c-afde-03ba217c5724.jpeg",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/86e93a4e_33d3_4b3c_afde_03ba217c5724.jpeg-1688551422084.jpeg?download=1",
              fileUrl:
                "file:///data/user/0/com.antarcticaglobal.ai.waddle/cache/ImagePicker/86e93a4e-33d3-4b3c-afde-03ba217c5724.jpeg",
              percent: 100,
              type: "image/jpeg",
              response: [
                [
                  {
                    fileName: "86e93a4e_33d3_4b3c_afde_03ba217c5724.jpeg",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/86e93a4e_33d3_4b3c_afde_03ba217c5724.jpeg-1688551422084.jpeg?download=1",
                    thumbnail: {
                      fileName: "86e93a4e_33d3_4b3c_afde_03ba217c5724.jpeg",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
          ],
        },
        {
          _id: "64a5402e9d58eb7772413a35",
          threadId: 330,
          message: "Hii",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-05T10:04:30.544Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
        },
        {
          _id: "64a540b6291acbd567cb9641",
          threadId: 330,
          message: "hello",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T10:06:47.071Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a54232291acbd567cb9646",
          threadId: 330,
          message: "hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T10:13:06.889Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a5424f291acbd567cb9649",
          threadId: 330,
          message: "imp",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T10:13:35.764Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a5425e291acbd567cb964a",
          threadId: 330,
          message: "imp",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T10:13:50.406Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a54264291acbd567cb964b",
          threadId: 330,
          message: "this is imp",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T10:13:56.906Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a5426c291acbd567cb964c",
          threadId: 330,
          message: "really imp",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T10:14:04.967Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a54278291acbd567cb964d",
          threadId: 330,
          message: "fdvdfb",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T10:14:17.186Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a54280291acbd567cb964e",
          threadId: 330,
          message: "122",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T10:14:24.712Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a54289291acbd567cb964f",
          threadId: 330,
          message: "123",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T10:14:33.298Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a543c11527143b8a520bbf",
          threadId: 330,
          message: "@@@__264^^__Anushka Gaonkar@@@^^^  check request modal",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-05T10:19:45.877Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: {
            type: "Attachment",
            status: "Open",
            requestClosingDate: null,
            urgency: "",
            deadlineDate: null,
            deadlineTime: "null:00:00 am",
            activity: [],
            updateCount: 0,
            updateSeenUsers: [],
          },
        },
        {
          _id: "64a55b23291acbd567cb9652",
          threadId: 330,
          message: "hello",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T11:59:32.225Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a55b49291acbd567cb9653",
          threadId: 330,
          message: "hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-05T12:00:09.781Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a55e2f0e24c9c7c8e26192",
          threadId: 330,
          message: "hii",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-05T12:12:31.467Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a55e7b712838b0e7f3f1d7",
          threadId: 330,
          message: "hii",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-05T12:13:47.171Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64a55ec2382ff1c52ef6ad4e",
          threadId: 330,
          message: "jii",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-05T12:14:58.446Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa58f097517752096ca73c",
          threadId: 330,
          message: "hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T06:51:28.146Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa58fe97517752096ca73d",
          threadId: 330,
          message: "hwllo",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T06:51:42.271Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa591a97517752096ca73e",
          threadId: 330,
          message: "kese ho",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T06:52:10.507Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa593697517752096ca73f",
          threadId: 330,
          message: "ki",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T06:52:38.497Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa5a9197517752096ca745",
          threadId: 330,
          message: "noo\\",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T06:58:25.281Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa5b9d97517752096ca748",
          threadId: 330,
          message: "jjj",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:02:53.345Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa5bee97517752096ca749",
          threadId: 330,
          message: "jjj",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:04:14.871Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa5bf797517752096ca74a",
          threadId: 330,
          message: "asdfghjk",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:04:23.345Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa5c0997517752096ca74c",
          threadId: 330,
          message: "jjj",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:04:41.492Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa5d9e97517752096ca74d",
          threadId: 330,
          message: "hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:11:26.335Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa5daf97517752096ca74e",
          threadId: 330,
          message: "uu",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:11:43.145Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa5ddd97517752096ca751",
          threadId: 330,
          message: "hii",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:12:29.533Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa60d997517752096ca75c",
          threadId: 330,
          message: "xyz",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:25:13.218Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa62c197517752096ca75f",
          threadId: 330,
          message: "xxxx",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:33:21.336Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa62d197517752096ca760",
          threadId: 330,
          message: "hjbjhv",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:33:37.381Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64aa62e997517752096ca761",
          threadId: 330,
          message: "jjjjj",
          senderId: "48",
          senderName: "Ganesh Shah",
          createdAt: "2023-07-09T07:34:01.113Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b122b4169d805e50016555",
          threadId: 330,
          message: "hh",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:25:56.352Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b12355169d805e50016556",
          threadId: 330,
          message: "hhh",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:28:37.352Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b123b9169d805e50016557",
          threadId: 330,
          message: "hii",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:30:17.327Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b12436169d805e50016558",
          threadId: 330,
          message: "jjj",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:32:22.891Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b124c2169d805e50016559",
          threadId: 330,
          message: "jj",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:34:42.180Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b1254d169d805e5001655a",
          threadId: 330,
          message: "hii",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:37:01.275Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b12578169d805e5001655b",
          threadId: 330,
          message: "hi",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:37:44.022Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b12628169d805e5001655c",
          threadId: 330,
          message: "hii",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:40:40.052Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b126c0169d805e5001655d",
          threadId: 330,
          message: "hii",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:43:12.702Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b126d8169d805e5001655e",
          threadId: 330,
          message: "ccc",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T10:43:36.786Z",
          reactions: {
            "😊": ["113"],
          },
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b12c96169d805e5001655f",
          threadId: 330,
          message: "Hello",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-14T11:08:06.564Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: {
            type: "Attachment",
            status: "Open",
            requestClosingDate: null,
            urgency: "Major",
            deadlineDate: null,
            deadlineTime: "12:01:00 am",
            activity: [],
            updateCount: 0,
            updateSeenUsers: [],
          },
        },
        {
          _id: "64b77e568d06f814ca9338b0",
          threadId: 330,
          message: "Test forward",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-19T06:10:30.246Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b7b8a7b35456df2ef32866",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-19T10:19:19.601Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              uid: "Wed Jul 19 2023 15:49:10 GMT+0530-6b263896-e17d-4b2b-954d-874e75daa74c.jpeg",
              name: "6b263896-e17d-4b2b-954d-874e75daa74c.jpeg",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/6b263896_e17d_4b2b_954d_874e75daa74c.jpeg-1689761951847.jpeg?download=1",
              fileUrl:
                "file:///data/user/0/com.antarcticaglobal.waddle.ai/cache/ImagePicker/6b263896-e17d-4b2b-954d-874e75daa74c.jpeg",
              percent: 100,
              type: "image/jpeg",
              response: [
                [
                  {
                    fileName: "6b263896_e17d_4b2b_954d_874e75daa74c.jpeg",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/6b263896_e17d_4b2b_954d_874e75daa74c.jpeg-1689761951847.jpeg?download=1",
                    thumbnail: {
                      fileName: "6b263896_e17d_4b2b_954d_874e75daa74c.jpeg",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
          ],
          request: null,
        },
        {
          _id: "64b7bc026914ff8efb4d1f52",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-19T10:33:38.603Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              uid: "Wed Jul 19 2023 16:03:26 GMT+0530-VID-20230719-WA0014.mp4",
              name: "VID-20230719-WA0014.mp4",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/VID_20230719_WA0014.mp4-1689762809360.mp4?download=1",
              fileUrl:
                "file:///data/user/0/com.antarcticaglobal.waddle.ai/cache/DocumentPicker/a9b51739-1c35-4654-b1cd-cc1adb56d0cb.mp4",
              percent: 100,
              type: "video/mp4",
              size: 6382115,
              response: [
                [
                  {
                    fileName: "VID_20230719_WA0014.mp4",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/VID_20230719_WA0014.mp4-1689762809360.mp4?download=1",
                    thumbnail: {
                      fileName: "VID_20230719_WA0014.mp4",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
          ],
          request: null,
        },
        {
          _id: "64b7bcb06914ff8efb4d1f53",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-19T10:36:33.212Z",
          reactions: {
            "😊": [],
            "😆": ["113"],
          },
          replyMessage: {},
          attachments: [
            {
              uid: "Wed Jul 19 2023 16:06:25 GMT+0530-Tushar Thorat.pdf",
              name: "Tushar Thorat.pdf",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Tushar_Thorat.pdf-1689762987675.pdf?download=1",
              fileUrl:
                "file:///data/user/0/com.antarcticaglobal.waddle.ai/cache/DocumentPicker/0682d387-117d-46c3-9eab-eb979072b305.pdf",
              percent: 100,
              type: "application/pdf",
              size: 294154,
              response: [
                [
                  {
                    fileName: "Tushar_Thorat.pdf",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Tushar_Thorat.pdf-1689762987675.pdf?download=1",
                    thumbnail: {
                      fileName: "Tushar_Thorat.pdf",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
          ],
          request: null,
        },
        {
          _id: "64b7c20ea247fe2cf6a14da2",
          threadId: 330,
          message: "Njjj",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-19T10:59:26.245Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b7c24ba247fe2cf6a14da3",
          threadId: 330,
          message: "Hhhj",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-19T11:00:27.447Z",
          reactions: {},
          replyMessage: {},
          attachments: [],
          request: null,
        },
        {
          _id: "64b7c55fa247fe2cf6a14da4",
          threadId: 330,
          message: "",
          senderId: "113",
          senderName: "Ganesh Mohanty",
          createdAt: "2023-07-19T11:13:35.722Z",
          reactions: {},
          replyMessage: {},
          attachments: [
            {
              uid: "Wed Jul 19 2023 16:43:28 GMT+0530-Tushar Thorat.pdf",
              name: "Tushar Thorat.pdf",
              status: "done",
              url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Tushar_Thorat.pdf-1689765211084.pdf?download=1",
              fileUrl:
                "file:///data/user/0/com.antarcticaglobal.waddle.ai/cache/DocumentPicker/c3affbbc-4b81-45e2-bba7-99e255a65cd6.pdf",
              percent: 100,
              type: "application/pdf",
              size: 294154,
              response: [
                [
                  {
                    fileName: "Tushar_Thorat.pdf",
                    fileUrl:
                      "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Tushar_Thorat.pdf-1689765211084.pdf?download=1",
                    thumbnail: {
                      fileName: "Tushar_Thorat.pdf",
                      fileUrl: "/images/thumbnails/file.svg",
                    },
                  },
                ],
              ],
            },
          ],
          request: null,
        },
      ],
    },
    {
      threadId: "332",
      threadName: "PGK",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Shah",
      createdAt: "2023-05-11T06:42:56.393Z",
      createdById: "48",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: "Ganesh Shah : jiii",
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: "345",
      lastMessageTime: "2023-07-09T01:42:11.766Z",
    },
    {
      threadId: "338",
      threadName: "Project Create",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Mohanty",
      createdAt: "2023-06-25T03:31:54.963Z",
      createdById: "113",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: null,
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: null,
      lastMessageTime: null,
    },
    {
      threadId: "335",
      threadName: "Team Go",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Mohanty",
      createdAt: "2023-06-25T01:03:12.104Z",
      createdById: "113",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: "Ganesh Shah : hiii",
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: "344",
      lastMessageTime: "2023-07-09T02:38:28.786Z",
    },
    {
      threadId: "329",
      threadName: "Antarctica website",
      newScope: 0,
      firstname: "Ganesh",
      lastname: "Mohanty",
      createdAt: "2023-05-03T05:27:17.107Z",
      createdById: "113",
      isApproved: 1,
      read: 1,
      isPinned: 0,
      isDashboard: 0,
      unreadCount: "0",
      lastMessage: null,
      phaseId: "1",
      description: null,
      isMute: 0,
      projectId: "367",
      lastMessageTime: null,
    },
  ],
  selectedUsers: [],
  editThread: false,
  suggestedThread: false,
  threadMembers: [
    {
      userId: "48",
      firstname: "Ganesh",
      lastname: "Shah",
      email: "ganesh@antarcticaglobal.com",
      mobile: null,
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/44776.jpg-1660301953228.jpg?download=1",
      systemRole: "2",
      systemRoleTitle: "Admin",
    },
    {
      userId: "113",
      firstname: "Ganesh",
      lastname: "Mohanty",
      email: "preetinder@antarcticaglobal.com",
      mobile: "7039734268",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
      systemRole: "1",
      systemRoleTitle: "Super Admin",
    },
  ],
  editThreadDetails: {
    threadId: "",
    threadName: "",
    description: "",
    phaseId: "",
    users: ["0"],
    firstname: "",
    lastname: "",
    profilePicture: "",
    createdAt: "10-10-9999",
    userToBeAdded: "",
    projectId: "",
  },
  leaveThreadDetails: {
    threadId: "",
  },
  currentThread: {
    threadId: "330",
    threadName: "Alibaug ",
    newScope: 0,
    firstname: "Ganesh",
    lastname: "Mohanty",
    createdAt: "2023-05-03T05:36:39.460Z",
    createdById: "113",
    isApproved: 1,
    read: 1,
    isPinned: 1,
    isDashboard: 0,
    unreadCount: "0",
    lastMessage: "You : ",
    phaseId: "1",
    description: null,
    isMute: 0,
    projectId: null,
    lastMessageTime: "2023-07-19T05:43:36.178Z",
    type: "group",
    limit: 20,
    offset: 0,
  },
  currentThreadMessage: [
    {
      _id: "6453e1b2dcd9fdaa27e2c665",
      threadId: 330,
      message: "hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-05-04T16:47:46.608Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
    },
    {
      _id: "6453e2c054e98285db728450",
      threadId: 330,
      message: "jjj",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-05-04T16:52:16.719Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
    },
    {
      _id: "6453e2e154e98285db728451",
      threadId: 330,
      message: "jjj",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-05-04T16:52:49.533Z",
      reactions: {
        "😊": ["48"],
      },
      replyMessage: {},
      attachments: [],
    },
    {
      _id: "6453e30454e98285db728452",
      threadId: 330,
      message: "hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-05-04T16:53:24.856Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
    },
    {
      _id: "6453e31454e98285db728453",
      threadId: 330,
      message: "kkk",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-05-04T16:53:40.610Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
    },
    {
      _id: "645686cdf6e8bcba5ba7e0d7",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-06T16:56:45.617Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          name: "volume (1).png",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/volume_1_lhc8936k.png-1683392200863.png?download=1",
          size: 6060,
          type: "image/png",
          lastModified: 1681886474876,
          savedInVault: false,
          thumbnail: "/images/thumbnails/file.svg",
        },
        {
          name: "volume.png",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/volume_lhc8936l.png-1683392200852.png?download=1",
          size: 10511,
          type: "image/png",
          lastModified: 1681886468864,
          savedInVault: false,
          thumbnail: "/images/thumbnails/file.svg",
        },
      ],
      request: null,
    },
    {
      _id: "645882f9d194391a16f48b5e",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T05:04:57.330Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          name: "sitemap.xml",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/sitemap_lhedpbun.xml-1683522289079.xml?download=1",
          size: 7021,
          type: "text/xml",
          lastModified: 1682330527359,
          savedInVault: false,
          thumbnail: "/images/thumbnails/file.svg",
        },
      ],
      request: null,
    },
    {
      _id: "6458865a7b2817e6e68cc2e0",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T05:19:22.618Z",
      reactions: {
        "😊": ["113"],
        "❤️": ["113"],
      },
      replyMessage: {},
      attachments: [
        {
          name: "Sat Dec 24 2022 12_52_58 GMT+0530 (India Standard Time).csv",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Sat_Dec_24_2022_12_52_58_GMT_0530_India_Standard_Time_lhee6v9h.csv-1683523107411.csv?download=1",
          size: 899,
          type: "text/csv",
          lastModified: 1671866578040,
          savedInVault: false,
          thumbnail: "/images/thumbnails/file.svg",
        },
      ],
      request: null,
    },
    {
      _id: "6458871a7b2817e6e68cc2e1",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T05:22:34.603Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          name: "Flonnect_2022_12_27_1fde03c0_31c2_42cd_9ad6_9f70376e11fb_lc7meswt_lcegdinf.mp4-1672642865195.mp4_download=1.mp4",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Flonnect_2022_12_27_1fde03c0_31c2_42cd_9ad6_9f70376e11fb_lc7meswt_lcegdinf_lheebrwk.mp4-1683523336259.mp4?download=1",
          size: 2233489,
          type: "video/mp4",
          lastModified: 1673077975643,
          savedInVault: false,
          thumbnail: "/images/thumbnails/file.svg",
        },
      ],
      request: null,
    },
    {
      _id: "645887a07b2817e6e68cc2e2",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T05:24:48.221Z",
      reactions: {
        "😊": ["113"],
      },
      replyMessage: {},
      attachments: [
        {
          name: "Flonnect_2022_12_27_1fde03c0_31c2_42cd_9ad6_9f70376e11fb_lc7meswt_lcegdinf.mp4-1672642865195.mp4_download=1.mp4",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Flonnect_2022_12_27_1fde03c0_31c2_42cd_9ad6_9f70376e11fb_lc7meswt_lcegdinf_lheeegvh.mp4-1683523461912.mp4?download=1",
          size: 2233489,
          type: "video/mp4",
          lastModified: 1673077975643,
          savedInVault: false,
          thumbnail: "/images/thumbnails/file.svg",
        },
        {
          name: "Sat Dec 24 2022 12_52_58 GMT+0530 (India Standard Time).csv",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Sat_Dec_24_2022_12_52_58_GMT_0530_India_Standard_Time_lheeeq5g.csv-1683523473923.csv?download=1",
          size: 899,
          type: "text/csv",
          lastModified: 1671866578040,
          savedInVault: false,
          thumbnail: "/images/thumbnails/file.svg",
        },
      ],
      request: null,
    },
    {
      _id: "645895267b2817e6e68cc2e3",
      threadId: 330,
      message: "https://meet.google.com/any-zeue-cdq",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T06:22:30.206Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64589999de4543f9d1cc5655",
      threadId: 330,
      message:
        "https://www.figma.com/file/5lrDaMCHMAWfNI0yR7I8bC/WADDLE-V1.3--Library-%26-Messages-for-Developers?node-id=11037-127915&t=lZ5ozZKleuqJPk6f-0",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T06:41:29.070Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458df04e6bb1bdaf5646f3c",
      threadId: 330,
      message: "hii",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:37:40.214Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e2f2cea860599cedca3b",
      threadId: 330,
      message: "pleadse done this",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:26.859Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: {
        type: "Approval",
        status: "Closed",
        requestClosingDate: "2023-05-08T11:58:38.949Z",
        urgency: "Critical",
        deadlineDate: "2023-05-10",
        deadlineTime: "5:00:00 pm",
        activity: [
          {
            threadId: "330",
            message: "ok will do it",
            senderId: "48",
            senderName: "Ganesh Shah",
            createdAt: "2023-05-08T11:58:11.891Z",
            _id: "6458e3d3802bae7fc78bf23d",
            reactions: {},
            replyMessage: {},
            attachments: [],
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            request: null,
          },
        ],
        updateCount: 1,
        updateSeenUsers: [],
      },
    },
    {
      _id: "6458e30ccea860599cedca3c",
      threadId: 330,
      message: "hi",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:52.758Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e30fcea860599cedca3d",
      threadId: 330,
      message: "fdvdf",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:55.471Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e30fcea860599cedca3e",
      threadId: 330,
      message: "fd",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:55.945Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e310cea860599cedca3f",
      threadId: 330,
      message: "fdvd",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:56.483Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e310cea860599cedca40",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:56.842Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e311cea860599cedca41",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:57.004Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e311cea860599cedca42",
      threadId: 330,
      message: "vdf",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:57.149Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e311cea860599cedca43",
      threadId: 330,
      message: "v",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:57.281Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e311cea860599cedca44",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:57.439Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e311cea860599cedca45",
      threadId: 330,
      message: "fdv",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:57.911Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e312cea860599cedca46",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:58.053Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e312cea860599cedca47",
      threadId: 330,
      message: "dfdscsd",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:58.349Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e312cea860599cedca48",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:58.634Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e312cea860599cedca49",
      threadId: 330,
      message: "fd",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:58.787Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e312cea860599cedca4a",
      threadId: 330,
      message: "v",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:58.903Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e313cea860599cedca4b",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:59.052Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e313cea860599cedca4c",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:59.206Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e313cea860599cedca4d",
      threadId: 330,
      message: "v",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:59.320Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e313cea860599cedca4e",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:59.461Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e313cea860599cedca4f",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:59.642Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6458e313cea860599cedca50",
      threadId: 330,
      message: "df",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-05-08T11:54:59.793Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "648afdbc9b79461f208fdd6e",
      threadId: 330,
      message: "hii",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-15T12:02:04.571Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "648c3c086f0c49e69e1660cb",
      threadId: 330,
      message: "okk will do it",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-16T10:40:08.761Z",
      reactions: {},
      replyMessage: {
        _id: "648afdbc9b79461f208fdd6e",
        threadId: 330,
        message: "hii",
        senderId: "113",
        senderName: "Preetinder Kalsi",
        createdAt: "2023-06-15T12:02:04.571Z",
        reactions: {},
        attachments: [],
        request: null,
      },
      attachments: [],
      request: null,
    },
    {
      _id: "648c8b9e6f0c49e69e1660cc",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-16T16:19:42.811Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          name: "big_buck_bunny.mp4",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/big_buck_bunny_liyryuod.mp4-1686932374579.mp4?download=1",
          size: 2239733,
          type: "video/mp4",
          lastModified: 1686932326370,
          savedInVault: false,
          thumbnail: "/images/thumbnails/file.svg",
        },
      ],
      request: null,
    },
    {
      _id: "648c95746f0c49e69e1660cd",
      threadId: 330,
      message:
        "@@@__113^^__Preetinder Kalsi (You)@@@^^^  hello this is link https://meet.google.com/",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-16T17:01:40.696Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64940ff8b4071fd196064042",
      threadId: 330,
      message: "Hiii23",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-22T09:10:17.115Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6494115ab4071fd196064045",
      threadId: 330,
      message: "Hello",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-22T09:16:10.913Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64942f18d9c0a4fc6c2a7c16",
      threadId: 330,
      message: "Hello",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-22T11:23:05.017Z",
      reactions: {},
      replyMessage: {
        _id: "6453e30454e98285db728452",
        threadId: 330,
        message: "hii",
        senderId: "48",
        senderName: "Ganesh Shah",
        createdAt: "2023-05-04T16:53:24.856Z",
        reactions: {},
        attachments: [],
      },
      attachments: [],
      request: null,
    },
    {
      _id: "6494478f799bad9dd6794f4d",
      threadId: 330,
      message: "Test forward message",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-22T13:07:28.142Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6495543d028e396c8ffb8c6c",
      threadId: 330,
      message: "Hello boho",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-23T08:13:49.677Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: {
        type: "Approval",
        status: "Closed",
        requestClosingDate: "2023-06-23T12:09:36.740Z",
        urgency: "Minor",
        deadlineDate: "2023-07-23",
        deadlineTime: "11:01 am",
        activity: [
          {
            threadId: "330",
            message: "Less ho",
            senderId: "113",
            senderName: "Preetinder Kalsi",
            createdAt: "2023-06-23T08:14:17.248Z",
            _id: "64955459028e396c8ffb8c6d",
            reactions: {},
            replyMessage: {},
            attachments: [],
            tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
            request: null,
          },
        ],
        updateCount: 1,
        updateSeenUsers: [],
      },
    },
    {
      _id: "64955485028e396c8ffb8c6e",
      threadId: 330,
      message: "Sahi",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-23T08:15:01.748Z",
      reactions: {},
      replyMessage: {
        threadId: "330",
        message: "Less ho",
        senderId: "113",
        senderName: "Preetinder Kalsi",
        createdAt: "2023-06-23T08:14:17.248Z",
        _id: "64955459028e396c8ffb8c6d",
        reactions: {},
        attachments: [],
        tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
        request: null,
      },
      attachments: [],
      request: null,
    },
    {
      _id: "64958c0a34f69b7d7fe37519",
      threadId: 330,
      message: "Please do this asap",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-23T12:11:54.840Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: {
        type: "Feedback",
        status: "Open",
        requestClosingDate: null,
        urgency: "Critical",
        deadlineDate: "2023-06-23",
        deadlineTime: "05:40 pm",
        activity: [],
        updateCount: 0,
        updateSeenUsers: [],
      },
    },
    {
      _id: "6496f43b0bef6fad2747c22a",
      threadId: 330,
      message: "Hello  😄",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-24T13:48:43.353Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6496f67a0bef6fad2747c22b",
      threadId: 330,
      message: "@[Preetinder Kalsi (You)](113) hello",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-24T13:58:18.823Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649735d52f8c7bc36f693e08",
      threadId: 330,
      message: "Hello",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-24T18:28:37.170Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6497364e2f8c7bc36f693e09",
      threadId: 330,
      message: "Hii",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-24T18:30:38.147Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          uid: "Sat Jun 24 2023 23:41:06 GMT+0530 (India Standard Time)-2801B455-E6E6-405D-B9B8-A6DD948097B3.png",
          name: "2801B455-E6E6-405D-B9B8-A6DD948097B3.png",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/2801B455_E6E6_405D_B9B8_A6DD948097B3.png-1687630266874.png?download=1",
          fileUrl:
            "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/2801B455-E6E6-405D-B9B8-A6DD948097B3.png",
          percent: 100,
          type: "image",
          size: 121624,
          response: [
            [
              {
                fileName: "2801B455_E6E6_405D_B9B8_A6DD948097B3.png",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/2801B455_E6E6_405D_B9B8_A6DD948097B3.png-1687630266874.png?download=1",
                thumbnail: {
                  fileName: "2801B455_E6E6_405D_B9B8_A6DD948097B3.png",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
        {
          uid: "Sat Jun 24 2023 23:54:24 GMT+0530 (India Standard Time)-141942CC-44FB-4B43-A032-2123D6A46556.jpg",
          name: "141942CC-44FB-4B43-A032-2123D6A46556.jpg",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/141942CC_44FB_4B43_A032_2123D6A46556.jpg-1687631064812.jpg?download=1",
          fileUrl:
            "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/141942CC-44FB-4B43-A032-2123D6A46556.jpg",
          percent: 100,
          type: "image",
          size: 13727237,
          response: [
            [
              {
                fileName: "141942CC_44FB_4B43_A032_2123D6A46556.jpg",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/141942CC_44FB_4B43_A032_2123D6A46556.jpg-1687631064812.jpg?download=1",
                thumbnail: {
                  fileName: "141942CC_44FB_4B43_A032_2123D6A46556.jpg",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
        {
          uid: "Sat Jun 24 2023 23:57:15 GMT+0530 (India Standard Time)-50EA9201-8A12-4E5E-8F2B-A43A512B3372.jpg",
          name: "50EA9201-8A12-4E5E-8F2B-A43A512B3372.jpg",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/50EA9201_8A12_4E5E_8F2B_A43A512B3372.jpg-1687631235675.jpg?download=1",
          fileUrl:
            "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/50EA9201-8A12-4E5E-8F2B-A43A512B3372.jpg",
          percent: 100,
          type: "image",
          size: 3423356,
          response: [
            [
              {
                fileName: "50EA9201_8A12_4E5E_8F2B_A43A512B3372.jpg",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/50EA9201_8A12_4E5E_8F2B_A43A512B3372.jpg-1687631235675.jpg?download=1",
                thumbnail: {
                  fileName: "50EA9201_8A12_4E5E_8F2B_A43A512B3372.jpg",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
        {
          uid: "Sat Jun 24 2023 23:58:03 GMT+0530 (India Standard Time)-C7487C92-7CC2-47DF-BAF0-024BBA1FD4AC.jpg",
          name: "C7487C92-7CC2-47DF-BAF0-024BBA1FD4AC.jpg",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/C7487C92_7CC2_47DF_BAF0_024BBA1FD4AC.jpg-1687631283995.jpg?download=1",
          fileUrl:
            "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/C7487C92-7CC2-47DF-BAF0-024BBA1FD4AC.jpg",
          percent: 100,
          type: "image",
          size: 2456699,
          response: [
            [
              {
                fileName: "C7487C92_7CC2_47DF_BAF0_024BBA1FD4AC.jpg",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/C7487C92_7CC2_47DF_BAF0_024BBA1FD4AC.jpg-1687631283995.jpg?download=1",
                thumbnail: {
                  fileName: "C7487C92_7CC2_47DF_BAF0_024BBA1FD4AC.jpg",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
      ],
      request: null,
    },
    {
      _id: "6497da018ef62af249d70cfb",
      threadId: 330,
      message: "Hello",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T06:09:05.810Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6497da198ef62af249d70cfc",
      threadId: 330,
      message: "Hii",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T06:09:29.203Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6497dbe9598044c76920f37e",
      threadId: 330,
      message: "Hii",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T06:17:13.859Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6497dcf5598044c76920f380",
      threadId: 330,
      message: "Hellooo",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T06:21:41.329Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          uid: "Sun Jun 25 2023 11:51:30 GMT+0530 (India Standard Time)-CAF1CB99-4792-437A-9199-ABBBD38FC21B.png",
          name: "CAF1CB99-4792-437A-9199-ABBBD38FC21B.png",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/CAF1CB99_4792_437A_9199_ABBBD38FC21B.png-1687674090229.png?download=1",
          fileUrl:
            "file:///Users/vikasdwivedi/Library/Developer/CoreSimulator/Devices/C785BAF8-B7CD-4847-9F6F-7F81D1850DDC/data/Containers/Data/Application/C31DBD75-3672-4622-A5FB-35ACA34738F8/Library/Caches/ExponentExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/CAF1CB99-4792-437A-9199-ABBBD38FC21B.png",
          percent: 100,
          type: "image/png",
          size: 121624,
          response: [
            [
              {
                fileName: "CAF1CB99_4792_437A_9199_ABBBD38FC21B.png",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/CAF1CB99_4792_437A_9199_ABBBD38FC21B.png-1687674090229.png?download=1",
                thumbnail: {
                  fileName: "CAF1CB99_4792_437A_9199_ABBBD38FC21B.png",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
      ],
      request: null,
    },
    {
      _id: "64982f3bdd6ed545e3b98a54",
      threadId: 330,
      message: "He’ll",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T12:12:43.876Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6498399ddacab76836dadd0a",
      threadId: 330,
      message: "Hiii",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T12:57:01.443Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649839aedacab76836dadd0b",
      threadId: 330,
      message: "Vol big",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T12:57:18.027Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649839f6dacab76836dadd0c",
      threadId: 330,
      message: "Na na",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T12:58:31.054Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64983c52952e51f85ddeef02",
      threadId: 330,
      message: "Hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T13:08:34.983Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64983c95952e51f85ddeef03",
      threadId: 330,
      message: "Fftt",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T13:09:41.066Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64983ccfa6a6bdac5ecdec0c",
      threadId: 330,
      message: "Hii",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T13:10:39.155Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64983cda952e51f85ddeef04",
      threadId: 330,
      message: "Dred",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T13:10:50.307Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6498738e8c94eb31ad33f392",
      threadId: 330,
      message: "Hii",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T17:04:14.602Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64987416c4be6c68835d0326",
      threadId: 330,
      message: "Hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T17:06:30.795Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6498749bc4be6c68835d0328",
      threadId: 330,
      message: "Xaaa",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T17:08:43.684Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649874a58c94eb31ad33f393",
      threadId: 330,
      message: "Bhjj",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T17:08:53.595Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64987535c4be6c68835d0329",
      threadId: 330,
      message: "999",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T17:11:17.510Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649875c6c4be6c68835d032a",
      threadId: 330,
      message: "Ddd",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T17:13:42.149Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649875f8c4be6c68835d032b",
      threadId: 330,
      message: "Xxx",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T17:14:32.094Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64987621c4be6c68835d032c",
      threadId: 330,
      message: "Aaa",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T17:15:13.284Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649876908c94eb31ad33f394",
      threadId: 330,
      message: "Gggg",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-25T17:17:04.949Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649876b6c4be6c68835d032d",
      threadId: 330,
      message: "Jjj",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-25T17:17:42.251Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64993809f89b13c1aca01b06",
      threadId: 330,
      message: "Hello there",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-26T07:02:33.933Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64993819f89b13c1aca01b07",
      threadId: 330,
      message: "Less ho",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-26T07:02:49.261Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6499382bf89b13c1aca01b08",
      threadId: 330,
      message: "Gine",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-26T07:03:07.480Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649938d6f89b13c1aca01b09",
      threadId: 330,
      message: "Hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-26T07:05:58.471Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649944f400d1dc0b1db3ca2c",
      threadId: 330,
      message: "@[Preetinder Kalsi](113).   Do this asap",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-26T07:57:40.564Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: {
        type: "Approval",
        status: "Closed",
        requestClosingDate: "2023-06-26T07:58:14.119Z",
        urgency: "Medium",
        deadlineDate: "2023-06-26",
        deadlineTime: "01:18 pm",
        activity: [],
        updateCount: 0,
        updateSeenUsers: [],
      },
    },
    {
      _id: "649957ec54d67f70c3baa560",
      threadId: 330,
      message: "Hello",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-26T09:18:36.330Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649957ff54d67f70c3baa561",
      threadId: 330,
      message: "Hii",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-26T09:18:55.170Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "6499580754d67f70c3baa562",
      threadId: 330,
      message: "Less hai",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-26T09:19:03.119Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "649958920572e740022cf0b6",
      threadId: 330,
      message: "Sahi hai",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-26T09:21:22.790Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          uid: "Mon Jun 26 2023 14:51:05 GMT+0530-37b89cde-d245-483c-a2e3-ccdb87fce2f0.jpeg",
          name: "37b89cde-d245-483c-a2e3-ccdb87fce2f0.jpeg",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/37b89cde_d245_483c_a2e3_ccdb87fce2f0.jpeg-1687771267553.jpeg?download=1",
          fileUrl:
            "file:///data/user/0/host.exp.exponent/cache/ExperienceData/%2540anonymous%252Fantarctica-waddle-mobile-e9b45197-a68d-42ef-b91e-5e104036aeab/ImagePicker/37b89cde-d245-483c-a2e3-ccdb87fce2f0.jpeg",
          percent: 100,
          type: "image/jpeg",
          response: [
            [
              {
                fileName: "37b89cde_d245_483c_a2e3_ccdb87fce2f0.jpeg",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/37b89cde_d245_483c_a2e3_ccdb87fce2f0.jpeg-1687771267553.jpeg?download=1",
                thumbnail: {
                  fileName: "37b89cde_d245_483c_a2e3_ccdb87fce2f0.jpeg",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
      ],
      request: null,
    },
    {
      _id: "64996f9f926d1e53ea7102f3",
      threadId: 330,
      message: "Hello",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-26T10:59:43.004Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64996fae926d1e53ea7102f4",
      threadId: 330,
      message: "Kesa hai",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-26T10:59:58.807Z",
      reactions: {
        "😊": ["48"],
      },
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64996fd054d67f70c3baa563",
      threadId: 330,
      message: "Mast mai au Ty bata",
      senderId: "113",
      senderName: "Preetinder Kalsi",
      createdAt: "2023-06-26T11:00:32.315Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64996ff9926d1e53ea7102f5",
      threadId: 330,
      message: "Hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-06-26T11:01:13.408Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a03b2901a057b7daaffeef",
      threadId: 330,
      message: "hello",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-01T14:41:45.630Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a03cb101a057b7daaffef0",
      threadId: 330,
      message: "hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-01T14:48:17.278Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a4548fe88ea2893ea812f8",
      threadId: 330,
      message: "hello in group",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-04T17:19:11.879Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a4550ce88ea2893ea812f9",
      threadId: 330,
      message: "hello in group",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-04T17:21:16.073Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a45bfbe3919e11e440f9f4",
      threadId: 330,
      message: "Thi",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-04T17:50:51.734Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          uid: "Tue Jul 04 2023 23:20:35 GMT+0530-60f5e264-81d7-4183-acee-abf9165a4f9a.jpeg",
          name: "60f5e264-81d7-4183-acee-abf9165a4f9a.jpeg",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/60f5e264_81d7_4183_acee_abf9165a4f9a.jpeg-1688493036723.jpeg?download=1",
          fileUrl:
            "file:///data/user/0/com.antarcticaglobal.ai.waddle/cache/ImagePicker/60f5e264-81d7-4183-acee-abf9165a4f9a.jpeg",
          percent: 100,
          type: "image/jpeg",
          response: [
            [
              {
                fileName: "60f5e264_81d7_4183_acee_abf9165a4f9a.jpeg",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/60f5e264_81d7_4183_acee_abf9165a4f9a.jpeg-1688493036723.jpeg?download=1",
                thumbnail: {
                  fileName: "60f5e264_81d7_4183_acee_abf9165a4f9a.jpeg",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
      ],
      request: null,
    },
    {
      _id: "64a45d4512116838548cda57",
      threadId: 330,
      message: "jjjjjjjjjjj",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-04T17:56:21.197Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a5400e9d58eb7772413a34",
      threadId: 330,
      message: "Hey",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-05T10:03:58.318Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          uid: "Wed Jul 05 2023 15:33:39 GMT+0530-86e93a4e-33d3-4b3c-afde-03ba217c5724.jpeg",
          name: "86e93a4e-33d3-4b3c-afde-03ba217c5724.jpeg",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/86e93a4e_33d3_4b3c_afde_03ba217c5724.jpeg-1688551422084.jpeg?download=1",
          fileUrl:
            "file:///data/user/0/com.antarcticaglobal.ai.waddle/cache/ImagePicker/86e93a4e-33d3-4b3c-afde-03ba217c5724.jpeg",
          percent: 100,
          type: "image/jpeg",
          response: [
            [
              {
                fileName: "86e93a4e_33d3_4b3c_afde_03ba217c5724.jpeg",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/86e93a4e_33d3_4b3c_afde_03ba217c5724.jpeg-1688551422084.jpeg?download=1",
                thumbnail: {
                  fileName: "86e93a4e_33d3_4b3c_afde_03ba217c5724.jpeg",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
      ],
    },
    {
      _id: "64a5402e9d58eb7772413a35",
      threadId: 330,
      message: "Hii",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-05T10:04:30.544Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
    },
    {
      _id: "64a540b6291acbd567cb9641",
      threadId: 330,
      message: "hello",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T10:06:47.071Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a54232291acbd567cb9646",
      threadId: 330,
      message: "hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T10:13:06.889Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a5424f291acbd567cb9649",
      threadId: 330,
      message: "imp",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T10:13:35.764Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a5425e291acbd567cb964a",
      threadId: 330,
      message: "imp",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T10:13:50.406Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a54264291acbd567cb964b",
      threadId: 330,
      message: "this is imp",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T10:13:56.906Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a5426c291acbd567cb964c",
      threadId: 330,
      message: "really imp",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T10:14:04.967Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a54278291acbd567cb964d",
      threadId: 330,
      message: "fdvdfb",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T10:14:17.186Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a54280291acbd567cb964e",
      threadId: 330,
      message: "122",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T10:14:24.712Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a54289291acbd567cb964f",
      threadId: 330,
      message: "123",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T10:14:33.298Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a543c11527143b8a520bbf",
      threadId: 330,
      message: "@@@__264^^__Anushka Gaonkar@@@^^^  check request modal",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-05T10:19:45.877Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: {
        type: "Attachment",
        status: "Open",
        requestClosingDate: null,
        urgency: "",
        deadlineDate: null,
        deadlineTime: "null:00:00 am",
        activity: [],
        updateCount: 0,
        updateSeenUsers: [],
      },
    },
    {
      _id: "64a55b23291acbd567cb9652",
      threadId: 330,
      message: "hello",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T11:59:32.225Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a55b49291acbd567cb9653",
      threadId: 330,
      message: "hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-05T12:00:09.781Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a55e2f0e24c9c7c8e26192",
      threadId: 330,
      message: "hii",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-05T12:12:31.467Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a55e7b712838b0e7f3f1d7",
      threadId: 330,
      message: "hii",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-05T12:13:47.171Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64a55ec2382ff1c52ef6ad4e",
      threadId: 330,
      message: "jii",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-05T12:14:58.446Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa58f097517752096ca73c",
      threadId: 330,
      message: "hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T06:51:28.146Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa58fe97517752096ca73d",
      threadId: 330,
      message: "hwllo",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T06:51:42.271Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa591a97517752096ca73e",
      threadId: 330,
      message: "kese ho",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T06:52:10.507Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa593697517752096ca73f",
      threadId: 330,
      message: "ki",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T06:52:38.497Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa5a9197517752096ca745",
      threadId: 330,
      message: "noo\\",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T06:58:25.281Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa5b9d97517752096ca748",
      threadId: 330,
      message: "jjj",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:02:53.345Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa5bee97517752096ca749",
      threadId: 330,
      message: "jjj",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:04:14.871Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa5bf797517752096ca74a",
      threadId: 330,
      message: "asdfghjk",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:04:23.345Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa5c0997517752096ca74c",
      threadId: 330,
      message: "jjj",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:04:41.492Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa5d9e97517752096ca74d",
      threadId: 330,
      message: "hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:11:26.335Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa5daf97517752096ca74e",
      threadId: 330,
      message: "uu",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:11:43.145Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa5ddd97517752096ca751",
      threadId: 330,
      message: "hii",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:12:29.533Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa60d997517752096ca75c",
      threadId: 330,
      message: "xyz",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:25:13.218Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa62c197517752096ca75f",
      threadId: 330,
      message: "xxxx",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:33:21.336Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa62d197517752096ca760",
      threadId: 330,
      message: "hjbjhv",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:33:37.381Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64aa62e997517752096ca761",
      threadId: 330,
      message: "jjjjj",
      senderId: "48",
      senderName: "Ganesh Shah",
      createdAt: "2023-07-09T07:34:01.113Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b122b4169d805e50016555",
      threadId: 330,
      message: "hh",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:25:56.352Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b12355169d805e50016556",
      threadId: 330,
      message: "hhh",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:28:37.352Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b123b9169d805e50016557",
      threadId: 330,
      message: "hii",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:30:17.327Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b12436169d805e50016558",
      threadId: 330,
      message: "jjj",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:32:22.891Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b124c2169d805e50016559",
      threadId: 330,
      message: "jj",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:34:42.180Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b1254d169d805e5001655a",
      threadId: 330,
      message: "hii",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:37:01.275Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b12578169d805e5001655b",
      threadId: 330,
      message: "hi",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:37:44.022Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b12628169d805e5001655c",
      threadId: 330,
      message: "hii",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:40:40.052Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b126c0169d805e5001655d",
      threadId: 330,
      message: "hii",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:43:12.702Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b126d8169d805e5001655e",
      threadId: 330,
      message: "ccc",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T10:43:36.786Z",
      reactions: {
        "😊": ["113"],
      },
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b12c96169d805e5001655f",
      threadId: 330,
      message: "Hello",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-14T11:08:06.564Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: {
        type: "Attachment",
        status: "Open",
        requestClosingDate: null,
        urgency: "Major",
        deadlineDate: null,
        deadlineTime: "12:01:00 am",
        activity: [],
        updateCount: 0,
        updateSeenUsers: [],
      },
    },
    {
      _id: "64b77e568d06f814ca9338b0",
      threadId: 330,
      message: "Test forward",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-19T06:10:30.246Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b7b8a7b35456df2ef32866",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-19T10:19:19.601Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          uid: "Wed Jul 19 2023 15:49:10 GMT+0530-6b263896-e17d-4b2b-954d-874e75daa74c.jpeg",
          name: "6b263896-e17d-4b2b-954d-874e75daa74c.jpeg",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/6b263896_e17d_4b2b_954d_874e75daa74c.jpeg-1689761951847.jpeg?download=1",
          fileUrl:
            "file:///data/user/0/com.antarcticaglobal.waddle.ai/cache/ImagePicker/6b263896-e17d-4b2b-954d-874e75daa74c.jpeg",
          percent: 100,
          type: "image/jpeg",
          response: [
            [
              {
                fileName: "6b263896_e17d_4b2b_954d_874e75daa74c.jpeg",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/6b263896_e17d_4b2b_954d_874e75daa74c.jpeg-1689761951847.jpeg?download=1",
                thumbnail: {
                  fileName: "6b263896_e17d_4b2b_954d_874e75daa74c.jpeg",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
      ],
      request: null,
    },
    {
      _id: "64b7bc026914ff8efb4d1f52",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-19T10:33:38.603Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          uid: "Wed Jul 19 2023 16:03:26 GMT+0530-VID-20230719-WA0014.mp4",
          name: "VID-20230719-WA0014.mp4",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/VID_20230719_WA0014.mp4-1689762809360.mp4?download=1",
          fileUrl:
            "file:///data/user/0/com.antarcticaglobal.waddle.ai/cache/DocumentPicker/a9b51739-1c35-4654-b1cd-cc1adb56d0cb.mp4",
          percent: 100,
          type: "video/mp4",
          size: 6382115,
          response: [
            [
              {
                fileName: "VID_20230719_WA0014.mp4",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/VID_20230719_WA0014.mp4-1689762809360.mp4?download=1",
                thumbnail: {
                  fileName: "VID_20230719_WA0014.mp4",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
      ],
      request: null,
    },
    {
      _id: "64b7bcb06914ff8efb4d1f53",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-19T10:36:33.212Z",
      reactions: {
        "😊": [],
        "😆": ["113"],
      },
      replyMessage: {},
      attachments: [
        {
          uid: "Wed Jul 19 2023 16:06:25 GMT+0530-Tushar Thorat.pdf",
          name: "Tushar Thorat.pdf",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Tushar_Thorat.pdf-1689762987675.pdf?download=1",
          fileUrl:
            "file:///data/user/0/com.antarcticaglobal.waddle.ai/cache/DocumentPicker/0682d387-117d-46c3-9eab-eb979072b305.pdf",
          percent: 100,
          type: "application/pdf",
          size: 294154,
          response: [
            [
              {
                fileName: "Tushar_Thorat.pdf",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Tushar_Thorat.pdf-1689762987675.pdf?download=1",
                thumbnail: {
                  fileName: "Tushar_Thorat.pdf",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
      ],
      request: null,
    },
    {
      _id: "64b7c20ea247fe2cf6a14da2",
      threadId: 330,
      message: "Njjj",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-19T10:59:26.245Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b7c24ba247fe2cf6a14da3",
      threadId: 330,
      message: "Hhhj",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-19T11:00:27.447Z",
      reactions: {},
      replyMessage: {},
      attachments: [],
      request: null,
    },
    {
      _id: "64b7c55fa247fe2cf6a14da4",
      threadId: 330,
      message: "",
      senderId: "113",
      senderName: "Ganesh Mohanty",
      createdAt: "2023-07-19T11:13:35.722Z",
      reactions: {},
      replyMessage: {},
      attachments: [
        {
          uid: "Wed Jul 19 2023 16:43:28 GMT+0530-Tushar Thorat.pdf",
          name: "Tushar Thorat.pdf",
          status: "done",
          url: "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Tushar_Thorat.pdf-1689765211084.pdf?download=1",
          fileUrl:
            "file:///data/user/0/com.antarcticaglobal.waddle.ai/cache/DocumentPicker/c3affbbc-4b81-45e2-bba7-99e255a65cd6.pdf",
          percent: 100,
          type: "application/pdf",
          size: 294154,
          response: [
            [
              {
                fileName: "Tushar_Thorat.pdf",
                fileUrl:
                  "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Tushar_Thorat.pdf-1689765211084.pdf?download=1",
                thumbnail: {
                  fileName: "Tushar_Thorat.pdf",
                  fileUrl: "/images/thumbnails/file.svg",
                },
              },
            ],
          ],
        },
      ],
      request: null,
    },
  ],
  replyMessage: {
    message: "",
    reply: false,
    messageObject: {
      senderName: "",
      attachments: [],
      request: {
        type: "",
      },
      _id: "",
    },
  },
  editMessage: {
    edit: false,
    messageObject: {
      _id: "",
      message: "",
    },
  },
  forwardMessage: {},
  deleteMessage: {
    deleteFlag: false,
    deleteMessageObject: {
      _id: "",
    },
  },
  directMessageUsers: [
    {
      id: "2",
      userId: "113",
      firstname: "Ganesh",
      lastname: "Mohanty (You)",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
      roleid: "1",
      email: "preetinder@antarcticaglobal.com",
      mobile: "7039734268",
      company: "Antarctica ",
      createdAt: "2023-05-02T12:46:38.058Z",
      updatedAt: "2023-05-02T12:46:38.058Z",
      lat: 19.0759837,
      long: 72.8776559,
      jobTitle: "Full Stack Developer",
      location: "Mumbai, Maharashtra, India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "113",
      lastMessage: "hello",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-07-06T04:00:52.768Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "3",
      userId: "136",
      firstname: "Kevinva",
      lastname: "Shah",
      profilePicture: null,
      roleid: "2",
      email: "kevin@antarcticaglobal.com",
      mobile: null,
      company: null,
      createdAt: "2023-05-02T12:46:44.605Z",
      updatedAt: "2023-05-02T12:46:44.605Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "136",
      lastMessage: "mmm",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-05-04T04:25:40.102Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "3",
      userId: "136",
      firstname: "Kevinva",
      lastname: "Shah",
      profilePicture: null,
      roleid: "2",
      email: "kevin@antarcticaglobal.com",
      mobile: null,
      company: null,
      createdAt: "2023-05-02T12:46:44.605Z",
      updatedAt: "2023-05-02T12:46:44.605Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "136",
      lastMessage: "mmm",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-05-04T04:25:40.102Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "3",
      userId: "136",
      firstname: "Kevinva",
      lastname: "Shah",
      profilePicture: null,
      roleid: "2",
      email: "kevin@antarcticaglobal.com",
      mobile: null,
      company: null,
      createdAt: "2023-05-02T12:46:44.605Z",
      updatedAt: "2023-05-02T12:46:44.605Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "136",
      lastMessage: "mmm",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-05-04T04:25:40.102Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "3",
      userId: "136",
      firstname: "Kevinva",
      lastname: "Shah",
      profilePicture: null,
      roleid: "2",
      email: "kevin@antarcticaglobal.com",
      mobile: null,
      company: null,
      createdAt: "2023-05-02T12:46:44.605Z",
      updatedAt: "2023-05-02T12:46:44.605Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "136",
      lastMessage: "mmm",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-05-04T04:25:40.102Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "3",
      userId: "136",
      firstname: "Kevinva",
      lastname: "Shah",
      profilePicture: null,
      roleid: "2",
      email: "kevin@antarcticaglobal.com",
      mobile: null,
      company: null,
      createdAt: "2023-05-02T12:46:44.605Z",
      updatedAt: "2023-05-02T12:46:44.605Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "136",
      lastMessage: "mmm",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-05-04T04:25:40.102Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "3",
      userId: "136",
      firstname: "Kevinva",
      lastname: "Shah",
      profilePicture: null,
      roleid: "2",
      email: "kevin@antarcticaglobal.com",
      mobile: null,
      company: null,
      createdAt: "2023-05-02T12:46:44.605Z",
      updatedAt: "2023-05-02T12:46:44.605Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "136",
      lastMessage: "mmm",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-05-04T04:25:40.102Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "4",
      userId: "48",
      firstname: "Ganesh",
      lastname: "Shah",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/44776.jpg-1660301953228.jpg?download=1",
      roleid: "2",
      email: "ganesh@antarcticaglobal.com",
      mobile: null,
      company: null,
      createdAt: "2023-05-02T12:47:45.125Z",
      updatedAt: "2023-05-02T12:47:45.125Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "48",
      lastMessage: "hiii",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-07-09T01:50:38.064Z",
      isMute: 1,
      isPinned: 0,
      read: 0,
    },
    {
      id: "4",
      userId: "48",
      firstname: "Ganesh",
      lastname: "Shah",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/44776.jpg-1660301953228.jpg?download=1",
      roleid: "2",
      email: "ganesh@antarcticaglobal.com",
      mobile: null,
      company: null,
      createdAt: "2023-05-02T12:47:45.125Z",
      updatedAt: "2023-05-02T12:47:45.125Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "48",
      lastMessage: "hiii",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-07-09T01:50:38.064Z",
      isMute: 1,
      isPinned: 0,
      read: 0,
    },
    {
      id: "274",
      userId: "274",
      firstname: "jhvh",
      lastname: "jkhbk",
      profilePicture: null,
      roleid: "1",
      email: "movuqy@lyft.live",
      mobile: null,
      company: null,
      createdAt: "2023-02-14T03:16:47.885Z",
      updatedAt: "2023-02-14T03:55:55.440Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
    },
    {
      id: "317",
      userId: "317",
      firstname: "Kumail",
      lastname: "A",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
      roleid: "1",
      email: "kumail@antarcticaglobal.com",
      mobile: "999",
      company: "Antarctica ",
      createdAt: "2022-07-28T04:47:25.000Z",
      updatedAt: "2023-07-06T12:58:20.102Z",
      lat: 19.0759837,
      long: 72.8776559,
      jobTitle: "Full Stack Developer",
      location: "Mumbai, Maharashtra, India",
      messages: [],
      unreadCount: 0,
    },
    {
      id: "318",
      userId: "318",
      firstname: "Mathieu",
      lastname: "f",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
      roleid: "1",
      email: "mathieu@antarcticaglobal.com",
      mobile: "991",
      company: "Antarctica ",
      createdAt: "2022-07-28T04:47:25.000Z",
      updatedAt: "2023-07-06T14:27:27.869Z",
      lat: 19.0759837,
      long: 72.8776559,
      jobTitle: "Full Stack Developer",
      location: "Mumbai, Maharashtra, India",
      messages: [],
      unreadCount: 0,
    },
    {
      id: "21",
      userId: "319",
      firstname: "Ebrahim",
      lastname: "k",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
      roleid: "1",
      email: "ebrahim@antarcticaglobal.com",
      mobile: "992",
      company: "Antarctica ",
      createdAt: "2023-07-06T05:26:43.040Z",
      updatedAt: "2023-07-06T05:26:43.040Z",
      lat: 19.0759837,
      long: 72.8776559,
      jobTitle: "Full Stack Developer",
      location: "Mumbai, Maharashtra, India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "319",
      lastMessage: "Hey",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-07-06T05:26:45.629Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "320",
      userId: "320",
      firstname: "Shraddha",
      lastname: "p",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
      roleid: "1",
      email: "shraddha@antarcticaglobal.com",
      mobile: "993",
      company: "Antarctica ",
      createdAt: "2022-07-28T04:47:25.000Z",
      updatedAt: "2023-07-06T02:16:39.383Z",
      lat: 19.0759837,
      long: 72.8776559,
      jobTitle: "Full Stack Developer",
      location: "Mumbai, Maharashtra, India",
      messages: [],
      unreadCount: 0,
    },
    {
      id: "28",
      userId: "322",
      firstname: "Nutan",
      lastname: "g",
      profilePicture:
        "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1",
      roleid: "1",
      email: "nutan@antarcticaglobal.com",
      mobile: "994",
      company: "Antarctica ",
      createdAt: "2023-07-12T01:35:10.287Z",
      updatedAt: "2023-07-12T01:35:10.287Z",
      lat: 19.0759837,
      long: 72.8776559,
      jobTitle: "Full Stack Developer",
      location: "Mumbai, Maharashtra, India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "322",
      lastMessage: "Hii",
      tenantId: "69291437-4846-4e92-8806-4547cda6b3e4",
      lastMessageTime: "2023-07-17T01:30:35.896Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "325",
      userId: "325",
      firstname: "xosuwome",
      lastname: "finews",
      profilePicture: null,
      roleid: "2",
      email: "xosuwome@finews.biz",
      mobile: null,
      company: "c3",
      createdAt: "2023-07-10T02:05:54.140Z",
      updatedAt: "2023-07-10T02:08:58.698Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
    },
    {
      id: "15",
      userId: "264",
      firstname: "Anushka",
      lastname: "Gaonkar",
      profilePicture:
        "https://lh3.googleusercontent.com/a/AAcHTtf7KFLdNrsVb6bZU-yQN-QOk4kzGdYW6UzUfNk-NJ6d=s96-c",
      roleid: "1",
      email: "anushka@antarcticaglobal.com",
      mobile: "8169535928",
      company: "Antarctica global",
      createdAt: "2023-05-31T23:49:16.266Z",
      updatedAt: "2023-05-31T23:49:16.266Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
      senderId: "113",
      receiverId: "264",
      lastMessage: "hi this should get truncated and dnkjenkjneklwcnlkncnkeq",
      tenantId: "e43f0717-83ed-483a-8f98-3f3c89ab50ba",
      lastMessageTime: "2023-07-04T02:08:27.496Z",
      isMute: 0,
      isPinned: 0,
      read: 0,
    },
    {
      id: "333",
      userId: "333",
      firstname: "not",
      lastname: "new",
      profilePicture: null,
      roleid: "1",
      email: "vidol75503@paldept.com",
      mobile: null,
      company: null,
      createdAt: "2023-07-18T05:43:09.985Z",
      updatedAt: null,
      lat: null,
      long: null,
      jobTitle: null,
      location: "India",
      messages: [],
      unreadCount: 0,
    },
    {
      id: "340",
      userId: "340",
      firstname: "hello",
      lastname: "hello",
      profilePicture: null,
      roleid: "3",
      email: "bewito8325@miqlab.com",
      mobile: null,
      company: "c3",
      createdAt: "2023-07-19T05:27:22.063Z",
      updatedAt: "2023-07-19T05:30:39.198Z",
      lat: null,
      long: null,
      jobTitle: null,
      location: null,
      messages: [],
      unreadCount: 0,
    },
  ],
  prefillUserInAddThread: false,
  unreadCount: 0,
  newGroupModal: false,
  requestOption: false,
  userDetailsDrawer: {
    visible: false,
    data: {},
  },
  groupDetailsDrawer: {
    visible: false,
    data: {},
  },
  sharedFilesDrawer: false,
  sharedFileDetailsDrawer: {
    visible: false,
    data: {},
  },
  chatRequest: {
    selectedRequestType: "Attachment",
    selectedUrgency: "",
    deadlineDate: null,
    deadlineTime: null,
  },
  saveInLibrary: {
    visible: false,
    file: {},
  },
  filePreview: {
    docs: [
      {
        mimeType: "",
        type: "",
        name: "",
        url: "",
      },
    ],
    visible: false,
    from: "",
    currentIndex: "",
    createdAt: "",
  },
  defaultFileList: [],
};

export const dashboardDummyData = {
 

    loading: false,
    error: {},
    onGoingProjects: 0,
    completedProjects: 0,
    workspaces: [
      {
        id: '6',
        userId: '1',
        tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
        tenantHostname: 'antarctica-global',
        tenantName: 'Antarctica Global',
        createdAt: '2023-01-11T07:29:51.506Z',
        updatedAt: null,
        sdgs: '["2","3","4"]',
        sectorsList: '[2,4,6]',
        tenantLocation: 'Mumbai',
        tenantSize: '2'
      },
      {
        id: '22',
        userId: '1',
        tenantId: '83ce3bbe-7cd4-43b3-996f-a2353b35f60c',
        tenantHostname: 'ant',
        tenantName: 'Ant',
        createdAt: '2023-07-07T06:25:25.322Z',
        updatedAt: null,
        sdgs: '["7","10","11"]',
        sectorsList: '[1,2]',
        tenantLocation: 'Mumbai, Maharashtra, India',
        tenantSize: '1'
      },
      {
        id: '24',
        userId: '1',
        tenantId: '52c00139-e600-4068-bd03-46004a16fa4a',
        tenantHostname: 'antarctica-g',
        tenantName: 'Antarctica G',
        createdAt: '2023-07-10T11:57:04.914Z',
        updatedAt: null,
        sdgs: '["2","5","17"]',
        sectorsList: '[2,10]',
        tenantLocation: 'Mumbai, Maharashtra, India',
        tenantSize: '1'
      },
      {
        id: '34',
        userId: '1',
        tenantId: '35c4b48a-64af-471e-b159-566aff02ce61',
        tenantHostname: 'antartica-g',
        tenantName: 'Antartica G',
        createdAt: '2023-07-10T12:23:20.376Z',
        updatedAt: null,
        sdgs: '["2","3"]',
        sectorsList: '[2]',
        tenantLocation: 'Mumbai, Maharashtra, India',
        tenantSize: '1'
      }
    ],
    todoList: [],
    myTasks: [],
    allProjectView: false,
    whiteBoardModal: false,
    checkListScreen: false,
    search: '',
    errorMessageModal: {
      visible: false,
      type: 'phase'
    },
    completeProjectModal: {
      visible: false,
      edit: false,
      action: 'start',
      projectId: ' '
    },
    discardProjectModal: {
      visible: false,
      type: 'discard',
      title: ' ',
      id: ' '
    },
    alertPopupModal: {
      visible: true,
      data: {
        title: 'Design ended',
        subTitle: 'Duration',
        description: '7 days'
      }
    },
    newEventModal: {
      visible: false,
      startTime: '',
      endTime: '',
      date: ''
    },
    takeTourFromWelcomeCard: false,
    createRoleModal: false,
    dashboardWelcomeTourModal: false,
    completePhaseModal: {
      visible: false,
      action: 'end',
      phase: {
        projectId: '22',
        phaseId: '2',
        isCompleted: 0,
        startDate: '2023-07-24T00:00:00.000Z',
        endDate: '2023-07-31T00:00:00.000Z',
        phaseProgress: 0,
        isOngoing: 1,
        phaseEndDateChangeReason: '',
        isActive: 1,
        tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
        components: '',
        phaseIteration: null,
        id: '2',
        phases: 'Design',
        color: '#F92B63',
        projectPhaseId: '55'
      }
    },
    userDetailsDrawer: {
      visible: false,
      data: {}
    },
    completeProjectSuccessModal: false,
    messagesScreen: true,
    viewType: 1,
    filters: {
      lead: [],
      duration: [],
      status: []
    },
    events: [
      {
        id: '21',
        userId: '113',
        eventId: '21',
        title: 'CFA Final Demo ',
        startTime: '2023-07-20T05:40:00.000Z',
        endTime: '2023-07-20T06:40:00.000Z',
        meetLink: 'https://meet.google.com/gwc-ibwg-ycj',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: 'r4gf7b7tbdnrse6ur9vophimjg',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379702302298000"',
          id: 'r4gf7b7tbdnrse6ur9vophimjg',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=cjRnZjdiN3RiZG5yc2U2dXI5dm9waGltamcgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T11:05:50.000Z',
          updated: '2023-07-20T11:05:52.043Z',
          summary: 'CFA Final Demo ',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T16:40:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T17:40:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: 'r4gf7b7tbdnrse6ur9vophimjg@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'ebrahim@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            },
            {
              email: 'anushka@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'nutan@antarcticaglobal.com',
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/gwc-ibwg-ycj',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb1qspg',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/gwc-ibwg-ycj',
                label: 'meet.google.com/gwc-ibwg-ycj'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/gwc-ibwg-ycj?pin=7483282713322',
                pin: '7483282713322'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-781-514-9163',
                label: '+1 781-514-9163',
                pin: '655952075'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'gwc-ibwg-ycj'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'popup',
                minutes: 10
              },
              {
                method: 'email',
                minutes: 1440
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      },
      {
        id: '22',
        userId: '113',
        eventId: '22',
        title: 'Test',
        startTime: '2023-07-20T07:00:00.000Z',
        endTime: '2023-07-20T07:30:00.000Z',
        meetLink: 'https://meet.google.com/izy-qoit-uud',
        description: '',
        notifyUser: 0,
        isActive: 1,
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        projectId: null,
        meetId: 'ht7lbc6bias7gudsgo2ekddcfo',
        meetMetaData: {
          kind: 'calendar#event',
          etag: '"3379708920623000"',
          id: 'ht7lbc6bias7gudsgo2ekddcfo',
          status: 'confirmed',
          htmllink: 'https://www.google.com/calendar/event?eid=aHQ3bGJjNmJpYXM3Z3Vkc2dvMmVrZGRjZm8gZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t',
          created: '2023-07-20T12:00:59.000Z',
          updated: '2023-07-20T12:01:01.116Z',
          summary: 'Test',
          location: 'Mumbai',
          creator: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          organizer: {
            email: 'ganesh@antarcticaglobal.com',
            self: true
          },
          start: {
            datetime: '2023-07-20T18:00:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          end: {
            datetime: '2023-07-20T18:30:00+05:30',
            timezone: 'Asia/Kolkata'
          },
          icaluid: 'ht7lbc6bias7gudsgo2ekddcfo@google.com',
          sequence: 0,
          attendees: [
            {
              email: 'kevin@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'preetinder@antarcticaglobal.com',
              responsestatus: 'needsAction'
            },
            {
              email: 'ganesh@antarcticaglobal.com',
              organizer: true,
              self: true,
              responsestatus: 'needsAction'
            }
          ],
          hangoutlink: 'https://meet.google.com/izy-qoit-uud',
          conferencedata: {
            createrequest: {
              requestid: '5z26x227ylkb3pq6b',
              conferencesolutionkey: {
                type: 'hangoutsMeet'
              },
              status: {
                statuscode: 'success'
              }
            },
            entrypoints: [
              {
                entrypointtype: 'video',
                uri: 'https://meet.google.com/izy-qoit-uud',
                label: 'meet.google.com/izy-qoit-uud'
              },
              {
                entrypointtype: 'more',
                uri: 'https://tel.meet/izy-qoit-uud?pin=5540874092180',
                pin: '5540874092180'
              },
              {
                regioncode: 'US',
                entrypointtype: 'phone',
                uri: 'tel:+1-609-469-1646',
                label: '+1 609-469-1646',
                pin: '624454471'
              }
            ],
            conferencesolution: {
              key: {
                type: 'hangoutsMeet'
              },
              name: 'Google Meet',
              iconuri: 'https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png'
            },
            conferenceid: 'izy-qoit-uud'
          },
          reminders: {
            usedefault: false,
            overrides: [
              {
                method: 'popup',
                minutes: 10
              },
              {
                method: 'email',
                minutes: 1440
              }
            ]
          },
          eventtype: 'default'
        },
        eventDate: '2023-07-19T18:30:00.000Z'
      }
    ],
    waddleItGoals: [],
    projectThemeColor: '#165ABF',
    alreadyExistsModal: 0
  ,
  
}

export const scheduleDummyData = {
  loading: false,
  sprintsLoading: false,
  phaseLoading: false,
  goalsLoading: false,
  newSprintModal: {
    visible: false,
    isNewScope: false,
    scopeDescription: ''
  },
  newSprintScope: false,
  newGoalModal: false,
  newGoalScope: false,
  newScopeModal: false,
  moveSwimlaneModal: false,
  status: 'fulfilled',
  phases: [
    {
      projectId: '345',
      phaseId: '1',
      isCompleted: 1,
      startDate: '2023-01-05T07:30:00.000Z',
      endDate: '2023-01-18T07:30:00.000Z',
      phaseProgress: 50,
      isOngoing: 0,
      phaseEndDateChangeReason: null,
      id: '1',
      isActive: 1,
      tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
      components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
      phaseIteration: null,
      phases: 'Ideation',
      color: '#FFDB01',
      projectPhaseId: '817'
    },
    {
      projectId: '345',
      phaseId: '2',
      isCompleted: 0,
      startDate: '2023-01-30T18:30:00.000Z',
      endDate: '2023-02-02T02:00:00.000Z',
      phaseProgress: 50,
      isOngoing: 1,
      phaseEndDateChangeReason: null,
      id: '2',
      isActive: 1,
      tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
      components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
      phaseIteration: null,
      phases: 'Design',
      color: '#F92B63',
      projectPhaseId: '818'
    },
    {
      projectId: '345',
      phaseId: '3',
      isCompleted: 1,
      startDate: '2023-01-08T18:30:00.000Z',
      endDate: '2023-01-25T18:30:00.000Z',
      phaseProgress: 50,
      isOngoing: 0,
      phaseEndDateChangeReason: null,
      id: '3',
      isActive: 1,
      tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
      components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
      phaseIteration: null,
      phases: 'Development',
      color: '#6938EF',
      projectPhaseId: '819'
    },
    {
      projectId: '345',
      phaseId: '4',
      isCompleted: 0,
      startDate: '2023-06-01T01:02:30.474Z',
      endDate: '2023-06-21T01:02:26.843Z',
      phaseProgress: 50,
      isOngoing: 1,
      phaseEndDateChangeReason: null,
      id: '4',
      isActive: 1,
      tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
      components: null,
      phaseIteration: null,
      phases: 'Deployment',
      color: '#33B7FF',
      projectPhaseId: '938'
    },
    {
      projectId: '345',
      phaseId: '5',
      isCompleted: 0,
      startDate: '2023-01-03T18:30:00.000Z',
      endDate: '2023-01-10T18:30:00.000Z',
      phaseProgress: 50,
      isOngoing: 1,
      phaseEndDateChangeReason: '',
      id: '5',
      isActive: 1,
      tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
      components: '',
      phaseIteration: '',
      phases: 'Maintenance',
      color: '#5FC3DB',
      projectPhaseId: '978'
    }
  ],
  currentPhaseGoals: [],
  currentSelectedPhase: {
    id: 0,
    phaseId: '0',
    phaseProgress: 0,
    phases: '',
    startDate: '',
    endDate: '',
    color: '',
    isOngoing: 0,
    isCompleted: 0
  },
  selectedSprint: {
    id: '0',
    name: '',
    endDate: '',
    startDate: '',
    goals: [],
    isActive: 1,
    isCompleted: 0,
    isOngoing: 0,
    status: 1,
    phaseId: 1
  },
  sprints: [ {
    id: 2469,
    name: 'new not started',
    isCompleted: 0,
    isNewSprint: 0,
    isActive: 1,
    projectId: '345',
    phaseId: '818',
    startDate: '2023-07-05T18:30:00.000Z',
    endDate: '2023-07-11T18:30:00.000Z',
    tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
    status: 1,
    isOngoing: 0,
    createdById: '113',
    createdAt: '2023-07-06T01:44:38.577Z',
    linkedSprintId: null,
    identifier: null,
    goalCount: '76',
    goals: [
      {
        id: '1051',
        name: 'Task 1',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '2',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-31T04:41:41.745Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: '625',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-122'
      },
      {
        id: '1050',
        name: 'New Task 1',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-30T03:28:08.397Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: '604',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-121'
      },
      {
        id: '1048',
        name: 'Task 1234567',
        isCompleted: 0,
        statusId: 2,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '57',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '1',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-12T05:43:04.532Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: '602',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-119'
      },
      {
        id: '1046',
        name: 'task 11',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-01T03:56:34.733Z',
        componentName: '',
        timeLogged: '2h 10m ',
        linkedGoalId: '978',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-117'
      },
      {
        id: '1045',
        name: 'This is reaaallllly Log titleeee This is reaaallllly Log titleeee This is reaaallllly Log titleeee ',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '114',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-06T04:08:52.876Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: '977',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-116'
      },
      {
        id: '1044',
        name: 'Reporter Task Testing',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '114',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-07T13:27:12.340Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: '976',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-115'
      },
      {
        id: '1043',
        name: 'waddle emails',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 4,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-13T04:38:47.893Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: '1022',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-114'
      },
      {
        id: '1042',
        name: 'waddle emails',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 4,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-13T02:54:34.939Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: '1020',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-113'
      },
      {
        id: '1040',
        name: 'waddle it bug',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 4,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-19T06:50:08.007Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-111'
      },
      {
        id: '1038',
        name: 'hello new',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[{"name":"🦆 emoji _love letter_.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8guwg9.png-1689695137763.png?download=1","size":595,"type":"image/png","lastModified":1673085186497,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8guwgd.svg-1689695137765.svg?download=1","size":2161,"type":"image/svg+xml","lastModified":1673080818081,"thumbnail":"/images/thumbnails/file.svg"},{"name":"01 1.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/01_1_lk8guwgh.png-1689695137766.png?download=1","size":2923,"type":"image/png","lastModified":1673082964314,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8gv52v.svg-1689695148930.svg?download=1","size":2161,"type":"image/svg+xml","lastModified":1673080818081,"thumbnail":"/images/thumbnails/file.svg"}]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-18T10:16:00.445Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-110'
      },
      {
        id: '1035',
        name: 'new ',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-17T00:08:47.995Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX-109'
      },
      {
        id: '1034',
        name: 'hello id check',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-17T00:07:51.906Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'PX_108'
      },
      {
        id: '1031',
        name: 'sdcvsdvs',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '264',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-16T10:12:29.747Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'P_107'
      },
      {
        id: '1030',
        name: 'cz zcs',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-16T10:05:53.729Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'P_106'
      },
      {
        id: '1029',
        name: 'hgvghvgh',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-16T09:19:59.257Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'P_105'
      },
      {
        id: '1028',
        name: 'bhvhj',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '48',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-16T09:19:14.670Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: 'P_104'
      },
      {
        id: '1025',
        name: 'notif',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-13T04:44:49.619Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '1024',
        name: 'hi',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[{"name":"file.mp4","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/file_lk2990ws.mp4-1689319562871.mp4?download=1","size":2233489,"type":"video/mp4","lastModified":1672749446278,"thumbnail":"/images/thumbnails/file.svg"},{"name":"Asset (1).csv","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Asset_1_lk2986u1.csv-1689319523783.csv?download=1","size":12806,"type":"text/csv","lastModified":1675235118371,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk2905ui.png-1689319149226.png?download=1","size":595,"type":"image/png","lastModified":1673085186497,"thumbnail":"/images/thumbnails/file.svg"}]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-13T04:43:13.714Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '1019',
        name: 'hello',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: null,
        description: null,
        swimlaneId: '149',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-07T11:41:57.455Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: '6',
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '1018',
        name: 'hello',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 3,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-07T11:40:21.349Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '1017',
        name: 'Hello',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: null,
        description: null,
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-07T11:38:32.617Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '1016',
        name: 'hello ',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: null,
        description: null,
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-07T11:24:38.163Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '1015',
        name: 'hell',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: null,
        description: null,
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-07T11:23:40.231Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '1014',
        name: 'test new copy',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-02T06:07:06.008Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '1013',
        name: 'test new copy',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-02T06:07:06.008Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '1002',
        name: 'waddwl',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '264',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-04T00:54:20.578Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '999',
        name: 'test',
        isCompleted: 0,
        statusId: 2,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: null,
        description: null,
        swimlaneId: '57',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: null,
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-02T07:10:57.739Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '998',
        name: 'test 3',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '48',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '1h',
        createdAt: '2023-07-02T06:10:41.549Z',
        componentName: null,
        timeLogged: '2h ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '995',
        name: 'test new',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-02T06:07:06.008Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '975',
        name: 'New Scope',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 1,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-01T07:15:27.107Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '974',
        name: 'Hello bug Reporter',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '48',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-01T07:12:58.997Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '973',
        name: 'bug reporter',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '48',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-01T07:11:30.839Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '972',
        name: 'Test bug 1',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-01T07:09:31.490Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '971',
        name: 'Rest Reporter ',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '48',
        reporterId: '113',
        description: '123',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-01T07:03:24.976Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '970',
        name: 'Hello 1',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-07-01T06:05:42.335Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '890',
        name: 'test waddle it 2',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: '2023-06-13T23:42:10.902Z',
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '48',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-06-12T23:42:19.327Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '889',
        name: 'test waddle it  1',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 3,
        dueDate: '2023-06-11T23:41:30.103Z',
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '48',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '818',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-06-12T23:41:38.958Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '884',
        name: 'waddle it ',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 3,
        dueDate: '2023-06-06T02:49:10.226Z',
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '136',
        reporterId: '48',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[{"name":"Screenshot 2023-04-20 at 1.44.36 AM.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Screenshot_2023_04_20_at_1_liiuckrq.png-1685968894616.png?download=1","size":2460701,"type":"image/png","lastModified":1681935288704,"thumbnail":"/images/thumbnails/file.svg"}]',
        phaseId: '817',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '2h',
        createdAt: '2023-06-05T02:49:14.405Z',
        componentName: null,
        timeLogged: '1w 2d 1h ',
        linkedGoalId: null,
        labelId: '6',
        releaseVersionId: '3',
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '883',
        name: 'hudjkvsndjkv',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: null,
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-06-05T02:47:52.771Z',
        componentName: null,
        timeLogged: '-1w -1d -1h ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '847',
        name: 'Chill Time',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '2',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: 'We will Chill ',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: null,
        phaseId: '1',
        meetId: 'tvmpo7fml8bs1gk241gila62p0',
        meetLink: 'https://meet.google.com/nwx-ygpz-cpq',
        meetMetaData: '{"kind":"calendar#event","etag":"\\"3360502023113000\\"","id":"tvmpo7fml8bs1gk241gila62p0","status":"confirmed","htmlLink":"https://www.google.com/calendar/event?eid=dHZtcG83Zm1sOGJzMWdrMjQxZ2lsYTYycDAgZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t","created":"2023-03-31T08:23:30.000Z","updated":"2023-03-31T08:23:32.194Z","summary":"Chill Time","description":"We will Chill ","location":"MUmbai ","creator":{"email":"ganesh@antarcticaglobal.com","self":true},"organizer":{"email":"ganesh@antarcticaglobal.com","self":true},"start":{"dateTime":"2023-03-31T15:53:00+05:30","timeZone":"Asia/Kolkata"},"end":{"dateTime":"2023-03-31T21:53:10+05:30","timeZone":"Asia/Kolkata"},"iCalUID":"tvmpo7fml8bs1gk241gila62p0@google.com","sequence":0,"attendees":[{"email":"kevin@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"preetinder@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"ganesh@antarcticaglobal.com","organizer":true,"self":true,"responseStatus":"needsAction"}],"hangoutLink":"https://meet.google.com/nwx-ygpz-cpq","conferenceData":{"createRequest":{"requestId":"5z26w8b7lfwa2hpj","conferenceSolutionKey":{"type":"hangoutsMeet"},"status":{"statusCode":"success"}},"entryPoints":[{"entryPointType":"video","uri":"https://meet.google.com/nwx-ygpz-cpq","label":"meet.google.com/nwx-ygpz-cpq"},{"entryPointType":"more","uri":"https://tel.meet/nwx-ygpz-cpq?pin=7590525011566","pin":"7590525011566"},{"regionCode":"US","entryPointType":"phone","uri":"tel:+1-252-404-1160","label":"+1 252-404-1160","pin":"753408721"}],"conferenceSolution":{"key":{"type":"hangoutsMeet"},"name":"Google Meet","iconUri":"https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"},"conferenceId":"nwx-ygpz-cpq"},"reminders":{"useDefault":false,"overrides":[{"method":"popup","minutes":10},{"method":"email","minutes":1440}]},"eventType":"default"}',
        estimatedTime: null,
        createdAt: '2023-03-31T02:53:34.591Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '846',
        name: 'Chill TIme',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '2',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: 'fsvsfv',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: null,
        phaseId: '1',
        meetId: '45fk2r0mpjid544oq8juf3dh68',
        meetLink: null,
        meetMetaData: '{"kind":"calendar#event","etag":"\\"3360501771840000\\"","id":"45fk2r0mpjid544oq8juf3dh68","status":"confirmed","htmlLink":"https://www.google.com/calendar/event?eid=NDVmazJyMG1wamlkNTQ0b3E4anVmM2RoNjggZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t","created":"2023-03-31T08:21:25.000Z","updated":"2023-03-31T08:21:25.920Z","summary":"Chill TIme","description":"fsvsfv","location":"Mumbai","creator":{"email":"ganesh@antarcticaglobal.com","self":true},"organizer":{"email":"ganesh@antarcticaglobal.com","self":true},"start":{"dateTime":"2023-03-31T15:00:00+05:30","timeZone":"Asia/Kolkata"},"end":{"dateTime":"2023-03-31T19:51:05+05:30","timeZone":"Asia/Kolkata"},"iCalUID":"45fk2r0mpjid544oq8juf3dh68@google.com","sequence":0,"attendees":[{"email":"kevin@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"preetinder@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"ganesh@antarcticaglobal.com","organizer":true,"self":true,"responseStatus":"needsAction"}],"reminders":{"useDefault":false,"overrides":[{"method":"email","minutes":1440},{"method":"popup","minutes":10}]},"eventType":"default"}',
        estimatedTime: null,
        createdAt: '2023-03-31T02:51:28.456Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '845',
        name: 'test',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '2',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: 'svhv',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: null,
        attachments: null,
        phaseId: '1',
        meetId: 'gc257suq3fojlk0qc3sa6vdhp8',
        meetLink: 'https://meet.google.com/xfi-rixi-jyo',
        meetMetaData: '{"kind":"calendar#event","etag":"\\"3360500807516000\\"","id":"gc257suq3fojlk0qc3sa6vdhp8","status":"confirmed","htmlLink":"https://www.google.com/calendar/event?eid=Z2MyNTdzdXEzZm9qbGswcWMzc2E2dmRocDggZ2FuZXNoQGFudGFyY3RpY2FnbG9iYWwuY29t","created":"2023-03-31T08:13:23.000Z","updated":"2023-03-31T08:13:24.280Z","summary":"test","description":"svhv","location":"mumabi","creator":{"email":"ganesh@antarcticaglobal.com","self":true},"organizer":{"email":"ganesh@antarcticaglobal.com","self":true},"start":{"dateTime":"2023-03-31T13:43:00+05:30","timeZone":"Asia/Kolkata"},"end":{"dateTime":"2023-03-31T13:52:05+05:30","timeZone":"Asia/Kolkata"},"iCalUID":"gc257suq3fojlk0qc3sa6vdhp8@google.com","sequence":0,"attendees":[{"email":"kevin@antarcticaglobal.com","responseStatus":"needsAction"},{"email":"ganesh@antarcticaglobal.com","organizer":true,"self":true,"responseStatus":"needsAction"}],"hangoutLink":"https://meet.google.com/xfi-rixi-jyo","conferenceData":{"createRequest":{"requestId":"5z26w8b7lfw9pgs5","conferenceSolutionKey":{"type":"hangoutsMeet"},"status":{"statusCode":"success"}},"entryPoints":[{"entryPointType":"video","uri":"https://meet.google.com/xfi-rixi-jyo","label":"meet.google.com/xfi-rixi-jyo"},{"entryPointType":"more","uri":"https://tel.meet/xfi-rixi-jyo?pin=1780429240315","pin":"1780429240315"},{"regionCode":"US","entryPointType":"phone","uri":"tel:+1-515-518-4413","label":"+1 515-518-4413","pin":"448324621"}],"conferenceSolution":{"key":{"type":"hangoutsMeet"},"name":"Google Meet","iconUri":"https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"},"conferenceId":"xfi-rixi-jyo"},"reminders":{"useDefault":false,"overrides":[{"method":"email","minutes":1440},{"method":"popup","minutes":10}]},"eventType":"default"}',
        estimatedTime: null,
        createdAt: '2023-03-31T02:43:26.795Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '705',
        name: 'hello 2',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-22T02:33:01.034Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: 'P1-67',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '704',
        name: 'hello',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-22T02:32:37.854Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: 'P1-66',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '703',
        name: '7678',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-22T02:31:17.075Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: 'P1-641',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '702',
        name: 'frfrfr',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-22T02:29:10.622Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: 'P1-7011',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '701',
        name: 'test 2',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-22T02:27:47.517Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: 'P1-1',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '700',
        name: 'test linkid 1',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-22T02:25:50.889Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: 'P1-1',
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '679',
        name: 'hello',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '114',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-13T07:08:15.172Z',
        componentName: '',
        timeLogged: '1h ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '678',
        name: '&lt;dfvsd>csvsd',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '114',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-13T07:04:40.536Z',
        componentName: '',
        timeLogged: '2h ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '677',
        name: '&lt;rr>dsvsv',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '114',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-13T07:04:10.025Z',
        componentName: '',
        timeLogged: '1h ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '665',
        name: 'csdcs',
        isCompleted: 0,
        statusId: 2,
        componentId: '0',
        priorityId: 2,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '48',
        reporterId: '48',
        description: '',
        swimlaneId: '57',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-07T13:32:43.538Z',
        componentName: '',
        timeLogged: '1h ',
        linkedGoalId: null,
        labelId: '6',
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '664',
        name: 'dcsdc',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '114',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-07T13:31:49.123Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '662',
        name: 'Reporter bug Testing',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '114',
        categoryId: '1',
        dependencyId: '1',
        environment: '',
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-07T13:27:55.339Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '660',
        name: 'Helloo',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '48',
        description: '',
        swimlaneId: '114',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-07T13:14:22.380Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '649',
        name: 'This is reaaallllly Log titleeee This is reaaallllly Log titleeee This is reaaallllly Log titleeee ',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '114',
        categoryId: '1',
        dependencyId: '1',
        environment: '',
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-06T04:08:26.706Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '629',
        name: 'Bug 3',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '114',
        categoryId: '1',
        dependencyId: '1',
        environment: '',
        attachments: '[{"name":"java_ldokvxq7.png-1675431887045.png_download=1 (2).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ldokvxq7_ldon6lhw.png-1675435743595.png?download=1","size":47826,"type":"image/png","lastModified":1675432292462}]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-01T04:14:04.456Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '628',
        name: 'Bug 2',
        isCompleted: 0,
        statusId: 2,
        componentId: '2',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '115',
        categoryId: '1',
        dependencyId: '1',
        environment: '',
        attachments: '[{"name":"arrow_right_ldmqmlrm.svg-1675320597022.svg_download=1 (1).svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/arrow_right_ldmqmlrm_ldoiybd7.svg-1675428638802.svg?download=1","size":281,"type":"image/svg+xml","lastModified":1675321668657}]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-01T04:09:01.710Z',
        componentName: 'Next JS',
        timeLogged: '1h ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '627',
        name: 'Bug 1 check again',
        isCompleted: 0,
        statusId: 1,
        componentId: '1',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: 'Vault',
        attachments: '[{"name":"animation-frosty-jr.mp4","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/animation_frosty_jr_lefb7iyu.mp4-1677048218383.mp4?download=1","size":836892,"type":"video/mp4","lastModified":1671093770660,"thumbnail":"/images/thumbnails/file.svg"},{"name":"Group 20168@2x.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Group_20168_2x_lefadoyy.png-1677046826467.png?download=1","size":21458,"type":"image/png","lastModified":1671093769397,"thumbnail":"/images/thumbnails/file.svg","uid":"__AUTO__1677048194240_0__"},{"name":"animation-frosty-jr.mp4","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/animation_frosty_jr_lefa3dpj.mp4-1677046345357.mp4?download=1","size":836892,"type":"video/mp4","lastModified":1671093770660,"thumbnail":"/images/thumbnails/file.svg","uid":"__AUTO__1677046389974_0__"}]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-02-01T04:07:59.529Z',
        componentName: 'React JS',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '619',
        name: 'task 2',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '136',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-31T05:43:21.132Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '617',
        name: 'Task 1',
        isCompleted: 0,
        statusId: 2,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '136',
        reporterId: '113',
        description: '',
        swimlaneId: '57',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[{"name":"java_ldokvxq7.png-1675431887045.png_download=1 (1).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ldokvxq7_ldon4jiv.png-1675435647795.png?download=1","size":47826,"type":"image/png","lastModified":1675432011123},{"name":"Polygon 3 (1).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Polygon_3_1_ldon4yao.png-1675435666886.png?download=1","size":47598,"type":"image/png","lastModified":1675062132614},{"name":"Polygon 3 (2).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Polygon_3_2_ldon4yar.png-1675435666896.png?download=1","size":72052,"type":"image/png","lastModified":1675062142310},{"name":"Polygon 3 (3).png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Polygon_3_3_ldon4yat.png-1675435666891.png?download=1","size":15431,"type":"image/png","lastModified":1675062161688}]',
        phaseId: '2',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '1h',
        createdAt: '2023-01-31T04:41:41.745Z',
        componentName: '',
        timeLogged: '-1w -1d -1h ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '600',
        name: 'New BIG BUG',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: '',
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-30T04:56:36.730Z',
        componentName: null,
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '598',
        name: 'New Task',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-30T03:26:05.983Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '597',
        name: 'tetx',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-30T02:41:41.067Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '590',
        name: 'bug2',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: '',
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '1h',
        createdAt: '2023-01-26T01:49:08.747Z',
        componentName: null,
        timeLogged: '1h 40m ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '589',
        name: 'bug',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: '',
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: null,
        createdAt: '2023-01-26T01:47:20.184Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '588',
        name: 'hello',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '20m',
        createdAt: '2023-01-26T01:46:08.108Z',
        componentName: '',
        timeLogged: '1h ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '587',
        name: 'BUG SUMMARY',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '587',
        environment: '',
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '6h',
        createdAt: '2023-01-24T07:40:38.653Z',
        componentName: null,
        timeLogged: '1h 25m ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '586',
        name: 'Bug summary',
        isCompleted: 0,
        statusId: 1,
        componentId: null,
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '3',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '1',
        dependencyId: '1',
        environment: '',
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: null,
        createdAt: '2023-01-24T05:29:53.577Z',
        componentName: null,
        timeLogged: null,
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '585',
        name: 'New Taskjnlksdls  s ,sdmcsdk skmc;kdsl sdlkmsd lk llkndslkldsk lk lksnlksd lsdknlkdsn lkds lkdsnlkds lkds lskd nlsdk ',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '61m',
        createdAt: '2023-01-24T05:14:10.845Z',
        componentName: '',
        timeLogged: '2d 4h 20m ',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '574',
        name: 'New Task',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-21T04:44:25.214Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '573',
        name: 'New Task',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '136',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-21T04:44:14.870Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '571',
        name: 'New Task 11',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '"[]"',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-20T05:23:37.869Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '569',
        name: 'New Task 12',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-20T05:15:31.013Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      },
      {
        id: '565',
        name: 'New Task 1',
        isCompleted: 0,
        statusId: 1,
        componentId: '0',
        priorityId: 1,
        dueDate: null,
        isNewGoal: 0,
        isActive: 1,
        projectId: '345',
        tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
        goalType: '1',
        parentGoalId: null,
        sprintId: '2469',
        assigneeId: '113',
        reporterId: '113',
        description: '',
        swimlaneId: '56',
        categoryId: '0',
        dependencyId: '1',
        environment: null,
        attachments: '[]',
        phaseId: '3',
        meetId: null,
        meetLink: null,
        meetMetaData: null,
        estimatedTime: '',
        createdAt: '2023-01-19T05:42:01.285Z',
        componentName: '',
        timeLogged: '',
        linkedGoalId: null,
        labelId: null,
        releaseVersionId: null,
        projectPhaseId: null,
        identifier: null
      }
    ]
  },],
  allSprints: [],
  goals:[  {
    id: '1051',
    name: 'Task 1',
    isCompleted: 0,
    statusId: 1,
    componentId: '0',
    priorityId: 1,
    dueDate: null,
    isNewGoal: 0,
    isActive: 1,
    projectId: '345',
    tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
    goalType: '1',
    parentGoalId: null,
    sprintId: '2469',
    assigneeId: '113',
    reporterId: '113',
    description: '',
    swimlaneId: '56',
    categoryId: '0',
    dependencyId: '1',
    environment: null,
    attachments: '[]',
    phaseId: '2',
    meetId: null,
    meetLink: null,
    meetMetaData: null,
    estimatedTime: '',
    createdAt: '2023-01-31T04:41:41.745Z',
    componentName: '',
    timeLogged: '',
    linkedGoalId: '625',
    labelId: null,
    releaseVersionId: null,
    projectPhaseId: null,
    identifier: 'PX-122'
  },
  {
    id: '1050',
    name: 'New Task 1',
    isCompleted: 0,
    statusId: 1,
    componentId: '0',
    priorityId: 1,
    dueDate: null,
    isNewGoal: 0,
    isActive: 1,
    projectId: '345',
    tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
    goalType: '1',
    parentGoalId: null,
    sprintId: '2469',
    assigneeId: '113',
    reporterId: '113',
    description: '',
    swimlaneId: '56',
    categoryId: '0',
    dependencyId: '1',
    environment: null,
    attachments: '[]',
    phaseId: '3',
    meetId: null,
    meetLink: null,
    meetMetaData: null,
    estimatedTime: '',
    createdAt: '2023-01-30T03:28:08.397Z',
    componentName: '',
    timeLogged: '',
    linkedGoalId: '604',
    labelId: null,
    releaseVersionId: null,
    projectPhaseId: null,
    identifier: 'PX-121'
  },
  {
    id: '1048',
    name: 'Task 1234567',
    isCompleted: 0,
    statusId: 2,
    componentId: '0',
    priorityId: 1,
    dueDate: null,
    isNewGoal: 0,
    isActive: 1,
    projectId: '345',
    tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
    goalType: '1',
    parentGoalId: null,
    sprintId: '2469',
    assigneeId: '113',
    reporterId: '113',
    description: '',
    swimlaneId: '57',
    categoryId: '0',
    dependencyId: '1',
    environment: null,
    attachments: '[]',
    phaseId: '1',
    meetId: null,
    meetLink: null,
    meetMetaData: null,
    estimatedTime: '',
    createdAt: '2023-01-12T05:43:04.532Z',
    componentName: '',
    timeLogged: '',
    linkedGoalId: '602',
    labelId: null,
    releaseVersionId: null,
    projectPhaseId: null,
    identifier: 'PX-119'
  },
  {
    id: '1046',
    name: 'task 11',
    isCompleted: 0,
    statusId: 1,
    componentId: '0',
    priorityId: 1,
    dueDate: null,
    isNewGoal: 0,
    isActive: 1,
    projectId: '345',
    tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
    goalType: '1',
    parentGoalId: null,
    sprintId: '2469',
    assigneeId: '113',
    reporterId: '113',
    description: '',
    swimlaneId: '56',
    categoryId: '0',
    dependencyId: '1',
    environment: null,
    attachments: '[]',
    phaseId: '3',
    meetId: null,
    meetLink: null,
    meetMetaData: null,
    estimatedTime: '',
    createdAt: '2023-02-01T03:56:34.733Z',
    componentName: '',
    timeLogged: '2h 10m ',
    linkedGoalId: '978',
    labelId: null,
    releaseVersionId: null,
    projectPhaseId: null,
    identifier: 'PX-117'
  },
  {
    id: '1045',
    name: 'This is reaaallllly Log titleeee This is reaaallllly Log titleeee This is reaaallllly Log titleeee ',
    isCompleted: 0,
    statusId: 1,
    componentId: '0',
    priorityId: 1,
    dueDate: null,
    isNewGoal: 0,
    isActive: 1,
    projectId: '345',
    tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
    goalType: '1',
    parentGoalId: null,
    sprintId: '2469',
    assigneeId: '113',
    reporterId: '113',
    description: '',
    swimlaneId: '114',
    categoryId: '0',
    dependencyId: '1',
    environment: null,
    attachments: '[]',
    phaseId: '3',
    meetId: null,
    meetLink: null,
    meetMetaData: null,
    estimatedTime: '',
    createdAt: '2023-02-06T04:08:52.876Z',
    componentName: '',
    timeLogged: '',
    linkedGoalId: '977',
    labelId: null,
    releaseVersionId: null,
    projectPhaseId: null,
    identifier: 'PX-116'
  },

  {
    id: '1040',
    name: 'waddle it bug',
    isCompleted: 0,
    statusId: 1,
    componentId: null,
    priorityId: 4,
    dueDate: null,
    isNewGoal: 0,
    isActive: 1,
    projectId: '345',
    tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
    goalType: '3',
    parentGoalId: null,
    sprintId: '2469',
    assigneeId: '113',
    reporterId: '48',
    description: '',
    swimlaneId: '56',
    categoryId: '1',
    dependencyId: '1',
    environment: null,
    attachments: '[]',
    phaseId: '818',
    meetId: null,
    meetLink: null,
    meetMetaData: null,
    estimatedTime: '',
    createdAt: '2023-07-19T06:50:08.007Z',
    componentName: null,
    timeLogged: '',
    linkedGoalId: null,
    labelId: null,
    releaseVersionId: null,
    projectPhaseId: null,
    identifier: 'PX-111'
  },
  {
    id: '1038',
    name: 'hello new',
    isCompleted: 0,
    statusId: 1,
    componentId: null,
    priorityId: 1,
    dueDate: null,
    isNewGoal: 0,
    isActive: 1,
    projectId: '345',
    tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
    goalType: '1',
    parentGoalId: null,
    sprintId: '2469',
    assigneeId: '113',
    reporterId: '136',
    description: '',
    swimlaneId: '56',
    categoryId: '1',
    dependencyId: '1',
    environment: null,
    attachments: '[{"name":"🦆 emoji _love letter_.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8guwg9.png-1689695137763.png?download=1","size":595,"type":"image/png","lastModified":1673085186497,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8guwgd.svg-1689695137765.svg?download=1","size":2161,"type":"image/svg+xml","lastModified":1673080818081,"thumbnail":"/images/thumbnails/file.svg"},{"name":"01 1.png","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/01_1_lk8guwgh.png-1689695137766.png?download=1","size":2923,"type":"image/png","lastModified":1673082964314,"thumbnail":"/images/thumbnails/file.svg"},{"name":"🦆 emoji _love letter_.svg","url":"https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/_emoji_love_letter_lk8gv52v.svg-1689695148930.svg?download=1","size":2161,"type":"image/svg+xml","lastModified":1673080818081,"thumbnail":"/images/thumbnails/file.svg"}]',
    phaseId: '818',
    meetId: null,
    meetLink: null,
    meetMetaData: null,
    estimatedTime: '',
    createdAt: '2023-07-18T10:16:00.445Z',
    componentName: null,
    timeLogged: '',
    linkedGoalId: null,
    labelId: null,
    releaseVersionId: null,
    projectPhaseId: null,
    identifier: 'PX-110'
  },],
  selectedTask: null,
  selectedBug: null,
  selectedSubTask: null,
  selectedEvent: null,
  sprintDetailsModal: false,
  deleteSubTaskModal: false,
  newTaskModal: {
    visible: false,
    isNewScope: false,
    scopeDescription: ''
  },
  newBugModal: false,
  newSubTaskModal: {
    visible: false,
    editTask: false,
    subtask: {
      sprintId: '',
      projectId: '',
      phaseId: '',
      names: [],
      isCompleted: false,
      statusId: '',
      priorityId: '',
      goalType: '',
      assigneeId: '',
      reporterId: '',
      description: '',
      parentGoalId: '',
      goalId: '',
      parentTaskName: 'Task'
    }
  },
  newEventModal: false,
  deleteTaskModal: {
    visible: false,
    statusId: 0,
    goalId: 1
  },
  deleteBugModal: {
    visible: false,
    statusId: 0,
    goalId: 1
  },
  deleteStatusModal: false,
  swimlanes: [
    {
      id: '56',
      projectId: '345',
      statusId: '1',
      title: 'To Do',
      tenantId: null,
      positionIndex: '1'
    },
    {
      id: '57',
      projectId: '345',
      statusId: '2',
      title: 'In Progress',
      tenantId: null,
      positionIndex: '2'
    },
    {
      id: '149',
      projectId: '345',
      statusId: '1',
      title: 'test 1',
      tenantId: null,
      positionIndex: '3'
    },
    {
      id: '150',
      projectId: '345',
      statusId: '2',
      title: 'test 2',
      tenantId: null,
      positionIndex: '4'
    },
    {
      id: '114',
      projectId: '345',
      statusId: '1',
      title: 'Hello',
      tenantId: null,
      positionIndex: '5'
    },
    {
      id: '115',
      projectId: '345',
      statusId: '2',
      title: 'Test New',
      tenantId: null,
      positionIndex: '6'
    },
    {
      id: '58',
      projectId: '345',
      statusId: '3',
      title: 'Done',
      tenantId: null,
      positionIndex: '7'
    }
  ],
  swimlaneDetails: {
    id: 0,
    projectId: 0,
    statusId: 1,
    title: 'To Do'
  },
  endPhaseModal: false,
  deleteSprintModal: {
    visible: false,
    sprintId: ''
  },
  completeSprintModal: {
    visible: false,
    sprintId: '',
    sprint: {
      name: ''
    }
  },
  filterGoalParameter: {
    searchValue: '',
    assignee: [],
    typeDropdown: [],
    filter: ''
  },
  createdSprintId: null,
  startSprintModal: false,
  startPhaseModal: false,
  newSprint: {},
  filters: {
    type: [],
    assignee: [],
    reporter: [],
    phase: [],
    priority: [],
    label: [],
    status: []
  },
  duplicateTaskModal: {
    visible: false,
    duplicateTaskDetails: null
  },
  editSprintModal: {
    visible: false,
    sprint: {
      id: '',
      name: '',
      startDate: '',
      endDate: '',
      phaseId: ''
    },
    mode: 'new',
    phaseId: ''
  },
  newLabelModal: false,
  manageLabelModal: false,
  deleteLabelModal: {
    visible: false,
    labelId: ''
  },
  newReleaseVersionModal: false,
  sprintKanbanView: true,
  plannerIsDragActive: {
    isActive: false,
    current: null
  },
  phaseModal: {
    visible: false,
    phaseId: ''
  },
  roadMapZoom: 160,
  roadMapView: 'ganttView',
  roadMapPhaseId: 0,
  run: true,
  showTakeTourModal: false,
  currentTourStep: 0,
  labels: [],
  releaseVersions: [
    {
      id: 3,
      tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
      versionName: 'P1 v1.0',
      projectId: '345',
      startDate: '2023-06-06T04:07:38.000Z',
      releaseDate: '2023-06-13T04:07:40.517Z',
      description: 'test release version',
      versionStatus: 0,
      associatedVersions: null,
      hasAssociatedVersions: 0
    }
  ],
  taskActivity: [],
  reportsGroupBy: {
    key: 'Status',
    value: []
  },
  todoList: [
    {
      id: '1041',
      name: 'Hello',
      isCompleted: 0,
      statusId: 1,
      componentId: null,
      priorityId: 1,
      dueDate: null,
      isNewGoal: 0,
      isActive: 1,
      projectId: '345',
      tenantId: '69291437-4846-4e92-8806-4547cda6b3e4',
      goalType: '1',
      parentGoalId: null,
      sprintId: null,
      assigneeId: '113',
      reporterId: null,
      description: null,
      swimlaneId: '56',
      categoryId: '1',
      dependencyId: '1',
      environment: null,
      attachments: null,
      phaseId: null,
      meetId: null,
      meetLink: null,
      meetMetaData: null,
      estimatedTime: null,
      createdAt: '2023-07-20T01:53:47.645Z',
      componentName: null,
      timeLogged: null,
      linkedGoalId: null,
      labelId: null,
      releaseVersionId: null,
      projectPhaseId: null,
      identifier: 'PX-112'
    }
  ],
  selectedTasksForDragAndDrop: [],
  showNewTaskCard: {
    visible: false,
    channel: {
      id: ''
    }
  },
  newTask: null,
 
}

export const vaultDummyData ={
  loading: false,
  error: null,
  status: 'fulfilled',
  addFileModal: false,
  addFolderModal: false,
  preFilledVaultModalValues: {
    goalId: null,
    phaseId: null,
    users: [],
    notificationId: ''
  },
  addLinkModal: false,
  openCommentsModal: {
    visible: false,
    docType: 'file',
    docId: '',
    name: '',
    comments: [],
    users: []
  },
  deleteFolderModal: {
    visible: false,
    id: null
  },
  deleteFileModal: {
    visible: false,
    id: null
  },
  deleteLinkModal: {
    visible: false,
    id: null
  },
  copyModal: {
    visible: false,
    docType: '',
    id: null,
    title: ''
  },
  moveToFolderModal: {
    visible: false,
    docId: '',
    docType: ''
  },
  requestAccessModal: {
    visible: false,
    title: '',
    subtext: '',
    data: {
      isProject: true,
      type: 'folder',
      folder: {
        id: '',
        createdById: '',
        folderName: ''
      },
      file: {
        id: '',
        createdById: '',
        name: ''
      },
      link: {
        id: '',
        createdById: '',
        name: ''
      }
    }
  },
  requestUploadModal: false,
  downloadModal: {
    visible: false,
    type: '',
    id: null
  },
  openLinkModal: {
    visible: false,
    id: null
  },
  folders: [
    {
      id: '169',
      filesCount: '3',
      linksCount: '0',
      folderName: "Preetinder's Space",
      phaseId: '1',
      createdAt: '2023-07-13T08:43:25.476Z',
      canEdit: 1,
      canView: 1,
      isOwner: 1,
      docaccessid: '589',
      dociddocaccess: '169',
      isChild: 0,
      createdById: '1',
      parentFolderId: null,
      hasChildFolder: 1,
      folderSize: '226595.00',
      usersWithAccess: [
        {
          id: '613',
          canEdit: 0,
          canView: 1,
          isOwner: 0,
          userId: '6',
          firstname: 'Kevin',
          lastname: 'Shah',
          profilePicture: null
        },
        {
          id: '589',
          canEdit: 1,
          canView: 1,
          isOwner: 1,
          userId: '1',
          firstname: 'Nutan1',
          lastname: 'Gaikwad1',
          profilePicture: null
        }
      ]
    },
    {
      id: '178',
      filesCount: '0',
      linksCount: '0',
      folderName: 'Test Folder',
      phaseId: null,
      createdAt: '2023-07-17T11:03:32.322Z',
      canEdit: 1,
      canView: 1,
      isOwner: 1,
      docaccessid: '628',
      dociddocaccess: '178',
      isChild: 0,
      createdById: '1',
      parentFolderId: null,
      hasChildFolder: null,
      folderSize: null,
      usersWithAccess: [
        {
          id: '628',
          canEdit: 1,
          canView: 1,
          isOwner: 1,
          userId: '1',
          firstname: 'Nutan1',
          lastname: 'Gaikwad1',
          profilePicture: null
        }
      ]
    }
  ],
  subfolders: [
    {
      id: '178',
      filesCount: '0',
      linksCount: '0',
      folderName: 'Sub Folder',
      phaseId: null,
      createdAt: '2023-07-17T11:03:32.322Z',
      canEdit: 1,
      canView: 1,
      isOwner: 1,
      docaccessid: '628',
      dociddocaccess: '178',
      isChild: 1,
      createdById: '1',
      parentFolderId: 169,
      hasChildFolder: null,
      folderSize: null,
      usersWithAccess: [
        {
          id: '628',
          canEdit: 1,
          canView: 1,
          isOwner: 1,
          userId: '1',
          firstname: 'Nutan1',
          lastname: 'Gaikwad1',
          profilePicture: null
        }
      ]
    }
  ],
  files:[ {
    id: '261',
    name: 'Thu Jul 13 2023 12_34_58 GMT+0530 (India Standard Time).csv',
    url: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/Thu_Jul_13_2023_12_34_58_GMT_0530_India_Standard_Time_.csv-1689251057182.csv?download=1',
    thumbnail: null,
    fileSize: '907',
    createdAt: '2023-07-13T12:24:21.045Z',
    updatedAt: null,
    phaseId: null,
    goalId: null,
    ownerFirstName: 'Nutan1',
    ownerLastName: 'Gaikwad1',
    isActive: 1,
    folderId: null,
    mimeType: 'text/csv',
    createdById: '1',
    canEdit: 1,
    canView: 1,
    isOwner: 1,
    usersWithAccess: [
      {
        id: '618',
        canEdit: 1,
        canView: 1,
        isOwner: 1,
        userId: '1',
        firstname: 'Nutan1',
        lastname: 'Gaikwad1',
        profilePicture: null
      }
    ]
  },
  {
    id: '260',
    name: 'Waddle-V-1-2 - 2023-07-13T124649.198.png',
    url: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/Waddle_V_1_2_2023_07_13T124649.198.png-1689251057589.png?download=1',
    thumbnail: null,
    fileSize: '88196',
    createdAt: '2023-07-13T12:24:21.045Z',
    updatedAt: null,
    phaseId: null,
    goalId: null,
    ownerFirstName: 'Nutan1',
    ownerLastName: 'Gaikwad1',
    isActive: 1,
    folderId: null,
    mimeType: 'image/png',
    createdById: '1',
    canEdit: 1,
    canView: 1,
    isOwner: 1,
    usersWithAccess: [
      {
        id: '617',
        canEdit: 1,
        canView: 1,
        isOwner: 1,
        userId: '1',
        firstname: 'Nutan1',
        lastname: 'Gaikwad1',
        profilePicture: null
      }
    ]
  },
  {
    id: '259',
    name: 'Waddle-V-1-2 - 2023-07-13T133727.160.png',
    url: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/Waddle_V_1_2_2023_07_13T133727.160.png-1689251058162.png?download=1',
    thumbnail: null,
    fileSize: '111364',
    createdAt: '2023-07-13T12:24:21.045Z',
    updatedAt: null,
    phaseId: null,
    goalId: null,
    ownerFirstName: 'Nutan1',
    ownerLastName: 'Gaikwad1',
    isActive: 1,
    folderId: null,
    mimeType: 'image/png',
    createdById: '1',
    canEdit: 1,
    canView: 1,
    isOwner: 1,
    usersWithAccess: [
      {
        id: '616',
        canEdit: 1,
        canView: 1,
        isOwner: 1,
        userId: '1',
        firstname: 'Nutan1',
        lastname: 'Gaikwad1',
        profilePicture: null
      }
    ]
  },
  {
    id: '258',
    name: 'Waddle-V-1-2 - 2023-07-13T134742.482.png',
    url: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/Waddle_V_1_2_2023_07_13T134742.482.png-1689251058257.png?download=1',
    thumbnail: null,
    fileSize: '102677',
    createdAt: '2023-07-13T12:24:21.045Z',
    updatedAt: null,
    phaseId: null,
    goalId: null,
    ownerFirstName: 'Nutan1',
    ownerLastName: 'Gaikwad1',
    isActive: 1,
    folderId: null,
    mimeType: 'image/png',
    createdById: '1',
    canEdit: 1,
    canView: 1,
    isOwner: 1,
    usersWithAccess: [
      {
        id: '615',
        canEdit: 1,
        canView: 1,
        isOwner: 1,
        userId: '1',
        firstname: 'Nutan1',
        lastname: 'Gaikwad1',
        profilePicture: null
      }
    ]
  },],
  links: [
    {
      id: '78',
      name: 'Link for devs',
      description: '',
      username: '',
      password: '',
      createdAt: '2023-07-20T17:24:43.437Z',
      createdById: '1',
      folderId: null,
      goalId: null,
      url: 'https://frontend-dev.waddle.ai/library/429',
      phaseId: null,
      updatedAt: null,
      canEdit: 1,
      canView: 1,
      isOwner: 1,
      usersWithAccess: [
        {
          id: '665',
          canEdit: 1,
          canView: 1,
          isOwner: 1,
          userId: '1',
          firstname: 'Nutan1',
          lastname: 'Gaikwad1',
          profilePicture: null
        }
      ]
    },
    {
      id: '77',
      name: 'Waddle Local',
      description: '',
      username: '',
      password: '',
      createdAt: '2023-07-20T17:23:49.180Z',
      createdById: '1',
      folderId: null,
      goalId: null,
      url: 'http://localhost:3000/library/429',
      phaseId: null,
      updatedAt: null,
      canEdit: 1,
      canView: 1,
      isOwner: 1,
      usersWithAccess: [
        {
          id: '664',
          canEdit: 1,
          canView: 1,
          isOwner: 1,
          userId: '1',
          firstname: 'Nutan1',
          lastname: 'Gaikwad1',
          profilePicture: null
        }
      ]
    }
  ],
  shareAccessDoc: {
    docId: '',
    users: [],
    docType: '',
    docDetails: {
      folderName: '',
      fileName: '',
      linkName: '',
      name: ''
    },
    notificationId: ''
  },
  showDocDetailsDrawer: {
    visible: false,
    docId: '',
    docType: '',
    docDetails: {}
  },
  transferOwnershipModal: {
    visible: false,
    id: null,
    docType: null,
    usersWithAccess: []
  },
  openAndShareAccessFile: {
    visible: false,
    id: ''
  },
  mySpaceFolder: null,
  newFolderModal: false,
  newLinkModal: false,
  editLinkModal: {
    visible: false,
    linkName: '',
    url: '',
    username: '',
    password: '',
    description: '',
    linkId: ''
  },
  renameModal: {
    visible: false,
    currentName: '',
    type: 'folder',
    id: ''
  },
  viewDetailsDrawer: {
    visible: false,
    documentType: '',
    document: {
      title: '',
      createdAt: '',
      usersWithAccess: [],
      fileSize: '',
      filesCount: '',
      docId: '',
      createdById: '',
      mimeType: '',
      folderSize: ''
    },
    title: ''
  },
  commentsDrawer: {
    visible: false,
    title: ''
  },
  shareModal: {
    visible: false,
    documentType: '',
    title: ''
  },
  shareAccessModal: {
    visible: false,
    docId: '',
    users: [],
    docType: '',
    docDetails: {
      folderName: '',
      fileName: '',
      linkName: '',
      name: ''
    },
    notificationId: ''
  },
  currentSelectedProject: {
    title: '',
    projectImage: ''
  },
  shareInMessageModal: {
    type: 'folder',
    visible: false,
    url: '',
    file: []
  },
  viewType: 1,
  search: '',
  currentSelectedFolder: {}
}

export const projectDummyData = {
  loading: false,
  error: null,
  status: 'fulfilled',
  loggedIn: true,
  projectList: [
    {
      id: '26',
      title: 'New Test',
      components: null,
      startDate: '2023-07-18T08:53:58.422Z',
      userId: '1',
      endDate: '2023-09-07T08:53:58.422Z',
      projectImage: '',
      createdById: '1',
      progress: 2,
      colorCode: '',
      isDiscarded: 0,
      isActive: 1,
      isPinned: 0,
      createdAt: '2023-07-18T08:54:16.111Z',
      currentactivesprint: null,
      fullName: 'Nutan1 Gaikwad1',
      profilePicture: null,
      phases: [
        {
          projectId: '26',
          phaseId: '1',
          isCompleted: 0,
          startDate: null,
          endDate: null,
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '65'
        },
        {
          projectId: '26',
          phaseId: '2',
          isCompleted: 0,
          startDate: null,
          endDate: null,
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '66'
        },
        {
          projectId: '26',
          phaseId: '3',
          isCompleted: 0,
          startDate: null,
          endDate: null,
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '67'
        },
        {
          projectId: '26',
          phaseId: '4',
          isCompleted: 0,
          startDate: null,
          endDate: null,
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '68'
        },
        {
          projectId: '26',
          phaseId: '5',
          isCompleted: 0,
          startDate: null,
          endDate: null,
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '69'
        }
      ]
    },
    {
      id: '22',
      title: 'Plants',
      components: null,
      startDate: '2023-07-12T10:52:24.659Z',
      userId: '1',
      endDate: '2023-08-30T10:52:24.659Z',
      projectImage: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/Waddle_V_1_2_2023_07_17T153712_lk6pdfmf.png-1689588518702.png?download=1',
      createdById: '1',
      progress: 14.25,
      colorCode: '',
      isDiscarded: 0,
      isActive: 1,
      isPinned: 0,
      createdAt: '2023-07-12T10:57:38.059Z',
      currentactivesprint: 51,
      fullName: 'Nutan1 Gaikwad1',
      profilePicture: null,
      phases: [
        {
          projectId: '22',
          phaseId: '1',
          isCompleted: 0,
          startDate: '2023-07-14T07:38:25.764Z',
          endDate: '2023-07-22T07:38:29.626Z',
          phaseProgress: 37.5,
          isOngoing: 1,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '53'
        },
        {
          projectId: '22',
          phaseId: '2',
          isCompleted: 1,
          startDate: '2023-07-24T00:00:00.000Z',
          endDate: '2023-07-27T00:00:00.000Z',
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '',
          phaseIteration: null,
          id: '55'
        },
        {
          projectId: '22',
          phaseId: '3',
          isCompleted: 0,
          startDate: '2023-08-01T00:00:00.000Z',
          endDate: '2023-08-15T00:00:00.000Z',
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '',
          phaseIteration: null,
          id: '62'
        }
      ]
    },
    {
      id: '20',
      title: 'Frames',
      components: null,
      startDate: '2023-07-11T09:51:32.622Z',
      userId: '1',
      endDate: '2023-08-31T09:51:32.622Z',
      projectImage: '',
      createdById: '1',
      progress: 34,
      colorCode: '#FFFF66',
      isDiscarded: 0,
      isActive: 1,
      isPinned: 0,
      createdAt: '2023-07-10T09:52:26.701Z',
      currentactivesprint: null,
      fullName: 'Nutan1 Gaikwad1',
      profilePicture: null,
      phases: [
        {
          projectId: '20',
          phaseId: '1',
          isCompleted: 0,
          startDate: '2023-07-11T07:02:43.186Z',
          endDate: '2023-07-19T07:02:45.303Z',
          phaseProgress: 50,
          isOngoing: 1,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '44'
        }
      ]
    },
    {
      id: '18',
      title: 'Nutan Test',
      components: null,
      startDate: '2023-07-06T07:01:23.879Z',
      userId: '1',
      endDate: '2023-08-31T07:01:23.879Z',
      projectImage: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/Screenshot_20230712_140520_ljzjdrhc.jpg-1689155189044.jpg?download=1',
      createdById: '1',
      progress: 16.25,
      colorCode: '',
      isDiscarded: 0,
      isActive: 1,
      isPinned: 1,
      createdAt: '2023-07-06T07:26:20.401Z',
      currentactivesprint: 38,
      fullName: 'Nutan1 Gaikwad1',
      profilePicture: null,
      phases: [
        {
          projectId: '18',
          phaseId: '1',
          isCompleted: 0,
          startDate: '2023-07-24T00:00:00.000Z',
          endDate: '2023-07-31T00:00:00.000Z',
          phaseProgress: 0,
          isOngoing: 1,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '',
          phaseIteration: '1',
          id: '46'
        },
        {
          projectId: '18',
          phaseId: '3',
          isCompleted: 0,
          startDate: '2023-07-06T00:00:00.000Z',
          endDate: '2023-07-12T00:00:00.000Z',
          phaseProgress: 50,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '37'
        },
        {
          projectId: '18',
          phaseId: '4',
          isCompleted: 0,
          startDate: '2023-07-13T00:00:00.000Z',
          endDate: '2023-07-25T00:00:00.000Z',
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 0,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '45'
        },
        {
          projectId: '18',
          phaseId: '4',
          isCompleted: 0,
          startDate: '2023-07-31T00:00:00.000Z',
          endDate: '2023-08-02T00:00:00.000Z',
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '',
          phaseIteration: '2',
          id: '54'
        },
        {
          projectId: '18',
          phaseId: '4',
          isCompleted: 0,
          startDate: '2023-07-25T00:00:00.000Z',
          endDate: '2023-07-31T00:00:00.000Z',
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '',
          phaseIteration: '0',
          id: '52'
        }
      ]
    },
    {
      id: '17',
      title: 'Waddle V3',
      components: null,
      startDate: '2023-06-13T05:23:29.357Z',
      userId: '1',
      endDate: '2023-08-31T05:23:29.357Z',
      projectImage: '',
      createdById: '1',
      progress: 43.5,
      colorCode: '#21e05a',
      isDiscarded: 0,
      isActive: 1,
      isPinned: 1,
      createdAt: '2023-06-14T05:31:00.173Z',
      currentactivesprint: 30,
      fullName: 'Nutan1 Gaikwad1',
      profilePicture: null,
      phases: [
        {
          projectId: '17',
          phaseId: '1',
          isCompleted: 1,
          startDate: '2023-06-15T00:00:00.000Z',
          endDate: '2023-07-03T00:00:00.000Z',
          phaseProgress: 50,
          isOngoing: 0,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '',
          phaseIteration: '1',
          id: '40'
        },
        {
          projectId: '17',
          phaseId: '1',
          isCompleted: 1,
          startDate: '2023-06-13T00:00:00.000Z',
          endDate: '2023-06-20T00:00:00.000Z',
          phaseProgress: 50,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '41'
        },
        {
          projectId: '17',
          phaseId: '2',
          isCompleted: 0,
          startDate: '2023-06-20T00:00:00.000Z',
          endDate: '2023-06-28T00:00:00.000Z',
          phaseProgress: 50,
          isOngoing: 0,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '',
          phaseIteration: '1',
          id: '36'
        },
        {
          projectId: '17',
          phaseId: '2',
          isCompleted: 0,
          startDate: '2023-06-21T00:00:00.000Z',
          endDate: '2023-06-28T00:00:00.000Z',
          phaseProgress: 50,
          isOngoing: 1,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '42'
        },
        {
          projectId: '17',
          phaseId: '3',
          isCompleted: 0,
          startDate: '2023-06-27T00:00:00.000Z',
          endDate: '2023-07-05T00:00:00.000Z',
          phaseProgress: 50,
          isOngoing: 1,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '',
          phaseIteration: '',
          id: '43'
        }
      ]
    },
    {
      id: '11',
      title: 'Road Management',
      components: null,
      startDate: '2023-01-23T09:55:26.745Z',
      userId: '1',
      endDate: '2023-03-10T00:00:00.000Z',
      projectImage: '',
      createdById: '1',
      progress: 16.965,
      colorCode: '#e62525',
      isDiscarded: 0,
      isActive: 1,
      isPinned: 0,
      createdAt: '2023-01-23T09:55:58.685Z',
      currentactivesprint: 13,
      fullName: 'Nutan1 Gaikwad1',
      profilePicture: null,
      phases: [
        {
          projectId: '11',
          phaseId: '1',
          isCompleted: 0,
          startDate: '2023-01-23T00:00:00.000Z',
          endDate: '2023-03-03T00:00:00.000Z',
          phaseProgress: 50,
          isOngoing: 1,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '35'
        },
        {
          projectId: '11',
          phaseId: '2',
          isCompleted: 0,
          startDate: '2023-02-08T00:00:00.000Z',
          endDate: '2023-02-22T00:00:00.000Z',
          phaseProgress: 17.86,
          isOngoing: 1,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '34'
        },
        {
          projectId: '11',
          phaseId: '3',
          isCompleted: 0,
          startDate: null,
          endDate: '2023-03-03T00:00:00.000Z',
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: '',
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '18'
        },
        {
          projectId: '11',
          phaseId: '4',
          isCompleted: 0,
          startDate: null,
          endDate: null,
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: null,
          phaseIteration: null,
          id: '22'
        }
      ]
    },
    {
      id: '8',
      title: 'AP',
      components: null,
      startDate: '2023-01-19T10:08:58.542Z',
      userId: '1',
      endDate: '2023-01-31T10:08:58.542Z',
      projectImage: '',
      createdById: '1',
      progress: 0,
      colorCode: '',
      isDiscarded: 1,
      isActive: 1,
      isPinned: 0,
      createdAt: '2023-01-19T10:09:26.573Z',
      currentactivesprint: null,
      fullName: 'Nutan1 Gaikwad1',
      profilePicture: null,
      phases: [
        {
          projectId: '8',
          phaseId: '1',
          isCompleted: 0,
          startDate: '2023-01-19T00:00:00.000Z',
          endDate: '2023-01-24T00:00:00.000Z',
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '23'
        }
      ]
    },
    {
      id: '4',
      title: 'LLP',
      components: null,
      startDate: '2023-01-12T10:03:39.974Z',
      userId: '1',
      endDate: '2023-01-31T10:03:39.974Z',
      projectImage: '',
      createdById: '5',
      progress: 100,
      colorCode: '',
      isDiscarded: 0,
      isActive: 1,
      isPinned: 0,
      createdAt: '2023-01-12T10:04:17.681Z',
      currentactivesprint: 11,
      fullName: 'Ganesh Mohanty',
      profilePicture: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/download_ld42t0ic.jfif-1674192198755.jfif?download=1',
      phases: [
        {
          projectId: '4',
          phaseId: '1',
          isCompleted: 1,
          startDate: '2023-01-17T00:00:00.000Z',
          endDate: '2023-01-20T08:24:26.225Z',
          phaseProgress: 66.67,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"},{"id":2,"heading":"Postman"}]}]',
          phaseIteration: null,
          id: '16'
        },
        {
          projectId: '4',
          phaseId: '2',
          isCompleted: 0,
          startDate: '2023-01-20T00:00:00.000Z',
          endDate: '2023-01-25T00:00:00.000Z',
          phaseProgress: 50,
          isOngoing: 1,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '12'
        }
      ]
    },
    {
      id: '3',
      title: 'TCS',
      components: null,
      startDate: '2023-01-11T10:10:50.035Z',
      userId: '1',
      endDate: '2023-01-31T10:10:50.035Z',
      projectImage: '',
      createdById: '5',
      progress: 16.666666666666668,
      colorCode: '',
      isDiscarded: 0,
      isActive: 1,
      isPinned: 0,
      createdAt: '2023-01-11T10:11:10.778Z',
      currentactivesprint: null,
      fullName: 'Ganesh Mohanty',
      profilePicture: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/download_ld42t0ic.jfif-1674192198755.jfif?download=1',
      phases: [
        {
          projectId: '3',
          phaseId: '1',
          isCompleted: 0,
          startDate: '2023-01-12T00:00:00.000Z',
          endDate: '2023-01-15T00:00:00.000Z',
          phaseProgress: 50,
          isOngoing: 1,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '11'
        },
        {
          projectId: '3',
          phaseId: '2',
          isCompleted: 0,
          startDate: null,
          endDate: null,
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '5'
        },
        {
          projectId: '3',
          phaseId: '3',
          isCompleted: 0,
          startDate: null,
          endDate: null,
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '6'
        }
      ]
    },
    {
      id: '2',
      title: 'Macbook',
      components: null,
      startDate: '2023-01-11T08:08:25.657Z',
      userId: '1',
      endDate: '2023-02-28T08:08:25.657Z',
      projectImage: '',
      createdById: '1',
      progress: 2.776666666666667,
      colorCode: '#ae1e1e',
      isDiscarded: 0,
      isActive: 1,
      isPinned: 0,
      createdAt: '2023-01-11T08:09:32.054Z',
      currentactivesprint: 1,
      fullName: 'Nutan1 Gaikwad1',
      profilePicture: null,
      phases: [
        {
          projectId: '2',
          phaseId: '1',
          isCompleted: 0,
          startDate: '2023-01-12T00:00:00.000Z',
          endDate: '2023-01-31T00:00:00.000Z',
          phaseProgress: 8.33,
          isOngoing: 1,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '7'
        },
        {
          projectId: '2',
          phaseId: '2',
          isCompleted: 1,
          startDate: '2023-01-18T00:00:00.000Z',
          endDate: '2023-01-18T10:45:05.144Z',
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '13'
        },
        {
          projectId: '2',
          phaseId: '3',
          isCompleted: 0,
          startDate: '2023-02-01T00:00:00.000Z',
          endDate: '2023-02-27T00:00:00.000Z',
          phaseProgress: 0,
          isOngoing: 0,
          phaseEndDateChangeReason: null,
          isActive: 1,
          tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
          components: '[{"id":1,"title":"Back-end","content":[{"id":1,"heading":"Node JS"}]},{"id":2,"title":"Front-end","content":[{"id":1,"heading":"React JS"},{"id":2,"heading":"Next JS"}]},{"id":3,"title":"Testing","content":[{"id":1,"heading":"BrowserStack"}]}]',
          phaseIteration: null,
          id: '4'
        }
      ]
    }
  ],
  refreshCount: 0,
  selectedProject: null,
  projectPermission: {
    overview: [
      {
        permissionId: '1',
        permissionName: 'projects',
        moduleName: 'overview'
      }
    ]
  },
  permissionArray: [
    '1'
  ],
  permissionInfo: null,
  overview: null,
  schedule: null,
  vault: null
}

export const overViewDummyData =  {
  loading: false,
  error: null,
  status: 'fulfilled',
  projectDetails: {
    id: '22',
    title: 'Plants',
    description: null,
    createdById: '1',
    updatedById: null,
    components: null,
    startDate: '2023-07-12T10:52:24.659Z',
    endDate: '2023-08-30T10:52:24.659Z',
    progress: 14.25,
    projectEndDateChangeReason: null,
    createdAt: '2023-07-12T10:57:38.059Z',
    updatedAt: null,
    isActive: 1,
    isPinned: 0,
    colorCode: '',
    tenantId: 'bc4ec66a-de04-4aef-a36d-e4a8937f6044',
    isEnded: 0,
    projectImage: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/Waddle_V_1_2_2023_07_17T153712_lk6pdfmf.png-1689588518702.png?download=1',
    clientName: 'ant',
    clientImage: '',
    clientLocation: 'Mumbai, Maharashtra, India',
    projectType: '[1]',
    internalProject: 0,
    clientColor: '',
    isDiscarded: 0,
    projectThemeColor: '',
    automations: true
  },
  userList: [
    {
      userId: '1',
      roleId: '35',
      firstname: 'Nutan1',
      lastname: 'Gaikwad1',
      isActive: true,
      mobile: '9876543212',
      email: 'nutan@antarcticaglobal.com',
      profilePicture: null,
      roleName: 'PM',
      dateAdded: '2023-07-18T10:10:27.025Z',
      createdAt: '2023-01-11T07:04:11.479Z',
      lat: 19.0759837,
      'long': 72.8776559,
      location: 'Mumbai, Maharashtra, India',
      company: 'Antarctica',
      jobTitle: 'PM',
      spoc: 0
    },
    {
      userId: '6',
      roleId: '34',
      firstname: 'Kevin',
      lastname: 'Shah',
      isActive: true,
      mobile: null,
      email: 'kevin@antarcticaglobal.com',
      profilePicture: null,
      roleName: 'User view only',
      dateAdded: '2023-07-18T10:09:57.126Z',
      createdAt: '2023-01-11T07:29:51.506Z',
      lat: null,
      'long': null,
      location: 'India',
      company: 'Antarctica Global',
      jobTitle: null,
      spoc: 0
    },
    {
      userId: '5',
      roleId: null,
      firstname: 'Ganesh',
      lastname: 'Mohanty',
      isActive: true,
      mobile: '98800988800',
      email: 'ganesh@antarcticaglobal.com',
      profilePicture: 'https://link.ap1.storjshare.io/s/juu3msqdfpq2yhnhpx4hmc35x65a/mvp-qa/contents/profile-pictures/download_ld42t0ic.jfif-1674192198755.jfif?download=1',
      roleName: null,
      dateAdded: '2023-07-18T12:36:25.346Z',
      createdAt: '2023-01-11T07:29:51.506Z',
      lat: 19.0759837,
      'long': 72.8776559,
      location: 'Mumbai, Maharashtra, India',
      company: 'Antarctica Global',
      jobTitle: 'Project Manager',
      spoc: 0
    }
  ]
}

export const userDetailsDummyData = {
  loading: false,
  error: null,
  status: 'fulfilled',
  loggedIn: true,
  user: {
    id: '113',
    roleId: '1',
    firstName: 'Ganesh',
    lastName: 'Mohanty',
    mobile: '7039734268',
    email: 'preetinder@antarcticaglobal.com',
    profilePicture: 'https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/java_ljh4vfru.png-1688042420677.png?download=1',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExMyIsImVtYWlsIjoicHJlZXRpbmRlckBhbnRhcmN0aWNhZ2xvYmFsLmNvbSIsInRlbmFudF9pZCI6IjY5MjkxNDM3LTQ4NDYtNGU5Mi04ODA2LTQ1NDdjZGE2YjNlNCIsImlhdCI6MTY4OTg3MzI3MywiZXhwIjoxNjg5OTU5NjczfQ.8RDN-vAn09ceUwcnclSXSPzE-7fNPls0gS6Tqw906Xc',
    logMe: 1,
    tenant_id: '69291437-4846-4e92-8806-4547cda6b3e4',
    tenantDetails: {
      tenantId: 'b33b4c23-19e9-48bf-bb8f-051572600198',
      tenantHostname: 'invite',
      tenantName: 'invite',
      createdAt: '2023-07-19T04:37:24.818Z',
      updatedAt: null,
      sdgs: '["4","6","12"]',
      sectorsList: '[2]',
      tenantLocation: 'Mumbai, Maharashtra, India',
      tenantSize: '2'
    },
    companyName: 'Antarctica ',
    conpany: 'Antarctica ',
    jobTitle: 'Full Stack Developer',
    location: 'Mumbai, Maharashtra, India',
    showJobTitle: true,
    showPhoneNumber: true,
    showCompanyName: true,
    myLibraryId: '3976',
    refreshToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExMyIsImVtYWlsIjoicHJlZXRpbmRlckBhbnRhcmN0aWNhZ2xvYmFsLmNvbSIsInRlbmFudF9pZCI6ImIzM2I0YzIzLTE5ZTktNDhiZi1iYjhmLTA1MTU3MjYwMDE5OCIsImlhdCI6MTY4OTg3MzI3MywiZXhwIjoxNjkyNDY1MjczfQ.n66cRzBG40eVLoh6QVWC8gxAAn2gxi0UPyYanK7HRpo',
    profileProgress: 100
  },
  allowedNotifications: []
}