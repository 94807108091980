/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from "react";
import { Dropdown, Tooltip, MenuProps } from "antd";
import { CommentIcon, CommentWithRedDotIcon } from "../../Core/svgV2/Comments";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { ShareAccessIcon } from "../../Core/svgV2/UserIcon";
import { MoveToIcon } from "../../Core/svgV2/MoveToIcon";
import { EditIcon } from "../../Core/svgV2/EditIcon";
import { CopyIcon } from "../../Core/svgV2/CopyIcon";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { useAppDispatch } from "../../Core/redux/hooks";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import { getPhaseName } from "../../Core/utils/getPhaseName";
import copyTextToClipboard from "../../Core/utils/clipboard";
import { useSearchParams } from "react-router-dom";

type Props = {
  link: any;
};

const LinkContainer = ({ link }: Props) => {
  const dispatch = useAppDispatch();

  const [copy, setCopy] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 2000);
    }
  }, [copy]);

  const groupItems = [
    {
      key: "1",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            link.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Edit
        </div>
      ),

      icon: (
        <EditIcon
          className={`text-gray-700 ${
            link.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">
          {copy ? "Copied" : "Copy Link"}
        </div>
      ),
      icon: <CopyIcon className="text-gray-700" />,
    },
    {
      key: "3",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">View details</div>
      ),
      icon: <EllipseIcon className="text-gray-700" />,
    },
    { key: "4", type: "divider" },
    {
      key: "5",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Open comments</div>
      ),
      icon: <CommentIcon className="text-gray-700" width="16" height="16" />,
    },
    {
      key: "6",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            link.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Share
        </div>
      ),

      icon: (
        <ShareAccessIcon
          className={`text-gray-700 ${
            link.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "7",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            link.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Move to
        </div>
      ),

      icon: (
        <MoveToIcon
          className={`text-gray-700 ${
            link.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },

    { key: "8", type: "divider" },
    {
      key: "9",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            link.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Delete
        </div>
      ),

      icon: (
        <DeleteIcon
          className={`text-gray-700 ${
            link.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setDropdownOpen(false);
    switch (key) {
      case "1":
        if (link.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "link",
                  link,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "editLinkModal",
            value: {
              visible: true,
              linkName: link.name,
              url: link.url,
              username: link.username,
              password: link.password,
              description: link.description,
              linkId: link.id,
            },
          })
        );
        dispatch(
          updateVault({
            key: "newLinkModal",
            value: true,
          })
        );
        break;
      case "2":
        copyTextToClipboard(link.url);
        setCopy(true);
        break;
      case "3":
        dispatch(
          updateVault({
            key: "viewDetailsDrawer",
            value: {
              visible: true,
              document: {
                title: link.name,
                usersWithAccess: link.usersWithAccess,
                createdAt: link.createdAt,
                docId: link.id,
                createdById: link.createdById,
              },
              documentType: "link",
            },
          })
        );
        break;
      case "5":
        dispatch(
          updateVault({
            key: "openCommentsModal",
            value: {
              visible: true,
              docType: "link",
              name: link.name,
              docId: link.id,
              comments: [],
              users: link.usersWithAccess,
            },
          })
        );
        break;
      case "6":
        if (link.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "link",
                  link,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "shareAccessModal",
            value: {
              visible: true,
              docId: link.id,
              users: link.usersWithAccess,
              docType: "link",
              docDetails: {
                ...link,
              },
            },
          })
        );
        break;
      case "7":
        if (link.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "link",
                  link,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "moveToFolderModal",
            value: {
              visible: true,
              docId: link.id,
              docType: "link",
            },
          })
        );
        break;
      case "9":
        if (link.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "link",
                  link,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "deleteLinkModal",
            value: {
              visible: true,
              id: link.id,
            },
          })
        );
        break;
      default:
        console.log("item others");
        break;
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClick = (e) => {
    // if (e.target !== e.currentTarget) {
    //   // Click occurred inside child element, skip the action
    //   return;
    // }
    console.log(e, "vsvsvsvsdvsvs");
    window.open(link.url, "_blank", "noopener,noreferrer");
  };

  return (
    <div onDoubleClick={handleClick}>
      <Tooltip title={link.url} placement="top">
        <div
          className={`w-[173px] h-[74px] rounded-lg bg-white border border-gray-200 pt-4 pb-[10px] px-3 group relative cursor-pointer ${
            searchParams.get("sharedLinkId") == link.id
              ? "shadow-[0px_0px_0px_2px_#D1E9FF]"
              : ""
          }`}
          // onClick={handleClick}
          data-link-id={link.id}
        >
          <div className="flex justify-between">
            <div className="flex items-center gap-x-3 pl-1">
              {link.phaseId && (
                <Tooltip
                  className="text-white cursor-pointer"
                  title={getPhaseName(link.phaseId)}
                >
                  <div>
                    <PhaseIcon
                      phaseId={+link.phaseId}
                      width="10px"
                      height="10px"
                    />
                  </div>
                </Tooltip>
              )}

              <Tooltip
                className="text-white cursor-pointer"
                title="Open comments"
                placement="bottom"
                color="#0D3271"
              >
                <div
                  className="mt-[-3px]"
                  onClick={() => {
                    dispatch(
                      updateVault({
                        key: "openCommentsModal",
                        value: {
                          visible: true,
                          docType: "link",
                          name: link.name,
                          docId: link.id,
                          comments: [],
                          users: link.usersWithAccess,
                        },
                      })
                    );
                  }}
                >
                  {true ? <CommentWithRedDotIcon /> : <CommentIcon />}
                </div>
              </Tooltip>
              <Tooltip
                className="text-white cursor-pointer"
                title="Shared document"
                placement="bottom"
                color="#0D3271"
              >
                <div
                  onDoubleClick={handleClick}
                  onClick={() =>
                    dispatch(
                      updateVault({
                        key: "shareAccessModal",
                        value: {
                          visible: true,
                          docId: link.id,
                          users: link.usersWithAccess,
                          docType: "link",
                          docDetails: {
                            ...link,
                          },
                        },
                      })
                    )
                  }
                >
                  <img src="/images/users.svg" alt="people with access" />
                </div>
              </Tooltip>
              <Tooltip
                className="text-white cursor-pointer"
                title={copy ? "Copied" : "Copy Link"}
                placement="bottom"
                color="#0D3271"
              >
                <div
                  onClick={() => {
                    copyTextToClipboard(link.url);
                    setCopy(true);
                  }}
                  className="group-hover:block hidden absolute top-[13px] left-[80px] cursor-pointer"
                >
                  <CopyIcon className="text-gray-700" />
                </div>
              </Tooltip>
            </div>
            <div
              className={`absolute top-[10px] right-[12px] cursor-pointer group-hover:block ${
                dropdownOpen ? "block" : "hidden"
              }`}
            >
              <Dropdown
                onOpenChange={(e) => setDropdownOpen(e)}
                menu={{ items: groupItems, onClick }}
                trigger={["click"]}
                dropdownRender={(origin) => {
                  return <div className="w-[250px]">{origin}</div>;
                }}
              >
                <DotsVerticalIcon className="text-black" />
              </Dropdown>
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <img src="/images/v2/library/link-icon.svg" className="w-5 h-5" />

            <p
              role="button"
              onClick={handleClick}
              className="ml-1.5 whitespace-nowrap truncate out-500-14 leading-5 text-gray-700"
            >
              {link.name}
            </p>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default LinkContainer;
