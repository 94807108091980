declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

function exportToCsv(filename, rows) {
  const processRow = function (row) {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function convertArrayToCsv(rows ,filename = `${new Date()}.csv`) {
  // Extract the keys from the first object in the rows array
  const keys = Object.keys(rows[0]);

  // Create a new array of rows with the values for each key
  // const processedRows = rows.map(row => keys.map(key => `"${row[key]}"`));

  const processedRows = rows.map(row => keys.map(key => {
    const value = row[key];
    return value == null || value === undefined ? '"-"' : `"${value}"`;
  }));
  // Add the keys as the first row in the processedRows array
  processedRows.unshift(keys.map(key => `"${key}"`));

  // Create the CSV file content by joining the rows with commas
  const csvFile = processedRows.map(row => row.join(',')).join('\n');

  // Create a Blob with the CSV file content
  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

  // Check if the browser supports the saveAs function
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    // Other browsers
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}


 


export default exportToCsv;
