import dayjs from "dayjs";

export function monthDiff(firstMonth, lastMonth) {
  let months;
  months = (lastMonth.getFullYear() - firstMonth.getFullYear()) * 12;
  months -= firstMonth.getMonth();
  months += lastMonth.getMonth();
  return months <= 0 ? 0 : months;
}

export function dayDiff(startDate, endDate) {
  const difference =
    new Date(endDate).getTime() - new Date(startDate).getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
}

// Function to calculate the number of days in each month between two dates
export function calculateTotalDays(startDate, endDate) {
  let totalDays = 0;

  for (
    let d = new Date(startDate);
    d <= endDate;
    d.setMonth(d.getMonth() + 1)
  ) {
    const daysInMonth = new Date(
      d.getFullYear(),
      d.getMonth() + 1,
      0
    ).getDate();

    totalDays += daysInMonth;
  }

  return totalDays;
}
export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function getDayOfWeek(year, month, day) {
  const daysOfTheWeekArr = ["M", "T", "W", "T", "F", "S", "S"];
  const dayOfTheWeekIndex = new Date(year, month, day).getDay();
  return daysOfTheWeekArr[dayOfTheWeekIndex];
}

export function createFormattedDateFromStr(year, month, day) {
  let monthStr = month.toString();
  let dayStr = day.toString();

  if (monthStr.length === 1) {
    monthStr = `0${monthStr}`;
  }
  if (dayStr.length === 1) {
    dayStr = `0${dayStr}`;
  }
  return `${year}-${monthStr}-${dayStr}`;
}

export function createFormattedDateFromDate(date) {
  let monthStr = (date.getMonth() + 1).toString();
  let dayStr = date.getDate().toString();

  if (monthStr.length === 1) {
    monthStr = `0${monthStr}`;
  }
  if (dayStr.length === 1) {
    dayStr = `0${dayStr}`;
  }
  return `${date.getFullYear()}-${monthStr}-${dayStr}`;
}

export const calculateNewDate = (
  distance: number,
  direction: string,
  dateWidth: number,
  currentDate: string
) => {
  console.log({ distance, direction, dateWidth, currentDate });
  const parsedDate = dayjs(currentDate);

  let daysToAdd;

  if (direction === "toRight") {
    daysToAdd = Math.ceil(distance / dateWidth);
    console.log(daysToAdd);
  } else if (direction === "toLeft") {
    daysToAdd = Math.floor(distance / dateWidth);
  } else {
    throw new Error("Invalid direction");
  }

  const newDate = parsedDate.add(daysToAdd, "day").format("YYYY-MM-DD");
  return newDate;
};
