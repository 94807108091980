import React, { useEffect, useState } from "react";

import EyeIcon from "../../Core/svg/EyeIcon.js";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "../../Core/CommonV2/CustomButton";
import CustomPopover from "../../Core/Common/CustomPopover";
import CustomOTPInput from "../../Core/Common/CustomOTPInput";

import CustomInputBox from "../../Core/CommonV2/CustomInputBox";
import RightScreenV2 from "./RightScreen";
import { setError } from "../../Core/redux/slices/global.slice";
import AlreadyExists from "./Modals/AlreadyExists";
import LoggedUser from "./Modals/LoggedUser";

import { client } from "../../Core/utils/axiosClient";
import { getWorkspaces } from "../../Core/redux/api/dashboardAPI";
import axios from "axios";
import { userAuthenticate } from "../../Core/redux/api/userAPI";
import { addActivityLog } from "../../Core/redux/api/activityLogAPI";

// type Props = {};

const SignUpScreenV2 = () => {
  const dispatch = useAppDispatch();
  const { user, loggedIn } = useAppSelector((state) => state.userDetails);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showExists, setShowExists] = useState(false);
  const [loggedUser, setLoggedUser] = useState(false);
  const [company, setCompany] = useState("");

  const token = searchParams.get("token");

  const [passwordFocused, setPasswordFocused] = useState(false);
  const [prevEmail, setPrevEmail] = useState("");
  const [step, setStep] = useState(1);

  const handleClose = () => {
    setLoggedUser(false);
  };
  const [createdUser, setCreatedUser] = useState(null);

  const hostname = window.location.host;
  const { tenantDetails } = useAppSelector((state) => state.tenant);

  const antarcticaTenantId = "3fb2a4e6-f736-4209-aceb-05e77d19b808"; // currently we are restricting some features for antarctica global tenant's users
  const [isLoading, setIsLoading] = useState(false);
  let emailExists;
  //todo allow creating users on qa

  const formik = useFormik({
    initialValues: {
      firstName: null,
      lastName: null,

      email: null,
      password: null,
      showPassword: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Please enter your first name")
        .typeError("Please enter your first name")
        .matches(/^[A-zÀ-ú\s]+$/, "Only alphabets are allowed for this field "),
      lastName: Yup.string()
        .required("Please enter your last name")
        .typeError("Please enter your last name")
        .matches(/^[A-zÀ-ú\s]+$/, "Only alphabets are allowed for this field "),

      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
      password: Yup.string()
        .required("Please enter the password")
        .typeError("Please enter the password")
        .min(8, "Password must be more than 8 characters")
        .test(
          "passwordRequirements",
          "Password does not meet requirements",
          (val) => {
            const regex =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-+_!@#$%^&*.,?]).+$/;

            // console.log(val, regex.test(val));
            return regex.test(val);
          }
        ),
      showPassword: Yup.bool(),
    }),
    onSubmit: async (values) => {
      const { showPassword, email, firstName, lastName, password } = values;

      if (token) {
        // invitee flow
        if (showPassword) handlePasswordVisible(false);
        console.log("email check", emailExists);
        emailExists = await client.get("auth/check-if-email-exists", {
          params: {
            email,
          },
        });
        console.log("email check 2", emailExists);
        //todo check for edge cases
        await client.post("/auth/send-otp", {
          userId: emailExists?.data.user.id,
          email: email,
          firstName,
          lastName,
          token,
        });
        localStorage.removeItem("signUpToken");
        setStep(2);
      } else {
        //super admin signup flow

        // setIsLoading(true);

        emailExists = await client.get("auth/check-if-email-exists", {
          params: {
            email,
          },
        });

        if (emailExists?.data?.exists) {
          await client.post("/auth/send-otp", {
            userId: emailExists?.data.user.id,
            email: email,
            firstName,
            lastName,
            token,
          });
          setStep(2);
        } else if (
          (hostname !== "localhost:3000" &&
            process.env.REACT_APP_ENV &&
            process.env.REACT_APP_ENV !== "qa" &&
            process.env.REACT_APP_ENV !== "develop" &&
            tenantDetails.tenantId !== antarcticaTenantId) ||
          +user.roleId === 4
        ) {
          await dispatch(
            setError({
              message:
                "You don't have the permission to perform this task, for further information please contact ebrahim@antarcticaglobal.com",
            })
          );
          setTimeout(() => navigate(-1), 1000);
        } else {
          try {
            if (prevEmail === email) {
              setStep(2);
              return;
            }
            emailExists = await client.get("auth/check-if-email-exists", {
              params: {
                email,
              },
            });

            if (emailExists.data.exists) {
              if (!emailExists.data.user.otpVerified) {
                // setShowExists(true);
                // setCompany(
                //   emailExists.data.userExistsInMultipleSpaces[0].tenantName
                // );
                handleClick();
                // console.log(emailExists, "workspaces");
              } else {
                if (emailExists.data.userExistsInMultipleSpaces.length > 1) {
                  setCompany("Multiple workspaces");
                } else {
                  setCompany(
                    emailExists.data.userExistsInMultipleSpaces[0].tenantName
                  );
                }
                setLoggedUser(true);
                // console.log(emailExists, "workspaces");
              }
              // setLoggedUser(true);
              formik.setFieldError("email", "Email already exists in Waddle.");
              return;
            } else {
              const response = await client.post("auth/register", {
                email,
                isMobile: false,
                firstname: firstName,
                lastname: lastName,
                password,
                confirmPassword: password,
              });

              if (response.status === 200) {
                setStep(2);
                setCreatedUser(response.data[0]);
              }
              //console.log(response);
            }
          } finally {
            setIsLoading(false);
          }
        }
        //we are not allowing superadmin flow currently
      }
    },
  });

  const handleClick = async () => {
    emailExists = await client.get("auth/check-if-email-exists", {
      params: {
        email: formik.values.email,
      },
    });
    console.log("email check 2", emailExists);
    //todo check for edge cases
    await client.post("/auth/send-otp", {
      userId: emailExists?.data.user.id,
      email: formik.values.email,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
    });
    setStep(2);
    // setShowExists(false);
  };
  const passwordRequirements = {
    hasLowercase: false,
    hasUppercase: false,
    hasNumerical: false,
    hasSpecialChar: false,
  };

  const password = formik.values.password;

  if (password) {
    const lowercaseRegex = /^(?=.*[a-z]).+$/;
    const uppercaseRegex = /^(?=.*[A-Z]).+$/;
    const numericalRegex = /^(?=.*[0-9]).+$/;
    const specialCharRegex = /^(?=.*[-+_!@#$%^&*.,?]).+$/;

    passwordRequirements.hasLowercase = lowercaseRegex.test(password);
    passwordRequirements.hasUppercase = uppercaseRegex.test(password);
    passwordRequirements.hasNumerical = numericalRegex.test(password);
    passwordRequirements.hasSpecialChar = specialCharRegex.test(password);
  }
  const handlePasswordVerified = () => {
    if (
      formik?.values?.password?.length >= 8 &&
      passwordRequirements.hasLowercase &&
      passwordRequirements.hasUppercase &&
      passwordRequirements.hasNumerical &&
      passwordRequirements.hasSpecialChar
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (searchParams.has("token")) {
      localStorage.setItem("signUpToken", token);
      const decoded = async () => {
        try {
          const { data } = await client.get("/auth/decode-token", {
            params: { token },
          });
          if (data.decode && data.decode.email) {
            formik.setFieldValue("email", data.decode.email);
          }
        } catch (e) {
          console.log(e);
        }
      };
      decoded();
    }
  }, [searchParams]);

  const formikOTP = useFormik({
    initialValues: {
      otpInput: null,
    },
    validationSchema: Yup.object({
      otpInput: Yup.string()
        .required("Please enter the OTP")
        .typeError("Please enter the OTP")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(6, "Must be exactly 6 digits")
        .max(6, "Must be exactly 6 digits"),
    }),
    onSubmit: async (values) => {
      const { otpInput } = values;
      // console.log(token, formik.values, otpInput);
      const { showPassword, ...user } = formik.values;
      const path = token ? "/auth/verify-otp" : "/auth/verify-otp-super-admin";
      try {
        const data = await client.post(path, {
          token,
          otp: otpInput,
          user,
        });
        setStep(3);
      } catch (err) {
        formikOTP.setFieldError("otpInput", "Invalid Otp");
      }

      // .then((res) => {

      //   console.log(res);
      //
      // })
      // .catch((e) => {
      //
      // });
    },
  });

  const handlePasswordVisible = (toggleState) => {
    formik.setFieldValue("showPassword", toggleState);
  };

  const handleLogin = async (values) => {
    const { email, password } = values;
    // console.log(values);

    // await dispatch(userAuthenticate({ email, password, isMobile: false }));

    // if (loggedIn) {
    //   navigate("/dashboard", { replace: true });
    // }
  };
  //for hiding email chars in case 2
  let encodedEmail;
  if (formik.values.email) {
    const atIndex = formik.values.email.indexOf("@");
    if (atIndex > 2) {
      // Extract the first two characters and the characters after the "@" symbol
      const firstTwo = formik.values.email.slice(0, 2);
      const afterAt = formik.values.email.slice(atIndex);
      encodedEmail = `${firstTwo}****${afterAt}`;
    } else {
      // Return the email as it is if it has less than two characters before the "@" symbol
      const afterAt = formik.values.email.slice(atIndex);
      encodedEmail = `****${afterAt}`;
      encodedEmail = formik.values.email;
    }
  }
  const handleWorkSpaces = async () => {
    const workspaces = await dispatch(getWorkspaces());

    if (workspaces.payload) {
      if (workspaces.payload.length === 1) {
        navigate("/dashboard");
      } else if (workspaces.payload.length === 0) {
        navigate("/onboardingV2");
      } else {
        navigate("/switch-workspace", { replace: true });
      }
    }
  };
  const handleRouting = (login?: any) => {
    const hostname = window.location.host;
    const subdomain = hostname.match(/^(.*?)\./)?.[1];
    const domain = hostname.match(/\.(.*?)\./)?.[1];
    const tld = hostname.match(/\.(.*)$/)?.[1];

    const userFromLocal = JSON.parse(localStorage.getItem("user"));

    console.log(hostname, subdomain, domain, tld, "hhhh");

    switch (true) {
      case login && login?.payload?.tenant_id === null:
        navigate("/onboardingV2", { replace: true });

        break;
      case loggedIn:
        if (
          hostname ===
          `${
            userFromLocal?.tenantDetails?.tenantHostname || ""
          }.${domain}.${tld}`
        ) {
          handleWorkSpaces();
          // navigate("/switch-workspace", { replace: true });
        } else {
          /* eslint-disable no-constant-condition */
          if (
            hostname !== "localhost:3000" &&
            process.env.REACT_APP_ENV &&
            process.env.REACT_APP_ENV !== "qa" &&
            false
          ) {
            axios.defaults.withCredentials = true;
            axios.defaults.xsrfCookieName = "jwt";
            axios.defaults.xsrfHeaderName = "jwt";
            axios.defaults.headers.common["jwt"] = user.token;

            window.location.hostname = `${userFromLocal?.tenantDetails?.tenantHostname}.waddle.ai/switch-workspace`;
          } else {
            handleWorkSpaces();
            //navigate("/switch-workspace", { replace: true });
          }
        }
        break;
    }
  };
  useEffect(() => {
    const userFromLocal = localStorage.getItem("user");
    if (
      loggedIn &&
      user.tenant_id &&
      userFromLocal &&
      JSON.parse(userFromLocal)?.id
    ) {
      // navigate("/dashboard", { replace: true });
      handleRouting();
    }
  }, [loggedIn]);

  const renderBody = () => {
    switch (step) {
      case 1:
        return (
          <div className=" flex flex-col  gap-y-5.5 bg-white ">
            <div className="h-[80px] flex justify-between w-full">
              <div className="min-w-[245px] w-[70%] gap-y-1 flex flex-col h-[76px] justify-between  ">
                <div className="out-600-24 text-primary-600 h-[32px]">
                  Welcome to Waddle!
                </div>
                <div className=" out-300-14 text-gray-500  ">
                  {
                    " You're among our first users to try it out. Glad to have you here!"
                  }
                </div>
              </div>
              <div className="h-[80px]  w-[58px]  ">
                <img
                  className="h-[80px]"
                  alt="img"
                  src={"/images/v2/onboarding/frosty-waving.svg"}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="out-300-14 text-gray-500">
                Already have an account?
              </div>
              <div>
                <CustomButton
                  text="Sign In"
                  className="text-gray-700  bg-inherit border-gray-300 border-[1px]  out-500-14"
                  height="30px"
                  width="90px"
                  onClick={() => navigate("/loginV2")}
                />
              </div>
            </div>

            <div className="   flex flex-col items-center justify-start w-full ">
              <form
                autoComplete="off"
                // style={{ width: 520 }}
                onSubmit={formik.handleSubmit}
                id="formik"
                className="w-full"
              >
                <div className="flex  gap-x-2">
                  <CustomInputBox
                    defaultValue="First name"
                    name={"firstName"}
                    placeholder="Enter your first name"
                    {...formik.getFieldProps("firstName")}
                    formikHook={formik}
                    className=""
                  />

                  <CustomInputBox
                    defaultValue="Last name"
                    name={"lastName"}
                    placeholder="Enter your last name"
                    {...formik.getFieldProps("lastName")}
                    formikHook={formik}
                    className=""
                  />
                </div>

                <CustomInputBox
                  defaultValue="Email"
                  name={"email"}
                  type="email"
                  placeholder="Enter your work email "
                  {...formik.getFieldProps("email")}
                  formikHook={formik}
                  className="mt-5.5"
                />

                <CustomPopover
                  content={
                    <div className="p-4">
                      <div className="text-gray-700 out-500-14 mb-2">
                        Password Requirements
                      </div>
                      <hr></hr>
                      <div className="text-gray-600 ">
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 mt-2 mb-2">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              formik?.values?.password?.length >= 8
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>{" "}
                          <div>8 characters minimum</div>
                        </div>
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 mb-2">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              passwordRequirements.hasLowercase
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>
                          <div>One lowercase character (a-z)</div>
                        </div>
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 mb-2">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              passwordRequirements.hasUppercase
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>
                          <div>One uppercase character (A-Z)</div>
                        </div>
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 mb-2">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              passwordRequirements.hasNumerical
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>
                          <div>One number (0-9)</div>
                        </div>
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 ">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              passwordRequirements.hasSpecialChar
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>
                          <div>One special character (!@#$%^&*)</div>
                        </div>
                      </div>
                    </div>
                  }
                  visible={passwordFocused}
                  placement={"left"}
                >
                  <CustomInputBox
                    onFocusChange={(isFocused, el = null) => {
                      const elName =
                        el?.nativeEvent?.explicitOriginalTarget?.getAttribute(
                          "name"
                        );
                      if (elName === "show-password" && !isFocused) return;
                      setPasswordFocused(isFocused);
                    }}
                    passwordUnverified={handlePasswordVerified()}
                    defaultValue="Password"
                    name={"password"}
                    type={formik.values.showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    {...formik.getFieldProps("password")}
                    formikHook={formik}
                    className="mt-5.5"
                    suffix={
                      <EyeIcon
                        color={"#667085"}
                        width={16}
                        show={formik.values.showPassword}
                        onToggle={handlePasswordVisible}
                      />
                    }
                  />
                </CustomPopover>

                {/* <>{console.log(formik.isValid, formik.dirty)}</> */}

                <CustomButton
                  text="Create your account"
                  type="submit"
                  height="36px"
                  width="100%"
                  className={`text-white ${
                    !(formik.isValid && formik.dirty)
                      ? "bg-primary-100"
                      : "bg-primary-600"
                  } out-500-14 mt-12`}
                  disabled={!(formik.isValid && formik.dirty)}
                  // onClick={() => dispatch(resetConversations(["editMessage"]))}
                />
              </form>
              {/* <Divider className="pb-2">
                <p className="out-500-15 text-gray-500">Or continue with</p>
              </Divider>
              <IdentityProviderSliceV2 /> */}
            </div>
          </div>
        );
      case 2:
        return (
          <div className=" flex flex-col h-[407px] gap-y-5.5 ">
            <div className="h-[80px] flex justify-between w-full">
              <div className="min-w-[223px] w-[70%] gap-y-1 flex flex-col h-[76px] justify-between  ">
                <div className="out-600-24 text-primary-600 h-[32px]">
                  Verify your email
                </div>
                <div className=" out-300-14 text-gray-500  ">
                  Enter the 6 digits code sent on{" "}
                  <span className="out-500-14 text-gray-900">
                    {encodedEmail}
                  </span>{" "}
                  to join the workspace.
                </div>
              </div>
              <div className="h-[80px]  w-[58px]  ">
                <img
                  className="h-[80px]"
                  alt="img"
                  src={"/images/v2/onboarding/resent-link.svg"}
                />
              </div>
            </div>
            <div className=" flex items-center justify-between w-[115px]">
              <form
                autoComplete="off"
                // style={{ width: 520 }}
                onSubmit={formikOTP.handleSubmit}
                id="formikOTP"
              >
                <CustomOTPInput
                  name="otpInput"
                  {...formikOTP.getFieldProps("otpInput")}
                  value={formikOTP.values.otpInput}
                  onChange={(value) => {
                    formikOTP.setFieldValue("otpInput", value);
                  }}
                  className="mt-5.5"
                  formikHook={formikOTP}
                />

                <div
                  className="out-500-14 text-primary-600 mt-5 cursor-pointer"
                  onClick={async () => {
                    setIsLoading(true);
                    await client.post("/auth/resend-otp", {
                      userId: createdUser?.id,
                      email: createdUser?.email,
                      token,
                    });
                    setIsLoading(false);
                  }}
                >
                  Resend code
                </div>

                <CustomButton
                  text="Verify"
                  type="submit"
                  height="36px"
                  width="100%"
                  className={`text-white ${
                    !(formikOTP.isValid && formikOTP.dirty)
                      ? "bg-primary-100"
                      : "bg-primary-600"
                  } out-500-14 mt-12 w-full`}
                  // disabled={!(formik.isValid && formik.dirty)}
                  // onClick={() => dispatch(resetConversations(["editMessage"]))}
                />
                <div
                  className="out-500-14 text-primary-700 mt-6 cursor-pointer"
                  onClick={() => {
                    setPrevEmail(formik.values.email);
                    setStep(1);
                  }}
                >
                  Change Email ID
                </div>
              </form>
            </div>
          </div>
        );
      case 3:
        return (
          <div className=" flex flex-col gap-y-6">
            <div className="h-[52px] flex flex-col justify-start w-full">
              <div className="min-w-[223px] gap-y-1 flex flex-col  justify-start  ">
                <div className="out-600-24 text-primary-600 h-[32px]">
                  Awesome!
                </div>
                <div className=" out-300-14 text-gray-500 w-full flex">
                  Verified as{" "}
                  <span className="out-500-14 text-gray-900 ml-1">
                    {" "}
                    {formik.values.email}
                  </span>{" "}
                  <span className="ml-2 h-5 w-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#F9FBFE"
                        d="M22 11.086v.92a10 10 0 11-5.93-9.14"
                      ></path>
                      <path fill="#F9FBFE" d="M22 4L12 14.01l-3-3"></path>
                      <path
                        stroke="#039855"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M22 11.086v.92a10 10 0 11-5.93-9.14M22 4L12 14.01l-3-3"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-center mx-auto items-center  h-[109px] w-[134px]">
              <img src="/images/v2/onboarding/jumping-frosty.svg" />
            </div>
            <div className=" flex flex-col h-[110px] gap-y-3 w-full mt-[2px] items-start justify-start ">
              <div className="out-500-14 text-gray-900 justify-start flex  ">
                {" We can’t wait for you to get started with Waddle 🐧"}
              </div>
              <div className="out-300-12 text-gray-500   ">
                We’ll start by answering some questions, so we can curate your
                experience better.
              </div>

              <div>
                <CustomButton
                  text="Lets go!"
                  className="bg-primary-600 text-white border-[1px]"
                  height="30px"
                  width="362px"
                  onClick={async () => {
                    const {
                      showPassword,
                      email,
                      firstName,
                      lastName,
                      password,
                    } = formik.values;

                    const login = await dispatch(
                      userAuthenticate({ email, password, isMobile: false })
                    );

                    dispatch(
                      addActivityLog({
                        title: ``,
                        activityType: 4,
                        meta: JSON.stringify({
                          email,
                        }),
                      })
                    );
                    // setLoading(false);

                    handleRouting(login);
                  }}
                />
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="flex w-screen h-screen overflow-x-hidden">
      <div className="flex-[6] bg-blue-50 overflow-y-scroll hide-scrollbar">
        <RightScreenV2 />
      </div>
      <div className="flex-[4] flex bg-white items-center h-full overflow-y-hidden">
        <div className="w-[407px] h-[90vh]  flex flex-col justify-start p-6  mt-[50px] mx-auto">
          {renderBody()}
        </div>
      </div>
      {showExists && (
        <AlreadyExists
          visible={showExists}
          email={formik.values.email}
          company={company}
          handleClose={handleClose}
          handleClick={handleClick}
        />
      )}
      {loggedUser && (
        <LoggedUser
          visible={loggedUser}
          company={company}
          email={formik.values.email}
          handleClose={handleClose}
          handleClick={handleClick}
        />
      )}
    </div>
  );
};

export default SignUpScreenV2;
