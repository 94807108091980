import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getActivityLogs = createAsyncThunk(
  "activityLogs/getActivityLogs",
  async (
    payload: {
      userId?: string[] | number[];
      activityType: string[] | number[];
      docId?: string;
      docType?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/activity-log`, { params: payload });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const addActivityLog = createAsyncThunk(
  "activityLogs/getActivityLogs",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(`/activity-log`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
