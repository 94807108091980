import { createSlice } from "@reduxjs/toolkit";
import { getActivityLogs } from "../api/activityLogAPI";


const initialState = {
    loading: false,
    error: {},
    status: "idle",
    activityLogs: [],
};

export const activityLogsSlice = createSlice({
    name: "activityLogs",
    initialState,
    reducers: {
        changeProject: (state, action) => {
            //state.selectedProject = action.payload;
        },
        resetToInitialActivityLogs: (state, action) => {
            Object.keys(initialState).forEach(
              (key) => (state[key] = initialState[key])
            );
          },
    },
    extraReducers(builder) {
        builder.addCase(getActivityLogs.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getActivityLogs.fulfilled, (state, action) => {
            state.loading = false;
            state.status = "fulfilled";
            
            state.activityLogs = action.payload.result;
        });
        builder.addCase(getActivityLogs.rejected, (state, action) => {
            state.loading = false;
            state.status = "rejected";
            state.error = action.error;
        });
    },
});

export const { changeProject , resetToInitialActivityLogs } = activityLogsSlice.actions;
export default activityLogsSlice.reducer;
