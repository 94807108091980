import React from "react";
import Icon from "@ant-design/icons";

const FolderSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="M3.2 4.8V3.2h4l1.2 1.6"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.8 12l-.015-6.8H3.2V12h7.6"
    ></path>
  </svg>
);

const FolderIcon = (props) => {
  return <Icon component={FolderSvg} {...props} />;
};

export { FolderIcon };
