import React, { useEffect, useRef, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import { DownloadIcon } from "../../../Core/svgV2/DownloadIcon";
import { MessagesIcon } from "../../../Core/LayoutV2/LayoutIcons";
import { ShareAccessIcon } from "../../../Core/svgV2/UserIcon";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { downloadFiles } from "../../../Core/utils";
import { Carousel, Dropdown } from "antd";

import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import dayjs from "dayjs";
import { formatBytes } from "../../../Core/utils/vaultUtils";
import { EllipseIcon } from "../../../Core/svgV2/Ellipse";
import { CommentIcon } from "../../../Core/svgV2/Comments";
import { MoveToIcon } from "../../../Core/svgV2/MoveToIcon";
import { ShareInMessageIcon } from "../../../Core/svgV2/ShareFile";
import { EditIcon } from "../../../Core/svgV2/EditIcon";
import { CopyIcon } from "../../../Core/svgV2/CopyIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import { updateVault } from "../../../Core/redux/slices/vault.slice";
import uniqid from "uniqid";
import getImageWeServeUrl from "../../../Core/utils/getImageWeServeUrl";

const ImagePan = ({ src, zoomIn, zoomOut, onZoomChange, imageOutOfBound }) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const [zoomInstance, setZoomInstance] = useState<ReactZoomPanPinchRef>(null);

  const minScale = 1;
  const maxScale = 4;

  useEffect(() => {
    onZoomChange(zoomInstance?.state?.scale);
  }, [zoomInstance?.state?.scale]);

  useEffect(() => {
    transformComponentRef?.current?.zoomIn(0.25, 150);
  }, [zoomIn]);
  useEffect(() => {
    transformComponentRef?.current?.zoomOut(0.25, 150);
  }, [zoomOut]);

  return (
    <div className="max-h-[60vh] min-w-[60vw] max-w-[60vw] min-h-[70vh]">
      <TransformWrapper
        initialScale={1}
        // initialPositionX={200}
        // initialPositionY={100}
        limitToBounds
        ref={transformComponentRef}
        key={src}
        centerOnInit
        minScale={minScale}
        maxScale={maxScale}
        centerZoomedOut
        onZoomStart={(zoom) => {
          onZoomChange(zoom.state.scale);
        }}
        onZoom={(zoom) => {
          onZoomChange(zoom.state.scale);
        }}
        onZoomStop={(zoom) => onZoomChange(zoom.state.scale)}
        onInit={(zoomzoom) => {
          setZoomInstance(zoomzoom);
          onZoomChange(zoomzoom.state.scale);
        }}
      >
        <TransformComponent>
          <img
            src={imageOutOfBound ? src : getImageWeServeUrl(src, {})}
            className="max-h-[60vh] min-w-[60vw] max-w-[60vw] min-h-[70vh] object-contain"
            alt="test"
            id="imgExample"
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

const FilePreviweModal = ({ visible, onClose, page }) => {
  const { filePreview } = useAppSelector((state) => state.conversations);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(
    filePreview.currentIndex || 0
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useAppDispatch();

  const { docs } = filePreview;

  const refImg = useRef(null);

  const [zoomIn, setZoomIn] = useState(1);
  const [zoomOut, setZoomOut] = useState(1);
  const [currentZoom, setCurrentZoom] = useState(1);

  useEffect(() => {
    if (refImg.current) {
      refImg.current.goTo(currentSlideIndex);
    }
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick, currentSlide } = props;

    setCurrentSlideIndex(currentSlide);
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715",
        }}
        onClick={onClick}
      >
        <ChevronIcon className="text-white transform rotate-[-90deg]" />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick, currentSlide } = props;
    setCurrentSlideIndex(currentSlide);
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715",
        }}
        onClick={onClick}
      >
        <ChevronIcon className="text-white transform rotate-90" />
      </div>
    );
  };

  const groupItems = (file) => [
    {
      key: "1",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">View details</div>
      ),
      icon: <EllipseIcon className="text-gray-700" />,
    },
    { key: "2", type: "divider" },
    {
      key: "3",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Open comments</div>
      ),
      icon: <CommentIcon className="text-gray-700" width="16" height="16" />,
    },
    {
      key: "4",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Share
        </div>
      ),

      icon: (
        <ShareAccessIcon
          className={`text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "5",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Move to
        </div>
      ),

      icon: (
        <MoveToIcon
          className={`text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "6",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">
          Send in message
        </div>
      ),
      icon: <ShareInMessageIcon className="text-gray-700" />,
    },
    {
      key: "7",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Rename
        </div>
      ),

      icon: (
        <EditIcon
          className={`text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "8",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Make a copy</div>
      ),
      icon: <CopyIcon className="text-gray-700" />,
    },
    {
      key: "9",
      label: <div className="out-300-14 leading-5 text-gray-700">Download</div>,
      icon: <DownloadIcon className="text-gray-700" />,
    },
    { key: "10", type: "divider" },
    {
      key: "11",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Delete
        </div>
      ),

      icon: (
        <DeleteIcon
          className={`text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
  ];

  const onClick = ({ key, file }) => {
    switch (key) {
      case "1":
        dispatch(
          updateVault({
            key: "viewDetailsDrawer",
            value: {
              visible: true,
              documentType: "file",
              document: {
                title: file.name,
                usersWithAccess: file.usersWithAccess,
                createdAt: file.createdAt,
                filesCount: file.filesCount,
                docId: file.id,
                createdById: file.createdById,
                fileSize: file.fileSize,
                mimeType: file.mimeType,
              },
            },
          })
        );
        break;
      case "3":
        dispatch(
          updateVault({
            key: "openCommentsModal",
            value: {
              visible: true,
              docType: "file",
              name: file.name,
              docId: file.id,
              comments: [],
              users: file.usersWithAccess,
            },
          })
        );
        break;
      case "4":
        if (file.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "file",
                  file,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "shareAccessModal",
            value: {
              visible: true,
              docId: file.id,
              users: file.usersWithAccess,
              docType: "file",
              docDetails: {
                ...file,
              },
            },
          })
        );
        break;
      case "5":
        if (file.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "file",
                  file,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "moveToFolderModal",
            value: {
              visible: true,
              docId: file.id,
              docType: "file",
            },
          })
        );
        break;
      case "6":
        dispatch(
          updateVault({
            key: "shareInMessageModal",
            value: {
              type: "file",
              visible: true,
              url: "",
              file: [
                {
                  name: file.name,
                  url: file.url,
                  size: file.fileSize,
                  type: file.mimeType,
                  lastModified: uniqid(`${file.name}-`),
                  savedInVault: true,
                },
              ],
            },
          })
        );
        break;
      case "7":
        if (file.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "file",
                  file,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "renameModal",
            value: {
              visible: true,
              type: "file",
              id: file.id,
              currentName: file.name,
            },
          })
        );
        break;
      case "8":
        dispatch(
          updateVault({
            key: "copyModal",
            value: {
              visible: true,
              docType: "file",
              id: file.id,
            },
          })
        );
        break;
      case "9":
        downloadFiles([file.url], file.fileSize);
        break;
      case "11":
        if (file.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "file",
                  file,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "deleteFileModal",
            value: {
              visible: true,
              id: file.id,
            },
          })
        );
        break;
      default:
        console.log("item others");
        break;
    }
    setDropdownOpen(false);
    onClose();
  };

  const getFile = (file) => {
    const type = file.mimeType || file.type;
    if (type?.includes("image")) {
      return (
        <ImagePan
          src={file.url}
          imageOutOfBound={file?.imageOutOfBound}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          onZoomChange={(num) => setCurrentZoom(num)}
        />
      );
    }

    if (type?.includes("video")) {
      return (
        <div className="pb-5 m-auto mt-2">
          <video
            muted
            // onCanPlayThrough={(e) => {
            //   const video = e.target;
            //   setTimeout(() => {
            //     video?.pause();
            //   }, 1000);
            // }}
            autoPlay={false}
            controls
            // poster="/images/thumbnails/video.svg"
            //poster={file.thumbnail}
            preload="metadata"
            className="h-full w-full"
          >
            <source src={`${file.url}#t=0.1`}></source>
          </video>
        </div>
      );
    }

    return (
      <div className="h-screen w-full flex justify-center items-center flex-col">
        <img src="/images/v2/common/frosty-no-file.svg" />
        <p className="out-500-12 text-white">No preview available</p>
        <div className="mt-5">
          <CustomButton
            text="Download File"
            icon={<DownloadIcon className="ml-2" />}
            className=""
            onClick={() =>
              downloadFiles([docs[currentSlideIndex].url], file.fileSize)
            }
          />
        </div>
      </div>
    );
  };

  const getPreview = () => {
    if (docs.length > 1) {
      return (
        <div className="max-h-[60vh] min-w-[60vw] max-w-[60vw] min-h-[70vh]">
          <Carousel
            dots={false}
            arrows
            autoplay={false}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
            ref={(ref) => {
              console.log(ref, "refImg?.current?.getCurrentIndex()");
              refImg.current = ref;
            }}
          >
            {docs.map((file, i) => (
              <React.Fragment key={i}>{getFile(file)}</React.Fragment>
            ))}
          </Carousel>
        </div>
      );
    } else {
      return <>{getFile(docs[0])}</>;
    }
  };

  const isLibrary = page === "library";
  return (
    <CustomModal
      visible={visible}
      onCancel={onClose}
      width="80vw"
      body={
        <div className="min-h-[90vh] bg-[#A1A1A1] flex justify-center items-center group overflow-hidden relative rounded-lg max-h-[80vh] min-w-[80vw] max-w-[80vw]">
          <div
            className="px-[24px] z-10 opacity-80 flex justify-between rounded-t-lg group-hover:top-0 absolute top-[-90px] transition-all duration-300 left-0 right-0 items-center py-4"
            style={{
              background: "rgba(27, 27, 27, 0.8)",
            }}
          >
            <div className="flex">
              <img src="/images/v2/conversations/image.svg" />
              <div className="ml-2">
                <p className="text-white out-500-14">
                  {docs[currentSlideIndex]?.title ||
                    docs[currentSlideIndex]?.name}
                </p>
                <p className="text-white out-500-12">
                  {filePreview.createdAt
                    ? dayjs(filePreview.createdAt).utc().local().fromNow()
                    : "-"}{" "}
                  • {formatBytes(docs[currentSlideIndex]?.size)}
                </p>
              </div>
            </div>
            <button onClick={onClose}>
              <CrossIcon className="text-white" />
            </button>
          </div>
          <div> {getPreview()}</div>
          <div
            className="px-[24px] opacity-80 flex justify-between rounded-b-lg group-hover:bottom-0 absolute bottom-[-90px] transition-all duration-300 left-0 right-0 items-center py-4"
            style={{
              background: "rgba(27, 27, 27, 0.8)",
            }}
          >
            <div className="flex items-center">
              <button onClick={() => setZoomIn(zoomIn + 0.2)}>
                <img
                  className="px-3"
                  src="/images/v2/conversations/zoom-in.svg"
                />
              </button>
              <div className="inline-block w-[1px] h-[32px] bg-white" />
              <button onClick={() => setZoomOut(zoomOut - 0.2)}>
                <img
                  className="px-3"
                  src="/images/v2/conversations/zoom-out.svg"
                />
              </button>
              <span className="out-500-12 text-white">
                {Math.round(currentZoom * 100)}%
              </span>
            </div>
            <div className="flex gap-x-4">
              {isLibrary && (
                <button
                  onClick={() =>
                    dispatch(
                      updateVault({
                        key: "shareAccessModal",
                        value: {
                          visible: true,
                          docId: docs[currentSlideIndex]?.id,
                          users: docs[currentSlideIndex]?.usersWithAccess,
                          docType: "file",
                          docDetails: {
                            ...docs[currentSlideIndex],
                          },
                        },
                      })
                    )
                  }
                >
                  <ShareAccessIcon className="text-white" />
                </button>
              )}
              <button
                onClick={() =>
                  downloadFiles(
                    [docs[currentSlideIndex].url],
                    docs[currentSlideIndex].size
                  )
                }
              >
                <DownloadIcon className="text-white" />
              </button>
              {isLibrary && (
                <button
                  onClick={() => {
                    dispatch(
                      updateVault({
                        key: "openCommentsModal",
                        value: {
                          visible: true,
                          docType: "file",
                          name: docs[currentSlideIndex].name,
                          docId: docs[currentSlideIndex].id,
                          comments: [],
                          users: docs[currentSlideIndex].usersWithAccess,
                        },
                      })
                    );
                    onClose();
                  }}
                >
                  <MessagesIcon className="text-white" />
                </button>
              )}
              {isLibrary && (
                <Dropdown
                  onOpenChange={(e) => setDropdownOpen(e)}
                  // getPopupContainer={(node) => node.parentElement.parentElement}

                  menu={{
                    items: groupItems(docs[currentSlideIndex]),
                    onClick: ({ key }) =>
                      onClick({ key, file: docs[currentSlideIndex] }),
                  }}
                  trigger={["click"]}
                  dropdownRender={(origin) => {
                    return (
                      <div className="w-[250px] bg-white rounded-md ">
                        {origin}
                      </div>
                    );
                  }}
                >
                  <button>
                    <DotsVerticalIcon className="text-white" />
                  </button>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default FilePreviweModal;
