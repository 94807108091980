import { Input } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { CircleIcon } from "../../Core/svgV2/Circle";
import { CrossIcon } from "../../Core/svgV2/CrossIcon";
import { EditTwoIcon } from "../../Core/svgV2/Edit02Icon";
import { OpenInWindowIcon } from "../../Core/svgV2/OpenInWindow";

import WhiteBoard from "./Modals/WhiteBoard";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import CustomColorPicker from "../../Core/CommonV2/CustomColorPicker";
import {
  createTodo,
  deleteTodo,
  getTodos,
  updateTodo,
} from "../../Core/redux/api/dashboardAPI";
import { CheckIcon } from "../../Core/svgV2/Check";
import { RefreshIcon } from "../../Core/svgV2/Refresh";
//  opacity-50, hover,focus,save opacity 0
const NotesCard = ({
  isWhiteBoardModal,
  className,
  todo,
}: {
  isWhiteBoardModal: boolean;
  className?: string;
  todo?: { id: string; status: 0 | 1; title: string; backgroundColor?: string };
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [whiteBoardModal, setWhiteBoardModal] = useState(false);
  const { todoList } = useAppSelector((state) => state.dashboard);

  const [backgroundColor, setBackgroundColor] = useState("#D1E9FF");
  const backgroundColorRef = useRef("");

  const { checkListScreen } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();

  const [todoInput, setTodoInput] = useState(null);

  const fetchFreshTodos = () => {
    dispatch(
      getTodos({
        userId: user.id,
      })
    );
  };
  useEffect(() => {
    dispatch(
      getTodos({
        userId: user.id,
      })
    );
  }, [backgroundColor]);

  const oncancel = () => {
    setWhiteBoardModal(false);
    setIsTyping(false);
  };

  // const onClearAll = () => {
  //   dispatch(
  //     deleteAllTodo({
  //       userId: user.id,
  //     })
  //   ).finally(() => {
  //     fetchFreshTodos();
  //   });
  //   if (todoInput?.trim()?.length === 0) {
  //     setTodoInput(null);
  //     return;
  //   }
  //   if (isWhiteBoardModal) {
  //     setIsTyping(true);
  //   }

  //   setTodoInput(null);
  // };

  const handleDeleteTodo = (id) => {
    dispatch(
      deleteTodo({
        userId: user.id,
        todoId: id,
      })
    ).finally(() => {
      fetchFreshTodos();
    });
  };

  const handleSaveTodo = async () => {
    if (todoInput?.trim()?.length === 0) {
      setTodoInput(null);
      return;
    }
    await dispatch(
      createTodo({
        userId: user.id,
        title: todoInput,
      })
    ).finally(() => {
      fetchFreshTodos();
    });

    if (isWhiteBoardModal) {
      setIsTyping(true);
    }

    setTodoInput(null);
  };
  return (
    <div
      className={`relative  w-[163px] h-[146px] justify-center  py-[39px]  flex flex-col items-center  `}
      id="dashboard-tour-notes"
    >
      <div
        className={` w-[163px] h-[146px] justify-center  py-[39px]  flex flex-col items-center group  z-10 ${className}`}
        style={{
          backgroundColor: checkListScreen
            ? "white"
            : backgroundColorRef.current
            ? backgroundColorRef.current
            : todo?.backgroundColor
            ? todo.backgroundColor
            : "#D1E9FF",
        }}
      >
        {!isWhiteBoardModal && (
          <div className="hidden absolute right-[2%] top-[7px] gap-x-1 group-hover:flex pr-1 z-30">
            {todoList.length ? (
              todoList[0].status ? (
                <button
                  onClick={async () => {
                    await dispatch(
                      updateTodo({
                        todoId: +todoList[0].id,
                        status: todoList[0].status === 1 ? 0 : 1,
                        title: todoList[0].title,
                        userId: user.id,
                      })
                    );
                    fetchFreshTodos();
                  }}
                >
                  <RefreshIcon className="text-gray-700" />
                </button>
              ) : (
                <button
                  onClick={async () => {
                    await dispatch(
                      updateTodo({
                        todoId: +todoList[0].id,
                        status: todoList[0].status === 1 ? 0 : 1,
                        title: todoList[0].title,
                        userId: user.id,
                      })
                    );
                    fetchFreshTodos();
                  }}
                >
                  <CheckIcon className="text-gray-700" />
                </button>
              )
            ) : (
              <></>
            )}
            <button onClick={() => setWhiteBoardModal(true)}>
              <OpenInWindowIcon className="text-gray-700  " />
            </button>
          </div>
        )}
        {isWhiteBoardModal && (
          <div className="hidden  absolute right-[2%] top-[7px] gap-x-2  group-hover:flex group-hover:items-center  ">
            {todo ? (
              <>
                <button
                  onClick={async () => {
                    await dispatch(
                      updateTodo({
                        todoId: +todo.id,
                        status: todo.status === 1 ? 0 : 1,
                        title: todo.title,
                        userId: user.id,
                      })
                    );
                    fetchFreshTodos();
                  }}
                >
                  {todo.status ? (
                    <RefreshIcon className="text-gray-700" />
                  ) : (
                    <CheckIcon className="text-gray-700" />
                  )}
                </button>
                <CustomColorPicker
                  placement="bottomLeft"
                  onColorChange={async (color) => {
                    backgroundColorRef.current = color;

                    setBackgroundColor(color);

                    dispatch(
                      updateTodo({
                        userId: user.id,
                        todoId: +todo.id,
                        status: +todo.status,
                        title: todo.title,
                        backgroundColor: color,
                      })
                    );
                  }}
                >
                  <button>
                    <CircleIcon
                      className=" relative "
                      style={{
                        color: todo?.backgroundColor
                          ? todo.backgroundColor
                          : backgroundColor,
                      }}
                    />
                  </button>
                </CustomColorPicker>
                <button
                  onClick={
                    todo
                      ? () => handleDeleteTodo(+todo.id)
                      : () => console.log("")
                  }
                >
                  <CrossIcon className="text-gray-700" />
                </button>{" "}
              </>
            ) : (
              ""
            )}
          </div>
        )}

        {isTyping ? (
          <div
            className="  w-[163px] h-[146px] justify-center  py-[39px]  flex flex-col items-center out-500-14 "
            style={{
              backgroundColor: checkListScreen ? "white" : backgroundColor,
              color: "black",
            }}
          >
            <button className="flex items-center justify-center">
              <Input.TextArea
                bordered={false}
                contentEditable={true}
                role={"textbox"}
                id={"todoInput"}
                value={todoInput}
                autoSize
                autoFocus
                onChange={(e) => setTodoInput(e.target.value)}
                onPressEnter={(e) => {
                  e.preventDefault();
                  if (todoInput.length == 0) {
                    return true;
                  } else if (e.key === "Enter" && (e.ctrlKey || e.shiftKey)) {
                    setTodoInput((val) => val + "\n");
                    return true;
                  }

                  handleSaveTodo();

                  if (!isWhiteBoardModal) {
                    setIsTyping(false);
                  }
                }}
                // onChange={(e)=>input=e.target}

                className="focus-visible:outline-0 bg-transparent text-center items-center max-w-[163px] out-500-14 text-black w-[163px] h-[60%]  "
              />
            </button>
          </div>
        ) : isWhiteBoardModal ? (
          <div
            className=" h-[68px] w-[116px] flex flex-col items-center justify-center"
            onClick={() => {
              if (!todo) {
                setIsTyping(true);
              }
            }}
          >
            <div
              className={`${
                todo?.status ? "line-through" : ""
              } text-center text-black out-500-14 whitespace-pre-wrap `}
            >
              {todo ? `${todo.title}` : "Write something"}
            </div>
          </div>
        ) : (
          <div
            className=" h-[68px] w-[116px] flex flex-col items-center "
            style={{ transition: "all 0.5s ease-in-out" }}
            onClick={() => setIsTyping(true)}
          >
            {todoList.length ? (
              <div
                className={`capitalize text-center out-500-14 text-black m-auto ${
                  todoList[0].status ? "line-through" : ""
                }   `}
              >
                {todoList[0].title}
              </div>
            ) : (
              <>
                <EditTwoIcon />
                <div className=" text-center out-500-14 text-black">
                  {checkListScreen
                    ? "Create your checklist"
                    : "Jot down your notes here"}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={`absolute top-[55%] bottom-[-6.11%] ${
          className ? "opacity-30" : ""
        }`}
      >
        <img src="/images/v2/dashboard/shadow.svg"></img>
      </div>
      {whiteBoardModal && (
        <WhiteBoard onCancel={oncancel} visible={whiteBoardModal} />
      )}
    </div>
  );
};

export default NotesCard;
