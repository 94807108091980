import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

import { useSearchParams } from "react-router-dom";
import { client } from "../../../Core/utils/axiosClient";
import { getTenantMembers } from "../../../Core/redux/api/tenantAPI";
import { getProjectDetatis } from "../../../Core/redux/api/projectAPI";

const RemoveMemberModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { removeMemberModal } = useAppSelector((state) => state.settings);

  const handleDelete = async () => {
    try {
      if (removeMemberModal.action === "removeTenantMember") {
        await client.delete(`/settings/delete-member/`, {
          params: { userId: removeMemberModal.user.userId },
        });
        dispatch(getTenantMembers({}));
      }

      if (
        removeMemberModal.action === "removeProjectMember" &&
        searchParams.has("projectId")
      ) {
        const projectId = searchParams.get("projectId");
        await client.delete(`/settings/delete-member/`, {
          params: { userId: removeMemberModal.user.userId, projectId },
        });
        dispatch(getProjectDetatis({ projectId }));
      }
    } catch (error) {
      console.log(error);
      //return error;
    } finally {
      onClose();
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Remove member</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to remove this <br />
            member? This action cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Remove"
              height="30px"
              className="bg-error-700 text-white"
              onClick={handleDelete}
            />
          </div>
        </div>
      }
    />
  );
};

export default RemoveMemberModal;
