import React, { useState } from "react";
import { ChevronDoubleIcon, ChevronIcon } from "../../../Core/svgV2/Chevron";
import {
  DashBoardIcon,
  LibraryIcon,
  MessagesIcon,
  ScheduleIcon,
} from "../../../Core/LayoutV2/LayoutIcons";

// const ListItem = () => {
//   return (
//     <div className="flex items-center pt-[22px]">
//       <div className="w-4 h-4 bg-gray-200 rounded-md"></div>
//       <div
//         className="bg-gray-200 rounded-2xl ml-[14px]"
//         style={{ width: "124px", height: "11px" }}
//       ></div>
//     </div>
//   );
// };
const MenuItemCollapse = ({
  title,
  icon: Icon,
  isSelected = false,
  collapsed,
  submenu = false,
  viewAll = "/library",
  onClick = null,
  id,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div id={id}>
      <div
        className={`flex group cursor-pointer rounded-lg  justify-between mt-[12px]   py-[5px] ${
          (isSelected || isActive) && "bg-primary-50"
        }  ${collapsed ? "px-[7px]" : "px-5"}`}
      >
        <div className="flex justify-center">
          <Icon className="group-hover:text-primary-600 text-[20px] text-black " />
          {!collapsed && (
            <span className="out-500-14 group-hover:text-primary-600 ml-[11px] text-black">
              {title}
            </span>
          )}
        </div>

        {!collapsed && submenu && (
          <ChevronIcon
            className="cursor-pointer group-hover:text-primary-600"
            style={{
              transform: "rotate(0deg)",
            }}
          />
        )}
      </div>
    </div>
  );
};

const SidebarSkeletonStructure = ({
  logo,
  projectName,
  internalProjectName,
  color,
}) => {
  return (
    <div className="h-full  bg-white  border-gray-200">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div
            className={`flex items-center justify-between ${"px-6"} pt-[18px]`}
          >
            <img className="" alt="logo" src={"/images/v2/layout/Logo.svg"} />

            <ChevronDoubleIcon
              className="block"
              rotate={90}
              style={{
                transition: "transform 0.3s ease-in-out 0s",
                transform: "rotate(0deg)",
              }}
            />
          </div>
          <div className="px-2">
            <MenuItemCollapse
              title={"Dashboard"}
              icon={DashBoardIcon}
              collapsed={false}
              isSelected={true}
              id="1"
            />
            <MenuItemCollapse
              title={"Messages"}
              icon={MessagesIcon}
              collapsed={false}
              id="2"
            />
            <MenuItemCollapse
              title={"Schedule"}
              icon={ScheduleIcon}
              collapsed={false}
              submenu={true}
              id="3"
            />

            <MenuItemCollapse
              title={"Library"}
              icon={LibraryIcon}
              collapsed={false}
              submenu={true}
              id="'4"
            />
          </div>
        </div>
        <div className="mb-2 " id="dashboard-tour-workspace">
          {/* <WorkSpaceSelect collapsed={collapsed} /> */}
        </div>
      </div>
    </div>
  );
};

export default SidebarSkeletonStructure;
