import React from "react";

import { useAppDispatch } from "../../../Core/redux/hooks";
import { resetConversations } from "../../../Core/redux/slices/conversations.slice";
import ParseMentions from "../ParseMentions";

const ReplyMessageFlow = ({ replyMessage }) => {
  const dispatch = useAppDispatch();
  return (
    <div className="bg-white border-b mx-1.5">
      <div className="bg-primary-50 mx-0.5 my-2 px-1.5 py-1.5 rounded-lg relative">
        <div className="flex items-center">
          {" "}
          <img src="/images/v2/conversations/message-reply-3.svg" />
          <span className="ml-2 out-300-14 text-black capitalize">
            {replyMessage.messageObject.senderName}
          </span>
        </div>

        <div className="ml-5 mt-1.5">
          <ParseMentions value={replyMessage.message} />
        </div>
        <div className="ml-7 mt-2">
          {/* {replyMessage.messageObject.attachments &&
            replyMessage.messageObject.attachments.length > 0 &&
            replyMessage.messageObject.attachments.map((att, i) => (
              <Attachments
                type={att.type}
                url={att.url}
                thumbnail={att.thumbnail}
                key={i}
                fileName={att.fileName}
              />
            ))} */}
        </div>
        <div
          className="absolute top-1 right-1 cursor-pointer"
          onClick={() => dispatch(resetConversations(["replyMessage"]))}
        >
          <img
            src="/images/icons/x-close.svg"
            className="min-h-[20px] min-w-[20px]"
          />
        </div>
      </div>
    </div>
  );
};

export default ReplyMessageFlow;
