import React from "react";

export const TrendUpIcon = ({ size = 18, className = "text-[#F26849]" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 17"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M1.5 12.167l4-4 2.667 2.666L13.5 5.5"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10.5 4.833h4v4"
        ></path>
      </svg>
    </span>
  );
};

export const TrendDownIcon = ({ size = 18, className = "text-[#4EAF76]" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 17"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M1.5 4.833l4 4 2.667-2.666L13.5 11.5"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10.5 12.167h4v-4"
        ></path>
      </svg>
    </span>
  );
};

export default TrendDownIcon;
