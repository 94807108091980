import dayjs from "dayjs";
import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useFormik } from "formik";
import { client } from "../../../Core/utils/axiosClient";
import * as Yup from "yup";
import {
  updateProjectDetails,
  getProjectDetatis,
} from "../../../Core/redux/api/projectAPI";
import { useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";
import CustomDatePicker from "../../../Core/CommonV2/CustomDatePicker";
import { setError } from "../../../Core/redux/slices/global.slice";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { getGoalCount } from "../../../Core/redux/api/scheduleAPI";

const CompleteProjectModal = () => {
  const { projectDetails } = useAppSelector((state) => state.overview);
  const { startDate, endDate } = projectDetails;
  const { projectId } = useParams();

  const getActiveSprint = async () => {
    try {
      // console.log("anushka 1", activeSprint);
      const { data } = await client.get("/schedule/activeSprint", {
        params: { projectId },
      });
      // if (data && data?.activeSprint) {
      //   setActiveSprint(data.activeSprint);
      // }
      return data?.activeSprint;
    } catch (e) {
      console.log(e, "spri");
    }
  };

  const handleUpdate = async (values) => {
    const {
      startDate,

      projectName: title,
      endDate,
      clientName,
      clientLocation,
      clientImage,
      projectImage,
    } = values;

    dispatch(
      updateProjectDetails({
        id: projectId,
        title,
        startDate,
        endDate,
        clientName,
        clientLocation,
        projectImage,
        clientImage,
        // projectImage: projectImage,
      })
    ).then(() => {
      dispatch(getProjectDetatis({ projectId }));
    });
  };
  const { control, handleSubmit, getValues } = useForm({
    values: {
      startDate: startDate ? dayjs(startDate) : null,
      endDate: endDate ? dayjs(endDate) : null,
    },
  });
  const formik = useFormik({
    initialValues: {
      startDate: startDate ? dayjs(startDate) : null,
      endDate: endDate ? dayjs(endDate) : null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      startDate: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
      endDate: Yup.string()
        .required("This field is required")
        .typeError("This field is required"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
      handleUpdate(values);
    },
  });
  const { completeProjectModal } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(
      updateDashboard({
        key: "completeProjectModal",
        value: {
          visible: false,
          edit: false,
          action: "end",
          projectId: projectId,
        },
      })
    );
  };
  const onSubmit = async (data) => {
    if (dayjs(endDate).isSame(data.endDate, "day")) {
      //for saving the changed dates
      const currentActiveSprint = await getActiveSprint();

      if (currentActiveSprint) {
        await dispatch(
          setError({
            message: "Please end the active sprint to complete the project",
          })
        );
        await dispatch(
          getGoalCount({ projectId, sprintId: currentActiveSprint.id })
        );
        dispatch(
          updateSchedule({
            key: "completeSprintModal",
            value: {
              visible: true,
              sprintId: currentActiveSprint.id,
              sprint: currentActiveSprint,
            },
          })
        );
      } else {
        dispatch(
          updateDashboard({
            key: "completeProjectModal",
            value: {
              visible: false,
              edit: false,
              action: "end",
              projectId: projectId,
            },
          })
        );
        await client.put("project/complete-project", { projectId }).then(() => {
          dispatch(getProjectDetatis({ projectId }));
          dispatch(
            updateDashboard({
              key: "completeProjectSuccessModal",
              value: true,
            })
          );
        });
      }
    } else {
      await dispatch(
        updateProjectDetails({
          id: projectId,
          endDate: data.endDate,
        })
      );
      dispatch(getProjectDetatis({ projectId }));

      dispatch(
        updateDashboard({
          key: "completeProjectModal",
          value: {
            visible: false,
            edit: false,
            action: "end",
            projectId: projectId,
          },
        })
      );
      //for completing the project =>isCompleted = 1
    }
  };

  return (
    <CustomModal
      visible={completeProjectModal.visible}
      width="352px"
      onCancel={() => console.log(status, "status")}
      body={
        <div className="h-[302px]  flex flex-col px-4  py-3 gap-y-4">
          <div className="flex   justify-between items-center ">
            <div className="h-5 out-500-14">Project Iceberg</div>
          </div>

          <div className="h-9 flex out-300-12 text-gray-500 ">
            You are about to complete this project . Make sure the dates are
            correct before proceeding.
          </div>

          <div className="h-[30px]  flex  items-center justify-end">
            <Controller
              control={control}
              name="startDate"
              rules={{
                required: "Please enter a start date before submitting",
              }}
              render={({ field, formState }) => (
                <CustomDatePicker
                  {...field}
                  placeholder="Select Date"
                  className="w-full h-[30px] bg-gray-50 border-gray-200 rounded-[4px]  shadow-xs"
                  suffixIcon={<></>}
                  disabled={
                    completeProjectModal.action === "end" ? true : false
                  }
                  format="MMMM DD YYYY"
                  disabledDate={(date) => {
                    const endDate = getValues("endDate");
                    return dayjs(date).isAfter(dayjs(endDate));
                  }}
                  formState={formState}
                />
              )}
            />
          </div>
          <div className="h-[30px]  flex  items-center justify-end">
            <Controller
              control={control}
              name="endDate"
              rules={{
                required: "Please enter an end date before submitting",
              }}
              render={({ field, formState }) => (
                <CustomDatePicker
                  {...field}
                  placeholder="Select date"
                  className="w-full h-[30px]  border-gray-200 rounded-[4px]  "
                  suffixIcon={<></>}
                  format="MMMM DD YYYY"
                  disabledDate={(date) => {
                    const startDate = getValues("startDate");
                    return dayjs(date).isBefore(dayjs(startDate));
                  }}
                  formState={formState}
                  onChange={(e) => {
                    dispatch(
                      updateDashboard({
                        key: "completeProjectModal",
                        value: {
                          visible: true,
                          edit: true,
                          projectId: projectId,
                        },
                      })
                    );
                    field.onChange(e);
                  }}
                />
              )}
            />
          </div>

          <div className="h-[52px] flex flex-col items-start justify-between gap-y-3  ">
            <div className="h-[20px] out-500-14 flex items-center  ">
              Duration{" "}
            </div>
            <div className="h-[20px] text-gray-900 out-300-14 flex items-center  ">
              {dayjs(formik.values.endDate).diff(
                formik.values.startDate,
                "day"
              )}{" "}
              days
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-3">
            <div
              className="h-[30px] out-500-14 text-gray-500 flex py-[5px] px-[10px]"
              onClick={handleClose}
            >
              Cancel
            </div>
            <button
              className="h-[30px] out-500-14 text-white bg-primary-600 shadow-[0px_1px_2px_rgba(16,24,40,0.05)] flex py-[5px] px-[10px] rounded-[4px] "
              onClick={handleSubmit(onSubmit)}
            >
              {completeProjectModal.edit ? "Save Changes" : "Complete Project"}
            </button>
          </div>
        </div>
      }
    />
  );
};

export default CompleteProjectModal;
