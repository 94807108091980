import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useParams } from "react-router";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const ReleaseErrorModal = () => {
  const { projectId } = useParams();
  const { releaseErrorModal } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(
      updateDashboard({
        key: "releaseErrorModal",
        value: { visible: false, type: releaseErrorModal.type },
      })
    );
  };

  return (
    <CustomModal
      visible={releaseErrorModal.visible}
      width="360px"
      onCancel={() => handleClose()}
      body={
        <div className="h-[279px] flex flex-col px-4 py-[15px] gap-y-[17px]">
          <div className="flex justify-between items-center">
            <div className="h-5 out-500-14 text-error-700">
              {"Ongoing phases"}
            </div>
            <button className="h-5 w-5" onClick={handleClose}>
              <CrossIcon />
            </button>
          </div>
          <div className="flex">
            <img
              src="/images/v2/dashboard/WarningState_DeletePopUps.svg"
              className="h-[108px]"
            />
          </div>
          <div className="h-10 flex">
            {
              "The project on QA is not released yet, so you cannot release it on Prod."
            }
          </div>
          <div className="h-[30px] flex items-center justify-end">
            <CustomButton
              text="OK"
              className="bg-transparent text-gray-500"
              height="30px"
              onClick={handleClose}
            />
          </div>
        </div>
      }
    />
  );
};

export default ReleaseErrorModal;
