import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import CustomButton from "../../../Core/CommonV2/CustomButton";
import { UrgencyFillIcon } from "../../../Core/svgV2/Urgency";
import { AlarmClockIcon } from "../../../Core/svgV2/AlarmClock";
import dayjs from "dayjs";
import { CalendarIcon } from "../../../Core/svgV2/CalendarIcon";
import Message from "../Message";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { closeRequest } from "../../../Core/redux/slices/conversations.slice";
import CloseRequestModal from "./CloseRequestModal";

const RequestDetailsModal = ({ visible, data, onClose, socket }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const getColor = (urgency) => {
    switch (urgency) {
      case "Critical":
        return "text-error-600";
      case "Major":
        return "text-warning-600";
      case "Medium":
        return "text-primary-600";
      case "Minor":
        return "text-gray-600";
      default:
        return "text-gray-600";
    }
  };
  console.log(data, "hivh");

  const [closeRequestModal, setCloseRequestModal] = useState(false);

  const handleCloseRequest = () => {
    socket.emit("closeMessageRequest", { messageId: data._id, message: data });
    onClose();
    dispatch(closeRequest({ message: data }));
  };

  const handleViewRequest = () => {
    onClose();
    const messageId = data._id;
    const selector = `[data-message-id="${messageId}"]`;
    const message = document.querySelector(selector);

    if (message) {
      message.scrollIntoView();
      message.classList.add("bg-gray-100");
      setTimeout(() => {
        message.classList.remove("bg-gray-100");
      }, 4000);
    }
  };
  return (
    <>
      <CustomModal
        visible={visible}
        width="511px"
        onCancel={() => onClose()}
        body={
          <div className="">
            <div className="flex justify-between items-center p-4 border-b">
              <div className="flex items-center">
                <img
                  src="/images/v2/conversations/AttachmentHeader.svg "
                  className="w-[26px] h-5"
                ></img>
                <p className="out-300-14 ml-1.5 text-black">
                  {data.request?.type}
                </p>
              </div>

              <div className="flex gap-x-5">
                <button className="flex gap-x-2 items-center justify-center">
                  <CalendarIcon className="text-gray-500" />

                  <span className="out-300-14 text-black">
                    {data.request?.deadlineDate
                      ? dayjs(data.request?.deadlineDate).format("MMMM DD YYYY")
                      : "-"}
                  </span>
                </button>
                <button className="flex gap-x-2 items-center justify-center">
                  <AlarmClockIcon
                    className="text-gray-500"
                    height="16px"
                    width="16px"
                  />
                  <span className="out-300-14 text-black">
                    {data.request?.deadlineTime
                      ? dayjs(data.request?.deadlineTime, "hh:mm a").format(
                          "hh:mm a"
                        )
                      : "-"}
                  </span>
                </button>
                <UrgencyFillIcon
                  className={getColor(data.request?.urgency)}
                  height={"16px"}
                  width="16px"
                />
              </div>
            </div>

            <div className="p-4 max-h-[270px] overflow-y-scroll">
              <Message allowHover={false} message={data} />
            </div>
            <div className="flex p-4  items-center gap-x-3 justify-end">
              <CustomButton
                text="View Request In Chat"
                className="bg-transparent text-gray-500"
                height="30px"
                width="152px"
                onClick={handleViewRequest}
              />
              {user.id == data.senderId && (
                <CustomButton
                  text="Close request"
                  height="30px"
                  width="105px"
                  onClick={() => {
                    // onClose();
                    setCloseRequestModal(true);
                  }}
                  // className="bg-error-700 text-white"
                />
              )}
            </div>
          </div>
        }
      />
      <CloseRequestModal
        visible={closeRequestModal}
        onClose={() => setCloseRequestModal(false)}
        handleCloseRequest={handleCloseRequest}
      />
    </>
  );
};

export default RequestDetailsModal;
