/**
 * Sort collisions from smallest to greatest value
 */
export function sortCollisionsAsc(a, b) {
  // { data: { value: a } },
  // { data: { value: b } }
  return a?.data?.value - b?.data?.value;
}

import { closestCorners } from "@dnd-kit/core";

function customCollisionDetectionAlgorithm(args) {
  // collosionRect is Drag Item
  const { collisionRect, droppableRects, droppableContainers } = args;

  const collisions = [];

  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer;
    const rect = droppableRects.get(id);
    // this is dropable container

    if (rect) {
      // Check if the draggable rectangle's top-left corner is inside the droppable rectangle
      if (collisionRect.left >= rect.left && collisionRect.left <= rect.right) {
        collisions.push({ id });
      }
    }
  }

  if (collisions.length === 1) {
    console.log(collisions);
    return collisions.sort(sortCollisionsAsc);
  }

  return closestCorners({ ...args, collisions });
}

export default customCollisionDetectionAlgorithm;
