import React from "react";

const colorMap = {
  0: { fill: "#AADAFF", stroke: "#165ABF" },
  1: { fill: "#A6F4C5", stroke: "#039855" },
  2: { fill: "#FFBCCE", stroke: "#F92B63" },
  3: { fill: "#D9D6FE", stroke: "#6938EF" },
  4: { fill: "#FCCEEE", stroke: "#DD2590" },
  5: { fill: "#FDDCAB", stroke: "#EC4A0A" },
  6: { fill: "#FFDB01", stroke: "#FFDB01" },
};

const CircleIcon = ({ colorNumber = 1 }) => {
  const { fill, stroke } = colorMap[colorNumber];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
      viewBox="0 0 14 14"
    >
      <circle cx="6" cy="6" r="5.5" fill={fill} stroke={stroke}></circle>
    </svg>
  );
};

const CircleIconPrimary = ({ colorNumber = 1 }) => {
  const { fill, stroke } = colorMap[colorNumber];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
    >
      <circle cx="10" cy="10" r="9.5" fill={fill} stroke={stroke}></circle>
    </svg>
  );
};

export { CircleIcon, CircleIconPrimary };
