/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

/**
 * @param  {String} text
 * @param  {String} width
 * @param   {String} height
 * @param  {Function} onClick
 * @param  {String} icon
 * */

type CustomButtonProps = {
  text?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  icon?: React.ReactNode;
  width?: string;
  height?: string;
  className?: string;
  type?: any;
  disabled?: boolean;
  iconAlignment?: string;
  iconPadding?: string;
  iconSize?: string;
  style?: React.CSSProperties;
};

export default function CustomButton({
  text = "button",
  onClick,
  icon,
  iconAlignment,
  width = "auto",
  height = "30px",
  className,
  type = "button",
  disabled = false,
}: CustomButtonProps) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${
        className || "bg-primary-600 text-white"
      }  out-500-14 rounded-[4px] ${disabled ? "opacity-50" : ""}`}
      style={{ width, height }}
      disabled={disabled}
    >
      <div
        className={`flex justify-center items-center ${
          iconAlignment === "right" ? "flex-row-reverse" : ""
        }`}
      >
        {icon && icon}
        <p
          className={`${
            icon && iconAlignment === "right" ? "mr-2.5" : "mx-2.5"
          }`}
        >
          {text}
        </p>
      </div>
    </button>
  );
}
