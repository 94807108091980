import { client } from "./axiosClient";

const getGoalsFromPhaseId = async ({ phaseId, projectId }) => {
  try {
    const response = await client.get(
      `schedule/ongoing-goals?phaseId=${phaseId}&projectId=${projectId}`
    );

    return response.data.result;
  } catch (error) {
    return [];
    //return error;
  }
};

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export { getGoalsFromPhaseId, formatBytes };
