import { Drawer } from "antd";
import React from "react";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CopyButton from "../../../Core/CommonV2/CopyButton";
import dayjs from "dayjs";
import useTimezone from "../../../Core/hooks/useTimezone";

const UserDetailsDrawer = ({ open = false, onClose, user }) => {
  const timezone = useTimezone(user.lat, user.long);
  return (
    <Drawer
      title={null}
      closable={false}
      placement="right"
      open={open}
      width={"318px"}
    >
      <div className="p-4 flex flex-col h-full relative">
        <div className="flex-1">
          <button onClick={onClose} className="absolute top-4">
            <CrossIcon className="text-gray-700" />
          </button>
          <div className="flex  w-full justify-center flex-col items-center">
            <CustomAvatar
              size={24}
              title={`${user.firstname} ${user.lastname}`}
              src={user.profilePicture}
            />
            <p className="out-500-14 mt-[6px]">
              {user.firstname} {user.lastname}
            </p>
          </div>

          <div className="mt-8">
            <p className="out-500-14 text-black">Details</p>
          </div>
          <div className="mt-4">
            <p className="out-300-12 text-gray-500">Email ID</p>
            <div className="mt-1.5 flex items-center justify-between">
              <p className="out-500-12 text-black ">{user.email || "N/A"}</p>
              <div>
                <CopyButton
                  className="out-500-14 text-primary-700"
                  height="20px"
                  text={user.email}
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <p className="out-300-12 text-gray-500">Mobile number</p>
            <p className="out-500-12 text-black mt-2">{user.mobile || "N/A"}</p>
          </div>
          <div className="mt-4">
            <p className="out-300-12 text-gray-500">Company</p>
            <p className="out-500-12 text-black mt-2">
              {user.company || "N/A"}
            </p>
          </div>
          <div className="mt-4">
            <p className="out-300-12 text-gray-500">Designation</p>
            <p className="out-500-12 text-black mt-2">
              {user.jobTitle || "N/A"}
            </p>
          </div>
          <div className="mt-4 ">
            <p className="out-300-12 text-gray-500">Currently located in</p>
            <div className="flex justify-between items-center mt-2 flex-wrap gap-y-1">
              <p className="out-500-12 text-black ">{user.location || "N/A"}</p>
              <div className="flex items-center">
                <img src="/images/v2/conversations/schedule.svg" />
                <p className="out-300-12 text-gray-500 ml-2">
                  {timezone.timeZoneId
                    ? dayjs().tz(timezone.timeZoneId).format("hh:mm a")
                    : "N/A"}{" "}
                  Local time
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default UserDetailsDrawer;
