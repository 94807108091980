export const getPhaseName = (id) => {
  switch (+id) {
    case 1:
      return "Ideation";
    case 2:
      return "Design";
    case 3:
      return "Development";
    case 4:
      return "Deployment";
    case 5:
      return "Maintenance";
    default:
      return "-";
  }
};

export const getPhaseColor = (id, mode = "dark") => {
  if (mode === "dark") {
    switch (+id) {
      case 1:
        return "#FFDB01";
      case 2:
        return "#F92B63";
      case 3:
        return "#6938EF";
      case 4:
        return "#33B7FF";
      case 5:
        return "#46C66C";
      default:
        return "#FFDB01";
    }
  } else {
    switch (+id) {
      case 1:
        return "#FFEF90";
      case 2:
        return "#FFA5BD";
      case 3:
        return "#BDB4FE";
      case 4:
        return "#A4DFFF";
      case 5:
        return "#A0FABA";
      default:
        return "#FFDB01";
    }
  }
};

export const getPhaseColorDark = (id, mode = "dark") => {
  if (mode === "dark") {
    switch (+id) {
      case 1:
        return "#FFDB01";
      case 2:
        return "#FFA5BD";
      case 3:
        return "#9B8AFB";
      case 4:
        return "#33B7FF";
      case 5:
        return "#46C66C";
      default:
        return "#FFDB01";
    }
  } else {
    switch (+id) {
      case 1:
        return "#FFEF90";
      case 2:
        return "#FFA5BD";
      case 3:
        return "#BDB4FE";
      case 4:
        return "#A4DFFF";
      case 5:
        return "#A0FABA";
      default:
        return "#FFDB01";
    }
  }
};
