import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import { useParams } from "react-router";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const ErrorMessageModal = ({ status }) => {
  const { projectId } = useParams();
  const { errorMessageModal, completePhaseModal } = useAppSelector(
    (state) => state.dashboard
  );
  const { edit, phase, action } = completePhaseModal;
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(
      updateDashboard({
        key: "errorMessageModal",
        value: { visible: false, type: errorMessageModal.type },
      })
    );
  };

  const handleProceed = () => {
    dispatch(
      updateDashboard({
        key: "errorMessageModal",
        value: { visible: false, type: errorMessageModal.type },
      })
    );
    if (errorMessageModal.type === "project") {
      dispatch(
        updateDashboard({
          key: "completeProjectModal",
          value: {
            visible: true,
            edit: false,
            action: "end",
            projectId: projectId,
          },
        })
      );
    } else {
      dispatch(
        updateDashboard({
          key: "completePhaseModal",
          value: {
            visible: true,
            edit: edit,
            action: action,
            phase: phase,
          },
        })
      );
    }
  };

  return (
    <CustomModal
      visible={errorMessageModal.visible}
      width="360px"
      onCancel={() => console.log(status, "status")}
      body={
        <div className="h-[279px]  flex flex-col px-4  py-[15px] gap-y-[17px]">
          <div className="flex   justify-between items-center ">
            <div className="h-5 out-500-14 text-error-700">
              {errorMessageModal.type === "project"
                ? "Ongoing phases"
                : "Unfinished tasks"}
            </div>
            <button className="h-5 w-5" onClick={handleClose}>
              <CrossIcon />
            </button>
          </div>
          <div className="flex   ">
            <img
              src="/images/v2/dashboard/WarningState_DeletePopUps.svg"
              className="h-[108px]"
            />
          </div>
          <div className="h-10 flex ">
            {errorMessageModal.type === "project"
              ? "Are you sure you want to complete this project? You still have phases that are ongoing."
              : "Are you sure you want to end this phase? You still have tasks that are in progress."}
          </div>
          <div className="h-[30px]  flex items-center justify-end">
            <CustomButton
              text="Discard"
              className="bg-transparent text-gray-500"
              height="30px"
              onClick={handleClose}
            />

            <CustomButton
              text="Proceed"
              className=" bg-error-700 text-white"
              height="30px"
              onClick={handleProceed}
            />
          </div>
        </div>
      }
    />
  );
};

export default ErrorMessageModal;
