import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  spin: PropTypes.bool,
};
const defaultProps = {
  color: "black",
  width: 14,
  height: 14,
  spin: false,
};

const Spinner = ({ color, width, height, spin }) => {
  return (
    <div className={spin ? "spin-loader" : ""}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1V5M11 17V21M5 11H1M21 11H17M18.0784 18.0784L15.25 15.25M18.0784 3.99994L15.25 6.82837M3.92157 18.0784L6.75 15.25M3.92157 3.99994L6.75 6.82837"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
