import { useQuery } from "react-query";
import { client } from "../../utils/axiosClient";

export const useGetTenantProjects = () => {
  return useQuery(
    "tenantProjects",
    async () => {
      const response = await client.get(`/time-estimate/get-all-projects`);
      return response.data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

export const useGetProjectMembers = (projectId) => {
  return useQuery(
    ["projectMembers", projectId],
    async () => {
      const response = await client.get(`/time-estimate/get-project-members`, {
        params: { projectId },
      });
      return response.data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

export const useGetProjectWeeks = (projectId) => {
  return useQuery(
    ["projectWeeks", projectId],
    async () => {
      const response = await client.get(`/time-estimate/get-weeks`, {
        params: { projectId },
      });
      return response.data.weeksWithAssigneesAndNames1;
    },
    {
      enabled: !!projectId,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};
