import { AutoComplete } from "antd";
import axios from "axios";
import React, { useState } from "react";

const handleGetTimezone = async ({ lat, lng }) => {
  console.log(lat, lng);
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  try {
    const result = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1331161200&key=${API_KEY}`
    );
    console.log(result.data);
    return result.data;
  } catch (e) {
    console.log("Error while getting timzone");
    return undefined;
  }
};

const CustomAddressInput = ({
  width,
  wrapperClassName,
  placeholder,
  value,
  onChange,
  className,
  rootStyle,
  onSelect,
  maxLength,
  name,

  getTimezone = false,
}: {
  width?: string;
  wrapperClassName?: string;
  placeholder?: React.ReactNode;

  value: string;
  onChange: (e) => void;
  className?: string;

  rootStyle?: any;
  onSelect: (obj) => void;
  maxLength?: number;
  name?: string;

  getTimezone?: boolean;
}) => {
  const [focused, setFocused] = useState(false);

  const showError = false;

  const [locationOptions, setLocationOptions] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const googleAddressSuggestions = (inputVal, callback) => {
    if (
      typeof window === "undefined" ||
      typeof window?.google !== "object" ||
      typeof callback !== "function"
    )
      return;

    const service = new window.google.maps.places.AutocompleteService();
    console.log(service, "check 1");

    // Country Restriction Codes : https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
    service?.getPlacePredictions(
      { input: inputVal, types: ["(cities)"] },
      (pred, status) => {
        console.log(pred, status, "check 2");

        if (
          status !== window.google.maps.places.PlacesServiceStatus.OK ||
          !pred
        ) {
          callback([]);
        } else {
          callback(pred);
        }
      }
    );

    // service?.getQueryPredictions({ input: inputVal }, (pred, status) => {
    //   if (status !== window.google.maps.places.PlacesServiceStatus.OK || !pred) {
    //     callback([]);
    //   } else {
    //     callback(pred);
    //   }
    // });
  };

  const googleAddressCoordinates = (placeId, callback) => {
    if (
      typeof window === "undefined" ||
      typeof window?.google !== "object" ||
      typeof callback !== "function"
    )
      return;

    const geocoder = new window.google.maps.Geocoder();

    geocoder
      .geocode({ placeId })
      .then((results) => {
        callback(results);
      })
      .catch(() => {
        callback([]);
      });
  };

  const handleInput = (value) => {
    // onSearch(value)
    if (!value) {
      setLocationOptions([]);
      return;
    }

    const callback = (pred) => {
      const options = pred.map((location) => {
        return {
          ...location,
          value: location.description,
          label: location.description,
        };
      });
      setLocationOptions(options);
    };
    console.log("check");
    googleAddressSuggestions(value, callback);
  };

  const handleSelect = (desc) => {
    const location = locationOptions.filter(
      (loc) => loc.description === desc
    )[0];

    const structured = location.structured_formatting;
    const locationName = structured.main_text;
    const locationSubName = structured.secondary_text;

    googleAddressCoordinates(location.place_id, async ({ results }) => {
      if (!results || results.length === 0) return;
      const location = results[0];
      const fullAddress = location.formatted_address;

      const getGps = location.geometry.location;
      const gps = {
        lat: getGps.lat(),
        lng: getGps.lng(),
      };
      let timezone = undefined;
      if (getTimezone) timezone = await handleGetTimezone(gps);
      console.log("googleAddressCoordinates: ", location, gps);

      onSelect({
        locationName,
        locationSubName,
        lat: gps.lat,
        long: gps.lng,
        fullAddress,
        timezone,
      });

      setFocused(false);
    });
  };

  // const onClear = (e) => {
  //   setDisabled(false);
  //   setLocationOptions([]);
  //   onSelect({
  //     locationName: "",
  //     lat: null,
  //     long: null,
  //   });
  // };

  return (
    <div
      className={`flex flex-col  items-start ${width} ${className}`}
      style={rootStyle}
    >
      <div
        className={`transition-shadow duration-300 border ${
          showError
            ? "border-error-600 shadow-input-error"
            : focused
            ? "border-primary-500 shadow-input-focus"
            : disabled
            ? "border-grey-200"
            : "border-grey-200"
        } bg-white rounded-[4px] h-full flex items-center w-full ${wrapperClassName}`}
      >
        {/* {prefix && (
          <div
            className={`h-full px-2.5 flex-center text-grey-200 out-400-13 border-r ${
              disabled ? "border-r-grey-100" : "border-r-grey-200"
            } bg-background rounded-l-lg`}
          >
            {prefix}
          </div>
        )} */}
        <div
          className={`border-0 ${
            disabled ? "bg-background" : "bg-white"
          } rounded-lg w-full flex-center`}
        >
          <AutoComplete
            options={locationOptions}
            bordered={false}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            onSearch={handleInput}
            onChange={onChange}
            onSelect={handleSelect}
            className="font-medium text-mid custom-address-input-ob "
            maxLength={maxLength}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>
      </div>
      {/* {showError && (
        <div className="mt-1.5 out-300-12 text-left text-error-500">
          {errorText}
        </div>
      )} */}
    </div>
  );
};

export default CustomAddressInput;
