import React, { useEffect, useState } from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { Checkbox } from "antd";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import useSearch from "../../../Core/hooks/useSearch";
import { client } from "../../../Core/utils/axiosClient";

import { updateSettings } from "../../../Core/redux/slices/settings.slice";

const UserRow = ({ user, checked, onCheck }) => {
  return (
    <div className="flex gap-x-3 py-2.5 px-3 items-center">
      <Checkbox checked={checked} onChange={() => onCheck(user.id)} />
      <CustomAvatar
        title={getUsersName(user)}
        src={user.profilePicture}
        size={24}
        fontSize={"12px"}
        whiteText
        color={user.profilePictureColorCode}
      />
      <div className="out-300-14 text-gray-900">{getUsersName(user)}</div>
    </div>
  );
};

const GuesttInteractionSettingsModal = ({ onClose }) => {
  const { tenantMembers, tenantDetails } = useAppSelector(
    (state) => state.tenant
  );
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();

  const { guestInteractionSettingsModal, newGuestInteractionSettings } =
    useAppSelector((state) => state.settings);
  const [selectedUser, setSelectedUser] = useState([]);
  // const [error, setError] = useState(false);
  let prevSelectedUsers = [];

  useEffect(() => {
    //editing the interaction settings
    if (
      window.location.pathname !== "/invite-members" &&
      window.location.pathname !== "/onboardingV2" &&
      window.location.pathname !== "/onboarding"
    ) {
      if (guestInteractionSettingsModal?.allowedUsers?.length) {
        setSelectedUser([
          ...selectedUser,
          ...guestInteractionSettingsModal.allowedUsers,
        ]);
      }
    } else {
      prevSelectedUsers = newGuestInteractionSettings.length
        ? newGuestInteractionSettings?.find(
            (user) => user.email === guestInteractionSettingsModal.email
          )?.selectedUsers
        : [];
      console.log(prevSelectedUsers, selectedUser, "prev 1");
      if (prevSelectedUsers?.length) {
        setSelectedUser([...selectedUser, ...prevSelectedUsers]);
      }
    }

    //creating new interaction settings
  }, [guestInteractionSettingsModal?.visible]);

  const handleCheckboxClick = (id) => {
    setSelectedUser((prevSelectedUsers) => {
      console.log(prevSelectedUsers, "prev");
      const exists = prevSelectedUsers.includes(id);

      return exists
        ? prevSelectedUsers.filter((permId) => permId !== id)
        : [...prevSelectedUsers, id];
    });
  };
  const newTenant = [];
  newTenant.push(user);

  const { search, setSearch, filteredData } = useSearch(
    window.location.pathname === "/onboarding" ||
      window.location.pathname === "/onboardingV2"
      ? newTenant
      : tenantMembers,
    ["firstname", "lastname", "email"]
  );

  const handleUserInteractionSettings = async () => {
    // if (selectedUser.length === 0) {
    //   setError(true);
    // } else {
    //   setError(false);

    try {
      if (guestInteractionSettingsModal?.userId) {
        const data = await client.post(
          "/settings/add-guest-interaction-allowed-members",
          {
            userId: guestInteractionSettingsModal?.userId,
            tenantId: tenantDetails.tenantId,
            allowedUsersIdArray: selectedUser,
          }
        );
      } else {
        const prevSelectedUsers = newGuestInteractionSettings.filter(
          (user) => user.email !== guestInteractionSettingsModal.email
        );
        dispatch(
          updateSettings({
            key: "newGuestInteractionSettings",
            value: [
              ...prevSelectedUsers,
              {
                email: guestInteractionSettingsModal.email,
                selectedUsers: selectedUser,
              },
            ],
          })
        );
      }

      // dispatch(getProjectDetatis({ projectId }));
    } catch (err) {
      console.log(err);
    } finally {
      setSelectedUser([]);
      onClose();
    }

    // const projectId = searchParams.get("projectId");
  };

  return (
    <CustomModal
      visible={guestInteractionSettingsModal.visible}
      width="667px"
      onCancel={() => {
        onClose();
      }}
      body={
        <div className="p-4 flex flex-col gap-y-4 z-30">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Select Members</p>

            <button
              onClick={() => {
                setSelectedUser([]);
                onClose();
              }}
            >
              <CrossIcon className="text-gray-700" />
            </button>
          </div>

          <CustomInputBox
            placeholder="Search by name or email"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className="text-gray-500  out-300-14">
            Select from the list of people in your workspace; this user can
            interact with.
          </div>

          <div className="max-h-[40vh] min-h-[40vh] overflow-y-scroll">
            {filteredData.map((user, i) => (
              <UserRow
                user={user}
                key={user.userId + i}
                checked={selectedUser.includes(user.userId || user.id)}
                onCheck={handleCheckboxClick}
              />
            ))}
          </div>

          <div className="flex justify-end gap-x-3">
            <CustomButton
              text="Add Later"
              height="30px"
              className="bg-white text-gray-500"
              onClick={() => {
                setSelectedUser([]);
                onClose();
              }}
            />
            <CustomButton
              text="Confirm Selection"
              height="30px"
              className="bg-white text-primary-700"
              onClick={handleUserInteractionSettings}
            />
          </div>
        </div>
      }
    />
  );
};

export default GuesttInteractionSettingsModal;
