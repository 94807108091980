import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

import {
  getAllFiles,
  getAllFolders,
  getAllLinks,
} from "../../../Core/redux/api/vaultAPI";
import { useParams } from "react-router-dom";
import { client } from "../../../Core/utils/axiosClient";
import FileFolderListAndSelect from "../../../Core/CommonV2/FileFolderListAndSelect";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

const MoveToModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();

  const { moveToFolderModal, folders, links, files } = useAppSelector(
    (state) => state.vault
  );
  const { projectId, "*": splat } = useParams();
  const [moveToFolderId, setMoveToFolderId] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");

  const { tenantDetails } = useAppSelector((state) => state.tenant);

  const DocumentsAfterMove = () => {
    let query: {
      projectId: string;
      folderId?: string;
      tenantId?: string;
    };

    if (projectId == "undefined" || projectId == null) {
      query = { projectId: null, tenantId: tenantDetails?.tenantId };
    } else {
      query = { projectId };
    }

    if (splat && splat.length > 0) {
      const folderId = splat.split("-");
      query.folderId = folderId[folderId.length - 1];
    }

    return query;
  };

  const onsubmit = async () => {
    const payload = {
      itemId: moveToFolderModal.docId,
      itemType: moveToFolderModal.docType,
      folderId: moveToFolderId,
      projectId: selectedProjectId,
    };

    const response = await client
      .put(`/repository/move-item-to-folder`, payload)
      .then(() => {
        onClose();
        const docName =
          moveToFolderModal.docType == "file"
            ? files.find((file) => moveToFolderModal.docId === file.id)?.name
            : links.find((link) => moveToFolderModal.docId === link.id)?.name;
        const moveToFolderName = folders.find(
          (folder) => folder.id === moveToFolderId
        )?.folderName;
        console.log("clicked", docName, moveToFolderId);
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: "Location updated",
                subtitle: `‘${docName}’  moved to   `,
                description: location.pathname.includes("my-library")
                  ? "My Library"
                  : moveToFolderName,
              },
            },
          })
        );
      });

    const queryObject = DocumentsAfterMove();

    if (moveToFolderModal.docType === "folder") {
      await dispatch(getAllFolders(queryObject));
    } else if (moveToFolderModal.docType === "file") {
      await dispatch(getAllFiles(queryObject));
    } else {
      await dispatch(getAllLinks(queryObject));
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => console.log("")}
      body={
        <div className="p-4">
          <p className="text-black out-500-14 pb-4">Move to</p>
          <FileFolderListAndSelect
            onFolderSelect={(selectedFolderId) =>
              setMoveToFolderId(selectedFolderId)
            }
            onProjectSelect={(selectedProjectId) =>
              setSelectedProjectId(selectedProjectId)
            }
          />
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={onClose}
            />
            <CustomButton
              text="Move Here"
              height="30px"
              width="88px"
              onClick={onsubmit}
              // disabled={!selectedProjectId && !moveToFolderId}
              // className="bg-error-700 text-white"
            />
          </div>
        </div>
      }
    />
  );
};

export default MoveToModal;
