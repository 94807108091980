import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";

import GoogleCalendarModal from "../Modals/Integrations/GoogleCalendarModal";
import BitbucketModal from "../Modals/Integrations/RepoServiceModal";
import { client } from "../../../Core/utils/axiosClient";
import { useAppSelector } from "../../../Core/redux/hooks";
import RepositoryServiceModal from "../Modals/Integrations/RepoServiceModal";

const Card = ({ icon, title, description, onClick }) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className="rounded-lg border border-gray-200 px-3 py-2.5 w-[202px]"
    >
      <img src={icon} />
      <div className="out-500-14 text-gray-700 mt-2">{title}</div>
      <div className="out-300-12 text-gray-500 mt-1.5">{description}</div>
    </div>
  );
};

const Integrations = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const [googleCalendarModal, setGoogleCalendarModal] = useState(false);
  const [repoServiceType, setRepoServiceType] = useState({
    type: "",
    visible: false,
  });
  const [isGoogleCalanderIntegrated, setIsGoogleCalenderIntegated] =
    useState(false);

  const [isBitbucketIntegrated, setIsBitbucketIntegarated] = useState(false);
  const [repoServices, setRepoServices] = useState([]);

  // const allApps = ["GOOGLE_CALANEDER", "BITBUCKET"];

  const checkIfgoogleCalenderIsIntegrated = async () => {
    try {
      const { data } = await client.get(
        "/integration/calender/refresh-token-status",
        {
          params: { userId: user.id },
        }
      );
      if (data.value) {
        setIsGoogleCalenderIntegated(true);
      }
    } catch (e) {
      setIsGoogleCalenderIntegated(false);
    }
  };

  // const checkIfBitbucketIsIntegrated = async () => {
  //   try {
  //     // const { data } = await client.get("/integration/bitbucket/status");
  //     const { data } = await client.get("/integration/reposervice/status");

  //     console.log(data);
  //     if (data.integartion.enabled) {
  //       setIsBitbucketIntegarated(true);
  //     }
  //   } catch (e) {
  //     setIsBitbucketIntegarated(false);
  //   }
  // };

  const getIntegratedRepositories = async () => {
    try {
      // const { data } = await client.get("/integration/bitbucket/status");
      const { data } = await client.get(
        "/integration/reposervice/get-all-reposervice-status"
      );

      if (data.integration) {
        setRepoServices([...data.integration]);
      }
    } catch (e) {
      setIsBitbucketIntegarated(false);
    }
  };
  useEffect(() => {
    checkIfgoogleCalenderIsIntegrated();
    // checkIfBitbucketIsIntegrated();
    getIntegratedRepositories();
  }, []);

  return (
    <div>
      <PageTitle
        title={"Integrations"}
        subtext={"Integrate with existing tools to make your workflow easier."}
      />

      <div className="out-500-14 text-black mt-6">My apps</div>
      <div className="flex flex-wrap w-[677px] gap-8 mt-6 ">
        {isGoogleCalanderIntegrated && (
          <Card
            icon={"/images/v2/settings/google-calendar.svg"}
            title="Google Calender"
            description="Sync your Google Calendar with Waddle"
            onClick={() => setGoogleCalendarModal(true)}
          />
        )}
        {repoServices.includes("Bitbucket") && (
          <Card
            icon={"/images/v2/settings/bitbucket.svg"}
            title="BitBucket"
            description="Integrate Waddle into your BitBucket commits & pull requests"
            onClick={() =>
              setRepoServiceType({ type: "Bitbucket", visible: true })
            }
          />
        )}
        {repoServices.includes("Github") && (
          <Card
            icon={"/images/v2/settings/github-filled.svg"}
            title="Github"
            description="Integrate Waddle into your Github commits & pull requests"
            onClick={() =>
              setRepoServiceType({ type: "Github", visible: true })
            }
          />
        )}
        {repoServices.includes("Gitlab") && (
          <Card
            icon={"/images/v2/settings/gitlab-filled.svg"}
            title="Gitlab"
            description="Integrate Waddle into your Gitlab commits & pull requests"
            onClick={() =>
              setRepoServiceType({ type: "Gitlab", visible: true })
            }
          />
        )}
      </div>

      <div className="out-500-14 text-black mt-6">Add apps</div>
      <div className="flex flex-wrap w-[677px] gap-8 mt-6 ">
        {!isGoogleCalanderIntegrated && (
          <Card
            icon={"/images/v2/settings/google-calendar.svg"}
            title="Google Calender"
            description="Sync your Google Calendar with Waddle"
            onClick={() => setGoogleCalendarModal(true)}
          />
        )}
        {!repoServices.includes("Bitbucket") && (
          <Card
            icon={"/images/v2/settings/bitbucket.svg"}
            title="BitBucket"
            description="Integrate Waddle into your BitBucket commits & pull requests"
            onClick={() =>
              setRepoServiceType({ type: "Bitbucket", visible: true })
            }
          />
        )}
        {!repoServices.includes("Github") && (
          <Card
            icon={"/images/v2/settings/github-filled.svg"}
            title="Github"
            description="Integrate Waddle into your Github commits & pull requests"
            onClick={() =>
              setRepoServiceType({ type: "Github", visible: true })
            }
          />
        )}
        {!repoServices.includes("Gitlab") && (
          <Card
            icon={"/images/v2/settings/gitlab-filled.svg"}
            title="Gitlab"
            description="Integrate Waddle into your Gitlab commits & pull requests"
            onClick={() =>
              setRepoServiceType({ type: "Gitlab", visible: true })
            }
          />
        )}
      </div>

      <GoogleCalendarModal
        visible={googleCalendarModal}
        onClose={() => setGoogleCalendarModal(false)}
      />

      <RepositoryServiceModal
        visible={repoServiceType?.visible}
        type={repoServiceType?.type}
        onClose={() => {
          setRepoServiceType({
            type: "",
            visible: false,
          });
          getIntegratedRepositories();
        }}
      />
    </div>
  );
};

export default Integrations;
