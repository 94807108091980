/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

import TrendIndicator from "./TrendIndicator";
import { DateFilterDropDown } from "./DateFilter";
import { InfoIcon } from "../../../Core/svgV3/InfoIcon";
import { DCFRoundFrostySVG } from "../../../Core/svgV3/DCFRoundFrosty";
import { Tooltip as AntdTooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  updateFootprint,
  updateFootprintData,
} from "../../../Core/redux/slices/footprint.slice";
import { date } from "yup";
import dayjs from "dayjs";
import { getActivityMonitorChartData } from "../../../Core/redux/api/myFootprintAPI";

const Options = [
  // {
  //   id: 1,
  //   value: 1,
  //   label: "All Time",
  // },
  {
    id: 1,
    value: 1,
    label: "Today",
    dateFilter: "today",
  },
  {
    id: 2,
    value: 2,
    label: "Yesterday",
    dateFilter: "yesterday",
  },
  {
    id: 3,
    value: 3,
    label: "Last week",
    dateFilter: [
      dayjs().subtract(1, "week").startOf("week").format("DD MMM YYYY"),
      dayjs()
        .subtract(1, "week")
        .endOf("week")
        .add(1, "day")
        .format("DD MMM YYYY"),
    ],

    trendComparisonFilter: "this_week",
  },

  {
    id: 4,
    value: 4,
    label: "Last month",
    dateFilter: "last_month",
    trendComparisonFilter: "this_month",
  },
  {
    id: 5,
    value: 5,
    label: "Last year",
    dateFilter: "last_year",
    trendComparisonFilter: "this_year",
  },
  // {
  //   id: 7,
  //   value: 7,
  //   label: "Custom",
  // },
];
const todayData = [
  { x: "12 am", y: 2.55 },
  { x: "3 am", y: 4.67 },
  { x: "6 am", y: 8.34 },
  { x: "9 am", y: 4.0 },
  { x: "12 pm", y: 2.78 },
  { x: "3 pm", y: 8.67 },
  { x: "6 pm", y: 16.12 },
  { x: "9 pm", y: 6.0 },
  { x: "12 am ", y: 6.0 },
];
const yesterdayData = [
  { x: "12 am", y: 3 },
  { x: "3 am", y: 7.67 },
  { x: "6 am", y: 2.34 },
  { x: "9 am", y: 5.0 },
  { x: "12 pm", y: 6.78 },
  { x: "3 pm", y: 9.67 },
  { x: "6 pm", y: 1.12 },
  { x: "9 pm", y: 16.46 },
  { x: "12 am ", y: 6.0 },
];
const lastWeekData = [
  { x: "13 Apr", y: 2.55 },
  { x: "14 Apr", y: 4.67 },
  { x: "15 Apr", y: 8.34 },
  { x: "16 Apr", y: 4.0 },
  { x: "17 Apr", y: 2.78 },
  { x: "18 Apr", y: 8.67 },
  { x: "19 Apr", y: 16.12 },
];
const lastMonthData = [
  { x: "1 Apr", y: 2.55 },
  { x: "10 Apr", y: 4.67 },
  { x: "20 Apr", y: 8.34 },
  { x: "30 Apr", y: 4.0 },
];
const lastYearData = [
  { x: "Mar", y: 0 },
  { x: "Apr", y: 0 },
  { x: "May", y: 0 },
  { x: "Jun", y: 0 },
  { x: "July", y: 0 },
  { x: "Aug", y: 0 },
  { x: "Sept", y: 400 },
  { x: "Oct", y: 0 },
  { x: "Nov", y: 0 },
  { x: "Dec", y: 0 },
  { x: "Jan", y: 0 },
  { x: "Feb", y: 0 },
  { x: "Mar", y: 0 },
];

// Convert incoming data to the desired format
// const formattedData = incomingData.data.map(item => ({
//   x: dayjs(item.createdAtMonth).format('MMM'),
//   y: item.dcf
// }));

// Generate last year data with 0 y values for missing months
const newlastYearData = [];
const formattedData = [];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const hours = [
  `${dayjs().format("DD MMM YYYY")} 12 am`,
  `${dayjs().format("DD MMM YYYY")} 1 am`,
  `${dayjs().format("DD MMM YYYY")} 2 am`,
  `${dayjs().format("DD MMM YYYY")} 3 am`,
  `${dayjs().format("DD MMM YYYY")} 4 am`,
  ` ${dayjs().format("DD MMM YYYY")} 5 am`,
  `${dayjs().format("DD MMM YYYY")} 6 am`,
  `${dayjs().format("DD MMM YYYY")} 7 am`,
  `${dayjs().format("DD MMM YYYY")} 8 am`,
  `${dayjs().format("DD MMM YYYY")} 9 am`,
  `${dayjs().format("DD MMM YYYY")} 10 am`,
  `${dayjs().format("DD MMM YYYY")} 11 am`,
  `${dayjs().format("DD MMM YYYY")} 12 pm`,
  `${dayjs().format("DD MMM YYYY")} 1 pm`,
  `${dayjs().format("DD MMM YYYY")} 2 pm`,
  `${dayjs().format("DD MMM YYYY")} 3 pm`,
  `${dayjs().format("DD MMM YYYY")} 4 pm`,
  `${dayjs().format("DD MMM YYYY")} 5 pm`,
  `${dayjs().format("DD MMM YYYY")} 6 pm`,
  `${dayjs().format("DD MMM YYYY")} 7 pm`,
  `${dayjs().format("DD MMM YYYY")} 8 pm`,
  `${dayjs().format("DD MMM YYYY")} 9 pm`,
  `${dayjs().format("DD MMM YYYY")} 10 pm`,
  `${dayjs().format("DD MMM YYYY")} 11 pm`,
];
const customHours = [
  `12 am`,
  `1 am`,
  `2 am`,
  `3 am`,
  `4 am`,
  `5 am`,
  `6 am`,
  `7 am`,
  `8 am`,
  `9 am`,
  `10 am`,
  `11 am`,
  `12 pm`,
  `1 pm`,
  `2 pm`,
  `3 pm`,
  `4 pm`,
  `5 pm`,
  `6 pm`,
  `7 pm`,
  `8 pm`,
  `9 pm`,
  `10 pm`,
  `11 pm`,
  `012 am`,
];
// Get the current date
const currentDate = dayjs();

// Create an array of hours from 0 to 23
const hoursArray = Array.from({ length: 24 }, (_, index) => index);

// Map each hour to a Day.js object representing that hour of the current day
const hoursOfDay = hoursArray.map((hour) => currentDate.hour(hour));

// Get the current hour
const currentHour = currentDate.hour();

// Create an array of hours from 0 to the current hour
const hoursPassed = Array.from(
  { length: currentHour + 1 },
  (_, index) => index
);

// Map each hour to a Day.js object representing that hour of the current day
const hoursPassedInCurrentDay = hoursPassed.map((hour) =>
  currentDate.hour(hour)
);

function generateDaysOfLastWeek() {
  const daysArray = [];
  const currentDate = dayjs().subtract(1, "week").startOf("week");

  for (let i = 0; i < 7; i++) {
    const formattedDate = currentDate.add(i, "day").format("DD MMM");
    daysArray.push(formattedDate);
  }

  return daysArray;
}

function generateDaysOfLastMonth() {
  const daysArray = [];
  const currentDate = dayjs().subtract(1, "month").startOf("month");

  const daysInMonth = currentDate.daysInMonth();
  for (let i = 0; i < daysInMonth; i++) {
    const formattedDate = currentDate.add(i, "day").format("DD MMM");
    daysArray.push(formattedDate);
  }

  return daysArray;
}
const getYearData = ({ data }) => {
  const lastYearData = [];

  months.forEach((month) => {
    const existingData = data.find(
      (data) => dayjs(data.createdAtMonth).add(1, "day").format("MMM") === month
    );
    if (existingData) {
      lastYearData.push({ x: month, y: existingData.dcf });
    } else {
      lastYearData.push({ x: month, y: 0 });
    }
  });
  return { data: lastYearData };
};
const getMonthData = ({ data }) => {
  const lastMonthData = [];

  const lastMonthDays = generateDaysOfLastMonth();

  lastMonthDays.forEach((day) => {
    const existingData = data.find(
      (data) => dayjs(data.createdAt).format("DD MMM") === day
    );
    if (existingData) {
      lastMonthData.push({ x: day, y: existingData.dcf });
    } else {
      lastMonthData.push({ x: day, y: 0 });
    }
  });
  return { data: lastMonthData };
};
const getWeekData = ({ data }) => {
  const lastWeekData = [];

  const lastWeekDays = generateDaysOfLastWeek();

  lastWeekDays.forEach((day) => {
    const existingData = data.find(
      (data) => dayjs(data.createdAt).format("DD MMM") === day
    );

    if (existingData) {
      lastWeekData.push({ x: day, y: existingData.dcf });
    } else {
      lastWeekData.push({ x: day, y: 0 });
    }
  });

  return { data: lastWeekData };
};

const getDayData = ({ data, selectedDate, dateFilter }) => {
  const presentDayData = [];

  hoursPassedInCurrentDay.forEach((hour) => {
    const existingData = data?.find(
      (data) =>
        dayjs(data.createdAtHour).utc(false).local().format("h a") ===
        dayjs(hour).subtract(30, "minutes").format("h a")
    );

    if (existingData) {
      presentDayData.push({
        x: dayjs(hour).format("hh a"),
        y: existingData.dcf,
        tooltip:
          selectedDate && dateFilter === 1
            ? dayjs(selectedDate).format("DD MMMM YYYY")
            : dateFilter === 1 && !selectedDate
            ? "Today"
            : dateFilter === 2
            ? dayjs().subtract(1, "day").format("DD MMMM YYYY")
            : null,
      });
    } else {
      if (dayjs().isAfter(dayjs(hour), "h")) {
        presentDayData.push({
          x: dayjs(hour).format("hh a"),
          y: 0,
          tooltip:
            selectedDate && dateFilter === 1
              ? dayjs(selectedDate).format("DD MMMM YYYY")
              : dateFilter === 1 && !selectedDate
              ? "Today"
              : dateFilter === 2
              ? "Yesterday"
              : null,
        });
      } else {
        presentDayData.push({
          x: dayjs(hour).format("hh a"),
          y: 0,
          tooltip:
            selectedDate && dateFilter === 1
              ? dayjs(selectedDate).format("DD MMMM YYYY")
              : dateFilter === 1 && !selectedDate
              ? "Today"
              : dateFilter === 2
              ? "Yesterday"
              : null,
        });
      }
    }
  });

  return { data: presentDayData };
};
const getCustomDayData = ({ data, selectedDate, dateFilter }) => {
  const dayData = [];

  customHours.forEach((hour) => {
    const existingData = data?.find(
      (data) =>
        dayjs(data.createdAtHour).utc(false).local().format("h a") === hour
    );

    if (existingData) {
      dayData.push({
        x: hour,
        y: existingData.dcf,
        tooltip:
          selectedDate && dateFilter === 1
            ? dayjs(selectedDate).format("DD MMMM YYYY")
            : dateFilter === 1 && !selectedDate
            ? "Today"
            : dateFilter === 2
            ? dayjs().subtract(1, "day").format("DD MMMM YYYY")
            : null,
      });
    } else {
      dayData.push({
        x: hour === `012 am` ? `12 am` : hour,
        y: 0,
        tooltip:
          selectedDate && dateFilter === 1
            ? dayjs(selectedDate).format("DD MMMM YYYY")
            : dateFilter === 1 && !selectedDate
            ? "Today"
            : dateFilter === 2
            ? "Yesterday"
            : null,
      });
    }
  });

  return { data: dayData };
};

function calculatePercentageChange(initialValue, finalValue) {
  // Percentage Change=
  // Today’s Value−Yesterday’s Value /
  // Yesterday’s Value
  //  ×100%
  //0.01 as final value when final is 0

  const difference = initialValue - finalValue;
  const percentageChange = (difference / finalValue) * 100;
  return percentageChange;
}

export const getData = (dateFilter) => {
  const weekStart = dayjs().subtract(1, "week").startOf("week");
  const yearStart = dayjs().subtract(1, "year").startOf("year");

  switch (dateFilter) {
    case 1:
      return {
        data: todayData,
        time: `Till ${dayjs(localStorage.getItem("lastFetchedDCFTime")).format(
          "hh:mm a"
        )}`,
        tooltip: "Today",
      };
    case 2:
      return {
        data: yesterdayData,
        time: `Yesterday, ${dayjs().subtract(1, "day").format("DD MMM")}`,
        tooltip: "Yesterday",
      };
    case 3:
      return {
        data: lastWeekData,

        time: `${weekStart.format("DD MMM")} - ${weekStart
          .add(6, "day")
          .format("DD MMM")}`,
      };
    case 4:
      return {
        data: lastMonthData,
        time: `${dayjs().subtract(1, "month").format("MMM YYYY")}`,
      };
    case 5:
      return {
        data: lastYearData,
        time: `${yearStart.format("MMM YYYY")} - ${yearStart.format(
          "MMM YYYY"
        )}`,
      };
    default:
      return { data: todayData, time: `Till ${dayjs().format("HH:mm a")}` };
  }
};

const CustomTick = (props, data) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x}, ${y})`}>
      <text x={0} y={0} dy={8} fill="#949494" fontSize={12} textAnchor="middle">
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, data, dateFilter }: any) => {
  if (active && payload && payload.length) {
    const { x, y, tooltip } = payload[0].payload;

    return (
      <div className="flex flex-col items-center gap-x-1 rounded-lg px-3 py-2 bg-gray-50 shadow-md">
        <p className="text-xs text-high-emphasis font-light">
          {tooltip ? tooltip : ""}
          {tooltip && ", "}
          {x}
        </p>
        <p className="text-xs font-semibold text-[#F26849] mt-1">
          {y > 1000000
            ? `${(y / 1000000).toFixed(2)} tonne CO2eq`
            : y > 1000
            ? `${(y / 1000).toFixed(2)} kgCO2eq`
            : `${y} gCO2eq`}
        </p>
      </div>
    );
  }

  return null;
};

const CustomActiveDot = (props) => {
  // const data = getData(view);
  const { cx, cy, index, data } = props;
  if (index == data.length - 1) {
    return null;
  }
  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={8}
        fill="#fff"
        strokeWidth={1}
        stroke="#EF4823"
      />
      <circle cx={cx} cy={cy} r={4} fill="#EF4823" />
    </g>
  );
};

const Proxy = ({ index, cx, cy, data }: any) => {
  if (index == data.length - 1) {
    return <DCFRoundFrostySVG cx={cx} cy={cy} />;
  }

  return null;
};

const getGraphActivityData = (dateFilter, activityGraphData, selectedDate) => {
  let data;
  switch (dateFilter) {
    case 5:
      data = getYearData({ data: activityGraphData?.graphData });

      break;
    case 4:
      data = getMonthData({ data: activityGraphData?.graphData });
      break;
    case 3:
      data = getWeekData({ data: activityGraphData?.graphData });
      break;

    default:
      // Handle default case
      if (
        (selectedDate && dayjs(selectedDate).isSame(dayjs(), "day")) ||
        (dateFilter == 1 && !selectedDate)
      ) {
        data = getDayData({
          data: activityGraphData?.graphData,
          selectedDate,
          dateFilter,
        });
      } else {
        data = getCustomDayData({
          data: activityGraphData?.graphData,
          selectedDate,
          dateFilter,
        });
      }
      break;
  }
  return data;
};

export const ActivityLineChart = ({ dateFilter }) => {
  const dispatch = useAppDispatch();
  const { activityGraphData, selectedDate, footprintMode } = useAppSelector(
    (state) => state.footPrintDetails
  );
  const [data, setData] = useState({ data: [] });
  // const [ticks, setTicks] = useState({  [] });
  const [currentHourData, setCurrentHourData] = useState(null);
  useEffect(() => {
    const data = getGraphActivityData(
      dateFilter,
      activityGraphData,
      selectedDate
    );
    setCurrentHourData(hoursPassedInCurrentDay);

    setData(data);
  }, [dateFilter, activityGraphData, selectedDate, footprintMode]);
  return (
    <ResponsiveContainer>
      <LineChart
        width={600}
        height={300}
        data={data.data}
        margin={{ right: 24, bottom: 24, left: 24 }}
      >
        <Line
          id="dcfGraph"
          className="relative"
          type="monotone"
          dataKey="y"
          stroke="#000000"
          dot={
            dateFilter === 1 &&
            (selectedDate === dayjs().format("DD MMM YYYY") ||
              !selectedDate) && <Proxy data={data.data} />
          }
          activeDot={<CustomActiveDot data={data.data} className="z-30" />}
          onMouseOver={(data) => console.log(data, "!@!@!@@")}
        />

        <CartesianGrid
          stroke="#DCDCDC"
          strokeDasharray="7 7"
          vertical={false}
        />

        {dateFilter === 4 ? (
          <XAxis
            dataKey="x"
            className="out-300-12 text-gray-500"
            fontSize={12}
            tick={<CustomTick data={data.data} />}
            axisLine={false}
            tickLine={false}
          />
        ) : dateFilter === 5 ? (
          <XAxis
            dataKey="x"
            className="out-300-12 text-gray-500"
            fontSize={12}
            tick={<CustomTick data={data.data} />}
            axisLine={false}
            tickLine={false}
          />
        ) : (
          <XAxis
            dataKey="x"
            className="out-300-12 text-gray-500"
            fontSize={12}
            tick={<CustomTick data={data.data} />}
            axisLine={false}
            tickLine={false}
          />
        )}

        <Tooltip
          content={<CustomTooltip data={data} dateFilter={dateFilter} />}
          cursor={{ stroke: "#B4B4B4", strokeWidth: 1, strokeDasharray: "5 5" }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const Activity = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);

  const [dateFilter, setDateFilter] = useState(1);
  const [activityData, setActivityData] = useState({ totalDcf: 0, data: [] });
  const {
    activityGraphData,
    footprintMode,
    selectedDate,
    todayGCO2value,
    previousDayGCO2value,
    view,
  } = useAppSelector((state) => state.footPrintDetails);

  const dispatch = useAppDispatch();
  const [sumData, setSumData] = useState(0);
  const [viewName, setViewName] = useState("Today");
  const [trendDetails, setTrendDetails] = useState({
    trend: "up",
    percent: 0,
    isNoTrend: false,
  });
  const [isNoTrend, setIsNoTrend] = useState(false);

  const data = getData(dateFilter);
  useEffect(() => {
    setDateFilter(+view);
  }, [view]);
  useEffect(() => {
    const totalY = data.data.reduce(
      (accumulator, currentValue) => accumulator + currentValue.y,
      0
    );
    setSumData(+totalY.toFixed(2));

    let percentageChange = 0;

    if (dateFilter === 1 && !selectedDate) {
      //than yesterday

      // Percentage Change=
      // Today’s Value−Yesterday’s Value /
      // Yesterday’s Value
      //  ×100%
      if (previousDayGCO2value === 0) {
        setIsNoTrend(true);
      }
      percentageChange = calculatePercentageChange(
        todayGCO2value,
        previousDayGCO2value
      );
    } else if (dateFilter == 2 && !selectedDate) {
      if (todayGCO2value === 0) {
        setIsNoTrend(true);
      }
      //than today
      percentageChange = calculatePercentageChange(
        previousDayGCO2value,
        todayGCO2value
      );
    } else if (selectedDate) {
      if (dayjs(selectedDate).isSame(dayjs(), "day")) {
        if (previousDayGCO2value === 0) {
          setIsNoTrend(true);
        }
        percentageChange = calculatePercentageChange(
          activityGraphData?.totalDcf,
          previousDayGCO2value
        );
      } else {
        if (todayGCO2value === 0) {
          setIsNoTrend(true);
        }
        //than today
        percentageChange = calculatePercentageChange(
          activityGraphData?.totalDcf,
          todayGCO2value
        );
      }
    } else {
      if (activityGraphData?.totalTrendDcf === 0) {
        setIsNoTrend(true);
      }
      //than this datefilter
      percentageChange = calculatePercentageChange(
        activityGraphData?.totalDcf,
        activityGraphData?.totalTrendDcf
      );
    }

    setTrendDetails({
      trend: percentageChange > 0 ? "up" : "down",
      percent: percentageChange,
      isNoTrend: isNoTrend,
    });
  }, [dateFilter, activityGraphData]);

  useEffect(() => {
    let payload;
    if (footprintMode === 1) {
      payload = {
        userId: user.id,
        tenantId: tenantDetails.tenantId,
      };
    } else {
      payload = {
        tenantId: tenantDetails.tenantId,
      };
    }
    dispatch(
      getActivityMonitorChartData({
        dateFilter: selectedDate
          ? "custom_date"
          : Options.find((option) => option.id === dateFilter)?.dateFilter,
        ...payload,
        customDate: selectedDate ? dayjs(selectedDate).add(1, "day") : "",
        trendComparisonFilter: selectedDate
          ? "custom_date"
          : Options.find((option) => option.id === dateFilter)
              ?.trendComparisonFilter,
        thisWeekRange: [
          dayjs().startOf("week").format("DD MMM YYYY"),
          dayjs().endOf("week").add(1, "day").format("DD MMM YYYY"),
        ],
      })
    );
  }, [dateFilter, footprintMode, selectedDate, tenantDetails]);

  return (
    <div className="bg-white rounded-2xl p-4 h-full" id="activity-line-chart">
      <div className="flex h-[30px] justify-between w-full ">
        <div className="flex gap-x-1.5 items-center w-full ">
          <p className="out-500-16 text-gray-900 ">
            {+user.roleId < 3 ? (
              footprintMode === 1 ? (
                "My Digital Carbon Footprint "
              ) : (
                <p>
                  <span className="capitalize">
                    {tenantDetails?.tenantName}
                  </span>
                  &apos;s Digital Carbon Footprint
                </p>
              )
            ) : (
              "My Digital Carbon Footprint "
            )}
          </p>
          <AntdTooltip
            placement="right"
            title={
              +user.roleId < 3
                ? footprintMode === 1
                  ? "Total amount of carbon emissions generated by the consumption of electricity necessary to run Waddle on your device."
                  : "Total amount of carbon emissions generated by the consumption of electricity necessary to run Waddle on  the devices of all the members of this workspace."
                : "Total amount of carbon emissions generated by the consumption of electricity necessary to run Waddle on your device."
            }
          >
            <InfoIcon />
          </AntdTooltip>
        </div>
        <DateFilterDropDown
          options={Options}
          value={dateFilter}
          onSelect={(key, val) => {
            setDateFilter(val);
            dispatch(updateFootprint({ key: "view", value: val }));
            dispatch(updateFootprint({ key: "selectedDate", value: "" }));
          }}
        />
      </div>
      <div className="flex mt-2  gap-x-3 ">
        <img alt="icons" src={"/images/v3/footprint/DCF.svg"} />
        <div className="flex gap-x-4">
          <div className="flex flex-col">
            {activityGraphData?.totalDcf > 1000 ? (
              <p className="out-600-20 text-black">
                {activityGraphData?.totalDcf
                  ? (activityGraphData.totalDcf / 1000).toFixed(2)
                  : 0}
                <span className="out-500-14 text-gray-600 ml-[7px]">
                  kgCO<sub>2</sub>e
                </span>
              </p>
            ) : (
              <p className="out-600-20 text-black">
                {activityGraphData?.totalDcf || 0}
                <span className="out-500-14 text-gray-600 ml-[7px]">
                  gCO<sub>2</sub>e
                </span>
              </p>
            )}
            <p className="out-300-14 text-gray-500 ">
              {+view === 1 && selectedDate
                ? dayjs(selectedDate).format("D MMMM YYYY")
                : data.time
                ? data.time
                : "Till 09:15 pm"}
            </p>
          </div>
          <div className="mt-1  ">
            <TrendIndicator
              trend={trendDetails.trend}
              percent={
                trendDetails?.percent ? Math.round(trendDetails?.percent) : 0
              }
              dateFilter={dateFilter}
              isNoTrend={trendDetails?.isNoTrend}
              trendDetails={trendDetails}
            />
          </div>
        </div>
      </div>

      <div className="h-[calc(100vh-490px)] mt-10">
        <ActivityLineChart dateFilter={dateFilter} />
      </div>
    </div>
  );
};

export default Activity;
