import React from "react";
import Icon from "@ant-design/icons";

const DotsVerticalSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      d="M10 10.832a.833.833 0 100-1.667.833.833 0 000 1.667zM10 4.999a.833.833 0 100-1.667.833.833 0 000 1.667zM10 16.665A.833.833 0 1010 15a.833.833 0 000 1.666z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10 10.832a.833.833 0 100-1.667.833.833 0 000 1.667zM10 4.999a.833.833 0 100-1.667.833.833 0 000 1.667zM10 16.665A.833.833 0 1010 15a.833.833 0 000 1.666z"
    ></path>
  </svg>
);

const DotsVerticalIcon = (props) => {
  return <Icon component={DotsVerticalSvg} {...props} />;
};

export { DotsVerticalIcon };
