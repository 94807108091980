import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { ChevronIcon } from "../../Core/svgV2/Chevron";

import CustomButton from "../../Core/CommonV2/CustomButton";
import { PlusIcon } from "../../Core/svgV2/PlusIcon";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { client } from "../../Core/utils/axiosClient";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { Spin } from "antd";
import Timeline from "../../ModulesV3/Calendar/MyCalendarTimelines";

const Events = () => {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const SCOPE = "openid email profile https://www.googleapis.com/auth/calendar";

  const { user } = useAppSelector((state) => state.userDetails);
  const [loading, setLoading] = useState(false);
  const [showCalendarEvents, setShowCalendarEvents] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs());

  const responseGoogle = async (response) => {
    console.log(response, "This is the consolelog");
    try {
      const { data } = await client.post(
        "/integration/calender/create-tokens",
        {
          code: response.code,
        }
      );
      await checkIfgoogleCalenderIsIntegrated();
    } catch (e) {
      console.log(e);
    }
  };

  const responseError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    function start() {
      gapi.client?.init({
        clientId: CLIENT_ID,
        scope: SCOPE,
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const getEvents = async () => {
    try {
      const timeZoneOffsetMinutes = dayjs().utcOffset();

      const { data: events } = await client.get(
        "/integration/calender/get-events",
        {
          params: {
            userId: user.id,
            date: currentDate.format("YYYY-MM-DD"),
            timeMin: dayjs(currentDate)
              .startOf("day")
              .utcOffset(timeZoneOffsetMinutes)
              .toISOString(),
            timeMax: dayjs(currentDate)
              .endOf("day")
              .utcOffset(timeZoneOffsetMinutes)
              .toISOString(),
          },
        }
      );

      console.log(events, "these are the events ");

      dispatch(
        updateDashboard({
          key: "events",
          value: events?.events || [],
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const checkIfgoogleCalenderIsIntegrated = async () => {
    setLoading(true);
    try {
      const { data } = await client.get(
        "/integration/calender/refresh-token-status",
        {
          params: { userId: user.id },
        }
      );
      if (data.value) {
        setShowCalendarEvents(true);
      }
    } catch (e) {
      setShowCalendarEvents(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvents();
  }, [currentDate]);

  useEffect(() => {
    checkIfgoogleCalenderIsIntegrated();
  }, []);

  const dispatch = useAppDispatch();

  if (loading) {
    return (
      <div className="pt-60 w-full flex items-center justify-center">
        <Spin spinning={loading} />
      </div>
    );
  }

  if (!showCalendarEvents) {
    return (
      <div className="mt-6 mx-2 px-2 pt-2 pb-4 bg-primary-50 rounded-lg">
        <div className="flex justify-between items-center ">
          <div className="flex  items-center">
            <img src="/images/icons/Googlecalendar.svg" className="h-5 w-5" />
            <p className="outl-500-14 ml-2">Google Calendar</p>
          </div>
          {/* <img src="/images/icons/Question.svg" className="h-5 w-5" /> */}
        </div>
        <p className="text-xs text-gray-500 font-normal pt-3">
          Bring all your meetings here without leaving Waddle!
        </p>
        <GoogleLogin
          clientId={CLIENT_ID}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="flex justify-center   items-center mt-2.5 w-full shadow-button rounded border border-gray-200 bg-white"
            >
              <img src="/images/icons/Googlecalendar.svg" className="h-5 w-5" />
              <p className="text-sm font-medium text-gray-700 ml-2.5  py-2.5">
                Integrate Google Calendar
              </p>
            </button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseError}
          cookiePolicy={"single_host_origin"}
          responseType="code"
          accessType="offline"
          scope={SCOPE}
          prompt="consent"
          uxMode="redirect"
        />
      </div>
    );
  }
  const dummyClick = (newDate) => {
    console.log(newDate);
  };

  return (
    <div className="overflow-hidden relative">
      <div className="flex p-6 pb-0 items-center justify-between w-full">
        <div className="flex gap-x-2 items-center">
          <button onClick={() => setCurrentDate(currentDate.add(-1, "days"))}>
            <ChevronIcon className="rotate-[90deg] text-gray-500" />
          </button>
          <div className="out-500-14 text-gray-900 py-2 bg-primary-100 whitespace-nowrap px-[14px] rounded">
            {currentDate.format("dddd, MMM DD")}
          </div>
          <button onClick={() => setCurrentDate(currentDate.add(1, "days"))}>
            <ChevronIcon className="rotate-[-90deg] text-gray-500" />
          </button>
        </div>
        <CustomButton
          text="Today"
          className="out-500-14 text-gray-500 bg-gray-50"
          onClick={() => setCurrentDate(dayjs())}
        />
      </div>
      <div className="max-h-[calc(100vh-230px)] overflow-y-scroll">
        <Timeline currentDate={currentDate} onClick={dummyClick} view={0} />
      </div>
    </div>
  );
};

export default Events;
