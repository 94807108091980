import React, { useState, useEffect, useRef } from "react";
import { useDrop, useDrag } from "react-dnd";

const DragSource = ({
  id,
  type,
  children,
  onDrag,
  disabled,
  className = "",
}) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: { id },
    // disabled: true,
    type: "card",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;

  if (!disabled) drag(ref);

  useEffect(() => {
    if (isDragging) onDrag(type, id);
  }, [isDragging]);

  return (
    <div ref={ref} style={{ opacity }} className={className}>
      {children}
    </div>
  );
};

export default DragSource;
