import React from "react";

const PhaseIcon = ({
  phaseId,
  width,
  height,
  icon = false,
}: {
  phaseId: number | number;
  width: string;
  height: string;
  icon?: boolean;
}) => {
  switch (phaseId) {
    // ideation
    case 1:
      if (icon) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <rect width="16" height="16" fill="#FFDB01" rx="4"></rect>
            <path
              fill="#F9FBFE"
              d="M8.892 10.515a2.668 2.668 0 10-1.78-5.03 2.668 2.668 0 001.78 5.03z"
            ></path>
            <path
              stroke="#F9FBFE"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M7.114 10.515v1.04a.889.889 0 001.778 0v-1.04m-1.778 0A2.668 2.668 0 015.336 8m1.778 2.515c.278.098.577.152.889.152m.889-.152A2.668 2.668 0 0010.67 8m-1.778 2.515a2.662 2.662 0 01-.889.152M10.67 8a2.667 2.667 0 01-2.667 2.667M10.67 8a2.667 2.667 0 10-5.334 0m2.667 2.667A2.667 2.667 0 015.336 8m2.667-4.444V4m-4 4h-.444m1.555-2.889l-.267-.266m6.045.266l.267-.266M12.447 8h-.444"
            ></path>
          </svg>
        );
      }
      return (
        <div
          style={{
            height,
            width,
            borderRadius: "3px",
            backgroundColor: "#FFDB01",
          }}
        />
      );
    // desing
    case 2:
      if (icon) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
          >
            <rect width="24" height="24" fill="#F92B63" rx="4"></rect>
            <path
              fill="#F9FBFE"
              d="M5.333 18.666c1.334 0 3.072.228 3.983-.683a2.333 2.333 0 10-3.3-3.3c-.91.911 0 2.65-.683 3.983z"
            ></path>
            <path
              fill="#F9FBFE"
              d="M9.333 14.666c-.327-.327-.491-.491-.584-.662a1.333 1.333 0 01.025-1.312c.1-.168.27-.325.61-.64l6.578-6.091a1.47 1.47 0 012.077 2.076l-6.091 6.58c-.315.34-.473.51-.64.608-.403.238-.9.248-1.312.026-.171-.093-.335-.257-.663-.585z"
            ></path>
          </svg>
        );
      }
      return (
        <div
          style={{
            height,
            width,
            borderRadius: "3px",
            backgroundColor: "#F92B63",
          }}
        />
      );
    //development
    case 3:
      if (icon) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
          >
            <rect width="24" height="24" fill="#6938EF" rx="4"></rect>
            <path
              fill="#F9FBFE"
              stroke="#F9FBFE"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M18.667 12a6.667 6.667 0 11-13.334 0 6.667 6.667 0 0113.334 0z"
            ></path>
            <path
              stroke="#6938EF"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.667 14l2-2-2-2m-3.334 0l-2 2 2 2"
            ></path>
          </svg>
        );
      }
      return (
        <div
          style={{
            height,
            width,
            borderRadius: "3px",
            backgroundColor: "#6938EF",
          }}
        />
      );
    //deployment
    case 4:
      if (icon) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <rect width="16" height="16" fill="#33B7FF" rx="4"></rect>
            <path
              fill="#F9FBFE"
              d="M10.67 3.556H5.336c-.414 0-.62 0-.784.067a.889.889 0 00-.481.481c-.068.164-.068.37-.068.785 0 .414 0 .621.068.785.09.217.263.39.48.48.164.068.371.068.785.068h5.334c.414 0 .621 0 .784-.067a.889.889 0 00.481-.481c.068-.164.068-.37.068-.785 0-.414 0-.621-.068-.785a.889.889 0 00-.48-.48c-.164-.068-.371-.068-.785-.068zM4.447 12.445a.889.889 0 100-1.778.889.889 0 000 1.778zM8.003 12.445a.889.889 0 100-1.778.889.889 0 000 1.778zM11.559 12.445a.889.889 0 100-1.778.889.889 0 000 1.778z"
            ></path>
            <path
              stroke="#F9FBFE"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M4.447 10.667v-.09c0-.746 0-1.12.146-1.404.128-.251.332-.455.583-.583.285-.145.658-.145 1.405-.145h2.844c.747 0 1.12 0 1.406.145.25.128.454.332.582.583.146.285.146.658.146 1.405v.089m-7.112 0a.889.889 0 100 1.777.889.889 0 000-1.777zm7.112 0a.889.889 0 100 1.778.889.889 0 000-1.778zm-3.556 0a.889.889 0 100 1.777.889.889 0 000-1.777zm0 0V6.222m-2.667 0h5.334m-5.334 0c-.414 0-.62 0-.784-.067a.889.889 0 01-.481-.481c-.068-.164-.068-.37-.068-.785 0-.414 0-.621.068-.785a.889.889 0 01.48-.48c.164-.068.371-.068.785-.068h5.334c.414 0 .621 0 .784.067.218.09.391.264.481.481.068.164.068.37.068.785 0 .414 0 .621-.068.785a.889.889 0 01-.48.48c-.164.068-.371.068-.785.068m-5.334 0h2.667m2.667 0H8.003"
            ></path>
          </svg>
        );
      }
      return (
        <div
          style={{
            height,
            width,
            borderRadius: "3px",
            backgroundColor: "#33B7FF",
          }}
        />
      );
    // maintanence
    case 5:
      if (icon) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
          >
            <rect width="24" height="24" fill="#6938EF" rx="4"></rect>
            <path
              fill="#F9FBFE"
              stroke="#F9FBFE"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.5"
              d="M18.667 12a6.667 6.667 0 11-13.334 0 6.667 6.667 0 0113.334 0z"
            ></path>
            <path
              stroke="#6938EF"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.667 14l2-2-2-2m-3.334 0l-2 2 2 2"
            ></path>
          </svg>
        );
      }
      return (
        <div
          style={{
            height,
            width,
            borderRadius: "3px",
            backgroundColor: "#5FC3DB",
          }}
        />
      );
    default:
      break;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="24" height="24" fill="#FFDB01" rx="4"></rect>
      <path
        fill="#F9FBFE"
        d="M13.333 15.773A4.002 4.002 0 0012 8.001a4 4 0 101.333 7.772z"
      ></path>
      <path
        stroke="#F9FBFE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M10.667 15.773v1.561a1.333 1.333 0 102.666 0v-1.561m-2.666 0A4.002 4.002 0 018 12.001m2.667 3.772c.417.147.865.228 1.333.228m1.333-.228A4.002 4.002 0 0016 12.001m-2.667 3.772a3.994 3.994 0 01-1.333.228m4-4a4 4 0 01-4 4m4-4a4 4 0 10-8 0m4 4a4 4 0 01-4-4m4-6.667v.667m-6 6h-.667m2.334-4.334l-.4-.4m9.066.4l.4-.4m1.934 4.734H18"
      ></path>
    </svg>
  );
};

const PhaseIconsON = ({ phaseId, width, height, color }) => {
  switch (phaseId) {
    case 1:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 32 32"
        >
          <path
            fill={color}
            d="M18.668 23.544a8 8 0 10-5.333-15.09 8 8 0 005.333 15.09z"
          ></path>
          <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.335 23.544v3.122a2.667 2.667 0 105.333 0v-3.122m-5.333 0A8.003 8.003 0 018 16m5.334 7.545A7.988 7.988 0 0016 24m2.667-.455A8.003 8.003 0 0024.001 16m-5.333 7.545a7.988 7.988 0 01-2.667.455m8-8a8 8 0 01-8 8m8-8a8 8 0 00-16 0m8 8a8 8 0 01-8-8m8-13.333v1.333m-12 12H2.668m4.667-8.666l-.8-.8m18.133.8l.8-.8m3.867 9.466H28"
          ></path>
        </svg>
      );
    case 2:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 32 32"
        >
          <path
            fill={color}
            d="M2.668 29.333c2.667 0 6.144.456 7.966-1.367a4.667 4.667 0 10-6.6-6.6c-1.822 1.823 0 5.3-1.366 7.967z"
          ></path>
          <path
            fill={color}
            d="M10.668 21.333c-.655-.655-.983-.983-1.168-1.326a2.667 2.667 0 01.05-2.623c.199-.336.538-.65 1.218-1.28L23.926 3.921a2.94 2.94 0 014.154 4.154L15.897 21.232c-.63.68-.945 1.02-1.28 1.219-.805.475-1.8.494-2.623.05-.343-.185-.67-.513-1.326-1.168z"
          ></path>
          <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12.001 14.963L17.038 20m-6.404 7.966c-1.822 1.822-5.3 1.367-7.966 1.367 1.367-2.667-.456-6.144 1.367-7.967a4.667 4.667 0 016.6 6.6zm5.263-6.734L28.08 8.075a2.94 2.94 0 00-4.154-4.154L10.768 16.104c-.68.63-1.02.944-1.218 1.28a2.667 2.667 0 00-.05 2.623c.185.343.513.67 1.168 1.326.655.655.983.983 1.326 1.168a2.667 2.667 0 002.623-.05c.335-.199.65-.539 1.28-1.219z"
          ></path>
        </svg>
      );
    case 3:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 32 32"
        >
          <path
            fill="#F0F5FA"
            stroke="#F0F5FA"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M29.335 16c0 7.363-5.97 13.333-13.334 13.333-7.363 0-13.333-5.97-13.333-13.334 0-7.363 5.97-13.333 13.333-13.333 7.364 0 13.334 5.97 13.334 13.333z"
          ></path>
          <path
            stroke={color === "#F9FBFE" ? "#6938EF" : color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19.335 20l4-4-4-4m-6.667 0l-4 4 4 4"
          ></path>
        </svg>
      );
    case 4:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 32 32"
        >
          <path
            fill={color}
            d="M24.001 2.666h-16c-1.242 0-1.863 0-2.354.203-.653.27-1.172.79-1.443 1.443-.203.49-.203 1.111-.203 2.354s0 1.864.203 2.354c.27.653.79 1.172 1.443 1.443.49.203 1.112.203 2.354.203h16c1.243 0 1.864 0 2.354-.203a2.666 2.666 0 001.443-1.443c.203-.49.203-1.111.203-2.354s0-1.864-.203-2.354a2.667 2.667 0 00-1.443-1.443c-.49-.203-1.111-.203-2.354-.203zM5.335 29.333a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.334zM16.001 29.333a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.334zM26.668 29.333a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.334z"
          ></path>
          <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5.335 24v-.267c0-2.24 0-3.36.436-4.216a4 4 0 011.748-1.748c.855-.436 1.975-.436 4.216-.436h8.533c2.24 0 3.36 0 4.216.436a4 4 0 011.748 1.748c.436.855.436 1.976.436 4.216v.266M5.335 24a2.667 2.667 0 100 5.334 2.667 2.667 0 000-5.334zm21.333 0a2.667 2.667 0 100 5.334 2.667 2.667 0 000-5.334zm-10.667 0a2.667 2.667 0 100 5.334 2.667 2.667 0 000-5.334zm0 0V10.666m-8 0h16m-16 0c-1.242 0-1.863 0-2.354-.203A2.666 2.666 0 014.204 9.02c-.203-.49-.203-1.111-.203-2.354s0-1.864.203-2.354c.27-.653.79-1.172 1.443-1.443.49-.203 1.112-.203 2.354-.203h16c1.243 0 1.864 0 2.354.203.654.27 1.173.79 1.443 1.443.203.49.203 1.111.203 2.354s0 1.864-.203 2.354a2.666 2.666 0 01-1.443 1.443c-.49.203-1.111.203-2.354.203m-16 0h8m8 0h-8"
          ></path>
        </svg>
      );
    case 5:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 32 32"
        >
          <path
            fill={color}
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.843 10.175c-.528-.529-.792-.793-.89-1.097a1.334 1.334 0 010-.824c.098-.304.362-.568.89-1.096l3.785-3.785a8 8 0 00-11.066 9.193c.159.652.238.978.224 1.184-.015.216-.047.33-.146.523-.095.183-.277.365-.641.73l-8.33 8.33a2.828 2.828 0 104 4l8.33-8.33c.364-.365.546-.547.73-.642.191-.1.306-.131.521-.146.206-.014.533.065 1.185.224a8 8 0 009.193-11.066l-3.785 3.785c-.528.527-.792.792-1.096.89a1.334 1.334 0 01-.824 0c-.304-.098-.568-.363-1.097-.89l-.983-.983z"
          ></path>
        </svg>
      );
    default:
      return <></>;
  }
};

export const PhaseIconPure = React.memo(PhaseIconsON);

export default React.memo(PhaseIcon);
