import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Core/CommonV2/CustomButton";
import CustomInput from "../../Core/Common/CustomInput";
import EyeIcon from "../../Core/svg/EyeIcon.js";
import * as Yup from "yup";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { client } from "../../Core/utils/axiosClient";

import RightScreenV2 from "./RightScreen";

import CustomInputBox from "../../Core/CommonV2/CustomInputBox";
import CustomPopover from "../../Core/Common/CustomPopover";

// type Props = {}
//todo error onky on submitting

const ResetPasswordV2 = () => {
  const navigate = useNavigate();
  const [newPasswordFocused, setNewPasswordFocused] = useState(false);
  const [step, setStep] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");

  useEffect(() => {
    if (!searchParams.has("token")) {
      navigate("/");
    }
  }, [searchParams]);

  const handleResetPassword = async ({
    email,
    confirmPassword,
    password: newPassword,
  }) => {
    try {
      const response = await client.put(`/auth/reset-password`, {
        email,
        isForgot: true,
        token,
        newPassword,
        confirmPassword,
      });

      // const { valid } = response.data;
      // if (valid) {
      //   setCheckingEmail(false);
      //   if (typeof callback === "function") callback();
      // } else {
      //   console.log("hehe");
      //   setIsEmailValid(false);
      // }
      if (response.status === 200) {
        // navigate("/");
        setStep(2);
      }
      console.log(response);
    } catch (error) {
      console.log(error, "eee");
      console.log(error.response?.data?.message);
      formik.setFieldError(
        "email",
        error.response?.data?.message || "Something went wrong"
      );
      //return error;
    } finally {
      // setCheckingEmail(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
      confirmPassword: null,
      showPassword: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the email address")
        .typeError("Please enter the email address"),
      password: Yup.string()
        .required("This field is required")
        .typeError("Please enter the email address"),
      // https://codesandbox.io/s/l2r832l8x7?file=/src/index.js:147-464
      confirmPassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both passwords need to be the same"
        ),
      }),
    }),
    onSubmit: (values) => {
      console.log(values);
      const { showPassword } = values;

      if (showPassword) handlePasswordVisible(false);
      handleResetPassword(values);
    },
  });
  const passwordRequirements = {
    hasLowercase: false,
    hasUppercase: false,
    hasNumerical: false,
    hasSpecialChar: false,
  };
  if (formik.values.password) {
    // const hasLowercase =  new RegExp(
    //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    // ).test(formik.values.password)
    passwordRequirements.hasLowercase = new RegExp("^(?=.*[a-z]).+$").test(
      formik.values.password
    );
    passwordRequirements.hasUppercase = new RegExp("^(?=.*[A-Z]).+$").test(
      formik.values.password
    );
    passwordRequirements.hasNumerical = new RegExp("^(?=.*[0-9]).+$").test(
      formik.values.password
    );
    passwordRequirements.hasSpecialChar = new RegExp(
      "^(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    ).test(formik.values.password);
  }
  const handlePasswordVisible = (toggleState) => {
    formik.setFieldValue("showPassword", toggleState);
  };
  const renderBody = () => {
    switch (step) {
      case 1:
        return (
          <div className=" flex flex-col h-[662px] gap-y-5.5 ">
            <div className="h-[80px] flex justify-between w-full">
              <div className="min-w-[245px] w-[70%] gap-y-1 flex flex-col h-[76px] justify-between  ">
                <div className="out-600-24 text-primary-600 h-[32px]">
                  Create new password
                </div>
                <div className=" out-300-14 text-gray-500  ">
                  Enter a new password
                </div>
              </div>
              <div className="h-[80px]  w-[64px]  ">
                <img
                  className="h-[80px]  w-[64px]"
                  alt="img"
                  src={"/images/v2/onboarding/frosty-waving.svg"}
                />
              </div>
            </div>

            <div className="flex flex-col items-center justify-start w-full ">
              <form
                autoComplete="off"
                // style={{ width: 520 }}
                onSubmit={formik.handleSubmit}
                id="formik"
                className="w-full"
              >
                <CustomInputBox
                  defaultValue="Email"
                  name={"email"}
                  type={"text"}
                  placeholder="Enter email address"
                  {...formik.getFieldProps("email")}
                  formikHook={formik}
                  className="mt-5.5"
                />
                <CustomPopover
                  content={
                    <div className="p-4">
                      <div className="text-gray-700 out-500-14 mb-2">
                        Password Requirements
                      </div>
                      <hr></hr>
                      <div className="text-gray-600 ">
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 mt-2 mb-2">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              formik?.values?.password?.length >= 8
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>{" "}
                          <div>8 characters minimum</div>
                        </div>
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 mb-2">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              passwordRequirements.hasLowercase
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>
                          <div>One lowercase character (a-z)</div>
                        </div>
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 mb-2">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              passwordRequirements.hasUppercase
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>
                          <div>One uppercase character (A-Z)</div>
                        </div>
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 mb-2">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              passwordRequirements.hasNumerical
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>
                          <div>One number (0-9)</div>
                        </div>
                        <div className="flex gap-1 items-center out-300-14 text-gray-500 ">
                          <div
                            className={`h-3 w-3 rounded-xl ${
                              passwordRequirements.hasSpecialChar
                                ? "bg-success-600"
                                : "bg-gray-300"
                            } `}
                          ></div>
                          <div>One special character (!@#$%^&*)</div>
                        </div>
                      </div>
                    </div>
                  }
                  visible={newPasswordFocused}
                  placement={"left"}
                >
                  <CustomInputBox
                    defaultValue="New password"
                    name={"password"}
                    type={formik.values.showPassword ? "text" : "password"}
                    placeholder="Must have atleast 6 characters"
                    {...formik.getFieldProps("password")}
                    formikHook={formik}
                    className="mt-5.5"
                    onFocusChange={(isFocused, el = null) => {
                      const elName =
                        el?.nativeEvent?.explicitOriginalTarget?.getAttribute(
                          "name"
                        );
                      if (elName === "show-password" && !isFocused) return;
                      setNewPasswordFocused(isFocused);
                    }}
                    suffix={
                      <EyeIcon
                        color={"#667085"}
                        width={16}
                        show={formik.values.showPassword}
                        onToggle={handlePasswordVisible}
                      />
                    }
                  />
                </CustomPopover>

                <CustomInputBox
                  defaultValue="Confirm password"
                  name={"confirmPassword"}
                  type={formik.values.showPassword ? "text" : "password"}
                  placeholder="Must have atleast 6 characters"
                  {...formik.getFieldProps("confirmPassword")}
                  formikHook={formik}
                  className="mt-5.5"
                  suffix={
                    <EyeIcon
                      color={"#667085"}
                      width={16}
                      show={formik.values.showPassword}
                      onToggle={handlePasswordVisible}
                    />
                  }
                />
                {!(formik.values.confirmPassword === formik.values.password) &&
                  formik.values.confirmPassword && (
                    <div className="mt-1.5 out-400-14 text-left text-error-500">
                      {` Passwords don't match`}
                    </div>
                  )}

                <CustomButton
                  text="Reset Password"
                  type="submit"
                  height="36px"
                  width="100%"
                  className={`text-white 
           bg-primary-600
           out-500-14 mt-12`}
                  // disabled={!(formik.isValid && formik.dirty)}
                  // onClick={() => dispatch(resetConversations(["editMessage"]))}
                />
              </form>
              <div className="flex w-full items-center justify-between pt-[48px]">
                <div className="out-300-14 text-gray-500">
                  {" Don't have an account?"}
                </div>

                <CustomButton
                  text="Sign up"
                  className="text-gray-700  bg-inherit border-gray-300 border-[1px]  out-500-14"
                  height="30px"
                  width="90px"
                  onClick={() => navigate("/signupV2")}
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className=" flex flex-col gap-y-6">
            <div className="h-[52px] flex flex-col justify-start w-full">
              <div className="min-w-[223px] max-w-[245px] gap-y-1 flex flex-col  justify-start  ">
                <div className="out-600-24 text-primary-600 h-[32px] ">
                  Password changed succesfully!
                </div>
                {/* <div className=" out-300-14 text-gray-500 w-full flex">
          Verified as{" "}
          <span className="out-500-14 text-gray-900 ml-1">
            {" "}
            {formik.values.email}
          </span>{" "}
          <span className="ml-2 h-5 w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#F9FBFE"
                d="M22 11.086v.92a10 10 0 11-5.93-9.14"
              ></path>
              <path fill="#F9FBFE" d="M22 4L12 14.01l-3-3"></path>
              <path
                stroke="#039855"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M22 11.086v.92a10 10 0 11-5.93-9.14M22 4L12 14.01l-3-3"
              ></path>
            </svg>
          </span>
        </div> */}
              </div>
            </div>
            <div className="flex justify-center mx-auto items-center my-[48px] h-[39px] w-[134px]">
              <img src="/images/v2/onboarding/jumping-frosty.svg" />
            </div>
            {/* <div className=" flex flex-col h-[110px] gap-y-3 w-full mt-[2px] items-start justify-start ">
      <div className="out-500-14 text-gray-900 justify-start flex  ">
        {" We can’t wait for you to get started with Waddle 🐧"}
      </div>
      <div className="out-300-12 text-gray-500   ">
        We’ll start by answering some questions, so we can curate your
        experience better.
      </div>

      <div>
        <CustomButton
          text="Lets go!"
          className="bg-primary-600 text-white border-[1px]"
          height="30px"
          width="362px"
          onClick={() => navigate("/login")}
        />
      </div>
    </div> */}
            <div className="gap-x-2 flex ">
              <CustomButton
                text="Sign in"
                className="text-gray-700  bg-inherit border-gray-300 border-[1px]  out-500-14"
                height="30px"
                width="90px"
                onClick={() => navigate("/loginV2")}
              />
              <div className="text-gray-500 flex items-center out-300-14 ">
                with your new password to continue
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="flex w-screen h-screen">
      <div className="flex-[6] bg-blue-50">
        <RightScreenV2 />
      </div>
      <div className="flex-[4] flex-center h-screen overflow-y-auto">
        <div className="w-[407px] h-[414px] flex flex-col justify-start p-6  mt-[50px] mx-auto">
          {renderBody()}
          {/* <div>
            <div className="mon-600-24 text-primary-600 mb-6">
              Create new password
            </div>
            <div className="mt-5.5 mon-400-14 text-gray-500">
              Enter a new password
            </div>

            <form
              autoComplete="off"
              // style={{ width: 520 }}
              onSubmit={formik.handleSubmit}
              id="formik"
              className="mt-5.5"
            >
              <CustomInput
                label="Email"
                labelDefaultCase
                name={"email"}
                type="email"
                placeholder="Enter email address"
                {...formik.getFieldProps("email")}
                formikHook={formik}
                className="mt-5.5"
              />

              <CustomInput
                label="New password"
                labelDefaultCase
                name={"password"}
                type={formik.values.showPassword ? "text" : "password"}
                placeholder="Must have atleast 6 characters"
                {...formik.getFieldProps("password")}
                formikHook={formik}
                className="mt-5.5"
                suffix={
                  <EyeIcon
                    color={"#667085"}
                    width={16}
                    show={formik.values.showPassword}
                    onToggle={handlePasswordVisible}
                  />
                }
              />

              <CustomInput
                label="Confirm password"
                labelDefaultCase
                name={"confirmPassword"}
                type={formik.values.showPassword ? "text" : "password"}
                placeholder="Must have atleast 6 characters"
                {...formik.getFieldProps("confirmPassword")}
                formikHook={formik}
                className="mt-5.5"
                suffix={
                  <EyeIcon
                    color={"#667085"}
                    width={16}
                    show={formik.values.showPassword}
                    onToggle={handlePasswordVisible}
                  />
                }
              />

              <CustomButton
                text="Reset Password"
                type="submit"
                height="36px"
                width="100%"
                className={`text-white ${
                  !(formik.isValid && formik.dirty)
                    ? "bg-primary-100"
                    : "bg-primary-600"
                } mon-500-14 mt-12`}
                disabled={!(formik.isValid && formik.dirty)}
              />
            </form>
            <div className="flex items-center justify-between mt-12">
              <div className="mon-400-14 text-gray-500">
                {"Don't have an account?"}
              </div>
              <div>
                <CustomButton
                  text="Sign Up"
                  className="text-primary-700 bg-primary-50 mon-500-14"
                  height="36px"
                  width="110px"
                  onClick={() => navigate("/signup")}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordV2;
