import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (
    {
      userId,
    }: {
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/notifications?userId=${userId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getAllowedNotification = createAsyncThunk(
  "notifications/getAllowedNotification",
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get(`/notifications/allowed-notifications`);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
