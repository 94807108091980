import React from "react";
import { useAppDispatch } from "../../../Core/redux/hooks";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const DiscardEditedGoalModal = ({ visible, onClose, onDiscard }) => {
  const dispatch = useAppDispatch();

  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Discard Changes ?</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 text-gray-500 out-300-14">
            Are you sure you want to discard the changes ? This action cannot be
            undone.
          </p>
          <div className="flex items-center justify-end mt-4 gap-x-3">
            <CustomButton
              text="Cancel"
              className="text-gray-500 bg-transparent"
              height="30px"
              width="63px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Discard"
              height="30px"
              width="61px"
              className="text-white bg-error-700"
              onClick={onDiscard}
            />
          </div>
        </div>
      }
    />
  );
};

export default DiscardEditedGoalModal;
