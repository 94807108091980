import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../../Core/svgV2/UploadIcon";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import axios from "axios";
import uniqid from "uniqid";
import useFileList from "./useFileList";
import { updateConversations } from "../../../Core/redux/slices/conversations.slice";

const MessageMask = () => {
  const { currentThread } = useAppSelector((state) => state.conversations);
  return (
    <div
      className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-end justify-center m-2 border rounded-md border-primary-400 "
      style={{
        background: "rgba(245, 250, 255 , 0.8)",
      }}
    >
      <div className="flex gap-x-2 justify-center pb-[43px]">
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary-600">
          <UploadIcon className="text-white" />
        </div>
        <div className="flex items-center justify-center h-10 px-2 text-white rounded-lg bg-primary-600 w-max out-300-16">
          Drop files to send them to
          <span className="ml-2 out-500-16">
            {currentThread.type == "group"
              ? currentThread.threadName
              : currentThread.firstname + " " + currentThread.lastname}
          </span>
        </div>
      </div>
    </div>
  );
};

const DragAndDropLayer = ({ children, comments, containerRef = null }) => {
  const [fileList, setFileList] = useState([]);
  const userLocal = JSON.parse(localStorage.getItem("user"));
  const { fileState, setFileState, setUploadingState } = useFileList();
  const dispatch = useAppDispatch();

  const handleUploadToStorJ = async (files) => {
    const fmData = new FormData();
    let dimensionalValue = 0;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + userLocal.token,
      },
    };

    // console.log(files, "dvsvhsvusfgvusfgu");
    setUploadingState(true);
    const reduxFiles = files.map((file, i) => {
      const uniqueFileName = `${uniqid(
        `${file.name.split(".")[0]}-`
      )}.${file.name.split(".").at(-1)}`;
      fmData.append(`image${i}`, file, uniqueFileName);
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result.toString();

        img.onload = () => {
          // Access image dimensions

          dimensionalValue = img.width * img.height;

          // You can set the image state or perform further actions here
          // setImage(file);
        };
      };
      reader.readAsDataURL(file);

      return {
        uid: `${i}`,
        name: file.name,
        status: "uploading",
        url: URL.createObjectURL(file),

        fileUrl: URL.createObjectURL(file),
        percent: 100,
        type: file.type,
        size: file.size,
      };
    });
    console.log(reduxFiles, fileState, "dvsvhsvusfgvusfgu");
    // dispatch(
    //   updateConversations({
    //     key: "defaultFileList",
    //     value: [...defaultFileList, ...reduxFiles],
    //   })
    // );
    setFileState([...fileState, ...reduxFiles]);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
        fmData,
        config
      );

      // dispatch(updateConversations({ key: "defaultFileList", value: [] }));

      const newFiles = response.data.map((file, i) => {
        const orjFile = files[i];
        let newFile = { ...file };
        if (+dimensionalValue >= 71000000) {
          newFile = { ...newFile, imageOutOfBound: true };
        }

        return {
          uid: `${i}`,
          name: file.fileName,
          status: "done",
          url: file.fileUrl,
          response: [newFile],
          fileUrl: file.fileUrl,
          percent: 100,
          type: orjFile.type,
          size: orjFile.size,
        };
      });

      // dispatch(
      //   updateConversations({
      //     key: "defaultFileList",
      //     value: [...defaultFileList, ...newFiles],
      //   })
      // );
      setFileState([...fileState, ...newFiles]);
      setUploadingState(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileList([...fileList, ...acceptedFiles]);
    },
    [fileList]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: (files) => {
      handleUploadToStorJ(files);
    },
  });

  if (comments) {
    return <> {children}</>;
  }

  return (
    <div
      style={{
        overflow: isDragActive ? "hidden" : "auto",
      }}
      ref={containerRef}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {/* {isDragActive ? } */}
        {isDragActive && <MessageMask />}
        {children}
      </div>
    </div>
  );
};

export default DragAndDropLayer;
