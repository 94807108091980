import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  deleteFileInConv,
  getPrivateMessages,
  getThreadMessages,
} from "../../../Core/redux/api/conversationAPI";

const DeleteFileModal = ({ visible, onClose }) => {
  const { deleteFileInMessage, currentThread } = useAppSelector(
    (state) => state.conversations
  );

  const { user } = useAppSelector((state) => state.userDetails);

  const dispatch = useAppDispatch();

  const handelDelete = async () => {
    if (deleteFileInMessage) {
      await dispatch(deleteFileInConv(deleteFileInMessage));
      if (currentThread.type === "group") {
        dispatch(
          getThreadMessages({
            threadId: currentThread.threadId,
            limit: 20,
            offset: 0,
          })
        );
      } else {
        dispatch(
          getPrivateMessages({
            userId: user.id,
            reciverId: +currentThread.userId,
            limit: 20,
            offset: 0,
          })
        );
      }
    }
    onClose();
  };
  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => console.log("")}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Delete file</p>
            <button>
              {" "}
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to delete this file? <br />
            This action cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={onClose}
            />
            <CustomButton
              text="Delete file"
              height="30px"
              width="90px"
              className="bg-error-700 text-white"
              onClick={handelDelete}
            />
          </div>
        </div>
      }
    />
  );
};

export default DeleteFileModal;
