import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatClient, client, getError } from "../../utils/axiosClient";

export const getThreads = createAsyncThunk(
  "conversations/getThreads",
  async (
    {
      userId,
    }: {
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/conversation/threads?userId=${userId}`
      );
      console.log(
        "🚀 ~ file: conversationAPI.ts:18 ~ getThreads ~ response:",
        response.data
      );
      response.data.userId = userId;
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getProjectsWithThreads = createAsyncThunk(
  "conversations/getProjectsWithThreads",
  async (
    {
      userId,
    }: {
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/conversation/project-threads?userId=${userId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createThread = createAsyncThunk(
  "conversations/createThread",
  async (
    {
      projectId,
      name,
      threadProfilePicture,
      users,
    }: {
      projectId: string | null | number;
      name: string;
      description?: string;
      users: number[] | string[];
      phaseId?: string | null | number;
      goalId?: string | null | number;
      threadProfilePicture?: string | null;
      threadProfilePictureColorCode?: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post(`/conversation/threads`, {
        projectId,
        name,
        threadProfilePicture,
        users,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const forwardMessageToUsers = createAsyncThunk(
  "conversations/forwardMessageToUsers",
  async (
    {
      messages,
    }: {
      messages: any[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await chatClient.post(`/conversation/forward-message`, {
        messages,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const saveComments = createAsyncThunk(
  "conversations/saveComments",
  async (
    {
      message,
    }: {
      message: any;
    },
    { rejectWithValue }
  ) => {
    try {
      console.log("message.users", message.users);
      const response = await chatClient.post(`/conversation/save-comment`, {
        ...message,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateComment = createAsyncThunk(
  "conversations/updateComment",
  async (
    {
      messageId,
      message,
    }: {
      messageId: string;
      message: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await chatClient.put(`/conversation/edit-message`, {
        messageId,
        message,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateThread = createAsyncThunk(
  "conversations/updateThread",
  async (
    payload: {
      projectId?: string;
      name: string;
      description?: string;
      users?: number[] | string[];
      phaseId?: string;
      threadId: string;
      threadProfilePicture?: string | null;
      threadProfilePictureColorCode?: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(`/conversation/threads`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

// export const updateThreadForUser = createAsyncThunk(
//   "conversations/updateThreadForUser",
//   async (
//     payload: {
//       userId?: string | number;
//       threadId: string;
//       isMute?: number;
//       isPinned?: number;
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       const response = await client.put(
//         `/conversation/update-thread-for-user`,
//         payload
//       );

//       return response.data;
//     } catch (error) {
//       return rejectWithValue(getError(error));
//       //return error;
//     }
//   }
// );

export const getThreadsMembers = createAsyncThunk(
  "conversations/getThreadsMembers",
  async (
    {
      threadId,
    }: {
      threadId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/conversation/threads/members?threadId=${threadId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const leaveThread = createAsyncThunk(
  "conversations/leaveThread",
  async (
    payload: {
      userId?: string;
      threadId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(`/conversation/threads/leave`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const closeThread = createAsyncThunk(
  "conversations/closeThread",
  async (
    payload: {
      userId?: string;
      threadId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(`/conversation/threads/close`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getThreadMessages = createAsyncThunk(
  "conversations/getThreadMessages",
  async (
    {
      threadId,
      limit,
      offset,
      search = "",
    }: {
      userId?: string;
      threadId: string;
      limit: number;
      offset: number;
      search?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await chatClient.get(
        `/conversation/thread-messages?threadId=${threadId}&limit=${limit}&offset=${offset}&search=${search}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getMoreThreadMessages = createAsyncThunk(
  "conversations/getMoreThreadMessages",
  async (
    {
      threadId,
      limit,
      offset,
      search = "",
    }: {
      userId?: string;
      threadId: string;
      limit: number;
      offset: number;
      search?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await chatClient.get(
        `/conversation/thread-messages?threadId=${threadId}&limit=${limit}&offset=${offset}&search=${search}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const deleteFileInConv = createAsyncThunk(
  "conversations/deleteFile",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await chatClient.post(
        `/conversation/delete-file`,
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const saveInVault = createAsyncThunk(
  "conversations/saveInVault",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await chatClient.post(
        `/conversation/save-in-vault`,
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getComments = createAsyncThunk(
  "conversations/getComments",
  async (
    {
      docId,
      docType,
      limit,
      offset,
    }: {
      docId?: string;
      docType: string;
      limit: number;
      offset: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await chatClient.get(
        `/conversation/comments?docId=${docId}&docType=${docType}&limit=${limit}&offset=${offset}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const reactToComment = createAsyncThunk(
  "conversations/reactToComment",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await chatClient.post(
        `/conversation/add-reaction`,
        payload
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getPrivateMessages = createAsyncThunk(
  "conversations/getPrivateMessages",
  async (
    {
      userId,
      reciverId,
      limit,
      offset,
      search = "",
    }: {
      userId: string | number;
      limit: number;
      offset: number;
      search?: string;
      reciverId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await chatClient.get(
        `/conversation/private-message?senderId=${userId}&reciverId=${reciverId}&limit=${limit}&offset=${offset}&search=${search}`
      );
      response.data.userId = userId;
      response.data.reciverId = reciverId;
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getMorePrivateMessages = createAsyncThunk(
  "conversations/getMorePrivateMessages",
  async (
    {
      userId,
      reciverId,
      limit,
      offset,
      search = "",
    }: {
      userId: string | number;
      limit: number;
      offset: number;
      search?: string;
      reciverId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await chatClient.get(
        `/conversation/private-message?senderId=${userId}&reciverId=${reciverId}&limit=${limit}&offset=${offset}&search=${search}`
      );
      response.data.userId = userId;
      response.data.reciverId = reciverId;
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

// export const getSearchResults = createAsyncThunk(
//   "conversations/getSearchResults",
//   async (
//     {
//       userId,
//       limit,
//       offset,
//       search = "",
//     }: {
//       userId: string | number;
//       limit: number;
//       offset: number;
//       search?: string;
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       const response = await chatClient.get(
//         `/conversation/search-results?userId=${userId}&limit=${limit}&offset=${offset}&search=${search}`
//       );
//       response.data.userId = userId;
//       console.log("response.data", response.data);
//       return response.data.result;
//     } catch (error) {
//       return rejectWithValue(getError(error));
//       //return error;
//     }
//   }
// );

export const updatePrivateChat = createAsyncThunk(
  "conversations/updatePrivateChat",
  async (
    payload: {
      senderId?: string | number;
      receiverId?: string | number;
      isMute?: boolean;
      isPinned?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.put(`/conversation/private-chat`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getUnreadCountDm = createAsyncThunk(
  "conversations/getUnreadCountDm",
  async (
    {
      userId,
    }: {
      userId: string | number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await chatClient.get(
        `/conversation/get-unread-count-dm?userId=${userId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getPrivateChats = createAsyncThunk(
  "conversations/getPrivateChats",
  async (
    {
      userId,
    }: {
      userId?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/conversation/private-chats?userId=${userId}`
      );
      console.log(
        "🚀 ~ file: conversationAPI.ts:475 ~ getPrivateChats ~ response:",
        response
      );
      response.data.userId = userId;
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
