import React, { useEffect, useState } from "react";

import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Core/CommonV2/CustomButton";
import PageTitle from "./PageTitle";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import CustomInputBox from "../../Core/CommonV2/CustomInputBox";
import { useForm, Controller } from "react-hook-form";
import { Checkbox } from "antd";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import dayjs from "dayjs";
import { updateUserProfile } from "../../Core/redux/api/userAPI";
import ChangeImageModal from "./Modals/ChangeImageModal";
import DiscardModal from "./Modals/DiscardModal";
import CustomAddressInput from "../../Core/CommonV2/CustomAddressInput";

const Border = () => (
  <div className="border-solid border-gray-200 relative w-full h-px  border-t  rounded-none" />
);

const ManageProfile = () => {
  const navigate = useNavigate();
  const { dashboardRedirected } = useAppSelector((state) => state.dashboard);

  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [changeImageModal, setChangeImageModal] = useState({
    visible: false,
    data: { image: "", color: "", title: "" },
    action: "changeProjectImage",
  });

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    values: {
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.company || user.companyName,
      jobTitle: user.jobTitle,
      location: user.location,
      mobile: user.mobile,
      profilePicture: user.profilePicture,
      profilePictureColorCode: user.profilePictureColorCode,
      cords: [user.lat, user.long],
      showJobTitle: user.showJobTitle,
      showPhoneNumber: user.showPhoneNumber,
      showCompanyName: user.showCompanyName,
    },
  });

  useEffect(() => {
    setValue("firstName", user.firstName);
    setValue("lastName", user.lastName);
    setValue("company", user.company || user.companyName);
    setValue("jobTitle", user.jobTitle);
    setValue("location", user.location);
    setValue("mobile", user.mobile);
    setValue("cords", [user.lat, user.long]);
    setValue("profilePicture", user.profilePicture);
    setValue("profilePictureColorCode", user.profilePictureColorCode);
    setValue("showJobTitle", user.showJobTitle);
    setValue("showPhoneNumber", user.showPhoneNumber);
    setValue("showCompanyName", user.showCompanyName);
  }, [user]);

  const handleCancel = () => {
    if (!formState.isDirty) {
      if (dashboardRedirected) {
        navigate("/dashboard");
      }
      navigate(-1);
    } else {
      setShowDiscardModal(true);
    }
  };

  const onSubmit = async (data) => {
    setShowDiscardModal(false);

    await dispatch(
      updateUserProfile({
        firstname: data.firstName,
        lastname: data.lastName,
        company: data.company,
        jobTitle: data.jobTitle,
        profilePicture: data.profilePicture,
        profilePictureColorCode: data.profilePictureColorCode,

        location: data.location,
        mobile: data.mobile,
        lat: data.cords[0],
        long: data.cords[1],
        showJobTitle: data.showJobTitle,
        showPhoneNumber: data.showPhoneNumber,
        showCompanyName: data.showCompanyName,
      })
    );
    reset({}, { keepValues: true });
    navigate(-1);
  };

  watch(["location", "showCompanyName", "showJobTitle", "showPhoneNumber"]);

  return (
    <div className=" flex flex-col bg-white">
      <div className="flex justify-between py-[11px] px-6 items-center h-[46px] border-b border-gray-200">
        <div
          role="button"
          onClick={handleCancel}
          className="flex items-center justify-center gap-x-2"
        >
          <ChevronIcon className="tranform rotate-90" />
          <div className="out-500-14">Profile</div>
        </div>
        <div className="flex justify-center items-center gap-x-3">
          <CustomButton
            text="Cancel"
            className="bg-white border border-gray-200"
            height="30px"
            onClick={handleCancel}
          />
          <CustomButton
            text="Save Changes"
            height="30px"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isDirty}
          />
        </div>
      </div>
      <div className="flex justify-center flex-1 overflow-y-scroll max-h-[calc(100vh-46px)] min-h-[calc(100vh-46px)] pt-6">
        <div className="min-w-[667px] max-w-[667px]">
          <PageTitle
            title={"Profile"}
            subtext={"Manage your Waddle profile."}
          />

          <div className="flex flex-col gap-y-6 mt-6 pb-40">
            <div className="out-300-14 text-gray-500">Personal info</div>
            <div>
              <p className="out-500-14 text-gray-700 pb-[6px]">
                Profile picture
              </p>

              <CustomAvatar
                size={64}
                title={`${getValues("firstName")} ${getValues("lastName")}`}
                whiteText
                color={getValues("profilePictureColorCode")}
                src={getValues("profilePicture")}
                fontSize={"24px"}
              />
              <button
                onClick={() =>
                  setChangeImageModal({
                    visible: true,
                    data: {
                      image: getValues("profilePicture"),
                      color: getValues("profilePictureColorCode"),
                      title: `${getValues("firstName")} ${getValues(
                        "lastName"
                      )}`,
                    },
                    action: "changeUserImage",
                  })
                }
                className="out-500-14 mt-3 text-primary-700"
              >
                Change Image
              </button>
            </div>
            <div className="w-[50%]">
              <p className="out-500-14 text-gray-700 pb-[6px]">Full name</p>
              <div className="flex gap-x-2">
                <Controller
                  control={control}
                  name="firstName"
                  rules={{
                    required: "Please Add Your Firstname",
                    maxLength: {
                      value: 25,
                      message: "Maximum 25 characters allowed",
                    },
                  }}
                  render={({ field }) => (
                    <CustomInputBox
                      {...field}
                      placeholder="Firstname"
                      rootStyle={
                        formState?.errors ? { height: "50%" } : { width: "50%" }
                      }
                      formState={formState}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="lastName"
                  rules={{
                    required: "Please Add Your Lastname",
                    maxLength: {
                      value: 25,
                      message: "Maximum 25 characters allowed",
                    },
                  }}
                  render={({ field }) => (
                    <CustomInputBox
                      {...field}
                      placeholder="Lastname"
                      rootStyle={
                        formState?.errors ? { height: "50%" } : { width: "50%" }
                      }
                      formState={formState}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <p className="out-500-14 text-gray-700 pb-[6px]">Email</p>

              <CustomInputBox
                placeholder="Email"
                rootStyle={{ width: "50%" }}
                disabled
                value={user.email}
              />
            </div>

            <div>
              <p className="out-500-14 text-gray-700 pb-[6px]">Mobile number</p>

              <Controller
                control={control}
                name="mobile"
                rules={{
                  required: "Please Add Your Mobile Number",
                  maxLength: {
                    value: 15,
                    message: "Mobile number should not exceed 15 digits",
                  },
                }}
                render={({ field }) => (
                  <CustomInputBox
                    {...field}
                    placeholder="Mobile"
                    rootStyle={{ width: "50%" }}
                    formState={formState}
                  />
                )}
              />

              <div className="out-300-14 text-gray-500 mt-1.5 w-[50%]">
                Please ensure you enter the right country code so everyone can
                reach you easily
              </div>

              <div className="flex gap-x-3 mt-1">
                <Checkbox
                  checked={getValues("showPhoneNumber")}
                  onChange={(e) =>
                    setValue("showPhoneNumber", e.target.checked, {
                      shouldDirty: true,
                    })
                  }
                />
                <p
                  className="out-500-14 text-gray-700 "
                  role="button"
                  onClick={() => {
                    const checked = getValues("showPhoneNumber");
                    setValue("showPhoneNumber", !checked, {
                      shouldDirty: true,
                    });
                  }}
                >
                  Show my phone number in my profile
                </p>
              </div>
            </div>

            <Border />

            <div className="out-300-14 text-gray-500">Company info</div>

            <div>
              <p className="out-500-14 text-gray-700 pb-[6px]">Company name</p>

              <Controller
                control={control}
                name="company"
                rules={{
                  required: false,
                  maxLength: {
                    value: 25,
                    message: "Maximum 25 characters allowed",
                  },
                }}
                render={({ field }) => (
                  <CustomInputBox
                    {...field}
                    placeholder="Company name"
                    rootStyle={{ width: "50%" }}
                    formState={formState}
                  />
                )}
              />

              <div className="flex gap-x-3 mt-6">
                <Checkbox
                  checked={getValues("showCompanyName")}
                  onChange={(e) =>
                    setValue("showCompanyName", e.target.checked, {
                      shouldDirty: true,
                    })
                  }
                />
                <p
                  className="out-500-14 text-gray-700 "
                  role="button"
                  onClick={() => {
                    const checked = getValues("showCompanyName");
                    setValue("showCompanyName", !checked, {
                      shouldDirty: true,
                    });
                  }}
                >
                  Show my company name in my profile
                </p>
              </div>
            </div>

            <div>
              <p className="out-500-14 text-gray-700 pb-[6px]">Job Title</p>

              <Controller
                control={control}
                name="jobTitle"
                rules={{
                  required: false,
                  maxLength: {
                    value: 25,
                    message: "Maximum 25 characters allowed",
                  },
                }}
                render={({ field }) => (
                  <CustomInputBox
                    {...field}
                    placeholder="Job Title"
                    rootStyle={{ width: "50%" }}
                    formState={formState}
                  />
                )}
              />

              <div className="flex gap-x-3 mt-5">
                <Checkbox
                  checked={getValues("showJobTitle")}
                  onChange={(e) =>
                    setValue("showJobTitle", e.target.checked, {
                      shouldDirty: true,
                    })
                  }
                />
                <p
                  className="out-500-14 text-gray-700 "
                  role="button"
                  onClick={() => {
                    const checked = getValues("showJobTitle");
                    setValue("showJobTitle", !checked, {
                      shouldDirty: true,
                    });
                  }}
                >
                  Show my job title in my profile
                </p>
              </div>
            </div>

            <div>
              <p className="out-500-14 text-gray-700 pb-[6px]">Based in</p>
              {/* <Controller
                control={control}
                name="location"
                rules={{ required: false }}
                render={({ field }) => (
                  <CustomInputBox
                    {...field}
                    placeholder="Based in"
                    rootStyle={{ width: "50%" }}
                    formState={formState}
                  />
                )}
              /> */}
              <CustomAddressInput
                width="w-[50%]"
                placeholder="Select City"
                name="locationName"
                value={getValues("location")}
                onChange={(value) => setValue("location", value)}
                onSelect={({
                  locationName,
                  fullAddress,
                  lat,
                  long,
                  timezone,
                }) => {
                  setValue("location", fullAddress, { shouldDirty: true });
                  setValue("cords", [lat, long], { shouldDirty: true });

                  if (!locationName) return;
                }}
                getTimezone
              />
            </div>

            <div>
              <p className="out-500-14 text-gray-700 pb-[6px]">
                Current time & timezone
              </p>
              <CustomInputBox
                disabled
                placeholder="Based in"
                rootStyle={{ width: "50%" }}
                value={`${dayjs().format("hh:mm a")}, ${dayjs.tz.guess()}`}
              />

              <p className="out-300-12 text-gray-500 mt-1">
                Updates automatically based on your current location.
              </p>
            </div>
            <Border />
          </div>
        </div>
      </div>

      <ChangeImageModal
        visible={changeImageModal.visible}
        onClose={() =>
          setChangeImageModal({
            visible: false,
            data: { image: "", color: "", title: "" },
            action: "changeUserImage",
          })
        }
        data={changeImageModal.data}
        onOk={(image, color) => {
          if (changeImageModal.action === "changeUserImage") {
            setValue("profilePicture", image, { shouldDirty: true });

            setValue("profilePictureColorCode", color, { shouldDirty: true });

            // dispatch(updateUserProfile({
            //  profilePicture:image,
            // }))

            // dispatch(updateUserProfile({
            //   profilePictureColorCode:color,
            // }))
          }
        }}
      />

      <DiscardModal
        visible={showDiscardModal}
        onClose={() => setShowDiscardModal(false)}
        onDiscard={() => {
          reset({}, { keepValues: true });
          setShowDiscardModal(false);
        }}
        onSave={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default ManageProfile;
