/**
 * Asynchronous function to fetch the user's location using their IP address.
 * This function makes two API calls to retrieve the IP address and then fetch the location data based on the IP address.
 * If successful, it returns an object containing latitude, longitude, and country name of the user's location.
 * If an error occurs during the process, it logs the error message.
 * @returns {Promise<{ latitude: number, longitude: number, countryName: string }>} An object with latitude, longitude, and country name.
 */
export const fetchUserLocation = async () => {
  try {
    const ipResponse = await fetch("https://api.ipify.org?format=json");
    const ipData = await ipResponse.json();
    const ipAddress = ipData.ip;

    const locationResponse = await fetch(`https://ipapi.co/${ipAddress}/json/`);
    const locationData = await locationResponse.json();

    const countryName = locationData.country_name;
    localStorage.setItem("userLocation", countryName);
    return {
      latitude: locationData.latitude,
      longitude: locationData.longitude,
      countryName,
    };
  } catch (error) {
    console.error("Error fetching user location:", error);
  }
};
