import React from "react";
import Icon from "@ant-design/icons";

const CopySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="M2.8 6H10v7.2H2.8V6z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.6 2.8H6v1.6m5.6-1.6h1.6v1.6m0 4v1.2h-1.6M9.2 2.8h.8M13.2 6v.8"
    ></path>
  </svg>
);

const CopyOutlinedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 6V3.9c0-.84 0-1.26.163-1.581a1.5 1.5 0 01.656-.656c.32-.163.74-.163 1.581-.163h5.7c.84 0 1.26 0 1.581.163a1.5 1.5 0 01.656.656c.163.32.163.74.163 1.581v5.7c0 .84 0 1.26-.163 1.581a1.5 1.5 0 01-.656.655c-.32.164-.74.164-1.581.164H12m-8.1 4.5h5.7c.84 0 1.26 0 1.581-.163a1.5 1.5 0 00.655-.656c.164-.32.164-.74.164-1.581V8.4c0-.84 0-1.26-.164-1.581a1.5 1.5 0 00-.655-.656C10.861 6 10.441 6 9.6 6H3.9c-.84 0-1.26 0-1.581.163a1.5 1.5 0 00-.656.656c-.163.32-.163.74-.163 1.581v5.7c0 .84 0 1.26.163 1.581a1.5 1.5 0 00.656.656c.32.163.74.163 1.581.163z"
    ></path>
  </svg>
);

const CopyOutlinSvg = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="20" 
    height="20" 
    viewBox="0 0 20 20" 
    fill="none"
  >
    <g clipPath="url(#clip0_7418_1594)">
      <path d="M4.16675 12.5C3.39018 12.5 3.00189 12.5 2.69561 12.3731C2.28723 12.204 1.96277 11.8795 1.79362 11.4711C1.66675 11.1648 1.66675 10.7766 1.66675 9.99999V4.33332C1.66675 3.3999 1.66675 2.93319 1.8484 2.57667C2.00819 2.26307 2.26316 2.0081 2.57676 1.84831C2.93328 1.66666 3.39999 1.66666 4.33341 1.66666H10.0001C10.7767 1.66666 11.1649 1.66666 11.4712 1.79352C11.8796 1.96268 12.2041 2.28714 12.3732 2.69552C12.5001 3.0018 12.5001 3.39009 12.5001 4.16666M10.1667 18.3333H15.6667C16.6002 18.3333 17.0669 18.3333 17.4234 18.1517C17.737 17.9919 17.992 17.7369 18.1518 17.4233C18.3334 17.0668 18.3334 16.6001 18.3334 15.6667V10.1667C18.3334 9.23324 18.3334 8.76653 18.1518 8.41001C17.992 8.0964 17.737 7.84143 17.4234 7.68165C17.0669 7.49999 16.6002 7.49999 15.6667 7.49999H10.1667C9.23333 7.49999 8.76662 7.49999 8.4101 7.68165C8.09649 7.84143 7.84153 8.0964 7.68174 8.41001C7.50008 8.76653 7.50008 9.23324 7.50008 10.1667V15.6667C7.50008 16.6001 7.50008 17.0668 7.68174 17.4233C7.84153 17.7369 8.09649 17.9919 8.4101 18.1517C8.76662 18.3333 9.23333 18.3333 10.1667 18.3333Z" 
        stroke="#667085" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7418_1594">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);


const CopyIcon = (props) => {
  return <Icon component={CopySvg} {...props} />;
};
const CopyOutlinedIcon = (props) => {
  return <Icon component={CopyOutlinedSvg} {...props} />;
};
const CopyOutlineIcon = (props) => {
  return <Icon component={CopyOutlinSvg} {...props} />;
};

const CopyLinkIcon = ({ className = "", size = "20", style = {} }) => {
  return (
    <span role="img" className={`anticon ${className}`} style={style}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.99967 11.3333H4.66634C2.82539 11.3333 1.33301 9.84091 1.33301 7.99996C1.33301 6.15901 2.82539 4.66663 4.66634 4.66663H5.99967M9.99967 11.3333H11.333C13.174 11.3333 14.6663 9.84091 14.6663 7.99996C14.6663 6.15901 13.174 4.66663 11.333 4.66663H9.99967"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66602 8L11.3327 8"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export { CopyIcon, CopyOutlinedIcon, CopyLinkIcon,CopyOutlineIcon };
