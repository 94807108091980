import React, { useEffect, useState } from "react";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  handleCompanySize,
  resetToInitialOnboarding,
} from "../../../Core/redux/slices/onboarding.slice";
import CurrentStep from "./CurrentStep";
import { Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import StepProgressBar from "./StepProgressBar";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomFrostyErrorMessage from "../../../Core/Common/CustomFrostyErrorMessage";
import oneToFifty from "../../../Core/LottieFilesV2/1-50.json";
// import oneTo500 from "../../../Core/LottieFilesV2/1-500.json";
// import oneMoreThan from "../../../Core/LottieFilesV2/1-more-than.json";
// import fiftyOne_1 from "../../../Core/LottieFilesV2/51-1.json";
import fiftyOne_100 from "../../../Core/LottieFilesV2/51-100.json"; //51-100
// import fiftyOne_more from "../../../Core/LottieFilesV2/51-more-than.json";
// import One01_1 from "../../../Core/LottieFilesV2/1-50.json";
// import One01_51 from "../../../Core/LottieFilesV2/1-50.json";
import oneHundred_500 from "../../../Core/LottieFilesV2/101-500.json";
import more500 from "../../../Core/LottieFilesV2/more-500.json";
// import more500 from "../../../Core/LottieFilesV2/1-50.json";

// import more500 from "../../../Core/LottieFilesV2/1-50.json";

// import more500 from "../../../Core/LottieFilesV2/1-50.json";

import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { CrossIconLarge } from "../../../Core/svgV2/CrossIcon";

const Content = ({ setSelectedSize }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companySize } = useAppSelector((state) => state.onboarding);
  const { tenant_id } = useAppSelector((state) => state.userDetails).user;

  const [error, setError] = useState(false);
  const [value, setValue] = useState(companySize);

  const formik = useFormik({
    initialValues: {
      companySize: "",
    },
    validationSchema: Yup.object({
      companySize: Yup.string()
        .required("Please select one option to continue")
        .typeError("Please select one option to continue"),
    }),
    onSubmit: (values) => {
      console.log(values, "values cm");
      if (value == "") {
        formik.setFieldError(
          "companySize",
          "Please select one option to continue"
        );
        console.log("fomik", error);
        setError(true);
      }
      console.log("comming here");
    },
  });

  const handleRadioButtonChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    setSelectedSize({ current: e.target.value });
  };
  React.useEffect(() => {
    formik.setFieldValue("companySize", companySize);
    if (value) {
      setError(false);
    }
  }, [value]);

  return (
    <div className="p-6 w-full h-screen bg-white">
      {tenant_id ? (
        <div
          className="ml-10 mt-10 flex items-center justify-center out-500-14 text-gray-500 gap-x-2  absolute z-[100]"
          role="button"
          onClick={() => {
            dispatch(resetToInitialOnboarding({}));
            navigate(-1);
          }}
        >
          <CrossIconLarge className="text-gray-500" /> Cancel
        </div>
      ) : (
        ""
      )}
      <div className="pl-14 w-full h-full bg-white flex justify-start items-center">
        <StepProgressBar
          currentStep={4}
          totalSteps={5}
          givenElementId="form4"
        />
        <div>
          <CurrentStep currentStep={4} totalStep={5} className="" id="form4" />
          <h1 className="out-500-20 text-gray-900 mb-2 mt-6">
            How many people work at your company?
          </h1>
          {!error ? (
            <div className="flex justify-start items-center text-show-animation gap-x-2 out-400-16 text-gray-500">
              <img
                src="/images/icons/frosty-icon.svg"
                className="min-h-[24px] min-w-[24px]"
                alt="frosty"
              />
              In other words, how many heads are solving the world’s problems?{" "}
            </div>
          ) : (
            <CustomFrostyErrorMessage text={formik.errors.companySize} />
          )}

          <div className="gap-x-3">
            <Radio.Group onChange={handleRadioButtonChange} value={value}>
              <Radio className="text-gray-700 out-500-14" value={1}>
                1 - 50
              </Radio>
              <Radio className="text-gray-700 out-500-14" value={2}>
                50 - 100
              </Radio>
              <Radio className="text-gray-700 out-500-14" value={3}>
                101 - 500
              </Radio>
              <Radio className="text-gray-700 out-500-14" value={4}>
                More than 500
              </Radio>
            </Radio.Group>
          </div>
          <div className="flex items-center gap-x-6 mt-6">
            <CustomButton
              className="bg-primary-600 text-white"
              onClick={() => {
                console.log(value, "companySize");
                if (value == "") {
                  formik.setFieldError(
                    "companySize",
                    "Please select one option to continue"
                  );
                  console.log("fomik", error);
                  setError(true);
                } else {
                  console.log("cm");
                  dispatch(handleCompanySize(value));
                  dispatch(changeStep(5));
                }
                //
              }}
              text="Continue"
              width="76px"
              height="30px"
            />
            <CustomButton
              className="bg-white border-gray-200 border-[1px] px-2.5 py-[5px]"
              onClick={() => dispatch(changeStep(3))}
              text="Back"
              width="51px"
              height="30px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Sidebar = ({ currentSelectedSize }) => {
  const [animationData, setAnimationData] = useState(oneToFifty);
  console.log(currentSelectedSize, "current");
  useEffect(() => {
    if (currentSelectedSize.current === 1) {
      setAnimationData(oneToFifty as any);
    }
    if (currentSelectedSize.current === 2) {
      setAnimationData(fiftyOne_100 as any);
    }
    if (currentSelectedSize.current === 3) {
      setAnimationData(oneHundred_500 as any);
    }
    if (currentSelectedSize.current === 4) {
      setAnimationData(more500 as any);
    }
  }, [currentSelectedSize]);

  return (
    <div className="w-full h-screen overflow-hidden  flex justify-center items-center bg-gray-50">
      {currentSelectedSize.current == 0 ? (
        <img src="/images/v2/dashboard/frostyOne.svg" />
      ) : (
        <Lottie animationData={animationData} loop={false} />
      )}
    </div>
  );
};

const MapSelection = () => {
  const [currentSelectedSize, setSelectedSize] = useState({
    prev: 0,
    current: 0,
  });
  return (
    <ProductOnboardingLayout
      content={<Content setSelectedSize={setSelectedSize} />}
      sidebar={<Sidebar currentSelectedSize={currentSelectedSize} />}
    />
  );
};

export default MapSelection;
