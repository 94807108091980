import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { getNotifications } from "../../Core/redux/api/notificationAPI";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../Core/utils/getUserName";
import dayjs from "dayjs";
import { client } from "../../Core/utils/axiosClient";
import ParseMentions from "../Conversations/ParseMentions";
import { useNavigate } from "react-router-dom";
import StatusBadge from "../Dashboard/Components/StatusBadge";
import { updateMyBoard } from "../../Core/redux/slices/myBoard.slice";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";

const NoticationWrapper = ({
  children = <></>,
  notification,
  midText,
  initial = null,
  title,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { newTimeRequestModal } = useAppSelector((state) => state.dashboard);
  const handleAddCollab = async ({ metaDetails }) => {
    const task = JSON.parse(metaDetails);
    dispatch(
      updateMyBoard({
        key: "collabRequestModal",
        value: {
          visible: true,
          taskId: task.taskId,
          taskName: task.taskName,
          sender: `${notification.firstname} ${notification.lastname}`,
        },
      })
    );
  };

  const handleClick = () => {
    console.log(notification.notificationType, notification, "comes here 2");
    const metaDetails = [40, 41, 42, 46].includes(+notification?.notificationType)
      ? notification.meta
      : JSON.parse(notification.meta);
    //when you are assigned a bug , task, subtask
    if ([9, 24, 2].includes(+notification?.notificationType)) {
      //http://localhost:3000/schedule/345/task/1055
      const url = `/schedule/${metaDetails.goal[0]?.projectId}/task/${metaDetails?.goal[0].id}`;
      console.log(url, metaDetails, "urllllll");

      // switch (+metaDetails.goalType) {
      //   case 1:
      //     dispatch(
      //       updateSchedule({
      //         key: "newTaskModal",
      //         value: {
      //           visible: true,
      //           isNewScope: false,
      //           scopeDescription: "",
      //         },
      //       })
      //     );
      //     dispatch(
      //       updateSchedule({ key: "selectedTask", value: metaDetails?.goal[0] })
      //     );
      //     break;
      //   case 2:
      //     dispatch(updateSchedule({ key: "newEventModal", value: true }));
      //     dispatch(
      //       updateSchedule({
      //         key: "selectedEvent",
      //         value: metaDetails?.goal[0],
      //       })
      //     );
      //     break;
      //   case 3:
      //     dispatch(updateSchedule({ key: "newBugModal", value: true }));
      //     dispatch(
      //       updateSchedule({ key: "selectedBug", value: metaDetails?.goal[0] })
      //     );
      //     break;
      //   case 4:
      //     dispatch(
      //       updateSchedule({
      //         key: "newSubTaskModal",
      //         value: {
      //           visible: true,
      //           editTask: true,
      //           subtask: {
      //             ...metaDetails?.goal[0],
      //             // sprintId: searchParams.get("selectedSprint"),
      //             // projectId: projectId,
      //             // phaseId: currentSelectedPhase.phaseId,
      //             names: [metaDetails?.goal[0].name],
      //             // isCompleted: 0,
      //             // statusId: subTask.statusId,
      //             // priorityId: subTask.priorityId,
      //             // goalType: subTask.goalType,
      //             // assigneeId: subTask.assigneeId,
      //             // reporterId: subTask.reporterId,
      //             // description: subTask.descripion,
      //             parentGoalId: metaDetails?.goal[0].parentGoalId,
      //             // goalId: subTask.id,
      //           },
      //         },
      //       })
      //     );
      //     break;
      //   default:
      //     break;
      // }
      navigate(url);
    } else if ([4, 5, 6].includes(+notification?.notificationType)) {
      navigate(`/dashboard`);
    } else if (
      [
        10, 13, 14, 15, 11, 16, 22, 7, 12, 14, 26, 27, 28, 29, 30, 31, 32, 46,
      ].includes(+notification.notificationType)
    ) {
      //redirecting event notifications to the url specified in click action
      const url = notification?.clickAction;

      navigate(url);
    } else if ([40].includes(+notification.notificationType)) {
      handleAddCollab({ metaDetails });
    } else if ([41, 42].includes(+notification.notificationType)) {
      navigate("/my-board");
    } else if (+notification.notificationType === 43) {
      // Dispatch action to reset newTimeRequestModal
      console.log("notification from calendar", notification);
      dispatch(
        updateDashboard({
          key: "newTimeRequestModal",
          value: {
            visible: true,
            eventId: metaDetails.proposedEventId,
            eventName: metaDetails.name,
            sender: notification.firstname,
            newStartTime: metaDetails.proposedStartTime,
            newEndTime: metaDetails.proposedEndTime,
            optionalNotes: metaDetails.optionalNotes,
            senderId: metaDetails.sender,
          },
        })
      );
    } else if (+notification.notificationType === 44) {
      navigate("/my-calendar");
    } else {
      navigate(notification.clickAction);
    }

    return;
  };
  return (
    <div
      className="w-full p-3 bg-white gap-x-1 hover:bg-gray-50  border-b border-gray-200 flex"
      role="button"
      onClick={handleClick}
    >
      <CustomAvatar
        size={24}
        title={getUsersName(notification)}
        src={notification.profilePicture}
        badge
        userId={notification.senderId}
        whiteText
        color={notification.profilePictureColorCode}
      />

      <div className="flex-1">
        <div className="flex justify-between items-center ">
          <p className="pr-3">
            <span className="out-500-14 text-gray-700">
              {initial || getUsersName(notification)}
            </span>
            <span className="out-300-14 text-gray-500"> {midText} </span>
            <span className="out-500-14 text-gray-700">{title}</span>
          </p>
          <p className="out-500-10 text-gray-500 whitespace-nowrap">
            {notification.createdAt
              ? dayjs(notification.createdAt)
                  // .utc(true)
                  .local()
                  .format("MMMM DD hh:mma")
              : "--"}
          </p>
        </div>

        {children}
      </div>
    </div>
  );
};

const NotificationPopup = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { notificationList } = useAppSelector((state) => state.notification);
  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const readNotification = async () => {
    await client.post(`/notifications/read?userId=${user.id}`);
    dispatch(getNotifications({ userId: user.id }));
  };

  useEffect(() => {
    readNotification();
    dispatch(getNotifications({ userId: user.id }));
  }, []);

  const renderNotification = (notification) => {
    const metaData = [40, 41, 42, 43].includes(+notification?.notificationType)
      ? notification.meta
      : notification.meta
      ? JSON.parse(notification.meta)
      : "";

    switch (notification?.notificationType) {
      case "1":
        // TComment
        if (["task", "bug"].includes(metaData.docType)) {
          return (
            <NoticationWrapper
              notification={notification}
              midText="commented on"
              title={metaData.docType}
            >
              <>
                <div className="out-300-14 text-gray-700 mt-1">
                  <ParseMentions
                    value={metaData.message}
                    textClassName="max-w-[250px] out-300-14"
                  />
                </div>
                <div className="out-300-12 text-gray-500 mt-1">
                  {metaData.name}
                </div>
              </>
            </NoticationWrapper>
          );
        }
        return (
          <NoticationWrapper
            notification={notification}
            midText="commented on"
            title={metaData.name}
          >
            <div className="out-300-14 text-gray-700 mt-1">
              {" "}
              <ParseMentions
                value={metaData.message}
                textClassName="max-w-[250px] out-300-14"
              />
            </div>
          </NoticationWrapper>
        );
      case "2":
        return "2";
      case "3":
        return "3";
      case "4":
        return "4";
      case "5":
        return "5";
      case "6":
        // new Evemt
        return <></>;

      case "7":
        // NEW_SCOPE
        return null;

      case "8":
        return "8";
      case "9":
        // Task Assigneed
        return (
          <NoticationWrapper
            notification={notification}
            midText="assigned you a"
            title="bug"
          >
            <div className="out-300-12 text-gray-500 mt-1">{metaData.name}</div>
          </NoticationWrapper>
        );
      case "10":
        // SPRINT START
        return (
          <NoticationWrapper
            notification={notification}
            midText="started"
            title={metaData[0].name}
          >
            <div className="out-300-12 text-gray-500 mt-1">
              {metaData[0].startDate
                ? dayjs(metaData[0].startDate).format("MMMM DD YYYY")
                : "--"}
              -{" "}
              {metaData[0].endDate
                ? dayjs(metaData[0].endDate).format("MMMM DD YYYY")
                : "--"}{" "}
              ({dayjs(metaData[0].endDate).diff(metaData[0].startDate, "days")}{" "}
              days )
            </div>
          </NoticationWrapper>
        );

      case "11":
        // START PHASE

        return (
          <NoticationWrapper
            notification={notification}
            midText="started"
            title={metaData.phaseName.phases}
          >
            <div className="out-300-12 text-gray-500 mt-1">
              {metaData[0].startDate
                ? dayjs(metaData[0].startDate).format("MMMM DD YYYY")
                : "--"}
              -{" "}
              {metaData[0].endDate
                ? dayjs(metaData[0].endDate).format("MMMM DD YYYY")
                : "--"}{" "}
              ({dayjs(metaData[0].endDate).diff(metaData[0].startDate, "days")}{" "}
              days )
            </div>
          </NoticationWrapper>
        );
      case "12":
        // Add to a project

        return (
          <NoticationWrapper
            notification={notification}
            midText="is inviting you to"
            title={notification.projectTitle}
          />
        );
      case "13":
        // ADDED YOU IN THREAD
        return (
          <NoticationWrapper
            notification={notification}
            midText="added you to"
            title={metaData.threadName || metaData.threadname}
          >
            <></>
          </NoticationWrapper>
        );
      case "14":
        if (metaData.request) {
          return (
            <NoticationWrapper
              notification={notification}
              midText="mentioned you in a"
              title={"request"}
            >
              <ParseMentions value={metaData.message} />
            </NoticationWrapper>
          );
        }
        // New Message in froup
        if (dayjs().diff(metaData?.createdAt, "h") <= 24) {
          return <></>;
        } else {
          if (metaData.isPrivate) {
            return (
              <NoticationWrapper
                notification={notification}
                midText="sent you a"
                title={"message"}
              >
                <></>
              </NoticationWrapper>
            );
          }
          return (
            <NoticationWrapper
              notification={notification}
              midText="messaged you in"
              title={metaData.threadName}
            >
              <></>
            </NoticationWrapper>
          );
        }
      case "15":
        return "15";
      case "16": {
        const render = () => {
          const icon =
            metaData.docType === "folder"
              ? "/images/icons/folder-fill-vault.svg"
              : metaData.docType === "link"
              ? "/images/v2/library/link-icon.svg"
              : metaData.docType === "file"
              ? "/images/v2/library/default-file-icon.svg"
              : "/images/v2/library/default-file-icon.svg";

          console.log(metaData, "metaaaaa");
          return (
            <div className="flex gap-x-3 py-2 px-3 mt-2 w-max rounded border border-gray-200">
              <img className="" src={icon} />{" "}
              <div className="out-500-14 text-gray-700">
                {metaData.name || metaData.docName}
              </div>
            </div>
          );
        };
        return (
          <NoticationWrapper
            notification={notification}
            midText={`shared a ${metaData.docType} with `}
            title={"you"}
          >
            {render()}
          </NoticationWrapper>
        );
      }
      case "17":
        return "17";
      case "18":
        return "18";
      case "19": {
        const render = () => {
          const icon =
            metaData.docType === "folder"
              ? "/images/icons/folder-fill-vault.svg"
              : metaData.docType === "link"
              ? "/images/v2/library/link-icon.svg"
              : metaData.docType === "file"
              ? "/images/v2/library/default-file-icon.svg"
              : "/images/v2/library/default-file-icon.svg";

          return (
            <div className="flex gap-x-3 py-2 px-3 mt-2 w-max rounded border border-gray-200">
              <img className="" src={icon} />{" "}
              <div className="out-500-14 text-gray-700">{metaData.docName}</div>
            </div>
          );
        };
        return (
          <NoticationWrapper
            notification={notification}
            midText="wants access to"
            title={metaData.docType}
          >
            <>
              {render()}

              {/* <div className="flex  items-center gap-x-3 mt-1">
                <div className="text-primary-600 out-500-14 cursor-pointer">
                  Share
                </div>
                <div className="text-gray-500 out-500-14 cursor-pointer">
                  Deny
                </div>
              </div> */}
            </>
          </NoticationWrapper>
        );
      }
      case "20":
        return "20";
      case "21":
        return "21";
      case "22":
        // Assigneed a role
        return (
          <NoticationWrapper
            notification={notification}
            midText="has assigned a new role to you in"
            title={notification.projectTitle}
          >
            <div className="out-300-12 text-gray-500 mt-1">
              {metaData.roleName}
            </div>
          </NoticationWrapper>
        );
      case "23":
        return "23";
      case "24":
        // Task Assigneed
        return (
          <NoticationWrapper
            notification={notification}
            midText="assigned you a"
            title="task"
          >
            <div className="out-300-12 text-gray-500 mt-1">{metaData.name}</div>
          </NoticationWrapper>
        );
      case "25":
        // 'Unread Message Reminders'
        return (
          <NoticationWrapper
            notification={{
              firstname: "Waddle",
              profilePicture: "/images/v2/common/frosty-icon.svg",
              createdAt: notification.createdAt,
            }}
            midText="unread messages"
            title=""
            initial={`You have ${metaData.count}`}
          >
            <div className="out-300-12 text-gray-500 mt-1">{metaData.name}</div>
          </NoticationWrapper>
        );
      case "26":
        // MAKE_REPORTER_TO_A_TASK
        return (
          <NoticationWrapper
            notification={notification}
            midText="made you reporter to a"
            title="bug"
          >
            <div className="out-300-12 text-gray-500 mt-1">{metaData.name}</div>
          </NoticationWrapper>
        );

      case "27":
        // MAKE_REPORTER_TO_A_TASK
        return (
          <NoticationWrapper
            notification={notification}
            midText="made you reporter to a"
            title="task"
          >
            <div className="out-300-12 text-gray-500 mt-1">{metaData.name}</div>
          </NoticationWrapper>
        );
      case "28":
        // MAKE_REPORTER_TO_A_TASK
        return "28";
      case "29":
        // DELETE Project

        return (
          <NoticationWrapper
            notification={notification}
            midText="deleted project"
            title={notification.projectTitle}
          ></NoticationWrapper>
        );
      case "30":
        // added as approver

        return (
          <NoticationWrapper
            notification={notification}
            midText="added you as an"
            title={"approver"}
          >
            <div className="out-300-12 text-gray-500 mt-1">{metaData.name}</div>
          </NoticationWrapper>
        );
      case "31":
        // waddle it update

        return (
          <NoticationWrapper
            notification={{
              firstname: metaData.name,
              profilePicture: "/images/v2/common/frosty-icon.svg",
              createdAt: notification.createdAt,
              clickAction: notification.clickAction,
            }}
            midText=""
            title={""}
          >
            {metaData.type === "un_waddled_by" ? (
              <div className="out-300-12 text-gray-500 mt-1">
                Un-waddled by {getUsersName(notification)}
              </div>
            ) : (
              <div className="px-1.5 py-[2px] bg-primary-600 rounded-full out-300-12 text-white w-max mt-1.5">
                Waddled by everyone 🎉
              </div>
            )}
          </NoticationWrapper>
        );
      case "32": {
        const oldAssignee = tenantMembers.find(
          (member) => member.userId == metaData.meta.oldAssigneeId
        );
        const newAssignee = tenantMembers.find(
          (member) => member.userId == metaData.meta.newAssigneeId
        );
        return (
          <NoticationWrapper
            notification={notification}
            midText="changed assignee in a"
            title={metaData.goalType === "1" ? "task" : "bug"}
          >
            <>
              <div className="flex gap-x-1.5 mt-1">
                {oldAssignee && (
                  <>
                    <div className="px-1.5 py-[2px] bg-gray-50 flex gap-x-1.5 rounded-full w-max">
                      <CustomAvatar
                        src={oldAssignee.profilePicture}
                        title={getUsersName(oldAssignee)}
                        size={16}
                        fontSize={"8px"}
                        whiteText
                        color={oldAssignee.profilePictureColorCode}
                      />
                      <div className="out-300-12 text-gray-700  line-through	">
                        {getUsersName(oldAssignee)}
                      </div>
                    </div>
                    <div className="out-300-16 text-gray-500">to</div>
                  </>
                )}
                {newAssignee && (
                  <div className="px-1.5 py-[2px] bg-gray-100 flex gap-x-1.5 rounded-full w-max">
                    <CustomAvatar
                      src={newAssignee.profilePicture}
                      title={getUsersName(newAssignee)}
                      size={16}
                      fontSize={"8px"}
                      color={newAssignee.profilePictureColorCode}
                      whiteText
                    />
                    <div className="out-500-12 text-gray-700 ">
                      {getUsersName(newAssignee)}
                    </div>
                  </div>
                )}
              </div>
              <div className="out-300-12 text-gray-500 mt-1">
                {metaData.name}
              </div>
            </>
          </NoticationWrapper>
        );
      }
      case "33": {
        console.log(+metaData.oldStatusId);
        //todo implement case 33
        return (
          <NoticationWrapper
            notification={notification}
            midText="changed status in a"
            title={metaData.goalType === "1" ? "task" : "bug"}
          >
            <>
              <div className="flex gap-x-1.5 mt-1">
                <>
                  <StatusBadge
                    statusType={+metaData.meta.oldStatusId}
                    strikethrough
                    done
                  />
                  <div className="out-300-16 text-gray-500">to</div>
                </>

                <StatusBadge statusType={+metaData.meta.newStatusId} done />
              </div>
              <div className="out-300-12 text-gray-500 mt-1">
                {metaData.name}
              </div>
            </>
          </NoticationWrapper>
        );
      }
      case "40":
        return (
          <NoticationWrapper
            notification={{
              firstname: notification.firstname,
              lastname: notification.lastname,
              profilePicture: "/images/v2/common/frosty-icon.svg",
              createdAt: notification.createdAt,
              notificationType: notification.notificationType,
              clickAction: notification.clickAction,
              meta: metaData,
            }}
            midText="is inviting you to collaborate on a task"
            title={""}
          >
            <div className="px-1.5 py-[2px] bg-gray-100 flex gap-x-1.5 rounded-full w-max"></div>
          </NoticationWrapper>
        );
      case "41":
        return (
          <NoticationWrapper
            notification={{
              firstname: notification.firstname,
              lastname: notification.lastname,
              profilePicture: "/images/v2/common/frosty-icon.svg",
              createdAt: notification.createdAt,
              notificationType: notification.notificationType,
              clickAction: notification.clickAction,
              meta: metaData,
            }}
            midText="declined your collaboration request"
            title={""}
          >
            <div className="px-1.5 py-[2px] bg-gray-100 flex gap-x-1.5 rounded-full w-max"></div>
          </NoticationWrapper>
        );
      case "42":
        return (
          <NoticationWrapper
            notification={{
              firstname: notification.firstname,
              lastname: notification.lastname,
              profilePicture: "/images/v2/common/frosty-icon.svg",
              createdAt: notification.createdAt,
              notificationType: notification.notificationType,
              clickAction: notification.clickAction,
              meta: metaData,
            }}
            midText="accepted your collaboration request"
            title={""}
          >
            <div className="px-1.5 py-[2px] bg-gray-100 flex gap-x-1.5 rounded-full w-max"></div>
          </NoticationWrapper>
        );

      case "43":
        return (
          <NoticationWrapper
            notification={{
              firstname: notification.firstname,
              lastname: notification.lastname,
              profilePicture: "/images/v2/common/frosty-icon.svg",
              createdAt: notification.createdAt,
              notificationType: notification.notificationType,
              clickAction: notification.clickAction,
              meta: metaData,
            }}
            midText="proposed a new time"
            title={""}
          >
            <div className="px-1.5 py-[2px] bg-gray-100 flex gap-x-1.5 rounded-full w-max"></div>
          </NoticationWrapper>
        );

      case "44":
        return (
          <NoticationWrapper
            notification={{
              firstname: notification.firstname,
              lastname: notification.lastname,
              profilePicture: "/images/v2/common/frosty-icon.svg",
              createdAt: notification.createdAt,
              notificationType: notification.notificationType,
              clickAction: notification.clickAction,
              meta: metaData,
            }}
            midText="Rejected Proposed Time"
            title={""}
          >
            <div className="px-1.5 py-[2px] bg-gray-100 flex gap-x-1.5 rounded-full w-max"></div>
          </NoticationWrapper>
        );
      case "45":
        return (
          <NoticationWrapper
            notification={{
              firstname: notification.firstname,
              lastname: notification.lastname,
              profilePicture: "/images/v2/common/frosty-icon.svg",
              createdAt: notification.createdAt,
              notificationType: notification.notificationType,
              clickAction: notification.clickAction,
              meta: metaData,
            }}
            midText="Created a New Event"
            title={""}
          >
            <div className="px-1.5 py-[2px] bg-gray-100 flex gap-x-1.5 rounded-full w-max"></div>
          </NoticationWrapper>
        );
        case "46":
          return (
            <NoticationWrapper
              notification={{
                firstname: notification.firstname,
                lastname: notification.lastname,
                profilePicture: "/images/v2/common/frosty-icon.svg",
                createdAt: notification.createdAt,
                notificationType: notification.notificationType,
                clickAction: notification.clickAction,
                meta: metaData,
              }}
              midText="has added you to as a collaborate on a task"
              title={""}
            >
              <div className="px-1.5 py-[2px] bg-gray-100 flex gap-x-1.5 rounded-full w-max"></div>
            </NoticationWrapper>
          );

      default:
        return null;
    }
  };

  const handleClearAll = async () => {
    try {
      await client.delete(`/notifications`);
      dispatch(getNotifications({ userId: user.id }));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="rounded-xl shadow-xl bg-white border p-3  border-gray-100">
      <div className="flex justify-between items-center p-3">
        <div className="out-500-16 text-black">Notifications</div>
        <div
          role="button"
          onClick={() => handleClearAll()}
          className="out-500-14 text-primary-700"
        >
          Clear All
        </div>
      </div>

      <div className="w-[512px] max-h-[70vh] min-h-[70vh]  overflow-y-scroll">
        {notificationList.length ? (
          notificationList.map((notification) =>
            renderNotification(notification)
          )
        ) : (
          <div className="flex flex-col justify-center items-center w-[512px] max-h-[70vh] min-h-[70vh]">
            <img className="" src="/images/v2/conversations/empty.svg" />
            <div className="out-500-16 text-gray-800 text-center">
              No New Notifications
            </div>
            <div className="out-300-14 text-gray-500 text-center">
              Stay tuned! We&apos;ll notify you as soon as there&apos;s
              something new.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationPopup;
