import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import CustomOnboardingInput from "../../Core/Common/CustomOnboardingInput";

import { ProductOnboardingLayout } from "../../Core/Layout/OnboardingLayouts";
import { client } from "../../Core/utils/axiosClient";
import { setSuccess } from "../../Core/redux/slices/global.slice";
import { useNavigate } from "react-router-dom";

import { CrossIcon, CrossIconLarge } from "../../Core/svgV2/CrossIcon";
import CustomButton from "../../Core/CommonV2/CustomButton";
import CustomModal from "../../Core/CommonV2/CustomModal";
import { EditTwoIcon } from "../../Core/svgV2/Edit02Icon";
import { updateSettings } from "../../Core/redux/slices/settings.slice";
import GuesttInteractionSettingsModal from "../../ModulesV2/Settings/Modals/GuestInteractionSettingsModal";
import { PlusIconSecondary } from "../../Core/svgV2/PlusIcon";

const Content = () => {
  const [memberList, setMemberList] = useState([
    {
      id: 1,
      email: "",
      roleId: 0,
    },
  ]);
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails, tenantMembers } = useAppSelector(
    (state) => state.tenant
  );
  const { newGuestInteractionSettings, guestInteractionSettingsModal } =
    useAppSelector((state) => state.settings);
  const navigate = useNavigate();
  useEffect(
    () => console.log("new render"),
    [guestInteractionSettingsModal.visible]
  );
  const getAllowedUsersName = (userEmail) => {
    const emptyListText =
      "Select people in your workspace, this user can interact with.";
    const selectedUsers = newGuestInteractionSettings?.find((allowedUser) => {
      allowedUser.email === userEmail;
    })?.selectedUsers;
    let allowedUserName;
    const allowedUserNameList = [];
    selectedUsers?.map((allowedUserId) => {
      const allowedUser = tenantMembers.find(
        (tenantMember) => +tenantMember.userId === +allowedUserId
      );
      allowedUserName = allowedUser.firstname + allowedUser.lastname;
      allowedUserNameList.push(allowedUserName);
    });
    if (allowedUserNameList.length) {
      return allowedUserNameList;
    } else return emptyListText;
  };

  const defineRole = (roleId, id) => {
    if (memberList.length <= id) {
      setMemberList((old) => [
        ...old,
        {
          id: old.length + 1,
          email: "",
          roleId: 0,
        },
      ]);
    }

    const oldMemberList = (oldList) => {
      console.log(oldList, "what is this");
      const list = oldList.map((m) => {
        if (m.id === id) {
          return {
            ...m,
            roleId: roleId,
          };
        }
        return m;
      });
      return list;
    };
    setMemberList((old) => [...oldMemberList(old)]);
  };

  const setEmail = (email, id) => {
    const oldMemberList = memberList.map((m) => {
      if (m.id === id) {
        return {
          ...m,
          email: email,
        };
      }
      return m;
    });

    setMemberList(oldMemberList);
  };

  const clearData = (id) => {
    const clearList = memberList.map((m) => {
      if (m.id === id) {
        return {
          ...m,
          email: "",
          roleId: 0,
        };
      }
      return m;
    });

    setMemberList(clearList);
  };

  const finalList = memberList.filter(
    ({ email, roleId }) => email !== "" && roleId !== 0
  );
  const dispatch = useAppDispatch();

  const onInvite = async () => {
    const users = memberList.filter(({ roleId }) => roleId !== 0);

    await client
      .post("settings/add-members-to-tenant", {
        users,
        createdById: user.id,
        newGuestInteractionSettings,
      })
      .then((res) => {
        console.log(res);
        dispatch(setSuccess({ message: "Users added successfully" }));
        res.data.usersTenant?.map(async (user) => {
          const allowedUsersIdArray = newGuestInteractionSettings?.length
            ? newGuestInteractionSettings?.find(
                (userArray) => userArray.email === user.email
              )?.selectedUsers
            : [];
          console.log(
            user,
            allowedUsersIdArray,
            "check for role id and allowedUsersIdArray"
          );
          if (+user.roleId === 4 || +user.roleid === 4 || +user.role_id === 4) {
            await client
              .post("settings/add-guest-interaction-allowed-members", {
                userId: user.user_id,
                tenantId: tenantDetails.tenantId,
                allowedUsersIdArray: allowedUsersIdArray?.length
                  ? allowedUsersIdArray
                  : [],
              })
              .then(() =>
                dispatch(
                  updateSettings({
                    key: "newGuestInteractionSettings",
                    value: [],
                  })
                )
              );
          }
        });

        navigate(-1);
      });
  };

  const [cancelModal, setCancelModal] = useState(false);
  const getRoleName = (role) => {
    switch (+role) {
      case 1:
        return "Owner";
      case 2:
        return "Admin";
      case 3:
        return "Basic User";
      case 4:
        return "Guest";
      default:
        return "Select Role";
    }
  };

  return (
    <div className="p-6 w-full h-screen bg-white">
      <div className="pl-14 w-full h-fit bg-white flex justify-start mt-[107px]">
        <div>
          {cancelModal && (
            <CustomModal
              title={<p className="out-500-16 text-gray-900">Cancel </p>}
              width={"524px"}
              visible={cancelModal}
              body={
                <div className="p-4">
                  <div className="flex justify-between">
                    <p className="text-black out-500-14">Cancel</p>
                    <button onClick={() => setCancelModal(false)}>
                      <CrossIcon className="text-gray-700" />
                    </button>
                  </div>
                  <div className="out-400-14 text-gray-900 pt-6 pb-2 ">
                    Are you sure you want to add members at a later stage?
                  </div>
                  <div className="flex justify-between pt-8 pb-4">
                    <CustomButton
                      text="Cancel"
                      onClick={() => setCancelModal(false)}
                      className="border border-gray-200 text-black bg-white  "
                      width="49%"
                    />
                    <CustomButton
                      text="Yes, Later"
                      onClick={() => {
                        dispatch(
                          updateSettings({
                            key: "newGuestInteractionSettings",
                            value: [],
                          })
                        );
                        dispatch(
                          updateSettings({
                            key: "guestInteractionSettingsModal",
                            value: [],
                          })
                        );
                        navigate(-1);
                      }}
                      className=" text-white  bg-primary-600 "
                      width="49%"
                    />
                  </div>
                </div>
              }
              onCancel={() => setCancelModal(false)}
            />
          )}
          <div className="absolute top-24 left-20">
            <div
              className=" flex items-center justify-center out-500-14 text-gray-500 gap-x-2   z-[100]"
              role="button"
              onClick={() => {
                setCancelModal(true);
              }}
            >
              <CrossIconLarge className="text-gray-500" /> Cancel
            </div>
          </div>
          <h1 className="out-500-20 text-gray-900 mb-2 mt-6">
            Create your waddle
          </h1>
          <div className="flex justify-start items-center text-show-animation gap-x-2 out-400-16 text-gray-500">
            <img
              src="/images/icons/frosty-icon.svg"
              className="min-h-[26px] min-w-[26px]"
              alt="frosty"
            />
            Start by adding team members. You can edit these later.
          </div>

          <div className="flex flex-col gap-y-6">
            {memberList.map((m, index) => (
              <div key={index} className="flex flex-col gap-2">
                <div className="flex items-center gap-x-2  " key={index}>
                  <CustomOnboardingInput
                    className="w-[500px]"
                    placeholder="name@example.com"
                    value={m.email}
                    onChange={(e, value) => setEmail(value, m.id)}
                    customEnd={
                      <div>
                        <Dropdown
                          overlay={
                            <Menu className="w-[367px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                              <Menu.Item className="py-2.5 px-4">
                                <div
                                  className="gap-y-1 pt-1.5"
                                  onClick={() => defineRole(1, m.id)}
                                >
                                  <p className="out-500-14 whitespace-nowrap text-gray-900">
                                    Owner
                                  </p>
                                  <p className="out-400-12 text-gray-500 whitespace-nowrap">
                                    Can manage all admins, users, product
                                    settings and bills
                                  </p>
                                </div>
                              </Menu.Item>
                              <Menu.Item
                                className="py-2.5 px-4"
                                onClick={() => defineRole(2, m.id)}
                              >
                                <div className="gap-y-1">
                                  <p className="out-500-14 whitespace-nowrap text-gray-900">
                                    Admin
                                  </p>
                                  <p className="out-400-12 text-gray-500 whitespace-nowrap">
                                    Has permissions for all administrative
                                    features
                                  </p>
                                </div>
                              </Menu.Item>
                              <Menu.Item
                                className="py-2.5 px-4"
                                onClick={() => defineRole(3, m.id)}
                              >
                                <div className="gap-y-1">
                                  <p className="out-500-14 whitespace-nowrap text-gray-900">
                                    Basic user
                                  </p>
                                  <p className="out-400-12 text-gray-500 whitespace-nowrap">
                                    Do not have permissions for administrative
                                    features
                                  </p>
                                </div>
                              </Menu.Item>
                              <Menu.Item
                                className="py-2.5 px-4"
                                onClick={() => {
                                  dispatch(
                                    updateSettings({
                                      key: "guestInteractionSettingsModal",
                                      value: {
                                        visible: true,
                                        email: m.email,
                                        userId: "",
                                      },
                                    })
                                  );
                                  defineRole(4, m.id);
                                }}
                              >
                                <div className="gap-y-1">
                                  <p className="out-500-14 whitespace-nowrap text-gray-900">
                                    Guest
                                  </p>
                                  <p className="out-400-12 text-gray-500 ">
                                    Has access to their own projects and have
                                    limited interaction with specific people
                                    within the workspace
                                  </p>
                                </div>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <div className="flex items-center cursor-pointer gap-x-3 mr-3">
                            <p className="out-400-14 text-gray-500 whitespace-nowrap">
                              {getRoleName(m.roleId)}
                            </p>
                            <img
                              src="/images/icons/chevron-down-gray.svg"
                              className="w-4 h-4"
                            />
                          </div>
                        </Dropdown>
                      </div>
                    }
                  />
                  {m.email || m.roleId ? (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        clearData(m.id);
                      }}
                    >
                      <img
                        src="/images/icons/sector-icons/input-cross.svg"
                        alt=""
                        className="w-4 h-4 ml-4"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* //guest role info */}
                {m.email && m.roleId === 4 && (
                  <div className="w-[556px] ml-[-8px] bg-primary-50 p-2 rounded-[8px] flex justify-between gap-2">
                    <div className="flex flex-col gap-2 ">
                      <div className="out-500-14 leading-5 text-black">
                        Can interact with
                      </div>
                      <div className="out-300-12 text-gray-500">
                        {getAllowedUsersName(m.email)}
                      </div>
                    </div>
                    <div>
                      {newGuestInteractionSettings?.find(
                        (u) => u.email === m.email
                      ) ? (
                        <EditTwoIcon
                          className="text-gray-500"
                          onClick={() =>
                            dispatch(
                              updateSettings({
                                key: "guestInteractionSettingsModal",
                                value: {
                                  visible: true,
                                  email: m.email,
                                  userId: "",
                                },
                              })
                            )
                          }
                        />
                      ) : (
                        <PlusIconSecondary
                          className="text-gray-700"
                          onClick={() =>
                            dispatch(
                              updateSettings({
                                key: "guestInteractionSettingsModal",
                                value: {
                                  visible: true,
                                  email: m.email,
                                  userId: "",
                                },
                              })
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex items-center gap-x-6 mt-6">
            <CustomButton
              className="bg-primary-600 text-white"
              onClick={() => {
                onInvite();
              }}
              text="Add"
              width="92px"
            />
            {/* <CustomButton
              className="bg-white border-gray-200 border-[1px]"
              onClick={() => navigate(-1)}
              text="Back"
              width="68px"
            /> */}
          </div>
        </div>
      </div>
      <GuesttInteractionSettingsModal
        onClose={() => {
          dispatch(
            updateSettings({
              key: "guestInteractionSettingsModal",
              value: {
                visible: false,
                email: "",
                userId: "",
              },
            })
          );
        }}
      />
    </div>
  );
};

const Sidebar = () => {
  return (
    <div className="w-full h-screen overflow-hidden  flex flex-col justify-center items-center bg-gradient-to-b from-[#E6F5F8] to-[#D7EEF4]">
      <img
        src="/images/icons/company-size-frosty.svg"
        className="w-[188px] h-[277px]"
      />
    </div>
  );
};

const InviteMembers = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default InviteMembers;
