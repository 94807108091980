import React, { useState } from "react";
import ScheduleRouteSelector from "../Components/ScheduleRouteSelector";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { Divider, Dropdown } from "antd";

import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import UserQuickFilters from "../Components/UserQuickFilters";
import NewLabelModal from "../Modals/NewLabelModal";
import NewReleaseVersionModal from "../Modals/NewReleaseVersionModal";
import ManageLabelsModal from "../Modals/ManageLabelsModal";
import DeleteLabelModal from "../Modals/DeleteLabelModal";
import CurrentActivePhase from "../Components/CurrentActivePhase";
import MyTaskFilterButton from "../Components/MyTaskFilterButton";
import { useParams } from "react-router-dom";

const NewButton = ({ setNewLabelModal, setNewReleaseVersionModal }) => {
  const [toggleNewButton, setToggleNewButton] = useState(false);
  const groupItems = [
    {
      key: "1",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">New Label</div>
      ),
      icon: <img src="/images/v2/schedule/Category.svg" />,
    },

    {
      key: "2",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">
          Release Version
        </div>
      ),
      icon: <img src="/images/v2/schedule/Dependancy.svg" />,
    },
  ];

  const onClick = ({ key }) => {
    switch (key) {
      case "1":
        setNewLabelModal(true);
        setToggleNewButton(false);
        break;
      case "2":
        setNewReleaseVersionModal(true);
        setToggleNewButton(false);
        break;
      default:
        console.log("item others");
        break;
    }
  };
  return (
    <Dropdown
      onOpenChange={(e) => setToggleNewButton(e)}
      menu={{ items: groupItems, onClick }}
      trigger={["click"]}
      dropdownRender={(origin) => {
        return <div className="w-[200px]">{origin}</div>;
      }}
    >
      <div
        id="schedule-tour-planner-new-button"
        className="flex items-center justify-center gap-x-2 bg-primary-600 w-[76px] h-[30px] rounded cursor-pointer"
      >
        <p className="out-500-14 leading-5 text-white">New</p>
        <ChevronIcon
          className={`text-white mt-0.5 ${toggleNewButton ? "rotate-180" : ""}`}
        />
      </div>
    </Dropdown>
  );
};

const PlannerMenu = ({ search }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const [newLabelModal, setNewLabelModal] = useState(false);
  const [newReleaseVersionModal, setNewReleaseVersionModal] = useState(false);

  const { filterGoalParameter } = useAppSelector((state) => state.schedule);
  const handleSearch = (e) => {
    const inputText = e.toLowerCase();

    dispatch(
      updateSchedule({
        key: "filterGoalParameter",
        value: {
          ...filterGoalParameter,
          searchValue: {
            searchValue: inputText,
            projectId: projectId,
            module: "planner",
          },
        },
      })
    );
  };

  return (
    <div className="py-3 px-6 border-b border-gray-200">
      <div className="flex justify-between">
        <div>
          <div className="out-500-14 flex items-center gap-x-2 text-black">
            Backlog
          </div>
          <p className="out-300-14 text-gray-500">
            Plan and prioritise your tasks in advance before moving them to a
            future sprint.
          </p>
        </div>
        <div>
          <ScheduleRouteSelector current="backlog" />
        </div>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <CustomSearchBox
            placeholder="Search in Backlog"
            style={{
              width: "226px",
            }}
            search={search}
            setSearch={(e) => {
              // setSearch(e);
              handleSearch(e);
            }}
          />
        </div>
        <div className="flex items-center">
          <MyTaskFilterButton />
          <Divider type="vertical" className="h-[30px]" />
          <UserQuickFilters />
        </div>
      </div>
    </div>
  );
};

export default PlannerMenu;
