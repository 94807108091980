import React, { useEffect, useState } from "react";
import LabelCard from "./LabelCard";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import CustomButton from "../../Core/CommonV2/CustomButton";
import { updateSchedule } from "../../Core/redux/slices/schedule.slice";
import { PlusIcon, PlusIconSecondary } from "../../Core/svgV2/PlusIcon";
import { useParams } from "react-router-dom";
import { getLabels } from "../../Core/redux/api/scheduleAPI";

const LabelInfo = ({ projectDetails }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const { labels } = useAppSelector((state) => state.schedule);
  useEffect(() => {
    dispatch(getLabels({ projectId, isMyBoard: false }));
  }, [projectId]);

  return (
    <div className="w-[60%]   pt-6   ">
      <div className=" flex flex-col w-full h-full ">
        <div className="h-6 out-500-16 pl-6 w-full ">Labels </div>
        <div className="flex flex-col  max-h-[400px] min-h-[400px] overflow-y-scroll mt-6 w-[95%] ml-4 justify-between gap-y-[22px] ">
          {labels?.filter(
            (label) => label.projectId === projectDetails.projectDetails.id
          ).length > 0 ? (
            <>
              {labels
                ?.filter(
                  (label) =>
                    label.projectId === projectDetails.projectDetails.id
                )
                .map((label) => (
                  <LabelCard label={label} key={label.id} />
                ))}
            </>
          ) : (
            <div className="flex flex-col justify-center items-center gap-y-4 pt-[69px]">
              <img src="/images/v2/dashboard/emptyActionCenter.svg" />
              <div className="flex-col flex gap-y-1.5 justify-center  w-full">
                <p className="out-300-14 text-gray-500 flex justify-center w-full">
                  Add a label to view here
                </p>
                <div className="flex  justify-center w-full">
                  <CustomButton
                    text="Add Label"
                    className=" text-primary-600 border border-primary-600 out-500-14 "
                    icon={<PlusIconSecondary />}
                    height="28px"
                    iconAlignment="right"
                    width="111px"
                    onClick={() => {
                      dispatch(
                        updateSchedule({ key: "newLabelModal", value: true })
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabelInfo;
