import React from "react";
import Icon from "@ant-design/icons";

const ReleaseNotesSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.667 8L8.001 5.333m0 0L5.334 8m2.667-2.667v6.134c0 .927 0 1.39.367 1.91.244.344.946.77 1.364.827.63.085.87-.04 1.348-.29a6.667 6.667 0 10-6.412-.14"
    ></path>
  </svg>
);

const ReleaseNotesIcon = (props) => {
  return <Icon component={ReleaseNotesSvg} {...props} />;
};

export { ReleaseNotesIcon };
