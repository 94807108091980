import React from "react";
import Icon from "@ant-design/icons";

const TaskSvg = ({ size = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#F9FBFE"
      stroke="#DDE5ED"
      d="M14.7 8c0 3.677-3.313 6.7-6.917 6.7C4.217 14.7 1.3 11.717 1.3 8c0-3.675 3.313-6.7 6.918-6.7 3.565 0 6.482 2.986 6.482 6.7z"
    ></path>
    <path
      stroke="#144EA6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M4.174 8.184l2.483 2.228a.5.5 0 00.69-.02l4.532-4.582"
    ></path>
  </svg>
);

const TaskIcon = (props) => <Icon component={TaskSvg} {...props} />;

export default TaskIcon;
