import { Dropdown } from "antd";
import React, { useState } from "react";
import { BellFilledIcon, BellOutlinedIcon } from "../../Core/svgV2/BellIcon";

import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { PinnedIcon, PinnedOutlinedIcon } from "../../Core/svgV2/Pinned";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";

import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  resetConversations,
  updateConversations,
  updateConversationsMultiple,
  updateThreadForUser,
} from "../../Core/redux/slices/conversations.slice";
import {
  getThreadsMembers,
  updatePrivateChat,
} from "../../Core/redux/api/conversationAPI";
import dayjs from "dayjs";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import { client } from "../../Core/utils/axiosClient";
import ParseMentions from "./ParseMentions";
import { EditPencilIcon } from "../../Core/svgV2/EditIcon";

const ChatCard = ({
  thread,
  socket,
  showMoreOptions = true,
  onChatClickCallBack,
}: {
  thread?: any;
  socket?: any;
  showMoreOptions?: boolean;
  onChatClickCallBack?: (val) => void;
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { currentThread } = useAppSelector((state) => state.conversations);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handlePinChat = async () => {
    dispatch(
      updateThreadForUser({
        threadId: thread.threadId,
        userId: thread.userId,
        isPinned: +!thread.isPinned,
        isThread: thread.threadId,
      })
    );

    if (thread.threadId) {
      await client.put("/conversation/update-thread-for-user", {
        threadId: thread.threadId,
        userId: user.id,
        isPinned: +!thread.isPinned,
      });
    } else {
      await client.put("/conversation/private-chat", {
        receiverId: thread.userId,
        senderId: user.id,
        isPinned: +!thread.isPinned,
      });
    }
  };

  const handleMuteChat = async () => {
    dispatch(
      updateThreadForUser({
        threadId: thread.threadId,
        userId: thread.userId,
        isMute: +!thread.isMute,
        isThread: thread.threadId,
      })
    );
    if (thread.threadId) {
      await client.put("/conversation/update-thread-for-user", {
        threadId: thread.threadId,
        userId: user.id,
        isMute: +!thread.isMute,
      });
    } else {
      await client.put("/conversation/private-chat", {
        receiverId: thread.userId,
        senderId: user.id,
        isMute: +!thread.isMute,
      });
    }
  };

  const handleViewDetails = () => {
    dispatch(
      updateConversations({
        key: thread.threadId ? "groupDetailsDrawer" : "userDetailsDrawer",
        value: { visible: true, data: thread },
      })
    );
  };
  const handleDeleteThread = async () => {
    dispatch(
      updateConversationsMultiple([
        { key: "deleteThread", value: true },
        { key: "deleteThreadDetails", value: thread },
      ])
    );
  };
  const handleEditThread = () => {
    dispatch(
      updateConversationsMultiple([
        { key: "editThread", value: true },
        { key: "newGroupModal", value: true },
        { key: "editThreadDetails", value: currentThread },
      ])
    );
  };
  const isThread = thread.threadId;
  const isAdmin = isThread && thread.createdById == user.id;

  const items = [
    {
      key: "1",
      label: (
        <div onClick={handleViewDetails} className="text-gray-700 out-300-14">
          View details{" "}
        </div>
      ),
      icon: <EllipseIcon className="text-gray-700" />,
    },
    {
      key: "2",
      label: (
        <div onClick={handlePinChat} className="text-gray-700 out-300-14">
          {thread.isPinned == 1 ? "Unpin chat" : "Pin chat"}
        </div>
      ),
      icon: <PinnedOutlinedIcon className="text-gray-700" />,
    },
    {
      key: "3",
      label: (
        <div onClick={handleMuteChat} className="text-gray-700 out-300-14">
          {thread.isMute == 1 ? "Unmute notifications" : "Mute notifications"}
        </div>
      ),
      icon: <BellOutlinedIcon className="text-gray-700" />,
    },
    isThread &&
      isAdmin && {
        key: "4",
        label: (
          <div onClick={handleEditThread} className="out-300-14 text-gray-700">
            Edit group
          </div>
        ),
        icon: <EditPencilIcon className="text-gray-700" />,
      },
    isThread && isAdmin && { key: "4", type: "divider" },
    isThread &&
      isAdmin && {
        key: "5",
        label: (
          <div
            className="flex text-gray-700 gap-x-2 hover:text-error-600"
            role="button"
            onClick={handleDeleteThread}
          >
            <DeleteIcon className="" />
            <div className="out-300-14 ">Delete group</div>
          </div>
        ),
        danger: true,
      },
  ];

  const joinThread = async (thread, projectId = null) => {
    const threadMessageOlder =
      thread.messages && thread.messages?.length > 0 ? thread.messages : [];

    dispatch(
      updateConversationsMultiple([
        {
          value: { ...thread, type: "group", limit: 20, offset: 0, projectId },
          key: "currentThread",
        },
        {
          key: "currentThreadMessage",
          value: threadMessageOlder,
        },
      ])
    );

    dispatch(resetConversations(["replyMessage", "editMessage"]));
    socket.emit("joinroom", {
      newThread: thread.threadId,
      prevThread: currentThread.threadId,
      joiningUserId: user.id,
    });
    console.log("[SOCKET]:joined room event");

    await dispatch(getThreadsMembers({ threadId: thread.threadId }));
    if (onChatClickCallBack && typeof onChatClickCallBack === "function") {
      onChatClickCallBack(true);
    }
    // scrollToChatBottom();
  };

  const joinPrivateThread = async (reciver) => {
    dispatch(
      updateConversationsMultiple([
        {
          value: { ...reciver, type: "private", limit: 20, offset: 0 },
          key: "currentThread",
        },
        {
          key: "currentThreadMessage",
          value:
            reciver.messages && reciver.messages?.lengrh > 0
              ? reciver.messages
              : [],
        },
      ])
    );

    dispatch(resetConversations(["replyMessage", "editMessage"]));

    dispatch(
      updatePrivateChat({
        senderId: user.id,
        receiverId: reciver.userId,
      })
    );

    if (onChatClickCallBack && typeof onChatClickCallBack === "function") {
      onChatClickCallBack(true);
    }
  };

  const getAvatar = () => {
    if (thread.threadId) {
      return (
        <div className="relative">
          <CustomAvatar
            src={
              thread.threadProfilePicture
                ? thread.threadProfilePicture
                : "/images/avatar2.png"
            }
            title={thread.threadName}
            size={48}
            fontSize={"20px"}
            userId={thread.userId}
            whiteText
            color={thread.threadProfilePictureColorCode}

            // badge
          />
          <div className="absolute bottom-0 right-0">
            {" "}
            <img
              className=""
              src="/images/v2/conversations/group-placeholder.svg"
            />
          </div>
        </div>
      );
    }
    return (
      <div className="relative">
        <CustomAvatar
          src={thread.profilePicture}
          title={`${thread.firstname} ${thread.lastname}`}
          size={48}
          badge
          fontSize={"20px"}
          userId={thread.userId}
          whiteText
          color={thread.profilePictureColorCode}
        />
      </div>
    );
  };

  const isSelected = currentThread.threadId
    ? currentThread.threadId === thread.threadId
    : currentThread.userId === thread.userId;

  return (
    <div
      className={`flex border-b cursor-pointer border-gray-200 flex-row items-start group hover:bg-gray-50  h-[80px] w-full  py-[16px] px-[24px] ${
        isSelected ? "bg-gray-50" : "bg-[#F9FBFE]"
      }`}
    >
      {getAvatar()}

      <div
        onClick={() =>
          isThread ? joinThread(thread) : joinPrivateThread(thread)
        }
        className="flex flex-col flex-1 h-[48px] items-start gap-[8px] ml-[12px] "
      >
        <div className="flex w-full max-w-[210px] justify-between">
          <div className="flex truncate flex-1 flex-row items-center h-[20px] ">
            <div className=" font-[500] whitespace-nowrap truncate text-[14px] leading-[20px]">
              {isThread
                ? thread.threadName
                : `${thread.firstname} ${thread.lastname}`}
            </div>

            <div className="pl-[8px] text-[12px] leading-[18px] text-gray-500 whitespace-nowrap">
              {" "}
              {thread?.lastMessageTime
                ? dayjs(thread?.lastMessageTime).local().fromNow()
                : ""}
            </div>

            {thread.isPinned ? (
              <div className="pl-[8px] min-w-[18px] ">
                <PinnedIcon className="text-gray-400" />
              </div>
            ) : (
              <></>
            )}
            {thread.isMute ? (
              <div className="pl-[8px] min-w-[18px]">
                <BellFilledIcon className="text-gray-400" />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-row items-start">
            <div className="flex flex-col items-end">
              <div className="flex items-center">
                {thread.isMentioned ? (
                  <div className="text-[#F04438] mr-1 relative -top-[2px] out-500-14 ">
                    @
                  </div>
                ) : (
                  " "
                )}

                {thread.unreadCount > 0 ? (
                  <div className="flex w-[18px] min-w-[18px] h-[18px] bg-[#F04438] rounded-[32px] items-center justify-center text-[#F9FBFE] text-[12px] font-[500]">
                    {thread.unreadCount}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="leading-[20px] w-[180px] text-[14px] font-[300] truncate ">
            <ParseMentions
              value={
                thread.lastMessage?.slice(0, 30)?.replace(/\n/g, " ") || ""
              }
              textClassName="leading-[20px] w-[180px] text-[14px] font-[300] truncate"
              clickDisable
            />
          </div>
          {showMoreOptions && (
            <div
              className={`group-hover:block ${
                dropdownOpen ? "block" : "hidden"
              }`}
            >
              <Dropdown
                onOpenChange={(e) => setDropdownOpen(e)}
                dropdownRender={(menu) => (
                  <div className="w-[200px]">{menu}</div>
                )}
                menu={{ items }}
                trigger={["click"]}
              >
                <button onClick={(e) => e.stopPropagation()}>
                  <DotsVerticalIcon className="text-gray-400" />
                </button>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
