import React from "react";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { compeleteResetProjectOnboarding } from "../../../Core/redux/slices/projectOnboarding.slice";
import { useNavigate } from "react-router-dom";
import PhasesDescriptionModal from "../../Dashboard/Modals/PhasesDescriptionModal";

const StepLayout = ({ children, style = {} }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div className="absolute top-24 left-24 " style={style}>
      <div className="max-h-[493px] ">{children}</div>
      <PhasesDescriptionModal />
    </div>
  );
};

export default StepLayout;
