import { MentionsInput, Mention } from "react-mentions";
import React, { useEffect, useRef, useState } from "react";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomButton from "../../../Core/Common/CustomButton";
import { Dropdown, Menu, Progress, Tooltip, Upload, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetConversations,
  updateConversations,
} from "../../../Core/redux/slices/conversations.slice";

import { ObjectID } from "bson";
import axios from "axios";
import uniqid from "uniqid";
import { updateVault } from "../../../Core/redux/slices/vault.slice";
import {
  saveComments,
  updateComment,
} from "../../../Core/redux/api/conversationAPI";

import CustomEmojiPicker from "../../../Core/Common/CustomEmojiPicker";

import { AtIcon } from "../../../Core/svgV2/AtIcon";
import { AttachmentIcon } from "../../../Core/svgV2/AttachmentIcon";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import RequestFlow from "./RequestFlow";
import ReplyMessageFlow from "./ReplyMessageFlow";
import UploadFromLibrary from "../Modals/UploadFromLibrary";
import useFileList from "./useFileList";
import { useParams } from "react-router-dom";

function isImageFilename(filename) {
  // Regular expression to match common image file extensions
  const imageExtensions = /\.(jpe?g|png|gif|bmp|webp)$/i;
  return imageExtensions.test(filename);
}

const ConversationsInput = ({
  socket,
  comments = false,
  allowRequest = true,
  isMyBoard = false,
}: {
  socket?: any;
  comments?: boolean;
  allowRequest?: boolean;
  isMyBoard?: boolean;
}) => {
  const {
    editMessage: { edit, messageObject },
    //deleteMessage: { delete, deleteMessageObject },
    currentThread,
    currentThreadMessage,
    replyMessage,
    directMessageUsers,
    threadMembers,
    threads,
    requestOption,
    chatRequest,
  } = useAppSelector((state) => state.conversations);

  const { openCommentsModal } = useAppSelector((state) => state.vault);
  const { user } = useAppSelector((state) => state.userDetails);
  const { taskId, subtaskId } = useParams();
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { userList } = useAppSelector((state) => state.overview);
  const { currentSelectedTask } = useAppSelector((state) => state.myBoard);

  const [moreOptions, setMoreOptions] = useState(true);
  const [value, setValue] = useState(edit ? messageObject.message : "");
  // const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadFromVault, setUploadFromVault] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const { selectedTask } = useAppSelector((state) => state.schedule);

  const isThread = currentThread?.threadId;

  React.useEffect(() => {
    if (edit) {
      setValue(messageObject.message);
      setMoreOptions(true);
    } else {
      setValue("");
    }
  }, [edit]);

  const setRequestOption = (val) => {
    dispatch(updateConversations({ key: "requestOption", value: val }));
  };

  React.useEffect(() => {
    if (replyMessage.reply) {
      setMoreOptions(true);
    }
  }, [replyMessage]);
  // const [emojiPanelOpen, setEmojiPanelOpen] = useState(false);
  //const [fileList, setFileList] = useState([]);

  const [fileUploadedList, setFileUploadedList] = useState([]);

  const {
    fileState,
    uploadingState: isFileUploading,
    setUploadingState: setIsFileUploading,
    setFileState,
  } = useFileList();

  const handleChange = ({ fileList: newFileList }) => {
    // setFileList(newFileList);
    console.log(newFileList, "dvsvhsvusfgvusfgu", fileState);
    setFileState([...newFileList]);

    const fileData = [];

    console.log(newFileList);

    newFileList.forEach((file) => {
      const { name, size, type, lastModified, status } = file;

      if (status !== "done") return;

      file.response.forEach((xfile) => {
        const { fileUrl: url, thumbnail } = xfile;

        fileData.push({
          name,
          url,
          size,
          type,
          lastModified,
          savedInVault: false,
          thumbnail: thumbnail.fileUrl,
          imageOutOfBound: xfile?.imageOutOfBound ? true : false,
        });
      });
    });
    console.log(fileData, "file");
    setFileUploadedList(fileData);
  };

  useEffect(() => {
    const handleChange = ({ fileList: newFileList }) => {
      // setFileList(newFileList);

      const fileData = [];

      newFileList.forEach((file) => {
        const { name, size, type, lastModified, status } = file;

        if (status !== "done") return;

        file.response.forEach((xfile) => {
          const { fileUrl: url, thumbnail } = xfile;

          fileData.push({
            name,
            url,
            size,
            type,
            lastModified,
            savedInVault: Boolean(file.savedInVault),
            thumbnail: thumbnail.fileUrl,
            imageOutOfBound: xfile?.imageOutOfBound ? true : false,
          });
        });
      });

      setFileUploadedList(fileData);
    };
    console.log(fileState, "fileState");
    if (fileState) {
      handleChange({ fileList: fileState });
    }
  }, [fileState]);

  let className = "flex items-center border-gray-200";
  if (moreOptions || edit || replyMessage.reply) {
    className = "";
  }
  if (value !== "") {
    if (edit) className = className + "border-[#FEC84B]";
    else className = className + "border-[#7DC7FF]";
  }

  let users =
    taskId || subtaskId
      ? isMyBoard
        ? tenantMembers
            ?.filter((user) =>
              currentSelectedTask?.collaborators?.includes(user.id)
            )
            .map((user) => {
              // return { id: i, display: "Ganesh " + i };
              return {
                id: user.id,
                display: user.firstname
                  ? user.firstname + " " + user.lastname
                  : user.email,
              };
            })
        : userList?.map((user) => {
            return {
              id: user.userId,
              display: user.firstname
                ? user.firstname + " " + user.lastname
                : user.email,
            };
          })
      : threadMembers?.map((user) => {
          return {
            id: user.userId,
            display: user.firstname
              ? user.firstname + " " + user.lastname
              : user.email,
          };
        });
  users = users.filter((chatUser) => +chatUser.id !== +user.id);

  const uploadRef = useRef();
  const uploadButton = useRef<HTMLButtonElement>(null);
  const inputEl = useRef(null);

  const onEmojiClick = (emojiObject) => {
    const { emoji } = emojiObject;

    console.log(value, "line 191");
    setValue((prevValue) => `${prevValue || ""} ${emoji}`);
    console.log(value, "line 193");
  };
  const dispatch = useAppDispatch();

  const { tenantDetails } = useAppSelector((state) => state.tenant);

  const userLocal = JSON.parse(localStorage.getItem("user"));

  const uploadImage = (options: any) => {
    // below file element is the file you uploaded
    let dimensionalValue = 0;
    setIsFileUploading(true);
    const { onSuccess, onError, file, onProgress } = options;

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result.toString();

      img.onload = () => {
        // Access image dimensions

        dimensionalValue = img.width * img.height;

        // You can set the image state or perform further actions here
        // setImage(file);
      };
    };
    reader.readAsDataURL(file);
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + userLocal.token,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    console.log("FILE: ", file);
    // create copy file and change the name when it is appending to data
    const data = new FormData();
    data.append(
      "file",
      file,
      uniqid(`${file.name.split(".")[0]}-`, `.${file.name.split(".").at(-1)}`)
    ); // replace all the spaces in the file name with -
    const _file = data.get("file");
    fmData.append("image", _file);
    try {
      // then send the copy file to the server
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/v1/upload/storej",
          fmData,
          config
        )
        .then((res) => {
          let newResObj = { ...res.data[0] };
          if (+dimensionalValue >= 71000000) {
            // setimageOutOfBound([...imageOutOfBound, res.data[0]?.fileUrl]);
            newResObj = { ...newResObj, imageOutOfBound: true };
          }

          const data = [];
          data.push(newResObj);
          console.log("UPLOADED", res.data);
          onSuccess(data);
          setIsFileUploading(false);
        });
    } catch (err) {
      onError({ err });
    }
  };

  const handleRequestOption = (message) => {
    if (requestOption) {
      const request = {
        type: chatRequest.selectedRequestType,
        status: "Open",
        requestClosingDate: null,
        urgency: chatRequest.selectedUrgency,
        deadlineDate: chatRequest.deadlineDate,
        deadlineTime: chatRequest.deadlineTime,
        activity: [],
        updateCount: 0,
        updateSeenUsers: [],
      };
      console.log({ ...message, request }, "privateMessage2");
      return { ...message, request };
    }
    console.log({ ...message, requestOption }, "privateMessage2");
    return { ...message, request: null };
  };

  const sendMessage = () => {
    const notifyToUsers = threadMembers
      .filter((m) => m.userId !== user.id)
      .map((m) => {
        return { userId: m.userId };
      });
    if (isFileUploading) return;
    if (value?.toString().trim().length === 0 && fileState.length === 0) return;

    const { firstName, lastName, id, profilePicture } = user;
    const objectID = new ObjectID();

    console.log(tenantDetails, "tenantDetailssss");

    let message = {
      _id: objectID.toString(),
      threadId: currentThread.threadId,
      message: value.trim(),
      senderName: `${firstName} ${lastName}`,
      senderId: id,
      createdAt: dayjs().utc().format(),
      senderProfilePicture: profilePicture,
      replyMessage: null,
      reactions: {},
      attachments: fileUploadedList,
      notifyToUsers,
      projectId: currentThread.projectId,
      threadName: currentThread.threadName,
      tenantId: tenantDetails.tenantId,
    };
    console.log(message, "message");

    if (replyMessage.reply) {
      message.replyMessage = {
        ...replyMessage.messageObject,
        replyMessage: undefined,
      };
    }
    message = handleRequestOption(message);

    let messageListCopy = [...currentThreadMessage];

    if (
      replyMessage.reply &&
      replyMessage.messageObject.request &&
      replyMessage.messageObject.request?.type
    ) {
      messageListCopy = messageListCopy.map((m) => {
        if (m._id === replyMessage.messageObject._id) {
          return {
            ...m,
            request: {
              ...m.request,
              updateCount: 1,
              activity: [
                ...m.request.activity,
                { ...message, replyMessage: {}, request: {} },
              ],
            },
          };
        }
        return m;
      });
      socket.emit("replyToRequest", {
        parentMessageId: replyMessage.messageObject._id,
        message,
      });
    } else {
      messageListCopy.push(message);
      socket.emit("message", message);
    }

    dispatch(
      updateConversations({
        key: "currentThreadMessage",
        value: [...messageListCopy],
      })
    );

    const newThreads = threads.map((thread) => {
      if (thread.threadId == currentThread.threadId) {
        return {
          ...thread,
          messages: [...messageListCopy],
          lastMessage: `You: ${value.trim()}`,
          lastMessageTime: new Date(),
          lastMessageLocal: true,
        };
      }
      return thread;
    });

    dispatch(
      updateConversations({
        key: "threads",
        value: newThreads,
      })
    );

    // setMessageList(messageListCopy);

    setValue("");
    setFileState([]);
    setFileUploadedList([]);
    dispatch(
      resetConversations([
        "replyMessage",
        "chatRequest",
        "requestOption",
        "defaultFileList",
      ])
    );
    localStorage.removeItem(
      `${
        currentThread.type === "group"
          ? currentThread.threadId
          : currentThread.userId
      }-${tenantDetails.tenantId}`
    );
    // scrollToChatBottom();
    // setEmojiPanelOpen(false);
  };

  const editMessage = () => {
    if (value?.toString().trim().length === 0) return;

    const message = {
      messageId: messageObject._id,
      threadId: currentThread.threadId,
      message: value.trim(),
    };

    const editMessage = (oldMessages) => {
      const newMessages = oldMessages.map((m) => {
        if (m._id === messageObject._id) {
          return {
            ...m,
            message: value,
          };
        }
        return m;
      });
      return newMessages;
    };

    if (comments) {
      dispatch(
        updateComment({ messageId: messageObject._id, message: value.trim() })
      );
      const oldModeMetaComments = openCommentsModal.comments;
      const message = editMessage(oldModeMetaComments);
      const newModelMeta = { ...openCommentsModal, comments: message };
      dispatch(
        updateVault({
          key: "openCommentsModal",
          value: newModelMeta,
        })
      );
      return "";
    }

    socket.emit("editMessage", message);

    if (currentThread.type === "private") {
      const users = directMessageUsers.map((user) => {
        if (+user.userId === +currentThread.userId) {
          const message = editMessage(user.messages);
          return { ...user, messages: [...message] };
        }
        return user;
      });

      dispatch(
        updateConversations({
          key: "directMessageUsers",
          value: [...users],
        })
      );
      localStorage.removeItem(
        `${currentThread.userId}-${tenantDetails.tenantId}`
      );
    } else {
      const messageListCopy = [...currentThreadMessage];

      const edittedMessage = editMessage(messageListCopy);

      dispatch(
        updateConversations({
          key: "currentThreadMessage",
          value: [...edittedMessage],
        })
      );
      localStorage.removeItem(
        `${
          currentThread.type === "group"
            ? currentThread.threadId
            : currentThread.userId
        }-${tenantDetails.tenantId}`
      );
    }

    // setMessageList(messageListCopy);
    setValue("");
    dispatch(resetConversations(["replyMessage"]));
    // scrollToChatBottom();
    // setEmojiPanelOpen(false);
  };

  const sendPrivateMessage = () => {
    console.log(tenantDetails, "tenantDetailssss");
    if (isFileUploading) return;
    if (value?.toString().trim().length === 0 && fileState.length === 0) return;

    const { firstName, lastName, id, profilePicture } = user;
    const objectID = new ObjectID();

    let message = {
      _id: objectID.toString(),
      //threadId: currentThread.threadId,
      message: value.trim(),
      senderName: `${firstName} ${lastName}`,
      senderId: id,
      reciverId: currentThread.userId,
      createdAt: dayjs().utc().format(),
      senderProfilePicture: profilePicture,
      replyMessage: null,
      reactions: {},
      attachments: fileUploadedList,
      tenantId: tenantDetails.tenantId,
      request: null,
    };

    if (replyMessage.reply) {
      message.replyMessage = {
        ...replyMessage.messageObject,
        replyMessage: undefined,
      };
    }

    message = handleRequestOption(message);
    //console.log(currentThread.userId);
    let users = [];
    if (
      replyMessage.reply &&
      replyMessage.messageObject.request &&
      replyMessage.messageObject.request?.type
    ) {
      users = directMessageUsers.map((user) => {
        if (+user.userId === +currentThread.userId) {
          const messages = user.messages.map((m) => {
            if (m._id === replyMessage.messageObject._id) {
              return {
                ...m,

                request: {
                  ...m.request,
                  updateCount: 1,
                  activity: [
                    ...m.request.activity,
                    { ...message, replyMessage: {}, request: {} },
                  ],
                },
              };
            }
            return m;
          });
          return {
            ...user,
            messages: [...messages],
            lastMessage: `${value.trim()}`,
            lastMessageTime: new Date(),
            lastMessageLocal: true,
          };
        }
        return user;
      });

      socket.emit("replyToRequest", {
        parentMessageId: replyMessage.messageObject._id,
        message,
      });
    } else {
      socket.emit("privateMessage", message);

      console.log(message, "privateMessage");

      users = directMessageUsers.map((user) => {
        if (+user.userId === +currentThread.userId) {
          return {
            ...user,
            messages: [...user.messages, message],
            lastMessage: `${value.trim()}`,
            lastMessageTime: new Date(),
            lastMessageLocal: true,
          };
        }
        return user;
      });
    }

    dispatch(
      updateConversations({
        key: "directMessageUsers",
        value: [...users],
      })
    );
    // setMessageList(messageListCopy);
    setValue("");
    setFileState([]);
    setFileUploadedList([]);
    dispatch(
      resetConversations([
        "replyMessage",
        "chatRequest",
        "requestOption",
        "defaultFileList",
      ])
    );
    localStorage.removeItem(
      `${
        currentThread.type === "group"
          ? currentThread.threadId
          : currentThread.userId
      }-${tenantDetails.tenantId}`
    );
    // scrollToChatBottom();
    // setEmojiPanelOpen(false);
  };

  const sendCommentsMessage = async () => {
    console.log("clicked");

    if (value?.toString().trim().length === 0 && fileState.length === 0) return;

    const mentionedTags = value
      .split(" ")
      .filter((element) => element.includes("@@@__"));

    const mentionedUsers = mentionedTags
      .map((element) => element.match(/\d+/g))
      .flat()
      .map((element) => ({ userId: element }));

    const sendNotificationToUsers = [
      ...openCommentsModal.users,
      ...mentionedUsers.filter((existingUser) => {
        // Check if the userId is not present in mentionedUsers
        return !openCommentsModal.users.some(
          (mentionedUser) => +existingUser.userId === +mentionedUser.userId
        );
      }),
    ];

    const { firstName, lastName, id, profilePicture } = user;
    const objectID = new ObjectID();

    const message = {
      _id: objectID.toString(),
      //threadId: currentThread.threadId,
      docId: openCommentsModal.docId,
      docType: openCommentsModal.docType,

      message: value.trim(),
      senderName: `${firstName} ${lastName}`,
      senderId: id,

      createdAt: dayjs().utc().format(),
      senderProfilePicture: profilePicture,
      replyMessage: null,
      reactions: {},
      attachments: fileUploadedList,
      users: sendNotificationToUsers,
      name: openCommentsModal.name,
      meta: {
        projectId: selectedTask?.projectId,
        url: window.location.href,
        name: openCommentsModal.name,
        sprintId: selectedTask?.sprintId,
        taskName: selectedTask?.name,
        taskId: selectedTask?.id,
      },
      //todo tenantid
    };
    console.log(message.users, "users");
    if (replyMessage.reply) {
      message.replyMessage = {
        ...replyMessage.messageObject,
        replyMessage: undefined,
      };
    }
    //console.log(currentThread.userId);
    // socket.emit("privateMessage", message);
    const oldComments = openCommentsModal.comments;
    const newModelMeta = {
      ...openCommentsModal,
      comments: [...oldComments, message],
    };

    dispatch(
      updateVault({
        key: "openCommentsModal",
        value: newModelMeta,
      })
    );
    // setMessageList(messageListCopy);
    setValue("");
    setFileState([]);
    setFileUploadedList([]);
    dispatch(resetConversations(["replyMessage"]));
    // scrollToChatBottom();
    // setEmojiPanelOpen(false);
    await dispatch(saveComments({ message }));
  };

  const handleUserTyping = (isTyping) => {
    if (comments) return;
    const { firstName, lastName } = user;

    const payload = {
      isTyping,
      projectId: 1,
      threadId:
        currentThread.type === "group"
          ? currentThread.threadId
          : currentThread.userId,
      userName: `${firstName} ${lastName}`,
      userId: user.id,
      threadType: currentThread.type,
    };

    // localStorage.setItem(
    //   `${
    //     currentThread.type === "group"
    //       ? currentThread.threadId
    //       : currentThread.userId
    //   }-${tenantDetails.tenantId}`,
    //   value
    // );

    socket.emit("user-typing", payload);
    // socket.emit("userTyping", payload);
  };

  // useEffect(() => {
  //   setFileList([]);
  //   setFileList(defaultFileList);
  //   setFileUploadedList([]);
  //   setFileUploadedList(defaultFileList);
  // }, [defaultFileList]);

  React.useEffect(() => {
    if (!comments) {
      const draft = localStorage.getItem(
        `${
          currentThread.type === "group"
            ? currentThread.threadId
            : currentThread.userId
        }-${tenantDetails.tenantId}`
      );

      setValue(draft ? draft : "");
    }
  }, [currentThread]);

  const handelUploadFromVault = (arr) => {
    console.log(arr, "handelUploadFromVault");
    // const newArr = arr.map((a, i) => {
    //   const file = {
    //     uid: `${a.name}-${i}`,
    //     name: a.name,
    //     status: "done",
    //     url: a.url,
    //     thumbUrl: a.thumbnail,
    //   };
    //   return file;
    // });

    // setFileState([...newArr]);
    const uploadArr = arr.map((a) => {
      const file = {
        name: a.name,
        url: a.url,
        size: a.fileSize,
        type: a.mimeType,
        lastModified: "",
        savedInVault: true,
        status: "done",
        response: [{ fileUrl: a.url, thumbnail: { fileUrl: a.thumbnail } }],
      };
      return file;
    });
    setFileState([...uploadArr]);
  };

  const handleScroll = () => {
    const selectedElement = document.querySelector('[aria-selected="true"]');

    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  return (
    <div id="conversations-tour-input-request">
      <div
        className={`min-h-[52px] w-full bg-white border transition-all  rounded-lg ${className}`}
        onClick={() => setMoreOptions(true)}
        id="conversations-tour-input"
      >
        {/* Message Reply section start */}
        {replyMessage.reply && <ReplyMessageFlow replyMessage={replyMessage} />}

        {/* request options code  */}
        {requestOption ? <RequestFlow /> : null}

        <Upload
          listType="picture-card"
          multiple
          action={process.env.REACT_APP_API_URL + "/v1/upload"}
          headers={{ Authorization: "Bearer " + user.token }}
          customRequest={uploadImage}
          ref={uploadRef}
          fileList={[...fileState]}
          onChange={handleChange}
          itemRender={(originNode, file, fileList, actions) => {
            const { response, status, percent } = file;

            // const { response, status, type, percent } = file;
            const fileUrl =
              response && response.length > 0
                ? isImageFilename(response[0]?.fileName)
                  ? response[0]?.fileUrl
                  : response[0]?.thumbnail?.fileUrl || ""
                : "";

            return (
              <div
                className="h-[56px] w-[56px] bg-no-repeat bg-center bg-cover rounded-lg relative flex justify-center items-center"
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(16, 24, 40, 0.2), rgba(16, 24, 40, 0.2)), url(${
                    fileUrl || file.url
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                {percent < 100 && status !== "done" ? (
                  <Progress
                    type="circle"
                    trailColor="#e6f4ff"
                    percent={percent}
                    strokeWidth={10}
                    width={40}
                    format={() => ""}
                    className="progress-upload"
                  />
                ) : percent === 100 && status !== "done" ? (
                  <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                  />
                ) : (
                  <></>
                )}
                <div
                  className="absolute flex items-center justify-center w-5 h-5 bg-gray-900 rounded-full cursor-pointer -right-1 -top-1"
                  onClick={actions.remove}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 5L5 15M5 5l10 10"
                    ></path>
                  </svg>
                </div>
              </div>
            );
          }}
          style={
            fileState.length === 0 && {
              display: "none",
            }
          }
          className={`${
            fileState.length === 0 ? "custom-upload-hide" : "custom-upload"
          } `}
        >
          {fileState.length === 0 ? (
            <button
              className=""
              style={{
                display: "none",
              }}
              ref={uploadButton}
            />
          ) : (
            <div>
              <img src="/images/icons/plus-gray.svg" />
            </div>
          )}
        </Upload>

        {uploadFromVault && (
          <UploadFromLibrary
            visible={uploadFromVault}
            onClose={() => setUploadFromVault(false)}
            onChange={(arr) => handelUploadFromVault(arr)}
          />
        )}

        <MentionsInput
          value={value}
          inputRef={inputEl}
          className="comments-textarea min-h-[52px]"
          onChange={(e) => {
            setValue(e.target.value);
            handleUserTyping(true);
            localStorage.setItem(
              `${
                currentThread.type === "group"
                  ? currentThread.threadId
                  : currentThread.userId
              }-${tenantDetails.tenantId}`,
              e.target.value
            );
            clearTimeout(timeoutId);

            setTimeoutId(
              setTimeout(() => {
                console.log("timeoutIdinset", timeoutId);
                handleUserTyping(false);
              }, 100)
            );
          }}
          onBlur={() => {
            handleUserTyping(false);
          }}
          autoFocus={true}
          id="conversation-chat-input"
          onPaste={(e) => {
            function getFileFromPasteEvent(event) {
              const items = (
                event.clipboardData || event.originalEvent.clipboardData
              ).items;
              for (const index in items) {
                const item = items[index];
                if (item.kind === "file") {
                  return item.getAsFile();
                }
              }
            }
            const file = getFileFromPasteEvent(e);
            if (!file) {
              return;
            } else {
              console.log(file, "onpaste file");
            }
          }}
          // onKeyUp={() => {
          //   handleUserTyping(false);
          // }}
          placeholder={
            comments
              ? "Add a comment"
              : requestOption
              ? "Mention the requestee by typing the “@” and selecting the contact's name from the list."
              : !allowRequest
              ? "Type a message"
              : "Type a message or “/” for requests"
          }
          allowSuggestionsAboveCursor
          onKeyDown={(e) => {
            //handleUserTyping(true);
            if (e.key === "/" && value.length === 0) {
              if (comments) return true;
              if (!allowRequest) return true;
              setRequestOption(true);
              e.stopPropagation();
              e.preventDefault();
              return false;
            }
            if (e.key === "Enter" && (e.ctrlKey || e.shiftKey)) {
              // e.preventDefault(); // Prevent the default Enter behavior

              // Check if the cursor is at the end of the line (no text after cursor)
              const newValue =
                document.getElementsByClassName("comments-textarea");
              console.log(newValue, "value before");
              // newValue = newValue[0].textContent + "\n";
              console.log(newValue, "value after");
              // setValue(newValue);

              return true;
            }
            if (e.key === "Enter" && !isFileUploading) {
              dispatch(resetConversations(["unreadCount"]));
              e.preventDefault();
              if (edit) {
                editMessage();
                dispatch(resetConversations(["editMessage"]));
                return true;
              }
              comments
                ? sendCommentsMessage()
                : currentThread.type === "group"
                ? sendMessage()
                : sendPrivateMessage();
              return true;
            }
          }}
        >
          <Mention
            trigger="@"
            data={taskId || subtaskId ? users : isThread ? users : []}
            markup="@@@____id__^^____display__@@@^^^"
            style={{
              // background: "linear-gradient(90deg, #7F56D9 0%, #9E77ED 100%)",
              background: "#2E90FA",
              // opacity: 0.3,
              borderRadius: 4,
              color: "white",
              position: "relative",
              zIndex: "1",
            }}
            //forceSuggestionsAboveCursor={true}
            appendSpaceOnAdd
            displayTransform={(id, text) => `@${text}`}
            renderSuggestion={(
              entry
              // search,
              // highlightedDisplay,
              // index,
              // focused
            ) => {
              // if (focused) {
              //   handleScroll();
              // }
              return (
                <div
                  key={entry.display}
                  onMouseEnter={(e) => e.stopPropagation()}
                  className="flex items-center"
                >
                  <CustomAvatar title={entry.display} size={25} />
                  <span className="ml-2 text-gray-700 capitalize mon-400-12">
                    {entry.display}
                  </span>
                </div>
              );
            }}
          />
        </MentionsInput>

        {/* main input box end */}

        {/* bootom bar or more section start */}

        <div
          className={` justify-between items-center px-2 pb-2 ${
            moreOptions || edit || replyMessage.reply ? "flex" : "hidden"
          }`}
        >
          <div className="flex items-center pl-2 gap-x-4">
            <Tooltip title="Mention someone">
              <button
                onClick={() => {
                  setValue(`${value} @`);
                  inputEl?.current?.focus();
                }}
              >
                <AtIcon className="text-gray-700" />
              </button>
            </Tooltip>

            <CustomEmojiPicker onChange={onEmojiClick} className="" />
            {!comments && (
              <Dropdown
                //getPopupContainer={(trigger) => trigger.parentElement}
                className=""
                overlay={
                  <Menu className="w-[225px] rounded-lg custom-menu-dropdown  shadow-dropdown">
                    <Menu.Item
                      className=" py-2.5 px-4 rounded-t-lg"
                      key="from-vault"
                    >
                      <div
                        className="flex items-center"
                        onClick={() => setUploadFromVault(true)}
                      >
                        <img
                          src="/images/icons/folder-dark-border.svg"
                          className="w-4 h-4"
                          alt="add-new-sprint"
                        />
                        <span className="ml-2 text-gray-700 mon-400-12 whitespace-nowrap">
                          From Library
                        </span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      className="py-2.5 px-4 rounded-b-lg"
                      key="from-computer"
                    >
                      <div
                        className="flex items-center "
                        onClick={() => {
                          if (uploadButton && uploadButton.current) {
                            uploadButton.current.click();
                          }
                        }}
                      >
                        <img
                          src="/images/icons/monitor-01.svg"
                          alt="add-new-sprint"
                          className="w-4 h-4"
                        />
                        <span className="ml-2 text-gray-700 mon-400-12 whitespace-nowrap">
                          Upload From your computer
                        </span>
                      </div>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Tooltip title="Attachment">
                  <AttachmentIcon className="text-gray-700" />
                </Tooltip>
              </Dropdown>
            )}
          </div>
          {edit ? (
            <div className="flex justify-end gap-x-3">
              <CustomButton
                text="Cancel"
                className="text-gray-700 bg-white border border-gray-200 mon-500-14"
                height="36px"
                width="81px"
                onClick={() => dispatch(resetConversations(["editMessage"]))}
              />
              <CustomButton
                text="Save"
                className="text-white mon-500-14 bg-primary-600 "
                height="36px"
                width="62px"
                onClick={() => {
                  editMessage();
                  dispatch(resetConversations(["editMessage"]));
                }}
              />
            </div>
          ) : (
            <div className="flex items-center">
              {requestOption && (
                <button
                  className="mr-2"
                  onClick={() => setRequestOption(false)}
                >
                  <CrossIcon className="text-gray-500" />
                </button>
              )}
              <button
                className={`min-w-[36px] min-h-[36px] flex items-center justify-center ${
                  value !== "" ? "bg-primary-600 rounded-[4px]" : ""
                }`}
                onClick={() => {
                  dispatch(resetConversations(["unreadCount"]));
                  comments && !isFileUploading
                    ? sendCommentsMessage()
                    : currentThread.type === "group"
                    ? sendMessage()
                    : sendPrivateMessage();
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3333 1.66797L9.16667 10.8346M18.3333 1.66797L12.5 18.3346L9.16667 10.8346M18.3333 1.66797L1.66667 7.5013L9.16667 10.8346"
                    stroke={value !== "" ? "#ffff" : "#667085"}
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>

        {/* bootom bar or more section end */}
      </div>
    </div>
  );
};

export default ConversationsInput;
