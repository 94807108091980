import { Drawer } from "antd";
import React, { useState, useEffect } from "react";

import CustomButton from "../../../Core/CommonV2/CustomButton";
import CopyButton from "../../../Core/CommonV2/CopyButton";
import dayjs from "dayjs";
import useTimezone from "../../../Core/hooks/useTimezone";
import { ChevronDoubleIcon } from "../../../Core/svgV2/Chevron";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { markSpoc } from "../../../Core/redux/api/projectAPI";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";

//todo redirect user to pvt chat of the member selected from the user details drawer

const UserDetailsDrawer = ({
  open = false,
  onClose,
  user,
  mode = "ProjectUserDetails",
}) => {
  const { projectId } = useParams();
  const timezone = useTimezone(user.lat, user.long);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [spoc, setSpoc] = useState(user.spoc);

  useEffect(() => {
    setSpoc(user?.spoc);
  }, [user]);

  const handleMarkSpoc = async () => {
    dispatch(
      markSpoc({
        projectId,
        userId: +user.userId,
        spoc: spoc === 1 ? 0 : 1,
      })
    );
  };

  return (
    <Drawer
      title={null}
      className="overflow-y-hidden "
      closable={false}
      placement="right"
      open={open}
      onClose={onClose}
      width={"318px"}
      footer={
        <div className="flex py-2 gap-x-[33.5px] justify-between  items-center">
          <div className="flex justify-between gap-x-3 ">
            <button className="bg-primary-50 h-[30px] w-[40px] flex items-center justify-center rounded-[4px]">
              <img
                src="/images/v2/dashboard/share.svg"
                className="text-primary-700 bg-primary-50 "
              />
            </button>
            <button
              className="bg-primary-50 h-[30px] w-[40px] flex items-center justify-center rounded-[4px]"
              onClick={() => {
                navigate("/messages");
              }}
            >
              <img
                src="/images/v2/dashboard/message-outline-blue.svg"
                className="text-primary-700 bg-primary-50 "
              />
            </button>
          </div>

          {mode !== "WorkspaceUserDetails" && (
            <CustomButton
              text={spoc === 1 ? "Unmark as SPOC" : "Mark as SPOC"}
              onClick={handleMarkSpoc}
              icon={
                spoc === 1 ? (
                  <img src="/images/icons/minus-circle.svg" />
                ) : (
                  <img src="/images/icons/check-circle.svg" />
                )
              }
              className={`${
                spoc === 1 ? "text-error-600" : "text-primary-600"
              }   mon-500-14`}
              height="30px"
              width="156px"
            />
          )}
        </div>
      }
    >
      <div className="p-4 flex border-gray-700   flex-col h-full relative">
        <div className="flex-1 ">
          <button onClick={onClose} className="absolute top-4">
            <ChevronDoubleIcon
              className="text-gray-700 "
              style={{
                transition: "transform 0.3s ease-in-out 0s",
                transform: "rotate(180deg)",
              }}
            />
          </button>
          <div className="flex  w-full justify-center flex-col items-center">
            <CustomAvatar
              size={144}
              title={`${user.firstname} ${user.lastname}`}
              src={user.profilePicture}
              style={{
                border: user.spoc === 1 ? "2px solid #165ABF" : "",
              }}
              fontSize={"40px"}
              color={user.profilePictureColorCode}
              whiteText
            />

            <p className="out-500-18 mt-[6px]">
              {user.firstname} {user.lastname}
            </p>
            <div
              className={
                user.spoc === 1
                  ? "out-500-14  w-[51px] h-[22px] py-0.5 px-2 rounded bottom-3 text-white bg-primary-600 flex justify-center items-center"
                  : "hidden"
              }
            >
              SPOC
            </div>
          </div>
          <div className="mt-[50px]">
            <div className="mt-4">
              <p className="out-300-14 text-gray-500">Email </p>
              <div className="mt-1.5 flex items-center gap-x-2">
                <p className="out-500-14 text-black ">{user.email || "N/A"}</p>
                <button
                  onClick={async () =>
                    await navigator.clipboard.writeText(user.email)
                  }
                >
                  <CopyButton
                    text={user.email}
                    copyIcon={true}
                    width={"20px"}
                    height={"20px"}
                  />
                </button>
              </div>
            </div>
            <div className="mt-4">
              <p className="out-300-14 text-gray-500">Mobile number</p>
              <p className="out-500-14 text-black mt-2">
                {user.mobile || "N/A"}
              </p>
            </div>
            <div className="mt-4">
              <p className="out-300-14 text-gray-500">Company</p>
              <p className="out-500-14 text-black mt-2">
                {user.company || "N/A"}
              </p>
            </div>
            <div className="mt-4">
              <p className="out-300-14 text-gray-500">Job title</p>
              <p className="out-500-14 text-black mt-2">
                {user.jobTitle || "N/A"}
              </p>
            </div>
            <div className="mt-4">
              <p className="out-300-14 text-gray-500">Project role</p>
              {user.roleName ? (
                <CustomBadge
                  dot
                  dotColor="#2E90FA"
                  text={user.roleName}
                  color="#144EA6"
                  className="out-500-14  mt-2 bg-primary-50 "
                />
              ) : (
                <p className="out-500-14 text-black mt-2"> -- </p>
              )}
            </div>

            <div className="mt-4">
              <p className="out-300-14 text-gray-500">Currently located in</p>
              <div className="flex justify-between items-center">
                <p className="out-500-14 text-black mt-2">
                  {user.location || "N/A"}
                </p>
                <div className="flex items-center min-w-[132px]">
                  <img src="/images/v2/conversations/schedule.svg" />
                  <p className="out-300-12 text-gray-500 ml-2">
                    {timezone.timeZoneId
                      ? dayjs().tz(timezone.timeZoneId).format("hh:mm a")
                      : "N/A"}{" "}
                    Local time
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default UserDetailsDrawer;
