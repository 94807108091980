import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pendingTimeEstimates: false, 
};

const timeEstimatesSlice = createSlice({
  name: 'timeEstimates',
  initialState,
  reducers: {
    setPendingTimeEstimates: (state, action) => {
      state.pendingTimeEstimates = action.payload; 
    },
  },
});


export const { setPendingTimeEstimates } = timeEstimatesSlice.actions;
export default timeEstimatesSlice.reducer;
