import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { Input, Popover, Tooltip } from "antd";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import PriorityFlag from "../../../Core/svgV2/PriorityFlag";
import { getPriorityDetails } from "../../../Core/utils/scheduleUtils";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import TimeInput from "../Task/TimeInput";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { useNavigate, useParams } from "react-router-dom";
import {
  createGoal,
  getGoalDetails,
  getGoalsBySprint,
  updateGoal,
} from "../../../Core/redux/api/scheduleAPI";
import { TASK_ACTIVITY_LOG_ACTIONS } from "../../../Core/constants";
import { QueryClient, useQueryClient } from "react-query";
import { BoxChevronIcon } from "../../../Core/svgV3/BoxChevronIcon";
import dayjs from "dayjs";
import {
  LabelSelect,
  SubtaskButton,
  TimeSelect,
  UserSelect,
} from "./TaskReusable";
import {
  resetMyBoard,
  resetToInitialMyBoard,
  updateMyBoard,
} from "../../../Core/redux/slices/myBoard.slice";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import {
  createMyBoardTask,
  getMyBoardTasks,
  updateMyBoardParentTaskTimeLog,
  updateMyBoardTask,
} from "../../../Core/redux/api/myBoardAPI";
import { CustomDatePicker } from "../Modals/ManageLabelsModal";
import { SubtaskUserSelect } from "../Task/AddSubtask";

const PrioritySelect = ({ visible = false, task, setTask }) => {
  const priority = task.priorityId;

  const [open, setOpen] = useState(false);

  const priorityData = getPriorityDetails(priority);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const getLable = (priority, bold = false) => {
    const { color, priorityName } = getPriorityDetails(priority);
    return (
      <div className="flex items-center ">
        <PriorityFlag
          style={{
            color,
          }}
        />
        <p
          className="out-300-14 ml-2"
          style={{ color, fontWeight: bold ? 500 : 300 }}
        >
          {priorityName}
        </p>
      </div>
    );
  };

  const option = [
    {
      id: 1,
      value: 1,
      label: "Low",
      render: () => getLable(1),
    },
    {
      id: 2,
      value: 2,
      label: "Medium",
      render: () => getLable(2),
    },
    {
      id: 3,
      value: 3,
      label: "High",
      render: () => getLable(3),
    },
    {
      id: 4,
      value: 4,
      label: "Urgent",
      render: () => getLable(4),
    },
  ];

  return (
    <Popover
      getPopupContainer={(parent) => parent.parentElement}
      content={
        <div className="min-w-[200px] text-zinc-800">
          <CustomSelect
            placeholder="Select Priority"
            options={option}
            defaultOpen
            showSearch
            onChange={(e) => {
              setOpen(false);
              setTask(e);
            }}
          />
        </div>
      }
      arrow={false}
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomLeft"
      trigger={"click"}
      destroyTooltipOnHide
    >
      <button>
        {(priority || visible) && (
          <PriorityFlag
            style={{ color: priorityData.color }}
            height="20"
            width="20"
          />
        )}
      </button>
    </Popover>
  );
};

const TaskType = ({ onTaskTypeChange, taskType = 1, isNewGoal = false }) => {
  const [open, setOpen] = useState(false);
  const { showSubtaskView, myBoardKanbanView } = useAppSelector(
    (state) => state.myBoard
  );
  const handleOpenChange = (newOpen: boolean) => {
    if (myBoardKanbanView && !showSubtaskView) setOpen(newOpen);
  };
  const [selectedType, setSelectedType] = useState(isNewGoal ? 4 : taskType);
  const getTaskDetails = (taskType) => {
    switch (+taskType) {
      case 1:
        return { src: "/images/v2/schedule/Task.svg", taskName: "Task" };
      case 2:
        return { src: "/images/v2/schedule/meet.svg", taskName: "Event" };
      case 3:
        return { src: "/images/v2/schedule/Bug.svg", taskName: "Bug" };
      case 4:
        return { src: "/images/v2/schedule/Subtask.svg", taskName: "Subtask" };
      default:
        return {
          src: "/images/v2/schedule/Task.svg",
          taskName: "Task",
        };
    }
  };

  const TaskItem = ({ taskType }) => {
    const { taskName, src } = getTaskDetails(taskType);
    return (
      <div
        onClick={() => {
          setSelectedType(taskType);
          onTaskTypeChange(taskType);
          setOpen(false);
        }}
        className="flex cursor-pointer py-2.5 px-3.5 hover:bg-gray-50 rounded-t-lg justify-between"
      >
        <div className="flex  items-center">
          <img src={src} />
          <span className="out-300-14 text-gray-700 ml-2">{taskName}</span>
        </div>
        {selectedType == taskType && <CheckIcon className="text-primary-600" />}
      </div>
    );
  };

  const { src } = getTaskDetails(selectedType);
  return myBoardKanbanView && !showSubtaskView ? (
    // <Popover
    //   arrow={false}
    //   placement="bottomLeft"
    //   open={open}
    //   onOpenChange={handleOpenChange}
    //   content={
    //     <div className="w-[200px] bg-white shadow-lg rounded-lg ">
    //       <TaskItem taskType={1} />
    //       <TaskItem taskType={3} />

    //       <TaskItem taskType={4} />
    //     </div>
    //   }
    //   trigger={["click"]}
    // >
    //   <Tooltip title="Select Type">
    <button
      className="flex items-center justify-between cursor-pointer gap-x-1.5 py-1 pl-0.5 pr-1.5"
      // onClick={() => {
      //   if (myBoardKanbanView && !showSubtaskView) {
      //     setOpen(true);
      //   }
      // }}
    >
      <img src={src} className="h-[16px] w-[16px]" />
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path d="M3 4.5L6 7.5L9 4.5" fill="#667085" />
        <path
          d="M3 4.5L6 7.5L9 4.5H3Z"
          stroke="#667085"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg> */}
    </button>
  ) : (
    //   </Tooltip>
    // </Popover>
    <button className="flex items-center justify-between cursor-pointer gap-x-1.5 py-1 pl-0.5 pr-1.5">
      <img src={src} className="h-[16px] w-[16px]" />
    </button>
  );
};

type TaskCardProps = {
  task: any;
  // scrollPosition: any;
  // onScroll: any;
};

const TaskCard: React.FC<TaskCardProps> = React.memo(({ task }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { userList } = useAppSelector((state) => state.overview);
  const { selectedTasksForDragAndDrop, labels } = useAppSelector(
    (state) => state.schedule
  );
  const { showSubtaskView, myBoardKanbanView } = useAppSelector(
    (state) => state.myBoard
  );
  const [taskDetails, setTaskDetails] = useState(task);
  const [dateRange, setDateRange] = useState(
    taskDetails?.startDate && taskDetails?.endDate
      ? [dayjs(taskDetails.startDate), dayjs(taskDetails.endDate)]
      : null
  );

  const assignee = userList.find((user) => +user.userId === +task.assigneeId);
  const [taskType, setTaskType] = useState(1);

  const [isFocus, setIsFoucs] = useState(false);

  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const [visible, setVisible] = useState(false);
  const [parentTask, setParentTask] = useState(null);

  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { myBoardTasks } = useAppSelector((state) => state.myBoard);

  useEffect(() => {
    const currentUser = tenantMembers.find(
      (u) => u.userId === taskDetails.assigneeId
    );
    setCurrentSelectedUser(currentUser || null);

    const parentTask = myBoardTasks?.result?.find((goal) =>
      taskDetails?.parentGoalId
        ? goal.id === taskDetails?.parentGoalId
        : goal.id === taskDetails?.id
    );
    setParentTask(parentTask);
  }, [taskDetails]);

  const handleSelectUser = async (user) => {
    if (user) {
      const data = await dispatch(
        updateMyBoardTask({
          assigneeId: +user.userId,
          goalId: +taskDetails.id,
          action: {
            oldValue: +taskDetails.assigneeId,
            newValue: +user.userId,
            actionName: TASK_ACTIVITY_LOG_ACTIONS.ASSIGNEE_CHANGED,
          },
        })
      );
    } else {
      dispatch(
        updateMyBoardTask({
          assigneeId: null,
          goalId: +taskDetails.id,
          action: {
            oldValue: +taskDetails.assigneeId,
            newValue: null,
            actionName: TASK_ACTIVITY_LOG_ACTIONS.ASSIGNEE_CHANGED,
          },
        })
      );
    }
    setCurrentSelectedUser(user);
    setVisible(false);
  };
  const labelOptions = labels
    ?.filter((item) => item.isMyBoard)
    ?.map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />
            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });

  const onSingleClick = async () => {
    //todo handle this in redux
    dispatch(
      updateMyBoard({ key: "currentSelectedTask", value: { ...taskDetails } })
    );
    if (showSubtaskView && myBoardKanbanView) {
      dispatch(
        updateMyBoard({
          key: "currentSelectedSubTask",
          value: { ...taskDetails },
        })
      );
      navigate(`/my-board/task/${task.parentGoalId}/subtask/${task.id}`);
    } else {
      navigate(`/my-board/task/${task.id}`);
    }

    return;
  };
  const handleDateChange = async (dates) => {
    if (dates[0] && dates[1]) {
      const formattedStartDate = dayjs(dates[0]).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      );
      const formattedEndDate = dayjs(dates[1]).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      );

      await setTaskDetails({
        ...taskDetails,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      await dispatch(
        updateMyBoardTask({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          goalId: task.id,
          action: {
            oldValue: task.timeLogged,
            newValue: dates,
            actionName: TASK_ACTIVITY_LOG_ACTIONS.TIMELOG_CHANGE,
          },
        })
      );
    }
  };

  const handelTaskCheck = (id, e) => {
    // dispatch(updateSchedule({ key: "selectedTasksForDragAndDrop", value: [] }));

    // if (e.target !== e.currentTarget) {
    //   // Click occurred inside child element, skip the action
    //   return;
    // }

    const isCtrlPressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed

    if (!isCtrlPressed) {
      onSingleClick();
    }
    // Ctrl + Click
    else {
      const isSelected = selectedTasksForDragAndDrop.includes(id);
      const newIds = isSelected
        ? selectedTasksForDragAndDrop.filter((taskId) => taskId !== id)
        : [...selectedTasksForDragAndDrop, id];

      dispatch(
        updateSchedule({ key: "selectedTasksForDragAndDrop", value: newIds })
      );
    }
  };
  const formattedPlaceholder =
    taskDetails?.startDate && taskDetails?.endDate
      ? `${dayjs(taskDetails.startDate).format("DD MMM")} - ${dayjs(
          taskDetails.endDate
        ).format("DD MMM")}`
      : "Add Duration";
  useEffect(() => {
    setTaskDetails(task);
  }, [task]);

  return (
    <div
      className={`bg-white group min-w-[250px] p-2 cursor-pointer rounded-lg mt-2 shadow-xs max-h-max hover:border-[2px] hover:border-gray-200 ${
        selectedTasksForDragAndDrop?.includes(task.id)
          ? "border border-primary-300"
          : "border border-transparent"
      }`}
      id={task.id}
      onClick={(e) => {
        handelTaskCheck(task.id, e);
      }}
      // onDoubleClick={onDoubleClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div
            onClick={(e) => {
              console.log(task, "scope checks");
              e.stopPropagation();
            }}
          >
            <TaskType
              isNewGoal={task.isNewGoal}
              taskType={+task.goalType}
              onTaskTypeChange={async (type) => {
                console.log(type, "scop");
                const updateObj: any = {
                  goalType: type === 4 ? 1 : type,
                  goalId: task.id,
                };
                if (type === 4) {
                  updateObj.isNewGoal = Number(type === 4);
                } else {
                  updateObj.isNewGoal = 0;
                }
                // setTask({ ...task, ...updateObj });
                await dispatch(updateGoal(updateObj));
                await dispatch(
                  updateSchedule({ key: "isGoalEdited", value: true })
                );
              }}
            />
          </div>
          <div
            className="out-300-12 text-gray-500 ml-1.5 hover:underline cursor-pointer"
            role="button"
            onClick={onSingleClick}
          >
            {taskDetails.parentGoalId
              ? `SMB-${
                  taskDetails.identifier
                    ? taskDetails.identifier
                    : taskDetails.id
                }`
              : `MB-${
                  taskDetails.identifier
                    ? taskDetails.identifier
                    : taskDetails.id
                }`}
          </div>
        </div>
        <div
          className="flex gap-x-2 items-center"
          onClick={(e) => e.stopPropagation()}
        >
          <CustomDatePicker
            value={dateRange}
            onChange={handleDateChange}
            className={""}
            textClassName="text-gray-600 out-400-10"
            placeholder={formattedPlaceholder}
          />

          <div onClick={(e) => e.stopPropagation()}>
            {/* <UserSelect task={taskDetails} /> */}
            <Popover
            content={              
              <SubtaskUserSelect
                setCurrentSelectedUser={handleSelectUser}
                currentSelectedUser={currentSelectedUser}
                collaborators={parentTask?.collaborators || []}
              />              
            }
            arrow={false}
            placement="bottomLeft"
            trigger={["click"]}
            open={visible}
            onOpenChange={(vis) => setVisible(vis)}
          >
            <button
              className="ml-2 min-h-[20px] min-w-5"
              onClick={() => setVisible(!visible)}
            >
              <Tooltip
                title={
                  currentSelectedUser
                    ? getUsersName(currentSelectedUser)
                    : "Unassigned"
                }
              >
                <div>
                  {currentSelectedUser ? (
                    <CustomAvatar
                      src={currentSelectedUser?.profilePicture}
                      title={getUsersName(currentSelectedUser)}
                      size={20}
                      whiteText
                      fontSize={"10px"}
                      color={currentSelectedUser?.profilePictureColorCode}
                    />
                  ) : (
                    <img src="/images/v2/schedule/Avatar.svg" />
                  )}
                </div>
              </Tooltip>
            </button>
          </Popover>
          </div>
        </div>
      </div>

      <div
        // role="button"
        // onClick={(e) => {
        //   const isCtrlPressed = e.ctrlKey || e.metaKey;
        //   if (!isCtrlPressed) {
        //     navigate(`/schedule/${projectId}/task/${task.id}`);
        //   }
        // }}
        className="out-300-14 text-black my-2.5 max-w-full break-words"
      >
        {task.name}
      </div>

      <div
        className="flex items-center  justify-between "
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex gap-x-2 items-center">
          <PrioritySelect
            task={taskDetails}
            setTask={async (e) => {
              setTaskDetails({ ...taskDetails, priorityId: e });

              await dispatch(
                updateMyBoardTask({ priorityId: e, goalId: taskDetails.id })
              );
            }}
          />
          {myBoardKanbanView && !showSubtaskView && (
            <SubtaskButton
              subTaskLength={taskDetails?.subtask?.length || 0}
              handleClick={() =>
                navigate(`/my-board/task/${taskDetails.id}/subtask/new`)
              }
            />
          )}
          {!showSubtaskView && myBoardKanbanView && (
            <LabelSelect
              visible={true}
              task={taskDetails}
              labelOptions={labelOptions}
              setTask={async (e) => {
                setTaskDetails({ ...taskDetails, labelId: e });
                await dispatch(
                  updateMyBoardTask({ labelId: e, goalId: taskDetails.id })
                );
              }}
            />
          )}
        </div>

        <TimeSelect
          task={taskDetails}
          setTask={setTaskDetails}
          visible={true}
        />
      </div>
    </div>
  );
});

TaskCard.displayName = "TaskCard";

type NewtaskCardProps = {
  status: any; // Replace 'string' with the actual type of 'status'
  isHovered: boolean;
  defaultOpen?: boolean;
  parentTaskId?: string | number;
};

export const NewtaskCard: React.FC<NewtaskCardProps> = ({
  status,
  isHovered,
  defaultOpen = false,
  parentTaskId,
}) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { showNewTaskCard, showSubtaskView, collaboratorsForTask } =
    useAppSelector((state) => state.myBoard);
  const { labels } = useAppSelector((state) => state.schedule);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();
  const { projectId, sprintId } = useParams();
  const [task, setTask] = useState({
    name: "",
    priorityId: 1,
    estimatedTime: "",
    assigneeId: user.id,
    goalType: showSubtaskView ? 4 : 1,
    isNewGoal: 0,
    linkedGoalId: null,
    id: null,
    labelId: null,
    timeLogged: "",
    parentGoalId: showSubtaskView ? showNewTaskCard?.goalId : "",
  });

  const { userList } = useAppSelector((state) => state.overview);
  const assignee = userList.find((user) => +user.userId === +task.assigneeId);

  const [showNewTask, setShowNewTask] = useState(false);
  const ref = useRef(null);
  const labelOptions = labels
    ?.filter((item) => item.isMyBoard)
    ?.map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />

            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });
  const handleClick = () => {
    const selector = `[data-taskitem="${parentTaskId.toString()}"]`;
    // const selector = `[data-kanbanboard="true"]`;

    const newTask = document.querySelector(selector);

    if (newTask) {
      newTask.scrollIntoView({
        inline: "start",
        block: "nearest",
        behavior: "smooth",
      });
    }
  };
  const onSubmit = async () => {
    try {
      const payload = {
        name: task.name,
        isCompleted: 0,
        isNewGoal: task.isNewGoal,
        statusId: status.statusId,
        priorityId: task.priorityId,
        goalType: showSubtaskView ? 4 : task.goalType,
        assigneeId: task.assigneeId,
        isSubTask: showSubtaskView ? 1 : "",
        estimatedTime: task.estimatedTime,
        timeLogged: task.timeLogged,
        labelId: task.labelId,
        parentGoalId: showSubtaskView ? task.parentGoalId : "",
        collaborators: [user.id],
      };

      await dispatch(createMyBoardTask(payload));
      if (parentTaskId && task.timeLogged) {
        // Dispatch the async thunk to update the parent task's time log
        const payload = {
          parentGoalId: parentTaskId,
          minutesToAdd: task.timeLogged,
        };
        dispatch(updateMyBoardParentTaskTimeLog(payload));
      }
      // dispatch(updateSchedule({ key: "selectedTask", value: payload }));
    } catch (e) {
      console.log(e);
    } finally {
      setShowNewTask(false);
      dispatch(resetMyBoard(["showNewTaskCard"]));
      dispatch(getMyBoardTasks({}));
      // queryClient.invalidateQueries(["goalsBySprint", projectId, +sprintId]);
      //todo add get myboard with filters and limit offset
    }
  };

  const handleExpand = () => {
    dispatch(
      updateMyBoard({
        key: "newTask",
        value: {
          goalType: task.goalType,
          name: task.name,
          timeLogged: task.timeLogged,
          priorityid: task.priorityId,
          labelId: task.labelId,
        },
      })
    );
    if (showSubtaskView) {
      navigate(`/my-board/task/${task.parentGoalId}/subtask/new`);
    } else {
      navigate(`/my-board/task/new`);
    }
    dispatch(
      updateMyBoard({
        key: "showNewTaskCard",
        value: { visible: false, channel: "", goalId: "" },
      })
    );
  };

  const EstimatedTimeSelect = ({
    estimatedTime,
    visible = false,
    task,
    setTask,
  }) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen: boolean) => {
      setOpen(newOpen);
    };
    return (
      <Popover
        getPopupContainer={(parent) => parent?.parentElement}
        content={
          <div className="min-w-[200px]">
            <TimeInput
              className=""
              autoFocus
              value={task.estimatedTime}
              onChange={(e) => setTask({ ...task, estimatedTime: e })}
              onOk={() => setOpen(false)}
            />
          </div>
        }
        arrow={false}
        open={open}
        onOpenChange={handleOpenChange}
        placement="bottomLeft"
        trigger={"click"}
        destroyTooltipOnHide
      >
        {estimatedTime ? (
          <Tooltip title={"Estimated Time"}>
            <img
              className="cursor-pointer"
              src="/images/v2/schedule/hourglass-02.svg"
            />
          </Tooltip>
        ) : (
          <Tooltip title={"Estimated Time"}>
            <img
              src="/images/v2/schedule/hourglass-02.svg"
              className={`group-hover:block cursor-pointer  ${
                visible ? "block" : "hidden"
              }`}
            />
          </Tooltip>
        )}
      </Popover>
    );
  };
  const newTaskCard = (
    <div className="bg-white group relative p-[8px] rounded-lg mt-10 border border-primary-300 isolate shadow-xs max-h-max cursor-pointer">
      <div className="flex absolute top-[-24px] right-0 gap-x-1 items-center">
        <button
          onClick={() => handleExpand()}
          className="flex justify-center items-center"
        >
          <img src="/images/v2/schedule/expand-01.svg" />
        </button>
        <button
          onClick={() => {
            setShowNewTask(false);
            dispatch(resetMyBoard(["showNewTaskCard"]));
          }}
          className="flex justify-center items-center"
        >
          <CrossIcon className="text-gray-500" />
        </button>
      </div>
      <div className="flex justify-between items-start">
        <div className="flex flex-1 items-start">
          <TaskType
            isNewGoal={showSubtaskView ? true : false}
            onTaskTypeChange={async (type) => {
              const updateObj: any = {
                goalType: type === 4 ? 1 : type,
              };
              if (type === 4) {
                updateObj.isNewScope = Number(type === 4);
              }
              setTask({ ...task, ...updateObj });
            }}
          />
          <Input.TextArea
            className="w-full flex-1  new-task-textarea pt-0 "
            placeholder={showSubtaskView ? "Subtask title" : "Task title"}
            onChange={(e) => setTask({ ...task, name: e.target.value })}
            autoSize
            bordered={false}
            onPressEnter={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          />
        </div>
        <div>
          {!showSubtaskView ? (
            <button
              className="relative"
              onClick={() => {
                dispatch(
                  updateMyBoard({
                    key: "addCollaboratorsModal",
                    value: true,
                  })
                );
              }}
            >
              <div
                className={`absolute top-[-6px] right-[-4px]  rounded-full w-[14px] h-[14px] z-20 ${
                  collaboratorsForTask?.length
                    ? "bg-success-600"
                    : "bg-primary-100"
                }`}
              >
                {collaboratorsForTask?.length ? (
                  <p className="text-center text-white out-600-8 pt-[1px]">
                    +{collaboratorsForTask?.length}
                  </p>
                ) : (
                  <div>
                    <PlusIcon
                      width={10}
                      height={10}
                      className="text-primary-600"
                    />
                  </div>
                )}
              </div>
              <CustomAvatar
                src={user.profilePicture}
                size={20}
                title={getUsersName(user)}
                whiteText
                color={user.profilePictureColorCode}
              />
            </button>
          ) : (
            <CustomAvatar
              src={user.profilePicture}
              size={20}
              title={getUsersName(user)}
              whiteText
              color={user.profilePictureColorCode}
            />
          )}
        </div>
      </div>

      {/* <div className="out-300-12 text-black my-2">{task.name}</div> */}

      <div className="flex items-center gap-x-2">
        <PrioritySelect
          task={task}
          setTask={(e) => {
            setTask({ ...task, priorityId: e });
          }}
          visible={true}
        />
        {!showSubtaskView && (
          <LabelSelect
            labelOptions={labelOptions}
            task={task}
            setTask={async (e) => {
              setTask({ ...task, labelId: e });
            }}
            visible={true}
          />
        )}

        <EstimatedTimeSelect
          estimatedTime={task.timeLogged}
          task={task}
          setTask={setTask}
        />
      </div>
    </div>
  );

  if (defaultOpen) {
    if (showSubtaskView && +showNewTaskCard.taskId === +parentTaskId) {
      console.log(parentTaskId, "atleast matches");
      return newTaskCard;
    } else if (showSubtaskView) {
      console.log(parentTaskId, "atleast matches");

      return newTaskCard;
    } else {
      console.log(parentTaskId, "atleast matches");

      return newTaskCard;
    }
  }

  if (!showNewTask && !isHovered) {
    return <></>;
  }
  {
    console.log(showNewTask, isHovered, "hovered task");
  }

  if (!showNewTask && isHovered) {
    // alert("hi");
    return (
      <button
        onClick={async () => {
          // setShowNewTask(true);
          console.log("click update", parentTaskId);
          await dispatch(
            updateMyBoard({
              key: "showNewTaskCard",
              value: { visible: true, channel: status, goalId: parentTaskId },
            })
          );
          handleClick();
        }}
        className="flex px-2.5 mt-2 py-1.5 hover:bg-gray-100 border border-transparent hover:border-gray-100 hover:shadow-xs rounded w-full"
      >
        <PlusIcon className="text-gray-500" />
        <span className="text-gray-500 out-500-12 ml-2">
          {" "}
          {showSubtaskView ? "Create New Subtask" : "Create New Task"}
        </span>
      </button>
    );
  }

  return newTaskCard;
};

NewtaskCard.displayName = "NewTaskCard";

export default TaskCard;
