import Cookies from "js-cookie";
import React from "react";
import CustomButton from "../Common/CustomButton";
import localforage from "localforage";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      errorInfo: {
        componentStack: "",
      },
      hasError: false,
    };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, hasError, errorInfo } = this.state;

    const componentStack =
      errorInfo && errorInfo.componentStack ? errorInfo.componentStack : null;

    const errorMessage = (error || "").toString();

    if (hasError) {
      return (
        <div className=" w-screen  py-[10vh] flex justify-center items-center">
          <div className="min-w-[800px] ">
            <img
              src="/images/icons/waddle-logo.svg"
              className="mx-auto h-20 mb-10"
            />
            <div className="flex justify-center text-center text-bold  mon-600-24 ">
              <p>
                Oops! Something went wrong while loading this page.{" "}
                <span
                  style={{ cursor: "pointer", color: "#0077FF" }}
                  className="underline"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh page
                </span>{" "}
              </p>
            </div>
            <div>
              <p className="mon-500-16 text-center mt-4 max-w-[800px]">
                If the problem persists after refreshing the page, you can try
                <span className="text-error-500"> resetting</span> the app. If
                that doesn&lsquo;t solve the issue, please reach out to support
                for further assistance.{" "}
              </p>
              <div className="flex justify-center gap-x-4 pt-6 pb-4 px-3">
                <CustomButton
                  text="Copy Error Details"
                  className="border border-gray-200 hover:bg-gray-50 text-black bg-white mon-500-16 "
                  height="44px"
                  width="29%"
                  onClick={async () => {
                    await navigator.clipboard.writeText(
                      JSON.stringify({
                        errorMessage,
                        componentStack,
                      })
                    );
                  }}
                />
                <CustomButton
                  text="Reset App"
                  className="mon-500-16 text-white  bg-error-600 "
                  height="44px"
                  width="29%"
                  onClick={() => {
                    localStorage.clear();
                    localforage.clear();
                    // indexedDB.deleteDatabase("localforage");
                    Cookies.remove("jwt");
                    Cookies.remove("refreshToken");
                    window.location.reload();
                  }}
                />
              </div>
            </div>

            {/* {[""]process.env.REACT_APP_ENV} */}
            <div className="card-body">
              <details className="error-details">
                <summary className="py-3 px-4 cursor-pointer select-none w-full outline-none mon-500-16">
                  See Error Details
                </summary>
                <div className="mt-4 px-6 w-full ">
                  <p className="mon-500-14"> {errorMessage && errorMessage}</p>
                  <pre style={{ fontSize: "0.9em", overflowX: "auto" }}>
                    {componentStack}
                  </pre>
                </div>
              </details>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
