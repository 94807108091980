import React from "react";
import Icon from "@ant-design/icons";

const MessageReplySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.867 10.505c-.203-.174-.305-.261-.342-.365a.417.417 0 010-.282c.037-.104.139-.19.342-.365l7.06-6.05c.35-.3.524-.45.673-.455a.417.417 0 01.333.154c.094.115.094.345.094.807v3.579a8.055 8.055 0 016.666 7.93v.51a9.5 9.5 0 00-6.666-3.41v3.491c0 .462 0 .692-.094.807a.417.417 0 01-.333.154c-.149-.004-.324-.154-.674-.454l-7.059-6.05z"
    ></path>
  </svg>
);

const MessageReplyIcon = (props) => {
  return <Icon component={MessageReplySvg} {...props} />;
};

export { MessageReplyIcon };
