import React from "react";
import Icon from "@ant-design/icons";

const ClipBoardSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.334 3.333c.775 0 1.162 0 1.48.085a2.5 2.5 0 011.768 1.768c.085.318.085.705.085 1.48v7.667c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 01-1.093 1.092c-.534.273-1.235.273-2.635.273H7.334c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 01-1.093-1.092c-.272-.535-.272-1.235-.272-2.635V6.666c0-.775 0-1.162.085-1.48a2.5 2.5 0 011.768-1.768c.318-.085.705-.085 1.48-.085m.834 9.166l1.666 1.667 3.75-3.75M8.001 4.999h4c.466 0 .7 0 .878-.09a.833.833 0 00.364-.365c.091-.178.091-.411.091-.878v-.667c0-.466 0-.7-.09-.878a.833.833 0 00-.365-.364c-.178-.091-.412-.091-.878-.091H8c-.467 0-.7 0-.879.09a.833.833 0 00-.364.365c-.09.178-.09.412-.09.878v.667c0 .467 0 .7.09.878.08.157.208.285.364.365.179.09.412.09.879.09z"
    ></path>
  </svg>
);

const ClipBoardIcon = (props) => <Icon component={ClipBoardSvg} {...props} />;

export default ClipBoardIcon;
