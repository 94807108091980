import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetVault,
  updateVault,
} from "../../../Core/redux/slices/vault.slice";
import { Input } from "antd";
import { useLocation, useParams } from "react-router-dom";
import {
  createLink,
  getAllLinks,
  updateVaultLink,
} from "../../../Core/redux/api/vaultAPI";
import { Controller, useForm } from "react-hook-form";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

const NewLinkModal = () => {
  const { newLinkModal, editLinkModal, links } = useAppSelector(
    (state) => state.vault
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { projectId, "*": splat } = useParams();
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { projectList } = useAppSelector((state) => state.projects);
  const projectDetails = projectList.find(
    (project) => project.id === projectId
  );
  const [linkDetails, setLinkDetails] = useState({
    linkName: "",
    url: "",
    username: "",
    password: "",
    description: "",
  });

  useEffect(() => {
    if (editLinkModal.visible) {
      setLinkDetails({
        linkName: editLinkModal.linkName,
        url: editLinkModal.url,
        username: editLinkModal.username,
        password: editLinkModal.password,
        description: editLinkModal.description,
      });
    } else {
      setLinkDetails({
        linkName: "",
        url: "",
        username: "",
        password: "",
        description: "",
      });
    }
  }, [editLinkModal]);

  const onSubmit = async () => {
    // formik.handleSubmit();
    const { linkName, description, url, username, password } = linkDetails;

    if (links) {
      links.map((existingLink) => {
        if (existingLink.name == linkName) {
          console.log("name already exists");
          return;
        }
      });
    }
    // new file[1,2,3]     existing file[4,5,3]
    //duplicate files
    const payload: any = {
      linkName: linkName,
      description: description,
      url: url,
      username: username,
      password: password,
      projectId: projectId,
      senderId: user.id,
      folderId: null,
      notificationId: null,
      tenantId: tenantDetails?.tenantId,
    };

    // if (preFilledVaultModalValues.notificationId) {
    //   payload.notificationId = preFilledVaultModalValues.notificationId;
    // }
    let queryObject: {
      projectId: string;
      folderId?: string;
      tenantId?: string;
    };

    if (projectId == "undefined" || projectId == null) {
      queryObject = { projectId: null, tenantId: tenantDetails?.tenantId };
    } else {
      queryObject = { projectId };
    }

    if (splat?.length > 0) {
      const LocationArray = location.pathname.split("/");
      const lastLocation = LocationArray[LocationArray.length - 1].split("-");
      const newId = lastLocation[lastLocation.length - 1];
      if (newId) {
        queryObject.folderId = newId;
        payload.folderId = newId;
      }
    }

    if (editLinkModal.visible) {
      payload.linkId = editLinkModal.linkId;
      payload.userId = user.id;
      await dispatch(updateVaultLink(payload as any)).then(() => {
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: "New Link",
                subtitle: "One new link uploaded to   ",
                description: location.pathname.includes("my-library")
                  ? "My Library"
                  : projectDetails
                  ? projectDetails?.title
                  : "Global Scope",
              },
            },
          })
        );
        reset();
      });
    } else {
      await dispatch(createLink(payload as any)).then(() => {
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: "New Link",
                subtitle: "One new link uploaded to  ",
                description: location.pathname.includes("my-library")
                  ? "My Library"
                  : projectDetails
                  ? projectDetails?.title
                  : "Global Scope",
              },
            },
          })
        );
        reset();
      });
    }

    setLinkDetails({
      linkName: "",
      url: "",
      username: "",
      password: "",
      description: "",
    });
    dispatch(getAllLinks(queryObject));
    dispatch(resetVault(["newLinkModal", "editLinkModal"]));
  };
  const { control, handleSubmit, reset } = useForm({
    values: {
      linkName: null,
      url: null,
    },
  });

  return (
    <CustomModal
      visible={newLinkModal}
      width="352px"
      onCancel={() =>
        dispatch(updateVault({ key: "newLinkModal", value: false }))
      }
      body={
        <div className="p-4">
          <div>
            <p className="text-black out-500-14 leading-5">
              {editLinkModal.visible ? "Edit Link" : "New Link"}
            </p>
            <div className="mt-4">
              {
                <Controller
                  control={control}
                  name="linkName"
                  rules={{
                    required:
                      "Please enter a name for the link before submitting",
                  }}
                  render={({ field, formState }) => (
                    <CustomInputBox
                      {...field}
                      placeholder="Untitled link"
                      value={linkDetails.linkName}
                      onChange={(e) => {
                        setLinkDetails({
                          ...linkDetails,
                          linkName: e.target.value,
                        });
                        field.onChange(e);
                      }}
                      formState={formState}
                    />
                  )}
                />
              }
            </div>
            <div className="mt-4">
              {
                <Controller
                  control={control}
                  name="url"
                  rules={{
                    required: "Please enter the link before submitting",
                  }}
                  render={({ field, formState }) => (
                    <CustomInputBox
                      {...field}
                      placeholder="Paste URL"
                      value={linkDetails.url}
                      onChange={(e) => {
                        setLinkDetails({
                          ...linkDetails,
                          url: e.target.value,
                        });
                        field.onChange(e);
                      }}
                      formState={formState}
                    />
                  )}
                />
              }
            </div>
            <div className="mt-4">
              <CustomInputBox
                placeholder="Email ID or Username"
                value={linkDetails.username}
                onChange={(e) => {
                  setLinkDetails({ ...linkDetails, username: e.target.value });
                }}
              />
            </div>
            <div className="mt-4">
              <CustomInputBox
                placeholder="Password"
                value={linkDetails.password}
                onChange={(e) => {
                  setLinkDetails({ ...linkDetails, password: e.target.value });
                }}
              />
            </div>
            <div className="mt-4">
              <Input.TextArea
                rows={4}
                autoSize={{ minRows: 4, maxRows: 4 }}
                placeholder="Add a description"
                required={true}
                className="w-full h-full bg-white rounded border border-gray-300 text-gray-500 pl-4 pt-2 active:outline-none"
                onChange={(e) => {
                  setLinkDetails({
                    ...linkDetails,
                    description: e.target.value,
                  });
                }}
                value={linkDetails.description}
              ></Input.TextArea>
            </div>
          </div>
          <div className="flex mt-[33px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => {
                reset();
                dispatch(resetVault(["newLinkModal", "editLinkModal"]));
              }}
            />
            <CustomButton
              text={editLinkModal.visible ? "Save" : "Upload"}
              height="30px"
              width={"66px"}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      }
    />
  );
};

export default NewLinkModal;
