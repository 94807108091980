import React from "react";
import Icon from "@ant-design/icons";

const DCFGradientBarSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="296"
    height="13"
    fill="none"
    viewBox="0 0 296 13"
  >
    <path
      fill="#667085"
      d="M9.519 10.1c-.52 0-.997-.147-1.43-.44-.427-.293-.767-.707-1.02-1.24-.254-.54-.38-1.173-.38-1.9 0-.727.123-1.357.37-1.89.253-.533.593-.943 1.02-1.23.426-.293.896-.44 1.41-.44.526 0 1.003.147 1.43.44.426.287.763.697 1.01 1.23.253.533.38 1.167.38 1.9 0 .727-.127 1.36-.38 1.9-.247.533-.584.947-1.01 1.24-.42.287-.887.43-1.4.43zm-.02-.89c.366 0 .686-.1.96-.3.28-.207.5-.51.66-.91.16-.4.24-.893.24-1.48s-.08-1.077-.24-1.47c-.16-.4-.38-.7-.66-.9a1.59 1.59 0 00-.97-.31c-.36 0-.68.103-.96.31-.28.2-.5.5-.66.9-.154.393-.23.883-.23 1.47s.076 1.08.23 1.48c.16.4.38.703.66.91.28.2.603.3.97.3z"
    ></path>
    <rect
      width="246"
      height="9"
      x="19"
      y="2"
      fill="url(#paint0_linear_4294_1594)"
      rx="4.5"
    ></rect>
    <path
      fill="#667085"
      d="M273.717 10.1c-.453 0-.867-.083-1.24-.25a2.528 2.528 0 01-.93-.75l.64-.64c.153.233.363.42.63.56.267.133.563.2.89.2.307 0 .57-.057.79-.17.227-.12.403-.287.53-.5.133-.213.2-.46.2-.74 0-.287-.067-.533-.2-.74a1.247 1.247 0 00-.54-.49 1.88 1.88 0 00-.83-.17c-.12 0-.24.01-.36.03-.12.013-.237.037-.35.07l.4-.54a1.98 1.98 0 01.85-.19c.373 0 .707.087 1 .26.3.173.537.417.71.73.18.313.27.677.27 1.09 0 .44-.107.83-.32 1.17a2.19 2.19 0 01-.86.79c-.367.187-.793.28-1.28.28zm-.77-3.59v-.57l2.03-2.31 1.12-.01-2.09 2.37-1.06.52zm-1.07-2.61v-.84h4.22v.56l-.82.28h-3.4zm8.051 6.2c-.52 0-.997-.147-1.43-.44-.427-.293-.767-.707-1.02-1.24-.253-.54-.38-1.173-.38-1.9 0-.727.123-1.357.37-1.89.253-.533.593-.943 1.02-1.23.427-.293.897-.44 1.41-.44.527 0 1.003.147 1.43.44.427.287.763.697 1.01 1.23.253.533.38 1.167.38 1.9 0 .727-.127 1.36-.38 1.9-.247.533-.583.947-1.01 1.24a2.43 2.43 0 01-1.4.43zm-.02-.89c.367 0 .687-.1.96-.3.28-.207.5-.51.66-.91.16-.4.24-.893.24-1.48s-.08-1.077-.24-1.47c-.16-.4-.38-.7-.66-.9a1.59 1.59 0 00-.97-.31c-.36 0-.68.103-.96.31-.28.2-.5.5-.66.9-.153.393-.23.883-.23 1.47s.077 1.08.23 1.48c.16.4.38.703.66.91.28.2.603.3.97.3zm6.582.89c-.52 0-.996-.147-1.43-.44-.426-.293-.766-.707-1.02-1.24-.253-.54-.38-1.173-.38-1.9 0-.727.124-1.357.37-1.89.254-.533.594-.943 1.02-1.23.427-.293.897-.44 1.41-.44.527 0 1.004.147 1.43.44.427.287.764.697 1.01 1.23.254.533.38 1.167.38 1.9 0 .727-.126 1.36-.38 1.9-.246.533-.583.947-1.01 1.24-.42.287-.886.43-1.4.43zm-.02-.89c.367 0 .687-.1.96-.3.28-.207.5-.51.66-.91.16-.4.24-.893.24-1.48s-.08-1.077-.24-1.47c-.16-.4-.38-.7-.66-.9a1.59 1.59 0 00-.97-.31c-.36 0-.68.103-.96.31-.28.2-.5.5-.66.9-.153.393-.23.883-.23 1.47s.077 1.08.23 1.48c.16.4.38.703.66.91.28.2.604.3.97.3z"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_4294_1594"
        x1="19"
        x2="265"
        y1="6.5"
        y2="6.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7F3FF"></stop>
        <stop offset="0.157" stopColor="#D1E9FF"></stop>
        <stop offset="0.455" stopColor="#2E90FA"></stop>
        <stop offset="0.93" stopColor="#0D3271"></stop>
      </linearGradient>
    </defs>
  </svg>
);

const DCFGradientBarIcon = (props) => (
  <Icon component={DCFGradientBarSvg} {...props} />
);

export { DCFGradientBarIcon };
