import React, { useState } from "react";
import { Select, Input, Divider, Empty } from "antd";
import SearchOutlined from "@ant-design/icons/SearchOutlined";

type CustomSelectProps = {
  options?: any;
  value?: any;
  onDropdownVisibleChange?: any;
  onChange?: (e) => void;
  onMouseOver?: (e) => void;
  placeholder?: React.ReactNode;
  border?: boolean;
  backgroundColor?: string;
  disabled?: boolean;
  formikHook?: any;
  name?: string;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  wrapperClassName?: string;
  onFocusChange?: (isTrue, e) => void;
  showBorderOnlyOnFocused?: boolean;
  defaultOpen?: boolean;
  showSearch?: boolean;
  group?: boolean;
};

const CustomSelect = ({
  options = [],
  value,
  onDropdownVisibleChange,
  onChange,
  placeholder,
  border = true,
  onMouseOver,
  backgroundColor = "bg-white",
  disabled,
  formikHook: formik,
  name,
  showError,
  errorText,
  wrapperClassName,
  onFocusChange,
  helperText,
  showBorderOnlyOnFocused = false,
  defaultOpen = false,
  showSearch = false,
  group = false,
}: CustomSelectProps) => {
  const [focused, setFocused] = useState(defaultOpen);

  if (formik && name) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
    // valid =
    //   valid ||
    //   (!formik.errors[name] &&
    //     formik.initialValues[name] !== formik.values[name])
  }
  console.log(formik, "formik");

  return (
    <div
      className={`
  transition-shadow duration-300 
  ${border ? "" : ""} 
  ${showBorderOnlyOnFocused && focused ? "border" : ""} 
  ${focused ? "border" : ""} 
  ${
    !border && !showBorderOnlyOnFocused
      ? ""
      : focused
      ? "border-primary-500 shadow-input-focus"
      : disabled
      ? "border-grey-200"
      : "border-grey-200"
  } 
  bg-white rounded-lg h-11 flex items-center w-full ${wrapperClassName}
`}
    >
      <div
        className={`
          rounded-xl w-full bg-transparent  h-5`}
      >
        <Select
          //id={id}
          showSearch={showSearch}
          className={`w-full h-5 flex items-center`}
          bordered={false}
          onFocus={(e) => {
            setFocused(true);
            if (typeof onFocusChange === "function") onFocusChange(true, e);
          }}
          onBlur={(e) => {
            setFocused(false);
            if (typeof onFocusChange === "function") onFocusChange(false, e);
          }}
          getPopupContainer={(trigger) => trigger.parentElement}
          //   defaultValue={

          //   }
          //open={true}
          defaultOpen={defaultOpen}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          suffixIcon={
            <img src="/images/icons/chevron-down-gray.svg" alt="down-arrow" />
          }
          //open={true}
          popupClassName={`truncate max-h-[320px] ${
            border ? "border border-gray-100" : ""
          }  bg-white rounded-lg`}
          dropdownRender={(menu) => (
            <>
              <div className="  hidden-scrollbar ">{menu}</div>
            </>
          )}
          onDropdownVisibleChange={(open) => {
            setFocused(open);
            if (typeof onDropdownVisibleChange === "function") {
              onDropdownVisibleChange(open);
            }
          }}
          onChange={onChange}
          filterOption={(input, option) => {
            const optionLabel = `${option.label || option.value}`.toLowerCase();
            return optionLabel.includes(input?.toLowerCase());
          }}
        >
          {options.length > 0 ? (
            group ? (
              <>
                {options.map((group) => (
                  <Select.OptGroup
                    label={
                      <p className="out-300-14  text-gray-500 px-3 py-2 border-b border-gray-200">
                        {group.title}
                      </p>
                    }
                    key={group.id}
                  >
                    {group.options.map((option, index) => (
                      <Select.Option
                        key={option.id}
                        value={option.value}
                        label={option.label || option.value}
                        className="py-2.5"
                      >
                        {option.render(value)}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </>
            ) : (
              options.map((option, index) => (
                <Select.Option
                  key={option.id}
                  value={option.value}
                  label={option.label || option.value}
                  className="py-2.5"
                  onMouseOver={onMouseOver}
                >
                  {option.render(value)}
                </Select.Option>
              ))
            )
          ) : (
            <Empty />
          )}
        </Select>
      </div>
    </div>
  );
};

export default CustomSelect;
