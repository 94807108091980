import React from "react";
import Icon from "@ant-design/icons";

const CircleSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <circle cx="10" cy="10" r="10" fill="#F9FBFE"></circle>
    <circle cx="10" cy="10" r="8" fill="currentColor"></circle>
  </svg>
);

const CircleIcon = (props) => {
  return <Icon component={CircleSvg} {...props} />;
};

export { CircleIcon };
