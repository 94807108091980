import React from "react";
// import CustomButton from "../../../Core/CommonV2/CustomButton";
import { Checkbox, DatePicker, Popover } from "antd";

import CustomButton from "../../Core/CommonV2/CustomButton";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../Core/utils/getUserName";
import CustomBadge from "../../Core/CommonV2/CustomBadge";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";

import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import dayjs from "dayjs";

const FilterLayout = ({
  filterName,
  children,
  footer = false,
  onClear = () => null,
}) => {
  return (
    <div
      className="w-[320px] bg-white border border-gray-100 rounded-lg "
      style={{
        boxShadow:
          "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      }}
    >
      <h3 className="out-300-14 pt-[14px] pb-2.5 px-[14px] border-b border-gray-200 text-gray-500">
        {filterName}
      </h3>
      <div className="max-h-[304px] overflow-y-auto">{children}</div>

      {footer ? (
        <div className="flex border-t border-gray-200 pt-[2px] pb-[2px] justify-end items-center">
          <CustomButton
            className="bg-transparent out-500-14 text-gray-500"
            text="Clear"
            onClick={onClear}
          />
          <CustomButton
            className="bg-transparent out-500-14 text-primary-600"
            text="Done"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const DurationFilter = () => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.dashboard);

  const handleClick = (e) => {
    console.log("duration", dayjs(e[0]).format("DD/mm/yyyy"));

    const newDurationFilters = e;
    dispatch(
      updateDashboard({
        key: "filters",
        value: { ...filters, duration: newDurationFilters },
      })
    );
  };
  // const dates =
  //   value && value[0] && value[1]
  //     ? [dayjs(value[0]), dayjs(value[1])]
  //     : [null, null];
  return (
    <FilterLayout
      filterName={"Type"}
      footer={true}
      onClear={() =>
        dispatch(
          updateDashboard({
            key: "filters",
            value: { ...filters, duration: [] },
          })
        )
      }
    >
      <DatePicker.RangePicker
        onChange={(e) => {
          handleClick(e);
        }}
      />
    </FilterLayout>
  );
};

const LeadFilters = () => {
  const dispatch = useAppDispatch();
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { filters } = useAppSelector((state) => state.dashboard);
  const leadFilters = filters.lead || [];
  const handleClick = (id) => {
    if (leadFilters.includes(id)) {
      const newleadFilters = leadFilters.filter((i) => i !== id);
      dispatch(
        updateDashboard({
          key: "filters",
          value: { ...filters, lead: newleadFilters },
        })
      );
    } else {
      const newleadFilters = [...leadFilters, id];
      dispatch(
        updateDashboard({
          key: "filters",
          value: { ...filters, lead: newleadFilters },
        })
      );
    }
  };
  return (
    <FilterLayout
      filterName={"Lead"}
      footer={true}
      onClear={() =>
        dispatch(
          updateDashboard({
            key: "filters",
            value: { ...filters, lead: [] },
          })
        )
      }
    >
      {tenantMembers.map((tenant) => (
        <div
          key={getUsersName(tenant)}
          role="button"
          onClick={() => handleClick(tenant.userId)}
          className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
        >
          <div className="flex items-center">
            <CustomAvatar
              src={tenant.profilePicture}
              title={getUsersName(tenant)}
              size={24}
              whiteText
              fontSize={"12px"}
            />
            <span className="ml-2 out-300-14 text-gray-900">
              {getUsersName(tenant)}
            </span>
          </div>
          <button>
            <Checkbox checked={leadFilters.includes(tenant.userId)} />
          </button>
        </div>
      ))}
    </FilterLayout>
  );
};

const StatusFilter = () => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.dashboard);
  const statusFilters = filters.status || [];

  const handleClick = (id) => {
    if (statusFilters.includes(id)) {
      const newStatusFilters = statusFilters.filter((i) => i !== id);
      dispatch(
        updateDashboard({
          key: "filters",
          value: { ...filters, status: newStatusFilters },
        })
      );
    } else {
      const newStatusFilters = [...statusFilters, id];
      dispatch(
        updateDashboard({
          key: "filters",
          value: { ...filters, status: newStatusFilters },
        })
      );
    }
  };

  console.log(statusFilters.includes(2), statusFilters, "checked");

  return (
    <FilterLayout
      filterName={"Status"}
      footer={true}
      onClear={() =>
        dispatch(
          updateDashboard({
            key: "filters",
            value: { ...filters, status: [] },
          })
        )
      }
    >
      <div
        role="button"
        onClick={() => handleClick(1)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <CustomBadge
          text="Ongoing"
          color="#165ABF"
          className="border border-[#165ABF]"
          dot
        />
        <Checkbox checked={statusFilters.includes(1)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(2)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <CustomBadge
          text="Completed"
          color="#039855"
          className="border border-[#039855]"
          dot
        />
        <Checkbox checked={statusFilters.includes(2)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(3)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <CustomBadge
          text="Discarded"
          color="#B42318"
          className="border border-[#B42318]"
          dot
        />
        <Checkbox checked={statusFilters.includes(3)} />
      </div>
      <div
        role="button"
        onClick={() => handleClick(4)} // New filter ID for Upcoming
        className="flex cursor-pointer hover:bg-gray-50 justify-between px-[14px] py-2.5 "
      >
        <CustomBadge
          text="Upcoming"
          color="#667085"
          className="border border-[#667085]"
          dot
        />
        <Checkbox checked={statusFilters.includes(4)} />
      </div>
    </FilterLayout>
  );
};

const FilterItem = ({ filterName, filterContent }) => {
  return (
    <Popover content={filterContent} arrow={false} placement="leftTop">
      <div className="flex cursor-pointer hover:bg-gray-50 justify-between px-[14px] py-2.5 ">
        <p className="out-300-14 text-gray-900 ">{filterName}</p>
        <ChevronIcon className="text-primary-600 rotate-[-90deg] transform " />
      </div>
    </Popover>
  );
};

const Filters = () => {
  return (
    <div>
      <FilterLayout filterName={"Filters"}>
        <FilterItem filterContent={<LeadFilters />} filterName={"Lead"} />
        <FilterItem
          filterContent={<DurationFilter />}
          filterName={"Duration"}
        />
        <FilterItem filterContent={<StatusFilter />} filterName={"Status"} />
        {/* <FilterItem
          filterContent={<PriorityFilters />}
          filterName={"Priority"}
        />
        <FilterItem filterContent={<LabelFilter />} filterName={"Label"} /> */}
      </FilterLayout>
    </div>
  );
};

const AppliedFilterItem = ({ filterName, filterCount, filterContent }) => {
  return (
    <Popover
      trigger={"click"}
      content={filterContent}
      arrow={false}
      placement="bottomLeft"
    >
      <button className="flex items-center justify-center px-2.5 py-[4px] gap-x-2  rounded border border-gray-200 bg-white">
        <p className="out-500-14 text-gray-700">
          {filterName} : {filterCount}
        </p>
        <ChevronIcon className=" text-gray-700" />
      </button>
    </Popover>
  );
};

export const AppliedFiltersDashboard = () => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.dashboard);
  const leadFilters = filters.lead || [];
  const durationFilters = filters.duration || [];
  const statusFilters = filters.status || [];

  const allFilters = [...leadFilters, ...durationFilters, ...statusFilters];

  return (
    <div className="flex gap-x-3 items-center">
      {allFilters.length > 0 && (
        <CustomButton
          text="Reset All filters"
          height="30px"
          className="bg-transparent text-gray-500 out-500-14"
          onClick={() =>
            dispatch(
              updateDashboard({
                key: "filters",
                value: {
                  lead: [],
                  duration: [],
                  status: [],
                },
              })
            )
          }
        />
      )}
      {leadFilters.length > 0 && (
        <AppliedFilterItem
          filterCount={leadFilters.length}
          filterName={"Lead"}
          filterContent={<LeadFilters />}
        />
      )}
      {durationFilters.length > 0 && (
        <AppliedFilterItem
          filterCount={`${dayjs(durationFilters[0]).format("DD/MM/YY")}-${dayjs(
            durationFilters[1]
          ).format("DD/MM/YY")}`}
          filterName={"Duration"}
          filterContent={<DurationFilter />}
        />
      )}
      {statusFilters.length > 0 && (
        <AppliedFilterItem
          filterCount={statusFilters.length}
          filterName={"Status"}
          filterContent={<StatusFilter />}
        />
      )}
    </div>
  );
};

export default Filters;
