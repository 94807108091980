import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { PdfFileIcon } from "../../../Core/svgV2/UploadingFileIcon";
import { Progress } from "antd";
import { TrashIcon } from "../../../Core/svgV2/TrashIcon";
import { UploadSucessIcon } from "../../../Core/svgV2/UploadSuccessIcon";

const UploadFileModal = () => {
  const renderUploadStatus = (isUploading) => {
    switch (isUploading) {
      case "uploading":
        return (
          <div className="px-4 flex items-start justify-between">
            <div className="flex items-start gap-x-6">
              <PdfFileIcon className="text-primary-600 mt-1" />
              <div>
                <p className="out-500-14 leading-5 text-black">
                  Tech design requirements.pdf
                </p>
                <p className="out-300-12 leading-[18px] text-gray-500">
                  200 KB – 40% uploaded
                </p>
              </div>
            </div>
            <Progress
              type="circle"
              showInfo={false}
              size={28}
              trailColor="#EFF8FF"
              strokeColor="#165ABF"
              percent={40}
            />
          </div>
        );
      case "success":
        return (
          <div className="px-4 flex items-start justify-between">
            <div className="flex items-start gap-x-6">
              <PdfFileIcon className="text-primary-600 mt-1" />
              <div>
                <p className="out-500-14 leading-5 text-black">
                  Tech design requirements.pdf
                </p>
                <p className="out-300-12 leading-[18px] text-gray-500">
                  200 KB – 100% uploaded
                </p>
              </div>
            </div>
            <UploadSucessIcon className="mt-1" />
          </div>
        );
      case "failed":
        return (
          <div className="px-4 flex items-start justify-between">
            <div className="flex items-start gap-x-6">
              <PdfFileIcon className="text-error-600 mt-1" />
              <div>
                <p className="out-500-14 leading-5 text-error-700">
                  Upload failed, please try again
                </p>
                <p className="out-300-12 leading-[18px] text-error-600">
                  Example dashboard screenshot.jpg
                </p>
                <p className="out-500-14 leading-5 text-error-700">Try again</p>
              </div>
            </div>
            <TrashIcon className="text-error-700 mt-1" />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <CustomModal
      visible={false}
      width="400px"
      onCancel={() => console.log("closed")}
      body={
        <div className="px-4 pt-3 pb-6">
          <div className="flex justify-between">
            <p className="text-black out-500-14 leading-5">Uploading Files</p>
            <div className="flex items-center gap-x-1">
              <ChevronIcon className="text-gray-700" />
              <CrossIcon className="text-gray-700" />
            </div>
          </div>
          <div className="h-[310px] mt-10 overflow-y-auto custom-scrollbar">
            <div className="">{renderUploadStatus("uploading")}</div>
            <div className="mt-6">{renderUploadStatus("uploading")}</div>
            <div className="mt-6">{renderUploadStatus("failed")}</div>
            <div className="mt-6">{renderUploadStatus("failed")}</div>
            <div className="mt-6">{renderUploadStatus("failed")}</div>
            <div className="mt-6">{renderUploadStatus("success")}</div>
          </div>
        </div>
      }
    />
  );
};

export default UploadFileModal;
