import React from "react";
import Icon from "@ant-design/icons";

const PrioritySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 11h9.095l-2-3 2-3.5H6v4"
    ></path>
    <path stroke="currentColor" strokeLinecap="round" d="M6 11v6"></path>
  </svg>
);

const PriorityIcon = (props) => {
  return <Icon component={PrioritySvg} {...props} />;
};

export { PriorityIcon };
