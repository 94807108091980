import React, { useState } from "react";
import ViewToggleButton from "./ViewToggleButton";
import NewButton from "./NewButton";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import { SearchElongatedIcon } from "../../../Core/svgV2/SearchIcon";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetVault,
  updateVault,
} from "../../../Core/redux/slices/vault.slice";
import { useNavigate, useParams } from "react-router-dom";
import usePermission from "../../../Core/hooks/usePermission";

const ProjectDocsMenuBar = ({ viewType, setViewType, onUploadClick }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { projectList } = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { currentSelectedProject, search } = useAppSelector(
    (State) => State.vault
  );
  const { projectId, "*": splat } = useParams();

  const pathSnippets = splat?.split("/").filter((i) => i);

  let routeName = currentSelectedProject.title;
  if (!currentSelectedProject.title) {
    const currentProject = projectList.find((p) => p.id == projectId);
    if (currentProject?.title) {
      routeName = currentProject.title;
    }
  }
  if (pathSnippets && pathSnippets.length) {
    routeName = pathSnippets[pathSnippets.length - 1].split("-")[0];
  }

  const { "*": spalt } = useParams();

  const { hasPermission: FOLDER_FILE_LINK_UPLOAD } = usePermission("22");

  const { currentSelectedFolder } = useAppSelector((state) => state.vault);

  let hasPermission = FOLDER_FILE_LINK_UPLOAD;

  if (spalt.length) {
    hasPermission =
      currentSelectedFolder?.canEdit === 1 ||
      currentSelectedFolder?.isOwner === 1;
  }

  return (
    <div className="flex w-full py-2 px-6 bg-white border-b border-gray-200 items-center justify-between">
      <div
        className="flex cursor-pointer items-center gap-x-2"
        onClick={() => {
          dispatch(resetVault(["currentSelectedProject"]));
          navigate(-1);
          //navigate("/library");
        }}
      >
        <ChevronIcon className="text-black rotate-90" />
        <p className="out-500-14 text-black">{routeName}</p>
      </div>
      <div className="flex items-center ">
        {isSearchOpen && (
          <div>
            <CustomSearchBox
              // isCancel={true}
              suffix={
                <button
                  onClick={() => {
                    setIsSearchOpen(false);
                    dispatch(updateVault({ key: "search", value: "" }));
                  }}
                >
                  <CrossIcon />
                </button>
              }
              // setSearch={(val) => handelSearch(val)}
              placeholder="Search in Library"
              className="w-[238px] h-[30px]"
              search={search}
              setSearch={(s) =>
                dispatch(updateVault({ key: "search", value: s }))
              }
            />
          </div>
        )}
        <div className="cursor-pointer">
          <button
            className={`${isSearchOpen ? "hidden" : "block"}`}
            onClick={() => setIsSearchOpen(!isSearchOpen)}
          >
            <SearchElongatedIcon className="text-gray-500" />
          </button>
        </div>
        {/* <div className="flex  ml-6 items-center gap-x-2 cursor-pointer">
          <FilterIcon />
          <p className="out-500-14 leading-5 text-gray-500">Filter</p>
        </div> */}
        <div className="ml-[22px] mr-[12px]">
          <ViewToggleButton viewType={viewType} setViewType={setViewType} />
        </div>
        <NewButton onUploadClick={onUploadClick} />
      </div>
    </div>
  );
};

export default ProjectDocsMenuBar;
