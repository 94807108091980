import React, { useEffect, useRef, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { Popover } from "antd";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { useSearchParams } from "react-router-dom";
import { convertMinutesToTimeLog } from "../../../Core/utils/timeLogUtils";

const RoundedBar = (props) => {
  const { x, y, width, height } = props;

  const radius = 5; // Set the radius for rounded corners

  if (height === 0) {
    return null; // Render nothing if height is zero
  }

  return (
    <g>
      <path
        d={`M${x},${y + radius} 
              A${radius},${radius} 0 0 1 ${x + radius},${y} 
              L${x + width - radius},${y} 
              A${radius},${radius} 0 0 1 ${x + width},${y + radius} 
              L${x + width},${y + height} 
              L${x},${y + height} Z`}
        fill={props.fill}
      />
    </g>
  );
};

function CustomTooltip({
  payload,

  active,
}: {
  payload?: any;

  active?: any;
}) {
  const [searchParams] = useSearchParams();
  const isTimeLogged = searchParams.get("report") === "ReportByTimeLog";
  if (active && payload?.[0].value > 0) {
    return (
      <div className="bg-white rounded-lg p-3 min-w-[168px]">
        <p className="pb-2 text-black border-b border-gray-200 out-500-12">
          {isTimeLogged
            ? convertMinutesToTimeLog(payload?.[0].value)
            : `${payload?.[0].value} tasks`}
        </p>
        <p className="pt-2 text-gray-500 out-500-12">Added By</p>
        {payload?.[0].payload?.users.map((user, i) => (
          <p key={i} className="pt-2 text-black out-300-12">
            {user.name} - {user.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
}

const ReportStatus = ({ report, total }) => {
  const [currentTimeUnit, setCurrentTimeUnit] = useState("Default");

  const Unit = ({ name }) => {
    return (
      <div
        role="button"
        onClick={() => setCurrentTimeUnit(name)}
        className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
      >
        <p className="text-gray-900 out-300-14">{name}</p>
        {name == currentTimeUnit && <CheckIcon className="text-primary-600" />}
      </div>
    );
  };
  if (report === "ReportByTask") {
    return (
      <div className="ml-[114px] mt-4">
        <p className="text-gray-500 out-300-14">Total no. of tasks</p>
        <p className="text-black out-500-18">{total}</p>
      </div>
    );
  } else {
    return (
      <div className="ml-[114px] mt-4">
        <p className="text-gray-500 out-300-14">Total logged times</p>
        <div className="flex items-center gap-x-2">
          <p className="text-black out-500-18">{total}</p>
          <Popover
            content={
              <div
                className="w-[200px] bg-white border border-gray-100 rounded-lg overflow-hidden"
                style={{
                  boxShadow:
                    "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
                }}
              >
                <h3 className="out-300-14 pt-[14px] pb-2.5 px-[14px] border-b border-gray-200 text-gray-500">
                  Time Unit
                </h3>
                <Unit name={"Default"} />
                <Unit name={"Hours"} />
                <Unit name={"Hours/Min"} />
                <Unit name={"Days"} />
                <Unit name={"Weeks"} />
                <Unit name={"Months"} />
              </div>
            }
            arrow={false}
            placement="bottomLeft"
          >
            <button>
              {" "}
              <ChevronIcon className="text-gray-500" />
            </button>
          </Popover>
        </div>
      </div>
    );
  }
};

const Reports = ({ report, data }) => {
  const [barData, setBarData] = useState();
  useEffect(() => {
    setBarData(data?.value);
  }, [data]);

  const ref = useRef(null);
  //IMP : to remove the hover effect on bar you can add scale point to data

  const getWidth = () => {
    const minWidth = 500;
    let width = minWidth;
    if (data?.value) {
      const calculatedWidth = data.value.length * 160;
      width = calculatedWidth > minWidth ? calculatedWidth : minWidth;
    }
    return width;
  };

  return (
    <div className="h-full p-4 ">
      <> {console.log(ref.current, "ref.cureen")}</>
      <ReportStatus report={report} total={data?.total} />
      <div
        className="flex flex-row  overflow-x-scroll overflow-y-hidden mt-[21px] pb-5"
        onScroll={(e) => {
          const ele = document.getElementsByClassName(
            "recharts-yAxis"
          )[0] as HTMLElement;
          ele.style.transform =
            "translateX(" + (e.target as HTMLDivElement).scrollLeft + "px)";
          // ele.style.fill = "#00000";
        }}
      >
        {/* <ResponsiveContainer width="80%" height="80%" className="mt-[21px]"> */}
        <BarChart
          data={data?.value}
          barSize={32}
          maxBarSize={32}
          barGap={80}
          barCategoryGap={80}
          height={500}
          width={getWidth()}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid color="#EDF3F9" vertical={false} />
          <XAxis
            dataKey="name"
            // scale="point"
            padding={{ left: 50, right: 50 }}
            tick={{
              fontFamily: "Outfit",
              fontSize: 12,
              fill: "#292927",
              fontWeight: 300,
            }}
            label={{
              value: data?.xAxisLable,
              position: "insideBottom",
              offset: -10,
              style: {
                fontFamily: "Outfit",
                fontSize: 12,
                fill: "#292927",
                fontWeight: 500,
              },
            }}
          />
          <YAxis
            tick={{
              fontFamily: "Outfit",
              fontSize: 12,
              fill: "#292927",
              fontWeight: 300,
            }}
            max={20}
            tickCount={10}
            label={{
              value: data?.yAxisLable,
              angle: -90,
              position: "insideLeft",
              style: {
                fontFamily: "Outfit",
                fontSize: 12,
                fill: "#292927",
                fontWeight: 500,
              },
            }}
            ref={ref}
            style={{ background: "#f0f0f0" }}
          />

          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          {/* <Brush dataKey="name" height={30} stroke="#8884d8" /> */}
          <Bar shape={<RoundedBar />} dataKey="value" fill="#7DC7FF" />
        </BarChart>
        {/* </ResponsiveContainer> */}
      </div>
    </div>
  );
};

export default React.memo(Reports);
