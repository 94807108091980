import { Progress, Dropdown, Tooltip, Popover, Pagination } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useState, useEffect } from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";
import { useAppSelector, useAppDispatch } from "../../Core/redux/hooks";

import { useNavigate } from "react-router-dom";
//custom components
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import CustomTable from "../../Core/CommonV2/CustomTable";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
//icons
import { FilterIcon } from "../../Core/svgV2/FilterIcon";
import { PlusIcon } from "../../Core/svgV2/PlusIcon";
import { LibraryIcon, ScheduleIcon } from "../../Core/LayoutV2/LayoutIcons";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { PinnedIcon } from "../../Core/svgV2/Pinned";
import { SettingIcon } from "../../Core/svgV2/SettingsIcon";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";

import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import DiscardDeleteProjectModal from "./Modals/DiscardDeleteProject";

import {
  getProjectList,
  updateProjectDetails,
} from "../../Core/redux/api/projectAPI";
import Filters, { AppliedFiltersDashboard } from "./Filters";
import StatusBadge from "./Components/StatusBadge";
import { CrossIcon } from "../../Core/svgV2/CrossIcon";
import usePermission from "../../Core/hooks/usePermission";
import { getDefaultNavigation } from "../../Core/redux/api/settingsAPI";
import { getScreenName } from "../../Core/utils/scheduleUtils";
import { useGetProjectList } from "../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import AlertIcon from "../../Core/svgV2/AlertIcon";

//todo import proper icons, background color of unselected segmented items on hover
const AllProjects = ({ socket }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useAppSelector((state) => state.overview.projectDetails);
  const { user } = useAppSelector((state) => state.userDetails);
  const { data: projectListData, isLoading: isLoadingProjectList } =
    useGetProjectList(user.id);

  const projectList = !isLoadingProjectList ? projectListData : [];

  const { lastVisitedSchedulePage } = useAppSelector((state) => state.schedule);
  const { discardProjectModal, filters } = useAppSelector(
    (state) => state.dashboard
  );

  const [limit, setLimit] = useState(10);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [title, setTitle] = useState("");
  const [maxProjectsPinned, setMaxProjectsPinned] = useState(0);
  const { defaultNavigation } = useAppSelector((state) => state.settings);

  const [search, setSearch] = useState("");
  useEffect(() => {
    let pinnedProjects = projectList?.filter((p) => p.isPinned == 1);
    if (pinnedProjects.length >= 3) {
      setMaxProjectsPinned(1);
      pinnedProjects = pinnedProjects.slice(0, 3);
    } else {
      setMaxProjectsPinned(0);
    }
  }, [projectList]);

  useEffect(() => {
    dispatch(getDefaultNavigation({ userId: user.id }));
  }, [user.id]);

  const sortProjects = useCallback(
    (projects) => {
      const pinnedProjects = projects.filter((p) => p.isPinned == 1);

      const unPinnedProjects = projects.filter(
        (p) => p.isPinned != 1 && p.isDiscarded != 1
      );
      const discardedProjects = projects.filter((p) => p.isDiscarded == 1);
      console.log([
        ...pinnedProjects,
        ...unPinnedProjects,
        ...discardedProjects,
      ]);
      return [...pinnedProjects, ...unPinnedProjects, ...discardedProjects];
    },
    [projectList]
  );
  const handleFilter = useCallback(
    (projects) => {
      let filteredProjects = [...projects];

      // Filter by lead
      if (filters.lead && filters.lead.length > 0) {
        filteredProjects = filteredProjects.filter((project) =>
          filters.lead.includes(project.createdById)
        );
      }

      // Filter by duration
      if (filters.duration && filters.duration.length > 0) {
        filteredProjects = filteredProjects.filter((project) => {
          return (
            dayjs(filters.duration[0]).isSameOrBefore(
              dayjs(project.startDate),
              "day"
            ) &&
            (dayjs(filters.duration[1]).isSame(dayjs(project.endDate), "day") ||
              dayjs(filters.duration[1]).isAfter(dayjs(project.endDate), "day"))
          );
        });
      }

      // Filter by status (Ongoing, Completed, Discarded, Upcoming)
      if (filters.status && filters.status.length > 0) {
        filteredProjects = filteredProjects.filter((project) => {
          const isUpcoming = dayjs(project.startDate).isAfter(dayjs()); // Check if project is upcoming

          return (
            (filters.status.includes(3) && project.isDiscarded === 1) || // Discarded
            (filters.status.includes(2) &&
              project.progress === 100 &&
              project.isDiscarded === 0) || // Completed
            (filters.status.includes(1) &&
              project.isDiscarded === 0 &&
              project.progress !== 100) || // Ongoing
            (filters.status.includes(4) && isUpcoming) // Upcoming (new status filter for upcoming projects)
          );
        });
      }

      // Search filter
      if (search) {
        const regex = new RegExp(search, "i");
        filteredProjects = filteredProjects.filter((project) => {
          if (
            regex.test(project.id) ||
            regex.test(project.title) ||
            regex.test(project.fullName)
          ) {
            return true;
          }
        });
      }

      return filteredProjects;
    },

    [filters, projectList, search]
  );

  const { hasPermission } = usePermission("1");
  const activeProjectItems = ({ record }) => [
    maxProjectsPinned !== 1 && {
      key: "1",
      label: (
        <div
          onClick={() => {
            dispatch(
              updateProjectDetails({
                id: record.id,
                isPinned: record.isPinned === 1 ? 0 : 1,
              })
            ).then(() => {
              dispatch(getProjectList(user.id));
            });
          }}
          className="out-300-14 text-gray-700"
        >
          {record.isPinned === 1 ? (
            <div className="out-300-14 text-gray-700">
              Un-pin from dashboard
            </div>
          ) : (
            <div className="out-300-14 text-gray-700">Pin to dashboard</div>
          )}
        </div>
      ),
      icon: <PinnedIcon className="text-gray-700" />,
    },
    maxProjectsPinned &&
      record.isPinned && {
        key: "5",
        label: (
          <div
            onClick={() => {
              dispatch(
                updateProjectDetails({
                  id: record.id,
                  isPinned: record.isPinned === 1 ? 0 : 1,
                })
              ).then(() => {
                dispatch(getProjectList(user.id));
              });
            }}
            className="out-300-14 text-gray-700"
          >
            <div className="out-300-14 text-gray-700">
              Un-pin from dashboard
            </div>
          </div>
        ),
        icon: <PinnedIcon className="text-gray-700" />,
      },
    {
      key: "2",
      label: <div className="out-300-14 text-gray-700">Project Settings</div>,
      icon: <SettingIcon className="text-gray-700" />,
    },
    hasPermission && { key: "3", type: "divider" },

    hasPermission && {
      key: "4",
      label: (
        <div
          className="out-300-14 text-gray-700"
          onClick={() => {
            dispatch(
              updateDashboard({
                key: "discardProjectModal",
                value: {
                  visible: true,
                  type: "discard",
                  title: record.title,
                  id: record.id,
                },
              })
            );
          }}
        >
          Discard
        </div>
      ),
      icon: <DeleteIcon className="text-gray-700" />,
    },
  ];
  // const activeProjectItemsOnPin = ({ record }) => [
  //   {
  //     key: "2",
  //     label: <div className="out-300-14 text-gray-700">Project Settings</div>,
  //     icon: <SettingIcon className="text-gray-700" />,
  //   },
  //   hasPermission && { key: "3", type: "divider" },

  //   hasPermission && {
  //     key: "4",
  //     label: (
  //       <div
  //         className="out-300-14 text-gray-700"
  //         onClick={() => {
  //           dispatch(
  //             updateDashboard({
  //               key: "discardProjectModal",
  //               value: {
  //                 visible: true,
  //                 type: "discard",
  //                 title: record.title,
  //                 id: record.id,
  //               },
  //             })
  //           );
  //         }}
  //       >
  //         Discard
  //       </div>
  //     ),
  //     icon: <DeleteIcon className="text-gray-700" />,
  //   },
  // ];
  // const activeItemsOnclick = ({ key }) => {
  //   switch (key) {
  //     case "1":
  //       break;

  //     default:
  //       break;
  //   }
  // };
  const discardedProjectItems = ({ record }) => [
    {
      key: "1",
      label: <div className="out-300-14 text-gray-700">Project Settings</div>,
      icon: <SettingIcon className="text-gray-700" />,
    },
    hasPermission && { key: "2", type: "divider" },

    hasPermission && {
      key: "3",
      label: (
        <div
          className="out-300-14 text-gray-700"
          onClick={() => {
            console.log(record, "record");
            dispatch(
              updateDashboard({
                key: "discardProjectModal",
                value: {
                  visible: true,
                  type: "delete",
                  title: record.title,
                  id: record.id,
                },
              })
            );
          }}
        >
          Delete permanently
        </div>
      ),
      icon: <DeleteIcon className="text-gray-700" />,
    },
  ];
  const nullSafeCompare = (a, b) => {
    if (!a && !b) {
      return 0;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    return a.localeCompare(b);
  };

  //for sorting based on status of the project (discarded, ongoing, completed respectively)
  const assignStatusId = (a) => {
    const today = dayjs();

    if (a.isDiscarded) {
      return 1; // Discarded
    } else if (dayjs(a.startDate).isAfter(today) && !a.isEnded) {
      return 4; // Upcoming
    } else if (a.isEnded) {
      return 3; // Completed
    } else {
      return 2; // Ongoing
    }
  };

  const columns = [
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          Name
        </div>
      ),
      width: "20%",
      dataIndex: "",
      defaultSortOrder: "",
      sorter: (a, b) => nullSafeCompare(a.title, b.title),
      render: (text, record) => (
        <div className="flex items-center gap-x-3 ">
          <div className="h-[10px] w-[10px] ">
            {record.isPinned ? (
              <PinnedIcon className="text-primary-700 " />
            ) : (
              " "
            )}
          </div>
          <div style={record.title === title ? { opacity: 0.5 } : {}}>
            <CustomAvatar size={20} title={record.title} />
          </div>
          <p
            className={
              record.isDiscarded === 1
                ? `out-500-14 leading-5 text-gray-300`
                : `out-500-14 leading-5 text-gray-700`
            }
          >
            {record.title}
          </p>
          {!record?.hasTimeEstimates && +record?.progress !== 100 && (
            <Tooltip
              title={"Estimates are pending"}
              placement="bottom"
              style={{
                display: "flex",
                padding: "4px 8px",
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "4px",
                background: "#000000",
                color: "#ffffff",
              }}
            >
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`/time-estimates/${record?.id}`);
                }}
                style={{ cursor: "pointer" }}
              >
                <AlertIcon height="14px" width="14px" />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          Lead
        </div>
      ),
      width: "20%",
      dataIndex: "lead",
      sorter: (a, b) => nullSafeCompare(a.fullName, b.fullName),
      render: (text, record) => (
        <div className="flex items-center gap-x-3">
          <CustomAvatar
            size={26}
            title={record.fullName}
            src={record.profilePicture}
          />
          <p className="out-300-14 text-gray-500">{record.fullName}</p>
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          Duration
        </div>
      ),
      width: "20%",
      dataIndex: "duration ",
      defaultSortOrder: "",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="flex items-start out-300-14 text-gray-500">
          <Tooltip
            placement="bottom"
            title={`${dayjs(record.endDate).diff(
              record.startDate,
              "days"
            )} days`}
          >
            {record
              ? `${dayjs(record.startDate).format("MMM DD YYYY")} - ${dayjs(
                  record.endDate
                ).format("MMM DD YYYY")}`
              : "-"}
          </Tooltip>
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          Progress
        </div>
      ),
      width: "20%",
      dataIndex: "progress",
      defaultSortOrder: "",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div
          className={
            record.isDiscarded === 1
              ? `flex items-center justify-center out-500-12  text-gray-300 gap-x-3 `
              : `flex items-center justify-center out-500-12  text-gray-700 gap-x-3 `
          }
        >
          {record.isDiscarded === 1 ? (
            <Progress
              showInfo={false}
              strokeWidth={4}
              strokeColor={"#C0CBD7"}
              percent={text}
              trailColor="#EFF8FF"
              className="progress-bar-tiny"
            />
          ) : (
            <Progress
              showInfo={false}
              strokeWidth={4}
              strokeColor={"#165ABF"}
              percent={text}
              trailColor="#EFF8FF"
              className="progress-bar-tiny"
            />
          )}
          {Math.round(+text)}%
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          Status
        </div>
      ),
      width: "20%",
      dataIndex: "progress,id",
      defaultSortOrder: "",
      sorter: (a, b) => assignStatusId(a) - assignStatusId(b),
      render: (text, record) => (
        <div className="flex items-center justify-start out-500-12 text-gray-700 gap-x-3 ">
          <div className="w-[120px] flex items-center justify-start">
            {record.isDiscarded ? (
              <StatusBadge statusType={1} />
            ) : dayjs(record.startDate).isAfter(dayjs()) && !record.isEnded ? (
              <StatusBadge statusType={4} />
            ) : record.isEnded ? (
              <StatusBadge statusType={3} />
            ) : (
              <StatusBadge statusType={2} />
            )}
          </div>
          <div className="flex items-center w-[138px] gap-x-2 justify-center">
            {/* Button icons for Schedule and Library */}
            <div className="h-[30px] w-[30px] flex items-center justify-center">
              <Tooltip
                title="Schedule"
                placement="bottom"
                className="out-500-12"
              >
                <button
                  onClick={() => {
                    if (!record.isDiscarded) {
                      let screenName = "roadmap";
                      if (defaultNavigation.isEnabled) {
                        screenName = getScreenName(defaultNavigation.screenId);
                      }
                      const scheduleRedirectLink =
                        +lastVisitedSchedulePage?.projectId === +record.id
                          ? lastVisitedSchedulePage?.redirectUrl
                          : record.currentactivesprint
                          ? `/schedule/${record.id}/${screenName}/${
                              screenName == "sprints"
                                ? record.currentactivesprint
                                : ""
                            }`
                          : `/schedule/${record.id}/${screenName}`;
                      navigate(scheduleRedirectLink);
                    }
                  }}
                >
                  <ScheduleIcon
                    className={
                      record.isDiscarded
                        ? `text-gray-300 hover:bg-gray-50`
                        : `text-gray-500 hover:bg-gray-50`
                    }
                  />
                </button>
              </Tooltip>
            </div>
            <div className="h-[30px] w-[30px] flex items-center justify-center">
              <Tooltip
                title="Library"
                placement="bottom"
                className="out-500-12"
              >
                <button
                  onClick={(e) => {
                    if (!record.isDiscarded) {
                      e.stopPropagation();
                      navigate(`/library/${record.id}`);
                    }
                  }}
                >
                  <LibraryIcon
                    className={`${
                      record.isDiscarded
                        ? `text-gray-300 hover:bg-gray-50`
                        : `text-gray-500 hover:bg-gray-50`
                    } z-30`}
                  />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <CustomLayout
      page="Dashboard"
      title={
        <div className="flex">
          <div
            className="out-500-14 text-gray-500 out-300-14 flex cursor-pointer"
            onClick={() => {
              dispatch(
                updateDashboard({ key: "allProjectView", value: false })
              );
              navigate(`/dashboard/${id}`);
            }}
          >
            Dashboard /{" "}
            <div className="text-black out-500-14 flex">
              {" "}
              &nbsp; All Projects
            </div>
          </div>
        </div>
      }
    >
      <div className="w-full h-[110px] border-b border-gray-200 bg-white px-6 pt-3 pb-2.5">
        <div className="flex justify-between">
          <div>
            <p className="out-500-14 leading-5 text-black cursor-pointer">
              {/* {menuBarType.find(({ id }) => id === menuBarId)?.title} */}
              {"All Projects"}
            </p>
            <p className="mt-1.5 out-300-14 leading-5 text-gray-500">
              {/* {menuBarType.find(({ id }) => id === menuBarId)?.subtitle} */}
              {" View and manage all your projects from here."}
            </p>
            <div className="mt-3">
              <CustomSearchBox
                placeholder="Search projects"
                className={`w-[285px] h-[30px] ${
                  search ? "border-primary-300" : ""
                } `}
                style={{ borderRadius: "8px" }}
                search={search}
                suffix={
                  <button
                    onClick={() => {
                      setSearch("");
                    }}
                  >
                    <CrossIcon className="text-gray-500" />
                  </button>
                }
                setSearch={(s) =>
                  // dispatch(updateVault({ key: "search", value: s }))
                  setSearch(s)
                }
              />
            </div>
          </div>
          <div className="flex flex-col items-end  justify-end">
            <div className="mt-5">
              <div className="flex items-center gap-x-[22px] ">
                <AppliedFiltersDashboard />
                <div className="flex items-center gap-x-2 cursor-pointer ">
                  <Popover
                    trigger={["click"]}
                    content={<Filters />}
                    arrow={false}
                    placement="bottomLeft"
                  >
                    <button className="hover:bg-gray-50 h-[30px] w-[30px] flex justify-center items-center">
                      <FilterIcon className="" />
                    </button>
                  </Popover>
                  <p className="out-500-14 leading-5 text-gray-500">Filter</p>
                </div>

                {/* <NewButton onUploadClick={console.log("")} /> */}
                {hasPermission && +user.roleId !== 4 && (
                  <button className="bg-primary-600 text-white out-500-14 w-[170px] h-[30px] py-[5px] px-[10px] flex justify-between items-center rounded-[4px]">
                    <PlusIcon className="flex text-[#F9FBFE] " />
                    <div
                      className="flex cursor-pointer"
                      role="button"
                      onClick={() => navigate("/project-onboardingV2")}
                    >
                      Create New Project
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[calc(100vh-156px)]  bg-white overflow-y-scroll flex-wrap whitespace-nowrap">
        <CustomTable
          className="custom-header custom-table cursor-pointer"
          columns={columns}
          data={sortProjects(handleFilter(projectList))}
          dataCount={projectList.length}
          current={currentPageNo}
          pageSize={limit}
          setPageSize={setLimit}
          customFooter={() => {
            return (
              <div
                className={`flex justify-between  border-gray-200 items-center h-full ${"px-3 "}`}
              >
                <div className="out-500-12 text-gray-400">
                  Showing {limit * (currentPageNo - 1) + 1}-
                  {limit * currentPageNo > projectList.length
                    ? projectList.length
                    : limit * currentPageNo}{" "}
                  of
                  {" " + projectList.length}
                </div>
                <Pagination
                  className="member-table-pagination"
                  total={projectList.length}
                  current={currentPageNo}
                  onChange={(e) => {
                    setCurrentPageNo(e);
                  }}
                  pageSize={limit}
                  size="small"
                />
              </div>
            );
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                console.log(record, "rec");

                let screenName = "roadmap";
                if (defaultNavigation.isEnabled) {
                  screenName = getScreenName(defaultNavigation.screenId);
                }

                const scheduleRedirectLink =
                  +lastVisitedSchedulePage?.projectId === +record.id
                    ? lastVisitedSchedulePage?.redirectUrl
                    : record.currentactivesprint
                    ? `/schedule/${record.id}/${screenName}/${
                        screenName == "sprints"
                          ? record.currentactivesprint
                          : ""
                      }`
                    : `/schedule/${record.id}/${screenName}`;

                // navigate(`/schedule/${record.id}/roadmap`);
                navigate(scheduleRedirectLink);
              }, // click row
            };

            console.log("");
          }}
          setCurrent={(e) => {
            setCurrentPageNo(e);
          }}
        />
      </div>
      {discardProjectModal.visible && <DiscardDeleteProjectModal />}
    </CustomLayout>
  );
};

export default AllProjects;
