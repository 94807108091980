import React from "react";

const LabelIcon = ({ size = 20, className = "text-gray-500" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.5 15h1l4.5-5-4.5-5h-2"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 5H4v10h5"
        ></path>
      </svg>
    </span>
  );
};

export default LabelIcon;
