import React from "react";
import Icon from "@ant-design/icons";

const ArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="20"
    fill="none"
    viewBox="0 0 16 20"
  >
    <rect width="16" height="20" fill="#98A2B3" rx="2"></rect>
    <path
      stroke="#F5FAFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 10H4m0 0l3 3m-3-3l3-3"
    ></path>
  </svg>
);

const ArrowIcon = (props) => <Icon component={ArrowSvg} {...props} />;

export { ArrowIcon };
