import React from "react";
import Icon from "@ant-design/icons";

const BellSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.485 14.328H4.657c-.229 0-.46-.04-.661-.147-1.743-.925-.869-2.58.133-3.668.347-.377.58-.855.58-1.367V6.453c.134-1.458 1.357-4.375 5.185-4.375 3.697 0 5.213 2.72 5.551 4.22.024.103.032.208.032.313v2.596c0 .477.204.927.517 1.286 1.615 1.857.977 3.084.217 3.636-.208.151-.469.199-.726.199zM6.962 15.8c0 .495.99 2.476 2.971 2.476 1.98 0 2.971-1.486 2.971-2.476"
    ></path>
  </svg>
);
const BellOutlinedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.333 13.999H6.667M5.755 2.02A4 4 0 0112 5.332c0 1.4.18 2.5.432 3.355M4.172 4.17A3.999 3.999 0 004 5.332c0 2.06-.52 3.47-1.1 4.404-.49.787-.735 1.18-.726 1.29.01.121.036.168.134.24.088.066.487.066 1.285.066h7.74M14 13.999l-12-12"
    ></path>
  </svg>
);
const BellFilledSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill="currentColor"
      d="M2.537 8.521c.508-.816.963-2.05.963-3.853 0-.347.052-.69.15-1.016l6.267 6.266H3.143c-.697 0-1.046 0-1.124-.057-.085-.064-.108-.105-.117-.21-.007-.097.207-.441.635-1.13z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.167 12.251H5.833m-.797-10.48A3.5 3.5 0 0110.5 4.668c0 1.225.158 2.188.378 2.936M3.651 3.65A3.5 3.5 0 003.5 4.668c0 1.803-.455 3.037-.963 3.853-.428.689-.642 1.033-.635 1.13.01.105.032.146.117.21.078.057.427.057 1.124.057h6.774m2.333 2.333l-10.5-10.5"
    ></path>
  </svg>
);

const BellIcon = (props) => {
  return <Icon component={BellSvg} {...props} />;
};
const BellOutlinedIcon = (props) => {
  return <Icon component={BellOutlinedSvg} {...props} />;
};
const BellFilledIcon = (props) => {
  return <Icon component={BellFilledSvg} {...props} />;
};

export { BellIcon, BellOutlinedIcon, BellFilledIcon };
