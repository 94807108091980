import React, { useCallback, useState } from "react";

import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { DndProvider, useDrag, useDrop } from "react-dnd";

import { manager } from "../../../Core/utils/dnd-utils";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { updateSwimlane } from "../../../Core/redux/api/scheduleAPI";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { useQueryClient } from "react-query";

type MoveSwimlaneModalProps = {
  visible: boolean;

  onCancel: () => void;
};

const Item = ({ id, children, index, moveItem, canDrag, canDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id, index },
    type: "card",
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "card",
    canDrop: () => canDrop,
    // hover: (item: { id: number; index: number }, monitor) => {
    //   if (item.id !== id) {
    //     moveItem(item.index, index);
    //   }
    // },
    drop: (item: { index: number }, monitor) => {
      moveItem(item.index, index);
    },

    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <div ref={canDrag ? drag : null} className={`rounded-xl `}>
      <div
        ref={drop}
        className={`${
          isOver ? (canDrop ? "bg-gray-200" : "bg-error-100") : "bg-white"
        }  bg-white   py-[11px] px-4 pr-2 border rounded-lg shadow-xs  hover:shadow-s flex items-center justify-between mt-2 max-h-[40px] min-h-[40px]`}
        style={{
          opacity,
          transform: " translate(0, 0)",
          cursor: canDrop ? "move" : "no-drop	",
        }}
      >
        <div className="out-500-12 text-gray-700 flex items-center gap-x-2">
          <p className="uppercase">{children}</p>
        </div>
      </div>
    </div>
  );
};

const MoveSwimlaneModal = ({
  visible,

  onCancel,
}: MoveSwimlaneModalProps) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { swimlanes } = useAppSelector((state) => state.schedule);
  const queryClient: any = useQueryClient();

  const [localSwimlanes, setLocalSwimlanes] = useState([...swimlanes]);
  const moveItem = useCallback(
    (dragIndex, dropIndex) => {
      const newSwimlanes = [...localSwimlanes];
      const draggedSwimlane = newSwimlanes[dragIndex];
      newSwimlanes.splice(dragIndex, 1);
      newSwimlanes.splice(dropIndex, 0, draggedSwimlane);

      const newArray = newSwimlanes.map((swimlane, index) => {
        return { ...swimlane, positionIndex: `${index + 1}` };
      });
      console.log(newArray);
      setLocalSwimlanes(newArray);
    },
    [dispatch, swimlanes, localSwimlanes]
  );

  const handleSave = async () => {
    const modifiedSwimlanes = [...localSwimlanes];
    await dispatch(
      updateSchedule({ key: "swimlanes", value: modifiedSwimlanes })
    );
    await dispatch(
      updateSwimlane({ newSwimlanes: modifiedSwimlanes, projectId })
    );
    queryClient.invalidateQueries(["swimlanes", projectId]);
    onCancel();
  };

  return (
    <CustomModal
      width={"400px"}
      visible={visible}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-gray-900 out-500-14">Move Swimlane</p>
            <button onClick={() => onCancel()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <div className="max-h-[70.189386056191466vh] overflow-y-scroll bg-white ">
            <DndProvider manager={manager}>
              {localSwimlanes.map((swimlane, i) => (
                <Item
                  key={swimlane.id}
                  id={swimlane.id}
                  index={i}
                  moveItem={moveItem}
                  canDrag={i !== 0 && i !== localSwimlanes.length - 1}
                  canDrop={i !== 0 && i !== localSwimlanes.length - 1}
                >
                  {swimlane.title}
                </Item>
              ))}
            </DndProvider>
          </div>
          <div className="flex item-center justify-end pt-4 ">
            <CustomButton
              text="Cancel"
              onClick={onCancel}
              className="bg-transparent text-gray-500"
              height="30px"
            />
            <CustomButton text="Save" onClick={handleSave} height="30px" />
          </div>
        </div>
      }
      onCancel={onCancel}
    />
  );
};

export default MoveSwimlaneModal;
