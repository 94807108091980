import { createSlice, current } from "@reduxjs/toolkit";
import { playNotifySound } from "../../utils";

import {
  createThread,
  forwardMessageToUsers,
  getMorePrivateMessages,
  getMoreThreadMessages,
  getPrivateChats,
  getPrivateMessages,
  getProjectsWithThreads,
  getThreadMessages,
  getThreads,
  getThreadsMembers,
  getUnreadCountDm,
  leaveThread,
  updateThread,
} from "../api/conversationAPI";
import { getOrganizationUsers, getProjectUsers } from "../api/projectAPI";

//We do not recommend the object notation for extraReducers any more, especially with TypeScript you should be using the builder notation --by redux toolkit team.

const initialState = {
  projectsWithThreads: [],
  loading: false,
  getMessageLoading: false,
  error: {},
  status: "idle",
  threadModal: false,
  showThreadProfileDrawer: false,
  threadProfileDrawerCurrenTab: "details",
  newMessageModal: false,
  leaveThread: false,
  deleteThread: false,
  addFolderModal: false,
  addLinkModal: false,
  shareMessageModal: false,
  shareInMessageModal: {
    type: "folder",
    visible: false,
    url: "",
    file: [],
  },
  viewFileDetails: false,
  deleteFileModal: false,
  deleteFileInMessage: null,
  saveInVaultModal: {
    visible: false,
    file: {},
  },
  shareType: "shareMessage",
  threads: [],
  selectedUsers: [],
  editThread: false,
  suggestedThread: false,
  threadMembers: [],
  editThreadDetails: {
    threadId: "",
    threadName: "",
    description: "",
    phaseId: "",
    users: ["0"],
    firstname: "",
    lastname: "",
    profilePicture: "",
    profilePictureColorCode: "",
    createdAt: "10-10-9999",
    userToBeAdded: "",
    projectId: "",
    threadProfilePicture: "",
    threadProfilePictureColorCode: "",
  },
  leaveThreadDetails: {
    threadId: "",
  },
  deleteThreadDetails: {
    type: "group",
    threadId: "",
    threadName: "",
    description: "",
    phaseId: "",
    users: ["0"],
    firstname: "",
    lastname: "",
    profilePicture: "",
    profilePictureColorCode: "",
    createdAt: "10-10-9999",
    createdById: "",
    email: "",
    userId: "",
    messages: [],
    isNotSelected: true,
    unreadCount: 0,
    limit: 20,
    offset: 0,
    projectId: "",
    isPinned: 0,
    isMute: 0,
  },
  currentThread: {
    type: "group",
    threadId: "",
    threadName: "",
    description: "",
    phaseId: "",
    users: ["0"],
    firstname: "",
    lastname: "",
    profilePicture: "",
    profilePictureColorCode: "",
    createdAt: "10-10-9999",
    createdById: "",
    email: "",
    userId: "",
    messages: [],
    isNotSelected: true,
    unreadCount: 0,
    limit: 20,
    offset: 0,
    projectId: "",
    isPinned: 0,
    isMute: 0,
    tenantId: null,
    threadProfilePicture: "",
    threadProfilePictureColorCode: "",
    senderId: "",
  },
  currentThreadMessage: [],
  replyMessage: {
    message: "",
    reply: false,
    messageObject: {
      senderName: "",
      attachments: [],
      request: { type: "" },
      _id: "",
    },
  },
  editMessage: {
    edit: false,
    messageObject: {
      _id: "",
      message: "",
    },
  },
  forwardMessage: {},
  deleteMessage: {
    deleteFlag: false,
    deleteMessageObject: {
      _id: "",
    },
  },
  directMessageUsers: [
    {
      userId: "",
      firstname: "",
      lastname: "",
      profilePicture: "",
      profilePictureColorCode: "",
      messages: [],
      email: "",
      unreadCount: 0,
      roleName: "",
    },
  ],
  prefillUserInAddThread: false,
  unreadCount: 0,

  // v2 states

  newGroupModal: false,
  requestOption: false,
  userDetailsDrawer: { visible: false, data: {} },
  groupDetailsDrawer: { visible: false, data: {} },
  sharedFilesDrawer: false,
  sharedFileDetailsDrawer: { visible: false, data: {} },
  chatRequest: {
    selectedRequestType: "Attachment",
    selectedUrgency: "",
    deadlineDate: null,
    deadlineTime: null,
  },
  saveInLibrary: { visible: false, file: {} },
  filePreview: {
    docs: [{ mimeType: "", type: "", name: "", url: "" }],
    visible: false,
    from: "",
    currentIndex: "",
    createdAt: "",
  },
  defaultFileList: [],
  sortedThreads: [],
  drafts: [],
  previousChatId: "",
  quickGlanceChatDraftUpdate: false,
};
//todo add a searchresults state for searching messages

export const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    addDummyDataForMessagesScreen: (state, action) => {
      const dummyState = action.payload;
      Object.keys(dummyState).forEach((key) => (state[key] = dummyState[key]));
    },

    resetToInitialConversation: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    updateConversations: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    updateConversationsMultiple: (state, action) => {
      action.payload.map((ele) => {
        state[ele.key] = ele.value;
      });
    },
    resetConversations: (state, action) => {
      action.payload.map((ele) => {
        state[ele] = initialState[ele];
      });
    },
    updateThreadType: (state, action) => {
      state.currentThread.type = action.payload;
    },
    updatePrivateConversations: (state, action) => {
      const message = action.payload;
      state.directMessageUsers = state.directMessageUsers.map((user) => {
        if (
          +user.userId === +message.senderId &&
          user.messages[user.messages.length - 1]?._id !== message._id
        ) {
          state.unreadCount = 0;
          return {
            ...user,
            messages: [...user.messages, message],
            lastMessage: message.message,
            lastMessageTime: message.createdAt,
            lastMessageLocal: true,
            unreadCount:
              +state.currentThread.userId !== +message.senderId
                ? +user.unreadCount + 1
                : 0,
          };
        }
        return user;
      });
    },

    updateUnreadCountForProjectThreads: (state, action) => {
      //console.log("updateUnreadCountForProjectThreads" , action)
      const { threadId, projectId } = action.payload;
      if (state.currentThread.threadId != threadId) {
        const project = state.projectsWithThreads.find(
          (p) => p.id == projectId
        );
        if (!projectId || !project) {
          state.threads = state.threads.map((t) =>
            +t.threadId === +threadId
              ? { ...t, unreadCount: +t.unreadCount + 1 }
              : t
          );
          return;
        }
        const newThreads = project.threads.map((t) =>
          +t.threadId === +threadId
            ? { ...t, unreadCount: +t.unreadCount + 1 }
            : t
        );
        const newProject = { ...project, threads: newThreads };
        state.projectsWithThreads = state.projectsWithThreads.map((p) =>
          p.id === projectId ? newProject : p
        );
        playNotifySound();
      }
    },

    updateUnreadCountForMarkAsUnread: (state, action) => {
      const unreadCount = action.payload.unreadCount;
      const threadId = action.payload.threadId;
      const userId = action.payload.userId;

      console.log(
        unreadCount,
        threadId,
        userId,
        "updateUnreadCountForMarkAsUnread"
      );
      if (threadId) {
        state.threads = state.threads.map((thread) => {
          if (thread.threadId == threadId) {
            return { ...thread, unreadCount };
          }
          return thread;
        });
      } else {
        state.directMessageUsers = state.directMessageUsers.map((user) => {
          if (user.userId === userId) {
            return { ...user, unreadCount };
          }
          return user;
        });
      }
      state.unreadCount = unreadCount;
    },

    updateReaction: (state, action) => {
      const { messageId, emoji, userId, threadId } = action.payload;

      const removeOrAdd = (userReaction, userId) => {
        if (userReaction.includes(userId)) {
          console.log(
            userReaction.filter((u) => u != userId),
            "reactions1"
          );
          return userReaction.filter((u) => u != userId);
        } else {
          console.log([...userReaction, userId], "reactions2");
          return [...userReaction, userId];
        }
      };

      const addReaction = (oldMessage) => {
        const updatedMessage = oldMessage.map((message) => {
          if (message._id === messageId) {
            const reactions = message.reactions || {};
            const userReaction = reactions[emoji] || [];
            return {
              ...message,
              reactions: {
                ...reactions,
                [emoji]: removeOrAdd(userReaction, userId),
              },
            };
          }
          return message;
        });
        return updatedMessage;
      };

      console.log(
        "socketOnReaction",
        state.currentThread.type,
        threadId,
        state.currentThread.userId
      );

      if (
        state.currentThread.type === "private" &&
        userId == state.currentThread.userId
      ) {
        const users = state.directMessageUsers.map((user) => {
          if (+user.userId === +userId) {
            const message = addReaction(user.messages);
            return { ...user, messages: [...message] };
          }
          return user;
        });
        state.directMessageUsers = users;
      } else {
        state.currentThreadMessage = addReaction(state.currentThreadMessage);
      }
    },

    muteThread: (state, action) => {
      const threadId = action.payload.threadId;
      const projectId = action.payload.projectId;
      const isProjectThread = action.payload.isProjectThread;
      const isMute = action.payload.isMute;

      if (isProjectThread) {
        const projects = state.projectsWithThreads.map((project) => {
          if (project.threads.length > 0 && +project.id === +projectId) {
            const threads = project.threads.map((thread) => {
              if (+thread.threadId === +threadId) {
                return { ...thread, isMute };
              }
              return thread;
            });
            return { ...project, threads };
          }
          return project;
        });
        state.projectsWithThreads = projects;
      } else {
        const threads = state.threads.map((thread) => {
          if (+thread.threadId === +threadId) {
            return { ...thread, isMute };
          }
          return thread;
        });
        state.threads = threads;
      }
    },

    updateThreadForUser: (state, action) => {
      const threadId = action.payload.threadId;
      const isThread = action.payload.isThread;
      const userId = action.payload.userId;

      const isMute = action.payload.isMute;
      const isPinned = action.payload.isPinned;

      const updateObj: { isMute?: number; isPinned?: number } = {};
      if (typeof isMute !== "undefined") updateObj.isMute = isMute;
      if (typeof isPinned !== "undefined") updateObj.isPinned = isPinned;

      if (isThread) {
        const threads = state.threads.map((thread) => {
          if (+thread.threadId === +threadId) {
            console.log(
              { ...thread, ...updateObj },
              updateObj,
              "updateThreadForUser"
            );
            return { ...thread, ...updateObj };
          }
          return thread;
        });
        if (state.currentThread.threadId === threadId) {
          state.currentThread = { ...state.currentThread, ...updateObj };
        }
        state.threads = threads;
      } else {
        const directMessageUsers = state.directMessageUsers.map((user) => {
          if (+user.userId === +userId) {
            console.log(
              { ...user, ...updateObj },
              updateObj,
              "updateThreadForUser"
            );
            return { ...user, ...updateObj };
          }
          return user;
        });
        state.directMessageUsers = directMessageUsers;
        if (state.currentThread.userId === userId) {
          state.currentThread = { ...state.currentThread, ...updateObj };
        }
      }
    },

    updateThreadMessage: (state, action) => {
      console.log(action.payload, "dddd");

      if (
        +state.currentThread.threadId === +action.payload.threadId &&
        state.currentThreadMessage[state.currentThreadMessage.length - 1]
          ?._id !== action.payload._id
      ) {
        state.unreadCount = 0;
        state.currentThreadMessage = [
          ...state.currentThreadMessage,
          action.payload,
        ];
      }

      // here we are adding the message to threads and projects with thread array as will
      const projectId = action.payload.projectId;
      if (projectId) {
        const newProjectsWithThreads = state.projectsWithThreads.map(
          (project) => {
            if (state.currentThread.projectId == project.id) {
              let newThreads = [];
              if (project.threads) {
                newThreads = project.threads.map((thread) => {
                  if (thread.threadId == state.currentThread.threadId) {
                    return {
                      ...thread,
                      messages: [...state.currentThreadMessage, action.payload],
                    };
                  }
                  return thread;
                });
              }
              return { ...project, threads: newThreads };
            }
            return project;
          }
        );
        state.projectsWithThreads = newProjectsWithThreads;
      } else {
        const newThreads = state.threads.map((thread) => {
          if (thread.threadId == state.currentThread.threadId) {
            return {
              ...thread,
              messages: [...state.currentThreadMessage, action.payload],
              lastMessage: `${action.payload.senderName}: ${action.payload.message}`,
              lastMessageTime: action.payload.createdAt,
              lastMessageLocal: true,
            };
          }
          return thread;
        });
        state.threads = newThreads;
      }
      // state.threads = state.threads.map((thread) => {
      //   if (+thread.threadId === +action.payload.threadId && +state.currentThread.threadId !== +action.payload.threadId) {
      //     return { ...thread, unreadCount: +thread.unreadCount + 1 };
      //   }
      //   return { ...thread };
      // });

      // if(action.payload.projectId){
      //   state.projectsWithThreads = state.projectsWithThreads.map((project)=>{
      //     if(project.threads.length > 0 && +project.id===+action.payload.projectId){
      //       const threads = project.threads.map((thread) => {
      //         if (+thread.threadId === +action.payload.threadId && +state.currentThread.threadId !== +action.payload.threadId) {
      //           return { ...thread, unreadCount: +thread.unreadCount + 1 };
      //         }
      //         return { ...thread };
      //       });
      //       return {...project , threads}
      //     }
      //     return project
      //   })
      // }
    },
    // setUser: (state, action) => {
    //   state.user = action.payload;
    //   state.loading = false;
    // },

    replyToRequest: (state, action) => {
      const message = action.payload.message;
      const parentMessageId = action.payload.parentMessageId;
      if (
        message.threadId &&
        +state.currentThread.threadId === +message.threadId
      ) {
        const messageListCopy = state.currentThreadMessage.map((m) => {
          if (m._id === parentMessageId) {
            return {
              ...m,
              request: {
                ...m.request,
                updateCount: m.request.updateCount + 1,
                activity: [
                  ...m.request.activity,
                  { ...message, replyMessage: {}, request: {} },
                ],
              },
            };
          }
          return m;
        });

        state.currentThreadMessage = messageListCopy;
      } else {
        const users = state.directMessageUsers.map((user) => {
          if (+user.userId === +state.currentThread.userId) {
            const messages = user.messages.map((m) => {
              if (m._id === parentMessageId) {
                return {
                  ...m,

                  request: {
                    ...m.request,
                    updateCount: m.request.updateCount + 1,
                    activity: [
                      ...m.request.activity,
                      { ...message, replyMessage: {}, request: {} },
                    ],
                  },
                };
              }
              return m;
            });
            return {
              ...user,
              messages: [...messages],
            };
          }
          return user;
        });

        state.directMessageUsers = users;
      }
    },

    closeRequest: (state, action) => {
      const message = action.payload.message;

      if (
        message.threadId &&
        +state.currentThread.threadId === +message.threadId
      ) {
        const messageListCopy = state.currentThreadMessage.map((m) => {
          if (m._id === message._id) {
            return {
              ...m,
              request: {
                ...m.request,
                status: "Closed",
                requestClosingDate: new Date(),
              },
            };
          }
          return m;
        });

        state.currentThreadMessage = messageListCopy;
      } else {
        const users = state.directMessageUsers.map((user) => {
          if (+user.userId === +state.currentThread.userId) {
            const messages = user.messages.map((m) => {
              if (m._id === message._id) {
                return {
                  ...m,

                  request: {
                    ...m.request,
                    status: "Closed",
                    requestClosingDate: new Date(),
                  },
                };
              }
              return m;
            });
            return {
              ...user,
              messages: [...messages],
            };
          }
          return user;
        });

        state.directMessageUsers = users;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getThreads.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getThreads.fulfilled, (state, { payload }) => {
      state.status = "fulfilled";
      state.threads = payload.thread;

      state.directMessageUsers = payload.users?.map((user) => {
        const oldUser = state.directMessageUsers.find(
          (oldUser) => oldUser.userId === user.userId
        );
        const messages = oldUser ? oldUser.messages : [];

        const lastname =
          +payload.userId === +user.userId
            ? `${user.lastname} (You)`
            : user.lastname;

        return {
          ...user,
          messages,
          unreadCount: 0,
          lastname,
        };
      });

      state.directMessageUsers = state.directMessageUsers?.map((user) => {
        const newUser = payload.chats.find(
          (chat) => chat.receiverId === user.userId
        );
        const messages = user.messages || [];
        return {
          ...user,
          ...newUser,
          messages,
        };
      });

      state.loading = false;
      state.error = null;
    });
    builder.addCase(getThreads.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
      state.threads = [];
    });

    builder.addCase(getProjectsWithThreads.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getProjectsWithThreads.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.projectsWithThreads = payload.result;
      state.error = null;
    });
    builder.addCase(getProjectsWithThreads.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
      state.projectsWithThreads = [];
    });

    builder.addCase(createThread.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(createThread.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(createThread.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(updateThread.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(updateThread.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      if (payload.result[0]) {
        state.currentThread = { ...state.currentThread, ...payload.result[0] };
        const projectId = payload.result[0].projectId;
        if (projectId) {
          const projects = state.projectsWithThreads.map((project) => {
            if (project.threads.length > 0 && +project.id === +projectId) {
              const threads = project.threads.map((thread) => {
                if (+thread.threadId === +payload.result[0].threadId) {
                  return { ...thread, ...payload.result[0] };
                }
                return thread;
              });
              return { ...project, threads };
            }
            return project;
          });
          state.projectsWithThreads = projects;
        } else {
          const threads = state.threads.map((thread) => {
            if (+thread.threadId === +payload.result[0].threadId) {
              return { ...thread, ...payload.result[0] };
            }
            return thread;
          });
          state.threads = threads;
        }
      }
      state.error = null;
    });
    builder.addCase(updateThread.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(getThreadsMembers.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(getThreadsMembers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
      state.threadMembers = payload.result;
    });
    builder.addCase(getThreadsMembers.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(leaveThread.pending, (state, { payload }) => {
      state.loading = true;
      state.status = "pending";
    });
    builder.addCase(leaveThread.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.error = null;
      //state.threadMembers = payload.result;
    });
    builder.addCase(leaveThread.rejected, (state, { payload }) => {
      state.loading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getThreadMessages.pending, (state, { payload }) => {
      state.getMessageLoading = true;
      state.status = "pending";
    });
    builder.addCase(getThreadMessages.fulfilled, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "fulfilled";
      state.error = null;
      state.currentThreadMessage = payload.result;
      state.threads = state.threads.map((thread) => {
        if (+thread.threadId === +payload.result[0]?.threadId) {
          state.unreadCount = +thread.unreadCount;
          return { ...thread, unreadCount: 0, messages: [...payload.result] };
        }
        return thread;
      });
      state.projectsWithThreads = state.projectsWithThreads.map((project) => {
        if (project.id === state.currentThread.projectId) {
          const threads = project.threads;
          const newThreads = threads.map((thread) => {
            if (+thread.threadId === +payload.result[0]?.threadId) {
              state.unreadCount = +thread.unreadCount;
              console.log(thread.unreadCount, "thread.unreadCount;");
              return {
                ...thread,
                unreadCount: 0,
                messages: [...payload.result],
              };
            }
            return thread;
          });
          const newProject = { ...project, threads: newThreads };
          return newProject;
        }
        return project;
      });
      //state.threadMembers = payload.result;
    });
    builder.addCase(getThreadMessages.rejected, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(getMoreThreadMessages.pending, (state, { payload }) => {
      state.getMessageLoading = true;
      state.status = "pending";
    });
    builder.addCase(getMoreThreadMessages.fulfilled, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "fulfilled";
      state.error = null;
      state.currentThreadMessage = [
        ...payload.result,
        ...state.currentThreadMessage,
      ];
      state.threads = state.threads.map((thread) => {
        if (+thread.threadId === +payload.result[0]?.threadId) {
          state.unreadCount = +thread.unreadCount;
          return { ...thread, unreadCount: 0, messages: [...payload.result] };
        }
        return thread;
      });
      state.projectsWithThreads = state.projectsWithThreads.map((project) => {
        if (project.id === state.currentThread.projectId) {
          const threads = project.threads;
          const newThreads = threads.map((thread) => {
            if (+thread.threadId === +payload.result[0]?.threadId) {
              state.unreadCount = +thread.unreadCount;
              console.log(thread.unreadCount, "thread.unreadCount;");
              return {
                ...thread,
                unreadCount: 0,
                messages: [...payload.result],
              };
            }
            return thread;
          });
          const newProject = { ...project, threads: newThreads };
          return newProject;
        }
        return project;
      });
      //state.threadMembers = payload.result;
    });
    builder.addCase(getMoreThreadMessages.rejected, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getProjectUsers.pending, (state, { payload }) => {
      state.getMessageLoading = true;
      state.status = "pending";
    });
    builder.addCase(getProjectUsers.fulfilled, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "fulfilled";
      state.error = null;
      state.directMessageUsers = payload.result.map((user) => {
        return {
          ...user,
          messages: [],
          unreadCount: 0,
          lastname:
            +payload.userId === +user.userId
              ? user.lastname + " " + "(You)"
              : user.lastname,
        };
      });
      //state.threadMembers = payload.result;
    });
    builder.addCase(getProjectUsers.rejected, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    // builder.addCase(getOrganizationUsers.pending, (state, { payload }) => {
    //   state.getMessageLoading = true;
    //   state.status = "pending";
    // });
    // builder.addCase(getOrganizationUsers.fulfilled, (state, { payload }) => {
    //   state.getMessageLoading = false;
    //   state.status = "fulfilled";
    //   state.error = null;
    //   const olderDirectThreadUsers = state.directMessageUsers;
    //   state.directMessageUsers = payload.result.map((user) => {
    //     const oldUser = olderDirectThreadUsers.find(
    //       (olduser) => user.userId == olduser.userId
    //     );
    //     let messages = [];
    //     if (oldUser && oldUser?.messages?.length > 0)
    //       messages = oldUser?.messages;
    //     return {
    //       ...user,
    //       messages,
    //       unreadCount: 0,
    //       lastname:
    //         +payload.userId === +user.userId
    //           ? user.lastname + " " + "(You)"
    //           : user.lastname,
    //     };
    //   });
    //   //state.threadMembers = payload.result;
    // });
    // builder.addCase(getOrganizationUsers.rejected, (state, { payload }) => {
    //   state.getMessageLoading = false;
    //   state.status = "rejected";
    //   state.error = payload;
    // });

    // builder.addCase(getPrivateChats.pending, (state, { payload }) => {
    //   state.getMessageLoading = true;
    //   state.status = "pending";
    // });
    // builder.addCase(getPrivateChats.fulfilled, (state, { payload }) => {
    //   state.getMessageLoading = false;
    //   state.status = "fulfilled";
    //   state.error = null;
    //   const olderDirectThreadUsers = state.directMessageUsers;
    //   state.directMessageUsers = state.directMessageUsers.map((user) => {
    //     const newUser = payload.result.find((x) => x.receiverId == user.userId);
    //     let messages = [];
    //     if (user?.messages?.length > 0) messages = user?.messages;
    //     return {
    //       ...user,
    //       ...newUser,
    //       messages,
    //     };
    //   });
    //   //state.threadMembers = payload.result;
    // });
    // builder.addCase(getPrivateChats.rejected, (state, { payload }) => {
    //   state.getMessageLoading = false;
    //   state.status = "rejected";
    //   state.error = payload;
    // });

    builder.addCase(forwardMessageToUsers.pending, (state, { payload }) => {
      state.getMessageLoading = true;
      state.status = "pending";
    });
    builder.addCase(forwardMessageToUsers.fulfilled, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "fulfilled";
      state.error = null;
    });
    builder.addCase(forwardMessageToUsers.rejected, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getUnreadCountDm.pending, (state, { payload }) => {
      state.getMessageLoading = true;
      state.status = "pending";
    });
    builder.addCase(getUnreadCountDm.fulfilled, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "fulfilled";
      state.error = null;
      console.log(
        payload.user !== null && typeof payload.users !== "undefined",
        payload.user !== null,
        typeof payload.users !== "undefined"
      );
      if (payload.user !== null && typeof payload.user !== "undefined") {
        state.directMessageUsers = state.directMessageUsers.map((user) => {
          console.log(
            payload.user.unreadUsers[+user.userId],
            payload.user.unreadUsers[user.userId],
            payload.user.unreadUsers,
            "xxx"
          );
          return {
            ...user,
            //messages: [],
            unreadCount: payload.user.unreadUsers[+user.userId]
              ? payload.user.unreadUsers[+user.userId]
              : 0,
          };
        });
      }

      //state.threadMembers = payload.result;
    });
    builder.addCase(getUnreadCountDm.rejected, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "rejected";
      state.error = payload;
    });

    builder.addCase(getPrivateMessages.pending, (state, { payload }) => {
      state.getMessageLoading = true;
      state.status = "pending";
    });
    builder.addCase(getPrivateMessages.fulfilled, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "fulfilled";
      state.error = null;
      state.currentThreadMessage = payload.result;

      const dm = state.directMessageUsers.map((user) => {
        if (+user.userId === +payload.reciverId) {
          console.log("sfvsfvsfdvsdvbskdbvksbkbkbk");
          state.unreadCount = +user.unreadCount;
          return {
            ...user,
            messages: payload.result,
            unreadCount: 0,
          };
        }
        return { ...user };
      });
      state.directMessageUsers = dm;
      //state.threadMembers = payload.result;
    });
    builder.addCase(getPrivateMessages.rejected, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
    builder.addCase(getMorePrivateMessages.pending, (state, { payload }) => {
      state.getMessageLoading = true;
      state.status = "pending";
    });
    builder.addCase(getMorePrivateMessages.fulfilled, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "fulfilled";
      state.error = null;
      state.currentThreadMessage = [
        ...payload.result,
        ...state.currentThreadMessage,
      ];

      const dm = state.directMessageUsers.map((user) => {
        if (+user.userId === +payload.reciverId) {
          console.log("sfvsfvsfdvsdvbskdbvksbkbkbk");
          state.unreadCount = +user.unreadCount;
          return {
            ...user,
            messages: state.currentThreadMessage,
            unreadCount: 0,
          };
        }
        return { ...user };
      });
      state.directMessageUsers = dm;
      //state.threadMembers = payload.result;
    });
    builder.addCase(getMorePrivateMessages.rejected, (state, { payload }) => {
      state.getMessageLoading = false;
      state.status = "rejected";
      state.error = payload;
    });
  },
});

export const {
  resetToInitialConversation,
  updateConversations,
  updateConversationsMultiple,
  resetConversations,
  updateThreadType,
  updatePrivateConversations,
  updateThreadMessage,
  updateUnreadCountForProjectThreads,
  muteThread,
  updateReaction,
  updateThreadForUser,
  updateUnreadCountForMarkAsUnread,
  replyToRequest,
  closeRequest,
  addDummyDataForMessagesScreen,
} = conversationsSlice.actions;
export default conversationsSlice.reducer;
