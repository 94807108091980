import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetVault,
  updateVault,
} from "../../../Core/redux/slices/vault.slice";
import { MinusIcon, PlusIconSecondary } from "../../../Core/svgV2/PlusIcon";

import PhaseIcon from "../../../Core/svg/PhaseIcon";
import { createFolder, getAllFolders } from "../../../Core/redux/api/vaultAPI";
import { useLocation, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { Popover } from "antd";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

const NewFolderModal = () => {
  const { newFolderModal } = useAppSelector((state) => state.vault);
  const dispatch = useAppDispatch();
  const { projectId, "*": splat } = useParams();
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const { folders } = useAppSelector((state) => state.vault);
  const [phase, setPhase] = useState(0);
  const [phaseCTA, setPhaseCTA] = useState(true);
  const location = useLocation();
  const [phaseDropdown, setPhaseDropdown] = useState(false);
  const [folderDetails, setFolderDetails] = useState({
    name: "",
    phaseId: null,
  });
  const { phases } = useAppSelector((state) => state.schedule);
  const { projectList } = useAppSelector((state) => state.projects);
  const projectDetails = projectList.find(
    (project) => project.id === projectId
  );

  // const option = [
  //   {
  //     id: 0,
  //     value: 0,
  //     label: "No phase",
  //     render: (value) => (
  //       <div className="flex justify-between">
  //         <div className="flex items-center border-b">
  //           <MinusIcon className="text-gray-200" width="16" height="16" />
  //           <p className="pl-2 out-300-14 leading-5 text-black capitalize ">
  //             No phase
  //           </p>
  //         </div>
  //         {!folderDetails.phaseId && <CheckIcon className="text-primary-600" />}
  //       </div>
  //     ),
  //   },

  //   {
  //     id: 1,
  //     value: 1,
  //     label: "Ideation",
  //     render: (value) => (
  //       <div className="flex justify-between">
  //         <div className="flex items-center">
  //           <PhaseIcon phaseId={1} width="16" height="16" icon />
  //           <p className="pl-2 out-300-14 leading-5 text-black capitalize">
  //             Ideation
  //           </p>
  //         </div>
  //         {folderDetails.phaseId == 1 && (
  //           <CheckIcon className="text-primary-600" />
  //         )}
  //       </div>
  //     ),
  //   },
  //   {
  //     id: 2,
  //     value: 2,
  //     label: "Design",
  //     render: (value) => (
  //       <div className="flex justify-between">
  //         <div className="flex items-center">
  //           <PhaseIcon phaseId={2} width="16" height="16" icon />
  //           <p className="pl-2 out-300-14 leading-5 text-black capitalize">
  //             Design
  //           </p>
  //         </div>
  //         {folderDetails.phaseId == 2 && (
  //           <CheckIcon className="text-primary-600" />
  //         )}
  //       </div>
  //     ),
  //   },
  //   {
  //     id: 3,
  //     value: 3,
  //     label: "Development",
  //     render: (value) => (
  //       <div className="flex items-center">
  //         <div className="flex items-center">
  //           <PhaseIcon phaseId={3} width="16" height="16" icon />
  //           <p className="pl-2 out-300-14 leading-5 text-black capitalize">
  //             Development
  //           </p>
  //         </div>
  //         {folderDetails.phaseId == 3 && (
  //           <CheckIcon className="text-primary-600" />
  //         )}
  //       </div>
  //     ),
  //   },
  //   {
  //     id: 4,
  //     value: 4,
  //     label: "Deployment",
  //     render: (value) => (
  //       <div className="flex justify-between">
  //         <div className="flex items-center">
  //           <PhaseIcon phaseId={4} width="16" height="16" icon />
  //           <p className="pl-2 out-300-14 leading-5 text-black capitalize">
  //             Deployment
  //           </p>
  //         </div>
  //         {folderDetails.phaseId == 4 && (
  //           <CheckIcon className="text-primary-600" />
  //         )}
  //       </div>
  //     ),
  //   },
  //   {
  //     id: 5,
  //     value: 5,
  //     label: "Maintenance",
  //     render: (value) => (
  //       <div className="flex justify-between">
  //         <div className="flex items-center">
  //           <PhaseIcon phaseId={5} width="16" height="16" icon />
  //           <p className="pl-2 out-300-14 leading-5 text-black capitalize">
  //             Maintenance
  //           </p>
  //         </div>
  //         {folderDetails.phaseId == 5 && (
  //           <CheckIcon className="text-primary-600" />
  //         )}
  //       </div>
  //     ),
  //   },
  // ];

  const onSubmit = async () => {
    const { name, phaseId } = folderDetails;

    if (folders) {
      folders.map((existingFolder) => {
        if (existingFolder.folderName == name) {
          console.log("name already exists");
          return;
        }
      });
    }

    const payload: {
      folderId?: string;
      folderName: string;
      phaseId: string | number;
      projectId: string;
      userId: number;
      notificationId?: string;
      tenantId?: string;
    } = {
      folderName: name,
      tenantId: tenantDetails?.tenantId,
      projectId: projectId,
      userId: user.id,
      phaseId: phaseId == 0 ? null : phaseId,
    };

    // if (preFilledVaultModalValues.notificationId) {
    //   payload.notificationId = preFilledVaultModalValues.notificationId;
    // }
    let queryObject: {
      projectId: string;
      folderId?: string;
      tenantId?: string;
    };

    if (projectId == "undefined" || projectId == null) {
      queryObject = { projectId: null, tenantId: tenantDetails?.tenantId };
    } else {
      queryObject = { projectId };
    }

    if (splat && splat.length > 0) {
      const folderId = splat.split("-");
      payload.folderId = folderId[folderId.length - 1];
      queryObject.folderId = folderId[folderId.length - 1];
    }
    setPhaseDropdown(false);
    [];
    //todo confirm the alert text
    await dispatch(createFolder(payload)).then(() => {
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: "New Folder",
              subtitle: "Created a new folder in  ",
              description: location.pathname.includes("my-library")
                ? "My Library"
                : projectDetails
                ? projectDetails.title
                : "Global Scope",
            },
          },
        })
      );

      setFolderDetails({
        name: "",
        phaseId: null,
      });
      reset();
    });

    dispatch(getAllFolders(queryObject));

    dispatch(resetVault(["newFolderModal"]));
  };

  // const renderPhaseName = (phase) => {
  //   switch (phase) {
  //     case 0:
  //       return (
  //         <div className="w-full flex items-center py-[5px] pl-3 border border-white hover:border hover:border-gray-200 hover:rounded">
  //           <MinusIcon className="text-gray-200" />
  //           <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
  //             No phase
  //           </p>
  //         </div>
  //       );
  //     case 1:
  //       return (
  //         <div className="w-full flex items-center py-[5px] pl-3 border border-white hover:border hover:border-gray-200 hover:rounded">
  //           <div className="w-3 h-3 bg-yellow-600 rounded-[3px]"></div>
  //           <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
  //             Ideation
  //           </p>
  //         </div>
  //       );
  //     case 2:
  //       return (
  //         <div className="w-full flex items-center py-[5px] pl-3 border border-white hover:border hover:border-gray-200 hover:rounded">
  //           <div className="w-3 h-3 bg-pink-600 rounded-[3px]"></div>
  //           <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
  //             Design
  //           </p>
  //         </div>
  //       );
  //     case 3:
  //       return (
  //         <div className="w-full flex items-center py-[5px] pl-3 border border-white hover:border hover:border-gray-200 hover:rounded">
  //           <div className="w-3 h-3 bg-purple-600 rounded-[3px]"></div>
  //           <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
  //             Development
  //           </p>
  //         </div>
  //       );
  //     case 4:
  //       return (
  //         <div className="w-full flex items-center py-[5px] pl-3 border border-white hover:border hover:border-gray-200 hover:rounded">
  //           <div className="w-3 h-3 bg-[#33B7FF] rounded-[3px]"></div>
  //           <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
  //             Deployment
  //           </p>
  //         </div>
  //       );
  //     case 5:
  //       return (
  //         <div className="w-full flex items-center py-[5px] pl-3 border border-white hover:border hover:border-gray-200 hover:rounded">
  //           <div className="w-3 h-3 bg-purple-600 rounded-[3px]"></div>
  //           <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
  //             Maintenance
  //           </p>
  //         </div>
  //       );
  //   }
  // };

  // const handleCreate = () => {
  //   dispatch(updateVault({ key: "newFolderModal", value: false }));
  // };
  const { control, handleSubmit, reset } = useForm({
    values: {
      folderName: null,
    },
  });

  return (
    <CustomModal
      visible={newFolderModal}
      width="352px"
      onCancel={() =>
        dispatch(updateVault({ key: "newFolderModal", value: false }))
      }
      body={
        <div className="p-4">
          <div>
            <div className="flex justify-between">
              <p className="text-black out-500-14 leading-5">New Folder</p>

              <button
                onClick={() =>
                  dispatch(
                    updateVault({
                      key: "newFolderModal",
                      value: false,
                    })
                  )
                }
              >
                <CrossIcon className="text-gray-700" />
              </button>
            </div>
            <div className="mt-4">
              {
                <Controller
                  control={control}
                  name="folderName"
                  rules={{
                    required: "Please enter a folder name before submitting",
                  }}
                  render={({ field, formState }) => (
                    <CustomInputBox
                      {...field}
                      placeholder="Untitled folder"
                      onChange={(e) => {
                        setFolderDetails({
                          ...folderDetails,
                          name: e.target.value,
                        });
                        console.log(folderDetails, "fd phase");
                        field.onChange(e);
                      }}
                      formState={formState}
                    />
                  )}
                />
              }
            </div>
            {phaseCTA ? (
              <Popover
                open={phaseDropdown}
                arrow={false}
                trigger={"click"}
                onOpenChange={(val) => setPhaseDropdown(val)}
                placement="bottom"
                content={
                  <div className=" w-[320px]">
                    <div
                      className="flex justify-between px-[14px] py-2.5 border-b cursor-pointer"
                      onClick={() => {
                        setPhase(null);
                        setFolderDetails({
                          ...folderDetails,
                          phaseId: null,
                        });

                        setPhaseDropdown(false);
                      }}
                    >
                      <div className="flex items-center ">
                        <MinusIcon
                          className="text-gray-200"
                          width="16"
                          height="16"
                        />
                        <p className="pl-2 out-300-14 leading-5 text-black capitalize ">
                          No phase
                        </p>
                      </div>
                      {!folderDetails.phaseId && (
                        <CheckIcon className="text-primary-600" />
                      )}
                    </div>

                    <div className="flex justify-between out-500-14 text-gray-500 px-[14px] py-2.5">
                      Ongoing
                    </div>

                    <div className="max-h-[160px] overflow-y-auto ">
                      {phases
                        .filter(({ isOngoing }) => isOngoing === 1)
                        .map((phase) => (
                          <div
                            className={`flex justify-between  px-[14px] py-2.5 ${
                              folderDetails.phaseId == phase.phaseId &&
                              "bg-gray-50"
                            }`}
                            key={phase.projectPhaseId}
                            role="button"
                            onClick={() => {
                              setPhase(+phase.phaseId);
                              setFolderDetails({
                                ...folderDetails,
                                phaseId: phase.phaseId,
                              });

                              setPhaseDropdown(false);
                            }}
                          >
                            <div className="flex items-center ">
                              <PhaseIcon
                                phaseId={+phase.phaseId}
                                width="16"
                                height="16"
                                icon
                              />
                              <p className="pl-2 out-300-14 leading-5 text-black capitalize">
                                {phase.phases} {phase.phaseIteration}
                              </p>
                            </div>
                            {folderDetails.phaseId == phase.phaseId && (
                              <CheckIcon className="text-primary-600" />
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                }
              >
                <div
                  className={`mt-4 w-[320px] py-[5px] pl-[6px] rounded border border-white hover:border hover:border-gray-200 hover:rounded ${
                    phaseDropdown && "border-primary-500 shadow-input-focus "
                  }`}
                  role="button"
                  onClick={() => {
                    setPhaseDropdown(!phaseDropdown);
                  }}
                >
                  {folderDetails.phaseId ? (
                    <div className="flex items-center ">
                      <PhaseIcon
                        phaseId={+folderDetails.phaseId}
                        width="16"
                        height="16"
                        icon
                      />
                      <p className="pl-2 out-300-14 leading-5 text-black capitalize">
                        {
                          phases.find((p) => folderDetails.phaseId == p.phaseId)
                            .phases
                        }
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-2 cursor-pointer">
                      <PlusIconSecondary className="text-gray-500" />
                      <p className="out-500-14 leading-5 text-gray-500">
                        Tag a Phase
                      </p>
                    </div>
                  )}
                </div>
              </Popover>
            ) : null}
          </div>
          <div className="flex mt-[33px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() =>
                dispatch(updateVault({ key: "newFolderModal", value: false }))
              }
            />
            <CustomButton
              text="Create"
              height="30px"
              width={"64px"}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      }
    />
  );
};

export default NewFolderModal;
