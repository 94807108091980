import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { UrgencyFillIcon } from "../../../Core/svgV2/Urgency";
import { AlarmClockIcon } from "../../../Core/svgV2/AlarmClock";
import dayjs from "dayjs";
import { CalendarIcon } from "../../../Core/svgV2/CalendarIcon";
import Message from "../Message";
import { useAppSelector } from "../../../Core/redux/hooks";

import { ChevronIcon } from "../../../Core/svgV2/Chevron";

const Request = ({ data }) => {
  const [open, setOpen] = useState(false);
  const getColor = (urgency) => {
    switch (urgency) {
      case "Critical":
        return "text-error-600";
      case "Major":
        return "text-warning-600";
      case "Medium":
        return "text-primary-600";
      case "Minor":
        return "text-gray-600";
      default:
        return "text-gray-600";
    }
  };

  return (
    <div className={`hover:bg-gray-50 `}>
      <div className="flex justify-between items-center py-[2px]">
        <div className="flex relative cursor-pointer flex-row items-center justify-center   gap-x-[8px] w-[115px] h-[30px] rounded-[4px] border border-gray-200">
          <UrgencyFillIcon className={getColor(data.request?.urgency)} />
          <div
            className={`out-500-14 ${getColor(
              data.request?.urgency
            )} text-center relative `}
          >
            {data.request?.type}
          </div>
        </div>

        {open ? (
          <div className="flex gap-x-5 items-center">
            <button className="flex gap-x-2 items-center justify-center">
              <CalendarIcon className="text-gray-500" />

              <span className="out-300-14 text-black">
                {data.request?.deadlineDate
                  ? dayjs(data.request?.deadlineDate).format("MMMM DD YYYY")
                  : "-"}
              </span>
            </button>
            <button className="flex gap-x-2 items-center justify-center">
              <AlarmClockIcon
                className="text-gray-500"
                height="16px"
                width="16px"
              />
              <span className="out-300-14 text-black">
                {data.request?.deadlineTime
                  ? dayjs(data.request?.deadlineTime, "hh:mm a").format(
                      "hh:mm a"
                    )
                  : "-"}
              </span>
            </button>
            <UrgencyFillIcon
              className={getColor(data.request?.urgency)}
              height={"16px"}
              width="16px"
            />

            <button
              onClick={() => setOpen(!open)}
              className={`${open ? "rotate-180 transform duration-300" : " "}`}
            >
              <ChevronIcon className="text-gray-500" />
            </button>
          </div>
        ) : (
          <div className="flex gap-x-5 items-center">
            <div className="text-success-600 out-500-14 ">
              Closed on{" "}
              {data.request?.requestClosingDate
                ? dayjs(data.request?.requestClosingDate)
                    .utc()
                    .local()
                    .format("MMMM DD YYYY, hh:mm a")
                : "-"}
            </div>
            <button
              onClick={() => setOpen(!open)}
              className={`${open ? "rotate-180 transform duration-300" : " "}`}
            >
              <ChevronIcon className="text-gray-500" />
            </button>
          </div>
        )}
      </div>

      {open && (
        <div className="p-4 max-h-[270px] overflow-y-scroll">
          <Message allowHover={false} message={data} />
        </div>
      )}
    </div>
  );
};

const PastRequestModal = ({ visible, onClose }) => {
  const { currentThreadMessage, currentThread, directMessageUsers } =
    useAppSelector((state) => state.conversations);

  const [requests, setRequests] = useState([]);

  const getRequests = () => {
    let Requests = [];

    if (
      currentThread &&
      currentThread.threadId &&
      currentThreadMessage.length > 0
    ) {
      Requests = currentThreadMessage.filter(
        (message) =>
          message.request &&
          message.request?.type &&
          message.request?.status === "Closed"
      );
    }

    if (currentThread.userId) {
      const user = directMessageUsers.find(
        (user) => user.userId === currentThread.userId
      );

      if (user && user.messages?.length > 0) {
        Requests = user.messages.filter(
          (message) =>
            message.request &&
            message.request?.type &&
            message.request?.status === "Closed"
        );
      }
    }

    return Requests;
  };

  useEffect(() => {
    const Requests = getRequests();

    setRequests(Requests);
  }, [currentThreadMessage, directMessageUsers]);

  return (
    <>
      <CustomModal
        visible={visible}
        width="511px"
        onCancel={() => onClose()}
        body={
          <div className="p-4">
            <div className="flex justify-between pb-2 mb-2 items-center border-b">
              <div className="flex items-center">
                <img
                  src="/images/v2/conversations/AttachmentHeader.svg "
                  className="w-[26px] h-5"
                ></img>
                <p className="out-300-14 ml-1.5 text-black">Requests</p>
              </div>

              <button onClick={onClose}>
                <CrossIcon />
              </button>
            </div>

            <div className="max-h-[300px] overflow-y-auto flex flex-col gap-y-2">
              {requests.map((req) => (
                <Request key={req._id} data={req} />
              ))}
            </div>
          </div>
        }
      />
    </>
  );
};

export default PastRequestModal;
