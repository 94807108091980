/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input, InputNumber } from "antd";

import React, { useState } from "react";

type InputProps = {
  className?: string;
  inputClassName?: string;
  rootStyle?: object;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  placeholder?: string;
  value?: string | number;
  onChange?: (e) => void;
  disabled?: boolean;
  maxLength?: number;
  name?: string;
  showError?: boolean;
  errorText?: string;
  helperText?: string;
  width?: string;
  valid?: boolean;
  formikHook?: any;
  type?: string;
  formatter?: any;
  parser?: any;
  mask?: any;
  formState?: any;
  control?: any;
  rules?: any;
  onFocus?: (e) => void;
  defaultValue?: string | number;
  onBlur?: (e) => void;
  onFocusChange?: (toggle, el) => void;
  onPressEnter?: (e) => void;
  wrapperClassName?: string;
  onClick?: (e) => void;
  autoFocus?: boolean;
  passwordUnverified?: boolean;
};

export default function CustomInputBox({
  width,
  className,
  inputClassName,
  rootStyle,
  prefix,
  suffix,
  placeholder,
  defaultValue,
  value,
  onChange,
  disabled,
  maxLength,
  name,
  type,
  formatter,
  parser,
  onFocus,
  onBlur,
  onFocusChange,
  onPressEnter,
  wrapperClassName,
  passwordUnverified,
  onClick,
  formState = {},
  autoFocus = false,
}: InputProps) {
  const [focused, setFocused] = useState(false);

  const InputComponent = formatter ? InputNumber : Input;
  const { errors } = formState;
  const showError = errors?.[name];
  const errorText = errors?.[name]?.message;

  return (
    <div
      className={`flex flex-col  items-start ${width} ${className}`}
      style={rootStyle}
    >
      {defaultValue && (
        <div className={`out-500-14 text-gray-700  mb-1.5`}>{defaultValue}</div>
      )}
      <div
        className={`transition-shadow duration-300 border ${
          focused && passwordUnverified
            ? showError || passwordUnverified
              ? "border-error-600 shadow-input-error"
              : focused
              ? "border-primary-500 shadow-input-focus"
              : disabled
              ? "border-grey-200"
              : "border-grey-200"
            : showError
            ? "border-error-600 shadow-input-error"
            : focused
            ? "border-primary-500 shadow-input-focus"
            : disabled
            ? "border-grey-200"
            : "border-grey-200"
        } bg-white rounded-[4px] h-full flex items-center w-full ${wrapperClassName}`}
      >
        {prefix && (
          <div
            className={`h-full px-2.5 flex-center text-grey-200 out-400-13 border-r ${
              disabled ? "border-r-grey-100" : "border-r-grey-200"
            } bg-background rounded-l-lg`}
          >
            {prefix}
          </div>
        )}
        <div
          className={`border-0 ${
            disabled ? "bg-background" : "bg-white"
          } rounded-lg w-full flex-center`}
        >
          <InputComponent
            type={type}
            bordered={false}
            disabled={disabled}
            defaultValue={defaultValue}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onClick={onClick}
            className={`custom-input-box h-[30px] ${inputClassName}`}
            maxLength={maxLength}
            name={name}
            autoFocus={autoFocus}
            formatter={formatter}
            parser={parser}
            onFocus={(e) => {
              if (typeof onFocus === "function") onFocus(e);
              setFocused(true);
              if (typeof onFocusChange === "function") onFocusChange(true, e);
            }}
            onBlur={(e) => {
              if (typeof onBlur === "function") onBlur(e);
              setFocused(false);
              if (typeof onFocusChange === "function") onFocusChange(false, e);
            }}
            onPressEnter={(e) => {
              if (typeof onPressEnter === "function") onPressEnter(e);
              setFocused(true);
            }}
          />
        </div>
        {suffix && (
          <div
            className={`input-suffix px-2.5 flex-center text-grey-200 out-400-14 rounded-r-lg`}
          >
            <div className="mr-2">{suffix}</div>
          </div>
        )}
      </div>
      {showError && (
        <div className="mt-1.5 out-300-12 text-left text-error-500">
          {errorText}
        </div>
      )}
    </div>
  );
}
