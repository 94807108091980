import React, { useEffect, useState } from "react";
import { Select, Empty, Menu, Dropdown } from "antd";
import { ChevronIcon } from "../svgV2/Chevron";
import CustomInputBox from "./CustomInputBox";
import { CheckIcon } from "../svgV2/Check";

type CustomSelectProps = {
  options?: any;
  value?: any;
  onDropdownVisibleChange?: any;
  onChange?: (e) => void;
  onMouseOver?: (e) => void;
  placeholder?: React.ReactNode;
  border?: boolean;
  backgroundColor?: string;
  disabled?: boolean;

  name?: string;

  wrapperClassName?: string;
  innerClassName?: string;
  onFocusChange?: (isTrue, e) => void;
  showBorderOnlyOnFocused?: boolean;
  defaultOpen?: boolean;
  showSearch?: boolean;
  mode?: "multiple" | "tags";
  customDropDownRender?: (menu) => React.ReactNode;
  group?: boolean;
  formState?: any;
  dropdownStyle?: React.CSSProperties;
};

const CustomSelect = ({
  options = [],
  value,
  onDropdownVisibleChange,
  onChange,
  placeholder,
  border = true,
  onMouseOver,

  backgroundColor = "bg-white",
  disabled,
  name,
  mode,
  wrapperClassName = "h-[30px]",
  innerClassName,
  onFocusChange,
  showBorderOnlyOnFocused = false,
  defaultOpen = false,
  showSearch = false,
  customDropDownRender,
  group = false,
  formState = {},
  dropdownStyle = {},
}: CustomSelectProps) => {
  const [focused, setFocused] = useState(defaultOpen);

  const { errors } = formState;
  const showError = errors?.[name];
  const errorText = errors?.[name]?.message;

  const wrapperClasses = [
    "transition-shadow duration-300",
    border && "border",
    showBorderOnlyOnFocused && focused && "border",
    focused && "border",
    showError
      ? "border border-error-600 shadow-input-error"
      : !border && !showBorderOnlyOnFocused
      ? ""
      : focused
      ? "border-primary-500 shadow-input-focus"
      : disabled
      ? "border-grey-200"
      : "border-grey-200",
    "bg-white rounded-[4px]",
    "flex items-center w-full",
    wrapperClassName,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <>
      <div className={wrapperClasses}>
        <div
          className={`
          rounded-[4px] w-full bg-transparent  `}
        >
          <Select
            //id={id}

            menuItemSelectedIcon={
              <CheckIcon className="text-primary-700 h-5 w-5" />
            }
            showSearch={showSearch}
            className={`w-full  flex items-center custom-select-input ${innerClassName}`}
            bordered={false}
            onFocus={(e) => {
              setFocused(true);
              if (typeof onFocusChange === "function") onFocusChange(true, e);
            }}
            onBlur={(e) => {
              setFocused(false);
              if (typeof onFocusChange === "function") onFocusChange(false, e);
            }}
            getPopupContainer={(trigger) => trigger.parentElement}
            //   defaultValue={

            //   }

            dropdownStyle={dropdownStyle}
            autoFocus={showSearch && defaultOpen}
            mode={mode}
            defaultOpen={defaultOpen}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            suffixIcon={
              <ChevronIcon
                className="text-gray-500 font-bold"
                // onClick={() => setFocused(!focused)}
              />
            }
            // open={focused}
            popupClassName={`truncate max-h-[320px] ${
              border ? "border border-gray-100" : ""
            }  bg-white rounded-lg`}
            dropdownRender={(menu) =>
              customDropDownRender ? (
                <>{customDropDownRender(menu)}</>
              ) : (
                <div className="  hidden-scrollbar ">{menu}</div>
              )
            }
            onDropdownVisibleChange={(open) => {
              setFocused(open);
              if (typeof onDropdownVisibleChange === "function") {
                onDropdownVisibleChange(open);
              }
            }}
            onChange={onChange}
            filterOption={(input, option) => {
              const lowerInput = input?.toLowerCase();
              const labelMatch =
                option.label &&
                `${option.label}`.toLowerCase().includes(lowerInput);
              const valueMatch =
                option.value &&
                `${option.value}`.toLowerCase().includes(lowerInput);

              return labelMatch || valueMatch;
            }}
          >
            {options.length > 0 ? (
              group ? (
                <>
                  {options.map((group) => (
                    <Select.OptGroup
                      label={
                        <p className="out-300-14  text-gray-500 px-3 py-2  border-b border-gray-200">
                          {group.title}
                        </p>
                      }
                      key={group.id}
                    >
                      {group.options.map((option) => (
                        <Select.Option
                          key={option.id}
                          value={option.value}
                          label={option.label || option.value}
                          className="py-2.5"
                        >
                          {option.render(value)}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </>
              ) : (
                options.map((option) => (
                  <Select.Option
                    key={option.id}
                    value={option.value}
                    label={option.label || option.value}
                    className="py-2.5"
                    onMouseOver={onMouseOver}
                  >
                    {option.render(value)}
                  </Select.Option>
                ))
              )
            ) : (
              <></>
            )}
          </Select>
        </div>
      </div>
      {showError && (
        <div className="mt-1.5 out-300-12 text-left text-error-600">
          {errorText}
        </div>
      )}
    </>
  );
};

export const MultiSelect = ({
  placeholder,
  options,
  onOpenChange,
  autoFocus = false,
}: {
  placeholder: string;
  options: any;
  onOpenChange?: (open) => void;
  autoFocus?: boolean;
}) => {
  const [search, setSearch] = useState("");
  const [filterOptions, setFilterOption] = useState(options);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (flag: boolean) => {
    console.log(flag, open, "flafffffff");
    setOpen(flag);
    if (onOpenChange && typeof onOpenChange === "function") {
      onOpenChange(flag);
    }
  };

  useEffect(() => {
    const filters = options?.filter(
      (option) =>
        option && option.label?.toLowerCase().includes(search.toLowerCase())
    );
    setFilterOption([...filters]);
  }, [search, options]);

  useEffect(() => {
    if (autoFocus) {
      handleOpenChange(true);
      // setOpen(true);
    }
  }, [autoFocus]);
  return (
    <Dropdown
      trigger={["click"]}
      onOpenChange={handleOpenChange}
      open={open}
      dropdownRender={() => (
        <Menu
          className=" rounded-[4px] w-full  shadow-dropdown max-h-[200px] overflow-y-scroll"
          //selectable
        >
          {filterOptions?.length > 0 ? (
            filterOptions.map((option) => {
              return (
                <Menu.Item
                  className=" py-2.5 px-4 rounded-lg"
                  key={option.id}
                  // onClick={() => {
                  //   option.onClick(option.value);
                  // }}
                >
                  {option.render(setSearch)}
                </Menu.Item>
              );
            })
          ) : (
            <div className="py-2">
              <Empty description="No users" />
            </div>
          )}
        </Menu>
      )}
      menu={{ items: filterOptions, onClick: () => console.log("Hello") }}
      placement="bottom"
    >
      <CustomInputBox
        placeholder={placeholder}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        autoFocus={autoFocus}
      />
    </Dropdown>
  );
};

export default CustomSelect;
