import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const WaddledItModal = ({ visible, onClose }) => {
  return (
    <CustomModal
      visible={visible}
      width="524px"
      onCancel={() => onClose()}
      body={
        <div className="max-h-[381px] py-[56px] flex-col flex items-center justify-center">
          <img
            src="/images/v2/onboarding/jumping-frosty.svg"
            className="h-[108px] w-[133px]"
          />
          <div className="out-500-14 text-gray-700 mt-[32px] mb-3">
            You just waddled this task. Way to move this project forward!
          </div>
          <CustomButton
            text="Close"
            className="bg-transparent text-gray-500"
            onClick={() => onClose()}
          />
        </div>
      }
    />
  );
};

export default WaddledItModal;
