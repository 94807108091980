import React, { useCallback } from "react";
import PageTitle from "../PageTitle";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";

import InfoByFrosty from "../../../Core/CommonV2/InfoByFrosty";
import { useAppSelector } from "../../../Core/redux/hooks";
import useSearch from "../../../Core/hooks/useSearch";
import EmptyState from "../../Library/EmptyState";

interface Props {
  text: string;
  title: string;
  description: string;
}

export const RoleCard = ({
  text = "0 members",
  title,
  description,
}: Props): JSX.Element => {
  return (
    <div
      className={`relative group rounded-lg px-3 py-2.5 border w-[201px] border-gray-200`}
    >
      <div className="out-500-14 text-gray-700">{title}</div>
      <div className="mt-1.5 out-300-14 text-gray-500">{text}</div>

      <div className="absolute top-1 right-2.5">
        <InfoByFrosty title={title} content={description} />
      </div>
    </div>
  );
};

const WorkspaceRoles = () => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);

  const getConut = useCallback(
    (id) => {
      return (
        (tenantMembers || []).filter((user) => user.roleid == id)?.length || 0
      );
    },
    [tenantMembers]
  );

  const roles = [
    {
      id: 1,
      title: "Owner",
      text: `${getConut(1)} members`,
      description: "Can manage all admins, users, workspace settings and bills",
    },
    {
      id: 2,
      title: "Admin",
      text: `${getConut(2)} members`,
      description: "Has permissions for all administrative features",
    },
    {
      id: 3,
      title: "Basic User",
      text: `${getConut(3)} members`,
      description: "Doesn’t have permissions for administrative features",
    },
  ];

  const { search, setSearch, filteredData } = useSearch(roles, ["title"]);

  if (user.roleId == 4) {
    return (
      <EmptyState
        message={
          <>You dont have any access. To view, ask the owner for access</>
        }
      />
    );
  } else {
    return (
      <div className="pb-40">
        <PageTitle
          title={"Roles"}
          subtext={"View workspace roles and permissions."}
        />
        <div className="my-6 flex justify-between">
          <CustomSearchBox
            placeholder="Search roles"
            style={{ width: "50%" }}
            search={search}
            setSearch={setSearch}
          />
        </div>

        <div className="flex gap-x-3">
          <div className="out-500-14 text-gray-500">Roles</div>{" "}
          <div className="bg-primary-50 px-2 py-0.5 rounded-full out-500-12 text-primary-600">
            3
          </div>
        </div>
        <div className="flex flex-wrap gap-8 mt-6 w-[667px]">
          {filteredData.map((role) => (
            <RoleCard
              key={role.id}
              title={role.title}
              text={role.text}
              description={role.description}
            />
          ))}
        </div>
      </div>
    );
  }
};

export default WorkspaceRoles;
