import React, { useState } from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import { CopyIcon } from "../svgV2/CopyIcon";

/**
 * @param  {String} text
 * @param  {String} width
 * @param   {String} height
 * @param  {Function} onClick
 * @param  {String} icon
 * */

type CopyButtonProps = {
  text?: string;
  className?: string;
  width?: string;
  height?: string;
  copyIcon?: boolean;
};

export default function CopyButton({
  text,
  width = "64px",
  height = "28px",
  className,
  copyIcon,
}: CopyButtonProps) {
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger.parentElement}
      className=""
      overlay={
        <Menu className="w-[110px] rounded-lg custom-menu-dropdown bg-white  shadow-dropdown">
          <Menu.Item key="copy" className=" py-2.5 px-4 rounded-t-lg">
            <span className="ml-2 text-sm font-normal text-gray-900 whitespace-nowrap">
              Copied
            </span>
          </Menu.Item>
        </Menu>
      }
      open={open}
      trigger={["click"]}
    >
      <button
        onClick={async () => {
          setOpen(true);
          await navigator.clipboard.writeText(text);
          setTimeout(() => setOpen(false), 1000);
          setShowTooltip(false);
        }}
        className={`${className} `}
        style={{ width, height }}
      >
        <Tooltip
          title={"Copy"}
          placement={"bottom"}
          open={showTooltip}
          onOpenChange={setShowTooltip}
        >
          <div className="flex items-center justify-center">
            {copyIcon ? <CopyIcon className="text-gray-black " /> : <p>Copy</p>}
          </div>
        </Tooltip>
      </button>
    </Dropdown>
  );
}
