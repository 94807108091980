import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetVault,
  updateVault,
} from "../../../Core/redux/slices/vault.slice";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import {
  updateVaultFile,
  updateVaultFolder,
  updateVaultLink,
} from "../../../Core/redux/api/vaultAPI";

const RenameModal = () => {
  const { renameModal } = useAppSelector((state) => state.vault);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const [renameDetails, setRenameDetails] = useState(renameModal.currentName);

  useEffect(() => {
    setRenameDetails(renameModal.currentName);
  }, [renameModal]);

  const handleSubmit = async () => {
    if (renameModal.type === "folder") {
      await dispatch(
        updateVaultFolder({
          folderName: renameDetails,
          folderId: renameModal.id,
          userId: user.id,
        })
      );
    }

    if (renameModal.type === "file") {
      await dispatch(
        updateVaultFile({
          name: renameDetails,
          fileId: renameModal.id,
          userId: user.id,
        })
      );
    }

    if (renameModal.type === "link") {
      await dispatch(
        updateVaultLink({
          linkName: renameDetails,
          linkId: renameModal.id,
          userId: user.id,
        })
      );
    }

    dispatch(resetVault(["renameModal"]));
  };

  return (
    <CustomModal
      visible={renameModal.visible}
      width="352px"
      onCancel={() =>
        dispatch(
          updateVault({
            key: "renameModal",
            value: {
              visible: false,
            },
          })
        )
      }
      body={
        <div className="p-4">
          <div>
            <div className="flex justify-between">
              <p className="text-black out-500-14 leading-5">Rename</p>
              <button
                onClick={() =>
                  dispatch(
                    updateVault({
                      key: "renameModal",
                      value: {
                        visible: false,
                      },
                    })
                  )
                }
              >
                <CrossIcon className="text-gray-700" />
              </button>
            </div>
            <div className="mt-4">
              <CustomInputBox
                placeholder="Rename"
                value={renameDetails}
                onChange={(e) => {
                  setRenameDetails(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex mt-[33px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() =>
                dispatch(
                  updateVault({
                    key: "renameModal",
                    value: {
                      visible: false,
                    },
                  })
                )
              }
            />
            <CustomButton
              text="Save"
              height="30px"
              width="51px"
              onClick={handleSubmit}
            />
          </div>
        </div>
      }
    />
  );
};

export default RenameModal;
