import { Tour } from "antd";
//import Tour from "@rc-component/tour";

import React, { useState } from "react";

import type { TourProps } from "antd";
import { ArrowIcon } from "../../Core/svgV2/Arrow";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  addDummyDataForMessagesScreen,
  resetToInitialConversation,
  updateConversations,
} from "../../Core/redux/slices/conversations.slice";
import TakeTourModal from "../../Core/CommonV2/TakeTourModal";

import { conversationsDummyData, projectDummyData } from "../../Core/dummyData";
import {
  addDummyDataForProject,
  resetToInitialProjects,
} from "../../Core/redux/slices/projects.slice";

import { updateTourStatus } from "../../Core/redux/api/dashboardAPI";

const TourPanel = ({ step, current, total, img, title, description }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  return (
    <div className="bg-white p-3 rounded-lg w-[403px] relative overflow-hidden">
      <div className="out-500-16 text-primary-600">{title}</div>
      <div className="flex gap-x-[23px] items-center">
        <div className="out-300-14 text-gray-500 w-[246px] mt-2 leading-5">
          {description}
        </div>
        <div>
          <img src={img} />
        </div>
      </div>
      <div className="mt-3 out-500-14 text-gray-700 leading-5">
        {current + 1}/{total}
      </div>
      <div className="mt-3">
        <button
          onClick={async () => {
            if (current + 1 === total) {
              localStorage.setItem("MessageTour", "1");
              await dispatch(
                updateTourStatus({
                  tourStatus: "messagesTourStatus",
                  userId: user.id,
                })
              );
              localStorage.removeItem("appTour");
              step.onNext();
              dispatch(resetToInitialConversation({}));
              dispatch(resetToInitialProjects({}));
              window.location.reload();
            }
            step.onNext();
          }}
        >
          {current + 1 === total ? (
            <div className="flex items-center gap-x-2">
              <span className="text-primary-700 out-500-14">Finish Tour</span>{" "}
            </div>
          ) : (
            <div className="flex items-center gap-x-2">
              <span className="text-primary-700 out-500-14">Next</span>{" "}
              <ArrowIcon className="text-primary-600" />
            </div>
          )}
        </button>
      </div>
      <div className="flex items-center absolute left-0 right-0 bottom-0 rounded-b-lg ">
        <div
          className="inline-block bg-primary-700 h-1 "
          style={{
            width: `${(403 / total) * (current + 1)}px`,
          }}
        ></div>
        <div className="inline-block flex-1 bg-gray-200 h-1 "></div>
      </div>
    </div>
  );
};

const ConversationsAppTour = () => {
  const [run, setRun] = useState(false);
  const [showTakeTourModal, setShowTakeTourModal] = useState(true);
  // const [startAt, setStartAt] = useState(0);
  const { toursPopup } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);

  const steps: TourProps["steps"] = [
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Messages"}
            description={
              "Messages section is a central hub for all your conversations to communicate and collaborate. It is the perfect place to keep everyone in the loop and ensure your project stays on track."
            }
            total={6}
            img="/images/v2/conversations/tour-1.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Messages</div>,
      placement: "rightTop",
      target: () =>
        document.querySelector("#conversations-tour-messages") as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Input bar"}
            description={
              "Effortlessly mention team members, attach important files, request files using commands and add a touch of personality with emojis, all in one intuitive interface."
            }
            total={6}
            img="/images/v2/conversations/tour-2.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Input bar</div>,
      placement: "top",
      target: () =>
        document.querySelector("#conversations-tour-input") as HTMLElement,
    },
    {
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Request"}
            description={`With our convenient "/req" feature, you can easily request any file or document from anyone in your organization by simply typing in the command, saving you time and hassle in tracking down important files.`}
            total={6}
            img="/images/v2/conversations/tour-3.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Request</div>,
      placement: "top",
      target: () =>
        document.querySelector(
          "#conversations-tour-input-request"
        ) as HTMLElement,
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Drag and Drop"}
            description={`Simply drag and drop files directly into the input bar, making sharing important documents and files with your team a breeze.`}
            total={6}
            img="/images/v2/conversations/tour-4.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Drag and Drop</div>,
      placement: "left",
      target: () =>
        document.querySelector(
          "#conversations-tour-chat-window"
        ) as HTMLElement,
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Reactions"}
            description={`Reacting to messages is a fun and convenient feature that allows you to quickly respond to your team's messages with a simple click.`}
            total={6}
            img="/images/v2/conversations/tour-5.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Reactions</div>,
      placement: "left",
      target: () =>
        document.querySelector(
          `[data-message-id="64b7bcb06914ff8efb4d1f53"]`
        ) as HTMLElement,
    },
    {
      style: {
        width: "403px",
      },
      renderPanel: (step, current) => {
        return (
          <TourPanel
            step={step}
            current={current}
            title={"Save in Library"}
            description={`Easily save files sent to you in messages directly to your Library, making it simple to organize and access important documents and files shared within your team, and streamlining your workflow.`}
            total={6}
            img="/images/v2/conversations/tour-6.svg"
          />
        );
      },
      title: <div className="out-500-16 text-primary-600">Save in Library</div>,
      placement: "left",
      target: () =>
        document.querySelector(
          "#conversations-tour-save-in-library"
        ) as HTMLElement,
    },
  ];

  const dispatch = useAppDispatch();

  // if (localStorage.getItem("MessageTour") === "1") {
  //   return <></>;
  // }

  if (
    !run &&
    showTakeTourModal &&
    toursPopup?.messageTour !== true
    // localStorage.getItem("MessageTour") !== "1"
  ) {
    return (
      <TakeTourModal
        onOk={() => {
          // add dummy datas

          dispatch(addDummyDataForMessagesScreen(conversationsDummyData));
          dispatch(addDummyDataForProject(projectDummyData));
          localStorage.setItem("appTour", "1");

          setRun(true);
        }}
        module="Conversations"
        onClose={async () => {
          setRun(false);
          localStorage.setItem("MessageTour", "1");
          await dispatch(
            updateTourStatus({
              tourStatus: "messagesTourStatus",
              userId: user.id,
            })
          );
          // updateGlobal({
          //   key: "toursPopup",
          //   value: {
          //     messagesTour: 1,
          //   },
          // });
          localStorage.removeItem("appTour");
          setShowTakeTourModal(false);
          window.location.reload();
        }}
      />
    );
  }

  return (
    <>
      <Tour
        open={run}
        onClose={() => setRun(false)}
        steps={steps}
        rootClassName="helelooo"
        mask={{
          style: {
            // boxShadow: "inset 0 0 15px #333",
            // boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            // WebkitBackdropFilter: "blur(px)",
          },

          color: "rgba(0, 0, 0, 0.25)",
        }}
        onChange={(current) => {
          if (current === 1 || current === 3) {
            dispatch(
              updateConversations({ key: "requestOption", value: false })
            );
          }
          if (current === 2) {
            dispatch(
              updateConversations({ key: "requestOption", value: true })
            );
          }
          if (current === 5) {
            dispatch(
              updateConversations({
                key: "saveInLibrary",
                value: { visible: true, file: {} },
              })
            );
          }
        }}
      />
    </>
  );
};

export default ConversationsAppTour;
