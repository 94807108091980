import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { Progress } from "antd";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { compeleteResetProjectOnboarding } from "../../../Core/redux/slices/projectOnboarding.slice";
import { useNavigate } from "react-router";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { RightArrowIcon } from "../../../Core/svgV2/RightArrow";
import { client } from "../../../Core/utils/axiosClient";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import { getMyBoardTasks } from "../../../Core/redux/api/myBoardAPI";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import { useQueryClient } from "react-query";

const ProjectCreationModal = () => {
  const [progress, setProgress] = useState(33);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { projectCreationModal } = useAppSelector(
    (state) => state.projectOnboarding
  );
  const { selectedTasks } = useAppSelector((state) => state.myBoard);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (projectCreationModal?.visible) {
      if (progress <= 99) {
        setTimeout(() => {
          setProgress(progress + 33);
        }, 2000);
      }
    }
  }, [projectCreationModal, progress]);
  return (
    <CustomModal
      title={
        <div className="flex items-center">
          <p className="out-400-12 text-gray-500">New group</p>
        </div>
      }
      width={"524px"}
      height={"532px"}
      onCancel={() => console.log("")}
      visible={projectCreationModal?.visible}
      body={
        <>
          <div className="flex relative flex-col w-full h-[532px] justify-end  overflow-hidden rounded-2xl">
            <div className="flex flex-col w-full h-full justify-between relative overflow-hidden bg-white ">
              <div className={`   w-full h-full absolute `}>
                <img
                  src="/images/v3/onboarding/CreateProjectFrosty.png"
                  className=""
                />
              </div>

              <div
                className={`flex flex-col py-[60px] gap-y-1.5 ${
                  progress <= 33 ? "z-10" : ""
                }`}
              >
                <p className="justify-center flex out-300-24  items-center">
                  Creating&nbsp;
                  <span className="out-500-24">
                    {projectCreationModal?.data?.projectName}
                  </span>
                </p>
                <p className="text-gray-700 flex out-300-14 justify-center items-center">
                  You’re almost there
                </p>
              </div>
            </div>

            <Progress
              showInfo={false}
              strokeWidth={4}
              className="w-full top-[13px]      "
              strokeColor={"#2E90FA"}
              percent={progress}
              trailColor="#EDF3F9"
              style={{ marginBottom: "4px" }}
            />
          </div>
          <div className="absolute top-[498px] flex w-full justify-center">
            {" "}
            {progress <= 33 ? (
              <p className="out-300-14 flex max-w-[524px] ">
                Frosty is understanding your preferences...
              </p>
            ) : progress <= 66 ? (
              <p className="out-300-14 flex max-w-[524px] ">
                Frosty is putting together the building blocks...
              </p>
            ) : (
              <p className="out-300-14 flex max-w-[524px] ">
                Frosty is putting together the building blocks...
              </p>
            )}
          </div>
          {progress > 66 ? (
            <div className="absolute top-[446px] flex w-full justify-center">
              {" "}
              <CustomButton
                text="Take Me to Dashboard"
                className=" text-white bg-primary-600 out-500-14"
                height="40px"
                width="208px"
                iconAlignment="right"
                icon={<RightArrowIcon />}
                onClick={async () => {
                  dispatch(compeleteResetProjectOnboarding({}));
                  queryClient.invalidateQueries(["projectList"]);
                  if (selectedTasks && selectedTasks.length > 0) {
                    const payload = {
                      taskIds: selectedTasks,
                      projectId: projectCreationModal?.data?.projectId,
                    };
                    await client.post("/my-board/move-to-project", payload);
                    await dispatch(
                      updateMyBoard({ key: "selectedTasks", value: [] })
                    );
                    dispatch(
                      getMyBoardTasks({
                        limit: DEFAULT_LIMIT,
                        offset: DEFAULT_OFFSET,
                      })
                    );
                    navigate(
                      `/schedule/${projectCreationModal?.data?.projectId}/backlog`
                    );
                    dispatch(
                      updateDashboard({
                        key: "alertPopupModal",
                        value: {
                          visible: true,
                          data: {
                            title: `Tasks Moved to New Project`,
                            subtitle: `Created a new project`,
                            description:
                              projectCreationModal?.data?.projectName,
                          },
                        },
                      })
                    );
                  } else {
                    navigate(
                      `/dashboard/${projectCreationModal?.data?.projectId}`
                    );

                    dispatch(
                      updateDashboard({
                        key: "alertPopupModal",
                        value: {
                          visible: true,
                          data: {
                            title: `New Project`,
                            subtitle: `Created a new project`,
                            description:
                              projectCreationModal?.data?.projectName,
                          },
                        },
                      })
                    );

                    dispatch(
                      updateDashboard({
                        key: "createRoleModal",
                        value: true,
                      })
                    );
                  }
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export default ProjectCreationModal;
