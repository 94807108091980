import React from "react";
import Icon from "@ant-design/icons";

const ThreadSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      d="M2.7 13.283a.5.5 0 001 0h-1zM3.2 3.2v-.5a.5.5 0 00-.5.5h.5zm9.6 0h.5a.5.5 0 00-.5-.5v.5zm0 8v.5a.5.5 0 00.5-.5h-.5zm-9.1 2.083V3.2h-1v10.083h1zM3.2 3.7h9.6v-1H3.2v1zm9.1-.5v8h1v-8h-1zm.5 7.5H6v1h6.8v-1z"
    ></path>
    <path
      fill="currentColor"
      d="M6 11.2v-.5h-.25l-.15.2.4.3zm-1.6 1.3a.5.5 0 10.8.6l-.8-.6zm1.6-.8h.8v-1H6v1zm-.4-.8l-1.2 1.6.8.6 1.2-1.6-.8-.6z"
    ></path>
    <path stroke="currentColor" strokeLinecap="round" d="M6.4 7.2h3.2"></path>
  </svg>
);

const ThreadIcon = (props) => {
  return <Icon component={ThreadSvg} {...props} />;
};

export { ThreadIcon };
