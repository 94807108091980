import { Modal } from "antd";
import React, { ReactNode } from "react";

type CustomModalProps = {
  visible?: boolean;
  onCancel: any;
  title?: ReactNode | string;
  body?: ReactNode | string;
  //   footer?: ReactNode | string;
  height?: string;
  width?: string;
  header?: boolean;
  menuDropdown?: ReactNode | boolean;
  footer?: ReactNode | boolean;
  mask?: boolean;
  maskStyle?: any;
  style?: React.CSSProperties;
  centered?: boolean;
};

const CustomModal = ({
  visible = false,
  onCancel,
  title,
  body,
  footer,
  height,
  width,
  header,
  menuDropdown,
  mask,
  maskStyle,
  style,
  centered = true,
}: CustomModalProps) => {
  if (!visible) {
    return <></>;
  }
  return (
    <Modal
      open={visible}
      mask={mask}
      maskStyle={maskStyle}
      centered={centered}
      // zIndex={50}
      width={width}
      destroyOnClose
      keyboard
      onCancel={onCancel}
      style={style}
      //footer={footer}
      modalRender={() => (
        <div
          className="w-full h-full bg-white rounded-xl shadow-md"
          // style={
          //   // pointerEvents: "all",
          //   // //   maxHeight: height,
          //   // //   minHeight: height,
          //   // maxWidth: width,
          //   // maxHeight: height,
          //   // //   minWidth: width,
          //   // //   overflowY: "scroll",
          //   style
          // }
          style={{
            pointerEvents: "all",
            //   maxHeight: height,
            //   minHeight: height,
            maxWidth: width,
            maxHeight: height,
            //   minWidth: width,
            //   overflowY: "scroll",
          }}
        >
          {body}
        </div>
      )}
    />
  );
};

export default CustomModal;
