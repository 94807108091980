import React, { useEffect, useState } from "react";
import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  handleCompanyMembers,
  resetToInitialOnboarding,
} from "../../../Core/redux/slices/onboarding.slice";
import CurrentStep from "./CurrentStep";
import { Dropdown, Menu } from "antd";
import StepProgressBar from "./StepProgressBar";
import { useNavigate } from "react-router-dom";
import { CrossIconLarge } from "../../../Core/svgV2/CrossIcon";
import oneToFifty from "../../../Core/LottieFilesV2/1-50selected.json";
import fiftyOne_100 from "../../../Core/LottieFilesV2/51-100selected.json"; //51-100
import oneHundred_500 from "../../../Core/LottieFilesV2/51-500selected.json";
import more500 from "../../../Core/LottieFilesV2/morethan500selected.json";
import Lottie from "lottie-react";
import { EditTwoIcon } from "../../../Core/svgV2/Edit02Icon";
import { updateSettings } from "../../../Core/redux/slices/settings.slice";
import { PlusIconSecondary } from "../../../Core/svgV2/PlusIcon";
import GuesttInteractionSettingsModal from "../../Settings/Modals/GuestInteractionSettingsModal";

const Content = () => {
  const navigate = useNavigate();
  const { companyMembers } = useAppSelector((state) => state.onboarding);
  const { newGuestInteractionSettings } = useAppSelector(
    (state) => state.settings
  );
  const { tenant_id } = useAppSelector((state) => state.userDetails).user;

  const [memberList, setMemberList] = useState(companyMembers);
  const getRoleName = (role) => {
    switch (+role) {
      case 1:
        return "Owner";
      case 2:
        return "Admin";
      case 3:
        return "Basic User";
      case 4:
        return "Guest";
      default:
        return "Select Role";
    }
  };

  const defineRole = (roleId, id) => {
    if (memberList.length <= id) {
      setMemberList((old) => [
        ...old,
        {
          id: old.length + 1,
          email: "",
          roleId: 0,
        },
      ]);
    }

    const oldMemberList = (oldList) => {
      const list = oldList.map((m) => {
        if (m.id === id) {
          return {
            ...m,
            roleId: roleId,
          };
        }
        return m;
      });
      return list;
    };
    setMemberList((old) => [...oldMemberList(old)]);
  };

  const setEmail = (email, id) => {
    const oldMemberList = memberList.map((m) => {
      if (m.id === id) {
        return {
          ...m,
          email: email,
        };
      }
      return m;
    });

    setMemberList(oldMemberList);
  };

  const clearData = (id) => {
    const clearList = memberList.map((m) => {
      if (m.id === id) {
        return {
          ...m,
          email: "",
          roleId: 0,
        };
      }
      return m;
    });

    setMemberList(clearList);
  };

  console.log(memberList, "member list");
  const finalList = memberList.filter(
    ({ email, roleId }) => email !== "" && roleId !== 0
  );
  console.log(finalList, "finalList");
  const dispatch = useAppDispatch();
  return (
    <div className="p-6 w-full h-screen  bg-white">
      {tenant_id ? (
        <div
          className="ml-10 mt-10 flex items-center justify-center out-500-14 text-gray-500 gap-x-2  absolute z-[100]"
          role="button"
          onClick={() => {
            dispatch(
              updateSettings({
                key: "newGuestInteractionSettings",
                value: [],
              })
            );
            dispatch(
              updateSettings({
                key: "guestInteractionSettingsModal",
                value: [],
              })
            );
            dispatch(resetToInitialOnboarding({}));
            navigate(-1);
          }}
        >
          <CrossIconLarge className="text-gray-500" /> Cancel
        </div>
      ) : (
        ""
      )}
      <div className="pl-14 w-full  bg-white flex  justify-start mt-[131px]">
        <StepProgressBar
          currentStep={5}
          totalSteps={5}
          givenElementId="form5"
        />
        <div>
          <CurrentStep currentStep={5} totalStep={5} className="" id="form5" />
          <h1 className="out-500-20 text-gray-900 mb-2 mt-6">
            Create your waddle
          </h1>
          <div className="flex justify-start items-center text-show-animation gap-x-2 out-400-16 text-gray-500">
            <img
              src="/images/icons/frosty-icon.svg"
              className="min-h-[26px] min-w-[26px]"
              alt="frosty"
            />
            Start by adding team members. You can edit these later.
          </div>

          <div className="flex flex-col gap-y-6">
            {memberList.map((m, index) => (
              <div key={index} className="flex flex-col gap-2">
                <div className="flex items-center gap-x-2" key={index}>
                  <CustomOnboardingInput
                    className="w-[500px]"
                    placeholder="name@example.com"
                    value={m.email}
                    onChange={(e, value) => setEmail(value, m.id)}
                    customEnd={
                      <div>
                        <Dropdown
                          overlay={
                            <Menu className="w-[367px] rounded-lg custom-menu-dropdown bg-error-600 shadow-dropdown">
                              <Menu.Item className="py-2.5 px-4">
                                <div
                                  className="gap-y-1 pt-1.5"
                                  onClick={() => defineRole(1, m.id)}
                                >
                                  <p className="out-500-14 whitespace-nowrap text-gray-900">
                                    Owner
                                  </p>
                                  <p className="out-400-12 text-gray-500 whitespace-nowrap">
                                    Can manage all admins, users, product
                                    settings and bills
                                  </p>
                                </div>
                              </Menu.Item>
                              <Menu.Item
                                className="py-2.5 px-4"
                                onClick={() => defineRole(2, m.id)}
                              >
                                <div className="gap-y-1">
                                  <p className="out-500-14 whitespace-nowrap text-gray-900">
                                    Admin
                                  </p>
                                  <p className="out-400-12 text-gray-500 whitespace-nowrap">
                                    Has permissions for all administrative
                                    features
                                  </p>
                                </div>
                              </Menu.Item>
                              <Menu.Item
                                className="py-2.5 px-4"
                                onClick={() => defineRole(3, m.id)}
                              >
                                <div className="gap-y-1">
                                  <p className="out-500-14 whitespace-nowrap text-gray-900">
                                    Basic user
                                  </p>
                                  <p className="out-400-12 text-gray-500 whitespace-nowrap">
                                    Do not have permissions for administrative
                                    features
                                  </p>
                                </div>
                              </Menu.Item>
                              <Menu.Item
                                className="py-2.5 px-4"
                                onClick={() => {
                                  defineRole(4, m.id);
                                  dispatch(
                                    updateSettings({
                                      key: "guestInteractionSettingsModal",
                                      value: {
                                        visible: true,
                                        email: m.email,
                                        userId: "",
                                      },
                                    })
                                  );
                                }}
                              >
                                <div className="gap-y-1">
                                  <p className="out-500-14 whitespace-nowrap text-gray-900">
                                    Guest
                                  </p>
                                  <p className="out-400-12 text-gray-500 ">
                                    Has access to their own projects and have
                                    limited interaction with specific people
                                    within the workspace
                                  </p>
                                </div>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={["click"]}
                        >
                          <div className="flex items-center cursor-pointer gap-x-3 mr-3">
                            <p className="out-400-14 text-gray-500 whitespace-nowrap">
                              {getRoleName(m.roleId)}
                            </p>
                            <img
                              src="/images/icons/chevron-down-gray.svg"
                              className="w-4 h-4"
                            />
                          </div>
                        </Dropdown>
                      </div>
                    }
                  />
                </div>
                {/* //guest role info */}
                {m.email && m.roleId === 4 && (
                  <div className="w-[556px] ml-[-8px] bg-primary-50 p-2 rounded-[8px] flex justify-between gap-2">
                    <div className="flex flex-col gap-2 ">
                      <div className="out-500-14 leading-5 text-black">
                        Can interact with
                      </div>
                      <div className="out-300-12 text-gray-500">
                        Select people in your workspace, this user can interact
                        with.
                      </div>
                    </div>
                    <div>
                      {newGuestInteractionSettings?.find(
                        (u) => u.mail === m.email
                      ) ? (
                        <EditTwoIcon
                          className="text-gray-500"
                          onClick={() =>
                            dispatch(
                              updateSettings({
                                key: "guestInteractionSettingsModal",
                                value: {
                                  visible: true,
                                  email: m.email,
                                  userId: "",
                                },
                              })
                            )
                          }
                        />
                      ) : (
                        <PlusIconSecondary
                          className="text-gray-700"
                          onClick={() =>
                            dispatch(
                              updateSettings({
                                key: "guestInteractionSettingsModal",
                                value: {
                                  visible: true,
                                  email: m.email,
                                  userId: "",
                                },
                              })
                            )
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between  ">
            <div className="flex items-center gap-x-6 mt-6">
              <CustomButton
                className="bg-primary-600 text-white"
                disabled={finalList.length == 0 ? true : false}
                onClick={() => {
                  dispatch(handleCompanyMembers(finalList));
                  dispatch(changeStep(6));
                }}
                text="Continue"
                width="76px"
                height="30px"
              />
              <CustomButton
                className="bg-white border-gray-200 border-[1px]"
                onClick={() => dispatch(changeStep(4))}
                text="Back"
                width="51px"
                height="30px"
              />
            </div>
            <div
              className="out-500-14 text-primary-700 cursor-pointer mt-6"
              onClick={() => dispatch(changeStep(6))}
            >
              {" "}
              I’ll do it later
            </div>
          </div>
        </div>
      </div>
      <GuesttInteractionSettingsModal
        onClose={() => {
          dispatch(
            updateSettings({
              key: "guestInteractionSettingsModal",
              value: {
                visible: false,
                email: "",
                userId: "",
              },
            })
          );
        }}
      />
    </div>
  );
};

const Sidebar = () => {
  const { companySize } = useAppSelector((state) => state.onboarding);

  const [animationData, setAnimationData] = useState();

  useEffect(() => {
    if (+companySize === 1) {
      setAnimationData(oneToFifty as any);
    }
    if (+companySize === 3) {
      setAnimationData(fiftyOne_100 as any);
    }
    if (+companySize === 2) {
      setAnimationData(oneHundred_500 as any);
    }
    if (+companySize === 4) {
      setAnimationData(more500 as any);
    }
  }, [companySize]);
  return (
    <div className="w-full h-screen overflow-hidden     flex flex-col justify-center items-center ">
      <Lottie animationData={animationData} loop={false} />
    </div>
  );
};

const MapSelection = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default MapSelection;
