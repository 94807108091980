import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";

import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { resetConversations } from "../../../Core/redux/slices/conversations.slice";
import {
  getThreads,
  leaveThread,
} from "../../../Core/redux/api/conversationAPI";

const LeaveGroupModal = ({ visible, onClose }) => {
  const { leaveThreadDetails } = useAppSelector((state) => state.conversations);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Leave group</p>
            <button onClick={onClose}>
              {" "}
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to leave this group? <br />
            This action cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={onClose}
            />
            <CustomButton
              text="Leave group"
              height="30px"
              width="101px"
              className="bg-error-700 text-white"
              onClick={async () => {
                await dispatch(
                  leaveThread({
                    userId: String(user.id),
                    threadId: leaveThreadDetails.threadId,
                  })
                );
                onClose();
                dispatch(
                  resetConversations([
                    "showThreadProfileDrawer",
                    "currentThread",
                    "currentThreadMessage",
                  ])
                );

                dispatch(getThreads({ userId: user.id }));
              }}
            />
          </div>
        </div>
      }
    />
  );
};

export default LeaveGroupModal;
