import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { resetConversations } from "../../../Core/redux/slices/conversations.slice";
import ParseMentions from "../ParseMentions";
import dayjs from "dayjs";
import { ObjectID } from "bson";
import {
  forwardMessageToUsers,
  getPrivateChats,
  getThreads,
} from "../../../Core/redux/api/conversationAPI";
import { resetVault } from "../../../Core/redux/slices/vault.slice";
import { getOrganizationUsers } from "../../../Core/redux/api/projectAPI";

const UserCard = ({
  thread,
  selectedThread,
  setSelectedThread,
  isSelected,
}) => {
  const isThread = thread.threadId;
  const getAvatar = () => {
    if (thread.threadId) {
      return (
        <div className="relative">
          <CustomAvatar
            src={"/images/avatar2.png"}
            title={thread.threadName}
            size={48}
            fontSize={"20px"}
            userId={thread.userId}

            // badge
          />
          <div className="absolute bottom-0 right-0">
            {" "}
            <img
              className=""
              src="/images/v2/conversations/group-placeholder.svg"
            />
          </div>
        </div>
      );
    }
    return (
      <div className="relative">
        <CustomAvatar
          src={thread.profilePicture}
          title={`${thread.firstname} ${thread.lastname}`}
          size={48}
          badge
          fontSize={"20px"}
          userId={thread.userId}
        />
      </div>
    );
  };

  console.log(thread, isSelected, "asdfghjk");

  return (
    <div
      onClick={() => setSelectedThread(thread)}
      className={`flex flex-row cursor-pointer gap-12px items-start group ${
        isSelected ? "bg-gray-50" : "bg-[#F9FBFE]"
      } hover:bg-gray-50  h-[64px] border-b w-[318px]  py-2 max-w-[318px] overflow-hidden  pl-2`}
    >
      {getAvatar()}
      <div className="flex flex-col pl-[12px] w-[180px] h-[48px] items-start gap-[8px] ">
        <div className="flex  flex-1 flex-row items-center h-[20px] ">
          <div className=" font-[500] truncate text-[14px] leading-[20px]">
            {isThread
              ? thread.threadName
              : `${thread.firstname} ${thread.lastname}`}{" "}
            {}
          </div>

          <div className="pl-[8px] text-[12px] leading-[18px] text-gray-500 whitespace-nowrap">
            {" "}
            {thread?.lastMessageTime
              ? dayjs(thread?.lastMessageTime)
                  .utc(thread?.lastMessageLocal ? false : true)
                  .local()
                  .fromNow()
              : ""}
          </div>
        </div>
        <div className="leading-[20px] w-[160px] text-[14px] font-[300] truncate ">
          <ParseMentions value={thread.lastMessage || ""} />
        </div>
      </div>
    </div>
  );
};

const ShareModal = ({ visible, onClose, socket }) => {
  const dispatch = useAppDispatch();
  const { threads, directMessageUsers, forwardMessage } = useAppSelector(
    (state) => state.conversations
  );

  const { shareInMessageModal } = useAppSelector((state) => state.vault);

  const { user } = useAppSelector((state) => state.userDetails);

  const [selectedThread, setSelectedThread] = useState({
    threadId: "",
    userId: "",
  });
  const allThreads = [...threads, ...directMessageUsers];
  const [search, setSearch] = useState("");

  const getDM = async () => {
    await dispatch(getOrganizationUsers({ userId: user.id }));
    await dispatch(getPrivateChats({ userId: user.id }));
  };

  useEffect(() => {
    dispatch(getThreads({ userId: user.id }));
    getDM();
  }, []);

  const handelForwardMessage = async () => {
    // const { firstName, lastName, id, profilePicture } = user;

    const objectID = new ObjectID();

    let message: any = {
      ...forwardMessage,
      reciverId: null,
      senderId: user.id,
      threadId: null,
      senderName: user.firstName + " " + user.lastName,

      _id: objectID.toString(),
    };

    if (shareInMessageModal.visible) {
      message = {
        message:
          shareInMessageModal.type === "folder" ||
          shareInMessageModal.type === "link"
            ? shareInMessageModal.url
            : "",
        senderName: `${user.firstName} ${user.lastName}`,
        createdAt: new Date(),
        senderProfilePicture: user.profilePicture,
        replyMessage: {},
        attachments:
          shareInMessageModal.type === "file" ? shareInMessageModal.file : [],
        reciverId: null,
        senderId: user.id,
        _id: objectID.toString(),
      };
    }

    if (selectedThread.threadId) {
      message.threadId = +selectedThread.threadId;
    } else {
      message.reciverId = selectedThread.userId;
    }

    await dispatch(forwardMessageToUsers({ messages: [message] }));
    dispatch(resetConversations(["forwardMessage", "shareMessageModal"]));
    dispatch(resetVault(["shareInMessageModal"]));
  };

  return (
    <CustomModal
      visible={visible}
      width="362px"
      onCancel={() => console.log("")}
      body={
        <div className="p-4" id="library-tour-share-in-message">
          <p className="pb-4 leading-5 text-black out-500-14">Send to</p>
          <CustomInputBox
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search in messages"
          />
          <p className="pb-4 mt-4 leading-5 text-black out-500-14 ">
            Recent Chats
          </p>
          <div className="max-h-[304px] min-h-[304px] overflow-y-scroll overflow-x-hidden">
            {allThreads
              .filter((thread) => {
                if (
                  thread.threadName &&
                  thread.threadName.toLowerCase().includes(search.toLowerCase())
                ) {
                  return thread;
                }
                if (
                  thread.firstname &&
                  thread.firstname.toLowerCase().includes(search.toLowerCase())
                ) {
                  return thread;
                }
                if (
                  thread.lastname &&
                  thread.lastname.toLowerCase().includes(search.toLowerCase())
                ) {
                  return thread;
                }
              })
              .map((thread, i) => (
                <UserCard
                  thread={thread}
                  key={i}
                  selectedThread={selectedThread}
                  setSelectedThread={setSelectedThread}
                  isSelected={
                    thread.threadId
                      ? thread.threadId === selectedThread.threadId
                      : thread.userId === selectedThread.userId
                  }
                />
              ))}
          </div>
          <div className="flex mt-[16px] items-center gap-x-3  justify-end">
            <CustomButton
              text="Cancel"
              className="text-gray-500 bg-transparent"
              height="30px"
              width="64px"
              onClick={onClose}
            />
            <CustomButton
              text="Share"
              height="30px"
              width="57px"
              onClick={handelForwardMessage}
            />
          </div>
        </div>
      }
    />
  );
};

export default ShareModal;
