import React from "react";
import Icon from "@ant-design/icons";

const CalendarAvatarSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <rect width="24" height="24" fill="#F0F5FA" rx="12"></rect>
    <path
      stroke="#165ABF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.333"
      d="M17.334 18v-1.333A2.667 2.667 0 0014.667 14H9.334a2.667 2.667 0 00-2.667 2.667V18m8-9.333a2.667 2.667 0 11-5.333 0 2.667 2.667 0 015.333 0z"
    ></path>
  </svg>
);

const CalendarAvatarIcon = (props) => (
  <Icon component={CalendarAvatarSvg} {...props} />
);

export { CalendarAvatarIcon };
