import { Tooltip } from "antd";
import React, { useEffect } from "react";
import { PriorityIcon } from "../../Core/svgV2/PriorityIcon";
import { client } from "../../Core/utils/axiosClient";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { useNavigate, useParams } from "react-router-dom";
import { getPriorityDetails } from "../../Core/utils/scheduleUtils";
import dayjs from "dayjs";
import { CalendarIcon } from "../../Core/svgV2/CalendarIcon";
import { getGoalsBySprint } from "../../Core/redux/api/scheduleAPI";
import TaskTypeIcon from "../../Core/svgV2/TaskTypeIcon";

const ActionCenterItem = ({
  taskOverdue,
  task,
}: {
  taskOverdue?: boolean;
  priority: "low" | "high" | "medium" | "urgent";
  task: any;
}) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { goals } = useAppSelector((state) => state.schedule);
  const selectedGoal = goals.find((goal) => goal.id == task.id);
  const goalTypeIcon = () => {
    switch (+selectedGoal?.goalType) {
      case 1:
        return (
          <Tooltip
            title={taskOverdue ? "Task: Overdue" : "Task"}
            placement="bottom"
          >
            {/* <Taskicon
              className={taskOverdue ? "text-error-600" : "text-primary-700"}
            /> */}
            <TaskTypeIcon
              type={+selectedGoal.goalType}
              width="20"
              height="20"
            />
          </Tooltip>
        );

      case 2:
        return (
          <Tooltip title={"Event"} placement="bottom">
            <CalendarIcon />
          </Tooltip>
        );

      case 3:
        return (
          <Tooltip title={"Bug"} placement="bottom">
            <img src="/images/v2/schedule/Bug.svg" />
          </Tooltip>
        );
    }
  };

  const priorityDetails = getPriorityDetails(selectedGoal?.priorityId);

  return (
    <div
      role="button"
      onClick={() => navigate(`/schedule/${projectId}/task/${task.id}`)}
      className={`h-[46px] border-b justify-between flex py-[13px] px-[24px] out-500-14 ${
        taskOverdue ? "bg-[#FDA29B] hover:bg-[#F97066]" : "hover:bg-gray-50"
      } group`}
    >
      <div
        className={`flex  items-center gap-[13px]  ${
          taskOverdue ? "text-white" : "text-gray-500"
        }`}
      >
        {goalTypeIcon()}

        <div className="max-w-[300px] 2xl:max-w-[350px] truncate ">
          {task.name}
        </div>
      </div>
      <div
        className={`flex gap-x-3 justify-center items-center ${
          taskOverdue ? "text-error-600" : "text-primary-700"
        }`}
      >
        <Tooltip
          title={`Priority: ${priorityDetails.priorityName}`}
          placement="bottom"
        >
          <PriorityIcon style={{ color: priorityDetails.color }} />
        </Tooltip>

        <div
          className={`group-hover:underline ${
            task.isWaddled == 0 ? "" : "text-transparent"
          }`}
        >
          Waddle It!
        </div>
      </div>
    </div>
  );
};
const ActionCenter = () => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { user } = useAppSelector((state) => state.userDetails);
  const { waddleItGoals } = useAppSelector((state) => state.dashboard);
  const { goals } = useAppSelector((state) => state.schedule);
  const actionCenterGoals = [];
  const navigate = useNavigate();

  const getWaddleItGoals = async () => {
    try {
      const response = await client.get(`/schedule/waddle-it-goals`, {
        params: { projectId, userId: user.id },
      });

      if (response?.data?.result) {
        dispatch(
          updateDashboard({
            key: "waddleItGoals",
            value: response?.data?.result || [],
          })
        );
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (projectId) {
      getWaddleItGoals();
    }
  }, [projectId]);
  useEffect(() => {
    if (projectId) {
      getWaddleItGoals();
    }
    dispatch(getGoalsBySprint({ projectId, module: "sprints" }));
  }, []);
  waddleItGoals.map((waddleItGoal) => actionCenterGoals.push(waddleItGoal));

  if (waddleItGoals.length < 10) {
    let tempGoals = goals
      .filter(
        (goal) =>
          (goal.goalType == 1 || goal.goalType == 3) &&
          (goal.assigneeId === user.id || goal.reporterId === user.id) &&
          goal.statusId != 3
      )
      .slice(0, (10 - waddleItGoals.length) * 2);
    tempGoals = tempGoals.filter(
      (tempGoal) =>
        !waddleItGoals.some(
          (waddleItGoal) => tempGoal.id === waddleItGoal.goalId
        )
    );

    tempGoals.map((tempGoal) => actionCenterGoals.push(tempGoal));
  }

  const getOverDue = (date) => {
    if (!date) return false;

    return dayjs(date).isSameOrBefore(dayjs(), "date");
  };
  return (
    <div className="border-b h-[374px] w-[60%]">
      <div className="h-[89px] border-b flex flex-col w-[full] justify-center p-[24px]">
        <div className="flex  justify-between">
          <div className="out-500-12">Action Center</div>
          <div
            className="out-500-14 text-primary-700"
            role="button"
            onClick={() => navigate(`/schedule/${projectId}/backlog`)}
          >
            View All
          </div>
        </div>
        <div className="text-gray-500 out-300-14">
          List of actions that you need your attention.
        </div>
      </div>
      <div className="overflow-y-scroll flex flex-col h-[284px]">
        {actionCenterGoals.length === 0 ? (
          <div className="flex flex-col justify-center items-center gap-y-4 pt-[69px]">
            <img src="/images/v2/dashboard/emptyActionCenter.svg" />
            <span className="out-300-14 text-gray-500">
              Nothing to do here.
            </span>
          </div>
        ) : (
          actionCenterGoals.map((goal) => (
            <ActionCenterItem
              taskOverdue={getOverDue(goal.dueDate)}
              priority={"high"}
              task={goal}
              key={goal.id}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ActionCenter;
