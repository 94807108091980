/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getMyBoardTasks = createAsyncThunk(
  "myBoard/getMyBoardTasks",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`/my-board/get-task`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getMyBoardTaskById = createAsyncThunk(
  "myBoard/getMyBoardTaskById",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`/my-board/get-task-by-goal-id`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getMyBoardSubtaskById = createAsyncThunk(
  "myBoard/getMyBoardSubaskById",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`/my-board/get-subtask-by-goal-id`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getAllMyBoardTasks = createAsyncThunk(
  "myBoard/getAllMyBoardTasks",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`/my-board/get-task`, {
        params: {},
      });

      return response.data.result;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const createMyBoardTask = createAsyncThunk(
  "myBoard/createMyBoardTask",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(`/my-board/create-task`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateMyBoardTask = createAsyncThunk(
  "myBoard/updateMyBoardTask",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.put(`/my-board/update-task`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const getTaskActivityLog = createAsyncThunk(
  "myBoard/getTaskActivityLog",
  async (
    {
      goalId,
    }: //phaseId,
    {
      goalId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(`/my-board/goal-activity-log`, {
        params: { goalId },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const deleteMyBoardTasks = createAsyncThunk(
  "myBoard/deleteMyBoardTasks",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.post(`/my-board/delete-task`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getCollaboratorsForCurrentUser = createAsyncThunk(
  "myBoard/getCollaboratorsForCurrentUser",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`/my-board/get-collaborators`, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const updateMyBoardParentTaskTimeLog = createAsyncThunk(
  "myBoard/updateMyBoardParentTaskTimeLog",
  async (payload: any) => {
      const response = await client.post(`/my-board/update-my-board-parent-task-time-log`, payload);
      return response.data;
  }
);
