import React from "react";
import Icon from "@ant-design/icons";

const HideIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_6246_26612)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.333 8h5.334m4 0A6.667 6.667 0 111.334 8a6.667 6.667 0 0113.333 0z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_6246_26612">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

const HideIcon = (props) => <Icon component={HideIconSvg} {...props} />;

export { HideIcon };
