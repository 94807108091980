/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { Dropdown, Tooltip } from "antd";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";
import { downloadFiles } from "../../Core/utils";

// import Album from "../../Core/Common/Album";
import dayjs from "dayjs";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import UserDetailsCard from "./UserDetailsCard";
import ParseMentions from "./ParseMentions";
import { EmojiIcon } from "../../Core/svgV2/EmojiIcon";

import { DownloadIcon } from "../../Core/svgV2/DownloadIcon";

import { EditIcon } from "../../Core/svgV2/EditIcon";

//todo add typescript validation
export const Thumbnail = ({
  type = "",
  url,
  className = "max-w-[200px] overflow-hidden h-auto",
  file,
}) => {
  const [isHover, setIsHover] = useState(false);
  let body = <></>;
  if (type.includes("video")) {
    body = (
      <div className={`${className} flex flex-col bg-white rounded-[12px]`}>
        {/* <div className="flex flex-col bg-white rounded-sm "> */}
        <div className=" h-[130px] rounded-[8px] my-[8px] mx-[8px] overflow-hidden">
          <video
            className=" h-[130px]"
            muted
            autoPlay={false}
            controls
            preload="metadata"
            //poster={thumbnail}
          >
            <source src={`${url}#t=0.1`}></source>
          </video>
        </div>
        <div
          className="flex flex-row mb-[8px] mx-[8px] justify-between "
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="flex flex-row  text-gray-700 gap-x-[8px] out-500-12 items-end h-[20px] w-[155px] ">
            <img src={"/images/v2/conversations/Video.svg"}></img>First draft of
            ideas.mp4
          </div>
          <div className="">
            {isHover ? <DownloadIcon className="out-500-12" /> : ""}
          </div>
        </div>
      </div>
    );
  } else if (type == "image") {
    body = (
      <div className={`${className} flex flex-col bg-white rounded-[12px]`}>
        <div className=" h-[130px] rounded-[8px] my-[8px] mx-[8px] overflow-hidden">
          <img src={"/images/v2/conversations/ImagePreview.svg"} />
        </div>
        <div
          className="flex flex-row mb-[8px] mx-[8px] justify-between "
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="flex flex-row  text-gray-700 gap-x-[8px] out-500-12 items-end h-[20px] w-[155px] ">
            <img src={"/images/v2/conversations/imageIcon.svg"}></img>First
            draft of ideas.jpg
          </div>
          <div className="">
            {isHover ? <DownloadIcon className="out-500-12" /> : ""}
          </div>
        </div>
      </div>
    );
  } else {
    body = (
      <div className={`${className} flex flex-col bg-white rounded-[12px]`}>
        <div className="rounded-[8px] my-[8px] mx-[8px] overflow-hidden">
          <img src={"/images/v2/conversations/ImagePreview.svg"} />
        </div>
        <div
          className="flex flex-row mb-[8px] mx-[8px] justify-between "
          onMouseEnter={() => {
            setIsHover(true);
            console.log("hovering");
          }}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="flex flex-row   text-gray-700 gap-x-[8px] out-500-12 items-end h-[20px] w-[155px] ">
            <img src={"/images/v2/conversations/pdf.svg"}></img>
            <p className="whitespace-nowrap truncate"> {file.name}</p>
          </div>
          <div className="">
            {isHover ? (
              <button
                onClick={() => {
                  downloadFiles([url], file.fileSize);
                }}
              >
                <DownloadIcon className="out-500-12" />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
  return body;
};

const Album = ({ message, files }) => {
  // TODO: like image array make this for video and other file
  const images = files.filter((file) => file.type?.includes("png")) || [];
  let imageArray = <></>;
  if (images.length > 0) {
    imageArray = (
      <div className="flex flex-col  gap-y-3 mt-1">
        {images.map((img, i) => (
          <div
            key={i}
            className="max-h-[100px] max-w-[147px] min-h-[100px] min-w-[147px] rounded-lg border flex justify-center items-center border-gray-200"
          >
            <img
              className="max-h-[100px] max-w-[147px] object-cover "
              src={img.url}
            />
          </div>
        ))}
      </div>
    );
  }
  const otherFiles = files.filter((file) => !file.type?.includes("png")) || [];
  let filesArray = <></>;
  if (otherFiles.length > 0) {
    filesArray = (
      <div className="flex flex-col gap-y-3 mt-1">
        {otherFiles.slice(0, 5).map((img, i) => (
          <div key={i}>
            <Thumbnail type={img.type} url={img.url} file={img} />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className="pb-1">
      {imageArray}
      <div className="">{filesArray}</div>
    </div>
  );
};

const QuickChatMessage = ({
  message,
  reactToMessage,
  allowHover = true,
  showSender = true,
}: {
  message: any;
  comments?: boolean;
  allowHover?: boolean;
  reactToMessage?: (
    emoji: string,
    messageId: string,
    isAlreadyReacted: boolean
  ) => void;
  handleMarkAsUnread?: (message: object) => void;
  deleteMessage?: (id: string) => void;
  showSender?: boolean;
}) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();

  // const regex =
  //   /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  // let allUrl = [];

  // allUrl = message?.message?.match(regex);

  const isAlreadyReacted = (emoji) =>
    message.reactions &&
    message.reactions[emoji] &&
    message.reactions[emoji].includes(user.id);

  const reactionOverLay = (
    <div className="flex bg-white shadow-bottom items-center rounded-full h-[40px]">
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("😊", message._id, isAlreadyReacted("😊"))
        }
      >
        <span className="text-xl w-5 h-5">😊</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("😆", message._id, isAlreadyReacted("😆"))
        }
      >
        <span className="text-xl">😆</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("❤️", message._id, isAlreadyReacted("❤️"))
        }
      >
        <span className="text-xl">❤️</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("👍", message._id, isAlreadyReacted("👍"))
        }
      >
        <span className="text-xl">👍</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("👎", message._id, isAlreadyReacted("👎"))
        }
      >
        <span className="text-xl">👎</span>
      </button>
      <button
        className="w-5 h-5 mx-3"
        onClick={() =>
          reactToMessage("👌", message._id, isAlreadyReacted("👌"))
        }
      >
        <span className="text-xl">👌</span>
      </button>
    </div>
  );

  const getHoverMenu = () => {
    return (
      <div className="bg-white absolute top-[-18px] right-4 h-9 border border-gray-100 flex justify-around items-center  rounded-[4px] w-[64px] ">
        <Dropdown
          trigger={["click"]}
          //open={true}
          overlay={reactionOverLay}
        >
          <Tooltip title="Add reaction">
            <button className="">
              <EmojiIcon />
            </button>
          </Tooltip>
        </Dropdown>
        <Tooltip title="Edit Comment">
          <button
            onClick={() => {
              dispatch(
                updateConversations({
                  key: "editMessage",
                  value: { edit: true, messageObject: message },
                })
              );
            }}
          >
            {" "}
            <EditIcon className="text-black" />
          </button>
        </Tooltip>
      </div>
    );
  };

  const getAttachements = (attachments) => {
    if (attachments.length === 0) return;

    const newAttacments = attachments.map((attachment) => {
      return { ...attachment, messageId: message._id };
    });

    return <Album files={newAttacments} message={message} />;
  };

  const senderProfilePicture = tenantMembers.find(
    (t) => t.id == message.senderId
  )?.profilePicture;

  return (
    <div
      className={` px-4 py-1 scroll-mt-[20vh]  relative ${
        allowHover ? "group hover:bg-gray-100" : ""
      }`}
      data-message-id={message._id}
    >
      <div className="flex ">
        <div className="hidden group-hover:block">{getHoverMenu()}</div>
        {showSender ? (
          <CustomAvatar
            title={message.senderName}
            size={28}
            src={senderProfilePicture}
            className=""
          />
        ) : (
          <div className="h-full mt-1.5  items-start flex w-[28px] ">
            <Tooltip
              title={
                message.createdAt
                  ? dayjs(message.createdAt)
                      .utc()
                      .local()
                      .format("MMMM DD YYYY, hh:mm a")
                  : "-"
              }
            >
              <div className="out-300-10 text-gray-500 hidden group-hover:block">
                {message.createdAt
                  ? dayjs(message.createdAt).utc().local().format("HH:MM")
                  : "-"}
              </div>
            </Tooltip>
          </div>
        )}
        <div className="ml-2 w-full">
          {showSender && (
            <div className="flex items-center gap-x-1.5">
              <UserDetailsCard
                userName={message.senderName}
                isDisable={false}
                userId={message.senderId}
              />
              {message.request?.type && (
                <img
                  src="/images/v2/conversations/AttachmentHeader.svg "
                  className="w-[26px] h-5"
                ></img>
              )}
              <Tooltip
                title={
                  message.createdAt
                    ? dayjs(message.createdAt)
                        .utc()
                        .local()
                        .format("MMMM DD YYYY, hh:mm a")
                    : "-"
                }
              >
                <div className="out-300-10 text-gray-500 ">
                  {message.createdAt
                    ? dayjs(message.createdAt).utc().local().format("hh:mm a")
                    : "-"}
                </div>
              </Tooltip>
            </div>
          )}

          <div className="mt-1">
            {message.replyMessage && message.replyMessage._id && (
              <div className="flex bg-gray-50 group-hover:bg-gray-25 mb-1 rounded-[4px] opacity-80 transition-all duration-300 p-1 items-start w-full">
                <img
                  src="/images/v2/conversations/message-reply-2.svg"
                  className="w-3 h-3 mt-1"
                />
                <div>
                  <div className="flex items-center">
                    {" "}
                    <span className="ml-1 out-500-12 text-gray-400  capitalize">
                      {message.replyMessage.senderName}
                    </span>
                  </div>
                  <div className="ml-1 mt-1 ">
                    <ParseMentions
                      value={message.replyMessage.message}
                      className={"out-300-12 text-gray-500 max-w-[200px]"}
                    />
                  </div>
                </div>
              </div>
            )}

            <ParseMentions
              value={message.message}
              textClassName=" max-w-[250px]  out-300-14"
            />
          </div>

          {message.attachments && getAttachements(message.attachments)}
          <div className={`flex items-center ${"justify-start"} gap-x-2`}>
            {message.reactions &&
              Object.keys(message.reactions).map(
                (emoji, index) =>
                  message.reactions[emoji].length > 0 && (
                    <button
                      className="flex items-center gap-x-2 pt-2 "
                      key={index}
                      onClick={() =>
                        reactToMessage(
                          emoji,
                          message._id,
                          message.reactions[emoji].includes(user.id)
                        )
                      }
                    >
                      <div
                        className="flex items-center py-0.5 px-2  h-6 bg-white rounded-2xl "
                        style={{
                          padding: "2px, 8px, 2px, 8px",
                        }}
                      >
                        <span className="text-lg">{emoji}</span>
                        {message.reactions[emoji].length > 1 && (
                          <span className="out-500-12 pl-1 text-gray-700">
                            {message.reactions[emoji].length}
                          </span>
                        )}
                      </div>
                    </button>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Day = ({ date }) => {
  const formateDate = (date) => {
    if (date === null || typeof date === "undefined") return "-";
    if (dayjs(date).isToday()) return "Today";
    if (dayjs(date).isTomorrow()) return "Tomorrow";
    return dayjs(date).format("MMMM DD YYYY");
  };
  return (
    <div className="px-[10px] py-[5px] mx-auto my-1 border w-max shadow-xs rounded-[4px] border-gray-200">
      <p className="out-300-14 text-gray-700">{formateDate(date)}</p>
    </div>
  );
};

export const Unread = ({ type, count }) => {
  return (
    <div className="out-500-14 bg-error-50 my-1 text-error-500 text-center w-full ">
      {count} New messages
    </div>
  );
};

export function TypingIndicator({
  typingStatus,
  currentThread,
  // reactToMessage,
  // deleteMessage,
}) {
  // const [isTyping, setIsTyping] = useState(false);
  const isPrivateThread = currentThread.type === "private";
  const isMatchingUser = +typingStatus.userId === +currentThread.userId;
  const isMatchingThread = +typingStatus.threadId === +currentThread.threadId;

  // useEffect(() => {
  //   console.log(isTyping, typingStatus.isTyping, "typingStatus");
  //   if (typingStatus.isTyping) {
  //     setIsTyping(typingStatus.isTyping);
  //   }
  //   if (!typingStatus.isTyping) {
  //     setTimeout(() => {
  //       if (!typingStatus.isTyping) {
  //         setIsTyping(typingStatus.isTyping);
  //       }
  //     }, 5000);
  //   }
  // }, [typingStatus]);

  if (
    !typingStatus.isTyping ||
    (isPrivateThread ? !isMatchingUser : !isMatchingThread)
  ) {
    return <></>;
  }

  if (currentThread.type !== typingStatus.threadType) {
    return <></>;
  }

  return (
    <div className="pl-24 mt-1.5 mb-0 flex items-center">
      <div className="dot-collision" />

      <span className="ml-7 mon-500-10 italic text-gray-500">
        {currentThread.type === "group"
          ? typingStatus.userName + " " + "is"
          : ""}{" "}
        Typing.....
      </span>
    </div>
  );
}

export default QuickChatMessage;
