import React from "react";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  resetToInitialOnboarding,
} from "../../../Core/redux/slices/onboarding.slice";
import { useNavigate } from "react-router-dom";
import { productOnboarding } from "../../../Core/redux/api/onboardingAPI";
import { changeWorkspace } from "../../../Core/redux/api/dashboardAPI";
import { CrossIconLarge } from "../../../Core/svgV2/CrossIcon";
import { client } from "../../../Core/utils/axiosClient";
import { updateSettings } from "../../../Core/redux/slices/settings.slice";
import { getTenantDetails } from "../../../Core/redux/api/tenantAPI";

const Content = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    selectedSector,
    selectedSDGs,
    companyName,
    companyLocation,
    companySize,
    companyMembers,
  } = useAppSelector((state) => state.onboarding);
  const { tenant_id } = useAppSelector((state) => state.userDetails).user;

  const { user } = useAppSelector((state) => state.userDetails);
  const { newGuestInteractionSettings } = useAppSelector(
    (state) => state.settings
  );

  const onSubmit = async () => {
    const payload = {
      sectorsList: selectedSector,
      sdgs: selectedSDGs,
      tenantName: companyName,
      tenantLocation: companyLocation,
      tenantSize: companySize,
      users: companyMembers.filter((memeber) => memeber.roleId),
      createdById: user.id,
    };

    await dispatch(productOnboarding(payload)).then(async (res) => {
      const work = await dispatch(
        changeWorkspace({ tenantId: res.payload.tenantId })
      );
      dispatch(getTenantDetails({}));

      const user = {
        ...JSON.parse(localStorage.getItem("user")),
        token: work.payload.token,
      };
      res.payload.guestUsers?.map(async (user) => {
        console.log("comes Headers", user);
        const allowedUsersIdArray = newGuestInteractionSettings?.length
          ? newGuestInteractionSettings?.find(
              (userArray) => userArray.email === user.email
            )?.selectedUsers
          : [];
        console.log(
          user,
          allowedUsersIdArray,
          "check for role id and allowedUsersIdArray"
        );
        if (+user.roleId === 4 || +user.roleid === 4 || +user.role_id === 4) {
          await client
            .post("settings/add-guest-interaction-allowed-members", {
              userId: user.user_id || user.id,
              tenantId: res.payload.tenantId,
              allowedUsersIdArray: allowedUsersIdArray?.length
                ? allowedUsersIdArray
                : [],
            })
            .then(() =>
              dispatch(
                updateSettings({
                  key: "newGuestInteractionSettings",
                  value: [],
                })
              )
            );
        }
      });
    

      localStorage.setItem("user", JSON.stringify(user));
      dispatch(resetToInitialOnboarding({}));
      navigate("/dashboard");
    });
  };

  return (
    <div className="p-6 w-full h-screen bg-white">
      {tenant_id ? (
        <div
          className="ml-10 mt-10 flex items-center justify-center out-500-14 text-gray-500 gap-x-2  absolute z-[100]"
          role="button"
          onClick={() => {
            dispatch(resetToInitialOnboarding({}));
            navigate(-1);
          }}
        >
          <CrossIconLarge className="text-gray-500" /> Cancel
        </div>
      ) : (
        ""
      )}
      <div className="pl-14 w-full h-full bg-white flex justify-start items-center">
        <div>
          <div className="flex items-center">
            <h1 className="out-500-20 text-gray-900">You’re all set!</h1>
            <img
              src="/images/icons/clinking-glasses.svg"
              className="w-7 h-7 ml-1"
            />
          </div>
          <div className="flex justify-start items-center text-show-animation gap-x-2 mt-2 out-400-16 text-gray-500">
            <img
              src="/images/icons/frosty-icon.svg"
              className="min-h-[26px] min-w-[26px]"
              alt="frosty"
            />
            Congratulations, ready to change the world?
            <img src="/images/icons/earth-emoji.svg" />
          </div>

          <div className="flex items-center gap-x-6 mt-6">
            <CustomButton
              className="bg-primary-600 text-white"
              onClick={() => {
                onSubmit();
              }}
              text="Launch Waddle"
              width="144px"
              height="30px"
            />
            <CustomButton
              className="bg-white border-gray-200 border-[1px]"
              onClick={() => dispatch(changeStep(5))}
              text="Back"
              width="51px"
              height="30px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => {
  return (
    <div className="w-full h-screen overflow-hidden relative flex justify-center items-center bg-[#D1FADF]">
      <div className="w-[330px] h-[286px]">
        <img
          className="w-full h-full"
          src="/images/icons/s6-launch-waddle-text.svg"
          alt=""
        />
      </div>
    </div>
  );
};

const MapSelection = () => {
  return (
    <ProductOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default MapSelection;
