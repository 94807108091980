import React from "react";
import { getPriorityDetails } from "../utils/scheduleUtils";

const PriorityFlag = ({
  className = "",
  height = "24",
  width = "24",
  style = {},
  priorityId = 0,
}) => {
  const newStyle: any = { ...style };
  if (priorityId) {
    const { color, priorityName } = getPriorityDetails(priorityId);
    newStyle.color = color;
  }
  return (
    <span role="img" className={`anticon ${className}`} style={newStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.2 13.2h10.913l-2.4-3.6 2.4-4.2H7.2v4.8"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M7.2 13.2v7.2"
        ></path>
      </svg>
    </span>
  );
};

export default PriorityFlag;
