import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { resetVault } from "../../../Core/redux/slices/vault.slice";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { Dropdown, Tooltip } from "antd";

import {
  getAllFiles,
  getAllFolders,
  getAllLinks,
  shareAccess,
} from "../../../Core/redux/api/vaultAPI";
import { useParams } from "react-router-dom";

import { CheckIcon } from "../../../Core/svgV2/Check";
import copyTextToClipboard from "../../../Core/utils/clipboard";
import getUsersName from "../../../Core/utils/getUserName";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

const ShareAccessModal = () => {
  const { shareAccessModal } = useAppSelector((state) => state.vault);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const dispatch = useAppDispatch();
  const [dropdownForShare, setDropdownForShare] = useState(false);
  const [accessValue, setAccessValue] = useState(1);
  const [userList, setUserList] = useState([...(shareAccessModal.users ?? [])]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    setUserList([...shareAccessModal.users]);
  }, [shareAccessModal]);

  const { projectId, "*": splat } = useParams();

  const { tenantDetails } = useAppSelector((state) => state.tenant);

  const { userList: projectUsers } = useAppSelector((state) => state.overview);

  const onClose = () => {
    setSelectedUsers([]);
    setUserList([]);
    dispatch(resetVault(["shareAccessModal"]));
  };

  const options = () => {
    return (shareAccessModal.isGlobal ? tenantMembers : projectUsers ?? [])
      .filter(
        (member) =>
          !(shareAccessModal.users ?? []).find(
            (user) => user.userId == member.userId
          )
      )
      .map((user) => {
        return {
          id: user.userId,
          value: user.userId,
          label: `${user.firstname} ${user.lastname}`,
          render: () => (
            <div className="flex items-center ">
              <CustomAvatar
                src={user.profilePicture}
                size={24}
                title={getUsersName(user)}
                whiteText
                color={user.profilePictureColorCode}
              />

              <p className="pl-2 text-gray-900 capitalize out-300-12 ">
                {getUsersName(user)}
              </p>
            </div>
          ),
        };
      });
  };
  console.log(options(), "users to display");
  const groupItems = [
    {
      key: "1",
      label: (
        <div
          className="leading-5 text-gray-700 out-300-14"
          onClick={() => {
            setAccessValue(1);
          }}
        >
          Can Edit
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="leading-5 text-gray-700 out-300-14"
          onClick={() => {
            setAccessValue(2);
          }}
        >
          Can View
        </div>
      ),
    },
  ];

  const handleSubmit = async () => {
    const users = [
      ...userList,
      ...selectedUsers.map((u) => {
        return {
          userId: u,
          isOwner: 0,
          canEdit: +(accessValue === 1),
          canView: 1,
        };
      }),
    ];
    const payload = {
      docId: shareAccessModal.docId,
      users: users,
      projectId: +projectId,
      docType: shareAccessModal.docType,
      name:
        shareAccessModal.docDetails.folderName ||
        shareAccessModal.docDetails.fileName ||
        shareAccessModal.docDetails.linkName ||
        shareAccessModal.docDetails.name,
      notificationId: shareAccessModal.notificationId,
    };
    //when shareAccessModal is called while using requestacessmodal, projectId is not available in the url, so getting selected projectID

    await dispatch(shareAccess(payload as any)).then(() => {
      onClose();
      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: "Shared access",
              subtitle: `Added ${users.length - 1} new collaborators to `,
              description: location.pathname.includes("my-library")
                ? "My Library"
                : payload.name,
            },
          },
        })
      );
    });
    dispatch(resetVault(["shareAccessDoc"]));

    let queryObject: {
      projectId?: string;
      folderId?: string;
      tenantId?: string;
    };
    if (projectId == "undefined" || projectId == null) {
      queryObject = {
        projectId: null,
        tenantId: tenantDetails?.tenantId,
      };
    } else {
      queryObject = {
        projectId,
      };
    }

    console.log(queryObject, "queryobj");
    if (splat && splat.length > 0) {
      const folderId = splat.split("-");
      queryObject.folderId = folderId[folderId.length - 1];
    }

    if (shareAccessModal.docType === "folder") {
      await dispatch(getAllFolders(queryObject));
    } else if (shareAccessModal.docType === "file") {
      await dispatch(getAllFiles(queryObject));
    } else {
      await dispatch(getAllLinks(queryObject));
    }

    // setSelectedUsers([]);
  };

  const handleCopy = () => {
    if (shareAccessModal.docType === "folder") {
      let newPath = "";
      if (shareAccessModal.isGlobal) {
        newPath =
          window.location.href +
          `/global/${shareAccessModal.docDetails.folderName}-${shareAccessModal.docId}`;
      } else {
        newPath =
          window.location.href +
          `/${shareAccessModal.docDetails.folderName}-${shareAccessModal.docId}`;
      }

      return copyTextToClipboard(newPath);
    } else {
      let newPath = window.location.href;
      //todo test if any other query params are passed
      newPath = newPath?.split("?").slice(0, 1)[0];

      if (shareAccessModal.docType === "link") {
        newPath = `${newPath}?sharedLinkId=${shareAccessModal.docId}`;
      } else {
        newPath = `${newPath}?sharedFileId=${shareAccessModal.docId}`;
      }

      return copyTextToClipboard(newPath);
    }
  };

  return (
    <CustomModal
      visible={shareAccessModal?.visible}
      width="362px"
      onCancel={() => dispatch(resetVault(["shareAccessModal"]))}
      body={
        <div className="p-4">
          <p className="leading-5 text-black out-500-14">
            Share ‘
            {shareAccessModal.docDetails.folderName ||
              shareAccessModal.docDetails.name ||
              shareAccessModal.docDetails.linkName}
            ’
          </p>
          {dropdownForShare ? (
            <div className="mt-4 flex gap-x-[26px]">
              <div className="w-[62%]">
                <CustomSelect
                  innerClassName=""
                  wrapperClassName="h-[30px]"
                  mode="multiple"
                  dropdownStyle={{ minWidth: "320px" }}
                  showSearch
                  placeholder="Add people to share"
                  options={options()}
                  onChange={(e) => {
                    setSelectedUsers([...e]);
                  }}
                  customDropDownRender={(menu) => {
                    return <div className=" h-[184px]">{menu}</div>;
                  }}
                />
              </div>
              <Dropdown
                menu={{ items: groupItems }}
                trigger={["click"]}
                placement="bottomRight"
                dropdownRender={(origin) => {
                  return <div className="w-[193px] h-[184px]">{origin}</div>;
                }}
              >
                <div className="w-[101px] h-[32px] rounded bg-primary-50 flex items-center py-[5px] px-[10px] cursor-pointer">
                  <p className="leading-5 capitalize out-500-14 text-primary-700 whitespace-nowrap">
                    {accessValue === 1 ? "Can Edit" : "Can View"}
                  </p>
                  <ChevronIcon className="ml-2 text-primary-500" />
                </div>
              </Dropdown>
            </div>
          ) : (
            <div className="mt-4">
              <div
                className="border border-primary-500 shadow-input-focus rounded px-[10px] py-[5px] out-300-14 
                      leading-5 text-gray-900 cursor-pointer"
                onClick={() => setDropdownForShare(true)}
              >
                Add people to share
              </div>
            </div>
          )}
          <div className="mt-4">
            <p className="leading-5 text-black out-500-14">
              People with access
            </p>
            <div className="ml-[14px]">
              {userList.map((user) => (
                <div
                  className="flex justify-between first:pt-[26px] pt-5"
                  key={user.id}
                >
                  <div className="flex items-center ">
                    <CustomAvatar
                      src={user.profilePicture}
                      size={24}
                      title={`${user.firstname} ${user.lastname}`}
                      whiteText
                      color={user.profilePictureColorCode}
                    />

                    <p className="pl-2 text-gray-700 out-300-14 ">
                      {user.firstname} {user.lastname}
                    </p>
                  </div>
                  {user.isOwner ? (
                    <p className="leading-5 text-gray-500 out-300-14">Owner</p>
                  ) : (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <div
                                className="flex items-center justify-between leading-5 text-gray-700 out-300-14 "
                                onClick={() =>
                                  setUserList((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 1,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <div>Can edit</div>
                                {user.canEdit ? (
                                  <CheckIcon className="text-primary-700" />
                                ) : (
                                  ""
                                )}
                              </div>
                            ),
                          },
                          {
                            key: "2",
                            label: (
                              <div
                                className="flex items-center justify-between leading-5 text-gray-700 out-300-14"
                                onClick={() =>
                                  setUserList((oldList) => [
                                    ...oldList.map((old) => {
                                      if (old.userId === user.userId) {
                                        return {
                                          ...old,
                                          canEdit: 0,
                                          canView: 1,
                                        };
                                      }
                                      return old;
                                    }),
                                  ])
                                }
                              >
                                <div>Can view</div>
                                {user.canEdit ? (
                                  ""
                                ) : user.canView ? (
                                  <CheckIcon className="text-primary-700" />
                                ) : (
                                  ""
                                )}
                              </div>
                            ),
                          },
                          { key: "3", type: "divider" },
                          {
                            key: "4",
                            label: (
                              <div
                                className="leading-5 out-300-14 text-error-600"
                                onClick={() =>
                                  setUserList((oldList) => [
                                    ...oldList.filter(
                                      (old) => old.userId !== user.userId
                                    ),
                                  ])
                                }
                              >
                                Remove access
                              </div>
                            ),
                          },
                        ],
                      }}
                      trigger={["click"]}
                      dropdownRender={(origin) => {
                        return <div className="w-[193px]">{origin}</div>;
                      }}
                    >
                      <div className="flex items-center cursor-pointer gap-x-3">
                        <p className="leading-5 text-gray-500 out-300-14">
                          {user.canEdit ? "Can edit" : "Can view"}
                        </p>
                        <ChevronIcon className="text-gray-500" />
                      </div>
                    </Dropdown>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-between items-center mt-[26px] gap-x-3">
            <Tooltip trigger={"click"} title="Copied!">
              <div
                className="flex items-center cursor-pointer gap-x-2"
                role="button"
                onClick={() => handleCopy()}
              >
                <img src="/images\v2\library\copy-link-icon.svg" />
                <p className="leading-5 text-gray-700 out-300-14">Copy Link</p>
              </div>
            </Tooltip>
            <div>
              <CustomButton
                text="Cancel"
                className="text-gray-500 bg-transparent"
                height="30px"
                width="64px"
                onClick={() => onClose()}
              />
              <CustomButton
                text="Share"
                height="30px"
                width="57px"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ShareAccessModal;
