import React, { useEffect, useState } from "react";
import Attachments from "./Attachments";
import AddSubtask from "./AddSubtask";
import { AttachmentIcon } from "../../../Core/svgV2/AttachmentIcon";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateGoal } from "../../../Core/redux/api/scheduleAPI";
import usePermission from "../../../Core/hooks/usePermission";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";

const AttachmentsAndSubtask = ({
  task,
  setAttachments,
  setSubtasks,
  setEditedSubtaskDetails,
  setEditedAttachmentDetails,
}) => {
  const { taskId, subtaskId } = useParams();
  const dispatch = useAppDispatch();
  const [showUploadAttachments, setShowUploadAttachments] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const { user } = useAppSelector((state) => state.userDetails);
  const [userInCollaborators, setUserInCollaborators] = useState(false);
  const { hasPermission: SUB_TASK_AND_BUG_ADD } = usePermission("11");
  const { hasPermission: TASK_EVENT_SCOPE_EDIT } = usePermission("11");

  useEffect(() => {
    if (task.collaborators) {
      setUserInCollaborators(
        task.collaborators.some((collaborator) => collaborator.id === user.id)
      );
    }
    if (task.assigneeId === user.id) {
      setUserInCollaborators(true);
    }
  }, [task, user.id]);

  console.log(subtaskId, "subtask id");

  return (
    <>
      <div className="mt-4 flex items-center gap-x-5">
        {task?.attachments?.length === 0 && (
          <button
            onClick={() => setShowUploadAttachments(!showUploadAttachments)}
            className="flex items-center h-[30px] cursor-pointer "
          >
            <AttachmentIcon className="text-gray-500" />
            <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
              Attach
            </p>
          </button>
        )}
        {task?.subtasks?.length === 0 &&
          !showUploadAttachments &&
          !showAddTask &&
          (userInCollaborators || SUB_TASK_AND_BUG_ADD) &&
          !subtaskId &&
          +task.goalType !== 3 && (
            <button
              className="flex items-center h-[30px] cursor-pointer "
              onClick={() => setShowAddTask(true)}
            >
              <PlusIcon className="text-gray-500" />
              <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
                Add Sub-task
              </p>
            </button>
          )}
      </div>

      <Attachments
        showUploadAttachments={showUploadAttachments}
        setShowUploadAttachments={setShowUploadAttachments}
        attachments={task.attachments || []}
        handleUpload={async (e) => {
          setAttachments((task) => {
            return { ...task, attachments: e };
          });
          setEditedAttachmentDetails({ attachments: JSON.stringify(e) });

          let goalId = taskId;
          if (subtaskId) {
            goalId = subtaskId;
          }

          if (goalId !== "new") {
            await dispatch(
              updateGoal({
                attachments: JSON.stringify(e),
                goalId: goalId,
              })
            );
            await dispatch(
              updateSchedule({ key: "isGoalEdited", value: true })
            );
          }
          setTimeout(() => setShowUploadAttachments(false), 1000);
        }}
      />

      {task?.subtasks?.length === 0 &&
        showUploadAttachments &&
        (SUB_TASK_AND_BUG_ADD || !userInCollaborators) &&
        !subtaskId &&
        +task.goalType !== 3 && (
          <button
            onClick={() => setShowAddTask(true)}
            className="flex items-center h-[30px] mt-4 cursor-pointer "
          >
            <PlusIcon className="text-gray-500" />
            <p className="pl-2 out-500-14 leading-5 text-gray-500 capitalize">
              Add Sub-task
            </p>
          </button>
        )}

      {(task?.subtasks?.length > 0 || showAddTask) && !subtaskId && (
        <AddSubtask
          subtasks={task.subtasks || []}
          setTask={setSubtasks}
          task={task}
          setShowAddTask={setShowAddTask}
          setEditedTaskDetails={setEditedSubtaskDetails}
          userInCollaborators={userInCollaborators}
        />
      )}
    </>
  );
};

export default AttachmentsAndSubtask;
