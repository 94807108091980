import React from "react";
import Icon from "@ant-design/icons";

const GridViewSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.5 8h15M8 .5v15M4.5.5h7c1.4 0 2.1 0 2.635.272a2.5 2.5 0 011.092 1.093C15.5 2.4 15.5 3.1 15.5 4.5v7c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 01-1.092 1.092c-.535.273-1.235.273-2.635.273h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 01-1.093-1.092C.5 13.6.5 12.9.5 11.5v-7c0-1.4 0-2.1.272-2.635A2.5 2.5 0 011.865.772C2.4.5 3.1.5 4.5.5z"
    ></path>
  </svg>
);

const GridViewIcon = (props) => {
  return <Icon component={GridViewSvg} {...props} />;
};

export { GridViewIcon };
