import React from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";

const ReportsLayout = ({ children }) => {
  return (
    <CustomLayout
      page="reportsV3"
      title={<div className="out-500-14 text-black">Reports</div>}
    >
      {/* add my reports tour component */}
      {children}
    </CustomLayout>
  );
};

export default ReportsLayout;
