import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const CancelUploadModal = () => {
  return (
    <CustomModal
      visible={false}
      width="360px"
      onCancel={() => console.log("closed")}
      body={
        <div className="">
          <div className="p-4">
            <p className="text-black out-500-14 leading-5">
              Cancel All Uploads?
            </p>
            <p className="mt-4 out-300-14 text-black leading-5">
              Your upload is in progress. Would you like to cancel all uploads?
            </p>
          </div>
          <div className="p-4 flex items-center border-t border-gray-200 gap-x-3 justify-end">
            <CustomButton
              text="Continue Upload"
              className="bg-primary-600 text-white"
              height="30px"
              width="124px"
            />
            <CustomButton
              text="Cancel All Uploads"
              height="30px"
              width="116px"
              className="bg-transparent text-gray-500 whitespace-nowrap"
            />
          </div>
        </div>
      }
    />
  );
};

export default CancelUploadModal;
