import React from "react";
import ReactDOM from "react-dom/client";
import "./App.less";
import "./index.css";
import "./fontStyles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./Core/redux/store";
import { PersistGate } from "redux-persist/integration/react"; // Import updated path
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { client, setupAxiosInterceptors } from "./Core/utils/axiosClient";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ErrorBoundary from "./Core/Layout/ErrorBoundary";
import handleNetworkRequest from "./Core/utils/networkMonitor";
import dayjs from "dayjs";
import { blob } from "stream/consumers";
import {
  calculateTimeDifference,
  storeDataConsumed,
} from "./Core/utils/dcfUtils";
import { fetchUserLocation } from "./Core/utils/fetchUserLocation";

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 50 }} spin />);
setupAxiosInterceptors(store);

let startTime = dayjs();
let totalTimeActive = 0;

// Function to handle visibility change
export const logDcfActivity = async (
  dataConsumedInBytes,
  userLocation,
  totalTimeActive
) => {
  await client
    .post("/my-footprint/create-dcf-activity-log", {
      usageTimeInSeconds: totalTimeActive,
      dataConsumedInBytes: +dataConsumedInBytes,
      userLocation: userLocation,
    })
    .then(() => resetVariables());
};
function handleVisibilityChange() {
  if (document.hidden) {
    // Tab is inactive
    //todo pass data consumed as a parameter
    calculateTimeDifference(totalTimeActive, startTime);
  } else {
    // Tab is active

    startTime = dayjs();
  }
}

// Event listener for visibility change
document.addEventListener("visibilitychange", handleVisibilityChange);

// Function to reset variables
function resetVariables() {
  startTime = dayjs();
  totalTimeActive = 0;
  localStorage.removeItem("dataConsumedInBytes");
}

//store data and time consumed every five minutes
setInterval(() => {
  calculateTimeDifference(totalTimeActive, startTime);
}, 300000); // Reset after 5 minutes

//performance monitor
let previousResourceCount =
  window.performance.getEntriesByType("resource").length;

/**
 * Monitors network requests and stores the data consumed by each resource.
 * Retrieves the list of performance entries of type 'resource' and compares it with the previous count.
 * For each new resource entry, extracts the transfer size and stores it using the 'storeDataConsumed' function.
 */
function monitorNetworkRequests() {
  const resources = window.performance.getEntriesByType("resource");
  if (resources.length > previousResourceCount) {
    for (let i = previousResourceCount; i < resources.length; i++) {
      const resource = resources[i];
      // console.log("comes here",res);
      console.log("Resource:", resource);
      if (resource.entryType === "resource") {
        const resourceTiming = resource as PerformanceResourceTiming; // Cast to PerformanceResourceTiming
        // console.log("Resource:", resourceTiming.transferSize);
        console.log("data debug on download", resource);

        // Store the required info
        storeDataConsumed(resourceTiming.transferSize);
      }
    }
    previousResourceCount = resources.length;
  }
}

// tracking all requests , Intercept XMLHttpRequest
/**
 * Represents a custom XMLHttpRequest class that tracks the size of request and response data.
 * Extends the native XMLHttpRequest class.
 * Get request and response size of all XHR requests
 */
class TrackedXHR extends XMLHttpRequest {
  private requestSize: number;
  private responseSize: number;

  constructor() {
    super();
    this.addEventListener("loadstart", this.trackRequestSize.bind(this));
    this.addEventListener("load", this.trackResponseSize.bind(this));
  }

  private trackRequestSize() {
    // Calculate the size of request headers
    const requestHeadersSize = JSON.stringify(
      this.getAllResponseHeaders()
    ).length;
    // Calculate the size of request body (if any)
    const requestBodySize = this.send.toString().length;
    this.requestSize = requestHeadersSize + requestBodySize;
  }

  private trackResponseSize() {
    // alert("comes here");
    monitorNetworkRequests();
    // Calculate the size of response body
    if (typeof this.response !== "string") {
      //todo use this.response.size as the size of downloaded files
      // console.log("data debug on download", this.response.size);
      // console.log(this.response, "blob dettected");
      // console.log(
      //   this.response,
      //   this.response.size, //store this
      //   typeof this.response,
      //   // this.response.data,
      //   "blob dettected"
      // );
      storeDataConsumed(this.response.size);
    } else {
      this.responseSize = this.responseText.length;
      const xmlChar = this.responseXML;
      const responseObj = this.response;
      // Log the sizes
      console.log(
        "both params Request size 78: response text",

        this.requestSize //store  this size
      );
      storeDataConsumed(+this.responseSize + +this.requestSize);

      console.log("both params Response size:", this.responseSize); //store this
    }
  }
}

// Replace XMLHttpRequest with TrackedXHR
window.XMLHttpRequest = TrackedXHR;

// Poll for new network requests every second
setInterval(() => monitorNetworkRequests(), 1000);

// Register the service worker here
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    // navigator.serviceWorker
    //   .register("/firebase-messaging-sw.js")
    //   .then((registration) => {
    //     console.log(
    //       "[SW]: Service Worker registered with scope:",
    //       registration.scope
    //     );
    //   })
    //   .catch((error) => {
    //     console.error("[SW]: Service Worker registration failed:", error);
    //   });
  });
}
//get current locaion of the user
fetchUserLocation();
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

reportWebVitals();
