import React from "react";
import Icon from "@ant-design/icons";

const SettingSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="M6.996 3.929l-1.454.278c-.802.154-1.436.78-1.524 1.591-.04.357-.068.7-.087 1a2.131 2.131 0 01-.604 1.367l-.621.624A2 2 0 003 11.856l.123.083a2 2 0 01.867 1.448l.07.674a2 2 0 001.613 1.757l1.323.253a2 2 0 01.79.34l1.048.752a2 2 0 002.332 0l1.048-.752a2 2 0 01.79-.34l1.473-.282a2 2 0 001.581-1.555l.218-1.043a2 2 0 01.745-1.181l.285-.218a2 2 0 00.297-2.901l-.833-.96a2 2 0 01-.448-.902l-.264-1.263a2 2 0 00-1.581-1.555l-1.473-.282a1.999 1.999 0 01-.79-.34l-1.048-.752a2 2 0 00-2.332 0l-1.048.752a2 2 0 01-.79.34z"
    ></path>
    <circle cx="10" cy="10" r="3" stroke="currentColor"></circle>
  </svg>
);

const SettingIcon = (props) => {
  return <Icon component={SettingSvg} {...props} />;
};

export { SettingIcon };
