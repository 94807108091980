import React from "react";

const ExpandIcon = ({ size = 20, className = "text-gray-500" }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#667085"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.67"
          d="M10.927 9.075l3.24-3.241m0 0H11.39m2.777 0v2.778m-5.092 2.315l-3.241 3.24m0 0h2.778m-2.778 0V11.39"
        ></path>
      </svg>
    </span>
  );
};

export default ExpandIcon;
