import React from "react";
import Icon from "@ant-design/icons";

const LeaveGroupSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.667 6L10 9.333M10 6L6.667 9.333m1.666 4a5.667 5.667 0 10-5.37-3.855c.071.214.107.32.114.403a.6.6 0 01-.019.217c-.02.08-.065.163-.155.33l-1.09 2.018c-.156.287-.234.431-.216.543a.333.333 0 00.156.232c.096.06.258.042.584.009l3.414-.353c.103-.011.155-.016.202-.015.046.002.08.007.124.017.046.01.104.033.22.077a5.653 5.653 0 002.036.377z"
    ></path>
  </svg>
);

const LeaveGroupIcon = (props) => {
  return <Icon component={LeaveGroupSvg} {...props} />;
};

export { LeaveGroupIcon };
