import React from "react";
import Icon from "@ant-design/icons";

const ForwardSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.86 8.404c.164-.14.245-.21.275-.292a.333.333 0 000-.225c-.03-.083-.111-.153-.274-.293l-5.647-4.84c-.28-.24-.42-.36-.54-.363a.333.333 0 00-.266.122c-.075.092-.075.277-.075.646v2.863A6.444 6.444 0 002 12.367v.407a7.6 7.6 0 015.333-2.728v2.794c0 .369 0 .553.075.645.065.08.164.125.267.123.119-.003.259-.123.539-.363l5.647-4.84z"
    ></path>
  </svg>
);

const ForwardIcon = (props) => {
  return <Icon component={ForwardSvg} {...props} />;
};

export { ForwardIcon };
