import React from "react";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
const StatusBadge = ({ statusType }) => {
  switch (+statusType) {
    case 1:
      return (
        <CustomBadge
          text="To Do"
          color="#667085"
          className="border border-[#667085]"
          dot
        />
      );
    case 2:
      return (
        <CustomBadge
          text="In Progress"
          color="#165ABF"
          className="border border-[#165ABF]"
          dot
        />
      );
    case 3:
      return (
        <CustomBadge
          text="To Be Reviewed"
          color="#F79009"
          className="border border-[#F79009]"
          dot
        />
      );
    case 4:
      return (
        <CustomBadge
          text="Done"
          color="#039855"
          className="border border-[#039855]"
          dot
        />
      );
    default:
      <CustomBadge
        text="To Do"
        color="#667085"
        className="border border-[#667085]"
        dot
      />;
  }

  return (
    <CustomBadge
      text="To Do"
      color="#667085"
      className="border border-[#667085]"
      dot
    />
  );
};

export default StatusBadge;
