import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  resetVault,
  updateVault,
} from "../../../Core/redux/slices/vault.slice";
import { useLocation, useParams } from "react-router-dom";
import { deleteFolder, getAllFolders } from "../../../Core/redux/api/vaultAPI";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

const DeleteFolderModal = () => {
  const { deleteFolderModal } = useAppSelector((state) => state.vault);
  const { tenantDetails } = useAppSelector((state) => state.tenant);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { user } = useAppSelector((state) => state.userDetails);
  const { projectId, "*": splat } = useParams();
  const { projectList } = useAppSelector((state) => state.projects);
  const projectDetails = projectList.find(
    (project) => project.id === projectId
  );

  const handleDelete = async () => {
    await dispatch(
      deleteFolder({ folderId: deleteFolderModal.id, userId: user.id })
    );

    let queryObject: {
      projectId: string;
      folderId?: string;
      tenantId?: string;
    };

    if (projectId == "undefined" || projectId == null) {
      queryObject = { projectId: null, tenantId: tenantDetails?.tenantId };
    } else {
      queryObject = { projectId };
    }

    if (splat && splat.length > 0) {
      const folderId = splat.split("-");
      queryObject.folderId = folderId[folderId.length - 1];
    }

    dispatch(resetVault(["deleteFolderModal"]));
    dispatch(
      updateDashboard({
        key: "alertPopupModal",
        value: {
          visible: true,
          data: {
            title: "New Folder",
            subtitle: "One new link uploaded to  ",
            description: location.pathname.includes("my-library")
              ? "My Library"
              : projectDetails.title,
          },
        },
      })
    );
    dispatch(getAllFolders(queryObject));
  };

  return (
    <CustomModal
      visible={deleteFolderModal.visible}
      width="360px"
      onCancel={() =>
        dispatch(
          updateVault({
            key: "deleteFolderModal",
            value: {
              visible: false,
              id: null,
            },
          })
        )
      }
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Delete folder</p>
            <button
              onClick={() =>
                dispatch(
                  updateVault({
                    key: "deleteFolderModal",
                    value: {
                      visible: false,
                      id: null,
                    },
                  })
                )
              }
            >
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to delete this folder? <br />
            This action cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() =>
                dispatch(
                  updateVault({
                    key: "deleteFolderModal",
                    value: {
                      visible: false,
                      id: null,
                    },
                  })
                )
              }
            />
            <CustomButton
              text="Delete"
              height="30px"
              width="101px"
              className="bg-error-700 text-white"
              onClick={handleDelete}
            />
          </div>
        </div>
      }
    />
  );
};

export default DeleteFolderModal;
