const rgbToHex = (r: number, g: number, b: number) =>
  `#${[r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join("")}`;

    function rgbToHexString(rgb) {
      // Extract the RGB values from the input string
      const values = rgb.match(/\d+/g);
    
      // Check that the input has three values
      if (values.length !== 3) {
        return "#000000";
      }
    
      // Convert the RGB values to hexadecimal strings and pad with leading zeros if necessary
      const hexValues = values.map(value => {
        let hex = parseInt(value).toString(16);
        if (hex.length === 1) hex = "0" + hex;
        return hex;
      });
    
      // Concatenate the hexadecimal strings and return the result
      return "#" + hexValues.join("");
    }

const hexToRgb = (hex = "#9B51E0", alpha = null) => {
  hex = hex || "#9B51E0"
  let cssNotation = "rgb";
  const rgbArray = hex
    ?.replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

  if (alpha !== null) {
    rgbArray?.push(alpha);
    cssNotation = "rgba";
  }

  return `${cssNotation}(${rgbArray?.join(",")})`;
};


const Colors = {
  Q: [66, 7, 231],
  W: [230, 98, 241],
  E: [249, 81, 211],
  R: [50, 193, 190],
  T: [20, 53, 108],
  Y: [199, 214, 159],
  U: [4, 239, 67],
  I: [188, 57, 47],
  O: [96, 148, 57],
  P: [81, 78, 16],
  L: [142, 102, 180],
  K: [157, 172, 221],
  J: [172, 243, 6],
  H: [203, 127, 88],
  G: [218, 197, 129],
  F: [234, 11, 170],
  D: [8, 151, 252],
  S: [35, 123, 149],
  A: [241, 12, 171],
  Z: [184, 144, 118],
  X: [215, 28, 200],
  C: [240, 189, 193],
  V: [245, 169, 26],
  B: [240, 229, 54],
  N: [111, 218, 98],
  M: [127, 32, 139],
};

// function randomColor() {
//   const hex = Math.floor(Math.random() * 0xffffff);
//   const color = "#" + hex.toString(16);

//   return color;
// }

const getColor = (opacity = 1 ,title ) => {
  const initials = title
  ? title
      .toString()
      .toUpperCase()
      .split(" ")
      .splice(0, 2)
      .map((n) => n[0])
      .join("")
  : "U";
 
  return {light:`rgba(${Colors[initials[0].toUpperCase()].join(",")},${opacity})` , original:`rgb(${Colors[initials[0].toUpperCase()].join(",")})`};
  
};

function getHexColor(input) {
  // Get the first letter of the input string
  if(!input) return ""
  const initial = input[0].toLowerCase();

  // Generate a hash code for the initial letter
  const hash = initial.charCodeAt(0) + 100;

  // Convert the hash code to a hexadecimal string and pad with leading zeros if necessary
  let hex = hash.toString(16);
  while (hex.length < 6) {
    hex = "0" + hex;
  }

  // Return the hexadecimal string as the color value
  return "#" + hex;
}


//const randomColorMemo = useMemo(() => randomColor(), [title]);



export { rgbToHex, hexToRgb , getColor , rgbToHexString ,getHexColor };
