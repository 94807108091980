import { useQuery, useMutation, useQueryClient } from "react-query";
import { client } from "../../utils/axiosClient";

// Fetch project details
export const useGetProjectDetails = (projectId: string) => {
  return useQuery(
    ["projectDetails", projectId],
    async () => {
      const { data } = await client.get(`/project?projectId=${projectId}`);
      return data;
    },
    {
      // Only enable the query if projectId is truthy
      enabled: !!projectId,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

// Create a project
export const useCreateProject = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (projectData: any) => {
      const { data } = await client.post("/project/create", projectData);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projectList");
      },
    }
  );
};

// Update project details
export const useUpdateProjectDetails = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: any) => {
      const { data } = await client.put("project/update-project", payload);
      return data;
    },
    {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries(["projectDetails", projectId]);
      },
    }
  );
};

// Setup project phases
export const useSetupPhases = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ projectId, phases }: { projectId: string; phases: any[] }) => {
      const { data } = await client.put(`/project/setup-phases`, {
        projectId,
        phases,
      });
      return data;
    },
    {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries(["projectDetails", projectId]);
      },
    }
  );
};

// Fetch project users
export const useGetProjectUsers = (projectId: string) => {
  return useQuery(
    ["projectUsers", projectId],
    async () => {
      const { data } = await client.get(
        `/project/project-users?projectId=${projectId}`
      );
      return data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

// Fetch organization users
export const useGetOrganizationUsers = () => {
  return useQuery(
    "organizationUsers",
    async () => {
      const { data } = await client.get(`/tenant/members`);
      return data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

// End phase
export const useEndPhase = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      projectId,
      phaseId,
      isCompleted,
      isOngoing,
      isActive,
      endDate,
    }: {
      projectId: string;
      phaseId?: string;
      isCompleted?: boolean;
      isOngoing?: boolean;
      isActive?: boolean;
      endDate?: string;
    }) => {
      const { data } = await client.put(`/project/project-phases`, {
        projectId,
        phaseId,
        isCompleted,
        isOngoing,
        isActive,
        endDate,
      });
      return data;
    },
    {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries(["projectDetails", projectId]);
      },
    }
  );
};

// Fetch project list
export const useGetProjectList = (userId: number) => {
  return useQuery(
    ["projectList", userId],
    async () => {
      const { data } = await client.get(`/project/project-list?id=${userId}`);
      return data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

// Delete a project
export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ projectId }: { projectId: string }) => {
      const { data } = await client.delete(
        `/project/delete-project?projectId=${projectId}`
      );
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projectList");
      },
    }
  );
};

// Fetch project permissions
export const useGetProjectPermission = ({
  projectId,
  userId,
}: {
  projectId: string;
  userId: number;
}) => {
  return useQuery(
    ["projectPermission", projectId, userId],
    async () => {
      const { data } = await client.get(
        `/project/project-permissions?projectId=${projectId}&userId=${userId}`
      );
      return data;
    },
    {
      enabled: true,
      refetchInterval: 1800000,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

// Mark SPOC
export const useMarkSpoc = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      projectId,
      userId,
      spoc,
    }: {
      projectId: string;
      userId: number;
      spoc: number;
    }) => {
      const { data } = await client.put(`/project/mark-spoc`, {
        projectId,
        userId,
        spoc,
      });
      return data;
    },
    {
      onSuccess: (_, { projectId }) => {
        queryClient.invalidateQueries(["projectDetails", projectId]);
      },
    }
  );
};
