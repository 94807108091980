import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../api/notificationAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  notificationList: [],
  notificationTab: "",
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    resetToInitialNotification: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    changeProject: (state, action) => {
      //state.selectedProject = action.payload;
    },

    updateNotification: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers(builder) {
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "fulfilled";
      state.notificationList = action.payload.result;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.loading = false;
      state.status = "rejected";
      state.error = action.error;
    });
  },
});

export const { changeProject, updateNotification  ,resetToInitialNotification} = projectSlice.actions;
export default projectSlice.reducer;
