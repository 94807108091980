import React, { useEffect, useState } from "react";
import { Checkbox, DatePicker, Divider, Dropdown, Popover } from "antd";
import ScheduleRouteSelector from "../Components/ScheduleRouteSelector";

import CustomButton from "../../../Core/CommonV2/CustomButton";
import UserQuickFilters from "../Components/UserQuickFilters";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";

import { PhaseIcon } from "../../../Core/svgV2/PhaseIcon";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getGoalsBySprint,
  getProjectPhases,
  getReportsByDateRange,
  getReportsBySprint,
  getSprintsByPhase,
  getSwimlanes,
} from "../../../Core/redux/api/scheduleAPI";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";
import { getStatusDetails } from "../../../Core/utils/scheduleUtils";
import { convertArrayToCsv } from "../../../Core/utils/exportToCsv";
import dayjs from "dayjs";
import { client } from "../../../Core/utils/axiosClient";

const FilterLayout = ({
  filterName,
  children,
  footer = false,
  onClear = () => null,
  onDone = () => null,
}) => {
  return (
    <div
      className="w-[320px]  bg-white border border-gray-100 rounded-lg "
      style={{
        boxShadow:
          "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      }}
    >
      <h3 className="out-300-14 pt-[14px] pb-2.5 px-[14px] border-b border-gray-200 text-gray-500">
        {filterName}
      </h3>
      {/* <div className="max-h-[288px] overflow-y-auto"> */}

      {children}
      {/* </div> */}
      {footer ? (
        <div className="flex border-t border-gray-200 pt-[2px] pb-[2px] justify-end items-center">
          <CustomButton
            className="text-gray-500 bg-transparent out-500-14"
            text="Clear"
            onClick={() => onClear()}
          />
          <CustomButton
            className="bg-transparent out-500-14 text-primary-600"
            text="Done"
            onClick={() => {
              onDone();

              const body = document.querySelector("body");
              // Programmatically trigger a click event on the body
              const clickEvent = new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              });

              body.dispatchEvent(clickEvent);
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const StatusFilter = () => {
  const { swimlanes, reportsGroupBy } = useAppSelector(
    (state) => state.schedule
  );
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState([]);

  const handleClick = (id) => {
    if (selected.includes(id)) {
      const newStatusFilters = selected.filter((i) => i !== id);
      setSelected(newStatusFilters);
    } else {
      const newStatusFilters = [...selected, id];
      setSelected(newStatusFilters);
    }
  };

  useEffect(() => {
    if (reportsGroupBy.key === "Status") {
      setSelected(reportsGroupBy.value);
    }
  }, [reportsGroupBy]);

  return (
    <FilterLayout
      filterName={"Status"}
      footer={true}
      onDone={() =>
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Status", value: selected },
          })
        )
      }
      onClear={() => {
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Status", value: [] },
          })
        );
      }}
    >
      {swimlanes.map((swimlane) => {
        const { color } = getStatusDetails(swimlane.statusId);
        return (
          <div
            key={swimlane.id}
            role="button"
            onClick={() => handleClick(swimlane.id)}
            className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
          >
            <CustomBadge
              text={swimlane.title}
              color={color}
              className="border border-[#667085]"
              dot
            />
            <Checkbox checked={selected.includes(swimlane.id)} />
          </div>
        );
      })}
    </FilterLayout>
  );
};

const PhaseFilter = () => {
  const { phases, reportsGroupBy } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState([]);

  const handleClick = (id) => {
    if (selected.includes(id)) {
      const newStatusFilters = selected.filter((i) => i !== id);
      setSelected(newStatusFilters);
    } else {
      const newStatusFilters = [...selected, id];
      setSelected(newStatusFilters);
    }
  };

  useEffect(() => {
    if (reportsGroupBy.key === "Phase") {
      setSelected(reportsGroupBy.value);
    }
  }, [reportsGroupBy]);

  return (
    <FilterLayout
      filterName={"Phase"}
      footer={true}
      onDone={() =>
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Phase", value: selected },
          })
        )
      }
      onClear={() => {
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Phase", value: [] },
          })
        );
      }}
    >
      {phases.map((p) => (
        <div
          role="button"
          onClick={() => handleClick(p.projectPhaseId)}
          key={p.id}
          className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
        >
          <div className="flex items-center gap-x-2">
            <PhaseIcon icon height="16px" width="16px" phaseId={+p.phaseId} />
            <p className="text-gray-900 out-300-14">
              {p.phases} {p.phaseIteration}
            </p>
          </div>
          <Checkbox checked={selected.includes(p.projectPhaseId)} />
        </div>
      ))}
    </FilterLayout>
  );
};

const SprintFilter = () => {
  const { sprints, reportsGroupBy } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const [selected, setSelected] = useState([]);

  const handleClick = (id) => {
    if (selected.includes(id)) {
      const newStatusFilters = selected.filter((i) => i !== id);
      setSelected(newStatusFilters);
    } else {
      const newStatusFilters = [...selected, id];
      setSelected(newStatusFilters);
    }
  };

  useEffect(() => {
    if (reportsGroupBy.key === "Sprint") {
      setSelected(reportsGroupBy.value);
    }
  }, [reportsGroupBy]);
  return (
    <FilterLayout
      filterName={"Sprint"}
      footer={true}
      onDone={async () => {
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Sprint", value: selected },
          })
        );
        // const data = await client.get("/reports/get-reports-by-sprints", {
        //   params: { filterType: "sprint", sprintParams: selected, projectId },
        // });
        await dispatch(
          getReportsBySprint({
            payload: {
              filterType: "sprint",
              sprintParams: selected,
              projectId,
            },
          })
        );
      }}
      onClear={() => {
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Sprint", value: [] },
          })
        );
      }}
    >
      <div className="max-h-[160px] overflow-y-auto">
        {sprints.map((sprint) => (
          <div
            key={sprint.id}
            onClick={() => handleClick(sprint.id)}
            role="button"
            className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
          >
            <div className="flex items-center gap-x-2">
              <img className="" src="/images/v2/schedule/Sprint.svg" />
              <p className="text-gray-900 out-300-14">{sprint.name}</p>
            </div>
            <Checkbox checked={selected.includes(sprint.id)} />
          </div>
        ))}
      </div>
    </FilterLayout>
  );
};

const MonthFilter = () => {
  const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const dispatch = useAppDispatch();
  const { reportsGroupBy } = useAppSelector((state) => state.schedule);
  const [selected, setSelected] = useState([]);

  const handleClick = (id) => {
    if (selected.includes(id)) {
      const newStatusFilters = selected.filter((i) => i !== id);
      setSelected(newStatusFilters);
    } else {
      const newStatusFilters = [...selected, id];
      setSelected(newStatusFilters);
    }
  };

  useEffect(() => {
    if (reportsGroupBy.key === "Month") {
      setSelected(reportsGroupBy.value);
    }
  }, [reportsGroupBy]);
  return (
    <FilterLayout
      filterName={"Month"}
      footer={true}
      onDone={() =>
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Month", value: selected },
          })
        )
      }
      onClear={() => {
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Month", value: [] },
          })
        );
      }}
    >
      <div className="max-h-[300px] overflow-y-scroll">
        {Months.map((month, i) => (
          <div
            key={i}
            role="button"
            onClick={() => handleClick(month)}
            className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
          >
            <p className="text-gray-900 out-300-14">{month}</p>
            <Checkbox checked={selected.includes(month)} />
          </div>
        ))}
      </div>
    </FilterLayout>
  );
};

const YearFilter = () => {
  const Years = ["2020", "2021", "2022", "2023"];
  const dispatch = useAppDispatch();
  const { reportsGroupBy } = useAppSelector((state) => state.schedule);
  const [selected, setSelected] = useState([]);

  const handleClick = (id) => {
    if (selected.includes(id)) {
      const newStatusFilters = selected.filter((i) => i !== id);
      setSelected(newStatusFilters);
    } else {
      const newStatusFilters = [...selected, id];
      setSelected(newStatusFilters);
    }
  };

  useEffect(() => {
    if (reportsGroupBy.key === "Year") {
      setSelected(reportsGroupBy.value);
    }
  }, [reportsGroupBy]);
  return (
    <FilterLayout
      filterName={"Year"}
      footer={true}
      onDone={() =>
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Year", value: selected },
          })
        )
      }
      onClear={() => {
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Year", value: [] },
          })
        );
      }}
    >
      <div className="max-h-[300px] overflow-y-scroll">
        {Years.map((year, i) => (
          <div
            key={i}
            role="button"
            onClick={() => handleClick(year)}
            className="flex cursor-pointer hover:bg-gray-50 justify-between  px-[14px] py-2.5 "
          >
            <p className="text-gray-900 out-300-14">{year}</p>
            <Checkbox checked={selected.includes(year)} />
          </div>
        ))}
      </div>
    </FilterLayout>
  );
};

const DateFilter = () => {
  const { reportsGroupBy } = useAppSelector((state) => state.schedule);
  const [range, setRange] = useState([null, null]);
  const { projectId } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (reportsGroupBy.key === "Date Range") {
      setRange(reportsGroupBy.value);
    }
  }, [reportsGroupBy]);

  return (
    <FilterLayout
      filterName={"Date Range"}
      footer={true}
      onDone={async () => {
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Date Range", value: range },
          })
        );
        await dispatch(
          getReportsByDateRange({
            payload: {
              filterType: "date_range",
              dateRange: [range[0], dayjs(range[1]).add(1, "day").format()],
              projectId,
            },
          })
        );
      }}
      onClear={() => {
        dispatch(
          updateSchedule({
            key: "reportsGroupBy",
            value: { key: "Date Range", value: [] },
          })
        );
      }}
    >
      <div className="w-full p-2">
        <DatePicker.RangePicker
          value={range as any}
          className="w-full bg-white"
          onChange={(e) => setRange(e)}
        />
      </div>
    </FilterLayout>
  );
};

const FilterItem = ({ filterName, filterContent }) => {
  const { reportsGroupBy } = useAppSelector((state) => state.schedule);
  return (
    <Popover
      content={filterContent}
      arrow={false}
      placement="leftTop"
      trigger={"click"}
    >
      <div
        className={`flex cursor-pointer hover:bg-gray-50 justify-between px-[14px] py-2.5 ${
          reportsGroupBy.key === filterName ? "bg-gray-50" : ""
        } `}
      >
        <p className="text-gray-900 out-300-14 ">{filterName}</p>
        <ChevronIcon className="text-primary-600 rotate-[-90deg] transform " />
      </div>
    </Popover>
  );
};

const Filters = () => {
  return (
    <FilterLayout filterName={"Group by"}>
      <FilterItem filterContent={<StatusFilter />} filterName={"Status"} />
      <FilterItem filterContent={<PhaseFilter />} filterName={"Phase"} />
      <FilterItem filterContent={<SprintFilter />} filterName={"Sprint"} />
      <FilterItem filterContent={<DateFilter />} filterName={"Date Range"} />
      <FilterItem filterContent={<MonthFilter />} filterName={"Month"} />
      <FilterItem filterContent={<YearFilter />} filterName={"Year"} />
    </FilterLayout>
  );
};

const GroupByStatus = () => {
  const { reportsGroupBy } = useAppSelector((state) => state.schedule);

  return (
    <Popover
      trigger={["click"]}
      content={<Filters />}
      arrow={false}
      placement="bottomLeft"
    >
      <button className="flex items-center justify-center px-2.5 py-[5px] gap-x-2  rounded border border-gray-200 bg-white">
        <p className="text-gray-700 out-500-14">
          Group by : {reportsGroupBy?.key}
        </p>
        <ChevronIcon className="text-gray-700 " />
      </button>
    </Popover>
  );
};

const ReportsMenu = ({ data }) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const { userList } = useAppSelector((state) => state.overview);
  const { swimlanes, filterGoalParameter, reportsGroupBy } = useAppSelector(
    (state) => state.schedule
  );

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectPhases({ projectId }));
      dispatch(getSwimlanes({ projectId }));
      dispatch(getSprintsByPhase({ projectId }));
      dispatch(
        getGoalsBySprint({
          projectId,
          module: "backlog",
        })
      );
    }
  }, [projectId]);

  useEffect(() => {
    return () => {
      dispatch(resetSchedule(["goals", "sprints", "swimlanes", "phases"]));
    };
  }, []);

  useEffect(() => {
    const { assignee } = filterGoalParameter;
    //final goal : add current user in assignnee array, if he exists remove him from the array

    //creating a copy of the assignee object as we cannot modify on a read only object
    let allProjectAssigneeFilter = [...assignee];
    //creating a copy of the current project filter object
    const previousFilters = allProjectAssigneeFilter?.find(
      (ele) => +ele.projectId === +projectId
    );
    let currentProjectFilters =
      previousFilters !== undefined
        ? JSON.parse(JSON.stringify(previousFilters))
        : [];

    if (currentProjectFilters !== undefined) {
      //if there is any filter on the current project , check if the assignee array includes the current user
      const isMyTaskSelected = currentProjectFilters?.assignee?.includes(
        user.id
      );

      let currentProjectAssigneeArray = currentProjectFilters?.assignee
        ? currentProjectFilters?.assignee
        : [];
      if (isMyTaskSelected) {
        //if the user filter existed , remove it
        currentProjectAssigneeArray = currentProjectFilters?.assignee?.filter(
          (assigneeId) => +assigneeId !== +user.id
        );
        userList.map((user) => currentProjectAssigneeArray.push(user.userId));
      } else {
        //if the user filter did not exist add it

        userList.map((user) => currentProjectAssigneeArray.push(user.userId));
      }

      //create the new assignee object for current project
      currentProjectFilters = {
        projectId,
        assignee: currentProjectAssigneeArray,
      };
      //remove the current project assignee filter from filtergoalparameter object
      allProjectAssigneeFilter = allProjectAssigneeFilter?.filter(
        (ele) => +ele.projectId !== +projectId
      );
    } else {
      //create the new assignee object for current project

      const assigneeArray = [];
      userList.map((user) => assigneeArray.push(user.userId));
      currentProjectFilters = { projectId, assignee: assigneeArray };
    }
    //push the updated array
    allProjectAssigneeFilter.push(currentProjectFilters);

    dispatch(
      updateSchedule({
        key: "filterGoalParameter",
        value: {
          ...filterGoalParameter,
          assignee: allProjectAssigneeFilter,
        },
      })
    );
  }, [userList]);

  useEffect(() => {
    const values = swimlanes.map((s) => s.id);
    dispatch(
      updateSchedule({
        key: "reportsGroupBy",
        value: { key: "Status", value: values },
      })
    );
  }, [projectId, swimlanes]);

  const title = () => {
    if (searchParams.has("report")) {
      if (searchParams.get("report") === "ReportByTask") {
        return (
          <div
            role="button"
            onClick={() => navigate(-1)}
            className="flex items-center text-black cursor-pointer out-500-14 gap-x-2"
          >
            <ChevronIcon className="transform rotate-90" />{" "}
            <span>Reports by Tasks</span>
          </div>
        );
      } else {
        return (
          <div
            role="button"
            onClick={() => navigate(-1)}
            className="flex items-center text-black cursor-pointer out-500-14 gap-x-2"
          >
            <ChevronIcon className="transform rotate-90" />{" "}
            <span> Reports by Time logged</span>
          </div>
        );
      }
    }
    return (
      <div className="flex items-center text-black out-500-14 gap-x-2">
        Reports
      </div>
    );
  };

  console.log(data, "datata");

  const handleExport = () => {
    const transformedData = data.value.reduce((result, status) => {
      status.users.forEach((user) => {
        const existingUserIndex = result.findIndex(
          (item) => item.name === user.name
        );
        if (existingUserIndex !== -1) {
          result[existingUserIndex][status.name] = user.value;
        } else {
          const newUser = {
            name: user.name,
            [status.name]: user.value,
          };
          result.push(newUser);
        }
      });
      return result;
    }, []);

    const fileName = `Report By -- ${reportsGroupBy.key} ${dayjs().format(
      "MMMM DD YYYY"
    )}.csv`;

    convertArrayToCsv(transformedData, fileName);
  };
  const currentProjectFilter = filterGoalParameter?.assignee?.find(
    (filter) => +filter.projectId === +projectId
  );
  const isMyTask =
    currentProjectFilter?.assignee?.includes(user.id) &&
    currentProjectFilter?.assignee?.length === 1;
  return (
    <div className="px-6 py-3 border-b border-gray-200">
      <div className="flex justify-between">
        <div>
          <div className="flex items-center gap-x-2">{title()}</div>
          <p className="text-gray-500 out-300-14">
            View reports for the time logged and number of tasks within a range
            of dates.
          </p>
        </div>
        <div>
          <ScheduleRouteSelector current="reports" />
        </div>
      </div>
      <div className="flex items-center justify-between mt-4">
        <div>
          {searchParams.has("report") && (
            <div className="flex items-center">
              <GroupByStatus />
              <div className="ml-3">
                <CustomButton
                  width="74px"
                  className={`text-gray-500 bg-transparent out-500-14 ${
                    isMyTask ? "text-primary-600" : "text-gray-500"
                  } `}
                  text="Only me"
                  onClick={() => {
                    const { assignee } = filterGoalParameter;
                    //final goal : add current user in assignnee array, if he exists remove him from the array

                    //creating a copy of the assignee object as we cannot modify on a read only object
                    let allProjectAssigneeFilter = [...assignee];

                    //creating a copy of the current project filter object
                    const previousFilters = allProjectAssigneeFilter?.find(
                      (ele) => +ele.projectId === +projectId
                    );
                    let currentProjectFilters =
                      previousFilters !== undefined
                        ? JSON.parse(JSON.stringify(previousFilters))
                        : [];

                    if (currentProjectFilters !== undefined) {
                      //if there is any filter on the current project , check if the assignee array includes the current user
                      const isMyTaskSelected =
                        currentProjectFilters?.assignee?.includes(user.id) &&
                        currentProjectFilters?.assignee?.length === 1;

                      let currentProjectAssigneeArray =
                        currentProjectFilters?.assignee
                          ? currentProjectFilters?.assignee
                          : [];
                      if (isMyTaskSelected) {
                        //if the user filter existed , remove it
                        currentProjectAssigneeArray =
                          currentProjectFilters?.assignee?.filter(
                            (assigneeId) => +assigneeId !== +user.id
                          );
                      } else {
                        //if the user filter did not exist add it

                        currentProjectAssigneeArray = [user.id];
                      }

                      //create the new assignee object for current project
                      currentProjectFilters = {
                        projectId,
                        assignee: currentProjectAssigneeArray,
                      };
                      //remove the current project assignee filter from filtergoalparameter object
                      allProjectAssigneeFilter =
                        allProjectAssigneeFilter?.filter(
                          (ele) => +ele.projectId !== +projectId
                        );
                    } else {
                      //create the new assignee object for current project
                      currentProjectFilters = {
                        projectId,
                        assignee: [user.id],
                      };
                    }
                    //push the updated array
                    allProjectAssigneeFilter.push(currentProjectFilters);

                    dispatch(
                      updateSchedule({
                        key: "filterGoalParameter",
                        value: {
                          ...filterGoalParameter,
                          assignee: allProjectAssigneeFilter,
                        },
                      })
                    );
                  }}
                />
              </div>
              <Divider type="vertical" className="h-[30px]" />
              <UserQuickFilters />
            </div>
          )}
        </div>
        <div className="flex items-center">
          {searchParams.has("report") && (
            <button
              onClick={handleExport}
              className="bg-transparent ml-4 flex items-center justify-center px-2.5 h-[30px] gap-x-2 rounded border out-500-14 text-gray-700 border-gray-200"
            >
              <img src="/images/v2/schedule/external-link.svg" />
              <span>Export</span>
            </button>
          )}
          <CustomButton
            className="ml-4 text-gray-700 bg-transparent border border-gray-200 out-500-14"
            text={"Release Notes"}
            height="30px"
            onClick={() => navigate(location.pathname + "/release-notes")}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsMenu;
