const WORKING_DAYS_IN_WEEK = 5;
const WORKING_HOURS_IN_A_DAY = 8;

export const getTimeLoginMinutes = (time) => {
  if (!time) return 0;
  const match = time.match(/\d+w|\d+d|\d+h|\d+m/g);

  // Convert the values to a total number of minutes
  let minutes = 0;

  if (match) {
    match.forEach((val) => {
      const unit = val.slice(-1);
      const amount = parseInt(val.slice(0, -1));
      switch (unit) {
        case "w":
          minutes +=
            amount * WORKING_DAYS_IN_WEEK * WORKING_HOURS_IN_A_DAY * 60;
          break;
        case "d":
          minutes += amount * WORKING_HOURS_IN_A_DAY * 60;
          break;
        case "h":
          minutes += amount * 60;
          break;
        case "m":
          minutes += amount;
          break;
      }
    });
  }

  return minutes;
};

export const convertMinutesToTimeLog = (currentMinutes) => {
  let totalMinutes = +currentMinutes;

  // Convert the total number of minutes back to weeks, days, hours, and minutes
  const weeks = Math.floor(
    totalMinutes / (WORKING_DAYS_IN_WEEK * WORKING_HOURS_IN_A_DAY * 60)
  );
  totalMinutes %= WORKING_DAYS_IN_WEEK * WORKING_HOURS_IN_A_DAY * 60;
  const days = Math.floor(totalMinutes / (WORKING_HOURS_IN_A_DAY * 60));
  totalMinutes %= WORKING_HOURS_IN_A_DAY * 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Format the estimate string in the desired format "0w 0d 00h 00m"
  let updatedEstimate = "";
  if (weeks) updatedEstimate += `${weeks}w `;
  if (days) updatedEstimate += `${days}d `;
  if (hours) updatedEstimate += `${hours}h `;
  if (minutes) updatedEstimate += `${minutes}m `;

  // Return the updated estimate
  return updatedEstimate;
};
export const convertMinutesToTimeLogForDcf = (currentSeconds) => {
  let totalMinutes = +currentSeconds / 60;

  // Convert the total number of minutes to days, hours, and minutes
  const days = Math.floor(totalMinutes / (24 * 60));
  totalMinutes %= 24 * 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Format the estimate string in the desired format "0d 00h 00m"
  const updatedEstimate = {
    days: days,
    hours: hours,
    minutes: parseInt(`${Math.ceil(minutes)}`),
  };

  // Return the updated estimate
  return updatedEstimate;
};
