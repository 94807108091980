import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomAvatar from "../../Core/Common/CustomAvatar";
import CustomButton from "../../Core/CommonV2/CustomButton";
import CustomSelect from "../../Core/CommonV2/CustomSelect";
import {
  changeWorkspace,
  getWorkspaces,
} from "../../Core/redux/api/dashboardAPI";
import { getTenantDetails } from "../../Core/redux/api/tenantAPI";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { changeTenant } from "../../Core/redux/slices/tenant.slice";
import { updateSettings } from "../../Core/redux/slices/settings.slice";
import { chatClient, client } from "../../Core/utils/axiosClient";

import db from "../../Core/utils/indexedDb";
// import db, { populateDatabase } from "../../Core/utils/indexedDb";

const SwitchWorkspaceScreen = () => {
  const { currentTenantId } = useAppSelector((state) => state.tenant);

  const hostname = window.location.host;
  const antarcticaTenantId = "3fb2a4e6-f736-4209-aceb-05e77d19b808"; // currently we are restricting some features for antarctica global tenant's users
  const dispatch = useAppDispatch();

  const handleWorkSpaces = async () => {
    const workspaces = await dispatch(getWorkspaces());
    if (workspaces.payload) {
      if (workspaces.payload.length === 1) {
        // const get
        // dispatch(updateSettings(key:"allowedUsersForGuest",value:))

        navigate("/dashboard");
      } else if (workspaces.payload.length === 0) {
        navigate("/onboardingV2");
      }
    }
  };

  useEffect(() => {
    handleWorkSpaces();
  }, []);
  const [tenantId, setTenantId] = useState(currentTenantId);
  const { workspaces } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.userDetails);

  const navigate = useNavigate();
  const handleSwitchWorkSpace = async () => {
    // dispatch(resetToInitialActivityLogs({}));
    // dispatch(resetToInitialConversation({}));
    // dispatch(resetToInitialDashBoard({}));
    // dispatch(resetToInitialNotification({}));
    // dispatch(resetToInitialOnboarding({}));
    // dispatch(resetToInitialGlobal({}));
    // dispatch(resetToInitialOverview({}));
    // dispatch(resetToInitialPreview({}));
    // dispatch(resetToInitialProjectOnboarding({}));
    // dispatch(resetToInitialProjects({}));
    // dispatch(resetToInitialSchedule({}));
    // dispatch(resetToInitialSetting({}));
    // dispatch(resetToInitialVault({}));

    dispatch(changeTenant(tenantId));

    await dispatch(changeWorkspace({ tenantId }));
    //location.reload();
    dispatch(getTenantDetails({}));
    //load all messages of user in indexeddb
    // dispatch(
    //   getAllV3UserMessages({
    //     userId: user.id,
    //     tenantId: tenantId,
    //   })
    // );

    navigate("/dashboard");
  };

  const tenantName = workspaces
    ? workspaces.find((work) => work.tenantId == user.tenant_id)?.tenantName ||
      ""
    : "";
  return (
    <div
      className="p-6 w-full h-screen  "
      style={{
        backgroundImage: `url("/images/v2/dashboard/workspace.svg") `,
        backgroundPositionY: "100%",
        backgroundRepeat: "no-repeat",

        backgroundPosition: "bottom",
        backgroundSize: "contain",
      }}
    >
      <div className="w-[107px] h-[33px]">
        <img
          className="w-full h-full "
          src="/images/icons/waddle-logo.svg"
          alt="waddle logo"
        />
      </div>

      <div className="flex justify-center mt-[76px] ">
        <div className="w-[407px] rounded-[24px] shadow-xl bg-white p-6 border">
          <div className="out-600-24 text-primary-600 mb-6">
            Select your Workspace
          </div>
          <div className="out-500-14 text-gray-700 mb-1.5"> Workspace</div>
          <div className=" mb-6">
            <CustomSelect
              wrapperClassName="h-[40px] rounded-[12px]  "
              value={tenantId === "" ? null : tenantId}
              border={true}
              placeholder={
                <div className="flex items-center gap-x-2">
                  <div className="w-6 h-6 rounded-3xl">
                    <img src="/images/v2/dashboard/workspacePH.svg" />
                  </div>
                  <div className="out-300-14 text-gray-900">
                    Select a workspace
                  </div>
                </div>
              }
              showBorderOnlyOnFocused
              options={workspaces?.map((p) => {
                return {
                  id: p.tenantId,
                  title: p.tenantName,
                  value: p.tenantId,
                  render: () => (
                    <div className="flex items-center" key={p.id + "phases"}>
                      <CustomAvatar size={24} title={p.tenantName} />

                      <p className="out-400-14 pl-2 text-gray-900 capitalize ">
                        {p.tenantName}
                      </p>
                    </div>
                  ),
                };
              })}
              onChange={(e) => setTenantId(e)}
            />
          </div>

          <CustomButton
            text="Continue"
            className="out-500-14 text-white bg-primary-600 rounded-xl  "
            height="36px"
            width="100%"
            disabled={tenantId === ""}
            onClick={() => {
              handleSwitchWorkSpace();
            }}
          />
          <Divider />

          <div className="out-300-14 text-gray-500">Recent login</div>
          <div
            className="flex justify-between items-center shadow-xs rounded-xl bg-[#EFF8FF] mt-6 p-[10px] px-4 cursor-pointer "
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <div className="out-500-14 text-primary-700">{tenantName}</div>
            <div>
              <img src="/images/icons/chevron-right.svg" />
            </div>
          </div>

          {(hostname !== "localhost:3000" &&
            process.env.REACT_APP_ENV &&
            process.env.REACT_APP_ENV !== "qa" &&
            process.env.REACT_APP_ENV !== "develop" &&
            currentTenantId !== antarcticaTenantId) ||
          +user.roleId === 4 ? (
            <></>
          ) : (
            <>
              <Divider>
                <div className="out-400-14 text-gray-500">Or</div>
              </Divider>
              <CustomButton
                text="Create Your Own"
                className="out-500-14 text-gray-700 bg-white border shadow-xs rounded-lg  "
                height="36px"
                width="100%"
                onClick={() => {
                  navigate("/onboardingV2");
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SwitchWorkspaceScreen;
