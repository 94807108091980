import React from "react";
import PageTitle from "../PageTitle";
import { Switch } from "antd";
import {
  DashBoardIcon,
  LibraryIcon,
  MessagesIcon,
  ScheduleIcon,
} from "../../../Core/LayoutV2/LayoutIcons";
import { RightArrowIcon } from "../../../Core/svgV2/RightArrow";
import { useNavigate } from "react-router-dom";

const Card = ({ icon: Icon, title, onClick }) => {
  return (
    <div className="rounded-lg border border-gray-200 px-3 py-2.5 w-[201px]">
      <Icon className="text-gray-500" />
      <div className="out-500-14 text-gray-700 mt-2">{title}</div>
      <div className="flex gap-x-2 mt-3" onClick={onClick} role="button">
        <div className="out-500-14 text-primary-700">Take The Tour</div>
        <RightArrowIcon className="text-primary-700" />
      </div>
    </div>
  );
};

const StartGuide = () => {
  const navigate = useNavigate();
  return (
    <div>
      <PageTitle
        title={"Start Guide"}
        subtext={"Learn how to use the app with our app tours."}
      />
      <div className="flex gap-x-2 mt-6">
        <Switch />
        <div>
          <p className="out-500-14 text-gray-700">App tour settings</p>
          <p className="out-300-12 text-gray-500">
            Always show app tour after creating a new project
          </p>
        </div>
      </div>

      <div className="out-500-14 text-black mt-6">Revisit a tour</div>
      <div className="flex flex-wrap w-[677px] gap-8 mt-6 ">
        <Card
          icon={DashBoardIcon}
          title="Dashboard"
          onClick={() => {
            localStorage.removeItem("DashboardTour");
            navigate("/dashboard");
          }}
        />
        <Card
          icon={MessagesIcon}
          title="Messages"
          onClick={() => {
            localStorage.removeItem("MessageTour");
            navigate("/messages");
          }}
        />
        <Card
          icon={ScheduleIcon}
          title="Schedule"
          onClick={() => {
            localStorage.removeItem("ScheduleTour");
            navigate("/schedule/1/timeline");
          }}
        />
        <Card
          icon={LibraryIcon}
          title="Library"
          onClick={() => {
            localStorage.removeItem("LibraryTour");
            navigate("/Library");
          }}
        />
      </div>
    </div>
  );
};

export default StartGuide;
