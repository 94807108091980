import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { TASK_ACTIVITY_LOG_ACTIONS } from "../../../Core/constants";
import {
  getGoalsBySprint,
  updateGoal,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams, useSearchParams } from "react-router-dom";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";

const MoveToVersionModal = ({ visible, onClose, task }) => {
  const { releaseVersions } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedVersion, setSelectedVersion] = useState(null);
  const releasedVersions = releaseVersions.filter(
    (version) => version.versionStatus == 1
  );
  const unReleasedVersions = releaseVersions.filter(
    (version) => version.versionStatus == 0
  );
  return (
    <CustomModal
      visible={visible}
      width="352px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Move to</p>
            <div className="flex items-center ">
              <button onClick={() => onClose()}>
                <CrossIcon className="text-gray-700" />
              </button>
            </div>
          </div>{" "}
          <div className=" mt-4  w-full ">
            {/* <CustomInputBox className="w-full" placeholder="Enter a name" /> */}
            <CustomSelect
              placeholder="Select a version"
              group
              onChange={(e) => setSelectedVersion(e)}
              options={[
                {
                  id: "1",
                  title: "Unreleased",
                  options: unReleasedVersions.map((version) => {
                    return {
                      id: version.id,
                      label: version.versionName,
                      value: version.id,
                      render: () => (
                        <div className="out-300-14 text-gray-900">
                          {version.versionName}
                        </div>
                      ),
                    };
                  }),
                },
                {
                  id: "2",
                  title: "Released",
                  options: releasedVersions.map((version) => {
                    return {
                      id: version.id,
                      label: version.versionName,
                      value: version.id,
                      render: () => (
                        <div className="out-300-14 text-gray-900">
                          {version.versionName}
                        </div>
                      ),
                    };
                  }),
                },
              ]}
            />
          </div>
          <div className="flex items-center mt-4 justify-end gap-x-2">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500 out-500-14"
              height="30px"
              onClick={onClose}
            />
            <CustomButton
              text="Add to version"
              className="bg-transparent text-primary-700 out-500-14"
              height="30px"
              onClick={async () => {
                await dispatch(
                  updateGoal({
                    releaseVersionId: selectedVersion,
                    goalId: task.id,
                    action: {
                      oldValue: task.releaseVersionId,
                      newValue: selectedVersion,
                      actionName:
                        TASK_ACTIVITY_LOG_ACTIONS.RELEASE_VERSION_CHANGE,
                    },
                  })
                );
                await dispatch(
                  updateSchedule({ key: "isGoalEdited", value: true })
                );
                dispatch(
                  getGoalsBySprint({
                    projectId,
                    filters: { releaseVersionId: searchParams.get("noteId") },
                    module: "sprints",
                  })
                );
                onClose();
              }}
            />
          </div>
        </div>
      }
    />
  );
};

export default MoveToVersionModal;
