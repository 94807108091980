import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";

import { useParams } from "react-router-dom";
import StatusBadge from "../Components/StatusBadge";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import {
  resetSchedule,
  updateSchedule,
} from "../../../Core/redux/slices/schedule.slice";

import {
  getGoalsBySprint,
  getSprintsByPhase,
  updateSprint,
} from "../../../Core/redux/api/scheduleAPI";
import dayjs from "dayjs";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import EditSprintModal from "./EditSprintModal";
import { useQueryClient } from "react-query";

const CompleteSprintModal = ({ visible, onClose }) => {
  const [value, setValue] = useState(null);
  const dispatch = useAppDispatch();
  const queryClient: any = useQueryClient();

  const {
    goals,
    sprints,
    completeSprintModal,
    editSprintModal,
    phases,
    toDoCount,
    inProgressCount,
  } = useAppSelector((state) => state.schedule);
  const { projectId } = useParams();
  const activePhase = phases.find((p) => p.isOngoing);

  const selectedSprint = sprints?.filter(
    (sprint) => +sprint.id === +completeSprintModal?.sprint?.id
  )[0];
  console.log(selectedSprint, "pending in prog");

  const handleCompleteSprint = async () => {
    dispatch(
      updateSchedule({
        key: "confirmDatesModal",
        value: {
          visible: true,
          sprint: completeSprintModal?.sprint,
          phaseId: activePhase?.projectPhaseId || "",
          newValue: value,
        },
      })
    );

    // const payload = {
    //   sprintId: completeSprintModal.sprintId,
    //   isCompleted: 1,

    //   isOngoing: 0,
    //   status: 3,
    //   newSprintId: value,
    //   endDate: dayjs(completeSprintModal?.sprint?.endDate).format("YYYY-MM-DD"),
    //   // swimlaneId: swimlanes.find((s) => s.statusId == 3)?.id,
    // };
    // await dispatch(updateSprint(payload));
    // await dispatch(
    //   getSprintsByPhase({
    //     projectId,
    //   })
    // );

    // onClose();

    // await dispatch(
    //   getGoalsBySprint({ projectId, sprintId: completeSprintModal.sprintId })
    // );

    // dispatch(
    //   updateDashboard({
    //     key: "alertPopupModal",
    //     value: {
    //       visible: true,
    //       data: {
    //         title: `${completeSprintModal.sprint.name} completed`,
    //         subtitle: `Completed Sprint - ${completeSprintModal.sprint.name}`,
    //         description: "",
    //       },
    //     },
    //   })
    // );

    // dispatch(resetSchedule(["completeSprintModal"]));
  };

  const pendingInprogress = +inProgressCount;

  const pendingTodo = +toDoCount;

  const totalPending = pendingInprogress + pendingTodo;

  return (
    <CustomModal
      visible={visible}
      width="409px"
      onCancel={() => onClose()}
      body={
        <div className="p-4 py-3">
          <h3 className="out-500-14 text-black">
            Complete the sprint: {completeSprintModal.sprint?.name}
          </h3>
          {totalPending > 0 && (
            <div>
              <p className="out-300-14 text-gray-500 mt-1">
                Move your pending tasks to a new sprint or existing sprint.
              </p>
              <h3 className="out-500-14 mt-4 text-black">Pending tasks</h3>
              {pendingTodo > 0 && (
                <div className="mt-4">
                  <div className="flex items-center">
                    <div className="w-[139px]">
                      <StatusBadge statusType={1} />
                    </div>
                    <span className="out-300-14 text-black">
                      : {pendingTodo} tasks
                    </span>
                  </div>
                </div>
              )}
              {pendingInprogress > 0 && (
                <div className="mt-4">
                  <div className="flex items-center">
                    <div className="w-[139px]">
                      <StatusBadge statusType={2} />
                    </div>
                    <span className="out-300-14 text-black">
                      : {pendingInprogress} tasks
                    </span>
                  </div>
                </div>
              )}

              <p className="out-300-14 text-gray-500 mt-4">
                Please select where all the pending tasks should be moved:
              </p>

              <div className="mt-4">
                <CustomSelect
                  placeholder="Select Sprint"
                  options={sprints
                    ?.filter(
                      (s) =>
                        s.status < 3 && +s.id != +completeSprintModal.sprintId
                    )
                    ?.map((s) => {
                      return {
                        id: s.id,
                        value: s.id,
                        label: s.name,
                        render: () => (
                          <span className="text-black out-300-14 ml-2">
                            {s.name}
                          </span>
                        ),
                      };
                    })}
                  customDropDownRender={(menu) => {
                    return (
                      <div>
                        <div
                          className="flex items-center px-4 pt-[8px] pb-2.5"
                          onClick={async () => {
                            {
                              await dispatch(
                                updateSchedule({
                                  key: "editSprintModal",
                                  value: {
                                    visible: true,
                                    mode: "new",
                                    phaseId: activePhase?.projectPhaseId || "",
                                  },
                                })
                              );
                            }
                          }}
                        >
                          {" "}
                          <PlusIcon className="text-black" />{" "}
                          <span className="text-black out-300-14 ml-2">
                            Add new sprint
                          </span>{" "}
                        </div>
                        <div className="  hidden-scrollbar ">{menu}</div>
                      </div>
                    );
                  }}
                  onChange={(e) => setValue(e)}
                />
              </div>
            </div>
          )}

          <div className="flex mt-4 items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Complete Sprint"
              height="30px"
              onClick={handleCompleteSprint}
            />
          </div>
        </div>
      }
    />
  );
};

export default CompleteSprintModal;
