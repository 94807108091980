import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import InfoByFrosty from "../../../Core/CommonV2/InfoByFrosty";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { DatePicker } from "antd";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { CalendarIcon } from "../../../Core/svgV2/CalendarIcon";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { client } from "../../../Core/utils/axiosClient";
import {
  getGoalsBySprint,
  getReleaseVersions,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";

const ReleaseModal = ({ visible, onClose, version }) => {
  const { goals } = useAppSelector((state) => state.schedule);
  const dispatch = useAppDispatch();
  const [releaseDate, setReleaseDate] = useState(null);
  const { projectId, parentNoteId } = useParams();

  const onSubmit = async () => {
    try {
      const payload = {
        releaseDate,
        versionId: version.id,
        releaseStatus: 1,
      };
      await client.put("/schedule/release-version", {
        ...payload,
      });
      dispatch(getReleaseVersions({ projectId }));
      onClose();
    } catch (e) {
      console.log("Something went wrong while release the version");
    }
  };
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">
              Release {version?.versionName}
            </p>

            <div className="flex items-center gap-x-2.5 ">
              <InfoByFrosty
                title="Label"
                content="Add labels to your tasks, bugs or events to help organize your boards. All the labels will be visible in every project of this workspace."
              />
              <button onClick={() => onClose()}>
                <CrossIcon className="text-gray-700" />
              </button>
            </div>
          </div>
          {!parentNoteId && (
            <p className="out-300-14 text-black mt-5">
              {goals.length} Tasks will be released in this version
            </p>
          )}
          <div className=" mt-4  w-full ">
            <DatePicker
              className="w-full  bg-transparent rounded-[4px] border-gray-200"
              format="DD MMM YYYY"
              placeholder="Select Release Date"
              suffixIcon={<CalendarIcon className="text-gray-500" />}
              onChange={(e) => setReleaseDate(e)}
            />
          </div>
          <div className="flex items-center mt-4 justify-end gap-x-2">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500 out-500-14"
              height="30px"
              onClick={onClose}
            />
            <CustomButton
              text="Release"
              height="30px"
              onClick={() => onSubmit()}
            />
          </div>
        </div>
      }
    />
  );
};

export default ReleaseModal;
