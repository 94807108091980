/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { Dropdown, Tooltip, MenuProps } from "antd";
import { CommentIcon, CommentWithRedDotIcon } from "../../Core/svgV2/Comments";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { ShareAccessIcon } from "../../Core/svgV2/UserIcon";
import { ShareInMessageIcon } from "../../Core/svgV2/ShareFile";
import { EditIcon } from "../../Core/svgV2/EditIcon";
import { CopyIcon } from "../../Core/svgV2/CopyIcon";
import { DownloadIcon } from "../../Core/svgV2/DownloadIcon";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { MoveToIcon } from "../../Core/svgV2/MoveToIcon";
import { useAppDispatch } from "../../Core/redux/hooks";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import { getPhaseName } from "../../Core/utils/getPhaseName";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import uniqid from "uniqid";
import { downloadFiles } from "../../Core/utils";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";
import { useSearchParams } from "react-router-dom";
import { storeDataConsumed } from "../../Core/utils/dcfUtils";

type Props = {
  file: any;
};

const FileContainer = ({ file }: Props) => {
  const dispatch = useAppDispatch();

  const fileType = "pdf";

  const [searchParams] = useSearchParams();

  // console.log(file, file?.imageOutOfBound, "outofbound");

  const renderFileTag = (mimeType = "") => {
    console.log(mimeType, "mime");
    if (mimeType?.includes("pdf")) {
      return "pdf-file-icon.svg";
    } else if (
      mimeType?.includes("excel") ||
      mimeType?.includes("spreadsheet")
    ) {
      return "excel-file-icon.svg";
    } else if (mimeType?.includes("image")) {
      return "image-file-icon.svg";
    } else if (mimeType?.includes("video")) {
      return "video-file-icon.svg";
    } else {
      return "default-file-icon.svg";
    }
  };

  const IMG_FORMATS = ["gif", "png", "jpg", "jpeg", ""];
  const VIDEOS_FORMATS = [
    "mp4",
    "mp4",
    "mov",
    "avi",
    "flv",
    "wmv",
    "mkv",
    "webm",
  ];

  const getThumbnail = (file) => {
    if (file?.url?.includes("svg")) return file.url;
    if (
      file?.url &&
      VIDEOS_FORMATS.some((format) => file.url.includes(format))
    ) {
      return "/images/thumbnails/video.svg";
    }

    return file.mimeType?.includes("image")
      ? file?.imageOutOfBound
        ? file?.url
        : `https://images.weserv.nl/?url=${file?.url}&h=78&q=70&output=webp`
      : "/images/icons/no-files.svg";
  };

  const groupItems = [
    {
      key: "1",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">View details</div>
      ),
      icon: <EllipseIcon className="text-gray-700" />,
    },
    { key: "2", type: "divider" },
    {
      key: "3",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Open comments</div>
      ),
      icon: <CommentIcon className="text-gray-700" width="16" height="16" />,
    },
    {
      key: "4",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Share
        </div>
      ),

      icon: (
        <ShareAccessIcon
          className={`text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "5",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Move to
        </div>
      ),

      icon: (
        <MoveToIcon
          className={`text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "6",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">
          Send in message
        </div>
      ),
      icon: <ShareInMessageIcon className="text-gray-700" />,
    },
    {
      key: "7",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Rename
        </div>
      ),

      icon: (
        <EditIcon
          className={`text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
    {
      key: "8",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Make a copy</div>
      ),
      icon: <CopyIcon className="text-gray-700" />,
    },
    {
      key: "9",
      label: <div className="out-300-14 leading-5 text-gray-700">Download</div>,
      icon: <DownloadIcon className="text-gray-700" />,
    },
    { key: "10", type: "divider" },
    {
      key: "11",
      label: (
        <div
          className={`out-300-14 leading-5 text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        >
          Delete
        </div>
      ),

      icon: (
        <DeleteIcon
          className={`text-gray-700 ${
            file.canEdit ? "opacity-100" : "opacity-50"
          }`}
        />
      ),
    },
  ];

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = file.name;
    link.href = file.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    storeDataConsumed(file.fileSize);
    console.log("check for file size", file);
  };

  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "1":
        dispatch(
          updateVault({
            key: "viewDetailsDrawer",
            value: {
              visible: true,
              documentType: "file",
              document: {
                title: file.name,
                usersWithAccess: file.usersWithAccess,
                createdAt: file.createdAt,
                filesCount: file.filesCount,
                docId: file.id,
                createdById: file.createdById,
                fileSize: file.fileSize,
                mimeType: file.mimeType,
              },
            },
          })
        );
        break;
      case "3":
        dispatch(
          updateVault({
            key: "openCommentsModal",
            value: {
              visible: true,
              docType: "file",
              name: file.name,
              docId: file.id,
              comments: [],
              users: file.usersWithAccess,
            },
          })
        );
        break;
      case "4":
        if (file.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "file",
                  file,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "shareAccessModal",
            value: {
              visible: true,
              docId: file.id,
              users: file.usersWithAccess,
              docType: "file",
              docDetails: {
                ...file,
              },
            },
          })
        );
        break;
      case "5":
        if (file.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "file",
                  file,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "moveToFolderModal",
            value: {
              visible: true,
              docId: file.id,
              docType: "file",
            },
          })
        );
        break;
      case "6":
        dispatch(
          updateVault({
            key: "shareInMessageModal",
            value: {
              type: "file",
              visible: true,
              url: "",
              file: [
                {
                  name: file.name,
                  url: file.url,
                  size: file.fileSize,
                  type: file.mimeType,
                  lastModified: uniqid(`${file.name}-`),
                  savedInVault: true,
                },
              ],
            },
          })
        );
        break;
      case "7":
        if (file.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "file",
                  file,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "renameModal",
            value: {
              visible: true,
              type: "file",
              id: file.id,
              currentName: file.name,
            },
          })
        );
        break;
      case "8":
        dispatch(
          updateVault({
            key: "copyModal",
            value: {
              visible: true,
              docType: "file",
              id: file.id,
            },
          })
        );
        break;
      case "9":
        onDownload();
        break;
      case "11":
        if (file.canEdit ? false : true) {
          return dispatch(
            updateVault({
              key: "requestAccessModal",
              value: {
                visible: true,
                data: {
                  type: "file",
                  file,
                },
              },
            })
          );
        }
        dispatch(
          updateVault({
            key: "deleteFileModal",
            value: {
              visible: true,
              id: file.id,
            },
          })
        );
        break;
      default:
        console.log("item others");
        break;
    }
    setDropdownOpen(false);
  };
  // console.log(file, "file mime");

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div
      data-file-id={file.id}
      className={`w-[173px] h-[182px] rounded-lg bg-white border border-gray-200 py-4 px-4 group relative cursor-pointer ${
        searchParams.get("sharedFileId") == file.id
          ? "shadow-[0px_0px_0px_2px_#D1E9FF]"
          : ""
      }`}
      onDoubleClick={() => {
        dispatch(
          updateConversations({
            key: "filePreview",
            value: {
              visible: true,
              currentIndex: 0,
              from: "library",
              docs: [file],
              createdAt: file.createdAt,
            },
          })
        );
      }}
    >
      <div className="flex justify-between">
        <div className="flex items-center gap-x-3">
          {file.phaseId && (
            <Tooltip
              className="text-white cursor-pointer"
              title={getPhaseName(file.phaseId)}
            >
              <div>
                <PhaseIcon phaseId={+file.phaseId} width="10px" height="10px" />
              </div>
            </Tooltip>
          )}
          <Tooltip
            className="text-white cursor-pointer"
            title="Open comments"
            placement="bottom"
            color="#0D3271"
          >
            <div
              className="mt-[-3px]"
              onClick={() => {
                dispatch(
                  updateVault({
                    key: "openCommentsModal",
                    value: {
                      visible: true,
                      docType: "file",
                      name: file.name,
                      docId: file.id,
                      comments: [],
                      users: file.usersWithAccess,
                    },
                  })
                );
              }}
            >
              {true ? <CommentWithRedDotIcon /> : <CommentIcon />}
            </div>
          </Tooltip>
          <Tooltip
            className="text-white cursor-pointer"
            title="Shared document"
            placement="bottom"
            color="#0D3271"
          >
            <div
              onClick={() =>
                dispatch(
                  updateVault({
                    key: "shareAccessModal",
                    value: {
                      visible: true,
                      docId: file.id,
                      users: file.usersWithAccess,
                      docType: "file",
                      docDetails: {
                        ...file,
                      },
                    },
                  })
                )
              }
            >
              <img src="/images/users.svg" alt="people with access" />
            </div>
          </Tooltip>
        </div>
        <div
          className={`absolute top-[10px] right-[12px] cursor-pointer group-hover:block ${
            dropdownOpen ? "block" : "hidden"
          }`}
        >
          <Dropdown
            onOpenChange={(e) => setDropdownOpen(e)}
            menu={{ items: groupItems, onClick }}
            trigger={["click"]}
            dropdownRender={(origin) => {
              return <div className="w-[250px]">{origin}</div>;
            }}
          >
            <DotsVerticalIcon className="text-black" />
          </Dropdown>
        </div>
      </div>

      <div className="mt-3 flex items-center justify-center relative">
        <div className="absolute left-[-6px] bottom-[-6px] z-[2]">
          <img
            src={`/images/v2/library/${renderFileTag(file.mimeType)}`}
            className="w-[15px] h-[15px]"
          />
        </div>
        <img
          role="button"
          // onClick={() => {
          //   dispatch(
          //     updateConversations({
          //       key: "filePreview",
          //       value: {
          //         visible: true,
          //         currentIndex: 0,
          //         from: "library",
          //         docs: [file],
          //         createdAt: file.createdAt,
          //       },
          //     })
          //   );
          // }}
          className="h-[78px] object-cover"
          src={getThumbnail(file)}
        />
      </div>
      <Tooltip title={file.name} placement="bottom">
        <p className="mt-3 whitespace-nowrap truncate out-500-14 leading-5 text-gray-700 text-center">
          {file.name}
        </p>
      </Tooltip>
    </div>
  );
};

export default FileContainer;
