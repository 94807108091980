import React from "react";
import Icon from "@ant-design/icons";

const BarLineChartSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.667 16.667v-5.834M10 16.667V8.333m-6.667 8.334v-3.334m7.84-9.143l4.306 1.615M9 4.5L4.334 8M17.55 5.366a1.25 1.25 0 11-1.768 1.768 1.25 1.25 0 011.768-1.768zm-13.334 2.5A1.25 1.25 0 112.45 9.634a1.25 1.25 0 011.768-1.768zm6.667-5a1.25 1.25 0 11-1.768 1.768 1.25 1.25 0 011.768-1.768z"
    ></path>
  </svg>
);

const BarLineChartIcon = (props) => {
  return <Icon component={BarLineChartSvg} {...props} />;
};

export { BarLineChartIcon };
