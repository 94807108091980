import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getError } from "../../utils/axiosClient";

export const getAllMembers = createAsyncThunk(
  "settings/getAllMembers",
  async (
    {
      projectId,
      userId,
    }: {
      projectId: number;
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/settings/get-all-members?projectId=${projectId}&userId=${userId}`
      );
      //   response.data.userId = userId;
      return response.data.result;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
export const RemoveMembers = createAsyncThunk(
  "settings/removeMembers",
  async (
    {
      projectId,
      userId,
    }: {
      projectId: number;
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.delete(
        `/settings/delete-member?projectId=${projectId}&userId=${userId}`
      );
      //   response.data.userId = userId;
      return response.data.result;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getAllRoles = createAsyncThunk(
  "settings/getAllRoles",
  async (params: { projectId: string }) => {
    try {
      const response = await client.get(`/permissions/role-list`, { params });
      //   response.data.userId = userId;
      return response.data.result;
    } catch (error) {
      //   return rejectWithValue(getError(error));
      return error;
    }
  }
);

export const getMetaCategories = createAsyncThunk(
  "settings/getMetaCategories",
  async (
    { metaId, search }: { metaId: number; search?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/settings/get-categories?metaId=${metaId}&search=${
          search === undefined ? "" : search
        }`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const addMetaCategory = createAsyncThunk(
  "settings/addMetaCategory",
  async (
    {
      metaId,
      name,
      color,
    }: {
      metaId: number;
      name?: string;
      color?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post(`/settings/add-category`, {
        metaId,
        name,
        color,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const deleteMetaCategory = createAsyncThunk(
  "settings/deleteMetaCategory",
  async (
    {
      metaId,
      categoryId,
    }: {
      metaId: number;
      categoryId?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.delete(
        `/settings/delete-category?metaId=${metaId}&categoryId=${categoryId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getNotificationActions = createAsyncThunk(
  "settings/getNotificationActions",
  async () => {
    try {
      const response = await client.get("/settings/notification-actions");
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getAlertSeverity = createAsyncThunk(
  "settings/getAlertSeverity",
  async ({ projectId }: { projectId: number }, { rejectWithValue }) => {
    try {
      const response = await client.get(
        `/settings/alert-severity?projectId=${projectId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(getError(err));
    }
  }
);

export const createAlert = createAsyncThunk(
  "settings/getAlert",
  async (
    {
      title,
      description,
      frequency,
      severity_id,
      sent_to,
      status_id,
      days,
    }: {
      title: string;
      description: string;
      frequency: number;
      severity_id: number;
      sent_to?: any[];
      days?: any[];
      status_id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.post("/notifications/create-alert", {
        title,
        description,
        frequency,
        severity_id,
        sent_to,
        status_id,
        days,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(getError(err));
    }
  }
);

export const getAllAutomation = createAsyncThunk(
  "settings/getAllAutomation",
  async (
    {
      projectId,
    }: {
      projectId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.get(
        `/settings/automations?projectId=${projectId}`
      );
      return response.data.result;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);

export const getDefaultNavigation = createAsyncThunk(
  "settings/getDefaultNavigation",
  async (
    {
      userId,
    }: {
      userId: string | number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await client.get("/settings/default-navigation", {
        params: {
          userId,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(getError(error));
      //return error;
    }
  }
);
