import React from "react";
import Icon from "@ant-design/icons";

const RemoveFromEventIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      stroke="#D92D20"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.333 10.5h3.5M7 9.042H4.375c-.814 0-1.221 0-1.552.1-.746.226-1.33.81-1.556 1.556-.1.33-.1.738-.1 1.552m7.291-7.875a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
    ></path>
  </svg>
);

const RemoveFromEventIcon = (props) => (
  <Icon component={RemoveFromEventIconSvg} {...props} />
);

export default RemoveFromEventIcon;
