import { Pagination, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import CopyButton from "../../Core/CommonV2/CopyButton";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import CustomTable from "../../Core/CommonV2/CustomTable";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";

const MemberTable = () => {
  const dispatch = useAppDispatch();

  const { userList } = useAppSelector((state) => state.overview);

  const [limit, setLimit] = useState(1000);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  const [filteredUserList, setFilteredUserList] = useState([...userList]);
  const nullSafeCompare = (a, b) => {
    if (!a && !b) {
      return 0;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    return a.localeCompare(b);
  };
  useEffect(() => {
    setFilteredUserList([...userList]);
  }, [userList]);
  const columns = [
    {
      title: () => <div className="out-500-12 pl-1.5 text-gray-500 ">Name</div>,
      width: "30%",
      dataIndex: "firstname",
      defaultSortOrder: "descend",
      sorter: (a, b) => nullSafeCompare(a.firstname, b.firstname),
      render: (text, record) => (
        <div
          className="flex  items-center pl-1.5 "
          role="button"
          onClick={() =>
            dispatch(
              updateDashboard({
                key: "userDetailsDrawer",
                value: { visible: true, data: record },
              })
            )
          }
        >
          {/* <img
            src={record.profilePicture || "/images/Avatar.png"}
            className="h-8 w-8 rounded-full"
            alt={text}
          /> */}
          <CustomAvatar
            title={text === null ? "User" : text}
            size={32}
            src={record.profilePicture}
            style={{
              border: record.spoc === 1 ? "2px solid #165ABF" : "",
            }}
          />
          {record.spoc === 1 && (
            <div
              className="out-500-6 h-[12px] w-[32px] absolute rounded bottom-3 text-white bg-primary-600 flex justify-center items-center"
              style={{
                fontSize: "6.5px",
              }}
            >
              <p>SPOC</p>
            </div>
          )}
          <div className="max-w-[calc(100%-110px)]">
            <div className="out-500-12 capitalize  whitespace-nowrap  pl-4 group-hover:underline">
              {typeof text === undefined || text === null
                ? "User"
                : `${text} ${record.lastname}`}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex items-center">
          <div className="out-500-12 text-gray-500  ">Email address</div>
        </div>
      ),
      //width: '12.85%',
      dataIndex: "email",
      defaultSortOrder: "descend",
      sorter: (a, b) => nullSafeCompare(a.email, b.email),
      render: (text) => (
        <div className="out-400-12 text-gray-500 flex justify-between items-center">
          {typeof text === undefined || text === null ? "N/A" : text}
          <div className="hidden group-hover:block   ">
            {/* <CopyOutlinedIcon className="text-gray-500 hidden " /> */}
            <CopyButton
              text={text}
              copyIcon={true}
              width="20px"
              height="20px"
              className="text-gray-500 "
            />
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex items-center">
          <div className="out-500-12 text-gray-500 ">Designation</div>
        </div>
      ),
      //width: '12.85%',
      dataIndex: "jobTitle",
      defaultSortOrder: "descend",
      sorter: (a, b) => nullSafeCompare(a.jobTitle, b.jobTitle),
      render: (text) => (
        <div className="out-400-12 text-gray-500">{text || "N/A"}</div>
      ),
    },

    {
      title: () => (
        <div className="flex">
          <div className="out-500-12 text-gray-500 ">Company</div>
          <Tooltip
            title={
              <div className="m-3">
                <p className="out-500-12 text-[#F9FBFE]">Project Role</p>
                <p className="out-400-12 text-[#F9FBFE] mt-1">
                  A project role defines the add, edit, delete accesses of a
                  member in this project
                </p>
              </div>
            }
            placement="bottom"
          ></Tooltip>
        </div>
      ),
      //width: '12.85%',
      dataIndex: "company",
      // defaultSortOrder: "descend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => <div className="out-400-12 text-gray-500">{text}</div>,
    },
  ];

  // const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
  //   let value = 0;

  //   switch (key) {
  //     case "1":
  //       value = 10;
  //       break;
  //     case "2":
  //       value = 50;
  //       break;
  //     case "3":
  //       value = 100;
  //       break;
  //     default:
  //       value = 10;
  //       break;
  //   }

  //   setLimit(value);
  // };

  // const menu = [
  //   {
  //     label: "10/Page",
  //     key: "1",
  //     //   value: 10,
  //   },
  //   {
  //     label: "50/Page",
  //     key: "2",
  //     //   value: 50,
  //   },
  //   {
  //     label: "100/Page",
  //     key: "3",
  //     //   value: 100,
  //   },
  // ];

  return (
    <CustomTable
      className="custom-footer custom-header"
      rowClassName={"group"}
      scroll="calc(100vh - 325px)"
      scrollX={null}
      columns={columns}
      data={[...filteredUserList]}
      dataCount={filteredUserList.length}
      current={currentPageNo}
      pageSize={limit}
      setPageSize={setLimit}
      setCurrent={(e) => {
        setCurrentPageNo(e);
      }}
      customFooter={() => {
        return (
          <div
            className={`flex justify-between border-t border-gray-200 items-center h-full ${"px-3 py-2"}`}
          >
            <div className="flex justify-start items-center gap-x-4">
              <div className="mon-500-12 text-gray-400">
                Showing {limit * (currentPageNo - 1) + 1}-
                {limit * currentPageNo > filteredUserList.length
                  ? filteredUserList.length
                  : limit * currentPageNo}{" "}
                of
                {" " + filteredUserList.length}
              </div>
            </div>
            <Pagination
              className="member-table-pagination"
              total={filteredUserList.length}
              current={currentPageNo}
              onChange={(e) => {
                setCurrentPageNo(e);
              }}
              pageSize={limit}
              size="small"
            />
          </div>
        );
      }}
    />
  );
};

export default MemberTable;
