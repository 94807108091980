import React from "react";
import Icon from "@ant-design/icons";

const SubtaskSvg = ({ size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 20 20"
  >
    <circle cx="10" cy="10" r="8.5" fill="#F9FBFE" stroke="#DDE5ED"></circle>
    <path
      fill="#F9FBFE"
      d="M12.692 14A1.31 1.31 0 0014 12.687c0-.726-.586-1.314-1.308-1.314-.723 0-1.309.588-1.309 1.314A1.31 1.31 0 0012.692 14zM7.308 8.627a1.31 1.31 0 001.309-1.314A1.31 1.31 0 007.308 6 1.31 1.31 0 006 7.313a1.31 1.31 0 001.308 1.314z"
    ></path>
    <path
      stroke="#667085"
      strokeLinecap="round"
      d="M7.308 8.627v3.26c0 .28 0 .42.055.527a.5.5 0 00.218.218c.107.055.247.055.527.055h3.275m-4.075-4.06a1.31 1.31 0 001.309-1.314A1.31 1.31 0 007.308 6 1.31 1.31 0 006 7.313a1.31 1.31 0 001.308 1.314zm4.075 4.06A1.31 1.31 0 0012.692 14 1.31 1.31 0 0014 12.687c0-.726-.586-1.314-1.308-1.314-.723 0-1.309.588-1.309 1.314z"
    ></path>
  </svg>
);

const SubTaskIcon = (props) => <Icon component={SubtaskSvg} {...props} />;

export default SubTaskIcon;
