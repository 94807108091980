import dayjs from "dayjs";
import React, { useEffect } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

import {
  addProjectPhase,
  getProjectPhases,
  updatePhase,
} from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router";

import { Controller, useForm } from "react-hook-form";
import CustomDatePicker from "../../../Core/CommonV2/CustomDatePicker";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useQueryClient } from "react-query";
import { useGetProjectDetails } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import { message } from "antd";

const CompletePhaseModal = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const { completePhaseModal } = useAppSelector((state) => state.dashboard);
  const { sprints, phases } = useAppSelector((state) => state.schedule);

  const { edit, phase, action } = completePhaseModal;
  const { startDate, endDate } = phase;
  const {
    data: projectDetailsData,
    isLoading,
    isError,
  } = useGetProjectDetails(projectId);
  const projectDetails = !isLoading ? projectDetailsData.projectDetails : [];
  const { control, handleSubmit, getValues } = useForm({
    values: {
      startDate: startDate ? dayjs(startDate) : null,
      endDate: endDate ? dayjs(endDate) : null,
    },
  });

  useEffect(() => {
    dispatch(
      updateDashboard({
        key: "completePhaseModal",
        value: {
          visible: completePhaseModal.visible,
          edit: false,
          action: completePhaseModal.action,
          phase: completePhaseModal.phase,
        },
      })
    );
  }, []);
  const handleClose = () => {
    dispatch(
      updateDashboard({
        key: "completePhaseModal",
        value: {
          visible: false,
          edit: edit,
          action: action,
          phase: phase,
        },
      })
    );
  };

  const handleUpdatePhase = async (payload, callback?: () => void) => {
    //if a phase has not been added while setting up phases, add the phase first

    if (phase.isNew) {
      const data = {
        phaseId: phase.phaseId,

        startDate: payload.startDate
          ? payload.startDate.format("YYYY-MM-DD")
          : "",
        endDate: payload.endDate ? payload.endDate.format("YYYY-MM-DD") : "",
        projectId,
      };

      await dispatch(addProjectPhase(data));
    } else {
      await dispatch(
        updatePhase({
          projectId,
          phaseId: phase.phaseId.toString(),
          projectPhaseId: phase.projectPhaseId.toString(),
          ...payload,
        })
      );
      if (callback && typeof callback === "function") callback();
    }

    dispatch(getProjectPhases({ projectId }));
  };

  const onSubmit = async (data) => {
    //if there are changes in the date
    if (completePhaseModal.edit && startDate && endDate) {
      //updating the phase
      await handleUpdatePhase({
        startDate: data.startDate,
        endDate: data.endDate,
      });
      //closing the modal
    } else if (completePhaseModal.action === "end") {
      const activeSprint = sprints.find((s) => s.status === 2);
      const onGoingPhases = phases.filter(({ isOngoing }) => isOngoing === 1);

      // if (activeSprint) {
      if (activeSprint && onGoingPhases && onGoingPhases.length <= 1) {
        message.error({
          content: (
            <span className="out-400-14 text-gray-900 cursor-pointer">
              Active sprint found. Please end the sprint first.
            </span>
          ),
          className: "custom-message",
          style: {
            borderRadius: "10px",
          },
        });
      } else {
        //updating the phase
        await handleUpdatePhase({
          isOngoing: 0,
          isCompleted: 1,
          phaseProgress: 100,
        });

        //opening the alert modal for phase updated.
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: `${phase.phases} phase ended`,
                subtitle: `Duration - `,
                description: `${dayjs(phase.endDate).diff(
                  phase.startDate,
                  "days"
                )} days`,
              },
            },
          })
        );
      }
    } else if (phase.isNew) {
      //add cta
      //adding phaseprogresss 1 because we want the progress to be one percent as sson as we start
      //todo apply a better logic
      await handleUpdatePhase({
        isOngoing: 1,
        isCompleted: 0,
        phaseProgress: 1,
      });

      //  dispatch(
      //    updateDashboard({
      //      key: "alertPopupModal",
      //      value: {
      //        visible: true,
      //        data: {
      //          title: `${phase.phases} started`,
      //          subTitle: `Duration`,
      //          description: `${dayjs(phase.endDate).diff(
      //            phase.startDate,
      //            "days"
      //          )} days`,
      //        },
      //      },
      //    })
      //  );

      handleClose();
    } else if (!startDate && !endDate) {
      await handleUpdatePhase({
        isOngoing: 1,
        isCompleted: 0,
        phaseProgress: 1,

        startDate: data.startDate,
        endDate: data.endDate,
      });

      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `${phase.phases} phase started`,
              subtitle: `Duration - `,
              description: `${dayjs(data.endDate).diff(
                data.startDate,
                "days"
              )} days`,
            },
          },
        })
      );
    } else {
      //start cta

      await handleUpdatePhase({
        isOngoing: 1,
        isCompleted: 0,
        phaseProgress: 1,
      });

      dispatch(
        updateDashboard({
          key: "alertPopupModal",
          value: {
            visible: true,
            data: {
              title: `${phase.phases} phase started`,
              subtitle: "Duration - ",
              description: `${dayjs(phase.endDate).diff(
                phase.startDate,
                "days"
              )} days`,
            },
          },
        })
      );
    }
    queryClient.invalidateQueries(["projectDetails", projectId]);
    queryClient.invalidateQueries(["projectPhases", projectId]);
    handleClose();
  };

  return (
    <CustomModal
      visible={completePhaseModal.visible}
      width="352px"
      onCancel={handleClose}
      body={
        <div className="h-[314px] bg-white rounded-xl flex flex-col px-4  py-3 gap-y-4">
          <div className="h-5 out-500-14">
            {completePhaseModal.phase.phases}
          </div>

          <div className="h-9 flex out-300-12 text-gray-500 ">
            You are about to {completePhaseModal.action} this phase . Make sure
            the dates are correct before proceeding.
          </div>

          <div className="h-[30px]  flex  items-center justify-end">
            {
              <Controller
                control={control}
                name="startDate"
                rules={{
                  required: "Please enter a start date before submitting",
                }}
                render={({ field, formState }) => (
                  <CustomDatePicker
                    {...field}
                    placeholder="Select date"
                    className="w-full h-[30px] bg-white  border-gray-200 rounded-[4px]  shadow-xs"
                    suffixIcon={<></>}
                    format="MMMM DD YYYY"
                    disabled={
                      completePhaseModal.action === "end" ? true : false
                    }
                    disabledDate={(date) => {
                      const endDate = getValues("endDate");
                      return (
                        dayjs(date).isBefore(projectDetails?.startDate) ||
                        dayjs(date).isAfter(projectDetails?.endDate) ||
                        dayjs(date).isAfter(dayjs(endDate))
                      );
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateDashboard({
                          key: "completePhaseModal",
                          value: {
                            visible: true,
                            edit: true,
                            action: action,
                            phase: phase,
                          },
                        })
                      );
                      field.onChange(e);
                    }}
                    formState={formState}
                  />
                )}
              />
            }
          </div>
          <div className="h-[30px] mt-3  flex  items-center justify-end">
            {
              <Controller
                control={control}
                name="endDate"
                rules={{
                  required: "Please enter an end date before submitting",
                }}
                render={({ field, formState }) => (
                  <CustomDatePicker
                    {...field}
                    placeholder="Select date"
                    className="w-full h-[30px] bg-white  border-gray-200 rounded-[4px]  "
                    suffixIcon={<></>}
                    format="MMMM DD YYYY"
                    disabledDate={(date) => {
                      const startDate = getValues("startDate");
                      return (
                        dayjs(date).isBefore(projectDetails?.startDate) ||
                        date.isAfter(projectDetails?.endDate) ||
                        dayjs(date).isBefore(dayjs(startDate))
                      );
                    }}
                    onChange={(e) => {
                      dispatch(
                        updateDashboard({
                          key: "completePhaseModal",
                          value: {
                            visible: true,
                            edit: true,
                            action: action,
                            phase: phase,
                          },
                        })
                      );
                      field.onChange(e);
                    }}
                    formState={formState}
                  />
                )}
              />
            }
          </div>

          <div className="h-[52px] flex flex-col items-start justify-between gap-y-3  ">
            <div className="h-[20px] out-500-14 flex items-center  ">
              Duration{" "}
            </div>
            <div className="h-[20px] text-gray-900 out-300-14 flex items-center  ">
              {getValues("startDate") && getValues("endDate")
                ? `${dayjs(getValues("endDate")).diff(
                    getValues("startDate"),
                    "day"
                  )}
              days`
                : "N/A"}
            </div>
          </div>

          <div className="flex items-center justify-end gap-x-3">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              onClick={handleClose}
            />

            <CustomButton
              text={
                !startDate && !endDate
                  ? completePhaseModal.action === "end"
                    ? "End Phase"
                    : phase.isNew
                    ? "Add Phase"
                    : "Start Phase"
                  : completePhaseModal.edit
                  ? "Save Changes"
                  : completePhaseModal.action === "end"
                  ? "End Phase"
                  : phase.isNew
                  ? "Add Phase"
                  : "Start Phase"
              }
              height="30px"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      }
    />
  );
};

export default CompletePhaseModal;
