import React from "react";
import Icon from "@ant-design/icons";

const OpenInWindowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 6V2m0 0h-4m4 0L8 8M6.667 2H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C2 3.52 2 4.08 2 5.2v5.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C3.52 14 4.08 14 5.2 14h5.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C14 12.48 14 11.92 14 10.8V9.333"
    ></path>
  </svg>
);

const OpenInWindowIcon = (props) => {
  return <Icon component={OpenInWindowSvg} {...props} />;
};

export { OpenInWindowIcon };
