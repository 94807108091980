import React, { useState } from "react";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { useGetProjectList } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import TickIcon from "../../../Core/svgV3/TickIcon";
import { client } from "../../../Core/utils/axiosClient";
import { getMyBoardTasks } from "../../../Core/redux/api/myBoardAPI";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { getTodoList } from "../../../Core/redux/api/scheduleAPI";
import { useParams } from "react-router-dom";

const EmptyState = () => {
  return (
    <div className="flex items-center mt-1 gap-x-3">
      <div className="w-6 h-6 rounded-full bg-gray-50" />
      <div className="flex-1 w-full h-4 rounded-full bg-gray-50" />
    </div>
  );
};

const MoveToProjectModal = ({ visible, onClose }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { selectedTasks } = useAppSelector((state) => state.myBoard);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const { data: projectList, isLoading: isLoadingProjectList } =
    useGetProjectList(user.id);

  const [search, setSearch] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);

  const handleSelect = (projectId) => {
    setSelectedProject(projectId);
  };

  const handleMove = async () => {
    const payload = {
      taskIds: selectedTasks,
      projectId: selectedProject,
      sprintId: null,
    };
    await client.post("/schedule/move-to-project", payload);
    await dispatch(updateSchedule({ key: "selectedTasks", value: [] }));
    dispatch(
      getTodoList({
        projectId: projectId,
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
      })
    );
    onClose();
  };

  return (
    <CustomModal
      visible={visible}
      width="320px"
      onCancel={() => onClose()}
      body={
        <div className="p-3">
          <div className="flex justify-between px-0.5">
            <p className="text-black out-500-14">
              Move selected tasks to one of your projects
            </p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>

          <CustomInputBox
            className="mt-5"
            placeholder="Search project"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            autoFocus
          />

          {isLoadingProjectList ? (
            <div className="mt-3">
              <EmptyState />
              <EmptyState />
              <EmptyState />
              <EmptyState />
              <EmptyState />
            </div>
          ) : (
            <div className="h-[200px] overflow-y-scroll mt-1.5">
              {projectList
                ?.filter((item) =>
                  item.title.toLowerCase().includes(search.toLowerCase())
                )
                ?.map((project) => (
                  <div
                    role="button"
                    className="flex items-center justify-between py-2 px-3.5"
                    key={project.id}
                    onClick={() => handleSelect(project.id)}
                  >
                    <div className="flex items-center gap-x-2">
                      <CustomAvatar
                        src={project.projectImage}
                        size={24}
                        title={project.title}
                      />

                      <p className="text-gray-900 capitalize out-300-12">
                        {project.title}
                      </p>
                    </div>

                    {selectedProject === project.id ? <TickIcon /> : null}
                  </div>
                ))}
            </div>
          )}

          <div className="z-10 flex items-center justify-end w-full pt-3">
            <CustomButton
              text="Cancel"
              className="text-gray-500 bg-transparent"
              height="30px"
              width="63px"
              onClick={onClose}
            />
            <CustomButton
              text="Select"
              height="30px"
              width="58px"
              className="bg-transparent text-primary-600"
              onClick={handleMove}
            />
          </div>
        </div>
      }
    />
  );
};

export default MoveToProjectModal;
