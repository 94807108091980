import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch } from "../../../Core/redux/hooks";

import { useParams, useSearchParams } from "react-router-dom";
import {
  getGoalsBySprint,
  getReleaseVersions,
  updateGoal,
} from "../../../Core/redux/api/scheduleAPI";
import { client } from "../../../Core/utils/axiosClient";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";

const RemoveFromVersionModal = ({
  visible,
  onClose,
  task,
  isParent = false,
}) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRemove = async () => {
    if (isParent) {
      await client.put("/schedule/release-version", {
        newChildNotes: JSON.stringify(task.newChildNotes),
        versionId: task.parentNoteId,
      });
      dispatch(getReleaseVersions({ projectId }));
    } else {
      await dispatch(
        updateGoal({
          releaseVersionId: null,
          goalId: task.id,
        })
      );
      await dispatch(updateSchedule({ key: "isGoalEdited", value: true }));
      dispatch(
        getGoalsBySprint({
          projectId,
          filters: { releaseVersionId: searchParams.get("noteId") },
          module: "sprints",
        })
      );
    }

    onClose();
  };
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Remove from Version</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to remove this from version? <br /> This
            action cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Remove"
              height="30px"
              width="101px"
              className="bg-error-700 text-white"
              onClick={handleRemove}
            />
          </div>
        </div>
      }
    />
  );
};

export default RemoveFromVersionModal;
