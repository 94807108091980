import React, { useEffect } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getProjectDetatis } from "../redux/api/projectAPI";
import { getWorkspaces } from "../redux/api/dashboardAPI";
import { getTenantMembers } from "../redux/api/tenantAPI";

type CustomProps = {
  children: React.ReactNode;
  page: string;
  sidebar?: boolean;
  title: React.ReactNode;
  // any props that come into the component
};

const CustomLayout = ({ children, page, title }: CustomProps) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const { currentTenantId } = useAppSelector((state) => state.tenant);

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectDetatis({ projectId }));
    }
  }, [projectId]);
  //TODO
  useEffect(() => {
    dispatch(getWorkspaces());
    dispatch(getTenantMembers({}));
  }, [currentTenantId]);
  return (
    <Layout hasSider>
      <SideBar page={page} />
      <Layout>
        <Header title={title} />
        <Layout.Content className=" max-h-[calc(100vh-46px)] min-h-[calc(100vh-46px)]">
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
