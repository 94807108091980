import React from "react";
import Icon from "@ant-design/icons";

const CalendarRightIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.416 12.833l2.917-2.916L13.417 7"
    ></path>
    <path stroke="#667085" strokeLinecap="round" d="M3 9.916h9.5"></path>
  </svg>
);

const CalendarRightIcon = (props) => (
  <Icon component={CalendarRightIconSvg} {...props} />
);

export { CalendarRightIcon };
