import React from "react";
import Icon from "@ant-design/icons";

const UploadSucessSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <rect width="15" height="15" x="0.5" y="0.5" fill="#165ABF" rx="7.5"></rect>
    <path
      stroke="#F9FBFE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.667"
      d="M11.333 5.5L6.75 10.083 4.667 8"
    ></path>
    <rect
      width="15"
      height="15"
      x="0.5"
      y="0.5"
      stroke="#165ABF"
      rx="7.5"
    ></rect>
  </svg>
);

export const UploadFailIcon = ({
  className = "",
  height = "12",
  width = "12",
  style = {},
}) => (
  <span role="img" className={`anticon ${className}`} style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_13440_21707)">
        <path
          d="M8.00022 7.99975L6.00022 5.99975M6.00022 5.99975L4.00022 7.99975M6.00022 5.99975V10.4997M10.1952 9.19475C10.6829 8.92888 11.0681 8.50819 11.2902 7.99906C11.5122 7.48993 11.5583 6.92136 11.4213 6.38309C11.2843 5.84482 10.972 5.3675 10.5336 5.02647C10.0952 4.68544 9.55566 4.50011 9.00022 4.49975H8.37022C8.21888 3.91437 7.9368 3.37092 7.5452 2.91024C7.15359 2.44957 6.66265 2.08367 6.10927 1.84005C5.5559 1.59643 4.9545 1.48143 4.35028 1.50369C3.74607 1.52595 3.15476 1.6849 2.62081 1.96858C2.08687 2.25226 1.62418 2.6533 1.26754 3.14154C0.91089 3.62978 0.669569 4.19252 0.561714 4.78745C0.453859 5.38237 0.482277 5.99401 0.644831 6.57638C0.807385 7.15874 1.09985 7.69668 1.50022 8.14975"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13440_21707">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

const UploadSucessIcon = (props) => {
  return <Icon component={UploadSucessSvg} {...props} />;
};

export { UploadSucessIcon };
