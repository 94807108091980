import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";

const EditStatusModal = ({ visible, onClose }) => {
  const [status, setStatus] = useState(2);
  return (
    <CustomModal
      visible={visible}
      width="352px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Edit Status</p>

            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <div className="flex  mt-4 gap-x-3 w-full items-start">
            <CustomSelect
              placeholder="Select status"
              options={[
                {
                  id: "1",
                  value: 1,
                  render: (value) => (
                    <div className="flex items-center bg-gray-100  max-w-max whitespace-nowrap rounded-2xl">
                      <p className="mon-500-12 text-gray-700 px-2 uppercase py-0">
                        To Do
                      </p>
                    </div>
                  ),
                },

                {
                  id: "2",
                  value: 2,
                  render: (value) => (
                    <div className="flex items-center max-w-max whitespace-nowrap bg-primary-600 rounded-2xl">
                      <p className="mon-500-12 text-white px-2 uppercase py-0">
                        In progress
                      </p>
                    </div>
                  ),
                },
              ]}
              value={status}
              onChange={(e) => {
                setStatus(e);
              }}
            />
          </div>
          <div className="flex items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500 out-500-14"
              height="30px"
              onClick={onClose}
            />
            <CustomButton text="Save" height="30px" />
          </div>
        </div>
      }
    />
  );
};

export default EditStatusModal;
