import React from "react";
import Icon from "@ant-design/icons";

const ListViewSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    fill="none"
    viewBox="0 0 16 12"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.5 6h15M.5 1h15M.5 11h15"
    ></path>
  </svg>
);

const ListViewIcon = (props) => {
  return <Icon component={ListViewSvg} {...props} />;
};

export { ListViewIcon };
