import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";

const CompleteProjectSuccessModal = () => {
  const dispatch = useAppDispatch();
  return (
    <CustomModal
      visible={true}
      width="524px"
      onCancel={() => console.log("")}
      body={
        <div className="h-[532px] relative flex ">
          <div className=" bg-white  right-0 overflow-hidden rounded-[12px] ">
            <img
              className=" h-full"
              src="/images/v2/dashboard/SuccessState_Frosty_climbed_a_mountain.svg"
            />
            <div className="absolute top-[411px] left-[calc(50%-288px/2)]  h-5  out-500-14 flex flex-col items-center  gap-y-[15px]">
              <div className="text-gray-800">
                Wohoo! That feels like climbing Mount Everest.
              </div>
              <button
                className="text-gray-500"
                onClick={() =>
                  dispatch(
                    updateDashboard({
                      key: "completeProjectSuccessModal",
                      value: false,
                    })
                  )
                }
              >
                Close
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default CompleteProjectSuccessModal;
