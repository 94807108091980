import React from "react";
import Icon from "@ant-design/icons";

const LogoutSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      d="M4.8 7.5a.5.5 0 000 1v-1zm0 1h7.6v-1H4.8v1z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.8 5.6l2 2.4-2 2.4M6.4 3.2H3.2v9.6h3.2"
    ></path>
  </svg>
);

const LogoutIcon = (props) => {
  return <Icon component={LogoutSvg} {...props} />;
};

export { LogoutIcon };
