import React from "react";

import ProjectProgress from "./ProjectProgress";
import ActionCenter from "./ActionCenter";
import ProjectInfo from "./ProjectInfo";
import PhasesInfo from "./PhasesInfo";
import MemberTable from "./MemberTable";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../Core/redux/hooks";

import CustomButton from "../../Core/CommonV2/CustomButton";
import usePermission from "../../Core/hooks/usePermission";
import LabelInfo from "./LabelInfo";
import {
  useGetProjectDetails,
  useGetProjectList,
} from "../../Core/redux/ReactQueryHooksV3/useProjectAPI";

const Overview = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.userDetails);
  const { userList } = useAppSelector((state) => state.overview); //details of the current project
  const { phases } = useAppSelector((state) => state.schedule);
  const { hasPermission: CREATE_PROJECT } = usePermission("1");

  const { data: projectList, isLoading: isLoadingProjectList } =
    useGetProjectList(user.id);

  const {
    data: projectDetails,
    isLoading: isLoadingProjectDetails,
    error: projectDetailsError,
  } = useGetProjectDetails(projectId);

  return (
    <div>
      {!isLoadingProjectDetails && !isLoadingProjectList && (
        <div
          className={`flex flex-col w-full ${
            projectList?.length == 0 ? "h-[220%]" : ""
          } bg-white rounded-xl `}
        >
          {projectList?.length == 0 ? (
            <div className="flex flex-col w-full h-full justify-center items-center gap-y-6">
              {/* {!CREATE_PROJECT && (
              <div className="out-500-14 text-center text-black">
                No project created
              </div>
            )} */}

              <img
                src="/images/v2/dashboard/noproject.svg"
                className="w-[173px] h-[163px]"
              />
              {CREATE_PROJECT && +user.roleId !== 4 ? (
                <CustomButton
                  className="bg-primary-600 out-500-14    text-white"
                  onClick={() => {
                    navigate("/project-onboardingV2");
                  }}
                  text="Create New Project"
                  width="142px"
                  height="30px"
                />
              ) : (
                <div className="out-500-14 text-center text-black">
                  No project created
                </div>
              )}
            </div>
          ) : (
            <>
              {projectId ? (
                <>
                  <div>
                    <div className="flex  w-full h-[374px]">
                      <ProjectProgress
                        projectDetails={projectDetails}
                        projectList={projectList}
                      />
                      <ActionCenter />
                    </div>
                  </div>
                  <div className="h-[492px] border-b w-full flex  ">
                    <ProjectInfo
                      projectDetails={projectDetails}
                      projectList={projectList}
                    />
                    {/* <PhasesInfo phases={phases} /> */}
                    <LabelInfo projectDetails={projectDetails} />
                  </div>
                  <div className="h-[63px] flex items-center pl-6">
                    <div className="h-6 flex gap-x-2 out-500-16">
                      Team members
                      <div className="h-full bg-primary-50 text-primary-600 out-500-12 rounded-2xl flex items-center justify-center py-[2px] px-2">
                        {userList.length}{" "}
                        {userList.length === 1 ? "user" : "users"}
                      </div>
                    </div>
                  </div>
                  <MemberTable />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Overview;
