import { Popover } from "antd";
import React, { useState } from "react";
import { CrossIcon } from "../svgV2/CrossIcon";

const InfoByFrosty = ({ title, content }) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover
      trigger={"click"}
      content={
        <div
          className="w-[266px] bg-white border border-gray-100 rounded-lg "
          style={{
            boxShadow:
              "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
          }}
        >
          <div className="out-300-14 pt-[14px] pb-2.5 px-[14px] flex justify-between  border-b border-gray-200 text-gray-500">
            <div className="flex items-center">
              <img src="/images/v2/schedule/01 1.svg" />
              <div className="out-500-16 text-black ml-3.5">{title}</div>
            </div>
            <button onClick={() => setOpen(false)}>
              <CrossIcon className="text-black" />
            </button>
          </div>
          <div className="p-4 out-300-14 text-gray-500"> {content}</div>
        </div>
      }
      open={open}
      arrow={false}
    >
      <button onClick={() => setOpen(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          fill="none"
          viewBox="0 0 12 12"
        >
          <path
            stroke="#667085"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 8V6m0-2h.005M11 6A5 5 0 111 6a5 5 0 0110 0z"
          ></path>
        </svg>
      </button>
    </Popover>
  );
};

export default InfoByFrosty;
