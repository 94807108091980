import React, { useState } from "react";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";

import { Progress } from "antd";

import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import NewButton from "./Components/NewButton";
import ViewToggleButton from "./Components/ViewToggleButton";
import usePermission from "../../Core/hooks/usePermission";

const LibraryMenuBar = ({
  menuBarId,
  viewType,
  setViewType,
  onUploadClick,
}) => {
  const dispatch = useAppDispatch();
  const { search } = useAppSelector((state) => state.vault);

  const menuBarType = [
    {
      id: 1,
      title: "All",
      subtitle: "View everything from your library here.",
      showStorageOption: true,
      showViewOption: false,
      showFilterAndNewOption: false,
    },
    {
      id: 2,
      title: "All Projects",
      subtitle:
        "Access all your projects and the folders, files or links shared with you within each project.",
      showStorageOption: false,
      showViewOption: true,
      showFilterAndNewOption: true,
    },
    {
      id: 3,
      title: "My Library",
      subtitle:
        "Keep your personal files, folders, and links organised in one place with My Library.",
      showStorageOption: false,
      showViewOption: true,
      showFilterAndNewOption: true,
    },
  ];

  return (
    <div className="w-full h-[110px] border-b border-gray-200 bg-white px-6 pt-3 pb-2.5">
      <div className="flex justify-between">
        <div>
          <p className="out-500-14 leading-5 text-black cursor-pointer">
            {menuBarType.find(({ id }) => id === menuBarId)?.title}
          </p>
          <p className="mt-1.5 out-300-14 leading-5 text-gray-500">
            {menuBarType.find(({ id }) => id === menuBarId)?.subtitle}
          </p>
          <div className="mt-3">
            <CustomSearchBox
              placeholder="Search in library"
              className="w-[285px] h-[30px]"
              style={{ borderRadius: "8px" }}
              search={search}
              setSearch={(s) =>
                dispatch(updateVault({ key: "search", value: s }))
              }
            />
          </div>
        </div>
        <div className="flex flex-col items-end">
          {menuBarType.find(({ id }) => id === menuBarId)?.showViewOption ? (
            <ViewToggleButton viewType={viewType} setViewType={setViewType} />
          ) : null}
          {menuBarType.find(({ id }) => id === menuBarId)
            ?.showFilterAndNewOption ? (
            <div className="mt-5">
              <div className="flex items-center gap-x-3">
                {/* <div className="flex items-center gap-x-2 cursor-pointer">
                  <FilterIcon />
                  <p className="out-500-14 leading-5 text-gray-500">Filter</p>
                </div> */}

                <NewButton onUploadClick={onUploadClick} />
              </div>
            </div>
          ) : null}
          {menuBarType.find(({ id }) => id === menuBarId)?.showStorageOption ? (
            <div className="mt-[56px] flex items-center justify-center gap-x-3">
              <img src="/images/download-cloud.svg" className="w-5 h-5 mb-1" />
              <p className="out-500-14 leading-5 text-gray-500 pb-1.5">
                Storage
              </p>
              <div className="w-[280px]">
                <Progress
                  className="progress-bar-tiny"
                  percent={40}
                  strokeColor="#165ABF"
                  trailColor="#EDF3F9"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LibraryMenuBar;
