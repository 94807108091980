import React from "react";

const PhaseIcon = ({
  className = "",
  height = "20",
  width = "20",
  style = {},
  phaseId = 0,
  icon = false,
  reportPhases = false, // New prop with default value false
}) => {
  const getPhaseColor = (phaseId) => {
    switch (+phaseId) {
      case 1:
        return "#FFDB01";
      case 2:
        return "#F92B63";
      case 3:
        return "#6938EF";
      case 4:
        return "#33B7FF";
      case 5:
        return "#46C66C";
      default:
        return "#98A2B3"; // Default gray color
    }
  };

  if (icon) {
    const getPhaseSrc = (phaseId) => {
      switch (+phaseId) {
        case 1:
          return "/images/v2/schedule/ideation.svg";
        case 2:
          return "/images/v2/schedule/design.svg";
        case 3:
          return "/images/v2/schedule/development.svg";
        case 4:
          return "/images/v2/schedule/deployment.svg";
        case 5:
          return "/images/v2/schedule/maintenance.svg";
        default:
          return "/images/v2/schedule/code-circle-01.svg";
      }
    };
    const src = getPhaseSrc(phaseId);
    return <img src={src} width={width} height={height} alt="Phase icon" />;
  }

  // If reportPhases is true, return a different structure
  if (reportPhases) {
    const phaseColor = getPhaseColor(phaseId);
    return (
      <span
        role="img"
        className={`anticon ${className}`}
        style={{ display: "inline-block" }}
      >
        <svg
          width="14" // Total width to account for the 1px border around the 12px circle
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_272_10859)">
            {/* Outer circle with 1px border */}
            <circle
              cx="7" // Center of the SVG
              cy="7" // Center of the SVG
              r="6" // Radius 6 to make the inner circle 12px in diameter (12/2 = 6)
              fill={phaseColor} // Fill with phase color
              stroke="#f0f5fa" // 1px border around the inner circle
              strokeWidth="1"
            />
          </g>
          <defs>
            <clipPath id="clip0_272_10859">
              <rect width="14" height="14" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>
    );
  }

  // Default return when not using report phases
  return (
    <span
      role="img"
      className={`anticon ${className}`}
      style={{ color: getPhaseColor(phaseId), ...style }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_272_10859)">
          <circle cx="10" cy="10" r="9.5" fill="#F9FBFE" stroke="#F0F5FA" />
          <circle cx="10" cy="10" r="4" fill="currentColor" />
        </g>
        <defs>
          <clipPath id="clip0_272_10859">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export { PhaseIcon };
