import React from "react";
import Icon from "@ant-design/icons";

const TaskSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#F9FBFE"
      stroke="#DDE5ED"
      d="M18.5 10c0 4.67-4.204 8.5-8.773 8.5-4.53 0-8.227-3.791-8.227-8.5 0-4.67 4.204-8.5 8.773-8.5 4.53 0 8.227 3.791 8.227 8.5z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M5.217 10.231l3.193 2.864a.5.5 0 00.69-.02l5.748-5.811"
    ></path>
  </svg>
);

const Taskicon = (props) => {
  return <Icon component={TaskSvg} {...props} />;
};

export { Taskicon };
