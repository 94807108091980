const addToRecentProject = (projectId) => {
  const recentProjectIds = localStorage.getItem("recentProjectIds")
    ? JSON.parse(localStorage.getItem("recentProjectIds"))
    : [];

  //if recent projects has three project id's we remove the last id
  if (recentProjectIds.length === 3) {
    recentProjectIds.pop();
  } else if (recentProjectIds.length > 3) {
    //else if the recent projects has more than three projects then pop till two elemnts are left
    while (recentProjectIds.length === 2) {
      recentProjectIds.pop();
    }
  }
  //add the projectId parameter to recent projectIds
  recentProjectIds.push(projectId);
  localStorage.setItem("recentProjectIds", JSON.stringify(recentProjectIds));
};

const getRecentProjects = (projects) => {
  const projectList = [...projects];

  const pinnedProjects = [];
  const unpinnedProjects = [];
  projectList.map((p) => {
    if (p.isPinned == 1) {
      pinnedProjects.push(p);
    } else {
      unpinnedProjects.push(p);
    }
  });

  const recentProjectIds = localStorage.getItem("recentProjectIds")
    ? JSON.parse(localStorage.getItem("recentProjectIds"))
    : [];

  if (projects.length < 3) {
    if (pinnedProjects.length === 3) {
      return pinnedProjects;
    }

    return [
      ...pinnedProjects,
      ...unpinnedProjects.slice(
        0,
        unpinnedProjects.length - pinnedProjects.length
      ),
    ];
  } else {
    if (recentProjectIds.length === 3) {
      if (pinnedProjects.length === 3) {
        return pinnedProjects;
      }

      return [
        ...pinnedProjects,
        ...unpinnedProjects.filter((project) =>
          recentProjectIds.includes(project.id)
        ),
      ];
    } else if (recentProjectIds.length > 0 || recentProjectIds.length < 3) {
      const recentP = [];

      // We store the index of the found element so that we dont have to filter again while pushing remaining elements
      const indexesOfFoundElement = [];

      // Pushing the project that is already present in recentProjectIds
      recentP.push(
        ...unpinnedProjects.filter((project, idx) => {
          if (recentProjectIds.includes(+project.id)) {
            indexesOfFoundElement.push(idx);
            return true;
          }
        })
      );

      // Till now recentP length will be 1. Because we pushed one element earlier
      for (let i = 0; i <= unpinnedProjects.length; i++) {
        //pushing elements with indexes that are not present in indexesOfFoundElementArray
        if (!indexesOfFoundElement.includes(i)) {
          recentP.push(unpinnedProjects[i]);
        }

        if (recentP.length === 3) {
          //breaking out of the loop when there are three elements in recentP
          break;
        }
      }
      if (pinnedProjects.length === 3) {
        return pinnedProjects;
      }

      return [
        ...pinnedProjects,
        ...recentP.slice(0, 3 - pinnedProjects.length),
      ];
    } else {
      if (pinnedProjects.length === 3) {
        return pinnedProjects;
      } else if (pinnedProjects.length > 3) {
        const pinnedTrim = pinnedProjects.slice(0, 3);
        return pinnedTrim;
      }

      return [
        ...pinnedProjects,
        ...unpinnedProjects.slice(0, 3 - pinnedProjects.length),
      ];
    }
  }
};
export { addToRecentProject, getRecentProjects };
