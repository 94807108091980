import React, { useState } from "react";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import {
  createMyBoardTask,
  getMyBoardTasks,
} from "../../../Core/redux/api/myBoardAPI";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../Core/constants";
import Filters, { AppliedFilters } from "./Filters";
import { Popover, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { FilterIcon } from "../../../Core/svgV2/FilterIcon";

const MyBoardMenu = ({ search, setSearch }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { myBoardKanbanView, showSubtaskView } = useAppSelector(
    (state) => state.myBoard
  );

  const [filtersOpen, setFiltersOpen] = useState(false);

  const [isMute, setIsMute] = useState(false);

  return (
    <div className="px-6 py-3 bg-white border-b border-gray-200">
      <h1 className="text-black out-500-14">My Board</h1>
      <p className="mt-0.5 out-300-14 text-gray-500">
        This is your Personal Board – Create and manage tasks that are uniquely
        yours, separate from projects and collaborate seamlessly with others to
        complete tasks together.
      </p>
      <div className="flex items-center justify-between mt-3.5">
        <div className="flex items-center gap-x-4">
          <CustomSearchBox
            placeholder="Search in tasks"
            style={{
              width: "226px",
            }}
            search={search}
            setSearch={(e) => {
              setSearch(e.toLowerCase());
            }}
          />

          <Popover
            trigger={["click"]}
            content={
              <Filters
                onClose={() => {
                  setFiltersOpen(false);
                }}
              />
            }
            arrow={false}
            open={filtersOpen}
            placement="bottomLeft"
            onOpenChange={(val) => setFiltersOpen(val)}
          >
            <button
              className={`hover:bg-gray-50 h-[30px] w-[30px] flex justify-center items-center ${
                filtersOpen && "bg-gray-50"
              }`}
            >
              <FilterIcon className="text-gray-500" />
            </button>
          </Popover>
          <AppliedFilters style={{ marginRight: "8px" }} />
        </div>

        <div className="flex items-center gap-x-2">
          {myBoardKanbanView && (
            <div className="flex gap-x-1.5">
              <p>Show Subtasks</p>
              <Switch
                checked={showSubtaskView}
                size={"small"}
                onChange={() =>
                  dispatch(
                    updateMyBoard({
                      key: "showSubtaskView",
                      value: !showSubtaskView,
                    })
                  )
                }
                className={`${
                  showSubtaskView ? "afterSwitch" : "beforeSwitch"
                }`}
              />
            </div>
          )}
          <button
            className="py-[5px] px-[10px] border border-gray-200 bg-white rounded"
            onClick={() => {
              dispatch(
                updateMyBoard({
                  key: "myBoardKanbanView",
                  value: !myBoardKanbanView,
                })
              );
            }}
          >
            <p className="text-gray-700 out-500-14">
              {myBoardKanbanView ? "List view" : "Kanban view"}
            </p>
          </button>

          <button
            className="py-[5px] px-[10px] border border-primary-600 bg-primary-600 rounded"
            onClick={() => {
              dispatch(updateMyBoard({ key: "newTask", value: {} }));
              navigate(`/my-board/task/new`);
            }}
          >
            <p className="text-white out-500-14">New Task</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyBoardMenu;
