import React from "react";
import Icon from "@ant-design/icons";

const MoveToSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_12167_11598)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.226 4.666a6.667 6.667 0 110 6.667M8 10.666L10.667 8m0 0L8.001 5.333M10.667 8H1.334"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_12167_11598">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

const MoveToIcon = (props) => {
  return <Icon component={MoveToSvg} {...props} />;
};

export { MoveToIcon };
