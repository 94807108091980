import React, { useState, useEffect } from "react";

const NoInternetConnection = () => {
  const [isOnline, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return isOnline ? (
    <></>
  ) : (
    <div className="fixed bottom-5 left-5 z-[999999] text-white out-500-16 bg-red-400 w-max px-4 py-2 rounded ">
      No Internet Connection. Please try again later.
    </div>
  );
};

export default NoInternetConnection;
