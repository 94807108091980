import React from "react";
import Icon from "@ant-design/icons";

const CommentIcon = ({ className = "", width = "12", height = "12" }) => (
  <span className={className} role="image">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 12"
    >
      <g clipPath="url(#clip0_12167_66847)">
        <path
          fill="#344054"
          d="M10.3 10.8a.5.5 0 001 0h-1zm-1.214.5a.5.5 0 000-1v1zM10.3 6v4.8h1V6h-1zM6 11.3h3.086v-1H6v1z"
        ></path>
        <path stroke="#344054" d="M10.8 6A4.8 4.8 0 106 10.8"></path>
        <path
          fill="#344054"
          d="M4.8 6A.4.4 0 114 6a.4.4 0 01.8 0zM6.4 6a.4.4 0 11-.8 0 .4.4 0 01.8 0zM8 6a.4.4 0 11-.8 0A.4.4 0 018 6z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_12167_66847">
          <path fill="#fff" d="M0 0H12V12H0z"></path>
        </clipPath>
      </defs>
    </svg>
  </span>
);

const CommentWithRedDotSvg = () => (
  <div className="relative">
    <div className="absolute top-[3px] right-0 w-1 h-1 rounded-full bg-error-500"></div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <g clipPath="url(#clip0_12167_66847)">
        <path
          fill="#344054"
          d="M10.3 10.8a.5.5 0 001 0h-1zm-1.214.5a.5.5 0 000-1v1zM10.3 6v4.8h1V6h-1zM6 11.3h3.086v-1H6v1z"
        ></path>
        <path stroke="#344054" d="M10.8 6A4.8 4.8 0 106 10.8"></path>
        <path
          fill="#344054"
          d="M4.8 6A.4.4 0 114 6a.4.4 0 01.8 0zM6.4 6a.4.4 0 11-.8 0 .4.4 0 01.8 0zM8 6a.4.4 0 11-.8 0A.4.4 0 018 6z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_12167_66847">
          <path fill="#fff" d="M0 0H12V12H0z"></path>
        </clipPath>
      </defs>
    </svg>
  </div>
);
const CommentWithLeftTailSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 8a6 6 0 01-8.256 5.561c-.134-.054-.2-.08-.254-.093a.606.606 0 00-.146-.016c-.055 0-.115.01-.236.03l-2.371.395c-.249.042-.373.062-.463.024a.333.333 0 01-.175-.175c-.038-.09-.018-.214.024-.463l.395-2.371c.02-.12.03-.18.03-.236a.606.606 0 00-.016-.146c-.012-.054-.04-.12-.093-.254A6 6 0 1114 8z"
    ></path>
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5 8h.007M8 8h.007M11 8h.007M5.333 8a.333.333 0 11-.666 0 .333.333 0 01.666 0zm3 0a.333.333 0 11-.666 0 .333.333 0 01.666 0zm3 0a.333.333 0 11-.666 0 .333.333 0 01.666 0z"
    ></path>
  </svg>
);

const CommentWithRedDotIcon = (props) => {
  return <Icon component={CommentWithRedDotSvg} {...props} />;
};
const CommentWithLeftTailIcon = (props) => {
  return <Icon component={CommentWithLeftTailSvg} {...props} />;
};

export { CommentIcon, CommentWithRedDotIcon, CommentWithLeftTailIcon };
