import React, { useEffect, useState, useCallback } from "react";
import ProjectTitleContainer from "./ProjectTitleContainer";
import FolderContainer from "./FolderContainer";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import FileContainer from "./FileContainer";
import LinkContainer from "./LinkContainer";
import CustomTable from "../../Core/CommonV2/CustomTable";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { getProjectList } from "../../Core/redux/api/projectAPI";
import dayjs from "dayjs";
import uniqid from "uniqid";

import { updateVault } from "../../Core/redux/slices/vault.slice";
import { useNavigate, useParams } from "react-router-dom";
import { downloadFiles, filterArrayObjectsByValue } from "../../Core/utils";
import {
  getAllFiles,
  getAllFolders,
  getAllLinks,
} from "../../Core/redux/api/vaultAPI";
import DropTarget from "./Components/DropTarget";
import { client } from "../../Core/utils/axiosClient";
import DragSource from "./Components/DragSource";
import { DndProvider } from "react-dnd";
import { manager } from "../../Core/utils/dnd-utils";
import { Dropdown, MenuProps, Pagination, message } from "antd";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { CommentIcon } from "../../Core/svgV2/Comments";
import { ShareAccessIcon } from "../../Core/svgV2/UserIcon";
import { ShareInMessageIcon } from "../../Core/svgV2/ShareFile";
import { EditIcon } from "../../Core/svgV2/EditIcon";
import { CopyIcon } from "../../Core/svgV2/CopyIcon";
import { DownloadIcon } from "../../Core/svgV2/DownloadIcon";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import { MoveToIcon } from "../../Core/svgV2/MoveToIcon";
import copyTextToClipboard from "../../Core/utils/clipboard";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";

/// ViewType == 1 == gridview
/// ViewType == 2 == Listview

const ProjectView = ({ viewType }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const navigate = useNavigate();
  const { search, folders, files, links } = useAppSelector(
    (state) => state.vault
  );
  const { tenantDetails } = useAppSelector((state) => state.tenant);

  useEffect(() => {
    dispatch(getProjectList(user.id));
  }, []);
  const { projectList } = useAppSelector((state) => state.projects);

  const { projectId, "*": splat } = useParams();
  const getGlobalFilesFoldersLinks = (callback = null) => {
    const queryObject: {
      projectId?: string | null;
      folderId?: string;
      tenantId?: string;
    } = {
      projectId: null,
      tenantId: tenantDetails?.tenantId,
    };

    if (splat?.length > 0) {
      const LocationArray = location.pathname.split("/");
      const lastLocation = LocationArray[LocationArray.length - 1].split("-");
      const newId = lastLocation[lastLocation.length - 1];
      if (newId) {
        queryObject.folderId = newId;
      }
    }

    // if (isMyLibrary) {
    //   queryObject.folderId = "0";
    //   queryObject.projectId = null;
    // }

    dispatch(getAllFolders(queryObject)).then(() => {
      if (typeof callback === "function") callback("folders");
    });
    dispatch(getAllFiles(queryObject)).then(() => {
      if (typeof callback === "function") callback("files");
    });
    dispatch(getAllLinks(queryObject)).then(() => {
      if (typeof callback === "function") callback("links");
    });
  };
  useEffect(() => {
    getGlobalFilesFoldersLinks();
  }, []);

  const globalScopeResource = [...projectList, ...folders, ...files, ...links];

  const [limit, setLimit] = useState(100);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [showFolders, setShowFolders] = useState(true);
  const [showFiles, setShowFiles] = useState(true);
  const [showLinks, setShowLinks] = useState(true);
  const [currentDragSource, setCurrentDragSource] = useState({
    type: "",
    id: null,
  });
  const [currentDropDestinationId, setCurrentDropDestinationId] =
    useState(null);

  const handleFilter = useCallback(
    (documents) => {
      let filteredProjects = [...globalScopeResource];

      if (search) {
        const regex = new RegExp(search, "i");
        console.log(regex, "regex");
        filteredProjects = filteredProjects.filter((document) => {
          if (
            regex.test(document.id) ||
            regex.test(document.title) ||
            regex.test(document.fullName) ||
            regex.test(document.name) ||
            regex.test(
              document.usersWithAccess?.find((user) => user.isOwner == 1)
                .firstname
            ) ||
            regex.test(
              document.usersWithAccess?.find((user) => user.isOwner == 1)
                .lastname
            )
          ) {
            return true;
          }
        });
      }
      return filteredProjects;
    },

    [globalScopeResource, search]
  );

  const handleMoveItemToFolder = async ({
    itemId,
    docType,
    moveToFolderId,
  }) => {
    const payload = {
      itemId: itemId,
      itemType: docType,
      folderId: moveToFolderId,
      projectId,
    };

    const DocumentsAfterMove = () => {
      const query: { projectId: string; folderId?: string } = { projectId };

      if (splat && splat.length > 0) {
        const folderId = splat.split("-");
        query.folderId = folderId[folderId.length - 1];
      }

      return query;
    };

    await client.put(`/repository/move-item-to-folder`, payload);

    const queryObject = DocumentsAfterMove();

    if (docType === "folder") {
      await dispatch(getAllFolders(queryObject));
    } else if (docType === "file") {
      await dispatch(getAllFiles(queryObject));
    } else {
      await dispatch(getAllLinks(queryObject));
    }
  };

  //title , name ,foldername

  const VIDEOS_FORMATS = [
    "mp4",
    "mp4",
    "mov",
    "avi",
    "flv",
    "wmv",
    "mkv",
    "webm",
  ];

  const getThumbnail = (file) => {
    if (file?.url?.includes("svg")) return file.url;
    if (
      file?.url &&
      VIDEOS_FORMATS.some((format) => file.url.includes(format))
    ) {
      return "/images/thumbnails/video.svg";
    }

    return file.mimeType?.includes("image")
      ? `https://images.weserv.nl/?url=${file?.url}&h=78&q=70&output=webp`
      : "/images/icons/no-files.svg";
  };

  const [copy, setCopy] = useState(false);

  const handelDownload = async (record) => {
    const { data } = await client.get("/repository/all-files", {
      params: { folderId: record.id, projectId },
    });

    if (data.result && data.result.length > 0) {
      const fileUrls = data.result.map((d) => d.url);
      const fileSizes = data.result.map((d) => d.fileSize);

      downloadFiles(fileUrls, fileSizes);
    } else {
      message.error("Nothing to download!", 3);
    }
  };

  const onDownload = (record) => {
    const link = document.createElement("a");
    link.download = record.name;
    link.href = record.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getDocType = (record) => {
    let docType = null;
    if (record.folderName) {
      return (docType = "folder");
    } else if (record.mimeType) {
      return (docType = "file");
    } else if (record.url) {
      return (docType = "link");
    } else {
      return docType;
    }
  };
  const getMenuItems = (record) => {
    const docType = getDocType(record);
    if (docType == "folder") {
      return {
        items: [
          {
            key: "1",
            label: (
              <div className="leading-5 text-gray-700 out-300-14">
                View details
              </div>
            ),
            icon: <EllipseIcon className="text-gray-700" />,
          },
          { key: "2", type: "divider" },
          {
            key: "3",
            label: (
              <div className="leading-5 text-gray-700 out-300-14">
                Open comments
              </div>
            ),
            icon: (
              <CommentIcon className="text-gray-700" width="16" height="16" />
            ),
          },
          {
            key: "4",
            label: (
              <div
                className={`out-300-14 leading-5 text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              >
                Share
              </div>
            ),

            icon: (
              <ShareAccessIcon
                className={`text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              />
            ),
          },
          {
            key: "5",
            label: (
              <div className="leading-5 text-gray-700 out-300-14">
                Send in message
              </div>
            ),
            icon: (
              <ShareInMessageIcon
                className="text-gray-700 "
                width="16"
                height="16"
              />
            ),
          },
          {
            key: "6",
            label: (
              <div
                className={`out-300-14 leading-5 text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              >
                Rename
              </div>
            ),

            icon: (
              <EditIcon
                className={`text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              />
            ),
          },
          {
            key: "7",
            label: (
              <div className="leading-5 text-gray-700 out-300-14">
                Make a copy
              </div>
            ),
            icon: <CopyIcon className="text-gray-700" />,
          },
          {
            key: "8",
            label: (
              <div className="leading-5 text-gray-700 out-300-14">Download</div>
            ),
            icon: <DownloadIcon className="text-gray-700" />,
          },
          { key: "9", type: "divider" },
          {
            key: "10",
            label: (
              <div
                className={`out-300-14 leading-5 text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              >
                Delete
              </div>
            ),

            icon: (
              <DeleteIcon
                className={`text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              />
            ),
          },
        ],
        onClick: ({ key }) => {
          switch (key) {
            case "1":
              dispatch(
                updateVault({
                  key: "viewDetailsDrawer",
                  value: {
                    visible: true,
                    documentType: "folder",
                    document: {
                      title: record.folderName,
                      usersWithAccess: record.usersWithAccess,
                      createdAt: record.createdAt,
                      filesCount: record.filesCount,
                      docId: record.id,
                      createdById: record.createdById,
                      folderSize: record.folderSize,
                    },
                  },
                })
              );
              break;
            case "3":
              dispatch(
                updateVault({
                  key: "openCommentsModal",
                  value: {
                    visible: true,
                    docType: "folder",
                    name: record.folderName,
                    docId: record.id,
                    comments: [],
                    users: record.usersWithAccess,
                  },
                })
              );
              break;
            case "4":
              if (record.canEdit ? false : true) {
                return dispatch(
                  updateVault({
                    key: "requestAccessModal",
                    value: {
                      visible: true,
                      data: {
                        type: docType,
                        record,
                      },
                    },
                  })
                );
              }
              dispatch(
                updateVault({
                  key: "shareAccessModal",
                  value: {
                    visible: true,
                    docId: record.id,
                    users: record.usersWithAccess,
                    docType: "folder",
                    docDetails: {
                      ...record,
                    },
                  },
                })
              );
              break;
            case "5":
              dispatch(
                updateVault({
                  key: "shareInMessageModal",
                  value: {
                    type: "folder",
                    visible: true,
                    url:
                      window.location.href +
                      `/${record.folderName}-${record.id}?shared-folder-id=${record.id}`,
                    file: [],
                  },
                })
              );
              break;
            case "6":
              if (record.canEdit ? false : true) {
                return dispatch(
                  updateVault({
                    key: "requestAccessModal",
                    value: {
                      visible: true,
                      data: {
                        type: docType,
                        record,
                      },
                    },
                  })
                );
              }
              dispatch(
                updateVault({
                  key: "renameModal",
                  value: {
                    visible: true,
                    type: "folder",
                    id: record.id,
                    currentName: record.folderName,
                  },
                })
              );
              break;
            case "7":
              dispatch(
                updateVault({
                  key: "copyModal",
                  value: {
                    visible: true,
                    docType: "folder",
                    id: record.id,
                  },
                })
              );
              break;
            case "8":
              handelDownload(record);
              break;
            case "10":
              if (record.canEdit ? false : true) {
                return dispatch(
                  updateVault({
                    key: "requestAccessModal",
                    value: {
                      visible: true,
                      data: {
                        type: docType,
                        record,
                      },
                    },
                  })
                );
              }
              dispatch(
                updateVault({
                  key: "deleteFolderModal",
                  value: {
                    visible: true,
                    id: record.id,
                  },
                })
              );
              break;
            default:
              console.log("item others");
              break;
          }
        },
      };
    }
    if (docType == "link") {
      const groupItems = [
        {
          key: "1",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Edit
            </div>
          ),

          icon: (
            <EditIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "2",
          label: (
            <div className="leading-5 text-gray-700 out-300-14">
              {copy ? "Copied" : "Copy Link"}
            </div>
          ),
          icon: <CopyIcon className="text-gray-700" />,
        },
        {
          key: "3",
          label: (
            <div className="leading-5 text-gray-700 out-300-14">
              View details
            </div>
          ),
          icon: <EllipseIcon className="text-gray-700" />,
        },
        { key: "4", type: "divider" },
        {
          key: "5",
          label: (
            <div className="leading-5 text-gray-700 out-300-14">
              Open comments
            </div>
          ),
          icon: (
            <CommentIcon className="text-gray-700" width="16" height="16" />
          ),
        },
        {
          key: "6",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Share access
            </div>
          ),

          icon: (
            <ShareAccessIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "7",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Move to
            </div>
          ),

          icon: (
            <MoveToIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },

        { key: "8", type: "divider" },
        {
          key: "9",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Delete
            </div>
          ),
          disabled: record.canEdit ? false : true,
          icon: (
            <DeleteIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
      ];

      const onClick: MenuProps["onClick"] = ({ key }) => {
        switch (key) {
          case "1":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "editLinkModal",
                value: {
                  visible: true,
                  linkName: record.name,
                  url: record.url,
                  username: record.username,
                  password: record.password,
                  description: record.description,
                  linkId: record.id,
                },
              })
            );
            dispatch(
              updateVault({
                key: "newLinkModal",
                value: true,
              })
            );
            break;
          case "2":
            copyTextToClipboard(record.url);
            setCopy(true);
            break;
          case "3":
            dispatch(
              updateVault({
                key: "viewDetailsDrawer",
                value: {
                  visible: true,
                  document: {
                    title: record.name,
                    usersWithAccess: record.usersWithAccess,
                    createdAt: record.createdAt,
                    docId: record.id,
                    createdById: record.createdById,
                  },
                  documentType: "link",
                },
              })
            );
            break;
          case "5":
            dispatch(
              updateVault({
                key: "openCommentsModal",
                value: {
                  visible: true,
                  docType: "link",
                  name: record.name,
                  docId: record.id,
                  comments: [],
                  users: record.usersWithAccess,
                },
              })
            );
            break;
          case "6":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "shareAccessModal",
                value: {
                  visible: true,
                  docId: record.id,
                  users: record.usersWithAccess,
                  docType: "link",
                  docDetails: {
                    ...record,
                  },
                },
              })
            );
            break;
          case "7":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "moveToFolderModal",
                value: {
                  visible: true,
                  docId: record.id,
                  docType: "link",
                },
              })
            );
            break;
          case "9":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "deleteLinkModal",
                value: {
                  visible: true,
                  id: record.id,
                },
              })
            );
            break;
          default:
            console.log("item others");
            break;
        }
      };
      return { items: groupItems, onClick };
    }
    if (docType == "file") {
      const groupItems = [
        {
          key: "1",
          label: (
            <div className="leading-5 text-gray-700 out-300-14">
              View details
            </div>
          ),
          icon: <EllipseIcon className="text-gray-700" />,
        },
        { key: "2", type: "divider" },
        {
          key: "3",
          label: (
            <div className="leading-5 text-gray-700 out-300-14">
              Open comments
            </div>
          ),
          icon: (
            <CommentIcon className="text-gray-700" width="16" height="16" />
          ),
        },
        {
          key: "4",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Share Access
            </div>
          ),

          icon: (
            <ShareAccessIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "5",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Move to
            </div>
          ),

          icon: (
            <MoveToIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "6",
          label: (
            <div className="leading-5 text-gray-700 out-300-14">
              Send in message
            </div>
          ),
          icon: <ShareInMessageIcon className="text-gray-700" />,
        },
        {
          key: "7",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Rename
            </div>
          ),

          icon: (
            <EditIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "8",
          label: (
            <div className="leading-5 text-gray-700 out-300-14">
              Make a copy
            </div>
          ),
          icon: <CopyIcon className="text-gray-700" />,
        },
        {
          key: "9",
          label: (
            <div className="leading-5 text-gray-700 out-300-14">Download</div>
          ),
          icon: <DownloadIcon className="text-gray-700" />,
        },
        { key: "10", type: "divider" },
        {
          key: "11",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Delete
            </div>
          ),

          icon: (
            <DeleteIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
      ];
      const onClick: MenuProps["onClick"] = ({ key }) => {
        switch (key) {
          case "1":
            dispatch(
              updateVault({
                key: "viewDetailsDrawer",
                value: {
                  visible: true,
                  documentType: "file",
                  document: {
                    title: record.name,
                    usersWithAccess: record.usersWithAccess,
                    createdAt: record.createdAt,
                    filesCount: record.filesCount,
                    docId: record.id,
                    createdById: record.createdById,
                    fileSize: record.fileSize,
                    mimeType: record.mimeType,
                  },
                },
              })
            );
            break;
          case "3":
            dispatch(
              updateVault({
                key: "openCommentsModal",
                value: {
                  visible: true,
                  docType: "link",
                  name: record.name,
                  docId: record.id,
                  comments: [],
                  users: record.usersWithAccess,
                },
              })
            );
            break;
          case "4":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "shareAccessModal",
                value: {
                  visible: true,
                  docId: record.id,
                  users: record.usersWithAccess,
                  docType: "file",
                  docDetails: {
                    ...record,
                  },
                },
              })
            );
            break;
          case "5":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "moveToFolderModal",
                value: {
                  visible: true,
                  docId: record.id,
                  docType: "file",
                },
              })
            );
            break;
          case "6":
            dispatch(
              updateVault({
                key: "shareInMessageModal",
                value: {
                  type: "file",
                  visible: true,
                  url: "",
                  file: [
                    {
                      name: record.name,
                      url: record.url,
                      size: record.fileSize,
                      type: record.mimeType,
                      lastModified: uniqid(`${record.name}-`),
                      savedInVault: true,
                    },
                  ],
                },
              })
            );
            break;
          case "7":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "renameModal",
                value: {
                  visible: true,
                  type: "file",
                  id: record.id,
                  currentName: record.name,
                },
              })
            );
            break;
          case "8":
            dispatch(
              updateVault({
                key: "copyModal",
                value: {
                  visible: true,
                  docType: "file",
                  id: record.id,
                },
              })
            );
            break;
          case "9":
            onDownload(record);
            break;
          case "11":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "deleteFileModal",
                value: {
                  visible: true,
                  id: record.id,
                },
              })
            );
            break;
          default:
            console.log("item others");
            break;
        }
      };

      return { items: groupItems, onClick };
    }
  };

  const columns = [
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          Name
        </div>
      ),
      //width: "25%",
      dataIndex: "title",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <>
          <div className="flex items-center gap-x-3">
            {record?.projectImage >= 0 ? (
              <CustomAvatar
                size={20}
                title={text}
                whiteText
                src={record.projectImage}
              />
            ) : record?.folderName ? (
              <img
                src="/images/icons/folder-fill-vault.svg"
                className="w-5 h-5"
              />
            ) : record?.ownerFirstName ? (
              <img src={getThumbnail(record)} className="w-5 h-5" />
            ) : (
              <img src="/images/v2/library/link-icon.svg" className="w-5 h-5" />
            )}

            <p className="leading-5 text-gray-700 out-500-14">
              {text || record?.name || record?.title || record?.folderName}
            </p>
          </div>
        </>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          Created On
        </div>
      ),
      //width: "25%",
      dataIndex: "createdAt",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <div className="flex items-center pl-2 text-gray-500 out-300-14">
          {text ? dayjs(text).format("DD MMMM YYYY") : "-"}
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          Lead
        </div>
      ),
      //width: "25%",
      dataIndex: "lead",
      render: (text, record) => (
        <div className="flex items-center gap-x-3">
          <CustomAvatar
            size={20}
            title={
              record.fullName
                ? record.fullName
                : `${
                    record.usersWithAccess?.find((doc) => doc.isOwner == 1)
                      .firstname
                  } ${
                    record.usersWithAccess?.find((doc) => doc.isOwner == 1)
                      .lastname
                  }`
            }
            src={
              record.profilePicture
                ? record.profilePicture
                : record.usersWithAccess?.find((doc) => doc.isOwner == 1)
                    .profilePicture
            }
            color={record.profilePictureColorCode}
            whiteText
          />
          <p className="text-gray-500 out-300-14">
            {record.fullName
              ? record.fullName
              : `${
                  record.usersWithAccess?.find((doc) => doc.isOwner == 1)
                    .firstname
                } ${
                  record.usersWithAccess?.find((doc) => doc.isOwner == 1)
                    .lastname
                }`}
          </p>
        </div>
      ),
    },
    {
      width: "5%",
      render: (text, record) => (
        <button
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {(record.name || record.folderName || record.url) && (
            <Dropdown
              menu={getMenuItems(record)}
              trigger={["click"]}
              dropdownRender={(origin) => {
                return <div className="w-[250px]">{origin}</div>;
              }}
            >
              <DotsVerticalIcon className="text-black" />
            </Dropdown>
          )}
        </button>
      ),
    },
  ];

  if (viewType === 2) {
    return (
      <div className="w-full h-[calc(100vh-156px)]  bg-white overflow-y-scroll">
        <CustomTable
          // customPadding="p-3"
          className="cursor-pointer custom-header custom-table"
          // rowClassName="border-none"
          //scroll={projectList ? "calc(100vh-325px)" : "0px"}
          columns={columns}
          data={handleFilter(globalScopeResource)}
          dataCount={globalScopeResource.length}
          current={currentPageNo}
          pageSize={limit}
          setPageSize={setLimit}
          onRow={(record, recordIndex) => {
            return {
              onClick: () => {
                console.log(record, "record");
                if (record.folderName || record.name || record.url) {
                  if (record.mimeType) {
                    console.log(record, "recc");

                    dispatch(
                      updateConversations({
                        key: "filePreview",
                        value: {
                          visible: true,
                          currentIndex: 0,
                          from: "library",
                          docs: [record],
                          createdAt: record.createdAt,
                        },
                      })
                    );
                  } else if (record.url) {
                    window.open(record.url, "_blank", "noopener,noreferrer");
                    console.log(record, "recc");
                    // console.log(link.url, "vsvsvsvsdvsvs");
                  } else {
                    let path = location.pathname;

                    path = location.pathname + "/global";
                    const newPath = path + `/${record.folderName}-${record.id}`;
                    navigate(newPath);
                  }
                } else {
                  dispatch(
                    updateVault({
                      key: "currentSelectedProject",
                      value: record,
                    })
                  );
                  navigate(`/library/${record.id}`);
                }
              },
            };
          }}
          setCurrent={(e) => {
            setCurrentPageNo(e);
          }}
          customFooter={() => {
            if (globalScopeResource.length > limit) {
              return (
                <div
                  className={`flex justify-between border-t border-gray-200 items-center h-full ${"px-3 py-2"}`}
                >
                  <div className="flex items-center justify-start gap-x-4">
                    {/* <Dropdown menu={{ items: menu, onClick: handleMenuClick }}>
                    <div className="flex items-center bg-gray-50 justify-between border rounded-lg border-grey-100 px-1 py-1 min-w-[61px]">
                      <div className="text-gray-400 mon-500-12">{limit}</div>
                      <img src="/images/icons/chevron-down-gray.svg" alt="" />
                    </div>
                  </Dropdown> */}
                    <div className="text-gray-400 mon-500-12">
                      Showing {currentPageNo}-
                      {limit > globalScopeResource.length
                        ? globalScopeResource.length
                        : limit}{" "}
                      of {Math.ceil(globalScopeResource.length / limit)}
                    </div>
                  </div>
                  <Pagination
                    className="member-table-pagination"
                    total={globalScopeResource.length}
                    current={currentPageNo}
                    onChange={(e) => {
                      setCurrentPageNo(e);
                    }}
                    pageSize={limit}
                    size="small"
                  />
                </div>
              );
            }
          }}
        />
      </div>
    );
  }

  return (
    <DndProvider manager={manager}>
      <div className="h-[calc(100vh-156px)]  bg-white">
        <div
          id="library-tour-projects"
          className="w-full max-h-[calc(100vh-156px)] pb-4 px-6 bg-white overflow-y-scroll "
        >
          <div
            className="w-[85px] flex items-center gap-x-3 pt-4 cursor-pointer"
            onClick={() => setShowFolders(!showFolders)}
          >
            <ChevronIcon
              className={`text-gray-500 mt-0.5 ${
                showFolders ? "" : "-rotate-90"
              }`}
            />
            <p className="leading-5 text-gray-500 out-500-14">Folders</p>
          </div>

          <div className="flex flex-wrap mt-5 gap-x-4 gap-y-4">
            {filterArrayObjectsByValue(projectList, "title", search).map(
              (project) => (
                <ProjectTitleContainer key={project.id} project={project} />
              )
            )}
            {showFolders
              ? filterArrayObjectsByValue(folders, "folderName", search).map(
                  (folder) => (
                    <DropTarget
                      key={folder.id}
                      id={folder.id}
                      type="folder"
                      onDrop={({ type, id: folderId }) => {
                        const { id: itemId, type: itemType } =
                          currentDragSource;
                        handleMoveItemToFolder({
                          itemId,
                          docType: itemType,
                          moveToFolderId: folderId,
                        });
                        // handleMoveItemToFolder(itemId, itemType, folderId);
                      }}
                      onDropTargetChange={(id, { isOver, canDrop }) => {
                        setCurrentDropDestinationId(
                          isOver && canDrop ? id : null
                        );
                      }}
                    >
                      <FolderContainer folder={folder} isGlobal />
                    </DropTarget>
                  )
                )
              : null}
          </div>
          {
            <div className="flex flex-wrap mt-5 gap-x-4 gap-y-4">
              <div className="flex flex-col">
                <div className="mt-6">
                  <div
                    className="w-[70px] flex items-center gap-x-3 cursor-pointer"
                    onClick={() => setShowFiles(!showFiles)}
                  >
                    <ChevronIcon
                      className={`text-gray-500 mt-0.5 ${
                        showFiles ? "" : "-rotate-90"
                      }`}
                    />
                    <p className="leading-5 text-gray-500 out-500-14">Files</p>
                  </div>
                  {showFiles ? (
                    <div className="flex flex-wrap gap-4 mt-4">
                      {filterArrayObjectsByValue(files, "name", search).map(
                        (file) => (
                          <DragSource
                            key={file.id}
                            id={file.id}
                            type="file" // "file" do not change this
                            onDrag={(type, id) => {
                              setCurrentDragSource({
                                type,
                                id,
                              });
                            }}
                            disabled={false}
                          >
                            <FileContainer key={file.id} file={file} />
                          </DragSource>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="mt-6">
                  <div
                    className="w-[70px] flex items-center gap-x-3 cursor-pointer"
                    onClick={() => setShowLinks(!showLinks)}
                  >
                    <ChevronIcon
                      className={`text-gray-500 mt-0.5 ${
                        showLinks ? "" : "-rotate-90"
                      }`}
                    />
                    <p className="leading-5 text-gray-500 out-500-14">Links</p>
                  </div>
                  {showLinks ? (
                    <div className="flex flex-wrap gap-4 mt-4">
                      {filterArrayObjectsByValue(links, "name", search).map(
                        (link) => (
                          <DragSource
                            key={link.id}
                            id={link.id}
                            type="link" // "links" do not change this
                            onDrag={(type, id) => {
                              setCurrentDragSource({
                                type,
                                id,
                              });
                            }}
                            disabled={false}
                          >
                            <LinkContainer key={link.id} link={link} />
                          </DragSource>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </DndProvider>
  );
};

export default ProjectView;
