import { Progress } from "antd";
import dayjs from "dayjs";
import React from "react";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import { useAppSelector } from "../../../Core/redux/hooks";
import PhaseIcon from "../../../Core/svg/PhaseIcon";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { getPhaseColor, getPhaseName } from "../../../Core/utils/getPhaseName";

const Card1 = ({ width, height, className = "", title }) => {
  const {
    projectName,
    projectImage,
    projectColor,
    projectDuration,
    clientName,
    clientImage,
    clientLocation,
    clientColor,
  } = useAppSelector((state) => state.projectOnboarding);
  return (
    <div className="pt-6 pl-6">
      {/* project cards - section 1 */}
      <div className="flex">
        <div>
          <div className="w-[full]  flex flex-row justify-between mb-[21px]">
            <div className="out-500-16">My projects</div>
          </div>
          <div className="flex flex-row gap-x-[17px] mb-[16px]">
            <div
              className="rounded-xl flex-col px-6 py-4 bg-white h-[78px] w-[255px] items-center
    cursor-pointer"
            >
              <div className="flex items-center">
                <div className="mr-3">
                  {projectImage ? (
                    <img src={projectImage} className="w-6 h-6" />
                  ) : projectName ? (
                    <CustomAvatar
                      title={projectName}
                      size={24}
                      whiteText
                      fontSize="9px"
                      src={""}
                      color={projectColor}
                    />
                  ) : (
                    <div className="w-6 h-6 bg-gray-100 rounded-2xl"></div>
                  )}
                </div>

                {projectName ? (
                  <span className="out-500-14 text-gray-900 capitalize truncate">
                    {projectName}
                  </span>
                ) : (
                  <div className="w-[92px] h-3 bg-gray-300 rounded-[4px]"></div>
                )}
              </div>
              {/* <div className="mt-4 mont-500-10 text-gray-500">LEAD</div> */}

              <div className="mt-3 flex">
                {projectName ? (
                  <>
                    <Progress
                      showInfo={false}
                      strokeWidth={4}
                      strokeColor="#165ABF"
                      percent={0}
                      trailColor="#EDF3F9"
                      className="progress-bar-tiny"
                    />
                    <div className="mont-500-14 text-gray-700 ml-3">0%</div>
                  </>
                ) : (
                  <>
                    <div className="w-[168px] h-1 rounded-[4px] bg-primary-50">
                      <div className="w-[68px] h-1 rounded-[4px] bg-gray-400"></div>
                    </div>
                    <div className="w-[27px] h-3 rounded-[4px] bg-gray-200"></div>{" "}
                  </>
                )}
              </div>
            </div>
            <div
              className="rounded-xl flex-col px-6 py-4 bg-white h-[78px] w-[255px] items-center
    cursor-pointer"
            >
              <div className="flex">
                <div className="mr-3">
                  <div className="w-6 h-6 bg-gray-100 rounded-2xl"></div>
                </div>
                <span className="out-500-14 text-gray-900">
                  <div className="w-[92px] h-3 bg-gray-300 rounded-[4px]"></div>
                </span>
              </div>
              {/* <div className="mt-4 mont-500-10 text-gray-500">LEAD</div> */}

              <div className="mt-3 flex">
                <div className="w-[168px] h-1 rounded-[4px] bg-primary-50">
                  <div className="w-[68px] h-1 rounded-[4px] bg-gray-400"></div>
                </div>
                <div className="w-[27px] h-3 rounded-[4px] bg-gray-200"></div>{" "}
              </div>
            </div>
            <div
              className="rounded-xl flex-col px-6 py-4 bg-white h-[78px] w-[255px] items-center
    cursor-pointer"
            >
              <div className="flex">
                <div className="mr-3">
                  <div className="w-6 h-6 bg-gray-100 rounded-2xl"></div>
                </div>
                <span className="out-500-14 text-gray-900">
                  <div className="w-[92px] h-3 bg-gray-300 rounded-[4px]"></div>
                </span>
              </div>
              {/* <div className="mt-4 mont-500-10 text-gray-500">LEAD</div> */}

              <div className="mt-3 flex">
                <div className="w-[168px] h-1 rounded-[4px] bg-primary-50">
                  <div className="w-[68px] h-1 rounded-[4px] bg-gray-400"></div>
                </div>
                <div className="w-[27px] h-3 rounded-[4px] bg-gray-200"></div>{" "}
              </div>
            </div>
          </div>
          <div>{/* <Overview /> */}</div>
        </div>
      </div>
      {/* project card - section 1 */}
      {/* overview  */}
      <div className="flex flex-col w-[799px] rounded-xl bg-white gap-x-6">
        {/* project progress and action center  */}
        <div className="flex  w-[799px] h-[374px]">
          <div className="w-[325px]  border-r  border-b ">
            <div className="flex justify-between  w-[full]  ">
              <div className="flex gap-x-2  pt-6 pl-[26px] justify-between items-center">
                {projectName ? (
                  <div className=" h-[24px] mr-[8px] out-500-16 truncate max-w-[130px]">
                    {projectName}{" "}
                  </div>
                ) : (
                  <div className="w-[92px] h-3 rounded-[4px] bg-gray-300"></div>
                )}
                <ChevronIcon className="text-black" />
              </div>
              <div className="w-[101px] mt-6 mr-6 h-5 rounded-[4px] bg-gray-200"></div>
            </div>
            <div className=" flex  mx-auto items-center justify-center  mt-[24px] mb-[20px] ">
              <img src="/images/v2/project-onboarding/progress-bar.svg" />
            </div>
            <div className={` flex flex-col mx-auto px-2`}>
              <img src="/images/v2/project-onboarding/frosty-help.svg" />
            </div>
          </div>
          <div className="border-b    w-[474px] pt-6">
            <img src="/images/v2/project-onboarding/card2-right.svg"></img>
          </div>
        </div>
        {/* project info and phases info  */}
        <div className="flex w-[799px] h-[492px]">
          <div className="w-[325px] border-r flex flex-col gap-y-5 pt-6 pl-6 border-b ">
            <div className="h-6 w-[284px] flex justify-between items-center  ">
              <div className="w-[82px] h-5 rounded bg-gray-400"></div>
              <div className="w-[25px] h-5 rounded bg-gray-200"></div>
            </div>
            <div className="w-[127px] h-[52px] flex flex-col gap-y-2 rounded">
              <div className="w-[60px] h-4 rounded bg-gray-200"></div>

              <div className="w-[127px] h-[28px] flex items-center justify-between ">
                <div className="w-6 h-6 rounded-2xl bg-gray-200"></div>
                <div className="w-[95px] h-[11px] rounded-[5px] bg-gray-200"></div>
              </div>
            </div>
            <div className="w-[284px] h-[52px] flex flex-col gap-y-5  ">
              <div className="w-[60px] h-4 rounded-[5px] bg-gray-200"></div>

              <div className="w-full h-4 rounded-[5px] bg-gray-200"></div>
            </div>
            <div className="w-[284px] h-[52px] flex flex-col gap-y-5  ">
              <div className="w-[60px] h-4 rounded-[5px] bg-gray-200"></div>

              <div className="w-full h-4 rounded-[5px] bg-gray-200"></div>
            </div>
            <div className="w-[284px] h-[52px] flex flex-col  gap-y-2">
              <div className="w-[60px] h-4 rounded-[5px] bg-gray-200"></div>
              <div className="w-full h-[28px] flex items-center justify-between ">
                <div className="w-6 h-6 rounded-2xl bg-gray-200"></div>
                <div className="w-[252px] h-[11px] rounded-[5px] bg-gray-200"></div>
              </div>
            </div>{" "}
            <div className="w-[284px] h-[52px] flex flex-col gap-y-5  ">
              <div className="w-[60px] h-4 rounded-[5px] bg-gray-200"></div>

              <div className="w-full h-4 rounded-[5px] bg-gray-200"></div>
            </div>{" "}
            <div className="w-[284px] h-[52px] flex flex-col gap-y-5  ">
              <div className="w-[60px] h-4 rounded-[5px] bg-gray-200"></div>

              <div className="w-full h-4 rounded-[5px] bg-gray-200"></div>
            </div>
          </div>
          <div className="border-b   w-[474px] pt-6">
            <img src="/images/v2/project-onboarding/card4-right.svg"></img>
          </div>
        </div>
        {/* team members table  */}
        <div className="flex  w-[799px] ">
          <img src="/images/v2/project-onboarding/Table.svg"></img>
        </div>
      </div>

      {/* overview  */}
    </div>
  );
};

// const ProjectInfoItem = ({
//   fieldName,
//   image,
//   fieldValue,
// }: {
//   fieldName: string;
//   image: string;
//   fieldValue: string;
// }) => {
//   return (
//     <div className="mt-7">
//       <div className="text-gray-500 uppercase text-[10px] font-medium">
//         {fieldName}
//       </div>
//       <div className="text-black text-xs font-medium capitalize mt-2">
//         {fieldValue}
//       </div>
//     </div>
//   );
// };

const Card2 = ({ width, height, className, title }) => {
  const {
    projectName,
    projectColor,
    projectDuration,
    clientName,
    clientImage,
    clientLocation,
    clientColor,
  } = useAppSelector((state) => state.projectOnboarding);

  return (
    <div className="flex flex-col w-[799px] bg-white  gap-x-6 ml-6 mt-0.5">
      {/* project progress and action center  */}
      <div className="flex">
        <div className="flex w-[324px] border-r border-b h-[9px]"></div>
        <div className="flex w-[474px] border-r border-b h-[9px]"></div>
      </div>
      {/* project info and phases info  */}
      <div className="flex w-[799px] h-[492px] ">
        <div className="w-[325px] border-r flex flex-col  pt-6 pl-6 border-b ">
          <div className="h-6 w-[284px] flex justify-between items-center mb-5 ">
            <div className=" h-5  out-500-16">Project Info</div>
            <div className=" h-5   text-primary-700 out-500-14">Edit</div>
          </div>
          <div className="min-w-[127px] max-w-[280px]  max-h-[100px] truncate   h-[52px] flex flex-col gap-y-2 rounded mb-5">
            <div className="out-300-14 text-gray-500 h-4  "> Project name</div>
            <div className=" out-500-14 text-black flex gap-x-2  items-center     ">
              <CustomAvatar
                title={projectName}
                size={24}
                whiteText
                fontSize="9px"
                src={""}
                color={projectColor}
              />
              <div className=" truncate  ">{projectName}</div>
            </div>
          </div>
          <div className="w-[284px] h-[52px] flex flex-col gap-y-2 mb-5 ">
            <div
              className={`h-4  ${
                projectDuration
                  ? "out-300-14 text-gray-500"
                  : "w-[60px] rounded-[5px] bg-gray-200"
              } `}
            >
              {projectDuration ? "Start date" : ""}
            </div>

            <div
              className={`h-4  ${
                projectDuration ? "" : "w-full rounded-[5px] bg-gray-200"
              }`}
            >
              {projectDuration
                ? dayjs(projectDuration[0]).format("DD MMMM YYYY")
                : ""}
            </div>
          </div>
          <div className="w-[284px] h-[52px] flex flex-col gap-y-2 mb-5 ">
            <div
              className={`h-4  ${
                projectDuration
                  ? "out-300-14 text-gray-500"
                  : " w-[60px] rounded-[5px] bg-gray-200"
              } `}
            >
              {projectDuration ? "End date" : ""}
            </div>

            <div
              className={`h-4  ${
                projectDuration ? "" : " w-full rounded-[5px] bg-gray-200"
              }`}
            >
              {projectDuration
                ? dayjs(projectDuration[1]).format("DD MMMM YYYY")
                : ""}
            </div>
          </div>
          {clientName ? (
            <div className="w-[284px] h-[52px]    flex flex-col  gap-y-2 mb-5">
              <div className=" h-5 out-300-14 text-gray-500">Client name</div>
              <div className="out-500-14 h-[28px] max-w-[280px]  truncate flex items-center   gap-x-2">
                {clientImage ? (
                  <div className="w-6 h-6 rounded-2xl bg-gray-200"></div>
                ) : (
                  <CustomAvatar
                    title={clientName ? clientName : "WD"}
                    size={24}
                    whiteText
                    fontSize="9px"
                    src={""}
                    color={clientColor}
                  />
                )}

                <div className="truncate">{clientName}</div>
              </div>
            </div>
          ) : (
            ///

            <div className="w-[284px] h-[52px] flex flex-col  gap-y-2">
              <div className="w-[60px] h-4 rounded-[5px] bg-gray-200"></div>
              <div className="w-full h-[28px] flex items-center justify-between ">
                <div className="w-6 h-6 rounded-2xl bg-gray-200"></div>
                <div className="w-[252px] h-[11px] rounded-[5px] bg-gray-200"></div>
              </div>
            </div>
          )}{" "}
          <div className="w-[284px] h-[52px] flex flex-col gap-y-2 mb-5 ">
            <div
              className={`h-4  ${
                clientLocation
                  ? "out-300-14 text-gray-500"
                  : " w-[60px] rounded-[5px] bg-gray-200"
              } `}
            >
              {clientLocation ? "Based In" : ""}
            </div>

            <div
              className={`h-4  ${
                clientLocation
                  ? ""
                  : " w-full rounded-[5px] bg-gray-200 capitalize"
              }`}
            >
              {clientLocation ? clientLocation : ""}
            </div>
          </div>
          <div className="w-[284px] h-[52px] flex flex-col gap-y-2 mb-5  ">
            <div
              className={`h-4  ${
                clientLocation
                  ? "out-300-14 text-gray-500"
                  : " w-[60px] rounded-[5px] bg-gray-200"
              } `}
            >
              {clientLocation ? "Current time & timezone" : ""}
            </div>

            <div
              className={`h-4  ${
                clientLocation
                  ? ""
                  : " w-full rounded-[5px] bg-gray-200 capitalize"
              }`}
            >
              {clientLocation ? dayjs.tz.guess() : ""}
            </div>
          </div>
        </div>
        <div className="border-b   w-[474px] pt-6">
          <img src="/images/v2/project-onboarding/card4-right.svg"></img>
        </div>
      </div>
      {/* team members table  */}
      <div className="flex  w-[799px] ">
        <img src="/images/v2/project-onboarding/Table.svg"></img>
      </div>
    </div>
  );
};

const Card3 = ({ width, height, className, title }) => {
  const { selectedPhase } = useAppSelector((state) => state.projectOnboarding);
  let notSelectedPhases = [1, 2, 3, 4, 5];
  notSelectedPhases = notSelectedPhases.filter(
    (phase) => !selectedPhase.includes(phase)
  );

  return (
    <div className="flex flex-col w-[474px] bg-white  gap-x-6 mt-0.5">
      <div className="flex">
        <div className="flex w-2 border-r border-b h-[9px]"></div>
        <div className="flex w-[474px] border-r border-b h-[9px]"></div>
      </div>
      <div className="flex h-[468px]">
        <div className="flex w-2 border-r border-b "></div>
        {/* phases info */}
        <div className="border-b w-[474px] pt-6">
          <div className=" w-[100%] h-full flex flex-col">
            <div className="h-6 out-500-16 pl-6 ">Phases </div>
            <div className="flex flex-col  h-[400px] mb-5   mt-6 w-[434px] ml-4 justify-between  ">
              {selectedPhase.map((phase, index) => (
                <div
                  className="w-full h-[64px] pt-2 items-end  flex flex-col"
                  key={index}
                >
                  <div className="flex items-center w-full justify-between">
                    <div className="flex items-center gap-x-1.5 ">
                      <PhaseIcon height="20" width="20" phaseId={+phase} />
                      <div className={`h-[10px] out-500-14 text-black  `}>
                        {getPhaseName(phase)}
                      </div>
                    </div>
                    <div
                      className={`bg-primary-600 h-[22px]  rounded-2xl gap-x-[7px] flex items-center justify-center out-500-12 text-white py-1 px-2 cursor-pointer`}
                    >
                      Start phase
                    </div>
                  </div>
                  <div className="w-full flex items-center mt-1.5 ">
                    <Progress
                      showInfo={false}
                      strokeWidth={4}
                      strokeColor={getPhaseColor(phase)}
                      percent={3}
                      trailColor="#EFF8FF"
                      style={{ marginBottom: "4px" }}
                    />
                    <div className="out-500-14 text-gray-700">0%</div>
                  </div>
                </div>
              ))}
              {notSelectedPhases.map((phase, index) => (
                <div
                  className="w-full h-[64px] pt-2 items-end  flex flex-col"
                  key={index}
                >
                  <div className="flex items-center w-full justify-between">
                    <div className="flex items-center gap-x-1.5 ">
                      <div
                        className={`w-5 h-5 rounded-[20px] bg-gray-200`}
                      ></div>
                      <div
                        className={`w-[53px] h-[10px] rounded bg-gray-300`}
                      ></div>
                    </div>
                    <div className="w-[78px] h-[22px] bg-gray-200 rounded-[11px]"></div>
                  </div>
                  <div className="w-[434px] h-1 rounded-[2px] mt-[15px] bg-gray-200"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex   ">
        <img src="/images/v2/project-onboarding/table-right.svg"></img>
      </div>
    </div>
  );
};

// const Card4 = ({ width, height, className, title }) => {
//   const { selectedPhase } = useAppSelector((state) => state.projectOnboarding);
//   const phaseCard = (phaseId, phaseName = "", color = "") => {
//     return (
//       <div className="flex mt-6">
//         <PhaseIcon height="48" width="48" phaseId={phaseId} icon />
//         <div className="w-[90%] pl-4">
//           <div className="flex justify-between items-center">
//             <p className="mon-500-12 text-gray-900">{phaseName}</p>
//             <button
//               className={` h-6 rounded-2xl text-xs font-medium bg-primary-50 text-primary-600`}
//             >
//               <div className="flex px-2 items-center">
//                 <div className="pr-1 ">Yet to begin</div>

//                 <svg
//                   width="12"
//                   height="12"
//                   viewBox="0 0 12 12"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     d="M4.5 9L7.5 6L4.5 3"
//                     stroke="#165ABF"
//                     strokeWidth="1.5"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                   />
//                 </svg>
//               </div>
//             </button>
//           </div>
//           <div className=" flex items-center truncate whitespace-nowrap">
//             <Progress
//               showInfo={false}
//               strokeColor={color}
//               percent={+5}
//               trailColor="#EDF3F9"
//               className="progress-bar-tiny"
//             />
//             <div className="mont-500-14 text-gray-700 ml-3">0%</div>
//           </div>
//         </div>
//       </div>
//     );
//   };
//   const noData = () => {
//     return (
//       <div className="flex mt-6">
//         <div className="bg-gray-300 w-[48px] h-[48px] rounded-md"></div>
//         <div className="w-[90%] pl-4">
//           <div className="bg-gray-300 w-[146px] h-[17px] rounded-md"></div>
//           <div className="bg-gray-200 w-[146px] h-[4px] rounded-md mt-4"></div>
//         </div>
//       </div>
//     );
//   };
//   return (
//     <div
//       className={`bg-[#fff] py-4 pt-6  px-7 rounded-2xl shadow-md ${className}`}
//       style={{ width, height }}
//     >
//       <div className="flex justify-between ">
//         {title ? (
//           <div className="flex text-base font-medium text-black">Phases</div>
//         ) : (
//           <div className="bg-gray-300 w-[146px] h-[17px] rounded-md"></div>
//         )}
//       </div>

//       {selectedPhase.includes(1)
//         ? phaseCard(1, "Ideation", "#FFDB01")
//         : noData()}
//       {selectedPhase.includes(2) ? phaseCard(2, "Design", "#F92B63") : noData()}
//       {selectedPhase.includes(3)
//         ? phaseCard(3, "Development", "#6938EF")
//         : noData()}
//       {selectedPhase.includes(4)
//         ? phaseCard(4, "Deployment", "#33A553")
//         : noData()}
//       {selectedPhase.includes(5)
//         ? phaseCard(5, "Maintenance", "#6536ED")
//         : noData()}
//     </div>
//   );
// };

const SkeletonStructure = ({
  width,
  height,
  className = "",
  title,
  body = "",
}) => {
  const { currentStep, hasPhases } = useAppSelector(
    (state) => state.projectOnboarding
  );
  const getSketeton = () => {
    switch (currentStep) {
      case 1:
        return (
          <Card1
            width={width}
            height={height}
            className={className}
            title={title}
          />
        );
      case 2:
        return (
          <>
            {/* <div className="flex items-center gap-x-6">
              <Card1
                width={width}
                height={height}
                className={className}
                title={title}
              />
              <Card2 width={width} height={height} className={className} />
            </div>
            <div className="flex items-center gap-x-6">
              <Card3
                width={width}
                height={height}
                className={className}
                title="Project Info"
              />{" "}
              <Card4
                width={width}
                height={height}
                className={className}
                title=""
              />
            </div> */}
            <Card2
              width={width}
              height={height}
              className={className}
              title={title}
            />
          </>
        );
      case 3:
        return (
          <>
            <Card2
              width={width}
              height={height}
              className={className}
              title={title}
            />
          </>
        );
      case 4:
        return (
          <>
            <Card2
              width={width}
              height={height}
              className={className}
              title={title}
            />
          </>
        );
      case 5:
        return hasPhases ? (
          <Card3
            width={width}
            height={height}
            className={className}
            title={title}
          />
        ) : (
          <Card1
            width={width}
            height={height}
            className={className}
            title={title}
          />
        );
      case 6:
        return (
          <>
            <Card3
              width={width}
              height={height}
              className={className}
              title={title}
            />
          </>
        );

      default:
        return (
          <>
            <div
              className={`bg-[#fff] my-4 rounded-2xl shadow-md ${className}`}
              style={{ width, height }}
            >
              <div className="p-6">
                {title ? (
                  title
                ) : (
                  <div className="bg-gray-300 w-[146px] h-[17px] rounded-md"></div>
                )}
              </div>
              <div>{body}</div>
            </div>
            <div
              className={`bg-[#fff] my-4 rounded-2xl shadow-md ${className}`}
              style={{ width, height }}
            ></div>
          </>
        );
    }
  };
  return <>{getSketeton()}</>;
};

export default SkeletonStructure;
