import React from "react";
import Icon from "@ant-design/icons";

const BugSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_12662_72258)">
      <circle
        cx="8.001"
        cy="8"
        r="6.7"
        fill="#F9FBFE"
        stroke="#DDE5ED"
      ></circle>
      <path
        stroke="#292927"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.602 4l.8.8M10.402 4l-.8.8"
      ></path>
      <path
        fill="#B42318"
        fillRule="evenodd"
        d="M8 4c-.848 0-1.662.345-2.262.96a3.316 3.316 0 00-.937 2.316v1.448c0 .869.337 1.702.937 2.316.6.615 1.414.96 2.263.96.848 0 1.662-.345 2.263-.96.6-.614.937-1.447.937-2.316V7.276c0-.869-.337-1.702-.938-2.316A3.163 3.163 0 008.002 4zM6.265 5.498a2.427 2.427 0 011.737-.736c.651 0 1.276.265 1.736.736.223.229.4.497.521.788H5.744c.121-.291.297-.56.52-.788zm-.709 1.55c-.007.075-.01.152-.01.228v1.448c0 .667.259 1.306.72 1.778.46.471 1.084.736 1.736.736.651 0 1.276-.265 1.736-.736.46-.472.72-1.111.72-1.778V7.276c0-.076-.004-.153-.01-.228H5.554z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#B42318"
        d="M5.555 7.048c-.007.075-.01.152-.01.228v1.448c0 .667.259 1.306.72 1.778.46.471 1.084.736 1.736.736.651 0 1.276-.265 1.736-.736.46-.472.72-1.111.72-1.778V7.276c0-.076-.004-.153-.01-.228H5.554z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_12662_72258">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

const BugIcon = (props) => {
  return <Icon component={BugSvg} {...props} />;
};

export { BugIcon };
