import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";

const ChangeAssigneeModal = ({ visible, onClose, onYes, onNo }) => {
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-grey-700 out-500-14">Update Task</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Do you want to remove the current assignee from collaborator as well
            ?
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="No"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={onNo}
            />
            <CustomButton
              text="Yes"
              height="30px"
              width="101px"
              className="bg-primary-700 text-white"
              onClick={onYes}
            />
          </div>
        </div>
      }
    />
  );
};

export default ChangeAssigneeModal;
