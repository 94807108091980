import React, { useState } from "react";
import { ChevronDoubleIcon, ChevronIcon } from "../../Core/svgV2/Chevron";
import { Checkbox } from "antd";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import { useAppSelector } from "../../Core/redux/hooks";

const MyCalendarSidebar = ({ onDateClick }) => {
  const [todayDate, setTodayDate] = useState(dayjs());
  const [collapsed, setCollapsed] = useState(false);
  const { myCalendarFilter, currentDate } = useAppSelector(
    (state) => state.dashboard
  );
  const dispatch = useDispatch();

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleCheckboxChange = (name) => {
    if (name === "waddleCalendar") {
      dispatch(
        updateDashboard({
          key: "myCalendarFilter",
          value: {
            ...myCalendarFilter,
            showWaddle: !myCalendarFilter?.showWaddle,
          },
        })
      );
    } else {
      dispatch(
        updateDashboard({
          key: "myCalendarFilter",
          value: {
            ...myCalendarFilter,
            showGoogle: !myCalendarFilter?.showGoogle,
          },
        })
      );
    }
  };

  const firstDayOfMonth = dayjs(todayDate).startOf("month").startOf("week");
  const lastDayOfMonth = dayjs(todayDate).endOf("month").endOf("week");

  const days = [];
  let dayIterator = dayjs(firstDayOfMonth);

  while (dayIterator.isSameOrBefore(lastDayOfMonth, "day")) {
    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(dayjs(dayIterator));
      dayIterator = dayIterator.add(1, "day");
    }
    days.push(week);
  }

  const isSelected = (day) => {
    return (
      day &&
      dayjs(day).isSame(dayjs(), "day") &&
      dayjs(day).isSame(dayjs(), "month") &&
      dayjs(day).isSame(dayjs(), "year")
    );
  };

  const onClick = (day) => {
    onDateClick(day);
  };

  return (
    <div
      className={`border-l border-slate-200 bg-slate-50 relative max-h-[calc(100vh-140px)] h-screen ${
        collapsed ? "w-[36px]" : "2xl:w-[14%] w-[18%]"
      } transition-all duration-500 ease-in-out`}
    >
      <div className="flex justify-start p-2">
        <button onClick={toggleCollapse}>
          <ChevronDoubleIcon
            className={`text-[#292927] transform ${
              collapsed ? "" : "rotate-180"
            }`}
          />
        </button>
      </div>
      {!collapsed && (
        <>
          <div className="flex justify-between px-2">
            <div className="flex items-center">
              <p className="text-grey-500 out-300-14">
                {todayDate.format("MMM")}
              </p>
              <p className="text-zinc-800 out-500-14 ml-1">
                {todayDate.format("YYYY")}
              </p>
            </div>
            <div className="flex gap-2">
              <button
                className="border border-slate-200 rounded p-1"
                onClick={() => setTodayDate(todayDate.subtract(1, "month"))}
              >
                <ChevronIcon className="transform rotate-90 text-[#667085]" />
              </button>
              <button
                className="border border-slate-200 rounded p-1"
                onClick={() => setTodayDate(todayDate.add(1, "month"))}
              >
                <ChevronIcon className="transform -rotate-90 text-[#667085]" />
              </button>
            </div>
          </div>
          <div className="p-2">
            <div className="grid grid-cols-7 gap-1 text-center text-gray-500 text-[10px] font-normal font-['Outfit']">
              {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
                <div key={index}>{day ? day : ""}</div>
              ))}
            </div>
            {days.map((week, rowIndex) => (
              <div key={rowIndex} className="grid grid-cols-7 gap-1 mt-1">
                {week.map((day, index) => (
                  <div
                    key={index}
                    className={`w-6 h-6 flex items-center justify-center cursor-pointer rounded-full ${
                      isSelected(day)
                        ? "bg-blue-700 text-slate-50"
                        : day &&
                          currentDate &&
                          dayjs(day).isSame(dayjs(currentDate), "day")
                        ? "bg-[#D2E3FC] text-[#185ABC]"
                        : day && dayjs(day).month() === todayDate.month()
                        ? "text-zinc-800 hover:bg-gray-300"
                        : "text-gray-500"
                    }`}
                    onClick={() => onClick(day)}
                  >
                    {day && (
                      <div className="text-center text-[10px] font-normal font-['Outfit']">
                        {day.format("D")}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MyCalendarSidebar;
