import React from "react";
import Icon from "@ant-design/icons";

const DownloadSvg = ({ width = 16, height = 16 }) => {
  const scaleFactor = Math.min(width, height) / 16;
  const adjustedViewBox = `${-0.5 / scaleFactor} ${-0.5 / scaleFactor} ${
    16 + 1 / scaleFactor
  } ${16 + 1 / scaleFactor}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={adjustedViewBox}
    >
      <g transform={`scale(${scaleFactor})`}>
        <path
          fill="currentColor"
          d="M8.5 2.4a.5.5 0 00-1 0h1zm-1 0v7.2h1V2.4h-1z"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.4 8L8 10 5.6 8"
        ></path>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.2 9.6v3.2h9.6V9.6"
        ></path>
      </g>
    </svg>
  );
};

const DownloadIcon = ({ width = 16, height = 16, ...props }) => {
  return (
    <Icon
      component={() => <DownloadSvg width={width} height={height} />}
      {...props}
    />
  );
};

export { DownloadIcon };
