import React from "react";
import Icon from "@ant-design/icons";

const CalendarNotificationSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.485 14.328H4.657c-.229 0-.46-.04-.661-.147-1.743-.925-.869-2.58.133-3.668.347-.376.58-.855.58-1.367V6.453c.134-1.458 1.357-4.375 5.185-4.375 3.697 0 5.213 2.72 5.551 4.22.024.103.032.208.032.313v2.596c0 .477.204.927.517 1.287 1.615 1.856.977 3.083.217 3.635-.208.151-.469.2-.726.2zM6.962 15.8c0 .495.99 2.476 2.971 2.476 1.98 0 2.971-1.486 2.971-2.476"
    ></path>
  </svg>
);

const CalendarNotificationIcon = (props) => (
  <Icon component={CalendarNotificationSvg} {...props} />
);

export { CalendarNotificationIcon };
