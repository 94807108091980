import React, { useEffect, useState } from "react";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { Popover, Tooltip } from "antd";
import { CheckIcon } from "../../../Core/svgV2/Check";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  getGoalsBySprint,
  getTodoList,
  updateGoal,
} from "../../../Core/redux/api/scheduleAPI";
import UnwaddleModal from "../Modals/UnwaddleModal";
import usePermission from "../../../Core/hooks/usePermission";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useGetProjectList } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import ToDoList from "../Planner/ToDoList";

// const GOAL_TYPE = {
//   TASK: 1,
//   EVENT: 2,
//   BUG: 3,
//   SUBTASK: 4,
// };

export const TaskType = ({
  onTaskTypeChange,
  value = 1,
  subtaskLength = false,
}) => {
  const [selectedType, setSelectedType] = useState(value);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { projectId, subtaskId } = useParams();

  useEffect(() => {
    setSelectedType(value);
  }, [value]);
  const getTaskDetails = (taskType) => {
    switch (+taskType) {
      case 1:
        return { src: "/images/v2/schedule/Task.svg", taskName: "Task" };
      case 2:
        return { src: "/images/v2/schedule/meet.svg", taskName: "Event" };
      case 3:
        return { src: "/images/v2/schedule/Bug.svg", taskName: "Bug" };
      case 4:
        return { src: "/images/v2/schedule/Subtask.svg", taskName: "Subtask" };
      default:
        return {
          src: "/images/v2/schedule/Task.svg",
          taskName: "Task",
        };
    }
  };

  const { hasPermission: SUB_TASK_AND_BUG_ADD } = usePermission("11");

  const TaskItem = ({ taskType }) => {
    const { taskName, src } = getTaskDetails(taskType);
    const { goals } = useAppSelector((state) => state.schedule);
    const { projectId, subtaskId } = useParams();
    const [randomGoal, setRandomGoal] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
      const randomGoal = goals.find((goal) => +goal.goalType === 1);

      setRandomGoal(randomGoal);
    }, [goals]);
    return (
      <div
        onClick={() => {
          onTaskTypeChange(taskType);
          setSelectedType(taskType);
          setPopoverOpen(false);

          // const randomGoal = goals.find((goal) => goal.goalType === 1);
          if (taskType === 4) {
            navigate(
              `/schedule/${projectId}/task/${randomGoal?.id}/subtask/new`
            );
          }
        }}
        className="flex cursor-pointer py-2.5 px-3.5 hover:bg-gray-50 rounded-t-lg justify-between"
      >
        <div className="flex  items-center">
          <img src={src} />
          <span className="out-300-14 text-gray-700 ml-2">{taskName}</span>
        </div>
        {selectedType == taskType && <CheckIcon className="text-primary-600" />}
      </div>
    );
  };

  const { src } = getTaskDetails(selectedType);
  return (
    <>
      {subtaskId ? (
        <button className="flex items-center cursor-pointer">
          <img src={src} />
        </button>
      ) : (
        <Popover
          arrow={false}
          open={popoverOpen}
          placement="bottomLeft"
          onOpenChange={(val) => setPopoverOpen(val)}
          content={
            <div className="w-[200px] bg-white shadow-lg rounded-lg ">
              <TaskItem taskType={1} />
              {SUB_TASK_AND_BUG_ADD && <TaskItem taskType={3} />}
              {/* <TaskItem taskType={2} /> */}
              {/* <TaskItem taskType={4} /> */}
            </div>
          }
          trigger={["click"]}
        >
          <button
            className="flex items-center cursor-pointer"
            onClick={() => setPopoverOpen(true)}
            disabled={subtaskLength}
          >
            <img src={src} />
            <img
              className={`ml-2 ${
                popoverOpen
                  ? "transform rotate-180 duration-200"
                  : "transform rotate-0 duration-200"
              }`}
              src="/images/v2/schedule/chevron-down-filled.svg"
            />
          </button>
        </Popover>
      )}
    </>
  );
};

const TaskNavigation = () => {
  const { taskId, projectId } = useParams();
  const { goals } = useAppSelector((state) => state.schedule);
  const navigate = useNavigate();

  if (goals.length === 0) return <></>;

  const newGoals = [...goals].sort((a, b) => a.statusId - b.statusId);
  const currentTaskIndex = newGoals.findIndex((g) => g.id === taskId);

  const handlePrevClick = () => {
    if (currentTaskIndex > 0) {
      const prevGoal = newGoals[currentTaskIndex - 1];
      // Handle navigation to prevGoal

      navigate(`/schedule/${projectId}/task/${prevGoal.id}`);
    }
  };

  const handleNextClick = () => {
    if (currentTaskIndex < newGoals.length - 1) {
      const nextGoal = newGoals[currentTaskIndex + 1];
      // Handle navigation to nextGoal
      navigate(`/schedule/${projectId}/task/${nextGoal.id}`);
    }
  };

  return (
    <>
      <button
        className="h-[30px] w-[30px] border rounded border-gray-200 flex justify-center items-center"
        onClick={handlePrevClick}
        disabled={!(currentTaskIndex > 0)}
        style={{
          opacity: !(currentTaskIndex > 0) ? 0.5 : 1,
        }}
      >
        <ChevronIcon className="text-gray-500 transform rotate-180" />
      </button>

      <button
        className="h-[30px] w-[30px] border rounded border-gray-200 flex justify-center items-center"
        onClick={handleNextClick}
        disabled={!(currentTaskIndex < newGoals.length - 1)}
        style={{
          opacity: !(currentTaskIndex < newGoals.length - 1) ? 0.5 : 1,
        }}
      >
        <ChevronIcon className="text-gray-500 transform" />
      </button>
    </>
  );
};

const TaskHeader = ({
  taskType,
  setTaskType,
  isWaddleIt,
  setIsWaddleIt,
  handleSubmit,
  task,
  onCancel,
  editedTaskDetails = null,
  isEdited = false,
}) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { goals, todoList } = useAppSelector((state) => state.schedule);
  const { data: projectList, isLoading: isLoadingProjectList } =
    useGetProjectList(user.id);
  const { selectedTask } = useAppSelector((state) => state.schedule);
  const [parentTask, setParentTask] = useState(null);
  const [unwaddleModal, setUnwaddleModal] = useState(false);
  const [subtaskLength, setSubtaskLength] = useState(false);

  useEffect(() => {
    if (+taskType === 3) {
      dispatch(
        updateSchedule({
          key: "isBug",
          value: true,
        })
      );
    }
    if (task?.subtasks?.length > 0) {
      setSubtaskLength(true);
    }
  }, [taskType]);

  const { projectId, sprintId } = useParams();
  const navigate = useNavigate();
  const { taskId, subtaskId } = useParams();
  const dispatch = useAppDispatch();
  const updateSprintGoals = (taskDetails) => {
    if (taskDetails.sprintId) {
      dispatch(
        getGoalsBySprint({
          projectId,
          sprintId: taskDetails.sprintId,
          module: "sprints",
        })
      );
    } else {
      dispatch(getTodoList({ projectId }));
    }
  };

  useEffect(() => {
    if (subtaskId === "new") {
      const parentTask = [...goals, ...todoList]?.find((goal) =>
        task?.parentGoalId ? goal.id === task?.parentGoalId : goal.id === taskId
      );

      setParentTask(parentTask);
    } else {
      const parentTask = [...goals, ...todoList]?.find((goal) =>
        editedTaskDetails?.parentGoalId
          ? goal.id === editedTaskDetails?.parentGoalId
          : goal.id === taskId
      );

      setParentTask(parentTask);
    }
  }, [editedTaskDetails, task, subtaskId]);

  const getByTitle = (id) => {
    switch (+id) {
      case 1:
        return "New Task";
      case 3:
        return "New Bug";
      case 4:
        return "New Subtask";
      default:
        return "New Task";
    }
  };

  let redirectToLink = "";
  if (projectList?.length > 0) {
    const project = projectList?.find((project) => +project?.id === +projectId);
    redirectToLink = project?.currentactivesprint
      ? `/schedule/${project?.id}/sprints/${project?.currentactivesprint}`
      : `/schedule/${project?.id}/sprints`;
  }

  const showWaddleIt = task.assigneeId == user.id || taskId === "new";
  const subtaskClose = () => {
    let redirectToLink = "";
    if (projectList?.length > 0) {
      const project = projectList?.find(
        (project) => +project.id === +projectId
      );
      redirectToLink = project?.currentactivesprint
        ? `/schedule/${project?.id}/sprints/${project?.currentactivesprint}`
        : `/schedule/${project?.id}/sprints`;
    }
    subtaskId
      ? parentTask?.sprintId
        ? redirectToLink
          ? navigate(redirectToLink)
          : navigate(-1)
        : navigate(`/schedule/${projectId}/backlog`)
      : navigate(-1);
  };

  const onSubtaskSubmit = async () => {
    await handleSubmit();
    if (subtaskId === "new") {
      subtaskClose();
    }
  };
  // const currentActiveSprint = sprints.find((sprint) => sprint.status == 2);

  const { hasPermission: TASK_EVENT_SCOPE_ADD } = usePermission("8");

  return (
    <div className="flex justify-between items-center px-6 py-2 bg-white border-b  border-gray-200">
      <div className="flex items-center gap-x-3">
        <TaskNavigation />

        {/* { task?.subtasks?.length > 0 &&  */}
        <TaskType
          value={taskType}
          onTaskTypeChange={async (type) => {
            setTaskType(type);
            if (type === 3) {
              dispatch(
                updateSchedule({
                  key: "isBug",
                  value: true,
                })
              );
            } else {
              dispatch(
                updateSchedule({
                  key: "isBug",
                  value: false,
                })
              );
            }
            console.log(selectedTask, "selectedTask", type);
            if (taskId !== "new") {
              const updateObj: any = {
                goalType: type === 4 ? 1 : type,
                goalId: taskId,
              };

              const res = await dispatch(updateGoal(updateObj));
              await dispatch(
                updateSchedule({ key: "isGoalEdited", value: true })
              );
            }
            updateSprintGoals(task);
          }}
          subtaskLength={subtaskLength}
        />
        {/* } */}
        <span className="out-300-14 text-gray-500 flex  ">
          {/* {subtaskId === "new" ? (
            <Tooltip title={parentTask?.name} placement="bottom">
              <div className="flex w-full">
                <p className=" uppercase ">
                  {parentTask?.identifier ? parentTask?.identifier : taskId} -
                  <span className="text-gray-900 out-500-14 truncate max-w-[100px] ">
                    {" "}
                    {parentTask?.name}
                  </span>
                </p>
                <span className=" text-gray-500 out-500-14 ">
                  / New Subtask
                </span>
              </div>
            </Tooltip>
          ) : (
            <></>
          )} */}
          {taskId === "new" ? (
            getByTitle(taskType)
          ) : subtaskId === "new" ? (
            <Tooltip title={parentTask?.name} placement="bottom">
              <div className=" w-full flex gap-x-1">
                <span className="uppercase ">
                  {parentTask?.identifier ? parentTask?.identifier : taskId}
                  {"  "}
                </span>
                <span className=" truncate max-w-[100px] ">
                  {parentTask?.name}
                </span>
                <span className="uppercase text-gray-900 out-500-14 ">
                  {" "}
                  / New Subtask
                </span>
              </div>
            </Tooltip>
          ) : subtaskId ? (
            <Tooltip title={parentTask?.name} placement="bottom">
              <div className=" w-full flex gap-x-1">
                <span className="uppercase ">
                  {parentTask?.identifier ? parentTask?.identifier : taskId}
                  {"  "}
                </span>
                <span className=" truncate max-w-[100px] ">
                  {parentTask?.name}
                </span>
                <span className="uppercase text-gray-900 out-500-14 ">
                  {" "}
                  / s{task?.identifier ? task?.identifier : subtaskId}
                </span>
              </div>
            </Tooltip>
          ) : task?.identifier ? (
            task?.identifier
          ) : (
            taskId
          )}
        </span>
      </div>
      <div className="flex items-center gap-x-3">
        {(TASK_EVENT_SCOPE_ADD ||
          parentTask?.collaborators.includes(user.id)) && (
          <>
            {(isEdited || taskId === "new") && (
              <>
                <CustomButton
                  text={
                    taskId === "new" || subtaskId === "new" ? "Create" : "Save"
                  }
                  height="30px"
                  onClick={onSubtaskSubmit}
                />
                <CustomButton
                  onClick={() => {
                    onCancel();
                  }}
                  text="Cancel"
                  className="bg-transparent text-gray-500"
                  height="30px"
                  width="64px"
                />
              </>
            )}
          </>
        )}
        {!subtaskId && (
          <UnwaddleModal
            visible={unwaddleModal}
            onClose={() => setUnwaddleModal(false)}
          />
        )}

        {showWaddleIt && (
          <button
            onClick={() => {
              if (taskId === "new") {
                setIsWaddleIt(!isWaddleIt);
                return;
              }
              if (isWaddleIt) {
                setUnwaddleModal(true);
                return;
              }

              setIsWaddleIt(!isWaddleIt);
            }}
            className="flex h-[30px] cursor-pointer bg-primary-50 rounded pl-[20px]  w-[308px] items-center"
          >
            <img src="/images/v2/schedule/star-07.svg" />
            <span className="ml-2 out-300-12 text-primary-600 underline">
              {isWaddleIt ? "Un-Waddle this task" : "   Waddle this task"}
            </span>
          </button>
        )}
        <button
          onClick={() => {
            onCancel();
            // subtaskId ? subtaskClose() : onCancel();
          }}
          className="h-[30px] w-[30px] hover:bg-gray-50 flex items-center justify-center"
        >
          <CrossIcon className="text-gray-700" />
        </button>
      </div>
    </div>
  );
};

export default TaskHeader;
