import React from "react";
import { ProjectOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import Step4 from "./Step4";
import Step1 from "./Step1";
import Step3 from "./Step3";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step2 from "./Step2";

import SkeletonStructure from "./SkeletonStructure";
import SidebarSkeletonStructure from "./SideBarSkeletonStructure";
import { useAppSelector } from "../../../Core/redux/hooks";
import StepProgressBar from "./StepProgressBar";
import Step5NoPhases from "./Step5NoPhases";

// const HeaderItem = ({ image, title }) => {
//   return (
//     <div className="flex ml-10 items-center">
//       <div className="w-5 h-5">
//         <img className="icon-hover w-full h-full" src={image} />
//       </div>

//       <div className={`pl-2 text-xs cursor-default font-normal`}>{title}</div>
//     </div>
//   );
// };

const Content = () => {
  // const [searchParams, setSearchParams] = useSearchParams("");
  const { currentStep, hasPhases } = useAppSelector(
    (state) => state.projectOnboarding
  );

  const getCurrentStep = (step) => {
    switch (step) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 5:
        if (hasPhases) {
          return <Step5 />;
        } else {
          return <Step5NoPhases />;
        }
      case 6:
        return <Step6 />;
      default:
        return <></>;
    }
  };

  return (
    <div className="p-6 w-full h-full">
      <StepProgressBar
        currentStep={currentStep}
        totalSteps={5}
        givenElementId="stepHead"
      />

      <div className="w-[81px] h-6">
        <img
          className="w-full h-full cursor-pointer"
          src="/images/icons/waddle-logo.svg"
          alt="waddle logo"
        />
      </div>
      {currentStep && getCurrentStep(currentStep)}
    </div>
  );
};

const Sidebar = () => {
  const { projectName, projectColor, clientName, projectImage } =
    useAppSelector((state) => state.projectOnboarding);
  return (
    <div className="absolute top-24 left-8 ">
      <div className="w-screen flex  h-full rounded-3xl relative shadow-2xl ">
        {/* sidebar  */}
        <div className="h-screen border-r  bg-white w-[200px]">
          <SidebarSkeletonStructure
            logo={projectImage}
            projectName={projectName}
            internalProjectName={clientName}
            color={projectColor}
          />
        </div>
        {/* content */}
        <div className="flex flex-col w-full ">
          {/* content - header */}
          <div className="bg-white flex pl-6 items-center  h-[46px] out-500-14">
            <div> Dashboard</div>
          </div>
          {/* content -- body */}
          <div className="bg-gray-100    h-full out-500-14">
            <SkeletonStructure
              width={"332px"}
              height={"402px"}
              title={""}
              body={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectSetupV2 = () => {
  return (
    <ProjectOnboardingLayout content={<Content />} sidebar={<Sidebar />} />
  );
};

export default ProjectSetupV2;
