import React from "react";
import Icon from "@ant-design/icons";

const EditTwoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 16.667h7.5m-15 0h1.395c.408 0 .612 0 .804-.046.17-.041.332-.108.482-.2.168-.103.312-.247.6-.535L16.25 5.416a1.768 1.768 0 10-2.5-2.5L3.281 13.387c-.288.288-.432.432-.535.6-.092.15-.16.312-.2.482-.046.192-.046.396-.046.803v1.396z"
    ></path>
  </svg>
);

const EditThreeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 16.667h7.5m-15 0h1.395c.408 0 .612 0 .804-.046.17-.041.332-.108.482-.2.168-.103.312-.247.6-.535L16.25 5.416a1.768 1.768 0 10-2.5-2.5L3.281 13.387c-.288.288-.432.432-.535.6-.092.15-.16.312-.2.482-.046.192-.046.396-.046.803v1.396z"
    ></path>
  </svg>
);

const EditTwoIcon = (props) => {
  return <Icon component={EditTwoSvg} {...props} />;
};

const EditThreeIcon = (props) => {
  return <Icon component={EditThreeSvg} {...props} />;
};

export { EditTwoIcon,EditThreeIcon };
