import React, { useEffect } from "react";
import { CrossIcon } from "../svgV2/CrossIcon";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { updateDashboard } from "../redux/slices/dashboard.slice";

const ActionCompletedAlertModal = () => {
  const dispatch = useAppDispatch();

  const { alertPopupModal } = useAppSelector((state) => state.dashboard);
  useEffect(() => {
    if (alertPopupModal?.visible) {
      setTimeout(
        () =>
          dispatch(
            updateDashboard({
              key: "alertPopupModal",
              value: {
                visible: false,
                data: {
                  title: "",
                  subtitle: "",
                  description: "",
                },
              },
            })
          ),
        3000
      );
    }
  }, [alertPopupModal]);

  const { title, subtitle, description } = useAppSelector(
    (state) => state.dashboard?.alertPopupModal?.data
  );

  if (!alertPopupModal.visible) {
    return <></>;
  }

  return (
    <div className="p-4 fixed bg-white min-w-[350px] min-h-[92px]  bottom-5 right-5 shadow-lg rounded-lg flex flex-col gap-y-4 z-50">
      <div className="flex justify-between items-center w-full h-6 ">
        <div className="flex items-center justify-between gap-x-3.5">
          <img src="/images/v2/dashboard/frostyAlert.svg"></img>
          <div className="out-500-16">{title}</div>
        </div>
        <button
          onClick={() =>
            dispatch(
              updateDashboard({
                key: "alertPopupModal",
                value: {
                  visible: false,
                  data: { title: "", subtitle: "", description: "" },
                },
              })
            )
          }
        >
          <CrossIcon className="text-black" />
        </button>
      </div>
      <div className="text-gray-500 out-500-14">
        <p className="text-gray-500 ">
          {subtitle}
          <span className="text-black ">{description}</span>
        </p>
      </div>
    </div>
  );
};

export default ActionCompletedAlertModal;
