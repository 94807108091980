import React, { useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { Radio } from "antd";

const ReplaceFileModal = () => {
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <CustomModal
      visible={false}
      width="359px"
      onCancel={() => console.log("Cancel upload")}
      body={
        <div className="p-4">
          <p className="text-black out-500-14 leading-5">
            Replace or Skip files
          </p>
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            <span className="out-500-14 leading-5 text-primary-600">
              Tech design requirements.pdf
            </span>{" "}
            already exists in this location. Replacing the file wont’ change
            sharing settings.
          </p>
          <div className="mt-4 ml-[14px]">
            <Radio.Group onChange={onChange} value={value}>
              <div className="flex items-center gap-x-[8.5px]">
                <Radio value={1}>
                  <p className="out-500-14 leading-5 text-black">
                    Replace existing
                  </p>
                </Radio>
              </div>
              <div className="mt-[18px] flex items-center gap-x-[8.5px]">
                <Radio value={2}>
                  <p className="out-500-14 leading-5 text-black">
                    Keep both files
                  </p>
                </Radio>
              </div>
            </Radio.Group>
          </div>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
            />
            <CustomButton
              text="Upload"
              height="30px"
              width="64px"
              className="bg-transparent text-primary-600"
            />
          </div>
        </div>
      }
    />
  );
};

export default ReplaceFileModal;
