import React from "react";
import Icon from "@ant-design/icons";

const DeleteIcon = ({
  width = "16",
  height = "16",
  className = "",
  style = {},
}) => (
  <span role="img" className={`anticon ${className}`} style={style}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 5.602c0 3.5.8 8 .8 8h6.4s.8-4.5.8-8"
      ></path>
      <path stroke="currentColor" strokeLinecap="round" d="M2.4 4h11.2"></path>
      <path
        stroke="currentColor"
        d="M5.6 3.998v-1.1a.5.5 0 01.5-.5h3.8a.5.5 0 01.5.5v1.1"
      ></path>
    </svg>
  </span>
);

export { DeleteIcon };
