import React, { useEffect } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import { Layout } from "antd";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useParams } from "react-router-dom";
import { getProjectDetatis } from "../redux/api/projectAPI";
import { getTenantMembers } from "../redux/api/tenantAPI";
import { getWorkspaces } from "../redux/api/dashboardAPI";
import { useGetTenantMembers } from "../redux/ReactQueryHooksV3/useTenantAPI";
import { useGetWorkspaces } from "../redux/ReactQueryHooksV3/useDashboardAPI";
import { useGetProjectDetails } from "../redux/ReactQueryHooksV3/useProjectAPI";

type CustomProps = {
  children: React.ReactNode;
  page: string;
  sidebar?: boolean;
  // any props that come into the component
};

const CustomLayout = ({ children, page, sidebar = true }: CustomProps) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const { currentTenantId } = useAppSelector((state) => state.tenant);

  useGetProjectDetails(projectId);

  // Using React Query hooks instead of Redux dispatch for workspaces and tenant members
  useGetWorkspaces();
  useGetTenantMembers();

  return (
    <Layout hasSider>
      <Header page={page} />

      {sidebar && <SideBar page={page} />}
      <Layout.Content className="mt-14 min-h-[calc(100vh-56px)]">
        {children}
      </Layout.Content>
    </Layout>
  );
};

export default CustomLayout;
