import React from "react";
import MyCalendarLayout from "./MyCalendarLayout";
import Events from "./MyCalendarEvents";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import EventInfoModal from "./Modals/EventInfoModal";
import { updateDashboard } from "../../Core/redux/slices/dashboard.slice";
import CreateEventModal from "./Modals/CreateEventModal";
import MonthDateModal from "./Modals/MonthDateModal";

const MyCalendarScreen = () => {
  const { eventInfoModal, createEventModal, monthDateModal } = useAppSelector(
    (state) => state.dashboard
  );
  const dispatch = useAppDispatch();
  return (
    <MyCalendarLayout>
      <Events />
      <EventInfoModal
        visible={eventInfoModal}
        onClose={() => {
          dispatch(
            updateDashboard({
              key: "eventInfoModal",
              value: false,
            })
          );
        }}
      />
      {createEventModal && (
        <CreateEventModal
          visible={createEventModal.visible}
          onClose={() => {
            dispatch(
              updateDashboard({
                key: "createEventModal",
                value: {
                  visible: false,
                  startTime: "",
                  endTime: "",
                  date: "",
                },
              })
            );
          }}
        />
      )}
      {monthDateModal && (
        <MonthDateModal
          visible={monthDateModal.visible}
          selectedDate={monthDateModal.selectedDate}
          onClose={() => {
            dispatch(
              updateDashboard({
                key: "monthDateModal",
                value: {
                  visible: false,
                  selectedDate: "",
                },
              })
            );
          }}
        />
      )}
    </MyCalendarLayout>
  );
};

export default MyCalendarScreen;
