import React from "react";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { useNavigate } from "react-router";
import { compeleteResetProjectOnboarding } from "../../../Core/redux/slices/projectOnboarding.slice";

const StepHeading = ({
  title,
  subTitle,
  hideSubtitle = false,
  className = "mt-6",
  subTitleAlignment = "items-center",
  error = false,
  frostyAlignment = "",
}) => {
  console.log("error", error);

  return (
    <div className={`${className} max-h-[62px] `}>
      <p className="text-gray-900 out-500-20">{title}</p>
      {!hideSubtitle && (
        <div
          className={`${subTitleAlignment} flex justify-start items-start text-show-animation-project gap-x-2 out-400-16 mt-2 `}
        >
          <img
            src="/images/icons/frosty-icon.svg"
            className={`min-h-[26px] min-w-[26px] ${frostyAlignment}`}
            alt="frosty"
          />
          <p
            className={`out-400-16 ${
              error ? "text-error-600" : "text-gray-500"
            } pl-2`}
          >
            {subTitle}
          </p>
        </div>
      )}
    </div>
  );
};

export default StepHeading;
