import React from "react";
import Icon from "@ant-design/icons";

const QuestionMarkSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.6 6.4A2.4 2.4 0 118 8.8v.8"
    ></path>
    <circle cx="8" cy="12" r="0.8" fill="currentColor"></circle>
    <circle cx="8" cy="8" r="6.4" stroke="currentColor"></circle>
  </svg>
);

const QuestionMarkIcon = (props) => {
  return <Icon component={QuestionMarkSvg} {...props} />;
};

export { QuestionMarkIcon };
