import React, { useCallback, useEffect, useState } from "react";
import FolderContainer from "./FolderContainer";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import FileContainer from "./FileContainer";
import LinkContainer from "./LinkContainer";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import {
  getAllFiles,
  getAllFolders,
  getAllLinks,
} from "../../Core/redux/api/vaultAPI";
import { useNavigate, useParams } from "react-router-dom";
import EmptyState from "./EmptyState";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import { CommentIcon, CommentWithRedDotIcon } from "../../Core/svgV2/Comments";
import { Dropdown, MenuProps, Tooltip, message } from "antd";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import CustomTable from "../../Core/CommonV2/CustomTable";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { ShareAccessIcon } from "../../Core/svgV2/UserIcon";
import { MoveToIcon } from "../../Core/svgV2/MoveToIcon";
import { ShareInMessageIcon } from "../../Core/svgV2/ShareFile";
import { EditIcon } from "../../Core/svgV2/EditIcon";
import { CopyIcon } from "../../Core/svgV2/CopyIcon";
import { DownloadIcon } from "../../Core/svgV2/DownloadIcon";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import PhaseIcon from "../../Core/svg/PhaseIcon";
import { getPhaseName } from "../../Core/utils/getPhaseName";
import { bytesToSize } from "../../Core/utils/bytesToSize";
import dayjs from "dayjs";
import { updateVault } from "../../Core/redux/slices/vault.slice";
import { client } from "../../Core/utils/axiosClient";
import { downloadFiles, filterArrayObjectsByValue } from "../../Core/utils";
import copyTextToClipboard from "../../Core/utils/clipboard";
import uniqid from "uniqid";
import DropTarget from "./Components/DropTarget";
import DragSource from "./Components/DragSource";
import { DndProvider } from "react-dnd";
import { manager } from "../../Core/utils/dnd-utils";
import { updateConversations } from "../../Core/redux/slices/conversations.slice";
import { storeDataConsumed } from "../../Core/utils/dcfUtils";

const DocsView = ({ isMyLibrary = false, viewType }) => {
  const dispatch = useAppDispatch();

  const { projectId, "*": splat } = useParams();
  console.log(projectId, "docs view");

  const [showFolders, setShowFolders] = useState(true);
  const [showFiles, setShowFiles] = useState(true);
  const [showLinks, setShowLinks] = useState(true);

  const { links, search, files, folders } = useAppSelector(
    (state) => state.vault
  );

  const [currentDropDestinationId, setCurrentDropDestinationId] =
    useState(null);
  const [currentDragSource, setCurrentDragSource] = useState({
    type: "",
    id: null,
  });

  const getFilesFoldersLinks = (callback = null) => {
    const queryObject: { projectId?: string; folderId?: string } = {
      projectId,
    };

    if (splat.length > 0) {
      const LocationArray = location.pathname.split("/");
      const lastLocation = LocationArray[LocationArray.length - 1].split("-");
      const newId = lastLocation[lastLocation.length - 1];

      if (newId) {
        queryObject.folderId = newId;
      }
    }

    // if (isMyLibrary) {
    //   queryObject.folderId = "0";
    //   queryObject.projectId = null;
    // }

    dispatch(getAllFolders(queryObject)).then(() => {
      if (typeof callback === "function") callback("folders");
    });
    dispatch(getAllFiles(queryObject)).then(() => {
      if (typeof callback === "function") callback("files");
    });
    dispatch(getAllLinks(queryObject)).then(() => {
      if (typeof callback === "function") callback("links");
    });
  };

  const handleMoveItemToFolder = async ({
    itemId,
    docType,
    moveToFolderId,
  }) => {
    const payload = {
      itemId: itemId,
      itemType: docType,
      folderId: moveToFolderId,
      projectId,
    };

    const DocumentsAfterMove = () => {
      const query: { projectId: string; folderId?: string } = { projectId };

      if (splat && splat.length > 0) {
        const folderId = splat.split("-");
        query.folderId = folderId[folderId.length - 1];
      }

      return query;
    };

    await client.put(`/repository/move-item-to-folder`, payload);

    const queryObject = DocumentsAfterMove();

    if (docType === "folder") {
      await dispatch(getAllFolders(queryObject));
    } else if (docType === "file") {
      await dispatch(getAllFiles(queryObject));
    } else {
      await dispatch(getAllLinks(queryObject));
    }
  };

  useEffect(() => {
    getFilesFoldersLinks();
  }, [projectId, splat]);

  const getDocList = useCallback(() => {
    const data = [
      ...filterArrayObjectsByValue(folders, "folderName", search).map((doc) => {
        return { ...doc, docType: "folder" };
      }),

      ...filterArrayObjectsByValue(files, "name", search).map((doc) => {
        return { ...doc, docType: "file" };
      }),
      ...filterArrayObjectsByValue(links, "name", search).map((doc) => {
        return { ...doc, docType: "link" };
      }),
    ];

    return data;
  }, [files, folders, links, search]);

  if (folders.length === 0 && files.length === 0 && links.length === 0) {
    return <EmptyState />;
  }

  if (viewType === 2) {
    return (
      <div
        className={`w-full ${
          isMyLibrary ? "h-[calc(100vh-156px)]" : "h-[calc(100vh-110px)]"
        } bg-white overflow-y-scroll`}
      >
        <ListViewForDocuments documentsList={getDocList()} />
      </div>
    );
  }

  return (
    <DndProvider manager={manager}>
      <div
        className={`w-full ${
          isMyLibrary ? "h-[calc(100vh-156px)]" : "h-[calc(100vh-100px)]"
        } pb-4 px-6 bg-white overflow-y-scroll `}
      >
        <div className="mt-5 flex flex-wrap gap-x-4 gap-y-4">
          <div className="flex flex-col">
            <div>
              <div
                className="w-[85px] flex items-center gap-x-3 cursor-pointer"
                onClick={() => setShowFolders(!showFolders)}
              >
                <ChevronIcon
                  className={`text-gray-500 mt-0.5 ${
                    showFolders ? "" : "-rotate-90"
                  }`}
                />
                <p className="out-500-14 leading-5 text-gray-500">Folders</p>
              </div>
              {showFolders ? (
                <div className="flex flex-wrap gap-4 mt-4">
                  {filterArrayObjectsByValue(folders, "folderName", search).map(
                    (folder) => (
                      <DropTarget
                        key={folder.id}
                        id={folder.id}
                        type="folder"
                        onDrop={({ type, id: folderId }) => {
                          const { id: itemId, type: itemType } =
                            currentDragSource;
                          handleMoveItemToFolder({
                            itemId,
                            docType: itemType,
                            moveToFolderId: folderId,
                          });
                          // handleMoveItemToFolder(itemId, itemType, folderId);
                        }}
                        onDropTargetChange={(id, { isOver, canDrop }) => {
                          setCurrentDropDestinationId(
                            isOver && canDrop ? id : null
                          );
                        }}
                      >
                        <FolderContainer folder={folder} />
                      </DropTarget>
                    )
                  )}
                </div>
              ) : null}
            </div>
            <div className="mt-6">
              <div
                className="w-[70px] flex items-center gap-x-3 cursor-pointer"
                onClick={() => setShowFiles(!showFiles)}
              >
                <ChevronIcon
                  className={`text-gray-500 mt-0.5 ${
                    showFiles ? "" : "-rotate-90"
                  }`}
                />
                <p className="out-500-14 leading-5 text-gray-500">Files</p>
              </div>
              {showFiles ? (
                <div className="flex flex-wrap gap-4 mt-4">
                  {filterArrayObjectsByValue(files, "name", search).map(
                    (file) => (
                      <DragSource
                        key={file.id}
                        id={file.id}
                        type="file" // "file" do not change this
                        onDrag={(type, id) => {
                          setCurrentDragSource({
                            type,
                            id,
                          });
                        }}
                        disabled={false}
                      >
                        <FileContainer key={file.id} file={file} />
                      </DragSource>
                    )
                  )}
                </div>
              ) : null}
            </div>
            <div className="mt-6">
              <div
                className="w-[70px] flex items-center gap-x-3 cursor-pointer"
                onClick={() => setShowLinks(!showLinks)}
              >
                <ChevronIcon
                  className={`text-gray-500 mt-0.5 ${
                    showLinks ? "" : "-rotate-90"
                  }`}
                />
                <p className="out-500-14 leading-5 text-gray-500">Links</p>
              </div>
              {showLinks ? (
                <div className="flex flex-wrap gap-4 mt-4">
                  {filterArrayObjectsByValue(links, "name", search).map(
                    (link) => (
                      <DragSource
                        key={link.id}
                        id={link.id}
                        type="link" // "links" do not change this
                        onDrag={(type, id) => {
                          setCurrentDragSource({
                            type,
                            id,
                          });
                        }}
                        disabled={false}
                      >
                        <LinkContainer key={link.id} link={link} />
                      </DragSource>
                    )
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default DocsView;

const ListViewForDocuments = ({ documentsList }) => {
  // const renderDocumentTag = (type) => {
  //   switch (type) {
  //     case "folder":
  //       return "/images/icons/folder-fill-vault.svg";
  //     case "link":
  //       return "/images/v2/library/link-icon.svg";
  //     case "pdf":
  //       return "images/v2/library/pdf-file-icon.svg";
  //     case "excel":
  //       return "images/v2/library/excel-file-icon.svg";
  //     case "image":
  //       return "images/v2/library/image-file-icon.svg";
  //     default:
  //       return "images/v2/library/default-file-icon.svg";
  //   }
  // };
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const { tenantMembers } = useAppSelector((state) => state.tenant);

  const getDocImage = (record) => {
    if (record.docType === "folder") {
      return "/images/icons/folder-fill-vault.svg";
    }
    if (record.docType === "link") {
      return "/images/v2/library/link-icon.svg";
    }
    return renderFileTag(record.mimeType);
  };

  const renderFileTag = (mimeType = "") => {
    console.log(mimeType, "hvjhvjhvj");
    if (mimeType.includes("pdf")) {
      return "/images/v2/library/pdf-file-icon.svg";
    } else if (mimeType.includes("excel") || mimeType.includes("spreadsheet")) {
      return "/images/v2/library/excel-file-icon.svg";
    } else if (mimeType.includes("image")) {
      return "/images/v2/library/image-file-icon.svg";
    } else if (mimeType.includes("video")) {
      return "/images/v2/library/video-file-icon.svg";
    } else {
      return "/images/v2/library/default-file-icon.svg";
    }
  };

  const handelDownload = async (record) => {
    const { data } = await client.get("/repository/all-files", {
      params: { folderId: record.id, projectId },
    });

    if (data.result && data.result.length > 0) {
      const fileUrls = data.result.map((d) => d.url);
      const fileSizes = data.result.map((d) => d.fileSize);

      downloadFiles(fileUrls, fileSizes);
    } else {
      message.error("Nothing to download!", 3);
    }
  };

  const onDownload = (record) => {
    const link = document.createElement("a");
    link.download = record.name;
    link.href = record.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    storeDataConsumed(record.fileSize);
  };

  const [copy, setCopy] = useState(false);

  const getMenuItems = (docType, record) => {
    if (docType === "folder") {
      return {
        items: [
          {
            key: "1",
            label: (
              <div className="out-300-14 leading-5 text-gray-700">
                View details
              </div>
            ),
            icon: <EllipseIcon className="text-gray-700" />,
          },
          { key: "2", type: "divider" },
          {
            key: "3",
            label: (
              <div className="out-300-14 leading-5 text-gray-700">
                Open comments
              </div>
            ),
            icon: (
              <CommentIcon className="text-gray-700" width="16" height="16" />
            ),
          },
          {
            key: "4",
            label: (
              <div
                className={`out-300-14 leading-5 text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              >
                Share
              </div>
            ),

            icon: (
              <ShareAccessIcon
                className={`text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              />
            ),
          },
          {
            key: "5",
            label: (
              <div className="out-300-14 leading-5 text-gray-700">
                Send in message
              </div>
            ),
            icon: (
              <ShareInMessageIcon
                className="text-gray-700 "
                width="16"
                height="16"
              />
            ),
          },
          {
            key: "6",
            label: (
              <div
                className={`out-300-14 leading-5 text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              >
                Rename
              </div>
            ),

            icon: (
              <EditIcon
                className={`text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              />
            ),
          },
          {
            key: "7",
            label: (
              <div className="out-300-14 leading-5 text-gray-700">
                Make a copy
              </div>
            ),
            icon: <CopyIcon className="text-gray-700" />,
          },
          {
            key: "8",
            label: (
              <div className="out-300-14 leading-5 text-gray-700">Download</div>
            ),
            icon: <DownloadIcon className="text-gray-700" />,
          },
          { key: "9", type: "divider" },
          {
            key: "10",
            label: (
              <div
                className={`out-300-14 leading-5 text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              >
                Delete
              </div>
            ),

            icon: (
              <DeleteIcon
                className={`text-gray-700 ${
                  record.canEdit ? "opacity-100" : "opacity-50"
                }`}
              />
            ),
          },
        ],
        onClick: ({ key }) => {
          switch (key) {
            case "1":
              dispatch(
                updateVault({
                  key: "viewDetailsDrawer",
                  value: {
                    visible: true,
                    documentType: "folder",
                    document: {
                      title: record.folderName,
                      usersWithAccess: record.usersWithAccess,
                      createdAt: record.createdAt,
                      filesCount: record.filesCount,
                      docId: record.id,
                      createdById: record.createdById,
                      folderSize: record.folderSize,
                    },
                  },
                })
              );
              break;
            case "3":
              dispatch(
                updateVault({
                  key: "openCommentsModal",
                  value: {
                    visible: true,
                    docType: "folder",
                    name: record.folderName,
                    docId: record.id,
                    comments: [],
                    users: record.usersWithAccess,
                  },
                })
              );
              break;
            case "4":
              if (record.canEdit ? false : true) {
                return dispatch(
                  updateVault({
                    key: "requestAccessModal",
                    value: {
                      visible: true,
                      data: {
                        type: docType,
                        record,
                      },
                    },
                  })
                );
              }
              dispatch(
                updateVault({
                  key: "shareAccessModal",
                  value: {
                    visible: true,
                    docId: record.id,
                    users: record.usersWithAccess,
                    docType: "folder",
                    docDetails: {
                      ...record,
                    },
                  },
                })
              );
              break;
            case "5":
              dispatch(
                updateVault({
                  key: "shareInMessageModal",
                  value: {
                    type: "folder",
                    visible: true,
                    url:
                      window.location.href +
                      `/${record.folderName}-${record.id}?shared-folder-id=${record.id}`,
                    file: [],
                  },
                })
              );
              break;
            case "6":
              if (record.canEdit ? false : true) {
                return dispatch(
                  updateVault({
                    key: "requestAccessModal",
                    value: {
                      visible: true,
                      data: {
                        type: docType,
                        record,
                      },
                    },
                  })
                );
              }
              dispatch(
                updateVault({
                  key: "renameModal",
                  value: {
                    visible: true,
                    type: "folder",
                    id: record.id,
                    currentName: record.folderName,
                  },
                })
              );
              break;
            case "7":
              dispatch(
                updateVault({
                  key: "copyModal",
                  value: {
                    visible: true,
                    docType: "folder",
                    id: record.id,
                  },
                })
              );
              break;
            case "8":
              handelDownload(record);
              break;
            case "10":
              if (record.canEdit ? false : true) {
                return dispatch(
                  updateVault({
                    key: "requestAccessModal",
                    value: {
                      visible: true,
                      data: {
                        type: docType,
                        record,
                      },
                    },
                  })
                );
              }
              dispatch(
                updateVault({
                  key: "deleteFolderModal",
                  value: {
                    visible: true,
                    id: record.id,
                  },
                })
              );
              break;
            default:
              console.log("item others");
              break;
          }
        },
      };
    }
    if (docType === "link") {
      const groupItems = [
        {
          key: "1",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Edit
            </div>
          ),

          icon: (
            <EditIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "2",
          label: (
            <div className="out-300-14 leading-5 text-gray-700">
              {copy ? "Copied" : "Copy Link"}
            </div>
          ),
          icon: <CopyIcon className="text-gray-700" />,
        },
        {
          key: "3",
          label: (
            <div className="out-300-14 leading-5 text-gray-700">
              View details
            </div>
          ),
          icon: <EllipseIcon className="text-gray-700" />,
        },
        { key: "4", type: "divider" },
        {
          key: "5",
          label: (
            <div className="out-300-14 leading-5 text-gray-700">
              Open comments
            </div>
          ),
          icon: (
            <CommentIcon className="text-gray-700" width="16" height="16" />
          ),
        },
        {
          key: "6",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Share access
            </div>
          ),

          icon: (
            <ShareAccessIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "7",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Move to
            </div>
          ),

          icon: (
            <MoveToIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },

        { key: "8", type: "divider" },
        {
          key: "9",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Delete
            </div>
          ),
          disabled: record.canEdit ? false : true,
          icon: (
            <DeleteIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
      ];

      const onClick: MenuProps["onClick"] = ({ key }) => {
        switch (key) {
          case "1":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "editLinkModal",
                value: {
                  visible: true,
                  linkName: record.name,
                  url: record.url,
                  username: record.username,
                  password: record.password,
                  description: record.description,
                  linkId: record.id,
                },
              })
            );
            dispatch(
              updateVault({
                key: "newLinkModal",
                value: true,
              })
            );
            break;
          case "2":
            copyTextToClipboard(record.url);
            setCopy(true);
            break;
          case "3":
            dispatch(
              updateVault({
                key: "viewDetailsDrawer",
                value: {
                  visible: true,
                  document: {
                    title: record.name,
                    usersWithAccess: record.usersWithAccess,
                    createdAt: record.createdAt,
                    docId: record.id,
                    createdById: record.createdById,
                  },
                  documentType: "link",
                },
              })
            );
            break;
          case "5":
            dispatch(
              updateVault({
                key: "openCommentsModal",
                value: {
                  visible: true,
                  docType: "link",
                  name: record.name,
                  docId: record.id,
                  comments: [],
                  users: record.usersWithAccess,
                },
              })
            );
            break;
          case "6":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "shareAccessModal",
                value: {
                  visible: true,
                  docId: record.id,
                  users: record.usersWithAccess,
                  docType: "link",
                  docDetails: {
                    ...record,
                  },
                },
              })
            );
            break;
          case "7":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "moveToFolderModal",
                value: {
                  visible: true,
                  docId: record.id,
                  docType: "link",
                },
              })
            );
            break;
          case "9":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "deleteLinkModal",
                value: {
                  visible: true,
                  id: record.id,
                },
              })
            );
            break;
          default:
            console.log("item others");
            break;
        }
      };
      return { items: groupItems, onClick };
    }
    if (docType === "file") {
      const groupItems = [
        {
          key: "1",
          label: (
            <div className="out-300-14 leading-5 text-gray-700">
              View details
            </div>
          ),
          icon: <EllipseIcon className="text-gray-700" />,
        },
        { key: "2", type: "divider" },
        {
          key: "3",
          label: (
            <div className="out-300-14 leading-5 text-gray-700">
              Open comments
            </div>
          ),
          icon: (
            <CommentIcon className="text-gray-700" width="16" height="16" />
          ),
        },
        {
          key: "4",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Share Access
            </div>
          ),

          icon: (
            <ShareAccessIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "5",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Move to
            </div>
          ),

          icon: (
            <MoveToIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "6",
          label: (
            <div className="out-300-14 leading-5 text-gray-700">
              Send in message
            </div>
          ),
          icon: <ShareInMessageIcon className="text-gray-700" />,
        },
        {
          key: "7",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Rename
            </div>
          ),

          icon: (
            <EditIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
        {
          key: "8",
          label: (
            <div className="out-300-14 leading-5 text-gray-700">
              Make a copy
            </div>
          ),
          icon: <CopyIcon className="text-gray-700" />,
        },
        {
          key: "9",
          label: (
            <div className="out-300-14 leading-5 text-gray-700">Download</div>
          ),
          icon: <DownloadIcon className="text-gray-700" />,
        },
        { key: "10", type: "divider" },
        {
          key: "11",
          label: (
            <div
              className={`out-300-14 leading-5 text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            >
              Delete
            </div>
          ),

          icon: (
            <DeleteIcon
              className={`text-gray-700 ${
                record.canEdit ? "opacity-100" : "opacity-50"
              }`}
            />
          ),
        },
      ];
      const onClick: MenuProps["onClick"] = ({ key }) => {
        switch (key) {
          case "1":
            dispatch(
              updateVault({
                key: "viewDetailsDrawer",
                value: {
                  visible: true,
                  documentType: "file",
                  document: {
                    title: record.name,
                    usersWithAccess: record.usersWithAccess,
                    createdAt: record.createdAt,
                    filesCount: record.filesCount,
                    docId: record.id,
                    createdById: record.createdById,
                    fileSize: record.fileSize,
                    mimeType: record.mimeType,
                  },
                },
              })
            );
            break;
          case "3":
            dispatch(
              updateVault({
                key: "openCommentsModal",
                value: {
                  visible: true,
                  docType: "link",
                  name: record.name,
                  docId: record.id,
                  comments: [],
                  users: record.usersWithAccess,
                },
              })
            );
            break;
          case "4":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "shareAccessModal",
                value: {
                  visible: true,
                  docId: record.id,
                  users: record.usersWithAccess,
                  docType: "file",
                  docDetails: {
                    ...record,
                  },
                },
              })
            );
            break;
          case "5":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "moveToFolderModal",
                value: {
                  visible: true,
                  docId: record.id,
                  docType: "file",
                },
              })
            );
            break;
          case "6":
            dispatch(
              updateVault({
                key: "shareInMessageModal",
                value: {
                  type: "file",
                  visible: true,
                  url: "",
                  file: [
                    {
                      name: record.name,
                      url: record.url,
                      size: record.fileSize,
                      type: record.mimeType,
                      lastModified: uniqid(`${record.name}-`),
                      savedInVault: true,
                    },
                  ],
                },
              })
            );
            break;
          case "7":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "renameModal",
                value: {
                  visible: true,
                  type: "file",
                  id: record.id,
                  currentName: record.name,
                },
              })
            );
            break;
          case "8":
            dispatch(
              updateVault({
                key: "copyModal",
                value: {
                  visible: true,
                  docType: "file",
                  id: record.id,
                },
              })
            );
            break;
          case "9":
            onDownload(record);
            break;
          case "11":
            if (record.canEdit ? false : true) {
              return dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    data: {
                      type: docType,
                      record,
                    },
                  },
                })
              );
            }
            dispatch(
              updateVault({
                key: "deleteFileModal",
                value: {
                  visible: true,
                  id: record.id,
                },
              })
            );
            break;
          default:
            console.log("item others");
            break;
        }
      };

      return { items: groupItems, onClick };
    }
  };

  const getOwnerDetails = (id) =>
    tenantMembers.filter((user) => user.userId === id)[0];

  const columns = [
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          Name
        </div>
      ),
      width: "20%",
      dataIndex: "title",

      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <div className="flex items-center gap-x-3 ">
          {record.phaseId ? (
            <Tooltip
              className="text-white cursor-pointer"
              title={getPhaseName(record.phaseId)}
            >
              <div>
                <PhaseIcon
                  phaseId={+record.phaseId}
                  width="10px"
                  height="10px"
                />
              </div>
            </Tooltip>
          ) : (
            <div className="w-2 h-2 rounded-[2px] bg-transparent"></div>
          )}

          <img src={getDocImage(record)} className="w-[15px] h-[15px]" />
          <p className="out-500-14 leading-5 text-gray-700 truncate">
            {record.name || record.folderName}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          size
        </div>
      ),
      width: "7%",
      dataIndex: "size",
      render: (text, record) => (
        <div className="out-300-14 leading-5 text-gray-500  whitespace-nowrap">
          {record.fileSize
            ? bytesToSize(record.fileSize)
            : record.folderSize
            ? bytesToSize(record.folderSize)
            : "0kb"}
        </div>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 uppercase whitespace-nowrap">
          Date Uploaded
        </div>
      ),
      width: "15%",
      dataIndex: "createdAt",

      sorter: (a, b) => a.id - b.id,
      render: (text) => (
        <p className="out-300-14 leading-5 text-gray-500 pl-2 whitespace-nowrap">
          {text ? dayjs(text).format("MMMM DD YYYY") : "-"}
        </p>
      ),
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px] text-gray-500 uppercase">
          Owner
        </div>
      ),
      width: "20%",
      dataIndex: "createdById",
      render: (text) => {
        const ownerDetails = getOwnerDetails(text);
        return (
          <div className="flex items-center gap-x-3 ">
            <CustomAvatar
              title={`${
                ownerDetails?.firstname ? ownerDetails.firstname : "User"
              } ${ownerDetails?.lastname ? ownerDetails.lastname : ""}`}
              src={ownerDetails?.profilePicture}
              size={24}
              whiteText
              color={ownerDetails?.profilePictureColorCode}
            />
            <p className="out-300-14 leading-5 text-gray-500 whitespace-nowrap">
              {`${ownerDetails?.firstname ? ownerDetails.firstname : "User"} ${
                ownerDetails?.lastname ? ownerDetails.lastname : ""
              }`}
            </p>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="out-500-12 leading-[18px]  text-gray-500 uppercase">
          collaborators
        </div>
      ),
      width: "15%",
      dataIndex: "usersWithAccess",

      sorter: (a, b) => a.id - b.id,
      render: (text, record) => (
        <>
          {text ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  updateVault({
                    key: "shareAccessModal",
                    value: {
                      visible: true,
                      docId: record.id,
                      users: record.usersWithAccess,
                      docType: record.docType,
                      docDetails: {
                        ...record,
                      },
                    },
                  })
                );
              }}
              className="flex justify-start  items-center "
            >
              <p className="out-300-14 leading-5 text-gray-500 whitespace-nowrap">
                {text.length} people
              </p>
              <ChevronIcon className="text-gray-500 ml-1" />
            </div>
          ) : null}
        </>
      ),
    },
    {
      title: () => <img src="/images/icons/message-plus-circle.svg" />,
      width: "5%",
      render: (text, record) => (
        <button
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              updateVault({
                key: "openCommentsModal",
                value: {
                  visible: true,
                  docType: record.docType,
                  name: record.folderName || record.name,
                  docId: record.id,
                  comments: [],
                  users: record.usersWithAccess,
                },
              })
            );
          }}
        >
          <CommentWithRedDotIcon />
        </button>
      ),
    },
    {
      width: "5%",
      render: (text, record) => (
        <button onClick={(e) => e.stopPropagation()}>
          <Dropdown
            menu={getMenuItems(record.docType, record)}
            trigger={["click"]}
            dropdownRender={(origin) => {
              return <div className="w-[250px]">{origin}</div>;
            }}
          >
            <DotsVerticalIcon className="text-black" />
          </Dropdown>
        </button>
      ),
    },
  ];

  const [limit, setLimit] = useState(1000);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  console.log(documentsList, "documentsList");
  return (
    <div className="">
      <CustomTable
        // customPadding="p-3"
        className="custom-header cursor-pointer"
        // rowClassName="border-none"
        //scroll={projectList ? "calc(100vh-325px)" : "0px"}
        columns={columns}
        data={[...documentsList]}
        dataCount={documentsList.length}
        current={currentPageNo}
        pageSize={limit}
        setPageSize={setLimit}
        setCurrent={(e) => {
          setCurrentPageNo(e);
        }}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              if (record.docType === "folder") {
                const newPath =
                  location.pathname + `/${record.folderName}-${record.id}`;
                navigate(newPath);
              }

              if (record.docType === "file") {
                dispatch(
                  updateConversations({
                    key: "filePreview",
                    value: {
                      visible: true,
                      currentIndex: 0,
                      from: "library",
                      docs: [record],
                      createdAt: record.createdAt,
                    },
                  })
                );
              }

              if (record.docType === "link") {
                window.open(record.url, "_blank", "noopener,noreferrer");
              }
            },
          };
        }}
      />
    </div>
  );
};
