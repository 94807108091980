import React from "react";
import { useAppSelector } from "../../../Core/redux/hooks";
import { useDraggable } from "@dnd-kit/core";

const KanbanItem = ({ id, children }) => {
  const { selectedTasksForDragAndDrop } = useAppSelector(
    (state) => state.schedule
  );

  const { attributes, transform, listeners, setNodeRef, active } = useDraggable(
    {
      id: id,
      disabled: false,
      data: {
        // selectedItems,
        dragId: id,
        // task,
      },
    }
  );

  let style: React.CSSProperties =
    transform && active
      ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          zIndex: 1,
          position: "relative",
        }
      : undefined;
  if (!transform && active && selectedTasksForDragAndDrop.includes(id)) {
    style = { opacity: 0 };
  }
  return (
    <div style={style} ref={setNodeRef} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

export default KanbanItem;
