import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";

import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";
import { Dropdown, Popover, Tooltip } from "antd";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import { CopyIcon } from "../../../Core/svgV2/CopyIcon";
import { EditIcon } from "../../../Core/svgV2/EditIcon";

import { PlusIcon } from "../../../Core/svgV2/PlusIcon";

import ImportRolesModal from "../Modals/ImportRolesModal";
import CreateAndEditRoleModal from "../Modals/CreateAndEditRoleModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { client } from "../../../Core/utils/axiosClient";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { getAllRoles } from "../../../Core/redux/api/settingsAPI";
import useSearch from "../../../Core/hooks/useSearch";
import { SearchProjects } from "../SettingsLayout";
import DeleteRoleModal from "../Modals/DeleteRoleModal";
import {
  resetSettings,
  updateSettings,
} from "../../../Core/redux/slices/settings.slice";
import ProjectsSettingsWrapper from "../ProjectsSettingsWrapper";
import usePermission from "../../../Core/hooks/usePermission";

interface Props {
  text: string;
  title: string;
  role: any;
}

export const RoleCard = ({
  text = "0 members",
  title,
  role,
}: Props): JSX.Element => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const projectId = searchParams.get("projectId");
  const groupItems = [
    {
      key: "1",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">View & edit</div>
      ),

      icon: <EditIcon className="text-gray-700" />,
    },
    {
      key: "2",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Make a copy</div>
      ),
      icon: <CopyIcon className="text-gray-700" />,
    },
    {
      key: "3",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Manage members</div>
      ),
      icon: <PlusIcon className="text-gray-700" />,
    },

    { key: "divider", type: "divider" },
    {
      key: "4",
      label: <div className="out-300-14 text-gray-700">Delete</div>,

      icon: <DeleteIcon className="text-gray-700" />,
    },
  ];

  const handleCopy = async () => {
    try {
      await client.post("permissions/copy-role", {
        projectId,
        roleId: role.id,
      });
      dispatch(getAllRoles({ projectId }));
    } catch (err) {
      console.log(err);
    }
  };

  const getUrl = (tabName: string) => {
    const baseUrl = `/settingsV2?tab=${tabName}`;
    if (searchParams.has("projectId")) {
      return baseUrl + `&projectId=${searchParams.get("projectId")}`;
    }
    return baseUrl;
  };

  const onClick = ({ key }) => {
    switch (key) {
      case "1":
        dispatch(
          updateSettings({
            key: "showRoleModal",
            value: {
              visible: true,
              mode: "edit",
              role,
            },
          })
        );
        break;
      case "2":
        handleCopy();
        break;
      case "3":
        navigate(getUrl("ProjectMembers"));
        break;
      case "4":
        setShowDeleteRoleModal(true);
        break;
      default:
        break;
    }
  };

  const { hasPermission } = usePermission("2");

  return (
    <div
      className={`relative group rounded-lg px-3 py-2.5 border w-[201px] border-gray-200`}
    >
      <div className="out-500-14 text-gray-700">{title}</div>
      <div className="mt-1.5 out-300-14 text-gray-500">{text}</div>

      {hasPermission && (
        <Dropdown
          onOpenChange={(e) => setDropdownOpen(e)}
          menu={{ items: groupItems, onClick }}
          trigger={["click"]}
          dropdownRender={(origin) => {
            return <div className="w-[250px]">{origin}</div>;
          }}
        >
          <button
            className={`absolute top-[10px] right-[12px] cursor-pointer group-hover:block ${
              dropdownOpen ? "block" : "hidden"
            }`}
          >
            <DotsVerticalIcon />
          </button>
        </Dropdown>
      )}

      {showDeleteRoleModal && (
        <DeleteRoleModal
          visible={showDeleteRoleModal}
          onClose={() => {
            dispatch(getAllRoles({ projectId }));
            setShowDeleteRoleModal(false);
          }}
          role={role}
        />
      )}
    </div>
  );
};

const ProjectRolesAndPermissions = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [showImportModal, setShowImportModal] = useState(false);
  const { hasPermission: MANAGE_MEMBERS } = usePermission("1");

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [currentSelectedProject, setCurrentSelectedProject] = useState({
    id: "",
    title: "",
  });

  const { roleList, showRoleModal } = useAppSelector((state) => state.settings);

  const onProjectClick = (project) => {
    setShowImportModal(true);
    setPopoverOpen(false);
    setCurrentSelectedProject(project);
  };

  useEffect(() => {
    if (searchParams.has("projectId")) {
      dispatch(getAllRoles({ projectId: searchParams.get("projectId") }));
    }
  }, [searchParams]);

  const { search, setSearch, filteredData } = useSearch(roleList, ["roleName"]);

  return (
    <div className="pb-40">
      <PageTitle
        title={"Roles & Permissions"}
        subtext={"Manage project roles and permissions."}
      />
      <ProjectsSettingsWrapper>
        <div className="my-6 flex justify-between">
          <CustomSearchBox
            placeholder="Search roles"
            style={{ width: "50%" }}
            search={search}
            setSearch={setSearch}
          />

          {MANAGE_MEMBERS && (
            <div className="flex gap-x-3">
              <Popover
                trigger={"click"}
                onOpenChange={(val) => setPopoverOpen(val)}
                content={
                  <SearchProjects
                    currentSelectedProject={currentSelectedProject}
                    onProjectClick={onProjectClick}
                  />
                }
                placement="bottom"
                arrow={false}
                open={popoverOpen}
              >
                <Tooltip title="Import roles from other projects">
                  <div>
                    <CustomButton
                      height="31px"
                      text="Import Roles"
                      className="bg-white border-gray-300 border"
                    />
                  </div>
                </Tooltip>
              </Popover>
              <CustomButton
                height="30px"
                text="New Role"
                onClick={() =>
                  dispatch(
                    updateSettings({
                      key: "showRoleModal",
                      value: {
                        visible: true,
                        mode: "new",
                        role: {},
                      },
                    })
                  )
                }
              />
            </div>
          )}
        </div>

        <div className="flex gap-x-3">
          <div className="out-500-14 text-gray-500">Roles</div>{" "}
          <div className="bg-primary-50 px-2 py-0.5 rounded-full out-500-12 text-primary-600">
            {roleList.length}
          </div>
        </div>
        <div className="flex flex-wrap gap-8 mt-6 w-[667px]">
          {filteredData.map((role) => (
            <RoleCard
              key={role.id}
              title={role.roleName}
              text={`${role.userCount} members`}
              role={role}
            />
          ))}
        </div>
      </ProjectsSettingsWrapper>

      <ImportRolesModal
        visible={showImportModal}
        onClose={() => {
          dispatch(getAllRoles({ projectId: searchParams.get("projectId") }));
          setShowImportModal(false);
        }}
        currentSelectedProject={currentSelectedProject}
        setCurrentSelectedProject={setCurrentSelectedProject}
      />

      <CreateAndEditRoleModal
        visible={showRoleModal.visible}
        onClose={() => {
          dispatch(resetSettings(["showRoleModal"]));
        }}
      />
    </div>
  );
};

export default ProjectRolesAndPermissions;
