import React from "react";
import { ArrowIcon } from "../svgV2/Arrow";
import { resetToInitialSchedule } from "../redux/slices/schedule.slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { resetToInitialVault } from "../redux/slices/vault.slice";
import { resetToInitialOverview } from "../redux/slices/overview.slice";
import { resetToInitialProjects } from "../redux/slices/projects.slice";
import { useNavigate } from "react-router-dom";
import { updateTourStatus } from "../redux/api/dashboardAPI";

const TourPanel = ({
  step,
  current,
  total,
  img,
  title,
  description,
  initialProjectId = "",
  module = "MessageTour",
  nextStepText = "",
  actionButton = {
    text: "",
    onClick: () => {
      console.log("");
    },
  },
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userDetails);
  const navigate = useNavigate();
  return (
    <div className="bg-white p-3 rounded-lg w-[403px] shadow-lg relative overflow-hidden">
      <div className="out-500-16 text-primary-600">{title}</div>
      <div className="flex gap-x-[23px] items-center">
        <div className="out-300-14 text-gray-500 w-[246px] mt-2 leading-5">
          {description}
        </div>
        <div>
          <img src={img} />
        </div>
      </div>
      <div className="mt-3 out-500-14 text-gray-700 leading-5">
        {current + 1}/{total}
      </div>
      <div className="mt-3">
        {actionButton.text && (
          <button onClick={actionButton.onClick}>
            <div className="flex items-center gap-x-2">
              <span className="text-primary-700 out-500-14">
                {actionButton.text || ""}
              </span>{" "}
            </div>
          </button>
        )}
        <button
          onClick={async () => {
            console.log(step, "sdcsd");
            if (current + 1 === total) {
              localStorage.setItem(module, "1");
              localStorage.removeItem("appTour");
              step.onNext();
              if (module == "ScheduleTour") {
                dispatch(resetToInitialSchedule({}));
                await dispatch(
                  updateTourStatus({
                    tourStatus: "scheduleTourStatus",
                    userId: user.id,
                  })
                );
                if (initialProjectId) {
                  navigate(`/schedule/${initialProjectId}/timeline`);
                } else {
                  navigate("/dashboard");
                }
              } else if (module === "LibraryTour") {
                dispatch(resetToInitialVault({}));
                dispatch(resetToInitialOverview({}));
                dispatch(resetToInitialProjects({}));
                await dispatch(
                  updateTourStatus({
                    tourStatus: "libraryTourStatus",
                    userId: user.id,
                  })
                );
                window.location.reload();
              }
            }
            step.onNext();
          }}
        >
          {current + 1 === total ? (
            <div className="flex items-center gap-x-2">
              <span className="text-primary-700 out-500-14">Finish Tour</span>{" "}
            </div>
          ) : (
            <div className="flex items-center gap-x-2">
              <span className="text-primary-700 out-500-14">
                {nextStepText ? nextStepText : "Next"}
              </span>{" "}
              {nextStepText ? "" : <ArrowIcon className="text-primary-600" />}
            </div>
          )}
        </button>
      </div>
      <div className="flex items-center absolute left-0 right-0 bottom-0 rounded-b-lg ">
        <div
          className="inline-block bg-primary-700 h-1 "
          style={{
            width: `${(403 / total) * (current + 1)}px`,
          }}
        ></div>
        <div className="inline-block flex-1 bg-gray-200 h-1 "></div>
      </div>
    </div>
  );
};

export default TourPanel;
