import dayjs from "dayjs";
import { client } from "./axiosClient";
import { logDcfActivity } from "../..";
import { functionalUpdate } from "react-query/types/core/utils";
import { useAppSelector } from "../redux/hooks";

export function storeDataConsumed(data) {
  console.log("file size on download", data);
  if (data > 0) {
    const existingDataConsumed =
      localStorage.getItem("dataConsumedInBytes") || "0";

    const newDataConsumed = +existingDataConsumed + +data;

    localStorage.setItem("dataConsumedInBytes", newDataConsumed.toString());
  }
}

/**
 * Calculates the time difference between the current time and a given start time,
 * updates the total time active, and sends a POST request to the API endpoint to log the activity.
 * @param {number} totalTimeActive - The total time active in seconds.
 * @param {string} startTime - The start time in string format.
 * @returns {void}
 */
export function calculateTimeDifference(totalTimeActive, startTime) {
  const dataConsumedInBytes = localStorage.getItem("dataConsumedInBytes");
  const userLocation = localStorage.getItem("userLocation");
  const user = localStorage.getItem("user");

  const currentTime = dayjs(); // Get current time using dayjs
  const timeSpent = currentTime.diff(startTime, "second"); // Calculate difference in seconds
  totalTimeActive += timeSpent;
  console.log(
    totalTimeActive,
    timeSpent,
    currentTime,
    startTime,
    "total time , time spent , currenttime"
  );
  if (user) {
    logDcfActivity(dataConsumedInBytes, userLocation, totalTimeActive);
  }
}
