import React from "react";
import Icon from "@ant-design/icons";

const ExportBoxIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <g>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.5 5h-2.667c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 00-1.092 1.093C10.833 6.9 10.833 7.6 10.833 9v1M17.5 5L15 2.5M17.5 5L15 7.5m-6.667-5H6.5c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 00-1.093 1.093C2.5 4.4 2.5 5.1 2.5 6.5v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 001.093 1.092C4.4 17.5 5.1 17.5 6.5 17.5h7c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 001.092-1.092c.273-.535.273-1.235.273-2.635v-1.833"
      ></path>
    </g>
  </svg>
);

const ExportBoxIcon = (props) => (
  <Icon component={ExportBoxIconSvg} {...props} />
);

export { ExportBoxIcon };
