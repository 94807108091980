import { createSlice } from "@reduxjs/toolkit";
import {
  getAlertSeverity,
  getAllAutomation,
  getDefaultNavigation,
  getNotificationActions,
} from "../api/settingsAPI";
import { getProjectUsers } from "../api/projectAPI";
import {
  getAllMembers,
  getAllRoles,
  getMetaCategories,
} from "../api/settingsAPI";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  loggedIn: false,
  currentTab: 1,
  tabs: [
    {
      id: 1,
      name: "Details",
    },
    {
      id: 2,
      name: "Notifications",
    },
    {
      id: 3,
      name: "Roles",
    },
    {
      id: 4,
      name: "Team Members",
    },
    {
      id: 5,
      name: "Schedule Metadata",
    },
  ],
  productTabs: [
    {
      id: 1,
      name: "My Account",
    },
    {
      id: 2,
      name: "Integrations",
    },
    {
      id: 3,
      name: "Members",
    },
    {
      id: 4,
      name: "Support",
    },
  ],
  currentProductTab: 1,
  memberList: [],

  selectedMembers: [],
  categoryList: [],
  selectedRole: [],
  notificationActions: [],
  alertSeverity: [],
  projectUsers: [],

  // nee States

  removeMemberModal: {
    visible: false,
    action: "removeTenantMember",
    user: { userId: "" },
  },
  guestInteractionSettingsModal: {
    visible: false,
    email: "",
    userId: "",
    allowedUsers: [],
  },
  newGuestInteractionSettings: [], //userid,allowedusers
  allowedUsersForGuest: [],
  roleList: [],
  showRoleModal: {
    visible: false,
    mode: "new",
    role: { id: "", roleName: "" },
  },
  automations: [],
  defaultNavigation: {
    isEnabled: false,
    id: null,
    screenId: null,
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.currentTab = action.payload;
    },
    changeProductTab: (state, action) => {
      state.currentProductTab = action.payload;
    },
    handleSelect: (state, action) => {
      if (state.selectedMembers.includes(action.payload)) {
        state.selectedMembers = state.selectedMembers.filter(
          (i) => i !== action.payload
        );
      } else {
        state.selectedMembers = [...state.selectedMembers, action.payload];
      }
    },
    resetToInitialSetting: (state, action) => {
      Object.keys(initialState).forEach(
        (key) => (state[key] = initialState[key])
      );
    },
    handleRoleSelect: (state, action) => {
      state.selectedRole = action.payload;
    },
    updateSettings: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    resetSettings: (state, action) => {
      action.payload.map((ele) => {
        state[ele] = initialState[ele];
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.memberList = action.payload;
      })
      .addCase(getAllMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllAutomation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAutomation.fulfilled, (state, action) => {
        state.loading = false;
        state.automations = action.payload;
      })
      .addCase(getAllAutomation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDefaultNavigation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDefaultNavigation.fulfilled, (state, action) => {
        state.loading = false;
        state.defaultNavigation = action.payload.result;
      })
      .addCase(getDefaultNavigation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roleList = action.payload;
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMetaCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMetaCategories.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload, "task");
        state.categoryList = action.payload;
      })
      .addCase(getMetaCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getNotificationActions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationActions.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload, "task");
        state.notificationActions = action.payload;
      })
      .addCase(getNotificationActions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAlertSeverity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAlertSeverity.fulfilled, (state, action) => {
        state.loading = false;
        state.alertSeverity = action.payload;
      })
      .addCase(getAlertSeverity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProjectUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.projectUsers = action.payload.result;
      })
      .addCase(getProjectUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  changeTab,
  changeProductTab,
  handleSelect,
  handleRoleSelect,
  resetToInitialSetting,
  resetSettings,
  updateSettings,
} = settingsSlice.actions;
export default settingsSlice.reducer;
