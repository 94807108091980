import React, { useEffect, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  deleteScheduleTasks,
  getGoalsBySprint,
  getTodoList,
  updateGoal,
  updateProgress,
} from "../../../Core/redux/api/scheduleAPI";
import { useNavigate, useParams } from "react-router-dom";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import { useQueryClient } from "react-query";
import { useGetProjectList } from "../../../Core/redux/ReactQueryHooksV3/useProjectAPI";
import { deleteMyBoardTasks } from "../../../Core/redux/api/myBoardAPI";
import { updateMyBoard } from "../../../Core/redux/slices/myBoard.slice";

const DeleteTaskModal = ({
  visible,
  onClose,
  isPlanner = false,
  selectedTasks = [],
}) => {
  const dispatch = useAppDispatch();
  const { projectId, taskId, subtaskId, sprintId } = useParams();
  const { goals, deleteTaskModal } = useAppSelector((state) => state.schedule);
  const queryClient: any = useQueryClient();

  const [selectedTaskId, setSelectedTaskId] = useState(
    isPlanner ? deleteTaskModal.goalId : taskId
  );
  const { user } = useAppSelector((state) => state.userDetails);

  const { data: projectList, isLoading: isLoadingProjectList } =
    useGetProjectList(user.id);
  useEffect(() => {
    if (isPlanner) {
      setSelectedTaskId(deleteTaskModal.goalId);
    }
  }, [deleteTaskModal, isPlanner]);
  const navigate = useNavigate();
  const selectedGoal = goals.find((goal) => +goal.id == +selectedTaskId);

  if (selectedTasks.length === 0 && deleteTaskModal?.goalId) {
    selectedTasks = [deleteTaskModal.goalId];
  }

  const handleDelete = async () => {
    if (projectId) {
      await dispatch(deleteScheduleTasks({ taskIds: [...selectedTasks] }));
      dispatch(
        updateProgress({
          projectId,
        })
      );
      if (selectedGoal?.sprintId || sprintId) {
        dispatch(
          getGoalsBySprint({ projectId, sprintId: sprintId, module: "sprints" })
        );

        // dispatch(getSprintsByPhase({ projectId }));
      } else {
        queryClient.invalidateQueries(["todoList", projectId]);
        queryClient.invalidateQueries([
          "goalsBySprint",
          projectId,
          +deleteTaskModal.sprintId,
        ]);
        dispatch(getTodoList({ projectId }));
      }
      dispatch(
        updateSchedule({
          key: "selectedTasks",
          value: [],
        })
      );
    } else {
      dispatch(deleteMyBoardTasks({ taskIds: [...selectedTasks] }));
    }

    dispatch(
      updateSchedule({
        key: "deleteTaskModal",
        value: {
          ...deleteTaskModal,
          visible: false,
          multiple: false,
          goalId: null,
        },
      })
    );
    const currentUrl = localStorage.getItem("taskRedirectUrl");
    dispatch(updateSchedule({ key: "isTaskExpanded", value: false }));
    if ((taskId || subtaskId) && projectId) {
      if (currentUrl) {
        localStorage.removeItem("taskRedirectUrl");
        navigate(currentUrl);
      } else {
        // navigate(-1);
        let redirectToLink = "";
        if (projectList?.length > 0) {
          const project = projectList?.find(
            (project) => +project.id === +projectId
          );
          redirectToLink = project?.currentactivesprint
            ? `/schedule/${project.id}/sprints/${project.currentactivesprint}`
            : `/schedule/${project.id}/sprints`;
        }
        redirectToLink ? navigate(redirectToLink) : navigate(-1);
      }
    } else {
      if (taskId || subtaskId) {
        navigate(-1);
      }
      dispatch(
        updateMyBoard({
          key: "selectedTasks",
          value: [],
        })
      );
    }

    onClose();
  };
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-error-700 out-500-14">Delete Task</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <img src="/images/v2/common/delete-modal.svg" className="mt-4" />
          <p className="mt-4 out-300-14 text-gray-500 leading-5">
            Are you sure you want to delete this task?
            <br /> This action cannot be undone.
          </p>
          <div className="flex mt-[21px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="bg-transparent text-gray-500"
              height="30px"
              width="64px"
              onClick={() => {
                if (projectId) {
                  onClose();
                } else {
                  dispatch(
                    updateMyBoard({
                      key: "selectedTasks",
                      value: [],
                    })
                  );
                  dispatch(
                    updateMyBoard({
                      key: "deleteTaskModal",
                      value: {
                        ...deleteTaskModal,
                        visible: false,
                        multiple: false,
                        goalId: null,
                      },
                    })
                  );
                }
              }}
            />
            <CustomButton
              text="Delete"
              height="30px"
              width="101px"
              className="bg-error-700 text-white"
              onClick={handleDelete}
            />
          </div>
        </div>
      }
    />
  );
};

export default DeleteTaskModal;
