import React, { useEffect, useRef, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { EditIcon } from "../../../Core/svgV2/EditIcon";
import { DeleteCalendarIcon } from "../../../Core/svgV3/DeleteIcon";
import { CrossIconSecondary } from "../../../Core/svgV2/CrossIcon";
import { Divider, Dropdown } from "antd";
import { CircleIcon } from "../../../Core/svgV3/CalendarCircleIcon";
import { CopyIcon } from "../../../Core/svgV3/CopyLinkIcon";
import { MarkerPinIcon } from "../../../Core/svgV3/MarkerPinIcon";
import { DotsPointsIcon } from "../../../Core/svgV3/DotsPointsIcon";
import { UsersIcon } from "../../../Core/svgV3/UsersIcon";
import { CalendarAvatarIcon } from "../../../Core/svgV3/CalendarAvatarIcon";
import { YesResponseIcon } from "../../../Core/svgV3/YesResponseIcon";
import { NoResponseIcon } from "../../../Core/svgV3/NoResponseIcon";
import { MaybeResponseIcon } from "../../../Core/svgV3/MaybeResponseIcon";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import dayjs from "dayjs";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import NewTimeModal from "./NewTimeModal";
import EditEventModal from "./EditEventModal";
import CancelEventModal from "./CancelEventModal";
import { updateEventResponseV3 } from "../../../Core/redux/api/scheduleAPI";
import { client } from "../../../Core/utils/axiosClient";
import Quill from "quill";
import { CalendarIcon } from "../../../Core/svgV2/CalendarIcon";
import { CalendarNotificationIcon } from "../../../Core/svgV3/NotificationCalendarIcon";

const AttendeeCard = ({ attendee, status }) => {
  let responseIcon;
  let optionalLabel = "";

  if (status === "accepted") {
    responseIcon = <YesResponseIcon />;
  } else if (status === "needsAction" && attendee?.organizer) {
    responseIcon = <YesResponseIcon />;
  } else if (status === "tentative") {
    responseIcon = <MaybeResponseIcon />;
  } else if (status === "declined") {
    responseIcon = <NoResponseIcon />;
  }

  if (attendee.optional && attendee.organizer) {
    optionalLabel = "(Organizer, Optional)";
  } else if (attendee.optional) {
    optionalLabel = "(Optional)";
  } else if (attendee.organizer) {
    optionalLabel = "(Organizer)";
  }

  return (
    <div className="px-3 py-2 justify-start items-center gap-3 inline-flex">
      <div className="">
        {attendee.external ? (
          <CalendarAvatarIcon />
        ) : (
          <CustomAvatar
            size={24}
            title={attendee.title}
            src={attendee.profilePicture}
          />
        )}
      </div>
      <div className="text-zinc-800 text-sm font-light font-['Outfit'] leading-tight">
        {attendee.title}{" "}
        {optionalLabel && (
          <span className="text-gray-400">{optionalLabel}</span>
        )}
      </div>
      <div className="">{responseIcon}</div>
    </div>
  );
};

const ReadOnlyQuill = ({ content }) => {
  const [quill, setQuill] = useState(null);

  useEffect(() => {
    const newQuill = new Quill("#quill-container", {
      theme: "snow",
      modules: {
        readOnly: true, // Use ReadOnly module to disable editing
        toolbar: false, // Disable toolbar
      },
    });
    setQuill(newQuill);
  }, []);

  useEffect(() => {
    if (quill) {
      quill.root.innerHTML = content;
    }
  }, [content, quill]);

  return (
    <div
      id="quill-container"
      className="event-info"
      style={{ border: "none" }}
    />
  );
};

const EventInfoModal = ({ visible, onClose }) => {
  const {
    selectedEvent,
    newTimeModal,
    editEventModal,
    cancelEventModal,
    myCalendarTimelineSelected,
    currentDate,
  } = useAppSelector((state) => state.dashboard);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(10);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [selectedResponse, setSelectedResponse] = useState("");
  const attendees = selectedEvent ? selectedEvent?.attendees : [];
  const dispatch = useAppDispatch();
  const getSelectedNotification = (reminders) => {
    if (!reminders || reminders.useDefault) {
      return 10;
    } else {
      const overridePopup = reminders?.overrides?.find(
        (override) => override.method === "popup"
      );
      return overridePopup ? overridePopup.minutes : 10;
    }
  };
  const notifyContent = [
    {
      key: "5",
      label: "5 minutes before the meeting",
      onClick: () => {
        setDropdownVisible(false);
        setSelectedNotification(5);
      },
    },
    {
      key: "10",
      label: "10 minutes before the meeting",
      onClick: () => {
        setDropdownVisible(false);

        setSelectedNotification(10);
      },
    },
    {
      key: "15",
      label: "15 minutes before the meeting",
      onClick: () => {
        setDropdownVisible(false);

        setSelectedNotification(15);
      },
    },
    {
      key: "30",
      label: "30 minutes before the meeting",
      onClick: () => {
        setDropdownVisible(false);

        setSelectedNotification(30);
      },
    },
    {
      key: "60",
      label: "1 hour before the meeting",
      onClick: () => {
        setDropdownVisible(false);
        setSelectedNotification(60);
      },
    },
    {
      key: "1440",
      label: "1 day before the meeting",
      onClick: () => {
        setDropdownVisible(false);

        setSelectedNotification(1440);
      },
    },
  ];

  useEffect(() => {
    if (visible && selectedEvent && selectedEvent.attendees) {
      const attendee = selectedEvent.attendees.find(
        (attendee) =>
          attendee.email === user.email &&
          attendee.self &&
          attendee.organizer &&
          attendee.responseStatus === "needsAction"
      );

      if (attendee) {
        setSelectedResponse("accepted");
      } else {
        setSelectedResponse(
          selectedEvent.attendees.find(
            (attendee) => attendee.email === user.email && attendee.self
          )?.responseStatus || ""
        );
      }
    }
    setSelectedNotification(getSelectedNotification(selectedEvent.reminders));

    console.log(selectedEvent, "selected event info");
  }, [visible]);

  const updatedAttendees = attendees?.map((attendee) => {
    const user = tenantMembers.find((user) => user.email === attendee.email);
    if (user) {
      return {
        ...attendee,
        title: `${user.firstname} ${user.lastname}`,
        profilePicture: user.profilePicture,
        external: false,
      };
    } else {
      return {
        ...attendee,
        title: attendee.email,
        profilePicture: "",
        external: true,
      };
    }
  });
  const handleDropDownVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };
  //note : we are considering that the logged in user email is the email used for google calendar
  const handleMenuClick = async ({ key }) => {
    setSelectedResponse(key);
    const payload = {
      eventId: selectedEvent?.id,
      attendeeEmail: user.email,
      response: key,
      attendees: attendees
        .map((attendee) => {
          return attendee;
        })
        .filter((attendee) => attendee.email !== user.email),
    };
    console.log(payload, "selectedResponse Payload");

    await dispatch(updateEventResponseV3(payload));
    setPopoverVisible(false);
    dispatch(
      updateDashboard({
        key: "alertPopupModal",
        value: {
          visible: true,
          data: {
            title: `Event ${key}`,
            subtitle: `${selectedEvent?.summary} on ${dayjs(
              selectedEvent?.start?.dateTime
            )
              ?.format("DD MMMM")
              .replace(/(^|\s)\S/g, (firstLetter) =>
                firstLetter.toUpperCase()
              )}, ${dayjs(selectedEvent?.start?.dateTime).format("h:mmA")}`,
          },
        },
      })
    );
    setSelectedResponse("");
    getEvents();
    onClose();
  };

  let displayDate;
  if (selectedEvent?.start.date && selectedEvent?.end.date) {
    const startDate = dayjs(selectedEvent?.start.date);
    displayDate = `${startDate.format("dddd, DD MMMM")} - All Day Event`;
  } else {
    const startDateTime = dayjs(selectedEvent?.start.dateTime);
    const endDateTime = dayjs(selectedEvent?.end.dateTime);
    displayDate = `${startDateTime.format(
      "dddd, DD MMMM hh:mm A"
    )} - ${endDateTime.format("hh:mm A")}`;
  }

  const Response = [
    {
      key: "accepted",
      label: "Yes",
      icon: <YesResponseIcon />,
    },
    {
      key: "declined",
      label: "No",
      icon: <NoResponseIcon />,
    },
    {
      key: "tentative",
      label: "Maybe",
      icon: <MaybeResponseIcon />,
    },
  ];

  const countStatus = (status) => {
    return attendees?.filter((attendee) => attendee.responseStatus === status)
      .length;
  };

  const yesCount = countStatus("accepted");
  const maybeCount = countStatus("tentative");
  const noCount = countStatus("declined");
  const awaitingCount = attendees?.length - (yesCount + maybeCount + noCount);

  const allDeclined =
    selectedEvent?.organizer?.email === user.email &&
    attendees?.length > 1 &&
    attendees
      ?.filter((att) => att.email !== user.email)
      ?.every((att) => att.responseStatus === "declined");

  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(selectedEvent.hangoutLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const getEvents = async () => {
    try {
      let minTime, maxTime;

      const timeZoneOffsetMinutes = dayjs().utcOffset();

      // For day view
      if (myCalendarTimelineSelected === 0) {
        minTime = dayjs(currentDate)
          .startOf("day")
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
        maxTime = dayjs(currentDate)
          .endOf("day")
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
      }
      // For week view
      else if (myCalendarTimelineSelected === 1) {
        minTime = dayjs(currentDate)
          .startOf("week")
          .startOf("day")
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
        maxTime = dayjs(currentDate)
          .endOf("week")
          .endOf("day")
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
      } else if (myCalendarTimelineSelected === 2) {
        const firstDayOfMonth = dayjs(currentDate)
          .clone()
          .startOf("month")
          .startOf("week");
        const lastDayOfMonth = dayjs(currentDate)
          .clone()
          .endOf("month")
          .endOf("week");

        minTime = firstDayOfMonth
          .utcOffset(timeZoneOffsetMinutes)
          .toISOString();
        maxTime = lastDayOfMonth.utcOffset(timeZoneOffsetMinutes).toISOString();
      }

      if (myCalendarTimelineSelected !== 3) {
        const { data: events } = await client.get(
          "/integration/calender/get-events",
          {
            params: {
              userId: user.id,
              date: dayjs(currentDate).format("YYYY-MM-DD"),
              timeMin: minTime,
              timeMax: maxTime,
            },
          }
        );

        console.log(events, "these are the events ");

        dispatch(
          updateDashboard({
            key: "events",
            value: events?.events || [],
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = () => {
    dispatch(
      updateDashboard({
        key: "cancelEventModal",
        value: false,
      })
    );
    getEvents();
    dispatch(
      updateDashboard({
        key: "alertPopupModal",
        value: {
          visible: true,
          data: {
            title: `Event Cancelled`,

            subtitle: `${selectedEvent.summary} on ${dayjs(
              selectedEvent?.start?.date
            )
              ?.format("DD MMMM")
              .replace(/(^|\s)\S/g, (firstLetter) =>
                firstLetter.toUpperCase()
              )}, ${dayjs(selectedEvent?.start?.dateTime).format("h:mm a")}`,
            description: "",
          },
        },
      })
    );
    onClose();
  };
  const formatLabel = (minutes) => {
    if (minutes === "60") {
      return "1 hour";
    } else if (minutes === "1440") {
      return "1 day";
    } else {
      return `${minutes} minutes`;
    }
  };
  return (
    <CustomModal
      visible={visible}
      onCancel={() => onClose()}
      width="480px"
      height="546px"
      body={
        <div className="bg-slate-50 flex flex-col justify-between rounded-xl h-[546px] ">
          <div>
            <div className="p-4 flex justify-between">
              <div className="text-zinc-800 text-base font-medium font-['Outfit'] leading-normal">
                Event Info
              </div>
              <div className="flex gap-x-3">
                {selectedEvent?.organizer?.self && (
                  <div className="flex gap-x-3">
                    <button
                      onClick={() => {
                        dispatch(
                          updateDashboard({
                            key: "editEventModal",
                            value: true,
                          })
                        );
                      }}
                    >
                      <EditIcon />
                    </button>
                    <button
                      onClick={() => {
                        dispatch(
                          updateDashboard({
                            key: "cancelEventModal",
                            value: true,
                          })
                        );
                      }}
                    >
                      <DeleteCalendarIcon className="grey-500" />
                    </button>
                  </div>
                )}
                <button onClick={onClose}>
                  <CrossIconSecondary className="grey-500" />
                </button>
              </div>
            </div>
            <Divider className="m-0" />
            <div className="max-h-[422px]  overflow-y-auto p-4  scroll-show custom-scrollbar">
              <div className="flex flex-col gap-y-4">
                <div>
                  <div className="flex items-center gap-x-2">
                    <div className="mt-[3px]">
                      <CircleIcon
                        colorNumber={
                          +selectedEvent?.selectedColor !== -1
                            ? selectedEvent?.selectedColor
                            : 6
                        }
                      />
                    </div>
                    <div className="text-zinc-800 text-base font-medium ">
                      {selectedEvent?.summary}
                    </div>
                  </div>
                  <div className="ml-5 text-gray-500 text-sm font-light ">
                    <div>{displayDate}</div>
                  </div>
                </div>

                {selectedEvent?.hangoutLink && (
                  <div className="flex gap-x-2.5 items-center">
                    <CopyIcon onClick={handleCopyLink} />
                    <div className="flex flex-grow">
                      <p className="text-grey-500 out-300-12">
                        {selectedEvent?.hangoutLink}
                      </p>
                    </div>
                    <button
                      onClick={() =>
                        window.open(selectedEvent?.hangoutLink, "_blank")
                      }
                      className="bg-primary-600 text-white out-500-14 w-[170px] h-[30px] py-[5px] px-[10px] items-center rounded-[4px]"
                    >
                      Join With Google Meet
                    </button>
                  </div>
                )}

                <div>
                  {selectedEvent?.location && (
                    <div className="flex items-center gap-x-2">
                      <MarkerPinIcon />
                      <div className="text-slate-700 text-sm font-medium">
                        {selectedEvent?.location}
                      </div>
                    </div>
                  )}
                  <div className="ml-6 text-gray-500 text-xs font-light">
                    {selectedEvent?.location}
                  </div>
                </div>
                {selectedEvent?.description && (
                  <div className=" flex gap-x-2 items-start">
                    <div className="mt-[3px]">
                      <DotsPointsIcon />
                    </div>
                    <ReadOnlyQuill content={selectedEvent?.description} />
                  </div>
                )}
                {selectedEvent?.attendees && (
                  <div>
                    <div className="flex gap-x-2">
                      <UsersIcon />
                      <div className="flex flex-grow text-slate-700 text-sm font-medium">
                        People Attending
                      </div>
                      <div className="text-gray-500 text-xs font-light">
                        {allDeclined
                          ? `${noCount} No`
                          : `${yesCount} Yes, ${maybeCount} Maybe, ${awaitingCount} awaiting`}
                      </div>
                    </div>
                    <div className="flex flex-col px-4">
                      {updatedAttendees?.map((attendee, index) => (
                        <AttendeeCard
                          key={index}
                          attendee={attendee}
                          status={attendee.responseStatus}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {/* <Dropdown
                  menu={{
                    items: notifyContent,
                  }}
                  open={dropdownVisible}
                  onOpenChange={handleDropDownVisibleChange}
                  placement="top"
                  trigger={["click"]}
                > */}
                <div className="flex ml-[-1px] text-black text-sm font-light out-300-14 gap-x-2">
                  <CalendarNotificationIcon />
                  <p className="ml-[-1px]">
                    {formatLabel(selectedNotification)} before the meeting
                  </p>
                  {/* <ChevronIcon
                    style={{
                      transform: dropdownVisible ? "rotate(180deg)" : "none",
                    }}
                  /> */}
                </div>
                {/* </Dropdown> */}
                <div>
                  <div className="flex gap-x-2">
                    <CalendarIcon className="text-gray-500" />
                    <div className="flex flex-grow text-gray-500 out-300-14 text-sm font-medium">
                      Created on{" "}
                      {+selectedEvent?.selectedColor === -1
                        ? "Google"
                        : "Waddle"}{" "}
                      Calendar
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Divider className="m-0" />

            {allDeclined ? (
              <div className="flex justify-between p-4 items-center">
                <div className="flex gap-2">
                  <InfoCircleOutlined className="text-error-700" />
                  <p>Everyone else declined the event</p>
                </div>
                <button
                  className="border border-slate-300 rounded-lg px-2.5 py-[5px]"
                  onClick={() => console.log("Remove event")}
                >
                  <p
                    className="text-slate-700 out-500-14"
                    onClick={() => {
                      dispatch(
                        updateDashboard({
                          key: "cancelEventModal",
                          value: true,
                        })
                      );
                    }}
                  >
                    Remove
                  </p>
                </button>
              </div>
            ) : (
              <div
                className={`flex ${
                  +selectedEvent?.selectedColor === -1 ||
                  selectedEvent?.organizer.self
                    ? "justify-end"
                    : "justify-between"
                } p-4 items-center`}
              >
                {+selectedEvent?.selectedColor !== -1 &&
                  !selectedEvent?.organizer?.self && (
                    <CustomButton
                      className="rounded border border-slate-300 text-slate-700 out-500-14 text-sm font-medium py-[5px] px-[10px]"
                      text="Propose a new time"
                      onClick={() => {
                        dispatch(
                          updateDashboard({
                            key: "newTimeModal",
                            value: true,
                          })
                        );
                      }}
                    />
                  )}
                <Dropdown
                  menu={{
                    items: Response,
                    onClick: handleMenuClick,
                  }}
                  trigger={["click"]}
                  placement="top"
                  open={popoverVisible}
                  onOpenChange={handleDropDownVisibleChange}
                >
                  <div>
                    {selectedResponse ? (
                      <>
                        {selectedResponse === "accepted" && (
                          <div
                            role="button"
                            className="flex items-center gap-3 bg-emerald-50 rounded border border-slate-300 text-gray-700 text-sm font-medium py-2 px-5"
                          >
                            <YesResponseIcon />
                            <p>Yes</p>
                            <ChevronIcon
                              style={{
                                transform: popoverVisible
                                  ? "rotate(180deg)"
                                  : "none",
                              }}
                            />
                          </div>
                        )}
                        {selectedResponse === "declined" && (
                          <div
                            role="button"
                            className="flex items-center gap-3 bg-red-50 rounded border border-slate-300 text-gray-700 text-sm font-medium py-2 px-5"
                          >
                            <NoResponseIcon />
                            <p>No</p>
                            <ChevronIcon
                              style={{
                                transform: popoverVisible
                                  ? "rotate(180deg)"
                                  : "none",
                              }}
                            />
                          </div>
                        )}
                        {selectedResponse === "tentative" && (
                          <div
                            role="button"
                            className="flex items-center gap-3 bg-slate-100 rounded border border-slate-300 text-gray-700 text-sm font-medium py-[8px] px-[10px]"
                          >
                            <MaybeResponseIcon />
                            <p>Maybe</p>
                            <ChevronIcon
                              style={{
                                transform: popoverVisible
                                  ? "rotate(180deg)"
                                  : "none",
                              }}
                            />
                          </div>
                        )}
                        {selectedResponse === "needsAction" && (
                          <div
                            role="button"
                            className="flex gap-x-3 bg-slate-100 rounded border border-slate-300 text-gray-500 text-sm font-medium py-[5px] px-[10px]"
                          >
                            Going ?
                            <ChevronIcon
                              style={{
                                transform: popoverVisible
                                  ? "rotate(180deg)"
                                  : "none",
                              }}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          role="button"
                          className="flex gap-x-3 bg-slate-100 rounded border border-slate-300 text-gray-500 text-sm font-medium py-[5px] px-[10px] justify-end"
                        >
                          Going ?
                          <ChevronIcon
                            style={{
                              transform: popoverVisible
                                ? "rotate(180deg)"
                                : "none",
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Dropdown>
              </div>
            )}
            {newTimeModal && (
              <NewTimeModal
                visible={newTimeModal}
                onClose={() => {
                  dispatch(
                    updateDashboard({
                      key: "newTimeModal",
                      value: false,
                    })
                  );
                }}
              />
            )}
            {editEventModal && (
              <EditEventModal
                visible={editEventModal}
                onClose={() => {
                  dispatch(
                    updateDashboard({
                      key: "editEventModal",
                      value: false,
                    })
                  );
                }}
              />
            )}
            {cancelEventModal && (
              <CancelEventModal
                visible={cancelEventModal}
                onClose={() => {
                  dispatch(
                    updateDashboard({
                      key: "cancelEventModal",
                      value: false,
                    })
                  );
                }}
                selectedEventId={selectedEvent?.id}
                onDelete={handleDelete}
              />
            )}
          </div>
        </div>
      }
    />
  );
};

export default EventInfoModal;
