import React from "react";
import { createGlobalState } from "react-hooks-global-state";

const initialState = { fileState: [], uploadingState: false };
const { useGlobalState } = createGlobalState(initialState);

const useFileList = () => {
  const [fileState, setFileState] = useGlobalState("fileState");
  const [uploadingState, setUploadingState] = useGlobalState("uploadingState");
  return { fileState, setFileState, uploadingState, setUploadingState };
};
export default useFileList;
