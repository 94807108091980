import React, { useEffect, useState } from "react";
import PlannerAccordian from "./PlannerAccordian";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import TaskItem from "./TaskItem";
import CreateCTA from "./CreateCTA";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import usePermission from "../../../Core/hooks/usePermission";
import { useDroppable } from "@dnd-kit/core";
import { updateSchedule } from "../../../Core/redux/slices/schedule.slice";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";
import { useGetTodoList } from "../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { useQueryClient } from "react-query";
import {
  PrimaryTaskHeader,
  SelectedTaskHeader,
  CreateTaskHeader,
} from "./BacklogTaskHeader";
import MyBoardLabel from "../../../Core/CommonV3/MyBoardLabel";
import CustomPagination from "../../../Core/CommonV3/CustomPagination";
import { DEFAULT_LIMIT } from "../../../Core/constants";
import MoveToProjectModal from "./MoveToProjectModal";
import ExportModal from "../Modals/ExportModal";
import { getTodoList } from "../../../Core/redux/api/scheduleAPI";
import useDebounce from "../../../Core/hooks/useDebounceValue";
import SubTaskItem from "./SubtaskItem";

const ToDoList = () => {
  const [checkedTask, setCheckedTask] = useState([]);
  const [open, setOpen] = useState(true);
  const dispatch = useAppDispatch();

  const queryClient: any = useQueryClient();
  const { projectId } = useParams();
  const {
    todoList,
    phases,
    labels,
    filterGoalParameter,
    selectedTasks,
    createTaskHeader,
    todoListCount,
    projectListModal,
    exportModal,
    isMyTasks,
  } = useAppSelector((state) => state.schedule);
  const { user } = useAppSelector((state) => state.userDetails);
  const [search, setSearch] = useState(
    filterGoalParameter?.searchValue?.projectId === projectId &&
      filterGoalParameter?.searchValue?.module === "planner"
      ? filterGoalParameter?.searchValue?.searchValue?.toLowerCase()
      : ""
  );
  useEffect(
    () =>
      setSearch(
        filterGoalParameter?.searchValue?.projectId === projectId &&
          filterGoalParameter?.searchValue?.module === "planner"
          ? filterGoalParameter?.searchValue?.searchValue?.toLowerCase()
          : ""
      ),
    [filterGoalParameter]
  );
  const debouncedValue = useDebounce(search, 500);
  const { hasPermission: PHASE_AND_SPRINT_ADD } = usePermission("5");

  const [offset, setOffset] = useState(0);
  // const {
  //   data: Data,
  //   isLoading: todoListLoading,
  //   refetch: refetchTodoList,
  // } = useGetTodoList({
  //   projectId,
  //   limit: DEFAULT_LIMIT,
  //   offset,
  // });
  // const todoListData = Data?.result;

  // useEffect(() => {
  //   refetchTodoList({});
  // }, [offset]);

  useEffect(() => {
    const ffilterGoalParams = filterGoalParameter.assignee as any;
    const payload: {
      limit: number;
      offset: number;
      searchQuery?: string;
      projectId: string;
      assignee?: any;
    } = {
      limit: DEFAULT_LIMIT,
      offset: offset,
      projectId,
      searchQuery: search,
      assignee: ffilterGoalParams?.assignee,
    };

    dispatch(getTodoList(payload));
  }, [offset, debouncedValue]);

  useEffect(() => {
    dispatch(
      updateSchedule({
        key: "selectedTasks",
        value: [],
      })
    );
  }, [projectId]);

  const handleFilter = (goals) => {
    let filteredGoals = [...goals];
    const filterParams = filterGoalParameter;

    //is my task filter on
    if (isMyTasks) {
      filteredGoals = filteredGoals.filter((goal) => {
        if (goal?.collaborators?.length > 0) {
          return goal?.collaborators?.some(
            (collaborator) => collaborator === user.id
          );
        } else {
          return goal.assigneeId === user.id;
        }
      });
    } else {
      // filter by assignee
      if (filterParams?.assignee.length > 0) {
        const currentProjectFilter = filterGoalParameter?.assignee?.find(
          (filter) => +filter.projectId === +projectId
        );
        if (
          currentProjectFilter !== undefined &&
          currentProjectFilter?.assignee.length > 0
        ) {
          filteredGoals = filteredGoals.filter((goal) =>
            currentProjectFilter?.assignee?.includes(goal.assigneeId)
          );
        }
      }
    }

    // filter by search value
    const searchValue =
      filterGoalParameter?.searchValue?.projectId === projectId &&
      filterGoalParameter?.searchValue?.module === "planner"
        ? filterParams?.searchValue?.searchValue?.toLowerCase()
        : "";
    filteredGoals = filteredGoals.filter((goal) => {
      if (goal?.subtasks?.length) {
        const filteredSubtasks = [...goal.subtasks].filter((goal) => {
          return (
            goal.name.toLowerCase().includes(searchValue) ||
            goal.id == searchValue ||
            goal.identifier?.includes(searchValue)
          );
        });
        if (filteredSubtasks.length) return true;
        return (
          goal.name.toLowerCase().includes(searchValue) ||
          goal.id == searchValue ||
          goal.identifier?.includes(searchValue)
        );
      }
      return (
        goal.name.toLowerCase().includes(searchValue) ||
        goal.id == searchValue ||
        goal.identifier?.includes(searchValue)
      );
    });

    return filteredGoals;
  };

  const handelTaskCheck = (id) => {
    if (checkedTask.includes(id)) {
      const newIds = checkedTask.filter((taskId) => taskId !== id);
      setCheckedTask(newIds);
    } else {
      setCheckedTask([...checkedTask, id]);
    }
  };

  const activePhase = phases.find((p) => p.isOngoing);

  const option = labels
    ?.filter((item) => !item.isMyBoard && item.projectId === projectId)
    ?.map((label) => {
      return {
        id: label.id,
        value: label.id,
        label: label.name,
        render: () => (
          <div className="flex items-center justify-between">
            <MyBoardLabel labelColorId={label.labelColorId} text={label.name} />

            <div className="h-[14px] w-[14px] rounded-full" />
          </div>
        ),
      };
    });
  const getHeader = () => {
    if (selectedTasks?.length > 0)
      return <SelectedTaskHeader isBacklog goalCount={todoListCount} />;
    if (createTaskHeader.visible && createTaskHeader.sprintId === 0)
      return (
        <CreateTaskHeader
          offset={offset}
          labelOptions={option}
          callBack={() => {
            queryClient.invalidateQueries(["todoList", projectId]);
            // refetchTodoList({});
          }}
          key={projectId}
        />
      );
    return <PrimaryTaskHeader selectedSprintId={0} />;
  };
  const handlePagination = async (page: number) => {
    setOffset((page - 1) * DEFAULT_LIMIT);
  };
  const handleMultipleSelect = (e, taskId) => {
    e.preventDefault();
    const isCtrlPressed = e.ctrlKey || e.metaKey;
    const currentTask = todoList?.find((item) => +item.id == taskId);
    const isCreatedByTheUser = currentTask?.createdById == user.id;

    if (isCtrlPressed) {
      dispatch(
        updateSchedule({
          key: "selectedTasks",
          value: selectedTasks?.includes(taskId)
            ? selectedTasks.filter((item) => item !== taskId)
            : [...selectedTasks, taskId],
        })
      );
    }
    if (selectedTasks?.length > 0 && !isCtrlPressed) {
      if (selectedTasks?.length > 0) {
        dispatch(
          updateSchedule({
            key: "selectedTasks",
            value: [],
          })
        );
      }
    }

    // if (isCtrlPressed) {
    //   dispatch(
    //     updateDashboard({
    //       key: "alertPopupModal",
    //       value: {
    //         visible: true,
    //         data: {
    //           title: "Action not possible",
    //           subtitle: "",
    //           description:
    //             "The task cannot be moved or deleted which are not created by you.",
    //         },
    //       },
    //     })
    //   );
    // }
  };
  return (
    <div>
      <PlannerAccordian
        sprint={null}
        title={"To-Do List"}
        key={"todoList"}
        itemsCount={todoListCount}
        open={open}
        setOpen={(val) => setOpen(val)}
        menu={<></>}
      >
        <div className="mt-3 ">
          {/* <CreateCTA
            callBack={() => {
              queryClient.invalidateQueries(["todoList", projectId]);
            }}
          /> */}
          {getHeader()}
          {/* {todoListLoading && <p>Loading...</p>} */}
          {todoList && (
            <>
              {todoList.length === 0 && (
                <div className="out-300-12 p-3 text-center w-full bg-white rounded-b-xl border border-dashed border-gray-200 text-gray-500">
                  Your to-do list is empty, create a task/bug/event to add to
                  your to-do list.{" "}
                </div>
              )}

              {handleFilter(todoList).map((task, index) => (
                <TaskItem
                  sprint={{ id: 0 }}
                  firstItem={index === 0}
                  isBacklog
                  selected={selectedTasks?.includes(task.id)}
                  taskId={task.id}
                  key={task.id}
                  task={task}
                  handleMultipleSelect={handleMultipleSelect}
                  labelOptions={option}
                  offset={offset}
                  handleFilter={handleFilter}
                />
              ))}
              {/* {handleFilter(todoList).length === 0 ? (
                todoList.map((goal) => {
                  return handleFilter(goal.subtasks).map((subtask) => (
                    <SubTaskItem key={subtask.id} subtask={subtask} />
                  ));
                })
              ) : (
                <></>
              )} */}
              {todoListCount > 0 && (
                <div className="flex p-2 bg-white rounde-b-lg justify-end w-full">
                  <CustomPagination
                    handleOnChange={handlePagination}
                    total={todoListCount}
                    pageSize={DEFAULT_LIMIT}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </PlannerAccordian>
      <MoveToProjectModal
        visible={projectListModal}
        onClose={() => {
          dispatch(
            updateSchedule({
              key: "projectListModal",
              value: false,
            })
          );
        }}
      />
      <ExportModal
        visible={exportModal}
        isBacklog
        todoListData={todoList}
        onClose={() => {
          dispatch(
            updateSchedule({
              key: "exportModal",
              value: false,
            })
          );
        }}
      />
    </div>
  );
};

export default ToDoList;
