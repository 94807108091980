import React from "react";
import Icon from "@ant-design/icons";

const ShareFileSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.333 1.514v2.753c0 .374 0 .56.073.703a.667.667 0 00.291.291c.143.073.33.073.703.073h2.754m-3.82 6h-4m5.333-2.667H5.333m8-2.008v4.808c0 1.12 0 1.68-.218 2.108a2 2 0 01-.874.874c-.427.218-.987.218-2.108.218H5.867c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874c-.218-.427-.218-.988-.218-2.108V4.534c0-1.12 0-1.68.218-2.108a2 2 0 01.874-.874c.428-.218.988-.218 2.108-.218h2.14c.49 0 .735 0 .965.055a2 2 0 01.578.24c.202.123.375.296.72.642l2.126 2.126c.346.346.519.519.643.72a2 2 0 01.24.579c.054.23.054.474.054.963z"
    ></path>
  </svg>
);

const ShareInMessageSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path stroke="currentColor" d="M6 8.533l3.598 2"></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.2 5.6a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2zM4.8 9.334a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2z"
    ></path>
    <path stroke="currentColor" d="M6.398 6.8l3.6-2"></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.2 12.8a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2z"
    ></path>
  </svg>
);

const ShareFileIcon = (props) => {
  return <Icon component={ShareFileSvg} {...props} />;
};

const ShareInMessageIcon = (props) => {
  return <Icon component={ShareInMessageSvg} {...props} />;
};

export { ShareFileIcon, ShareInMessageIcon };
