import React, { useEffect, useState } from "react";
import { PlusIcon } from "../../../Core/svgV2/PlusIcon";
import CustomAvatar from "../../../Core/Common/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { useAppSelector } from "../../../Core/redux/hooks";
import { Avatar, Tooltip } from "antd";
import { useParams } from "react-router-dom";

const getCurrentUser = ({ task, isSubTask = false }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { userList } = useAppSelector((state) => state.overview);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { collaboratorsForTask } = useAppSelector((state) => state.schedule);
  const hasCollaborators = task?.collaborators?.length > 1;
  const { taskId, subtaskId } = useParams();
  const [addedCollabs, setAddedCollabs] = useState([]);
  useEffect(() => {
    const collaboratorList = userList.filter((user) =>
      collaboratorsForTask.includes(user.userId)
    );
    setAddedCollabs([...collaboratorList]);
  }, [collaboratorsForTask]);
  return (
    <>
      {" "}
      {taskId || subtaskId ? (
        <div className="gap-x-1 flex w-full h-full cursor-pointer">
          {collaboratorsForTask?.length > 0 && (
            <Avatar.Group
              maxCount={4}
              maxPopoverTrigger="click"
              size={24}
              maxStyle={{
                color: "#165ABF",
                backgroundColor: "#EFF8FF",
                cursor: "pointer",
              }}
            >
              {/* // .filter((u) => task.collaborators.includes(u.userId)) */}
              {taskId === "new"
                ? [...task.collaborators, ...addedCollabs]
                    ?.filter((member) => member.userId !== task.assigneeId)
                    .map((member) => {
                      return (
                        <Tooltip
                          key={member.title}
                          title={`${member.firstname} ${member.lastname}`}
                          placement="bottom"
                        >
                          <div className="cursor-pointer">
                            {" "}
                            <CustomAvatar
                              title={`${member.firstname} ${member.lastname}`}
                              src={member.profilePicture}
                              size={24}
                              whiteText
                              fontSize={"10px"}
                              color={member.profilePictureColorCode}
                            />
                          </div>
                        </Tooltip>
                      );
                    })
                : task?.collaborators
                    ?.filter((member) => member.userId !== task.assigneeId)
                    .map((member) => {
                      return (
                        <Tooltip
                          key={member.title}
                          title={`${member.firstname} ${member.lastname}`}
                          placement="bottom"
                        >
                          <div className="cursor-pointer">
                            {" "}
                            <CustomAvatar
                              title={`${member.firstname} ${member.lastname}`}
                              src={member.profilePicture}
                              size={24}
                              whiteText
                              fontSize={"10px"}
                              color={member.profilePictureColorCode}
                            />
                          </div>
                        </Tooltip>
                      );
                    })}
            </Avatar.Group>
          )}
          <div className="w-6 h-6 rounded-[12px] border border-dashed border-primary-500 relative">
            <PlusIcon className="text-primary-500 absolute top-1  right-1" />
          </div>
        </div>
      ) : (
        <div
          className="relative cursor-pointer"
          role={!isSubTask && "button"}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {!isSubTask && (
            <div
              className={`absolute top-[-6px] right-[-4px] cursor-pointer rounded-full w-[14px] h-[14px] z-20 ${
                hasCollaborators ? "bg-success-600" : "bg-primary-100"
              }`}
            >
              {hasCollaborators ? (
                <p className="text-center cursor-pointer text-white out-600-8 pt-[1px]">
                  +{task?.collaborators?.length - 1}
                </p>
              ) : (
                <div className="cursor-pointer">
                  <PlusIcon
                    width={10}
                    height={10}
                    className="text-primary-600"
                  />
                </div>
              )}
            </div>
          )}
          <CustomAvatar
            title={getUsersName(user)}
            src={user.profilePicture}
            size={20}
            fontSize={"12px"}
            whiteText
            color={user.profilePictureColorCode}
          />
        </div>
      )}
    </>
  );
};

export default getCurrentUser;
