import React from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { useLocation, useParams } from "react-router-dom";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { createRequest } from "../../../Core/redux/api/vaultAPI";

const RequestAccessModal = ({ visible, onClose }) => {
  const { requestAccessModal, currentSelectedFolder } = useAppSelector(
    (state) => state.vault
  );

  const { user } = useAppSelector((state) => state.userDetails);

  const { projectId } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const requestAccess = async () => {
    const currentUrl = location.pathname;
    if (requestAccessModal.data.isProject) {
      /// TODO
      const req = {};
    } else if (requestAccessModal.data.type === "folder") {
      const ownerId = requestAccessModal.data.folder.createdById;
      const req = {
        projectId: +projectId,
        senderId: +user.id,
        url: `${currentUrl}?selectedFolderId=${requestAccessModal.data.folder?.id}`,
        receiverId: ownerId,
        docType: "folder",
        requestType: "Access",
        docName: requestAccessModal.data.folder?.folderName,
      };
      await dispatch(createRequest(req));
    } else if (requestAccessModal.data.type === "link") {
      const ownerId = requestAccessModal.data.link.createdById;
      const req = {
        projectId: +projectId,
        senderId: +user.id,
        url: `${currentUrl}?sharedLinkId=${requestAccessModal.data.link?.id}`,
        receiverId: ownerId,
        docType: "link",
        requestType: "Access",
        docName: requestAccessModal.data.link?.name,
      };
      await dispatch(createRequest(req));
    } else if (requestAccessModal.data.type === "file") {
      const ownerId = requestAccessModal.data.file.createdById;
      const req = {
        projectId: +projectId,
        senderId: +user.id,
        url: `${currentUrl}?sharedFileId=${requestAccessModal.data.file?.id}`,
        receiverId: ownerId,
        docType: "file",
        requestType: "Access",
        docName: requestAccessModal.data.file?.name,
      };
      await dispatch(createRequest(req));
    }

    onClose();
  };
  return (
    <CustomModal
      visible={visible}
      width="360px"
      onCancel={onClose}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <div className="flex gap-x-3.5 items-center">
              <img
                src="/images/v2/common/frosty-icon.svg"
                className="w-5 h-5"
              />
              <p className="text-black out-500-16">
                {requestAccessModal.title || "View only"}
              </p>
            </div>
            <button onClick={onClose}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <p className="mt-4 out-300-14 text-gray-500 leading-5 pb-4">
            {requestAccessModal.subtext ||
              "You can only view this folder. To make changes, ask the owner for edit access."}
          </p>

          <CustomButton
            text="Request for access"
            height="30px"
            onClick={requestAccess}
          />
        </div>
      }
    />
  );
};

export default RequestAccessModal;
