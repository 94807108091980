import React from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";

const MyCalendarLayout = ({ children }) => {
  return (
    <CustomLayout
      page="my-calendar"
      title={<div className="">My Calendar</div>}
    >
      {/* add my calendar tour component */}
      {children}
    </CustomLayout>
  );
};

export default MyCalendarLayout;
