import React, { useEffect, useState } from "react";
import MyFootprintLayout from "./MyFootPrintLayout";
import dayjs from "dayjs";
// import { DetailsIcon } from "../../Core/svgV3/Details";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { InfoIcon } from "../../Core/svgV3/InfoIcon";
import { CalendarIcon } from "../../Core/svgV2/CalendarIcon";
import Activity from "./Components/ActivityGraph";
import DCFCalendar from "./Components/DCFCalendar";
import { calculateTotalCarbonFootprintAndElectricity } from "../../Core/utils/dcfConvertor";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { Dropdown, Tooltip } from "antd";
import WelcomeModal from "./DCFModals/WelcomeModal";
import FrostyTooltipIcon from "../../Core/svgV3/FrostyTooltip";
import { CrossIcon, CrossIconLarge } from "../../Core/svgV2/CrossIcon";
import FrostyTipOfTheDayIcon from "../../Core/svgV3/FrostyTipOfTheDay";
import { tips } from "../../Core/constants";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { CheckIcon } from "../../Core/svgV2/Check";
import { updateFootprint } from "../../Core/redux/slices/footprint.slice";
import {
  getActivityMonitorChartData,
  getTodaysDcfActivity,
  getTotalDCFDetails,
} from "../../Core/redux/api/myFootprintAPI";
import { bytesToSizeForDcf } from "../../Core/utils/bytesToSize";
import {
  convertMinutesToTimeLog,
  convertMinutesToTimeLogForDcf,
} from "../../Core/utils/timeLogUtils";
import { CalendarDcfIcon } from "../../Core/svgV2/CalendarDCFIcon";
import { UserGroupIcon } from "../../Core/svgV2/UserGroup";
import { useGetTenantDetails } from "../../Core/redux/ReactQueryHooksV3/useTenantAPI";
import { motion } from "framer-motion";

const DCFHeader = ({ setSelectedMode }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { footprintMode } = useAppSelector((state) => state.footPrintDetails);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dcfModes = [
    {
      key: "1",
      label: (
        <div className=" flex justify-between w-full gap-x-1.5 py-1.5 px-3  out-300-14 text-black">
          My Footprint
          {footprintMode === 1 && <CheckIcon className="text-primary-600" />}
        </div>
      ),

      onClick: () => {
        setSelectedMode(1);
      },
    },
    {
      key: "2",
      label: (
        <div className=" flex justify-between w-full gap-x-1.5 py-1.5 px-3  out-300-14 text-black">
          Workspace Footprint
          {footprintMode === 2 && <CheckIcon className="text-primary-600" />}
        </div>
      ),

      onClick: () => {
        setSelectedMode(2);
      },
    },
  ];

  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  return (
    <div className="px-6 py-3 bg-white border-b border-gray-200 h-16 flex justify-between gap-x-3">
      <div className="flex flex-col">
        <div className="flex">
          {+user.roleId < 3 ? (
            <Dropdown
              menu={{
                items: dcfModes,
              }}
              trigger={["click"]}
              open={dropdownVisible}
              onOpenChange={handleDropDownVisibleChange}
            >
              <div className="flex flex-shrink gap-2 items-center">
                <p className="out-500-14">
                  {footprintMode === 1 ? "My Footprint" : "Workspace Footprint"}
                </p>
                <ChevronIcon
                  style={{
                    transform: dropdownVisible ? "rotate(180deg)" : "none",
                  }}
                />
              </div>
            </Dropdown>
          ) : (
            <p className="out-500-14">My Footprint</p>
          )}
        </div>
        <div>
          {footprintMode === 1 ? (
            <p className="out-300-14 text-gray-500">
              Discover the impact of your Waddle usage!
            </p>
          ) : (
            <p className="out-300-14 text-gray-500">
              Discover the total impact of Waddle usage by all the members in
              this workspace!
            </p>
          )}
        </div>
      </div>
      <div className="items-end flex ">
        <p className="out-300-14 text-gray-500">
          Today, {dayjs().format("DD MMM, YYYY")}
        </p>
      </div>
    </div>
  );
};

const TipOfTheDayClosed = ({ onTooltipClose, onClick }) => {
  return (
    <>
      <div
        className={` absolute border-[2px] border-yellow-200  overflow-hidden flex items-end w-[62px] h-[62px] rounded-[112px] bottom-4 right-4 `}
      ></div>
      <motion.div
        initial={{ x: "-70%" }} // Initial position outside the viewport on the right
        animate={{ x: 0 }} // Move to center
        transition={{ duration: 0.5 }}
        className={`absolute cursor-pointer overflow-hidden flex items-end h-[80px] rounded-[112px] bottom-[17px] right-[17px] ${
          onTooltipClose ? "" : ""
        }`}
        onClick={onClick}
      >
        <div
          className="flex w-[60px] h-[60px] rounded-[112px] bg-yellow-200 overflow-bottom-hidden   "
          style={{
            transitionDelay: "12000ms",
            transitionTimingFunction: "ease-out",
            transitionDuration: "12000ms",
          }}
        >
          <div className="absolute bottom-0 flex w-[60px] h-[12px] rounded-b-[112px] bg-[#FFFFFF]"></div>
          <motion.div
            whileHover={{ scale: 1.1, y: -10, bottom: "-8px" }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <FrostyTooltipIcon
              className=" absolute bottom-0  "
              // style={{
              //   transition: "bottom 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
              // }}
            />
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

const getTipOfTheDay = () => {
  const dayOfMonth = dayjs().date();
  const tipIndex = (dayOfMonth - 1) % tips.length;
  return tips[tipIndex];
};

const TipOfTheDay = ({ onClose }) => {
  const tip = getTipOfTheDay();
  const [visible, setVisible] = useState(true);
  const onCancel = () => {
    setVisible(false);
    onClose();
  };

  return (
    <motion.div
      initial={{ x: visible ? "10%" : 0 }} // Initial position outside the viewport on the right
      animate={{ x: visible ? 0 : "100%" }} // Move to center
      transition={{ duration: 0.4 }}
    >
      <div
        className={`absolute cursor-pointer w-[300px] h-[90px] rounded-xl border border-yellow-200 bg-yellow-50 p-3 flex flex-col items-end bottom-4 right-4 `}
      >
        <div className="flex justify-between w-full">
          <p className="text-gray-900 out-500-14">Frosty’s Tip of the Day!</p>
          <CrossIconLarge className={"text-gray-500"} onClick={onCancel} />
        </div>
        <p className="out-300-12 text-gray-800">{tip}</p>
      </div>
      <FrostyTipOfTheDayIcon className=" absolute bottom-[75px] right-9" />
    </motion.div>
  );
};

const PersonalDCFTracker = () => {
  const { user } = useAppSelector((state) => state.userDetails);
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { footprintMode, totalUsageOverview } = useAppSelector(
    (state) => state.footPrintDetails
  );
  const [tooltipClose, setTooltipClose] = useState(true);
  const [selectedMode, setSelectedMode] = useState(1);
  const dispatch = useAppDispatch();

  const { data: Data, isLoading, isError } = useGetTenantDetails();
  const tenantDetails = Data?.result;
  // useEffect(() => {
  //   if (!tooltipClose) {
  //     setTimeout(() => setTooltipClose(true), 4000);
  //   }
  // }, [tooltipClose]);
  useEffect(() => {
    let payload;
    if (footprintMode === 1) {
      payload = {
        time: "all_time",
        userId: user.id,
        tenantId: tenantDetails?.tenantId,
      };
    } else {
      payload = {
        time: "all_time",
        tenantId: tenantDetails?.tenantId,
      };
    }
    dispatch(getTotalDCFDetails({ ...payload }));
  }, [footprintMode, tenantDetails]);
  useEffect(() => {
    dispatch(updateFootprint({ key: "selectedDate", value: null }));
    dispatch(updateFootprint({ key: "view", value: 1 }));
  }, [tenantDetails]);

  const tota = calculateTotalCarbonFootprintAndElectricity(
    9.82e9,
    58560,
    user.location,
    1
  );

  return (
    <MyFootprintLayout>
      <div className="w-full h-full bg-gray-50  ">
        {/* header */}
        <DCFHeader
          setSelectedMode={(val) => {
            dispatch(updateFootprint({ key: "footprintMode", value: val }));
          }}
        />
        {/* body */}
        <div className="px-[25px] py-6 w-full h-full max-h-[calc(100vh-110px)] flex gap-x-4 relative">
          <div className="flex flex-col gap-y-4 h-full w-full">
            <div className="w-full rounded-xl bg-primary-100 h-[165px] flex  gap-y-4  relative">
              <div className="w-full rounded-xl bg-primary-100 h-[1005] flex flex-col p-4  gap-y-4  ">
                <div className="flex justify-between">
                  <Tooltip
                    placement="right"
                    title={
                      footprintMode === 1 ? (
                        <div>
                          Your total digital carbon footprint, data used and
                          total time spent on Waddle since the day you signed
                          up.
                        </div>
                      ) : (
                        <div>
                          The combined digital carbon footprint, total data
                          usage, and total time spent during logged-in sessions
                          by all members in this workspace.
                        </div>
                      )
                    }
                  >
                    <div className="flex gap-x-2 ">
                      <p className="out-500-16 text-gray-900 ">
                        {+user.roleId < 3 ? (
                          footprintMode === 1 ? (
                            "My Waddle Usage Overview"
                          ) : (
                            <p>
                              <span className="capitalize">
                                {tenantDetails?.tenantName}
                              </span>
                              &apos;s Usage Overview
                            </p>
                          )
                        ) : (
                          "My Waddle Usage Overview"
                        )}
                      </p>
                      <InfoIcon className="text-gray-500" />
                    </div>
                  </Tooltip>
                  {footprintMode === 1 ? (
                    <div className="flex bg-[#93CBFE40]  gap-x-2 z-10 px-2 rounded items-center">
                      <CalendarDcfIcon className="text-primary-600 " />
                      <p className="text-primary-600 out-300-14 ">
                        Since{" "}
                        {`${
                          dayjs(user.createdAt).isBefore("2024-05-31", "day")
                            ? "1 June, 2024"
                            : dayjs(user.createdAt).format("D MMMM, YYYY")
                        }`}
                      </p>
                    </div>
                  ) : (
                    <div className="flex bg-[#93CBFE40]  gap-x-2 z-10 px-2 rounded items-center">
                      <UserGroupIcon className="text-primary-600 " />
                      <p className="text-primary-600 out-300-14 ">
                        {`${tenantMembers.length} `}
                        member{tenantMembers.length === 1 ? "" : "s"}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex max-w-[84%] z-30">
                  <div className="flex py-4  w-full items-center justify-center">
                    <div className="flex flex-col px-4 w-full items-center justify-center border-r border-r-primary-200">
                      {totalUsageOverview?.dcf > 1000000 ? (
                        <p className="out-600-20 text-black">
                          {(totalUsageOverview?.dcf / 1000000).toFixed(2) || 0}
                          <span className="out-500-14  text-gray-600">
                            {" "}
                            tonne CO<sub>2</sub>e
                          </span>
                        </p>
                      ) : totalUsageOverview?.dcf > 1000 ? (
                        <p className="out-600-20 text-black">
                          {(totalUsageOverview?.dcf / 1000).toFixed(2) || 0}
                          <span className="out-500-14  text-gray-600">
                            {" "}
                            kgCO<sub>2</sub>e
                          </span>
                        </p>
                      ) : (
                        <p className="out-600-20 text-black">
                          {(totalUsageOverview?.dcf
                            ? totalUsageOverview.dcf.toFixed(2)
                            : 0) || 0}
                          <span className="out-500-14  text-gray-600">
                            {" "}
                            gCO<sub>2</sub>e
                          </span>
                        </p>
                      )}
                      <p className="out-300-14  text-gray-500">
                        Carbon Emitted
                      </p>
                    </div>
                  </div>
                  <div className="flex py-4  w-full items-center justify-center ">
                    <div className="flex flex-col px-4 w-full items-center justify-center border-r border-primary-200">
                      <p className="out-600-20 text-black">
                        {bytesToSizeForDcf(
                          totalUsageOverview?.totalDataConsumed
                        )?.size || 0}
                        <span className="out-500-14  text-gray-600 lowercase">
                          {" "}
                          {bytesToSizeForDcf(
                            totalUsageOverview?.totalDataConsumed
                          )?.unit || "gb"}
                        </span>
                      </p>
                      <p className="out-300-14 text-gray-500">Data Used</p>
                    </div>
                  </div>
                  <div className="flex py-4  w-full items-center justify-center ">
                    <div className="flex flex-col px-4 w-full items-center justify-center ">
                      <div className="flex items-center justify-center gap-x-2">
                        {totalUsageOverview?.totalUsageTime == 0 ||
                        !totalUsageOverview?.totalUsageTime ? (
                          <p className="out-600-20 text-black">
                            {0}{" "}
                            <span className="out-500-14 text-gray-600">m</span>
                          </p>
                        ) : (
                          <>
                            {convertMinutesToTimeLogForDcf(
                              totalUsageOverview?.totalUsageTime
                            )?.days ? (
                              <p className="out-600-20 text-black">
                                {
                                  convertMinutesToTimeLogForDcf(
                                    totalUsageOverview?.totalUsageTime
                                  )?.days
                                }{" "}
                                <span className="out-500-14 text-gray-600">
                                  d
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                            {convertMinutesToTimeLogForDcf(
                              totalUsageOverview?.totalUsageTime
                            )?.hours ? (
                              <p className="out-600-20 text-black">
                                {
                                  convertMinutesToTimeLogForDcf(
                                    totalUsageOverview?.totalUsageTime
                                  )?.hours
                                }{" "}
                                <span className="out-500-14 text-gray-600">
                                  h
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                            {convertMinutesToTimeLogForDcf(
                              totalUsageOverview?.totalUsageTime
                            )?.minutes ? (
                              <p className="out-600-20 text-black">
                                {
                                  convertMinutesToTimeLogForDcf(
                                    totalUsageOverview?.totalUsageTime
                                  )?.minutes
                                }{" "}
                                <span className="out-500-14 text-gray-600">
                                  m
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>

                      <p className="out-300-14 text-gray-500">
                        Total Session Time
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="/images/v3/footprint/footprintStatisticsBg.svg"
                alt="Penguin-with-a-laptop"
                className="h-[100%] absolute right-0 overflow-hidden  "
              />
            </div>
            <div className="w-full  h-full ">
              <Activity />
            </div>
          </div>
          <div className=" w-[25%] h-full">
            <DCFCalendar />
          </div>
          <WelcomeModal />
        </div>
      </div>
      {tooltipClose ? (
        <TipOfTheDayClosed
          onTooltipClose={tooltipClose}
          onClick={() => setTooltipClose(false)}
        />
      ) : (
        <TipOfTheDay onClose={() => setTooltipClose(true)} />
      )}
    </MyFootprintLayout>
  );
};

export default PersonalDCFTracker;
