import React, { useEffect, useRef, useState } from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { CrossIcon, CrossIconSecondary } from "../../../Core/svgV2/CrossIcon";
import {
  Checkbox,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Select,
  TimePicker,
} from "antd";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { ClockCircleOutlined } from "@ant-design/icons";
import { CalendarRightIcon } from "../../../Core/svgV3/CalendarRightIcon";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import { MarkerPinIcon } from "../../../Core/svgV3/MarkerPinIcon";
import CustomTextEditor from "../../../Core/CommonV3/CustomTextEditor";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import useSearch from "../../../Core/hooks/useSearch";
import { CalendarAvatarIcon } from "../../../Core/svgV3/CalendarAvatarIcon";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import MarkOptionalIcon from "../../../Core/svgV3/MarkOptionalIcon";
import RemoveFromEventIcon from "../../../Core/svgV3/RemoveFromEventIcon";
import { CircleIconPrimary } from "../../../Core/svgV3/CalendarCircleIcon";
import { CalendarNotificationIcon } from "../../../Core/svgV3/NotificationCalendarIcon";
import CustomRecurrenceModal from "./CustomRecurrenceModal";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import dayjs from "dayjs";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import {
  newCreateEvent,
  updateEventResponseV3,
} from "../../../Core/redux/api/scheduleAPI";
import { client } from "../../../Core/utils/axiosClient";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import CustomInputWithEdit from "../../../Core/CommonV2/CustomInputWithEdit";
import CustomInputWithSaveAndEdit from "../../../Core/Common/CustomInputWithSaveAndEdit";
import CustomOnboardingInput from "../../../Core/Common/CustomOnboardingInput";
import CustomInput from "../../../Core/Common/CustomInput";
import { Option } from "antd/lib/mentions";

function roundTimeToHalfHour(currentTime) {
  // Get the current hour and minute
  let currentHour = currentTime.getHours();
  let currentMinute = currentTime.getMinutes();

  // Round to the nearest half-hour
  if (currentMinute < 15) {
    currentMinute = 0;
  } else if (currentMinute < 45) {
    currentMinute = 30;
  } else {
    currentMinute = 0;
    currentHour++;
  }

  // Format the rounded time
  const formattedTime = formatTime(currentHour, currentMinute);

  return formattedTime;
}

function formatTime(hour, minute) {
  // Format hour and minute into 12-hour format with AM/PM
  const period = hour < 12 ? "AM" : "PM";
  hour = hour % 12;
  hour = hour ? hour : 12; // Convert hour 0 to 12
  minute = minute < 10 ? "0" + minute : minute;
  return hour + ":" + minute + " " + period;
}
const MoreInformationAccordion = ({
  onLocationChange,
  onRecurrenceChange,
  isChecked,
  handleCheckboxChange,
  selectedDate,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [recurrenceCustomLine, setRecurrenceCustomLine] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedRepitition, setSelectedRepitition] =
    useState("Doesn’t repeat");
  const { myCalendarRecurrence } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (recurrenceCustomLine) {
      dispatch(
        updateDashboard({
          key: "customRecurrenceLine",
          value: recurrenceCustomLine,
        })
      );
    } else {
      dispatch(
        updateDashboard({
          key: "customRecurrenceLine",
          value: "",
        })
      );
    }
  }, [recurrenceCustomLine]);

  const currentYear = new Date().getFullYear();
  const endDate = `${currentYear}1231T235959Z`;

  const options = [
    {
      key: "1",
      label: <div className="text-gray-900 out-300-14">Doesn’t repeat,</div>,
      onClick: () => {
        setSelectedRepitition("Doesn’t repeat");
        onRecurrenceChange("");
      },
    },
    {
      key: "2",

      label: <div className="text-gray-900 out-300-14">Daily,</div>,

      onClick: () => {
        setSelectedRepitition("Daily");
        onRecurrenceChange(`RRULE:FREQ=DAILY;UNTIL=${endDate}`);
      },
    },
    {
      key: "3",
      label: <div className="text-gray-900 out-300-14">Weekly on Monday</div>,
      onClick: () => {
        setSelectedRepitition("Weekly on Monday");
        onRecurrenceChange(`RRULE:FREQ=WEEKLY;BYDAY=MO;UNTIL=${endDate}`);
      },
    },
    {
      key: "4",
      label: (
        <div className="text-gray-900 out-300-14">
          Monthly on the second Monday
        </div>
      ),

      onClick: () => {
        setSelectedRepitition("Monthly on the second Monday");
        onRecurrenceChange(`RRULE:FREQ=MONTHLY;BYDAY=2MO;UNTIL=${endDate}`);
      },
    },
    {
      key: "5",
      label: (
        <div className="text-gray-900 out-300-14">
          Annually on {dayjs(selectedDate).format("MMMM DD")}
        </div>
      ),
      onClick: () => {
        setSelectedRepitition(
          `Annually on ${dayjs(selectedDate).format("MMMM DD")}`
        );
        onRecurrenceChange(
          `RRULE:FREQ=YEARLY;BYMONTH=7;BYMONTHDAY=14;UNTIL=${endDate}`
        );
      },
    },
    {
      key: "6",
      label: (
        <div className="text-gray-900 out-300-14">
          Every weekday (Monday to Friday)
        </div>
      ),
      onClick: () => {
        setSelectedRepitition("Every weekday (Monday to Friday)");
        const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
        onRecurrenceChange(`RRULE:FREQ=WEEKLY;BYDAY=${days};UNTIL=${endDate}`);
      },
    },
    {
      key: "7",
      label: <div className="text-gray-900 out-300-14">Custom</div>,
      onClick: () => {
        setSelectedRepitition("Custom");
        setDropdownVisible(false);

        dispatch(
          updateDashboard({
            key: "myCalendarRecurrence",
            value: true,
          })
        );
      },
    },
  ];

  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  const handleLocationChange = (e) => {
    const location = e.target.value;
    onLocationChange(location);
  };

  const handleCancelRecurrence = () => {
    dispatch(
      updateDashboard({
        key: "myCalendarRecurrence",
        value: false,
      })
    );
    setSelectedRepitition("Doesn’t repeat");
  };

  const handleChange = (isChecked) => () => {
    handleCheckboxChange(!isChecked);
  };

  return (
    <div className="px-2.5 pt-2 pb-2.5 bg-slate-100 rounded flex-col gap-2 inline-flex">
      <div className="flex gap-2" role="button" onClick={toggleAccordion}>
        <div className="flex flex-grow">
          <div className="flex flex-col gap-1">
            <p className="text-slate-700 out-500-14">More Information</p>
            <p className="text-gray-500 out-300-12">
              Add location and set the frequency of repeating this event
            </p>
          </div>
        </div>
        <div className="w-5 h-5">
          <ChevronIcon
            className={`transform ${
              accordionOpen ? "rotate-180" : ""
            } text-gray-500 cursor-pointer`}
          />
        </div>
      </div>
      {accordionOpen && (
        <div className="flex-col gap-2 flex">
          {/* Checkbox for All Day */}
          <div className="items-center gap-2 flex">
            <Checkbox
              style={{
                borderColor: "initial",
                background: "initial",
              }}
              className="createEventV3"
              checked={isChecked}
              onChange={handleChange(isChecked)}
            />
            <p className="text-slate-700 out-300-14">All Day</p>
          </div>
          {/* Repeat Options */}
          <Dropdown
            menu={{
              items: options,
            }}
            trigger={["click"]}
            open={dropdownVisible}
            className="more-options-calendar"
            dropdownRender={(menu) => <div className="">{menu}</div>}
            onOpenChange={handleDropDownVisibleChange}
          >
            <div className="flex justify-between bg-white border border-slate-200 items-center py-[5px] px-[10px] rounded">
              <p className="text-grey-900 out-300-14">
                {recurrenceCustomLine
                  ? recurrenceCustomLine
                  : selectedRepitition}
              </p>
              <ChevronIcon
                style={{
                  transform: dropdownVisible ? "rotate(180deg)" : "none",
                }}
              />
            </div>
          </Dropdown>
          {/* Location Input */}
          <CustomInputBox
            placeholder="Enter location"
            prefix={<MarkerPinIcon />}
            onChange={handleLocationChange}
          />
        </div>
      )}
      {myCalendarRecurrence && (
        <CustomRecurrenceModal
          selectedDate={selectedDate}
          visible={myCalendarRecurrence}
          onCancel={handleCancelRecurrence}
          onSave={({ recurrenceRule, recurrenceCustomLine }) => {
            onRecurrenceChange(recurrenceRule);
            handleCancelRecurrence;
            setRecurrenceCustomLine(recurrenceCustomLine);
            dispatch(
              updateDashboard({
                key: "myCalendarRecurrence",
                value: false,
              })
            );
          }}
        />
      )}
    </div>
  );
};

const NotesAccordion = ({ initialNotesContent, onSave }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [quill, setQuill] = useState(null);
  const editorContainerRef = useRef(null);
  const toolbarRef = useRef(null);

  const initializeQuillEditor = () => {
    if (!quill && editorContainerRef.current) {
      const newQuill = new Quill(editorContainerRef.current, {
        theme: "snow",
        modules: modules,
      });
      newQuill.root.innerHTML = initialNotesContent || "";
      setQuill(newQuill);
    }
  };

  const destroyQuillEditor = () => {
    if (quill) {
      quill.off("text-change");
      quill.disable(); // Disable editor before destroying
      quill.container.remove(); // Remove the editor container
      setQuill(null);
    }
  };

  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };

  useEffect(() => {
    if (accordionOpen) {
      initializeQuillEditor();
    } else {
      destroyQuillEditor();
    }
  }, [accordionOpen, initialNotesContent]);

  useEffect(() => {
    if (quill) {
      const handleChange = () => {
        const notesContent = quill.root.innerHTML;
        onSave(notesContent);
      };
      quill.on("text-change", handleChange);

      return () => {
        quill.off("text-change", handleChange);
      };
    }
  }, [quill, onSave]);

  return (
    <div className="px-2.5 pt-2 pb-2.5 bg-slate-100 rounded flex-col inline-flex">
      <div className="flex gap-2" role="button" onClick={toggleAccordion}>
        <div className="flex flex-grow">
          <div className="flex flex-col gap-1">
            <p className="text-slate-700 out-500-14">Notes</p>
            <p className="text-gray-500 out-300-12">
              Add your notes or agenda prior to the meeting
            </p>
          </div>
        </div>
        <div className="w-5 h-5">
          <ChevronIcon
            className={`transform ${
              accordionOpen ? "rotate-180" : ""
            } text-gray-500 cursor-pointer`}
          />
        </div>
      </div>
      {accordionOpen && (
        <div className="mt-0.5 bg-white">
          <div ref={toolbarRef}></div>
          <div ref={editorContainerRef}></div>
        </div>
      )}
    </div>
  );
};

const AttendeeCard = ({ attendee, onRemoveAttendee, onMarkAsOptional }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const isOptional = attendee.dbField.optional || false;

  const markOptionalText = isOptional
    ? "Unmark as optional"
    : "Mark as optional";

  const content = [
    {
      key: "1",
      label: markOptionalText,
      icon: <MarkOptionalIcon />,
      onClick: () => onMarkAsOptional(attendee),
    },
    {
      key: "2",
      label: "Remove",
      icon: <RemoveFromEventIcon />,
      danger: true,
      onClick: () => onRemoveAttendee(attendee),
    },
  ];

  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  const optionalLabel = isOptional ? "Optional" : "";
  const organizerLabel = attendee.organizer ? "Organizer" : "";
  const label =
    optionalLabel && organizerLabel
      ? `${organizerLabel}, ${optionalLabel}`
      : optionalLabel || organizerLabel;

  return (
    <div className="px-3 py-2 justify-start items-center gap-3 inline-flex">
      <div className="">
        {attendee.external ? (
          <CalendarAvatarIcon />
        ) : (
          <CustomAvatar
            size={24}
            title={attendee.title}
            src={attendee.profilePicture}
          />
        )}
      </div>
      <div className="text-zinc-800 text-sm font-light font-['Outfit'] leading-tight">
        {attendee.title}
        {label && <span className="text-gray-400 ml-1">{`(${label})`}</span>}
      </div>

      <div className="">
        <Dropdown
          menu={{
            items: content,
          }}
          open={dropdownVisible}
          onOpenChange={handleDropDownVisibleChange}
          placement="top"
          trigger={["click"]}
        >
          <ChevronIcon
            style={{
              transform: dropdownVisible ? "rotate(180deg)" : "none",
            }}
          />
        </Dropdown>
      </div>
    </div>
  );
};

const modules = {
  toolbar: {
    container: [
      ["bold", "italic"],
      [{ header: 1 }, { header: 2 }],
      ["blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  },
};

const CustomTimePicker = () => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // Function to generate time options with durations from start time
  const generateTimeOptions = () => {
    const timeOptions = [];
    let currentTime = dayjs().startOf("day");
    const endTime = dayjs().startOf("day").add(1, "day"); // 24 hours later

    while (currentTime.isBefore(endTime)) {
      const time = currentTime.format("HH:mm");
      const duration = calculateDuration(startTime, time);
      timeOptions.push({ value: time, label: `${time} (${duration})` });
      currentTime = currentTime.add(30, "minute");
    }

    return timeOptions;
  };

  // Function to calculate duration from start time
  const calculateDuration = (start, end) => {
    if (!start) return "";

    const startMoment = dayjs(start, "HH:mm");
    const endMoment = dayjs(end, "HH:mm");
    const durationMinutes = endMoment.diff(startMoment, "minutes");

    if (durationMinutes === 0) {
      return `0 mins`;
    } else {
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;
      if (hours === 0) {
        return `${minutes} mins`;
      } else if (minutes === 0) {
        return `${hours} hour${hours > 1 ? "s" : ""}`;
      } else {
        return `${hours} hour${hours > 1 ? "s" : ""} ${minutes} mins`;
      }
    }
  };

  // Handle start time change
  const handleStartTimeChange = (value) => {
    setStartTime(value);
    setEndTime(null); // Reset end time when start time changes
  };

  // Handle end time change
  const handleEndTimeChange = (value) => {
    setEndTime(value);
  };

  return (
    <div style={{ width: "300px" }}>
      <label>Start Time:</label>

      <br />
      <br />
      <label>End Time:</label>
    </div>
  );
};

const CreateEventModal = ({ visible, onClose }) => {
  const { tenantMembers } = useAppSelector((state) => state.tenant);
  const { user } = useAppSelector((state) => state.userDetails);
  const { createEventModal, myCalendarTimelineSelected, currentDate } =
    useAppSelector((state) => state.dashboard);
  const filterKeys = ["firstname", "lastname", "email"];
  const { search, setSearch, filteredData } = useSearch(
    tenantMembers,
    filterKeys
  );
  const { customRecurrenceLine } = useAppSelector((state) => state.dashboard);

  const [selectedColor, setSelectedColor] = useState(0);
  const [focus, setFocus] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(10);
  const [location, setLocation] = useState("");
  const [recurrence, setRecurrence] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [isGoogleMeet, setIsGoogleMeet] = useState(false);
  const [notes, setNotes] = useState("");
  const [attendees, setAttendees] = useState([]);
  const [isAllDay, setIsAllDay] = useState(false);
  const [inviteDropdownOpen, setInviteDropdownOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(false);
  const [customStartTime, setCustomStartTime] = useState(null);
  const [customEndTime, setCustomEndTime] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(false);
  const dispatch = useAppDispatch();
  const quillRef = useRef(null);
  const editor = quillRef.current;

  const handleTimeInput = (value) => {
    setCustomStartTime(
      dayjs().hour(value.split(":")[0]).minute(value.split(":")[1])
    );
  };
  const handleEndTimeInput = (value) => {
    if (value && value !== null) {
      setCustomEndTime(
        dayjs().hour(value.split(":")[0]).minute(value.split(":")[1])
      );
    } else {
      setCustomEndTime(null);
    }
  };

  useEffect(() => {
    if (visible) {
      setAttendees((prevAttendees) => [
        ...prevAttendees,
        {
          dbField: {
            email: user.email,
            userId: user.id,
          },
          profilePicture: user.profilePicture,
          title: `${user.firstName} ${user.lastName}`,
          external: false,
          organizer: true, // Assuming the user is the organizer by default
        },
      ]);
      const editor = quillRef.current;
      if (editor) {
        editor.setText("");
      }
      setSelectedDate(dayjs(createEventModal.date));
      setSelectedStartTime(dayjs(createEventModal.startTime));
      setSelectedEndTime(dayjs(createEventModal.endTime));
    }
  }, [visible]);

  const handleLocationChange = (location) => {
    setLocation(location);
  };

  const handleRecurrenceChange = (recurrence) => {
    setRecurrence(recurrence);
  };

  const handleColorSelect = (colorNumber) => {
    setSelectedColor(colorNumber);
  };

  const handleDropDownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };
  const handleInviteDropDownVisibleChange = (visible) => {
    setInviteDropdownOpen(visible);
  };

  useEffect(() => {
    if (search != "") {
      handleInviteDropDownVisibleChange(true);
    }
  }, [search]);
  const handleRemoveAttendee = (attendeeToRemove) => {
    setAttendees((prevAttendees) =>
      prevAttendees.filter(
        (attendee) => attendee.dbField.email !== attendeeToRemove.dbField.email
      )
    );
  };

  const notifyContent = [
    {
      key: "5",
      label: "5 minutes before the meeting",
      onClick: () => {
        setDropdownVisible(false);
        setSelectedNotification(5);
      },
    },
    {
      key: "10",
      label: "10 minutes before the meeting",
      onClick: () => {
        setDropdownVisible(false);

        setSelectedNotification(10);
      },
    },
    {
      key: "15",
      label: "15 minutes before the meeting",
      onClick: () => {
        setDropdownVisible(false);

        setSelectedNotification(15);
      },
    },
    {
      key: "30",
      label: "30 minutes before the meeting",
      onClick: () => {
        setDropdownVisible(false);

        setSelectedNotification(30);
      },
    },
    {
      key: "60",
      label: "1 hour before the meeting",
      onClick: () => {
        setDropdownVisible(false);
        setSelectedNotification(60);
      },
    },
    {
      key: "1440",
      label: "1 day before the meeting",
      onClick: () => {
        setDropdownVisible(false);

        setSelectedNotification(1440);
      },
    },
  ];

  const handleMarkAsOptional = (attendeeToMark) => {
    const updatedAttendees = attendees.map((attendee) => {
      if (attendee.dbField.email === attendeeToMark.dbField.email) {
        let optional = true;
        if (attendee.dbField.optional !== undefined) {
          optional = !attendee.dbField.optional;
        }
        return {
          ...attendee,
          dbField: {
            ...attendee.dbField,
            optional: optional,
          },
        };
      } else {
        return attendee;
      }
    });

    setAttendees(updatedAttendees);
  };

  const selectedAttendees = attendees.map((attendee) => (
    <AttendeeCard
      key={attendee.dbField.email}
      attendee={attendee}
      onRemoveAttendee={handleRemoveAttendee}
      onMarkAsOptional={handleMarkAsOptional}
    />
  ));

  // Create the users dropdown options
  const usersDropdown = filteredData.length
    ? filteredData
        .filter((selUser) => +selUser.id !== +user.id)
        .map((user, index) => ({
          key: index.toString(),
          label: `${user.firstname} ${user.lastname}`,
          profilePicture: user.profilePicture,
          firstname: user.firstname,
          lastname: user.lastname,
          external: false,
          onClick: () => {
            // Check if the user is already present in the attendees list
            const userExists = attendees.some(
              (attendee) => attendee.dbField.email === user.email
            );

            // If the user is not already present, add them to attendees with default responseStatus
            if (!userExists) {
              setAttendees((prevAttendees) => [
                ...prevAttendees,
                {
                  dbField: {
                    email: user.email,
                    userId: user.userId,
                    optional: false,
                  },
                  profilePicture: user.profilePicture,
                  title: `${user.firstname} ${user.lastname}`,
                  external: false,
                  organizer: false,
                },
              ]);
            }
            setInviteDropdownOpen(false);
            setSearch("");
          },
        }))
    : [
        {
          key: "search",
          label: `${search}`,
          firstname: search,
          lastname: "",
          profilePicture: "",
          external: true,
          onClick: () => {
            // Check if the search value is already present in the attendees list
            const searchExists = attendees.some(
              (attendee) => attendee.dbField.email === search
            );

            // If the search value is not already present, add it to attendees with default responseStatus
            if (!searchExists) {
              setAttendees((prevAttendees) => [
                ...prevAttendees,
                {
                  dbField: {
                    email: search,
                    userId: -1,
                  },
                  profilePicture: "",
                  title: search,
                  external: true,
                  organizer: false,
                },
              ]);
            }
            setInviteDropdownOpen(false);

            setSearch("");
          },
        },
      ];

  const formatLabel = (minutes) => {
    if (minutes === "60") {
      return "1 hour";
    } else if (minutes === "1440") {
      return "1 day";
    } else {
      return `${minutes} minutes`;
    }
  };

  const handleSave = async () => {
    // Early return if any required fields are missing
    if (
      !eventTitle ||
      !selectedDate ||
      !selectedStartTime ||
      !selectedEndTime ||
      attendees.length === 0
    ) {
      alert("Input fields empty");
      return;
    }

    // Set the date part to the selected date
    const startTimeWithDate = selectedStartTime.set(
      "date",
      selectedDate.date()
    );
    const endTimeWithDate = dayjs(selectedDate).isBefore(endTime, "day")
      ? selectedEndTime.set("date", endTime.date())
      : selectedEndTime.set("date", selectedDate.date());

    // Convert to UTC
    const startTimeUTC = dayjs.utc(startTimeWithDate).toISOString();
    const endTimeUTC = dayjs.utc(endTimeWithDate).toISOString();

    const payload = {
      date: selectedDate?.format("YYYY-MM-DD"),
      startTime: startTimeUTC,
      endTime: endTimeUTC,
      description: notes,
      eventTitle,
      attendees,
      location,
      recurrence,
      selectedColor,
      selectedNotification,
      isGoogleMeet,
      isAllDay,
      customRecurrenceLine,
    };

    const reponseEvent = await dispatch(newCreateEvent(payload));
    if (reponseEvent) {
      //todo find out a way to add organiser as attending the event , without having to update after creatiom
      const payload = {
        eventId: reponseEvent?.payload?.meetResponse?.data?.id,
        attendeeEmail: user.email,
        response: "accepted",
        attendees: attendees
          .map((attendee) => {
            return attendee.dbField;
          })
          .filter((attendee) => attendee.email !== user.email),
      };

      await dispatch(updateEventResponseV3(payload));
    }

    const getEvents = async () => {
      try {
        let minTime, maxTime;

        const timeZoneOffsetMinutes = dayjs().utcOffset();

        // For day view
        if (myCalendarTimelineSelected === 0) {
          minTime = dayjs(currentDate)
            .startOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
          maxTime = dayjs(currentDate)
            .endOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
        }
        // For week view
        else if (myCalendarTimelineSelected === 1) {
          minTime = dayjs(currentDate)
            .startOf("week")
            .startOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
          maxTime = dayjs(currentDate)
            .endOf("week")
            .endOf("day")
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
        } else if (myCalendarTimelineSelected === 2) {
          const firstDayOfMonth = dayjs(currentDate)
            .clone()
            .startOf("month")
            .startOf("week");
          const lastDayOfMonth = dayjs(currentDate)
            .clone()
            .endOf("month")
            .endOf("week");

          minTime = firstDayOfMonth
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
          maxTime = lastDayOfMonth
            .utcOffset(timeZoneOffsetMinutes)
            .toISOString();
        }

        if (myCalendarTimelineSelected !== 3) {
          const { data: events } = await client.get(
            "/integration/calender/get-events",
            {
              params: {
                userId: user.id,
                date: dayjs(currentDate).format("YYYY-MM-DD"),
                timeMin: minTime,
                timeMax: maxTime,
              },
            }
          );

          // console.log(events, "these are the events ");

          dispatch(
            updateDashboard({
              key: "events",
              value: events?.events || [],
            })
          );
        }
        dispatch(
          updateDashboard({
            key: "alertPopupModal",
            value: {
              visible: true,
              data: {
                title: `Event Created`,
                subtitle: `${eventTitle} on ${selectedDate
                  ?.format("DD MMMM")
                  .replace(/(^|\s)\S/g, (firstLetter) =>
                    firstLetter.toUpperCase()
                  )}, ${startTimeWithDate.format("h:mmA")}`,
                description: "",
              },
            },
          })
        );
      } catch (e) {
        console.log(e);
      }
    };

    if (selectedDate) {
      getEvents();
    }

    handleCancel();
  };

  const handleCancel = () => {
    // Reset all values back to default
    setEventTitle("");
    setNotes("");
    setSelectedDate(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setAttendees([]);
    setLocation("");
    setRecurrence("");
    setSelectedColor(0);
    setSelectedNotification(10);
    setIsGoogleMeet(false);
    setIsAllDay(false);
    setFocus(false);
    setCustomEndTime(null);
    setCustomStartTime(null);

    onClose();
  };
  const [startTime, setStartTime] = useState(
    selectedStartTime ? selectedStartTime : null
  );
  const [endTime, setEndTime] = useState(
    selectedEndTime ? selectedEndTime : null
  );
  useEffect(() => setStartTime(selectedStartTime), [selectedStartTime]);
  useEffect(() => setEndTime(selectedEndTime), [selectedEndTime]);
  // Function to calculate duration from start time
  const calculateDuration = (start, end) => {
    if (!start) return "";

    const startMoment = start ? dayjs(start, "hh:mm") : dayjs().format("hh:mm");

    const endMoment = end
      ? dayjs(end, "hh:mm")
      : dayjs().add(30, "minutes").format("hh:mm");
    const durationMinutes = dayjs(endMoment).diff(
      dayjs(startMoment),
      "minutes"
    );
    // console.log(endMoment, dayjs(startMoment), "difference");
    if (durationMinutes === 0) {
      return `0 mins`;
    } else {
      const hours = Math.floor(durationMinutes / 60);
      const minutes = durationMinutes % 60;
      if (hours === 0) {
        return `${minutes} mins`;
      } else if (minutes === 0) {
        return `${hours} hr${hours > 1 ? "s" : ""}`;
      } else {
        return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} mins`;
      }
    }
  };

  // Function to generate time options with durations from start time
  const generateTimeOptions = () => {
    const startTimeOptions = [];
    const endTimeOptions = [];
    const now = new Date();
    const currentHour = now.getHours();
    let currentTime = dayjs(currentHour).add(30, "minutes");
    const endTime = dayjs().add(1, "day"); // 24 hours later

    while (currentTime.isBefore(endTime)) {
      const time = currentTime.format("hh:mm");
      const duration = calculateDuration(startTime, time);
      startTimeOptions.push({ value: time, label: `${time}` });
      endTimeOptions.push({ value: time, label: `${time} (${duration})` });
      currentTime = currentTime.add(30, "minute");
    }

    return { startTimeOptions, endTimeOptions };
  };

  const generateStartTime = () => {
    const currentHour = dayjs().startOf("D").hour();
    const currentMinute = dayjs().startOf("D").minute();
    let tempHour = currentHour;
    let tempMinute = currentMinute;

    // Move to the next half-hour if current time is past the half-hour mark
    if (tempMinute > 30) {
      tempMinute = 0;
      tempHour += 1;
    } else if (tempMinute > 0 && tempMinute < 30) {
      tempMinute = 30;
    }

    const startTime = [];

    do {
      // Push the current time
      startTime.push(dayjs().hour(tempHour).minute(tempMinute));

      // Toggle between 0 and 30 minutes
      tempMinute = tempMinute === 0 ? 30 : 0;

      // If we toggled to 0, move to the next hour
      if (tempMinute === 0) {
        tempHour += 1;
      }
    } while (tempHour <= 23);

    return startTime;
  };
  const generateEndTime = ({ startTime }) => {
    const currentHour = dayjs(startTime).add(30, "minutes").hour();
    const currentMinute = dayjs(startTime).add(30, "minutes").minute();
    let tempHour = currentHour;
    let tempMinute = currentMinute;

    // Move to the next half-hour if current time is past the half-hour mark
    if (tempMinute > 30) {
      tempMinute = 0;
      tempHour += 1;
    } else if (tempMinute > 0 && tempMinute < 30) {
      tempMinute = 30;
    }

    const endTime = [];

    do {
      // Push the current time
      endTime.push(dayjs().hour(tempHour).minute(tempMinute));

      // Toggle between 0 and 30 minutes
      tempMinute = tempMinute === 0 ? 30 : 0;

      // If we toggled to 0, move to the next hour
      if (tempMinute === 0) {
        tempHour += 1;
      }
    } while (tempHour <= 23);

    return endTime;
  };
  // Handle start time change
  const handleStartTimeChange = (value) => {
    // alert(value);
    console.log(value, "starttttttt 2");
    // if (dayjs(value, "hh:mm a", true).isValid()) {
    setStartTime(dayjs().hour(value.split(":")[0]).minute(value.split(":")[1]));
    setEndTime(null); // Reset end time when start time
    setSelectedStartTime(
      dayjs().hour(value.split(":")[0]).minute(value.split(":")[1])
    );
    // }
  };

  // Handle end time change
  const handleEndTimeChange = (value) => {
    if (
      dayjs()
        .hour(value.split(":")[0])
        .minute(value.split(":")[1])
        .isBefore(dayjs(startTime))
    ) {
      setCustomEndTime(
        dayjs()
          .add(1, "day")
          .hour(value.split(":")[0])
          .minute(value.split(":")[1])
      );
      setEndTime(
        dayjs()
          .add(1, "day")
          .hour(value.split(":")[0])
          .minute(value.split(":")[1])
      );
      setSelectedEndTime(
        dayjs()
          .add(1, "day")
          .hour(value.split(":")[0])
          .minute(value.split(":")[1])
      );
    } else {
      setEndTime(dayjs().hour(value.split(":")[0]).minute(value.split(":")[1]));
      setSelectedEndTime(
        dayjs().hour(value.split(":")[0]).minute(value.split(":")[1])
      );
    }
  };
  useEffect(() => {
    if (visible) {
      // setTimeout(() =>
      setFocus(true);
      // , 1000);
    }
  }, [visible]);

  return (
    <CustomModal
      visible={visible}
      width="480px"
      onCancel={handleCancel}
      body={
        focus ? (
          <div className=" h-[546px]">
            <div className="p-4 flex justify-between">
              <p className="text-zinc-800 out-500-16">New Event</p>
              <button className="w-5 h-5" onClick={handleCancel}>
                <CrossIcon className={"text-gray-500 cursor-pointer"} />
              </button>
            </div>
            <Divider className="m-0" />
            <div className="flex flex-col gap-4 p-4 max-h-[428px] overflow-y-scroll">
              <CustomInputBox
                placeholder="New event title"
                value={eventTitle}
                autoFocus={true}
                onChange={(e) => setEventTitle(e.target.value)}
              />
              <DatePicker
                placeholder="Select Date"
                format="dddd, D MMMM"
                value={selectedDate}
                className="bg-white border-gray-200"
                onChange={(date) => setSelectedDate(date)}
                suffixIcon={null}
              />

              {!isAllDay && (
                <div className="flex gap-3">
                  <div className="flex flex-grow">
                    <Select
                      showSearch
                      onSearch={handleTimeInput}
                      placeholder="Start Time"
                      className="calendar-select"
                      suffixIcon={
                        <ClockCircleOutlined className="text-gray-500 mr-1" />
                      }
                      value={
                        startTime ? dayjs(startTime).format("HH:mm a") : null
                      }
                      style={{ width: "202px" }}
                      onChange={handleStartTimeChange}
                    >
                      {customStartTime !== null
                        ? [...generateStartTime(), customStartTime].map(
                            (option, index) => (
                              <Option
                                key={`${index}`}
                                value={dayjs(option).format("HH:mm")}
                              >
                                <div className="text-gray-900 out-300-14">
                                  {dayjs(option).format("HH:mm a")}
                                </div>
                              </Option>
                            )
                          )
                        : generateStartTime().map((option, index) => (
                            <Option
                              key={`${index}`}
                              value={dayjs(option).format("HH:mm")}
                            >
                              <div className="text-gray-900 out-300-14">
                                {dayjs(option).format("HH:mm a")}
                              </div>
                            </Option>
                          ))}
                    </Select>
                  </div>
                  <CalendarRightIcon />
                  <div className="flex flex-grow">
                    <Select
                      showSearch
                      onSearch={handleEndTimeInput}
                      className="calendar-select"
                      placeholder="End Time"
                      suffixIcon={
                        <ClockCircleOutlined className="text-gray-500 mr-1" />
                      }
                      value={endTime ? dayjs(endTime).format("HH:mm a") : null}
                      style={{ width: "202px" }}
                      onChange={handleEndTimeChange}
                      disabled={!startTime} // Disable until start time is selected
                    >
                      {startTime ? (
                        customEndTime !== null ? (
                          [
                            ...generateEndTime({ startTime: dayjs(startTime) }),
                            customEndTime,
                          ].map((option, index) => (
                            <Option
                              key={`${index}`}
                              value={dayjs(option).format("HH:mm")}
                            >
                              <div className="flex w-full whitespace-nowrap out-300-14 items-center">
                                <div className="text-gray-900">
                                  {dayjs(option).format("HH:mm a")}{" "}
                                </div>
                                <div className="text-gray-500  ml-[6px]">
                                  {(customEndTime &&
                                    customEndTime.toString().includes(":") &&
                                    +dayjs(customEndTime)
                                      .hour()
                                      .toString()
                                      .split(":")[0] <
                                      +dayjs(startTime).hour()) ||
                                  (customEndTime &&
                                    customEndTime < +dayjs(startTime).hour())
                                    ? dayjs(option).diff(
                                        dayjs(startTime),
                                        "minutes"
                                      ) > 60
                                      ? ` ${dayjs(customEndTime).hour()}
                                         
                                          ${dayjs(startTime).hour()}${
                                          dayjs(option)
                                            .add(1, "day")
                                            .diff(dayjs(startTime), "minutes") %
                                            60 ===
                                          0
                                            ? dayjs(option)
                                                .add(1, "day")
                                                .diff(
                                                  dayjs(startTime),
                                                  "minutes"
                                                ) / 60
                                            : (
                                                dayjs(option)
                                                  .add(1, "day")
                                                  .diff(
                                                    dayjs(startTime),
                                                    "minutes"
                                                  ) / 60
                                              ).toFixed(1)
                                        } hr
                                 
                              
                              `
                                      : `${dayjs(option)
                                          .add(1, "day")
                                          .diff(
                                            dayjs(startTime),
                                            "minutes"
                                          )} min`
                                    : dayjs(option).diff(
                                        dayjs(startTime),
                                        "minutes"
                                      ) > 60
                                    ? `${
                                        dayjs(option).diff(
                                          dayjs(startTime),
                                          "minutes"
                                        ) %
                                          60 ===
                                        0
                                          ? dayjs(option).diff(
                                              dayjs(startTime),
                                              "minutes"
                                            ) / 60
                                          : (
                                              dayjs(option).diff(
                                                dayjs(startTime),
                                                "minutes"
                                              ) / 60
                                            ).toFixed(1)
                                      } hr
                              `
                                    : `${dayjs(option).diff(
                                        dayjs(startTime),
                                        "minutes"
                                      )} min`}
                                </div>
                              </div>
                            </Option>
                          ))
                        ) : (
                          generateEndTime({ startTime: dayjs(startTime) }).map(
                            (option, index) => (
                              <Option
                                key={`${index}`}
                                value={dayjs(option).format("HH:mm")}
                              >
                                <div className="flex w-full whitespace-nowrap out-300-14 items-center">
                                  <div className="text-gray-900">
                                    {dayjs(option).format("HH:mm a")}{" "}
                                  </div>
                                  <div className="text-gray-500  ml-[6px]">
                                    {dayjs(option).diff(
                                      dayjs(startTime),
                                      "minutes"
                                    ) > 60
                                      ? `${
                                          dayjs(option).diff(
                                            dayjs(startTime),
                                            "minutes"
                                          ) %
                                            60 ===
                                          0
                                            ? dayjs(option).diff(
                                                dayjs(startTime),
                                                "minutes"
                                              ) / 60
                                            : (
                                                dayjs(option).diff(
                                                  dayjs(startTime),
                                                  "minutes"
                                                ) / 60
                                              ).toFixed(1)
                                        } hr
                                   
                                
                                `
                                      : `${dayjs(option).diff(
                                          dayjs(startTime),
                                          "minutes"
                                        )} min`}
                                  </div>
                                </div>
                              </Option>
                            )
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </Select>
                  </div>
                </div>
              )}
              <div
                className="rounded-lg items-center text-white justify-end gap-x-2 out-500-14 flex px-[10px] py-[5px] bg-primary-600 w-fit whitespace-nowrap h-[30px] cursor-pointer"
                onClick={() => setIsGoogleMeet(!isGoogleMeet)}
              >
                <img
                  src={
                    isGoogleMeet
                      ? "/images/icons/check-white.svg"
                      : "/images/icons/plus.svg"
                  }
                />
                Add Google Meet video conferencing
              </div>
              <MoreInformationAccordion
                selectedDate={selectedDate}
                onLocationChange={handleLocationChange}
                onRecurrenceChange={handleRecurrenceChange}
                isChecked={isAllDay}
                handleCheckboxChange={setIsAllDay}
              />
              <NotesAccordion initialNotesContent={notes} onSave={setNotes} />
              <div className="flex flex-col gap-2">
                <p className="text-slate-700 out-500-14">Invite People</p>
                {/* Dropdown only when search is not empty */}
                <Dropdown
                  menu={{
                    items: usersDropdown,
                  }}
                  trigger={["click"]}
                  getPopupContainer={(parent) =>
                    parent.parentElement.parentElement.parentElement
                  }
                  placement="bottom"
                  overlayClassName="max-h-[300px] overflow-y-scroll  "
                  onOpenChange={(e) => {
                    handleInviteDropDownVisibleChange(e);
                  }}
                  open={inviteDropdownOpen}
                >
                  <CustomSearchBox
                    placeholder="Invite people to the event"
                    search={search}
                    setSearch={setSearch}
                  />
                </Dropdown>
              </div>
              <div className="flex flex-col">{selectedAttendees}</div>
              <div className="flex gap-2">
                {[...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    onClick={() => handleColorSelect(index)}
                    className={`flex items-center justify-center w-5 h-5 rounded-full border-2 ${
                      selectedColor === index
                        ? "border-blue-500"
                        : "border-transparent"
                    } cursor-pointer`}
                  >
                    <CircleIconPrimary colorNumber={index} />
                  </div>
                ))}
              </div>

              <Dropdown
                menu={{
                  items: notifyContent,
                }}
                open={dropdownVisible}
                onOpenChange={handleDropDownVisibleChange}
                placement="top"
                trigger={["click"]}
              >
                <div className="flex gap-3 px-2.5 py-2">
                  <CalendarNotificationIcon />
                  <p>{formatLabel(selectedNotification)} before the meeting</p>
                  <ChevronIcon
                    style={{
                      transform: dropdownVisible ? "rotate(180deg)" : "none",
                    }}
                  />
                </div>
              </Dropdown>
            </div>
            <Divider className="m-0" />
            <div className="flex gap-3 justify-end items-center p-4">
              <button
                className="text-grey-500 out-500-14 py-[5px] px-[10px] flex justify-between items-center rounded-[4px]"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-primary-600 text-white out-500-14 py-[5px] px-[10px] flex justify-between items-center rounded-[4px]"
                onClick={handleSave}
              >
                Create
              </button>
            </div>
          </div>
        ) : (
          <></>
        )
      }
    />
  );
};

export default CreateEventModal;
