import React, { useEffect } from "react";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import CustomBadge from "../../../Core/CommonV2/CustomBadge";

const PlannerAccordian = ({
  title,
  itemsCount,
  children,
  menu,
  open,
  sprint,
  setOpen,
}) => {
  useEffect(() => {
    console.log("");
  }, [window.location.pathname]);
  return (
    <div>
      <div
        className={`flex justify-between items-center ${
          !open ? "bg-white px-3 py-2.5 border rounded-lg" : ""
        } `}
      >
        {window.location.pathname.includes("sprints") && (
          <div
            className=" flex items-center gap-x-3 cursor-pointer "
            onClick={() => setOpen(!open)}
          >
            <ChevronIcon
              className={`text-gray-500 transform transition-all mt-0.5 ${
                open ? "" : "-rotate-90"
              }`}
              height="16"
              width="16"
            />
            <p className="out-500-14 leading-[20px]  text-black capitalize">
              {title}
            </p>
            {sprint && (
              <CustomBadge
                text={
                  sprint?.status === 2
                    ? "Active"
                    : sprint.status === 3
                    ? "Completed"
                    : "Upcoming"
                }
                color={
                  sprint?.status === 2
                    ? "#165ABF"
                    : sprint.status === 3
                    ? "#027A48"
                    : "#667085"
                }
                className={`out-500-12 h-[18px] ${
                  sprint?.status === 2
                    ? "bg-primary-100"
                    : sprint.status === 3
                    ? "bg-green-100"
                    : "bg-gray-200"
                }  `}
              />
            )}
            <p className="out-300-12 text-gray-500">{itemsCount} issues</p>
          </div>
        )}
        {menu}
      </div>
      {open && children}
    </div>
  );
};

export default PlannerAccordian;
