import { Dropdown, Empty, Input, Menu, Select } from "antd";
import React, { useState, useEffect } from "react";

/**
 * @param  {String} width
 * @param  {String} search
 * @param  {Function} setSearch
 * @param  {String} placeholder
 * */

type CustomSearchProps = {
  width?: string;
  search?: string;
  setSearch?: (...args: string[]) => void;
  onChange?: (e, v) => void;
  placeholder?: string;
  className?: string;
  allowClear?: boolean;
  style?: React.CSSProperties;
  onBlur?: (e) => void;
};

type CustomSearchWithDropDownProps = {
  width?: string;
  placeholder?: string;
  className?: string;
  allowClear?: boolean;
  style?: React.CSSProperties;
  options?: {
    id: string | number;
    value: string;
    label: string;
    render: any;
    //onClick: (val) => void;
  }[];
};

const { Option } = Select;

export default function CustomSearch({
  width,
  search,
  setSearch,
  onChange,
  placeholder,
  className,
  style,
  onBlur,
}: CustomSearchProps) {
  return (
    <div
      className={`shadow-xs border-0 flex justify-center items-center bg-white search-shadow rounded-xl h-10  ${width} ${className}`}
      style={{ ...style, boxSizing: "content-box" }}
    >
      <Input
        bordered={false}
        placeholder={placeholder || "Search"}
        value={search}
        allowClear
        onBlur={onBlur}
        onChange={(event) => {
          if (typeof setSearch === "function") setSearch(event.target.value);
          if (typeof onChange === "function")
            onChange(event, event.target.value);
        }}
        className=" font-medium text-sm text-gray-500  h-10"
        prefix={
          <img src="/images/icons/search.svg" alt="" className="pr-2.5" />
        }
      />
    </div>
  );
}

export function CustomSearchWithDropDown({
  width,
  placeholder,
  className,
  style,
  options,
}: //value,
CustomSearchWithDropDownProps) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filterOptions, setFilterOption] = useState(options);

  useEffect(() => {
    const filters = options?.filter(
      (option) =>
        option && option.label.toLowerCase().includes(search.toLowerCase())
    );
    setFilterOption([...filters]);
  }, [search, options]);

  return (
    <Dropdown
      overlay={
        <Menu
          className=" rounded-lg custom-menu-dropdown  shadow-dropdown max-h-[200px] overflow-y-scroll"
          //selectable
        >
          {filterOptions?.length > 0 ? (
            filterOptions.map((option) => {
              return (
                <Menu.Item
                  className=" py-2.5 px-4 rounded-lg"
                  key={option.id}
                  // onClick={() => {
                  //   option.onClick(option.value);
                  // }}
                >
                  {option.render(setSearch)}
                </Menu.Item>
              );
            })
          ) : (
            <div className="py-2">
              <Empty description="No users" />
            </div>
          )}
        </Menu>
      }
      trigger={["click"]}
    >
      <div
        className={`shadow-xs border-0 bg-white search-shadow rounded-xl h-10  ${width} ${className}`}
        style={{ ...style, boxSizing: "content-box" }}
      >
        <Input
          bordered={false}
          placeholder={placeholder || "Search"}
          value={search}
          allowClear
          onChange={(event) => setSearch(event.target.value)}
          className=" font-medium text-sm text-gray-500  h-10"
          prefix={
            <img src="/images/icons/search.svg" alt="" className="pr-2.5" />
          }
        />
      </div>
    </Dropdown>
  );
}
