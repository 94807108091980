import React from "react";
import Icon from "@ant-design/icons";

const MarkerPinSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 8.333a2 2 0 100-4 2 2 0 000 4z"
    ></path>
    <path
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 14.667c1.334-2.667 5.334-4.388 5.334-8a5.333 5.333 0 00-10.667 0c0 3.612 4 5.333 5.333 8z"
    ></path>
  </svg>
);

const MarkerPinIcon = (props) => <Icon component={MarkerPinSvg} {...props} />;

export { MarkerPinIcon };
