import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import {
  CrossIcon,
  CrossIconLarge,
  CrossIconSecondary,
} from "../../../Core/svgV2/CrossIcon";
import FrostyTImeEstimatesIcon from "../../../Core/svgV3/FrostyTimeEstimatesIcon";

const EstimatesMissingModal = ({
  visible,
  onClose,
  title,
  description,
  buttonOneText,
  buttonTwoText,
  onButtonOneClick,
  onButtonTwoClick,
}) => {
  return (
    <CustomModal
      visible={visible}
      width="370px"
      onCancel={onClose}
      body={
        <div className="w-full inline-flex flex-col items-start gap-4 p-4 rounded-lg bg-white shadow-lg">
          <div className="flex w-full h-5 justify-between">
            <div className="text-[#344054] text-sm font-medium leading-5 font-[outfit]">
              {title}
            </div>
            <div className="cursor-pointer" onClick={onClose}>
              <CrossIconSecondary />
            </div>
          </div>

          <FrostyTImeEstimatesIcon
            style={{
              width: "141px",
              height: "108px",
            }}
          ></FrostyTImeEstimatesIcon>

          <div
            className="self-stretch text-[#667085] out-300-14 leading-5"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>

          <div className="flex justify-end items-center gap-4 self-stretch">
            <div className="flex items-start rounded-md">
              <div
                className="flex p-1.5 justify-center items-center gap-2 rounded-sm cursor-pointer mr-3"
                onClick={onButtonOneClick}
              >
                <div className="text-[#667085] out-500-14 mt-0.5">
                  {buttonOneText}
                </div>
              </div>

              <div className="flex items-start rounded-sm">
                <div
                  className="flex p-1.5 px-2.5 justify-center items-center gap-2 rounded border border-[#165ABF] bg-[#165ABF] shadow-xs cursor-pointer"
                  onClick={onButtonTwoClick}
                >
                  <div className="text-[#F9FBFE] out-500-14">
                    {buttonTwoText}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default EstimatesMissingModal;
