import React from "react";
import Icon from "@ant-design/icons";

const SearchSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14 14l-2.9-2.9m1.567-3.767A5.333 5.333 0 112 7.333a5.333 5.333 0 0110.667 0z"
    ></path>
  </svg>
);
const SearchElongatedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.5 17.5l-5-5m1.667-4.167a5.833 5.833 0 11-11.667 0 5.833 5.833 0 0111.667 0z"
    ></path>
  </svg>
);
const SearchIcon = (props) => {
  return <Icon component={SearchSvg} {...props} />;
};
const SearchElongatedIcon = (props) => {
  return <Icon component={SearchElongatedSvg} {...props} />;
};

export { SearchIcon, SearchElongatedIcon };
