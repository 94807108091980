import React from "react";
import Icon from "@ant-design/icons";

const AlarmClockSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.167 2.5L1.667 5m16.666 0l-2.5-2.5M5 15.833L3.333 17.5M15 15.833l1.666 1.667M10 7.5v3.333l1.666 1.667M10 17.5a6.667 6.667 0 100-13.334A6.667 6.667 0 0010 17.5z"
    ></path>
  </svg>
);

const AlarmClockIcon = (props) => {
  return <Icon component={AlarmClockSvg} {...props} />;
};

export { AlarmClockIcon };
