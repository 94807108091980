import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import { ChevronIcon } from "../../../../Core/svgV2/Chevron";
import CustomTable from "../../../../Core/CommonV2/CustomTable";
import CustomBadge from "../../../../Core/CommonV2/CustomBadge";
import { getStatusDetails } from "../../../../Core/utils/scheduleUtils";
import { useGetSwimlanes } from "../../../../Core/redux/ReactQueryHooksV3/useScheduleAPI";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../../Core/redux/hooks";
import {
  getGoalDetails,
  getGoalsByLabelId,
} from "../../../../Core/redux/api/scheduleAPI";
import { updateGlobalKey } from "../../../../Core/redux/slices/global.slice";
import AssigneeFilter from "./AssigneeFilter";
import CustomAvatar from "../../../../Core/CommonV2/CustomAvatar";
import { labelColors } from "../../../../Core/constants";
import { updateSchedule } from "../../../../Core/redux/slices/schedule.slice";

const CustomProgressBar = ({
  percent,
  progressColor = "bg-blue-500",
  heightClass = "h-2",
  borderRadiusClass = "rounded-lg",
  showDates = false,
  startDate = "",
  endDate = "",
}) => {
  const isComplete = percent >= 100;
  const progressBarStyle = {
    width: `${percent}%`,
    backgroundColor: progressColor,
  };

  const progressBorderClass = isComplete ? borderRadiusClass : "rounded-l-lg";

  return (
    <div
      className={`w-full bg-gray-200 ${borderRadiusClass} h-1 relative overflow-hidden `}
    >
      <div
        style={progressBarStyle}
        className={`${progressBorderClass} ${heightClass} transition-all duration-300 ease-in-out overflow-hidden`}
      ></div>
      {showDates && (
        <div className="absolute inset-0 flex justify-center items-center">
          <span className="text-xs font-medium z-10">
            {startDate} - {endDate}
          </span>
        </div>
      )}
    </div>
  );
};

const AccordionComponent = ({ label }) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: swimLanesData, isLoading: isLoading } = useGetSwimlanes({
    projectId,
  });
  const { labels } = useAppSelector((state) => state.schedule);

  const { userList } = useAppSelector((state) => state.overview);
  const swimLanes = swimLanesData?.result;
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [isOpen, setIsOpen] = useState(label?.open ? label?.open : true);
  const goals = useAppSelector(
    (state) => state.schedule.goalsByLabelId[label.id] || []
  );

  useEffect(() => {
    if (label.id) {
      dispatch(
        getGoalsByLabelId({
          labelId: label.id.toString(),
          projectId,
        })
      );
    }
  }, [label.id, projectId, dispatch]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    const filteredLabels = labels.filter((lbl) => lbl.id !== label.id);
    const newLabel = { ...label, open: !isOpen };
    dispatch(
      updateSchedule({ key: "labels", value: [...filteredLabels, newLabel] })
    );
  };

  const labelColor = labelColors.find(
    (color) => color.id === +label.labelColorId
  );
  const baseColor = labelColor ? labelColor.textColor : "#7DC7FF";

  const assigneeIds = goals.reduce((acc, goal) => {
    if (goal.assigneeIds && goal.assigneeIds.length > 0) {
      acc.push(...goal.assigneeIds.filter((id) => id)); // Filter out null values
    }
    return acc;
  }, []);

  // Filter out duplicate IDs
  const uniqueAssigneeIds = Array.from(new Set(assigneeIds));

  // Map unique assignee IDs to user details
  const uniqueAssignees = uniqueAssigneeIds
    .map((id) => {
      const user = userList.find((user) => +user.userId === id);
      if (user) {
        return {
          id: user.userId,
          src: user.profilePicture,
          title: `${user.firstname} ${user.lastname}`,
        };
      }
      return null;
    })
    .filter((assignee) => assignee !== null);

  const handleAssigneeFilterChange = (selectedAssigneeIds) => {
    setSelectedAssignees(selectedAssigneeIds);
  };

  // Filter goals based on selected assignees
  const filteredGoals = goals.filter((goal) => {
    const assigneeIds = goal.assigneeIds || [];
    const selectedAssigneesArray = Array.isArray(selectedAssignees)
      ? selectedAssignees
      : [selectedAssignees];

    const result =
      selectedAssigneesArray.length === 0 ||
      assigneeIds.some((assigneeId) =>
        selectedAssigneesArray.includes(assigneeId.toString())
      );

    return result;
  });

  const getProjectTimelineProgress = (startDate, endDate) => {
    const today = dayjs();
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const completedDuration = today.diff(start, "day");
    const totalDuration = end.diff(start, "day");

    let projectTimelineProgress = (completedDuration / totalDuration) * 100;

    if (today.isSame(start, "day")) {
      projectTimelineProgress = Math.max(projectTimelineProgress, 1);
    }

    return Math.min(Math.max(projectTimelineProgress, 0), 100);
  };

  const overallProgress = getProjectTimelineProgress(
    label.startDate,
    label.endDate
  );

  const renderHeaderCell = (text) => (
    <div
      className="header-cell text-zinc-800 out-500-12"
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {text}
    </div>
  );

  const columns = [
    {
      title: renderHeaderCell("No."),
      dataIndex: "No",
      key: "id",
      width: "8%",
      align: "center",
      render: (_, __, index) => (
        <div className="text-slate-700 out-300-12">{index + 1}</div>
      ),
    },
    {
      title: renderHeaderCell("Task ID"),
      dataIndex: "identifier",
      key: "taskId",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <div className="text-slate-700 out-300-12 capitalize">
          {record.identifier}
        </div>
      ),
    },
    {
      title: renderHeaderCell("Task"),
      dataIndex: "name",
      key: "taskName",
      width: "30%",
      render: (text) => (
        <div
          className="truncate text-slate-700 out-500-12"
          style={{ maxWidth: "100%" }}
          title={text}
        >
          {text}
        </div>
      ),
    },
    {
      title: renderHeaderCell("Subtasks"),
      dataIndex: "subtasksCount",
      key: "subtasks",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <div className="text-slate-700 out-500-12">
            {record.subtasksCount}
          </div>
        );
      },
    },
    {
      title: renderHeaderCell("Status"),
      dataIndex: "statusId",
      key: "status",
      width: "10%",
      align: "center",
      render: (statusId) => {
        const currentSwimlane = swimLanes?.find(
          (swimlane) => swimlane?.statusId === statusId?.toString()
        );
        const { color, className } = getStatusDetails(statusId);

        return (
          <div className="flex justify-center">
            <CustomBadge
              text={currentSwimlane?.title || "To Do"}
              color={color}
              className={className}
              dot
            />
          </div>
        );
      },
    },
    {
      title: renderHeaderCell("Timeline"),
      dataIndex: "timeline",
      key: "timelineProgress",
      width: "20%",
      align: "center",
      render: (_, goal) => {
        // Here we calculate the timeline progress for each goal
        const timelineProgress = getProjectTimelineProgress(
          goal.startDate,
          goal.endDate
        );

        const formattedCreatedAt = goal.startDate
          ? dayjs(goal.startDate).format("MMM DD")
          : "Not set";
        const formattedDueDate = goal.endDate
          ? dayjs(goal.endDate).format("MMM DD")
          : "Not set";

        const labelColor = labelColors.find(
          (color) => color.id === +label.labelColorId
        );
        return (
          <div className="relative">
            <div className="absolute top-[5px] z-10 left-[32%] whitespace-nowrap  text-zinc-800 text-[10px] font-medium font-['Outfit']">
              {formattedCreatedAt} - {formattedDueDate}
            </div>
            <Progress
              type="line"
              percent={timelineProgress}
              showInfo={false}
              strokeWidth={18}
              className="opacity-[50%]"
              strokeColor={labelColor?.textColor || "#3498db"}
            />
          </div>
        );
      },
    },
    {
      title: renderHeaderCell("Assignee"),
      dataIndex: "assignees",
      key: "assignees",
      width: "12%",
      align: "center",
      render: (_, record) => {
        const assigneeIds = record.assigneeIds || [];
        const assignees = assigneeIds
          .map((id) => {
            const user = userList.find((user) => +user.userId === id);
            if (user) {
              return {
                id: user.userId,
                src: user.profilePicture,
                title: `${user.firstname} ${user.lastname}`,
              };
            }
            return null;
          })
          .filter((assignee) => assignee !== null);

        if (assignees.length === 1) {
          const assignee = assignees[0];
          return (
            <div className="flex justify-center">
              <CustomAvatar
                size={24}
                title={assignee.title}
                src={assignee.src}
              />
            </div>
          );
        } else {
          return (
            <div className="flex justify-center items-center">
              <AssigneeFilter
                assignees={assignees.map((assignee) => ({
                  id: assignee.id,
                  src: assignee.src,
                  title: assignee.title,
                }))}
                onFilterChange={() => {
                  console.log();
                }}
                maxCount={4}
                filteringEnabled={false}
              />
            </div>
          );
        }
      },
    },
  ];

  const handleDoubleClick = async (goal) => {
    dispatch(updateGlobalKey({ key: "loading", value: true }));
    localStorage.removeItem("taskRedirectUrl");
    localStorage.setItem("taskRedirectUrl", window.location.pathname);

    await dispatch(getGoalDetails({ goalId: goal.id }))
      .then(() => {
        navigate(`/schedule/${projectId}/task/${goal.id}`);
      })
      .finally(() => {
        dispatch(updateGlobalKey({ key: "loading", value: false }));
      });
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full rounded-lg border border-slate-200 overflow-hidden">
        <div className="w-full h-1 bg-slate-200 rounded-lg">
          <CustomProgressBar
            percent={overallProgress}
            progressColor={baseColor}
          />
        </div>
        <div
          className={`w-full px-4 py-2.5 bg-slate-50 border-b border-gray-200 justify-between flex items-center cursor-pointer ${
            isOpen ? "" : "rounded-b-lg"
          }`}
        >
          <div className="flex-grow flex items-center gap-2">
            <div className="text-zinc-800 text-sm font-medium whitespace-nowrap">
              {label.name}
            </div>
            <div className="text-gray-500 w-full text-xs font-light">
              {+overallProgress ? (
                `${Math.round(overallProgress)}% Done`
              ) : (
                <></>
              )}
            </div>
            {uniqueAssignees.length > 0 && (
              <div role="button" onClick={() => setIsOpen(true)}>
                <AssigneeFilter
                  assignees={uniqueAssignees}
                  onFilterChange={handleAssigneeFilterChange}
                  maxCount={4}
                />
              </div>
            )}
            <ChevronIcon
              style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0)" }}
              onClick={toggleAccordion}
            />
          </div>
        </div>
        {label?.open && (
          <div className="w-full overflow-x-auto">
            <CustomTable
              className="custom-header-timeline custom-table rounded-[40px]"
              columns={columns}
              data={filteredGoals}
              loading={isLoading}
              paginationEnabled={false}
              onRow={(goal) => ({
                onDoubleClick: () => handleDoubleClick(goal),
              })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccordionComponent;
