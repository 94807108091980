import React, { useEffect, useState } from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";
import LibraryMenuBar from "./LibraryMenuBar";

import DeleteFolderModal from "./Modals/DeleteFolderModal";
import DeleteFileModal from "./Modals/DeleteFileModal";
import DeleteLinkModal from "./Modals/DeleteLinkModal";
import MakeACopyModal from "./Modals/MakeACopyModal";
import NewFolderModal from "./Modals/NewFolderModal";
import NewLinkModal from "./Modals/NewLinkModal";
import RenameModal from "./Modals/RenameModal";
import ReplaceFileModal from "./Modals/ReplaceFileModal";
import ViewDetailsDrawer from "./Drawers/ViewDetailsDrawer";
import { useAppDispatch, useAppSelector } from "../../Core/redux/hooks";
import { resetVault, updateVault } from "../../Core/redux/slices/vault.slice";
import CommentsDrawer from "./Drawers/CommentsDrawer";
import CancelUploadModal from "./Modals/CancelUploadModal";
import UploadFileModal from "./Modals/UploadFileModal";

import ShareAccessModal from "./Modals/ShareAccessModal";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import ProjectView from "./ProjectView";
import DocsView from "./DocsView";
import ProjectDocsMenuBar from "./Components/ProjectDocsMenuBar";
import ShareModal from "../Conversations/Modals/ShareModal";
import MoveToModal from "./Modals/MoveToModal";
import DragAndDropLayer from "./DragAndDropLayer";
import LibraryAppTour from "./LibraryAppTour";
import RequestAccessModal from "./Modals/RequestAccessModal";
import { Dropdown, MenuProps } from "antd";
import { NewFolderIcon } from "../../Core/svgV2/NewFolderIcon";
import { FileUploadIcon, LinkUploadIcon } from "../../Core/svgV2/uploadIcons";
import FilePreviweModal from "../Conversations/Modals/FIlePreviweModal";
import { resetConversations } from "../../Core/redux/slices/conversations.slice";
import { client } from "../../Core/utils/axiosClient";
import EmptyState from "./EmptyState";
import { getAllTourStatus } from "../../Core/redux/api/dashboardAPI";

const ContextMenuWrapper = ({ onUploadClick, children }) => {
  const { user } = useAppSelector((state) => state.userDetails);
  const dispatch = useAppDispatch();
  const groupItems = [
    {
      key: "1",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">New Folder</div>
      ),
      icon: <NewFolderIcon className="text-gray-500" />,
    },
    { key: "2", type: "divider" },

    {
      key: "3",
      label: (
        // <Upload>
        <div className="out-300-14 leading-5 text-gray-700">File Upload</div>
        // </Upload>
      ),
      icon: <FileUploadIcon className="text-gray-500" />,
    },
    {
      key: "4",
      label: (
        <div className="out-300-14 leading-5 text-gray-700">Link Upload</div>
      ),
      icon: <LinkUploadIcon className="text-gray-500" />,
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "1":
        dispatch(
          updateVault({
            key: "newFolderModal",
            value: true,
          })
        );
        break;
      case "3":
        onUploadClick();
        break;
      case "4":
        dispatch(
          updateVault({
            key: "newLinkModal",
            value: true,
          })
        );
        break;
      default:
        console.log("item others");
        break;
    }
  };
  if (+user.roleId === 4) {
    return <div>{children}</div>;
  } else {
    return (
      <Dropdown
        menu={{
          items: groupItems,
          onClick,
        }}
        trigger={["contextMenu"]}
        dropdownRender={(origin) => {
          return <div className="w-[250px]">{origin}</div>;
        }}
      >
        <div>{children}</div>
      </Dropdown>
    );
  }
};

const LibraryScreen = ({ socket }) => {
  const {
    viewDetailsDrawer,
    shareInMessageModal,
    moveToFolderModal,
    copyModal,
    currentSelectedProject,
    openCommentsModal,
    viewType,
    requestAccessModal,
    shareAccessModal,
    currentSelectedFolder,
  } = useAppSelector((state) => state.vault);

  const { projectId, "*": splat } = useParams();
  const [searchParams] = useSearchParams();

  const { filePreview } = useAppSelector((state) => state.conversations);
  const { user } = useAppSelector((state) => state.userDetails);
  const tourStatus = async () => {
    await dispatch(getAllTourStatus({ userId: user.id }));
  };

  const setViewType = (value: number) =>
    dispatch(updateVault({ key: "viewType", value }));

  const { projectList } = useAppSelector((state) => state.projects);

  const [hasCurrentFolderAccess, setHasCurrentFolderAccess] = useState(true);

  const dispatch = useAppDispatch();
  useEffect(() => {
    tourStatus();
  }, [user.id]);

  const location = useLocation();
  console.log(location.pathname, "path name lib proj");

  // const groupItems = [
  //   {
  //     key: "1",
  //     label: (
  //       <div className="out-300-14 leading-5 text-gray-700">New Folder</div>
  //     ),
  //     icon: <NewFolderIcon className="text-gray-500" />,
  //   },
  //   { key: "2", type: "divider" },

  //   {
  //     key: "3",
  //     label: (
  //       // <Upload>
  //       <div className="out-300-14 leading-5 text-gray-700">File Upload</div>
  //       // </Upload>
  //     ),
  //     icon: <FileUploadIcon className="text-gray-500" />,
  //   },
  //   {
  //     key: "4",
  //     label: (
  //       <div className="out-300-14 leading-5 text-gray-700">Link Upload</div>
  //     ),
  //     icon: <LinkUploadIcon className="text-gray-500" />,
  //   },
  // ];

  const CheckAccess = async () => {
    try {
      console.log(
        currentSelectedFolder.canEdit === 1 ||
          currentSelectedFolder.canView === 1 ||
          currentSelectedFolder.isOwner === 1 ||
          splat?.length === 0 ||
          location.pathname.includes("my-library"),
        "queries",
        location.pathname,
        splat
      );
      if (
        currentSelectedFolder.canEdit === 1 ||
        currentSelectedFolder.canView === 1 ||
        currentSelectedFolder.isOwner === 1 ||
        splat?.length === 0 ||
        location.pathname.includes("my-library") ||
        location.pathname === "/library"
      ) {
        dispatch(resetVault(["requestAccessModal"]));
        setHasCurrentFolderAccess(true);

        //
        if (searchParams.has("sharedLinkId")) {
          const { data } = await client.get("/repository/get-all-links", {
            params: { id: searchParams.get("sharedLinkId") },
          });
          if (data.currentLink) {
            const currentLink = data.currentLink;
            if (
              currentLink.canEdit === 1 ||
              currentLink.canView === 1 ||
              currentLink.isOwner === 1
            ) {
              console.log("sharedFileId::hasAccess");

              const selector = `[data-link-id="${searchParams.get(
                "sharedLinkId"
              )}"]`;
              const link = document.querySelector(selector);

              if (link) {
                link.scrollIntoView();
                link.classList.add("bg-gray-100");
                setTimeout(() => {
                  link.classList.remove("bg-gray-100");
                }, 4000);
              }
            } else {
              dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    title: "Waddle",
                    subtext:
                      "You need access. Request access, or switch to an account with access.",
                    data: {
                      type: "link",
                      link: currentLink,
                    },
                  },
                })
              );
            }
            console.log(data.currentFile, "sharedFileId");
          }
        } else if (searchParams.has("sharedFileId")) {
          const { data } = await client.get("/repository/all-files", {
            params: { id: searchParams.get("sharedFileId") },
          });
          if (data.currentFile) {
            const currentFile = data.currentFile;
            if (
              currentFile.canEdit === 1 ||
              currentFile.canView === 1 ||
              currentFile.isOwner === 1
            ) {
              const selector = `[data-file-id="${searchParams.get(
                "sharedFileId"
              )}"]`;
              const file = document.querySelector(selector);

              if (file) {
                file.scrollIntoView();
                file.classList.add("bg-gray-100");
                setTimeout(() => {
                  file.classList.remove("bg-gray-100");
                }, 4000);
              }
              console.log("sharedFileId::hasAccess");
            } else {
              dispatch(
                updateVault({
                  key: "requestAccessModal",
                  value: {
                    visible: true,
                    title: "Waddle",
                    subtext:
                      "You need access. Request access, or switch to an account with access.",
                    data: {
                      type: "file",
                      file: currentFile,
                    },
                  },
                })
              );
            }
            console.log(data.currentFile, "sharedFileId");
          }
        }
      } else {
        setHasCurrentFolderAccess(false);
        dispatch(
          updateVault({
            key: "requestAccessModal",
            value: {
              visible: true,
              title: "Waddle",
              subtext:
                "You need access. Request access, or switch to an account with access.",
              data: {
                type: "folder",
                folder: currentSelectedFolder,
              },
            },
          })
        );
      }
    } catch (e) {
      console.log(e, "queries");
    }
  };

  useEffect(() => {
    console.log("queries");
    CheckAccess();
  }, [currentSelectedFolder, searchParams]);

  const renderLibraryScreen = () => {
    if (!hasCurrentFolderAccess) {
      return (
        <EmptyState
          message={
            <>You dont have any access. To view, ask the owner for access</>
          }
        />
      );
    }

    if (projectId) {
      console.log(projectId, "project path");
      return (
        <>
          <DragAndDropLayer>
            {(onUploadClick) => (
              <ContextMenuWrapper onUploadClick={onUploadClick}>
                <>
                  <ProjectDocsMenuBar
                    viewType={viewType}
                    setViewType={setViewType}
                    onUploadClick={onUploadClick}
                  />

                  <DocsView viewType={viewType} />
                </>
              </ContextMenuWrapper>
            )}
          </DragAndDropLayer>
        </>
      );
    } else if (location.pathname.includes("my-library")) {
      if (+user.roleId === 4) {
        return (
          <EmptyState
            message={
              <>You dont have any access. To view, ask the owner for access</>
            }
          />
        );
      } else {
        return (
          <DragAndDropLayer>
            {(onUploadClick) => (
              <ContextMenuWrapper onUploadClick={onUploadClick}>
                <>
                  <LibraryMenuBar
                    menuBarId={3}
                    viewType={viewType}
                    setViewType={setViewType}
                    onUploadClick={onUploadClick}
                  />

                  <DocsView
                    viewType={viewType}
                    isMyLibrary={location.pathname.includes("my-library")}
                  />
                </>
              </ContextMenuWrapper>
            )}
          </DragAndDropLayer>
        );
      }
    } else if (location.pathname.includes("/library/global")) {
      return (
        <>
          <DragAndDropLayer>
            {(onUploadClick) => (
              <ContextMenuWrapper onUploadClick={onUploadClick}>
                <>
                  <ProjectDocsMenuBar
                    viewType={viewType}
                    setViewType={setViewType}
                    onUploadClick={onUploadClick}
                  />

                  <DocsView viewType={viewType} />
                </>
              </ContextMenuWrapper>
            )}
          </DragAndDropLayer>
        </>
      );
    } else {
      return (
        <div id="library-tour-grid-view">
          <DragAndDropLayer>
            {(onUploadClick) => (
              <ContextMenuWrapper onUploadClick={onUploadClick}>
                <>
                  <LibraryMenuBar
                    menuBarId={2}
                    viewType={viewType}
                    setViewType={setViewType}
                    onUploadClick={onUploadClick}
                  />

                  <ProjectView viewType={viewType} />
                </>
              </ContextMenuWrapper>
            )}
          </DragAndDropLayer>
        </div>
      );
    }
    // switch (viewType) {
    //   case 0:
    //     return <EmptyState />;
    //   case 1:
    //     return <GridView />;
    //   // return <EmptyState />;
    //   case 2:
    //     return <ListViewForDocuments />;
    //   default:
    //     return <></>;
    // }
  };

  const getTitle = () => {
    let routeName = "";
    if (location.pathname.includes("my-library")) {
      return "My Library";
    }
    if (projectId) {
      routeName = currentSelectedProject.title;
      if (!currentSelectedProject.title) {
        const currentProject = projectList.find((p) => p.id == projectId);
        if (currentProject?.title) {
          routeName = currentProject.title;
        }
      }
      return routeName;
    } else {
      return "All Projects";
    }
  };

  return (
    <CustomLayout
      page="library"
      title={
        <div className="flex gap-x-1.5">
          <span className="out-300-14 text-gray-500">Library</span>{" "}
          <span className="out-300-14 text-gray-500">/</span>
          <span className="out-500-14 text-black">{getTitle()}</span>{" "}
        </div>
      }
    >
      <LibraryAppTour />
      <div className="bg-white h-full">{renderLibraryScreen()}</div>
      <DeleteFolderModal />
      <DeleteFileModal />
      <DeleteLinkModal />

      <NewFolderModal />
      <NewLinkModal />
      {shareAccessModal?.visible && <ShareAccessModal />}

      <MoveToModal
        visible={moveToFolderModal.visible}
        onClose={() => dispatch(resetVault(["moveToFolderModal"]))}
      />

      <MakeACopyModal
        visible={copyModal.visible}
        onClose={() => dispatch(resetVault(["copyModal"]))}
      />

      {/* TO share the file,folder,doc in message  */}
      {shareInMessageModal.visible && (
        <ShareModal
          visible={shareInMessageModal.visible}
          socket={""}
          onClose={() => dispatch(resetVault(["shareInMessageModal"]))}
        />
      )}

      <RenameModal />
      <ReplaceFileModal />
      <CancelUploadModal />
      <UploadFileModal />
      <ViewDetailsDrawer
        open={viewDetailsDrawer.visible}
        onClose={() =>
          dispatch(
            updateVault({
              key: "viewDetailsDrawer",
              value: {
                visible: false,
                title: "",
              },
            })
          )
        }
      />
      <CommentsDrawer
        open={openCommentsModal.visible}
        socket={socket}
        onClose={() => dispatch(resetVault(["openCommentsModal"]))}
      />

      <RequestAccessModal
        visible={requestAccessModal.visible}
        onClose={() => dispatch(resetVault(["requestAccessModal"]))}
      />

      <FilePreviweModal
        visible={filePreview.visible}
        page="library"
        onClose={() => dispatch(resetConversations(["filePreview"]))}
      />
    </CustomLayout>
  );
};

export default LibraryScreen;
