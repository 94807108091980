import React, { useEffect } from "react";
import { ProductOnboardingLayout } from "../../../Core/Layout/OnboardingLayouts";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import {
  changeStep,
  handleHoverIndex,
  handleSelect,
  resetToInitialOnboarding,
} from "../../../Core/redux/slices/onboarding.slice";

import { getSDGs, getSectors } from "../../../Core/redux/api/onboardingAPI";

import { useFormik } from "formik";
import * as Yup from "yup";
import StepProgressBar from "./StepProgressBar";
import CurrentStep from "./CurrentStep";
import { CrossIconLarge } from "../../../Core/svgV2/CrossIcon";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../../Core/CommonV2/CustomSelect";

const Content = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sectorList, selectedSector } = useAppSelector(
    (state) => state.onboarding
  );
  const { tenant_id } = useAppSelector((state) => state.userDetails).user;
  // const [f, setF] = useState(false);

  React.useEffect(() => {
    dispatch(getSectors());
    dispatch(getSDGs());
  }, []);

  const formik = useFormik({
    initialValues: {
      sectorList: [],
    },
    validationSchema: Yup.object({
      sectorList: Yup.array()
        .min(1, "Please select the sector you work with to continue")
        .required("Please select the sector you work with to continue")
        .typeError("Please select the sector you work with to continue"),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(changeStep(2));
    },
  });
  console.log(formik, "formik");

  useEffect(() => {
    console.log(selectedSector);
    formik.setFieldValue("sectorList", selectedSector);
  }, [selectedSector]);

  const sectors = () => {
    // Return an empty array if sectorList is falsy
    if (!sectorList) return [];

    // Filter sectorList and return the result, or an empty array if selectedSector is falsy
    return selectedSector
      ? sectorList.filter((item) => selectedSector.includes(item?.id))
      : [];
  };
  let showError;
  let errorText;
  const name = "sectorList";
  if (formik) {
    showError = showError || (formik.touched[name] && !!formik.errors[name]);
    errorText = errorText || (formik.touched[name] && formik.errors[name]);
    // valid =
    //   valid ||
    //   (!formik.errors[name] &&
    //     formik.initialValues[name] !== formik.values[name])
  }
  return (
    <div className="p-6 w-full h-full bg-white">
      {/* <div className="progress-line">
        <div className="step top">
          <div className="number">1</div>
          <div className="box"></div>
        </div>
        <div className="step middle">
          <div className="number">2</div>
          <div className="box"></div>
        </div>
        <div className="step bottom">
          <div className="number">3</div>
          <div className="box"></div>
        </div>
      </div> */}
      {tenant_id ? (
        <div
          className="ml-10 mt-10 flex items-center justify-center out-500-14 text-gray-500 gap-x-2  absolute z-[100]"
          role="button"
          onClick={() => {
            dispatch(resetToInitialOnboarding({}));
            navigate(-1);
          }}
        >
          <CrossIconLarge className="text-gray-500" /> Cancel
        </div>
      ) : (
        ""
      )}
      <div className="pl-14 flex justify-start h-full items-center">
        <StepProgressBar
          currentStep={1}
          totalSteps={5}
          givenElementId="form1"
        />
        <form
          autoComplete="off"
          // style={{ width: 520 }}
          onSubmit={formik.handleSubmit}
          id="formik"
        >
          <div>
            <CurrentStep
              currentStep={1}
              totalStep={5}
              className=""
              id="form1"
            />
            <h1 className="out-500-20 text-gray-900 mb-2 mt-6">
              How do you intend to make a difference in tackling{" "}
              <span className="out-600-20 text-error-600">climate change?</span>
            </h1>
            <div
              className={
                showError
                  ? "flex justify-start items-center text-show-animation gap-x-2 out-300-16 text-error-600 min-h-[24px]"
                  : "flex justify-start items-center text-show-animation gap-x-2 out-400-16 text-gray-500 min-h-[24px]"
              }
            >
              <img
                src="/images/icons/frosty-icon.svg"
                className="min-h-[24px] min-w-[24px]"
                alt="frosty"
              />
              {showError ? errorText : "Let’s change the world together 🙌"}
            </div>
            <div className="flex justify-start items-center mb-6 gap-x-6 ">
              <div className="out-500-14 text-gray-900">
                I’m <span className="text-[#12B76A]">fighting</span> climate
                change with
              </div>
              <div className=" w-[258px] relative">
                <CustomSelect
                  value={null}
                  border={false}
                  placeholder={
                    <div className="out-300-14 text-gray-500">
                      Select your sector
                    </div>
                  }
                  showBorderOnlyOnFocused
                  options={sectorList?.map((s) => {
                    return {
                      id: s.id,
                      value: s.id,
                      render: () => (
                        <div
                          className="flex justify-between items-center"
                          onMouseEnter={() => dispatch(handleHoverIndex(s.id))}
                          onMouseLeave={() => dispatch(handleHoverIndex(s.id))}
                        >
                          <p>{s.value}</p>
                          {selectedSector?.includes(s.id) && (
                            <img src="/images/icons/check.svg" alt="" />
                          )}
                        </div>
                      ),
                    };
                  })}
                  onChange={async (e) => {
                    await dispatch(handleSelect(e));
                  }}
                  // formikHook={formik}

                  name={"sectorList"}
                />
                <div className="absolute top-10 left-2.5 w-[238px]">
                  {sectors()?.map((s, i) => (
                    <div
                      className={`mt-[15px] out-500-14 text-gray-900 flex justify-between items-center w-full ${
                        i == sectors.length - 1 ? "mb-6" : ""
                      }`}
                      key={i}
                    >
                      <div>{s.value}</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => dispatch(handleSelect(s.id))}
                      >
                        <CrossIconLarge className="text-gray-500" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: `${sectors().length * 35 + 24}px`,
              }}
            >
              <CustomButton
                type="submit"
                text={`Continue`}
                width="76px"
                height="30px"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const [hoverIndex, setHoverIndex] = React.useState(0);

  const { selectedSector, hoverIndexFromContent } = useAppSelector(
    (state) => state.onboarding
  );
  useEffect(() => {
    setHoverIndex(hoverIndexFromContent);
  }, [hoverIndexFromContent]);

  const onHover = (id) => {
    setHoverIndex(id);
  };

  const changeBgColor = () => {
    const finalEle = selectedSector ? selectedSector.length - 1 : 0;
    switch (hoverIndexFromContent || selectedSector?.[finalEle]) {
      case 1:
        return "bg-opacity-10 bg-success-600";
      case 2:
        return "bg-opacity-10 bg-transport-orange";
      case 3:
        return "bg-opacity-10 bg-farming-green";
      case 4:
        return "bg-opacity-10 bg-energy-yellow";
      case 5:
        return "bg-opacity-10 bg-energy-yellow";
      case 6:
        return "bg-opacity-10 bg-education-red";
      case 7:
        return "bg-opacity-10 bg-building-yellow";
      case 8:
        return "bg-opacity-10 bg-ecommerce-red";
      case 9:
        return "bg-opacity-10 bg-banking-red";
      case 10:
        return "bg-opacity-10 bg-primary-600";
      default:
        return "bg-gray-50";
    }
  };
  return (
    <div
      className={`w-full h-screen flex justify-center items-center ${changeBgColor()} `}
    >
      <div className="hexagon-wrapper mt-24">
        <div className="hexagon-list clearfix">
          <div className="placeholder-hexagon hexagon"></div>
          <div
            onMouseEnter={() => onHover(1)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(1) ? (
              <img
                src={`/images/icons/sector-icons/healthcare-selected.svg`}
                alt=""
                className="object-fit isthisit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 1
                    ? "healthcare-hover"
                    : "healthcare-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
        </div>
        <div className="hexagon-list relative -top-6 clearfix">
          <div
            onMouseEnter={() => onHover(2)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(2) ? (
              <img
                src={`/images/icons/sector-icons/transportation-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 2
                    ? "transportation-hover"
                    : "transportation-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(3)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(3) ? (
              <img
                src={`/images/icons/sector-icons/farming-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 3 ? "farming-hover" : "farming-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
        </div>
        <div className="hexagon-list relative -top-12 clearfix">
          <div className="placeholder-hexagon hexagon"></div>
          <div
            onMouseEnter={() => onHover(4)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(4) ? (
              <img
                src={`/images/icons/sector-icons/energy-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 4 ? "energy-hover" : "energy-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(5)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(5) ? (
              <img
                src={`/images/icons/sector-icons/technology-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 5
                    ? "technology-hover"
                    : "technology-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(6)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(6) ? (
              <img
                src={`/images/icons/sector-icons/education-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 6 ? "education-hover" : "education-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
        </div>
        <div className="hexagon-list relative -top-[72px] clearfix">
          <div
            onMouseEnter={() => onHover(7)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(7) ? (
              <img
                src={`/images/icons/sector-icons/building-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 7 ? "building-hover" : "building-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(8)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(8) ? (
              <img
                src={`/images/icons/sector-icons/ecommerce-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 8 ? "ecommerce-hover" : "ecommerce-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          <div
            onMouseEnter={() => onHover(9)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(9) ? (
              <img
                src={`/images/icons/sector-icons/banking-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 9 ? "banking-hover" : "banking-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
          {/* <div className="hexagon">
            <div className="hexagon-inner">CSS</div>
          </div> */}
        </div>
        <div className="hexagon-list relative -top-24 clearfix">
          <div className="placeholder-hexagon hexagon"></div>
          <div className="placeholder-hexagon hexagon"></div>
          <div
            onMouseEnter={() => onHover(10)}
            onMouseLeave={() => onHover(null)}
            className="hexagon"
          >
            {selectedSector?.includes(10) ? (
              <img
                src={`/images/icons/sector-icons/other-selected.svg`}
                alt=""
                className="object-fit"
              />
            ) : (
              <img
                src={`/images/icons/sector-icons/${
                  hoverIndex === 10 ? "others-hover" : "others-unselected"
                }.svg`}
                alt=""
                className="object-fit"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SectorSelection = () => {
  return (
    <ProductOnboardingLayout
      biggerSiderbar={true}
      content={<Content />}
      sidebar={<Sidebar />}
    />
  );
};

export default SectorSelection;
