import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import { Popover, Table } from "antd";

import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import CustomAvatar from "../../../Core/CommonV2/CustomAvatar";
import getUsersName from "../../../Core/utils/getUserName";
import { DotsVerticalIcon } from "../../../Core/svgV2/DotsVertical";

import { UserEditIcon, UserIcon } from "../../../Core/svgV2/UserIcon";

import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import AddMembersToProjectModal from "../Modals/AddMembersToProjectModal";
import ProjectsSettingsWrapper from "../ProjectsSettingsWrapper";
import useSearch from "../../../Core/hooks/useSearch";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import UserDetailsDrawer from "../../Dashboard/Drawers/UserDetailsDrawer";

import {
  resetSettings,
  updateSettings,
} from "../../../Core/redux/slices/settings.slice";
import { getAllRoles } from "../../../Core/redux/api/settingsAPI";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { client } from "../../../Core/utils/axiosClient";
import { getProjectDetatis } from "../../../Core/redux/api/projectAPI";
import usePermission from "../../../Core/hooks/usePermission";
import dayjs from "dayjs";
import { ChevronIcon } from "../../../Core/svgV2/Chevron";
import CreateAndEditRoleModal from "../Modals/CreateAndEditRoleModal";
import { TooltipPlacement } from "antd/es/tooltip";

const ChangeRoles = ({ children, user, placement = "leftBottom" }) => {
  const { roleList } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const { search, setSearch, filteredData } = useSearch(roleList, ["roleName"]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const handleRoleChange = async (role) => {
    try {
      await client.put("/settings/member-assign-roles", {
        userId: user.userId,
        roleId: role.id,
        roleName: role.roleName,
        projectId,
      });
      setPopoverOpen(false);
      dispatch(getProjectDetatis({ projectId }));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Popover
      open={popoverOpen}
      onOpenChange={(val) => setPopoverOpen(val)}
      content={
        <div className="w-[320px] bg-white  border border-gray-200 rounded-lg shadow-lg py-1">
          <div className="py-2.5  px-[14px]">
            <CustomSearchBox
              placeholder="Search Roles"
              search={search}
              setSearch={setSearch}
            />
          </div>
          <div className="max-h-[200px]  overflow-y-auto">
            {filteredData.map((role) => (
              <div
                role="button"
                className={`py-2.5 flex items-center gap-x-2 px-[14px] ${
                  +user.roleId === role.id && "bg-gray-100"
                }`}
                key={role.id}
                onClick={() => handleRoleChange(role)}
              >
                <div className="out-300-14 text-gray-900 flex-1">
                  {role.roleName}
                </div>
                {+user.roleId === role.id && (
                  <CheckIcon className="text-primary-600" />
                )}
              </div>
            ))}

            <div
              role="button"
              onClick={() => {
                setPopoverOpen(false);
                dispatch(
                  updateSettings({
                    key: "showRoleModal",
                    value: {
                      visible: true,
                      mode: "new",
                      role: {},
                    },
                  })
                );
              }}
              className="out-500-14  text-primary-600 px-[14px] py-[10px]"
            >
              Create New Role
            </div>
          </div>
        </div>
      }
      arrow={false}
      placement={placement as TooltipPlacement}
      trigger={"click"}
    >
      {children}
    </Popover>
  );
};

const MoreOptions = ({ text, record }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const dispatch = useAppDispatch();

  const { hasPermission } = usePermission("2");
  return (
    <Popover
      onOpenChange={(val) => setPopoverOpen(val)}
      open={popoverOpen}
      content={
        <div className="w-[250px] bg-white rounded-lg border border-gray-100 shadow-lg">
          <div
            role="button"
            className="cursor-pointer px-3.5 py-2.5 flex gap-x-2"
            onClick={() => {
              dispatch(
                updateDashboard({
                  key: "userDetailsDrawer",
                  value: { visible: true, data: record },
                })
              );
              setPopoverOpen(false);
            }}
          >
            <UserIcon className="text-gray-700" />{" "}
            <div className="out-300-14 leading-5 text-gray-700">
              View profile
            </div>
          </div>
          {hasPermission && (
            <>
              <ChangeRoles user={record}>
                <div
                  role="button"
                  className="px-3.5 cursor-pointer py-2.5 flex gap-x-2"
                >
                  <UserEditIcon className="text-gray-700" />
                  <div className="out-300-14 leading-5 text-gray-700">
                    Assign role
                  </div>
                </div>
              </ChangeRoles>
              <div
                role="button"
                className="px-3.5 cursor-pointer py-2.5 flex gap-x-2 border-t border-gray-100"
                onClick={() => {
                  dispatch(
                    updateSettings({
                      key: "removeMemberModal",
                      value: {
                        visible: true,
                        user: record,
                        action: "removeProjectMember",
                      },
                    })
                  );
                  setPopoverOpen(false);
                }}
              >
                <DeleteIcon className="text-gray-700" />
                <div className="out-300-14 leading-5 text-gray-700">
                  Remove member
                </div>
              </div>
            </>
          )}
        </div>
      }
      trigger={["click"]}
      placement="bottomRight"
      arrow={false}
    >
      <DotsVerticalIcon className="text-black" />
    </Popover>
  );
};

const ProjectMembers = () => {
  const dispatch = useAppDispatch();
  const { showRoleModal } = useAppSelector((state) => state.settings);
  const { userDetailsDrawer } = useAppSelector((state) => state.dashboard);
  const [searchParams] = useSearchParams();
  const { userList } = useAppSelector((state) => state.overview);
  const [addMembersToProjectModal, setAddMembersToProjectModal] =
    useState(false);
  const navigate = useNavigate();
  const { hasPermission: manageMembers } = usePermission("2");

  console.log(userList, "userList");
  const columns = [
    {
      title: () => (
        <div className="mon-500-10 leading-[18px] text-gray-500 ">Name</div>
      ),
      width: "20%",
      dataIndex: "firstName",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.firstname?.localeCompare(b.firstname),
      render: (text, record) => (
        <div className="flex items-center gap-x-3 ">
          <CustomAvatar
            title={getUsersName(record)}
            size={24}
            fontSize={"10px"}
            src={record.profilePicture}
            whiteText
            color={record.profilePictureColorCode}
          />
          <div>
            <p className="out-500-14 text-gray-900">{getUsersName(record)}</p>
            <p className="out-300-12 text-gray-500">{record.email || "N/A"}</p>
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 leading-[18px]  text-gray-500 ">
          Project Role
        </div>
      ),
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.roleId - b.roleId,
      dataIndex: "roleid",
      render: (text, record) => (
        <>
          {/* TODO: find where is this empty comming from  */}
          {manageMembers ? (
            <ChangeRoles user={record} placement="bottomLeft">
              {record.roleName && record.roleName !== "EMPTY" ? (
                <div className="flex items-center gap-x-1">
                  <div className="out-500-14 leading-5 group-hover:underline  whitespace-nowrap truncate text-primary-700 ">
                    {record.roleName}
                  </div>
                  <ChevronIcon className="text-primary-700" />
                </div>
              ) : (
                <div className="flex items-center gap-x-1">
                  <div className="out-500-14 leading-5 group-hover:underline text-primary-700 ">
                    Assign Role
                  </div>
                  <ChevronIcon className="text-primary-700" />
                </div>
              )}
            </ChangeRoles>
          ) : (
            <div className="out-500-14 leading-5  text-primary-700  whitespace-nowrap truncate">
              {record.roleName || "--"}
            </div>
          )}
        </>
      ),
    },
    {
      title: () => (
        <div className="mon-500-10 leading-[18px]  text-gray-500 ">
          Date Added
        </div>
      ),

      dataIndex: "dateAdded",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.userId - b.userId,
      render: (text) => (
        <p className="out-300-14 leading-5 text-gray-500 whitespace-nowrap truncate">
          {text ? dayjs(text).format("MMMM DD YYYY") : "--"}
        </p>
      ),
    },
    {
      width: "50px",
      render: (text, record) => <MoreOptions text={text} record={record} />,
    },
  ];

  const { search, setSearch, filteredData } = useSearch(userList, [
    "firstname",
    "lastname",
    "email",
  ]);

  useEffect(() => {
    if (searchParams.has("projectId")) {
      dispatch(getAllRoles({ projectId: searchParams.get("projectId") }));
    }
  }, [searchParams]);

  return (
    <div className="pb-40 max-w-[667px]">
      <PageTitle
        title={"Members"}
        subtext={"Manage project members and their roles."}
      />
      <ProjectsSettingsWrapper>
        <div className="my-6 flex justify-between">
          <CustomSearchBox
            placeholder="Search by name or email"
            style={{ width: "50%" }}
            search={search}
            setSearch={setSearch}
          />
          {manageMembers && (
            <div className="flex gap-x-3">
              <CustomButton
                height="31px"
                text="Invite People"
                className="bg-white border-gray-300 border"
                onClick={() => navigate("/invite-members")}
              />

              <CustomButton
                height="30px"
                text="Add Members"
                onClick={() => setAddMembersToProjectModal(true)}
              />
            </div>
          )}
        </div>

        <div className="mt-6 members-table">
          <style>{`.members-table .ant-table-tbody .ant-table-cell {
          padding:6px 24px !important;
        }`}</style>
          <Table
            columns={columns as any}
            dataSource={filteredData}
            pagination={false}
            rowClassName={"group"}
          />
        </div>
      </ProjectsSettingsWrapper>
      <AddMembersToProjectModal
        visible={addMembersToProjectModal}
        onClose={() => setAddMembersToProjectModal(false)}
      />

      <UserDetailsDrawer
        open={userDetailsDrawer.visible}
        user={userDetailsDrawer.data}
        mode="WorkspaceUserDetails"
        onClose={() =>
          dispatch(
            updateDashboard({
              key: "userDetailsDrawer",
              value: {
                visible: false,
                data: { title: "", subtitle: "", description: "" },
              },
            })
          )
        }
      />

      <CreateAndEditRoleModal
        visible={showRoleModal.visible}
        onClose={() => {
          dispatch(resetSettings(["showRoleModal"]));
        }}
      />
    </div>
  );
};

export default ProjectMembers;
