import React, { useState } from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";
import { useParams } from "react-router-dom";
import { Popover } from "antd";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { ProjectDropDown } from "./TimeEstimatesReusable";
import { useGetTenantProjects } from "../../Core/redux/ReactQueryHooksV3/userTimeEstimatesAPI";
import { CrossIconSecondary } from "../../Core/svgV2/CrossIcon";

const TimeEstimatesLayout = ({ children }) => {
  const { projectId } = useParams();
  const [isActive, setIsActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { data: projectsData, isLoading: isLoadingProject } =
    useGetTenantProjects();
  const projectList = projectsData?.projects;

  const [search, setSearch] = useState("");

  const filteredProjects = projectList?.filter((project) =>
    project.title.toLowerCase().includes(search.toLowerCase())
  );

  const getTitle = () => {
    let routeName = "";

    if (projectId) {
      const currentProject = projectList?.find((p) => p.id == projectId);
      if (currentProject?.title) {
        routeName = currentProject.title;
      }

      return routeName;
    } else {
      return "All Projects";
    }
  };
  const handleProjectSelect = () => {
    return setIsActive(false);
  };

  return (
    <CustomLayout
      page="time-estimates"
      title={
        <div className="flex items-center gap-x-1.5 ">
          <span className="out-300-14 text-gray-500">Time Estimates</span>{" "}
          <span className="out-300-14 text-gray-500">/</span>
          <Popover
            open={isActive}
            placement="bottomLeft"
            onOpenChange={(open) => {
              setSearch("");
              setIsActive(open);
            }}
            content={
              <div className="w-[400px] shadow-md rounded-md bg-white border border-gray-100">
                <div className="p-2 px-4">
                  <CustomSearchBox
                    placeholder="Search Projects"
                    className={`${
                      isFocused ? "border-[#2E90FA]" : "border-gray-300"
                    }`}
                    search={search}
                    setSearch={setSearch}
                    suffix={
                      search ? (
                        <span
                          onClick={() => setSearch("")}
                          className="cursor-pointer"
                        >
                          <CrossIconSecondary />
                        </span>
                      ) : null
                    }
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                </div>

                <div className="flex h-[255px]  flex-col items-start self-stretch">
                  <div className="w-full max-h-full overflow-y-auto hide-scrollbar">
                    {filteredProjects?.length ? (
                      filteredProjects?.map((project) => (
                        <ProjectDropDown
                          key={project.id}
                          project={project}
                          onSelect={() => setIsActive(false)}
                        />
                      ))
                    ) : (
                      <div className="p-4 text-center text-gray-500">
                        No projects found
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="w-full py-3 px-4 flex justify-between items-center"
                  style={{
                    borderTop: "1px solid var(--Gray-200, #DDE5ED)",
                    background: "var(--White, #F9FBFE)",
                  }}
                >
                  <div
                    className="text-primary-600 font-outfit text-sm font-medium leading-5 cursor-pointer"
                    onClick={() => setIsActive(!isActive)}
                  >
                    View all projects
                  </div>
                </div>
              </div>
            }
            arrow={false}
            trigger={["click"]}
          >
            <span
              className="flex items-center"
              onClick={() => setIsActive(!isActive)}
            >
              <span className="out-500-14 text-black cursor-pointer">
                {getTitle()}
              </span>{" "}
              <ChevronIcon
                className="cursor-pointer text-black"
                style={{
                  transition: "transform 0.3s ease-in-out 0s",
                  transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </span>
          </Popover>
        </div>
      }
    >
      {children}
    </CustomLayout>
  );
};

export default TimeEstimatesLayout;
