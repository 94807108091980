import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { HexagonalColorPicker } from "react-hexagon-color-picker";

const CustomColorPicker = ({
  children,
  onColorChange,
  placement,
  currentColor,
}: {
  children?: React.ReactNode;
  onColorChange?: (color) => void;
  placement?: any;
  currentColor?: string;
}) => {
  const [color, setColor] = useState("#165ABF");

  useEffect(() => {
    setColor(currentColor);
  }, [currentColor]);
  return (
    <Popover
      trigger={"click"}
      placement={placement}
      content={
        <div className="p-4">
          {" "}
          <HexagonalColorPicker
            hue={0}
            color={color}
            onColorChange={(e) => {
              setColor(e);
              onColorChange(e);
            }} // f("#444444")
            //   onColorClick={(e) => console.log("hi", e)}
          />
        </div>
      }
      arrow={false}
    >
      {children ? (
        children
      ) : (
        <button className="h-[30px] min-w-[30px] rounded border boder-gray-200 flex justify-center items-center">
          <div
            className="h-2.5 w-2.5 rounded-full"
            style={{
              backgroundColor: color,
            }}
          />
        </button>
      )}
    </Popover>
  );
};

export default CustomColorPicker;
